define("ember-promise-modals/templates/components/modal-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "74odEp1F",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"modals\",\"_stack\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"epm-backdrop \",[52,[30,1,[\"isClosing\"]],\"epm-out\"]]]],[14,\"tabindex\",\"-1\"],[14,\"role\",\"presentation\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-epm-backdrop\",[29,[[30,2]]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"epm-modal-container\"],[12],[1,\"\\n    \"],[8,[39,3],[[16,\"data-test-epm-modal\",[29,[[30,2]]]]],[[\"@modal\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"modal\",\"index\"],false,[\"each\",\"-track-array\",\"if\",\"epm-modal\"]]",
    "moduleName": "ember-promise-modals/templates/components/modal-container.hbs",
    "isStrictMode": false
  });
});