define("ember-svg-jar/inlined/play-chapters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>play</title><path d=\"M5.92 24.096q0 1.088.928 1.728.512.288 1.088.288.448 0 .896-.224l16.16-8.064q.48-.256.8-.736T26.08 16t-.288-1.056-.8-.736L8.832 6.144q-.448-.224-.896-.224-.544 0-1.088.288-.928.608-.928 1.728v16.16z\"/>",
    "attrs": {
      "fill": "#fff",
      "width": "64",
      "height": "64",
      "viewBox": "-14.4 -14.4 60.8 60.8",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "#fff",
      "stroke-width": "0"
    }
  };
});