define("ember-composable-helpers/helpers/find-by", ["exports", "@ember/component/helper", "@ember/utils", "@ember/array"], function (_exports, _helper, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findBy = findBy;
  function findBy([byPath, value, array]) {
    if ((0, _utils.isEmpty)(byPath)) {
      return [];
    }
    return (0, _array.A)(array).findBy(byPath, value);
  }
  var _default = _exports.default = (0, _helper.helper)(findBy);
});