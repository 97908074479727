define("ember-light-table/-private/sync-array-proxy", ["exports", "@ember/array/proxy", "@ember/debug", "@ember/array"], function (_exports, _proxy, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EMPTY_ARRAY = [];
  var _default = _exports.default = _proxy.default.extend({
    /**
     * The model that will be synchronized to the content of this proxy
     * @property syncArray
     * @type {Array}
     */
    syncArray: null,
    /**
     * @property syncEnabled
     * @type {Boolean}
     */
    syncEnabled: true,
    init() {
      this._super(...arguments);
      let syncArray = this.get('syncArray');
      (true && !((0, _array.isArray)(syncArray) && typeof syncArray.addArrayObserver === 'function') && (0, _debug.assert)('[ember-light-table] enableSync requires the passed array to be an instance of Ember.A', (0, _array.isArray)(syncArray) && typeof syncArray.addArrayObserver === 'function'));
      syncArray.addArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });
    },
    destroy() {
      this.get('syncArray').removeArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });
      this.setProperties({
        syncArray: null,
        content: null
      });
    },
    /**
     * Serialize objects before they are inserted into the content array
     * @method serializeContentObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeContentObjects(objects) {
      return objects;
    },
    /**
     * Serialize objects before they are inserted into the sync array
     * @method serializeSyncArrayObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeSyncArrayObjects(objects) {
      return objects;
    },
    syncArrayWillChange() {/* Not needed */},
    syncArrayDidChange(syncArray, start, removeCount, addCount) {
      let content = this.get('content');
      let objectsToAdd = EMPTY_ARRAY;
      if (!this.get('syncEnabled')) {
        return;
      }
      if (addCount > 0) {
        objectsToAdd = this.serializeContentObjects(syncArray.slice(start, start + addCount));
      }
      content.replace(start, removeCount, objectsToAdd);
    },
    replaceContent(start, removeCount, objectsToAdd) {
      let syncArray = this.get('syncArray');
      if (!this.get('syncEnabled')) {
        return this._super(...arguments);
      }
      syncArray.replace(start, removeCount, this.serializeSyncArrayObjects(objectsToAdd));
    }
  });
});