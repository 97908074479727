define("ember-svg-jar/inlined/play-search-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M486 226.9H127.6c-27 0-49 22-49 49v254.9c0 26.9 22 49 49 49H486c27 0 49-22.1 49-49V275.9c0-27-22-49-49-49zM330.5 454.7L245 503.9l.1-98.6.1-98.6 85.4 49.4 85.4 49.4-85.5 49.2z\" fill=\"#7F7F7F\" stroke=\"#7f7f7f\" stroke-width=\"5\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 595.3 841.9"
    }
  };
});