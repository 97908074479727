define("ember-svg-jar/inlined/play-chapters-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button</title><path fill=\"#edf2f7\" d=\"M47.5 3.5a44 44 0 1044 44 44.05 44.05 0 00-44-44zm0 84a40 40 0 1140-40 40 40 0 01-40 40z\"/><path fill=\"#edf2f7\" d=\"M70.44 45.77L36.56 26.21a2 2 0 00-3 1.73v39.12a2 2 0 002 2 2 2 0 001-.27l33.88-19.56a2 2 0 000-3.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 95 95"
    }
  };
});