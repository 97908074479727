// AWS SDK for JavaScript v2.702.0
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// License at https://sdk.amazonaws.com/js/BUNDLE_LICENSE.txt
(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-12-08",
    "endpointPrefix": "acm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "ACM",
    "serviceFullName": "AWS Certificate Manager",
    "serviceId": "ACM",
    "signatureVersion": "v4",
    "targetPrefix": "CertificateManager",
    "uid": "acm-2015-12-08"
  },
  "operations": {
    "AddTagsToCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "Tags"
        ],
        "members": {
          "CertificateArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      }
    },
    "DeleteCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      }
    },
    "DescribeCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {
            "type": "structure",
            "members": {
              "CertificateArn": {},
              "DomainName": {},
              "SubjectAlternativeNames": {
                "shape": "Sc"
              },
              "DomainValidationOptions": {
                "shape": "Sd"
              },
              "Serial": {},
              "Subject": {},
              "Issuer": {},
              "CreatedAt": {
                "type": "timestamp"
              },
              "IssuedAt": {
                "type": "timestamp"
              },
              "ImportedAt": {
                "type": "timestamp"
              },
              "Status": {},
              "RevokedAt": {
                "type": "timestamp"
              },
              "RevocationReason": {},
              "NotBefore": {
                "type": "timestamp"
              },
              "NotAfter": {
                "type": "timestamp"
              },
              "KeyAlgorithm": {},
              "SignatureAlgorithm": {},
              "InUseBy": {
                "type": "list",
                "member": {}
              },
              "FailureReason": {},
              "Type": {},
              "RenewalSummary": {
                "type": "structure",
                "required": [
                  "RenewalStatus",
                  "DomainValidationOptions",
                  "UpdatedAt"
                ],
                "members": {
                  "RenewalStatus": {},
                  "DomainValidationOptions": {
                    "shape": "Sd"
                  },
                  "RenewalStatusReason": {},
                  "UpdatedAt": {
                    "type": "timestamp"
                  }
                }
              },
              "KeyUsages": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              },
              "ExtendedKeyUsages": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "OID": {}
                  }
                }
              },
              "CertificateAuthorityArn": {},
              "RenewalEligibility": {},
              "Options": {
                "shape": "S11"
              }
            }
          }
        }
      }
    },
    "ExportCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "Passphrase"
        ],
        "members": {
          "CertificateArn": {},
          "Passphrase": {
            "type": "blob",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {},
          "CertificateChain": {},
          "PrivateKey": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {},
          "CertificateChain": {}
        }
      }
    },
    "ImportCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "Certificate",
          "PrivateKey"
        ],
        "members": {
          "CertificateArn": {},
          "Certificate": {
            "type": "blob"
          },
          "PrivateKey": {
            "type": "blob",
            "sensitive": true
          },
          "CertificateChain": {
            "type": "blob"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateArn": {}
        }
      }
    },
    "ListCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "CertificateStatuses": {
            "type": "list",
            "member": {}
          },
          "Includes": {
            "type": "structure",
            "members": {
              "extendedKeyUsage": {
                "type": "list",
                "member": {}
              },
              "keyUsage": {
                "type": "list",
                "member": {}
              },
              "keyTypes": {
                "type": "list",
                "member": {}
              }
            }
          },
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "CertificateSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CertificateArn": {},
                "DomainName": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3"
          }
        }
      }
    },
    "RemoveTagsFromCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "Tags"
        ],
        "members": {
          "CertificateArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      }
    },
    "RenewCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      }
    },
    "RequestCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "ValidationMethod": {},
          "SubjectAlternativeNames": {
            "shape": "Sc"
          },
          "IdempotencyToken": {},
          "DomainValidationOptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DomainName",
                "ValidationDomain"
              ],
              "members": {
                "DomainName": {},
                "ValidationDomain": {}
              }
            }
          },
          "Options": {
            "shape": "S11"
          },
          "CertificateAuthorityArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateArn": {}
        }
      }
    },
    "ResendValidationEmail": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "Domain",
          "ValidationDomain"
        ],
        "members": {
          "CertificateArn": {},
          "Domain": {},
          "ValidationDomain": {}
        }
      }
    },
    "UpdateCertificateOptions": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn",
          "Options"
        ],
        "members": {
          "CertificateArn": {},
          "Options": {
            "shape": "S11"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "ValidationEmails": {
            "type": "list",
            "member": {}
          },
          "ValidationDomain": {},
          "ValidationStatus": {},
          "ResourceRecord": {
            "type": "structure",
            "required": [
              "Name",
              "Type",
              "Value"
            ],
            "members": {
              "Name": {},
              "Type": {},
              "Value": {}
            }
          },
          "ValidationMethod": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "CertificateTransparencyLoggingPreference": {}
      }
    }
  }
}
},{}],2:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListCertificates": {
      "input_token": "NextToken",
      "limit_key": "MaxItems",
      "output_token": "NextToken",
      "result_key": "CertificateSummaryList"
    }
  }
}
},{}],3:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "CertificateValidated": {
      "delay": 60,
      "maxAttempts": 40,
      "operation": "DescribeCertificate",
      "acceptors": [
        {
          "matcher": "pathAll",
          "expected": "SUCCESS",
          "argument": "Certificate.DomainValidationOptions[].ValidationStatus",
          "state": "success"
        },
        {
          "matcher": "pathAny",
          "expected": "PENDING_VALIDATION",
          "argument": "Certificate.DomainValidationOptions[].ValidationStatus",
          "state": "retry"
        },
        {
          "matcher": "path",
          "expected": "FAILED",
          "argument": "Certificate.Status",
          "state": "failure"
        },
        {
          "matcher": "error",
          "expected": "ResourceNotFoundException",
          "state": "failure"
        }
      ]
    }
  }
}

},{}],4:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-07-09",
    "endpointPrefix": "apigateway",
    "protocol": "rest-json",
    "serviceFullName": "Amazon API Gateway",
    "serviceId": "API Gateway",
    "signatureVersion": "v4",
    "uid": "apigateway-2015-07-09"
  },
  "operations": {
    "CreateApiKey": {
      "http": {
        "requestUri": "/apikeys",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "enabled": {
            "type": "boolean"
          },
          "generateDistinctId": {
            "type": "boolean"
          },
          "value": {},
          "stageKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "restApiId": {},
                "stageName": {}
              }
            }
          },
          "customerId": {},
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "CreateAuthorizer": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/authorizers",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "name",
          "type"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "name": {},
          "type": {},
          "providerARNs": {
            "shape": "Sc"
          },
          "authType": {},
          "authorizerUri": {},
          "authorizerCredentials": {},
          "identitySource": {},
          "identityValidationExpression": {},
          "authorizerResultTtlInSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "Sf"
      }
    },
    "CreateBasePathMapping": {
      "http": {
        "requestUri": "/domainnames/{domain_name}/basepathmappings",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "restApiId"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "basePath": {},
          "restApiId": {},
          "stage": {}
        }
      },
      "output": {
        "shape": "Sh"
      }
    },
    "CreateDeployment": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/deployments",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {},
          "stageDescription": {},
          "description": {},
          "cacheClusterEnabled": {
            "type": "boolean"
          },
          "cacheClusterSize": {},
          "variables": {
            "shape": "S6"
          },
          "canarySettings": {
            "type": "structure",
            "members": {
              "percentTraffic": {
                "type": "double"
              },
              "stageVariableOverrides": {
                "shape": "S6"
              },
              "useStageCache": {
                "type": "boolean"
              }
            }
          },
          "tracingEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "CreateDocumentationPart": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/documentation/parts",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "location",
          "properties"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "location": {
            "shape": "Ss"
          },
          "properties": {}
        }
      },
      "output": {
        "shape": "Sv"
      }
    },
    "CreateDocumentationVersion": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/documentation/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationVersion"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationVersion": {},
          "stageName": {},
          "description": {}
        }
      },
      "output": {
        "shape": "Sx"
      }
    },
    "CreateDomainName": {
      "http": {
        "requestUri": "/domainnames",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {},
          "certificateName": {},
          "certificateBody": {},
          "certificatePrivateKey": {},
          "certificateChain": {},
          "certificateArn": {},
          "regionalCertificateName": {},
          "regionalCertificateArn": {},
          "endpointConfiguration": {
            "shape": "Sz"
          },
          "tags": {
            "shape": "S6"
          },
          "securityPolicy": {}
        }
      },
      "output": {
        "shape": "S13"
      }
    },
    "CreateModel": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/models",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "name",
          "contentType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "name": {},
          "description": {},
          "schema": {},
          "contentType": {}
        }
      },
      "output": {
        "shape": "S16"
      }
    },
    "CreateRequestValidator": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/requestvalidators",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "name": {},
          "validateRequestBody": {
            "type": "boolean"
          },
          "validateRequestParameters": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S18"
      }
    },
    "CreateResource": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/resources/{parent_id}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "parentId",
          "pathPart"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "parentId": {
            "location": "uri",
            "locationName": "parent_id"
          },
          "pathPart": {}
        }
      },
      "output": {
        "shape": "S1a"
      }
    },
    "CreateRestApi": {
      "http": {
        "requestUri": "/restapis",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "version": {},
          "cloneFrom": {},
          "binaryMediaTypes": {
            "shape": "S9"
          },
          "minimumCompressionSize": {
            "type": "integer"
          },
          "apiKeySource": {},
          "endpointConfiguration": {
            "shape": "Sz"
          },
          "policy": {},
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S1r"
      }
    },
    "CreateStage": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/stages",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName",
          "deploymentId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {},
          "deploymentId": {},
          "description": {},
          "cacheClusterEnabled": {
            "type": "boolean"
          },
          "cacheClusterSize": {},
          "variables": {
            "shape": "S6"
          },
          "documentationVersion": {},
          "canarySettings": {
            "shape": "S1t"
          },
          "tracingEnabled": {
            "type": "boolean"
          },
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "CreateUsagePlan": {
      "http": {
        "requestUri": "/usageplans",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "apiStages": {
            "shape": "S21"
          },
          "throttle": {
            "shape": "S24"
          },
          "quota": {
            "shape": "S25"
          },
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "CreateUsagePlanKey": {
      "http": {
        "requestUri": "/usageplans/{usageplanId}/keys",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId",
          "keyId",
          "keyType"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "keyId": {},
          "keyType": {}
        }
      },
      "output": {
        "shape": "S29"
      }
    },
    "CreateVpcLink": {
      "http": {
        "requestUri": "/vpclinks",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "targetArns"
        ],
        "members": {
          "name": {},
          "description": {},
          "targetArns": {
            "shape": "S9"
          },
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S2b"
      }
    },
    "DeleteApiKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/apikeys/{api_Key}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "apiKey"
        ],
        "members": {
          "apiKey": {
            "location": "uri",
            "locationName": "api_Key"
          }
        }
      }
    },
    "DeleteAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/authorizers/{authorizer_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "authorizerId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "authorizerId": {
            "location": "uri",
            "locationName": "authorizer_id"
          }
        }
      }
    },
    "DeleteBasePathMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domainnames/{domain_name}/basepathmappings/{base_path}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "basePath"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "basePath": {
            "location": "uri",
            "locationName": "base_path"
          }
        }
      }
    },
    "DeleteClientCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clientcertificates/{clientcertificate_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "clientCertificateId"
        ],
        "members": {
          "clientCertificateId": {
            "location": "uri",
            "locationName": "clientcertificate_id"
          }
        }
      }
    },
    "DeleteDeployment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/deployments/{deployment_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "deploymentId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "deploymentId": {
            "location": "uri",
            "locationName": "deployment_id"
          }
        }
      }
    },
    "DeleteDocumentationPart": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/documentation/parts/{part_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationPartId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationPartId": {
            "location": "uri",
            "locationName": "part_id"
          }
        }
      }
    },
    "DeleteDocumentationVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/documentation/versions/{doc_version}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationVersion"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationVersion": {
            "location": "uri",
            "locationName": "doc_version"
          }
        }
      }
    },
    "DeleteDomainName": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domainnames/{domain_name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          }
        }
      }
    },
    "DeleteGatewayResponse": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/gatewayresponses/{response_type}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "responseType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "responseType": {
            "location": "uri",
            "locationName": "response_type"
          }
        }
      }
    },
    "DeleteIntegration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          }
        }
      }
    },
    "DeleteIntegrationResponse": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration/responses/{status_code}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          }
        }
      }
    },
    "DeleteMethod": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          }
        }
      }
    },
    "DeleteMethodResponse": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/responses/{status_code}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          }
        }
      }
    },
    "DeleteModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/models/{model_name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "modelName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "modelName": {
            "location": "uri",
            "locationName": "model_name"
          }
        }
      }
    },
    "DeleteRequestValidator": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/requestvalidators/{requestvalidator_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "requestValidatorId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "requestValidatorId": {
            "location": "uri",
            "locationName": "requestvalidator_id"
          }
        }
      }
    },
    "DeleteResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          }
        }
      }
    },
    "DeleteRestApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          }
        }
      }
    },
    "DeleteStage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          }
        }
      }
    },
    "DeleteUsagePlan": {
      "http": {
        "method": "DELETE",
        "requestUri": "/usageplans/{usageplanId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          }
        }
      }
    },
    "DeleteUsagePlanKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/usageplans/{usageplanId}/keys/{keyId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId",
          "keyId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "keyId": {
            "location": "uri",
            "locationName": "keyId"
          }
        }
      }
    },
    "DeleteVpcLink": {
      "http": {
        "method": "DELETE",
        "requestUri": "/vpclinks/{vpclink_id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "vpcLinkId"
        ],
        "members": {
          "vpcLinkId": {
            "location": "uri",
            "locationName": "vpclink_id"
          }
        }
      }
    },
    "FlushStageAuthorizersCache": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}/cache/authorizers",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          }
        }
      }
    },
    "FlushStageCache": {
      "http": {
        "method": "DELETE",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}/cache/data",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          }
        }
      }
    },
    "GenerateClientCertificate": {
      "http": {
        "requestUri": "/clientcertificates",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "description": {},
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S32"
      }
    },
    "GetAccount": {
      "http": {
        "method": "GET",
        "requestUri": "/account"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "shape": "S34"
      }
    },
    "GetApiKey": {
      "http": {
        "method": "GET",
        "requestUri": "/apikeys/{api_Key}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiKey"
        ],
        "members": {
          "apiKey": {
            "location": "uri",
            "locationName": "api_Key"
          },
          "includeValue": {
            "location": "querystring",
            "locationName": "includeValue",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "GetApiKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/apikeys"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nameQuery": {
            "location": "querystring",
            "locationName": "name"
          },
          "customerId": {
            "location": "querystring",
            "locationName": "customerId"
          },
          "includeValues": {
            "location": "querystring",
            "locationName": "includeValues",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "warnings": {
            "shape": "S9"
          },
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      }
    },
    "GetAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/authorizers/{authorizer_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "authorizerId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "authorizerId": {
            "location": "uri",
            "locationName": "authorizer_id"
          }
        }
      },
      "output": {
        "shape": "Sf"
      }
    },
    "GetAuthorizers": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/authorizers"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          }
        }
      }
    },
    "GetBasePathMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/domainnames/{domain_name}/basepathmappings/{base_path}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "basePath"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "basePath": {
            "location": "uri",
            "locationName": "base_path"
          }
        }
      },
      "output": {
        "shape": "Sh"
      }
    },
    "GetBasePathMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/domainnames/{domain_name}/basepathmappings"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "Sh"
            }
          }
        }
      }
    },
    "GetClientCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/clientcertificates/{clientcertificate_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientCertificateId"
        ],
        "members": {
          "clientCertificateId": {
            "location": "uri",
            "locationName": "clientcertificate_id"
          }
        }
      },
      "output": {
        "shape": "S32"
      }
    },
    "GetClientCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/clientcertificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S32"
            }
          }
        }
      }
    },
    "GetDeployment": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/deployments/{deployment_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "deploymentId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "deploymentId": {
            "location": "uri",
            "locationName": "deployment_id"
          },
          "embed": {
            "shape": "S9",
            "location": "querystring",
            "locationName": "embed"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "GetDeployments": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/deployments"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          }
        }
      }
    },
    "GetDocumentationPart": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/documentation/parts/{part_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationPartId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationPartId": {
            "location": "uri",
            "locationName": "part_id"
          }
        }
      },
      "output": {
        "shape": "Sv"
      }
    },
    "GetDocumentationParts": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/documentation/parts"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          },
          "nameQuery": {
            "location": "querystring",
            "locationName": "name"
          },
          "path": {
            "location": "querystring",
            "locationName": "path"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "locationStatus": {
            "location": "querystring",
            "locationName": "locationStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          }
        }
      }
    },
    "GetDocumentationVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/documentation/versions/{doc_version}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationVersion"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationVersion": {
            "location": "uri",
            "locationName": "doc_version"
          }
        }
      },
      "output": {
        "shape": "Sx"
      }
    },
    "GetDocumentationVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/documentation/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          }
        }
      }
    },
    "GetDomainName": {
      "http": {
        "method": "GET",
        "requestUri": "/domainnames/{domain_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          }
        }
      },
      "output": {
        "shape": "S13"
      }
    },
    "GetDomainNames": {
      "http": {
        "method": "GET",
        "requestUri": "/domainnames"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "GetExport": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}/exports/{export_type}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName",
          "exportType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          },
          "exportType": {
            "location": "uri",
            "locationName": "export_type"
          },
          "parameters": {
            "shape": "S6",
            "location": "querystring"
          },
          "accepts": {
            "location": "header",
            "locationName": "Accept"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "contentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "body": {
            "type": "blob"
          }
        },
        "payload": "body"
      }
    },
    "GetGatewayResponse": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/gatewayresponses/{response_type}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "responseType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "responseType": {
            "location": "uri",
            "locationName": "response_type"
          }
        }
      },
      "output": {
        "shape": "S46"
      }
    },
    "GetGatewayResponses": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/gatewayresponses"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S46"
            }
          }
        }
      }
    },
    "GetIntegration": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          }
        }
      },
      "output": {
        "shape": "S1h"
      }
    },
    "GetIntegrationResponse": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration/responses/{status_code}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          }
        }
      },
      "output": {
        "shape": "S1n"
      }
    },
    "GetMethod": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          }
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "GetMethodResponse": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/responses/{status_code}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          }
        }
      },
      "output": {
        "shape": "S1f"
      }
    },
    "GetModel": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/models/{model_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "modelName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "modelName": {
            "location": "uri",
            "locationName": "model_name"
          },
          "flatten": {
            "location": "querystring",
            "locationName": "flatten",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S16"
      }
    },
    "GetModelTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/models/{model_name}/default_template"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "modelName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "modelName": {
            "location": "uri",
            "locationName": "model_name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "value": {}
        }
      }
    },
    "GetModels": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/models"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S16"
            }
          }
        }
      }
    },
    "GetRequestValidator": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/requestvalidators/{requestvalidator_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "requestValidatorId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "requestValidatorId": {
            "location": "uri",
            "locationName": "requestvalidator_id"
          }
        }
      },
      "output": {
        "shape": "S18"
      }
    },
    "GetRequestValidators": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/requestvalidators"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S18"
            }
          }
        }
      }
    },
    "GetResource": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "embed": {
            "shape": "S9",
            "location": "querystring",
            "locationName": "embed"
          }
        }
      },
      "output": {
        "shape": "S1a"
      }
    },
    "GetResources": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/resources"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "embed": {
            "shape": "S9",
            "location": "querystring",
            "locationName": "embed"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          }
        }
      }
    },
    "GetRestApi": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          }
        }
      },
      "output": {
        "shape": "S1r"
      }
    },
    "GetRestApis": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S1r"
            }
          }
        }
      }
    },
    "GetSdk": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}/sdks/{sdk_type}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName",
          "sdkType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          },
          "sdkType": {
            "location": "uri",
            "locationName": "sdk_type"
          },
          "parameters": {
            "shape": "S6",
            "location": "querystring"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "contentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "body": {
            "type": "blob"
          }
        },
        "payload": "body"
      }
    },
    "GetSdkType": {
      "http": {
        "method": "GET",
        "requestUri": "/sdktypes/{sdktype_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {
            "location": "uri",
            "locationName": "sdktype_id"
          }
        }
      },
      "output": {
        "shape": "S4z"
      }
    },
    "GetSdkTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/sdktypes"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S4z"
            }
          }
        }
      }
    },
    "GetStage": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "GetStages": {
      "http": {
        "method": "GET",
        "requestUri": "/restapis/{restapi_id}/stages"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "deploymentId": {
            "location": "querystring",
            "locationName": "deploymentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "item": {
            "type": "list",
            "member": {
              "shape": "S1u"
            }
          }
        }
      }
    },
    "GetTags": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resource_arn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resource_arn"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S6"
          }
        }
      }
    },
    "GetUsage": {
      "http": {
        "method": "GET",
        "requestUri": "/usageplans/{usageplanId}/usage"
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId",
          "startDate",
          "endDate"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "keyId": {
            "location": "querystring",
            "locationName": "keyId"
          },
          "startDate": {
            "location": "querystring",
            "locationName": "startDate"
          },
          "endDate": {
            "location": "querystring",
            "locationName": "endDate"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S5c"
      }
    },
    "GetUsagePlan": {
      "http": {
        "method": "GET",
        "requestUri": "/usageplans/{usageplanId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "GetUsagePlanKey": {
      "http": {
        "method": "GET",
        "requestUri": "/usageplans/{usageplanId}/keys/{keyId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId",
          "keyId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "keyId": {
            "location": "uri",
            "locationName": "keyId"
          }
        }
      },
      "output": {
        "shape": "S29"
      }
    },
    "GetUsagePlanKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/usageplans/{usageplanId}/keys"
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nameQuery": {
            "location": "querystring",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S29"
            }
          }
        }
      }
    },
    "GetUsagePlans": {
      "http": {
        "method": "GET",
        "requestUri": "/usageplans"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "keyId": {
            "location": "querystring",
            "locationName": "keyId"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S27"
            }
          }
        }
      }
    },
    "GetVpcLink": {
      "http": {
        "method": "GET",
        "requestUri": "/vpclinks/{vpclink_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "vpcLinkId"
        ],
        "members": {
          "vpcLinkId": {
            "location": "uri",
            "locationName": "vpclink_id"
          }
        }
      },
      "output": {
        "shape": "S2b"
      }
    },
    "GetVpcLinks": {
      "http": {
        "method": "GET",
        "requestUri": "/vpclinks"
      },
      "input": {
        "type": "structure",
        "members": {
          "position": {
            "location": "querystring",
            "locationName": "position"
          },
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "position": {},
          "items": {
            "locationName": "item",
            "type": "list",
            "member": {
              "shape": "S2b"
            }
          }
        }
      }
    },
    "ImportApiKeys": {
      "http": {
        "requestUri": "/apikeys?mode=import",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "body",
          "format"
        ],
        "members": {
          "body": {
            "type": "blob"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "failOnWarnings": {
            "location": "querystring",
            "locationName": "failonwarnings",
            "type": "boolean"
          }
        },
        "payload": "body"
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S9"
          },
          "warnings": {
            "shape": "S9"
          }
        }
      }
    },
    "ImportDocumentationParts": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/documentation/parts"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "body"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "mode": {
            "location": "querystring",
            "locationName": "mode"
          },
          "failOnWarnings": {
            "location": "querystring",
            "locationName": "failonwarnings",
            "type": "boolean"
          },
          "body": {
            "type": "blob"
          }
        },
        "payload": "body"
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S9"
          },
          "warnings": {
            "shape": "S9"
          }
        }
      }
    },
    "ImportRestApi": {
      "http": {
        "requestUri": "/restapis?mode=import",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "body"
        ],
        "members": {
          "failOnWarnings": {
            "location": "querystring",
            "locationName": "failonwarnings",
            "type": "boolean"
          },
          "parameters": {
            "shape": "S6",
            "location": "querystring"
          },
          "body": {
            "type": "blob"
          }
        },
        "payload": "body"
      },
      "output": {
        "shape": "S1r"
      }
    },
    "PutGatewayResponse": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/gatewayresponses/{response_type}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "responseType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "responseType": {
            "location": "uri",
            "locationName": "response_type"
          },
          "statusCode": {},
          "responseParameters": {
            "shape": "S6"
          },
          "responseTemplates": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S46"
      }
    },
    "PutIntegration": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "type"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "type": {},
          "integrationHttpMethod": {
            "locationName": "httpMethod"
          },
          "uri": {},
          "connectionType": {},
          "connectionId": {},
          "credentials": {},
          "requestParameters": {
            "shape": "S6"
          },
          "requestTemplates": {
            "shape": "S6"
          },
          "passthroughBehavior": {},
          "cacheNamespace": {},
          "cacheKeyParameters": {
            "shape": "S9"
          },
          "contentHandling": {},
          "timeoutInMillis": {
            "type": "integer"
          },
          "tlsConfig": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "shape": "S1h"
      }
    },
    "PutIntegrationResponse": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration/responses/{status_code}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          },
          "selectionPattern": {},
          "responseParameters": {
            "shape": "S6"
          },
          "responseTemplates": {
            "shape": "S6"
          },
          "contentHandling": {}
        }
      },
      "output": {
        "shape": "S1n"
      }
    },
    "PutMethod": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "authorizationType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "authorizationType": {},
          "authorizerId": {},
          "apiKeyRequired": {
            "type": "boolean"
          },
          "operationName": {},
          "requestParameters": {
            "shape": "S1d"
          },
          "requestModels": {
            "shape": "S6"
          },
          "requestValidatorId": {},
          "authorizationScopes": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "PutMethodResponse": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/responses/{status_code}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          },
          "responseParameters": {
            "shape": "S1d"
          },
          "responseModels": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S1f"
      }
    },
    "PutRestApi": {
      "http": {
        "method": "PUT",
        "requestUri": "/restapis/{restapi_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "body"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "mode": {
            "location": "querystring",
            "locationName": "mode"
          },
          "failOnWarnings": {
            "location": "querystring",
            "locationName": "failonwarnings",
            "type": "boolean"
          },
          "parameters": {
            "shape": "S6",
            "location": "querystring"
          },
          "body": {
            "type": "blob"
          }
        },
        "payload": "body"
      },
      "output": {
        "shape": "S1r"
      }
    },
    "TagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/tags/{resource_arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resource_arn"
          },
          "tags": {
            "shape": "S6"
          }
        }
      }
    },
    "TestInvokeAuthorizer": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/authorizers/{authorizer_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "authorizerId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "authorizerId": {
            "location": "uri",
            "locationName": "authorizer_id"
          },
          "headers": {
            "shape": "S6"
          },
          "multiValueHeaders": {
            "shape": "S68"
          },
          "pathWithQueryString": {},
          "body": {},
          "stageVariables": {
            "shape": "S6"
          },
          "additionalContext": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clientStatus": {
            "type": "integer"
          },
          "log": {},
          "latency": {
            "type": "long"
          },
          "principalId": {},
          "policy": {},
          "authorization": {
            "shape": "S68"
          },
          "claims": {
            "shape": "S6"
          }
        }
      }
    },
    "TestInvokeMethod": {
      "http": {
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "pathWithQueryString": {},
          "body": {},
          "headers": {
            "shape": "S6"
          },
          "multiValueHeaders": {
            "shape": "S68"
          },
          "clientCertificateId": {},
          "stageVariables": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {
            "type": "integer"
          },
          "body": {},
          "headers": {
            "shape": "S6"
          },
          "multiValueHeaders": {
            "shape": "S68"
          },
          "log": {},
          "latency": {
            "type": "long"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resource_arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resource_arn"
          },
          "tagKeys": {
            "shape": "S9",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        }
      }
    },
    "UpdateAccount": {
      "http": {
        "method": "PATCH",
        "requestUri": "/account"
      },
      "input": {
        "type": "structure",
        "members": {
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S34"
      }
    },
    "UpdateApiKey": {
      "http": {
        "method": "PATCH",
        "requestUri": "/apikeys/{api_Key}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiKey"
        ],
        "members": {
          "apiKey": {
            "location": "uri",
            "locationName": "api_Key"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "UpdateAuthorizer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/authorizers/{authorizer_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "authorizerId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "authorizerId": {
            "location": "uri",
            "locationName": "authorizer_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "Sf"
      }
    },
    "UpdateBasePathMapping": {
      "http": {
        "method": "PATCH",
        "requestUri": "/domainnames/{domain_name}/basepathmappings/{base_path}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "basePath"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "basePath": {
            "location": "uri",
            "locationName": "base_path"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "Sh"
      }
    },
    "UpdateClientCertificate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/clientcertificates/{clientcertificate_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientCertificateId"
        ],
        "members": {
          "clientCertificateId": {
            "location": "uri",
            "locationName": "clientcertificate_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S32"
      }
    },
    "UpdateDeployment": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/deployments/{deployment_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "deploymentId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "deploymentId": {
            "location": "uri",
            "locationName": "deployment_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "UpdateDocumentationPart": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/documentation/parts/{part_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationPartId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationPartId": {
            "location": "uri",
            "locationName": "part_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "Sv"
      }
    },
    "UpdateDocumentationVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/documentation/versions/{doc_version}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "documentationVersion"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "documentationVersion": {
            "location": "uri",
            "locationName": "doc_version"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "Sx"
      }
    },
    "UpdateDomainName": {
      "http": {
        "method": "PATCH",
        "requestUri": "/domainnames/{domain_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domain_name"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S13"
      }
    },
    "UpdateGatewayResponse": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/gatewayresponses/{response_type}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "responseType"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "responseType": {
            "location": "uri",
            "locationName": "response_type"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S46"
      }
    },
    "UpdateIntegration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1h"
      }
    },
    "UpdateIntegrationResponse": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/integration/responses/{status_code}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1n"
      }
    },
    "UpdateMethod": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "UpdateMethodResponse": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}/methods/{http_method}/responses/{status_code}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId",
          "httpMethod",
          "statusCode"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "httpMethod": {
            "location": "uri",
            "locationName": "http_method"
          },
          "statusCode": {
            "location": "uri",
            "locationName": "status_code"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1f"
      }
    },
    "UpdateModel": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/models/{model_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "modelName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "modelName": {
            "location": "uri",
            "locationName": "model_name"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S16"
      }
    },
    "UpdateRequestValidator": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/requestvalidators/{requestvalidator_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "requestValidatorId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "requestValidatorId": {
            "location": "uri",
            "locationName": "requestvalidator_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S18"
      }
    },
    "UpdateResource": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/resources/{resource_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "resourceId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "resourceId": {
            "location": "uri",
            "locationName": "resource_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1a"
      }
    },
    "UpdateRestApi": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1r"
      }
    },
    "UpdateStage": {
      "http": {
        "method": "PATCH",
        "requestUri": "/restapis/{restapi_id}/stages/{stage_name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "restApiId",
          "stageName"
        ],
        "members": {
          "restApiId": {
            "location": "uri",
            "locationName": "restapi_id"
          },
          "stageName": {
            "location": "uri",
            "locationName": "stage_name"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "UpdateUsage": {
      "http": {
        "method": "PATCH",
        "requestUri": "/usageplans/{usageplanId}/keys/{keyId}/usage"
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId",
          "keyId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "keyId": {
            "location": "uri",
            "locationName": "keyId"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S5c"
      }
    },
    "UpdateUsagePlan": {
      "http": {
        "method": "PATCH",
        "requestUri": "/usageplans/{usageplanId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "usagePlanId"
        ],
        "members": {
          "usagePlanId": {
            "location": "uri",
            "locationName": "usageplanId"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "UpdateVpcLink": {
      "http": {
        "method": "PATCH",
        "requestUri": "/vpclinks/{vpclink_id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "vpcLinkId"
        ],
        "members": {
          "vpcLinkId": {
            "location": "uri",
            "locationName": "vpclink_id"
          },
          "patchOperations": {
            "shape": "S6e"
          }
        }
      },
      "output": {
        "shape": "S2b"
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S7": {
      "type": "structure",
      "members": {
        "id": {},
        "value": {},
        "name": {},
        "customerId": {},
        "description": {},
        "enabled": {
          "type": "boolean"
        },
        "createdDate": {
          "type": "timestamp"
        },
        "lastUpdatedDate": {
          "type": "timestamp"
        },
        "stageKeys": {
          "shape": "S9"
        },
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S9": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "type": {},
        "providerARNs": {
          "shape": "Sc"
        },
        "authType": {},
        "authorizerUri": {},
        "authorizerCredentials": {},
        "identitySource": {},
        "identityValidationExpression": {},
        "authorizerResultTtlInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "basePath": {},
        "restApiId": {},
        "stage": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "createdDate": {
          "type": "timestamp"
        },
        "apiSummary": {
          "type": "map",
          "key": {},
          "value": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "authorizationType": {},
                "apiKeyRequired": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "Ss": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "path": {},
        "method": {},
        "statusCode": {},
        "name": {}
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "id": {},
        "location": {
          "shape": "Ss"
        },
        "properties": {}
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "version": {},
        "createdDate": {
          "type": "timestamp"
        },
        "description": {}
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "types": {
          "type": "list",
          "member": {}
        },
        "vpcEndpointIds": {
          "shape": "S9"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "domainName": {},
        "certificateName": {},
        "certificateArn": {},
        "certificateUploadDate": {
          "type": "timestamp"
        },
        "regionalDomainName": {},
        "regionalHostedZoneId": {},
        "regionalCertificateName": {},
        "regionalCertificateArn": {},
        "distributionDomainName": {},
        "distributionHostedZoneId": {},
        "endpointConfiguration": {
          "shape": "Sz"
        },
        "domainNameStatus": {},
        "domainNameStatusMessage": {},
        "securityPolicy": {},
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "description": {},
        "schema": {},
        "contentType": {}
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "validateRequestBody": {
          "type": "boolean"
        },
        "validateRequestParameters": {
          "type": "boolean"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "id": {},
        "parentId": {},
        "pathPart": {},
        "path": {},
        "resourceMethods": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S1c"
          }
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "httpMethod": {},
        "authorizationType": {},
        "authorizerId": {},
        "apiKeyRequired": {
          "type": "boolean"
        },
        "requestValidatorId": {},
        "operationName": {},
        "requestParameters": {
          "shape": "S1d"
        },
        "requestModels": {
          "shape": "S6"
        },
        "methodResponses": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S1f"
          }
        },
        "methodIntegration": {
          "shape": "S1h"
        },
        "authorizationScopes": {
          "shape": "S9"
        }
      }
    },
    "S1d": {
      "type": "map",
      "key": {},
      "value": {
        "type": "boolean"
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "statusCode": {},
        "responseParameters": {
          "shape": "S1d"
        },
        "responseModels": {
          "shape": "S6"
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "type": {},
        "httpMethod": {},
        "uri": {},
        "connectionType": {},
        "connectionId": {},
        "credentials": {},
        "requestParameters": {
          "shape": "S6"
        },
        "requestTemplates": {
          "shape": "S6"
        },
        "passthroughBehavior": {},
        "contentHandling": {},
        "timeoutInMillis": {
          "type": "integer"
        },
        "cacheNamespace": {},
        "cacheKeyParameters": {
          "shape": "S9"
        },
        "integrationResponses": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S1n"
          }
        },
        "tlsConfig": {
          "shape": "S1o"
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "statusCode": {},
        "selectionPattern": {},
        "responseParameters": {
          "shape": "S6"
        },
        "responseTemplates": {
          "shape": "S6"
        },
        "contentHandling": {}
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "insecureSkipVerification": {
          "type": "boolean"
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "description": {},
        "createdDate": {
          "type": "timestamp"
        },
        "version": {},
        "warnings": {
          "shape": "S9"
        },
        "binaryMediaTypes": {
          "shape": "S9"
        },
        "minimumCompressionSize": {
          "type": "integer"
        },
        "apiKeySource": {},
        "endpointConfiguration": {
          "shape": "Sz"
        },
        "policy": {},
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "percentTraffic": {
          "type": "double"
        },
        "deploymentId": {},
        "stageVariableOverrides": {
          "shape": "S6"
        },
        "useStageCache": {
          "type": "boolean"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "deploymentId": {},
        "clientCertificateId": {},
        "stageName": {},
        "description": {},
        "cacheClusterEnabled": {
          "type": "boolean"
        },
        "cacheClusterSize": {},
        "cacheClusterStatus": {},
        "methodSettings": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "metricsEnabled": {
                "type": "boolean"
              },
              "loggingLevel": {},
              "dataTraceEnabled": {
                "type": "boolean"
              },
              "throttlingBurstLimit": {
                "type": "integer"
              },
              "throttlingRateLimit": {
                "type": "double"
              },
              "cachingEnabled": {
                "type": "boolean"
              },
              "cacheTtlInSeconds": {
                "type": "integer"
              },
              "cacheDataEncrypted": {
                "type": "boolean"
              },
              "requireAuthorizationForCacheControl": {
                "type": "boolean"
              },
              "unauthorizedCacheControlHeaderStrategy": {}
            }
          }
        },
        "variables": {
          "shape": "S6"
        },
        "documentationVersion": {},
        "accessLogSettings": {
          "type": "structure",
          "members": {
            "format": {},
            "destinationArn": {}
          }
        },
        "canarySettings": {
          "shape": "S1t"
        },
        "tracingEnabled": {
          "type": "boolean"
        },
        "webAclArn": {},
        "tags": {
          "shape": "S6"
        },
        "createdDate": {
          "type": "timestamp"
        },
        "lastUpdatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S21": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "apiId": {},
          "stage": {},
          "throttle": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S24"
            }
          }
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "burstLimit": {
          "type": "integer"
        },
        "rateLimit": {
          "type": "double"
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "limit": {
          "type": "integer"
        },
        "offset": {
          "type": "integer"
        },
        "period": {}
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "description": {},
        "apiStages": {
          "shape": "S21"
        },
        "throttle": {
          "shape": "S24"
        },
        "quota": {
          "shape": "S25"
        },
        "productCode": {},
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "id": {},
        "type": {},
        "value": {},
        "name": {}
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "description": {},
        "targetArns": {
          "shape": "S9"
        },
        "status": {},
        "statusMessage": {},
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "clientCertificateId": {},
        "description": {},
        "pemEncodedCertificate": {},
        "createdDate": {
          "type": "timestamp"
        },
        "expirationDate": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S6"
        }
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "cloudwatchRoleArn": {},
        "throttleSettings": {
          "shape": "S24"
        },
        "features": {
          "shape": "S9"
        },
        "apiKeyVersion": {}
      }
    },
    "S46": {
      "type": "structure",
      "members": {
        "responseType": {},
        "statusCode": {},
        "responseParameters": {
          "shape": "S6"
        },
        "responseTemplates": {
          "shape": "S6"
        },
        "defaultResponse": {
          "type": "boolean"
        }
      }
    },
    "S4z": {
      "type": "structure",
      "members": {
        "id": {},
        "friendlyName": {},
        "description": {},
        "configurationProperties": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "friendlyName": {},
              "description": {},
              "required": {
                "type": "boolean"
              },
              "defaultValue": {}
            }
          }
        }
      }
    },
    "S5c": {
      "type": "structure",
      "members": {
        "usagePlanId": {},
        "startDate": {},
        "endDate": {},
        "position": {},
        "items": {
          "locationName": "values",
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S68": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S9"
      }
    },
    "S6e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "op": {},
          "path": {},
          "value": {},
          "from": {}
        }
      }
    }
  }
}
},{}],5:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetApiKeys": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetBasePathMappings": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetClientCertificates": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetDeployments": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetDomainNames": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetModels": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetResources": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetRestApis": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsage": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsagePlanKeys": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetUsagePlans": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    },
    "GetVpcLinks": {
      "input_token": "position",
      "limit_key": "limit",
      "output_token": "position",
      "result_key": "items"
    }
  }
}
},{}],6:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-02-06",
    "endpointPrefix": "application-autoscaling",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Application Auto Scaling",
    "serviceId": "Application Auto Scaling",
    "signatureVersion": "v4",
    "signingName": "application-autoscaling",
    "targetPrefix": "AnyScaleFrontendService",
    "uid": "application-autoscaling-2016-02-06"
  },
  "operations": {
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ScheduledActionName",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ScheduledActionName": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeScalableTargets": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceIds": {
            "shape": "Sb"
          },
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalableTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "MinCapacity",
                "MaxCapacity",
                "RoleARN",
                "CreationTime"
              ],
              "members": {
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "MinCapacity": {
                  "type": "integer"
                },
                "MaxCapacity": {
                  "type": "integer"
                },
                "RoleARN": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "SuspendedState": {
                  "shape": "Sj"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingActivities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ActivityId",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "Description",
                "Cause",
                "StartTime",
                "StatusCode"
              ],
              "members": {
                "ActivityId": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "Description": {},
                "Cause": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "StatusCode": {},
                "StatusMessage": {},
                "Details": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "PolicyNames": {
            "shape": "Sb"
          },
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PolicyARN",
                "PolicyName",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "PolicyType",
                "CreationTime"
              ],
              "members": {
                "PolicyARN": {},
                "PolicyName": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "PolicyType": {},
                "StepScalingPolicyConfiguration": {
                  "shape": "Sx"
                },
                "TargetTrackingScalingPolicyConfiguration": {
                  "shape": "S16"
                },
                "Alarms": {
                  "shape": "S1k"
                },
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ScheduledActionNames": {
            "shape": "Sb"
          },
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ScheduledActionName",
                "ScheduledActionARN",
                "ServiceNamespace",
                "Schedule",
                "ResourceId",
                "CreationTime"
              ],
              "members": {
                "ScheduledActionName": {},
                "ScheduledActionARN": {},
                "ServiceNamespace": {},
                "Schedule": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "ScalableTargetAction": {
                  "shape": "S1r"
                },
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "PolicyType": {},
          "StepScalingPolicyConfiguration": {
            "shape": "Sx"
          },
          "TargetTrackingScalingPolicyConfiguration": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "PolicyARN"
        ],
        "members": {
          "PolicyARN": {},
          "Alarms": {
            "shape": "S1k"
          }
        }
      }
    },
    "PutScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ScheduledActionName",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "Schedule": {},
          "ScheduledActionName": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ScalableTargetAction": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MinCapacity": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "integer"
          },
          "RoleARN": {},
          "SuspendedState": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "structure",
      "members": {
        "DynamicScalingInSuspended": {
          "type": "boolean"
        },
        "DynamicScalingOutSuspended": {
          "type": "boolean"
        },
        "ScheduledScalingSuspended": {
          "type": "boolean"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "AdjustmentType": {},
        "StepAdjustments": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ScalingAdjustment"
            ],
            "members": {
              "MetricIntervalLowerBound": {
                "type": "double"
              },
              "MetricIntervalUpperBound": {
                "type": "double"
              },
              "ScalingAdjustment": {
                "type": "integer"
              }
            }
          }
        },
        "MinAdjustmentMagnitude": {
          "type": "integer"
        },
        "Cooldown": {
          "type": "integer"
        },
        "MetricAggregationType": {}
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "TargetValue": {
          "type": "double"
        },
        "PredefinedMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "CustomizedMetricSpecification": {
          "type": "structure",
          "required": [
            "MetricName",
            "Namespace",
            "Statistic"
          ],
          "members": {
            "MetricName": {},
            "Namespace": {},
            "Dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {},
                  "Value": {}
                }
              }
            },
            "Statistic": {},
            "Unit": {}
          }
        },
        "ScaleOutCooldown": {
          "type": "integer"
        },
        "ScaleInCooldown": {
          "type": "integer"
        },
        "DisableScaleIn": {
          "type": "boolean"
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AlarmName",
          "AlarmARN"
        ],
        "members": {
          "AlarmName": {},
          "AlarmARN": {}
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        }
      }
    }
  }
}
},{}],7:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeScalableTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalableTargets"
    },
    "DescribeScalingActivities": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalingActivities"
    },
    "DescribeScalingPolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalingPolicies"
    },
    "DescribeScheduledActions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledActions"
    }
  }
}
},{}],8:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2011-01-01",
    "endpointPrefix": "autoscaling",
    "protocol": "query",
    "serviceFullName": "Auto Scaling",
    "serviceId": "Auto Scaling",
    "signatureVersion": "v4",
    "uid": "autoscaling-2011-01-01",
    "xmlNamespace": "http://autoscaling.amazonaws.com/doc/2011-01-01/"
  },
  "operations": {
    "AttachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      }
    },
    "AttachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "AttachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "BatchDeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionNames": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "resultWrapper": "BatchDeleteScheduledActionResult",
        "type": "structure",
        "members": {
          "FailedScheduledActions": {
            "shape": "Sg"
          }
        }
      }
    },
    "BatchPutScheduledUpdateGroupAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledUpdateGroupActions"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledUpdateGroupActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ScheduledActionName"
              ],
              "members": {
                "ScheduledActionName": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Recurrence": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "BatchPutScheduledUpdateGroupActionResult",
        "type": "structure",
        "members": {
          "FailedScheduledUpdateGroupActions": {
            "shape": "Sg"
          }
        }
      }
    },
    "CancelInstanceRefresh": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "CancelInstanceRefreshResult",
        "type": "structure",
        "members": {
          "InstanceRefreshId": {}
        }
      }
    },
    "CompleteLifecycleAction": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName",
          "LifecycleActionResult"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "LifecycleActionResult": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "CompleteLifecycleActionResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "MinSize",
          "MaxSize"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "LaunchTemplate": {
            "shape": "S10"
          },
          "MixedInstancesPolicy": {
            "shape": "S12"
          },
          "InstanceId": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "S1d"
          },
          "LoadBalancerNames": {
            "shape": "Sa"
          },
          "TargetGroupARNs": {
            "shape": "S6"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "S1g"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          },
          "LifecycleHookSpecificationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LifecycleHookName",
                "LifecycleTransition"
              ],
              "members": {
                "LifecycleHookName": {},
                "LifecycleTransition": {},
                "NotificationMetadata": {},
                "HeartbeatTimeout": {
                  "type": "integer"
                },
                "DefaultResult": {},
                "NotificationTargetARN": {},
                "RoleARN": {}
              }
            }
          },
          "Tags": {
            "shape": "S1p"
          },
          "ServiceLinkedRoleARN": {},
          "MaxInstanceLifetime": {
            "type": "integer"
          }
        }
      }
    },
    "CreateLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {},
          "ImageId": {},
          "KeyName": {},
          "SecurityGroups": {
            "shape": "S1w"
          },
          "ClassicLinkVPCId": {},
          "ClassicLinkVPCSecurityGroups": {
            "shape": "S1x"
          },
          "UserData": {},
          "InstanceId": {},
          "InstanceType": {},
          "KernelId": {},
          "RamdiskId": {},
          "BlockDeviceMappings": {
            "shape": "S1z"
          },
          "InstanceMonitoring": {
            "shape": "S28"
          },
          "SpotPrice": {},
          "IamInstanceProfile": {},
          "EbsOptimized": {
            "type": "boolean"
          },
          "AssociatePublicIpAddress": {
            "type": "boolean"
          },
          "PlacementTenancy": {}
        }
      }
    },
    "CreateOrUpdateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S1p"
          }
        }
      }
    },
    "DeleteAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {}
        }
      }
    },
    "DeleteLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {}
        }
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S1p"
          }
        }
      }
    },
    "DescribeAccountLimits": {
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "MaxNumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "MaxNumberOfLaunchConfigurations": {
            "type": "integer"
          },
          "NumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "NumberOfLaunchConfigurations": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeAdjustmentTypes": {
      "output": {
        "resultWrapper": "DescribeAdjustmentTypesResult",
        "type": "structure",
        "members": {
          "AdjustmentTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AdjustmentType": {}
              }
            }
          }
        }
      }
    },
    "DescribeAutoScalingGroups": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S2w"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingGroupsResult",
        "type": "structure",
        "required": [
          "AutoScalingGroups"
        ],
        "members": {
          "AutoScalingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AutoScalingGroupName",
                "MinSize",
                "MaxSize",
                "DesiredCapacity",
                "DefaultCooldown",
                "AvailabilityZones",
                "HealthCheckType",
                "CreatedTime"
              ],
              "members": {
                "AutoScalingGroupName": {},
                "AutoScalingGroupARN": {},
                "LaunchConfigurationName": {},
                "LaunchTemplate": {
                  "shape": "S10"
                },
                "MixedInstancesPolicy": {
                  "shape": "S12"
                },
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                },
                "DefaultCooldown": {
                  "type": "integer"
                },
                "AvailabilityZones": {
                  "shape": "S1d"
                },
                "LoadBalancerNames": {
                  "shape": "Sa"
                },
                "TargetGroupARNs": {
                  "shape": "S6"
                },
                "HealthCheckType": {},
                "HealthCheckGracePeriod": {
                  "type": "integer"
                },
                "Instances": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "InstanceId",
                      "AvailabilityZone",
                      "LifecycleState",
                      "HealthStatus",
                      "ProtectedFromScaleIn"
                    ],
                    "members": {
                      "InstanceId": {},
                      "InstanceType": {},
                      "AvailabilityZone": {},
                      "LifecycleState": {},
                      "HealthStatus": {},
                      "LaunchConfigurationName": {},
                      "LaunchTemplate": {
                        "shape": "S10"
                      },
                      "ProtectedFromScaleIn": {
                        "type": "boolean"
                      },
                      "WeightedCapacity": {}
                    }
                  }
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "SuspendedProcesses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ProcessName": {},
                      "SuspensionReason": {}
                    }
                  }
                },
                "PlacementGroup": {},
                "VPCZoneIdentifier": {},
                "EnabledMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {},
                      "Granularity": {}
                    }
                  }
                },
                "Status": {},
                "Tags": {
                  "shape": "S38"
                },
                "TerminationPolicies": {
                  "shape": "S1g"
                },
                "NewInstancesProtectedFromScaleIn": {
                  "type": "boolean"
                },
                "ServiceLinkedRoleARN": {},
                "MaxInstanceLifetime": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingInstances": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingInstancesResult",
        "type": "structure",
        "members": {
          "AutoScalingInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceId",
                "AutoScalingGroupName",
                "AvailabilityZone",
                "LifecycleState",
                "HealthStatus",
                "ProtectedFromScaleIn"
              ],
              "members": {
                "InstanceId": {},
                "InstanceType": {},
                "AutoScalingGroupName": {},
                "AvailabilityZone": {},
                "LifecycleState": {},
                "HealthStatus": {},
                "LaunchConfigurationName": {},
                "LaunchTemplate": {
                  "shape": "S10"
                },
                "ProtectedFromScaleIn": {
                  "type": "boolean"
                },
                "WeightedCapacity": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingNotificationTypes": {
      "output": {
        "resultWrapper": "DescribeAutoScalingNotificationTypesResult",
        "type": "structure",
        "members": {
          "AutoScalingNotificationTypes": {
            "shape": "S3f"
          }
        }
      }
    },
    "DescribeInstanceRefreshes": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "InstanceRefreshIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeInstanceRefreshesResult",
        "type": "structure",
        "members": {
          "InstanceRefreshes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceRefreshId": {},
                "AutoScalingGroupName": {},
                "Status": {},
                "StatusReason": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "PercentageComplete": {
                  "type": "integer"
                },
                "InstancesToUpdate": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLaunchConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "LaunchConfigurationNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLaunchConfigurationsResult",
        "type": "structure",
        "required": [
          "LaunchConfigurations"
        ],
        "members": {
          "LaunchConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LaunchConfigurationName",
                "ImageId",
                "InstanceType",
                "CreatedTime"
              ],
              "members": {
                "LaunchConfigurationName": {},
                "LaunchConfigurationARN": {},
                "ImageId": {},
                "KeyName": {},
                "SecurityGroups": {
                  "shape": "S1w"
                },
                "ClassicLinkVPCId": {},
                "ClassicLinkVPCSecurityGroups": {
                  "shape": "S1x"
                },
                "UserData": {},
                "InstanceType": {},
                "KernelId": {},
                "RamdiskId": {},
                "BlockDeviceMappings": {
                  "shape": "S1z"
                },
                "InstanceMonitoring": {
                  "shape": "S28"
                },
                "SpotPrice": {},
                "IamInstanceProfile": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "AssociatePublicIpAddress": {
                  "type": "boolean"
                },
                "PlacementTenancy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLifecycleHookTypes": {
      "output": {
        "resultWrapper": "DescribeLifecycleHookTypesResult",
        "type": "structure",
        "members": {
          "LifecycleHookTypes": {
            "shape": "S3f"
          }
        }
      }
    },
    "DescribeLifecycleHooks": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LifecycleHookNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLifecycleHooksResult",
        "type": "structure",
        "members": {
          "LifecycleHooks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LifecycleHookName": {},
                "AutoScalingGroupName": {},
                "LifecycleTransition": {},
                "NotificationTargetARN": {},
                "RoleARN": {},
                "NotificationMetadata": {},
                "HeartbeatTimeout": {
                  "type": "integer"
                },
                "GlobalTimeout": {
                  "type": "integer"
                },
                "DefaultResult": {}
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {
          "LoadBalancerTargetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerTargetGroupARN": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancersResult",
        "type": "structure",
        "members": {
          "LoadBalancers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMetricCollectionTypes": {
      "output": {
        "resultWrapper": "DescribeMetricCollectionTypesResult",
        "type": "structure",
        "members": {
          "Metrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Metric": {}
              }
            }
          },
          "Granularities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Granularity": {}
              }
            }
          }
        }
      }
    },
    "DescribeNotificationConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S2w"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeNotificationConfigurationsResult",
        "type": "structure",
        "required": [
          "NotificationConfigurations"
        ],
        "members": {
          "NotificationConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "TopicARN": {},
                "NotificationType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "PolicyTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribePoliciesResult",
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "PolicyName": {},
                "PolicyARN": {},
                "PolicyType": {},
                "AdjustmentType": {},
                "MinAdjustmentStep": {
                  "shape": "S4n"
                },
                "MinAdjustmentMagnitude": {
                  "type": "integer"
                },
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "Cooldown": {
                  "type": "integer"
                },
                "StepAdjustments": {
                  "shape": "S4q"
                },
                "MetricAggregationType": {},
                "EstimatedInstanceWarmup": {
                  "type": "integer"
                },
                "Alarms": {
                  "shape": "S4u"
                },
                "TargetTrackingConfiguration": {
                  "shape": "S4w"
                },
                "Enabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "members": {
          "ActivityIds": {
            "type": "list",
            "member": {}
          },
          "AutoScalingGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeScalingActivitiesResult",
        "type": "structure",
        "required": [
          "Activities"
        ],
        "members": {
          "Activities": {
            "shape": "S5d"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingProcessTypes": {
      "output": {
        "resultWrapper": "DescribeScalingProcessTypesResult",
        "type": "structure",
        "members": {
          "Processes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProcessName"
              ],
              "members": {
                "ProcessName": {}
              }
            }
          }
        }
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionNames": {
            "shape": "Se"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeScheduledActionsResult",
        "type": "structure",
        "members": {
          "ScheduledUpdateGroupActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "ScheduledActionName": {},
                "ScheduledActionARN": {},
                "Time": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Recurrence": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S38"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTerminationPolicyTypes": {
      "output": {
        "resultWrapper": "DescribeTerminationPolicyTypesResult",
        "type": "structure",
        "members": {
          "TerminationPolicyTypes": {
            "shape": "S1g"
          }
        }
      }
    },
    "DetachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachInstancesResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S5d"
          }
        }
      }
    },
    "DetachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DetachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "DisableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S62"
          }
        }
      }
    },
    "EnableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "Granularity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S62"
          },
          "Granularity": {}
        }
      }
    },
    "EnterStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "EnterStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S5d"
          }
        }
      }
    },
    "ExecutePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "HonorCooldown": {
            "type": "boolean"
          },
          "MetricValue": {
            "type": "double"
          },
          "BreachThreshold": {
            "type": "double"
          }
        }
      }
    },
    "ExitStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "ExitStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S5d"
          }
        }
      }
    },
    "PutLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleTransition": {},
          "RoleARN": {},
          "NotificationTargetARN": {},
          "NotificationMetadata": {},
          "HeartbeatTimeout": {
            "type": "integer"
          },
          "DefaultResult": {}
        }
      },
      "output": {
        "resultWrapper": "PutLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN",
          "NotificationTypes"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {},
          "NotificationTypes": {
            "shape": "S3f"
          }
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "PolicyType": {},
          "AdjustmentType": {},
          "MinAdjustmentStep": {
            "shape": "S4n"
          },
          "MinAdjustmentMagnitude": {
            "type": "integer"
          },
          "ScalingAdjustment": {
            "type": "integer"
          },
          "Cooldown": {
            "type": "integer"
          },
          "MetricAggregationType": {},
          "StepAdjustments": {
            "shape": "S4q"
          },
          "EstimatedInstanceWarmup": {
            "type": "integer"
          },
          "TargetTrackingConfiguration": {
            "shape": "S4w"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "PutScalingPolicyResult",
        "type": "structure",
        "members": {
          "PolicyARN": {},
          "Alarms": {
            "shape": "S4u"
          }
        }
      }
    },
    "PutScheduledUpdateGroupAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {},
          "Time": {
            "type": "timestamp"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Recurrence": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          }
        }
      }
    },
    "RecordLifecycleActionHeartbeat": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "RecordLifecycleActionHeartbeatResult",
        "type": "structure",
        "members": {}
      }
    },
    "ResumeProcesses": {
      "input": {
        "shape": "S6i"
      }
    },
    "SetDesiredCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "DesiredCapacity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "DesiredCapacity": {
            "type": "integer"
          },
          "HonorCooldown": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceHealth": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HealthStatus"
        ],
        "members": {
          "InstanceId": {},
          "HealthStatus": {},
          "ShouldRespectGracePeriod": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceProtection": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds",
          "AutoScalingGroupName",
          "ProtectedFromScaleIn"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ProtectedFromScaleIn": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetInstanceProtectionResult",
        "type": "structure",
        "members": {}
      }
    },
    "StartInstanceRefresh": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Strategy": {},
          "Preferences": {
            "type": "structure",
            "members": {
              "MinHealthyPercentage": {
                "type": "integer"
              },
              "InstanceWarmup": {
                "type": "integer"
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "StartInstanceRefreshResult",
        "type": "structure",
        "members": {
          "InstanceRefreshId": {}
        }
      }
    },
    "SuspendProcesses": {
      "input": {
        "shape": "S6i"
      }
    },
    "TerminateInstanceInAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceId": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "TerminateInstanceInAutoScalingGroupResult",
        "type": "structure",
        "members": {
          "Activity": {
            "shape": "S5e"
          }
        }
      }
    },
    "UpdateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "LaunchTemplate": {
            "shape": "S10"
          },
          "MixedInstancesPolicy": {
            "shape": "S12"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "S1d"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "S1g"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          },
          "ServiceLinkedRoleARN": {},
          "MaxInstanceLifetime": {
            "type": "integer"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScheduledActionName"
        ],
        "members": {
          "ScheduledActionName": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {},
        "LaunchTemplateName": {},
        "Version": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "LaunchTemplate": {
          "type": "structure",
          "members": {
            "LaunchTemplateSpecification": {
              "shape": "S10"
            },
            "Overrides": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "InstanceType": {},
                  "WeightedCapacity": {}
                }
              }
            }
          }
        },
        "InstancesDistribution": {
          "type": "structure",
          "members": {
            "OnDemandAllocationStrategy": {},
            "OnDemandBaseCapacity": {
              "type": "integer"
            },
            "OnDemandPercentageAboveBaseCapacity": {
              "type": "integer"
            },
            "SpotAllocationStrategy": {},
            "SpotInstancePools": {
              "type": "integer"
            },
            "SpotMaxPrice": {}
          }
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {}
    },
    "S1x": {
      "type": "list",
      "member": {}
    },
    "S1z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DeviceName"
        ],
        "members": {
          "VirtualName": {},
          "DeviceName": {},
          "Ebs": {
            "type": "structure",
            "members": {
              "SnapshotId": {},
              "VolumeSize": {
                "type": "integer"
              },
              "VolumeType": {},
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Iops": {
                "type": "integer"
              },
              "Encrypted": {
                "type": "boolean"
              }
            }
          },
          "NoDevice": {
            "type": "boolean"
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S2w": {
      "type": "list",
      "member": {}
    },
    "S38": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S3f": {
      "type": "list",
      "member": {}
    },
    "S4n": {
      "type": "integer",
      "deprecated": true
    },
    "S4q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScalingAdjustment"
        ],
        "members": {
          "MetricIntervalLowerBound": {
            "type": "double"
          },
          "MetricIntervalUpperBound": {
            "type": "double"
          },
          "ScalingAdjustment": {
            "type": "integer"
          }
        }
      }
    },
    "S4u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AlarmName": {},
          "AlarmARN": {}
        }
      }
    },
    "S4w": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "PredefinedMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "CustomizedMetricSpecification": {
          "type": "structure",
          "required": [
            "MetricName",
            "Namespace",
            "Statistic"
          ],
          "members": {
            "MetricName": {},
            "Namespace": {},
            "Dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {},
                  "Value": {}
                }
              }
            },
            "Statistic": {},
            "Unit": {}
          }
        },
        "TargetValue": {
          "type": "double"
        },
        "DisableScaleIn": {
          "type": "boolean"
        }
      }
    },
    "S5d": {
      "type": "list",
      "member": {
        "shape": "S5e"
      }
    },
    "S5e": {
      "type": "structure",
      "required": [
        "ActivityId",
        "AutoScalingGroupName",
        "Cause",
        "StartTime",
        "StatusCode"
      ],
      "members": {
        "ActivityId": {},
        "AutoScalingGroupName": {},
        "Description": {},
        "Cause": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "StatusCode": {},
        "StatusMessage": {},
        "Progress": {
          "type": "integer"
        },
        "Details": {}
      }
    },
    "S62": {
      "type": "list",
      "member": {}
    },
    "S6i": {
      "type": "structure",
      "required": [
        "AutoScalingGroupName"
      ],
      "members": {
        "AutoScalingGroupName": {},
        "ScalingProcesses": {
          "type": "list",
          "member": {}
        }
      }
    }
  }
}
},{}],9:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeAutoScalingGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "AutoScalingGroups"
    },
    "DescribeAutoScalingInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "AutoScalingInstances"
    },
    "DescribeLaunchConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "LaunchConfigurations"
    },
    "DescribeNotificationConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "NotificationConfigurations"
    },
    "DescribePolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "ScalingPolicies"
    },
    "DescribeScalingActivities": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Activities"
    },
    "DescribeScheduledActions": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "ScheduledUpdateGroupActions"
    },
    "DescribeTags": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Tags"
    }
  }
}
},{}],10:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-25",
    "endpointPrefix": "ce",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Cost Explorer",
    "serviceFullName": "AWS Cost Explorer Service",
    "serviceId": "Cost Explorer",
    "signatureVersion": "v4",
    "signingName": "ce",
    "targetPrefix": "AWSInsightsIndexService",
    "uid": "ce-2017-10-25"
  },
  "operations": {
    "CreateCostCategoryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RuleVersion",
          "Rules"
        ],
        "members": {
          "Name": {},
          "RuleVersion": {},
          "Rules": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CostCategoryArn": {},
          "EffectiveStart": {}
        }
      }
    },
    "DeleteCostCategoryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "CostCategoryArn"
        ],
        "members": {
          "CostCategoryArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CostCategoryArn": {},
          "EffectiveEnd": {}
        }
      }
    },
    "DescribeCostCategoryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "CostCategoryArn"
        ],
        "members": {
          "CostCategoryArn": {},
          "EffectiveOn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CostCategory": {
            "type": "structure",
            "required": [
              "CostCategoryArn",
              "EffectiveStart",
              "Name",
              "RuleVersion",
              "Rules"
            ],
            "members": {
              "CostCategoryArn": {},
              "EffectiveStart": {},
              "EffectiveEnd": {},
              "Name": {},
              "RuleVersion": {},
              "Rules": {
                "shape": "S4"
              }
            }
          }
        }
      }
    },
    "GetCostAndUsage": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "Metrics": {
            "shape": "Su"
          },
          "GroupBy": {
            "shape": "Sw"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextPageToken": {},
          "GroupDefinitions": {
            "shape": "Sw"
          },
          "ResultsByTime": {
            "shape": "S12"
          }
        }
      }
    },
    "GetCostAndUsageWithResources": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "Metrics": {
            "shape": "Su"
          },
          "GroupBy": {
            "shape": "Sw"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextPageToken": {},
          "GroupDefinitions": {
            "shape": "Sw"
          },
          "ResultsByTime": {
            "shape": "S12"
          }
        }
      }
    },
    "GetCostForecast": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Metric",
          "Granularity"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Metric": {},
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "PredictionIntervalLevel": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Total": {
            "shape": "S15"
          },
          "ForecastResultsByTime": {
            "shape": "S1j"
          }
        }
      }
    },
    "GetDimensionValues": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Dimension"
        ],
        "members": {
          "SearchString": {},
          "TimePeriod": {
            "shape": "Sr"
          },
          "Dimension": {},
          "Context": {},
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DimensionValues",
          "ReturnSize",
          "TotalSize"
        ],
        "members": {
          "DimensionValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {},
                "Attributes": {
                  "shape": "S1s"
                }
              }
            }
          },
          "ReturnSize": {
            "type": "integer"
          },
          "TotalSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationCoverage": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "GroupBy": {
            "shape": "Sw"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "Metrics": {
            "shape": "Su"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CoveragesByTime"
        ],
        "members": {
          "CoveragesByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TimePeriod": {
                  "shape": "Sr"
                },
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Attributes": {
                        "shape": "S1s"
                      },
                      "Coverage": {
                        "shape": "S22"
                      }
                    }
                  }
                },
                "Total": {
                  "shape": "S22"
                }
              }
            }
          },
          "Total": {
            "shape": "S22"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationPurchaseRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "AccountId": {},
          "Service": {},
          "AccountScope": {},
          "LookbackPeriodInDays": {},
          "TermInYears": {},
          "PaymentOption": {},
          "ServiceSpecification": {
            "shape": "S2k"
          },
          "PageSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "type": "structure",
            "members": {
              "RecommendationId": {},
              "GenerationTimestamp": {}
            }
          },
          "Recommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountScope": {},
                "LookbackPeriodInDays": {},
                "TermInYears": {},
                "PaymentOption": {},
                "ServiceSpecification": {
                  "shape": "S2k"
                },
                "RecommendationDetails": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AccountId": {},
                      "InstanceDetails": {
                        "type": "structure",
                        "members": {
                          "EC2InstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "InstanceType": {},
                              "Region": {},
                              "AvailabilityZone": {},
                              "Platform": {},
                              "Tenancy": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "RDSInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "InstanceType": {},
                              "Region": {},
                              "DatabaseEngine": {},
                              "DatabaseEdition": {},
                              "DeploymentOption": {},
                              "LicenseModel": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "RedshiftInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "NodeType": {},
                              "Region": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "ElastiCacheInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "NodeType": {},
                              "Region": {},
                              "ProductDescription": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "ESInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "InstanceClass": {},
                              "InstanceSize": {},
                              "Region": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          }
                        }
                      },
                      "RecommendedNumberOfInstancesToPurchase": {},
                      "RecommendedNormalizedUnitsToPurchase": {},
                      "MinimumNumberOfInstancesUsedPerHour": {},
                      "MinimumNormalizedUnitsUsedPerHour": {},
                      "MaximumNumberOfInstancesUsedPerHour": {},
                      "MaximumNormalizedUnitsUsedPerHour": {},
                      "AverageNumberOfInstancesUsedPerHour": {},
                      "AverageNormalizedUnitsUsedPerHour": {},
                      "AverageUtilization": {},
                      "EstimatedBreakEvenInMonths": {},
                      "CurrencyCode": {},
                      "EstimatedMonthlySavingsAmount": {},
                      "EstimatedMonthlySavingsPercentage": {},
                      "EstimatedMonthlyOnDemandCost": {},
                      "EstimatedReservationCostForLookbackPeriod": {},
                      "UpfrontCost": {},
                      "RecurringStandardMonthlyCost": {}
                    }
                  }
                },
                "RecommendationSummary": {
                  "type": "structure",
                  "members": {
                    "TotalEstimatedMonthlySavingsAmount": {},
                    "TotalEstimatedMonthlySavingsPercentage": {},
                    "CurrencyCode": {}
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationUtilization": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "GroupBy": {
            "shape": "Sw"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UtilizationsByTime"
        ],
        "members": {
          "UtilizationsByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TimePeriod": {
                  "shape": "Sr"
                },
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Key": {},
                      "Value": {},
                      "Attributes": {
                        "shape": "S1s"
                      },
                      "Utilization": {
                        "shape": "S3a"
                      }
                    }
                  }
                },
                "Total": {
                  "shape": "S3a"
                }
              }
            }
          },
          "Total": {
            "shape": "S3a"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetRightsizingRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "Filter": {
            "shape": "S7"
          },
          "Configuration": {
            "shape": "S3q"
          },
          "Service": {},
          "PageSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "type": "structure",
            "members": {
              "RecommendationId": {},
              "GenerationTimestamp": {},
              "LookbackPeriodInDays": {}
            }
          },
          "Summary": {
            "type": "structure",
            "members": {
              "TotalRecommendationCount": {},
              "EstimatedTotalMonthlySavingsAmount": {},
              "SavingsCurrencyCode": {},
              "SavingsPercentage": {}
            }
          },
          "RightsizingRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "CurrentInstance": {
                  "type": "structure",
                  "members": {
                    "ResourceId": {},
                    "Tags": {
                      "type": "list",
                      "member": {
                        "shape": "Sf"
                      }
                    },
                    "ResourceDetails": {
                      "shape": "S3z"
                    },
                    "ResourceUtilization": {
                      "shape": "S41"
                    },
                    "ReservationCoveredHoursInLookbackPeriod": {},
                    "SavingsPlansCoveredHoursInLookbackPeriod": {},
                    "OnDemandHoursInLookbackPeriod": {},
                    "TotalRunningHoursInLookbackPeriod": {},
                    "MonthlyCost": {},
                    "CurrencyCode": {}
                  }
                },
                "RightsizingType": {},
                "ModifyRecommendationDetail": {
                  "type": "structure",
                  "members": {
                    "TargetInstances": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "EstimatedMonthlyCost": {},
                          "EstimatedMonthlySavings": {},
                          "CurrencyCode": {},
                          "DefaultTargetInstance": {
                            "type": "boolean"
                          },
                          "ResourceDetails": {
                            "shape": "S3z"
                          },
                          "ExpectedResourceUtilization": {
                            "shape": "S41"
                          }
                        }
                      }
                    }
                  }
                },
                "TerminateRecommendationDetail": {
                  "type": "structure",
                  "members": {
                    "EstimatedMonthlySavings": {},
                    "CurrencyCode": {}
                  }
                }
              }
            }
          },
          "NextPageToken": {},
          "Configuration": {
            "shape": "S3q"
          }
        }
      }
    },
    "GetSavingsPlansCoverage": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "GroupBy": {
            "shape": "Sw"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "Metrics": {
            "shape": "Su"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SavingsPlansCoverages"
        ],
        "members": {
          "SavingsPlansCoverages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Attributes": {
                  "shape": "S1s"
                },
                "Coverage": {
                  "type": "structure",
                  "members": {
                    "SpendCoveredBySavingsPlans": {},
                    "OnDemandCost": {},
                    "TotalCost": {},
                    "CoveragePercentage": {}
                  }
                },
                "TimePeriod": {
                  "shape": "Sr"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSavingsPlansPurchaseRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "SavingsPlansType",
          "TermInYears",
          "PaymentOption",
          "LookbackPeriodInDays"
        ],
        "members": {
          "SavingsPlansType": {},
          "TermInYears": {},
          "PaymentOption": {},
          "AccountScope": {},
          "NextPageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "LookbackPeriodInDays": {},
          "Filter": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "type": "structure",
            "members": {
              "RecommendationId": {},
              "GenerationTimestamp": {}
            }
          },
          "SavingsPlansPurchaseRecommendation": {
            "type": "structure",
            "members": {
              "AccountScope": {},
              "SavingsPlansType": {},
              "TermInYears": {},
              "PaymentOption": {},
              "LookbackPeriodInDays": {},
              "SavingsPlansPurchaseRecommendationDetails": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "SavingsPlansDetails": {
                      "type": "structure",
                      "members": {
                        "Region": {},
                        "InstanceFamily": {},
                        "OfferingId": {}
                      }
                    },
                    "AccountId": {},
                    "UpfrontCost": {},
                    "EstimatedROI": {},
                    "CurrencyCode": {},
                    "EstimatedSPCost": {},
                    "EstimatedOnDemandCost": {},
                    "EstimatedOnDemandCostWithCurrentCommitment": {},
                    "EstimatedSavingsAmount": {},
                    "EstimatedSavingsPercentage": {},
                    "HourlyCommitmentToPurchase": {},
                    "EstimatedAverageUtilization": {},
                    "EstimatedMonthlySavingsAmount": {},
                    "CurrentMinimumHourlyOnDemandSpend": {},
                    "CurrentMaximumHourlyOnDemandSpend": {},
                    "CurrentAverageHourlyOnDemandSpend": {}
                  }
                }
              },
              "SavingsPlansPurchaseRecommendationSummary": {
                "type": "structure",
                "members": {
                  "EstimatedROI": {},
                  "CurrencyCode": {},
                  "EstimatedTotalCost": {},
                  "CurrentOnDemandSpend": {},
                  "EstimatedSavingsAmount": {},
                  "TotalRecommendationCount": {},
                  "DailyCommitmentToPurchase": {},
                  "HourlyCommitmentToPurchase": {},
                  "EstimatedSavingsPercentage": {},
                  "EstimatedMonthlySavingsAmount": {},
                  "EstimatedOnDemandCostWithCurrentCommitment": {}
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "GetSavingsPlansUtilization": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Total"
        ],
        "members": {
          "SavingsPlansUtilizationsByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TimePeriod",
                "Utilization"
              ],
              "members": {
                "TimePeriod": {
                  "shape": "Sr"
                },
                "Utilization": {
                  "shape": "S4r"
                },
                "Savings": {
                  "shape": "S4s"
                },
                "AmortizedCommitment": {
                  "shape": "S4t"
                }
              }
            }
          },
          "Total": {
            "shape": "S4u"
          }
        }
      }
    },
    "GetSavingsPlansUtilizationDetails": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Filter": {
            "shape": "S7"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SavingsPlansUtilizationDetails",
          "TimePeriod"
        ],
        "members": {
          "SavingsPlansUtilizationDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SavingsPlanArn": {},
                "Attributes": {
                  "shape": "S1s"
                },
                "Utilization": {
                  "shape": "S4r"
                },
                "Savings": {
                  "shape": "S4s"
                },
                "AmortizedCommitment": {
                  "shape": "S4t"
                }
              }
            }
          },
          "Total": {
            "shape": "S4u"
          },
          "TimePeriod": {
            "shape": "Sr"
          },
          "NextToken": {}
        }
      }
    },
    "GetTags": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "SearchString": {},
          "TimePeriod": {
            "shape": "Sr"
          },
          "TagKey": {},
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "ReturnSize",
          "TotalSize"
        ],
        "members": {
          "NextPageToken": {},
          "Tags": {
            "type": "list",
            "member": {}
          },
          "ReturnSize": {
            "type": "integer"
          },
          "TotalSize": {
            "type": "integer"
          }
        }
      }
    },
    "GetUsageForecast": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Metric",
          "Granularity"
        ],
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Metric": {},
          "Granularity": {},
          "Filter": {
            "shape": "S7"
          },
          "PredictionIntervalLevel": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Total": {
            "shape": "S15"
          },
          "ForecastResultsByTime": {
            "shape": "S1j"
          }
        }
      }
    },
    "ListCostCategoryDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "EffectiveOn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CostCategoryReferences": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CostCategoryArn": {},
                "Name": {},
                "EffectiveStart": {},
                "EffectiveEnd": {},
                "NumberOfRules": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "UpdateCostCategoryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "CostCategoryArn",
          "RuleVersion",
          "Rules"
        ],
        "members": {
          "CostCategoryArn": {},
          "RuleVersion": {},
          "Rules": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CostCategoryArn": {},
          "EffectiveStart": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Value",
          "Rule"
        ],
        "members": {
          "Value": {},
          "Rule": {
            "shape": "S7"
          }
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "Or": {
          "shape": "S8"
        },
        "And": {
          "shape": "S8"
        },
        "Not": {
          "shape": "S7"
        },
        "Dimensions": {
          "type": "structure",
          "members": {
            "Key": {},
            "Values": {
              "shape": "Sb"
            },
            "MatchOptions": {
              "shape": "Sd"
            }
          }
        },
        "Tags": {
          "shape": "Sf"
        },
        "CostCategories": {
          "type": "structure",
          "members": {
            "Key": {},
            "Values": {
              "shape": "Sb"
            }
          }
        }
      }
    },
    "S8": {
      "type": "list",
      "member": {
        "shape": "S7"
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "structure",
      "members": {
        "Key": {},
        "Values": {
          "shape": "Sb"
        },
        "MatchOptions": {
          "shape": "Sd"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "Start",
        "End"
      ],
      "members": {
        "Start": {},
        "End": {}
      }
    },
    "Su": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Key": {}
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "Total": {
            "shape": "S14"
          },
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Keys": {
                  "type": "list",
                  "member": {}
                },
                "Metrics": {
                  "shape": "S14"
                }
              }
            }
          },
          "Estimated": {
            "type": "boolean"
          }
        }
      }
    },
    "S14": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S15"
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "Amount": {},
        "Unit": {}
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TimePeriod": {
            "shape": "Sr"
          },
          "MeanValue": {},
          "PredictionIntervalLowerBound": {},
          "PredictionIntervalUpperBound": {}
        }
      }
    },
    "S1s": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S22": {
      "type": "structure",
      "members": {
        "CoverageHours": {
          "type": "structure",
          "members": {
            "OnDemandHours": {},
            "ReservedHours": {},
            "TotalRunningHours": {},
            "CoverageHoursPercentage": {}
          }
        },
        "CoverageNormalizedUnits": {
          "type": "structure",
          "members": {
            "OnDemandNormalizedUnits": {},
            "ReservedNormalizedUnits": {},
            "TotalRunningNormalizedUnits": {},
            "CoverageNormalizedUnitsPercentage": {}
          }
        },
        "CoverageCost": {
          "type": "structure",
          "members": {
            "OnDemandCost": {}
          }
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "EC2Specification": {
          "type": "structure",
          "members": {
            "OfferingClass": {}
          }
        }
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "UtilizationPercentage": {},
        "UtilizationPercentageInUnits": {},
        "PurchasedHours": {},
        "PurchasedUnits": {},
        "TotalActualHours": {},
        "TotalActualUnits": {},
        "UnusedHours": {},
        "UnusedUnits": {},
        "OnDemandCostOfRIHoursUsed": {},
        "NetRISavings": {},
        "TotalPotentialRISavings": {},
        "AmortizedUpfrontFee": {},
        "AmortizedRecurringFee": {},
        "TotalAmortizedFee": {}
      }
    },
    "S3q": {
      "type": "structure",
      "required": [
        "RecommendationTarget",
        "BenefitsConsidered"
      ],
      "members": {
        "RecommendationTarget": {},
        "BenefitsConsidered": {
          "type": "boolean"
        }
      }
    },
    "S3z": {
      "type": "structure",
      "members": {
        "EC2ResourceDetails": {
          "type": "structure",
          "members": {
            "HourlyOnDemandRate": {},
            "InstanceType": {},
            "Platform": {},
            "Region": {},
            "Sku": {},
            "Memory": {},
            "NetworkPerformance": {},
            "Storage": {},
            "Vcpu": {}
          }
        }
      }
    },
    "S41": {
      "type": "structure",
      "members": {
        "EC2ResourceUtilization": {
          "type": "structure",
          "members": {
            "MaxCpuUtilizationPercentage": {},
            "MaxMemoryUtilizationPercentage": {},
            "MaxStorageUtilizationPercentage": {}
          }
        }
      }
    },
    "S4r": {
      "type": "structure",
      "members": {
        "TotalCommitment": {},
        "UsedCommitment": {},
        "UnusedCommitment": {},
        "UtilizationPercentage": {}
      }
    },
    "S4s": {
      "type": "structure",
      "members": {
        "NetSavings": {},
        "OnDemandCostEquivalent": {}
      }
    },
    "S4t": {
      "type": "structure",
      "members": {
        "AmortizedRecurringCommitment": {},
        "AmortizedUpfrontCommitment": {},
        "TotalAmortizedCommitment": {}
      }
    },
    "S4u": {
      "type": "structure",
      "required": [
        "Utilization"
      ],
      "members": {
        "Utilization": {
          "shape": "S4r"
        },
        "Savings": {
          "shape": "S4s"
        },
        "AmortizedCommitment": {
          "shape": "S4t"
        }
      }
    }
  }
}
},{}],11:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetSavingsPlansCoverage": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "GetSavingsPlansUtilizationDetails": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListCostCategoryDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],12:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-05-15",
    "endpointPrefix": "cloudformation",
    "protocol": "query",
    "serviceFullName": "AWS CloudFormation",
    "serviceId": "CloudFormation",
    "signatureVersion": "v4",
    "uid": "cloudformation-2010-05-15",
    "xmlNamespace": "http://cloudformation.amazonaws.com/doc/2010-05-15/"
  },
  "operations": {
    "CancelUpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "ClientRequestToken": {}
        }
      }
    },
    "ContinueUpdateRollback": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RoleARN": {},
          "ResourcesToSkip": {
            "type": "list",
            "member": {}
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "ContinueUpdateRollbackResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "ChangeSetName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "NotificationARNs": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sv"
          },
          "ChangeSetName": {},
          "ClientToken": {},
          "Description": {},
          "ChangeSetType": {},
          "ResourcesToImport": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ResourceType",
                "LogicalResourceId",
                "ResourceIdentifier"
              ],
              "members": {
                "ResourceType": {},
                "LogicalResourceId": {},
                "ResourceIdentifier": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "CreateChangeSetResult",
        "type": "structure",
        "members": {
          "Id": {},
          "StackId": {}
        }
      }
    },
    "CreateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "DisableRollback": {
            "type": "boolean"
          },
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "TimeoutInMinutes": {
            "type": "integer"
          },
          "NotificationARNs": {
            "shape": "St"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "OnFailure": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "Tags": {
            "shape": "Sv"
          },
          "ClientRequestToken": {},
          "EnableTerminationProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "CreateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1m"
          },
          "DeploymentTargets": {
            "shape": "S1o"
          },
          "Regions": {
            "shape": "S1r"
          },
          "ParameterOverrides": {
            "shape": "Se"
          },
          "OperationPreferences": {
            "shape": "S1t"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "CreateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "PermissionModel": {},
          "AutoDeployment": {
            "shape": "S22"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackSetResult",
        "type": "structure",
        "members": {
          "StackSetId": {}
        }
      }
    },
    "DeleteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RetainResources": {
            "type": "list",
            "member": {}
          },
          "RoleARN": {},
          "ClientRequestToken": {}
        }
      }
    },
    "DeleteStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions",
          "RetainStacks"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1m"
          },
          "DeploymentTargets": {
            "shape": "S1o"
          },
          "Regions": {
            "shape": "S1r"
          },
          "OperationPreferences": {
            "shape": "S1t"
          },
          "RetainStacks": {
            "type": "boolean"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "DeleteStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteStackSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterType": {
      "input": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "DeregisterTypeResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "AccountLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeChangeSetResult",
        "type": "structure",
        "members": {
          "ChangeSetName": {},
          "ChangeSetId": {},
          "StackId": {},
          "StackName": {},
          "Description": {},
          "Parameters": {
            "shape": "Se"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ExecutionStatus": {},
          "Status": {},
          "StatusReason": {},
          "NotificationARNs": {
            "shape": "St"
          },
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "Changes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ResourceChange": {
                  "type": "structure",
                  "members": {
                    "Action": {},
                    "LogicalResourceId": {},
                    "PhysicalResourceId": {},
                    "ResourceType": {},
                    "Replacement": {},
                    "Scope": {
                      "type": "list",
                      "member": {}
                    },
                    "Details": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Target": {
                            "type": "structure",
                            "members": {
                              "Attribute": {},
                              "Name": {},
                              "RequiresRecreation": {}
                            }
                          },
                          "Evaluation": {},
                          "ChangeSource": {},
                          "CausingEntity": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackDriftDetectionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackDriftDetectionStatusResult",
        "type": "structure",
        "required": [
          "StackId",
          "StackDriftDetectionId",
          "DetectionStatus",
          "Timestamp"
        ],
        "members": {
          "StackId": {},
          "StackDriftDetectionId": {},
          "StackDriftStatus": {},
          "DetectionStatus": {},
          "DetectionStatusReason": {},
          "DriftedStackResourceCount": {
            "type": "integer"
          },
          "Timestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStackEvents": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackEventsResult",
        "type": "structure",
        "members": {
          "StackEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackId",
                "EventId",
                "StackName",
                "Timestamp"
              ],
              "members": {
                "StackId": {},
                "EventId": {},
                "StackName": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "ResourceProperties": {},
                "ClientRequestToken": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "StackInstanceAccount",
          "StackInstanceRegion"
        ],
        "members": {
          "StackSetName": {},
          "StackInstanceAccount": {},
          "StackInstanceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackInstanceResult",
        "type": "structure",
        "members": {
          "StackInstance": {
            "type": "structure",
            "members": {
              "StackSetId": {},
              "Region": {},
              "Account": {},
              "StackId": {},
              "ParameterOverrides": {
                "shape": "Se"
              },
              "Status": {},
              "StatusReason": {},
              "OrganizationalUnitId": {},
              "DriftStatus": {},
              "LastDriftCheckTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeStackResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceResult",
        "type": "structure",
        "members": {
          "StackResourceDetail": {
            "type": "structure",
            "required": [
              "LogicalResourceId",
              "ResourceType",
              "LastUpdatedTimestamp",
              "ResourceStatus"
            ],
            "members": {
              "StackName": {},
              "StackId": {},
              "LogicalResourceId": {},
              "PhysicalResourceId": {},
              "ResourceType": {},
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "ResourceStatus": {},
              "ResourceStatusReason": {},
              "Description": {},
              "Metadata": {},
              "DriftInformation": {
                "shape": "S46"
              }
            }
          }
        }
      }
    },
    "DescribeStackResourceDrifts": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackResourceDriftStatusFilters": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceDriftsResult",
        "type": "structure",
        "required": [
          "StackResourceDrifts"
        ],
        "members": {
          "StackResourceDrifts": {
            "type": "list",
            "member": {
              "shape": "S4d"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackResources": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "PhysicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "Timestamp",
                "ResourceStatus"
              ],
              "members": {
                "StackName": {},
                "StackId": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "Description": {},
                "DriftInformation": {
                  "shape": "S46"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetResult",
        "type": "structure",
        "members": {
          "StackSet": {
            "type": "structure",
            "members": {
              "StackSetName": {},
              "StackSetId": {},
              "Description": {},
              "Status": {},
              "TemplateBody": {},
              "Parameters": {
                "shape": "Se"
              },
              "Capabilities": {
                "shape": "Sj"
              },
              "Tags": {
                "shape": "Sv"
              },
              "StackSetARN": {},
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {},
              "StackSetDriftDetectionDetails": {
                "shape": "S4x"
              },
              "AutoDeployment": {
                "shape": "S22"
              },
              "PermissionModel": {},
              "OrganizationalUnitIds": {
                "shape": "S1p"
              }
            }
          }
        }
      }
    },
    "DescribeStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetOperationResult",
        "type": "structure",
        "members": {
          "StackSetOperation": {
            "type": "structure",
            "members": {
              "OperationId": {},
              "StackSetId": {},
              "Action": {},
              "Status": {},
              "OperationPreferences": {
                "shape": "S1t"
              },
              "RetainStacks": {
                "type": "boolean"
              },
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {},
              "CreationTimestamp": {
                "type": "timestamp"
              },
              "EndTimestamp": {
                "type": "timestamp"
              },
              "DeploymentTargets": {
                "shape": "S1o"
              },
              "StackSetDriftDetectionDetails": {
                "shape": "S4x"
              }
            }
          }
        }
      }
    },
    "DescribeStacks": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStacksResult",
        "type": "structure",
        "members": {
          "Stacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "Description": {},
                "Parameters": {
                  "shape": "Se"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "RollbackConfiguration": {
                  "shape": "Sn"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "DisableRollback": {
                  "type": "boolean"
                },
                "NotificationARNs": {
                  "shape": "St"
                },
                "TimeoutInMinutes": {
                  "type": "integer"
                },
                "Capabilities": {
                  "shape": "Sj"
                },
                "Outputs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "OutputKey": {},
                      "OutputValue": {},
                      "Description": {},
                      "ExportName": {}
                    }
                  }
                },
                "RoleARN": {},
                "Tags": {
                  "shape": "Sv"
                },
                "EnableTerminationProtection": {
                  "type": "boolean"
                },
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeType": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "Arn": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTypeResult",
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "DefaultVersionId": {},
          "IsDefaultVersion": {
            "type": "boolean"
          },
          "Description": {},
          "Schema": {},
          "ProvisioningType": {},
          "DeprecatedStatus": {},
          "LoggingConfig": {
            "shape": "S5w"
          },
          "ExecutionRoleArn": {},
          "Visibility": {},
          "SourceUrl": {},
          "DocumentationUrl": {},
          "LastUpdated": {
            "type": "timestamp"
          },
          "TimeCreated": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeTypeRegistration": {
      "input": {
        "type": "structure",
        "required": [
          "RegistrationToken"
        ],
        "members": {
          "RegistrationToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTypeRegistrationResult",
        "type": "structure",
        "members": {
          "ProgressStatus": {},
          "Description": {},
          "TypeArn": {},
          "TypeVersionArn": {}
        }
      },
      "idempotent": true
    },
    "DetectStackDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceIds": {
            "shape": "S66"
          }
        }
      },
      "output": {
        "resultWrapper": "DetectStackDriftResult",
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      }
    },
    "DetectStackResourceDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DetectStackResourceDriftResult",
        "type": "structure",
        "required": [
          "StackResourceDrift"
        ],
        "members": {
          "StackResourceDrift": {
            "shape": "S4d"
          }
        }
      }
    },
    "DetectStackSetDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "OperationPreferences": {
            "shape": "S1t"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "DetectStackSetDriftResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "EstimateTemplateCost": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "resultWrapper": "EstimateTemplateCostResult",
        "type": "structure",
        "members": {
          "Url": {}
        }
      }
    },
    "ExecuteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "ExecuteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "GetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "GetStackPolicyResult",
        "type": "structure",
        "members": {
          "StackPolicyBody": {}
        }
      }
    },
    "GetTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "ChangeSetName": {},
          "TemplateStage": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateResult",
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "StagesAvailable": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetTemplateSummary": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "StackName": {},
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateSummaryResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Sj"
          },
          "CapabilitiesReason": {},
          "ResourceTypes": {
            "shape": "Sl"
          },
          "Version": {},
          "Metadata": {},
          "DeclaredTransforms": {
            "shape": "S6z"
          },
          "ResourceIdentifierSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "LogicalResourceIds": {
                  "shape": "S66"
                },
                "ResourceIdentifiers": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "ListChangeSets": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListChangeSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "ChangeSetName": {},
                "ExecutionStatus": {},
                "Status": {},
                "StatusReason": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExports": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListExportsResult",
        "type": "structure",
        "members": {
          "Exports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExportingStackId": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImports": {
      "input": {
        "type": "structure",
        "required": [
          "ExportName"
        ],
        "members": {
          "ExportName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListImportsResult",
        "type": "structure",
        "members": {
          "Imports": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StackInstanceAccount": {},
          "StackInstanceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackInstancesResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetId": {},
                "Region": {},
                "Account": {},
                "StackId": {},
                "Status": {},
                "StatusReason": {},
                "OrganizationalUnitId": {},
                "DriftStatus": {},
                "LastDriftCheckTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackResources": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResourceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "LastUpdatedTimestamp",
                "ResourceStatus"
              ],
              "members": {
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "LastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackResourceDriftStatus"
                  ],
                  "members": {
                    "StackResourceDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperationResults": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationResultsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "Region": {},
                "Status": {},
                "StatusReason": {},
                "AccountGateResult": {
                  "type": "structure",
                  "members": {
                    "Status": {},
                    "StatusReason": {}
                  }
                },
                "OrganizationalUnitId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperations": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OperationId": {},
                "Action": {},
                "Status": {},
                "CreationTimestamp": {
                  "type": "timestamp"
                },
                "EndTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetName": {},
                "StackSetId": {},
                "Description": {},
                "Status": {},
                "AutoDeployment": {
                  "shape": "S22"
                },
                "PermissionModel": {},
                "DriftStatus": {},
                "LastDriftCheckTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStacks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StackStatusFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ListStacksResult",
        "type": "structure",
        "members": {
          "StackSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "TemplateDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTypeRegistrations": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "TypeArn": {},
          "RegistrationStatusFilter": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypeRegistrationsResult",
        "type": "structure",
        "members": {
          "RegistrationTokenList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTypeVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Type": {},
          "TypeName": {},
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DeprecatedStatus": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypeVersionsResult",
        "type": "structure",
        "members": {
          "TypeVersionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "TypeName": {},
                "VersionId": {},
                "IsDefaultVersion": {
                  "type": "boolean"
                },
                "Arn": {},
                "TimeCreated": {
                  "type": "timestamp"
                },
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTypes": {
      "input": {
        "type": "structure",
        "members": {
          "Visibility": {},
          "ProvisioningType": {},
          "DeprecatedStatus": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListTypesResult",
        "type": "structure",
        "members": {
          "TypeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "TypeName": {},
                "DefaultVersionId": {},
                "TypeArn": {},
                "LastUpdated": {
                  "type": "timestamp"
                },
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "RecordHandlerProgress": {
      "input": {
        "type": "structure",
        "required": [
          "BearerToken",
          "OperationStatus"
        ],
        "members": {
          "BearerToken": {},
          "OperationStatus": {},
          "CurrentOperationStatus": {},
          "StatusMessage": {},
          "ErrorCode": {},
          "ResourceModel": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "RecordHandlerProgressResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RegisterType": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName",
          "SchemaHandlerPackage"
        ],
        "members": {
          "Type": {},
          "TypeName": {},
          "SchemaHandlerPackage": {},
          "LoggingConfig": {
            "shape": "S5w"
          },
          "ExecutionRoleArn": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "RegisterTypeResult",
        "type": "structure",
        "members": {
          "RegistrationToken": {}
        }
      },
      "idempotent": true
    },
    "SetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {}
        }
      }
    },
    "SetTypeDefaultVersion": {
      "input": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "TypeName": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "SetTypeDefaultVersionResult",
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "SignalResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId",
          "UniqueId",
          "Status"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "UniqueId": {},
          "Status": {}
        }
      }
    },
    "StopStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {}
        }
      },
      "output": {
        "resultWrapper": "StopStackSetOperationResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "StackPolicyDuringUpdateBody": {},
          "StackPolicyDuringUpdateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "NotificationARNs": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sv"
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "UpdateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1m"
          },
          "DeploymentTargets": {
            "shape": "S1o"
          },
          "Regions": {
            "shape": "S1r"
          },
          "ParameterOverrides": {
            "shape": "Se"
          },
          "OperationPreferences": {
            "shape": "S1t"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "OperationPreferences": {
            "shape": "S1t"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "DeploymentTargets": {
            "shape": "S1o"
          },
          "PermissionModel": {},
          "AutoDeployment": {
            "shape": "S22"
          },
          "OperationId": {
            "idempotencyToken": true
          },
          "Accounts": {
            "shape": "S1m"
          },
          "Regions": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackSetResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "EnableTerminationProtection",
          "StackName"
        ],
        "members": {
          "EnableTerminationProtection": {
            "type": "boolean"
          },
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateTerminationProtectionResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "ValidateTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {}
        }
      },
      "output": {
        "resultWrapper": "ValidateTemplateResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {}
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Sj"
          },
          "CapabilitiesReason": {},
          "DeclaredTransforms": {
            "shape": "S6z"
          }
        }
      }
    }
  },
  "shapes": {
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ParameterKey": {},
          "ParameterValue": {},
          "UsePreviousValue": {
            "type": "boolean"
          },
          "ResolvedValue": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "structure",
      "members": {
        "RollbackTriggers": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Type"
            ],
            "members": {
              "Arn": {},
              "Type": {}
            }
          }
        },
        "MonitoringTimeInMinutes": {
          "type": "integer"
        }
      }
    },
    "St": {
      "type": "list",
      "member": {}
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1m": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "structure",
      "members": {
        "Accounts": {
          "shape": "S1m"
        },
        "OrganizationalUnitIds": {
          "shape": "S1p"
        }
      }
    },
    "S1p": {
      "type": "list",
      "member": {}
    },
    "S1r": {
      "type": "list",
      "member": {}
    },
    "S1t": {
      "type": "structure",
      "members": {
        "RegionOrder": {
          "shape": "S1r"
        },
        "FailureToleranceCount": {
          "type": "integer"
        },
        "FailureTolerancePercentage": {
          "type": "integer"
        },
        "MaxConcurrentCount": {
          "type": "integer"
        },
        "MaxConcurrentPercentage": {
          "type": "integer"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "RetainStacksOnAccountRemoval": {
          "type": "boolean"
        }
      }
    },
    "S46": {
      "type": "structure",
      "required": [
        "StackResourceDriftStatus"
      ],
      "members": {
        "StackResourceDriftStatus": {},
        "LastCheckTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S4d": {
      "type": "structure",
      "required": [
        "StackId",
        "LogicalResourceId",
        "ResourceType",
        "StackResourceDriftStatus",
        "Timestamp"
      ],
      "members": {
        "StackId": {},
        "LogicalResourceId": {},
        "PhysicalResourceId": {},
        "PhysicalResourceIdContext": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "Value"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        },
        "ResourceType": {},
        "ExpectedProperties": {},
        "ActualProperties": {},
        "PropertyDifferences": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "PropertyPath",
              "ExpectedValue",
              "ActualValue",
              "DifferenceType"
            ],
            "members": {
              "PropertyPath": {},
              "ExpectedValue": {},
              "ActualValue": {},
              "DifferenceType": {}
            }
          }
        },
        "StackResourceDriftStatus": {},
        "Timestamp": {
          "type": "timestamp"
        }
      }
    },
    "S4x": {
      "type": "structure",
      "members": {
        "DriftStatus": {},
        "DriftDetectionStatus": {},
        "LastDriftCheckTimestamp": {
          "type": "timestamp"
        },
        "TotalStackInstancesCount": {
          "type": "integer"
        },
        "DriftedStackInstancesCount": {
          "type": "integer"
        },
        "InSyncStackInstancesCount": {
          "type": "integer"
        },
        "InProgressStackInstancesCount": {
          "type": "integer"
        },
        "FailedStackInstancesCount": {
          "type": "integer"
        }
      }
    },
    "S5w": {
      "type": "structure",
      "required": [
        "LogRoleArn",
        "LogGroupName"
      ],
      "members": {
        "LogRoleArn": {},
        "LogGroupName": {}
      }
    },
    "S66": {
      "type": "list",
      "member": {}
    },
    "S6z": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],13:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeAccountLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "AccountLimits"
    },
    "DescribeStackEvents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackEvents"
    },
    "DescribeStackResourceDrifts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "DescribeStackResources": {
      "result_key": "StackResources"
    },
    "DescribeStacks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Stacks"
    },
    "ListChangeSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListExports": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Exports"
    },
    "ListImports": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Imports"
    },
    "ListStackInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackResourceSummaries"
    },
    "ListStackSetOperationResults": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackSetOperations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStackSets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListStacks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "StackSummaries"
    },
    "ListTypeRegistrations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTypeVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTypes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}
},{}],14:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "StackExists": {
      "delay": 5,
      "operation": "DescribeStacks",
      "maxAttempts": 20,
      "acceptors": [
        {
          "matcher": "status",
          "expected": 200,
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "ValidationError",
          "state": "retry"
        }
      ]
    },
    "StackCreateComplete": {
      "delay": 30,
      "operation": "DescribeStacks",
      "maxAttempts": 120,
      "description": "Wait until stack status is CREATE_COMPLETE.",
      "acceptors": [
        {
          "argument": "Stacks[].StackStatus",
          "expected": "CREATE_COMPLETE",
          "matcher": "pathAll",
          "state": "success"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "CREATE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "DELETE_COMPLETE",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "DELETE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "ROLLBACK_COMPLETE",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "StackDeleteComplete": {
      "delay": 30,
      "operation": "DescribeStacks",
      "maxAttempts": 120,
      "description": "Wait until stack status is DELETE_COMPLETE.",
      "acceptors": [
        {
          "argument": "Stacks[].StackStatus",
          "expected": "DELETE_COMPLETE",
          "matcher": "pathAll",
          "state": "success"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "success"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "DELETE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "CREATE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_IN_PROGRESS",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_COMPLETE",
          "matcher": "pathAny",
          "state": "failure"
        }
      ]
    },
    "StackUpdateComplete": {
      "delay": 30,
      "maxAttempts": 120,
      "operation": "DescribeStacks",
      "description": "Wait until stack status is UPDATE_COMPLETE.",
      "acceptors": [
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_COMPLETE",
          "matcher": "pathAll",
          "state": "success"
        },
        {
          "expected": "UPDATE_FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Stacks[].StackStatus"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "expected": "UPDATE_ROLLBACK_COMPLETE",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Stacks[].StackStatus"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "StackImportComplete": {
      "delay": 30,
      "maxAttempts": 120,
      "operation": "DescribeStacks",
      "description": "Wait until stack status is IMPORT_COMPLETE.",
      "acceptors": [
        {
          "argument": "Stacks[].StackStatus",
          "expected": "IMPORT_COMPLETE",
          "matcher": "pathAll",
          "state": "success"
        },
        {
          "expected": "ROLLBACK_COMPLETE",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Stacks[].StackStatus"
        },
        {
          "expected": "ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Stacks[].StackStatus"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "IMPORT_ROLLBACK_IN_PROGRESS",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "IMPORT_ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "expected": "IMPORT_ROLLBACK_COMPLETE",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Stacks[].StackStatus"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "StackRollbackComplete": {
      "delay": 30,
      "operation": "DescribeStacks",
      "maxAttempts": 120,
      "description": "Wait until stack status is UPDATE_ROLLBACK_COMPLETE.",
      "acceptors": [
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_COMPLETE",
          "matcher": "pathAll",
          "state": "success"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "UPDATE_ROLLBACK_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "argument": "Stacks[].StackStatus",
          "expected": "DELETE_FAILED",
          "matcher": "pathAny",
          "state": "failure"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "ChangeSetCreateComplete": {
      "delay": 30,
      "operation": "DescribeChangeSet",
      "maxAttempts": 120,
      "description": "Wait until change set status is CREATE_COMPLETE.",
      "acceptors": [
        {
          "argument": "Status",
          "expected": "CREATE_COMPLETE",
          "matcher": "path",
          "state": "success"
        },
        {
          "argument": "Status",
          "expected": "FAILED",
          "matcher": "path",
          "state": "failure"
        },
        {
          "expected": "ValidationError",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "TypeRegistrationComplete": {
      "delay": 30,
      "operation": "DescribeTypeRegistration",
      "maxAttempts": 120,
      "description": "Wait until type registration is COMPLETE.",
      "acceptors": [
        {
          "argument": "ProgressStatus",
          "expected": "COMPLETE",
          "matcher": "path",
          "state": "success"
        },
        {
          "argument": "ProgressStatus",
          "expected": "FAILED",
          "matcher": "path",
          "state": "failure"
        }
      ]
    }
  }
}

},{}],15:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-25",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2016-11-25"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2016-11-25/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2016-11-25/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S21"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2016-11-25/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S28",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S2c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2016-11-25/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2016-11-25/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S2e"
              },
              "Tags": {
                "shape": "S21"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2016-11-25/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2016-11-25/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S2c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S3a"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S3a"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S2f"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "Sy"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-11-25/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S21"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2016-11-25/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S21",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2016-11-25/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2016-11-25/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2016-11-25/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2016-11-25/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2016-11-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "DefaultCacheBehavior": {
          "shape": "Sn"
        },
        "CacheBehaviors": {
          "shape": "S1a"
        },
        "CustomErrorResponses": {
          "shape": "S1d"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1i"
        },
        "Restrictions": {
          "shape": "S1m"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "So"
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S12"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S16"
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S13"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {}
            }
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "So"
              },
              "TrustedSigners": {
                "shape": "Sy"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S12"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S16"
              }
            }
          }
        }
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1m": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1u"
        },
        "DistributionConfig": {
          "shape": "S7"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S2c": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S28"
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S2f"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S2i": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1u"
        },
        "StreamingDistributionConfig": {
          "shape": "S2e"
        }
      }
    },
    "S3a": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "DefaultCacheBehavior": {
                "shape": "Sn"
              },
              "CacheBehaviors": {
                "shape": "S1a"
              },
              "CustomErrorResponses": {
                "shape": "S1d"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1i"
              },
              "Restrictions": {
                "shape": "S1m"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    }
  }
}
},{}],16:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListCloudFrontOriginAccessIdentities": {
      "input_token": "Marker",
      "output_token": "CloudFrontOriginAccessIdentityList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "CloudFrontOriginAccessIdentityList.IsTruncated",
      "result_key": "CloudFrontOriginAccessIdentityList.Items"
    },
    "ListDistributions": {
      "input_token": "Marker",
      "output_token": "DistributionList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "DistributionList.IsTruncated",
      "result_key": "DistributionList.Items"
    },
    "ListInvalidations": {
      "input_token": "Marker",
      "output_token": "InvalidationList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "InvalidationList.IsTruncated",
      "result_key": "InvalidationList.Items"
    },
    "ListStreamingDistributions": {
      "input_token": "Marker",
      "output_token": "StreamingDistributionList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "StreamingDistributionList.IsTruncated",
      "result_key": "StreamingDistributionList.Items"
    }
  }
}

},{}],17:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DistributionDeployed": {
      "delay": 60,
      "operation": "GetDistribution",
      "maxAttempts": 25,
      "description": "Wait until a distribution is deployed.",
      "acceptors": [
        {
          "expected": "Deployed",
          "matcher": "path",
          "state": "success",
          "argument": "Distribution.Status"
        }
      ]
    },
    "InvalidationCompleted": {
      "delay": 20,
      "operation": "GetInvalidation",
      "maxAttempts": 30,
      "description": "Wait until an invalidation has completed.",
      "acceptors": [
        {
          "expected": "Completed",
          "matcher": "path",
          "state": "success",
          "argument": "Invalidation.Status"
        }
      ]
    },
    "StreamingDistributionDeployed": {
      "delay": 60,
      "operation": "GetStreamingDistribution",
      "maxAttempts": 25,
      "description": "Wait until a streaming distribution is deployed.",
      "acceptors": [
        {
          "expected": "Deployed",
          "matcher": "path",
          "state": "success",
          "argument": "StreamingDistribution.Status"
        }
      ]
    }
  }
}

},{}],18:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-25",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2017-03-25"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2017-03-25/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2017-03-25/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S21"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2017-03-25/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S28",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S2c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2017-03-25/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2017-03-25/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S2e"
              },
              "Tags": {
                "shape": "S21"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-25/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteServiceLinkedRole": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-25/service-linked-role/{RoleName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {
            "location": "uri",
            "locationName": "RoleName"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-25/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S2c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S3b"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S3b"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S2f"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "Sy"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-25/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S21"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-03-25/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S21",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2017-03-25/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-03-25/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-03-25/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1s"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-03-25/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S2e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-03-25/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S2i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "DefaultCacheBehavior": {
          "shape": "Sn"
        },
        "CacheBehaviors": {
          "shape": "S1a"
        },
        "CustomErrorResponses": {
          "shape": "S1d"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1i"
        },
        "Restrictions": {
          "shape": "S1m"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  },
                  "OriginReadTimeout": {
                    "type": "integer"
                  },
                  "OriginKeepaliveTimeout": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "So"
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S12"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S16"
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S13"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {}
            }
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "So"
              },
              "TrustedSigners": {
                "shape": "Sy"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S12"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S16"
              }
            }
          }
        }
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1m": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1u"
        },
        "DistributionConfig": {
          "shape": "S7"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S2c": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S28"
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S2f"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S2i": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1u"
        },
        "StreamingDistributionConfig": {
          "shape": "S2e"
        }
      }
    },
    "S3b": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "DefaultCacheBehavior": {
                "shape": "Sn"
              },
              "CacheBehaviors": {
                "shape": "S1a"
              },
              "CustomErrorResponses": {
                "shape": "S1d"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1i"
              },
              "Restrictions": {
                "shape": "S1m"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    }
  }
}
},{}],19:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListCloudFrontOriginAccessIdentities": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "CloudFrontOriginAccessIdentityList.IsTruncated",
      "output_token": "CloudFrontOriginAccessIdentityList.NextMarker",
      "result_key": "CloudFrontOriginAccessIdentityList.Items"
    },
    "ListDistributions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "DistributionList.IsTruncated",
      "output_token": "DistributionList.NextMarker",
      "result_key": "DistributionList.Items"
    },
    "ListInvalidations": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "InvalidationList.IsTruncated",
      "output_token": "InvalidationList.NextMarker",
      "result_key": "InvalidationList.Items"
    },
    "ListStreamingDistributions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "StreamingDistributionList.IsTruncated",
      "output_token": "StreamingDistributionList.NextMarker",
      "result_key": "StreamingDistributionList.Items"
    }
  }
}
},{}],20:[function(require,module,exports){
arguments[4][17][0].apply(exports,arguments)
},{"dup":17}],21:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-30",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2017-10-30"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2017-10-30/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2017-10-30/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S22"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateFieldLevelEncryptionConfig": {
      "http": {
        "requestUri": "/2017-10-30/field-level-encryption",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "CreateFieldLevelEncryptionProfile": {
      "http": {
        "requestUri": "/2017-10-30/field-level-encryption-profile",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2017-10-30/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S2v",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S2z"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreatePublicKey": {
      "http": {
        "requestUri": "/2017-10-30/public-key",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S31",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2017-10-30/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2017-10-30/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S35"
              },
              "Tags": {
                "shape": "S22"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/field-level-encryption/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/field-level-encryption-profile/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeletePublicKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/public-key/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-30/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetFieldLevelEncryption": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "GetFieldLevelEncryptionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      }
    },
    "GetFieldLevelEncryptionProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption-profile/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "GetFieldLevelEncryptionProfileConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S2z"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetPublicKey": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/public-key/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "GetPublicKeyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyConfig": {
            "shape": "S31"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKeyConfig"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4g"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4g"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListFieldLevelEncryptionConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Comment": {},
                    "QueryArgProfileConfig": {
                      "shape": "S2a"
                    },
                    "ContentTypeProfileConfig": {
                      "shape": "S2e"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionList"
      }
    },
    "ListFieldLevelEncryptionProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/field-level-encryption-profile"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionProfileSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime",
                    "Name",
                    "EncryptionEntities"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Name": {},
                    "EncryptionEntities": {
                      "shape": "S2n"
                    },
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListPublicKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/public-key"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "PublicKeySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "Name",
                    "CreatedTime",
                    "EncodedKey"
                  ],
                  "members": {
                    "Id": {},
                    "Name": {},
                    "CreatedTime": {
                      "type": "timestamp"
                    },
                    "EncodedKey": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "PublicKeyList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S36"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "Sy"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-10-30/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S22"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-10-30/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S22",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2017-10-30/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateFieldLevelEncryptionConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "UpdateFieldLevelEncryptionProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "UpdatePublicKey": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig",
          "Id"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S31",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-30/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2017-10-30/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "DefaultCacheBehavior": {
          "shape": "Sn"
        },
        "CacheBehaviors": {
          "shape": "S1b"
        },
        "CustomErrorResponses": {
          "shape": "S1e"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1j"
        },
        "Restrictions": {
          "shape": "S1n"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  },
                  "OriginReadTimeout": {
                    "type": "integer"
                  },
                  "OriginKeepaliveTimeout": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "So"
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S12"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S16"
        },
        "FieldLevelEncryptionId": {}
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S13"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "required": [
              "LambdaFunctionARN",
              "EventType"
            ],
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {}
            }
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "So"
              },
              "TrustedSigners": {
                "shape": "Sy"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S12"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S16"
              },
              "FieldLevelEncryptionId": {}
            }
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1v"
        },
        "DistributionConfig": {
          "shape": "S7"
        }
      }
    },
    "S1v": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "CallerReference"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {},
        "QueryArgProfileConfig": {
          "shape": "S2a"
        },
        "ContentTypeProfileConfig": {
          "shape": "S2e"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "required": [
        "ForwardWhenQueryArgProfileIsUnknown"
      ],
      "members": {
        "ForwardWhenQueryArgProfileIsUnknown": {
          "type": "boolean"
        },
        "QueryArgProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "QueryArgProfile",
                "type": "structure",
                "required": [
                  "QueryArg",
                  "ProfileId"
                ],
                "members": {
                  "QueryArg": {},
                  "ProfileId": {}
                }
              }
            }
          }
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "ForwardWhenContentTypeIsUnknown"
      ],
      "members": {
        "ForwardWhenContentTypeIsUnknown": {
          "type": "boolean"
        },
        "ContentTypeProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "ContentTypeProfile",
                "type": "structure",
                "required": [
                  "Format",
                  "ContentType"
                ],
                "members": {
                  "Format": {},
                  "ProfileId": {},
                  "ContentType": {}
                }
              }
            }
          }
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionConfig": {
          "shape": "S29"
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "Name",
        "CallerReference",
        "EncryptionEntities"
      ],
      "members": {
        "Name": {},
        "CallerReference": {},
        "Comment": {},
        "EncryptionEntities": {
          "shape": "S2n"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "EncryptionEntity",
            "type": "structure",
            "required": [
              "PublicKeyId",
              "ProviderId",
              "FieldPatterns"
            ],
            "members": {
              "PublicKeyId": {},
              "ProviderId": {},
              "FieldPatterns": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "FieldPattern"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionProfileConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionProfileConfig": {
          "shape": "S2m"
        }
      }
    },
    "S2v": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S2v"
        }
      }
    },
    "S31": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Name",
        "EncodedKey"
      ],
      "members": {
        "CallerReference": {},
        "Name": {},
        "EncodedKey": {},
        "Comment": {}
      }
    },
    "S33": {
      "type": "structure",
      "required": [
        "Id",
        "CreatedTime",
        "PublicKeyConfig"
      ],
      "members": {
        "Id": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "PublicKeyConfig": {
          "shape": "S31"
        }
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S36"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1v"
        },
        "StreamingDistributionConfig": {
          "shape": "S35"
        }
      }
    },
    "S4g": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "DefaultCacheBehavior": {
                "shape": "Sn"
              },
              "CacheBehaviors": {
                "shape": "S1b"
              },
              "CustomErrorResponses": {
                "shape": "S1e"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1j"
              },
              "Restrictions": {
                "shape": "S1n"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    }
  }
}
},{}],22:[function(require,module,exports){
arguments[4][19][0].apply(exports,arguments)
},{"dup":19}],23:[function(require,module,exports){
arguments[4][17][0].apply(exports,arguments)
},{"dup":17}],24:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-18",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2018-06-18"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2018-06-18/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2018-06-18/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S22"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateFieldLevelEncryptionConfig": {
      "http": {
        "requestUri": "/2018-06-18/field-level-encryption",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "CreateFieldLevelEncryptionProfile": {
      "http": {
        "requestUri": "/2018-06-18/field-level-encryption-profile",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2018-06-18/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S2v",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S2z"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreatePublicKey": {
      "http": {
        "requestUri": "/2018-06-18/public-key",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S31",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2018-06-18/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2018-06-18/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S35"
              },
              "Tags": {
                "shape": "S22"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/field-level-encryption/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/field-level-encryption-profile/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeletePublicKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/public-key/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-06-18/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetFieldLevelEncryption": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "GetFieldLevelEncryptionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      }
    },
    "GetFieldLevelEncryptionProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption-profile/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "GetFieldLevelEncryptionProfileConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S2z"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetPublicKey": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/public-key/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "GetPublicKeyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyConfig": {
            "shape": "S31"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKeyConfig"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4g"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4g"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListFieldLevelEncryptionConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Comment": {},
                    "QueryArgProfileConfig": {
                      "shape": "S2a"
                    },
                    "ContentTypeProfileConfig": {
                      "shape": "S2e"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionList"
      }
    },
    "ListFieldLevelEncryptionProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/field-level-encryption-profile"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionProfileSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime",
                    "Name",
                    "EncryptionEntities"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Name": {},
                    "EncryptionEntities": {
                      "shape": "S2n"
                    },
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListPublicKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/public-key"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "PublicKeySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "Name",
                    "CreatedTime",
                    "EncodedKey"
                  ],
                  "members": {
                    "Id": {},
                    "Name": {},
                    "CreatedTime": {
                      "type": "timestamp"
                    },
                    "EncodedKey": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "PublicKeyList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S36"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "Sy"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-06-18/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S22"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2018-06-18/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S22",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2018-06-18/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S1t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateFieldLevelEncryptionConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S29",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2k"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "UpdateFieldLevelEncryptionProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "UpdatePublicKey": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig",
          "Id"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S31",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S33"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-06-18/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S35",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-06-18/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S39"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "DefaultCacheBehavior": {
          "shape": "Sn"
        },
        "CacheBehaviors": {
          "shape": "S1b"
        },
        "CustomErrorResponses": {
          "shape": "S1e"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1j"
        },
        "Restrictions": {
          "shape": "S1n"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  },
                  "OriginReadTimeout": {
                    "type": "integer"
                  },
                  "OriginKeepaliveTimeout": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "So"
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S12"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S16"
        },
        "FieldLevelEncryptionId": {}
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S13"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "required": [
              "LambdaFunctionARN",
              "EventType"
            ],
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {},
              "IncludeBody": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "So"
              },
              "TrustedSigners": {
                "shape": "Sy"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S12"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S16"
              },
              "FieldLevelEncryptionId": {}
            }
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1v"
        },
        "DistributionConfig": {
          "shape": "S7"
        }
      }
    },
    "S1v": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "CallerReference"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {},
        "QueryArgProfileConfig": {
          "shape": "S2a"
        },
        "ContentTypeProfileConfig": {
          "shape": "S2e"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "required": [
        "ForwardWhenQueryArgProfileIsUnknown"
      ],
      "members": {
        "ForwardWhenQueryArgProfileIsUnknown": {
          "type": "boolean"
        },
        "QueryArgProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "QueryArgProfile",
                "type": "structure",
                "required": [
                  "QueryArg",
                  "ProfileId"
                ],
                "members": {
                  "QueryArg": {},
                  "ProfileId": {}
                }
              }
            }
          }
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "ForwardWhenContentTypeIsUnknown"
      ],
      "members": {
        "ForwardWhenContentTypeIsUnknown": {
          "type": "boolean"
        },
        "ContentTypeProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "ContentTypeProfile",
                "type": "structure",
                "required": [
                  "Format",
                  "ContentType"
                ],
                "members": {
                  "Format": {},
                  "ProfileId": {},
                  "ContentType": {}
                }
              }
            }
          }
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionConfig": {
          "shape": "S29"
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "Name",
        "CallerReference",
        "EncryptionEntities"
      ],
      "members": {
        "Name": {},
        "CallerReference": {},
        "Comment": {},
        "EncryptionEntities": {
          "shape": "S2n"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "EncryptionEntity",
            "type": "structure",
            "required": [
              "PublicKeyId",
              "ProviderId",
              "FieldPatterns"
            ],
            "members": {
              "PublicKeyId": {},
              "ProviderId": {},
              "FieldPatterns": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "FieldPattern"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionProfileConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionProfileConfig": {
          "shape": "S2m"
        }
      }
    },
    "S2v": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S2v"
        }
      }
    },
    "S31": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Name",
        "EncodedKey"
      ],
      "members": {
        "CallerReference": {},
        "Name": {},
        "EncodedKey": {},
        "Comment": {}
      }
    },
    "S33": {
      "type": "structure",
      "required": [
        "Id",
        "CreatedTime",
        "PublicKeyConfig"
      ],
      "members": {
        "Id": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "PublicKeyConfig": {
          "shape": "S31"
        }
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S36"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "Sy"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S1v"
        },
        "StreamingDistributionConfig": {
          "shape": "S35"
        }
      }
    },
    "S4g": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "DefaultCacheBehavior": {
                "shape": "Sn"
              },
              "CacheBehaviors": {
                "shape": "S1b"
              },
              "CustomErrorResponses": {
                "shape": "S1e"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1j"
              },
              "Restrictions": {
                "shape": "S1n"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    }
  }
}
},{}],25:[function(require,module,exports){
arguments[4][19][0].apply(exports,arguments)
},{"dup":19}],26:[function(require,module,exports){
arguments[4][17][0].apply(exports,arguments)
},{"dup":17}],27:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-05",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2018-11-05"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2018-11-05/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S22"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2018-11-05/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S2b"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S22"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateFieldLevelEncryptionConfig": {
      "http": {
        "requestUri": "/2018-11-05/field-level-encryption",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2i",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2t"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "CreateFieldLevelEncryptionProfile": {
      "http": {
        "requestUri": "/2018-11-05/field-level-encryption-profile",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2v",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S32"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2018-11-05/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S34",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S38"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreatePublicKey": {
      "http": {
        "requestUri": "/2018-11-05/public-key",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S3a",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3c"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2018-11-05/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2018-11-05/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S3e"
              },
              "Tags": {
                "shape": "S2b"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3i"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/field-level-encryption/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/field-level-encryption-profile/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeletePublicKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/public-key/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-11-05/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S22"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetFieldLevelEncryption": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "GetFieldLevelEncryptionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      }
    },
    "GetFieldLevelEncryptionProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption-profile/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S32"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "GetFieldLevelEncryptionProfileConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2v"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S38"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetPublicKey": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/public-key/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3c"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "GetPublicKeyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyConfig": {
            "shape": "S3a"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKeyConfig"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3e"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4p"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4p"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListFieldLevelEncryptionConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Comment": {},
                    "QueryArgProfileConfig": {
                      "shape": "S2j"
                    },
                    "ContentTypeProfileConfig": {
                      "shape": "S2n"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionList"
      }
    },
    "ListFieldLevelEncryptionProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/field-level-encryption-profile"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionProfileSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime",
                    "Name",
                    "EncryptionEntities"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Name": {},
                    "EncryptionEntities": {
                      "shape": "S2w"
                    },
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListPublicKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/public-key"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "PublicKeySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "Name",
                    "CreatedTime",
                    "EncodedKey"
                  ],
                  "members": {
                    "Id": {},
                    "Name": {},
                    "CreatedTime": {
                      "type": "timestamp"
                    },
                    "EncodedKey": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "PublicKeyList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S3f"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "S17"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-11-05/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S2b"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2018-11-05/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S2b",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2018-11-05/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S22"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateFieldLevelEncryptionConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2i",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2t"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "UpdateFieldLevelEncryptionProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2v",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S32"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "UpdatePublicKey": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig",
          "Id"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S3a",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3c"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2018-11-05/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3e",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2018-11-05/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "OriginGroups": {
          "shape": "Sn"
        },
        "DefaultCacheBehavior": {
          "shape": "Sw"
        },
        "CacheBehaviors": {
          "shape": "S1k"
        },
        "CustomErrorResponses": {
          "shape": "S1n"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1s"
        },
        "Restrictions": {
          "shape": "S1w"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  },
                  "OriginReadTimeout": {
                    "type": "integer"
                  },
                  "OriginKeepaliveTimeout": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "OriginGroup",
            "type": "structure",
            "required": [
              "Id",
              "FailoverCriteria",
              "Members"
            ],
            "members": {
              "Id": {},
              "FailoverCriteria": {
                "type": "structure",
                "required": [
                  "StatusCodes"
                ],
                "members": {
                  "StatusCodes": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "StatusCode",
                          "type": "integer"
                        }
                      }
                    }
                  }
                }
              },
              "Members": {
                "type": "structure",
                "required": [
                  "Quantity",
                  "Items"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginGroupMember",
                      "type": "structure",
                      "required": [
                        "OriginId"
                      ],
                      "members": {
                        "OriginId": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "Sx"
        },
        "TrustedSigners": {
          "shape": "S17"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S1b"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S1f"
        },
        "FieldLevelEncryptionId": {}
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S1c"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S1c"
            }
          }
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "required": [
              "LambdaFunctionARN",
              "EventType"
            ],
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {},
              "IncludeBody": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S1k": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "Sx"
              },
              "TrustedSigners": {
                "shape": "S17"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S1b"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S1f"
              },
              "FieldLevelEncryptionId": {}
            }
          }
        }
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S22": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S24"
        },
        "DistributionConfig": {
          "shape": "S7"
        }
      }
    },
    "S24": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S2i": {
      "type": "structure",
      "required": [
        "CallerReference"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {},
        "QueryArgProfileConfig": {
          "shape": "S2j"
        },
        "ContentTypeProfileConfig": {
          "shape": "S2n"
        }
      }
    },
    "S2j": {
      "type": "structure",
      "required": [
        "ForwardWhenQueryArgProfileIsUnknown"
      ],
      "members": {
        "ForwardWhenQueryArgProfileIsUnknown": {
          "type": "boolean"
        },
        "QueryArgProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "QueryArgProfile",
                "type": "structure",
                "required": [
                  "QueryArg",
                  "ProfileId"
                ],
                "members": {
                  "QueryArg": {},
                  "ProfileId": {}
                }
              }
            }
          }
        }
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "ForwardWhenContentTypeIsUnknown"
      ],
      "members": {
        "ForwardWhenContentTypeIsUnknown": {
          "type": "boolean"
        },
        "ContentTypeProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "ContentTypeProfile",
                "type": "structure",
                "required": [
                  "Format",
                  "ContentType"
                ],
                "members": {
                  "Format": {},
                  "ProfileId": {},
                  "ContentType": {}
                }
              }
            }
          }
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionConfig": {
          "shape": "S2i"
        }
      }
    },
    "S2v": {
      "type": "structure",
      "required": [
        "Name",
        "CallerReference",
        "EncryptionEntities"
      ],
      "members": {
        "Name": {},
        "CallerReference": {},
        "Comment": {},
        "EncryptionEntities": {
          "shape": "S2w"
        }
      }
    },
    "S2w": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "EncryptionEntity",
            "type": "structure",
            "required": [
              "PublicKeyId",
              "ProviderId",
              "FieldPatterns"
            ],
            "members": {
              "PublicKeyId": {},
              "ProviderId": {},
              "FieldPatterns": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "FieldPattern"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionProfileConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionProfileConfig": {
          "shape": "S2v"
        }
      }
    },
    "S34": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S38": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S34"
        }
      }
    },
    "S3a": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Name",
        "EncodedKey"
      ],
      "members": {
        "CallerReference": {},
        "Name": {},
        "EncodedKey": {},
        "Comment": {}
      }
    },
    "S3c": {
      "type": "structure",
      "required": [
        "Id",
        "CreatedTime",
        "PublicKeyConfig"
      ],
      "members": {
        "Id": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "PublicKeyConfig": {
          "shape": "S3a"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S3f"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "S17"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S3f": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S3i": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S24"
        },
        "StreamingDistributionConfig": {
          "shape": "S3e"
        }
      }
    },
    "S4p": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "OriginGroups": {
                "shape": "Sn"
              },
              "DefaultCacheBehavior": {
                "shape": "Sw"
              },
              "CacheBehaviors": {
                "shape": "S1k"
              },
              "CustomErrorResponses": {
                "shape": "S1n"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1s"
              },
              "Restrictions": {
                "shape": "S1w"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    }
  }
}
},{}],28:[function(require,module,exports){
arguments[4][19][0].apply(exports,arguments)
},{"dup":19}],29:[function(require,module,exports){
arguments[4][17][0].apply(exports,arguments)
},{"dup":17}],30:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-03-26",
    "endpointPrefix": "cloudfront",
    "globalEndpoint": "cloudfront.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "CloudFront",
    "serviceFullName": "Amazon CloudFront",
    "serviceId": "CloudFront",
    "signatureVersion": "v4",
    "uid": "cloudfront-2019-03-26"
  },
  "operations": {
    "CreateCloudFrontOriginAccessIdentity": {
      "http": {
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "CreateDistribution": {
      "http": {
        "requestUri": "/2019-03-26/distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S23"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateDistributionWithTags": {
      "http": {
        "requestUri": "/2019-03-26/distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfigWithTags"
        ],
        "members": {
          "DistributionConfigWithTags": {
            "locationName": "DistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            },
            "type": "structure",
            "required": [
              "DistributionConfig",
              "Tags"
            ],
            "members": {
              "DistributionConfig": {
                "shape": "S7"
              },
              "Tags": {
                "shape": "S2f"
              }
            }
          }
        },
        "payload": "DistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S23"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "CreateFieldLevelEncryptionConfig": {
      "http": {
        "requestUri": "/2019-03-26/field-level-encryption",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2x"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "CreateFieldLevelEncryptionProfile": {
      "http": {
        "requestUri": "/2019-03-26/field-level-encryption-profile",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2z",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S36"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "CreateInvalidation": {
      "http": {
        "requestUri": "/2019-03-26/distribution/{DistributionId}/invalidation",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "InvalidationBatch"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "InvalidationBatch": {
            "shape": "S38",
            "locationName": "InvalidationBatch",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "InvalidationBatch"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "Invalidation": {
            "shape": "S3c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "CreatePublicKey": {
      "http": {
        "requestUri": "/2019-03-26/public-key",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S3e",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3g"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "CreateStreamingDistribution": {
      "http": {
        "requestUri": "/2019-03-26/streaming-distribution",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3i",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3m"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "CreateStreamingDistributionWithTags": {
      "http": {
        "requestUri": "/2019-03-26/streaming-distribution?WithTags",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfigWithTags"
        ],
        "members": {
          "StreamingDistributionConfigWithTags": {
            "locationName": "StreamingDistributionConfigWithTags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            },
            "type": "structure",
            "required": [
              "StreamingDistributionConfig",
              "Tags"
            ],
            "members": {
              "StreamingDistributionConfig": {
                "shape": "S3i"
              },
              "Tags": {
                "shape": "S2f"
              }
            }
          }
        },
        "payload": "StreamingDistributionConfigWithTags"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3m"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "DeleteCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/field-level-encryption/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteFieldLevelEncryptionProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/field-level-encryption-profile/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeletePublicKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/public-key/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "DeleteStreamingDistribution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-03-26/streaming-distribution/{Id}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        }
      }
    },
    "GetCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "GetCloudFrontOriginAccessIdentityConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      }
    },
    "GetDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S23"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "GetDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionConfig": {
            "shape": "S7"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "DistributionConfig"
      }
    },
    "GetFieldLevelEncryption": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2x"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "GetFieldLevelEncryptionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2m"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      }
    },
    "GetFieldLevelEncryptionProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption-profile/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S36"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "GetFieldLevelEncryptionProfileConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2z"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      }
    },
    "GetInvalidation": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distribution/{DistributionId}/invalidation/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId",
          "Id"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invalidation": {
            "shape": "S3c"
          }
        },
        "payload": "Invalidation"
      }
    },
    "GetPublicKey": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/public-key/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3g"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "GetPublicKeyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyConfig": {
            "shape": "S3e"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKeyConfig"
      }
    },
    "GetStreamingDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/streaming-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3m"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    },
    "GetStreamingDistributionConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3i"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistributionConfig"
      }
    },
    "ListCloudFrontOriginAccessIdentities": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentityList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "CloudFrontOriginAccessIdentitySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "S3CanonicalUserId",
                    "Comment"
                  ],
                  "members": {
                    "Id": {},
                    "S3CanonicalUserId": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "CloudFrontOriginAccessIdentityList"
      }
    },
    "ListDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4t"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListDistributionsByWebACLId": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distributionsByWebACLId/{WebACLId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          },
          "WebACLId": {
            "location": "uri",
            "locationName": "WebACLId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DistributionList": {
            "shape": "S4t"
          }
        },
        "payload": "DistributionList"
      }
    },
    "ListFieldLevelEncryptionConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Comment": {},
                    "QueryArgProfileConfig": {
                      "shape": "S2n"
                    },
                    "ContentTypeProfileConfig": {
                      "shape": "S2r"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionList"
      }
    },
    "ListFieldLevelEncryptionProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/field-level-encryption-profile"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfileList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "FieldLevelEncryptionProfileSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "LastModifiedTime",
                    "Name",
                    "EncryptionEntities"
                  ],
                  "members": {
                    "Id": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Name": {},
                    "EncryptionEntities": {
                      "shape": "S30"
                    },
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "FieldLevelEncryptionProfileList"
      }
    },
    "ListInvalidations": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/distribution/{DistributionId}/invalidation"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionId"
        ],
        "members": {
          "DistributionId": {
            "location": "uri",
            "locationName": "DistributionId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidationList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "InvalidationSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "CreateTime",
                    "Status"
                  ],
                  "members": {
                    "Id": {},
                    "CreateTime": {
                      "type": "timestamp"
                    },
                    "Status": {}
                  }
                }
              }
            }
          }
        },
        "payload": "InvalidationList"
      }
    },
    "ListPublicKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/public-key"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "structure",
            "required": [
              "MaxItems",
              "Quantity"
            ],
            "members": {
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "PublicKeySummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "Name",
                    "CreatedTime",
                    "EncodedKey"
                  ],
                  "members": {
                    "Id": {},
                    "Name": {},
                    "CreatedTime": {
                      "type": "timestamp"
                    },
                    "EncodedKey": {},
                    "Comment": {}
                  }
                }
              }
            }
          }
        },
        "payload": "PublicKeyList"
      }
    },
    "ListStreamingDistributions": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/streaming-distribution"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistributionList": {
            "type": "structure",
            "required": [
              "Marker",
              "MaxItems",
              "IsTruncated",
              "Quantity"
            ],
            "members": {
              "Marker": {},
              "NextMarker": {},
              "MaxItems": {
                "type": "integer"
              },
              "IsTruncated": {
                "type": "boolean"
              },
              "Quantity": {
                "type": "integer"
              },
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "StreamingDistributionSummary",
                  "type": "structure",
                  "required": [
                    "Id",
                    "ARN",
                    "Status",
                    "LastModifiedTime",
                    "DomainName",
                    "S3Origin",
                    "Aliases",
                    "TrustedSigners",
                    "Comment",
                    "PriceClass",
                    "Enabled"
                  ],
                  "members": {
                    "Id": {},
                    "ARN": {},
                    "Status": {},
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "DomainName": {},
                    "S3Origin": {
                      "shape": "S3j"
                    },
                    "Aliases": {
                      "shape": "S8"
                    },
                    "TrustedSigners": {
                      "shape": "S17"
                    },
                    "Comment": {},
                    "PriceClass": {},
                    "Enabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "payload": "StreamingDistributionList"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-03-26/tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S2f"
          }
        },
        "payload": "Tags"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2019-03-26/tagging?Operation=Tag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "Tags": {
            "shape": "S2f",
            "locationName": "Tags",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          }
        },
        "payload": "Tags"
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/2019-03-26/tagging?Operation=Untag",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "querystring",
            "locationName": "Resource"
          },
          "TagKeys": {
            "locationName": "TagKeys",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            },
            "type": "structure",
            "members": {
              "Items": {
                "type": "list",
                "member": {
                  "locationName": "Key"
                }
              }
            }
          }
        },
        "payload": "TagKeys"
      }
    },
    "UpdateCloudFrontOriginAccessIdentity": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/origin-access-identity/cloudfront/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "CloudFrontOriginAccessIdentityConfig",
          "Id"
        ],
        "members": {
          "CloudFrontOriginAccessIdentityConfig": {
            "shape": "S2",
            "locationName": "CloudFrontOriginAccessIdentityConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "CloudFrontOriginAccessIdentityConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontOriginAccessIdentity": {
            "shape": "S5"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "CloudFrontOriginAccessIdentity"
      }
    },
    "UpdateDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DistributionConfig",
          "Id"
        ],
        "members": {
          "DistributionConfig": {
            "shape": "S7",
            "locationName": "DistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "DistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "Distribution": {
            "shape": "S23"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "Distribution"
      }
    },
    "UpdateFieldLevelEncryptionConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/field-level-encryption/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionConfig": {
            "shape": "S2m",
            "locationName": "FieldLevelEncryptionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryption": {
            "shape": "S2x"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryption"
      }
    },
    "UpdateFieldLevelEncryptionProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/field-level-encryption-profile/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "FieldLevelEncryptionProfileConfig",
          "Id"
        ],
        "members": {
          "FieldLevelEncryptionProfileConfig": {
            "shape": "S2z",
            "locationName": "FieldLevelEncryptionProfileConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "FieldLevelEncryptionProfileConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "FieldLevelEncryptionProfile": {
            "shape": "S36"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "FieldLevelEncryptionProfile"
      }
    },
    "UpdatePublicKey": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/public-key/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicKeyConfig",
          "Id"
        ],
        "members": {
          "PublicKeyConfig": {
            "shape": "S3e",
            "locationName": "PublicKeyConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "PublicKeyConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKey": {
            "shape": "S3g"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "PublicKey"
      }
    },
    "UpdateStreamingDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-03-26/streaming-distribution/{Id}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamingDistributionConfig",
          "Id"
        ],
        "members": {
          "StreamingDistributionConfig": {
            "shape": "S3i",
            "locationName": "StreamingDistributionConfig",
            "xmlNamespace": {
              "uri": "http://cloudfront.amazonaws.com/doc/2019-03-26/"
            }
          },
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          }
        },
        "payload": "StreamingDistributionConfig"
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamingDistribution": {
            "shape": "S3m"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          }
        },
        "payload": "StreamingDistribution"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Comment"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {}
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Id",
        "S3CanonicalUserId"
      ],
      "members": {
        "Id": {},
        "S3CanonicalUserId": {},
        "CloudFrontOriginAccessIdentityConfig": {
          "shape": "S2"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Origins",
        "DefaultCacheBehavior",
        "Comment",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "Aliases": {
          "shape": "S8"
        },
        "DefaultRootObject": {},
        "Origins": {
          "shape": "Sb"
        },
        "OriginGroups": {
          "shape": "Sn"
        },
        "DefaultCacheBehavior": {
          "shape": "Sw"
        },
        "CacheBehaviors": {
          "shape": "S1k"
        },
        "CustomErrorResponses": {
          "shape": "S1n"
        },
        "Comment": {
          "type": "string",
          "sensitive": true
        },
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "IncludeCookies",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "IncludeCookies": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        },
        "ViewerCertificate": {
          "shape": "S1t"
        },
        "Restrictions": {
          "shape": "S1x"
        },
        "WebACLId": {},
        "HttpVersion": {},
        "IsIPV6Enabled": {
          "type": "boolean"
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CNAME"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Origin",
            "type": "structure",
            "required": [
              "Id",
              "DomainName"
            ],
            "members": {
              "Id": {},
              "DomainName": {},
              "OriginPath": {},
              "CustomHeaders": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginCustomHeader",
                      "type": "structure",
                      "required": [
                        "HeaderName",
                        "HeaderValue"
                      ],
                      "members": {
                        "HeaderName": {},
                        "HeaderValue": {}
                      }
                    }
                  }
                }
              },
              "S3OriginConfig": {
                "type": "structure",
                "required": [
                  "OriginAccessIdentity"
                ],
                "members": {
                  "OriginAccessIdentity": {}
                }
              },
              "CustomOriginConfig": {
                "type": "structure",
                "required": [
                  "HTTPPort",
                  "HTTPSPort",
                  "OriginProtocolPolicy"
                ],
                "members": {
                  "HTTPPort": {
                    "type": "integer"
                  },
                  "HTTPSPort": {
                    "type": "integer"
                  },
                  "OriginProtocolPolicy": {},
                  "OriginSslProtocols": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "SslProtocol"
                        }
                      }
                    }
                  },
                  "OriginReadTimeout": {
                    "type": "integer"
                  },
                  "OriginKeepaliveTimeout": {
                    "type": "integer"
                  }
                }
              },
              "ConnectionAttempts": {
                "type": "integer"
              },
              "ConnectionTimeout": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "OriginGroup",
            "type": "structure",
            "required": [
              "Id",
              "FailoverCriteria",
              "Members"
            ],
            "members": {
              "Id": {},
              "FailoverCriteria": {
                "type": "structure",
                "required": [
                  "StatusCodes"
                ],
                "members": {
                  "StatusCodes": {
                    "type": "structure",
                    "required": [
                      "Quantity",
                      "Items"
                    ],
                    "members": {
                      "Quantity": {
                        "type": "integer"
                      },
                      "Items": {
                        "type": "list",
                        "member": {
                          "locationName": "StatusCode",
                          "type": "integer"
                        }
                      }
                    }
                  }
                }
              },
              "Members": {
                "type": "structure",
                "required": [
                  "Quantity",
                  "Items"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "OriginGroupMember",
                      "type": "structure",
                      "required": [
                        "OriginId"
                      ],
                      "members": {
                        "OriginId": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "TargetOriginId",
        "ForwardedValues",
        "TrustedSigners",
        "ViewerProtocolPolicy",
        "MinTTL"
      ],
      "members": {
        "TargetOriginId": {},
        "ForwardedValues": {
          "shape": "Sx"
        },
        "TrustedSigners": {
          "shape": "S17"
        },
        "ViewerProtocolPolicy": {},
        "MinTTL": {
          "type": "long"
        },
        "AllowedMethods": {
          "shape": "S1b"
        },
        "SmoothStreaming": {
          "type": "boolean"
        },
        "DefaultTTL": {
          "type": "long"
        },
        "MaxTTL": {
          "type": "long"
        },
        "Compress": {
          "type": "boolean"
        },
        "LambdaFunctionAssociations": {
          "shape": "S1f"
        },
        "FieldLevelEncryptionId": {}
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "QueryString",
        "Cookies"
      ],
      "members": {
        "QueryString": {
          "type": "boolean"
        },
        "Cookies": {
          "type": "structure",
          "required": [
            "Forward"
          ],
          "members": {
            "Forward": {},
            "WhitelistedNames": {
              "type": "structure",
              "required": [
                "Quantity"
              ],
              "members": {
                "Quantity": {
                  "type": "integer"
                },
                "Items": {
                  "type": "list",
                  "member": {
                    "locationName": "Name"
                  }
                }
              }
            }
          }
        },
        "Headers": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        },
        "QueryStringCacheKeys": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Name"
              }
            }
          }
        }
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "AwsAccountNumber"
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "Quantity",
        "Items"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "shape": "S1c"
        },
        "CachedMethods": {
          "type": "structure",
          "required": [
            "Quantity",
            "Items"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "shape": "S1c"
            }
          }
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "locationName": "Method"
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "LambdaFunctionAssociation",
            "type": "structure",
            "required": [
              "LambdaFunctionARN",
              "EventType"
            ],
            "members": {
              "LambdaFunctionARN": {},
              "EventType": {},
              "IncludeBody": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S1k": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CacheBehavior",
            "type": "structure",
            "required": [
              "PathPattern",
              "TargetOriginId",
              "ForwardedValues",
              "TrustedSigners",
              "ViewerProtocolPolicy",
              "MinTTL"
            ],
            "members": {
              "PathPattern": {},
              "TargetOriginId": {},
              "ForwardedValues": {
                "shape": "Sx"
              },
              "TrustedSigners": {
                "shape": "S17"
              },
              "ViewerProtocolPolicy": {},
              "MinTTL": {
                "type": "long"
              },
              "AllowedMethods": {
                "shape": "S1b"
              },
              "SmoothStreaming": {
                "type": "boolean"
              },
              "DefaultTTL": {
                "type": "long"
              },
              "MaxTTL": {
                "type": "long"
              },
              "Compress": {
                "type": "boolean"
              },
              "LambdaFunctionAssociations": {
                "shape": "S1f"
              },
              "FieldLevelEncryptionId": {}
            }
          }
        }
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "CustomErrorResponse",
            "type": "structure",
            "required": [
              "ErrorCode"
            ],
            "members": {
              "ErrorCode": {
                "type": "integer"
              },
              "ResponsePagePath": {},
              "ResponseCode": {},
              "ErrorCachingMinTTL": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "CloudFrontDefaultCertificate": {
          "type": "boolean"
        },
        "IAMCertificateId": {},
        "ACMCertificateArn": {},
        "SSLSupportMethod": {},
        "MinimumProtocolVersion": {},
        "Certificate": {
          "deprecated": true
        },
        "CertificateSource": {
          "deprecated": true
        }
      }
    },
    "S1x": {
      "type": "structure",
      "required": [
        "GeoRestriction"
      ],
      "members": {
        "GeoRestriction": {
          "type": "structure",
          "required": [
            "RestrictionType",
            "Quantity"
          ],
          "members": {
            "RestrictionType": {},
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Location"
              }
            }
          }
        }
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "LastModifiedTime",
        "InProgressInvalidationBatches",
        "DomainName",
        "ActiveTrustedSigners",
        "DistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "InProgressInvalidationBatches": {
          "type": "integer"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S25"
        },
        "DistributionConfig": {
          "shape": "S7"
        },
        "AliasICPRecordals": {
          "shape": "S2a"
        }
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "Enabled",
        "Quantity"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Signer",
            "type": "structure",
            "members": {
              "AwsAccountNumber": {},
              "KeyPairIds": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "KeyPairId"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S2a": {
      "type": "list",
      "member": {
        "locationName": "AliasICPRecordal",
        "type": "structure",
        "members": {
          "CNAME": {},
          "ICPRecordalStatus": {}
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "Items": {
          "type": "list",
          "member": {
            "locationName": "Tag",
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "CallerReference"
      ],
      "members": {
        "CallerReference": {},
        "Comment": {},
        "QueryArgProfileConfig": {
          "shape": "S2n"
        },
        "ContentTypeProfileConfig": {
          "shape": "S2r"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "ForwardWhenQueryArgProfileIsUnknown"
      ],
      "members": {
        "ForwardWhenQueryArgProfileIsUnknown": {
          "type": "boolean"
        },
        "QueryArgProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "QueryArgProfile",
                "type": "structure",
                "required": [
                  "QueryArg",
                  "ProfileId"
                ],
                "members": {
                  "QueryArg": {},
                  "ProfileId": {}
                }
              }
            }
          }
        }
      }
    },
    "S2r": {
      "type": "structure",
      "required": [
        "ForwardWhenContentTypeIsUnknown"
      ],
      "members": {
        "ForwardWhenContentTypeIsUnknown": {
          "type": "boolean"
        },
        "ContentTypeProfiles": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "ContentTypeProfile",
                "type": "structure",
                "required": [
                  "Format",
                  "ContentType"
                ],
                "members": {
                  "Format": {},
                  "ProfileId": {},
                  "ContentType": {}
                }
              }
            }
          }
        }
      }
    },
    "S2x": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionConfig": {
          "shape": "S2m"
        }
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "Name",
        "CallerReference",
        "EncryptionEntities"
      ],
      "members": {
        "Name": {},
        "CallerReference": {},
        "Comment": {},
        "EncryptionEntities": {
          "shape": "S30"
        }
      }
    },
    "S30": {
      "type": "structure",
      "required": [
        "Quantity"
      ],
      "members": {
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "EncryptionEntity",
            "type": "structure",
            "required": [
              "PublicKeyId",
              "ProviderId",
              "FieldPatterns"
            ],
            "members": {
              "PublicKeyId": {},
              "ProviderId": {},
              "FieldPatterns": {
                "type": "structure",
                "required": [
                  "Quantity"
                ],
                "members": {
                  "Quantity": {
                    "type": "integer"
                  },
                  "Items": {
                    "type": "list",
                    "member": {
                      "locationName": "FieldPattern"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "Id",
        "LastModifiedTime",
        "FieldLevelEncryptionProfileConfig"
      ],
      "members": {
        "Id": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FieldLevelEncryptionProfileConfig": {
          "shape": "S2z"
        }
      }
    },
    "S38": {
      "type": "structure",
      "required": [
        "Paths",
        "CallerReference"
      ],
      "members": {
        "Paths": {
          "type": "structure",
          "required": [
            "Quantity"
          ],
          "members": {
            "Quantity": {
              "type": "integer"
            },
            "Items": {
              "type": "list",
              "member": {
                "locationName": "Path"
              }
            }
          }
        },
        "CallerReference": {}
      }
    },
    "S3c": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "CreateTime",
        "InvalidationBatch"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "InvalidationBatch": {
          "shape": "S38"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "required": [
        "CallerReference",
        "Name",
        "EncodedKey"
      ],
      "members": {
        "CallerReference": {},
        "Name": {},
        "EncodedKey": {},
        "Comment": {}
      }
    },
    "S3g": {
      "type": "structure",
      "required": [
        "Id",
        "CreatedTime",
        "PublicKeyConfig"
      ],
      "members": {
        "Id": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "PublicKeyConfig": {
          "shape": "S3e"
        }
      }
    },
    "S3i": {
      "type": "structure",
      "required": [
        "CallerReference",
        "S3Origin",
        "Comment",
        "TrustedSigners",
        "Enabled"
      ],
      "members": {
        "CallerReference": {},
        "S3Origin": {
          "shape": "S3j"
        },
        "Aliases": {
          "shape": "S8"
        },
        "Comment": {},
        "Logging": {
          "type": "structure",
          "required": [
            "Enabled",
            "Bucket",
            "Prefix"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Bucket": {},
            "Prefix": {}
          }
        },
        "TrustedSigners": {
          "shape": "S17"
        },
        "PriceClass": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S3j": {
      "type": "structure",
      "required": [
        "DomainName",
        "OriginAccessIdentity"
      ],
      "members": {
        "DomainName": {},
        "OriginAccessIdentity": {}
      }
    },
    "S3m": {
      "type": "structure",
      "required": [
        "Id",
        "ARN",
        "Status",
        "DomainName",
        "ActiveTrustedSigners",
        "StreamingDistributionConfig"
      ],
      "members": {
        "Id": {},
        "ARN": {},
        "Status": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "DomainName": {},
        "ActiveTrustedSigners": {
          "shape": "S25"
        },
        "StreamingDistributionConfig": {
          "shape": "S3i"
        }
      }
    },
    "S4t": {
      "type": "structure",
      "required": [
        "Marker",
        "MaxItems",
        "IsTruncated",
        "Quantity"
      ],
      "members": {
        "Marker": {},
        "NextMarker": {},
        "MaxItems": {
          "type": "integer"
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Quantity": {
          "type": "integer"
        },
        "Items": {
          "type": "list",
          "member": {
            "locationName": "DistributionSummary",
            "type": "structure",
            "required": [
              "Id",
              "ARN",
              "Status",
              "LastModifiedTime",
              "DomainName",
              "Aliases",
              "Origins",
              "DefaultCacheBehavior",
              "CacheBehaviors",
              "CustomErrorResponses",
              "Comment",
              "PriceClass",
              "Enabled",
              "ViewerCertificate",
              "Restrictions",
              "WebACLId",
              "HttpVersion",
              "IsIPV6Enabled"
            ],
            "members": {
              "Id": {},
              "ARN": {},
              "Status": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "DomainName": {},
              "Aliases": {
                "shape": "S8"
              },
              "Origins": {
                "shape": "Sb"
              },
              "OriginGroups": {
                "shape": "Sn"
              },
              "DefaultCacheBehavior": {
                "shape": "Sw"
              },
              "CacheBehaviors": {
                "shape": "S1k"
              },
              "CustomErrorResponses": {
                "shape": "S1n"
              },
              "Comment": {},
              "PriceClass": {},
              "Enabled": {
                "type": "boolean"
              },
              "ViewerCertificate": {
                "shape": "S1t"
              },
              "Restrictions": {
                "shape": "S1x"
              },
              "WebACLId": {},
              "HttpVersion": {},
              "IsIPV6Enabled": {
                "type": "boolean"
              },
              "AliasICPRecordals": {
                "shape": "S2a"
              }
            }
          }
        }
      }
    }
  }
}
},{}],31:[function(require,module,exports){
arguments[4][19][0].apply(exports,arguments)
},{"dup":19}],32:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DistributionDeployed": {
      "delay": 60,
      "operation": "GetDistribution",
      "maxAttempts": 35,
      "description": "Wait until a distribution is deployed.",
      "acceptors": [
        {
          "expected": "Deployed",
          "matcher": "path",
          "state": "success",
          "argument": "Distribution.Status"
        }
      ]
    },
    "InvalidationCompleted": {
      "delay": 20,
      "operation": "GetInvalidation",
      "maxAttempts": 30,
      "description": "Wait until an invalidation has completed.",
      "acceptors": [
        {
          "expected": "Completed",
          "matcher": "path",
          "state": "success",
          "argument": "Invalidation.Status"
        }
      ]
    },
    "StreamingDistributionDeployed": {
      "delay": 60,
      "operation": "GetStreamingDistribution",
      "maxAttempts": 25,
      "description": "Wait until a streaming distribution is deployed.",
      "acceptors": [
        {
          "expected": "Deployed",
          "matcher": "path",
          "state": "success",
          "argument": "StreamingDistribution.Status"
        }
      ]
    }
  }
}

},{}],33:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-05-30",
    "endpointPrefix": "cloudhsm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CloudHSM",
    "serviceFullName": "Amazon CloudHSM",
    "serviceId": "CloudHSM",
    "signatureVersion": "v4",
    "targetPrefix": "CloudHsmFrontendService",
    "uid": "cloudhsm-2014-05-30"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagList"
        ],
        "members": {
          "ResourceArn": {},
          "TagList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "CreateHapg": {
      "input": {
        "type": "structure",
        "required": [
          "Label"
        ],
        "members": {
          "Label": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {}
        }
      }
    },
    "CreateHsm": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "SshKey",
          "IamRoleArn",
          "SubscriptionType"
        ],
        "members": {
          "SubnetId": {
            "locationName": "SubnetId"
          },
          "SshKey": {
            "locationName": "SshKey"
          },
          "EniIp": {
            "locationName": "EniIp"
          },
          "IamRoleArn": {
            "locationName": "IamRoleArn"
          },
          "ExternalId": {
            "locationName": "ExternalId"
          },
          "SubscriptionType": {
            "locationName": "SubscriptionType"
          },
          "ClientToken": {
            "locationName": "ClientToken"
          },
          "SyslogIp": {
            "locationName": "SyslogIp"
          }
        },
        "locationName": "CreateHsmRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {}
        }
      }
    },
    "CreateLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "Certificate"
        ],
        "members": {
          "Label": {},
          "Certificate": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {}
        }
      }
    },
    "DeleteHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteHsm": {
      "input": {
        "type": "structure",
        "required": [
          "HsmArn"
        ],
        "members": {
          "HsmArn": {
            "locationName": "HsmArn"
          }
        },
        "locationName": "DeleteHsmRequest"
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn"
        ],
        "members": {
          "ClientArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DescribeHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {},
          "HapgSerial": {},
          "HsmsLastActionFailed": {
            "shape": "Sz"
          },
          "HsmsPendingDeletion": {
            "shape": "Sz"
          },
          "HsmsPendingRegistration": {
            "shape": "Sz"
          },
          "Label": {},
          "LastModifiedTimestamp": {},
          "PartitionSerialList": {
            "shape": "S11"
          },
          "State": {}
        }
      }
    },
    "DescribeHsm": {
      "input": {
        "type": "structure",
        "members": {
          "HsmArn": {},
          "HsmSerialNumber": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {},
          "Status": {},
          "StatusDetails": {},
          "AvailabilityZone": {},
          "EniId": {},
          "EniIp": {},
          "SubscriptionType": {},
          "SubscriptionStartDate": {},
          "SubscriptionEndDate": {},
          "VpcId": {},
          "SubnetId": {},
          "IamRoleArn": {},
          "SerialNumber": {},
          "VendorName": {},
          "HsmType": {},
          "SoftwareVersion": {},
          "SshPublicKey": {},
          "SshKeyLastUpdated": {},
          "ServerCertUri": {},
          "ServerCertLastUpdated": {},
          "Partitions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "DescribeLunaClient": {
      "input": {
        "type": "structure",
        "members": {
          "ClientArn": {},
          "CertificateFingerprint": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {},
          "Certificate": {},
          "CertificateFingerprint": {},
          "LastModifiedTimestamp": {},
          "Label": {}
        }
      }
    },
    "GetConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn",
          "ClientVersion",
          "HapgList"
        ],
        "members": {
          "ClientArn": {},
          "ClientVersion": {},
          "HapgList": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigType": {},
          "ConfigFile": {},
          "ConfigCred": {}
        }
      }
    },
    "ListAvailableZones": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AZList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListHapgs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HapgList"
        ],
        "members": {
          "HapgList": {
            "shape": "S1i"
          },
          "NextToken": {}
        }
      }
    },
    "ListHsms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmList": {
            "shape": "Sz"
          },
          "NextToken": {}
        }
      }
    },
    "ListLunaClients": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClientList"
        ],
        "members": {
          "ClientList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagList"
        ],
        "members": {
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "ModifyHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {},
          "Label": {},
          "PartitionSerialList": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {}
        }
      }
    },
    "ModifyHsm": {
      "input": {
        "type": "structure",
        "required": [
          "HsmArn"
        ],
        "members": {
          "HsmArn": {
            "locationName": "HsmArn"
          },
          "SubnetId": {
            "locationName": "SubnetId"
          },
          "EniIp": {
            "locationName": "EniIp"
          },
          "IamRoleArn": {
            "locationName": "IamRoleArn"
          },
          "ExternalId": {
            "locationName": "ExternalId"
          },
          "SyslogIp": {
            "locationName": "SyslogIp"
          }
        },
        "locationName": "ModifyHsmRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {}
        }
      }
    },
    "ModifyLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn",
          "Certificate"
        ],
        "members": {
          "ClientArn": {},
          "Certificate": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeyList"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeyList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S11": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],34:[function(require,module,exports){
module.exports={
  "pagination": {
  }
}

},{}],35:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-11-01",
    "endpointPrefix": "cloudtrail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CloudTrail",
    "serviceFullName": "AWS CloudTrail",
    "serviceId": "CloudTrail",
    "signatureVersion": "v4",
    "targetPrefix": "com.amazonaws.cloudtrail.v20131101.CloudTrail_20131101",
    "uid": "cloudtrail-2013-11-01"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "S3BucketName"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          },
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeTrails": {
      "input": {
        "type": "structure",
        "members": {
          "trailNameList": {
            "type": "list",
            "member": {}
          },
          "includeShadowTrails": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "trailList": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          }
        }
      },
      "idempotent": true
    },
    "GetEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "idempotent": true
    },
    "GetInsightSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "InsightSelectors": {
            "shape": "Sr"
          }
        }
      },
      "idempotent": true
    },
    "GetTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trail": {
            "shape": "Sf"
          }
        }
      },
      "idempotent": true
    },
    "GetTrailStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsLogging": {
            "type": "boolean"
          },
          "LatestDeliveryError": {},
          "LatestNotificationError": {},
          "LatestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestNotificationTime": {
            "type": "timestamp"
          },
          "StartLoggingTime": {
            "type": "timestamp"
          },
          "StopLoggingTime": {
            "type": "timestamp"
          },
          "LatestCloudWatchLogsDeliveryError": {},
          "LatestCloudWatchLogsDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryError": {},
          "LatestDeliveryAttemptTime": {},
          "LatestNotificationAttemptTime": {},
          "LatestNotificationAttemptSucceeded": {},
          "LatestDeliveryAttemptSucceeded": {},
          "TimeLoggingStarted": {},
          "TimeLoggingStopped": {}
        }
      },
      "idempotent": true
    },
    "ListPublicKeys": {
      "input": {
        "type": "structure",
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {
                  "type": "blob"
                },
                "ValidityStartTime": {
                  "type": "timestamp"
                },
                "ValidityEndTime": {
                  "type": "timestamp"
                },
                "Fingerprint": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIdList"
        ],
        "members": {
          "ResourceIdList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTagList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "TagsList": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTrails": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrailARN": {},
                "Name": {},
                "HomeRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "LookupEvents": {
      "input": {
        "type": "structure",
        "members": {
          "LookupAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AttributeKey",
                "AttributeValue"
              ],
              "members": {
                "AttributeKey": {},
                "AttributeValue": {}
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "EventCategory": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "EventName": {},
                "ReadOnly": {},
                "AccessKeyId": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "EventSource": {},
                "Username": {},
                "Resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ResourceType": {},
                      "ResourceName": {}
                    }
                  }
                },
                "CloudTrailEvent": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PutEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName",
          "EventSelectors"
        ],
        "members": {
          "TrailName": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "idempotent": true
    },
    "PutInsightSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName",
          "InsightSelectors"
        ],
        "members": {
          "TrailName": {},
          "InsightSelectors": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "InsightSelectors": {
            "shape": "Sr"
          }
        }
      },
      "idempotent": true
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StopLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "Name": {},
        "S3BucketName": {},
        "S3KeyPrefix": {},
        "SnsTopicName": {
          "deprecated": true
        },
        "SnsTopicARN": {},
        "IncludeGlobalServiceEvents": {
          "type": "boolean"
        },
        "IsMultiRegionTrail": {
          "type": "boolean"
        },
        "HomeRegion": {},
        "TrailARN": {},
        "LogFileValidationEnabled": {
          "type": "boolean"
        },
        "CloudWatchLogsLogGroupArn": {},
        "CloudWatchLogsRoleArn": {},
        "KmsKeyId": {},
        "HasCustomEventSelectors": {
          "type": "boolean"
        },
        "HasInsightSelectors": {
          "type": "boolean"
        },
        "IsOrganizationTrail": {
          "type": "boolean"
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ReadWriteType": {},
          "IncludeManagementEvents": {
            "type": "boolean"
          },
          "DataResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ExcludeManagementEventSources": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "InsightType": {}
        }
      }
    }
  }
}
},{}],36:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeTrails": {
      "result_key": "trailList"
    },
    "ListPublicKeys": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "PublicKeyList"
    },
    "ListTags": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ResourceTagList"
    },
    "ListTrails": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Trails"
    },
    "LookupEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Events"
    }
  }
}
},{}],37:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-10-06",
    "endpointPrefix": "codebuild",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS CodeBuild",
    "serviceId": "CodeBuild",
    "signatureVersion": "v4",
    "targetPrefix": "CodeBuild_20161006",
    "uid": "codebuild-2016-10-06"
  },
  "operations": {
    "BatchDeleteBuilds": {
      "input": {
        "type": "structure",
        "required": [
          "ids"
        ],
        "members": {
          "ids": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "buildsDeleted": {
            "shape": "S2"
          },
          "buildsNotDeleted": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "statusCode": {}
              }
            }
          }
        }
      }
    },
    "BatchGetBuilds": {
      "input": {
        "type": "structure",
        "required": [
          "ids"
        ],
        "members": {
          "ids": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "builds": {
            "type": "list",
            "member": {
              "shape": "Sb"
            }
          },
          "buildsNotFound": {
            "shape": "S2"
          }
        }
      }
    },
    "BatchGetProjects": {
      "input": {
        "type": "structure",
        "required": [
          "names"
        ],
        "members": {
          "names": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "projects": {
            "type": "list",
            "member": {
              "shape": "S1t"
            }
          },
          "projectsNotFound": {
            "shape": "S1q"
          }
        }
      }
    },
    "BatchGetReportGroups": {
      "input": {
        "type": "structure",
        "required": [
          "reportGroupArns"
        ],
        "members": {
          "reportGroupArns": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reportGroups": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "reportGroupsNotFound": {
            "shape": "S2e"
          }
        }
      }
    },
    "BatchGetReports": {
      "input": {
        "type": "structure",
        "required": [
          "reportArns"
        ],
        "members": {
          "reportArns": {
            "shape": "S2p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "type": {},
                "name": {},
                "reportGroupArn": {},
                "executionId": {},
                "status": {},
                "created": {
                  "type": "timestamp"
                },
                "expired": {
                  "type": "timestamp"
                },
                "exportConfig": {
                  "shape": "S2k"
                },
                "truncated": {
                  "type": "boolean"
                },
                "testSummary": {
                  "type": "structure",
                  "required": [
                    "total",
                    "statusCounts",
                    "durationInNanoSeconds"
                  ],
                  "members": {
                    "total": {
                      "type": "integer"
                    },
                    "statusCounts": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "integer"
                      }
                    },
                    "durationInNanoSeconds": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "reportsNotFound": {
            "shape": "S2p"
          }
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "source",
          "artifacts",
          "environment",
          "serviceRole"
        ],
        "members": {
          "name": {},
          "description": {},
          "source": {
            "shape": "Sk"
          },
          "secondarySources": {
            "shape": "Sr"
          },
          "sourceVersion": {},
          "secondarySourceVersions": {
            "shape": "Ss"
          },
          "artifacts": {
            "shape": "S1w"
          },
          "secondaryArtifacts": {
            "shape": "S20"
          },
          "cache": {
            "shape": "Sw"
          },
          "environment": {
            "shape": "S10"
          },
          "serviceRole": {},
          "timeoutInMinutes": {
            "type": "integer"
          },
          "queuedTimeoutInMinutes": {
            "type": "integer"
          },
          "encryptionKey": {},
          "tags": {
            "shape": "S22"
          },
          "vpcConfig": {
            "shape": "S1f"
          },
          "badgeEnabled": {
            "type": "boolean"
          },
          "logsConfig": {
            "shape": "S2c"
          },
          "fileSystemLocations": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "S1t"
          }
        }
      }
    },
    "CreateReportGroup": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "type",
          "exportConfig"
        ],
        "members": {
          "name": {},
          "type": {},
          "exportConfig": {
            "shape": "S2k"
          },
          "tags": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reportGroup": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "branchFilter": {},
          "filterGroups": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhook": {
            "shape": "S26"
          }
        }
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReport": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReportGroup": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSourceCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "DeleteWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeTestCases": {
      "input": {
        "type": "structure",
        "required": [
          "reportArn"
        ],
        "members": {
          "reportArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "status": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "testCases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "reportArn": {},
                "testRawDataPath": {},
                "prefix": {},
                "name": {},
                "status": {},
                "durationInNanoSeconds": {
                  "type": "long"
                },
                "message": {},
                "expired": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policy": {}
        }
      }
    },
    "ImportSourceCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "token",
          "serverType",
          "authType"
        ],
        "members": {
          "username": {},
          "token": {
            "type": "string",
            "sensitive": true
          },
          "serverType": {},
          "authType": {},
          "shouldOverwrite": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "InvalidateProjectCache": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListBuilds": {
      "input": {
        "type": "structure",
        "members": {
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S2"
          },
          "nextToken": {}
        }
      }
    },
    "ListBuildsForProject": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S2"
          },
          "nextToken": {}
        }
      }
    },
    "ListCuratedEnvironmentImages": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "platforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "platform": {},
                "languages": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "language": {},
                      "images": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "name": {},
                            "description": {},
                            "versions": {
                              "type": "list",
                              "member": {}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListProjects": {
      "input": {
        "type": "structure",
        "members": {
          "sortBy": {},
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "projects": {
            "shape": "S1q"
          }
        }
      }
    },
    "ListReportGroups": {
      "input": {
        "type": "structure",
        "members": {
          "sortOrder": {},
          "sortBy": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "reportGroups": {
            "shape": "S2e"
          }
        }
      }
    },
    "ListReports": {
      "input": {
        "type": "structure",
        "members": {
          "sortOrder": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "shape": "S4g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "reports": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListReportsForReportGroup": {
      "input": {
        "type": "structure",
        "required": [
          "reportGroupArn"
        ],
        "members": {
          "reportGroupArn": {},
          "nextToken": {},
          "sortOrder": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "shape": "S4g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "reports": {
            "shape": "S2p"
          }
        }
      }
    },
    "ListSharedProjects": {
      "input": {
        "type": "structure",
        "members": {
          "sortBy": {},
          "sortOrder": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "projects": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListSharedReportGroups": {
      "input": {
        "type": "structure",
        "members": {
          "sortOrder": {},
          "sortBy": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "reportGroups": {
            "shape": "S2e"
          }
        }
      }
    },
    "ListSourceCredentials": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceCredentialsInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "serverType": {},
                "authType": {}
              }
            }
          }
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policy",
          "resourceArn"
        ],
        "members": {
          "policy": {},
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {}
        }
      }
    },
    "StartBuild": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "secondarySourcesOverride": {
            "shape": "Sr"
          },
          "secondarySourcesVersionOverride": {
            "shape": "Ss"
          },
          "sourceVersion": {},
          "artifactsOverride": {
            "shape": "S1w"
          },
          "secondaryArtifactsOverride": {
            "shape": "S20"
          },
          "environmentVariablesOverride": {
            "shape": "S13"
          },
          "sourceTypeOverride": {},
          "sourceLocationOverride": {},
          "sourceAuthOverride": {
            "shape": "Sp"
          },
          "gitCloneDepthOverride": {
            "type": "integer"
          },
          "gitSubmodulesConfigOverride": {
            "shape": "Sn"
          },
          "buildspecOverride": {},
          "insecureSslOverride": {
            "type": "boolean"
          },
          "reportBuildStatusOverride": {
            "type": "boolean"
          },
          "environmentTypeOverride": {},
          "imageOverride": {},
          "computeTypeOverride": {},
          "certificateOverride": {},
          "cacheOverride": {
            "shape": "Sw"
          },
          "serviceRoleOverride": {},
          "privilegedModeOverride": {
            "type": "boolean"
          },
          "timeoutInMinutesOverride": {
            "type": "integer"
          },
          "queuedTimeoutInMinutesOverride": {
            "type": "integer"
          },
          "encryptionKeyOverride": {},
          "idempotencyToken": {},
          "logsConfigOverride": {
            "shape": "S2c"
          },
          "registryCredentialOverride": {
            "shape": "S16"
          },
          "imagePullCredentialsTypeOverride": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "build": {
            "shape": "Sb"
          }
        }
      }
    },
    "StopBuild": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "build": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "source": {
            "shape": "Sk"
          },
          "secondarySources": {
            "shape": "Sr"
          },
          "sourceVersion": {},
          "secondarySourceVersions": {
            "shape": "Ss"
          },
          "artifacts": {
            "shape": "S1w"
          },
          "secondaryArtifacts": {
            "shape": "S20"
          },
          "cache": {
            "shape": "Sw"
          },
          "environment": {
            "shape": "S10"
          },
          "serviceRole": {},
          "timeoutInMinutes": {
            "type": "integer"
          },
          "queuedTimeoutInMinutes": {
            "type": "integer"
          },
          "encryptionKey": {},
          "tags": {
            "shape": "S22"
          },
          "vpcConfig": {
            "shape": "S1f"
          },
          "badgeEnabled": {
            "type": "boolean"
          },
          "logsConfig": {
            "shape": "S2c"
          },
          "fileSystemLocations": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "S1t"
          }
        }
      }
    },
    "UpdateReportGroup": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "exportConfig": {
            "shape": "S2k"
          },
          "tags": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reportGroup": {
            "shape": "S2h"
          }
        }
      }
    },
    "UpdateWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "branchFilter": {},
          "rotateSecret": {
            "type": "boolean"
          },
          "filterGroups": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhook": {
            "shape": "S26"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "buildNumber": {
          "type": "long"
        },
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        },
        "currentPhase": {},
        "buildStatus": {},
        "sourceVersion": {},
        "resolvedSourceVersion": {},
        "projectName": {},
        "phases": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "phaseType": {},
              "phaseStatus": {},
              "startTime": {
                "type": "timestamp"
              },
              "endTime": {
                "type": "timestamp"
              },
              "durationInSeconds": {
                "type": "long"
              },
              "contexts": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "statusCode": {},
                    "message": {}
                  }
                }
              }
            }
          }
        },
        "source": {
          "shape": "Sk"
        },
        "secondarySources": {
          "shape": "Sr"
        },
        "secondarySourceVersions": {
          "shape": "Ss"
        },
        "artifacts": {
          "shape": "Su"
        },
        "secondaryArtifacts": {
          "type": "list",
          "member": {
            "shape": "Su"
          }
        },
        "cache": {
          "shape": "Sw"
        },
        "environment": {
          "shape": "S10"
        },
        "serviceRole": {},
        "logs": {
          "type": "structure",
          "members": {
            "groupName": {},
            "streamName": {},
            "deepLink": {},
            "s3DeepLink": {},
            "cloudWatchLogsArn": {},
            "s3LogsArn": {},
            "cloudWatchLogs": {
              "shape": "S1a"
            },
            "s3Logs": {
              "shape": "S1c"
            }
          }
        },
        "timeoutInMinutes": {
          "type": "integer"
        },
        "queuedTimeoutInMinutes": {
          "type": "integer"
        },
        "buildComplete": {
          "type": "boolean"
        },
        "initiator": {},
        "vpcConfig": {
          "shape": "S1f"
        },
        "networkInterface": {
          "type": "structure",
          "members": {
            "subnetId": {},
            "networkInterfaceId": {}
          }
        },
        "encryptionKey": {},
        "exportedEnvironmentVariables": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "value": {}
            }
          }
        },
        "reportArns": {
          "type": "list",
          "member": {}
        },
        "fileSystemLocations": {
          "shape": "S1m"
        }
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "gitCloneDepth": {
          "type": "integer"
        },
        "gitSubmodulesConfig": {
          "shape": "Sn"
        },
        "buildspec": {},
        "auth": {
          "shape": "Sp"
        },
        "reportBuildStatus": {
          "type": "boolean"
        },
        "insecureSsl": {
          "type": "boolean"
        },
        "sourceIdentifier": {}
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "fetchSubmodules"
      ],
      "members": {
        "fetchSubmodules": {
          "type": "boolean"
        }
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "resource": {}
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "shape": "Sk"
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "sourceIdentifier",
          "sourceVersion"
        ],
        "members": {
          "sourceIdentifier": {},
          "sourceVersion": {}
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "location": {},
        "sha256sum": {},
        "md5sum": {},
        "overrideArtifactName": {
          "type": "boolean"
        },
        "encryptionDisabled": {
          "type": "boolean"
        },
        "artifactIdentifier": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "modes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "type",
        "image",
        "computeType"
      ],
      "members": {
        "type": {},
        "image": {},
        "computeType": {},
        "environmentVariables": {
          "shape": "S13"
        },
        "privilegedMode": {
          "type": "boolean"
        },
        "certificate": {},
        "registryCredential": {
          "shape": "S16"
        },
        "imagePullCredentialsType": {}
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {},
          "type": {}
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "credential",
        "credentialProvider"
      ],
      "members": {
        "credential": {},
        "credentialProvider": {}
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "status"
      ],
      "members": {
        "status": {},
        "groupName": {},
        "streamName": {}
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "status"
      ],
      "members": {
        "status": {},
        "location": {},
        "encryptionDisabled": {
          "type": "boolean"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "vpcId": {},
        "subnets": {
          "type": "list",
          "member": {}
        },
        "securityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "location": {},
          "mountPoint": {},
          "identifier": {},
          "mountOptions": {}
        }
      }
    },
    "S1q": {
      "type": "list",
      "member": {}
    },
    "S1t": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "description": {},
        "source": {
          "shape": "Sk"
        },
        "secondarySources": {
          "shape": "Sr"
        },
        "sourceVersion": {},
        "secondarySourceVersions": {
          "shape": "Ss"
        },
        "artifacts": {
          "shape": "S1w"
        },
        "secondaryArtifacts": {
          "shape": "S20"
        },
        "cache": {
          "shape": "Sw"
        },
        "environment": {
          "shape": "S10"
        },
        "serviceRole": {},
        "timeoutInMinutes": {
          "type": "integer"
        },
        "queuedTimeoutInMinutes": {
          "type": "integer"
        },
        "encryptionKey": {},
        "tags": {
          "shape": "S22"
        },
        "created": {
          "type": "timestamp"
        },
        "lastModified": {
          "type": "timestamp"
        },
        "webhook": {
          "shape": "S26"
        },
        "vpcConfig": {
          "shape": "S1f"
        },
        "badge": {
          "type": "structure",
          "members": {
            "badgeEnabled": {
              "type": "boolean"
            },
            "badgeRequestUrl": {}
          }
        },
        "logsConfig": {
          "shape": "S2c"
        },
        "fileSystemLocations": {
          "shape": "S1m"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "path": {},
        "namespaceType": {},
        "name": {},
        "packaging": {},
        "overrideArtifactName": {
          "type": "boolean"
        },
        "encryptionDisabled": {
          "type": "boolean"
        },
        "artifactIdentifier": {}
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "shape": "S1w"
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "url": {},
        "payloadUrl": {},
        "secret": {},
        "branchFilter": {},
        "filterGroups": {
          "shape": "S27"
        },
        "lastModifiedSecret": {
          "type": "timestamp"
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "list",
        "member": {
          "type": "structure",
          "required": [
            "type",
            "pattern"
          ],
          "members": {
            "type": {},
            "pattern": {},
            "excludeMatchedPattern": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "cloudWatchLogs": {
          "shape": "S1a"
        },
        "s3Logs": {
          "shape": "S1c"
        }
      }
    },
    "S2e": {
      "type": "list",
      "member": {}
    },
    "S2h": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "exportConfig": {
          "shape": "S2k"
        },
        "created": {
          "type": "timestamp"
        },
        "lastModified": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S22"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "exportConfigType": {},
        "s3Destination": {
          "type": "structure",
          "members": {
            "bucket": {},
            "path": {},
            "packaging": {},
            "encryptionKey": {},
            "encryptionDisabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S2p": {
      "type": "list",
      "member": {}
    },
    "S4g": {
      "type": "structure",
      "members": {
        "status": {}
      }
    }
  }
}
},{}],38:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeTestCases": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "testCases"
    },
    "ListBuilds": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListBuildsForProject": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "ids"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListReportGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reportGroups"
    },
    "ListReports": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reports"
    },
    "ListReportsForReportGroup": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reports"
    },
    "ListSharedProjects": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListSharedReportGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "reportGroups"
    }
  }
}
},{}],39:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-04-13",
    "endpointPrefix": "codecommit",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CodeCommit",
    "serviceFullName": "AWS CodeCommit",
    "serviceId": "CodeCommit",
    "signatureVersion": "v4",
    "targetPrefix": "CodeCommit_20150413",
    "uid": "codecommit-2015-04-13"
  },
  "operations": {
    "AssociateApprovalRuleTemplateWithRepository": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "repositoryName"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "repositoryName": {}
        }
      }
    },
    "BatchAssociateApprovalRuleTemplateWithRepositories": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "repositoryNames"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "repositoryNames": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "associatedRepositoryNames",
          "errors"
        ],
        "members": {
          "associatedRepositoryNames": {
            "shape": "S5"
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "repositoryName": {},
                "errorCode": {},
                "errorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchDescribeMergeConflicts": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "destinationCommitSpecifier",
          "sourceCommitSpecifier",
          "mergeOption"
        ],
        "members": {
          "repositoryName": {},
          "destinationCommitSpecifier": {},
          "sourceCommitSpecifier": {},
          "mergeOption": {},
          "maxMergeHunks": {
            "type": "integer"
          },
          "maxConflictFiles": {
            "type": "integer"
          },
          "filePaths": {
            "type": "list",
            "member": {}
          },
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "conflicts",
          "destinationCommitId",
          "sourceCommitId"
        ],
        "members": {
          "conflicts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "conflictMetadata": {
                  "shape": "Sn"
                },
                "mergeHunks": {
                  "shape": "S12"
                }
              }
            }
          },
          "nextToken": {},
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "filePath",
                "exceptionName",
                "message"
              ],
              "members": {
                "filePath": {},
                "exceptionName": {},
                "message": {}
              }
            }
          },
          "destinationCommitId": {},
          "sourceCommitId": {},
          "baseCommitId": {}
        }
      }
    },
    "BatchDisassociateApprovalRuleTemplateFromRepositories": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "repositoryNames"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "repositoryNames": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "disassociatedRepositoryNames",
          "errors"
        ],
        "members": {
          "disassociatedRepositoryNames": {
            "shape": "S5"
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "repositoryName": {},
                "errorCode": {},
                "errorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetCommits": {
      "input": {
        "type": "structure",
        "required": [
          "commitIds",
          "repositoryName"
        ],
        "members": {
          "commitIds": {
            "type": "list",
            "member": {}
          },
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commits": {
            "type": "list",
            "member": {
              "shape": "S1l"
            }
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "commitId": {},
                "errorCode": {},
                "errorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetRepositories": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryNames"
        ],
        "members": {
          "repositoryNames": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "shape": "S1x"
            }
          },
          "repositoriesNotFound": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "CreateApprovalRuleTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "approvalRuleTemplateContent"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "approvalRuleTemplateContent": {},
          "approvalRuleTemplateDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplate"
        ],
        "members": {
          "approvalRuleTemplate": {
            "shape": "S2c"
          }
        }
      }
    },
    "CreateBranch": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName",
          "commitId"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {},
          "commitId": {}
        }
      }
    },
    "CreateCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {},
          "parentCommitId": {},
          "authorName": {},
          "email": {},
          "commitMessage": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "putFiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "filePath"
              ],
              "members": {
                "filePath": {},
                "fileMode": {},
                "fileContent": {
                  "type": "blob"
                },
                "sourceFile": {
                  "type": "structure",
                  "required": [
                    "filePath"
                  ],
                  "members": {
                    "filePath": {},
                    "isMove": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          },
          "deleteFiles": {
            "shape": "S2o"
          },
          "setFileModes": {
            "shape": "S2q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commitId": {},
          "treeId": {},
          "filesAdded": {
            "shape": "S2t"
          },
          "filesUpdated": {
            "shape": "S2t"
          },
          "filesDeleted": {
            "shape": "S2t"
          }
        }
      }
    },
    "CreatePullRequest": {
      "input": {
        "type": "structure",
        "required": [
          "title",
          "targets"
        ],
        "members": {
          "title": {},
          "description": {},
          "targets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "repositoryName",
                "sourceReference"
              ],
              "members": {
                "repositoryName": {},
                "sourceReference": {},
                "destinationReference": {}
              }
            }
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequest"
        ],
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "CreatePullRequestApprovalRule": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "approvalRuleName",
          "approvalRuleContent"
        ],
        "members": {
          "pullRequestId": {},
          "approvalRuleName": {},
          "approvalRuleContent": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRule"
        ],
        "members": {
          "approvalRule": {
            "shape": "S3c"
          }
        }
      }
    },
    "CreateRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "repositoryDescription": {},
          "tags": {
            "shape": "S3k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryMetadata": {
            "shape": "S1x"
          }
        }
      }
    },
    "CreateUnreferencedMergeCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier",
          "mergeOption"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "mergeOption": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "authorName": {},
          "email": {},
          "commitMessage": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "conflictResolution": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commitId": {},
          "treeId": {}
        }
      }
    },
    "DeleteApprovalRuleTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName"
        ],
        "members": {
          "approvalRuleTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateId"
        ],
        "members": {
          "approvalRuleTemplateId": {}
        }
      }
    },
    "DeleteBranch": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deletedBranch": {
            "shape": "S3y"
          }
        }
      }
    },
    "DeleteCommentContent": {
      "input": {
        "type": "structure",
        "required": [
          "commentId"
        ],
        "members": {
          "commentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "comment": {
            "shape": "S42"
          }
        }
      }
    },
    "DeleteFile": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName",
          "filePath",
          "parentCommitId"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {},
          "filePath": {},
          "parentCommitId": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "commitMessage": {},
          "name": {},
          "email": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commitId",
          "blobId",
          "treeId",
          "filePath"
        ],
        "members": {
          "commitId": {},
          "blobId": {},
          "treeId": {},
          "filePath": {}
        }
      }
    },
    "DeletePullRequestApprovalRule": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "approvalRuleName"
        ],
        "members": {
          "pullRequestId": {},
          "approvalRuleName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleId"
        ],
        "members": {
          "approvalRuleId": {}
        }
      }
    },
    "DeleteRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryId": {}
        }
      }
    },
    "DescribeMergeConflicts": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "destinationCommitSpecifier",
          "sourceCommitSpecifier",
          "mergeOption",
          "filePath"
        ],
        "members": {
          "repositoryName": {},
          "destinationCommitSpecifier": {},
          "sourceCommitSpecifier": {},
          "mergeOption": {},
          "maxMergeHunks": {
            "type": "integer"
          },
          "filePath": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "conflictMetadata",
          "mergeHunks",
          "destinationCommitId",
          "sourceCommitId"
        ],
        "members": {
          "conflictMetadata": {
            "shape": "Sn"
          },
          "mergeHunks": {
            "shape": "S12"
          },
          "nextToken": {},
          "destinationCommitId": {},
          "sourceCommitId": {},
          "baseCommitId": {}
        }
      }
    },
    "DescribePullRequestEvents": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId"
        ],
        "members": {
          "pullRequestId": {},
          "pullRequestEventType": {},
          "actorArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequestEvents"
        ],
        "members": {
          "pullRequestEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "pullRequestId": {},
                "eventDate": {
                  "type": "timestamp"
                },
                "pullRequestEventType": {},
                "actorArn": {},
                "pullRequestCreatedEventMetadata": {
                  "type": "structure",
                  "members": {
                    "repositoryName": {},
                    "sourceCommitId": {},
                    "destinationCommitId": {},
                    "mergeBase": {}
                  }
                },
                "pullRequestStatusChangedEventMetadata": {
                  "type": "structure",
                  "members": {
                    "pullRequestStatus": {}
                  }
                },
                "pullRequestSourceReferenceUpdatedEventMetadata": {
                  "type": "structure",
                  "members": {
                    "repositoryName": {},
                    "beforeCommitId": {},
                    "afterCommitId": {},
                    "mergeBase": {}
                  }
                },
                "pullRequestMergedStateChangedEventMetadata": {
                  "type": "structure",
                  "members": {
                    "repositoryName": {},
                    "destinationReference": {},
                    "mergeMetadata": {
                      "shape": "S38"
                    }
                  }
                },
                "approvalRuleEventMetadata": {
                  "type": "structure",
                  "members": {
                    "approvalRuleName": {},
                    "approvalRuleId": {},
                    "approvalRuleContent": {}
                  }
                },
                "approvalStateChangedEventMetadata": {
                  "type": "structure",
                  "members": {
                    "revisionId": {},
                    "approvalStatus": {}
                  }
                },
                "approvalRuleOverriddenEventMetadata": {
                  "type": "structure",
                  "members": {
                    "revisionId": {},
                    "overrideStatus": {}
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DisassociateApprovalRuleTemplateFromRepository": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "repositoryName"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "repositoryName": {}
        }
      }
    },
    "EvaluatePullRequestApprovalRules": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "revisionId"
        ],
        "members": {
          "pullRequestId": {},
          "revisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "evaluation"
        ],
        "members": {
          "evaluation": {
            "type": "structure",
            "members": {
              "approved": {
                "type": "boolean"
              },
              "overridden": {
                "type": "boolean"
              },
              "approvalRulesSatisfied": {
                "type": "list",
                "member": {}
              },
              "approvalRulesNotSatisfied": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "GetApprovalRuleTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName"
        ],
        "members": {
          "approvalRuleTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplate"
        ],
        "members": {
          "approvalRuleTemplate": {
            "shape": "S2c"
          }
        }
      }
    },
    "GetBlob": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "blobId"
        ],
        "members": {
          "repositoryName": {},
          "blobId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "content"
        ],
        "members": {
          "content": {
            "type": "blob"
          }
        }
      }
    },
    "GetBranch": {
      "input": {
        "type": "structure",
        "members": {
          "repositoryName": {},
          "branchName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "branch": {
            "shape": "S3y"
          }
        }
      }
    },
    "GetComment": {
      "input": {
        "type": "structure",
        "required": [
          "commentId"
        ],
        "members": {
          "commentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "comment": {
            "shape": "S42"
          }
        }
      }
    },
    "GetCommentsForComparedCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "afterCommitId"
        ],
        "members": {
          "repositoryName": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commentsForComparedCommitData": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "repositoryName": {},
                "beforeCommitId": {},
                "afterCommitId": {},
                "beforeBlobId": {},
                "afterBlobId": {},
                "location": {
                  "shape": "S5d"
                },
                "comments": {
                  "shape": "S5g"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetCommentsForPullRequest": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId"
        ],
        "members": {
          "pullRequestId": {},
          "repositoryName": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commentsForPullRequestData": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "pullRequestId": {},
                "repositoryName": {},
                "beforeCommitId": {},
                "afterCommitId": {},
                "beforeBlobId": {},
                "afterBlobId": {},
                "location": {
                  "shape": "S5d"
                },
                "comments": {
                  "shape": "S5g"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "commitId"
        ],
        "members": {
          "repositoryName": {},
          "commitId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commit"
        ],
        "members": {
          "commit": {
            "shape": "S1l"
          }
        }
      }
    },
    "GetDifferences": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "afterCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "beforeCommitSpecifier": {},
          "afterCommitSpecifier": {},
          "beforePath": {},
          "afterPath": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "differences": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "beforeBlob": {
                  "shape": "S5s"
                },
                "afterBlob": {
                  "shape": "S5s"
                },
                "changeType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetFile": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "filePath"
        ],
        "members": {
          "repositoryName": {},
          "commitSpecifier": {},
          "filePath": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commitId",
          "blobId",
          "filePath",
          "fileMode",
          "fileSize",
          "fileContent"
        ],
        "members": {
          "commitId": {},
          "blobId": {},
          "filePath": {},
          "fileMode": {},
          "fileSize": {
            "type": "long"
          },
          "fileContent": {
            "type": "blob"
          }
        }
      }
    },
    "GetFolder": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "folderPath"
        ],
        "members": {
          "repositoryName": {},
          "commitSpecifier": {},
          "folderPath": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commitId",
          "folderPath"
        ],
        "members": {
          "commitId": {},
          "folderPath": {},
          "treeId": {},
          "subFolders": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "treeId": {},
                "absolutePath": {},
                "relativePath": {}
              }
            }
          },
          "files": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "blobId": {},
                "absolutePath": {},
                "relativePath": {},
                "fileMode": {}
              }
            }
          },
          "symbolicLinks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "blobId": {},
                "absolutePath": {},
                "relativePath": {},
                "fileMode": {}
              }
            }
          },
          "subModules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "commitId": {},
                "absolutePath": {},
                "relativePath": {}
              }
            }
          }
        }
      }
    },
    "GetMergeCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceCommitId": {},
          "destinationCommitId": {},
          "baseCommitId": {},
          "mergedCommitId": {}
        }
      }
    },
    "GetMergeConflicts": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "destinationCommitSpecifier",
          "sourceCommitSpecifier",
          "mergeOption"
        ],
        "members": {
          "repositoryName": {},
          "destinationCommitSpecifier": {},
          "sourceCommitSpecifier": {},
          "mergeOption": {},
          "conflictDetailLevel": {},
          "maxConflictFiles": {
            "type": "integer"
          },
          "conflictResolutionStrategy": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mergeable",
          "destinationCommitId",
          "sourceCommitId",
          "conflictMetadataList"
        ],
        "members": {
          "mergeable": {
            "type": "boolean"
          },
          "destinationCommitId": {},
          "sourceCommitId": {},
          "baseCommitId": {},
          "conflictMetadataList": {
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetMergeOptions": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mergeOptions",
          "sourceCommitId",
          "destinationCommitId",
          "baseCommitId"
        ],
        "members": {
          "mergeOptions": {
            "type": "list",
            "member": {}
          },
          "sourceCommitId": {},
          "destinationCommitId": {},
          "baseCommitId": {}
        }
      }
    },
    "GetPullRequest": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId"
        ],
        "members": {
          "pullRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequest"
        ],
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "GetPullRequestApprovalStates": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "revisionId"
        ],
        "members": {
          "pullRequestId": {},
          "revisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "approvals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "userArn": {},
                "approvalState": {}
              }
            }
          }
        }
      }
    },
    "GetPullRequestOverrideState": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "revisionId"
        ],
        "members": {
          "pullRequestId": {},
          "revisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "overridden": {
            "type": "boolean"
          },
          "overrider": {}
        }
      }
    },
    "GetRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryMetadata": {
            "shape": "S1x"
          }
        }
      }
    },
    "GetRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationId": {},
          "triggers": {
            "shape": "S6t"
          }
        }
      }
    },
    "ListApprovalRuleTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "approvalRuleTemplateNames": {
            "shape": "S72"
          },
          "nextToken": {}
        }
      }
    },
    "ListAssociatedApprovalRuleTemplatesForRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "approvalRuleTemplateNames": {
            "shape": "S72"
          },
          "nextToken": {}
        }
      }
    },
    "ListBranches": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "branches": {
            "shape": "S6x"
          },
          "nextToken": {}
        }
      }
    },
    "ListPullRequests": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "authorArn": {},
          "pullRequestStatus": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequestIds"
        ],
        "members": {
          "pullRequestIds": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "sortBy": {},
          "order": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "repositoryName": {},
                "repositoryId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositoriesForApprovalRuleTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryNames": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S3k"
          },
          "nextToken": {}
        }
      }
    },
    "MergeBranchesByFastForward": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "targetBranch": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commitId": {},
          "treeId": {}
        }
      }
    },
    "MergeBranchesBySquash": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "targetBranch": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "authorName": {},
          "email": {},
          "commitMessage": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "conflictResolution": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commitId": {},
          "treeId": {}
        }
      }
    },
    "MergeBranchesByThreeWay": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "sourceCommitSpecifier",
          "destinationCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "sourceCommitSpecifier": {},
          "destinationCommitSpecifier": {},
          "targetBranch": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "authorName": {},
          "email": {},
          "commitMessage": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "conflictResolution": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "commitId": {},
          "treeId": {}
        }
      }
    },
    "MergePullRequestByFastForward": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "repositoryName"
        ],
        "members": {
          "pullRequestId": {},
          "repositoryName": {},
          "sourceCommitId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "MergePullRequestBySquash": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "repositoryName"
        ],
        "members": {
          "pullRequestId": {},
          "repositoryName": {},
          "sourceCommitId": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "commitMessage": {},
          "authorName": {},
          "email": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "conflictResolution": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "MergePullRequestByThreeWay": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "repositoryName"
        ],
        "members": {
          "pullRequestId": {},
          "repositoryName": {},
          "sourceCommitId": {},
          "conflictDetailLevel": {},
          "conflictResolutionStrategy": {},
          "commitMessage": {},
          "authorName": {},
          "email": {},
          "keepEmptyFolders": {
            "type": "boolean"
          },
          "conflictResolution": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "OverridePullRequestApprovalRules": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "revisionId",
          "overrideStatus"
        ],
        "members": {
          "pullRequestId": {},
          "revisionId": {},
          "overrideStatus": {}
        }
      }
    },
    "PostCommentForComparedCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "afterCommitId",
          "content"
        ],
        "members": {
          "repositoryName": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "location": {
            "shape": "S5d"
          },
          "content": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryName": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "beforeBlobId": {},
          "afterBlobId": {},
          "location": {
            "shape": "S5d"
          },
          "comment": {
            "shape": "S42"
          }
        }
      },
      "idempotent": true
    },
    "PostCommentForPullRequest": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "repositoryName",
          "beforeCommitId",
          "afterCommitId",
          "content"
        ],
        "members": {
          "pullRequestId": {},
          "repositoryName": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "location": {
            "shape": "S5d"
          },
          "content": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryName": {},
          "pullRequestId": {},
          "beforeCommitId": {},
          "afterCommitId": {},
          "beforeBlobId": {},
          "afterBlobId": {},
          "location": {
            "shape": "S5d"
          },
          "comment": {
            "shape": "S42"
          }
        }
      },
      "idempotent": true
    },
    "PostCommentReply": {
      "input": {
        "type": "structure",
        "required": [
          "inReplyTo",
          "content"
        ],
        "members": {
          "inReplyTo": {},
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "comment": {
            "shape": "S42"
          }
        }
      },
      "idempotent": true
    },
    "PutFile": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName",
          "fileContent",
          "filePath"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {},
          "fileContent": {
            "type": "blob"
          },
          "filePath": {},
          "fileMode": {},
          "parentCommitId": {},
          "commitMessage": {},
          "name": {},
          "email": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commitId",
          "blobId",
          "treeId"
        ],
        "members": {
          "commitId": {},
          "blobId": {},
          "treeId": {}
        }
      }
    },
    "PutRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "triggers"
        ],
        "members": {
          "repositoryName": {},
          "triggers": {
            "shape": "S6t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S3k"
          }
        }
      }
    },
    "TestRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "triggers"
        ],
        "members": {
          "repositoryName": {},
          "triggers": {
            "shape": "S6t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulExecutions": {
            "type": "list",
            "member": {}
          },
          "failedExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "trigger": {},
                "failureMessage": {}
              }
            }
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateApprovalRuleTemplateContent": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "newRuleContent"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "newRuleContent": {},
          "existingRuleContentSha256": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplate"
        ],
        "members": {
          "approvalRuleTemplate": {
            "shape": "S2c"
          }
        }
      }
    },
    "UpdateApprovalRuleTemplateDescription": {
      "input": {
        "type": "structure",
        "required": [
          "approvalRuleTemplateName",
          "approvalRuleTemplateDescription"
        ],
        "members": {
          "approvalRuleTemplateName": {},
          "approvalRuleTemplateDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplate"
        ],
        "members": {
          "approvalRuleTemplate": {
            "shape": "S2c"
          }
        }
      }
    },
    "UpdateApprovalRuleTemplateName": {
      "input": {
        "type": "structure",
        "required": [
          "oldApprovalRuleTemplateName",
          "newApprovalRuleTemplateName"
        ],
        "members": {
          "oldApprovalRuleTemplateName": {},
          "newApprovalRuleTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRuleTemplate"
        ],
        "members": {
          "approvalRuleTemplate": {
            "shape": "S2c"
          }
        }
      }
    },
    "UpdateComment": {
      "input": {
        "type": "structure",
        "required": [
          "commentId",
          "content"
        ],
        "members": {
          "commentId": {},
          "content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "comment": {
            "shape": "S42"
          }
        }
      }
    },
    "UpdateDefaultBranch": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "defaultBranchName"
        ],
        "members": {
          "repositoryName": {},
          "defaultBranchName": {}
        }
      }
    },
    "UpdatePullRequestApprovalRuleContent": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "approvalRuleName",
          "newRuleContent"
        ],
        "members": {
          "pullRequestId": {},
          "approvalRuleName": {},
          "existingRuleContentSha256": {},
          "newRuleContent": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "approvalRule"
        ],
        "members": {
          "approvalRule": {
            "shape": "S3c"
          }
        }
      }
    },
    "UpdatePullRequestApprovalState": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "revisionId",
          "approvalState"
        ],
        "members": {
          "pullRequestId": {},
          "revisionId": {},
          "approvalState": {}
        }
      }
    },
    "UpdatePullRequestDescription": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "description"
        ],
        "members": {
          "pullRequestId": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequest"
        ],
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "UpdatePullRequestStatus": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "pullRequestStatus"
        ],
        "members": {
          "pullRequestId": {},
          "pullRequestStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequest"
        ],
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "UpdatePullRequestTitle": {
      "input": {
        "type": "structure",
        "required": [
          "pullRequestId",
          "title"
        ],
        "members": {
          "pullRequestId": {},
          "title": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "pullRequest"
        ],
        "members": {
          "pullRequest": {
            "shape": "S33"
          }
        }
      }
    },
    "UpdateRepositoryDescription": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "repositoryDescription": {}
        }
      }
    },
    "UpdateRepositoryName": {
      "input": {
        "type": "structure",
        "required": [
          "oldName",
          "newName"
        ],
        "members": {
          "oldName": {},
          "newName": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "structure",
      "members": {
        "filePath": {},
        "fileSizes": {
          "type": "structure",
          "members": {
            "source": {
              "type": "long"
            },
            "destination": {
              "type": "long"
            },
            "base": {
              "type": "long"
            }
          }
        },
        "fileModes": {
          "type": "structure",
          "members": {
            "source": {},
            "destination": {},
            "base": {}
          }
        },
        "objectTypes": {
          "type": "structure",
          "members": {
            "source": {},
            "destination": {},
            "base": {}
          }
        },
        "numberOfConflicts": {
          "type": "integer"
        },
        "isBinaryFile": {
          "type": "structure",
          "members": {
            "source": {
              "type": "boolean"
            },
            "destination": {
              "type": "boolean"
            },
            "base": {
              "type": "boolean"
            }
          }
        },
        "contentConflict": {
          "type": "boolean"
        },
        "fileModeConflict": {
          "type": "boolean"
        },
        "objectTypeConflict": {
          "type": "boolean"
        },
        "mergeOperations": {
          "type": "structure",
          "members": {
            "source": {},
            "destination": {}
          }
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "isConflict": {
            "type": "boolean"
          },
          "source": {
            "shape": "S15"
          },
          "destination": {
            "shape": "S15"
          },
          "base": {
            "shape": "S15"
          }
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "startLine": {
          "type": "integer"
        },
        "endLine": {
          "type": "integer"
        },
        "hunkContent": {}
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "commitId": {},
        "treeId": {},
        "parents": {
          "type": "list",
          "member": {}
        },
        "message": {},
        "author": {
          "shape": "S1n"
        },
        "committer": {
          "shape": "S1n"
        },
        "additionalData": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "name": {},
        "email": {},
        "date": {}
      }
    },
    "S1x": {
      "type": "structure",
      "members": {
        "accountId": {},
        "repositoryId": {},
        "repositoryName": {},
        "repositoryDescription": {},
        "defaultBranch": {},
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        },
        "cloneUrlHttp": {},
        "cloneUrlSsh": {},
        "Arn": {}
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "approvalRuleTemplateId": {},
        "approvalRuleTemplateName": {},
        "approvalRuleTemplateDescription": {},
        "approvalRuleTemplateContent": {},
        "ruleContentSha256": {},
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedUser": {}
      }
    },
    "S2o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "filePath"
        ],
        "members": {
          "filePath": {}
        }
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "filePath",
          "fileMode"
        ],
        "members": {
          "filePath": {},
          "fileMode": {}
        }
      }
    },
    "S2t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "absolutePath": {},
          "blobId": {},
          "fileMode": {}
        }
      }
    },
    "S33": {
      "type": "structure",
      "members": {
        "pullRequestId": {},
        "title": {},
        "description": {},
        "lastActivityDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        },
        "pullRequestStatus": {},
        "authorArn": {},
        "pullRequestTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "repositoryName": {},
              "sourceReference": {},
              "destinationReference": {},
              "destinationCommit": {},
              "sourceCommit": {},
              "mergeBase": {},
              "mergeMetadata": {
                "shape": "S38"
              }
            }
          }
        },
        "clientRequestToken": {},
        "revisionId": {},
        "approvalRules": {
          "type": "list",
          "member": {
            "shape": "S3c"
          }
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "isMerged": {
          "type": "boolean"
        },
        "mergedBy": {},
        "mergeCommitId": {},
        "mergeOption": {}
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "approvalRuleId": {},
        "approvalRuleName": {},
        "approvalRuleContent": {},
        "ruleContentSha256": {},
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedUser": {},
        "originApprovalRuleTemplate": {
          "type": "structure",
          "members": {
            "approvalRuleTemplateId": {},
            "approvalRuleTemplateName": {}
          }
        }
      }
    },
    "S3k": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3p": {
      "type": "structure",
      "members": {
        "replaceContents": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "filePath",
              "replacementType"
            ],
            "members": {
              "filePath": {},
              "replacementType": {},
              "content": {
                "type": "blob"
              },
              "fileMode": {}
            }
          }
        },
        "deleteFiles": {
          "shape": "S2o"
        },
        "setFileModes": {
          "shape": "S2q"
        }
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "branchName": {},
        "commitId": {}
      }
    },
    "S42": {
      "type": "structure",
      "members": {
        "commentId": {},
        "content": {},
        "inReplyTo": {},
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "authorArn": {},
        "deleted": {
          "type": "boolean"
        },
        "clientRequestToken": {}
      }
    },
    "S5d": {
      "type": "structure",
      "members": {
        "filePath": {},
        "filePosition": {
          "type": "long"
        },
        "relativeFileVersion": {}
      }
    },
    "S5g": {
      "type": "list",
      "member": {
        "shape": "S42"
      }
    },
    "S5s": {
      "type": "structure",
      "members": {
        "blobId": {},
        "path": {},
        "mode": {}
      }
    },
    "S6t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "destinationArn",
          "events"
        ],
        "members": {
          "name": {},
          "destinationArn": {},
          "customData": {},
          "branches": {
            "shape": "S6x"
          },
          "events": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S6x": {
      "type": "list",
      "member": {}
    },
    "S72": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],40:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeMergeConflicts": {
      "input_token": "nextToken",
      "limit_key": "maxMergeHunks",
      "output_token": "nextToken"
    },
    "DescribePullRequestEvents": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetCommentsForComparedCommit": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetCommentsForPullRequest": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetDifferences": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetMergeConflicts": {
      "input_token": "nextToken",
      "limit_key": "maxConflictFiles",
      "output_token": "nextToken"
    },
    "ListApprovalRuleTemplates": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "ListAssociatedApprovalRuleTemplatesForRepository": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "ListBranches": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "branches"
    },
    "ListPullRequests": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "ListRepositories": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "repositories"
    },
    "ListRepositoriesForApprovalRuleTemplate": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    }
  }
}
},{}],41:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-10-06",
    "endpointPrefix": "codedeploy",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CodeDeploy",
    "serviceFullName": "AWS CodeDeploy",
    "serviceId": "CodeDeploy",
    "signatureVersion": "v4",
    "targetPrefix": "CodeDeploy_20141006",
    "uid": "codedeploy-2014-10-06"
  },
  "operations": {
    "AddTagsToOnPremisesInstances": {
      "input": {
        "type": "structure",
        "required": [
          "tags",
          "instanceNames"
        ],
        "members": {
          "tags": {
            "shape": "S2"
          },
          "instanceNames": {
            "shape": "S6"
          }
        }
      }
    },
    "BatchGetApplicationRevisions": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revisions"
        ],
        "members": {
          "applicationName": {},
          "revisions": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "errorMessage": {},
          "revisions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "revisionLocation": {
                  "shape": "Sb"
                },
                "genericRevisionInfo": {
                  "shape": "Su"
                }
              }
            }
          }
        }
      }
    },
    "BatchGetApplications": {
      "input": {
        "type": "structure",
        "required": [
          "applicationNames"
        ],
        "members": {
          "applicationNames": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationsInfo": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "BatchGetDeploymentGroups": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupNames"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupNames": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupsInfo": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          },
          "errorMessage": {}
        }
      }
    },
    "BatchGetDeploymentInstances": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId",
          "instanceIds"
        ],
        "members": {
          "deploymentId": {},
          "instanceIds": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instancesSummary": {
            "type": "list",
            "member": {
              "shape": "S35"
            }
          },
          "errorMessage": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This operation is deprecated, use BatchGetDeploymentTargets instead."
    },
    "BatchGetDeploymentTargets": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {},
          "targetIds": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentTargets": {
            "type": "list",
            "member": {
              "shape": "S3m"
            }
          }
        }
      }
    },
    "BatchGetDeployments": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentIds"
        ],
        "members": {
          "deploymentIds": {
            "shape": "S48"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentsInfo": {
            "type": "list",
            "member": {
              "shape": "S4b"
            }
          }
        }
      }
    },
    "BatchGetOnPremisesInstances": {
      "input": {
        "type": "structure",
        "required": [
          "instanceNames"
        ],
        "members": {
          "instanceNames": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceInfos": {
            "type": "list",
            "member": {
              "shape": "S4r"
            }
          }
        }
      }
    },
    "ContinueDeployment": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {},
          "deploymentWaitType": {}
        }
      }
    },
    "CreateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "computePlatform": {},
          "tags": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {}
        }
      }
    },
    "CreateDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "revision": {
            "shape": "Sb"
          },
          "deploymentConfigName": {},
          "description": {},
          "ignoreApplicationStopFailures": {
            "type": "boolean"
          },
          "targetInstances": {
            "shape": "S4i"
          },
          "autoRollbackConfiguration": {
            "shape": "S1z"
          },
          "updateOutdatedInstancesOnly": {
            "type": "boolean"
          },
          "fileExistsBehavior": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      }
    },
    "CreateDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {},
          "minimumHealthyHosts": {
            "shape": "S52"
          },
          "trafficRoutingConfig": {
            "shape": "S55"
          },
          "computePlatform": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigId": {}
        }
      }
    },
    "CreateDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName",
          "serviceRoleArn"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "deploymentConfigName": {},
          "ec2TagFilters": {
            "shape": "S1e"
          },
          "onPremisesInstanceTagFilters": {
            "shape": "S1h"
          },
          "autoScalingGroups": {
            "shape": "S4j"
          },
          "serviceRoleArn": {},
          "triggerConfigurations": {
            "shape": "S1p"
          },
          "alarmConfiguration": {
            "shape": "S1v"
          },
          "autoRollbackConfiguration": {
            "shape": "S1z"
          },
          "deploymentStyle": {
            "shape": "S22"
          },
          "blueGreenDeploymentConfiguration": {
            "shape": "S25"
          },
          "loadBalancerInfo": {
            "shape": "S2d"
          },
          "ec2TagSet": {
            "shape": "S2s"
          },
          "ecsServices": {
            "shape": "S2w"
          },
          "onPremisesTagSet": {
            "shape": "S2u"
          },
          "tags": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupId": {}
        }
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {}
        }
      }
    },
    "DeleteDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {}
        }
      }
    },
    "DeleteDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "hooksNotCleanedUp": {
            "shape": "S1k"
          }
        }
      }
    },
    "DeleteGitHubAccountToken": {
      "input": {
        "type": "structure",
        "members": {
          "tokenName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tokenName": {}
        }
      }
    },
    "DeleteResourcesByExternalId": {
      "input": {
        "type": "structure",
        "members": {
          "externalId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      }
    },
    "GetApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "application": {
            "shape": "S13"
          }
        }
      }
    },
    "GetApplicationRevision": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revision"
        ],
        "members": {
          "applicationName": {},
          "revision": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "revision": {
            "shape": "Sb"
          },
          "revisionInfo": {
            "shape": "Su"
          }
        }
      }
    },
    "GetDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentInfo": {
            "shape": "S4b"
          }
        }
      }
    },
    "GetDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigInfo": {
            "type": "structure",
            "members": {
              "deploymentConfigId": {},
              "deploymentConfigName": {},
              "minimumHealthyHosts": {
                "shape": "S52"
              },
              "createTime": {
                "type": "timestamp"
              },
              "computePlatform": {},
              "trafficRoutingConfig": {
                "shape": "S55"
              }
            }
          }
        }
      }
    },
    "GetDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupInfo": {
            "shape": "S1b"
          }
        }
      }
    },
    "GetDeploymentInstance": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId",
          "instanceId"
        ],
        "members": {
          "deploymentId": {},
          "instanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceSummary": {
            "shape": "S35"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This operation is deprecated, use GetDeploymentTarget instead."
    },
    "GetDeploymentTarget": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {},
          "targetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentTarget": {
            "shape": "S3m"
          }
        }
      }
    },
    "GetOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceInfo": {
            "shape": "S4r"
          }
        }
      }
    },
    "ListApplicationRevisions": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "sortBy": {},
          "sortOrder": {},
          "s3Bucket": {},
          "s3KeyPrefix": {},
          "deployed": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "revisions": {
            "shape": "Sa"
          },
          "nextToken": {}
        }
      }
    },
    "ListApplications": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applications": {
            "shape": "S10"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigsList": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentGroups": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "deploymentGroups": {
            "shape": "Sw"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentInstances": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {},
          "nextToken": {},
          "instanceStatusFilter": {
            "type": "list",
            "member": {
              "shape": "S36"
            }
          },
          "instanceTypeFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instancesList": {
            "shape": "S31"
          },
          "nextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This operation is deprecated, use ListDeploymentTargets instead."
    },
    "ListDeploymentTargets": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {},
          "nextToken": {},
          "targetFilters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "targetIds": {
            "shape": "S3i"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeployments": {
      "input": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "externalId": {},
          "includeOnlyStatuses": {
            "type": "list",
            "member": {}
          },
          "createTimeRange": {
            "type": "structure",
            "members": {
              "start": {
                "type": "timestamp"
              },
              "end": {
                "type": "timestamp"
              }
            }
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deployments": {
            "shape": "S48"
          },
          "nextToken": {}
        }
      }
    },
    "ListGitHubAccountTokenNames": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tokenNameList": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListOnPremisesInstances": {
      "input": {
        "type": "structure",
        "members": {
          "registrationStatus": {},
          "tagFilters": {
            "shape": "S1h"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceNames": {
            "shape": "S6"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      }
    },
    "PutLifecycleEventHookExecutionStatus": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {},
          "lifecycleEventHookExecutionId": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "lifecycleEventHookExecutionId": {}
        }
      }
    },
    "RegisterApplicationRevision": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revision"
        ],
        "members": {
          "applicationName": {},
          "description": {},
          "revision": {
            "shape": "Sb"
          }
        }
      }
    },
    "RegisterOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {},
          "iamSessionArn": {},
          "iamUserArn": {}
        }
      }
    },
    "RemoveTagsFromOnPremisesInstances": {
      "input": {
        "type": "structure",
        "required": [
          "tags",
          "instanceNames"
        ],
        "members": {
          "tags": {
            "shape": "S2"
          },
          "instanceNames": {
            "shape": "S6"
          }
        }
      }
    },
    "SkipWaitTimeForInstanceTermination": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This operation is deprecated, use ContinueDeployment with DeploymentWaitType instead."
    },
    "StopDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {},
          "autoRollbackEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusMessage": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "newApplicationName": {}
        }
      }
    },
    "UpdateDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "currentDeploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "currentDeploymentGroupName": {},
          "newDeploymentGroupName": {},
          "deploymentConfigName": {},
          "ec2TagFilters": {
            "shape": "S1e"
          },
          "onPremisesInstanceTagFilters": {
            "shape": "S1h"
          },
          "autoScalingGroups": {
            "shape": "S4j"
          },
          "serviceRoleArn": {},
          "triggerConfigurations": {
            "shape": "S1p"
          },
          "alarmConfiguration": {
            "shape": "S1v"
          },
          "autoRollbackConfiguration": {
            "shape": "S1z"
          },
          "deploymentStyle": {
            "shape": "S22"
          },
          "blueGreenDeploymentConfiguration": {
            "shape": "S25"
          },
          "loadBalancerInfo": {
            "shape": "S2d"
          },
          "ec2TagSet": {
            "shape": "S2s"
          },
          "ecsServices": {
            "shape": "S2w"
          },
          "onPremisesTagSet": {
            "shape": "S2u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "hooksNotCleanedUp": {
            "shape": "S1k"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {
        "shape": "Sb"
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "revisionType": {},
        "s3Location": {
          "type": "structure",
          "members": {
            "bucket": {},
            "key": {},
            "bundleType": {},
            "version": {},
            "eTag": {}
          }
        },
        "gitHubLocation": {
          "type": "structure",
          "members": {
            "repository": {},
            "commitId": {}
          }
        },
        "string": {
          "type": "structure",
          "members": {
            "content": {},
            "sha256": {}
          },
          "deprecated": true,
          "deprecatedMessage": "RawString and String revision type are deprecated, use AppSpecContent type instead."
        },
        "appSpecContent": {
          "type": "structure",
          "members": {
            "content": {},
            "sha256": {}
          }
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "description": {},
        "deploymentGroups": {
          "shape": "Sw"
        },
        "firstUsedTime": {
          "type": "timestamp"
        },
        "lastUsedTime": {
          "type": "timestamp"
        },
        "registerTime": {
          "type": "timestamp"
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {}
    },
    "S10": {
      "type": "list",
      "member": {}
    },
    "S13": {
      "type": "structure",
      "members": {
        "applicationId": {},
        "applicationName": {},
        "createTime": {
          "type": "timestamp"
        },
        "linkedToGitHub": {
          "type": "boolean"
        },
        "gitHubAccountName": {},
        "computePlatform": {}
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "applicationName": {},
        "deploymentGroupId": {},
        "deploymentGroupName": {},
        "deploymentConfigName": {},
        "ec2TagFilters": {
          "shape": "S1e"
        },
        "onPremisesInstanceTagFilters": {
          "shape": "S1h"
        },
        "autoScalingGroups": {
          "shape": "S1k"
        },
        "serviceRoleArn": {},
        "targetRevision": {
          "shape": "Sb"
        },
        "triggerConfigurations": {
          "shape": "S1p"
        },
        "alarmConfiguration": {
          "shape": "S1v"
        },
        "autoRollbackConfiguration": {
          "shape": "S1z"
        },
        "deploymentStyle": {
          "shape": "S22"
        },
        "blueGreenDeploymentConfiguration": {
          "shape": "S25"
        },
        "loadBalancerInfo": {
          "shape": "S2d"
        },
        "lastSuccessfulDeployment": {
          "shape": "S2p"
        },
        "lastAttemptedDeployment": {
          "shape": "S2p"
        },
        "ec2TagSet": {
          "shape": "S2s"
        },
        "onPremisesTagSet": {
          "shape": "S2u"
        },
        "computePlatform": {},
        "ecsServices": {
          "shape": "S2w"
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Type": {}
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Type": {}
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "hook": {}
        }
      }
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "triggerName": {},
          "triggerTargetArn": {},
          "triggerEvents": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "ignorePollAlarmFailure": {
          "type": "boolean"
        },
        "alarms": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {}
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "events": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "deploymentType": {},
        "deploymentOption": {}
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "terminateBlueInstancesOnDeploymentSuccess": {
          "type": "structure",
          "members": {
            "action": {},
            "terminationWaitTimeInMinutes": {
              "type": "integer"
            }
          }
        },
        "deploymentReadyOption": {
          "type": "structure",
          "members": {
            "actionOnTimeout": {},
            "waitTimeInMinutes": {
              "type": "integer"
            }
          }
        },
        "greenFleetProvisioningOption": {
          "type": "structure",
          "members": {
            "action": {}
          }
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "elbInfoList": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {}
            }
          }
        },
        "targetGroupInfoList": {
          "shape": "S2h"
        },
        "targetGroupPairInfoList": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "targetGroups": {
                "shape": "S2h"
              },
              "prodTrafficRoute": {
                "shape": "S2m"
              },
              "testTrafficRoute": {
                "shape": "S2m"
              }
            }
          }
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "shape": "S2i"
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "name": {}
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "listenerArns": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2p": {
      "type": "structure",
      "members": {
        "deploymentId": {},
        "status": {},
        "endTime": {
          "type": "timestamp"
        },
        "createTime": {
          "type": "timestamp"
        }
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "ec2TagSetList": {
          "type": "list",
          "member": {
            "shape": "S1e"
          }
        }
      }
    },
    "S2u": {
      "type": "structure",
      "members": {
        "onPremisesTagSetList": {
          "type": "list",
          "member": {
            "shape": "S1h"
          }
        }
      }
    },
    "S2w": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "serviceName": {},
          "clusterName": {}
        }
      }
    },
    "S31": {
      "type": "list",
      "member": {}
    },
    "S35": {
      "type": "structure",
      "members": {
        "deploymentId": {},
        "instanceId": {},
        "status": {
          "shape": "S36"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "lifecycleEvents": {
          "shape": "S37"
        },
        "instanceType": {}
      },
      "deprecated": true,
      "deprecatedMessage": "InstanceSummary is deprecated, use DeploymentTarget instead."
    },
    "S36": {
      "type": "string",
      "deprecated": true,
      "deprecatedMessage": "InstanceStatus is deprecated, use TargetStatus instead."
    },
    "S37": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "lifecycleEventName": {},
          "diagnostics": {
            "type": "structure",
            "members": {
              "errorCode": {},
              "scriptName": {},
              "message": {},
              "logTail": {}
            }
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "status": {}
        }
      }
    },
    "S3i": {
      "type": "list",
      "member": {}
    },
    "S3m": {
      "type": "structure",
      "members": {
        "deploymentTargetType": {},
        "instanceTarget": {
          "type": "structure",
          "members": {
            "deploymentId": {},
            "targetId": {},
            "targetArn": {},
            "status": {},
            "lastUpdatedAt": {
              "type": "timestamp"
            },
            "lifecycleEvents": {
              "shape": "S37"
            },
            "instanceLabel": {}
          }
        },
        "lambdaTarget": {
          "type": "structure",
          "members": {
            "deploymentId": {},
            "targetId": {},
            "targetArn": {},
            "status": {},
            "lastUpdatedAt": {
              "type": "timestamp"
            },
            "lifecycleEvents": {
              "shape": "S37"
            },
            "lambdaFunctionInfo": {
              "type": "structure",
              "members": {
                "functionName": {},
                "functionAlias": {},
                "currentVersion": {},
                "targetVersion": {},
                "targetVersionWeight": {
                  "type": "double"
                }
              }
            }
          }
        },
        "ecsTarget": {
          "type": "structure",
          "members": {
            "deploymentId": {},
            "targetId": {},
            "targetArn": {},
            "lastUpdatedAt": {
              "type": "timestamp"
            },
            "lifecycleEvents": {
              "shape": "S37"
            },
            "status": {},
            "taskSetsInfo": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "identifer": {},
                  "desiredCount": {
                    "type": "long"
                  },
                  "pendingCount": {
                    "type": "long"
                  },
                  "runningCount": {
                    "type": "long"
                  },
                  "status": {},
                  "trafficWeight": {
                    "type": "double"
                  },
                  "targetGroup": {
                    "shape": "S2i"
                  },
                  "taskSetLabel": {}
                }
              }
            }
          }
        },
        "cloudFormationTarget": {
          "type": "structure",
          "members": {
            "deploymentId": {},
            "targetId": {},
            "lastUpdatedAt": {
              "type": "timestamp"
            },
            "lifecycleEvents": {
              "shape": "S37"
            },
            "status": {},
            "resourceType": {},
            "targetVersionWeight": {
              "type": "double"
            }
          }
        }
      }
    },
    "S48": {
      "type": "list",
      "member": {}
    },
    "S4b": {
      "type": "structure",
      "members": {
        "applicationName": {},
        "deploymentGroupName": {},
        "deploymentConfigName": {},
        "deploymentId": {},
        "previousRevision": {
          "shape": "Sb"
        },
        "revision": {
          "shape": "Sb"
        },
        "status": {},
        "errorInformation": {
          "type": "structure",
          "members": {
            "code": {},
            "message": {}
          }
        },
        "createTime": {
          "type": "timestamp"
        },
        "startTime": {
          "type": "timestamp"
        },
        "completeTime": {
          "type": "timestamp"
        },
        "deploymentOverview": {
          "type": "structure",
          "members": {
            "Pending": {
              "type": "long"
            },
            "InProgress": {
              "type": "long"
            },
            "Succeeded": {
              "type": "long"
            },
            "Failed": {
              "type": "long"
            },
            "Skipped": {
              "type": "long"
            },
            "Ready": {
              "type": "long"
            }
          }
        },
        "description": {},
        "creator": {},
        "ignoreApplicationStopFailures": {
          "type": "boolean"
        },
        "autoRollbackConfiguration": {
          "shape": "S1z"
        },
        "updateOutdatedInstancesOnly": {
          "type": "boolean"
        },
        "rollbackInfo": {
          "type": "structure",
          "members": {
            "rollbackDeploymentId": {},
            "rollbackTriggeringDeploymentId": {},
            "rollbackMessage": {}
          }
        },
        "deploymentStyle": {
          "shape": "S22"
        },
        "targetInstances": {
          "shape": "S4i"
        },
        "instanceTerminationWaitTimeStarted": {
          "type": "boolean"
        },
        "blueGreenDeploymentConfiguration": {
          "shape": "S25"
        },
        "loadBalancerInfo": {
          "shape": "S2d"
        },
        "additionalDeploymentStatusInfo": {
          "type": "string",
          "deprecated": true,
          "deprecatedMessage": "AdditionalDeploymentStatusInfo is deprecated, use DeploymentStatusMessageList instead."
        },
        "fileExistsBehavior": {},
        "deploymentStatusMessages": {
          "type": "list",
          "member": {}
        },
        "computePlatform": {},
        "externalId": {}
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "tagFilters": {
          "shape": "S1e"
        },
        "autoScalingGroups": {
          "shape": "S4j"
        },
        "ec2TagSet": {
          "shape": "S2s"
        }
      }
    },
    "S4j": {
      "type": "list",
      "member": {}
    },
    "S4r": {
      "type": "structure",
      "members": {
        "instanceName": {},
        "iamSessionArn": {},
        "iamUserArn": {},
        "instanceArn": {},
        "registerTime": {
          "type": "timestamp"
        },
        "deregisterTime": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S2"
        }
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "value": {
          "type": "integer"
        },
        "type": {}
      }
    },
    "S55": {
      "type": "structure",
      "members": {
        "type": {},
        "timeBasedCanary": {
          "type": "structure",
          "members": {
            "canaryPercentage": {
              "type": "integer"
            },
            "canaryInterval": {
              "type": "integer"
            }
          }
        },
        "timeBasedLinear": {
          "type": "structure",
          "members": {
            "linearPercentage": {
              "type": "integer"
            },
            "linearInterval": {
              "type": "integer"
            }
          }
        }
      }
    }
  }
}
},{}],42:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListApplicationRevisions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "revisions"
    },
    "ListApplications": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "applications"
    },
    "ListDeploymentConfigs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deploymentConfigsList"
    },
    "ListDeploymentGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deploymentGroups"
    },
    "ListDeploymentInstances": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "instancesList"
    },
    "ListDeployments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "deployments"
    }
  }
}
},{}],43:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DeploymentSuccessful": {
      "delay": 15,
      "operation": "GetDeployment",
      "maxAttempts": 120,
      "acceptors": [
        {
          "expected": "Succeeded",
          "matcher": "path",
          "state": "success",
          "argument": "deploymentInfo.status"
        },
        {
          "expected": "Failed",
          "matcher": "path",
          "state": "failure",
          "argument": "deploymentInfo.status"
        },
        {
          "expected": "Stopped",
          "matcher": "path",
          "state": "failure",
          "argument": "deploymentInfo.status"
        }
      ]
    }
  }
}

},{}],44:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-07-09",
    "endpointPrefix": "codepipeline",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CodePipeline",
    "serviceFullName": "AWS CodePipeline",
    "serviceId": "CodePipeline",
    "signatureVersion": "v4",
    "targetPrefix": "CodePipeline_20150709",
    "uid": "codepipeline-2015-07-09"
  },
  "operations": {
    "AcknowledgeJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "nonce"
        ],
        "members": {
          "jobId": {},
          "nonce": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {}
        }
      }
    },
    "AcknowledgeThirdPartyJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "nonce",
          "clientToken"
        ],
        "members": {
          "jobId": {},
          "nonce": {},
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {}
        }
      }
    },
    "CreateCustomActionType": {
      "input": {
        "type": "structure",
        "required": [
          "category",
          "provider",
          "version",
          "inputArtifactDetails",
          "outputArtifactDetails"
        ],
        "members": {
          "category": {},
          "provider": {},
          "version": {},
          "settings": {
            "shape": "Se"
          },
          "configurationProperties": {
            "shape": "Sh"
          },
          "inputArtifactDetails": {
            "shape": "Sn"
          },
          "outputArtifactDetails": {
            "shape": "Sn"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "actionType"
        ],
        "members": {
          "actionType": {
            "shape": "Sv"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      }
    },
    "CreatePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "pipeline"
        ],
        "members": {
          "pipeline": {
            "shape": "Sz"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipeline": {
            "shape": "Sz"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      }
    },
    "DeleteCustomActionType": {
      "input": {
        "type": "structure",
        "required": [
          "category",
          "provider",
          "version"
        ],
        "members": {
          "category": {},
          "provider": {},
          "version": {}
        }
      }
    },
    "DeletePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      }
    },
    "DeleteWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterWebhookWithThirdParty": {
      "input": {
        "type": "structure",
        "members": {
          "webhookName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableStageTransition": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "stageName",
          "transitionType",
          "reason"
        ],
        "members": {
          "pipelineName": {},
          "stageName": {},
          "transitionType": {},
          "reason": {}
        }
      }
    },
    "EnableStageTransition": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "stageName",
          "transitionType"
        ],
        "members": {
          "pipelineName": {},
          "stageName": {},
          "transitionType": {}
        }
      }
    },
    "GetJobDetails": {
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobDetails": {
            "type": "structure",
            "members": {
              "id": {},
              "data": {
                "shape": "S29"
              },
              "accountId": {}
            }
          }
        }
      }
    },
    "GetPipeline": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "version": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipeline": {
            "shape": "Sz"
          },
          "metadata": {
            "type": "structure",
            "members": {
              "pipelineArn": {},
              "created": {
                "type": "timestamp"
              },
              "updated": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "pipelineExecutionId"
        ],
        "members": {
          "pipelineName": {},
          "pipelineExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineExecution": {
            "type": "structure",
            "members": {
              "pipelineName": {},
              "pipelineVersion": {
                "type": "integer"
              },
              "pipelineExecutionId": {},
              "status": {},
              "artifactRevisions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "name": {},
                    "revisionId": {},
                    "revisionChangeIdentifier": {},
                    "revisionSummary": {},
                    "created": {
                      "type": "timestamp"
                    },
                    "revisionUrl": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetPipelineState": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineName": {},
          "pipelineVersion": {
            "type": "integer"
          },
          "stageStates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "stageName": {},
                "inboundTransitionState": {
                  "type": "structure",
                  "members": {
                    "enabled": {
                      "type": "boolean"
                    },
                    "lastChangedBy": {},
                    "lastChangedAt": {
                      "type": "timestamp"
                    },
                    "disabledReason": {}
                  }
                },
                "actionStates": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "actionName": {},
                      "currentRevision": {
                        "shape": "S3h"
                      },
                      "latestExecution": {
                        "type": "structure",
                        "members": {
                          "status": {},
                          "summary": {},
                          "lastStatusChange": {
                            "type": "timestamp"
                          },
                          "token": {},
                          "lastUpdatedBy": {},
                          "externalExecutionId": {},
                          "externalExecutionUrl": {},
                          "percentComplete": {
                            "type": "integer"
                          },
                          "errorDetails": {
                            "type": "structure",
                            "members": {
                              "code": {},
                              "message": {}
                            }
                          }
                        }
                      },
                      "entityUrl": {},
                      "revisionUrl": {}
                    }
                  }
                },
                "latestExecution": {
                  "type": "structure",
                  "required": [
                    "pipelineExecutionId",
                    "status"
                  ],
                  "members": {
                    "pipelineExecutionId": {},
                    "status": {}
                  }
                }
              }
            }
          },
          "created": {
            "type": "timestamp"
          },
          "updated": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetThirdPartyJobDetails": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "clientToken"
        ],
        "members": {
          "jobId": {},
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobDetails": {
            "type": "structure",
            "members": {
              "id": {},
              "data": {
                "type": "structure",
                "members": {
                  "actionTypeId": {
                    "shape": "Sw"
                  },
                  "actionConfiguration": {
                    "shape": "S2a"
                  },
                  "pipelineContext": {
                    "shape": "S2b"
                  },
                  "inputArtifacts": {
                    "shape": "S2h"
                  },
                  "outputArtifacts": {
                    "shape": "S2h"
                  },
                  "artifactCredentials": {
                    "shape": "S2p"
                  },
                  "continuationToken": {},
                  "encryptionKey": {
                    "shape": "S15"
                  }
                }
              },
              "nonce": {}
            }
          }
        }
      }
    },
    "ListActionExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName"
        ],
        "members": {
          "pipelineName": {},
          "filter": {
            "type": "structure",
            "members": {
              "pipelineExecutionId": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionExecutionDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "pipelineExecutionId": {},
                "actionExecutionId": {},
                "pipelineVersion": {
                  "type": "integer"
                },
                "stageName": {},
                "actionName": {},
                "startTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "status": {},
                "input": {
                  "type": "structure",
                  "members": {
                    "actionTypeId": {
                      "shape": "Sw"
                    },
                    "configuration": {
                      "shape": "S1l"
                    },
                    "resolvedConfiguration": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    },
                    "roleArn": {},
                    "region": {},
                    "inputArtifacts": {
                      "shape": "S48"
                    },
                    "namespace": {}
                  }
                },
                "output": {
                  "type": "structure",
                  "members": {
                    "outputArtifacts": {
                      "shape": "S48"
                    },
                    "executionResult": {
                      "type": "structure",
                      "members": {
                        "externalExecutionId": {},
                        "externalExecutionSummary": {},
                        "externalExecutionUrl": {}
                      }
                    },
                    "outputVariables": {
                      "shape": "S4h"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListActionTypes": {
      "input": {
        "type": "structure",
        "members": {
          "actionOwnerFilter": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "actionTypes"
        ],
        "members": {
          "actionTypes": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPipelineExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName"
        ],
        "members": {
          "pipelineName": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineExecutionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "pipelineExecutionId": {},
                "status": {},
                "startTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "sourceRevisions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "actionName"
                    ],
                    "members": {
                      "actionName": {},
                      "revisionId": {},
                      "revisionSummary": {},
                      "revisionUrl": {}
                    }
                  }
                },
                "trigger": {
                  "type": "structure",
                  "members": {
                    "triggerType": {},
                    "triggerDetail": {}
                  }
                },
                "stopTrigger": {
                  "type": "structure",
                  "members": {
                    "reason": {}
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPipelines": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelines": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "version": {
                  "type": "integer"
                },
                "created": {
                  "type": "timestamp"
                },
                "updated": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sq"
          },
          "nextToken": {}
        }
      }
    },
    "ListWebhooks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhooks": {
            "type": "list",
            "member": {
              "shape": "S58"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PollForJobs": {
      "input": {
        "type": "structure",
        "required": [
          "actionTypeId"
        ],
        "members": {
          "actionTypeId": {
            "shape": "Sw"
          },
          "maxBatchSize": {
            "type": "integer"
          },
          "queryParam": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "data": {
                  "shape": "S29"
                },
                "nonce": {},
                "accountId": {}
              }
            }
          }
        }
      }
    },
    "PollForThirdPartyJobs": {
      "input": {
        "type": "structure",
        "required": [
          "actionTypeId"
        ],
        "members": {
          "actionTypeId": {
            "shape": "Sw"
          },
          "maxBatchSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "clientId": {},
                "jobId": {}
              }
            }
          }
        }
      }
    },
    "PutActionRevision": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "stageName",
          "actionName",
          "actionRevision"
        ],
        "members": {
          "pipelineName": {},
          "stageName": {},
          "actionName": {},
          "actionRevision": {
            "shape": "S3h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "newRevision": {
            "type": "boolean"
          },
          "pipelineExecutionId": {}
        }
      }
    },
    "PutApprovalResult": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "stageName",
          "actionName",
          "result",
          "token"
        ],
        "members": {
          "pipelineName": {},
          "stageName": {},
          "actionName": {},
          "result": {
            "type": "structure",
            "required": [
              "summary",
              "status"
            ],
            "members": {
              "summary": {},
              "status": {}
            }
          },
          "token": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "approvedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "PutJobFailureResult": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "failureDetails"
        ],
        "members": {
          "jobId": {},
          "failureDetails": {
            "shape": "S68"
          }
        }
      }
    },
    "PutJobSuccessResult": {
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {},
          "currentRevision": {
            "shape": "S6b"
          },
          "continuationToken": {},
          "executionDetails": {
            "shape": "S6d"
          },
          "outputVariables": {
            "shape": "S4h"
          }
        }
      }
    },
    "PutThirdPartyJobFailureResult": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "clientToken",
          "failureDetails"
        ],
        "members": {
          "jobId": {},
          "clientToken": {},
          "failureDetails": {
            "shape": "S68"
          }
        }
      }
    },
    "PutThirdPartyJobSuccessResult": {
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "clientToken"
        ],
        "members": {
          "jobId": {},
          "clientToken": {},
          "currentRevision": {
            "shape": "S6b"
          },
          "continuationToken": {},
          "executionDetails": {
            "shape": "S6d"
          }
        }
      }
    },
    "PutWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "webhook"
        ],
        "members": {
          "webhook": {
            "shape": "S59"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhook": {
            "shape": "S58"
          }
        }
      }
    },
    "RegisterWebhookWithThirdParty": {
      "input": {
        "type": "structure",
        "members": {
          "webhookName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RetryStageExecution": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "stageName",
          "pipelineExecutionId",
          "retryMode"
        ],
        "members": {
          "pipelineName": {},
          "stageName": {},
          "pipelineExecutionId": {},
          "retryMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineExecutionId": {}
        }
      }
    },
    "StartPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineExecutionId": {}
        }
      }
    },
    "StopPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "pipelineExecutionId"
        ],
        "members": {
          "pipelineName": {},
          "pipelineExecutionId": {},
          "abandon": {
            "type": "boolean"
          },
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineExecutionId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "pipeline"
        ],
        "members": {
          "pipeline": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipeline": {
            "shape": "Sz"
          }
        }
      }
    }
  },
  "shapes": {
    "Se": {
      "type": "structure",
      "members": {
        "thirdPartyConfigurationUrl": {},
        "entityUrlTemplate": {},
        "executionUrlTemplate": {},
        "revisionUrlTemplate": {}
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "required",
          "key",
          "secret"
        ],
        "members": {
          "name": {},
          "required": {
            "type": "boolean"
          },
          "key": {
            "type": "boolean"
          },
          "secret": {
            "type": "boolean"
          },
          "queryable": {
            "type": "boolean"
          },
          "description": {},
          "type": {}
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "minimumCount",
        "maximumCount"
      ],
      "members": {
        "minimumCount": {
          "type": "integer"
        },
        "maximumCount": {
          "type": "integer"
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "id",
        "inputArtifactDetails",
        "outputArtifactDetails"
      ],
      "members": {
        "id": {
          "shape": "Sw"
        },
        "settings": {
          "shape": "Se"
        },
        "actionConfigurationProperties": {
          "shape": "Sh"
        },
        "inputArtifactDetails": {
          "shape": "Sn"
        },
        "outputArtifactDetails": {
          "shape": "Sn"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "category",
        "owner",
        "provider",
        "version"
      ],
      "members": {
        "category": {},
        "owner": {},
        "provider": {},
        "version": {}
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "name",
        "roleArn",
        "stages"
      ],
      "members": {
        "name": {},
        "roleArn": {},
        "artifactStore": {
          "shape": "S12"
        },
        "artifactStores": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S12"
          }
        },
        "stages": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "name",
              "actions"
            ],
            "members": {
              "name": {},
              "blockers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "name",
                    "type"
                  ],
                  "members": {
                    "name": {},
                    "type": {}
                  }
                }
              },
              "actions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "name",
                    "actionTypeId"
                  ],
                  "members": {
                    "name": {},
                    "actionTypeId": {
                      "shape": "Sw"
                    },
                    "runOrder": {
                      "type": "integer"
                    },
                    "configuration": {
                      "shape": "S1l"
                    },
                    "outputArtifacts": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "name"
                        ],
                        "members": {
                          "name": {}
                        }
                      }
                    },
                    "inputArtifacts": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "name"
                        ],
                        "members": {
                          "name": {}
                        }
                      }
                    },
                    "roleArn": {},
                    "region": {},
                    "namespace": {}
                  }
                }
              }
            }
          }
        },
        "version": {
          "type": "integer"
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "type",
        "location"
      ],
      "members": {
        "type": {},
        "location": {},
        "encryptionKey": {
          "shape": "S15"
        }
      }
    },
    "S15": {
      "type": "structure",
      "required": [
        "id",
        "type"
      ],
      "members": {
        "id": {},
        "type": {}
      }
    },
    "S1l": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S29": {
      "type": "structure",
      "members": {
        "actionTypeId": {
          "shape": "Sw"
        },
        "actionConfiguration": {
          "shape": "S2a"
        },
        "pipelineContext": {
          "shape": "S2b"
        },
        "inputArtifacts": {
          "shape": "S2h"
        },
        "outputArtifacts": {
          "shape": "S2h"
        },
        "artifactCredentials": {
          "shape": "S2p"
        },
        "continuationToken": {},
        "encryptionKey": {
          "shape": "S15"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "configuration": {
          "shape": "S1l"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "pipelineName": {},
        "stage": {
          "type": "structure",
          "members": {
            "name": {}
          }
        },
        "action": {
          "type": "structure",
          "members": {
            "name": {},
            "actionExecutionId": {}
          }
        },
        "pipelineArn": {},
        "pipelineExecutionId": {}
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "revision": {},
          "location": {
            "type": "structure",
            "members": {
              "type": {},
              "s3Location": {
                "type": "structure",
                "required": [
                  "bucketName",
                  "objectKey"
                ],
                "members": {
                  "bucketName": {},
                  "objectKey": {}
                }
              }
            }
          }
        }
      }
    },
    "S2p": {
      "type": "structure",
      "required": [
        "accessKeyId",
        "secretAccessKey",
        "sessionToken"
      ],
      "members": {
        "accessKeyId": {},
        "secretAccessKey": {},
        "sessionToken": {}
      },
      "sensitive": true
    },
    "S3h": {
      "type": "structure",
      "required": [
        "revisionId",
        "revisionChangeId",
        "created"
      ],
      "members": {
        "revisionId": {},
        "revisionChangeId": {},
        "created": {
          "type": "timestamp"
        }
      }
    },
    "S48": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "s3location": {
            "type": "structure",
            "members": {
              "bucket": {},
              "key": {}
            }
          }
        }
      }
    },
    "S4h": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S58": {
      "type": "structure",
      "required": [
        "definition",
        "url"
      ],
      "members": {
        "definition": {
          "shape": "S59"
        },
        "url": {},
        "errorMessage": {},
        "errorCode": {},
        "lastTriggered": {
          "type": "timestamp"
        },
        "arn": {},
        "tags": {
          "shape": "Sq"
        }
      }
    },
    "S59": {
      "type": "structure",
      "required": [
        "name",
        "targetPipeline",
        "targetAction",
        "filters",
        "authentication",
        "authenticationConfiguration"
      ],
      "members": {
        "name": {},
        "targetPipeline": {},
        "targetAction": {},
        "filters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "jsonPath"
            ],
            "members": {
              "jsonPath": {},
              "matchEquals": {}
            }
          }
        },
        "authentication": {},
        "authenticationConfiguration": {
          "type": "structure",
          "members": {
            "AllowedIPRange": {},
            "SecretToken": {}
          }
        }
      }
    },
    "S68": {
      "type": "structure",
      "required": [
        "type",
        "message"
      ],
      "members": {
        "type": {},
        "message": {},
        "externalExecutionId": {}
      }
    },
    "S6b": {
      "type": "structure",
      "required": [
        "revision",
        "changeIdentifier"
      ],
      "members": {
        "revision": {},
        "changeIdentifier": {},
        "created": {
          "type": "timestamp"
        },
        "revisionSummary": {}
      }
    },
    "S6d": {
      "type": "structure",
      "members": {
        "summary": {},
        "externalExecutionId": {},
        "percentComplete": {
          "type": "integer"
        }
      }
    }
  }
}
},{}],45:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListActionExecutions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "actionExecutionDetails"
    },
    "ListActionTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "actionTypes"
    },
    "ListPipelineExecutions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "pipelineExecutionSummaries"
    },
    "ListPipelines": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "pipelines"
    },
    "ListTagsForResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "tags"
    },
    "ListWebhooks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "webhooks"
    }
  }
}

},{}],46:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-06-30",
    "endpointPrefix": "cognito-identity",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Cognito Identity",
    "serviceId": "Cognito Identity",
    "signatureVersion": "v4",
    "targetPrefix": "AWSCognitoIdentityService",
    "uid": "cognito-identity-2014-06-30"
  },
  "operations": {
    "CreateIdentityPool": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolName",
          "AllowUnauthenticatedIdentities"
        ],
        "members": {
          "IdentityPoolName": {},
          "AllowUnauthenticatedIdentities": {
            "type": "boolean"
          },
          "AllowClassicFlow": {
            "type": "boolean"
          },
          "SupportedLoginProviders": {
            "shape": "S5"
          },
          "DeveloperProviderName": {},
          "OpenIdConnectProviderARNs": {
            "shape": "S9"
          },
          "CognitoIdentityProviders": {
            "shape": "Sb"
          },
          "SamlProviderARNs": {
            "shape": "Sg"
          },
          "IdentityPoolTags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "shape": "Sk"
      }
    },
    "DeleteIdentities": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityIdsToDelete"
        ],
        "members": {
          "IdentityIdsToDelete": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedIdentityIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IdentityId": {},
                "ErrorCode": {}
              }
            }
          }
        }
      }
    },
    "DeleteIdentityPool": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {}
        }
      }
    },
    "DescribeIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityId"
        ],
        "members": {
          "IdentityId": {}
        }
      },
      "output": {
        "shape": "Sv"
      }
    },
    "DescribeIdentityPool": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {}
        }
      },
      "output": {
        "shape": "Sk"
      }
    },
    "GetCredentialsForIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityId"
        ],
        "members": {
          "IdentityId": {},
          "Logins": {
            "shape": "S10"
          },
          "CustomRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {},
          "Credentials": {
            "type": "structure",
            "members": {
              "AccessKeyId": {},
              "SecretKey": {},
              "SessionToken": {},
              "Expiration": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetId": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "AccountId": {},
          "IdentityPoolId": {},
          "Logins": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {}
        }
      }
    },
    "GetIdentityPoolRoles": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {},
          "Roles": {
            "shape": "S1c"
          },
          "RoleMappings": {
            "shape": "S1e"
          }
        }
      }
    },
    "GetOpenIdToken": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityId"
        ],
        "members": {
          "IdentityId": {},
          "Logins": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {},
          "Token": {}
        }
      }
    },
    "GetOpenIdTokenForDeveloperIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "Logins"
        ],
        "members": {
          "IdentityPoolId": {},
          "IdentityId": {},
          "Logins": {
            "shape": "S10"
          },
          "TokenDuration": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {},
          "Token": {}
        }
      }
    },
    "ListIdentities": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "MaxResults"
        ],
        "members": {
          "IdentityPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "HideDisabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {},
          "Identities": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityPools": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPools": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IdentityPoolId": {},
                "IdentityPoolName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sh"
          }
        }
      }
    },
    "LookupDeveloperIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {},
          "IdentityId": {},
          "DeveloperUserIdentifier": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {},
          "DeveloperUserIdentifierList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "MergeDeveloperIdentities": {
      "input": {
        "type": "structure",
        "required": [
          "SourceUserIdentifier",
          "DestinationUserIdentifier",
          "DeveloperProviderName",
          "IdentityPoolId"
        ],
        "members": {
          "SourceUserIdentifier": {},
          "DestinationUserIdentifier": {},
          "DeveloperProviderName": {},
          "IdentityPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityId": {}
        }
      }
    },
    "SetIdentityPoolRoles": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "Roles"
        ],
        "members": {
          "IdentityPoolId": {},
          "Roles": {
            "shape": "S1c"
          },
          "RoleMappings": {
            "shape": "S1e"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UnlinkDeveloperIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityId",
          "IdentityPoolId",
          "DeveloperProviderName",
          "DeveloperUserIdentifier"
        ],
        "members": {
          "IdentityId": {},
          "IdentityPoolId": {},
          "DeveloperProviderName": {},
          "DeveloperUserIdentifier": {}
        }
      }
    },
    "UnlinkIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "IdentityId",
          "Logins",
          "LoginsToRemove"
        ],
        "members": {
          "IdentityId": {},
          "Logins": {
            "shape": "S10"
          },
          "LoginsToRemove": {
            "shape": "Sw"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateIdentityPool": {
      "input": {
        "shape": "Sk"
      },
      "output": {
        "shape": "Sk"
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ProviderName": {},
          "ClientId": {},
          "ServerSideTokenCheck": {
            "type": "boolean"
          }
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Sh": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sk": {
      "type": "structure",
      "required": [
        "IdentityPoolId",
        "IdentityPoolName",
        "AllowUnauthenticatedIdentities"
      ],
      "members": {
        "IdentityPoolId": {},
        "IdentityPoolName": {},
        "AllowUnauthenticatedIdentities": {
          "type": "boolean"
        },
        "AllowClassicFlow": {
          "type": "boolean"
        },
        "SupportedLoginProviders": {
          "shape": "S5"
        },
        "DeveloperProviderName": {},
        "OpenIdConnectProviderARNs": {
          "shape": "S9"
        },
        "CognitoIdentityProviders": {
          "shape": "Sb"
        },
        "SamlProviderARNs": {
          "shape": "Sg"
        },
        "IdentityPoolTags": {
          "shape": "Sh"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "IdentityId": {},
        "Logins": {
          "shape": "Sw"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {}
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1c": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1e": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "Type": {},
          "AmbiguousRoleResolution": {},
          "RulesConfiguration": {
            "type": "structure",
            "required": [
              "Rules"
            ],
            "members": {
              "Rules": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Claim",
                    "MatchType",
                    "Value",
                    "RoleARN"
                  ],
                  "members": {
                    "Claim": {},
                    "MatchType": {},
                    "Value": {},
                    "RoleARN": {}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
},{}],47:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],48:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-04-18",
    "endpointPrefix": "cognito-idp",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Cognito Identity Provider",
    "serviceId": "Cognito Identity Provider",
    "signatureVersion": "v4",
    "targetPrefix": "AWSCognitoIdentityProviderService",
    "uid": "cognito-idp-2016-04-18"
  },
  "operations": {
    "AddCustomAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "CustomAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "CustomAttributes": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminAddUserToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminCreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Sj"
          },
          "ValidationData": {
            "shape": "Sj"
          },
          "TemporaryPassword": {
            "shape": "Sn"
          },
          "ForceAliasCreation": {
            "type": "boolean"
          },
          "MessageAction": {},
          "DesiredDeliveryMediums": {
            "type": "list",
            "member": {}
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "St"
          }
        }
      }
    },
    "AdminDeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      }
    },
    "AdminDeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributeNames"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributeNames": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminDisableProviderForUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "User"
        ],
        "members": {
          "UserPoolId": {},
          "User": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminDisableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminEnableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {}
        }
      }
    },
    "AdminGetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey",
          "UserPoolId",
          "Username"
        ],
        "members": {
          "DeviceKey": {},
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S1e"
          }
        }
      }
    },
    "AdminGetUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Sj"
          },
          "UserCreateDate": {
            "type": "timestamp"
          },
          "UserLastModifiedDate": {
            "type": "timestamp"
          },
          "Enabled": {
            "type": "boolean"
          },
          "UserStatus": {},
          "MFAOptions": {
            "shape": "Sw"
          },
          "PreferredMfaSetting": {},
          "UserMFASettingList": {
            "shape": "S1h"
          }
        }
      }
    },
    "AdminInitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "AuthFlow"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          },
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1l"
          },
          "ClientMetadata": {
            "shape": "Sg"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ContextData": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "AdminLinkProviderForUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "DestinationUser",
          "SourceUser"
        ],
        "members": {
          "UserPoolId": {},
          "DestinationUser": {
            "shape": "S13"
          },
          "SourceUser": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S24"
          },
          "PaginationToken": {}
        }
      }
    },
    "AdminListGroupsForUser": {
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "UserPoolId"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S28"
          },
          "NextToken": {}
        }
      }
    },
    "AdminListUserAuthEvents": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "EventType": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "EventResponse": {},
                "EventRisk": {
                  "type": "structure",
                  "members": {
                    "RiskDecision": {},
                    "RiskLevel": {},
                    "CompromisedCredentialsDetected": {
                      "type": "boolean"
                    }
                  }
                },
                "ChallengeResponses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ChallengeName": {},
                      "ChallengeResponse": {}
                    }
                  }
                },
                "EventContextData": {
                  "type": "structure",
                  "members": {
                    "IpAddress": {},
                    "DeviceName": {},
                    "Timezone": {},
                    "City": {},
                    "Country": {}
                  }
                },
                "EventFeedback": {
                  "type": "structure",
                  "required": [
                    "FeedbackValue",
                    "Provider"
                  ],
                  "members": {
                    "FeedbackValue": {},
                    "Provider": {},
                    "FeedbackDate": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "AdminRemoveUserFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminResetUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminRespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          },
          "ChallengeName": {},
          "ChallengeResponses": {
            "shape": "S2y"
          },
          "Session": {},
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ContextData": {
            "shape": "S1n"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "AdminSetUserMFAPreference": {
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "UserPoolId"
        ],
        "members": {
          "SMSMfaSettings": {
            "shape": "S31"
          },
          "SoftwareTokenMfaSettings": {
            "shape": "S32"
          },
          "Username": {
            "shape": "Sd"
          },
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminSetUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "Password"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "Password": {
            "shape": "Sn"
          },
          "Permanent": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminSetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "MFAOptions"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "MFAOptions": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateAuthEventFeedback": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "EventId",
          "FeedbackValue"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "EventId": {},
          "FeedbackValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Sj"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUserGlobalSignOut": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateSoftwareToken": {
      "input": {
        "type": "structure",
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Session": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecretCode": {
            "type": "string",
            "sensitive": true
          },
          "Session": {}
        }
      }
    },
    "ChangePassword": {
      "input": {
        "type": "structure",
        "required": [
          "PreviousPassword",
          "ProposedPassword",
          "AccessToken"
        ],
        "members": {
          "PreviousPassword": {
            "shape": "Sn"
          },
          "ProposedPassword": {
            "shape": "Sn"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmDevice": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {},
          "DeviceSecretVerifierConfig": {
            "type": "structure",
            "members": {
              "PasswordVerifier": {},
              "Salt": {}
            }
          },
          "DeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserConfirmationNecessary": {
            "type": "boolean"
          }
        }
      }
    },
    "ConfirmForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "SecretHash": {
            "shape": "S3s"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "Password": {
            "shape": "Sn"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "SecretHash": {
            "shape": "S3s"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "ForceAliasCreation": {
            "type": "boolean"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "CreateIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName",
          "ProviderType",
          "ProviderDetails"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {},
          "ProviderType": {},
          "ProviderDetails": {
            "shape": "S43"
          },
          "AttributeMapping": {
            "shape": "S44"
          },
          "IdpIdentifiers": {
            "shape": "S46"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S49"
          }
        }
      }
    },
    "CreateResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier",
          "Name"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {},
          "Name": {},
          "Scopes": {
            "shape": "S4d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4i"
          }
        }
      }
    },
    "CreateUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "UserPoolId",
          "CloudWatchLogsRoleArn"
        ],
        "members": {
          "JobName": {},
          "UserPoolId": {},
          "CloudWatchLogsRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4m"
          }
        }
      }
    },
    "CreateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "PoolName"
        ],
        "members": {
          "PoolName": {},
          "Policies": {
            "shape": "S4u"
          },
          "LambdaConfig": {
            "shape": "S4y"
          },
          "AutoVerifiedAttributes": {
            "shape": "S4z"
          },
          "AliasAttributes": {
            "shape": "S51"
          },
          "UsernameAttributes": {
            "shape": "S53"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "VerificationMessageTemplate": {
            "shape": "S58"
          },
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S5d"
          },
          "EmailConfiguration": {
            "shape": "S5e"
          },
          "SmsConfiguration": {
            "shape": "S5i"
          },
          "UserPoolTags": {
            "shape": "S5j"
          },
          "AdminCreateUserConfig": {
            "shape": "S5m"
          },
          "Schema": {
            "shape": "S5p"
          },
          "UserPoolAddOns": {
            "shape": "S5q"
          },
          "UsernameConfiguration": {
            "shape": "S5s"
          },
          "AccountRecoverySetting": {
            "shape": "S5t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S5z"
          }
        }
      }
    },
    "CreateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientName": {},
          "GenerateSecret": {
            "type": "boolean"
          },
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S66"
          },
          "WriteAttributes": {
            "shape": "S66"
          },
          "ExplicitAuthFlows": {
            "shape": "S68"
          },
          "SupportedIdentityProviders": {
            "shape": "S6a"
          },
          "CallbackURLs": {
            "shape": "S6b"
          },
          "LogoutURLs": {
            "shape": "S6d"
          },
          "DefaultRedirectURI": {},
          "AllowedOAuthFlows": {
            "shape": "S6e"
          },
          "AllowedOAuthScopes": {
            "shape": "S6g"
          },
          "AllowedOAuthFlowsUserPoolClient": {
            "type": "boolean"
          },
          "AnalyticsConfiguration": {
            "shape": "S6i"
          },
          "PreventUserExistenceErrors": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6m"
          }
        }
      }
    },
    "CreateUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {},
          "CustomDomainConfig": {
            "shape": "S6p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontDomain": {}
        }
      }
    },
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      }
    },
    "DeleteIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {}
        }
      }
    },
    "DeleteResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "authtype": "none"
    },
    "DeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributeNames",
          "AccessToken"
        ],
        "members": {
          "UserAttributeNames": {
            "shape": "S10"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "DeleteUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      }
    },
    "DeleteUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S49"
          }
        }
      }
    },
    "DescribeResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4i"
          }
        }
      }
    },
    "DescribeRiskConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RiskConfiguration"
        ],
        "members": {
          "RiskConfiguration": {
            "shape": "S77"
          }
        }
      }
    },
    "DescribeUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4m"
          }
        }
      }
    },
    "DescribeUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S5z"
          }
        }
      }
    },
    "DescribeUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6m"
          }
        }
      }
    },
    "DescribeUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainDescription": {
            "type": "structure",
            "members": {
              "UserPoolId": {},
              "AWSAccountId": {},
              "Domain": {},
              "S3Bucket": {},
              "CloudFrontDistribution": {},
              "Version": {},
              "Status": {},
              "CustomDomainConfig": {
                "shape": "S6p"
              }
            }
          }
        }
      }
    },
    "ForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {}
        }
      }
    },
    "ForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "SecretHash": {
            "shape": "S3s"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "Username": {
            "shape": "Sd"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S85"
          }
        }
      },
      "authtype": "none"
    },
    "GetCSVHeader": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolId": {},
          "CSVHeader": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "DeviceKey": {},
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S1e"
          }
        }
      }
    },
    "GetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "GetIdentityProviderByIdentifier": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "IdpIdentifier"
        ],
        "members": {
          "UserPoolId": {},
          "IdpIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S49"
          }
        }
      }
    },
    "GetSigningCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {}
        }
      }
    },
    "GetUICustomization": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UICustomization"
        ],
        "members": {
          "UICustomization": {
            "shape": "S8j"
          }
        }
      }
    },
    "GetUser": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username",
          "UserAttributes"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Sj"
          },
          "MFAOptions": {
            "shape": "Sw"
          },
          "PreferredMfaSetting": {},
          "UserMFASettingList": {
            "shape": "S1h"
          }
        }
      },
      "authtype": "none"
    },
    "GetUserAttributeVerificationCode": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "AttributeName"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "AttributeName": {},
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S85"
          }
        }
      },
      "authtype": "none"
    },
    "GetUserPoolMfaConfig": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SmsMfaConfiguration": {
            "shape": "S8t"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8u"
          },
          "MfaConfiguration": {}
        }
      }
    },
    "GlobalSignOut": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "InitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "AuthFlow",
          "ClientId"
        ],
        "members": {
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1l"
          },
          "ClientMetadata": {
            "shape": "Sg"
          },
          "ClientId": {
            "shape": "S1j"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S24"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S28"
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityProviders": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Providers"
        ],
        "members": {
          "Providers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProviderName": {},
                "ProviderType": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceServers": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServers"
        ],
        "members": {
          "ResourceServers": {
            "type": "list",
            "member": {
              "shape": "S4i"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S5j"
          }
        }
      }
    },
    "ListUserImportJobs": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "MaxResults"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJobs": {
            "type": "list",
            "member": {
              "shape": "S4m"
            }
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUserPoolClients": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClients": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ClientId": {
                  "shape": "S1j"
                },
                "UserPoolId": {},
                "ClientName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserPools": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPools": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "LambdaConfig": {
                  "shape": "S4y"
                },
                "Status": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "AttributesToGet": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {},
          "Filter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S9w"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUsersInGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "GroupName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S9w"
          },
          "NextToken": {}
        }
      }
    },
    "ResendConfirmationCode": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "SecretHash": {
            "shape": "S3s"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "Username": {
            "shape": "Sd"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S85"
          }
        }
      },
      "authtype": "none"
    },
    "RespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "ChallengeName": {},
          "Session": {},
          "ChallengeResponses": {
            "shape": "S2y"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "SetRiskConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          },
          "CompromisedCredentialsRiskConfiguration": {
            "shape": "S78"
          },
          "AccountTakeoverRiskConfiguration": {
            "shape": "S7d"
          },
          "RiskExceptionConfiguration": {
            "shape": "S7m"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RiskConfiguration"
        ],
        "members": {
          "RiskConfiguration": {
            "shape": "S77"
          }
        }
      }
    },
    "SetUICustomization": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          },
          "CSS": {},
          "ImageFile": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UICustomization"
        ],
        "members": {
          "UICustomization": {
            "shape": "S8j"
          }
        }
      }
    },
    "SetUserMFAPreference": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "SMSMfaSettings": {
            "shape": "S31"
          },
          "SoftwareTokenMfaSettings": {
            "shape": "S32"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SetUserPoolMfaConfig": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "SmsMfaConfiguration": {
            "shape": "S8t"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8u"
          },
          "MfaConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SmsMfaConfiguration": {
            "shape": "S8t"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8u"
          },
          "MfaConfiguration": {}
        }
      }
    },
    "SetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "MFAOptions"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "MFAOptions": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "SignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1j"
          },
          "SecretHash": {
            "shape": "S3s"
          },
          "Username": {
            "shape": "Sd"
          },
          "Password": {
            "shape": "Sn"
          },
          "UserAttributes": {
            "shape": "Sj"
          },
          "ValidationData": {
            "shape": "Sj"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3u"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UserConfirmed",
          "UserSub"
        ],
        "members": {
          "UserConfirmed": {
            "type": "boolean"
          },
          "CodeDeliveryDetails": {
            "shape": "S85"
          },
          "UserSub": {}
        }
      },
      "authtype": "none"
    },
    "StartUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4m"
          }
        }
      }
    },
    "StopUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4m"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S5j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuthEventFeedback": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "EventId",
          "FeedbackToken",
          "FeedbackValue"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "EventId": {},
          "FeedbackToken": {
            "shape": "S1v"
          },
          "FeedbackValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "UpdateIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {},
          "ProviderDetails": {
            "shape": "S43"
          },
          "AttributeMapping": {
            "shape": "S44"
          },
          "IdpIdentifiers": {
            "shape": "S46"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S49"
          }
        }
      }
    },
    "UpdateResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier",
          "Name"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {},
          "Name": {},
          "Scopes": {
            "shape": "S4d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4i"
          }
        }
      }
    },
    "UpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributes",
          "AccessToken"
        ],
        "members": {
          "UserAttributes": {
            "shape": "Sj"
          },
          "AccessToken": {
            "shape": "S1v"
          },
          "ClientMetadata": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetailsList": {
            "type": "list",
            "member": {
              "shape": "S85"
            }
          }
        }
      },
      "authtype": "none"
    },
    "UpdateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Policies": {
            "shape": "S4u"
          },
          "LambdaConfig": {
            "shape": "S4y"
          },
          "AutoVerifiedAttributes": {
            "shape": "S4z"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "VerificationMessageTemplate": {
            "shape": "S58"
          },
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S5d"
          },
          "EmailConfiguration": {
            "shape": "S5e"
          },
          "SmsConfiguration": {
            "shape": "S5i"
          },
          "UserPoolTags": {
            "shape": "S5j"
          },
          "AdminCreateUserConfig": {
            "shape": "S5m"
          },
          "UserPoolAddOns": {
            "shape": "S5q"
          },
          "AccountRecoverySetting": {
            "shape": "S5t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1j"
          },
          "ClientName": {},
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S66"
          },
          "WriteAttributes": {
            "shape": "S66"
          },
          "ExplicitAuthFlows": {
            "shape": "S68"
          },
          "SupportedIdentityProviders": {
            "shape": "S6a"
          },
          "CallbackURLs": {
            "shape": "S6b"
          },
          "LogoutURLs": {
            "shape": "S6d"
          },
          "DefaultRedirectURI": {},
          "AllowedOAuthFlows": {
            "shape": "S6e"
          },
          "AllowedOAuthScopes": {
            "shape": "S6g"
          },
          "AllowedOAuthFlowsUserPoolClient": {
            "type": "boolean"
          },
          "AnalyticsConfiguration": {
            "shape": "S6i"
          },
          "PreventUserExistenceErrors": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6m"
          }
        }
      }
    },
    "UpdateUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId",
          "CustomDomainConfig"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {},
          "CustomDomainConfig": {
            "shape": "S6p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontDomain": {}
        }
      }
    },
    "VerifySoftwareToken": {
      "input": {
        "type": "structure",
        "required": [
          "UserCode"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Session": {},
          "UserCode": {},
          "FriendlyDeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "Session": {}
        }
      }
    },
    "VerifyUserAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "AttributeName",
          "Code"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "AttributeName": {},
          "Code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Name": {},
        "AttributeDataType": {},
        "DeveloperOnlyAttribute": {
          "type": "boolean"
        },
        "Mutable": {
          "type": "boolean"
        },
        "Required": {
          "type": "boolean"
        },
        "NumberAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinValue": {},
            "MaxValue": {}
          }
        },
        "StringAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinLength": {},
            "MaxLength": {}
          }
        }
      }
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Sg": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Sn": {
      "type": "string",
      "sensitive": true
    },
    "St": {
      "type": "structure",
      "members": {
        "Username": {
          "shape": "Sd"
        },
        "Attributes": {
          "shape": "Sj"
        },
        "UserCreateDate": {
          "type": "timestamp"
        },
        "UserLastModifiedDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "UserStatus": {},
        "MFAOptions": {
          "shape": "Sw"
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DeliveryMedium": {},
          "AttributeName": {}
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {}
    },
    "S13": {
      "type": "structure",
      "members": {
        "ProviderName": {},
        "ProviderAttributeName": {},
        "ProviderAttributeValue": {}
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "DeviceKey": {},
        "DeviceAttributes": {
          "shape": "Sj"
        },
        "DeviceCreateDate": {
          "type": "timestamp"
        },
        "DeviceLastModifiedDate": {
          "type": "timestamp"
        },
        "DeviceLastAuthenticatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "string",
      "sensitive": true
    },
    "S1l": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S1m": {
      "type": "structure",
      "members": {
        "AnalyticsEndpointId": {}
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "IpAddress",
        "ServerName",
        "ServerPath",
        "HttpHeaders"
      ],
      "members": {
        "IpAddress": {},
        "ServerName": {},
        "ServerPath": {},
        "HttpHeaders": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "headerName": {},
              "headerValue": {}
            }
          }
        },
        "EncodedData": {}
      }
    },
    "S1t": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1u": {
      "type": "structure",
      "members": {
        "AccessToken": {
          "shape": "S1v"
        },
        "ExpiresIn": {
          "type": "integer"
        },
        "TokenType": {},
        "RefreshToken": {
          "shape": "S1v"
        },
        "IdToken": {
          "shape": "S1v"
        },
        "NewDeviceMetadata": {
          "type": "structure",
          "members": {
            "DeviceKey": {},
            "DeviceGroupKey": {}
          }
        }
      }
    },
    "S1v": {
      "type": "string",
      "sensitive": true
    },
    "S24": {
      "type": "list",
      "member": {
        "shape": "S1e"
      }
    },
    "S28": {
      "type": "list",
      "member": {
        "shape": "S29"
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "GroupName": {},
        "UserPoolId": {},
        "Description": {},
        "RoleArn": {},
        "Precedence": {
          "type": "integer"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S2y": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S31": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "PreferredMfa": {
          "type": "boolean"
        }
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "PreferredMfa": {
          "type": "boolean"
        }
      }
    },
    "S3s": {
      "type": "string",
      "sensitive": true
    },
    "S3u": {
      "type": "structure",
      "members": {
        "EncodedData": {}
      }
    },
    "S43": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S44": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S46": {
      "type": "list",
      "member": {}
    },
    "S49": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ProviderName": {},
        "ProviderType": {},
        "ProviderDetails": {
          "shape": "S43"
        },
        "AttributeMapping": {
          "shape": "S44"
        },
        "IdpIdentifiers": {
          "shape": "S46"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S4d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScopeName",
          "ScopeDescription"
        ],
        "members": {
          "ScopeName": {},
          "ScopeDescription": {}
        }
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "Identifier": {},
        "Name": {},
        "Scopes": {
          "shape": "S4d"
        }
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "JobName": {},
        "JobId": {},
        "UserPoolId": {},
        "PreSignedUrl": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "StartDate": {
          "type": "timestamp"
        },
        "CompletionDate": {
          "type": "timestamp"
        },
        "Status": {},
        "CloudWatchLogsRoleArn": {},
        "ImportedUsers": {
          "type": "long"
        },
        "SkippedUsers": {
          "type": "long"
        },
        "FailedUsers": {
          "type": "long"
        },
        "CompletionMessage": {}
      }
    },
    "S4u": {
      "type": "structure",
      "members": {
        "PasswordPolicy": {
          "type": "structure",
          "members": {
            "MinimumLength": {
              "type": "integer"
            },
            "RequireUppercase": {
              "type": "boolean"
            },
            "RequireLowercase": {
              "type": "boolean"
            },
            "RequireNumbers": {
              "type": "boolean"
            },
            "RequireSymbols": {
              "type": "boolean"
            },
            "TemporaryPasswordValidityDays": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4y": {
      "type": "structure",
      "members": {
        "PreSignUp": {},
        "CustomMessage": {},
        "PostConfirmation": {},
        "PreAuthentication": {},
        "PostAuthentication": {},
        "DefineAuthChallenge": {},
        "CreateAuthChallenge": {},
        "VerifyAuthChallengeResponse": {},
        "PreTokenGeneration": {},
        "UserMigration": {}
      }
    },
    "S4z": {
      "type": "list",
      "member": {}
    },
    "S51": {
      "type": "list",
      "member": {}
    },
    "S53": {
      "type": "list",
      "member": {}
    },
    "S58": {
      "type": "structure",
      "members": {
        "SmsMessage": {},
        "EmailMessage": {},
        "EmailSubject": {},
        "EmailMessageByLink": {},
        "EmailSubjectByLink": {},
        "DefaultEmailOption": {}
      }
    },
    "S5d": {
      "type": "structure",
      "members": {
        "ChallengeRequiredOnNewDevice": {
          "type": "boolean"
        },
        "DeviceOnlyRememberedOnUserPrompt": {
          "type": "boolean"
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "SourceArn": {},
        "ReplyToEmailAddress": {},
        "EmailSendingAccount": {},
        "From": {},
        "ConfigurationSet": {}
      }
    },
    "S5i": {
      "type": "structure",
      "required": [
        "SnsCallerArn"
      ],
      "members": {
        "SnsCallerArn": {},
        "ExternalId": {}
      }
    },
    "S5j": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5m": {
      "type": "structure",
      "members": {
        "AllowAdminCreateUserOnly": {
          "type": "boolean"
        },
        "UnusedAccountValidityDays": {
          "type": "integer"
        },
        "InviteMessageTemplate": {
          "type": "structure",
          "members": {
            "SMSMessage": {},
            "EmailMessage": {},
            "EmailSubject": {}
          }
        }
      }
    },
    "S5p": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    },
    "S5q": {
      "type": "structure",
      "required": [
        "AdvancedSecurityMode"
      ],
      "members": {
        "AdvancedSecurityMode": {}
      }
    },
    "S5s": {
      "type": "structure",
      "required": [
        "CaseSensitive"
      ],
      "members": {
        "CaseSensitive": {
          "type": "boolean"
        }
      }
    },
    "S5t": {
      "type": "structure",
      "members": {
        "RecoveryMechanisms": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Priority",
              "Name"
            ],
            "members": {
              "Priority": {
                "type": "integer"
              },
              "Name": {}
            }
          }
        }
      }
    },
    "S5z": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Policies": {
          "shape": "S4u"
        },
        "LambdaConfig": {
          "shape": "S4y"
        },
        "Status": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "SchemaAttributes": {
          "shape": "S5p"
        },
        "AutoVerifiedAttributes": {
          "shape": "S4z"
        },
        "AliasAttributes": {
          "shape": "S51"
        },
        "UsernameAttributes": {
          "shape": "S53"
        },
        "SmsVerificationMessage": {},
        "EmailVerificationMessage": {},
        "EmailVerificationSubject": {},
        "VerificationMessageTemplate": {
          "shape": "S58"
        },
        "SmsAuthenticationMessage": {},
        "MfaConfiguration": {},
        "DeviceConfiguration": {
          "shape": "S5d"
        },
        "EstimatedNumberOfUsers": {
          "type": "integer"
        },
        "EmailConfiguration": {
          "shape": "S5e"
        },
        "SmsConfiguration": {
          "shape": "S5i"
        },
        "UserPoolTags": {
          "shape": "S5j"
        },
        "SmsConfigurationFailure": {},
        "EmailConfigurationFailure": {},
        "Domain": {},
        "CustomDomain": {},
        "AdminCreateUserConfig": {
          "shape": "S5m"
        },
        "UserPoolAddOns": {
          "shape": "S5q"
        },
        "UsernameConfiguration": {
          "shape": "S5s"
        },
        "Arn": {},
        "AccountRecoverySetting": {
          "shape": "S5t"
        }
      }
    },
    "S66": {
      "type": "list",
      "member": {}
    },
    "S68": {
      "type": "list",
      "member": {}
    },
    "S6a": {
      "type": "list",
      "member": {}
    },
    "S6b": {
      "type": "list",
      "member": {}
    },
    "S6d": {
      "type": "list",
      "member": {}
    },
    "S6e": {
      "type": "list",
      "member": {}
    },
    "S6g": {
      "type": "list",
      "member": {}
    },
    "S6i": {
      "type": "structure",
      "required": [
        "ApplicationId",
        "RoleArn",
        "ExternalId"
      ],
      "members": {
        "ApplicationId": {},
        "RoleArn": {},
        "ExternalId": {},
        "UserDataShared": {
          "type": "boolean"
        }
      }
    },
    "S6m": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientName": {},
        "ClientId": {
          "shape": "S1j"
        },
        "ClientSecret": {
          "type": "string",
          "sensitive": true
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "RefreshTokenValidity": {
          "type": "integer"
        },
        "ReadAttributes": {
          "shape": "S66"
        },
        "WriteAttributes": {
          "shape": "S66"
        },
        "ExplicitAuthFlows": {
          "shape": "S68"
        },
        "SupportedIdentityProviders": {
          "shape": "S6a"
        },
        "CallbackURLs": {
          "shape": "S6b"
        },
        "LogoutURLs": {
          "shape": "S6d"
        },
        "DefaultRedirectURI": {},
        "AllowedOAuthFlows": {
          "shape": "S6e"
        },
        "AllowedOAuthScopes": {
          "shape": "S6g"
        },
        "AllowedOAuthFlowsUserPoolClient": {
          "type": "boolean"
        },
        "AnalyticsConfiguration": {
          "shape": "S6i"
        },
        "PreventUserExistenceErrors": {}
      }
    },
    "S6p": {
      "type": "structure",
      "required": [
        "CertificateArn"
      ],
      "members": {
        "CertificateArn": {}
      }
    },
    "S77": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientId": {
          "shape": "S1j"
        },
        "CompromisedCredentialsRiskConfiguration": {
          "shape": "S78"
        },
        "AccountTakeoverRiskConfiguration": {
          "shape": "S7d"
        },
        "RiskExceptionConfiguration": {
          "shape": "S7m"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        }
      }
    },
    "S78": {
      "type": "structure",
      "required": [
        "Actions"
      ],
      "members": {
        "EventFilter": {
          "type": "list",
          "member": {}
        },
        "Actions": {
          "type": "structure",
          "required": [
            "EventAction"
          ],
          "members": {
            "EventAction": {}
          }
        }
      }
    },
    "S7d": {
      "type": "structure",
      "required": [
        "Actions"
      ],
      "members": {
        "NotifyConfiguration": {
          "type": "structure",
          "required": [
            "SourceArn"
          ],
          "members": {
            "From": {},
            "ReplyTo": {},
            "SourceArn": {},
            "BlockEmail": {
              "shape": "S7f"
            },
            "NoActionEmail": {
              "shape": "S7f"
            },
            "MfaEmail": {
              "shape": "S7f"
            }
          }
        },
        "Actions": {
          "type": "structure",
          "members": {
            "LowAction": {
              "shape": "S7j"
            },
            "MediumAction": {
              "shape": "S7j"
            },
            "HighAction": {
              "shape": "S7j"
            }
          }
        }
      }
    },
    "S7f": {
      "type": "structure",
      "required": [
        "Subject"
      ],
      "members": {
        "Subject": {},
        "HtmlBody": {},
        "TextBody": {}
      }
    },
    "S7j": {
      "type": "structure",
      "required": [
        "Notify",
        "EventAction"
      ],
      "members": {
        "Notify": {
          "type": "boolean"
        },
        "EventAction": {}
      }
    },
    "S7m": {
      "type": "structure",
      "members": {
        "BlockedIPRangeList": {
          "type": "list",
          "member": {}
        },
        "SkippedIPRangeList": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S85": {
      "type": "structure",
      "members": {
        "Destination": {},
        "DeliveryMedium": {},
        "AttributeName": {}
      }
    },
    "S8j": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientId": {
          "shape": "S1j"
        },
        "ImageUrl": {},
        "CSS": {},
        "CSSVersion": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S8t": {
      "type": "structure",
      "members": {
        "SmsAuthenticationMessage": {},
        "SmsConfiguration": {
          "shape": "S5i"
        }
      }
    },
    "S8u": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S9w": {
      "type": "list",
      "member": {
        "shape": "St"
      }
    }
  }
}
},{}],49:[function(require,module,exports){
module.exports={
  "pagination": {
    "AdminListGroupsForUser": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "AdminListUserAuthEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AuthEvents"
    },
    "ListGroups": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "ListIdentityProviders": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Providers"
    },
    "ListResourceServers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ResourceServers"
    },
    "ListUserPoolClients": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserPoolClients"
    },
    "ListUserPools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserPools"
    },
    "ListUsers": {
      "input_token": "PaginationToken",
      "limit_key": "Limit",
      "output_token": "PaginationToken",
      "result_key": "Users"
    },
    "ListUsersInGroup": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "Users"
    }
  }
}
},{}],50:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-06-30",
    "endpointPrefix": "cognito-sync",
    "jsonVersion": "1.1",
    "serviceFullName": "Amazon Cognito Sync",
    "serviceId": "Cognito Sync",
    "signatureVersion": "v4",
    "protocol": "rest-json",
    "uid": "cognito-sync-2014-06-30"
  },
  "operations": {
    "BulkPublish": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/bulkpublish",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {}
        }
      }
    },
    "DeleteDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Dataset": {
            "shape": "S8"
          }
        }
      }
    },
    "DescribeDataset": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Dataset": {
            "shape": "S8"
          }
        }
      }
    },
    "DescribeIdentityPoolUsage": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolUsage": {
            "shape": "Sg"
          }
        }
      }
    },
    "DescribeIdentityUsage": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityUsage": {
            "type": "structure",
            "members": {
              "IdentityId": {},
              "IdentityPoolId": {},
              "LastModifiedDate": {
                "type": "timestamp"
              },
              "DatasetCount": {
                "type": "integer"
              },
              "DataStorage": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetBulkPublishDetails": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/getBulkPublishDetails",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {},
          "BulkPublishStartTime": {
            "type": "timestamp"
          },
          "BulkPublishCompleteTime": {
            "type": "timestamp"
          },
          "BulkPublishStatus": {},
          "FailureMessage": {}
        }
      }
    },
    "GetCognitoEvents": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "shape": "Sq"
          }
        }
      }
    },
    "GetIdentityPoolConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {},
          "PushSync": {
            "shape": "Sv"
          },
          "CognitoStreams": {
            "shape": "Sz"
          }
        }
      }
    },
    "ListDatasets": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityId",
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Datasets": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "Count": {
            "type": "integer"
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityPoolUsage": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolUsages": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "Count": {
            "type": "integer"
          },
          "NextToken": {}
        }
      }
    },
    "ListRecords": {
      "http": {
        "method": "GET",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}/records",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          },
          "LastSyncCount": {
            "location": "querystring",
            "locationName": "lastSyncCount",
            "type": "long"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "SyncSessionToken": {
            "location": "querystring",
            "locationName": "syncSessionToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Records": {
            "shape": "S1c"
          },
          "NextToken": {},
          "Count": {
            "type": "integer"
          },
          "DatasetSyncCount": {
            "type": "long"
          },
          "LastModifiedBy": {},
          "MergedDatasetNames": {
            "type": "list",
            "member": {}
          },
          "DatasetExists": {
            "type": "boolean"
          },
          "DatasetDeletedAfterRequestedSyncCount": {
            "type": "boolean"
          },
          "SyncSessionToken": {}
        }
      }
    },
    "RegisterDevice": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/identity/{IdentityId}/device",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "Platform",
          "Token"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "Platform": {},
          "Token": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeviceId": {}
        }
      }
    },
    "SetCognitoEvents": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "Events"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "Events": {
            "shape": "Sq"
          }
        }
      }
    },
    "SetIdentityPoolConfiguration": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "PushSync": {
            "shape": "Sv"
          },
          "CognitoStreams": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityPoolId": {},
          "PushSync": {
            "shape": "Sv"
          },
          "CognitoStreams": {
            "shape": "Sz"
          }
        }
      }
    },
    "SubscribeToDataset": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}/subscriptions/{DeviceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName",
          "DeviceId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          },
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UnsubscribeFromDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}/subscriptions/{DeviceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName",
          "DeviceId"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          },
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRecords": {
      "http": {
        "requestUri": "/identitypools/{IdentityPoolId}/identities/{IdentityId}/datasets/{DatasetName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityPoolId",
          "IdentityId",
          "DatasetName",
          "SyncSessionToken"
        ],
        "members": {
          "IdentityPoolId": {
            "location": "uri",
            "locationName": "IdentityPoolId"
          },
          "IdentityId": {
            "location": "uri",
            "locationName": "IdentityId"
          },
          "DatasetName": {
            "location": "uri",
            "locationName": "DatasetName"
          },
          "DeviceId": {},
          "RecordPatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Op",
                "Key",
                "SyncCount"
              ],
              "members": {
                "Op": {},
                "Key": {},
                "Value": {},
                "SyncCount": {
                  "type": "long"
                },
                "DeviceLastModifiedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "SyncSessionToken": {},
          "ClientContext": {
            "location": "header",
            "locationName": "x-amz-Client-Context"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Records": {
            "shape": "S1c"
          }
        }
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "structure",
      "members": {
        "IdentityId": {},
        "DatasetName": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "LastModifiedBy": {},
        "DataStorage": {
          "type": "long"
        },
        "NumRecords": {
          "type": "long"
        }
      }
    },
    "Sg": {
      "type": "structure",
      "members": {
        "IdentityPoolId": {},
        "SyncSessionsCount": {
          "type": "long"
        },
        "DataStorage": {
          "type": "long"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        }
      }
    },
    "Sq": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ApplicationArns": {
          "type": "list",
          "member": {}
        },
        "RoleArn": {}
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "StreamName": {},
        "RoleArn": {},
        "StreamingStatus": {}
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "SyncCount": {
            "type": "long"
          },
          "LastModifiedDate": {
            "type": "timestamp"
          },
          "LastModifiedBy": {},
          "DeviceLastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    }
  },
  "examples": {}
}
},{}],51:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "comprehend",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Comprehend",
    "serviceId": "Comprehend",
    "signatureVersion": "v4",
    "signingName": "comprehend",
    "targetPrefix": "Comprehend_20171127",
    "uid": "comprehend-2017-11-27"
  },
  "operations": {
    "BatchDetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "TextList"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Languages": {
                  "shape": "S8"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Entities": {
                  "shape": "Si"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "KeyPhrases": {
                  "shape": "Sp"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Sentiment": {},
                "SentimentScore": {
                  "shape": "Sw"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "SyntaxTokens": {
                  "shape": "S12"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "ClassifyDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "EndpointArn"
        ],
        "members": {
          "Text": {},
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Score": {
                  "type": "float"
                }
              }
            }
          },
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Score": {
                  "type": "float"
                }
              }
            }
          }
        }
      }
    },
    "CreateDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "DocumentClassifierName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S1g"
          },
          "InputDataConfig": {
            "shape": "S1k"
          },
          "OutputDataConfig": {
            "shape": "S1n"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          },
          "Mode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierArn": {}
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "ModelArn",
          "DesiredInferenceUnits"
        ],
        "members": {
          "EndpointName": {},
          "ModelArn": {},
          "DesiredInferenceUnits": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "CreateEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "RecognizerName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "RecognizerName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S1g"
          },
          "InputDataConfig": {
            "shape": "S25"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerArn": {}
        }
      }
    },
    "DeleteDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobProperties": {
            "shape": "S2n"
          }
        }
      }
    },
    "DescribeDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierProperties": {
            "shape": "S2x"
          }
        }
      }
    },
    "DescribeDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobProperties": {
            "shape": "S34"
          }
        }
      }
    },
    "DescribeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointProperties": {
            "shape": "S37"
          }
        }
      }
    },
    "DescribeEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobProperties": {
            "shape": "S3b"
          }
        }
      }
    },
    "DescribeEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerProperties": {
            "shape": "S3e"
          }
        }
      }
    },
    "DescribeKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobProperties": {
            "shape": "S3m"
          }
        }
      }
    },
    "DescribeSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobProperties": {
            "shape": "S3p"
          }
        }
      }
    },
    "DescribeTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobProperties": {
            "shape": "S3s"
          }
        }
      }
    },
    "DetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Languages": {
            "shape": "S8"
          }
        }
      }
    },
    "DetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "shape": "Si"
          }
        }
      }
    },
    "DetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrases": {
            "shape": "Sp"
          }
        }
      }
    },
    "DetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sentiment": {},
          "SentimentScore": {
            "shape": "Sw"
          }
        }
      }
    },
    "DetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SyntaxTokens": {
            "shape": "S12"
          }
        }
      }
    },
    "ListDocumentClassificationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentClassifiers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2x"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDominantLanguageDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S34"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "ModelArn": {},
              "Status": {},
              "CreationTimeBefore": {
                "type": "timestamp"
              },
              "CreationTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S37"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntitiesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3b"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntityRecognizers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListKeyPhrasesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3m"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSentimentDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3p"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1g"
          }
        }
      }
    },
    "ListTopicsDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S3s"
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn",
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "JobName": {},
          "DocumentClassifierArn": {},
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "EntityRecognizerArn": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2s"
          },
          "OutputDataConfig": {
            "shape": "S2u"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "NumberOfTopics": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTrainingDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopTrainingEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn",
          "DesiredInferenceUnits"
        ],
        "members": {
          "EndpointArn": {},
          "DesiredInferenceUnits": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LanguageCode": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Index": {
            "type": "integer"
          },
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Type": {},
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "Positive": {
          "type": "float"
        },
        "Negative": {
          "type": "float"
        },
        "Neutral": {
          "type": "float"
        },
        "Mixed": {
          "type": "float"
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TokenId": {
            "type": "integer"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          },
          "PartOfSpeech": {
            "type": "structure",
            "members": {
              "Tag": {},
              "Score": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1k": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "LabelDelimiter": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S1q": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "EntityTypes",
        "Documents"
      ],
      "members": {
        "EntityTypes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {}
            }
          }
        },
        "Documents": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        },
        "Annotations": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        },
        "EntityList": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "DocumentClassifierArn": {},
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "InputFormat": {}
      }
    },
    "S2u": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S2x": {
      "type": "structure",
      "members": {
        "DocumentClassifierArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S1k"
        },
        "OutputDataConfig": {
          "shape": "S1n"
        },
        "ClassifierMetadata": {
          "type": "structure",
          "members": {
            "NumberOfLabels": {
              "type": "integer"
            },
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Accuracy": {
                  "type": "double"
                },
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                },
                "MicroPrecision": {
                  "type": "double"
                },
                "MicroRecall": {
                  "type": "double"
                },
                "MicroF1Score": {
                  "type": "double"
                },
                "HammingLoss": {
                  "type": "double"
                }
              }
            }
          }
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        },
        "Mode": {}
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "EndpointArn": {},
        "Status": {},
        "Message": {},
        "ModelArn": {},
        "DesiredInferenceUnits": {
          "type": "integer"
        },
        "CurrentInferenceUnits": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        }
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "EntityRecognizerArn": {},
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "EntityRecognizerArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S25"
        },
        "RecognizerMetadata": {
          "type": "structure",
          "members": {
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                }
              }
            },
            "EntityTypes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Type": {},
                  "EvaluationMetrics": {
                    "type": "structure",
                    "members": {
                      "Precision": {
                        "type": "double"
                      },
                      "Recall": {
                        "type": "double"
                      },
                      "F1Score": {
                        "type": "double"
                      }
                    }
                  },
                  "NumberOfTrainMentions": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S3m": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S3p": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2s"
        },
        "OutputDataConfig": {
          "shape": "S2u"
        },
        "NumberOfTopics": {
          "type": "integer"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1q"
        }
      }
    }
  }
}
},{}],52:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListDocumentClassificationJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDocumentClassifiers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDominantLanguageDetectionJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListEntitiesDetectionJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListEntityRecognizers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListKeyPhrasesDetectionJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSentimentDetectionJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListTopicsDetectionJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],53:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-10-30",
    "endpointPrefix": "comprehendmedical",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "ComprehendMedical",
    "serviceFullName": "AWS Comprehend Medical",
    "serviceId": "ComprehendMedical",
    "signatureVersion": "v4",
    "signingName": "comprehendmedical",
    "targetPrefix": "ComprehendMedical_20181030",
    "uid": "comprehendmedical-2018-10-30"
  },
  "operations": {
    "DescribeEntitiesDetectionV2Job": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobProperties": {
            "shape": "S4"
          }
        }
      }
    },
    "DescribeICD10CMInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobProperties": {
            "shape": "S4"
          }
        }
      }
    },
    "DescribePHIDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobProperties": {
            "shape": "S4"
          }
        }
      }
    },
    "DescribeRxNormInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobProperties": {
            "shape": "S4"
          }
        }
      }
    },
    "DetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entities",
          "ModelVersion"
        ],
        "members": {
          "Entities": {
            "shape": "Sr"
          },
          "UnmappedAttributes": {
            "shape": "S14"
          },
          "PaginationToken": {},
          "ModelVersion": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This operation is deprecated, use DetectEntitiesV2 instead."
    },
    "DetectEntitiesV2": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entities",
          "ModelVersion"
        ],
        "members": {
          "Entities": {
            "shape": "Sr"
          },
          "UnmappedAttributes": {
            "shape": "S14"
          },
          "PaginationToken": {},
          "ModelVersion": {}
        }
      }
    },
    "DetectPHI": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entities",
          "ModelVersion"
        ],
        "members": {
          "Entities": {
            "shape": "Sr"
          },
          "PaginationToken": {},
          "ModelVersion": {}
        }
      }
    },
    "InferICD10CM": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entities"
        ],
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {
                  "type": "integer"
                },
                "Text": {},
                "Category": {},
                "Type": {},
                "Score": {
                  "type": "float"
                },
                "BeginOffset": {
                  "type": "integer"
                },
                "EndOffset": {
                  "type": "integer"
                },
                "Attributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Type": {},
                      "Score": {
                        "type": "float"
                      },
                      "RelationshipScore": {
                        "type": "float"
                      },
                      "Id": {
                        "type": "integer"
                      },
                      "BeginOffset": {
                        "type": "integer"
                      },
                      "EndOffset": {
                        "type": "integer"
                      },
                      "Text": {},
                      "Traits": {
                        "shape": "S1k"
                      }
                    }
                  }
                },
                "Traits": {
                  "shape": "S1k"
                },
                "ICD10CMConcepts": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Description": {},
                      "Code": {},
                      "Score": {
                        "type": "float"
                      }
                    }
                  }
                }
              }
            }
          },
          "PaginationToken": {},
          "ModelVersion": {}
        }
      }
    },
    "InferRxNorm": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entities"
        ],
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {
                  "type": "integer"
                },
                "Text": {},
                "Category": {},
                "Type": {},
                "Score": {
                  "type": "float"
                },
                "BeginOffset": {
                  "type": "integer"
                },
                "EndOffset": {
                  "type": "integer"
                },
                "Attributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Type": {},
                      "Score": {
                        "type": "float"
                      },
                      "RelationshipScore": {
                        "type": "float"
                      },
                      "Id": {
                        "type": "integer"
                      },
                      "BeginOffset": {
                        "type": "integer"
                      },
                      "EndOffset": {
                        "type": "integer"
                      },
                      "Text": {},
                      "Traits": {
                        "shape": "S1y"
                      }
                    }
                  }
                },
                "Traits": {
                  "shape": "S1y"
                },
                "RxNormConcepts": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Description": {},
                      "Code": {},
                      "Score": {
                        "type": "float"
                      }
                    }
                  }
                }
              }
            }
          },
          "PaginationToken": {},
          "ModelVersion": {}
        }
      }
    },
    "ListEntitiesDetectionV2Jobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S24"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobPropertiesList": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "ListICD10CMInferenceJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S24"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobPropertiesList": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "ListPHIDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S24"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobPropertiesList": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "ListRxNormInferenceJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S24"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComprehendMedicalAsyncJobPropertiesList": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "StartEntitiesDetectionV2Job": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S9"
          },
          "OutputDataConfig": {
            "shape": "Sc"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "KMSKey": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartICD10CMInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S9"
          },
          "OutputDataConfig": {
            "shape": "Sc"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "KMSKey": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartPHIDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S9"
          },
          "OutputDataConfig": {
            "shape": "Sc"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "KMSKey": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartRxNormInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S9"
          },
          "OutputDataConfig": {
            "shape": "Sc"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "KMSKey": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StopEntitiesDetectionV2Job": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StopICD10CMInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StopPHIDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StopRxNormInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "ExpirationTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S9"
        },
        "OutputDataConfig": {
          "shape": "Sc"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "ManifestFilePath": {},
        "KMSKey": {},
        "ModelVersion": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "S3Bucket"
      ],
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "Sc": {
      "type": "structure",
      "required": [
        "S3Bucket"
      ],
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {
            "type": "integer"
          },
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          },
          "Score": {
            "type": "float"
          },
          "Text": {},
          "Category": {},
          "Type": {},
          "Traits": {
            "shape": "Sy"
          },
          "Attributes": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          }
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "Type": {},
        "Score": {
          "type": "float"
        },
        "RelationshipScore": {
          "type": "float"
        },
        "RelationshipType": {},
        "Id": {
          "type": "integer"
        },
        "BeginOffset": {
          "type": "integer"
        },
        "EndOffset": {
          "type": "integer"
        },
        "Text": {},
        "Category": {},
        "Traits": {
          "shape": "Sy"
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Attribute": {
            "shape": "S12"
          }
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "JobName": {},
        "JobStatus": {},
        "SubmitTimeBefore": {
          "type": "timestamp"
        },
        "SubmitTimeAfter": {
          "type": "timestamp"
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    }
  }
}
},{}],54:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],55:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-12",
    "endpointPrefix": "config",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Config Service",
    "serviceFullName": "AWS Config",
    "serviceId": "Config Service",
    "signatureVersion": "v4",
    "targetPrefix": "StarlingDoveService",
    "uid": "config-2014-11-12"
  },
  "operations": {
    "BatchGetAggregateResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceIdentifiers"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaseConfigurationItems": {
            "shape": "Sb"
          },
          "UnprocessedResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      }
    },
    "BatchGetResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "resourceKeys"
        ],
        "members": {
          "resourceKeys": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "baseConfigurationItems": {
            "shape": "Sb"
          },
          "unprocessedResourceKeys": {
            "shape": "Sq"
          }
        }
      }
    },
    "DeleteAggregationAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "AuthorizedAccountId",
          "AuthorizedAwsRegion"
        ],
        "members": {
          "AuthorizedAccountId": {},
          "AuthorizedAwsRegion": {}
        }
      }
    },
    "DeleteConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      }
    },
    "DeleteConfigurationAggregator": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {}
        }
      }
    },
    "DeleteConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "DeleteConformancePack": {
      "input": {
        "type": "structure",
        "required": [
          "ConformancePackName"
        ],
        "members": {
          "ConformancePackName": {}
        }
      }
    },
    "DeleteDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannelName"
        ],
        "members": {
          "DeliveryChannelName": {}
        }
      }
    },
    "DeleteEvaluationResults": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOrganizationConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {}
        }
      }
    },
    "DeleteOrganizationConformancePack": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConformancePackName"
        ],
        "members": {
          "OrganizationConformancePackName": {}
        }
      }
    },
    "DeletePendingAggregationRequest": {
      "input": {
        "type": "structure",
        "required": [
          "RequesterAccountId",
          "RequesterAwsRegion"
        ],
        "members": {
          "RequesterAccountId": {},
          "RequesterAwsRegion": {}
        }
      }
    },
    "DeleteRemediationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRemediationExceptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "ResourceKeys"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedBatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FailureMessage": {},
                "FailedItems": {
                  "shape": "S1f"
                }
              }
            }
          }
        }
      }
    },
    "DeleteResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {}
        }
      }
    },
    "DeleteRetentionConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "RetentionConfigurationName"
        ],
        "members": {
          "RetentionConfigurationName": {}
        }
      }
    },
    "DeliverConfigSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "deliveryChannelName"
        ],
        "members": {
          "deliveryChannelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configSnapshotId": {}
        }
      }
    },
    "DescribeAggregateComplianceByConfigRules": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ConfigRuleName": {},
              "ComplianceType": {},
              "AccountId": {},
              "AwsRegion": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregateComplianceByConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "Compliance": {
                  "shape": "S20"
                },
                "AccountId": {},
                "AwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAggregationAuthorizations": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregationAuthorizations": {
            "type": "list",
            "member": {
              "shape": "S28"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeComplianceByConfigRule": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S2b"
          },
          "ComplianceTypes": {
            "shape": "S2c"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "Compliance": {
                  "shape": "S20"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeComplianceByResource": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "S2c"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ResourceId": {},
                "Compliance": {
                  "shape": "S20"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRuleEvaluationStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S2b"
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRulesEvaluationStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "ConfigRuleArn": {},
                "ConfigRuleId": {},
                "LastSuccessfulInvocationTime": {
                  "type": "timestamp"
                },
                "LastFailedInvocationTime": {
                  "type": "timestamp"
                },
                "LastSuccessfulEvaluationTime": {
                  "type": "timestamp"
                },
                "LastFailedEvaluationTime": {
                  "type": "timestamp"
                },
                "FirstActivatedTime": {
                  "type": "timestamp"
                },
                "LastDeactivatedTime": {
                  "type": "timestamp"
                },
                "LastErrorCode": {},
                "LastErrorMessage": {},
                "FirstEvaluationStarted": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRules": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S2b"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRules": {
            "type": "list",
            "member": {
              "shape": "S2t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationAggregatorSourcesStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "UpdateStatus": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregatedSourceStatusList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceId": {},
                "SourceType": {},
                "AwsRegion": {},
                "LastUpdateStatus": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                },
                "LastErrorCode": {},
                "LastErrorMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationAggregators": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationAggregatorNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationAggregators": {
            "type": "list",
            "member": {
              "shape": "S3h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationRecorderStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecordersStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "lastStartTime": {
                  "type": "timestamp"
                },
                "lastStopTime": {
                  "type": "timestamp"
                },
                "recording": {
                  "type": "boolean"
                },
                "lastStatus": {},
                "lastErrorCode": {},
                "lastErrorMessage": {},
                "lastStatusChangeTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeConfigurationRecorders": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S3p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecorders": {
            "type": "list",
            "member": {
              "shape": "S3x"
            }
          }
        }
      }
    },
    "DescribeConformancePackCompliance": {
      "input": {
        "type": "structure",
        "required": [
          "ConformancePackName"
        ],
        "members": {
          "ConformancePackName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ConfigRuleNames": {
                "shape": "S44"
              },
              "ComplianceType": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConformancePackName",
          "ConformancePackRuleComplianceList"
        ],
        "members": {
          "ConformancePackName": {},
          "ConformancePackRuleComplianceList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "ComplianceType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConformancePackStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConformancePackNames": {
            "shape": "S4b"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConformancePackStatusDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ConformancePackName",
                "ConformancePackId",
                "ConformancePackArn",
                "ConformancePackState",
                "StackArn",
                "LastUpdateRequestedTime"
              ],
              "members": {
                "ConformancePackName": {},
                "ConformancePackId": {},
                "ConformancePackArn": {},
                "ConformancePackState": {},
                "StackArn": {},
                "ConformancePackStatusReason": {},
                "LastUpdateRequestedTime": {
                  "type": "timestamp"
                },
                "LastUpdateCompletedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConformancePacks": {
      "input": {
        "type": "structure",
        "members": {
          "ConformancePackNames": {
            "shape": "S4b"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConformancePackDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ConformancePackName",
                "ConformancePackArn",
                "ConformancePackId",
                "DeliveryS3Bucket"
              ],
              "members": {
                "ConformancePackName": {},
                "ConformancePackArn": {},
                "ConformancePackId": {},
                "DeliveryS3Bucket": {},
                "DeliveryS3KeyPrefix": {},
                "ConformancePackInputParameters": {
                  "shape": "S4r"
                },
                "LastUpdateRequestedTime": {
                  "type": "timestamp"
                },
                "CreatedBy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDeliveryChannelStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S4w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannelsStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "configSnapshotDeliveryInfo": {
                  "shape": "S50"
                },
                "configHistoryDeliveryInfo": {
                  "shape": "S50"
                },
                "configStreamDeliveryInfo": {
                  "type": "structure",
                  "members": {
                    "lastStatus": {},
                    "lastErrorCode": {},
                    "lastErrorMessage": {},
                    "lastStatusChangeTime": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDeliveryChannels": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S4w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannels": {
            "type": "list",
            "member": {
              "shape": "S56"
            }
          }
        }
      }
    },
    "DescribeOrganizationConfigRuleStatuses": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleNames": {
            "shape": "S59"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConfigRuleName",
                "OrganizationRuleStatus"
              ],
              "members": {
                "OrganizationConfigRuleName": {},
                "OrganizationRuleStatus": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOrganizationConfigRules": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleNames": {
            "shape": "S59"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConfigRuleName",
                "OrganizationConfigRuleArn"
              ],
              "members": {
                "OrganizationConfigRuleName": {},
                "OrganizationConfigRuleArn": {},
                "OrganizationManagedRuleMetadata": {
                  "shape": "S5j"
                },
                "OrganizationCustomRuleMetadata": {
                  "shape": "S5o"
                },
                "ExcludedAccounts": {
                  "shape": "S5r"
                },
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOrganizationConformancePackStatuses": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConformancePackNames": {
            "shape": "S5t"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConformancePackStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConformancePackName",
                "Status"
              ],
              "members": {
                "OrganizationConformancePackName": {},
                "Status": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOrganizationConformancePacks": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConformancePackNames": {
            "shape": "S5t"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConformancePacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConformancePackName",
                "OrganizationConformancePackArn",
                "DeliveryS3Bucket",
                "LastUpdateTime"
              ],
              "members": {
                "OrganizationConformancePackName": {},
                "OrganizationConformancePackArn": {},
                "DeliveryS3Bucket": {},
                "DeliveryS3KeyPrefix": {},
                "ConformancePackInputParameters": {
                  "shape": "S4r"
                },
                "ExcludedAccounts": {
                  "shape": "S5r"
                },
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePendingAggregationRequests": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PendingAggregationRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RequesterAccountId": {},
                "RequesterAwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRemediationConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleNames"
        ],
        "members": {
          "ConfigRuleNames": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RemediationConfigurations": {
            "shape": "S69"
          }
        }
      }
    },
    "DescribeRemediationExceptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "S1f"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RemediationExceptions": {
            "shape": "S6p"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRemediationExecutionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "Sq"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RemediationExecutionStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceKey": {
                  "shape": "Sr"
                },
                "State": {},
                "StepDetails": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "State": {},
                      "ErrorMessage": {},
                      "StartTime": {
                        "type": "timestamp"
                      },
                      "StopTime": {
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "InvocationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRetentionConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "RetentionConfigurationNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RetentionConfigurations": {
            "type": "list",
            "member": {
              "shape": "S73"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateComplianceDetailsByConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ConfigRuleName",
          "AccountId",
          "AwsRegion"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ConfigRuleName": {},
          "AccountId": {},
          "AwsRegion": {},
          "ComplianceType": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregateEvaluationResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EvaluationResultIdentifier": {
                  "shape": "S79"
                },
                "ComplianceType": {},
                "ResultRecordedTime": {
                  "type": "timestamp"
                },
                "ConfigRuleInvokedTime": {
                  "type": "timestamp"
                },
                "Annotation": {},
                "AccountId": {},
                "AwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateConfigRuleComplianceSummary": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "AwsRegion": {}
            }
          },
          "GroupByKey": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupByKey": {},
          "AggregateComplianceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "ComplianceSummary": {
                  "shape": "S7h"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateDiscoveredResourceCounts": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ResourceType": {},
              "AccountId": {},
              "Region": {}
            }
          },
          "GroupByKey": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TotalDiscoveredResources"
        ],
        "members": {
          "TotalDiscoveredResources": {
            "type": "long"
          },
          "GroupByKey": {},
          "GroupedResourceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GroupName",
                "ResourceCount"
              ],
              "members": {
                "GroupName": {},
                "ResourceCount": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceIdentifier"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceIdentifier": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationItem": {
            "shape": "S7r"
          }
        }
      }
    },
    "GetComplianceDetailsByConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ComplianceTypes": {
            "shape": "S2c"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S83"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceDetailsByResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "S2c"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S83"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceSummaryByConfigRule": {
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummary": {
            "shape": "S7h"
          }
        }
      }
    },
    "GetComplianceSummaryByResourceType": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceTypes": {
            "shape": "S89"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummariesByResourceType": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ComplianceSummary": {
                  "shape": "S7h"
                }
              }
            }
          }
        }
      }
    },
    "GetConformancePackComplianceDetails": {
      "input": {
        "type": "structure",
        "required": [
          "ConformancePackName"
        ],
        "members": {
          "ConformancePackName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ConfigRuleNames": {
                "shape": "S44"
              },
              "ComplianceType": {},
              "ResourceType": {},
              "ResourceIds": {
                "type": "list",
                "member": {}
              }
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConformancePackName"
        ],
        "members": {
          "ConformancePackName": {},
          "ConformancePackRuleEvaluationResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ComplianceType",
                "EvaluationResultIdentifier",
                "ConfigRuleInvokedTime",
                "ResultRecordedTime"
              ],
              "members": {
                "ComplianceType": {},
                "EvaluationResultIdentifier": {
                  "shape": "S79"
                },
                "ConfigRuleInvokedTime": {
                  "type": "timestamp"
                },
                "ResultRecordedTime": {
                  "type": "timestamp"
                },
                "Annotation": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetConformancePackComplianceSummary": {
      "input": {
        "type": "structure",
        "required": [
          "ConformancePackNames"
        ],
        "members": {
          "ConformancePackNames": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConformancePackComplianceSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ConformancePackName",
                "ConformancePackComplianceStatus"
              ],
              "members": {
                "ConformancePackName": {},
                "ConformancePackComplianceStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetDiscoveredResourceCounts": {
      "input": {
        "type": "structure",
        "members": {
          "resourceTypes": {
            "shape": "S89"
          },
          "limit": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "totalDiscoveredResources": {
            "type": "long"
          },
          "resourceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "count": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetOrganizationConfigRuleDetailedStatus": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "MemberAccountRuleStatus": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleDetailedStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AccountId",
                "ConfigRuleName",
                "MemberAccountRuleStatus"
              ],
              "members": {
                "AccountId": {},
                "ConfigRuleName": {},
                "MemberAccountRuleStatus": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetOrganizationConformancePackDetailedStatus": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConformancePackName"
        ],
        "members": {
          "OrganizationConformancePackName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "Status": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConformancePackDetailedStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AccountId",
                "ConformancePackName",
                "Status"
              ],
              "members": {
                "AccountId": {},
                "ConformancePackName": {},
                "Status": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetResourceConfigHistory": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType",
          "resourceId"
        ],
        "members": {
          "resourceType": {},
          "resourceId": {},
          "laterTime": {
            "type": "timestamp"
          },
          "earlierTime": {
            "type": "timestamp"
          },
          "chronologicalOrder": {},
          "limit": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationItems": {
            "type": "list",
            "member": {
              "shape": "S7r"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAggregateDiscoveredResources": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceType"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceType": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "ResourceId": {},
              "ResourceName": {},
              "Region": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDiscoveredResources": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType"
        ],
        "members": {
          "resourceType": {},
          "resourceIds": {
            "type": "list",
            "member": {}
          },
          "resourceName": {},
          "limit": {
            "type": "integer"
          },
          "includeDeletedResources": {
            "type": "boolean"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "resourceId": {},
                "resourceName": {},
                "resourceDeletionTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S9p"
          },
          "NextToken": {}
        }
      }
    },
    "PutAggregationAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "AuthorizedAccountId",
          "AuthorizedAwsRegion"
        ],
        "members": {
          "AuthorizedAccountId": {},
          "AuthorizedAwsRegion": {},
          "Tags": {
            "shape": "S9u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregationAuthorization": {
            "shape": "S28"
          }
        }
      }
    },
    "PutConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRule"
        ],
        "members": {
          "ConfigRule": {
            "shape": "S2t"
          },
          "Tags": {
            "shape": "S9u"
          }
        }
      }
    },
    "PutConfigurationAggregator": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "AccountAggregationSources": {
            "shape": "S3j"
          },
          "OrganizationAggregationSource": {
            "shape": "S3n"
          },
          "Tags": {
            "shape": "S9u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationAggregator": {
            "shape": "S3h"
          }
        }
      }
    },
    "PutConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorder"
        ],
        "members": {
          "ConfigurationRecorder": {
            "shape": "S3x"
          }
        }
      }
    },
    "PutConformancePack": {
      "input": {
        "type": "structure",
        "required": [
          "ConformancePackName",
          "DeliveryS3Bucket"
        ],
        "members": {
          "ConformancePackName": {},
          "TemplateS3Uri": {},
          "TemplateBody": {},
          "DeliveryS3Bucket": {},
          "DeliveryS3KeyPrefix": {},
          "ConformancePackInputParameters": {
            "shape": "S4r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConformancePackArn": {}
        }
      }
    },
    "PutDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannel"
        ],
        "members": {
          "DeliveryChannel": {
            "shape": "S56"
          }
        }
      }
    },
    "PutEvaluations": {
      "input": {
        "type": "structure",
        "required": [
          "ResultToken"
        ],
        "members": {
          "Evaluations": {
            "shape": "Sa6"
          },
          "ResultToken": {},
          "TestMode": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEvaluations": {
            "shape": "Sa6"
          }
        }
      }
    },
    "PutOrganizationConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {},
          "OrganizationManagedRuleMetadata": {
            "shape": "S5j"
          },
          "OrganizationCustomRuleMetadata": {
            "shape": "S5o"
          },
          "ExcludedAccounts": {
            "shape": "S5r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleArn": {}
        }
      }
    },
    "PutOrganizationConformancePack": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConformancePackName",
          "DeliveryS3Bucket"
        ],
        "members": {
          "OrganizationConformancePackName": {},
          "TemplateS3Uri": {},
          "TemplateBody": {},
          "DeliveryS3Bucket": {},
          "DeliveryS3KeyPrefix": {},
          "ConformancePackInputParameters": {
            "shape": "S4r"
          },
          "ExcludedAccounts": {
            "shape": "S5r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConformancePackArn": {}
        }
      }
    },
    "PutRemediationConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "RemediationConfigurations"
        ],
        "members": {
          "RemediationConfigurations": {
            "shape": "S69"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedBatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FailureMessage": {},
                "FailedItems": {
                  "shape": "S69"
                }
              }
            }
          }
        }
      }
    },
    "PutRemediationExceptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "ResourceKeys"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "S1f"
          },
          "Message": {},
          "ExpirationTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedBatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FailureMessage": {},
                "FailedItems": {
                  "shape": "S6p"
                }
              }
            }
          }
        }
      }
    },
    "PutResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "SchemaVersionId",
          "ResourceId",
          "Configuration"
        ],
        "members": {
          "ResourceType": {},
          "SchemaVersionId": {},
          "ResourceId": {},
          "ResourceName": {},
          "Configuration": {},
          "Tags": {
            "shape": "S7t"
          }
        }
      }
    },
    "PutRetentionConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "RetentionPeriodInDays"
        ],
        "members": {
          "RetentionPeriodInDays": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RetentionConfiguration": {
            "shape": "S73"
          }
        }
      }
    },
    "SelectAggregateResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "Expression",
          "ConfigurationAggregatorName"
        ],
        "members": {
          "Expression": {},
          "ConfigurationAggregatorName": {},
          "Limit": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "shape": "Sat"
          },
          "QueryInfo": {
            "shape": "Sau"
          },
          "NextToken": {}
        }
      }
    },
    "SelectResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "Expression": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "shape": "Sat"
          },
          "QueryInfo": {
            "shape": "Sau"
          },
          "NextToken": {}
        }
      }
    },
    "StartConfigRulesEvaluation": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "StartRemediationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "ResourceKeys"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailureMessage": {},
          "FailedItems": {
            "shape": "Sq"
          }
        }
      }
    },
    "StopConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S9p"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "SourceAccountId",
        "SourceRegion",
        "ResourceId",
        "ResourceType"
      ],
      "members": {
        "SourceAccountId": {},
        "SourceRegion": {},
        "ResourceId": {},
        "ResourceType": {},
        "ResourceName": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "version": {},
          "accountId": {},
          "configurationItemCaptureTime": {
            "type": "timestamp"
          },
          "configurationItemStatus": {},
          "configurationStateId": {},
          "arn": {},
          "resourceType": {},
          "resourceId": {},
          "resourceName": {},
          "awsRegion": {},
          "availabilityZone": {},
          "resourceCreationTime": {
            "type": "timestamp"
          },
          "configuration": {},
          "supplementaryConfiguration": {
            "shape": "Sl"
          }
        }
      }
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sq": {
      "type": "list",
      "member": {
        "shape": "Sr"
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "resourceType",
        "resourceId"
      ],
      "members": {
        "resourceType": {},
        "resourceId": {}
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "ResourceId": {}
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "ComplianceType": {},
        "ComplianceContributorCount": {
          "shape": "S21"
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "CappedCount": {
          "type": "integer"
        },
        "CapExceeded": {
          "type": "boolean"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "AggregationAuthorizationArn": {},
        "AuthorizedAccountId": {},
        "AuthorizedAwsRegion": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2c": {
      "type": "list",
      "member": {}
    },
    "S2t": {
      "type": "structure",
      "required": [
        "Source"
      ],
      "members": {
        "ConfigRuleName": {},
        "ConfigRuleArn": {},
        "ConfigRuleId": {},
        "Description": {},
        "Scope": {
          "type": "structure",
          "members": {
            "ComplianceResourceTypes": {
              "type": "list",
              "member": {}
            },
            "TagKey": {},
            "TagValue": {},
            "ComplianceResourceId": {}
          }
        },
        "Source": {
          "type": "structure",
          "required": [
            "Owner",
            "SourceIdentifier"
          ],
          "members": {
            "Owner": {},
            "SourceIdentifier": {},
            "SourceDetails": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "EventSource": {},
                  "MessageType": {},
                  "MaximumExecutionFrequency": {}
                }
              }
            }
          }
        },
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ConfigRuleState": {},
        "CreatedBy": {}
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "ConfigurationAggregatorName": {},
        "ConfigurationAggregatorArn": {},
        "AccountAggregationSources": {
          "shape": "S3j"
        },
        "OrganizationAggregationSource": {
          "shape": "S3n"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "AllAwsRegions": {
            "type": "boolean"
          },
          "AwsRegions": {
            "shape": "S3m"
          }
        }
      }
    },
    "S3m": {
      "type": "list",
      "member": {}
    },
    "S3n": {
      "type": "structure",
      "required": [
        "RoleArn"
      ],
      "members": {
        "RoleArn": {},
        "AwsRegions": {
          "shape": "S3m"
        },
        "AllAwsRegions": {
          "type": "boolean"
        }
      }
    },
    "S3p": {
      "type": "list",
      "member": {}
    },
    "S3x": {
      "type": "structure",
      "members": {
        "name": {},
        "roleARN": {},
        "recordingGroup": {
          "type": "structure",
          "members": {
            "allSupported": {
              "type": "boolean"
            },
            "includeGlobalResourceTypes": {
              "type": "boolean"
            },
            "resourceTypes": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S44": {
      "type": "list",
      "member": {}
    },
    "S4b": {
      "type": "list",
      "member": {}
    },
    "S4r": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ParameterName",
          "ParameterValue"
        ],
        "members": {
          "ParameterName": {},
          "ParameterValue": {}
        }
      }
    },
    "S4w": {
      "type": "list",
      "member": {}
    },
    "S50": {
      "type": "structure",
      "members": {
        "lastStatus": {},
        "lastErrorCode": {},
        "lastErrorMessage": {},
        "lastAttemptTime": {
          "type": "timestamp"
        },
        "lastSuccessfulTime": {
          "type": "timestamp"
        },
        "nextDeliveryTime": {
          "type": "timestamp"
        }
      }
    },
    "S56": {
      "type": "structure",
      "members": {
        "name": {},
        "s3BucketName": {},
        "s3KeyPrefix": {},
        "snsTopicARN": {},
        "configSnapshotDeliveryProperties": {
          "type": "structure",
          "members": {
            "deliveryFrequency": {}
          }
        }
      }
    },
    "S59": {
      "type": "list",
      "member": {}
    },
    "S5j": {
      "type": "structure",
      "required": [
        "RuleIdentifier"
      ],
      "members": {
        "Description": {},
        "RuleIdentifier": {},
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ResourceTypesScope": {
          "shape": "S5m"
        },
        "ResourceIdScope": {},
        "TagKeyScope": {},
        "TagValueScope": {}
      }
    },
    "S5m": {
      "type": "list",
      "member": {}
    },
    "S5o": {
      "type": "structure",
      "required": [
        "LambdaFunctionArn",
        "OrganizationConfigRuleTriggerTypes"
      ],
      "members": {
        "Description": {},
        "LambdaFunctionArn": {},
        "OrganizationConfigRuleTriggerTypes": {
          "type": "list",
          "member": {}
        },
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ResourceTypesScope": {
          "shape": "S5m"
        },
        "ResourceIdScope": {},
        "TagKeyScope": {},
        "TagValueScope": {}
      }
    },
    "S5r": {
      "type": "list",
      "member": {}
    },
    "S5t": {
      "type": "list",
      "member": {}
    },
    "S69": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "TargetType",
          "TargetId"
        ],
        "members": {
          "ConfigRuleName": {},
          "TargetType": {},
          "TargetId": {},
          "TargetVersion": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "ResourceValue": {
                  "type": "structure",
                  "required": [
                    "Value"
                  ],
                  "members": {
                    "Value": {}
                  }
                },
                "StaticValue": {
                  "type": "structure",
                  "required": [
                    "Values"
                  ],
                  "members": {
                    "Values": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "ResourceType": {},
          "Automatic": {
            "type": "boolean"
          },
          "ExecutionControls": {
            "type": "structure",
            "members": {
              "SsmControls": {
                "type": "structure",
                "members": {
                  "ConcurrentExecutionRatePercentage": {
                    "type": "integer"
                  },
                  "ErrorPercentage": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "MaximumAutomaticAttempts": {
            "type": "integer"
          },
          "RetryAttemptSeconds": {
            "type": "long"
          },
          "Arn": {},
          "CreatedByService": {}
        }
      }
    },
    "S6p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceType": {},
          "ResourceId": {},
          "Message": {},
          "ExpirationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S73": {
      "type": "structure",
      "required": [
        "Name",
        "RetentionPeriodInDays"
      ],
      "members": {
        "Name": {},
        "RetentionPeriodInDays": {
          "type": "integer"
        }
      }
    },
    "S79": {
      "type": "structure",
      "members": {
        "EvaluationResultQualifier": {
          "type": "structure",
          "members": {
            "ConfigRuleName": {},
            "ResourceType": {},
            "ResourceId": {}
          }
        },
        "OrderingTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S7h": {
      "type": "structure",
      "members": {
        "CompliantResourceCount": {
          "shape": "S21"
        },
        "NonCompliantResourceCount": {
          "shape": "S21"
        },
        "ComplianceSummaryTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S7r": {
      "type": "structure",
      "members": {
        "version": {},
        "accountId": {},
        "configurationItemCaptureTime": {
          "type": "timestamp"
        },
        "configurationItemStatus": {},
        "configurationStateId": {},
        "configurationItemMD5Hash": {},
        "arn": {},
        "resourceType": {},
        "resourceId": {},
        "resourceName": {},
        "awsRegion": {},
        "availabilityZone": {},
        "resourceCreationTime": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S7t"
        },
        "relatedEvents": {
          "type": "list",
          "member": {}
        },
        "relationships": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceId": {},
              "resourceName": {},
              "relationshipName": {}
            }
          }
        },
        "configuration": {},
        "supplementaryConfiguration": {
          "shape": "Sl"
        }
      }
    },
    "S7t": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S83": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EvaluationResultIdentifier": {
            "shape": "S79"
          },
          "ComplianceType": {},
          "ResultRecordedTime": {
            "type": "timestamp"
          },
          "ConfigRuleInvokedTime": {
            "type": "timestamp"
          },
          "Annotation": {},
          "ResultToken": {}
        }
      }
    },
    "S89": {
      "type": "list",
      "member": {}
    },
    "S9p": {
      "type": "list",
      "member": {
        "shape": "S9q"
      }
    },
    "S9q": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S9u": {
      "type": "list",
      "member": {
        "shape": "S9q"
      }
    },
    "Sa6": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ComplianceResourceType",
          "ComplianceResourceId",
          "ComplianceType",
          "OrderingTimestamp"
        ],
        "members": {
          "ComplianceResourceType": {},
          "ComplianceResourceId": {},
          "ComplianceType": {},
          "Annotation": {},
          "OrderingTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sat": {
      "type": "list",
      "member": {}
    },
    "Sau": {
      "type": "structure",
      "members": {
        "SelectFields": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {}
            }
          }
        }
      }
    }
  }
}
},{}],56:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeRemediationExceptions": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken"
    },
    "DescribeRemediationExecutionStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "RemediationExecutionStatuses"
    },
    "GetResourceConfigHistory": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "configurationItems"
    },
    "SelectAggregateResourceConfig": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}
},{}],57:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-08-08",
    "endpointPrefix": "connect",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon Connect",
    "serviceFullName": "Amazon Connect Service",
    "serviceId": "Connect",
    "signatureVersion": "v4",
    "signingName": "connect",
    "uid": "connect-2017-08-08"
  },
  "operations": {
    "CreateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/users/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "PhoneConfig",
          "SecurityProfileIds",
          "RoutingProfileId",
          "InstanceId"
        ],
        "members": {
          "Username": {},
          "Password": {},
          "IdentityInfo": {
            "shape": "S4"
          },
          "PhoneConfig": {
            "shape": "S8"
          },
          "DirectoryUserId": {},
          "SecurityProfileIds": {
            "shape": "Se"
          },
          "RoutingProfileId": {},
          "HierarchyGroupId": {},
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "UserArn": {}
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          }
        }
      }
    },
    "DescribeUser": {
      "http": {
        "method": "GET",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Username": {},
              "IdentityInfo": {
                "shape": "S4"
              },
              "PhoneConfig": {
                "shape": "S8"
              },
              "DirectoryUserId": {},
              "SecurityProfileIds": {
                "shape": "Se"
              },
              "RoutingProfileId": {},
              "HierarchyGroupId": {},
              "Tags": {
                "shape": "Sj"
              }
            }
          }
        }
      }
    },
    "DescribeUserHierarchyGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups/{InstanceId}/{HierarchyGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HierarchyGroupId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {
            "location": "uri",
            "locationName": "HierarchyGroupId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyGroup": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Name": {},
              "LevelId": {},
              "HierarchyPath": {
                "type": "structure",
                "members": {
                  "LevelOne": {
                    "shape": "Sz"
                  },
                  "LevelTwo": {
                    "shape": "Sz"
                  },
                  "LevelThree": {
                    "shape": "Sz"
                  },
                  "LevelFour": {
                    "shape": "Sz"
                  },
                  "LevelFive": {
                    "shape": "Sz"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeUserHierarchyStructure": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-structure/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyStructure": {
            "type": "structure",
            "members": {
              "LevelOne": {
                "shape": "S13"
              },
              "LevelTwo": {
                "shape": "S13"
              },
              "LevelThree": {
                "shape": "S13"
              },
              "LevelFour": {
                "shape": "S13"
              },
              "LevelFive": {
                "shape": "S13"
              }
            }
          }
        }
      }
    },
    "GetContactAttributes": {
      "http": {
        "method": "GET",
        "requestUri": "/contact/attributes/{InstanceId}/{InitialContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "InitialContactId": {
            "location": "uri",
            "locationName": "InitialContactId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S18"
          }
        }
      }
    },
    "GetCurrentMetricData": {
      "http": {
        "requestUri": "/metrics/current/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Filters",
          "CurrentMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "S1c"
          },
          "Groupings": {
            "shape": "S1h"
          },
          "CurrentMetrics": {
            "type": "list",
            "member": {
              "shape": "S1k"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "S1s"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "S1k"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "DataSnapshotTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetFederationToken": {
      "http": {
        "method": "GET",
        "requestUri": "/user/federate/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Credentials": {
            "type": "structure",
            "members": {
              "AccessToken": {
                "shape": "S21"
              },
              "AccessTokenExpiration": {
                "type": "timestamp"
              },
              "RefreshToken": {
                "shape": "S21"
              },
              "RefreshTokenExpiration": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetMetricData": {
      "http": {
        "requestUri": "/metrics/historical/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "StartTime",
          "EndTime",
          "Filters",
          "HistoricalMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Filters": {
            "shape": "S1c"
          },
          "Groupings": {
            "shape": "S1h"
          },
          "HistoricalMetrics": {
            "type": "list",
            "member": {
              "shape": "S24"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "S1s"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "S24"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListContactFlows": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-flows-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowTypes": {
            "location": "querystring",
            "locationName": "contactFlowTypes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlowSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "ContactFlowType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHoursOfOperations": {
      "http": {
        "method": "GET",
        "requestUri": "/hours-of-operations-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HoursOfOperationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumbers": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-numbers-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PhoneNumberTypes": {
            "location": "querystring",
            "locationName": "phoneNumberTypes",
            "type": "list",
            "member": {}
          },
          "PhoneNumberCountryCodes": {
            "location": "querystring",
            "locationName": "phoneNumberCountryCodes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "PhoneNumber": {},
                "PhoneNumberType": {},
                "PhoneNumberCountryCode": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListQueues": {
      "http": {
        "method": "GET",
        "requestUri": "/queues-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueTypes": {
            "location": "querystring",
            "locationName": "queueTypes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueueSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "QueueType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRoutingProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/routing-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sj"
          }
        }
      }
    },
    "ListUserHierarchyGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserHierarchyGroupSummaryList": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/users-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Username": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartChatContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/chat"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId",
          "ParticipantDetails"
        ],
        "members": {
          "InstanceId": {},
          "ContactFlowId": {},
          "Attributes": {
            "shape": "S18"
          },
          "ParticipantDetails": {
            "type": "structure",
            "required": [
              "DisplayName"
            ],
            "members": {
              "DisplayName": {}
            }
          },
          "InitialMessage": {
            "type": "structure",
            "required": [
              "ContentType",
              "Content"
            ],
            "members": {
              "ContentType": {},
              "Content": {}
            }
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {},
          "ParticipantId": {},
          "ParticipantToken": {}
        }
      }
    },
    "StartOutboundVoiceContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/outbound-voice"
      },
      "input": {
        "type": "structure",
        "required": [
          "DestinationPhoneNumber",
          "ContactFlowId",
          "InstanceId"
        ],
        "members": {
          "DestinationPhoneNumber": {},
          "ContactFlowId": {},
          "InstanceId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "SourcePhoneNumber": {},
          "QueueId": {},
          "Attributes": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {}
        }
      }
    },
    "StopContact": {
      "http": {
        "requestUri": "/contact/stop"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sj"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateContactAttributes": {
      "http": {
        "requestUri": "/contact/attributes"
      },
      "input": {
        "type": "structure",
        "required": [
          "InitialContactId",
          "InstanceId",
          "Attributes"
        ],
        "members": {
          "InitialContactId": {},
          "InstanceId": {},
          "Attributes": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserHierarchy": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/hierarchy"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserIdentityInfo": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/identity-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityInfo",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "IdentityInfo": {
            "shape": "S4"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserPhoneConfig": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/phone-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneConfig",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "PhoneConfig": {
            "shape": "S8"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserRoutingProfile": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/routing-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "RoutingProfileId",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "RoutingProfileId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserSecurityProfiles": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/security-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileIds",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileIds": {
            "shape": "Se"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "FirstName": {},
        "LastName": {},
        "Email": {}
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "PhoneType"
      ],
      "members": {
        "PhoneType": {},
        "AutoAccept": {
          "type": "boolean"
        },
        "AfterContactWorkTimeLimit": {
          "type": "integer"
        },
        "DeskPhoneNumber": {}
      }
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sz": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {}
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {}
      }
    },
    "S18": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "Queues": {
          "type": "list",
          "member": {}
        },
        "Channels": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Name": {},
        "Unit": {}
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "Queue": {
          "type": "structure",
          "members": {
            "Id": {},
            "Arn": {}
          }
        },
        "Channel": {}
      }
    },
    "S21": {
      "type": "string",
      "sensitive": true
    },
    "S24": {
      "type": "structure",
      "members": {
        "Name": {},
        "Threshold": {
          "type": "structure",
          "members": {
            "Comparison": {},
            "ThresholdValue": {
              "type": "double"
            }
          }
        },
        "Statistic": {},
        "Unit": {}
      }
    }
  }
}
},{}],58:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetCurrentMetricData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetMetricData": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListContactFlows": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ContactFlowSummaryList"
    },
    "ListHoursOfOperations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "HoursOfOperationSummaryList"
    },
    "ListPhoneNumbers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PhoneNumberSummaryList"
    },
    "ListQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QueueSummaryList"
    },
    "ListRoutingProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RoutingProfileSummaryList"
    },
    "ListSecurityProfiles": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityProfileSummaryList"
    },
    "ListUserHierarchyGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserHierarchyGroupSummaryList"
    },
    "ListUsers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "UserSummaryList"
    }
  }
}
},{}],59:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-01-06",
    "endpointPrefix": "cur",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Cost and Usage Report Service",
    "serviceId": "Cost and Usage Report Service",
    "signatureVersion": "v4",
    "signingName": "cur",
    "targetPrefix": "AWSOrigamiServiceGatewayService",
    "uid": "cur-2017-01-06"
  },
  "operations": {
    "DeleteReportDefinition": {
      "input": {
        "type": "structure",
        "members": {
          "ReportName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResponseMessage": {}
        }
      }
    },
    "DescribeReportDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReportDefinitions": {
            "type": "list",
            "member": {
              "shape": "Sa"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ModifyReportDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "ReportName",
          "ReportDefinition"
        ],
        "members": {
          "ReportName": {},
          "ReportDefinition": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutReportDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "ReportDefinition"
        ],
        "members": {
          "ReportDefinition": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "structure",
      "required": [
        "ReportName",
        "TimeUnit",
        "Format",
        "Compression",
        "AdditionalSchemaElements",
        "S3Bucket",
        "S3Prefix",
        "S3Region"
      ],
      "members": {
        "ReportName": {},
        "TimeUnit": {},
        "Format": {},
        "Compression": {},
        "AdditionalSchemaElements": {
          "type": "list",
          "member": {}
        },
        "S3Bucket": {},
        "S3Prefix": {},
        "S3Region": {},
        "AdditionalArtifacts": {
          "type": "list",
          "member": {}
        },
        "RefreshClosedReports": {
          "type": "boolean"
        },
        "ReportVersioning": {}
      }
    }
  }
}
},{}],60:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeReportDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],61:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-06-23",
    "endpointPrefix": "devicefarm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Device Farm",
    "serviceId": "Device Farm",
    "signatureVersion": "v4",
    "targetPrefix": "DeviceFarm_20150623",
    "uid": "devicefarm-2015-06-23"
  },
  "operations": {
    "CreateDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "name",
          "rules"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "description": {},
          "rules": {
            "shape": "S5"
          },
          "maxDevices": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sc"
          }
        }
      }
    },
    "CreateInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "packageCleanup": {
            "type": "boolean"
          },
          "excludeAppPackagesFromCleanup": {
            "shape": "Sg"
          },
          "rebootAfterUse": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceProfile": {
            "shape": "Si"
          }
        }
      }
    },
    "CreateNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "name"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "description": {},
          "type": {},
          "uplinkBandwidthBits": {
            "type": "long"
          },
          "downlinkBandwidthBits": {
            "type": "long"
          },
          "uplinkDelayMs": {
            "type": "long"
          },
          "downlinkDelayMs": {
            "type": "long"
          },
          "uplinkJitterMs": {
            "type": "long"
          },
          "downlinkJitterMs": {
            "type": "long"
          },
          "uplinkLossPercent": {
            "type": "integer"
          },
          "downlinkLossPercent": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkProfile": {
            "shape": "So"
          }
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "defaultJobTimeoutMinutes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Ss"
          }
        }
      }
    },
    "CreateRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "deviceArn"
        ],
        "members": {
          "projectArn": {},
          "deviceArn": {},
          "instanceArn": {},
          "sshPublicKey": {},
          "remoteDebugEnabled": {
            "type": "boolean"
          },
          "remoteRecordEnabled": {
            "type": "boolean"
          },
          "remoteRecordAppArn": {},
          "name": {},
          "clientId": {},
          "configuration": {
            "type": "structure",
            "members": {
              "billingMethod": {},
              "vpceConfigurationArns": {
                "shape": "Sz"
              }
            }
          },
          "interactionMode": {},
          "skipAppResign": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateTestGridProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridProject": {
            "shape": "S1n"
          }
        }
      }
    },
    "CreateTestGridUrl": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "expiresInSeconds"
        ],
        "members": {
          "projectArn": {},
          "expiresInSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "url": {},
          "expires": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateUpload": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "name",
          "type"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "type": {},
          "contentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "upload": {
            "shape": "S1w"
          }
        }
      }
    },
    "CreateVPCEConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "vpceConfigurationName",
          "vpceServiceName",
          "serviceDnsName"
        ],
        "members": {
          "vpceConfigurationName": {},
          "vpceServiceName": {},
          "serviceDnsName": {},
          "vpceConfigurationDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "vpceConfiguration": {
            "shape": "S27"
          }
        }
      }
    },
    "DeleteDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTestGridProject": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn"
        ],
        "members": {
          "projectArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteUpload": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVPCEConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAccountSettings": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "accountSettings": {
            "type": "structure",
            "members": {
              "awsAccountNumber": {},
              "unmeteredDevices": {
                "shape": "S2u"
              },
              "unmeteredRemoteAccessDevices": {
                "shape": "S2u"
              },
              "maxJobTimeoutMinutes": {
                "type": "integer"
              },
              "trialMinutes": {
                "type": "structure",
                "members": {
                  "total": {
                    "type": "double"
                  },
                  "remaining": {
                    "type": "double"
                  }
                }
              },
              "maxSlots": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "integer"
                }
              },
              "defaultJobTimeoutMinutes": {
                "type": "integer"
              },
              "skipAppResign": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "device": {
            "shape": "S15"
          }
        }
      }
    },
    "GetDeviceInstance": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deviceInstance": {
            "shape": "S1c"
          }
        }
      }
    },
    "GetDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetDevicePoolCompatibility": {
      "input": {
        "type": "structure",
        "required": [
          "devicePoolArn"
        ],
        "members": {
          "devicePoolArn": {},
          "appArn": {},
          "testType": {},
          "test": {
            "shape": "S35"
          },
          "configuration": {
            "shape": "S38"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "compatibleDevices": {
            "shape": "S3g"
          },
          "incompatibleDevices": {
            "shape": "S3g"
          }
        }
      }
    },
    "GetInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceProfile": {
            "shape": "Si"
          }
        }
      }
    },
    "GetJob": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S3o"
          }
        }
      }
    },
    "GetNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkProfile": {
            "shape": "So"
          }
        }
      }
    },
    "GetOfferingStatus": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "current": {
            "shape": "S3w"
          },
          "nextPeriod": {
            "shape": "S3w"
          },
          "nextToken": {}
        }
      }
    },
    "GetProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Ss"
          }
        }
      }
    },
    "GetRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "S12"
          }
        }
      }
    },
    "GetRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S4d"
          }
        }
      }
    },
    "GetSuite": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "suite": {
            "shape": "S4m"
          }
        }
      }
    },
    "GetTest": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "test": {
            "shape": "S4p"
          }
        }
      }
    },
    "GetTestGridProject": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn"
        ],
        "members": {
          "projectArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridProject": {
            "shape": "S1n"
          }
        }
      }
    },
    "GetTestGridSession": {
      "input": {
        "type": "structure",
        "members": {
          "projectArn": {},
          "sessionId": {},
          "sessionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridSession": {
            "shape": "S4v"
          }
        }
      }
    },
    "GetUpload": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "upload": {
            "shape": "S1w"
          }
        }
      }
    },
    "GetVPCEConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "vpceConfiguration": {
            "shape": "S27"
          }
        }
      }
    },
    "InstallToRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "remoteAccessSessionArn",
          "appArn"
        ],
        "members": {
          "remoteAccessSessionArn": {},
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "appUpload": {
            "shape": "S1w"
          }
        }
      }
    },
    "ListArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "type"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "artifacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "type": {},
                "extension": {},
                "url": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDeviceInstances": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deviceInstances": {
            "shape": "S1b"
          },
          "nextToken": {}
        }
      }
    },
    "ListDevicePools": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePools": {
            "type": "list",
            "member": {
              "shape": "Sc"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "members": {
          "arn": {},
          "nextToken": {},
          "filters": {
            "shape": "S4g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devices": {
            "type": "list",
            "member": {
              "shape": "S15"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListInstanceProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceProfiles": {
            "type": "list",
            "member": {
              "shape": "Si"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobs": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "shape": "S3o"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListNetworkProfiles": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkProfiles": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOfferingPromotions": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringPromotions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOfferingTransactions": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransactions": {
            "type": "list",
            "member": {
              "shape": "S5y"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offerings": {
            "type": "list",
            "member": {
              "shape": "S40"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProjects": {
      "input": {
        "type": "structure",
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "projects": {
            "type": "list",
            "member": {
              "shape": "Ss"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRemoteAccessSessions": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSessions": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRuns": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "runs": {
            "type": "list",
            "member": {
              "shape": "S4d"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSamples": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "samples": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "type": {},
                "url": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSuites": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "suites": {
            "type": "list",
            "member": {
              "shape": "S4m"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S6m"
          }
        }
      }
    },
    "ListTestGridProjects": {
      "input": {
        "type": "structure",
        "members": {
          "maxResult": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridProjects": {
            "type": "list",
            "member": {
              "shape": "S1n"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTestGridSessionActions": {
      "input": {
        "type": "structure",
        "required": [
          "sessionArn"
        ],
        "members": {
          "sessionArn": {},
          "maxResult": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "action": {},
                "started": {
                  "type": "timestamp"
                },
                "duration": {
                  "type": "long"
                },
                "statusCode": {},
                "requestMethod": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTestGridSessionArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "sessionArn"
        ],
        "members": {
          "sessionArn": {},
          "type": {},
          "maxResult": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "artifacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "filename": {},
                "type": {},
                "url": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTestGridSessions": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn"
        ],
        "members": {
          "projectArn": {},
          "status": {},
          "creationTimeAfter": {
            "type": "timestamp"
          },
          "creationTimeBefore": {
            "type": "timestamp"
          },
          "endTimeAfter": {
            "type": "timestamp"
          },
          "endTimeBefore": {
            "type": "timestamp"
          },
          "maxResult": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridSessions": {
            "type": "list",
            "member": {
              "shape": "S4v"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTests": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tests": {
            "type": "list",
            "member": {
              "shape": "S4p"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListUniqueProblems": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uniqueProblems": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "message": {},
                  "problems": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "run": {
                          "shape": "S7h"
                        },
                        "job": {
                          "shape": "S7h"
                        },
                        "suite": {
                          "shape": "S7h"
                        },
                        "test": {
                          "shape": "S7h"
                        },
                        "device": {
                          "shape": "S15"
                        },
                        "result": {},
                        "message": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListUploads": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uploads": {
            "type": "list",
            "member": {
              "shape": "S1w"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListVPCEConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "vpceConfigurations": {
            "type": "list",
            "member": {
              "shape": "S27"
            }
          },
          "nextToken": {}
        }
      }
    },
    "PurchaseOffering": {
      "input": {
        "type": "structure",
        "members": {
          "offeringId": {},
          "quantity": {
            "type": "integer"
          },
          "offeringPromotionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransaction": {
            "shape": "S5y"
          }
        }
      }
    },
    "RenewOffering": {
      "input": {
        "type": "structure",
        "members": {
          "offeringId": {},
          "quantity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransaction": {
            "shape": "S5y"
          }
        }
      }
    },
    "ScheduleRun": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "test"
        ],
        "members": {
          "projectArn": {},
          "appArn": {},
          "devicePoolArn": {},
          "deviceSelectionConfiguration": {
            "type": "structure",
            "required": [
              "filters",
              "maxDevices"
            ],
            "members": {
              "filters": {
                "shape": "S4g"
              },
              "maxDevices": {
                "type": "integer"
              }
            }
          },
          "name": {},
          "test": {
            "shape": "S35"
          },
          "configuration": {
            "shape": "S38"
          },
          "executionConfiguration": {
            "type": "structure",
            "members": {
              "jobTimeoutMinutes": {
                "type": "integer"
              },
              "accountsCleanup": {
                "type": "boolean"
              },
              "appPackagesCleanup": {
                "type": "boolean"
              },
              "videoCapture": {
                "type": "boolean"
              },
              "skipAppResign": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S4d"
          }
        }
      }
    },
    "StopJob": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S3o"
          }
        }
      }
    },
    "StopRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "S12"
          }
        }
      }
    },
    "StopRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S4d"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S6m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDeviceInstance": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "profileArn": {},
          "labels": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deviceInstance": {
            "shape": "S1c"
          }
        }
      }
    },
    "UpdateDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "description": {},
          "rules": {
            "shape": "S5"
          },
          "maxDevices": {
            "type": "integer"
          },
          "clearMaxDevices": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sc"
          }
        }
      }
    },
    "UpdateInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "description": {},
          "packageCleanup": {
            "type": "boolean"
          },
          "excludeAppPackagesFromCleanup": {
            "shape": "Sg"
          },
          "rebootAfterUse": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceProfile": {
            "shape": "Si"
          }
        }
      }
    },
    "UpdateNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "description": {},
          "type": {},
          "uplinkBandwidthBits": {
            "type": "long"
          },
          "downlinkBandwidthBits": {
            "type": "long"
          },
          "uplinkDelayMs": {
            "type": "long"
          },
          "downlinkDelayMs": {
            "type": "long"
          },
          "uplinkJitterMs": {
            "type": "long"
          },
          "downlinkJitterMs": {
            "type": "long"
          },
          "uplinkLossPercent": {
            "type": "integer"
          },
          "downlinkLossPercent": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkProfile": {
            "shape": "So"
          }
        }
      }
    },
    "UpdateProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "defaultJobTimeoutMinutes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Ss"
          }
        }
      }
    },
    "UpdateTestGridProject": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "testGridProject": {
            "shape": "S1n"
          }
        }
      }
    },
    "UpdateUpload": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "contentType": {},
          "editContent": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "upload": {
            "shape": "S1w"
          }
        }
      }
    },
    "UpdateVPCEConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "vpceConfigurationName": {},
          "vpceServiceName": {},
          "serviceDnsName": {},
          "vpceConfigurationDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "vpceConfiguration": {
            "shape": "S27"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "attribute": {},
          "operator": {},
          "value": {}
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "type": {},
        "rules": {
          "shape": "S5"
        },
        "maxDevices": {
          "type": "integer"
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "members": {
        "arn": {},
        "packageCleanup": {
          "type": "boolean"
        },
        "excludeAppPackagesFromCleanup": {
          "shape": "Sg"
        },
        "rebootAfterUse": {
          "type": "boolean"
        },
        "name": {},
        "description": {}
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "type": {},
        "uplinkBandwidthBits": {
          "type": "long"
        },
        "downlinkBandwidthBits": {
          "type": "long"
        },
        "uplinkDelayMs": {
          "type": "long"
        },
        "downlinkDelayMs": {
          "type": "long"
        },
        "uplinkJitterMs": {
          "type": "long"
        },
        "downlinkJitterMs": {
          "type": "long"
        },
        "uplinkLossPercent": {
          "type": "integer"
        },
        "downlinkLossPercent": {
          "type": "integer"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "defaultJobTimeoutMinutes": {
          "type": "integer"
        },
        "created": {
          "type": "timestamp"
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "message": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "device": {
          "shape": "S15"
        },
        "instanceArn": {},
        "remoteDebugEnabled": {
          "type": "boolean"
        },
        "remoteRecordEnabled": {
          "type": "boolean"
        },
        "remoteRecordAppArn": {},
        "hostAddress": {},
        "clientId": {},
        "billingMethod": {},
        "deviceMinutes": {
          "shape": "S1h"
        },
        "endpoint": {},
        "deviceUdid": {},
        "interactionMode": {},
        "skipAppResign": {
          "type": "boolean"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "manufacturer": {},
        "model": {},
        "modelId": {},
        "formFactor": {},
        "platform": {},
        "os": {},
        "cpu": {
          "type": "structure",
          "members": {
            "frequency": {},
            "architecture": {},
            "clock": {
              "type": "double"
            }
          }
        },
        "resolution": {
          "type": "structure",
          "members": {
            "width": {
              "type": "integer"
            },
            "height": {
              "type": "integer"
            }
          }
        },
        "heapSize": {
          "type": "long"
        },
        "memory": {
          "type": "long"
        },
        "image": {},
        "carrier": {},
        "radio": {},
        "remoteAccessEnabled": {
          "type": "boolean"
        },
        "remoteDebugEnabled": {
          "type": "boolean"
        },
        "fleetType": {},
        "fleetName": {},
        "instances": {
          "shape": "S1b"
        },
        "availability": {}
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "shape": "S1c"
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "arn": {},
        "deviceArn": {},
        "labels": {
          "shape": "S1d"
        },
        "status": {},
        "udid": {},
        "instanceProfile": {
          "shape": "Si"
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "structure",
      "members": {
        "total": {
          "type": "double"
        },
        "metered": {
          "type": "double"
        },
        "unmetered": {
          "type": "double"
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "created": {
          "type": "timestamp"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "created": {
          "type": "timestamp"
        },
        "type": {},
        "status": {},
        "url": {},
        "metadata": {},
        "contentType": {},
        "message": {},
        "category": {}
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "arn": {},
        "vpceConfigurationName": {},
        "vpceServiceName": {},
        "serviceDnsName": {},
        "vpceConfigurationDescription": {}
      }
    },
    "S2u": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "testPackageArn": {},
        "testSpecArn": {},
        "filter": {},
        "parameters": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "extraDataPackageArn": {},
        "networkProfileArn": {},
        "locale": {},
        "location": {
          "shape": "S39"
        },
        "vpceConfigurationArns": {
          "shape": "Sz"
        },
        "customerArtifactPaths": {
          "shape": "S3a"
        },
        "radios": {
          "shape": "S3e"
        },
        "auxiliaryApps": {
          "shape": "Sz"
        },
        "billingMethod": {}
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "latitude",
        "longitude"
      ],
      "members": {
        "latitude": {
          "type": "double"
        },
        "longitude": {
          "type": "double"
        }
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "iosPaths": {
          "type": "list",
          "member": {}
        },
        "androidPaths": {
          "type": "list",
          "member": {}
        },
        "deviceHostPaths": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "wifi": {
          "type": "boolean"
        },
        "bluetooth": {
          "type": "boolean"
        },
        "nfc": {
          "type": "boolean"
        },
        "gps": {
          "type": "boolean"
        }
      }
    },
    "S3g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "device": {
            "shape": "S15"
          },
          "compatible": {
            "type": "boolean"
          },
          "incompatibilityMessages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "message": {},
                "type": {}
              }
            }
          }
        }
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S3p"
        },
        "message": {},
        "device": {
          "shape": "S15"
        },
        "instanceArn": {},
        "deviceMinutes": {
          "shape": "S1h"
        },
        "videoEndpoint": {},
        "videoCapture": {
          "type": "boolean"
        }
      }
    },
    "S3p": {
      "type": "structure",
      "members": {
        "total": {
          "type": "integer"
        },
        "passed": {
          "type": "integer"
        },
        "failed": {
          "type": "integer"
        },
        "warned": {
          "type": "integer"
        },
        "errored": {
          "type": "integer"
        },
        "stopped": {
          "type": "integer"
        },
        "skipped": {
          "type": "integer"
        }
      }
    },
    "S3w": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S3y"
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "type": {},
        "offering": {
          "shape": "S40"
        },
        "quantity": {
          "type": "integer"
        },
        "effectiveOn": {
          "type": "timestamp"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "type": {},
        "platform": {},
        "recurringCharges": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "cost": {
                "shape": "S44"
              },
              "frequency": {}
            }
          }
        }
      }
    },
    "S44": {
      "type": "structure",
      "members": {
        "amount": {
          "type": "double"
        },
        "currencyCode": {}
      }
    },
    "S4d": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "platform": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S3p"
        },
        "message": {},
        "totalJobs": {
          "type": "integer"
        },
        "completedJobs": {
          "type": "integer"
        },
        "billingMethod": {},
        "deviceMinutes": {
          "shape": "S1h"
        },
        "networkProfile": {
          "shape": "So"
        },
        "parsingResultUrl": {},
        "resultCode": {},
        "seed": {
          "type": "integer"
        },
        "appUpload": {},
        "eventCount": {
          "type": "integer"
        },
        "jobTimeoutMinutes": {
          "type": "integer"
        },
        "devicePoolArn": {},
        "locale": {},
        "radios": {
          "shape": "S3e"
        },
        "location": {
          "shape": "S39"
        },
        "customerArtifactPaths": {
          "shape": "S3a"
        },
        "webUrl": {},
        "skipAppResign": {
          "type": "boolean"
        },
        "testSpecArn": {},
        "deviceSelectionResult": {
          "type": "structure",
          "members": {
            "filters": {
              "shape": "S4g"
            },
            "matchedDevicesCount": {
              "type": "integer"
            },
            "maxDevices": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "attribute": {},
          "operator": {},
          "values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S3p"
        },
        "message": {},
        "deviceMinutes": {
          "shape": "S1h"
        }
      }
    },
    "S4p": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S3p"
        },
        "message": {},
        "deviceMinutes": {
          "shape": "S1h"
        }
      }
    },
    "S4v": {
      "type": "structure",
      "members": {
        "arn": {},
        "status": {},
        "created": {
          "type": "timestamp"
        },
        "ended": {
          "type": "timestamp"
        },
        "billingMinutes": {
          "type": "double"
        },
        "seleniumProperties": {}
      }
    },
    "S5y": {
      "type": "structure",
      "members": {
        "offeringStatus": {
          "shape": "S3y"
        },
        "transactionId": {},
        "offeringPromotionId": {},
        "createdOn": {
          "type": "timestamp"
        },
        "cost": {
          "shape": "S44"
        }
      }
    },
    "S6m": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S7h": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {}
      }
    }
  }
}
},{}],62:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetOfferingStatus": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": [
        "current",
        "nextPeriod"
      ]
    },
    "ListArtifacts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "artifacts"
    },
    "ListDevicePools": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "devicePools"
    },
    "ListDevices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "devices"
    },
    "ListJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "jobs"
    },
    "ListOfferingTransactions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "offeringTransactions"
    },
    "ListOfferings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "offerings"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "projects"
    },
    "ListRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "runs"
    },
    "ListSamples": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "samples"
    },
    "ListSuites": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "suites"
    },
    "ListTestGridProjects": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessionActions": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessionArtifacts": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTestGridSessions": {
      "input_token": "nextToken",
      "limit_key": "maxResult",
      "output_token": "nextToken"
    },
    "ListTests": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "tests"
    },
    "ListUniqueProblems": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "uniqueProblems"
    },
    "ListUploads": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "uploads"
    }
  }
}
},{}],63:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-10-25",
    "endpointPrefix": "directconnect",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Direct Connect",
    "serviceId": "Direct Connect",
    "signatureVersion": "v4",
    "targetPrefix": "OvertureService",
    "uid": "directconnect-2012-10-25"
  },
  "operations": {
    "AcceptDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId",
          "proposalId",
          "associatedGatewayOwnerAccount"
        ],
        "members": {
          "directConnectGatewayId": {},
          "proposalId": {},
          "associatedGatewayOwnerAccount": {},
          "overrideAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "AllocateConnectionOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "bandwidth",
          "connectionName",
          "ownerAccount",
          "interconnectId",
          "vlan"
        ],
        "members": {
          "bandwidth": {},
          "connectionName": {},
          "ownerAccount": {},
          "interconnectId": {},
          "vlan": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "So"
      },
      "deprecated": true
    },
    "AllocateHostedConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "bandwidth",
          "connectionName",
          "vlan"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "bandwidth": {},
          "connectionName": {},
          "vlan": {
            "type": "integer"
          },
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AllocatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPrivateVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPrivateVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "addressFamily": {},
              "customerAddress": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1e"
      }
    },
    "AllocatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPublicVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPublicVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "S5"
              },
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1e"
      }
    },
    "AllocateTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newTransitVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newTransitVirtualInterfaceAllocation": {
            "type": "structure",
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1e"
          }
        }
      }
    },
    "AssociateConnectionWithLag": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "lagId"
        ],
        "members": {
          "connectionId": {},
          "lagId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AssociateHostedConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "parentConnectionId"
        ],
        "members": {
          "connectionId": {},
          "parentConnectionId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AssociateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId",
          "connectionId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S1e"
      }
    },
    "ConfirmConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectionState": {}
        }
      }
    },
    "ConfirmPrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "virtualGatewayId": {},
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "ConfirmPublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "ConfirmTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId",
          "directConnectGatewayId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "CreateBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "newBGPPeer": {
            "type": "structure",
            "members": {
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1e"
          }
        }
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "location",
          "bandwidth",
          "connectionName"
        ],
        "members": {
          "location": {},
          "bandwidth": {},
          "connectionName": {},
          "lagId": {},
          "tags": {
            "shape": "Sz"
          },
          "providerName": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "CreateDirectConnectGateway": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayName"
        ],
        "members": {
          "directConnectGatewayName": {},
          "amazonSideAsn": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateway": {
            "shape": "S2c"
          }
        }
      }
    },
    "CreateDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId"
        ],
        "members": {
          "directConnectGatewayId": {},
          "gatewayId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "virtualGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId",
          "directConnectGatewayOwnerAccount",
          "gatewayId"
        ],
        "members": {
          "directConnectGatewayId": {},
          "directConnectGatewayOwnerAccount": {},
          "gatewayId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "removeAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposal": {
            "shape": "S2j"
          }
        }
      }
    },
    "CreateInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectName",
          "bandwidth",
          "location"
        ],
        "members": {
          "interconnectName": {},
          "bandwidth": {},
          "location": {},
          "lagId": {},
          "tags": {
            "shape": "Sz"
          },
          "providerName": {}
        }
      },
      "output": {
        "shape": "S2n"
      }
    },
    "CreateLag": {
      "input": {
        "type": "structure",
        "required": [
          "numberOfConnections",
          "location",
          "connectionsBandwidth",
          "lagName"
        ],
        "members": {
          "numberOfConnections": {
            "type": "integer"
          },
          "location": {},
          "connectionsBandwidth": {},
          "lagName": {},
          "connectionId": {},
          "tags": {
            "shape": "Sz"
          },
          "childConnectionTags": {
            "shape": "Sz"
          },
          "providerName": {}
        }
      },
      "output": {
        "shape": "S2s"
      }
    },
    "CreatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPrivateVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPrivateVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "virtualGatewayId": {},
              "directConnectGatewayId": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1e"
      }
    },
    "CreatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPublicVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPublicVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "S5"
              },
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1e"
      }
    },
    "CreateTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newTransitVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newTransitVirtualInterface": {
            "type": "structure",
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "directConnectGatewayId": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1e"
          }
        }
      }
    },
    "DeleteBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "asn": {
            "type": "integer"
          },
          "customerAddress": {},
          "bgpPeerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1e"
          }
        }
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "DeleteDirectConnectGateway": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId"
        ],
        "members": {
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateway": {
            "shape": "S2c"
          }
        }
      }
    },
    "DeleteDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "directConnectGatewayId": {},
          "virtualGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "DeleteDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "proposalId"
        ],
        "members": {
          "proposalId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposal": {
            "shape": "S2j"
          }
        }
      }
    },
    "DeleteInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnectState": {}
        }
      }
    },
    "DeleteLag": {
      "input": {
        "type": "structure",
        "required": [
          "lagId"
        ],
        "members": {
          "lagId": {}
        }
      },
      "output": {
        "shape": "S2s"
      }
    },
    "DeleteVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "DescribeConnectionLoa": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S3k"
          }
        }
      },
      "deprecated": true
    },
    "DescribeConnections": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S3n"
      }
    },
    "DescribeConnectionsOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "shape": "S3n"
      },
      "deprecated": true
    },
    "DescribeDirectConnectGatewayAssociationProposals": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "proposalId": {},
          "associatedGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposals": {
            "type": "list",
            "member": {
              "shape": "S2j"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGatewayAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "associatedGatewayId": {},
          "directConnectGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "virtualGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociations": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGatewayAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "virtualInterfaceId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAttachments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "directConnectGatewayId": {},
                "virtualInterfaceId": {},
                "virtualInterfaceRegion": {},
                "virtualInterfaceOwnerAccount": {},
                "attachmentState": {},
                "attachmentType": {},
                "stateChangeError": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGateways": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateways": {
            "type": "list",
            "member": {
              "shape": "S2c"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeHostedConnections": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S3n"
      }
    },
    "DescribeInterconnectLoa": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S3k"
          }
        }
      },
      "deprecated": true
    },
    "DescribeInterconnects": {
      "input": {
        "type": "structure",
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnects": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          }
        }
      }
    },
    "DescribeLags": {
      "input": {
        "type": "structure",
        "members": {
          "lagId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "lags": {
            "type": "list",
            "member": {
              "shape": "S2s"
            }
          }
        }
      }
    },
    "DescribeLoa": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "shape": "S3k"
      }
    },
    "DescribeLocations": {
      "output": {
        "type": "structure",
        "members": {
          "locations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "locationCode": {},
                "locationName": {},
                "region": {},
                "availablePortSpeeds": {
                  "type": "list",
                  "member": {}
                },
                "availableProviders": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArns"
        ],
        "members": {
          "resourceArns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceTags": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceArn": {},
                "tags": {
                  "shape": "Sz"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVirtualGateways": {
      "output": {
        "type": "structure",
        "members": {
          "virtualGateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "virtualGatewayId": {},
                "virtualGatewayState": {}
              }
            }
          }
        }
      }
    },
    "DescribeVirtualInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {},
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaces": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          }
        }
      }
    },
    "DisassociateConnectionFromLag": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "lagId"
        ],
        "members": {
          "connectionId": {},
          "lagId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "ListVirtualInterfaceTestHistory": {
      "input": {
        "type": "structure",
        "members": {
          "testId": {},
          "virtualInterfaceId": {},
          "bgpPeers": {
            "shape": "S55"
          },
          "status": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceTestHistory": {
            "type": "list",
            "member": {
              "shape": "S59"
            }
          },
          "nextToken": {}
        }
      }
    },
    "StartBgpFailoverTest": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "bgpPeers": {
            "shape": "S55"
          },
          "testDurationInMinutes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceTest": {
            "shape": "S59"
          }
        }
      }
    },
    "StopBgpFailoverTest": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceTest": {
            "shape": "S59"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "removeAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "UpdateLag": {
      "input": {
        "type": "structure",
        "required": [
          "lagId"
        ],
        "members": {
          "lagId": {},
          "lagName": {},
          "minimumLinks": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S2s"
      }
    },
    "UpdateVirtualInterfaceAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "mtu": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S1e"
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "cidr": {}
        }
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "directConnectGatewayId": {},
        "directConnectGatewayOwnerAccount": {},
        "associationState": {},
        "stateChangeError": {},
        "associatedGateway": {
          "shape": "Sc"
        },
        "associationId": {},
        "allowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        },
        "virtualGatewayId": {},
        "virtualGatewayRegion": {
          "type": "string",
          "deprecated": true
        },
        "virtualGatewayOwnerAccount": {}
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "id": {},
        "type": {},
        "ownerAccount": {},
        "region": {}
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "connectionId": {},
        "connectionName": {},
        "connectionState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "vlan": {
          "type": "integer"
        },
        "partnerName": {},
        "loaIssueTime": {
          "type": "timestamp"
        },
        "lagId": {},
        "awsDevice": {
          "shape": "Sv"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "awsDeviceV2": {},
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        },
        "providerName": {}
      }
    },
    "Sv": {
      "type": "string",
      "deprecated": true
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "virtualInterfaceId": {},
        "location": {},
        "connectionId": {},
        "virtualInterfaceType": {},
        "virtualInterfaceName": {},
        "vlan": {
          "type": "integer"
        },
        "asn": {
          "type": "integer"
        },
        "amazonSideAsn": {
          "type": "long"
        },
        "authKey": {},
        "amazonAddress": {},
        "customerAddress": {},
        "addressFamily": {},
        "virtualInterfaceState": {},
        "customerRouterConfig": {},
        "mtu": {
          "type": "integer"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "virtualGatewayId": {},
        "directConnectGatewayId": {},
        "routeFilterPrefixes": {
          "shape": "S5"
        },
        "bgpPeers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "bgpPeerId": {},
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {},
              "bgpPeerState": {},
              "bgpStatus": {},
              "awsDeviceV2": {}
            }
          }
        },
        "region": {},
        "awsDeviceV2": {},
        "tags": {
          "shape": "Sz"
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "directConnectGatewayId": {},
        "directConnectGatewayName": {},
        "amazonSideAsn": {
          "type": "long"
        },
        "ownerAccount": {},
        "directConnectGatewayState": {},
        "stateChangeError": {}
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "proposalId": {},
        "directConnectGatewayId": {},
        "directConnectGatewayOwnerAccount": {},
        "proposalState": {},
        "associatedGateway": {
          "shape": "Sc"
        },
        "existingAllowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        },
        "requestedAllowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "interconnectId": {},
        "interconnectName": {},
        "interconnectState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "loaIssueTime": {
          "type": "timestamp"
        },
        "lagId": {},
        "awsDevice": {
          "shape": "Sv"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "awsDeviceV2": {},
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        },
        "providerName": {}
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "connectionsBandwidth": {},
        "numberOfConnections": {
          "type": "integer"
        },
        "lagId": {},
        "ownerAccount": {},
        "lagName": {},
        "lagState": {},
        "location": {},
        "region": {},
        "minimumLinks": {
          "type": "integer"
        },
        "awsDevice": {
          "shape": "Sv"
        },
        "awsDeviceV2": {},
        "connections": {
          "shape": "S2u"
        },
        "allowsHostedConnections": {
          "type": "boolean"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        },
        "providerName": {}
      }
    },
    "S2u": {
      "type": "list",
      "member": {
        "shape": "So"
      }
    },
    "S3k": {
      "type": "structure",
      "members": {
        "loaContent": {
          "type": "blob"
        },
        "loaContentType": {}
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "connections": {
          "shape": "S2u"
        }
      }
    },
    "S55": {
      "type": "list",
      "member": {}
    },
    "S59": {
      "type": "structure",
      "members": {
        "testId": {},
        "virtualInterfaceId": {},
        "bgpPeers": {
          "shape": "S55"
        },
        "status": {},
        "ownerAccount": {},
        "testDurationInMinutes": {
          "type": "integer"
        },
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        }
      }
    }
  }
}
},{}],64:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeConnections": {
      "result_key": "connections"
    },
    "DescribeConnectionsOnInterconnect": {
      "result_key": "connections"
    },
    "DescribeInterconnects": {
      "result_key": "interconnects"
    },
    "DescribeLocations": {
      "result_key": "locations"
    },
    "DescribeVirtualGateways": {
      "result_key": "virtualGateways"
    },
    "DescribeVirtualInterfaces": {
      "result_key": "virtualInterfaces"
    }
  }
}
},{}],65:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2011-12-05",
    "endpointPrefix": "dynamodb",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "DynamoDB",
    "serviceFullName": "Amazon DynamoDB",
    "serviceId": "DynamoDB",
    "signatureVersion": "v4",
    "targetPrefix": "DynamoDB_20111205",
    "uid": "dynamodb-2011-12-05"
  },
  "operations": {
    "BatchGetItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Items": {
                  "shape": "Sk"
                },
                "ConsumedCapacityUnits": {
                  "type": "double"
                }
              }
            }
          },
          "UnprocessedKeys": {
            "shape": "S2"
          }
        }
      }
    },
    "BatchWriteItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "ConsumedCapacityUnits": {
                  "type": "double"
                }
              }
            }
          },
          "UnprocessedItems": {
            "shape": "So"
          }
        }
      }
    },
    "CreateTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "KeySchema",
          "ProvisionedThroughput"
        ],
        "members": {
          "TableName": {},
          "KeySchema": {
            "shape": "Sy"
          },
          "ProvisionedThroughput": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S15"
          }
        }
      }
    },
    "DeleteItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "Expected": {
            "shape": "S1b"
          },
          "ReturnValues": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sl"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "DeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S15"
          }
        }
      }
    },
    "DescribeTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "S15"
          }
        }
      }
    },
    "GetItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "AttributesToGet": {
            "shape": "Se"
          },
          "ConsistentRead": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Item": {
            "shape": "Sl"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "ListTables": {
      "input": {
        "type": "structure",
        "members": {
          "ExclusiveStartTableName": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableNames": {
            "type": "list",
            "member": {}
          },
          "LastEvaluatedTableName": {}
        }
      }
    },
    "PutItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Item"
        ],
        "members": {
          "TableName": {},
          "Item": {
            "shape": "Ss"
          },
          "Expected": {
            "shape": "S1b"
          },
          "ReturnValues": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sl"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "Query": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "HashKeyValue"
        ],
        "members": {
          "TableName": {},
          "AttributesToGet": {
            "shape": "Se"
          },
          "Limit": {
            "type": "integer"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "Count": {
            "type": "boolean"
          },
          "HashKeyValue": {
            "shape": "S7"
          },
          "RangeKeyCondition": {
            "shape": "S1u"
          },
          "ScanIndexForward": {
            "type": "boolean"
          },
          "ExclusiveStartKey": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "Sk"
          },
          "Count": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S6"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "Scan": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "AttributesToGet": {
            "shape": "Se"
          },
          "Limit": {
            "type": "integer"
          },
          "Count": {
            "type": "boolean"
          },
          "ScanFilter": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S1u"
            }
          },
          "ExclusiveStartKey": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "Sk"
          },
          "Count": {
            "type": "integer"
          },
          "ScannedCount": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S6"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "UpdateItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key",
          "AttributeUpdates"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "AttributeUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Value": {
                  "shape": "S7"
                },
                "Action": {}
              }
            }
          },
          "Expected": {
            "shape": "S1b"
          },
          "ReturnValues": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sl"
          },
          "ConsumedCapacityUnits": {
            "type": "double"
          }
        }
      }
    },
    "UpdateTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "ProvisionedThroughput"
        ],
        "members": {
          "TableName": {},
          "ProvisionedThroughput": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S15"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Keys"
        ],
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "AttributesToGet": {
            "shape": "Se"
          },
          "ConsistentRead": {
            "type": "boolean"
          }
        }
      }
    },
    "S6": {
      "type": "structure",
      "required": [
        "HashKeyElement"
      ],
      "members": {
        "HashKeyElement": {
          "shape": "S7"
        },
        "RangeKeyElement": {
          "shape": "S7"
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "S": {},
        "N": {},
        "B": {
          "type": "blob"
        },
        "SS": {
          "type": "list",
          "member": {}
        },
        "NS": {
          "type": "list",
          "member": {}
        },
        "BS": {
          "type": "list",
          "member": {
            "type": "blob"
          }
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sk": {
      "type": "list",
      "member": {
        "shape": "Sl"
      }
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S7"
      }
    },
    "So": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "type": "structure",
          "members": {
            "PutRequest": {
              "type": "structure",
              "required": [
                "Item"
              ],
              "members": {
                "Item": {
                  "shape": "Ss"
                }
              }
            },
            "DeleteRequest": {
              "type": "structure",
              "required": [
                "Key"
              ],
              "members": {
                "Key": {
                  "shape": "S6"
                }
              }
            }
          }
        }
      }
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S7"
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "HashKeyElement"
      ],
      "members": {
        "HashKeyElement": {
          "shape": "Sz"
        },
        "RangeKeyElement": {
          "shape": "Sz"
        }
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "AttributeName",
        "AttributeType"
      ],
      "members": {
        "AttributeName": {},
        "AttributeType": {}
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "ReadCapacityUnits",
        "WriteCapacityUnits"
      ],
      "members": {
        "ReadCapacityUnits": {
          "type": "long"
        },
        "WriteCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "TableName": {},
        "KeySchema": {
          "shape": "Sy"
        },
        "TableStatus": {},
        "CreationDateTime": {
          "type": "timestamp"
        },
        "ProvisionedThroughput": {
          "type": "structure",
          "members": {
            "LastIncreaseDateTime": {
              "type": "timestamp"
            },
            "LastDecreaseDateTime": {
              "type": "timestamp"
            },
            "NumberOfDecreasesToday": {
              "type": "long"
            },
            "ReadCapacityUnits": {
              "type": "long"
            },
            "WriteCapacityUnits": {
              "type": "long"
            }
          }
        },
        "TableSizeBytes": {
          "type": "long"
        },
        "ItemCount": {
          "type": "long"
        }
      }
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {
            "shape": "S7"
          },
          "Exists": {
            "type": "boolean"
          }
        }
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "ComparisonOperator"
      ],
      "members": {
        "AttributeValueList": {
          "type": "list",
          "member": {
            "shape": "S7"
          }
        },
        "ComparisonOperator": {}
      }
    }
  }
}
},{}],66:[function(require,module,exports){
module.exports={
  "pagination": {
    "BatchGetItem": {
      "input_token": "RequestItems",
      "output_token": "UnprocessedKeys"
    },
    "ListTables": {
      "input_token": "ExclusiveStartTableName",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedTableName",
      "result_key": "TableNames"
    },
    "Query": {
      "input_token": "ExclusiveStartKey",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedKey",
      "result_key": "Items"
    },
    "Scan": {
      "input_token": "ExclusiveStartKey",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedKey",
      "result_key": "Items"
    }
  }
}
},{}],67:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "TableExists": {
      "delay": 20,
      "operation": "DescribeTable",
      "maxAttempts": 25,
      "acceptors": [
        {
          "expected": "ACTIVE",
          "matcher": "path",
          "state": "success",
          "argument": "Table.TableStatus"
        },
        {
          "expected": "ResourceNotFoundException",
          "matcher": "error",
          "state": "retry"
        }
      ]
    },
    "TableNotExists": {
      "delay": 20,
      "operation": "DescribeTable",
      "maxAttempts": 25,
      "acceptors": [
        {
          "expected": "ResourceNotFoundException",
          "matcher": "error",
          "state": "success"
        }
      ]
    }
  }
}

},{}],68:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-08-10",
    "endpointPrefix": "dynamodb",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceAbbreviation": "DynamoDB",
    "serviceFullName": "Amazon DynamoDB",
    "serviceId": "DynamoDB",
    "signatureVersion": "v4",
    "targetPrefix": "DynamoDB_20120810",
    "uid": "dynamodb-2012-08-10"
  },
  "operations": {
    "BatchGetItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "S2"
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Responses": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "Sr"
            }
          },
          "UnprocessedKeys": {
            "shape": "S2"
          },
          "ConsumedCapacity": {
            "shape": "St"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "BatchWriteItem": {
      "input": {
        "type": "structure",
        "required": [
          "RequestItems"
        ],
        "members": {
          "RequestItems": {
            "shape": "S10"
          },
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedItems": {
            "shape": "S10"
          },
          "ItemCollectionMetrics": {
            "shape": "S18"
          },
          "ConsumedCapacity": {
            "shape": "St"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateBackup": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "BackupName"
        ],
        "members": {
          "TableName": {},
          "BackupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDetails": {
            "shape": "S1h"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName",
          "ReplicationGroup"
        ],
        "members": {
          "GlobalTableName": {},
          "ReplicationGroup": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S1t"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "CreateTable": {
      "input": {
        "type": "structure",
        "required": [
          "AttributeDefinitions",
          "TableName",
          "KeySchema"
        ],
        "members": {
          "AttributeDefinitions": {
            "shape": "S27"
          },
          "TableName": {},
          "KeySchema": {
            "shape": "S2b"
          },
          "LocalSecondaryIndexes": {
            "shape": "S2e"
          },
          "GlobalSecondaryIndexes": {
            "shape": "S2k"
          },
          "BillingMode": {},
          "ProvisionedThroughput": {
            "shape": "S2m"
          },
          "StreamSpecification": {
            "shape": "S2o"
          },
          "SSESpecification": {
            "shape": "S2r"
          },
          "Tags": {
            "shape": "S2u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupArn"
        ],
        "members": {
          "BackupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDescription": {
            "shape": "S3o"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "Expected": {
            "shape": "S41"
          },
          "ConditionalOperator": {},
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          },
          "ExpressionAttributeValues": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Ss"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          },
          "ItemCollectionMetrics": {
            "shape": "S1a"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupArn"
        ],
        "members": {
          "BackupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupDescription": {
            "shape": "S3o"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeContinuousBackups": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuousBackupsDescription": {
            "shape": "S4i"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeContributorInsights": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsRuleList": {
            "type": "list",
            "member": {}
          },
          "ContributorInsightsStatus": {},
          "LastUpdateDateTime": {
            "type": "timestamp"
          },
          "FailureException": {
            "type": "structure",
            "members": {
              "ExceptionName": {},
              "ExceptionDescription": {}
            }
          }
        }
      }
    },
    "DescribeEndpoints": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "Endpoints"
        ],
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Address",
                "CachePeriodInMinutes"
              ],
              "members": {
                "Address": {},
                "CachePeriodInMinutes": {
                  "type": "long"
                }
              }
            }
          }
        }
      },
      "endpointoperation": true
    },
    "DescribeGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S1t"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeGlobalTableSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableName": {},
          "ReplicaSettings": {
            "shape": "S53"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeLimits": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountMaxReadCapacityUnits": {
            "type": "long"
          },
          "AccountMaxWriteCapacityUnits": {
            "type": "long"
          },
          "TableMaxReadCapacityUnits": {
            "type": "long"
          },
          "TableMaxWriteCapacityUnits": {
            "type": "long"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "DescribeTableReplicaAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableAutoScalingDescription": {
            "shape": "S5k"
          }
        }
      }
    },
    "DescribeTimeToLive": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeToLiveDescription": {
            "shape": "S3x"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "GetItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "AttributesToGet": {
            "shape": "Sj"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "ReturnConsumedCapacity": {},
          "ProjectionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Item": {
            "shape": "Ss"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "ListBackups": {
      "input": {
        "type": "structure",
        "members": {
          "TableName": {},
          "Limit": {
            "type": "integer"
          },
          "TimeRangeLowerBound": {
            "type": "timestamp"
          },
          "TimeRangeUpperBound": {
            "type": "timestamp"
          },
          "ExclusiveStartBackupArn": {},
          "BackupType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "TableId": {},
                "TableArn": {},
                "BackupArn": {},
                "BackupName": {},
                "BackupCreationDateTime": {
                  "type": "timestamp"
                },
                "BackupExpiryDateTime": {
                  "type": "timestamp"
                },
                "BackupStatus": {},
                "BackupType": {},
                "BackupSizeBytes": {
                  "type": "long"
                }
              }
            }
          },
          "LastEvaluatedBackupArn": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListContributorInsights": {
      "input": {
        "type": "structure",
        "members": {
          "TableName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContributorInsightsSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "IndexName": {},
                "ContributorInsightsStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListGlobalTables": {
      "input": {
        "type": "structure",
        "members": {
          "ExclusiveStartGlobalTableName": {},
          "Limit": {
            "type": "integer"
          },
          "RegionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTables": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GlobalTableName": {},
                "ReplicationGroup": {
                  "shape": "S1p"
                }
              }
            }
          },
          "LastEvaluatedGlobalTableName": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListTables": {
      "input": {
        "type": "structure",
        "members": {
          "ExclusiveStartTableName": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableNames": {
            "type": "list",
            "member": {}
          },
          "LastEvaluatedTableName": {}
        }
      },
      "endpointdiscovery": {}
    },
    "ListTagsOfResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2u"
          },
          "NextToken": {}
        }
      },
      "endpointdiscovery": {}
    },
    "PutItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Item"
        ],
        "members": {
          "TableName": {},
          "Item": {
            "shape": "S14"
          },
          "Expected": {
            "shape": "S41"
          },
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ConditionalOperator": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          },
          "ExpressionAttributeValues": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Ss"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          },
          "ItemCollectionMetrics": {
            "shape": "S1a"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "Query": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "Select": {},
          "AttributesToGet": {
            "shape": "Sj"
          },
          "Limit": {
            "type": "integer"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "KeyConditions": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S6o"
            }
          },
          "QueryFilter": {
            "shape": "S6p"
          },
          "ConditionalOperator": {},
          "ScanIndexForward": {
            "type": "boolean"
          },
          "ExclusiveStartKey": {
            "shape": "S6"
          },
          "ReturnConsumedCapacity": {},
          "ProjectionExpression": {},
          "FilterExpression": {},
          "KeyConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          },
          "ExpressionAttributeValues": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "Sr"
          },
          "Count": {
            "type": "integer"
          },
          "ScannedCount": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S6"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "RestoreTableFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "TargetTableName",
          "BackupArn"
        ],
        "members": {
          "TargetTableName": {},
          "BackupArn": {},
          "BillingModeOverride": {},
          "GlobalSecondaryIndexOverride": {
            "shape": "S2k"
          },
          "LocalSecondaryIndexOverride": {
            "shape": "S2e"
          },
          "ProvisionedThroughputOverride": {
            "shape": "S2m"
          },
          "SSESpecificationOverride": {
            "shape": "S2r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "RestoreTableToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "TargetTableName"
        ],
        "members": {
          "SourceTableArn": {},
          "SourceTableName": {},
          "TargetTableName": {},
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "RestoreDateTime": {
            "type": "timestamp"
          },
          "BillingModeOverride": {},
          "GlobalSecondaryIndexOverride": {
            "shape": "S2k"
          },
          "LocalSecondaryIndexOverride": {
            "shape": "S2e"
          },
          "ProvisionedThroughputOverride": {
            "shape": "S2m"
          },
          "SSESpecificationOverride": {
            "shape": "S2r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "Scan": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "AttributesToGet": {
            "shape": "Sj"
          },
          "Limit": {
            "type": "integer"
          },
          "Select": {},
          "ScanFilter": {
            "shape": "S6p"
          },
          "ConditionalOperator": {},
          "ExclusiveStartKey": {
            "shape": "S6"
          },
          "ReturnConsumedCapacity": {},
          "TotalSegments": {
            "type": "integer"
          },
          "Segment": {
            "type": "integer"
          },
          "ProjectionExpression": {},
          "FilterExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          },
          "ExpressionAttributeValues": {
            "shape": "S49"
          },
          "ConsistentRead": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "shape": "Sr"
          },
          "Count": {
            "type": "integer"
          },
          "ScannedCount": {
            "type": "integer"
          },
          "LastEvaluatedKey": {
            "shape": "S6"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S2u"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TransactGetItems": {
      "input": {
        "type": "structure",
        "required": [
          "TransactItems"
        ],
        "members": {
          "TransactItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Get"
              ],
              "members": {
                "Get": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S6"
                    },
                    "TableName": {},
                    "ProjectionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "Sm"
                    }
                  }
                }
              }
            }
          },
          "ReturnConsumedCapacity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConsumedCapacity": {
            "shape": "St"
          },
          "Responses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Item": {
                  "shape": "Ss"
                }
              }
            }
          }
        }
      },
      "endpointdiscovery": {}
    },
    "TransactWriteItems": {
      "input": {
        "type": "structure",
        "required": [
          "TransactItems"
        ],
        "members": {
          "TransactItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConditionCheck": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName",
                    "ConditionExpression"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S6"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "Sm"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S49"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Put": {
                  "type": "structure",
                  "required": [
                    "Item",
                    "TableName"
                  ],
                  "members": {
                    "Item": {
                      "shape": "S14"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "Sm"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S49"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S6"
                    },
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "Sm"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S49"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                },
                "Update": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "UpdateExpression",
                    "TableName"
                  ],
                  "members": {
                    "Key": {
                      "shape": "S6"
                    },
                    "UpdateExpression": {},
                    "TableName": {},
                    "ConditionExpression": {},
                    "ExpressionAttributeNames": {
                      "shape": "Sm"
                    },
                    "ExpressionAttributeValues": {
                      "shape": "S49"
                    },
                    "ReturnValuesOnConditionCheckFailure": {}
                  }
                }
              }
            }
          },
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConsumedCapacity": {
            "shape": "St"
          },
          "ItemCollectionMetrics": {
            "shape": "S18"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateContinuousBackups": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "PointInTimeRecoverySpecification"
        ],
        "members": {
          "TableName": {},
          "PointInTimeRecoverySpecification": {
            "type": "structure",
            "required": [
              "PointInTimeRecoveryEnabled"
            ],
            "members": {
              "PointInTimeRecoveryEnabled": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuousBackupsDescription": {
            "shape": "S4i"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateContributorInsights": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "ContributorInsightsAction"
        ],
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsAction": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableName": {},
          "IndexName": {},
          "ContributorInsightsStatus": {}
        }
      }
    },
    "UpdateGlobalTable": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName",
          "ReplicaUpdates"
        ],
        "members": {
          "GlobalTableName": {},
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Create": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableDescription": {
            "shape": "S1t"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateGlobalTableSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalTableName"
        ],
        "members": {
          "GlobalTableName": {},
          "GlobalTableBillingMode": {},
          "GlobalTableProvisionedWriteCapacityUnits": {
            "type": "long"
          },
          "GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate": {
            "shape": "S7z"
          },
          "GlobalTableGlobalSecondaryIndexSettingsUpdate": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName"
              ],
              "members": {
                "IndexName": {},
                "ProvisionedWriteCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedWriteCapacityAutoScalingSettingsUpdate": {
                  "shape": "S7z"
                }
              }
            }
          },
          "ReplicaSettingsUpdate": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegionName"
              ],
              "members": {
                "RegionName": {},
                "ReplicaProvisionedReadCapacityUnits": {
                  "type": "long"
                },
                "ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate": {
                  "shape": "S7z"
                },
                "ReplicaGlobalSecondaryIndexSettingsUpdate": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "IndexName"
                    ],
                    "members": {
                      "IndexName": {},
                      "ProvisionedReadCapacityUnits": {
                        "type": "long"
                      },
                      "ProvisionedReadCapacityAutoScalingSettingsUpdate": {
                        "shape": "S7z"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GlobalTableName": {},
          "ReplicaSettings": {
            "shape": "S53"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateItem": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "Key"
        ],
        "members": {
          "TableName": {},
          "Key": {
            "shape": "S6"
          },
          "AttributeUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Value": {
                  "shape": "S8"
                },
                "Action": {}
              }
            }
          },
          "Expected": {
            "shape": "S41"
          },
          "ConditionalOperator": {},
          "ReturnValues": {},
          "ReturnConsumedCapacity": {},
          "ReturnItemCollectionMetrics": {},
          "UpdateExpression": {},
          "ConditionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          },
          "ExpressionAttributeValues": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Ss"
          },
          "ConsumedCapacity": {
            "shape": "Su"
          },
          "ItemCollectionMetrics": {
            "shape": "S1a"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateTable": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "AttributeDefinitions": {
            "shape": "S27"
          },
          "TableName": {},
          "BillingMode": {},
          "ProvisionedThroughput": {
            "shape": "S2m"
          },
          "GlobalSecondaryIndexUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Update": {
                  "type": "structure",
                  "required": [
                    "IndexName",
                    "ProvisionedThroughput"
                  ],
                  "members": {
                    "IndexName": {},
                    "ProvisionedThroughput": {
                      "shape": "S2m"
                    }
                  }
                },
                "Create": {
                  "type": "structure",
                  "required": [
                    "IndexName",
                    "KeySchema",
                    "Projection"
                  ],
                  "members": {
                    "IndexName": {},
                    "KeySchema": {
                      "shape": "S2b"
                    },
                    "Projection": {
                      "shape": "S2g"
                    },
                    "ProvisionedThroughput": {
                      "shape": "S2m"
                    }
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "IndexName"
                  ],
                  "members": {
                    "IndexName": {}
                  }
                }
              }
            }
          },
          "StreamSpecification": {
            "shape": "S2o"
          },
          "SSESpecification": {
            "shape": "S2r"
          },
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Create": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {},
                    "KMSMasterKeyId": {},
                    "ProvisionedThroughputOverride": {
                      "shape": "S20"
                    },
                    "GlobalSecondaryIndexes": {
                      "shape": "S8o"
                    }
                  }
                },
                "Update": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {},
                    "KMSMasterKeyId": {},
                    "ProvisionedThroughputOverride": {
                      "shape": "S20"
                    },
                    "GlobalSecondaryIndexes": {
                      "shape": "S8o"
                    }
                  }
                },
                "Delete": {
                  "type": "structure",
                  "required": [
                    "RegionName"
                  ],
                  "members": {
                    "RegionName": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableDescription": {
            "shape": "S2z"
          }
        }
      },
      "endpointdiscovery": {}
    },
    "UpdateTableReplicaAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "TableName"
        ],
        "members": {
          "GlobalSecondaryIndexUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IndexName": {},
                "ProvisionedWriteCapacityAutoScalingUpdate": {
                  "shape": "S7z"
                }
              }
            }
          },
          "TableName": {},
          "ProvisionedWriteCapacityAutoScalingUpdate": {
            "shape": "S7z"
          },
          "ReplicaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegionName"
              ],
              "members": {
                "RegionName": {},
                "ReplicaGlobalSecondaryIndexUpdates": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "IndexName": {},
                      "ProvisionedReadCapacityAutoScalingUpdate": {
                        "shape": "S7z"
                      }
                    }
                  }
                },
                "ReplicaProvisionedReadCapacityAutoScalingUpdate": {
                  "shape": "S7z"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableAutoScalingDescription": {
            "shape": "S5k"
          }
        }
      }
    },
    "UpdateTimeToLive": {
      "input": {
        "type": "structure",
        "required": [
          "TableName",
          "TimeToLiveSpecification"
        ],
        "members": {
          "TableName": {},
          "TimeToLiveSpecification": {
            "shape": "S92"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeToLiveSpecification": {
            "shape": "S92"
          }
        }
      },
      "endpointdiscovery": {}
    }
  },
  "shapes": {
    "S2": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Keys"
        ],
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "AttributesToGet": {
            "shape": "Sj"
          },
          "ConsistentRead": {
            "type": "boolean"
          },
          "ProjectionExpression": {},
          "ExpressionAttributeNames": {
            "shape": "Sm"
          }
        }
      }
    },
    "S6": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S8"
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "S": {},
        "N": {},
        "B": {
          "type": "blob"
        },
        "SS": {
          "type": "list",
          "member": {}
        },
        "NS": {
          "type": "list",
          "member": {}
        },
        "BS": {
          "type": "list",
          "member": {
            "type": "blob"
          }
        },
        "M": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S8"
          }
        },
        "L": {
          "type": "list",
          "member": {
            "shape": "S8"
          }
        },
        "NULL": {
          "type": "boolean"
        },
        "BOOL": {
          "type": "boolean"
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sr": {
      "type": "list",
      "member": {
        "shape": "Ss"
      }
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S8"
      }
    },
    "St": {
      "type": "list",
      "member": {
        "shape": "Su"
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "TableName": {},
        "CapacityUnits": {
          "type": "double"
        },
        "ReadCapacityUnits": {
          "type": "double"
        },
        "WriteCapacityUnits": {
          "type": "double"
        },
        "Table": {
          "shape": "Sw"
        },
        "LocalSecondaryIndexes": {
          "shape": "Sx"
        },
        "GlobalSecondaryIndexes": {
          "shape": "Sx"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "ReadCapacityUnits": {
          "type": "double"
        },
        "WriteCapacityUnits": {
          "type": "double"
        },
        "CapacityUnits": {
          "type": "double"
        }
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Sw"
      }
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "type": "structure",
          "members": {
            "PutRequest": {
              "type": "structure",
              "required": [
                "Item"
              ],
              "members": {
                "Item": {
                  "shape": "S14"
                }
              }
            },
            "DeleteRequest": {
              "type": "structure",
              "required": [
                "Key"
              ],
              "members": {
                "Key": {
                  "shape": "S6"
                }
              }
            }
          }
        }
      }
    },
    "S14": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S8"
      }
    },
    "S18": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {
          "shape": "S1a"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "ItemCollectionKey": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S8"
          }
        },
        "SizeEstimateRangeGB": {
          "type": "list",
          "member": {
            "type": "double"
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "required": [
        "BackupArn",
        "BackupName",
        "BackupStatus",
        "BackupType",
        "BackupCreationDateTime"
      ],
      "members": {
        "BackupArn": {},
        "BackupName": {},
        "BackupSizeBytes": {
          "type": "long"
        },
        "BackupStatus": {},
        "BackupType": {},
        "BackupCreationDateTime": {
          "type": "timestamp"
        },
        "BackupExpiryDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {}
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "ReplicationGroup": {
          "shape": "S1u"
        },
        "GlobalTableArn": {},
        "CreationDateTime": {
          "type": "timestamp"
        },
        "GlobalTableStatus": {},
        "GlobalTableName": {}
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RegionName": {},
          "ReplicaStatus": {},
          "ReplicaStatusDescription": {},
          "ReplicaStatusPercentProgress": {},
          "KMSMasterKeyId": {},
          "ProvisionedThroughputOverride": {
            "shape": "S20"
          },
          "GlobalSecondaryIndexes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IndexName": {},
                "ProvisionedThroughputOverride": {
                  "shape": "S20"
                }
              }
            }
          }
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "ReadCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributeName",
          "AttributeType"
        ],
        "members": {
          "AttributeName": {},
          "AttributeType": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributeName",
          "KeyType"
        ],
        "members": {
          "AttributeName": {},
          "KeyType": {}
        }
      }
    },
    "S2e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName",
          "KeySchema",
          "Projection"
        ],
        "members": {
          "IndexName": {},
          "KeySchema": {
            "shape": "S2b"
          },
          "Projection": {
            "shape": "S2g"
          }
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "ProjectionType": {},
        "NonKeyAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName",
          "KeySchema",
          "Projection"
        ],
        "members": {
          "IndexName": {},
          "KeySchema": {
            "shape": "S2b"
          },
          "Projection": {
            "shape": "S2g"
          },
          "ProvisionedThroughput": {
            "shape": "S2m"
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "ReadCapacityUnits",
        "WriteCapacityUnits"
      ],
      "members": {
        "ReadCapacityUnits": {
          "type": "long"
        },
        "WriteCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "required": [
        "StreamEnabled"
      ],
      "members": {
        "StreamEnabled": {
          "type": "boolean"
        },
        "StreamViewType": {}
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "SSEType": {},
        "KMSMasterKeyId": {}
      }
    },
    "S2u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S2z": {
      "type": "structure",
      "members": {
        "AttributeDefinitions": {
          "shape": "S27"
        },
        "TableName": {},
        "KeySchema": {
          "shape": "S2b"
        },
        "TableStatus": {},
        "CreationDateTime": {
          "type": "timestamp"
        },
        "ProvisionedThroughput": {
          "shape": "S31"
        },
        "TableSizeBytes": {
          "type": "long"
        },
        "ItemCount": {
          "type": "long"
        },
        "TableArn": {},
        "TableId": {},
        "BillingModeSummary": {
          "shape": "S36"
        },
        "LocalSecondaryIndexes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "IndexName": {},
              "KeySchema": {
                "shape": "S2b"
              },
              "Projection": {
                "shape": "S2g"
              },
              "IndexSizeBytes": {
                "type": "long"
              },
              "ItemCount": {
                "type": "long"
              },
              "IndexArn": {}
            }
          }
        },
        "GlobalSecondaryIndexes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "IndexName": {},
              "KeySchema": {
                "shape": "S2b"
              },
              "Projection": {
                "shape": "S2g"
              },
              "IndexStatus": {},
              "Backfilling": {
                "type": "boolean"
              },
              "ProvisionedThroughput": {
                "shape": "S31"
              },
              "IndexSizeBytes": {
                "type": "long"
              },
              "ItemCount": {
                "type": "long"
              },
              "IndexArn": {}
            }
          }
        },
        "StreamSpecification": {
          "shape": "S2o"
        },
        "LatestStreamLabel": {},
        "LatestStreamArn": {},
        "GlobalTableVersion": {},
        "Replicas": {
          "shape": "S1u"
        },
        "RestoreSummary": {
          "type": "structure",
          "required": [
            "RestoreDateTime",
            "RestoreInProgress"
          ],
          "members": {
            "SourceBackupArn": {},
            "SourceTableArn": {},
            "RestoreDateTime": {
              "type": "timestamp"
            },
            "RestoreInProgress": {
              "type": "boolean"
            }
          }
        },
        "SSEDescription": {
          "shape": "S3h"
        },
        "ArchivalSummary": {
          "type": "structure",
          "members": {
            "ArchivalDateTime": {
              "type": "timestamp"
            },
            "ArchivalReason": {},
            "ArchivalBackupArn": {}
          }
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "LastIncreaseDateTime": {
          "type": "timestamp"
        },
        "LastDecreaseDateTime": {
          "type": "timestamp"
        },
        "NumberOfDecreasesToday": {
          "type": "long"
        },
        "ReadCapacityUnits": {
          "type": "long"
        },
        "WriteCapacityUnits": {
          "type": "long"
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "BillingMode": {},
        "LastUpdateToPayPerRequestDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "Status": {},
        "SSEType": {},
        "KMSMasterKeyArn": {},
        "InaccessibleEncryptionDateTime": {
          "type": "timestamp"
        }
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "BackupDetails": {
          "shape": "S1h"
        },
        "SourceTableDetails": {
          "type": "structure",
          "required": [
            "TableName",
            "TableId",
            "KeySchema",
            "TableCreationDateTime",
            "ProvisionedThroughput"
          ],
          "members": {
            "TableName": {},
            "TableId": {},
            "TableArn": {},
            "TableSizeBytes": {
              "type": "long"
            },
            "KeySchema": {
              "shape": "S2b"
            },
            "TableCreationDateTime": {
              "type": "timestamp"
            },
            "ProvisionedThroughput": {
              "shape": "S2m"
            },
            "ItemCount": {
              "type": "long"
            },
            "BillingMode": {}
          }
        },
        "SourceTableFeatureDetails": {
          "type": "structure",
          "members": {
            "LocalSecondaryIndexes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "IndexName": {},
                  "KeySchema": {
                    "shape": "S2b"
                  },
                  "Projection": {
                    "shape": "S2g"
                  }
                }
              }
            },
            "GlobalSecondaryIndexes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "IndexName": {},
                  "KeySchema": {
                    "shape": "S2b"
                  },
                  "Projection": {
                    "shape": "S2g"
                  },
                  "ProvisionedThroughput": {
                    "shape": "S2m"
                  }
                }
              }
            },
            "StreamDescription": {
              "shape": "S2o"
            },
            "TimeToLiveDescription": {
              "shape": "S3x"
            },
            "SSEDescription": {
              "shape": "S3h"
            }
          }
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "TimeToLiveStatus": {},
        "AttributeName": {}
      }
    },
    "S41": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {
            "shape": "S8"
          },
          "Exists": {
            "type": "boolean"
          },
          "ComparisonOperator": {},
          "AttributeValueList": {
            "shape": "S45"
          }
        }
      }
    },
    "S45": {
      "type": "list",
      "member": {
        "shape": "S8"
      }
    },
    "S49": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S8"
      }
    },
    "S4i": {
      "type": "structure",
      "required": [
        "ContinuousBackupsStatus"
      ],
      "members": {
        "ContinuousBackupsStatus": {},
        "PointInTimeRecoveryDescription": {
          "type": "structure",
          "members": {
            "PointInTimeRecoveryStatus": {},
            "EarliestRestorableDateTime": {
              "type": "timestamp"
            },
            "LatestRestorableDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S53": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RegionName"
        ],
        "members": {
          "RegionName": {},
          "ReplicaStatus": {},
          "ReplicaBillingModeSummary": {
            "shape": "S36"
          },
          "ReplicaProvisionedReadCapacityUnits": {
            "type": "long"
          },
          "ReplicaProvisionedReadCapacityAutoScalingSettings": {
            "shape": "S55"
          },
          "ReplicaProvisionedWriteCapacityUnits": {
            "type": "long"
          },
          "ReplicaProvisionedWriteCapacityAutoScalingSettings": {
            "shape": "S55"
          },
          "ReplicaGlobalSecondaryIndexSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName"
              ],
              "members": {
                "IndexName": {},
                "IndexStatus": {},
                "ProvisionedReadCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedReadCapacityAutoScalingSettings": {
                  "shape": "S55"
                },
                "ProvisionedWriteCapacityUnits": {
                  "type": "long"
                },
                "ProvisionedWriteCapacityAutoScalingSettings": {
                  "shape": "S55"
                }
              }
            }
          }
        }
      }
    },
    "S55": {
      "type": "structure",
      "members": {
        "MinimumUnits": {
          "type": "long"
        },
        "MaximumUnits": {
          "type": "long"
        },
        "AutoScalingDisabled": {
          "type": "boolean"
        },
        "AutoScalingRoleArn": {},
        "ScalingPolicies": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PolicyName": {},
              "TargetTrackingScalingPolicyConfiguration": {
                "type": "structure",
                "required": [
                  "TargetValue"
                ],
                "members": {
                  "DisableScaleIn": {
                    "type": "boolean"
                  },
                  "ScaleInCooldown": {
                    "type": "integer"
                  },
                  "ScaleOutCooldown": {
                    "type": "integer"
                  },
                  "TargetValue": {
                    "type": "double"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "TableName": {},
        "TableStatus": {},
        "Replicas": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "RegionName": {},
              "GlobalSecondaryIndexes": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "IndexName": {},
                    "IndexStatus": {},
                    "ProvisionedReadCapacityAutoScalingSettings": {
                      "shape": "S55"
                    },
                    "ProvisionedWriteCapacityAutoScalingSettings": {
                      "shape": "S55"
                    }
                  }
                }
              },
              "ReplicaProvisionedReadCapacityAutoScalingSettings": {
                "shape": "S55"
              },
              "ReplicaProvisionedWriteCapacityAutoScalingSettings": {
                "shape": "S55"
              },
              "ReplicaStatus": {}
            }
          }
        }
      }
    },
    "S6o": {
      "type": "structure",
      "required": [
        "ComparisonOperator"
      ],
      "members": {
        "AttributeValueList": {
          "shape": "S45"
        },
        "ComparisonOperator": {}
      }
    },
    "S6p": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S6o"
      }
    },
    "S7z": {
      "type": "structure",
      "members": {
        "MinimumUnits": {
          "type": "long"
        },
        "MaximumUnits": {
          "type": "long"
        },
        "AutoScalingDisabled": {
          "type": "boolean"
        },
        "AutoScalingRoleArn": {},
        "ScalingPolicyUpdate": {
          "type": "structure",
          "required": [
            "TargetTrackingScalingPolicyConfiguration"
          ],
          "members": {
            "PolicyName": {},
            "TargetTrackingScalingPolicyConfiguration": {
              "type": "structure",
              "required": [
                "TargetValue"
              ],
              "members": {
                "DisableScaleIn": {
                  "type": "boolean"
                },
                "ScaleInCooldown": {
                  "type": "integer"
                },
                "ScaleOutCooldown": {
                  "type": "integer"
                },
                "TargetValue": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    },
    "S8o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {},
          "ProvisionedThroughputOverride": {
            "shape": "S20"
          }
        }
      }
    },
    "S92": {
      "type": "structure",
      "required": [
        "Enabled",
        "AttributeName"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "AttributeName": {}
      }
    }
  }
}
},{}],69:[function(require,module,exports){
module.exports={
  "pagination": {
    "BatchGetItem": {
      "input_token": "RequestItems",
      "output_token": "UnprocessedKeys"
    },
    "ListContributorInsights": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTables": {
      "input_token": "ExclusiveStartTableName",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedTableName",
      "result_key": "TableNames"
    },
    "Query": {
      "input_token": "ExclusiveStartKey",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedKey",
      "result_key": "Items"
    },
    "Scan": {
      "input_token": "ExclusiveStartKey",
      "limit_key": "Limit",
      "output_token": "LastEvaluatedKey",
      "result_key": "Items"
    }
  }
}
},{}],70:[function(require,module,exports){
arguments[4][67][0].apply(exports,arguments)
},{"dup":67}],71:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-15",
    "endpointPrefix": "ec2",
    "protocol": "ec2",
    "serviceAbbreviation": "Amazon EC2",
    "serviceFullName": "Amazon Elastic Compute Cloud",
    "serviceId": "EC2",
    "signatureVersion": "v4",
    "uid": "ec2-2016-11-15",
    "xmlNamespace": "http://ec2.amazonaws.com/doc/2016-11-15"
  },
  "operations": {
    "AcceptReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExchangeId": {
            "locationName": "exchangeId"
          }
        }
      }
    },
    "AcceptTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Se",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "AcceptTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sn",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "AcceptVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "Su",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "AcceptVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "S13",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "AdvertiseByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1e",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "AllocateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "Domain": {},
          "Address": {},
          "PublicIpv4Pool": {},
          "NetworkBorderGroup": {},
          "CustomerOwnedIpv4Pool": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "AllocationId": {
            "locationName": "allocationId"
          },
          "PublicIpv4Pool": {
            "locationName": "publicIpv4Pool"
          },
          "NetworkBorderGroup": {
            "locationName": "networkBorderGroup"
          },
          "Domain": {
            "locationName": "domain"
          },
          "CustomerOwnedIp": {
            "locationName": "customerOwnedIp"
          },
          "CustomerOwnedIpv4Pool": {
            "locationName": "customerOwnedIpv4Pool"
          }
        }
      }
    },
    "AllocateHosts": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "Quantity"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "InstanceFamily": {},
          "Quantity": {
            "locationName": "quantity",
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "HostRecovery": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostIds": {
            "shape": "S1q",
            "locationName": "hostIdSet"
          }
        }
      }
    },
    "ApplySecurityGroupsToClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "VpcId",
          "SecurityGroupIds"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "VpcId": {},
          "SecurityGroupIds": {
            "shape": "S1u",
            "locationName": "SecurityGroupId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupIds": {
            "shape": "S1u",
            "locationName": "securityGroupIds"
          }
        }
      }
    },
    "AssignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S1y",
            "locationName": "ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignedIpv6Addresses": {
            "shape": "S1y",
            "locationName": "assignedIpv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "AssignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "AllowReassignment": {
            "locationName": "allowReassignment",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S22",
            "locationName": "privateIpAddress"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "AssignedPrivateIpAddresses": {
            "locationName": "assignedPrivateIpAddressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                }
              }
            }
          }
        }
      }
    },
    "AssociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "InstanceId": {},
          "PublicIp": {},
          "AllowReassociation": {
            "locationName": "allowReassociation",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "SubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "SubnetId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S2d",
            "locationName": "status"
          }
        }
      }
    },
    "AssociateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId",
          "VpcId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "AssociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "InstanceId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S2i"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S2k",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "AssociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "GatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "AssociationState": {
            "shape": "S2r",
            "locationName": "associationState"
          }
        }
      }
    },
    "AssociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "Ipv6CidrBlock",
          "SubnetId"
        ],
        "members": {
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S2v",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "AssociateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "So"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "S31",
            "locationName": "associations"
          }
        }
      }
    },
    "AssociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S39",
            "locationName": "association"
          }
        }
      }
    },
    "AssociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "CidrBlock": {},
          "VpcId": {
            "locationName": "vpcId"
          },
          "Ipv6CidrBlockNetworkBorderGroup": {},
          "Ipv6Pool": {},
          "Ipv6CidrBlock": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S3e",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S3h",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "Groups",
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S3j",
            "locationName": "SecurityGroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "AttachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceIndex",
          "InstanceId",
          "NetworkInterfaceId"
        ],
        "members": {
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          }
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "Device",
          "InstanceId",
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S3r"
      }
    },
    "AttachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcAttachment": {
            "shape": "S3w",
            "locationName": "attachment"
          }
        }
      }
    },
    "AuthorizeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "AuthorizeAllGroups": {
            "type": "boolean"
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S40",
            "locationName": "status"
          }
        }
      }
    },
    "AuthorizeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S43",
            "locationName": "ipPermissions"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      }
    },
    "AuthorizeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S43"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "BundleInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Storage"
        ],
        "members": {
          "InstanceId": {},
          "Storage": {
            "shape": "S4g"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S4k",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelBundleTask": {
      "input": {
        "type": "structure",
        "required": [
          "BundleId"
        ],
        "members": {
          "BundleId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S4k",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CancelConversionTask": {
      "input": {
        "type": "structure",
        "required": [
          "ConversionTaskId"
        ],
        "members": {
          "ConversionTaskId": {
            "locationName": "conversionTaskId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReasonMessage": {
            "locationName": "reasonMessage"
          }
        }
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskId"
        ],
        "members": {
          "ExportTaskId": {
            "locationName": "exportTaskId"
          }
        }
      }
    },
    "CancelImportTask": {
      "input": {
        "type": "structure",
        "members": {
          "CancelReason": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "PreviousState": {
            "locationName": "previousState"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "CancelReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesListingId"
        ],
        "members": {
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S53",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CancelSpotFleetRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestIds": {
            "shape": "S5f",
            "locationName": "spotFleetRequestId"
          },
          "TerminateInstances": {
            "locationName": "terminateInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetRequests": {
            "locationName": "successfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentSpotFleetRequestState": {
                  "locationName": "currentSpotFleetRequestState"
                },
                "PreviousSpotFleetRequestState": {
                  "locationName": "previousSpotFleetRequestState"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          },
          "UnsuccessfulFleetRequests": {
            "locationName": "unsuccessfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          }
        }
      }
    },
    "CancelSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotInstanceRequestIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S5q",
            "locationName": "SpotInstanceRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelledSpotInstanceRequests": {
            "locationName": "spotInstanceRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SpotInstanceRequestId": {
                  "locationName": "spotInstanceRequestId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          }
        }
      }
    },
    "ConfirmProductInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ProductCode"
        ],
        "members": {
          "InstanceId": {},
          "ProductCode": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OwnerId": {
            "locationName": "ownerId"
          },
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CopyFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "SourceFpgaImageId",
          "SourceRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "SourceFpgaImageId": {},
          "Description": {},
          "Name": {},
          "SourceRegion": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          }
        }
      }
    },
    "CopyImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SourceImageId",
          "SourceRegion"
        ],
        "members": {
          "ClientToken": {},
          "Description": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "Name": {},
          "SourceImageId": {},
          "SourceRegion": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceRegion",
          "SourceSnapshotId"
        ],
        "members": {
          "Description": {},
          "DestinationRegion": {
            "locationName": "destinationRegion"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "PresignedUrl": {
            "locationName": "presignedUrl"
          },
          "SourceRegion": {},
          "SourceSnapshotId": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "InstancePlatform",
          "InstanceCount"
        ],
        "members": {
          "ClientToken": {},
          "InstanceType": {},
          "InstancePlatform": {},
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "Tenancy": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EbsOptimized": {
            "type": "boolean"
          },
          "EphemeralStorage": {
            "type": "boolean"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "InstanceMatchCriteria": {},
          "TagSpecifications": {
            "shape": "S1l"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservation": {
            "shape": "S6c",
            "locationName": "capacityReservation"
          }
        }
      }
    },
    "CreateClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientCidrBlock",
          "ServerCertificateArn",
          "AuthenticationOptions",
          "ConnectionLogOptions"
        ],
        "members": {
          "ClientCidrBlock": {},
          "ServerCertificateArn": {},
          "AuthenticationOptions": {
            "locationName": "Authentication",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ActiveDirectory": {
                  "type": "structure",
                  "members": {
                    "DirectoryId": {}
                  }
                },
                "MutualAuthentication": {
                  "type": "structure",
                  "members": {
                    "ClientRootCertificateChainArn": {}
                  }
                },
                "FederatedAuthentication": {
                  "type": "structure",
                  "members": {
                    "SAMLProviderArn": {}
                  }
                }
              }
            }
          },
          "ConnectionLogOptions": {
            "shape": "S6l"
          },
          "DnsServers": {
            "shape": "So"
          },
          "TransportProtocol": {},
          "VpnPort": {
            "type": "integer"
          },
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "SecurityGroupIds": {
            "shape": "S1u",
            "locationName": "SecurityGroupId"
          },
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Status": {
            "shape": "S6o",
            "locationName": "status"
          },
          "DnsName": {
            "locationName": "dnsName"
          }
        }
      }
    },
    "CreateClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock",
          "TargetVpcSubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DestinationCidrBlock": {},
          "TargetVpcSubnetId": {},
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S6s",
            "locationName": "status"
          }
        }
      }
    },
    "CreateCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "BgpAsn",
          "Type"
        ],
        "members": {
          "BgpAsn": {
            "type": "integer"
          },
          "PublicIp": {
            "locationName": "IpAddress"
          },
          "CertificateArn": {},
          "Type": {},
          "DeviceName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateway": {
            "shape": "S6x",
            "locationName": "customerGateway"
          }
        }
      }
    },
    "CreateDefaultSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "S70",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateDefaultVpc": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "S76",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpConfigurations"
        ],
        "members": {
          "DhcpConfigurations": {
            "locationName": "dhcpConfiguration",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "Values": {
                  "shape": "So",
                  "locationName": "Value"
                }
              }
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "shape": "S7f",
            "locationName": "dhcpOptions"
          }
        }
      }
    },
    "CreateEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "ClientToken": {},
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "EgressOnlyInternetGateway": {
            "shape": "S7m",
            "locationName": "egressOnlyInternetGateway"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateConfigs",
          "TargetCapacitySpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "SpotOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "InstanceInterruptionBehavior": {},
              "InstancePoolsToUseCount": {
                "type": "integer"
              },
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "OnDemandOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "CapacityReservationOptions": {
                "type": "structure",
                "members": {
                  "UsageStrategy": {}
                }
              },
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "ExcessCapacityTerminationPolicy": {},
          "LaunchTemplateConfigs": {
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateSpecification": {
                  "type": "structure",
                  "members": {
                    "LaunchTemplateId": {},
                    "LaunchTemplateName": {},
                    "Version": {}
                  }
                },
                "Overrides": {
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceType": {},
                      "MaxPrice": {},
                      "SubnetId": {},
                      "AvailabilityZone": {},
                      "WeightedCapacity": {
                        "type": "double"
                      },
                      "Priority": {
                        "type": "double"
                      },
                      "Placement": {
                        "shape": "S87"
                      }
                    }
                  }
                }
              }
            }
          },
          "TargetCapacitySpecification": {
            "shape": "S88"
          },
          "TerminateInstancesWithExpiration": {
            "type": "boolean"
          },
          "Type": {},
          "ValidFrom": {
            "type": "timestamp"
          },
          "ValidUntil": {
            "type": "timestamp"
          },
          "ReplaceUnhealthyInstances": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {
            "locationName": "fleetId"
          },
          "Errors": {
            "locationName": "errorSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "S8f",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "ErrorCode": {
                  "locationName": "errorCode"
                },
                "ErrorMessage": {
                  "locationName": "errorMessage"
                }
              }
            }
          },
          "Instances": {
            "locationName": "fleetInstanceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "S8f",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "InstanceIds": {
                  "shape": "S8m",
                  "locationName": "instanceIds"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "Platform": {
                  "locationName": "platform"
                }
              }
            }
          }
        }
      }
    },
    "CreateFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds",
          "ResourceType",
          "TrafficType"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "DeliverLogsPermissionArn": {},
          "LogGroupName": {},
          "ResourceIds": {
            "locationName": "ResourceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ResourceType": {},
          "TrafficType": {},
          "LogDestinationType": {},
          "LogDestination": {},
          "LogFormat": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "MaxAggregationInterval": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "FlowLogIds": {
            "shape": "So",
            "locationName": "flowLogIdSet"
          },
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "CreateFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "InputStorageLocation"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InputStorageLocation": {
            "shape": "S8w"
          },
          "LogsStorageLocation": {
            "shape": "S8w"
          },
          "Description": {},
          "Name": {},
          "ClientToken": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          },
          "FpgaImageGlobalId": {
            "locationName": "fpgaImageGlobalId"
          }
        }
      }
    },
    "CreateImage": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "S8z",
            "locationName": "blockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Name": {
            "locationName": "name"
          },
          "NoReboot": {
            "locationName": "noReboot",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CreateInstanceExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ExportToS3Task": {
            "locationName": "exportToS3",
            "type": "structure",
            "members": {
              "ContainerFormat": {
                "locationName": "containerFormat"
              },
              "DiskImageFormat": {
                "locationName": "diskImageFormat"
              },
              "S3Bucket": {
                "locationName": "s3Bucket"
              },
              "S3Prefix": {
                "locationName": "s3Prefix"
              }
            }
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "TargetEnvironment": {
            "locationName": "targetEnvironment"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTask": {
            "shape": "S9a",
            "locationName": "exportTask"
          }
        }
      }
    },
    "CreateInternetGateway": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateway": {
            "shape": "S9g",
            "locationName": "internetGateway"
          }
        }
      }
    },
    "CreateKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "KeyName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyMaterial": {
            "locationName": "keyMaterial",
            "type": "string",
            "sensitive": true
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyPairId": {
            "locationName": "keyPairId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "CreateLaunchTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateName",
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateName": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "S9m"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sax",
            "locationName": "launchTemplate"
          },
          "Warning": {
            "shape": "Say",
            "locationName": "warning"
          }
        }
      }
    },
    "CreateLaunchTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "SourceVersion": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "S9m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersion": {
            "shape": "Sb3",
            "locationName": "launchTemplateVersion"
          },
          "Warning": {
            "shape": "Say",
            "locationName": "warning"
          }
        }
      }
    },
    "CreateLocalGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "LocalGatewayRouteTableId",
          "LocalGatewayVirtualInterfaceGroupId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "LocalGatewayRouteTableId": {},
          "LocalGatewayVirtualInterfaceGroupId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sc0",
            "locationName": "route"
          }
        }
      }
    },
    "CreateLocalGatewayRouteTableVpcAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId",
          "VpcId"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "VpcId": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociation": {
            "shape": "Sc5",
            "locationName": "localGatewayRouteTableVpcAssociation"
          }
        }
      }
    },
    "CreateNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId",
          "SubnetId"
        ],
        "members": {
          "AllocationId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "SubnetId": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "NatGateway": {
            "shape": "Sc9",
            "locationName": "natGateway"
          }
        }
      }
    },
    "CreateNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcl": {
            "shape": "Scg",
            "locationName": "networkAcl"
          }
        }
      }
    },
    "CreateNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Scl",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Scm",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "CreateNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S9v",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sbb",
            "locationName": "ipv6Addresses"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "S9y",
            "locationName": "privateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "InterfaceType": {},
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterface": {
            "shape": "Sct",
            "locationName": "networkInterface"
          }
        }
      }
    },
    "CreateNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "Permission"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "AwsAccountId": {},
          "AwsService": {},
          "Permission": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InterfacePermission": {
            "shape": "Sd7",
            "locationName": "interfacePermission"
          }
        }
      }
    },
    "CreatePlacementGroup": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          },
          "Strategy": {
            "locationName": "strategy"
          },
          "PartitionCount": {
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroup": {
            "shape": "Sdd",
            "locationName": "placementGroup"
          }
        }
      }
    },
    "CreateReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "InstanceCount",
          "PriceSchedules",
          "ReservedInstancesId"
        ],
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S53",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CreateRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "LocalGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CreateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTable": {
            "shape": "Sdq",
            "locationName": "routeTable"
          }
        }
      }
    },
    "CreateSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Description",
          "GroupName"
        ],
        "members": {
          "Description": {
            "locationName": "GroupDescription"
          },
          "GroupName": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Description": {},
          "VolumeId": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Se2"
      }
    },
    "CreateSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceSpecification"
        ],
        "members": {
          "Description": {},
          "InstanceSpecification": {
            "type": "structure",
            "members": {
              "InstanceId": {},
              "ExcludeBootVolume": {
                "type": "boolean"
              }
            }
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "CopyTagsFromSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "State": {
                  "locationName": "state"
                },
                "VolumeSize": {
                  "locationName": "volumeSize",
                  "type": "integer"
                },
                "StartTime": {
                  "locationName": "startTime",
                  "type": "timestamp"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "SnapshotId": {
                  "locationName": "snapshotId"
                }
              }
            }
          }
        }
      }
    },
    "CreateSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "locationName": "bucket"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Prefix": {
            "locationName": "prefix"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "Sed",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "CreateSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "CidrBlock",
          "VpcId"
        ],
        "members": {
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "CidrBlock": {},
          "Ipv6CidrBlock": {},
          "OutpostArn": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "S70",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources",
          "Tags"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Sej",
            "locationName": "ResourceId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "Tag"
          }
        }
      }
    },
    "CreateTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Sen",
            "locationName": "trafficMirrorFilter"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId",
          "TrafficDirection",
          "RuleNumber",
          "RuleAction",
          "DestinationCidrBlock",
          "SourceCidrBlock"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sex"
          },
          "SourcePortRange": {
            "shape": "Sex"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sep",
            "locationName": "trafficMirrorFilterRule"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "TrafficMirrorTargetId",
          "TrafficMirrorFilterId",
          "SessionNumber"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sf2",
            "locationName": "trafficMirrorSession"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {},
          "NetworkLoadBalancerArn": {},
          "Description": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTarget": {
            "shape": "Sf5",
            "locationName": "trafficMirrorTarget"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTransitGateway": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "Options": {
            "type": "structure",
            "members": {
              "AmazonSideAsn": {
                "type": "long"
              },
              "AutoAcceptSharedAttachments": {},
              "DefaultRouteTableAssociation": {},
              "DefaultRouteTablePropagation": {},
              "VpnEcmpSupport": {},
              "DnsSupport": {},
              "MulticastSupport": {}
            }
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Sff",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "CreateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomain": {
            "shape": "Sfk",
            "locationName": "transitGatewayMulticastDomain"
          }
        }
      }
    },
    "CreateTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId",
          "PeerTransitGatewayId",
          "PeerAccountId",
          "PeerRegion"
        ],
        "members": {
          "TransitGatewayId": {},
          "PeerTransitGatewayId": {},
          "PeerAccountId": {},
          "PeerRegion": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Se",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "CreateTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sfr",
            "locationName": "route"
          }
        }
      }
    },
    "CreateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "TagSpecifications": {
            "shape": "S1l"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sfy",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "CreateTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId",
          "VpcId",
          "SubnetIds"
        ],
        "members": {
          "TransitGatewayId": {},
          "VpcId": {},
          "SubnetIds": {
            "shape": "Sg1"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "Ipv6Support": {}
            }
          },
          "TagSpecifications": {
            "shape": "S1l"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sn",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "KmsKeyId": {},
          "OutpostArn": {},
          "Size": {
            "type": "integer"
          },
          "SnapshotId": {},
          "VolumeType": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "MultiAttachEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sg5"
      }
    },
    "CreateVpc": {
      "input": {
        "type": "structure",
        "required": [
          "CidrBlock"
        ],
        "members": {
          "CidrBlock": {},
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "Ipv6Pool": {},
          "Ipv6CidrBlock": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "Ipv6CidrBlockNetworkBorderGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "S76",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "ServiceName"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointType": {},
          "VpcId": {},
          "ServiceName": {},
          "PolicyDocument": {},
          "RouteTableIds": {
            "shape": "Sgc",
            "locationName": "RouteTableId"
          },
          "SubnetIds": {
            "shape": "Sgd",
            "locationName": "SubnetId"
          },
          "SecurityGroupIds": {
            "shape": "Sge",
            "locationName": "SecurityGroupId"
          },
          "ClientToken": {},
          "PrivateDnsEnabled": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoint": {
            "shape": "Sgg",
            "locationName": "vpcEndpoint"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationArn",
          "ConnectionEvents"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "So"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotification": {
            "shape": "Sgp",
            "locationName": "connectionNotification"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkLoadBalancerArns"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "PrivateDnsName": {},
          "NetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "NetworkLoadBalancerArn"
          },
          "ClientToken": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfiguration": {
            "shape": "Sgu",
            "locationName": "serviceConfiguration"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PeerOwnerId": {
            "locationName": "peerOwnerId"
          },
          "PeerVpcId": {
            "locationName": "peerVpcId"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "PeerRegion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "S13",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "CreateVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId",
          "Type"
        ],
        "members": {
          "CustomerGatewayId": {},
          "Type": {},
          "VpnGatewayId": {},
          "TransitGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Options": {
            "locationName": "options",
            "type": "structure",
            "members": {
              "EnableAcceleration": {
                "type": "boolean"
              },
              "StaticRoutesOnly": {
                "locationName": "staticRoutesOnly",
                "type": "boolean"
              },
              "TunnelOptions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "TunnelInsideCidr": {},
                    "PreSharedKey": {},
                    "Phase1LifetimeSeconds": {
                      "type": "integer"
                    },
                    "Phase2LifetimeSeconds": {
                      "type": "integer"
                    },
                    "RekeyMarginTimeSeconds": {
                      "type": "integer"
                    },
                    "RekeyFuzzPercentage": {
                      "type": "integer"
                    },
                    "ReplayWindowSize": {
                      "type": "integer"
                    },
                    "DPDTimeoutSeconds": {
                      "type": "integer"
                    },
                    "Phase1EncryptionAlgorithms": {
                      "shape": "Sh8",
                      "locationName": "Phase1EncryptionAlgorithm"
                    },
                    "Phase2EncryptionAlgorithms": {
                      "shape": "Sha",
                      "locationName": "Phase2EncryptionAlgorithm"
                    },
                    "Phase1IntegrityAlgorithms": {
                      "shape": "Shc",
                      "locationName": "Phase1IntegrityAlgorithm"
                    },
                    "Phase2IntegrityAlgorithms": {
                      "shape": "She",
                      "locationName": "Phase2IntegrityAlgorithm"
                    },
                    "Phase1DHGroupNumbers": {
                      "shape": "Shg",
                      "locationName": "Phase1DHGroupNumber"
                    },
                    "Phase2DHGroupNumbers": {
                      "shape": "Shi",
                      "locationName": "Phase2DHGroupNumber"
                    },
                    "IKEVersions": {
                      "shape": "Shk",
                      "locationName": "IKEVersion"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Shn",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "CreateVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "CreateVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "AvailabilityZone": {},
          "Type": {},
          "AmazonSideAsn": {
            "type": "long"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateway": {
            "shape": "Sig",
            "locationName": "vpnGateway"
          }
        }
      }
    },
    "DeleteClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S6o",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetVpcSubnetId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S6s",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId"
        ],
        "members": {
          "CustomerGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "EgressOnlyInternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnCode": {
            "locationName": "returnCode",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteFleets": {
      "input": {
        "type": "structure",
        "required": [
          "FleetIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FleetIds": {
            "shape": "Sis",
            "locationName": "FleetId"
          },
          "TerminateInstances": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetDeletions": {
            "locationName": "successfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentFleetState": {
                  "locationName": "currentFleetState"
                },
                "PreviousFleetState": {
                  "locationName": "previousFleetState"
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          },
          "UnsuccessfulFleetDeletions": {
            "locationName": "unsuccessfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          }
        }
      }
    },
    "DeleteFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "FlowLogIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FlowLogIds": {
            "shape": "Sj2",
            "locationName": "FlowLogId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          }
        }
      }
    },
    "DeleteKeyPair": {
      "input": {
        "type": "structure",
        "members": {
          "KeyName": {},
          "KeyPairId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sax",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "DeleteLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "required": [
          "Versions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Sjd",
            "locationName": "LaunchTemplateVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "successfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                }
              }
            }
          },
          "UnsuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "unsuccessfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                },
                "ResponseError": {
                  "locationName": "responseError",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DeleteLocalGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "LocalGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "LocalGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sc0",
            "locationName": "route"
          }
        }
      }
    },
    "DeleteLocalGatewayRouteTableVpcAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableVpcAssociationId"
        ],
        "members": {
          "LocalGatewayRouteTableVpcAssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociation": {
            "shape": "Sc5",
            "locationName": "localGatewayRouteTableVpcAssociation"
          }
        }
      }
    },
    "DeleteNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "NatGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      }
    },
    "DeleteNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      }
    },
    "DeleteNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "RuleNumber"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "DeleteNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "DeleteNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfacePermissionId"
        ],
        "members": {
          "NetworkInterfacePermissionId": {},
          "Force": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeletePlacementGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          }
        }
      }
    },
    "DeleteQueuedReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstancesIds": {
            "locationName": "ReservedInstancesId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulQueuedPurchaseDeletions": {
            "locationName": "successfulQueuedPurchaseDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                }
              }
            }
          },
          "FailedQueuedPurchaseDeletions": {
            "locationName": "failedQueuedPurchaseDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                }
              }
            }
          }
        }
      }
    },
    "DeleteRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteSecurityGroup": {
      "input": {
        "type": "structure",
        "members": {
          "GroupId": {},
          "GroupName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Sej",
            "locationName": "resourceId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tag"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterId": {
            "locationName": "trafficMirrorFilterId"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRuleId": {
            "locationName": "trafficMirrorFilterRuleId"
          }
        }
      }
    },
    "DeleteTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionId": {
            "locationName": "trafficMirrorSessionId"
          }
        }
      }
    },
    "DeleteTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorTargetId"
        ],
        "members": {
          "TrafficMirrorTargetId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetId": {
            "locationName": "trafficMirrorTargetId"
          }
        }
      }
    },
    "DeleteTransitGateway": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Sff",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "DeleteTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayMulticastDomainId"
        ],
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomain": {
            "shape": "Sfk",
            "locationName": "transitGatewayMulticastDomain"
          }
        }
      }
    },
    "DeleteTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Se",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "DeleteTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "DestinationCidrBlock"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sfr",
            "locationName": "route"
          }
        }
      }
    },
    "DeleteTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sfy",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "DeleteTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sn",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpc": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationIds": {
            "shape": "So",
            "locationName": "ConnectionNotificationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sl5",
            "locationName": "ServiceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "Su",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "DeleteVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId"
        ],
        "members": {
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeprovisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1e",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "DeregisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeregisterInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTagAttribute": {
            "type": "structure",
            "members": {
              "IncludeAllTagsOfInstance": {
                "type": "boolean"
              },
              "InstanceTagKeys": {
                "shape": "Slj",
                "locationName": "InstanceTagKey"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "Sll",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "DeregisterTransitGatewayMulticastGroupMembers": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "Sln"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeregisteredMulticastGroupMembers": {
            "locationName": "deregisteredMulticastGroupMembers",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "DeregisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "deregisteredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "DeregisterTransitGatewayMulticastGroupSources": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "Sln"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeregisteredMulticastGroupSources": {
            "locationName": "deregisteredMulticastGroupSources",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "DeregisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "deregisteredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AttributeNames": {
            "locationName": "attributeName",
            "type": "list",
            "member": {
              "locationName": "attributeName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountAttributes": {
            "locationName": "accountAttributeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AttributeName": {
                  "locationName": "attributeName"
                },
                "AttributeValues": {
                  "locationName": "attributeValueSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AttributeValue": {
                        "locationName": "attributeValue"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "PublicIps": {
            "locationName": "PublicIp",
            "type": "list",
            "member": {
              "locationName": "PublicIp"
            }
          },
          "AllocationIds": {
            "locationName": "AllocationId",
            "type": "list",
            "member": {
              "locationName": "AllocationId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Addresses": {
            "locationName": "addressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                },
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AssociationId": {
                  "locationName": "associationId"
                },
                "Domain": {
                  "locationName": "domain"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "NetworkInterfaceOwnerId": {
                  "locationName": "networkInterfaceOwnerId"
                },
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "PublicIpv4Pool": {
                  "locationName": "publicIpv4Pool"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "CustomerOwnedIp": {
                  "locationName": "customerOwnedIp"
                },
                "CustomerOwnedIpv4Pool": {
                  "locationName": "customerOwnedIpv4Pool"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAggregateIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UseLongIdsAggregated": {
            "locationName": "useLongIdsAggregated",
            "type": "boolean"
          },
          "Statuses": {
            "shape": "Smb",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeAvailabilityZones": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "ZoneNames": {
            "locationName": "ZoneName",
            "type": "list",
            "member": {
              "locationName": "ZoneName"
            }
          },
          "ZoneIds": {
            "locationName": "ZoneId",
            "type": "list",
            "member": {
              "locationName": "ZoneId"
            }
          },
          "AllAvailabilityZones": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "locationName": "availabilityZoneInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "State": {
                  "locationName": "zoneState"
                },
                "OptInStatus": {
                  "locationName": "optInStatus"
                },
                "Messages": {
                  "locationName": "messageSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Message": {
                        "locationName": "message"
                      }
                    }
                  }
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "ZoneName": {
                  "locationName": "zoneName"
                },
                "ZoneId": {
                  "locationName": "zoneId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                }
              }
            }
          }
        }
      }
    },
    "DescribeBundleTasks": {
      "input": {
        "type": "structure",
        "members": {
          "BundleIds": {
            "locationName": "BundleId",
            "type": "list",
            "member": {
              "locationName": "BundleId"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTasks": {
            "locationName": "bundleInstanceTasksSet",
            "type": "list",
            "member": {
              "shape": "S4k",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeByoipCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidrs": {
            "locationName": "byoipCidrSet",
            "type": "list",
            "member": {
              "shape": "S1e",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCapacityReservations": {
      "input": {
        "type": "structure",
        "members": {
          "CapacityReservationIds": {
            "locationName": "CapacityReservationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservations": {
            "locationName": "capacityReservationSet",
            "type": "list",
            "member": {
              "shape": "S6c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeClassicLinkInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "locationName": "instancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Groups": {
                  "shape": "Scw",
                  "locationName": "groupSet"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnAuthorizationRules": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizationRules": {
            "locationName": "authorizationRule",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "AccessAll": {
                  "locationName": "accessAll",
                  "type": "boolean"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "Status": {
                  "shape": "S40",
                  "locationName": "status"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "locationName": "connections",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Timestamp": {
                  "locationName": "timestamp"
                },
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "Username": {
                  "locationName": "username"
                },
                "ConnectionEstablishedTime": {
                  "locationName": "connectionEstablishedTime"
                },
                "IngressBytes": {
                  "locationName": "ingressBytes"
                },
                "EgressBytes": {
                  "locationName": "egressBytes"
                },
                "IngressPackets": {
                  "locationName": "ingressPackets"
                },
                "EgressPackets": {
                  "locationName": "egressPackets"
                },
                "ClientIp": {
                  "locationName": "clientIp"
                },
                "CommonName": {
                  "locationName": "commonName"
                },
                "Status": {
                  "shape": "Snh",
                  "locationName": "status"
                },
                "ConnectionEndTime": {
                  "locationName": "connectionEndTime"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointIds": {
            "locationName": "ClientVpnEndpointId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpoints": {
            "locationName": "clientVpnEndpoint",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "Status": {
                  "shape": "S6o",
                  "locationName": "status"
                },
                "CreationTime": {
                  "locationName": "creationTime"
                },
                "DeletionTime": {
                  "locationName": "deletionTime"
                },
                "DnsName": {
                  "locationName": "dnsName"
                },
                "ClientCidrBlock": {
                  "locationName": "clientCidrBlock"
                },
                "DnsServers": {
                  "shape": "So",
                  "locationName": "dnsServer"
                },
                "SplitTunnel": {
                  "locationName": "splitTunnel",
                  "type": "boolean"
                },
                "VpnProtocol": {
                  "locationName": "vpnProtocol"
                },
                "TransportProtocol": {
                  "locationName": "transportProtocol"
                },
                "VpnPort": {
                  "locationName": "vpnPort",
                  "type": "integer"
                },
                "AssociatedTargetNetworks": {
                  "deprecated": true,
                  "deprecatedMessage": "This property is deprecated. To view the target networks associated with a Client VPN endpoint, call DescribeClientVpnTargetNetworks and inspect the clientVpnTargetNetworks response element.",
                  "locationName": "associatedTargetNetwork",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "NetworkId": {
                        "locationName": "networkId"
                      },
                      "NetworkType": {
                        "locationName": "networkType"
                      }
                    }
                  }
                },
                "ServerCertificateArn": {
                  "locationName": "serverCertificateArn"
                },
                "AuthenticationOptions": {
                  "locationName": "authenticationOptions",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Type": {
                        "locationName": "type"
                      },
                      "ActiveDirectory": {
                        "locationName": "activeDirectory",
                        "type": "structure",
                        "members": {
                          "DirectoryId": {
                            "locationName": "directoryId"
                          }
                        }
                      },
                      "MutualAuthentication": {
                        "locationName": "mutualAuthentication",
                        "type": "structure",
                        "members": {
                          "ClientRootCertificateChain": {
                            "locationName": "clientRootCertificateChain"
                          }
                        }
                      },
                      "FederatedAuthentication": {
                        "locationName": "federatedAuthentication",
                        "type": "structure",
                        "members": {
                          "SamlProviderArn": {
                            "locationName": "samlProviderArn"
                          }
                        }
                      }
                    }
                  }
                },
                "ConnectionLogOptions": {
                  "locationName": "connectionLogOptions",
                  "type": "structure",
                  "members": {
                    "Enabled": {
                      "type": "boolean"
                    },
                    "CloudwatchLogGroup": {},
                    "CloudwatchLogStream": {}
                  }
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "SecurityGroupIds": {
                  "shape": "S1u",
                  "locationName": "securityGroupIdSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routes",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "TargetSubnet": {
                  "locationName": "targetSubnet"
                },
                "Type": {
                  "locationName": "type"
                },
                "Origin": {
                  "locationName": "origin"
                },
                "Status": {
                  "shape": "S6s",
                  "locationName": "status"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnTargetNetworks": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationIds": {
            "shape": "So"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnTargetNetworks": {
            "locationName": "clientVpnTargetNetworks",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AssociationId": {
                  "locationName": "associationId"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "TargetNetworkId": {
                  "locationName": "targetNetworkId"
                },
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Status": {
                  "shape": "S2d",
                  "locationName": "status"
                },
                "SecurityGroups": {
                  "shape": "So",
                  "locationName": "securityGroups"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCoipPools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPools": {
            "locationName": "coipPoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "PoolCidrs": {
                  "shape": "So",
                  "locationName": "poolCidrSet"
                },
                "LocalGatewayRouteTableId": {
                  "locationName": "localGatewayRouteTableId"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeConversionTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ConversionTaskIds": {
            "locationName": "conversionTaskId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTasks": {
            "locationName": "conversionTasks",
            "type": "list",
            "member": {
              "shape": "Soj",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeCustomerGateways": {
      "input": {
        "type": "structure",
        "members": {
          "CustomerGatewayIds": {
            "locationName": "CustomerGatewayId",
            "type": "list",
            "member": {
              "locationName": "CustomerGatewayId"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateways": {
            "locationName": "customerGatewaySet",
            "type": "list",
            "member": {
              "shape": "S6x",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeDhcpOptions": {
      "input": {
        "type": "structure",
        "members": {
          "DhcpOptionsIds": {
            "locationName": "DhcpOptionsId",
            "type": "list",
            "member": {
              "locationName": "DhcpOptionsId"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "locationName": "dhcpOptionsSet",
            "type": "list",
            "member": {
              "shape": "S7f",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeEgressOnlyInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayIds": {
            "locationName": "EgressOnlyInternetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EgressOnlyInternetGateways": {
            "locationName": "egressOnlyInternetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S7m",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeElasticGpus": {
      "input": {
        "type": "structure",
        "members": {
          "ElasticGpuIds": {
            "locationName": "ElasticGpuId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticGpuSet": {
            "locationName": "elasticGpuSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ElasticGpuId": {
                  "locationName": "elasticGpuId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "ElasticGpuType": {
                  "locationName": "elasticGpuType"
                },
                "ElasticGpuHealth": {
                  "locationName": "elasticGpuHealth",
                  "type": "structure",
                  "members": {
                    "Status": {
                      "locationName": "status"
                    }
                  }
                },
                "ElasticGpuState": {
                  "locationName": "elasticGpuState"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "ExportImageTaskIds": {
            "locationName": "ExportImageTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportImageTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportImageTasks": {
            "locationName": "exportImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ExportImageTaskId": {
                  "locationName": "exportImageTaskId"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "S3ExportLocation": {
                  "shape": "Spm",
                  "locationName": "s3ExportLocation"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ExportTaskIds": {
            "locationName": "exportTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportTaskId"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTasks": {
            "locationName": "exportTaskSet",
            "type": "list",
            "member": {
              "shape": "S9a",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FastSnapshotRestores": {
            "locationName": "fastSnapshotRestoreSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFleetHistory": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EventType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "Sq3",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "Sq6",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          }
        }
      }
    },
    "DescribeFleets": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetIds": {
            "shape": "Sis",
            "locationName": "FleetId"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Fleets": {
            "locationName": "fleetSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "FleetId": {
                  "locationName": "fleetId"
                },
                "FleetState": {
                  "locationName": "fleetState"
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "ExcessCapacityTerminationPolicy": {
                  "locationName": "excessCapacityTerminationPolicy"
                },
                "FulfilledCapacity": {
                  "locationName": "fulfilledCapacity",
                  "type": "double"
                },
                "FulfilledOnDemandCapacity": {
                  "locationName": "fulfilledOnDemandCapacity",
                  "type": "double"
                },
                "LaunchTemplateConfigs": {
                  "locationName": "launchTemplateConfigs",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateSpecification": {
                        "shape": "S8g",
                        "locationName": "launchTemplateSpecification"
                      },
                      "Overrides": {
                        "locationName": "overrides",
                        "type": "list",
                        "member": {
                          "shape": "S8h",
                          "locationName": "item"
                        }
                      }
                    }
                  }
                },
                "TargetCapacitySpecification": {
                  "locationName": "targetCapacitySpecification",
                  "type": "structure",
                  "members": {
                    "TotalTargetCapacity": {
                      "locationName": "totalTargetCapacity",
                      "type": "integer"
                    },
                    "OnDemandTargetCapacity": {
                      "locationName": "onDemandTargetCapacity",
                      "type": "integer"
                    },
                    "SpotTargetCapacity": {
                      "locationName": "spotTargetCapacity",
                      "type": "integer"
                    },
                    "DefaultTargetCapacityType": {
                      "locationName": "defaultTargetCapacityType"
                    }
                  }
                },
                "TerminateInstancesWithExpiration": {
                  "locationName": "terminateInstancesWithExpiration",
                  "type": "boolean"
                },
                "Type": {
                  "locationName": "type"
                },
                "ValidFrom": {
                  "locationName": "validFrom",
                  "type": "timestamp"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "ReplaceUnhealthyInstances": {
                  "locationName": "replaceUnhealthyInstances",
                  "type": "boolean"
                },
                "SpotOptions": {
                  "locationName": "spotOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "InstanceInterruptionBehavior": {
                      "locationName": "instanceInterruptionBehavior"
                    },
                    "InstancePoolsToUseCount": {
                      "locationName": "instancePoolsToUseCount",
                      "type": "integer"
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "OnDemandOptions": {
                  "locationName": "onDemandOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "CapacityReservationOptions": {
                      "locationName": "capacityReservationOptions",
                      "type": "structure",
                      "members": {
                        "UsageStrategy": {
                          "locationName": "usageStrategy"
                        }
                      }
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "Errors": {
                  "locationName": "errorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "S8f",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "ErrorCode": {
                        "locationName": "errorCode"
                      },
                      "ErrorMessage": {
                        "locationName": "errorMessage"
                      }
                    }
                  }
                },
                "Instances": {
                  "locationName": "fleetInstanceSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "S8f",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "InstanceIds": {
                        "shape": "S8m",
                        "locationName": "instanceIds"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      },
                      "Platform": {
                        "locationName": "platform"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeFlowLogs": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filter": {
            "shape": "Sm2"
          },
          "FlowLogIds": {
            "shape": "Sj2",
            "locationName": "FlowLogId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowLogs": {
            "locationName": "flowLogSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "DeliverLogsErrorMessage": {
                  "locationName": "deliverLogsErrorMessage"
                },
                "DeliverLogsPermissionArn": {
                  "locationName": "deliverLogsPermissionArn"
                },
                "DeliverLogsStatus": {
                  "locationName": "deliverLogsStatus"
                },
                "FlowLogId": {
                  "locationName": "flowLogId"
                },
                "FlowLogStatus": {
                  "locationName": "flowLogStatus"
                },
                "LogGroupName": {
                  "locationName": "logGroupName"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "TrafficType": {
                  "locationName": "trafficType"
                },
                "LogDestinationType": {
                  "locationName": "logDestinationType"
                },
                "LogDestination": {
                  "locationName": "logDestination"
                },
                "LogFormat": {
                  "locationName": "logFormat"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "MaxAggregationInterval": {
                  "locationName": "maxAggregationInterval",
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "Sqw",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "DescribeFpgaImages": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageIds": {
            "locationName": "FpgaImageId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Owners": {
            "shape": "Sr5",
            "locationName": "Owner"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImages": {
            "locationName": "fpgaImageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "FpgaImageId": {
                  "locationName": "fpgaImageId"
                },
                "FpgaImageGlobalId": {
                  "locationName": "fpgaImageGlobalId"
                },
                "Name": {
                  "locationName": "name"
                },
                "Description": {
                  "locationName": "description"
                },
                "ShellVersion": {
                  "locationName": "shellVersion"
                },
                "PciId": {
                  "locationName": "pciId",
                  "type": "structure",
                  "members": {
                    "DeviceId": {},
                    "VendorId": {},
                    "SubsystemId": {},
                    "SubsystemVendorId": {}
                  }
                },
                "State": {
                  "locationName": "state",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "UpdateTime": {
                  "locationName": "updateTime",
                  "type": "timestamp"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "ProductCodes": {
                  "shape": "Sr0",
                  "locationName": "productCodes"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tags"
                },
                "Public": {
                  "locationName": "public",
                  "type": "boolean"
                },
                "DataRetentionSupport": {
                  "locationName": "dataRetentionSupport",
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHostReservationOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sm2"
          },
          "MaxDuration": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "integer"
          },
          "NextToken": {},
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingSet": {
            "locationName": "offeringSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                }
              }
            }
          }
        }
      }
    },
    "DescribeHostReservations": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sm2"
          },
          "HostReservationIdSet": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostReservationSet": {
            "locationName": "hostReservationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Count": {
                  "locationName": "count",
                  "type": "integer"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "HostIdSet": {
                  "shape": "Srq",
                  "locationName": "hostIdSet"
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHosts": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sm2",
            "locationName": "filter"
          },
          "HostIds": {
            "shape": "Srt",
            "locationName": "hostId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Hosts": {
            "locationName": "hostSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AutoPlacement": {
                  "locationName": "autoPlacement"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableCapacity": {
                  "locationName": "availableCapacity",
                  "type": "structure",
                  "members": {
                    "AvailableInstanceCapacity": {
                      "locationName": "availableInstanceCapacity",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "AvailableCapacity": {
                            "locationName": "availableCapacity",
                            "type": "integer"
                          },
                          "InstanceType": {
                            "locationName": "instanceType"
                          },
                          "TotalCapacity": {
                            "locationName": "totalCapacity",
                            "type": "integer"
                          }
                        }
                      }
                    },
                    "AvailableVCpus": {
                      "locationName": "availableVCpus",
                      "type": "integer"
                    }
                  }
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "HostId": {
                  "locationName": "hostId"
                },
                "HostProperties": {
                  "locationName": "hostProperties",
                  "type": "structure",
                  "members": {
                    "Cores": {
                      "locationName": "cores",
                      "type": "integer"
                    },
                    "InstanceType": {
                      "locationName": "instanceType"
                    },
                    "InstanceFamily": {
                      "locationName": "instanceFamily"
                    },
                    "Sockets": {
                      "locationName": "sockets",
                      "type": "integer"
                    },
                    "TotalVCpus": {
                      "locationName": "totalVCpus",
                      "type": "integer"
                    }
                  }
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "Instances": {
                  "locationName": "instances",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceId": {
                        "locationName": "instanceId"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      },
                      "OwnerId": {
                        "locationName": "ownerId"
                      }
                    }
                  }
                },
                "State": {
                  "locationName": "state"
                },
                "AllocationTime": {
                  "locationName": "allocationTime",
                  "type": "timestamp"
                },
                "ReleaseTime": {
                  "locationName": "releaseTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "HostRecovery": {
                  "locationName": "hostRecovery"
                },
                "AllowsMultipleInstanceTypes": {
                  "locationName": "allowsMultipleInstanceTypes"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "AvailabilityZoneId": {
                  "locationName": "availabilityZoneId"
                },
                "MemberOfServiceLinkedResourceGroup": {
                  "locationName": "memberOfServiceLinkedResourceGroup",
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIamInstanceProfileAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "AssociationId"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociations": {
            "locationName": "iamInstanceProfileAssociationSet",
            "type": "list",
            "member": {
              "shape": "S2k",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "Resource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Smb",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Smb",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlockDeviceMappings": {
            "shape": "Ssi",
            "locationName": "blockDeviceMapping"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "LaunchPermissions": {
            "shape": "Ssj",
            "locationName": "launchPermission"
          },
          "ProductCodes": {
            "shape": "Sr0",
            "locationName": "productCodes"
          },
          "Description": {
            "shape": "S7j",
            "locationName": "description"
          },
          "KernelId": {
            "shape": "S7j",
            "locationName": "kernel"
          },
          "RamdiskId": {
            "shape": "S7j",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "S7j",
            "locationName": "sriovNetSupport"
          }
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "members": {
          "ExecutableUsers": {
            "locationName": "ExecutableBy",
            "type": "list",
            "member": {
              "locationName": "ExecutableBy"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "ImageIds": {
            "locationName": "ImageId",
            "type": "list",
            "member": {
              "locationName": "ImageId"
            }
          },
          "Owners": {
            "shape": "Sr5",
            "locationName": "Owner"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "locationName": "imagesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "CreationDate": {
                  "locationName": "creationDate"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImageLocation": {
                  "locationName": "imageLocation"
                },
                "ImageType": {
                  "locationName": "imageType"
                },
                "Public": {
                  "locationName": "isPublic",
                  "type": "boolean"
                },
                "KernelId": {
                  "locationName": "kernelId"
                },
                "OwnerId": {
                  "locationName": "imageOwnerId"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "PlatformDetails": {
                  "locationName": "platformDetails"
                },
                "UsageOperation": {
                  "locationName": "usageOperation"
                },
                "ProductCodes": {
                  "shape": "Sr0",
                  "locationName": "productCodes"
                },
                "RamdiskId": {
                  "locationName": "ramdiskId"
                },
                "State": {
                  "locationName": "imageState"
                },
                "BlockDeviceMappings": {
                  "shape": "Ssi",
                  "locationName": "blockDeviceMapping"
                },
                "Description": {
                  "locationName": "description"
                },
                "EnaSupport": {
                  "locationName": "enaSupport",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageOwnerAlias": {
                  "locationName": "imageOwnerAlias"
                },
                "Name": {
                  "locationName": "name"
                },
                "RootDeviceName": {
                  "locationName": "rootDeviceName"
                },
                "RootDeviceType": {
                  "locationName": "rootDeviceType"
                },
                "SriovNetSupport": {
                  "locationName": "sriovNetSupport"
                },
                "StateReason": {
                  "shape": "Ssw",
                  "locationName": "stateReason"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "VirtualizationType": {
                  "locationName": "virtualizationType"
                }
              }
            }
          }
        }
      }
    },
    "DescribeImportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2"
          },
          "ImportTaskIds": {
            "locationName": "ImportTaskId",
            "type": "list",
            "member": {
              "locationName": "ImportTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportImageTasks": {
            "locationName": "importImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "Description": {
                  "locationName": "description"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "KmsKeyId": {
                  "locationName": "kmsKeyId"
                },
                "LicenseType": {
                  "locationName": "licenseType"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "SnapshotDetails": {
                  "shape": "St4",
                  "locationName": "snapshotDetailSet"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "LicenseSpecifications": {
                  "shape": "St7",
                  "locationName": "licenseSpecifications"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeImportSnapshotTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2"
          },
          "ImportTaskIds": {
            "locationName": "ImportTaskId",
            "type": "list",
            "member": {
              "locationName": "ImportTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportSnapshotTasks": {
            "locationName": "importSnapshotTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "SnapshotTaskDetail": {
                  "shape": "Stf",
                  "locationName": "snapshotTaskDetail"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "Scw",
            "locationName": "groupSet"
          },
          "BlockDeviceMappings": {
            "shape": "Stj",
            "locationName": "blockDeviceMapping"
          },
          "DisableApiTermination": {
            "shape": "Stm",
            "locationName": "disableApiTermination"
          },
          "EnaSupport": {
            "shape": "Stm",
            "locationName": "enaSupport"
          },
          "EbsOptimized": {
            "shape": "Stm",
            "locationName": "ebsOptimized"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S7j",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "S7j",
            "locationName": "instanceType"
          },
          "KernelId": {
            "shape": "S7j",
            "locationName": "kernel"
          },
          "ProductCodes": {
            "shape": "Sr0",
            "locationName": "productCodes"
          },
          "RamdiskId": {
            "shape": "S7j",
            "locationName": "ramdisk"
          },
          "RootDeviceName": {
            "shape": "S7j",
            "locationName": "rootDeviceName"
          },
          "SourceDestCheck": {
            "shape": "Stm",
            "locationName": "sourceDestCheck"
          },
          "SriovNetSupport": {
            "shape": "S7j",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "shape": "S7j",
            "locationName": "userData"
          }
        }
      }
    },
    "DescribeInstanceCreditSpecifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceCreditSpecifications": {
            "locationName": "instanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "CpuCredits": {
                  "locationName": "cpuCredits"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "Sll",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "DescribeInstanceStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "IncludeAllInstances": {
            "locationName": "includeAllInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceStatuses": {
            "locationName": "instanceStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "shape": "Stz",
                    "locationName": "item"
                  }
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "InstanceState": {
                  "shape": "Su2",
                  "locationName": "instanceState"
                },
                "InstanceStatus": {
                  "shape": "Su4",
                  "locationName": "instanceStatus"
                },
                "SystemStatus": {
                  "shape": "Su4",
                  "locationName": "systemStatus"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceTypeOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LocationType": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypeOfferings": {
            "locationName": "instanceTypeOfferingSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "LocationType": {
                  "locationName": "locationType"
                },
                "Location": {
                  "locationName": "location"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceTypes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypes": {
            "locationName": "instanceTypeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "CurrentGeneration": {
                  "locationName": "currentGeneration",
                  "type": "boolean"
                },
                "FreeTierEligible": {
                  "locationName": "freeTierEligible",
                  "type": "boolean"
                },
                "SupportedUsageClasses": {
                  "locationName": "supportedUsageClasses",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "SupportedRootDeviceTypes": {
                  "locationName": "supportedRootDeviceTypes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "SupportedVirtualizationTypes": {
                  "locationName": "supportedVirtualizationTypes",
                  "type": "list",
                  "member": {
                    "locationName": "item"
                  }
                },
                "BareMetal": {
                  "locationName": "bareMetal",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ProcessorInfo": {
                  "locationName": "processorInfo",
                  "type": "structure",
                  "members": {
                    "SupportedArchitectures": {
                      "locationName": "supportedArchitectures",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    },
                    "SustainedClockSpeedInGhz": {
                      "locationName": "sustainedClockSpeedInGhz",
                      "type": "double"
                    }
                  }
                },
                "VCpuInfo": {
                  "locationName": "vCpuInfo",
                  "type": "structure",
                  "members": {
                    "DefaultVCpus": {
                      "locationName": "defaultVCpus",
                      "type": "integer"
                    },
                    "DefaultCores": {
                      "locationName": "defaultCores",
                      "type": "integer"
                    },
                    "DefaultThreadsPerCore": {
                      "locationName": "defaultThreadsPerCore",
                      "type": "integer"
                    },
                    "ValidCores": {
                      "locationName": "validCores",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "integer"
                      }
                    },
                    "ValidThreadsPerCore": {
                      "locationName": "validThreadsPerCore",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "integer"
                      }
                    }
                  }
                },
                "MemoryInfo": {
                  "locationName": "memoryInfo",
                  "type": "structure",
                  "members": {
                    "SizeInMiB": {
                      "locationName": "sizeInMiB",
                      "type": "long"
                    }
                  }
                },
                "InstanceStorageSupported": {
                  "locationName": "instanceStorageSupported",
                  "type": "boolean"
                },
                "InstanceStorageInfo": {
                  "locationName": "instanceStorageInfo",
                  "type": "structure",
                  "members": {
                    "TotalSizeInGB": {
                      "locationName": "totalSizeInGB",
                      "type": "long"
                    },
                    "Disks": {
                      "locationName": "disks",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "SizeInGB": {
                            "locationName": "sizeInGB",
                            "type": "long"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Type": {
                            "locationName": "type"
                          }
                        }
                      }
                    }
                  }
                },
                "EbsInfo": {
                  "locationName": "ebsInfo",
                  "type": "structure",
                  "members": {
                    "EbsOptimizedSupport": {
                      "locationName": "ebsOptimizedSupport"
                    },
                    "EncryptionSupport": {
                      "locationName": "encryptionSupport"
                    },
                    "EbsOptimizedInfo": {
                      "locationName": "ebsOptimizedInfo",
                      "type": "structure",
                      "members": {
                        "BaselineBandwidthInMbps": {
                          "locationName": "baselineBandwidthInMbps",
                          "type": "integer"
                        },
                        "BaselineThroughputInMBps": {
                          "locationName": "baselineThroughputInMBps",
                          "type": "double"
                        },
                        "BaselineIops": {
                          "locationName": "baselineIops",
                          "type": "integer"
                        },
                        "MaximumBandwidthInMbps": {
                          "locationName": "maximumBandwidthInMbps",
                          "type": "integer"
                        },
                        "MaximumThroughputInMBps": {
                          "locationName": "maximumThroughputInMBps",
                          "type": "double"
                        },
                        "MaximumIops": {
                          "locationName": "maximumIops",
                          "type": "integer"
                        }
                      }
                    },
                    "NvmeSupport": {
                      "locationName": "nvmeSupport"
                    }
                  }
                },
                "NetworkInfo": {
                  "locationName": "networkInfo",
                  "type": "structure",
                  "members": {
                    "NetworkPerformance": {
                      "locationName": "networkPerformance"
                    },
                    "MaximumNetworkInterfaces": {
                      "locationName": "maximumNetworkInterfaces",
                      "type": "integer"
                    },
                    "Ipv4AddressesPerInterface": {
                      "locationName": "ipv4AddressesPerInterface",
                      "type": "integer"
                    },
                    "Ipv6AddressesPerInterface": {
                      "locationName": "ipv6AddressesPerInterface",
                      "type": "integer"
                    },
                    "Ipv6Supported": {
                      "locationName": "ipv6Supported",
                      "type": "boolean"
                    },
                    "EnaSupport": {
                      "locationName": "enaSupport"
                    },
                    "EfaSupported": {
                      "locationName": "efaSupported",
                      "type": "boolean"
                    }
                  }
                },
                "GpuInfo": {
                  "locationName": "gpuInfo",
                  "type": "structure",
                  "members": {
                    "Gpus": {
                      "locationName": "gpus",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalGpuMemoryInMiB": {
                      "locationName": "totalGpuMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "FpgaInfo": {
                  "locationName": "fpgaInfo",
                  "type": "structure",
                  "members": {
                    "Fpgas": {
                      "locationName": "fpgas",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          },
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "MemoryInfo": {
                            "locationName": "memoryInfo",
                            "type": "structure",
                            "members": {
                              "SizeInMiB": {
                                "locationName": "sizeInMiB",
                                "type": "integer"
                              }
                            }
                          }
                        }
                      }
                    },
                    "TotalFpgaMemoryInMiB": {
                      "locationName": "totalFpgaMemoryInMiB",
                      "type": "integer"
                    }
                  }
                },
                "PlacementGroupInfo": {
                  "locationName": "placementGroupInfo",
                  "type": "structure",
                  "members": {
                    "SupportedStrategies": {
                      "locationName": "supportedStrategies",
                      "type": "list",
                      "member": {
                        "locationName": "item"
                      }
                    }
                  }
                },
                "InferenceAcceleratorInfo": {
                  "locationName": "inferenceAcceleratorInfo",
                  "type": "structure",
                  "members": {
                    "Accelerators": {
                      "locationName": "item",
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Count": {
                            "locationName": "count",
                            "type": "integer"
                          },
                          "Name": {
                            "locationName": "name"
                          },
                          "Manufacturer": {
                            "locationName": "manufacturer"
                          }
                        }
                      }
                    }
                  }
                },
                "HibernationSupported": {
                  "locationName": "hibernationSupported",
                  "type": "boolean"
                },
                "BurstablePerformanceSupported": {
                  "locationName": "burstablePerformanceSupported",
                  "type": "boolean"
                },
                "DedicatedHostsSupported": {
                  "locationName": "dedicatedHostsSupported",
                  "type": "boolean"
                },
                "AutoRecoverySupported": {
                  "locationName": "autoRecoverySupported",
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Reservations": {
            "locationName": "reservationSet",
            "type": "list",
            "member": {
              "shape": "Sww",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayIds": {
            "locationName": "internetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateways": {
            "locationName": "internetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S9g",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIpv6Pools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6Pools": {
            "locationName": "ipv6PoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "Description": {
                  "locationName": "description"
                },
                "PoolCidrBlocks": {
                  "locationName": "poolCidrBlockSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Cidr": {
                        "locationName": "poolCidrBlock"
                      }
                    }
                  }
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeKeyPairs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "KeyNames": {
            "locationName": "KeyName",
            "type": "list",
            "member": {
              "locationName": "KeyName"
            }
          },
          "KeyPairIds": {
            "locationName": "KeyPairId",
            "type": "list",
            "member": {
              "locationName": "KeyPairId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPairs": {
            "locationName": "keySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "KeyPairId": {
                  "locationName": "keyPairId"
                },
                "KeyFingerprint": {
                  "locationName": "keyFingerprint"
                },
                "KeyName": {
                  "locationName": "keyName"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Sjd",
            "locationName": "LaunchTemplateVersion"
          },
          "MinVersion": {},
          "MaxVersion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersions": {
            "locationName": "launchTemplateVersionSet",
            "type": "list",
            "member": {
              "shape": "Sb3",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLaunchTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateIds": {
            "locationName": "LaunchTemplateId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "LaunchTemplateNames": {
            "locationName": "LaunchTemplateName",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplates": {
            "locationName": "launchTemplates",
            "type": "list",
            "member": {
              "shape": "Sax",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociationIds": {
            "locationName": "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
            "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayRouteTableVirtualInterfaceGroupAssociationId": {
                  "locationName": "localGatewayRouteTableVirtualInterfaceGroupAssociationId"
                },
                "LocalGatewayVirtualInterfaceGroupId": {
                  "locationName": "localGatewayVirtualInterfaceGroupId"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "LocalGatewayRouteTableId": {
                  "locationName": "localGatewayRouteTableId"
                },
                "State": {
                  "locationName": "state"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTableVpcAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociationIds": {
            "locationName": "LocalGatewayRouteTableVpcAssociationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableVpcAssociations": {
            "locationName": "localGatewayRouteTableVpcAssociationSet",
            "type": "list",
            "member": {
              "shape": "Sc5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTableIds": {
            "locationName": "LocalGatewayRouteTableId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayRouteTables": {
            "locationName": "localGatewayRouteTableSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayRouteTableId": {
                  "locationName": "localGatewayRouteTableId"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "State": {
                  "locationName": "state"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayVirtualInterfaceGroups": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceGroupIds": {
            "locationName": "LocalGatewayVirtualInterfaceGroupId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceGroups": {
            "locationName": "localGatewayVirtualInterfaceGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayVirtualInterfaceGroupId": {
                  "locationName": "localGatewayVirtualInterfaceGroupId"
                },
                "LocalGatewayVirtualInterfaceIds": {
                  "shape": "Syy",
                  "locationName": "localGatewayVirtualInterfaceIdSet"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGatewayVirtualInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaceIds": {
            "shape": "Syy",
            "locationName": "LocalGatewayVirtualInterfaceId"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGatewayVirtualInterfaces": {
            "locationName": "localGatewayVirtualInterfaceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayVirtualInterfaceId": {
                  "locationName": "localGatewayVirtualInterfaceId"
                },
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "Vlan": {
                  "locationName": "vlan",
                  "type": "integer"
                },
                "LocalAddress": {
                  "locationName": "localAddress"
                },
                "PeerAddress": {
                  "locationName": "peerAddress"
                },
                "LocalBgpAsn": {
                  "locationName": "localBgpAsn",
                  "type": "integer"
                },
                "PeerBgpAsn": {
                  "locationName": "peerBgpAsn",
                  "type": "integer"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLocalGateways": {
      "input": {
        "type": "structure",
        "members": {
          "LocalGatewayIds": {
            "locationName": "LocalGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocalGateways": {
            "locationName": "localGatewaySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LocalGatewayId": {
                  "locationName": "localGatewayId"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "State": {
                  "locationName": "state"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeMovingAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "PublicIps": {
            "shape": "So",
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MovingAddressStatuses": {
            "locationName": "movingAddressStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "MoveStatus": {
                  "locationName": "moveStatus"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNatGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filter": {
            "shape": "Sm2"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NatGatewayIds": {
            "locationName": "NatGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGateways": {
            "locationName": "natGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sc9",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkAcls": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclIds": {
            "locationName": "NetworkAclId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcls": {
            "locationName": "networkAclSet",
            "type": "list",
            "member": {
              "shape": "Scg",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attachment": {
            "shape": "Scv",
            "locationName": "attachment"
          },
          "Description": {
            "shape": "S7j",
            "locationName": "description"
          },
          "Groups": {
            "shape": "Scw",
            "locationName": "groupSet"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "Stm",
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "DescribeNetworkInterfacePermissions": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissionIds": {
            "locationName": "NetworkInterfacePermissionId",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissions": {
            "locationName": "networkInterfacePermissions",
            "type": "list",
            "member": {
              "shape": "Sd7",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceIds": {
            "locationName": "NetworkInterfaceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaces": {
            "locationName": "networkInterfaceSet",
            "type": "list",
            "member": {
              "shape": "Sct",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePlacementGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupNames": {
            "locationName": "groupName",
            "type": "list",
            "member": {}
          },
          "GroupIds": {
            "locationName": "GroupId",
            "type": "list",
            "member": {
              "locationName": "GroupId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroups": {
            "locationName": "placementGroupSet",
            "type": "list",
            "member": {
              "shape": "Sdd",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribePrefixLists": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PrefixListIds": {
            "locationName": "PrefixListId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PrefixLists": {
            "locationName": "prefixListSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Cidrs": {
                  "shape": "So",
                  "locationName": "cidrSet"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                },
                "PrefixListName": {
                  "locationName": "prefixListName"
                }
              }
            }
          }
        }
      }
    },
    "DescribePrincipalIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Resources": {
            "locationName": "Resource",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "locationName": "principalSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Statuses": {
                  "shape": "Smb",
                  "locationName": "statusSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePublicIpv4Pools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "locationName": "PoolId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIpv4Pools": {
            "locationName": "publicIpv4PoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "Description": {
                  "locationName": "description"
                },
                "PoolAddressRanges": {
                  "locationName": "poolAddressRangeSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "FirstAddress": {
                        "locationName": "firstAddress"
                      },
                      "LastAddress": {
                        "locationName": "lastAddress"
                      },
                      "AddressCount": {
                        "locationName": "addressCount",
                        "type": "integer"
                      },
                      "AvailableAddressCount": {
                        "locationName": "availableAddressCount",
                        "type": "integer"
                      }
                    }
                  }
                },
                "TotalAddressCount": {
                  "locationName": "totalAddressCount",
                  "type": "integer"
                },
                "TotalAvailableAddressCount": {
                  "locationName": "totalAvailableAddressCount",
                  "type": "integer"
                },
                "NetworkBorderGroup": {
                  "locationName": "networkBorderGroup"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRegions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "RegionNames": {
            "locationName": "RegionName",
            "type": "list",
            "member": {
              "locationName": "RegionName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AllRegions": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Regions": {
            "locationName": "regionInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Endpoint": {
                  "locationName": "regionEndpoint"
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "OptInStatus": {
                  "locationName": "optInStatus"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "OfferingClass": {},
          "ReservedInstancesIds": {
            "shape": "S110",
            "locationName": "ReservedInstancesId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstances": {
            "locationName": "reservedInstancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "RecurringCharges": {
                  "shape": "S118",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesListings": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S53",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "DescribeReservedInstancesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "ReservedInstancesModificationIds": {
            "locationName": "ReservedInstancesModificationId",
            "type": "list",
            "member": {
              "locationName": "ReservedInstancesModificationId"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ReservedInstancesModifications": {
            "locationName": "reservedInstancesModificationsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "CreateDate": {
                  "locationName": "createDate",
                  "type": "timestamp"
                },
                "EffectiveDate": {
                  "locationName": "effectiveDate",
                  "type": "timestamp"
                },
                "ModificationResults": {
                  "locationName": "modificationResultSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      },
                      "TargetConfiguration": {
                        "shape": "S11m",
                        "locationName": "targetConfiguration"
                      }
                    }
                  }
                },
                "ReservedInstancesIds": {
                  "locationName": "reservedInstancesSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      }
                    }
                  }
                },
                "ReservedInstancesModificationId": {
                  "locationName": "reservedInstancesModificationId"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "UpdateDate": {
                  "locationName": "updateDate",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZone": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "IncludeMarketplace": {
            "type": "boolean"
          },
          "InstanceType": {},
          "MaxDuration": {
            "type": "long"
          },
          "MaxInstanceCount": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "long"
          },
          "OfferingClass": {},
          "ProductDescription": {},
          "ReservedInstancesOfferingIds": {
            "locationName": "ReservedInstancesOfferingId",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesOfferings": {
            "locationName": "reservedInstancesOfferingsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesOfferingId": {
                  "locationName": "reservedInstancesOfferingId"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "Marketplace": {
                  "locationName": "marketplace",
                  "type": "boolean"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "PricingDetails": {
                  "locationName": "pricingDetailsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Count": {
                        "locationName": "count",
                        "type": "integer"
                      },
                      "Price": {
                        "locationName": "price",
                        "type": "double"
                      }
                    }
                  }
                },
                "RecurringCharges": {
                  "shape": "S118",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableIds": {
            "locationName": "RouteTableId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTables": {
            "locationName": "routeTableSet",
            "type": "list",
            "member": {
              "shape": "Sdq",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeScheduledInstanceAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "FirstSlotStartTimeRange",
          "Recurrence"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "FirstSlotStartTimeRange": {
            "type": "structure",
            "required": [
              "EarliestTime",
              "LatestTime"
            ],
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "MaxSlotDurationInHours": {
            "type": "integer"
          },
          "MinSlotDurationInHours": {
            "type": "integer"
          },
          "NextToken": {},
          "Recurrence": {
            "type": "structure",
            "members": {
              "Frequency": {},
              "Interval": {
                "type": "integer"
              },
              "OccurrenceDays": {
                "locationName": "OccurrenceDay",
                "type": "list",
                "member": {
                  "locationName": "OccurenceDay",
                  "type": "integer"
                }
              },
              "OccurrenceRelativeToEnd": {
                "type": "boolean"
              },
              "OccurrenceUnit": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceAvailabilitySet": {
            "locationName": "scheduledInstanceAvailabilitySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableInstanceCount": {
                  "locationName": "availableInstanceCount",
                  "type": "integer"
                },
                "FirstSlotStartTime": {
                  "locationName": "firstSlotStartTime",
                  "type": "timestamp"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "MaxTermDurationInDays": {
                  "locationName": "maxTermDurationInDays",
                  "type": "integer"
                },
                "MinTermDurationInDays": {
                  "locationName": "minTermDurationInDays",
                  "type": "integer"
                },
                "NetworkPlatform": {
                  "locationName": "networkPlatform"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "PurchaseToken": {
                  "locationName": "purchaseToken"
                },
                "Recurrence": {
                  "shape": "S129",
                  "locationName": "recurrence"
                },
                "SlotDurationInHours": {
                  "locationName": "slotDurationInHours",
                  "type": "integer"
                },
                "TotalScheduledInstanceHours": {
                  "locationName": "totalScheduledInstanceHours",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeScheduledInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ScheduledInstanceIds": {
            "locationName": "ScheduledInstanceId",
            "type": "list",
            "member": {
              "locationName": "ScheduledInstanceId"
            }
          },
          "SlotStartTimeRange": {
            "type": "structure",
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "S12h",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeSecurityGroupReferences": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupReferenceSet": {
            "locationName": "securityGroupReferenceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupId": {
                  "locationName": "groupId"
                },
                "ReferencingVpcId": {
                  "locationName": "referencingVpcId"
                },
                "VpcPeeringConnectionId": {
                  "locationName": "vpcPeeringConnectionId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "GroupIds": {
            "shape": "S3j",
            "locationName": "GroupId"
          },
          "GroupNames": {
            "shape": "S12o",
            "locationName": "GroupName"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroups": {
            "locationName": "securityGroupInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "groupDescription"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "IpPermissions": {
                  "shape": "S43",
                  "locationName": "ipPermissions"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "IpPermissionsEgress": {
                  "shape": "S43",
                  "locationName": "ipPermissionsEgress"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateVolumePermissions": {
            "shape": "S12w",
            "locationName": "createVolumePermission"
          },
          "ProductCodes": {
            "shape": "Sr0",
            "locationName": "productCodes"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "OwnerIds": {
            "shape": "Sr5",
            "locationName": "Owner"
          },
          "RestorableByUserIds": {
            "locationName": "RestorableBy",
            "type": "list",
            "member": {}
          },
          "SnapshotIds": {
            "shape": "S130",
            "locationName": "SnapshotId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "shape": "Se2",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "Sed",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "DescribeSpotFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "Sq6",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "DescribeSpotFleetRequestHistory": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EventType": {
            "locationName": "eventType"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "Sq3",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSpotFleetRequests": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestIds": {
            "shape": "S5f",
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestConfigs": {
            "locationName": "spotFleetRequestConfigSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "SpotFleetRequestConfig": {
                  "shape": "S13j",
                  "locationName": "spotFleetRequestConfig"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "SpotFleetRequestState": {
                  "locationName": "spotFleetRequestState"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S5q",
            "locationName": "SpotInstanceRequestId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "S148",
            "locationName": "spotInstanceRequestSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotPriceHistory": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "ProductDescriptions": {
            "locationName": "ProductDescription",
            "type": "list",
            "member": {}
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotPriceHistory": {
            "locationName": "spotPriceHistorySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "SpotPrice": {
                  "locationName": "spotPrice"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStaleSecurityGroups": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "StaleSecurityGroupSet": {
            "locationName": "staleSecurityGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "StaleIpPermissions": {
                  "shape": "S14q",
                  "locationName": "staleIpPermissions"
                },
                "StaleIpPermissionsEgress": {
                  "shape": "S14q",
                  "locationName": "staleIpPermissionsEgress"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSubnets": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "SubnetIds": {
            "locationName": "SubnetId",
            "type": "list",
            "member": {
              "locationName": "SubnetId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnets": {
            "locationName": "subnetSet",
            "type": "list",
            "member": {
              "shape": "S70",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Tags": {
            "locationName": "tagSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "Value": {
                  "locationName": "value"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTrafficMirrorFilters": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterIds": {
            "locationName": "TrafficMirrorFilterId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilters": {
            "locationName": "trafficMirrorFilterSet",
            "type": "list",
            "member": {
              "shape": "Sen",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorSessions": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionIds": {
            "locationName": "TrafficMirrorSessionId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessions": {
            "locationName": "trafficMirrorSessionSet",
            "type": "list",
            "member": {
              "shape": "Sf2",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorTargets": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetIds": {
            "locationName": "TrafficMirrorTargetId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargets": {
            "locationName": "trafficMirrorTargetSet",
            "type": "list",
            "member": {
              "shape": "Sf5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S15i"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachments": {
            "locationName": "transitGatewayAttachments",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "TransitGatewayId": {
                  "locationName": "transitGatewayId"
                },
                "TransitGatewayOwnerId": {
                  "locationName": "transitGatewayOwnerId"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "State": {
                  "locationName": "state"
                },
                "Association": {
                  "locationName": "association",
                  "type": "structure",
                  "members": {
                    "TransitGatewayRouteTableId": {
                      "locationName": "transitGatewayRouteTableId"
                    },
                    "State": {
                      "locationName": "state"
                    }
                  }
                },
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayMulticastDomains": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomains": {
            "locationName": "transitGatewayMulticastDomains",
            "type": "list",
            "member": {
              "shape": "Sfk",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayPeeringAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S15i"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachments": {
            "locationName": "transitGatewayPeeringAttachments",
            "type": "list",
            "member": {
              "shape": "Se",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTables": {
            "locationName": "transitGatewayRouteTables",
            "type": "list",
            "member": {
              "shape": "Sfy",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayVpcAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "S15i"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachments": {
            "locationName": "transitGatewayVpcAttachments",
            "type": "list",
            "member": {
              "shape": "Sn",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGateways": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateways": {
            "locationName": "transitGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sff",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VolumeId"
        ],
        "members": {
          "Attribute": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoEnableIO": {
            "shape": "Stm",
            "locationName": "autoEnableIO"
          },
          "ProductCodes": {
            "shape": "Sr0",
            "locationName": "productCodes"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "DescribeVolumeStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VolumeIds": {
            "shape": "S16a",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "VolumeStatuses": {
            "locationName": "volumeStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Actions": {
                  "locationName": "actionsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Code": {
                        "locationName": "code"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      }
                    }
                  }
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "OutpostArn": {
                  "locationName": "outpostArn"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      },
                      "NotAfter": {
                        "locationName": "notAfter",
                        "type": "timestamp"
                      },
                      "NotBefore": {
                        "locationName": "notBefore",
                        "type": "timestamp"
                      },
                      "InstanceId": {
                        "locationName": "instanceId"
                      }
                    }
                  }
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "VolumeStatus": {
                  "locationName": "volumeStatus",
                  "type": "structure",
                  "members": {
                    "Details": {
                      "locationName": "details",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Status": {
                            "locationName": "status"
                          }
                        }
                      }
                    },
                    "Status": {
                      "locationName": "status"
                    }
                  }
                },
                "AttachmentStatuses": {
                  "locationName": "attachmentStatuses",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "IoPerformance": {
                        "locationName": "ioPerformance"
                      },
                      "InstanceId": {
                        "locationName": "instanceId"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "VolumeIds": {
            "shape": "S16a",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "locationName": "volumeSet",
            "type": "list",
            "member": {
              "shape": "Sg5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeIds": {
            "shape": "S16a",
            "locationName": "VolumeId"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumesModifications": {
            "locationName": "volumeModificationSet",
            "type": "list",
            "member": {
              "shape": "S16v",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VpcId"
        ],
        "members": {
          "Attribute": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableDnsHostnames": {
            "shape": "Stm",
            "locationName": "enableDnsHostnames"
          },
          "EnableDnsSupport": {
            "shape": "Stm",
            "locationName": "enableDnsSupport"
          }
        }
      }
    },
    "DescribeVpcClassicLink": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcIds": {
            "shape": "S171",
            "locationName": "VpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkEnabled": {
                  "locationName": "classicLinkEnabled",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "VpcIds": {
            "shape": "S171"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Vpcs": {
            "locationName": "vpcs",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkDnsSupported": {
                  "locationName": "classicLinkDnsSupported",
                  "type": "boolean"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotificationSet": {
            "locationName": "connectionNotificationSet",
            "type": "list",
            "member": {
              "shape": "Sgp",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpointConnections": {
            "locationName": "vpcEndpointConnectionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "VpcEndpointId": {
                  "locationName": "vpcEndpointId"
                },
                "VpcEndpointOwner": {
                  "locationName": "vpcEndpointOwner"
                },
                "VpcEndpointState": {
                  "locationName": "vpcEndpointState"
                },
                "CreationTimestamp": {
                  "locationName": "creationTimestamp",
                  "type": "timestamp"
                },
                "DnsEntries": {
                  "shape": "Sgk",
                  "locationName": "dnsEntrySet"
                },
                "NetworkLoadBalancerArns": {
                  "shape": "So",
                  "locationName": "networkLoadBalancerArnSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sl5",
            "locationName": "ServiceId"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfigurations": {
            "locationName": "serviceConfigurationSet",
            "type": "list",
            "member": {
              "shape": "Sgu",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllowedPrincipals": {
            "locationName": "allowedPrincipals",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrincipalType": {
                  "locationName": "principalType"
                },
                "Principal": {
                  "locationName": "principal"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServices": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceNames": {
            "shape": "So",
            "locationName": "ServiceName"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceNames": {
            "shape": "So",
            "locationName": "serviceNameSet"
          },
          "ServiceDetails": {
            "locationName": "serviceDetailSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceName": {
                  "locationName": "serviceName"
                },
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "ServiceType": {
                  "shape": "Sgv",
                  "locationName": "serviceType"
                },
                "AvailabilityZones": {
                  "shape": "So",
                  "locationName": "availabilityZoneSet"
                },
                "Owner": {
                  "locationName": "owner"
                },
                "BaseEndpointDnsNames": {
                  "shape": "So",
                  "locationName": "baseEndpointDnsNameSet"
                },
                "PrivateDnsName": {
                  "locationName": "privateDnsName"
                },
                "VpcEndpointPolicySupported": {
                  "locationName": "vpcEndpointPolicySupported",
                  "type": "boolean"
                },
                "AcceptanceRequired": {
                  "locationName": "acceptanceRequired",
                  "type": "boolean"
                },
                "ManagesVpcEndpoints": {
                  "locationName": "managesVpcEndpoints",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "PrivateDnsNameVerificationState": {
                  "locationName": "privateDnsNameVerificationState"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "Su",
            "locationName": "VpcEndpointId"
          },
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoints": {
            "locationName": "vpcEndpointSet",
            "type": "list",
            "member": {
              "shape": "Sgg",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionIds": {
            "locationName": "VpcPeeringConnectionId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "locationName": "vpcPeeringConnectionSet",
            "type": "list",
            "member": {
              "shape": "S13",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "VpcIds": {
            "locationName": "VpcId",
            "type": "list",
            "member": {
              "locationName": "VpcId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "shape": "S76",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpnConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "VpnConnectionIds": {
            "locationName": "VpnConnectionId",
            "type": "list",
            "member": {
              "locationName": "VpnConnectionId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnections": {
            "locationName": "vpnConnectionSet",
            "type": "list",
            "member": {
              "shape": "Shn",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpnGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "VpnGatewayIds": {
            "locationName": "VpnGatewayId",
            "type": "list",
            "member": {
              "locationName": "VpnGatewayId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateways": {
            "locationName": "vpnGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sig",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DetachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DetachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "DetachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "AttachmentId"
        ],
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "Force": {
            "type": "boolean"
          },
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S3r"
      }
    },
    "DetachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "DisableFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZones",
          "SourceSnapshotIds"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S18q",
            "locationName": "AvailabilityZone"
          },
          "SourceSnapshotIds": {
            "shape": "S130",
            "locationName": "SourceSnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "locationName": "successful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "Unsuccessful": {
            "locationName": "unsuccessful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "FastSnapshotRestoreStateErrors": {
                  "locationName": "fastSnapshotRestoreStateErrorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AvailabilityZone": {
                        "locationName": "availabilityZone"
                      },
                      "Error": {
                        "locationName": "error",
                        "type": "structure",
                        "members": {
                          "Code": {
                            "locationName": "code"
                          },
                          "Message": {
                            "locationName": "message"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DisableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "S191",
            "locationName": "propagation"
          }
        }
      }
    },
    "DisableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "PublicIp": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "AssociationId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S2d",
            "locationName": "status"
          }
        }
      }
    },
    "DisassociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S2k",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "DisassociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S2v",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "DisassociateTransitGatewayMulticastDomain": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "TransitGatewayAttachmentId": {},
          "SubnetIds": {
            "shape": "So"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "shape": "S31",
            "locationName": "associations"
          }
        }
      }
    },
    "DisassociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S39",
            "locationName": "association"
          }
        }
      }
    },
    "DisassociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S3e",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S3h",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "EnableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "EnableFastSnapshotRestores": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZones",
          "SourceSnapshotIds"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S18q",
            "locationName": "AvailabilityZone"
          },
          "SourceSnapshotIds": {
            "shape": "S130",
            "locationName": "SourceSnapshotId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "locationName": "successful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "State": {
                  "locationName": "state"
                },
                "StateTransitionReason": {
                  "locationName": "stateTransitionReason"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "EnablingTime": {
                  "locationName": "enablingTime",
                  "type": "timestamp"
                },
                "OptimizingTime": {
                  "locationName": "optimizingTime",
                  "type": "timestamp"
                },
                "EnabledTime": {
                  "locationName": "enabledTime",
                  "type": "timestamp"
                },
                "DisablingTime": {
                  "locationName": "disablingTime",
                  "type": "timestamp"
                },
                "DisabledTime": {
                  "locationName": "disabledTime",
                  "type": "timestamp"
                }
              }
            }
          },
          "Unsuccessful": {
            "locationName": "unsuccessful",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SnapshotId": {
                  "locationName": "snapshotId"
                },
                "FastSnapshotRestoreStateErrors": {
                  "locationName": "fastSnapshotRestoreStateErrorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AvailabilityZone": {
                        "locationName": "availabilityZone"
                      },
                      "Error": {
                        "locationName": "error",
                        "type": "structure",
                        "members": {
                          "Code": {
                            "locationName": "code"
                          },
                          "Message": {
                            "locationName": "message"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "EnableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "S191",
            "locationName": "propagation"
          }
        }
      }
    },
    "EnableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "EnableVolumeIO": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "EnableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ExportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateRevocationList": {
            "locationName": "certificateRevocationList"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              }
            }
          }
        }
      }
    },
    "ExportClientVpnClientConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientConfiguration": {
            "locationName": "clientConfiguration"
          }
        }
      }
    },
    "ExportImage": {
      "input": {
        "type": "structure",
        "required": [
          "DiskImageFormat",
          "ImageId",
          "S3ExportLocation"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "DiskImageFormat": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImageId": {},
          "S3ExportLocation": {
            "type": "structure",
            "required": [
              "S3Bucket"
            ],
            "members": {
              "S3Bucket": {},
              "S3Prefix": {}
            }
          },
          "RoleName": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImageFormat": {
            "locationName": "diskImageFormat"
          },
          "ExportImageTaskId": {
            "locationName": "exportImageTaskId"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "RoleName": {
            "locationName": "roleName"
          },
          "Progress": {
            "locationName": "progress"
          },
          "S3ExportLocation": {
            "shape": "Spm",
            "locationName": "s3ExportLocation"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ExportTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "S3Bucket"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "S3Bucket": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "S3Location": {
            "locationName": "s3Location"
          }
        }
      }
    },
    "GetAssociatedIpv6PoolCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId"
        ],
        "members": {
          "PoolId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrAssociations": {
            "locationName": "ipv6CidrAssociationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Ipv6Cidr": {
                  "locationName": "ipv6Cidr"
                },
                "AssociatedResource": {
                  "locationName": "associatedResource"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetCapacityReservationUsage": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservationId": {
            "locationName": "capacityReservationId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "TotalInstanceCount": {
            "locationName": "totalInstanceCount",
            "type": "integer"
          },
          "AvailableInstanceCount": {
            "locationName": "availableInstanceCount",
            "type": "integer"
          },
          "State": {
            "locationName": "state"
          },
          "InstanceUsages": {
            "locationName": "instanceUsageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AccountId": {
                  "locationName": "accountId"
                },
                "UsedInstanceCount": {
                  "locationName": "usedInstanceCount",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "GetCoipPoolUsage": {
      "input": {
        "type": "structure",
        "required": [
          "PoolId"
        ],
        "members": {
          "PoolId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CoipPoolId": {
            "locationName": "coipPoolId"
          },
          "CoipAddressUsages": {
            "locationName": "coipAddressUsageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AwsAccountId": {
                  "locationName": "awsAccountId"
                },
                "AwsService": {
                  "locationName": "awsService"
                },
                "CoIp": {
                  "locationName": "coIp"
                }
              }
            }
          },
          "LocalGatewayRouteTableId": {
            "locationName": "localGatewayRouteTableId"
          }
        }
      }
    },
    "GetConsoleOutput": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Latest": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Output": {
            "locationName": "output"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetConsoleScreenshot": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "WakeUp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageData": {
            "locationName": "imageData"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "GetDefaultCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceFamily"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceFamily": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFamilyCreditSpecification": {
            "shape": "S1b5",
            "locationName": "instanceFamilyCreditSpecification"
          }
        }
      }
    },
    "GetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "GetEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "GetHostReservationPurchasePreview": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "HostIdSet": {
            "shape": "S1bb"
          },
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S1bd",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "GetLaunchTemplateData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateData": {
            "shape": "Sb4",
            "locationName": "launchTemplateData"
          }
        }
      }
    },
    "GetPasswordData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PasswordData": {
            "locationName": "passwordData"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "IsValidExchange": {
            "locationName": "isValidExchange",
            "type": "boolean"
          },
          "OutputReservedInstancesWillExpireAt": {
            "locationName": "outputReservedInstancesWillExpireAt",
            "type": "timestamp"
          },
          "PaymentDue": {
            "locationName": "paymentDue"
          },
          "ReservedInstanceValueRollup": {
            "shape": "S1bl",
            "locationName": "reservedInstanceValueRollup"
          },
          "ReservedInstanceValueSet": {
            "locationName": "reservedInstanceValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S1bl",
                  "locationName": "reservationValue"
                },
                "ReservedInstanceId": {
                  "locationName": "reservedInstanceId"
                }
              }
            }
          },
          "TargetConfigurationValueRollup": {
            "shape": "S1bl",
            "locationName": "targetConfigurationValueRollup"
          },
          "TargetConfigurationValueSet": {
            "locationName": "targetConfigurationValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S1bl",
                  "locationName": "reservationValue"
                },
                "TargetConfiguration": {
                  "locationName": "targetConfiguration",
                  "type": "structure",
                  "members": {
                    "InstanceCount": {
                      "locationName": "instanceCount",
                      "type": "integer"
                    },
                    "OfferingId": {
                      "locationName": "offeringId"
                    }
                  }
                }
              }
            }
          },
          "ValidationFailureReason": {
            "locationName": "validationFailureReason"
          }
        }
      }
    },
    "GetTransitGatewayAttachmentPropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentPropagations": {
            "locationName": "transitGatewayAttachmentPropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayRouteTableId": {
                  "locationName": "transitGatewayRouteTableId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayMulticastDomainAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MulticastDomainAssociations": {
            "locationName": "multicastDomainAssociations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "Subnet": {
                  "shape": "S34",
                  "locationName": "subnet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTableAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "locationName": "associations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTablePropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTablePropagations": {
            "locationName": "transitGatewayRouteTablePropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ImportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "CertificateRevocationList"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "CertificateRevocationList": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ImportImage": {
      "input": {
        "type": "structure",
        "members": {
          "Architecture": {},
          "ClientData": {
            "shape": "S1ca"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainers": {
            "locationName": "DiskContainer",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {},
                "DeviceName": {},
                "Format": {},
                "SnapshotId": {},
                "Url": {},
                "UserBucket": {
                  "shape": "S1cd"
                }
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "Hypervisor": {},
          "KmsKeyId": {},
          "LicenseType": {},
          "Platform": {},
          "RoleName": {},
          "LicenseSpecifications": {
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LicenseConfigurationArn": {}
              }
            }
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Architecture": {
            "locationName": "architecture"
          },
          "Description": {
            "locationName": "description"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Hypervisor": {
            "locationName": "hypervisor"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "LicenseType": {
            "locationName": "licenseType"
          },
          "Platform": {
            "locationName": "platform"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotDetails": {
            "shape": "St4",
            "locationName": "snapshotDetailSet"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "LicenseSpecifications": {
            "shape": "St7",
            "locationName": "licenseSpecifications"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ImportInstance": {
      "input": {
        "type": "structure",
        "required": [
          "Platform"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImages": {
            "locationName": "diskImage",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Image": {
                  "shape": "S1ck"
                },
                "Volume": {
                  "shape": "S1cl"
                }
              }
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "AdditionalInfo": {
                "locationName": "additionalInfo"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "GroupIds": {
                "shape": "S9v",
                "locationName": "GroupId"
              },
              "GroupNames": {
                "shape": "Saf",
                "locationName": "GroupName"
              },
              "InstanceInitiatedShutdownBehavior": {
                "locationName": "instanceInitiatedShutdownBehavior"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "boolean"
              },
              "Placement": {
                "shape": "S87",
                "locationName": "placement"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData",
                "type": "structure",
                "members": {
                  "Data": {
                    "locationName": "data"
                  }
                },
                "sensitive": true
              }
            }
          },
          "Platform": {
            "locationName": "platform"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "Soj",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ImportKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName",
          "PublicKeyMaterial"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "PublicKeyMaterial": {
            "locationName": "publicKeyMaterial",
            "type": "blob"
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyPairId": {
            "locationName": "keyPairId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ImportSnapshot": {
      "input": {
        "type": "structure",
        "members": {
          "ClientData": {
            "shape": "S1ca"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainer": {
            "type": "structure",
            "members": {
              "Description": {},
              "Format": {},
              "Url": {},
              "UserBucket": {
                "shape": "S1cd"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "RoleName": {},
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "SnapshotTaskDetail": {
            "shape": "Stf",
            "locationName": "snapshotTaskDetail"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          }
        }
      }
    },
    "ImportVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "Image",
          "Volume"
        ],
        "members": {
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Image": {
            "shape": "S1ck",
            "locationName": "image"
          },
          "Volume": {
            "shape": "S1cl",
            "locationName": "volume"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "Soj",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ModifyAvailabilityZoneGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "OptInStatus"
        ],
        "members": {
          "GroupName": {},
          "OptInStatus": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ServerCertificateArn": {},
          "ConnectionLogOptions": {
            "shape": "S6l"
          },
          "DnsServers": {
            "type": "structure",
            "members": {
              "CustomDnsServers": {
                "shape": "So"
              },
              "Enabled": {
                "type": "boolean"
              }
            }
          },
          "VpnPort": {
            "type": "integer"
          },
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "SecurityGroupIds": {
            "shape": "S1u",
            "locationName": "SecurityGroupId"
          },
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyDefaultCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceFamily",
          "CpuCredits"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceFamily": {},
          "CpuCredits": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFamilyCreditSpecification": {
            "shape": "S1b5",
            "locationName": "instanceFamilyCreditSpecification"
          }
        }
      }
    },
    "ModifyEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "required": [
          "KmsKeyId"
        ],
        "members": {
          "KmsKeyId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ModifyFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "TargetCapacitySpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ExcessCapacityTerminationPolicy": {},
          "FleetId": {},
          "TargetCapacitySpecification": {
            "shape": "S88"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {},
          "OperationType": {},
          "UserIds": {
            "shape": "S1dc",
            "locationName": "UserId"
          },
          "UserGroups": {
            "shape": "S1dd",
            "locationName": "UserGroup"
          },
          "ProductCodes": {
            "shape": "S1de",
            "locationName": "ProductCode"
          },
          "LoadPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S1dg"
              },
              "Remove": {
                "shape": "S1dg"
              }
            }
          },
          "Description": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "Sqw",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "ModifyHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "HostIds": {
            "shape": "Srt",
            "locationName": "hostId"
          },
          "HostRecovery": {},
          "InstanceType": {},
          "InstanceFamily": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S1q",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S1dl",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ModifyIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "Resource": {},
          "UseLongIds": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn",
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "Description": {
            "shape": "S7j"
          },
          "ImageId": {},
          "LaunchPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "Ssj"
              },
              "Remove": {
                "shape": "Ssj"
              }
            }
          },
          "OperationType": {},
          "ProductCodes": {
            "shape": "S1de",
            "locationName": "ProductCode"
          },
          "UserGroups": {
            "shape": "S1dd",
            "locationName": "UserGroup"
          },
          "UserIds": {
            "shape": "S1dc",
            "locationName": "UserId"
          },
          "Value": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "SourceDestCheck": {
            "shape": "Stm"
          },
          "Attribute": {
            "locationName": "attribute"
          },
          "BlockDeviceMappings": {
            "locationName": "blockDeviceMapping",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "DeviceName": {
                  "locationName": "deviceName"
                },
                "Ebs": {
                  "locationName": "ebs",
                  "type": "structure",
                  "members": {
                    "DeleteOnTermination": {
                      "locationName": "deleteOnTermination",
                      "type": "boolean"
                    },
                    "VolumeId": {
                      "locationName": "volumeId"
                    }
                  }
                },
                "NoDevice": {
                  "locationName": "noDevice"
                },
                "VirtualName": {
                  "locationName": "virtualName"
                }
              }
            }
          },
          "DisableApiTermination": {
            "shape": "Stm",
            "locationName": "disableApiTermination"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "shape": "Stm",
            "locationName": "ebsOptimized"
          },
          "EnaSupport": {
            "shape": "Stm",
            "locationName": "enaSupport"
          },
          "Groups": {
            "shape": "S3j",
            "locationName": "GroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S7j",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "S7j",
            "locationName": "instanceType"
          },
          "Kernel": {
            "shape": "S7j",
            "locationName": "kernel"
          },
          "Ramdisk": {
            "shape": "S7j",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "S7j",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "locationName": "userData",
            "type": "structure",
            "members": {
              "Value": {
                "locationName": "value",
                "type": "blob"
              }
            }
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "ModifyInstanceCapacityReservationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "CapacityReservationSpecification"
        ],
        "members": {
          "InstanceId": {},
          "CapacityReservationSpecification": {
            "shape": "S1dw"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCreditSpecifications"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "InstanceCreditSpecifications": {
            "locationName": "InstanceCreditSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {},
                "CpuCredits": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulInstanceCreditSpecifications": {
            "locationName": "successfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                }
              }
            }
          },
          "UnsuccessfulInstanceCreditSpecifications": {
            "locationName": "unsuccessfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ModifyInstanceEventStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InstanceEventId",
          "NotBefore"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "InstanceEventId": {},
          "NotBefore": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Event": {
            "shape": "Stz",
            "locationName": "event"
          }
        }
      }
    },
    "ModifyInstanceMetadataOptions": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "HttpTokens": {},
          "HttpPutResponseHopLimit": {
            "type": "integer"
          },
          "HttpEndpoint": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceMetadataOptions": {
            "shape": "Sxh",
            "locationName": "instanceMetadataOptions"
          }
        }
      }
    },
    "ModifyInstancePlacement": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Affinity": {
            "locationName": "affinity"
          },
          "GroupName": {},
          "HostId": {
            "locationName": "hostId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Tenancy": {
            "locationName": "tenancy"
          },
          "PartitionNumber": {
            "type": "integer"
          },
          "HostResourceGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "DefaultVersion": {
            "locationName": "SetDefaultVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "Sax",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "ModifyNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attachment": {
            "locationName": "attachment",
            "type": "structure",
            "members": {
              "AttachmentId": {
                "locationName": "attachmentId"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              }
            }
          },
          "Description": {
            "shape": "S7j",
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S9v",
            "locationName": "SecurityGroupId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "Stm",
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ModifyReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds",
          "TargetConfigurations"
        ],
        "members": {
          "ReservedInstancesIds": {
            "shape": "S110",
            "locationName": "ReservedInstancesId"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "TargetConfigurations": {
            "locationName": "ReservedInstancesConfigurationSetItemType",
            "type": "list",
            "member": {
              "shape": "S11m",
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesModificationId": {
            "locationName": "reservedInstancesModificationId"
          }
        }
      }
    },
    "ModifySnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "CreateVolumePermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S12w"
              },
              "Remove": {
                "shape": "S12w"
              }
            }
          },
          "GroupNames": {
            "shape": "S12o",
            "locationName": "UserGroup"
          },
          "OperationType": {},
          "SnapshotId": {},
          "UserIds": {
            "shape": "S1dc",
            "locationName": "UserId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySpotFleetRequest": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "ExcessCapacityTerminationPolicy": {
            "locationName": "excessCapacityTerminationPolicy"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "TargetCapacity": {
            "locationName": "targetCapacity",
            "type": "integer"
          },
          "OnDemandTargetCapacity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySubnetAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "AssignIpv6AddressOnCreation": {
            "shape": "Stm"
          },
          "MapPublicIpOnLaunch": {
            "shape": "Stm"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "MapCustomerOwnedIpOnLaunch": {
            "shape": "Stm"
          },
          "CustomerOwnedIpv4Pool": {}
        }
      }
    },
    "ModifyTrafficMirrorFilterNetworkServices": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "AddNetworkServices": {
            "shape": "Set",
            "locationName": "AddNetworkService"
          },
          "RemoveNetworkServices": {
            "shape": "Set",
            "locationName": "RemoveNetworkService"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Sen",
            "locationName": "trafficMirrorFilter"
          }
        }
      }
    },
    "ModifyTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sex"
          },
          "SourcePortRange": {
            "shape": "Sex"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sep",
            "locationName": "trafficMirrorFilterRule"
          }
        }
      }
    },
    "ModifyTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sf2",
            "locationName": "trafficMirrorSession"
          }
        }
      }
    },
    "ModifyTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "AddSubnetIds": {
            "shape": "Sg1"
          },
          "RemoveSubnetIds": {
            "shape": "Sg1"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "Ipv6Support": {}
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sn",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "ModifyVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeId": {},
          "Size": {
            "type": "integer"
          },
          "VolumeType": {},
          "Iops": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeModification": {
            "shape": "S16v",
            "locationName": "volumeModification"
          }
        }
      }
    },
    "ModifyVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "AutoEnableIO": {
            "shape": "Stm"
          },
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "EnableDnsHostnames": {
            "shape": "Stm"
          },
          "EnableDnsSupport": {
            "shape": "Stm"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "ModifyVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "ResetPolicy": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "AddRouteTableIds": {
            "shape": "Sgc",
            "locationName": "AddRouteTableId"
          },
          "RemoveRouteTableIds": {
            "shape": "Sgc",
            "locationName": "RemoveRouteTableId"
          },
          "AddSubnetIds": {
            "shape": "Sgd",
            "locationName": "AddSubnetId"
          },
          "RemoveSubnetIds": {
            "shape": "Sgd",
            "locationName": "RemoveSubnetId"
          },
          "AddSecurityGroupIds": {
            "shape": "Sge",
            "locationName": "AddSecurityGroupId"
          },
          "RemoveSecurityGroupIds": {
            "shape": "Sge",
            "locationName": "RemoveSecurityGroupId"
          },
          "PrivateDnsEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "PrivateDnsName": {},
          "RemovePrivateDnsName": {
            "type": "boolean"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "AddNetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "AddNetworkLoadBalancerArn"
          },
          "RemoveNetworkLoadBalancerArns": {
            "shape": "So",
            "locationName": "RemoveNetworkLoadBalancerArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "AddAllowedPrincipals": {
            "shape": "So"
          },
          "RemoveAllowedPrincipals": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcPeeringConnectionOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S1fi"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S1fi"
          },
          "VpcPeeringConnectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S1fk",
            "locationName": "accepterPeeringConnectionOptions"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S1fk",
            "locationName": "requesterPeeringConnectionOptions"
          }
        }
      }
    },
    "ModifyVpcTenancy": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "InstanceTenancy"
        ],
        "members": {
          "VpcId": {},
          "InstanceTenancy": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "TransitGatewayId": {},
          "CustomerGatewayId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Shn",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnTunnelCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Shn",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnTunnelOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress",
          "TunnelOptions"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "TunnelOptions": {
            "type": "structure",
            "members": {
              "TunnelInsideCidr": {},
              "PreSharedKey": {},
              "Phase1LifetimeSeconds": {
                "type": "integer"
              },
              "Phase2LifetimeSeconds": {
                "type": "integer"
              },
              "RekeyMarginTimeSeconds": {
                "type": "integer"
              },
              "RekeyFuzzPercentage": {
                "type": "integer"
              },
              "ReplayWindowSize": {
                "type": "integer"
              },
              "DPDTimeoutSeconds": {
                "type": "integer"
              },
              "Phase1EncryptionAlgorithms": {
                "shape": "Sh8",
                "locationName": "Phase1EncryptionAlgorithm"
              },
              "Phase2EncryptionAlgorithms": {
                "shape": "Sha",
                "locationName": "Phase2EncryptionAlgorithm"
              },
              "Phase1IntegrityAlgorithms": {
                "shape": "Shc",
                "locationName": "Phase1IntegrityAlgorithm"
              },
              "Phase2IntegrityAlgorithms": {
                "shape": "She",
                "locationName": "Phase2IntegrityAlgorithm"
              },
              "Phase1DHGroupNumbers": {
                "shape": "Shg",
                "locationName": "Phase1DHGroupNumber"
              },
              "Phase2DHGroupNumbers": {
                "shape": "Shi",
                "locationName": "Phase2DHGroupNumber"
              },
              "IKEVersions": {
                "shape": "Shk",
                "locationName": "IKEVersion"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Shn",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "MonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S1fx",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "MoveAddressToVpc": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllocationId": {
            "locationName": "allocationId"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "ProvisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "CidrAuthorizationContext": {
            "type": "structure",
            "required": [
              "Message",
              "Signature"
            ],
            "members": {
              "Message": {},
              "Signature": {}
            }
          },
          "PubliclyAdvertisable": {
            "type": "boolean"
          },
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "PoolTagSpecifications": {
            "shape": "S1l",
            "locationName": "PoolTagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1e",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "PurchaseHostReservation": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "ClientToken": {},
          "CurrencyCode": {},
          "HostIdSet": {
            "shape": "S1bb"
          },
          "LimitPrice": {},
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S1bd",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "PurchaseReservedInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCount",
          "ReservedInstancesOfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "ReservedInstancesOfferingId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LimitPrice": {
            "locationName": "limitPrice",
            "type": "structure",
            "members": {
              "Amount": {
                "locationName": "amount",
                "type": "double"
              },
              "CurrencyCode": {
                "locationName": "currencyCode"
              }
            }
          },
          "PurchaseTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      }
    },
    "PurchaseScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "PurchaseRequests"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "PurchaseRequests": {
            "locationName": "PurchaseRequest",
            "type": "list",
            "member": {
              "locationName": "PurchaseRequest",
              "type": "structure",
              "required": [
                "InstanceCount",
                "PurchaseToken"
              ],
              "members": {
                "InstanceCount": {
                  "type": "integer"
                },
                "PurchaseToken": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "S12h",
              "locationName": "item"
            }
          }
        }
      }
    },
    "RebootInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RegisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "ImageLocation": {},
          "Architecture": {
            "locationName": "architecture"
          },
          "BlockDeviceMappings": {
            "shape": "S8z",
            "locationName": "BlockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EnaSupport": {
            "locationName": "enaSupport",
            "type": "boolean"
          },
          "KernelId": {
            "locationName": "kernelId"
          },
          "Name": {
            "locationName": "name"
          },
          "BillingProducts": {
            "locationName": "BillingProduct",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "RamdiskId": {
            "locationName": "ramdiskId"
          },
          "RootDeviceName": {
            "locationName": "rootDeviceName"
          },
          "SriovNetSupport": {
            "locationName": "sriovNetSupport"
          },
          "VirtualizationType": {
            "locationName": "virtualizationType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "RegisterInstanceEventNotificationAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceTagAttribute": {
            "type": "structure",
            "members": {
              "IncludeAllTagsOfInstance": {
                "type": "boolean"
              },
              "InstanceTagKeys": {
                "shape": "Slj",
                "locationName": "InstanceTagKey"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTagAttribute": {
            "shape": "Sll",
            "locationName": "instanceTagAttribute"
          }
        }
      }
    },
    "RegisterTransitGatewayMulticastGroupMembers": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "Sln"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegisteredMulticastGroupMembers": {
            "locationName": "registeredMulticastGroupMembers",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "RegisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "registeredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "RegisterTransitGatewayMulticastGroupSources": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "GroupIpAddress": {},
          "NetworkInterfaceIds": {
            "shape": "Sln"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegisteredMulticastGroupSources": {
            "locationName": "registeredMulticastGroupSources",
            "type": "structure",
            "members": {
              "TransitGatewayMulticastDomainId": {
                "locationName": "transitGatewayMulticastDomainId"
              },
              "RegisteredNetworkInterfaceIds": {
                "shape": "So",
                "locationName": "registeredNetworkInterfaceIds"
              },
              "GroupIpAddress": {
                "locationName": "groupIpAddress"
              }
            }
          }
        }
      }
    },
    "RejectTransitGatewayPeeringAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayPeeringAttachment": {
            "shape": "Se",
            "locationName": "transitGatewayPeeringAttachment"
          }
        }
      }
    },
    "RejectTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sn",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "RejectVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "Su",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sx",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "RejectVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "PublicIp": {},
          "NetworkBorderGroup": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "HostIds": {
            "shape": "Srt",
            "locationName": "hostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S1q",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S1dl",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ReplaceIamInstanceProfileAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "AssociationId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S2i"
          },
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S2k",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "ReplaceNetworkAclAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "NetworkAclId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReplaceNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Scl",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Scm",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "ReplaceRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "LocalTarget": {
            "type": "boolean"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "LocalGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      }
    },
    "ReplaceRouteTableAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "RouteTableId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          },
          "AssociationState": {
            "shape": "S2r",
            "locationName": "associationState"
          }
        }
      }
    },
    "ReplaceTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Sfr",
            "locationName": "route"
          }
        }
      }
    },
    "ReportInstanceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Instances",
          "ReasonCodes",
          "Status"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "Instances": {
            "shape": "Sn2",
            "locationName": "instanceId"
          },
          "ReasonCodes": {
            "locationName": "reasonCode",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RequestSpotFleet": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestConfig"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestConfig": {
            "shape": "S13j",
            "locationName": "spotFleetRequestConfig"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "RequestSpotInstances": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "type": "structure",
            "members": {
              "SecurityGroupIds": {
                "locationName": "SecurityGroupId",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              },
              "SecurityGroups": {
                "locationName": "SecurityGroup",
                "type": "list",
                "member": {
                  "locationName": "item"
                }
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Ssi",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S2i",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "shape": "S14b",
                "locationName": "monitoring"
              },
              "NetworkInterfaces": {
                "shape": "S13q",
                "locationName": "NetworkInterface"
              },
              "Placement": {
                "shape": "S13s",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData"
              }
            }
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "InstanceInterruptionBehavior": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "S148",
            "locationName": "spotInstanceRequestSet"
          }
        }
      }
    },
    "ResetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ResetFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ResetImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ResetInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "ResetNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ResetSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RestoreAddressToClassic": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RevokeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "RevokeAllGroups": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S40",
            "locationName": "status"
          }
        }
      }
    },
    "RevokeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S43",
            "locationName": "ipPermissions"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      }
    },
    "RevokeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S43"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RunInstances": {
      "input": {
        "type": "structure",
        "required": [
          "MaxCount",
          "MinCount"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "S8z",
            "locationName": "BlockDeviceMapping"
          },
          "ImageId": {},
          "InstanceType": {},
          "Ipv6AddressCount": {
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sbb",
            "locationName": "Ipv6Address"
          },
          "KernelId": {},
          "KeyName": {},
          "MaxCount": {
            "type": "integer"
          },
          "MinCount": {
            "type": "integer"
          },
          "Monitoring": {
            "shape": "S14b"
          },
          "Placement": {
            "shape": "S87"
          },
          "RamdiskId": {},
          "SecurityGroupIds": {
            "shape": "S9v",
            "locationName": "SecurityGroupId"
          },
          "SecurityGroups": {
            "shape": "Saf",
            "locationName": "SecurityGroup"
          },
          "SubnetId": {},
          "UserData": {},
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "ClientToken": {
            "idempotencyToken": true,
            "locationName": "clientToken"
          },
          "DisableApiTermination": {
            "locationName": "disableApiTermination",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "locationName": "ebsOptimized",
            "type": "boolean"
          },
          "IamInstanceProfile": {
            "shape": "S2i",
            "locationName": "iamInstanceProfile"
          },
          "InstanceInitiatedShutdownBehavior": {
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "NetworkInterfaces": {
            "shape": "S13q",
            "locationName": "networkInterface"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "ElasticGpuSpecification": {
            "type": "list",
            "member": {
              "shape": "Sab",
              "locationName": "item"
            }
          },
          "ElasticInferenceAccelerators": {
            "locationName": "ElasticInferenceAccelerator",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "Type"
              ],
              "members": {
                "Type": {},
                "Count": {
                  "type": "integer"
                }
              }
            }
          },
          "TagSpecifications": {
            "shape": "S1l",
            "locationName": "TagSpecification"
          },
          "LaunchTemplate": {
            "type": "structure",
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "InstanceMarketOptions": {
            "type": "structure",
            "members": {
              "MarketType": {},
              "SpotOptions": {
                "type": "structure",
                "members": {
                  "MaxPrice": {},
                  "SpotInstanceType": {},
                  "BlockDurationMinutes": {
                    "type": "integer"
                  },
                  "ValidUntil": {
                    "type": "timestamp"
                  },
                  "InstanceInterruptionBehavior": {}
                }
              }
            }
          },
          "CreditSpecification": {
            "shape": "Sal"
          },
          "CpuOptions": {
            "type": "structure",
            "members": {
              "CoreCount": {
                "type": "integer"
              },
              "ThreadsPerCore": {
                "type": "integer"
              }
            }
          },
          "CapacityReservationSpecification": {
            "shape": "S1dw"
          },
          "HibernationOptions": {
            "type": "structure",
            "members": {
              "Configured": {
                "type": "boolean"
              }
            }
          },
          "LicenseSpecifications": {
            "locationName": "LicenseSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LicenseConfigurationArn": {}
              }
            }
          },
          "MetadataOptions": {
            "type": "structure",
            "members": {
              "HttpTokens": {},
              "HttpPutResponseHopLimit": {
                "type": "integer"
              },
              "HttpEndpoint": {}
            }
          }
        }
      },
      "output": {
        "shape": "Sww"
      }
    },
    "RunScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchSpecification",
          "ScheduledInstanceId"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "InstanceCount": {
            "type": "integer"
          },
          "LaunchSpecification": {
            "type": "structure",
            "required": [
              "ImageId"
            ],
            "members": {
              "BlockDeviceMappings": {
                "locationName": "BlockDeviceMapping",
                "type": "list",
                "member": {
                  "locationName": "BlockDeviceMapping",
                  "type": "structure",
                  "members": {
                    "DeviceName": {},
                    "Ebs": {
                      "type": "structure",
                      "members": {
                        "DeleteOnTermination": {
                          "type": "boolean"
                        },
                        "Encrypted": {
                          "type": "boolean"
                        },
                        "Iops": {
                          "type": "integer"
                        },
                        "SnapshotId": {},
                        "VolumeSize": {
                          "type": "integer"
                        },
                        "VolumeType": {}
                      }
                    },
                    "NoDevice": {},
                    "VirtualName": {}
                  }
                }
              },
              "EbsOptimized": {
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "type": "structure",
                "members": {
                  "Arn": {},
                  "Name": {}
                }
              },
              "ImageId": {},
              "InstanceType": {},
              "KernelId": {},
              "KeyName": {},
              "Monitoring": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "NetworkInterface",
                "type": "list",
                "member": {
                  "locationName": "NetworkInterface",
                  "type": "structure",
                  "members": {
                    "AssociatePublicIpAddress": {
                      "type": "boolean"
                    },
                    "DeleteOnTermination": {
                      "type": "boolean"
                    },
                    "Description": {},
                    "DeviceIndex": {
                      "type": "integer"
                    },
                    "Groups": {
                      "shape": "S1ir",
                      "locationName": "Group"
                    },
                    "Ipv6AddressCount": {
                      "type": "integer"
                    },
                    "Ipv6Addresses": {
                      "locationName": "Ipv6Address",
                      "type": "list",
                      "member": {
                        "locationName": "Ipv6Address",
                        "type": "structure",
                        "members": {
                          "Ipv6Address": {}
                        }
                      }
                    },
                    "NetworkInterfaceId": {},
                    "PrivateIpAddress": {},
                    "PrivateIpAddressConfigs": {
                      "locationName": "PrivateIpAddressConfig",
                      "type": "list",
                      "member": {
                        "locationName": "PrivateIpAddressConfigSet",
                        "type": "structure",
                        "members": {
                          "Primary": {
                            "type": "boolean"
                          },
                          "PrivateIpAddress": {}
                        }
                      }
                    },
                    "SecondaryPrivateIpAddressCount": {
                      "type": "integer"
                    },
                    "SubnetId": {}
                  }
                }
              },
              "Placement": {
                "type": "structure",
                "members": {
                  "AvailabilityZone": {},
                  "GroupName": {}
                }
              },
              "RamdiskId": {},
              "SecurityGroupIds": {
                "shape": "S1ir",
                "locationName": "SecurityGroupId"
              },
              "SubnetId": {},
              "UserData": {}
            }
          },
          "ScheduledInstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceIdSet": {
            "locationName": "instanceIdSet",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      }
    },
    "SearchLocalGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "LocalGatewayRouteTableId",
          "Filters"
        ],
        "members": {
          "LocalGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routeSet",
            "type": "list",
            "member": {
              "shape": "Sc0",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "SearchTransitGatewayMulticastGroups": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayMulticastDomainId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MulticastGroups": {
            "locationName": "multicastGroups",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupIpAddress": {
                  "locationName": "groupIpAddress"
                },
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "SubnetId": {
                  "locationName": "subnetId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "GroupMember": {
                  "locationName": "groupMember",
                  "type": "boolean"
                },
                "GroupSource": {
                  "locationName": "groupSource",
                  "type": "boolean"
                },
                "MemberType": {
                  "locationName": "memberType"
                },
                "SourceType": {
                  "locationName": "sourceType"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "SearchTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "Filters"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sm2",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routeSet",
            "type": "list",
            "member": {
              "shape": "Sfr",
              "locationName": "item"
            }
          },
          "AdditionalRoutesAvailable": {
            "locationName": "additionalRoutesAvailable",
            "type": "boolean"
          }
        }
      }
    },
    "SendDiagnosticInterrupt": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "StartInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartingInstances": {
            "shape": "S1jf",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "StartVpcEndpointServicePrivateDnsVerification": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "StopInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "Hibernate": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StoppingInstances": {
            "shape": "S1jf",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "TerminateClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ConnectionId": {},
          "Username": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Username": {
            "locationName": "username"
          },
          "ConnectionStatuses": {
            "locationName": "connectionStatuses",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "PreviousStatus": {
                  "shape": "Snh",
                  "locationName": "previousStatus"
                },
                "CurrentStatus": {
                  "shape": "Snh",
                  "locationName": "currentStatus"
                }
              }
            }
          }
        }
      }
    },
    "TerminateInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminatingInstances": {
            "shape": "S1jf",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UnassignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "Ipv6Addresses",
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6Addresses": {
            "shape": "S1y",
            "locationName": "ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "UnassignedIpv6Addresses": {
            "shape": "S1y",
            "locationName": "unassignedIpv6Addresses"
          }
        }
      }
    },
    "UnassignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "PrivateIpAddresses"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S22",
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "UnmonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sn2",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S1fx",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsEgress": {
      "input": {
        "type": "structure",
        "required": [
          "IpPermissions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S43"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsIngress": {
      "input": {
        "type": "structure",
        "required": [
          "IpPermissions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S43"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "WithdrawByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S1e",
            "locationName": "byoipCidr"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstanceId"
      }
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "TargetConfigurationRequest",
        "type": "structure",
        "required": [
          "OfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "OfferingId": {}
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "RequesterTgwInfo": {
          "shape": "Sf",
          "locationName": "requesterTgwInfo"
        },
        "AccepterTgwInfo": {
          "shape": "Sf",
          "locationName": "accepterTgwInfo"
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Region": {
          "locationName": "region"
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Key": {
            "locationName": "key"
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcOwnerId": {
          "locationName": "vpcOwnerId"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetIds": {
          "shape": "So",
          "locationName": "subnetIds"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "DnsSupport": {
              "locationName": "dnsSupport"
            },
            "Ipv6Support": {
              "locationName": "ipv6Support"
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "So": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "shape": "Sy",
        "locationName": "item"
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "Error": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "ResourceId": {
          "locationName": "resourceId"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "AccepterVpcInfo": {
          "shape": "S14",
          "locationName": "accepterVpcInfo"
        },
        "ExpirationTime": {
          "locationName": "expirationTime",
          "type": "timestamp"
        },
        "RequesterVpcInfo": {
          "shape": "S14",
          "locationName": "requesterVpcInfo"
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "Ipv6CidrBlockSet": {
          "locationName": "ipv6CidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              }
            }
          }
        },
        "CidrBlockSet": {
          "locationName": "cidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PeeringOptions": {
          "locationName": "peeringOptions",
          "type": "structure",
          "members": {
            "AllowDnsResolutionFromRemoteVpc": {
              "locationName": "allowDnsResolutionFromRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalClassicLinkToRemoteVpc": {
              "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalVpcToRemoteClassicLink": {
              "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
              "type": "boolean"
            }
          }
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Region": {
          "locationName": "region"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "Description": {
          "locationName": "description"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ResourceType": {
            "locationName": "resourceType"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "Tag"
          }
        }
      }
    },
    "S1q": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "locationName": "PrivateIpAddress"
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "IamInstanceProfile": {
          "shape": "S2l",
          "locationName": "iamInstanceProfile"
        },
        "State": {
          "locationName": "state"
        },
        "Timestamp": {
          "locationName": "timestamp",
          "type": "timestamp"
        }
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Id": {
          "locationName": "id"
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S2v": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "locationName": "ipv6CidrBlockState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "TransitGatewayMulticastDomainId": {
          "locationName": "transitGatewayMulticastDomainId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "Subnets": {
          "locationName": "subnets",
          "type": "list",
          "member": {
            "shape": "S34",
            "locationName": "item"
          }
        }
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "SubnetId": {
          "locationName": "subnetId"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "shape": "S3f",
          "locationName": "ipv6CidrBlockState"
        },
        "NetworkBorderGroup": {
          "locationName": "networkBorderGroup"
        },
        "Ipv6Pool": {
          "locationName": "ipv6Pool"
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "CidrBlockState": {
          "shape": "S3f",
          "locationName": "cidrBlockState"
        }
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "locationName": "groupId"
      }
    },
    "S3r": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "Device": {
          "locationName": "device"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        }
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S43": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIp": {
                  "locationName": "cidrIp"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "Ipv6Ranges": {
            "locationName": "ipv6Ranges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIpv6": {
                  "locationName": "cidrIpv6"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                }
              }
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S4c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PeeringStatus": {
          "locationName": "peeringStatus"
        },
        "UserId": {
          "locationName": "userId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "S4g": {
      "type": "structure",
      "members": {
        "S3": {
          "type": "structure",
          "members": {
            "AWSAccessKeyId": {},
            "Bucket": {
              "locationName": "bucket"
            },
            "Prefix": {
              "locationName": "prefix"
            },
            "UploadPolicy": {
              "locationName": "uploadPolicy",
              "type": "blob"
            },
            "UploadPolicySignature": {
              "locationName": "uploadPolicySignature"
            }
          }
        }
      }
    },
    "S4k": {
      "type": "structure",
      "members": {
        "BundleId": {
          "locationName": "bundleId"
        },
        "BundleTaskError": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "state"
        },
        "Storage": {
          "shape": "S4g",
          "locationName": "storage"
        },
        "UpdateTime": {
          "locationName": "updateTime",
          "type": "timestamp"
        }
      }
    },
    "S53": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CreateDate": {
            "locationName": "createDate",
            "type": "timestamp"
          },
          "InstanceCounts": {
            "locationName": "instanceCounts",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Active": {
                  "locationName": "active",
                  "type": "boolean"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          },
          "UpdateDate": {
            "locationName": "updateDate",
            "type": "timestamp"
          }
        }
      }
    },
    "S5f": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S5q": {
      "type": "list",
      "member": {
        "locationName": "SpotInstanceRequestId"
      }
    },
    "S6c": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "CapacityReservationArn": {
          "locationName": "capacityReservationArn"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "InstancePlatform": {
          "locationName": "instancePlatform"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "TotalInstanceCount": {
          "locationName": "totalInstanceCount",
          "type": "integer"
        },
        "AvailableInstanceCount": {
          "locationName": "availableInstanceCount",
          "type": "integer"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "EphemeralStorage": {
          "locationName": "ephemeralStorage",
          "type": "boolean"
        },
        "State": {
          "locationName": "state"
        },
        "EndDate": {
          "locationName": "endDate",
          "type": "timestamp"
        },
        "EndDateType": {
          "locationName": "endDateType"
        },
        "InstanceMatchCriteria": {
          "locationName": "instanceMatchCriteria"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S6l": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "CloudwatchLogGroup": {},
        "CloudwatchLogStream": {}
      }
    },
    "S6o": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S6x": {
      "type": "structure",
      "members": {
        "BgpAsn": {
          "locationName": "bgpAsn"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "IpAddress": {
          "locationName": "ipAddress"
        },
        "CertificateArn": {
          "locationName": "certificateArn"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "DeviceName": {
          "locationName": "deviceName"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S70": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "AvailableIpAddressCount": {
          "locationName": "availableIpAddressCount",
          "type": "integer"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DefaultForAz": {
          "locationName": "defaultForAz",
          "type": "boolean"
        },
        "MapPublicIpOnLaunch": {
          "locationName": "mapPublicIpOnLaunch",
          "type": "boolean"
        },
        "MapCustomerOwnedIpOnLaunch": {
          "locationName": "mapCustomerOwnedIpOnLaunch",
          "type": "boolean"
        },
        "CustomerOwnedIpv4Pool": {
          "locationName": "customerOwnedIpv4Pool"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "AssignIpv6AddressOnCreation": {
          "locationName": "assignIpv6AddressOnCreation",
          "type": "boolean"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S2v",
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "SubnetArn": {
          "locationName": "subnetArn"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        }
      }
    },
    "S76": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "InstanceTenancy": {
          "locationName": "instanceTenancy"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S3e",
            "locationName": "item"
          }
        },
        "CidrBlockAssociationSet": {
          "locationName": "cidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S3h",
            "locationName": "item"
          }
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S7f": {
      "type": "structure",
      "members": {
        "DhcpConfigurations": {
          "locationName": "dhcpConfigurationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Key": {
                "locationName": "key"
              },
              "Values": {
                "locationName": "valueSet",
                "type": "list",
                "member": {
                  "shape": "S7j",
                  "locationName": "item"
                }
              }
            }
          }
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S7j": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value"
        }
      }
    },
    "S7m": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "S7n",
          "locationName": "attachmentSet"
        },
        "EgressOnlyInternetGatewayId": {
          "locationName": "egressOnlyInternetGatewayId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S7n": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "S87": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Affinity": {
          "locationName": "affinity"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PartitionNumber": {
          "locationName": "partitionNumber",
          "type": "integer"
        },
        "HostId": {
          "locationName": "hostId"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "SpreadDomain": {
          "locationName": "spreadDomain"
        },
        "HostResourceGroupArn": {
          "locationName": "hostResourceGroupArn"
        }
      }
    },
    "S88": {
      "type": "structure",
      "required": [
        "TotalTargetCapacity"
      ],
      "members": {
        "TotalTargetCapacity": {
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "type": "integer"
        },
        "SpotTargetCapacity": {
          "type": "integer"
        },
        "DefaultTargetCapacityType": {}
      }
    },
    "S8f": {
      "type": "structure",
      "members": {
        "LaunchTemplateSpecification": {
          "shape": "S8g",
          "locationName": "launchTemplateSpecification"
        },
        "Overrides": {
          "shape": "S8h",
          "locationName": "overrides"
        }
      }
    },
    "S8g": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "Version": {
          "locationName": "version"
        }
      }
    },
    "S8h": {
      "type": "structure",
      "members": {
        "InstanceType": {
          "locationName": "instanceType"
        },
        "MaxPrice": {
          "locationName": "maxPrice"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "WeightedCapacity": {
          "locationName": "weightedCapacity",
          "type": "double"
        },
        "Priority": {
          "locationName": "priority",
          "type": "double"
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "GroupName": {
              "locationName": "groupName"
            }
          }
        }
      }
    },
    "S8m": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S8w": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "S8z": {
      "type": "list",
      "member": {
        "shape": "S90",
        "locationName": "BlockDeviceMapping"
      }
    },
    "S90": {
      "type": "structure",
      "members": {
        "DeviceName": {
          "locationName": "deviceName"
        },
        "VirtualName": {
          "locationName": "virtualName"
        },
        "Ebs": {
          "locationName": "ebs",
          "type": "structure",
          "members": {
            "DeleteOnTermination": {
              "locationName": "deleteOnTermination",
              "type": "boolean"
            },
            "Iops": {
              "locationName": "iops",
              "type": "integer"
            },
            "SnapshotId": {
              "locationName": "snapshotId"
            },
            "VolumeSize": {
              "locationName": "volumeSize",
              "type": "integer"
            },
            "VolumeType": {
              "locationName": "volumeType"
            },
            "KmsKeyId": {},
            "Encrypted": {
              "locationName": "encrypted",
              "type": "boolean"
            }
          }
        },
        "NoDevice": {
          "locationName": "noDevice"
        }
      }
    },
    "S9a": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "ExportTaskId": {
          "locationName": "exportTaskId"
        },
        "ExportToS3Task": {
          "locationName": "exportToS3",
          "type": "structure",
          "members": {
            "ContainerFormat": {
              "locationName": "containerFormat"
            },
            "DiskImageFormat": {
              "locationName": "diskImageFormat"
            },
            "S3Bucket": {
              "locationName": "s3Bucket"
            },
            "S3Key": {
              "locationName": "s3Key"
            }
          }
        },
        "InstanceExportDetails": {
          "locationName": "instanceExport",
          "type": "structure",
          "members": {
            "InstanceId": {
              "locationName": "instanceId"
            },
            "TargetEnvironment": {
              "locationName": "targetEnvironment"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S9g": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "S7n",
          "locationName": "attachmentSet"
        },
        "InternetGatewayId": {
          "locationName": "internetGatewayId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S9m": {
      "type": "structure",
      "members": {
        "KernelId": {},
        "EbsOptimized": {
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "type": "structure",
          "members": {
            "Arn": {},
            "Name": {}
          }
        },
        "BlockDeviceMappings": {
          "locationName": "BlockDeviceMapping",
          "type": "list",
          "member": {
            "locationName": "BlockDeviceMapping",
            "type": "structure",
            "members": {
              "DeviceName": {},
              "VirtualName": {},
              "Ebs": {
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "type": "boolean"
                  },
                  "Iops": {
                    "type": "integer"
                  },
                  "KmsKeyId": {},
                  "SnapshotId": {},
                  "VolumeSize": {
                    "type": "integer"
                  },
                  "VolumeType": {}
                }
              },
              "NoDevice": {}
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "NetworkInterface",
          "type": "list",
          "member": {
            "locationName": "InstanceNetworkInterfaceSpecification",
            "type": "structure",
            "members": {
              "AssociatePublicIpAddress": {
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Description": {},
              "DeviceIndex": {
                "type": "integer"
              },
              "Groups": {
                "shape": "S9v",
                "locationName": "SecurityGroupId"
              },
              "InterfaceType": {},
              "Ipv6AddressCount": {
                "type": "integer"
              },
              "Ipv6Addresses": {
                "type": "list",
                "member": {
                  "locationName": "InstanceIpv6Address",
                  "type": "structure",
                  "members": {
                    "Ipv6Address": {}
                  }
                }
              },
              "NetworkInterfaceId": {},
              "PrivateIpAddress": {},
              "PrivateIpAddresses": {
                "shape": "S9y"
              },
              "SecondaryPrivateIpAddressCount": {
                "type": "integer"
              },
              "SubnetId": {}
            }
          }
        },
        "ImageId": {},
        "InstanceType": {},
        "KeyName": {},
        "Monitoring": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "type": "structure",
          "members": {
            "AvailabilityZone": {},
            "Affinity": {},
            "GroupName": {},
            "HostId": {},
            "Tenancy": {},
            "SpreadDomain": {},
            "HostResourceGroupArn": {},
            "PartitionNumber": {
              "type": "integer"
            }
          }
        },
        "RamDiskId": {},
        "DisableApiTermination": {
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {},
        "UserData": {},
        "TagSpecifications": {
          "locationName": "TagSpecification",
          "type": "list",
          "member": {
            "locationName": "LaunchTemplateTagSpecificationRequest",
            "type": "structure",
            "members": {
              "ResourceType": {},
              "Tags": {
                "shape": "Sj",
                "locationName": "Tag"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "ElasticGpuSpecification",
          "type": "list",
          "member": {
            "shape": "Sab",
            "locationName": "ElasticGpuSpecification"
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "ElasticInferenceAccelerator",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Count": {
                "type": "integer"
              }
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "S9v",
          "locationName": "SecurityGroupId"
        },
        "SecurityGroups": {
          "shape": "Saf",
          "locationName": "SecurityGroup"
        },
        "InstanceMarketOptions": {
          "type": "structure",
          "members": {
            "MarketType": {},
            "SpotOptions": {
              "type": "structure",
              "members": {
                "MaxPrice": {},
                "SpotInstanceType": {},
                "BlockDurationMinutes": {
                  "type": "integer"
                },
                "ValidUntil": {
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {}
              }
            }
          }
        },
        "CreditSpecification": {
          "shape": "Sal"
        },
        "CpuOptions": {
          "type": "structure",
          "members": {
            "CoreCount": {
              "type": "integer"
            },
            "ThreadsPerCore": {
              "type": "integer"
            }
          }
        },
        "CapacityReservationSpecification": {
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {},
            "CapacityReservationTarget": {
              "shape": "Sap"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "LicenseSpecification",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {}
            }
          }
        },
        "HibernationOptions": {
          "type": "structure",
          "members": {
            "Configured": {
              "type": "boolean"
            }
          }
        },
        "MetadataOptions": {
          "type": "structure",
          "members": {
            "HttpTokens": {},
            "HttpPutResponseHopLimit": {
              "type": "integer"
            },
            "HttpEndpoint": {}
          }
        }
      }
    },
    "S9v": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S9y": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Primary": {
            "locationName": "primary",
            "type": "boolean"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "Sab": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {}
      }
    },
    "Saf": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroup"
      }
    },
    "Sal": {
      "type": "structure",
      "required": [
        "CpuCredits"
      ],
      "members": {
        "CpuCredits": {}
      }
    },
    "Sap": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {}
      }
    },
    "Sax": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersionNumber": {
          "locationName": "defaultVersionNumber",
          "type": "long"
        },
        "LatestVersionNumber": {
          "locationName": "latestVersionNumber",
          "type": "long"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Say": {
      "type": "structure",
      "members": {
        "Errors": {
          "locationName": "errorSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              }
            }
          }
        }
      }
    },
    "Sb3": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "VersionNumber": {
          "locationName": "versionNumber",
          "type": "long"
        },
        "VersionDescription": {
          "locationName": "versionDescription"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersion": {
          "locationName": "defaultVersion",
          "type": "boolean"
        },
        "LaunchTemplateData": {
          "shape": "Sb4",
          "locationName": "launchTemplateData"
        }
      }
    },
    "Sb4": {
      "type": "structure",
      "members": {
        "KernelId": {
          "locationName": "kernelId"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "locationName": "iamInstanceProfile",
          "type": "structure",
          "members": {
            "Arn": {
              "locationName": "arn"
            },
            "Name": {
              "locationName": "name"
            }
          }
        },
        "BlockDeviceMappings": {
          "locationName": "blockDeviceMappingSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DeviceName": {
                "locationName": "deviceName"
              },
              "VirtualName": {
                "locationName": "virtualName"
              },
              "Ebs": {
                "locationName": "ebs",
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "locationName": "encrypted",
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "locationName": "deleteOnTermination",
                    "type": "boolean"
                  },
                  "Iops": {
                    "locationName": "iops",
                    "type": "integer"
                  },
                  "KmsKeyId": {
                    "locationName": "kmsKeyId"
                  },
                  "SnapshotId": {
                    "locationName": "snapshotId"
                  },
                  "VolumeSize": {
                    "locationName": "volumeSize",
                    "type": "integer"
                  },
                  "VolumeType": {
                    "locationName": "volumeType"
                  }
                }
              },
              "NoDevice": {
                "locationName": "noDevice"
              }
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "networkInterfaceSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AssociatePublicIpAddress": {
                "locationName": "associatePublicIpAddress",
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Description": {
                "locationName": "description"
              },
              "DeviceIndex": {
                "locationName": "deviceIndex",
                "type": "integer"
              },
              "Groups": {
                "shape": "S3j",
                "locationName": "groupSet"
              },
              "InterfaceType": {
                "locationName": "interfaceType"
              },
              "Ipv6AddressCount": {
                "locationName": "ipv6AddressCount",
                "type": "integer"
              },
              "Ipv6Addresses": {
                "shape": "Sbb",
                "locationName": "ipv6AddressesSet"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "PrivateIpAddresses": {
                "shape": "S9y",
                "locationName": "privateIpAddressesSet"
              },
              "SecondaryPrivateIpAddressCount": {
                "locationName": "secondaryPrivateIpAddressCount",
                "type": "integer"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "ImageId": {
          "locationName": "imageId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "KeyName": {
          "locationName": "keyName"
        },
        "Monitoring": {
          "locationName": "monitoring",
          "type": "structure",
          "members": {
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "Affinity": {
              "locationName": "affinity"
            },
            "GroupName": {
              "locationName": "groupName"
            },
            "HostId": {
              "locationName": "hostId"
            },
            "Tenancy": {
              "locationName": "tenancy"
            },
            "SpreadDomain": {
              "locationName": "spreadDomain"
            },
            "HostResourceGroupArn": {
              "locationName": "hostResourceGroupArn"
            },
            "PartitionNumber": {
              "locationName": "partitionNumber",
              "type": "integer"
            }
          }
        },
        "RamDiskId": {
          "locationName": "ramDiskId"
        },
        "DisableApiTermination": {
          "locationName": "disableApiTermination",
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {
          "locationName": "instanceInitiatedShutdownBehavior"
        },
        "UserData": {
          "locationName": "userData"
        },
        "TagSpecifications": {
          "locationName": "tagSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceType": {
                "locationName": "resourceType"
              },
              "Tags": {
                "shape": "Sj",
                "locationName": "tagSet"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "elasticGpuSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              }
            }
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "elasticInferenceAcceleratorSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              },
              "Count": {
                "locationName": "count",
                "type": "integer"
              }
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "So",
          "locationName": "securityGroupIdSet"
        },
        "SecurityGroups": {
          "shape": "So",
          "locationName": "securityGroupSet"
        },
        "InstanceMarketOptions": {
          "locationName": "instanceMarketOptions",
          "type": "structure",
          "members": {
            "MarketType": {
              "locationName": "marketType"
            },
            "SpotOptions": {
              "locationName": "spotOptions",
              "type": "structure",
              "members": {
                "MaxPrice": {
                  "locationName": "maxPrice"
                },
                "SpotInstanceType": {
                  "locationName": "spotInstanceType"
                },
                "BlockDurationMinutes": {
                  "locationName": "blockDurationMinutes",
                  "type": "integer"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {
                  "locationName": "instanceInterruptionBehavior"
                }
              }
            }
          }
        },
        "CreditSpecification": {
          "locationName": "creditSpecification",
          "type": "structure",
          "members": {
            "CpuCredits": {
              "locationName": "cpuCredits"
            }
          }
        },
        "CpuOptions": {
          "locationName": "cpuOptions",
          "type": "structure",
          "members": {
            "CoreCount": {
              "locationName": "coreCount",
              "type": "integer"
            },
            "ThreadsPerCore": {
              "locationName": "threadsPerCore",
              "type": "integer"
            }
          }
        },
        "CapacityReservationSpecification": {
          "locationName": "capacityReservationSpecification",
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {
              "locationName": "capacityReservationPreference"
            },
            "CapacityReservationTarget": {
              "shape": "Sbq",
              "locationName": "capacityReservationTarget"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "licenseSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {
                "locationName": "licenseConfigurationArn"
              }
            }
          }
        },
        "HibernationOptions": {
          "locationName": "hibernationOptions",
          "type": "structure",
          "members": {
            "Configured": {
              "locationName": "configured",
              "type": "boolean"
            }
          }
        },
        "MetadataOptions": {
          "locationName": "metadataOptions",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "HttpTokens": {
              "locationName": "httpTokens"
            },
            "HttpPutResponseHopLimit": {
              "locationName": "httpPutResponseHopLimit",
              "type": "integer"
            },
            "HttpEndpoint": {
              "locationName": "httpEndpoint"
            }
          }
        }
      }
    },
    "Sbb": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv6Address": {
            "locationName": "ipv6Address"
          }
        }
      }
    },
    "Sbq": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        }
      }
    },
    "Sc0": {
      "type": "structure",
      "members": {
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "LocalGatewayVirtualInterfaceGroupId": {
          "locationName": "localGatewayVirtualInterfaceGroupId"
        },
        "Type": {
          "locationName": "type"
        },
        "State": {
          "locationName": "state"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        }
      }
    },
    "Sc5": {
      "type": "structure",
      "members": {
        "LocalGatewayRouteTableVpcAssociationId": {
          "locationName": "localGatewayRouteTableVpcAssociationId"
        },
        "LocalGatewayRouteTableId": {
          "locationName": "localGatewayRouteTableId"
        },
        "LocalGatewayId": {
          "locationName": "localGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sc9": {
      "type": "structure",
      "members": {
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "DeleteTime": {
          "locationName": "deleteTime",
          "type": "timestamp"
        },
        "FailureCode": {
          "locationName": "failureCode"
        },
        "FailureMessage": {
          "locationName": "failureMessage"
        },
        "NatGatewayAddresses": {
          "locationName": "natGatewayAddressSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AllocationId": {
                "locationName": "allocationId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "PrivateIp": {
                "locationName": "privateIp"
              },
              "PublicIp": {
                "locationName": "publicIp"
              }
            }
          }
        },
        "NatGatewayId": {
          "locationName": "natGatewayId"
        },
        "ProvisionedBandwidth": {
          "locationName": "provisionedBandwidth",
          "type": "structure",
          "members": {
            "ProvisionTime": {
              "locationName": "provisionTime",
              "type": "timestamp"
            },
            "Provisioned": {
              "locationName": "provisioned"
            },
            "RequestTime": {
              "locationName": "requestTime",
              "type": "timestamp"
            },
            "Requested": {
              "locationName": "requested"
            },
            "Status": {
              "locationName": "status"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Scg": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "NetworkAclAssociationId": {
                "locationName": "networkAclAssociationId"
              },
              "NetworkAclId": {
                "locationName": "networkAclId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "Entries": {
          "locationName": "entrySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              },
              "Egress": {
                "locationName": "egress",
                "type": "boolean"
              },
              "IcmpTypeCode": {
                "shape": "Scl",
                "locationName": "icmpTypeCode"
              },
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              },
              "PortRange": {
                "shape": "Scm",
                "locationName": "portRange"
              },
              "Protocol": {
                "locationName": "protocol"
              },
              "RuleAction": {
                "locationName": "ruleAction"
              },
              "RuleNumber": {
                "locationName": "ruleNumber",
                "type": "integer"
              }
            }
          }
        },
        "IsDefault": {
          "locationName": "default",
          "type": "boolean"
        },
        "NetworkAclId": {
          "locationName": "networkAclId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Scl": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Type": {
          "locationName": "type",
          "type": "integer"
        }
      }
    },
    "Scm": {
      "type": "structure",
      "members": {
        "From": {
          "locationName": "from",
          "type": "integer"
        },
        "To": {
          "locationName": "to",
          "type": "integer"
        }
      }
    },
    "Sct": {
      "type": "structure",
      "members": {
        "Association": {
          "shape": "Scu",
          "locationName": "association"
        },
        "Attachment": {
          "shape": "Scv",
          "locationName": "attachment"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Description": {
          "locationName": "description"
        },
        "Groups": {
          "shape": "Scw",
          "locationName": "groupSet"
        },
        "InterfaceType": {
          "locationName": "interfaceType"
        },
        "Ipv6Addresses": {
          "locationName": "ipv6AddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6Address": {
                "locationName": "ipv6Address"
              }
            }
          }
        },
        "MacAddress": {
          "locationName": "macAddress"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "PrivateIpAddress": {
          "locationName": "privateIpAddress"
        },
        "PrivateIpAddresses": {
          "locationName": "privateIpAddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Association": {
                "shape": "Scu",
                "locationName": "association"
              },
              "Primary": {
                "locationName": "primary",
                "type": "boolean"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              }
            }
          }
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "SourceDestCheck": {
          "locationName": "sourceDestCheck",
          "type": "boolean"
        },
        "Status": {
          "locationName": "status"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "TagSet": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        }
      }
    },
    "Scu": {
      "type": "structure",
      "members": {
        "AllocationId": {
          "locationName": "allocationId"
        },
        "AssociationId": {
          "locationName": "associationId"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        }
      }
    },
    "Scv": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "AttachmentId": {
          "locationName": "attachmentId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        },
        "DeviceIndex": {
          "locationName": "deviceIndex",
          "type": "integer"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "InstanceOwnerId": {
          "locationName": "instanceOwnerId"
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "Scw": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "GroupName": {
            "locationName": "groupName"
          },
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "Sd7": {
      "type": "structure",
      "members": {
        "NetworkInterfacePermissionId": {
          "locationName": "networkInterfacePermissionId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "AwsAccountId": {
          "locationName": "awsAccountId"
        },
        "AwsService": {
          "locationName": "awsService"
        },
        "Permission": {
          "locationName": "permission"
        },
        "PermissionState": {
          "locationName": "permissionState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        }
      }
    },
    "Sdd": {
      "type": "structure",
      "members": {
        "GroupName": {
          "locationName": "groupName"
        },
        "State": {
          "locationName": "state"
        },
        "Strategy": {
          "locationName": "strategy"
        },
        "PartitionCount": {
          "locationName": "partitionCount",
          "type": "integer"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sdq": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Main": {
                "locationName": "main",
                "type": "boolean"
              },
              "RouteTableAssociationId": {
                "locationName": "routeTableAssociationId"
              },
              "RouteTableId": {
                "locationName": "routeTableId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "AssociationState": {
                "shape": "S2r",
                "locationName": "associationState"
              }
            }
          }
        },
        "PropagatingVgws": {
          "locationName": "propagatingVgwSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GatewayId": {
                "locationName": "gatewayId"
              }
            }
          }
        },
        "RouteTableId": {
          "locationName": "routeTableId"
        },
        "Routes": {
          "locationName": "routeSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "DestinationIpv6CidrBlock": {
                "locationName": "destinationIpv6CidrBlock"
              },
              "DestinationPrefixListId": {
                "locationName": "destinationPrefixListId"
              },
              "EgressOnlyInternetGatewayId": {
                "locationName": "egressOnlyInternetGatewayId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceOwnerId": {
                "locationName": "instanceOwnerId"
              },
              "NatGatewayId": {
                "locationName": "natGatewayId"
              },
              "TransitGatewayId": {
                "locationName": "transitGatewayId"
              },
              "LocalGatewayId": {
                "locationName": "localGatewayId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "Origin": {
                "locationName": "origin"
              },
              "State": {
                "locationName": "state"
              },
              "VpcPeeringConnectionId": {
                "locationName": "vpcPeeringConnectionId"
              }
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Se2": {
      "type": "structure",
      "members": {
        "DataEncryptionKeyId": {
          "locationName": "dataEncryptionKeyId"
        },
        "Description": {
          "locationName": "description"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "status"
        },
        "StateMessage": {
          "locationName": "statusMessage"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "VolumeSize": {
          "locationName": "volumeSize",
          "type": "integer"
        },
        "OwnerAlias": {
          "locationName": "ownerAlias"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sed": {
      "type": "structure",
      "members": {
        "Bucket": {
          "locationName": "bucket"
        },
        "Fault": {
          "shape": "See",
          "locationName": "fault"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Prefix": {
          "locationName": "prefix"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "See": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sej": {
      "type": "list",
      "member": {}
    },
    "Sen": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "IngressFilterRules": {
          "shape": "Seo",
          "locationName": "ingressFilterRuleSet"
        },
        "EgressFilterRules": {
          "shape": "Seo",
          "locationName": "egressFilterRuleSet"
        },
        "NetworkServices": {
          "shape": "Set",
          "locationName": "networkServiceSet"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Seo": {
      "type": "list",
      "member": {
        "shape": "Sep",
        "locationName": "item"
      }
    },
    "Sep": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterRuleId": {
          "locationName": "trafficMirrorFilterRuleId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "TrafficDirection": {
          "locationName": "trafficDirection"
        },
        "RuleNumber": {
          "locationName": "ruleNumber",
          "type": "integer"
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "Protocol": {
          "locationName": "protocol",
          "type": "integer"
        },
        "DestinationPortRange": {
          "shape": "Ses",
          "locationName": "destinationPortRange"
        },
        "SourcePortRange": {
          "shape": "Ses",
          "locationName": "sourcePortRange"
        },
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "SourceCidrBlock": {
          "locationName": "sourceCidrBlock"
        },
        "Description": {
          "locationName": "description"
        }
      }
    },
    "Ses": {
      "type": "structure",
      "members": {
        "FromPort": {
          "locationName": "fromPort",
          "type": "integer"
        },
        "ToPort": {
          "locationName": "toPort",
          "type": "integer"
        }
      }
    },
    "Set": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sex": {
      "type": "structure",
      "members": {
        "FromPort": {
          "type": "integer"
        },
        "ToPort": {
          "type": "integer"
        }
      }
    },
    "Sf2": {
      "type": "structure",
      "members": {
        "TrafficMirrorSessionId": {
          "locationName": "trafficMirrorSessionId"
        },
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PacketLength": {
          "locationName": "packetLength",
          "type": "integer"
        },
        "SessionNumber": {
          "locationName": "sessionNumber",
          "type": "integer"
        },
        "VirtualNetworkId": {
          "locationName": "virtualNetworkId",
          "type": "integer"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sf5": {
      "type": "structure",
      "members": {
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "NetworkLoadBalancerArn": {
          "locationName": "networkLoadBalancerArn"
        },
        "Type": {
          "locationName": "type"
        },
        "Description": {
          "locationName": "description"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sff": {
      "type": "structure",
      "members": {
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "TransitGatewayArn": {
          "locationName": "transitGatewayArn"
        },
        "State": {
          "locationName": "state"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Description": {
          "locationName": "description"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "AmazonSideAsn": {
              "locationName": "amazonSideAsn",
              "type": "long"
            },
            "AutoAcceptSharedAttachments": {
              "locationName": "autoAcceptSharedAttachments"
            },
            "DefaultRouteTableAssociation": {
              "locationName": "defaultRouteTableAssociation"
            },
            "AssociationDefaultRouteTableId": {
              "locationName": "associationDefaultRouteTableId"
            },
            "DefaultRouteTablePropagation": {
              "locationName": "defaultRouteTablePropagation"
            },
            "PropagationDefaultRouteTableId": {
              "locationName": "propagationDefaultRouteTableId"
            },
            "VpnEcmpSupport": {
              "locationName": "vpnEcmpSupport"
            },
            "DnsSupport": {
              "locationName": "dnsSupport"
            },
            "MulticastSupport": {
              "locationName": "multicastSupport"
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sfk": {
      "type": "structure",
      "members": {
        "TransitGatewayMulticastDomainId": {
          "locationName": "transitGatewayMulticastDomainId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sfr": {
      "type": "structure",
      "members": {
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "TransitGatewayAttachments": {
          "locationName": "transitGatewayAttachments",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceId": {
                "locationName": "resourceId"
              },
              "TransitGatewayAttachmentId": {
                "locationName": "transitGatewayAttachmentId"
              },
              "ResourceType": {
                "locationName": "resourceType"
              }
            }
          }
        },
        "Type": {
          "locationName": "type"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sfy": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "DefaultAssociationRouteTable": {
          "locationName": "defaultAssociationRouteTable",
          "type": "boolean"
        },
        "DefaultPropagationRouteTable": {
          "locationName": "defaultPropagationRouteTable",
          "type": "boolean"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sg1": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sg5": {
      "type": "structure",
      "members": {
        "Attachments": {
          "locationName": "attachmentSet",
          "type": "list",
          "member": {
            "shape": "S3r",
            "locationName": "item"
          }
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "OutpostArn": {
          "locationName": "outpostArn"
        },
        "Size": {
          "locationName": "size",
          "type": "integer"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "Iops": {
          "locationName": "iops",
          "type": "integer"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VolumeType": {
          "locationName": "volumeType"
        },
        "FastRestored": {
          "locationName": "fastRestored",
          "type": "boolean"
        },
        "MultiAttachEnabled": {
          "locationName": "multiAttachEnabled",
          "type": "boolean"
        }
      }
    },
    "Sgc": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sgd": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sge": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sgg": {
      "type": "structure",
      "members": {
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "VpcEndpointType": {
          "locationName": "vpcEndpointType"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "State": {
          "locationName": "state"
        },
        "PolicyDocument": {
          "locationName": "policyDocument"
        },
        "RouteTableIds": {
          "shape": "So",
          "locationName": "routeTableIdSet"
        },
        "SubnetIds": {
          "shape": "So",
          "locationName": "subnetIdSet"
        },
        "Groups": {
          "locationName": "groupSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GroupId": {
                "locationName": "groupId"
              },
              "GroupName": {
                "locationName": "groupName"
              }
            }
          }
        },
        "PrivateDnsEnabled": {
          "locationName": "privateDnsEnabled",
          "type": "boolean"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "NetworkInterfaceIds": {
          "shape": "So",
          "locationName": "networkInterfaceIdSet"
        },
        "DnsEntries": {
          "shape": "Sgk",
          "locationName": "dnsEntrySet"
        },
        "CreationTimestamp": {
          "locationName": "creationTimestamp",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "LastError": {
          "locationName": "lastError",
          "type": "structure",
          "members": {
            "Message": {
              "locationName": "message"
            },
            "Code": {
              "locationName": "code"
            }
          }
        }
      }
    },
    "Sgk": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DnsName": {
            "locationName": "dnsName"
          },
          "HostedZoneId": {
            "locationName": "hostedZoneId"
          }
        }
      }
    },
    "Sgp": {
      "type": "structure",
      "members": {
        "ConnectionNotificationId": {
          "locationName": "connectionNotificationId"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "ConnectionNotificationType": {
          "locationName": "connectionNotificationType"
        },
        "ConnectionNotificationArn": {
          "locationName": "connectionNotificationArn"
        },
        "ConnectionEvents": {
          "shape": "So",
          "locationName": "connectionEvents"
        },
        "ConnectionNotificationState": {
          "locationName": "connectionNotificationState"
        }
      }
    },
    "Sgu": {
      "type": "structure",
      "members": {
        "ServiceType": {
          "shape": "Sgv",
          "locationName": "serviceType"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "ServiceState": {
          "locationName": "serviceState"
        },
        "AvailabilityZones": {
          "shape": "So",
          "locationName": "availabilityZoneSet"
        },
        "AcceptanceRequired": {
          "locationName": "acceptanceRequired",
          "type": "boolean"
        },
        "ManagesVpcEndpoints": {
          "locationName": "managesVpcEndpoints",
          "type": "boolean"
        },
        "NetworkLoadBalancerArns": {
          "shape": "So",
          "locationName": "networkLoadBalancerArnSet"
        },
        "BaseEndpointDnsNames": {
          "shape": "So",
          "locationName": "baseEndpointDnsNameSet"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "PrivateDnsNameConfiguration": {
          "locationName": "privateDnsNameConfiguration",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "Type": {
              "locationName": "type"
            },
            "Value": {
              "locationName": "value"
            },
            "Name": {
              "locationName": "name"
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sgv": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ServiceType": {
            "locationName": "serviceType"
          }
        }
      }
    },
    "Sh8": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Sha": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Shc": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "She": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Shg": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {
            "type": "integer"
          }
        }
      }
    },
    "Shi": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {
            "type": "integer"
          }
        }
      }
    },
    "Shk": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Shn": {
      "type": "structure",
      "members": {
        "CustomerGatewayConfiguration": {
          "locationName": "customerGatewayConfiguration"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "Category": {
          "locationName": "category"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpnConnectionId": {
          "locationName": "vpnConnectionId"
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "EnableAcceleration": {
              "locationName": "enableAcceleration",
              "type": "boolean"
            },
            "StaticRoutesOnly": {
              "locationName": "staticRoutesOnly",
              "type": "boolean"
            },
            "TunnelOptions": {
              "locationName": "tunnelOptionSet",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "OutsideIpAddress": {
                    "locationName": "outsideIpAddress"
                  },
                  "TunnelInsideCidr": {
                    "locationName": "tunnelInsideCidr"
                  },
                  "PreSharedKey": {
                    "locationName": "preSharedKey"
                  },
                  "Phase1LifetimeSeconds": {
                    "locationName": "phase1LifetimeSeconds",
                    "type": "integer"
                  },
                  "Phase2LifetimeSeconds": {
                    "locationName": "phase2LifetimeSeconds",
                    "type": "integer"
                  },
                  "RekeyMarginTimeSeconds": {
                    "locationName": "rekeyMarginTimeSeconds",
                    "type": "integer"
                  },
                  "RekeyFuzzPercentage": {
                    "locationName": "rekeyFuzzPercentage",
                    "type": "integer"
                  },
                  "ReplayWindowSize": {
                    "locationName": "replayWindowSize",
                    "type": "integer"
                  },
                  "DpdTimeoutSeconds": {
                    "locationName": "dpdTimeoutSeconds",
                    "type": "integer"
                  },
                  "Phase1EncryptionAlgorithms": {
                    "locationName": "phase1EncryptionAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase2EncryptionAlgorithms": {
                    "locationName": "phase2EncryptionAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase1IntegrityAlgorithms": {
                    "locationName": "phase1IntegrityAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase2IntegrityAlgorithms": {
                    "locationName": "phase2IntegrityAlgorithmSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  },
                  "Phase1DHGroupNumbers": {
                    "locationName": "phase1DHGroupNumberSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value",
                          "type": "integer"
                        }
                      }
                    }
                  },
                  "Phase2DHGroupNumbers": {
                    "locationName": "phase2DHGroupNumberSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value",
                          "type": "integer"
                        }
                      }
                    }
                  },
                  "IkeVersions": {
                    "locationName": "ikeVersionSet",
                    "type": "list",
                    "member": {
                      "locationName": "item",
                      "type": "structure",
                      "members": {
                        "Value": {
                          "locationName": "value"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Routes": {
          "locationName": "routes",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "Source": {
                "locationName": "source"
              },
              "State": {
                "locationName": "state"
              }
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VgwTelemetry": {
          "locationName": "vgwTelemetry",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AcceptedRouteCount": {
                "locationName": "acceptedRouteCount",
                "type": "integer"
              },
              "LastStatusChange": {
                "locationName": "lastStatusChange",
                "type": "timestamp"
              },
              "OutsideIpAddress": {
                "locationName": "outsideIpAddress"
              },
              "Status": {
                "locationName": "status"
              },
              "StatusMessage": {
                "locationName": "statusMessage"
              },
              "CertificateArn": {
                "locationName": "certificateArn"
              }
            }
          }
        }
      }
    },
    "Sig": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpcAttachments": {
          "locationName": "attachments",
          "type": "list",
          "member": {
            "shape": "S3w",
            "locationName": "item"
          }
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "AmazonSideAsn": {
          "locationName": "amazonSideAsn",
          "type": "long"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Sis": {
      "type": "list",
      "member": {}
    },
    "Sj2": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sjd": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sl5": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Slj": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sll": {
      "type": "structure",
      "members": {
        "InstanceTagKeys": {
          "shape": "Slj",
          "locationName": "instanceTagKeySet"
        },
        "IncludeAllTagsOfInstance": {
          "locationName": "includeAllTagsOfInstance",
          "type": "boolean"
        }
      }
    },
    "Sln": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sm2": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "shape": "So",
            "locationName": "Value"
          }
        }
      }
    },
    "Smb": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Deadline": {
            "locationName": "deadline",
            "type": "timestamp"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "Sn2": {
      "type": "list",
      "member": {
        "locationName": "InstanceId"
      }
    },
    "Snh": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Soj": {
      "type": "structure",
      "members": {
        "ConversionTaskId": {
          "locationName": "conversionTaskId"
        },
        "ExpirationTime": {
          "locationName": "expirationTime"
        },
        "ImportInstance": {
          "locationName": "importInstance",
          "type": "structure",
          "members": {
            "Description": {
              "locationName": "description"
            },
            "InstanceId": {
              "locationName": "instanceId"
            },
            "Platform": {
              "locationName": "platform"
            },
            "Volumes": {
              "locationName": "volumes",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "AvailabilityZone": {
                    "locationName": "availabilityZone"
                  },
                  "BytesConverted": {
                    "locationName": "bytesConverted",
                    "type": "long"
                  },
                  "Description": {
                    "locationName": "description"
                  },
                  "Image": {
                    "shape": "Son",
                    "locationName": "image"
                  },
                  "Status": {
                    "locationName": "status"
                  },
                  "StatusMessage": {
                    "locationName": "statusMessage"
                  },
                  "Volume": {
                    "shape": "Soo",
                    "locationName": "volume"
                  }
                }
              }
            }
          }
        },
        "ImportVolume": {
          "locationName": "importVolume",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "BytesConverted": {
              "locationName": "bytesConverted",
              "type": "long"
            },
            "Description": {
              "locationName": "description"
            },
            "Image": {
              "shape": "Son",
              "locationName": "image"
            },
            "Volume": {
              "shape": "Soo",
              "locationName": "volume"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "Son": {
      "type": "structure",
      "members": {
        "Checksum": {
          "locationName": "checksum"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "Soo": {
      "type": "structure",
      "members": {
        "Id": {
          "locationName": "id"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "Spm": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Prefix": {
          "locationName": "s3Prefix"
        }
      }
    },
    "Sq3": {
      "type": "structure",
      "members": {
        "EventDescription": {
          "locationName": "eventDescription"
        },
        "EventSubType": {
          "locationName": "eventSubType"
        },
        "InstanceId": {
          "locationName": "instanceId"
        }
      }
    },
    "Sq6": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "InstanceHealth": {
            "locationName": "instanceHealth"
          }
        }
      }
    },
    "Sqw": {
      "type": "structure",
      "members": {
        "FpgaImageId": {
          "locationName": "fpgaImageId"
        },
        "Name": {
          "locationName": "name"
        },
        "Description": {
          "locationName": "description"
        },
        "LoadPermissions": {
          "locationName": "loadPermissions",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "UserId": {
                "locationName": "userId"
              },
              "Group": {
                "locationName": "group"
              }
            }
          }
        },
        "ProductCodes": {
          "shape": "Sr0",
          "locationName": "productCodes"
        }
      }
    },
    "Sr0": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ProductCodeId": {
            "locationName": "productCode"
          },
          "ProductCodeType": {
            "locationName": "type"
          }
        }
      }
    },
    "Sr5": {
      "type": "list",
      "member": {
        "locationName": "Owner"
      }
    },
    "Srq": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Srt": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Ssi": {
      "type": "list",
      "member": {
        "shape": "S90",
        "locationName": "item"
      }
    },
    "Ssj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          }
        }
      }
    },
    "Ssw": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "St4": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DeviceName": {
            "locationName": "deviceName"
          },
          "DiskImageSize": {
            "locationName": "diskImageSize",
            "type": "double"
          },
          "Format": {
            "locationName": "format"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Url": {
            "locationName": "url"
          },
          "UserBucket": {
            "shape": "St6",
            "locationName": "userBucket"
          }
        }
      }
    },
    "St6": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Key": {
          "locationName": "s3Key"
        }
      }
    },
    "St7": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "LicenseConfigurationArn": {
            "locationName": "licenseConfigurationArn"
          }
        }
      }
    },
    "Stf": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "DiskImageSize": {
          "locationName": "diskImageSize",
          "type": "double"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "Format": {
          "locationName": "format"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Url": {
          "locationName": "url"
        },
        "UserBucket": {
          "shape": "St6",
          "locationName": "userBucket"
        }
      }
    },
    "Stj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DeviceName": {
            "locationName": "deviceName"
          },
          "Ebs": {
            "locationName": "ebs",
            "type": "structure",
            "members": {
              "AttachTime": {
                "locationName": "attachTime",
                "type": "timestamp"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Status": {
                "locationName": "status"
              },
              "VolumeId": {
                "locationName": "volumeId"
              }
            }
          }
        }
      }
    },
    "Stm": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value",
          "type": "boolean"
        }
      }
    },
    "Stz": {
      "type": "structure",
      "members": {
        "InstanceEventId": {
          "locationName": "instanceEventId"
        },
        "Code": {
          "locationName": "code"
        },
        "Description": {
          "locationName": "description"
        },
        "NotAfter": {
          "locationName": "notAfter",
          "type": "timestamp"
        },
        "NotBefore": {
          "locationName": "notBefore",
          "type": "timestamp"
        },
        "NotBeforeDeadline": {
          "locationName": "notBeforeDeadline",
          "type": "timestamp"
        }
      }
    },
    "Su2": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "Su4": {
      "type": "structure",
      "members": {
        "Details": {
          "locationName": "details",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ImpairedSince": {
                "locationName": "impairedSince",
                "type": "timestamp"
              },
              "Name": {
                "locationName": "name"
              },
              "Status": {
                "locationName": "status"
              }
            }
          }
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "Sww": {
      "type": "structure",
      "members": {
        "Groups": {
          "shape": "Scw",
          "locationName": "groupSet"
        },
        "Instances": {
          "locationName": "instancesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AmiLaunchIndex": {
                "locationName": "amiLaunchIndex",
                "type": "integer"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "LaunchTime": {
                "locationName": "launchTime",
                "type": "timestamp"
              },
              "Monitoring": {
                "shape": "Swz",
                "locationName": "monitoring"
              },
              "Placement": {
                "shape": "S87",
                "locationName": "placement"
              },
              "Platform": {
                "locationName": "platform"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "ProductCodes": {
                "shape": "Sr0",
                "locationName": "productCodes"
              },
              "PublicDnsName": {
                "locationName": "dnsName"
              },
              "PublicIpAddress": {
                "locationName": "ipAddress"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "State": {
                "shape": "Su2",
                "locationName": "instanceState"
              },
              "StateTransitionReason": {
                "locationName": "reason"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "VpcId": {
                "locationName": "vpcId"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "BlockDeviceMappings": {
                "shape": "Stj",
                "locationName": "blockDeviceMapping"
              },
              "ClientToken": {
                "locationName": "clientToken"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "EnaSupport": {
                "locationName": "enaSupport",
                "type": "boolean"
              },
              "Hypervisor": {
                "locationName": "hypervisor"
              },
              "IamInstanceProfile": {
                "shape": "S2l",
                "locationName": "iamInstanceProfile"
              },
              "InstanceLifecycle": {
                "locationName": "instanceLifecycle"
              },
              "ElasticGpuAssociations": {
                "locationName": "elasticGpuAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticGpuId": {
                      "locationName": "elasticGpuId"
                    },
                    "ElasticGpuAssociationId": {
                      "locationName": "elasticGpuAssociationId"
                    },
                    "ElasticGpuAssociationState": {
                      "locationName": "elasticGpuAssociationState"
                    },
                    "ElasticGpuAssociationTime": {
                      "locationName": "elasticGpuAssociationTime"
                    }
                  }
                }
              },
              "ElasticInferenceAcceleratorAssociations": {
                "locationName": "elasticInferenceAcceleratorAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticInferenceAcceleratorArn": {
                      "locationName": "elasticInferenceAcceleratorArn"
                    },
                    "ElasticInferenceAcceleratorAssociationId": {
                      "locationName": "elasticInferenceAcceleratorAssociationId"
                    },
                    "ElasticInferenceAcceleratorAssociationState": {
                      "locationName": "elasticInferenceAcceleratorAssociationState"
                    },
                    "ElasticInferenceAcceleratorAssociationTime": {
                      "locationName": "elasticInferenceAcceleratorAssociationTime",
                      "type": "timestamp"
                    }
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "networkInterfaceSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "Association": {
                      "shape": "Sx8",
                      "locationName": "association"
                    },
                    "Attachment": {
                      "locationName": "attachment",
                      "type": "structure",
                      "members": {
                        "AttachTime": {
                          "locationName": "attachTime",
                          "type": "timestamp"
                        },
                        "AttachmentId": {
                          "locationName": "attachmentId"
                        },
                        "DeleteOnTermination": {
                          "locationName": "deleteOnTermination",
                          "type": "boolean"
                        },
                        "DeviceIndex": {
                          "locationName": "deviceIndex",
                          "type": "integer"
                        },
                        "Status": {
                          "locationName": "status"
                        }
                      }
                    },
                    "Description": {
                      "locationName": "description"
                    },
                    "Groups": {
                      "shape": "Scw",
                      "locationName": "groupSet"
                    },
                    "Ipv6Addresses": {
                      "shape": "Sbb",
                      "locationName": "ipv6AddressesSet"
                    },
                    "MacAddress": {
                      "locationName": "macAddress"
                    },
                    "NetworkInterfaceId": {
                      "locationName": "networkInterfaceId"
                    },
                    "OwnerId": {
                      "locationName": "ownerId"
                    },
                    "PrivateDnsName": {
                      "locationName": "privateDnsName"
                    },
                    "PrivateIpAddress": {
                      "locationName": "privateIpAddress"
                    },
                    "PrivateIpAddresses": {
                      "locationName": "privateIpAddressesSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Association": {
                            "shape": "Sx8",
                            "locationName": "association"
                          },
                          "Primary": {
                            "locationName": "primary",
                            "type": "boolean"
                          },
                          "PrivateDnsName": {
                            "locationName": "privateDnsName"
                          },
                          "PrivateIpAddress": {
                            "locationName": "privateIpAddress"
                          }
                        }
                      }
                    },
                    "SourceDestCheck": {
                      "locationName": "sourceDestCheck",
                      "type": "boolean"
                    },
                    "Status": {
                      "locationName": "status"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "VpcId": {
                      "locationName": "vpcId"
                    },
                    "InterfaceType": {
                      "locationName": "interfaceType"
                    }
                  }
                }
              },
              "OutpostArn": {
                "locationName": "outpostArn"
              },
              "RootDeviceName": {
                "locationName": "rootDeviceName"
              },
              "RootDeviceType": {
                "locationName": "rootDeviceType"
              },
              "SecurityGroups": {
                "shape": "Scw",
                "locationName": "groupSet"
              },
              "SourceDestCheck": {
                "locationName": "sourceDestCheck",
                "type": "boolean"
              },
              "SpotInstanceRequestId": {
                "locationName": "spotInstanceRequestId"
              },
              "SriovNetSupport": {
                "locationName": "sriovNetSupport"
              },
              "StateReason": {
                "shape": "Ssw",
                "locationName": "stateReason"
              },
              "Tags": {
                "shape": "Sj",
                "locationName": "tagSet"
              },
              "VirtualizationType": {
                "locationName": "virtualizationType"
              },
              "CpuOptions": {
                "locationName": "cpuOptions",
                "type": "structure",
                "members": {
                  "CoreCount": {
                    "locationName": "coreCount",
                    "type": "integer"
                  },
                  "ThreadsPerCore": {
                    "locationName": "threadsPerCore",
                    "type": "integer"
                  }
                }
              },
              "CapacityReservationId": {
                "locationName": "capacityReservationId"
              },
              "CapacityReservationSpecification": {
                "locationName": "capacityReservationSpecification",
                "type": "structure",
                "members": {
                  "CapacityReservationPreference": {
                    "locationName": "capacityReservationPreference"
                  },
                  "CapacityReservationTarget": {
                    "shape": "Sbq",
                    "locationName": "capacityReservationTarget"
                  }
                }
              },
              "HibernationOptions": {
                "locationName": "hibernationOptions",
                "type": "structure",
                "members": {
                  "Configured": {
                    "locationName": "configured",
                    "type": "boolean"
                  }
                }
              },
              "Licenses": {
                "locationName": "licenseSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "LicenseConfigurationArn": {
                      "locationName": "licenseConfigurationArn"
                    }
                  }
                }
              },
              "MetadataOptions": {
                "shape": "Sxh",
                "locationName": "metadataOptions"
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "ReservationId": {
          "locationName": "reservationId"
        }
      }
    },
    "Swz": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sx8": {
      "type": "structure",
      "members": {
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        }
      }
    },
    "Sxh": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "HttpTokens": {
          "locationName": "httpTokens"
        },
        "HttpPutResponseHopLimit": {
          "locationName": "httpPutResponseHopLimit",
          "type": "integer"
        },
        "HttpEndpoint": {
          "locationName": "httpEndpoint"
        }
      }
    },
    "Syy": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S110": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstancesId"
      }
    },
    "S118": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Amount": {
            "locationName": "amount",
            "type": "double"
          },
          "Frequency": {
            "locationName": "frequency"
          }
        }
      }
    },
    "S11m": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "Platform": {
          "locationName": "platform"
        },
        "Scope": {
          "locationName": "scope"
        }
      }
    },
    "S129": {
      "type": "structure",
      "members": {
        "Frequency": {
          "locationName": "frequency"
        },
        "Interval": {
          "locationName": "interval",
          "type": "integer"
        },
        "OccurrenceDaySet": {
          "locationName": "occurrenceDaySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "integer"
          }
        },
        "OccurrenceRelativeToEnd": {
          "locationName": "occurrenceRelativeToEnd",
          "type": "boolean"
        },
        "OccurrenceUnit": {
          "locationName": "occurrenceUnit"
        }
      }
    },
    "S12h": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "NetworkPlatform": {
          "locationName": "networkPlatform"
        },
        "NextSlotStartTime": {
          "locationName": "nextSlotStartTime",
          "type": "timestamp"
        },
        "Platform": {
          "locationName": "platform"
        },
        "PreviousSlotEndTime": {
          "locationName": "previousSlotEndTime",
          "type": "timestamp"
        },
        "Recurrence": {
          "shape": "S129",
          "locationName": "recurrence"
        },
        "ScheduledInstanceId": {
          "locationName": "scheduledInstanceId"
        },
        "SlotDurationInHours": {
          "locationName": "slotDurationInHours",
          "type": "integer"
        },
        "TermEndDate": {
          "locationName": "termEndDate",
          "type": "timestamp"
        },
        "TermStartDate": {
          "locationName": "termStartDate",
          "type": "timestamp"
        },
        "TotalScheduledInstanceHours": {
          "locationName": "totalScheduledInstanceHours",
          "type": "integer"
        }
      }
    },
    "S12o": {
      "type": "list",
      "member": {
        "locationName": "GroupName"
      }
    },
    "S12w": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          }
        }
      }
    },
    "S130": {
      "type": "list",
      "member": {
        "locationName": "SnapshotId"
      }
    },
    "S13j": {
      "type": "structure",
      "required": [
        "IamFleetRole",
        "TargetCapacity"
      ],
      "members": {
        "AllocationStrategy": {
          "locationName": "allocationStrategy"
        },
        "OnDemandAllocationStrategy": {
          "locationName": "onDemandAllocationStrategy"
        },
        "ClientToken": {
          "locationName": "clientToken"
        },
        "ExcessCapacityTerminationPolicy": {
          "locationName": "excessCapacityTerminationPolicy"
        },
        "FulfilledCapacity": {
          "locationName": "fulfilledCapacity",
          "type": "double"
        },
        "OnDemandFulfilledCapacity": {
          "locationName": "onDemandFulfilledCapacity",
          "type": "double"
        },
        "IamFleetRole": {
          "locationName": "iamFleetRole"
        },
        "LaunchSpecifications": {
          "locationName": "launchSpecifications",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "SecurityGroups": {
                "shape": "Scw",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Ssi",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S2i",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "structure",
                "members": {
                  "Enabled": {
                    "locationName": "enabled",
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "shape": "S13q",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "S13s",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SpotPrice": {
                "locationName": "spotPrice"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData"
              },
              "WeightedCapacity": {
                "locationName": "weightedCapacity",
                "type": "double"
              },
              "TagSpecifications": {
                "locationName": "tagSpecificationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ResourceType": {
                      "locationName": "resourceType"
                    },
                    "Tags": {
                      "shape": "Sj",
                      "locationName": "tag"
                    }
                  }
                }
              }
            }
          }
        },
        "LaunchTemplateConfigs": {
          "locationName": "launchTemplateConfigs",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LaunchTemplateSpecification": {
                "shape": "S8g",
                "locationName": "launchTemplateSpecification"
              },
              "Overrides": {
                "locationName": "overrides",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "InstanceType": {
                      "locationName": "instanceType"
                    },
                    "SpotPrice": {
                      "locationName": "spotPrice"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "AvailabilityZone": {
                      "locationName": "availabilityZone"
                    },
                    "WeightedCapacity": {
                      "locationName": "weightedCapacity",
                      "type": "double"
                    },
                    "Priority": {
                      "locationName": "priority",
                      "type": "double"
                    }
                  }
                }
              }
            }
          }
        },
        "SpotPrice": {
          "locationName": "spotPrice"
        },
        "TargetCapacity": {
          "locationName": "targetCapacity",
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "locationName": "onDemandTargetCapacity",
          "type": "integer"
        },
        "OnDemandMaxTotalPrice": {
          "locationName": "onDemandMaxTotalPrice"
        },
        "SpotMaxTotalPrice": {
          "locationName": "spotMaxTotalPrice"
        },
        "TerminateInstancesWithExpiration": {
          "locationName": "terminateInstancesWithExpiration",
          "type": "boolean"
        },
        "Type": {
          "locationName": "type"
        },
        "ValidFrom": {
          "locationName": "validFrom",
          "type": "timestamp"
        },
        "ValidUntil": {
          "locationName": "validUntil",
          "type": "timestamp"
        },
        "ReplaceUnhealthyInstances": {
          "locationName": "replaceUnhealthyInstances",
          "type": "boolean"
        },
        "InstanceInterruptionBehavior": {
          "locationName": "instanceInterruptionBehavior"
        },
        "LoadBalancersConfig": {
          "locationName": "loadBalancersConfig",
          "type": "structure",
          "members": {
            "ClassicLoadBalancersConfig": {
              "locationName": "classicLoadBalancersConfig",
              "type": "structure",
              "members": {
                "ClassicLoadBalancers": {
                  "locationName": "classicLoadBalancers",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Name": {
                        "locationName": "name"
                      }
                    }
                  }
                }
              }
            },
            "TargetGroupsConfig": {
              "locationName": "targetGroupsConfig",
              "type": "structure",
              "members": {
                "TargetGroups": {
                  "locationName": "targetGroups",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Arn": {
                        "locationName": "arn"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "InstancePoolsToUseCount": {
          "locationName": "instancePoolsToUseCount",
          "type": "integer"
        },
        "TagSpecifications": {
          "shape": "S1l",
          "locationName": "TagSpecification"
        }
      }
    },
    "S13q": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "AssociatePublicIpAddress": {
            "locationName": "associatePublicIpAddress",
            "type": "boolean"
          },
          "DeleteOnTermination": {
            "locationName": "deleteOnTermination",
            "type": "boolean"
          },
          "Description": {
            "locationName": "description"
          },
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "Groups": {
            "shape": "S9v",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "Sbb",
            "locationName": "ipv6AddressesSet",
            "queryName": "Ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "S9y",
            "locationName": "privateIpAddressesSet",
            "queryName": "PrivateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "InterfaceType": {}
        }
      }
    },
    "S13s": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "Tenancy": {
          "locationName": "tenancy"
        }
      }
    },
    "S148": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ActualBlockHourlyPrice": {
            "locationName": "actualBlockHourlyPrice"
          },
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "CreateTime": {
            "locationName": "createTime",
            "type": "timestamp"
          },
          "Fault": {
            "shape": "See",
            "locationName": "fault"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "UserData": {
                "locationName": "userData"
              },
              "SecurityGroups": {
                "shape": "Scw",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Ssi",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S2i",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "NetworkInterfaces": {
                "shape": "S13q",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "S13s",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "Monitoring": {
                "shape": "S14b",
                "locationName": "monitoring"
              }
            }
          },
          "LaunchedAvailabilityZone": {
            "locationName": "launchedAvailabilityZone"
          },
          "ProductDescription": {
            "locationName": "productDescription"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "State": {
            "locationName": "state"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              },
              "UpdateTime": {
                "locationName": "updateTime",
                "type": "timestamp"
              }
            }
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "InstanceInterruptionBehavior": {
            "locationName": "instanceInterruptionBehavior"
          }
        }
      }
    },
    "S14b": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "S14q": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S4c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "S15i": {
      "type": "list",
      "member": {}
    },
    "S16a": {
      "type": "list",
      "member": {
        "locationName": "VolumeId"
      }
    },
    "S16v": {
      "type": "structure",
      "members": {
        "VolumeId": {
          "locationName": "volumeId"
        },
        "ModificationState": {
          "locationName": "modificationState"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "TargetSize": {
          "locationName": "targetSize",
          "type": "integer"
        },
        "TargetIops": {
          "locationName": "targetIops",
          "type": "integer"
        },
        "TargetVolumeType": {
          "locationName": "targetVolumeType"
        },
        "OriginalSize": {
          "locationName": "originalSize",
          "type": "integer"
        },
        "OriginalIops": {
          "locationName": "originalIops",
          "type": "integer"
        },
        "OriginalVolumeType": {
          "locationName": "originalVolumeType"
        },
        "Progress": {
          "locationName": "progress",
          "type": "long"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "EndTime": {
          "locationName": "endTime",
          "type": "timestamp"
        }
      }
    },
    "S171": {
      "type": "list",
      "member": {
        "locationName": "VpcId"
      }
    },
    "S18q": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "S191": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S1b5": {
      "type": "structure",
      "members": {
        "InstanceFamily": {
          "locationName": "instanceFamily"
        },
        "CpuCredits": {
          "locationName": "cpuCredits"
        }
      }
    },
    "S1bb": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1bd": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Duration": {
            "locationName": "duration",
            "type": "integer"
          },
          "HostIdSet": {
            "shape": "Srq",
            "locationName": "hostIdSet"
          },
          "HostReservationId": {
            "locationName": "hostReservationId"
          },
          "HourlyPrice": {
            "locationName": "hourlyPrice"
          },
          "InstanceFamily": {
            "locationName": "instanceFamily"
          },
          "PaymentOption": {
            "locationName": "paymentOption"
          },
          "UpfrontPrice": {
            "locationName": "upfrontPrice"
          }
        }
      }
    },
    "S1bl": {
      "type": "structure",
      "members": {
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "RemainingTotalValue": {
          "locationName": "remainingTotalValue"
        },
        "RemainingUpfrontValue": {
          "locationName": "remainingUpfrontValue"
        }
      }
    },
    "S1ca": {
      "type": "structure",
      "members": {
        "Comment": {},
        "UploadEnd": {
          "type": "timestamp"
        },
        "UploadSize": {
          "type": "double"
        },
        "UploadStart": {
          "type": "timestamp"
        }
      }
    },
    "S1cd": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "S1ck": {
      "type": "structure",
      "required": [
        "Bytes",
        "Format",
        "ImportManifestUrl"
      ],
      "members": {
        "Bytes": {
          "locationName": "bytes",
          "type": "long"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        }
      }
    },
    "S1cl": {
      "type": "structure",
      "required": [
        "Size"
      ],
      "members": {
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "S1dc": {
      "type": "list",
      "member": {
        "locationName": "UserId"
      }
    },
    "S1dd": {
      "type": "list",
      "member": {
        "locationName": "UserGroup"
      }
    },
    "S1de": {
      "type": "list",
      "member": {
        "locationName": "ProductCode"
      }
    },
    "S1dg": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {},
          "UserId": {}
        }
      }
    },
    "S1dl": {
      "type": "list",
      "member": {
        "shape": "Sy",
        "locationName": "item"
      }
    },
    "S1dw": {
      "type": "structure",
      "members": {
        "CapacityReservationPreference": {},
        "CapacityReservationTarget": {
          "shape": "Sap"
        }
      }
    },
    "S1fi": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "type": "boolean"
        }
      }
    },
    "S1fk": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "locationName": "allowDnsResolutionFromRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
          "type": "boolean"
        }
      }
    },
    "S1fx": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Monitoring": {
            "shape": "Swz",
            "locationName": "monitoring"
          }
        }
      }
    },
    "S1ir": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S1jf": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrentState": {
            "shape": "Su2",
            "locationName": "currentState"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PreviousState": {
            "shape": "Su2",
            "locationName": "previousState"
          }
        }
      }
    }
  }
}
},{}],72:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeAccountAttributes": {
      "result_key": "AccountAttributes"
    },
    "DescribeAddresses": {
      "result_key": "Addresses"
    },
    "DescribeAvailabilityZones": {
      "result_key": "AvailabilityZones"
    },
    "DescribeBundleTasks": {
      "result_key": "BundleTasks"
    },
    "DescribeByoipCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ByoipCidrs"
    },
    "DescribeCapacityReservations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CapacityReservations"
    },
    "DescribeClassicLinkInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Instances"
    },
    "DescribeClientVpnAuthorizationRules": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AuthorizationRules"
    },
    "DescribeClientVpnConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Connections"
    },
    "DescribeClientVpnEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ClientVpnEndpoints"
    },
    "DescribeClientVpnRoutes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Routes"
    },
    "DescribeClientVpnTargetNetworks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ClientVpnTargetNetworks"
    },
    "DescribeCoipPools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CoipPools"
    },
    "DescribeConversionTasks": {
      "result_key": "ConversionTasks"
    },
    "DescribeCustomerGateways": {
      "result_key": "CustomerGateways"
    },
    "DescribeDhcpOptions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DhcpOptions"
    },
    "DescribeEgressOnlyInternetGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EgressOnlyInternetGateways"
    },
    "DescribeExportImageTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ExportImageTasks"
    },
    "DescribeExportTasks": {
      "result_key": "ExportTasks"
    },
    "DescribeFastSnapshotRestores": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FastSnapshotRestores"
    },
    "DescribeFleets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Fleets"
    },
    "DescribeFlowLogs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FlowLogs"
    },
    "DescribeFpgaImages": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "FpgaImages"
    },
    "DescribeHostReservationOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "OfferingSet"
    },
    "DescribeHostReservations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "HostReservationSet"
    },
    "DescribeHosts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Hosts"
    },
    "DescribeIamInstanceProfileAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "IamInstanceProfileAssociations"
    },
    "DescribeImages": {
      "result_key": "Images"
    },
    "DescribeImportImageTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ImportImageTasks"
    },
    "DescribeImportSnapshotTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ImportSnapshotTasks"
    },
    "DescribeInstanceCreditSpecifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceCreditSpecifications"
    },
    "DescribeInstanceStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceStatuses"
    },
    "DescribeInstanceTypeOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceTypeOfferings"
    },
    "DescribeInstanceTypes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceTypes"
    },
    "DescribeInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Reservations"
    },
    "DescribeInternetGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InternetGateways"
    },
    "DescribeIpv6Pools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Ipv6Pools"
    },
    "DescribeKeyPairs": {
      "result_key": "KeyPairs"
    },
    "DescribeLaunchTemplateVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LaunchTemplateVersions"
    },
    "DescribeLaunchTemplates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LaunchTemplates"
    },
    "DescribeLocalGatewayRouteTableVirtualInterfaceGroupAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTableVirtualInterfaceGroupAssociations"
    },
    "DescribeLocalGatewayRouteTableVpcAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTableVpcAssociations"
    },
    "DescribeLocalGatewayRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayRouteTables"
    },
    "DescribeLocalGatewayVirtualInterfaceGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayVirtualInterfaceGroups"
    },
    "DescribeLocalGatewayVirtualInterfaces": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGatewayVirtualInterfaces"
    },
    "DescribeLocalGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "LocalGateways"
    },
    "DescribeMovingAddresses": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MovingAddressStatuses"
    },
    "DescribeNatGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NatGateways"
    },
    "DescribeNetworkAcls": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkAcls"
    },
    "DescribeNetworkInterfacePermissions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInterfacePermissions"
    },
    "DescribeNetworkInterfaces": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "NetworkInterfaces"
    },
    "DescribePlacementGroups": {
      "result_key": "PlacementGroups"
    },
    "DescribePrefixLists": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PrefixLists"
    },
    "DescribePrincipalIdFormat": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Principals"
    },
    "DescribePublicIpv4Pools": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "PublicIpv4Pools"
    },
    "DescribeRegions": {
      "result_key": "Regions"
    },
    "DescribeReservedInstances": {
      "result_key": "ReservedInstances"
    },
    "DescribeReservedInstancesListings": {
      "result_key": "ReservedInstancesListings"
    },
    "DescribeReservedInstancesModifications": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesModifications"
    },
    "DescribeReservedInstancesOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesOfferings"
    },
    "DescribeRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "RouteTables"
    },
    "DescribeScheduledInstanceAvailability": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledInstanceAvailabilitySet"
    },
    "DescribeScheduledInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledInstanceSet"
    },
    "DescribeSecurityGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SecurityGroups"
    },
    "DescribeSnapshots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Snapshots"
    },
    "DescribeSpotFleetRequests": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotFleetRequestConfigs"
    },
    "DescribeSpotInstanceRequests": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotInstanceRequests"
    },
    "DescribeSpotPriceHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotPriceHistory"
    },
    "DescribeStaleSecurityGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StaleSecurityGroupSet"
    },
    "DescribeSubnets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Subnets"
    },
    "DescribeTags": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "DescribeTrafficMirrorFilters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorFilters"
    },
    "DescribeTrafficMirrorSessions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorSessions"
    },
    "DescribeTrafficMirrorTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TrafficMirrorTargets"
    },
    "DescribeTransitGatewayAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayAttachments"
    },
    "DescribeTransitGatewayMulticastDomains": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayMulticastDomains"
    },
    "DescribeTransitGatewayPeeringAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayPeeringAttachments"
    },
    "DescribeTransitGatewayRouteTables": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayRouteTables"
    },
    "DescribeTransitGatewayVpcAttachments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayVpcAttachments"
    },
    "DescribeTransitGateways": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGateways"
    },
    "DescribeVolumeStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VolumeStatuses"
    },
    "DescribeVolumes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Volumes"
    },
    "DescribeVolumesModifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VolumesModifications"
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Vpcs"
    },
    "DescribeVpcEndpointConnectionNotifications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ConnectionNotificationSet"
    },
    "DescribeVpcEndpointConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcEndpointConnections"
    },
    "DescribeVpcEndpointServiceConfigurations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ServiceConfigurations"
    },
    "DescribeVpcEndpointServicePermissions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AllowedPrincipals"
    },
    "DescribeVpcEndpoints": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcEndpoints"
    },
    "DescribeVpcPeeringConnections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VpcPeeringConnections"
    },
    "DescribeVpcs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Vpcs"
    },
    "DescribeVpnConnections": {
      "result_key": "VpnConnections"
    },
    "DescribeVpnGateways": {
      "result_key": "VpnGateways"
    },
    "GetAssociatedIpv6PoolCidrs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Ipv6CidrAssociations"
    },
    "GetTransitGatewayAttachmentPropagations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayAttachmentPropagations"
    },
    "GetTransitGatewayMulticastDomainAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MulticastDomainAssociations"
    },
    "GetTransitGatewayRouteTableAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "GetTransitGatewayRouteTablePropagations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "TransitGatewayRouteTablePropagations"
    },
    "SearchLocalGatewayRoutes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Routes"
    },
    "SearchTransitGatewayMulticastGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "MulticastGroups"
    }
  }
}
},{}],73:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "InstanceExists": {
      "delay": 5,
      "maxAttempts": 40,
      "operation": "DescribeInstances",
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(Reservations[]) > `0`",
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "InvalidInstanceID.NotFound",
          "state": "retry"
        }
      ]
    },
    "BundleTaskComplete": {
      "delay": 15,
      "operation": "DescribeBundleTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "complete",
          "matcher": "pathAll",
          "state": "success",
          "argument": "BundleTasks[].State"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "BundleTasks[].State"
        }
      ]
    },
    "ConversionTaskCancelled": {
      "delay": 15,
      "operation": "DescribeConversionTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "cancelled",
          "matcher": "pathAll",
          "state": "success",
          "argument": "ConversionTasks[].State"
        }
      ]
    },
    "ConversionTaskCompleted": {
      "delay": 15,
      "operation": "DescribeConversionTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "completed",
          "matcher": "pathAll",
          "state": "success",
          "argument": "ConversionTasks[].State"
        },
        {
          "expected": "cancelled",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "ConversionTasks[].State"
        },
        {
          "expected": "cancelling",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "ConversionTasks[].State"
        }
      ]
    },
    "ConversionTaskDeleted": {
      "delay": 15,
      "operation": "DescribeConversionTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "deleted",
          "matcher": "pathAll",
          "state": "success",
          "argument": "ConversionTasks[].State"
        }
      ]
    },
    "CustomerGatewayAvailable": {
      "delay": 15,
      "operation": "DescribeCustomerGateways",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "CustomerGateways[].State"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "CustomerGateways[].State"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "CustomerGateways[].State"
        }
      ]
    },
    "ExportTaskCancelled": {
      "delay": 15,
      "operation": "DescribeExportTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "cancelled",
          "matcher": "pathAll",
          "state": "success",
          "argument": "ExportTasks[].State"
        }
      ]
    },
    "ExportTaskCompleted": {
      "delay": 15,
      "operation": "DescribeExportTasks",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "completed",
          "matcher": "pathAll",
          "state": "success",
          "argument": "ExportTasks[].State"
        }
      ]
    },
    "ImageExists": {
      "operation": "DescribeImages",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(Images[]) > `0`",
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "InvalidAMIID.NotFound",
          "state": "retry"
        }
      ]
    },
    "ImageAvailable": {
      "operation": "DescribeImages",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "Images[].State",
          "expected": "available"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "Images[].State",
          "expected": "failed"
        }
      ]
    },
    "InstanceRunning": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "running",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "shutting-down",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "terminated",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "stopping",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "matcher": "error",
          "expected": "InvalidInstanceID.NotFound",
          "state": "retry"
        }
      ]
    },
    "InstanceStatusOk": {
      "operation": "DescribeInstanceStatus",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "InstanceStatuses[].InstanceStatus.Status",
          "expected": "ok"
        },
        {
          "matcher": "error",
          "expected": "InvalidInstanceID.NotFound",
          "state": "retry"
        }
      ]
    },
    "InstanceStopped": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "stopped",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "pending",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "terminated",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        }
      ]
    },
    "InstanceTerminated": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "terminated",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "pending",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        },
        {
          "expected": "stopping",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Reservations[].Instances[].State.Name"
        }
      ]
    },
    "KeyPairExists": {
      "operation": "DescribeKeyPairs",
      "delay": 5,
      "maxAttempts": 6,
      "acceptors": [
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(KeyPairs[].KeyName) > `0`"
        },
        {
          "expected": "InvalidKeyPair.NotFound",
          "matcher": "error",
          "state": "retry"
        }
      ]
    },
    "NatGatewayAvailable": {
      "operation": "DescribeNatGateways",
      "delay": 15,
      "maxAttempts": 40,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "NatGateways[].State",
          "expected": "available"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "NatGateways[].State",
          "expected": "failed"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "NatGateways[].State",
          "expected": "deleting"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "NatGateways[].State",
          "expected": "deleted"
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "NatGatewayNotFound"
        }
      ]
    },
    "NetworkInterfaceAvailable": {
      "operation": "DescribeNetworkInterfaces",
      "delay": 20,
      "maxAttempts": 10,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "NetworkInterfaces[].Status"
        },
        {
          "expected": "InvalidNetworkInterfaceID.NotFound",
          "matcher": "error",
          "state": "failure"
        }
      ]
    },
    "PasswordDataAvailable": {
      "operation": "GetPasswordData",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "length(PasswordData) > `0`",
          "expected": true
        }
      ]
    },
    "SnapshotCompleted": {
      "delay": 15,
      "operation": "DescribeSnapshots",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "completed",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Snapshots[].State"
        }
      ]
    },
    "SecurityGroupExists": {
      "operation": "DescribeSecurityGroups",
      "delay": 5,
      "maxAttempts": 6,
      "acceptors": [
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(SecurityGroups[].GroupId) > `0`"
        },
        {
          "expected": "InvalidGroupNotFound",
          "matcher": "error",
          "state": "retry"
        }
      ]
    },
    "SpotInstanceRequestFulfilled": {
      "operation": "DescribeSpotInstanceRequests",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "fulfilled"
        },
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "request-canceled-and-instance-running"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "schedule-expired"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "canceled-before-fulfillment"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "bad-parameters"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "SpotInstanceRequests[].Status.Code",
          "expected": "system-error"
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "InvalidSpotInstanceRequestID.NotFound"
        }
      ]
    },
    "SubnetAvailable": {
      "delay": 15,
      "operation": "DescribeSubnets",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Subnets[].State"
        }
      ]
    },
    "SystemStatusOk": {
      "operation": "DescribeInstanceStatus",
      "maxAttempts": 40,
      "delay": 15,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "InstanceStatuses[].SystemStatus.Status",
          "expected": "ok"
        }
      ]
    },
    "VolumeAvailable": {
      "delay": 15,
      "operation": "DescribeVolumes",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Volumes[].State"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Volumes[].State"
        }
      ]
    },
    "VolumeDeleted": {
      "delay": 15,
      "operation": "DescribeVolumes",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "deleted",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Volumes[].State"
        },
        {
          "matcher": "error",
          "expected": "InvalidVolume.NotFound",
          "state": "success"
        }
      ]
    },
    "VolumeInUse": {
      "delay": 15,
      "operation": "DescribeVolumes",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "in-use",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Volumes[].State"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Volumes[].State"
        }
      ]
    },
    "VpcAvailable": {
      "delay": 15,
      "operation": "DescribeVpcs",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Vpcs[].State"
        }
      ]
    },
    "VpcExists": {
      "operation": "DescribeVpcs",
      "delay": 1,
      "maxAttempts": 5,
      "acceptors": [
        {
          "matcher": "status",
          "expected": 200,
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "InvalidVpcID.NotFound",
          "state": "retry"
        }
      ]
    },
    "VpnConnectionAvailable": {
      "delay": 15,
      "operation": "DescribeVpnConnections",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "VpnConnections[].State"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "VpnConnections[].State"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "VpnConnections[].State"
        }
      ]
    },
    "VpnConnectionDeleted": {
      "delay": 15,
      "operation": "DescribeVpnConnections",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "deleted",
          "matcher": "pathAll",
          "state": "success",
          "argument": "VpnConnections[].State"
        },
        {
          "expected": "pending",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "VpnConnections[].State"
        }
      ]
    },
    "VpcPeeringConnectionExists": {
      "delay": 15,
      "operation": "DescribeVpcPeeringConnections",
      "maxAttempts": 40,
      "acceptors": [
        {
          "matcher": "status",
          "expected": 200,
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "InvalidVpcPeeringConnectionID.NotFound",
          "state": "retry"
        }
      ]
    },
    "VpcPeeringConnectionDeleted": {
      "delay": 15,
      "operation": "DescribeVpcPeeringConnections",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "deleted",
          "matcher": "pathAll",
          "state": "success",
          "argument": "VpcPeeringConnections[].Status.Code"
        },
        {
          "matcher": "error",
          "expected": "InvalidVpcPeeringConnectionID.NotFound",
          "state": "success"
        }
      ]
    }
  }
}

},{}],74:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-09-21",
    "endpointPrefix": "api.ecr",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon ECR",
    "serviceFullName": "Amazon EC2 Container Registry",
    "serviceId": "ECR",
    "signatureVersion": "v4",
    "signingName": "ecr",
    "targetPrefix": "AmazonEC2ContainerRegistry_V20150921",
    "uid": "ecr-2015-09-21"
  },
  "operations": {
    "BatchCheckLayerAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "layerDigests"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "layerDigests": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "layerDigest": {},
                "layerAvailability": {},
                "layerSize": {
                  "type": "long"
                },
                "mediaType": {}
              }
            }
          },
          "failures": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "layerDigest": {},
                "failureCode": {},
                "failureReason": {}
              }
            }
          }
        }
      }
    },
    "BatchDeleteImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageIds"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageIds": {
            "shape": "Si"
          },
          "failures": {
            "shape": "Sn"
          }
        }
      }
    },
    "BatchGetImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageIds"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          },
          "acceptedMediaTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "images": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "failures": {
            "shape": "Sn"
          }
        }
      }
    },
    "CompleteLayerUpload": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "uploadId",
          "layerDigests"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "layerDigests": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "layerDigest": {}
        }
      }
    },
    "CreateRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "tags": {
            "shape": "S12"
          },
          "imageTagMutability": {},
          "imageScanningConfiguration": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S1a"
          }
        }
      }
    },
    "DeleteLifecyclePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {},
          "lastEvaluatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DeleteRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S1a"
          }
        }
      }
    },
    "DeleteRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "DescribeImageScanFindings": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageId"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageId": {
            "shape": "Sj"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageId": {
            "shape": "Sj"
          },
          "imageScanStatus": {
            "shape": "S1s"
          },
          "imageScanFindings": {
            "type": "structure",
            "members": {
              "imageScanCompletedAt": {
                "type": "timestamp"
              },
              "vulnerabilitySourceUpdatedAt": {
                "type": "timestamp"
              },
              "findings": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "name": {},
                    "description": {},
                    "uri": {},
                    "severity": {},
                    "attributes": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "key"
                        ],
                        "members": {
                          "key": {},
                          "value": {}
                        }
                      }
                    }
                  }
                }
              },
              "findingSeverityCounts": {
                "shape": "S27"
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "tagStatus": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "registryId": {},
                "repositoryName": {},
                "imageDigest": {},
                "imageTags": {
                  "shape": "S2f"
                },
                "imageSizeInBytes": {
                  "type": "long"
                },
                "imagePushedAt": {
                  "type": "timestamp"
                },
                "imageScanStatus": {
                  "shape": "S1s"
                },
                "imageScanFindingsSummary": {
                  "type": "structure",
                  "members": {
                    "imageScanCompletedAt": {
                      "type": "timestamp"
                    },
                    "vulnerabilitySourceUpdatedAt": {
                      "type": "timestamp"
                    },
                    "findingSeverityCounts": {
                      "shape": "S27"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetAuthorizationToken": {
      "input": {
        "type": "structure",
        "members": {
          "registryIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizationData": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authorizationToken": {},
                "expiresAt": {
                  "type": "timestamp"
                },
                "proxyEndpoint": {}
              }
            }
          }
        }
      }
    },
    "GetDownloadUrlForLayer": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "layerDigest"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "layerDigest": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "downloadUrl": {},
          "layerDigest": {}
        }
      }
    },
    "GetLifecyclePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {},
          "lastEvaluatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetLifecyclePolicyPreview": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "tagStatus": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {},
          "status": {},
          "nextToken": {},
          "previewResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "imageTags": {
                  "shape": "S2f"
                },
                "imageDigest": {},
                "imagePushedAt": {
                  "type": "timestamp"
                },
                "action": {
                  "type": "structure",
                  "members": {
                    "type": {}
                  }
                },
                "appliedRulePriority": {
                  "type": "integer"
                }
              }
            }
          },
          "summary": {
            "type": "structure",
            "members": {
              "expiringImageTotalCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "GetRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "InitiateLayerUpload": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uploadId": {},
          "partSize": {
            "type": "long"
          }
        }
      }
    },
    "ListImages": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "tagStatus": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageIds": {
            "shape": "Si"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "PutImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageManifest"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageManifest": {},
          "imageManifestMediaType": {},
          "imageTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "image": {
            "shape": "Sv"
          }
        }
      }
    },
    "PutImageScanningConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageScanningConfiguration"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageScanningConfiguration": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageScanningConfiguration": {
            "shape": "S17"
          }
        }
      }
    },
    "PutImageTagMutability": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageTagMutability"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageTagMutability": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageTagMutability": {}
        }
      }
    },
    "PutLifecyclePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "lifecyclePolicyText"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {}
        }
      }
    },
    "SetRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "policyText"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "StartImageScan": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageId"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageId": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageId": {
            "shape": "Sj"
          },
          "imageScanStatus": {
            "shape": "S1s"
          }
        }
      }
    },
    "StartLifecyclePolicyPreview": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "lifecyclePolicyText": {},
          "status": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UploadLayerPart": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "uploadId",
          "partFirstByte",
          "partLastByte",
          "layerPartBlob"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "partFirstByte": {
            "type": "long"
          },
          "partLastByte": {
            "type": "long"
          },
          "layerPartBlob": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "lastByteReceived": {
            "type": "long"
          }
        }
      }
    }
  },
  "shapes": {
    "Si": {
      "type": "list",
      "member": {
        "shape": "Sj"
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "imageDigest": {},
        "imageTag": {}
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "imageId": {
            "shape": "Sj"
          },
          "failureCode": {},
          "failureReason": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "registryId": {},
        "repositoryName": {},
        "imageId": {
          "shape": "Sj"
        },
        "imageManifest": {},
        "imageManifestMediaType": {}
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "scanOnPush": {
          "type": "boolean"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "repositoryArn": {},
        "registryId": {},
        "repositoryName": {},
        "repositoryUri": {},
        "createdAt": {
          "type": "timestamp"
        },
        "imageTagMutability": {},
        "imageScanningConfiguration": {
          "shape": "S17"
        }
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "status": {},
        "description": {}
      }
    },
    "S27": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "S2f": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],75:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeImageScanFindings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "registryId",
        "repositoryName",
        "imageId",
        "imageScanStatus",
        "imageScanFindings"
      ],
      "output_token": "nextToken",
      "result_key": "imageScanFindings.findings"
    },
    "DescribeImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageDetails"
    },
    "DescribeRepositories": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "repositories"
    },
    "GetLifecyclePolicyPreview": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "non_aggregate_keys": [
        "registryId",
        "repositoryName",
        "lifecyclePolicyText",
        "status",
        "summary"
      ],
      "output_token": "nextToken",
      "result_key": "previewResults"
    },
    "ListImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageIds"
    }
  }
}
},{}],76:[function(require,module,exports){
module.exports={
    "version": 2,
    "waiters": {
        "ImageScanComplete": {
            "description": "Wait until an image scan is complete and findings can be accessed",
            "operation": "DescribeImageScanFindings",
            "delay": 5,
            "maxAttempts": 60,
            "acceptors": [
                {
                    "state": "success",
                    "matcher": "path",
                    "argument": "imageScanStatus.status",
                    "expected": "COMPLETE"
                },
                {
                    "state": "failure",
                    "matcher": "path",
                    "argument": "imageScanStatus.status",
                    "expected": "FAILED"
                }
            ]
        },
        "LifecyclePolicyPreviewComplete": {
            "description": "Wait until a lifecycle policy preview request is complete and results can be accessed",
            "operation": "GetLifecyclePolicyPreview",
            "delay": 5,
            "maxAttempts": 20,
            "acceptors": [
                {
                    "state": "success",
                    "matcher": "path",
                    "argument": "status",
                    "expected": "COMPLETE"
                },
                {
                    "state": "failure",
                    "matcher": "path",
                    "argument": "status",
                    "expected": "FAILED"
                }
            ]
        }
    }
}
},{}],77:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-13",
    "endpointPrefix": "ecs",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon ECS",
    "serviceFullName": "Amazon EC2 Container Service",
    "serviceId": "ECS",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonEC2ContainerServiceV20141113",
    "uid": "ecs-2014-11-13"
  },
  "operations": {
    "CreateCapacityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "autoScalingGroupProvider"
        ],
        "members": {
          "name": {},
          "autoScalingGroupProvider": {
            "shape": "S3"
          },
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProvider": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "members": {
          "clusterName": {},
          "tags": {
            "shape": "S9"
          },
          "settings": {
            "shape": "Si"
          },
          "capacityProviders": {
            "shape": "Sl"
          },
          "defaultCapacityProviderStrategy": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sr"
          }
        }
      }
    },
    "CreateService": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName"
        ],
        "members": {
          "cluster": {},
          "serviceName": {},
          "taskDefinition": {},
          "loadBalancers": {
            "shape": "Sz"
          },
          "serviceRegistries": {
            "shape": "S12"
          },
          "desiredCount": {
            "type": "integer"
          },
          "clientToken": {},
          "launchType": {},
          "capacityProviderStrategy": {
            "shape": "Sm"
          },
          "platformVersion": {},
          "role": {},
          "deploymentConfiguration": {
            "shape": "S15"
          },
          "placementConstraints": {
            "shape": "S16"
          },
          "placementStrategy": {
            "shape": "S19"
          },
          "networkConfiguration": {
            "shape": "S1c"
          },
          "healthCheckGracePeriodSeconds": {
            "type": "integer"
          },
          "schedulingStrategy": {},
          "deploymentController": {
            "shape": "S1g"
          },
          "tags": {
            "shape": "S9"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "propagateTags": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S1l"
          }
        }
      }
    },
    "CreateTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "service",
          "cluster",
          "taskDefinition"
        ],
        "members": {
          "service": {},
          "cluster": {},
          "externalId": {},
          "taskDefinition": {},
          "networkConfiguration": {
            "shape": "S1c"
          },
          "loadBalancers": {
            "shape": "Sz"
          },
          "serviceRegistries": {
            "shape": "S12"
          },
          "launchType": {},
          "capacityProviderStrategy": {
            "shape": "Sm"
          },
          "platformVersion": {},
          "scale": {
            "shape": "S1p"
          },
          "clientToken": {},
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S1n"
          }
        }
      }
    },
    "DeleteAccountSetting": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "principalArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S22"
          }
        }
      }
    },
    "DeleteAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S24"
          }
        }
      }
    },
    "DeleteCapacityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "capacityProvider"
        ],
        "members": {
          "capacityProvider": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProvider": {
            "shape": "Se"
          }
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sr"
          }
        }
      }
    },
    "DeleteService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S1l"
          }
        }
      }
    },
    "DeleteTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "taskSet"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSet": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S1n"
          }
        }
      }
    },
    "DeregisterContainerInstance": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S2j"
          }
        }
      }
    },
    "DeregisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S2r"
          }
        }
      }
    },
    "DescribeCapacityProviders": {
      "input": {
        "type": "structure",
        "members": {
          "capacityProviders": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProviders": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          },
          "failures": {
            "shape": "S4v"
          },
          "nextToken": {}
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "clusters": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "DescribeContainerInstances": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S56"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "DescribeServices": {
      "input": {
        "type": "structure",
        "required": [
          "services"
        ],
        "members": {
          "cluster": {},
          "services": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "services": {
            "type": "list",
            "member": {
              "shape": "S1l"
            }
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "DescribeTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {},
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S2r"
          },
          "tags": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeTaskSets": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSets": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSets": {
            "shape": "S1m"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "DescribeTasks": {
      "input": {
        "type": "structure",
        "required": [
          "tasks"
        ],
        "members": {
          "cluster": {},
          "tasks": {
            "shape": "Sl"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S5o"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "DiscoverPollEndpoint": {
      "input": {
        "type": "structure",
        "members": {
          "containerInstance": {},
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {},
          "telemetryEndpoint": {}
        }
      }
    },
    "ListAccountSettings": {
      "input": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {},
          "principalArn": {},
          "effectiveSettings": {
            "type": "boolean"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "settings": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "targetType"
        ],
        "members": {
          "cluster": {},
          "targetType": {},
          "attributeName": {},
          "attributeValue": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S24"
          },
          "nextToken": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusterArns": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "ListContainerInstances": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "filter": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstanceArns": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "launchType": {},
          "schedulingStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceArns": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S9"
          }
        }
      }
    },
    "ListTaskDefinitionFamilies": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "families": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "ListTaskDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "sort": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinitionArns": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "ListTasks": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "containerInstance": {},
          "family": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "startedBy": {},
          "serviceName": {},
          "desiredStatus": {},
          "launchType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskArns": {
            "shape": "Sl"
          },
          "nextToken": {}
        }
      }
    },
    "PutAccountSetting": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {},
          "principalArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S22"
          }
        }
      }
    },
    "PutAccountSettingDefault": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S22"
          }
        }
      }
    },
    "PutAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S24"
          }
        }
      }
    },
    "PutClusterCapacityProviders": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "capacityProviders",
          "defaultCapacityProviderStrategy"
        ],
        "members": {
          "cluster": {},
          "capacityProviders": {
            "shape": "Sl"
          },
          "defaultCapacityProviderStrategy": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sr"
          }
        }
      }
    },
    "RegisterContainerInstance": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "instanceIdentityDocument": {},
          "instanceIdentityDocumentSignature": {},
          "totalResources": {
            "shape": "S2m"
          },
          "versionInfo": {
            "shape": "S2l"
          },
          "containerInstanceArn": {},
          "attributes": {
            "shape": "S24"
          },
          "platformDevices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "type"
              ],
              "members": {
                "id": {},
                "type": {}
              }
            }
          },
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S2j"
          }
        }
      }
    },
    "RegisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "family",
          "containerDefinitions"
        ],
        "members": {
          "family": {},
          "taskRoleArn": {},
          "executionRoleArn": {},
          "networkMode": {},
          "containerDefinitions": {
            "shape": "S2s"
          },
          "volumes": {
            "shape": "S42"
          },
          "placementConstraints": {
            "shape": "S4e"
          },
          "requiresCompatibilities": {
            "shape": "S4h"
          },
          "cpu": {},
          "memory": {},
          "tags": {
            "shape": "S9"
          },
          "pidMode": {},
          "ipcMode": {},
          "proxyConfiguration": {
            "shape": "S4n"
          },
          "inferenceAccelerators": {
            "shape": "S4j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S2r"
          },
          "tags": {
            "shape": "S9"
          }
        }
      }
    },
    "RunTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "capacityProviderStrategy": {
            "shape": "Sm"
          },
          "cluster": {},
          "count": {
            "type": "integer"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "group": {},
          "launchType": {},
          "networkConfiguration": {
            "shape": "S1c"
          },
          "overrides": {
            "shape": "S5z"
          },
          "placementConstraints": {
            "shape": "S16"
          },
          "placementStrategy": {
            "shape": "S19"
          },
          "platformVersion": {},
          "propagateTags": {},
          "referenceId": {},
          "startedBy": {},
          "tags": {
            "shape": "S9"
          },
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S5o"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "StartTask": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances",
          "taskDefinition"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sl"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "group": {},
          "networkConfiguration": {
            "shape": "S1c"
          },
          "overrides": {
            "shape": "S5z"
          },
          "propagateTags": {},
          "referenceId": {},
          "startedBy": {},
          "tags": {
            "shape": "S9"
          },
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S5o"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "StopTask": {
      "input": {
        "type": "structure",
        "required": [
          "task"
        ],
        "members": {
          "cluster": {},
          "task": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "task": {
            "shape": "S5p"
          }
        }
      }
    },
    "SubmitAttachmentStateChanges": {
      "input": {
        "type": "structure",
        "required": [
          "attachments"
        ],
        "members": {
          "cluster": {},
          "attachments": {
            "shape": "S7g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "SubmitContainerStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "containerName": {},
          "runtimeId": {},
          "status": {},
          "exitCode": {
            "type": "integer"
          },
          "reason": {},
          "networkBindings": {
            "shape": "S5t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "SubmitTaskStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "status": {},
          "reason": {},
          "containers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "containerName": {},
                "imageDigest": {},
                "runtimeId": {},
                "exitCode": {
                  "type": "integer"
                },
                "networkBindings": {
                  "shape": "S5t"
                },
                "reason": {},
                "status": {}
              }
            }
          },
          "attachments": {
            "shape": "S7g"
          },
          "pullStartedAt": {
            "type": "timestamp"
          },
          "pullStoppedAt": {
            "type": "timestamp"
          },
          "executionStoppedAt": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateClusterSettings": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "settings"
        ],
        "members": {
          "cluster": {},
          "settings": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sr"
          }
        }
      }
    },
    "UpdateContainerAgent": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S2j"
          }
        }
      }
    },
    "UpdateContainerInstancesState": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances",
          "status"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sl"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S56"
          },
          "failures": {
            "shape": "S4v"
          }
        }
      }
    },
    "UpdateService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "desiredCount": {
            "type": "integer"
          },
          "taskDefinition": {},
          "capacityProviderStrategy": {
            "shape": "Sm"
          },
          "deploymentConfiguration": {
            "shape": "S15"
          },
          "networkConfiguration": {
            "shape": "S1c"
          },
          "placementConstraints": {
            "shape": "S16"
          },
          "placementStrategy": {
            "shape": "S19"
          },
          "platformVersion": {},
          "forceNewDeployment": {
            "type": "boolean"
          },
          "healthCheckGracePeriodSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S1l"
          }
        }
      }
    },
    "UpdateServicePrimaryTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "primaryTaskSet"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "primaryTaskSet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S1n"
          }
        }
      }
    },
    "UpdateTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "taskSet",
          "scale"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSet": {},
          "scale": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S1n"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "autoScalingGroupArn"
      ],
      "members": {
        "autoScalingGroupArn": {},
        "managedScaling": {
          "type": "structure",
          "members": {
            "status": {},
            "targetCapacity": {
              "type": "integer"
            },
            "minimumScalingStepSize": {
              "type": "integer"
            },
            "maximumScalingStepSize": {
              "type": "integer"
            }
          }
        },
        "managedTerminationProtection": {}
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "capacityProviderArn": {},
        "name": {},
        "status": {},
        "autoScalingGroupProvider": {
          "shape": "S3"
        },
        "updateStatus": {},
        "updateStatusReason": {},
        "tags": {
          "shape": "S9"
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "capacityProvider"
        ],
        "members": {
          "capacityProvider": {},
          "weight": {
            "type": "integer"
          },
          "base": {
            "type": "integer"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "clusterArn": {},
        "clusterName": {},
        "status": {},
        "registeredContainerInstancesCount": {
          "type": "integer"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "activeServicesCount": {
          "type": "integer"
        },
        "statistics": {
          "type": "list",
          "member": {
            "shape": "Su"
          }
        },
        "tags": {
          "shape": "S9"
        },
        "settings": {
          "shape": "Si"
        },
        "capacityProviders": {
          "shape": "Sl"
        },
        "defaultCapacityProviderStrategy": {
          "shape": "Sm"
        },
        "attachments": {
          "shape": "Sv"
        },
        "attachmentsStatus": {}
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "name": {},
        "value": {}
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "id": {},
          "type": {},
          "status": {},
          "details": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          }
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "targetGroupArn": {},
          "loadBalancerName": {},
          "containerName": {},
          "containerPort": {
            "type": "integer"
          }
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "registryArn": {},
          "port": {
            "type": "integer"
          },
          "containerName": {},
          "containerPort": {
            "type": "integer"
          }
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "maximumPercent": {
          "type": "integer"
        },
        "minimumHealthyPercent": {
          "type": "integer"
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "field": {}
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "awsvpcConfiguration": {
          "type": "structure",
          "required": [
            "subnets"
          ],
          "members": {
            "subnets": {
              "shape": "Sl"
            },
            "securityGroups": {
              "shape": "Sl"
            },
            "assignPublicIp": {}
          }
        }
      }
    },
    "S1g": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {}
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "serviceArn": {},
        "serviceName": {},
        "clusterArn": {},
        "loadBalancers": {
          "shape": "Sz"
        },
        "serviceRegistries": {
          "shape": "S12"
        },
        "status": {},
        "desiredCount": {
          "type": "integer"
        },
        "runningCount": {
          "type": "integer"
        },
        "pendingCount": {
          "type": "integer"
        },
        "launchType": {},
        "capacityProviderStrategy": {
          "shape": "Sm"
        },
        "platformVersion": {},
        "taskDefinition": {},
        "deploymentConfiguration": {
          "shape": "S15"
        },
        "taskSets": {
          "shape": "S1m"
        },
        "deployments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "status": {},
              "taskDefinition": {},
              "desiredCount": {
                "type": "integer"
              },
              "pendingCount": {
                "type": "integer"
              },
              "runningCount": {
                "type": "integer"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "updatedAt": {
                "type": "timestamp"
              },
              "capacityProviderStrategy": {
                "shape": "Sm"
              },
              "launchType": {},
              "platformVersion": {},
              "networkConfiguration": {
                "shape": "S1c"
              }
            }
          }
        },
        "roleArn": {},
        "events": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "createdAt": {
                "type": "timestamp"
              },
              "message": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "placementConstraints": {
          "shape": "S16"
        },
        "placementStrategy": {
          "shape": "S19"
        },
        "networkConfiguration": {
          "shape": "S1c"
        },
        "healthCheckGracePeriodSeconds": {
          "type": "integer"
        },
        "schedulingStrategy": {},
        "deploymentController": {
          "shape": "S1g"
        },
        "tags": {
          "shape": "S9"
        },
        "createdBy": {},
        "enableECSManagedTags": {
          "type": "boolean"
        },
        "propagateTags": {}
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "shape": "S1n"
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "id": {},
        "taskSetArn": {},
        "serviceArn": {},
        "clusterArn": {},
        "startedBy": {},
        "externalId": {},
        "status": {},
        "taskDefinition": {},
        "computedDesiredCount": {
          "type": "integer"
        },
        "pendingCount": {
          "type": "integer"
        },
        "runningCount": {
          "type": "integer"
        },
        "createdAt": {
          "type": "timestamp"
        },
        "updatedAt": {
          "type": "timestamp"
        },
        "launchType": {},
        "capacityProviderStrategy": {
          "shape": "Sm"
        },
        "platformVersion": {},
        "networkConfiguration": {
          "shape": "S1c"
        },
        "loadBalancers": {
          "shape": "Sz"
        },
        "serviceRegistries": {
          "shape": "S12"
        },
        "scale": {
          "shape": "S1p"
        },
        "stabilityStatus": {},
        "stabilityStatusAt": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S9"
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "value": {
          "type": "double"
        },
        "unit": {}
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "name": {},
        "value": {},
        "principalArn": {}
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "shape": "S25"
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "value": {},
        "targetType": {},
        "targetId": {}
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "containerInstanceArn": {},
        "ec2InstanceId": {},
        "capacityProviderName": {},
        "version": {
          "type": "long"
        },
        "versionInfo": {
          "shape": "S2l"
        },
        "remainingResources": {
          "shape": "S2m"
        },
        "registeredResources": {
          "shape": "S2m"
        },
        "status": {},
        "statusReason": {},
        "agentConnected": {
          "type": "boolean"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "agentUpdateStatus": {},
        "attributes": {
          "shape": "S24"
        },
        "registeredAt": {
          "type": "timestamp"
        },
        "attachments": {
          "shape": "Sv"
        },
        "tags": {
          "shape": "S9"
        }
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "agentVersion": {},
        "agentHash": {},
        "dockerVersion": {}
      }
    },
    "S2m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {},
          "doubleValue": {
            "type": "double"
          },
          "longValue": {
            "type": "long"
          },
          "integerValue": {
            "type": "integer"
          },
          "stringSetValue": {
            "shape": "Sl"
          }
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "taskDefinitionArn": {},
        "containerDefinitions": {
          "shape": "S2s"
        },
        "family": {},
        "taskRoleArn": {},
        "executionRoleArn": {},
        "networkMode": {},
        "revision": {
          "type": "integer"
        },
        "volumes": {
          "shape": "S42"
        },
        "status": {},
        "requiresAttributes": {
          "type": "list",
          "member": {
            "shape": "S25"
          }
        },
        "placementConstraints": {
          "shape": "S4e"
        },
        "compatibilities": {
          "shape": "S4h"
        },
        "requiresCompatibilities": {
          "shape": "S4h"
        },
        "cpu": {},
        "memory": {},
        "inferenceAccelerators": {
          "shape": "S4j"
        },
        "pidMode": {},
        "ipcMode": {},
        "proxyConfiguration": {
          "shape": "S4n"
        }
      }
    },
    "S2s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "image": {},
          "repositoryCredentials": {
            "type": "structure",
            "required": [
              "credentialsParameter"
            ],
            "members": {
              "credentialsParameter": {}
            }
          },
          "cpu": {
            "type": "integer"
          },
          "memory": {
            "type": "integer"
          },
          "memoryReservation": {
            "type": "integer"
          },
          "links": {
            "shape": "Sl"
          },
          "portMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "containerPort": {
                  "type": "integer"
                },
                "hostPort": {
                  "type": "integer"
                },
                "protocol": {}
              }
            }
          },
          "essential": {
            "type": "boolean"
          },
          "entryPoint": {
            "shape": "Sl"
          },
          "command": {
            "shape": "Sl"
          },
          "environment": {
            "shape": "S2y"
          },
          "environmentFiles": {
            "shape": "S2z"
          },
          "mountPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceVolume": {},
                "containerPath": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "volumesFrom": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceContainer": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "linuxParameters": {
            "type": "structure",
            "members": {
              "capabilities": {
                "type": "structure",
                "members": {
                  "add": {
                    "shape": "Sl"
                  },
                  "drop": {
                    "shape": "Sl"
                  }
                }
              },
              "devices": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "hostPath"
                  ],
                  "members": {
                    "hostPath": {},
                    "containerPath": {},
                    "permissions": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              },
              "initProcessEnabled": {
                "type": "boolean"
              },
              "sharedMemorySize": {
                "type": "integer"
              },
              "tmpfs": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "containerPath",
                    "size"
                  ],
                  "members": {
                    "containerPath": {},
                    "size": {
                      "type": "integer"
                    },
                    "mountOptions": {
                      "shape": "Sl"
                    }
                  }
                }
              },
              "maxSwap": {
                "type": "integer"
              },
              "swappiness": {
                "type": "integer"
              }
            }
          },
          "secrets": {
            "shape": "S3e"
          },
          "dependsOn": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "containerName",
                "condition"
              ],
              "members": {
                "containerName": {},
                "condition": {}
              }
            }
          },
          "startTimeout": {
            "type": "integer"
          },
          "stopTimeout": {
            "type": "integer"
          },
          "hostname": {},
          "user": {},
          "workingDirectory": {},
          "disableNetworking": {
            "type": "boolean"
          },
          "privileged": {
            "type": "boolean"
          },
          "readonlyRootFilesystem": {
            "type": "boolean"
          },
          "dnsServers": {
            "shape": "Sl"
          },
          "dnsSearchDomains": {
            "shape": "Sl"
          },
          "extraHosts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "hostname",
                "ipAddress"
              ],
              "members": {
                "hostname": {},
                "ipAddress": {}
              }
            }
          },
          "dockerSecurityOptions": {
            "shape": "Sl"
          },
          "interactive": {
            "type": "boolean"
          },
          "pseudoTerminal": {
            "type": "boolean"
          },
          "dockerLabels": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "ulimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "softLimit",
                "hardLimit"
              ],
              "members": {
                "name": {},
                "softLimit": {
                  "type": "integer"
                },
                "hardLimit": {
                  "type": "integer"
                }
              }
            }
          },
          "logConfiguration": {
            "type": "structure",
            "required": [
              "logDriver"
            ],
            "members": {
              "logDriver": {},
              "options": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "secretOptions": {
                "shape": "S3e"
              }
            }
          },
          "healthCheck": {
            "type": "structure",
            "required": [
              "command"
            ],
            "members": {
              "command": {
                "shape": "Sl"
              },
              "interval": {
                "type": "integer"
              },
              "timeout": {
                "type": "integer"
              },
              "retries": {
                "type": "integer"
              },
              "startPeriod": {
                "type": "integer"
              }
            }
          },
          "systemControls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "namespace": {},
                "value": {}
              }
            }
          },
          "resourceRequirements": {
            "shape": "S3v"
          },
          "firelensConfiguration": {
            "type": "structure",
            "required": [
              "type"
            ],
            "members": {
              "type": {},
              "options": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    },
    "S2y": {
      "type": "list",
      "member": {
        "shape": "Su"
      }
    },
    "S2z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S3e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "valueFrom"
        ],
        "members": {
          "name": {},
          "valueFrom": {}
        }
      }
    },
    "S3v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S42": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "host": {
            "type": "structure",
            "members": {
              "sourcePath": {}
            }
          },
          "dockerVolumeConfiguration": {
            "type": "structure",
            "members": {
              "scope": {},
              "autoprovision": {
                "type": "boolean"
              },
              "driver": {},
              "driverOpts": {
                "shape": "S47"
              },
              "labels": {
                "shape": "S47"
              }
            }
          },
          "efsVolumeConfiguration": {
            "type": "structure",
            "required": [
              "fileSystemId"
            ],
            "members": {
              "fileSystemId": {},
              "rootDirectory": {},
              "transitEncryption": {},
              "transitEncryptionPort": {
                "type": "integer"
              },
              "authorizationConfig": {
                "type": "structure",
                "members": {
                  "accessPointId": {},
                  "iam": {}
                }
              }
            }
          }
        }
      }
    },
    "S47": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {}
    },
    "S4j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "deviceName",
          "deviceType"
        ],
        "members": {
          "deviceName": {},
          "deviceType": {}
        }
      }
    },
    "S4n": {
      "type": "structure",
      "required": [
        "containerName"
      ],
      "members": {
        "type": {},
        "containerName": {},
        "properties": {
          "type": "list",
          "member": {
            "shape": "Su"
          }
        }
      }
    },
    "S4v": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "reason": {},
          "detail": {}
        }
      }
    },
    "S56": {
      "type": "list",
      "member": {
        "shape": "S2j"
      }
    },
    "S5o": {
      "type": "list",
      "member": {
        "shape": "S5p"
      }
    },
    "S5p": {
      "type": "structure",
      "members": {
        "attachments": {
          "shape": "Sv"
        },
        "attributes": {
          "shape": "S24"
        },
        "availabilityZone": {},
        "capacityProviderName": {},
        "clusterArn": {},
        "connectivity": {},
        "connectivityAt": {
          "type": "timestamp"
        },
        "containerInstanceArn": {},
        "containers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "containerArn": {},
              "taskArn": {},
              "name": {},
              "image": {},
              "imageDigest": {},
              "runtimeId": {},
              "lastStatus": {},
              "exitCode": {
                "type": "integer"
              },
              "reason": {},
              "networkBindings": {
                "shape": "S5t"
              },
              "networkInterfaces": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "attachmentId": {},
                    "privateIpv4Address": {},
                    "ipv6Address": {}
                  }
                }
              },
              "healthStatus": {},
              "cpu": {},
              "memory": {},
              "memoryReservation": {},
              "gpuIds": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "cpu": {},
        "createdAt": {
          "type": "timestamp"
        },
        "desiredStatus": {},
        "executionStoppedAt": {
          "type": "timestamp"
        },
        "group": {},
        "healthStatus": {},
        "inferenceAccelerators": {
          "shape": "S4j"
        },
        "lastStatus": {},
        "launchType": {},
        "memory": {},
        "overrides": {
          "shape": "S5z"
        },
        "platformVersion": {},
        "pullStartedAt": {
          "type": "timestamp"
        },
        "pullStoppedAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "startedBy": {},
        "stopCode": {},
        "stoppedAt": {
          "type": "timestamp"
        },
        "stoppedReason": {},
        "stoppingAt": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S9"
        },
        "taskArn": {},
        "taskDefinitionArn": {},
        "version": {
          "type": "long"
        }
      }
    },
    "S5t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "bindIP": {},
          "containerPort": {
            "type": "integer"
          },
          "hostPort": {
            "type": "integer"
          },
          "protocol": {}
        }
      }
    },
    "S5z": {
      "type": "structure",
      "members": {
        "containerOverrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "command": {
                "shape": "Sl"
              },
              "environment": {
                "shape": "S2y"
              },
              "environmentFiles": {
                "shape": "S2z"
              },
              "cpu": {
                "type": "integer"
              },
              "memory": {
                "type": "integer"
              },
              "memoryReservation": {
                "type": "integer"
              },
              "resourceRequirements": {
                "shape": "S3v"
              }
            }
          }
        },
        "cpu": {},
        "inferenceAcceleratorOverrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "deviceName": {},
              "deviceType": {}
            }
          }
        },
        "executionRoleArn": {},
        "memory": {},
        "taskRoleArn": {}
      }
    },
    "S7g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "attachmentArn",
          "status"
        ],
        "members": {
          "attachmentArn": {},
          "status": {}
        }
      }
    }
  }
}
},{}],78:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListAccountSettings": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "settings"
    },
    "ListAttributes": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "attributes"
    },
    "ListClusters": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "clusterArns"
    },
    "ListContainerInstances": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "containerInstanceArns"
    },
    "ListServices": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "serviceArns"
    },
    "ListTaskDefinitionFamilies": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "families"
    },
    "ListTaskDefinitions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "taskDefinitionArns"
    },
    "ListTasks": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "taskArns"
    }
  }
}
},{}],79:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "TasksRunning": {
      "delay": 6,
      "operation": "DescribeTasks",
      "maxAttempts": 100,
      "acceptors": [
        {
          "expected": "STOPPED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "tasks[].lastStatus"
        },
        {
          "expected": "MISSING",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "failures[].reason"
        },
        {
          "expected": "RUNNING",
          "matcher": "pathAll",
          "state": "success",
          "argument": "tasks[].lastStatus"
        }
      ]
    },
    "TasksStopped": {
      "delay": 6,
      "operation": "DescribeTasks",
      "maxAttempts": 100,
      "acceptors": [
        {
          "expected": "STOPPED",
          "matcher": "pathAll",
          "state": "success",
          "argument": "tasks[].lastStatus"
        }
      ]
    },
    "ServicesStable": {
      "delay": 15,
      "operation": "DescribeServices",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "MISSING",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "failures[].reason"
        },
        {
          "expected": "DRAINING",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "services[].status"
        },
        {
          "expected": "INACTIVE",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "services[].status"
        },
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(services[?!(length(deployments) == `1` && runningCount == desiredCount)]) == `0`"
        }
      ]
    },
    "ServicesInactive": {
      "delay": 15,
      "operation": "DescribeServices",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": "MISSING",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "failures[].reason"
        },
        {
          "expected": "INACTIVE",
          "matcher": "pathAny",
          "state": "success",
          "argument": "services[].status"
        }
      ]
    }
  }
}

},{}],80:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-02-02",
    "endpointPrefix": "elasticache",
    "protocol": "query",
    "serviceFullName": "Amazon ElastiCache",
    "serviceId": "ElastiCache",
    "signatureVersion": "v4",
    "uid": "elasticache-2015-02-02",
    "xmlNamespace": "http://elasticache.amazonaws.com/doc/2015-02-02/"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "AddTagsToResourceResult"
      }
    },
    "AuthorizeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "BatchApplyUpdateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceUpdateName"
        ],
        "members": {
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "ServiceUpdateName": {}
        }
      },
      "output": {
        "shape": "Se",
        "resultWrapper": "BatchApplyUpdateActionResult"
      }
    },
    "BatchStopUpdateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceUpdateName"
        ],
        "members": {
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "ServiceUpdateName": {}
        }
      },
      "output": {
        "shape": "Se",
        "resultWrapper": "BatchStopUpdateActionResult"
      }
    },
    "CompleteMigration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CompleteMigrationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceSnapshotName",
          "TargetSnapshotName"
        ],
        "members": {
          "SourceSnapshotName": {},
          "TargetSnapshotName": {},
          "TargetBucket": {},
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "CopySnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1a"
          }
        }
      }
    },
    "CreateCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {},
          "AZMode": {},
          "PreferredAvailabilityZone": {},
          "PreferredAvailabilityZones": {
            "shape": "S1i"
          },
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "S1j"
          },
          "SecurityGroupIds": {
            "shape": "S1k"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "S1l"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S1n"
          }
        }
      }
    },
    "CreateCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "CacheParameterGroupFamily",
          "Description"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "CacheParameterGroupFamily": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheParameterGroupResult",
        "type": "structure",
        "members": {
          "CacheParameterGroup": {
            "shape": "S20"
          }
        }
      }
    },
    "CreateCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "Description"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSecurityGroupResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName",
          "CacheSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S26"
          }
        }
      }
    },
    "CreateGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupIdSuffix",
          "PrimaryReplicationGroupId"
        ],
        "members": {
          "GlobalReplicationGroupIdSuffix": {},
          "GlobalReplicationGroupDescription": {},
          "PrimaryReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "CreateGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "CreateReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ReplicationGroupDescription"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "GlobalReplicationGroupId": {},
          "PrimaryClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "MultiAZEnabled": {
            "type": "boolean"
          },
          "NumCacheClusters": {
            "type": "integer"
          },
          "PreferredCacheClusterAZs": {
            "shape": "S1f"
          },
          "NumNodeGroups": {
            "type": "integer"
          },
          "ReplicasPerNodeGroup": {
            "type": "integer"
          },
          "NodeGroupConfiguration": {
            "type": "list",
            "member": {
              "shape": "S1d",
              "locationName": "NodeGroupConfiguration"
            }
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "S1j"
          },
          "SecurityGroupIds": {
            "shape": "S1k"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "S1l"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {},
          "TransitEncryptionEnabled": {
            "type": "boolean"
          },
          "AtRestEncryptionEnabled": {
            "type": "boolean"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "CreateReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {},
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "CreateSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1a"
          }
        }
      }
    },
    "DecreaseNodeGroupsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "GlobalNodeGroupsToRemove": {
            "shape": "S2n"
          },
          "GlobalNodeGroupsToRetain": {
            "shape": "S2n"
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DecreaseNodeGroupsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "DecreaseReplicaCount": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "ReplicaConfiguration": {
            "shape": "S2q"
          },
          "ReplicasToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DecreaseReplicaCountResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "DeleteCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S1n"
          }
        }
      }
    },
    "DeleteCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {}
        }
      }
    },
    "DeleteCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName"
        ],
        "members": {
          "CacheSecurityGroupName": {}
        }
      }
    },
    "DeleteCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {}
        }
      }
    },
    "DeleteGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "RetainPrimaryReplicationGroup"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "RetainPrimaryReplicationGroup": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "DeleteReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "RetainPrimaryCluster": {
            "type": "boolean"
          },
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "SnapshotName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S1a"
          }
        }
      }
    },
    "DescribeCacheClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "ShowCacheNodeInfo": {
            "type": "boolean"
          },
          "ShowCacheClustersNotInReplicationGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheClusters": {
            "type": "list",
            "member": {
              "shape": "S1n",
              "locationName": "CacheCluster"
            }
          }
        }
      }
    },
    "DescribeCacheEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "CacheEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "CacheParameterGroupFamily": {},
                "CacheEngineDescription": {},
                "CacheEngineVersionDescription": {}
              }
            }
          }
        }
      }
    },
    "DescribeCacheParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S20",
              "locationName": "CacheParameterGroup"
            }
          }
        }
      }
    },
    "DescribeCacheParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParametersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Parameters": {
            "shape": "S3h"
          },
          "CacheNodeTypeSpecificParameters": {
            "shape": "S3k"
          }
        }
      }
    },
    "DescribeCacheSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "S8",
              "locationName": "CacheSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeCacheSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S26",
              "locationName": "CacheSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupFamily"
        ],
        "members": {
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "CacheParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S3h"
              },
              "CacheNodeTypeSpecificParameters": {
                "shape": "S3k"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeGlobalReplicationGroups": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalReplicationGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "ShowMemberInfo": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeGlobalReplicationGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "GlobalReplicationGroups": {
            "type": "list",
            "member": {
              "shape": "S2c",
              "locationName": "GlobalReplicationGroup"
            }
          }
        }
      }
    },
    "DescribeReplicationGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReplicationGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationGroups": {
            "type": "list",
            "member": {
              "shape": "So",
              "locationName": "ReplicationGroup"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodes": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodeId": {},
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodes": {
            "type": "list",
            "member": {
              "shape": "S4b",
              "locationName": "ReservedCacheNode"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedCacheNodesOffering",
              "type": "structure",
              "members": {
                "ReservedCacheNodesOfferingId": {},
                "CacheNodeType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "ProductDescription": {},
                "OfferingType": {},
                "RecurringCharges": {
                  "shape": "S4c"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeServiceUpdates": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceUpdateName": {},
          "ServiceUpdateStatus": {
            "shape": "S4j"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeServiceUpdatesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ServiceUpdates": {
            "type": "list",
            "member": {
              "locationName": "ServiceUpdate",
              "type": "structure",
              "members": {
                "ServiceUpdateName": {},
                "ServiceUpdateReleaseDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateEndDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateSeverity": {},
                "ServiceUpdateRecommendedApplyByDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateStatus": {},
                "ServiceUpdateDescription": {},
                "ServiceUpdateType": {},
                "Engine": {},
                "EngineVersion": {},
                "AutoUpdateAfterRecommendedApplyByDate": {
                  "type": "boolean"
                },
                "EstimatedUpdateTime": {}
              }
            }
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {},
          "SnapshotSource": {},
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "ShowNodeGroupConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "S1a",
              "locationName": "Snapshot"
            }
          }
        }
      }
    },
    "DescribeUpdateActions": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceUpdateName": {},
          "ReplicationGroupIds": {
            "shape": "Sc"
          },
          "CacheClusterIds": {
            "shape": "Sd"
          },
          "Engine": {},
          "ServiceUpdateStatus": {
            "shape": "S4j"
          },
          "ServiceUpdateTimeRange": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "UpdateActionStatus": {
            "type": "list",
            "member": {}
          },
          "ShowNodeLevelUpdateStatus": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeUpdateActionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "UpdateActions": {
            "type": "list",
            "member": {
              "locationName": "UpdateAction",
              "type": "structure",
              "members": {
                "ReplicationGroupId": {},
                "CacheClusterId": {},
                "ServiceUpdateName": {},
                "ServiceUpdateReleaseDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateSeverity": {},
                "ServiceUpdateStatus": {},
                "ServiceUpdateRecommendedApplyByDate": {
                  "type": "timestamp"
                },
                "ServiceUpdateType": {},
                "UpdateActionAvailableDate": {
                  "type": "timestamp"
                },
                "UpdateActionStatus": {},
                "NodesUpdated": {},
                "UpdateActionStatusModifiedDate": {
                  "type": "timestamp"
                },
                "SlaMet": {},
                "NodeGroupUpdateStatus": {
                  "type": "list",
                  "member": {
                    "locationName": "NodeGroupUpdateStatus",
                    "type": "structure",
                    "members": {
                      "NodeGroupId": {},
                      "NodeGroupMemberUpdateStatus": {
                        "type": "list",
                        "member": {
                          "locationName": "NodeGroupMemberUpdateStatus",
                          "type": "structure",
                          "members": {
                            "CacheClusterId": {},
                            "CacheNodeId": {},
                            "NodeUpdateStatus": {},
                            "NodeDeletionDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateStartDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateEndDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateInitiatedBy": {},
                            "NodeUpdateInitiatedDate": {
                              "type": "timestamp"
                            },
                            "NodeUpdateStatusModifiedDate": {
                              "type": "timestamp"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "CacheNodeUpdateStatus": {
                  "type": "list",
                  "member": {
                    "locationName": "CacheNodeUpdateStatus",
                    "type": "structure",
                    "members": {
                      "CacheNodeId": {},
                      "NodeUpdateStatus": {},
                      "NodeDeletionDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateStartDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateEndDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateInitiatedBy": {},
                      "NodeUpdateInitiatedDate": {
                        "type": "timestamp"
                      },
                      "NodeUpdateStatusModifiedDate": {
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "EstimatedUpdateTime": {},
                "Engine": {}
              }
            }
          }
        }
      }
    },
    "DisassociateGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ReplicationGroupId",
          "ReplicationGroupRegion"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ReplicationGroupId": {},
          "ReplicationGroupRegion": {}
        }
      },
      "output": {
        "resultWrapper": "DisassociateGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "FailoverGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "PrimaryRegion",
          "PrimaryReplicationGroupId"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "PrimaryRegion": {},
          "PrimaryReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "FailoverGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "IncreaseNodeGroupsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "RegionalConfigurations": {
            "type": "list",
            "member": {
              "locationName": "RegionalConfiguration",
              "type": "structure",
              "required": [
                "ReplicationGroupId",
                "ReplicationGroupRegion",
                "ReshardingConfiguration"
              ],
              "members": {
                "ReplicationGroupId": {},
                "ReplicationGroupRegion": {},
                "ReshardingConfiguration": {
                  "shape": "S5f"
                }
              }
            }
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "IncreaseNodeGroupsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "IncreaseReplicaCount": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "ReplicaConfiguration": {
            "shape": "S2q"
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "IncreaseReplicaCountResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "ListAllowedNodeTypeModifications": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "ListAllowedNodeTypeModificationsResult",
        "type": "structure",
        "members": {
          "ScaleUpModifications": {
            "shape": "S5m"
          },
          "ScaleDownModifications": {
            "shape": "S5m"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {}
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "ListTagsForResourceResult"
      }
    },
    "ModifyCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeIdsToRemove": {
            "shape": "S1p"
          },
          "AZMode": {},
          "NewAvailabilityZones": {
            "shape": "S1i"
          },
          "CacheSecurityGroupNames": {
            "shape": "S1j"
          },
          "SecurityGroupIds": {
            "shape": "S1k"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {},
          "AuthToken": {},
          "AuthTokenUpdateStrategy": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S1n"
          }
        }
      }
    },
    "ModifyCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "ParameterNameValues"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ParameterNameValues": {
            "shape": "S5s"
          }
        }
      },
      "output": {
        "shape": "S5u",
        "resultWrapper": "ModifyCacheParameterGroupResult"
      }
    },
    "ModifyCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S26"
          }
        }
      }
    },
    "ModifyGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "CacheNodeType": {},
          "EngineVersion": {},
          "GlobalReplicationGroupDescription": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "ModifyReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "PrimaryClusterId": {},
          "SnapshottingClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "MultiAZEnabled": {
            "type": "boolean"
          },
          "NodeGroupId": {
            "deprecated": true
          },
          "CacheSecurityGroupNames": {
            "shape": "S1j"
          },
          "SecurityGroupIds": {
            "shape": "S1k"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {},
          "AuthToken": {},
          "AuthTokenUpdateStrategy": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "ModifyReplicationGroupShardConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "NodeGroupCount",
          "ApplyImmediately"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NodeGroupCount": {
            "type": "integer"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "ReshardingConfiguration": {
            "shape": "S5f"
          },
          "NodeGroupsToRemove": {
            "type": "list",
            "member": {
              "locationName": "NodeGroupToRemove"
            }
          },
          "NodeGroupsToRetain": {
            "type": "list",
            "member": {
              "locationName": "NodeGroupToRetain"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyReplicationGroupShardConfigurationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "PurchaseReservedCacheNodesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedCacheNodesOfferingId"
        ],
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "ReservedCacheNodeId": {},
          "CacheNodeCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedCacheNodesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedCacheNode": {
            "shape": "S4b"
          }
        }
      }
    },
    "RebalanceSlotsInGlobalReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalReplicationGroupId",
          "ApplyImmediately"
        ],
        "members": {
          "GlobalReplicationGroupId": {},
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebalanceSlotsInGlobalReplicationGroupResult",
        "type": "structure",
        "members": {
          "GlobalReplicationGroup": {
            "shape": "S2c"
          }
        }
      }
    },
    "RebootCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId",
          "CacheNodeIdsToReboot"
        ],
        "members": {
          "CacheClusterId": {},
          "CacheNodeIdsToReboot": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "S1n"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "RemoveTagsFromResourceResult"
      }
    },
    "ResetCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "ParameterNameValues": {
            "shape": "S5s"
          }
        }
      },
      "output": {
        "shape": "S5u",
        "resultWrapper": "ResetCacheParameterGroupResult"
      }
    },
    "RevokeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "StartMigration": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "CustomerNodeEndpointList"
        ],
        "members": {
          "ReplicationGroupId": {},
          "CustomerNodeEndpointList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Address": {},
                "Port": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "StartMigrationResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    },
    "TestFailover": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "NodeGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "NodeGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "TestFailoverResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "So"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "TagList": {
          "shape": "S3"
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CacheSecurityGroupName": {},
        "Description": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "structure",
      "members": {
        "ProcessedUpdateActions": {
          "type": "list",
          "member": {
            "locationName": "ProcessedUpdateAction",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "CacheClusterId": {},
              "ServiceUpdateName": {},
              "UpdateActionStatus": {}
            }
          }
        },
        "UnprocessedUpdateActions": {
          "type": "list",
          "member": {
            "locationName": "UnprocessedUpdateAction",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "CacheClusterId": {},
              "ServiceUpdateName": {},
              "ErrorType": {},
              "ErrorMessage": {}
            }
          }
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "ReplicationGroupId": {},
        "Description": {},
        "GlobalReplicationGroupInfo": {
          "type": "structure",
          "members": {
            "GlobalReplicationGroupId": {},
            "GlobalReplicationGroupMemberRole": {}
          }
        },
        "Status": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "PrimaryClusterId": {},
            "AutomaticFailoverStatus": {},
            "Resharding": {
              "type": "structure",
              "members": {
                "SlotMigration": {
                  "type": "structure",
                  "members": {
                    "ProgressPercentage": {
                      "type": "double"
                    }
                  }
                }
              }
            },
            "AuthTokenStatus": {}
          }
        },
        "MemberClusters": {
          "type": "list",
          "member": {
            "locationName": "ClusterId"
          }
        },
        "NodeGroups": {
          "type": "list",
          "member": {
            "locationName": "NodeGroup",
            "type": "structure",
            "members": {
              "NodeGroupId": {},
              "Status": {},
              "PrimaryEndpoint": {
                "shape": "Sz"
              },
              "ReaderEndpoint": {
                "shape": "Sz"
              },
              "Slots": {},
              "NodeGroupMembers": {
                "type": "list",
                "member": {
                  "locationName": "NodeGroupMember",
                  "type": "structure",
                  "members": {
                    "CacheClusterId": {},
                    "CacheNodeId": {},
                    "ReadEndpoint": {
                      "shape": "Sz"
                    },
                    "PreferredAvailabilityZone": {},
                    "CurrentRole": {}
                  }
                }
              }
            }
          }
        },
        "SnapshottingClusterId": {},
        "AutomaticFailover": {},
        "MultiAZ": {},
        "ConfigurationEndpoint": {
          "shape": "Sz"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "ClusterEnabled": {
          "type": "boolean"
        },
        "CacheNodeType": {},
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "AuthTokenLastModifiedDate": {
          "type": "timestamp"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "ARN": {}
      },
      "wrapper": true
    },
    "Sz": {
      "type": "structure",
      "members": {
        "Address": {},
        "Port": {
          "type": "integer"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "SnapshotName": {},
        "ReplicationGroupId": {},
        "ReplicationGroupDescription": {},
        "CacheClusterId": {},
        "SnapshotStatus": {},
        "SnapshotSource": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "TopicArn": {},
        "Port": {
          "type": "integer"
        },
        "CacheParameterGroupName": {},
        "CacheSubnetGroupName": {},
        "VpcId": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "NumNodeGroups": {
          "type": "integer"
        },
        "AutomaticFailover": {},
        "NodeSnapshots": {
          "type": "list",
          "member": {
            "locationName": "NodeSnapshot",
            "type": "structure",
            "members": {
              "CacheClusterId": {},
              "NodeGroupId": {},
              "CacheNodeId": {},
              "NodeGroupConfiguration": {
                "shape": "S1d"
              },
              "CacheSize": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "SnapshotCreateTime": {
                "type": "timestamp"
              }
            },
            "wrapper": true
          }
        },
        "KmsKeyId": {},
        "ARN": {}
      },
      "wrapper": true
    },
    "S1d": {
      "type": "structure",
      "members": {
        "NodeGroupId": {},
        "Slots": {},
        "ReplicaCount": {
          "type": "integer"
        },
        "PrimaryAvailabilityZone": {},
        "ReplicaAvailabilityZones": {
          "shape": "S1f"
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "locationName": "PreferredAvailabilityZone"
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "locationName": "CacheSecurityGroupName"
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "locationName": "SnapshotArn"
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "CacheClusterId": {},
        "ConfigurationEndpoint": {
          "shape": "Sz"
        },
        "ClientDownloadLandingPage": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "CacheClusterStatus": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "NumCacheNodes": {
              "type": "integer"
            },
            "CacheNodeIdsToRemove": {
              "shape": "S1p"
            },
            "EngineVersion": {},
            "CacheNodeType": {},
            "AuthTokenStatus": {}
          }
        },
        "NotificationConfiguration": {
          "type": "structure",
          "members": {
            "TopicArn": {},
            "TopicStatus": {}
          }
        },
        "CacheSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "CacheSecurityGroup",
            "type": "structure",
            "members": {
              "CacheSecurityGroupName": {},
              "Status": {}
            }
          }
        },
        "CacheParameterGroup": {
          "type": "structure",
          "members": {
            "CacheParameterGroupName": {},
            "ParameterApplyStatus": {},
            "CacheNodeIdsToReboot": {
              "shape": "S1p"
            }
          }
        },
        "CacheSubnetGroupName": {},
        "CacheNodes": {
          "type": "list",
          "member": {
            "locationName": "CacheNode",
            "type": "structure",
            "members": {
              "CacheNodeId": {},
              "CacheNodeStatus": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "Endpoint": {
                "shape": "Sz"
              },
              "ParameterGroupStatus": {},
              "SourceCacheNodeId": {},
              "CustomerAvailabilityZone": {}
            }
          }
        },
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "ReplicationGroupId": {},
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "AuthTokenLastModifiedDate": {
          "type": "timestamp"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S1p": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeId"
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {},
        "CacheParameterGroupFamily": {},
        "Description": {},
        "IsGlobal": {
          "type": "boolean"
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S24": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "CacheSubnetGroupName": {},
        "CacheSubnetGroupDescription": {},
        "VpcId": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "type": "structure",
                "members": {
                  "Name": {}
                },
                "wrapper": true
              }
            }
          }
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S2c": {
      "type": "structure",
      "members": {
        "GlobalReplicationGroupId": {},
        "GlobalReplicationGroupDescription": {},
        "Status": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "Members": {
          "type": "list",
          "member": {
            "locationName": "GlobalReplicationGroupMember",
            "type": "structure",
            "members": {
              "ReplicationGroupId": {},
              "ReplicationGroupRegion": {},
              "Role": {},
              "AutomaticFailover": {},
              "Status": {}
            },
            "wrapper": true
          }
        },
        "ClusterEnabled": {
          "type": "boolean"
        },
        "GlobalNodeGroups": {
          "type": "list",
          "member": {
            "locationName": "GlobalNodeGroup",
            "type": "structure",
            "members": {
              "GlobalNodeGroupId": {},
              "Slots": {}
            }
          }
        },
        "AuthTokenEnabled": {
          "type": "boolean"
        },
        "TransitEncryptionEnabled": {
          "type": "boolean"
        },
        "AtRestEncryptionEnabled": {
          "type": "boolean"
        },
        "ARN": {}
      },
      "wrapper": true
    },
    "S2n": {
      "type": "list",
      "member": {
        "locationName": "GlobalNodeGroupId"
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "locationName": "ConfigureShard",
        "type": "structure",
        "required": [
          "NodeGroupId",
          "NewReplicaCount"
        ],
        "members": {
          "NodeGroupId": {},
          "NewReplicaCount": {
            "type": "integer"
          },
          "PreferredAvailabilityZones": {
            "shape": "S1i"
          }
        }
      }
    },
    "S3h": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ChangeType": {}
        }
      }
    },
    "S3k": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeTypeSpecificParameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "CacheNodeTypeSpecificValues": {
            "type": "list",
            "member": {
              "locationName": "CacheNodeTypeSpecificValue",
              "type": "structure",
              "members": {
                "CacheNodeType": {},
                "Value": {}
              }
            }
          },
          "ChangeType": {}
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "ReservedCacheNodeId": {},
        "ReservedCacheNodesOfferingId": {},
        "CacheNodeType": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CacheNodeCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "State": {},
        "RecurringCharges": {
          "shape": "S4c"
        },
        "ReservationARN": {}
      },
      "wrapper": true
    },
    "S4c": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S4j": {
      "type": "list",
      "member": {}
    },
    "S5f": {
      "type": "list",
      "member": {
        "locationName": "ReshardingConfiguration",
        "type": "structure",
        "members": {
          "NodeGroupId": {},
          "PreferredAvailabilityZones": {
            "shape": "S1f"
          }
        }
      }
    },
    "S5m": {
      "type": "list",
      "member": {}
    },
    "S5s": {
      "type": "list",
      "member": {
        "locationName": "ParameterNameValue",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {}
        }
      }
    },
    "S5u": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {}
      }
    }
  }
}
},{}],81:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeCacheClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheClusters"
    },
    "DescribeCacheEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheEngineVersions"
    },
    "DescribeCacheParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheParameterGroups"
    },
    "DescribeCacheParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeCacheSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheSecurityGroups"
    },
    "DescribeCacheSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CacheSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeGlobalReplicationGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "GlobalReplicationGroups"
    },
    "DescribeReplicationGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReplicationGroups"
    },
    "DescribeReservedCacheNodes": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedCacheNodes"
    },
    "DescribeReservedCacheNodesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedCacheNodesOfferings"
    },
    "DescribeServiceUpdates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ServiceUpdates"
    },
    "DescribeSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Snapshots"
    },
    "DescribeUpdateActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "UpdateActions"
    }
  }
}
},{}],82:[function(require,module,exports){
module.exports={
    "version":2,
    "waiters":{
        "CacheClusterAvailable":{
            "acceptors":[
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"available",
                    "matcher":"pathAll",
                    "state":"success"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"deleted",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"deleting",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"incompatible-network",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"restore-failed",
                    "matcher":"pathAny",
                    "state":"failure"
                }
            ],
            "delay":15,
            "description":"Wait until ElastiCache cluster is available.",
            "maxAttempts":40,
            "operation":"DescribeCacheClusters"
        },
        "CacheClusterDeleted":{
            "acceptors":[
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"deleted",
                    "matcher":"pathAll",
                    "state":"success"
                },
                {
                    "expected":"CacheClusterNotFound",
                    "matcher":"error",
                    "state":"success"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"available",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"creating",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"incompatible-network",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"modifying",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"restore-failed",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "argument":"CacheClusters[].CacheClusterStatus",
                    "expected":"snapshotting",
                    "matcher":"pathAny",
                    "state":"failure"
                }
            ],
            "delay":15,
            "description":"Wait until ElastiCache cluster is deleted.",
            "maxAttempts":40,
            "operation":"DescribeCacheClusters"
        },
        "ReplicationGroupAvailable":{
            "acceptors":[
                {
                    "argument":"ReplicationGroups[].Status",
                    "expected":"available",
                    "matcher":"pathAll",
                    "state":"success"
                },
                {
                    "argument":"ReplicationGroups[].Status",
                    "expected":"deleted",
                    "matcher":"pathAny",
                    "state":"failure"
                }
            ],
            "delay":15,
            "description":"Wait until ElastiCache replication group is available.",
            "maxAttempts":40,
            "operation":"DescribeReplicationGroups"
        },
        "ReplicationGroupDeleted":{
            "acceptors":[
                {
                    "argument":"ReplicationGroups[].Status",
                    "expected":"deleted",
                    "matcher":"pathAll",
                    "state":"success"
                },
                {
                    "argument":"ReplicationGroups[].Status",
                    "expected":"available",
                    "matcher":"pathAny",
                    "state":"failure"
                },
                {
                    "expected":"ReplicationGroupNotFoundFault",
                    "matcher":"error",
                    "state":"success"
                }
            ],
            "delay":15,
            "description":"Wait until ElastiCache replication group is deleted.",
            "maxAttempts":40,
            "operation":"DescribeReplicationGroups"
        }
    }
}

},{}],83:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-12-01",
    "endpointPrefix": "elasticbeanstalk",
    "protocol": "query",
    "serviceAbbreviation": "Elastic Beanstalk",
    "serviceFullName": "AWS Elastic Beanstalk",
    "serviceId": "Elastic Beanstalk",
    "signatureVersion": "v4",
    "uid": "elasticbeanstalk-2010-12-01",
    "xmlNamespace": "http://elasticbeanstalk.amazonaws.com/docs/2010-12-01/"
  },
  "operations": {
    "AbortEnvironmentUpdate": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {}
        }
      }
    },
    "ApplyEnvironmentManagedAction": {
      "input": {
        "type": "structure",
        "required": [
          "ActionId"
        ],
        "members": {
          "EnvironmentName": {},
          "EnvironmentId": {},
          "ActionId": {}
        }
      },
      "output": {
        "resultWrapper": "ApplyEnvironmentManagedActionResult",
        "type": "structure",
        "members": {
          "ActionId": {},
          "ActionDescription": {},
          "ActionType": {},
          "Status": {}
        }
      }
    },
    "AssociateEnvironmentOperationsRole": {
      "input": {
        "type": "structure",
        "required": [
          "EnvironmentName",
          "OperationsRole"
        ],
        "members": {
          "EnvironmentName": {},
          "OperationsRole": {}
        }
      }
    },
    "CheckDNSAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "CNAMEPrefix"
        ],
        "members": {
          "CNAMEPrefix": {}
        }
      },
      "output": {
        "resultWrapper": "CheckDNSAvailabilityResult",
        "type": "structure",
        "members": {
          "Available": {
            "type": "boolean"
          },
          "FullyQualifiedCNAME": {}
        }
      }
    },
    "ComposeEnvironments": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "GroupName": {},
          "VersionLabels": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "shape": "Sk",
        "resultWrapper": "ComposeEnvironmentsResult"
      }
    },
    "CreateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "Description": {},
          "ResourceLifecycleConfig": {
            "shape": "S19"
          },
          "Tags": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "shape": "S1j",
        "resultWrapper": "CreateApplicationResult"
      }
    },
    "CreateApplicationVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "VersionLabel"
        ],
        "members": {
          "ApplicationName": {},
          "VersionLabel": {},
          "Description": {},
          "SourceBuildInformation": {
            "shape": "S1p"
          },
          "SourceBundle": {
            "shape": "S1t"
          },
          "BuildConfiguration": {
            "type": "structure",
            "required": [
              "CodeBuildServiceRole",
              "Image"
            ],
            "members": {
              "ArtifactName": {},
              "CodeBuildServiceRole": {},
              "ComputeType": {},
              "Image": {},
              "TimeoutInMinutes": {
                "type": "integer"
              }
            }
          },
          "AutoCreateApplication": {
            "type": "boolean"
          },
          "Process": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "shape": "S21",
        "resultWrapper": "CreateApplicationVersionResult"
      }
    },
    "CreateConfigurationTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "TemplateName"
        ],
        "members": {
          "ApplicationName": {},
          "TemplateName": {},
          "SolutionStackName": {},
          "PlatformArn": {},
          "SourceConfiguration": {
            "type": "structure",
            "members": {
              "ApplicationName": {},
              "TemplateName": {}
            }
          },
          "EnvironmentId": {},
          "Description": {},
          "OptionSettings": {
            "shape": "S27"
          },
          "Tags": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "shape": "S2d",
        "resultWrapper": "CreateConfigurationTemplateResult"
      }
    },
    "CreateEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "EnvironmentName": {},
          "GroupName": {},
          "Description": {},
          "CNAMEPrefix": {},
          "Tier": {
            "shape": "S13"
          },
          "Tags": {
            "shape": "S1f"
          },
          "VersionLabel": {},
          "TemplateName": {},
          "SolutionStackName": {},
          "PlatformArn": {},
          "OptionSettings": {
            "shape": "S27"
          },
          "OptionsToRemove": {
            "shape": "S2g"
          },
          "OperationsRole": {}
        }
      },
      "output": {
        "shape": "Sm",
        "resultWrapper": "CreateEnvironmentResult"
      }
    },
    "CreatePlatformVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformName",
          "PlatformVersion",
          "PlatformDefinitionBundle"
        ],
        "members": {
          "PlatformName": {},
          "PlatformVersion": {},
          "PlatformDefinitionBundle": {
            "shape": "S1t"
          },
          "EnvironmentName": {},
          "OptionSettings": {
            "shape": "S27"
          },
          "Tags": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "resultWrapper": "CreatePlatformVersionResult",
        "type": "structure",
        "members": {
          "PlatformSummary": {
            "shape": "S2m"
          },
          "Builder": {
            "type": "structure",
            "members": {
              "ARN": {}
            }
          }
        }
      }
    },
    "CreateStorageLocation": {
      "output": {
        "resultWrapper": "CreateStorageLocationResult",
        "type": "structure",
        "members": {
          "S3Bucket": {}
        }
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "TerminateEnvByForce": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteApplicationVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "VersionLabel"
        ],
        "members": {
          "ApplicationName": {},
          "VersionLabel": {},
          "DeleteSourceBundle": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteConfigurationTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "TemplateName"
        ],
        "members": {
          "ApplicationName": {},
          "TemplateName": {}
        }
      }
    },
    "DeleteEnvironmentConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "EnvironmentName"
        ],
        "members": {
          "ApplicationName": {},
          "EnvironmentName": {}
        }
      }
    },
    "DeletePlatformVersion": {
      "input": {
        "type": "structure",
        "members": {
          "PlatformArn": {}
        }
      },
      "output": {
        "resultWrapper": "DeletePlatformVersionResult",
        "type": "structure",
        "members": {
          "PlatformSummary": {
            "shape": "S2m"
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "output": {
        "resultWrapper": "DescribeAccountAttributesResult",
        "type": "structure",
        "members": {
          "ResourceQuotas": {
            "type": "structure",
            "members": {
              "ApplicationQuota": {
                "shape": "S3c"
              },
              "ApplicationVersionQuota": {
                "shape": "S3c"
              },
              "EnvironmentQuota": {
                "shape": "S3c"
              },
              "ConfigurationTemplateQuota": {
                "shape": "S3c"
              },
              "CustomPlatformQuota": {
                "shape": "S3c"
              }
            }
          }
        }
      }
    },
    "DescribeApplicationVersions": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "VersionLabels": {
            "shape": "S1m"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeApplicationVersionsResult",
        "type": "structure",
        "members": {
          "ApplicationVersions": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeApplications": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeApplicationsResult",
        "type": "structure",
        "members": {
          "Applications": {
            "type": "list",
            "member": {
              "shape": "S1k"
            }
          }
        }
      }
    },
    "DescribeConfigurationOptions": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "TemplateName": {},
          "EnvironmentName": {},
          "SolutionStackName": {},
          "PlatformArn": {},
          "Options": {
            "shape": "S2g"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeConfigurationOptionsResult",
        "type": "structure",
        "members": {
          "SolutionStackName": {},
          "PlatformArn": {},
          "Options": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Namespace": {},
                "Name": {},
                "DefaultValue": {},
                "ChangeSeverity": {},
                "UserDefined": {
                  "type": "boolean"
                },
                "ValueType": {},
                "ValueOptions": {
                  "type": "list",
                  "member": {}
                },
                "MinValue": {
                  "type": "integer"
                },
                "MaxValue": {
                  "type": "integer"
                },
                "MaxLength": {
                  "type": "integer"
                },
                "Regex": {
                  "type": "structure",
                  "members": {
                    "Pattern": {},
                    "Label": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeConfigurationSettings": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "TemplateName": {},
          "EnvironmentName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeConfigurationSettingsResult",
        "type": "structure",
        "members": {
          "ConfigurationSettings": {
            "type": "list",
            "member": {
              "shape": "S2d"
            }
          }
        }
      }
    },
    "DescribeEnvironmentHealth": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentName": {},
          "EnvironmentId": {},
          "AttributeNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEnvironmentHealthResult",
        "type": "structure",
        "members": {
          "EnvironmentName": {},
          "HealthStatus": {},
          "Status": {},
          "Color": {},
          "Causes": {
            "shape": "S48"
          },
          "ApplicationMetrics": {
            "shape": "S4a"
          },
          "InstancesHealth": {
            "type": "structure",
            "members": {
              "NoData": {
                "type": "integer"
              },
              "Unknown": {
                "type": "integer"
              },
              "Pending": {
                "type": "integer"
              },
              "Ok": {
                "type": "integer"
              },
              "Info": {
                "type": "integer"
              },
              "Warning": {
                "type": "integer"
              },
              "Degraded": {
                "type": "integer"
              },
              "Severe": {
                "type": "integer"
              }
            }
          },
          "RefreshedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeEnvironmentManagedActionHistory": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {},
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEnvironmentManagedActionHistoryResult",
        "type": "structure",
        "members": {
          "ManagedActionHistoryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActionId": {},
                "ActionType": {},
                "ActionDescription": {},
                "FailureType": {},
                "Status": {},
                "FailureDescription": {},
                "ExecutedTime": {
                  "type": "timestamp"
                },
                "FinishedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeEnvironmentManagedActions": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentName": {},
          "EnvironmentId": {},
          "Status": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEnvironmentManagedActionsResult",
        "type": "structure",
        "members": {
          "ManagedActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActionId": {},
                "ActionDescription": {},
                "ActionType": {},
                "Status": {},
                "WindowStartTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeEnvironmentResources": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEnvironmentResourcesResult",
        "type": "structure",
        "members": {
          "EnvironmentResources": {
            "type": "structure",
            "members": {
              "EnvironmentName": {},
              "AutoScalingGroups": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              },
              "Instances": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Id": {}
                  }
                }
              },
              "LaunchConfigurations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              },
              "LaunchTemplates": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Id": {}
                  }
                }
              },
              "LoadBalancers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              },
              "Triggers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              },
              "Queues": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "URL": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeEnvironments": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "VersionLabel": {},
          "EnvironmentIds": {
            "type": "list",
            "member": {}
          },
          "EnvironmentNames": {
            "type": "list",
            "member": {}
          },
          "IncludeDeleted": {
            "type": "boolean"
          },
          "IncludedDeletedBackTo": {
            "type": "timestamp"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "shape": "Sk",
        "resultWrapper": "DescribeEnvironmentsResult"
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "VersionLabel": {},
          "TemplateName": {},
          "EnvironmentId": {},
          "EnvironmentName": {},
          "PlatformArn": {},
          "RequestId": {},
          "Severity": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventDate": {
                  "type": "timestamp"
                },
                "Message": {},
                "ApplicationName": {},
                "VersionLabel": {},
                "TemplateName": {},
                "EnvironmentName": {},
                "PlatformArn": {},
                "RequestId": {},
                "Severity": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancesHealth": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentName": {},
          "EnvironmentId": {},
          "AttributeNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeInstancesHealthResult",
        "type": "structure",
        "members": {
          "InstanceHealthList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceId": {},
                "HealthStatus": {},
                "Color": {},
                "Causes": {
                  "shape": "S48"
                },
                "LaunchedAt": {
                  "type": "timestamp"
                },
                "ApplicationMetrics": {
                  "shape": "S4a"
                },
                "System": {
                  "type": "structure",
                  "members": {
                    "CPUUtilization": {
                      "type": "structure",
                      "members": {
                        "User": {
                          "type": "double"
                        },
                        "Nice": {
                          "type": "double"
                        },
                        "System": {
                          "type": "double"
                        },
                        "Idle": {
                          "type": "double"
                        },
                        "IOWait": {
                          "type": "double"
                        },
                        "IRQ": {
                          "type": "double"
                        },
                        "SoftIRQ": {
                          "type": "double"
                        },
                        "Privileged": {
                          "type": "double"
                        }
                      }
                    },
                    "LoadAverage": {
                      "type": "list",
                      "member": {
                        "type": "double"
                      }
                    }
                  }
                },
                "Deployment": {
                  "type": "structure",
                  "members": {
                    "VersionLabel": {},
                    "DeploymentId": {
                      "type": "long"
                    },
                    "Status": {},
                    "DeploymentTime": {
                      "type": "timestamp"
                    }
                  }
                },
                "AvailabilityZone": {},
                "InstanceType": {}
              }
            }
          },
          "RefreshedAt": {
            "type": "timestamp"
          },
          "NextToken": {}
        }
      }
    },
    "DescribePlatformVersion": {
      "input": {
        "type": "structure",
        "members": {
          "PlatformArn": {}
        }
      },
      "output": {
        "resultWrapper": "DescribePlatformVersionResult",
        "type": "structure",
        "members": {
          "PlatformDescription": {
            "type": "structure",
            "members": {
              "PlatformArn": {},
              "PlatformOwner": {},
              "PlatformName": {},
              "PlatformVersion": {},
              "SolutionStackName": {},
              "PlatformStatus": {},
              "DateCreated": {
                "type": "timestamp"
              },
              "DateUpdated": {
                "type": "timestamp"
              },
              "PlatformCategory": {},
              "Description": {},
              "Maintainer": {},
              "OperatingSystemName": {},
              "OperatingSystemVersion": {},
              "ProgrammingLanguages": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Version": {}
                  }
                }
              },
              "Frameworks": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Version": {}
                  }
                }
              },
              "CustomAmiList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "VirtualizationType": {},
                    "ImageId": {}
                  }
                }
              },
              "SupportedTierList": {
                "shape": "S2s"
              },
              "SupportedAddonList": {
                "shape": "S2u"
              },
              "PlatformLifecycleState": {},
              "PlatformBranchName": {},
              "PlatformBranchLifecycleState": {}
            }
          }
        }
      }
    },
    "DisassociateEnvironmentOperationsRole": {
      "input": {
        "type": "structure",
        "required": [
          "EnvironmentName"
        ],
        "members": {
          "EnvironmentName": {}
        }
      }
    },
    "ListAvailableSolutionStacks": {
      "output": {
        "resultWrapper": "ListAvailableSolutionStacksResult",
        "type": "structure",
        "members": {
          "SolutionStacks": {
            "type": "list",
            "member": {}
          },
          "SolutionStackDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SolutionStackName": {},
                "PermittedFileTypes": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "ListPlatformBranches": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Attribute": {},
                "Operator": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListPlatformBranchesResult",
        "type": "structure",
        "members": {
          "PlatformBranchSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlatformName": {},
                "BranchName": {},
                "LifecycleState": {},
                "BranchOrder": {
                  "type": "integer"
                },
                "SupportedTierList": {
                  "shape": "S2s"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPlatformVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Operator": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListPlatformVersionsResult",
        "type": "structure",
        "members": {
          "PlatformSummaryList": {
            "type": "list",
            "member": {
              "shape": "S2m"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "ResourceTags": {
            "shape": "S7f"
          }
        }
      }
    },
    "RebuildEnvironment": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {}
        }
      }
    },
    "RequestEnvironmentInfo": {
      "input": {
        "type": "structure",
        "required": [
          "InfoType"
        ],
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {},
          "InfoType": {}
        }
      }
    },
    "RestartAppServer": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {}
        }
      }
    },
    "RetrieveEnvironmentInfo": {
      "input": {
        "type": "structure",
        "required": [
          "InfoType"
        ],
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {},
          "InfoType": {}
        }
      },
      "output": {
        "resultWrapper": "RetrieveEnvironmentInfoResult",
        "type": "structure",
        "members": {
          "EnvironmentInfo": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InfoType": {},
                "Ec2InstanceId": {},
                "SampleTimestamp": {
                  "type": "timestamp"
                },
                "Message": {}
              }
            }
          }
        }
      }
    },
    "SwapEnvironmentCNAMEs": {
      "input": {
        "type": "structure",
        "members": {
          "SourceEnvironmentId": {},
          "SourceEnvironmentName": {},
          "DestinationEnvironmentId": {},
          "DestinationEnvironmentName": {}
        }
      }
    },
    "TerminateEnvironment": {
      "input": {
        "type": "structure",
        "members": {
          "EnvironmentId": {},
          "EnvironmentName": {},
          "TerminateResources": {
            "type": "boolean"
          },
          "ForceTerminate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sm",
        "resultWrapper": "TerminateEnvironmentResult"
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "S1j",
        "resultWrapper": "UpdateApplicationResult"
      }
    },
    "UpdateApplicationResourceLifecycle": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "ResourceLifecycleConfig"
        ],
        "members": {
          "ApplicationName": {},
          "ResourceLifecycleConfig": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateApplicationResourceLifecycleResult",
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "ResourceLifecycleConfig": {
            "shape": "S19"
          }
        }
      }
    },
    "UpdateApplicationVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "VersionLabel"
        ],
        "members": {
          "ApplicationName": {},
          "VersionLabel": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "S21",
        "resultWrapper": "UpdateApplicationVersionResult"
      }
    },
    "UpdateConfigurationTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "TemplateName"
        ],
        "members": {
          "ApplicationName": {},
          "TemplateName": {},
          "Description": {},
          "OptionSettings": {
            "shape": "S27"
          },
          "OptionsToRemove": {
            "shape": "S2g"
          }
        }
      },
      "output": {
        "shape": "S2d",
        "resultWrapper": "UpdateConfigurationTemplateResult"
      }
    },
    "UpdateEnvironment": {
      "input": {
        "type": "structure",
        "members": {
          "ApplicationName": {},
          "EnvironmentId": {},
          "EnvironmentName": {},
          "GroupName": {},
          "Description": {},
          "Tier": {
            "shape": "S13"
          },
          "VersionLabel": {},
          "TemplateName": {},
          "SolutionStackName": {},
          "PlatformArn": {},
          "OptionSettings": {
            "shape": "S27"
          },
          "OptionsToRemove": {
            "shape": "S2g"
          }
        }
      },
      "output": {
        "shape": "Sm",
        "resultWrapper": "UpdateEnvironmentResult"
      }
    },
    "UpdateTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "TagsToAdd": {
            "shape": "S7f"
          },
          "TagsToRemove": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ValidateConfigurationSettings": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "OptionSettings"
        ],
        "members": {
          "ApplicationName": {},
          "TemplateName": {},
          "EnvironmentName": {},
          "OptionSettings": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "resultWrapper": "ValidateConfigurationSettingsResult",
        "type": "structure",
        "members": {
          "Messages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Message": {},
                "Severity": {},
                "Namespace": {},
                "OptionName": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sk": {
      "type": "structure",
      "members": {
        "Environments": {
          "type": "list",
          "member": {
            "shape": "Sm"
          }
        },
        "NextToken": {}
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "EnvironmentName": {},
        "EnvironmentId": {},
        "ApplicationName": {},
        "VersionLabel": {},
        "SolutionStackName": {},
        "PlatformArn": {},
        "TemplateName": {},
        "Description": {},
        "EndpointURL": {},
        "CNAME": {},
        "DateCreated": {
          "type": "timestamp"
        },
        "DateUpdated": {
          "type": "timestamp"
        },
        "Status": {},
        "AbortableOperationInProgress": {
          "type": "boolean"
        },
        "Health": {},
        "HealthStatus": {},
        "Resources": {
          "type": "structure",
          "members": {
            "LoadBalancer": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "Domain": {},
                "Listeners": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Protocol": {},
                      "Port": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Tier": {
          "shape": "S13"
        },
        "EnvironmentLinks": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "LinkName": {},
              "EnvironmentName": {}
            }
          }
        },
        "EnvironmentArn": {},
        "OperationsRole": {}
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "Name": {},
        "Type": {},
        "Version": {}
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "ServiceRole": {},
        "VersionLifecycleConfig": {
          "type": "structure",
          "members": {
            "MaxCountRule": {
              "type": "structure",
              "required": [
                "Enabled"
              ],
              "members": {
                "Enabled": {
                  "type": "boolean"
                },
                "MaxCount": {
                  "type": "integer"
                },
                "DeleteSourceFromS3": {
                  "type": "boolean"
                }
              }
            },
            "MaxAgeRule": {
              "type": "structure",
              "required": [
                "Enabled"
              ],
              "members": {
                "Enabled": {
                  "type": "boolean"
                },
                "MaxAgeInDays": {
                  "type": "integer"
                },
                "DeleteSourceFromS3": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "shape": "S1g"
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "Application": {
          "shape": "S1k"
        }
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "ApplicationArn": {},
        "ApplicationName": {},
        "Description": {},
        "DateCreated": {
          "type": "timestamp"
        },
        "DateUpdated": {
          "type": "timestamp"
        },
        "Versions": {
          "shape": "S1m"
        },
        "ConfigurationTemplates": {
          "type": "list",
          "member": {}
        },
        "ResourceLifecycleConfig": {
          "shape": "S19"
        }
      }
    },
    "S1m": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "required": [
        "SourceType",
        "SourceRepository",
        "SourceLocation"
      ],
      "members": {
        "SourceType": {},
        "SourceRepository": {},
        "SourceLocation": {}
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "ApplicationVersion": {
          "shape": "S22"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "ApplicationVersionArn": {},
        "ApplicationName": {},
        "Description": {},
        "VersionLabel": {},
        "SourceBuildInformation": {
          "shape": "S1p"
        },
        "BuildArn": {},
        "SourceBundle": {
          "shape": "S1t"
        },
        "DateCreated": {
          "type": "timestamp"
        },
        "DateUpdated": {
          "type": "timestamp"
        },
        "Status": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceName": {},
          "Namespace": {},
          "OptionName": {},
          "Value": {}
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "SolutionStackName": {},
        "PlatformArn": {},
        "ApplicationName": {},
        "TemplateName": {},
        "Description": {},
        "EnvironmentName": {},
        "DeploymentStatus": {},
        "DateCreated": {
          "type": "timestamp"
        },
        "DateUpdated": {
          "type": "timestamp"
        },
        "OptionSettings": {
          "shape": "S27"
        }
      }
    },
    "S2g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceName": {},
          "Namespace": {},
          "OptionName": {}
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "PlatformArn": {},
        "PlatformOwner": {},
        "PlatformStatus": {},
        "PlatformCategory": {},
        "OperatingSystemName": {},
        "OperatingSystemVersion": {},
        "SupportedTierList": {
          "shape": "S2s"
        },
        "SupportedAddonList": {
          "shape": "S2u"
        },
        "PlatformLifecycleState": {},
        "PlatformVersion": {},
        "PlatformBranchName": {},
        "PlatformBranchLifecycleState": {}
      }
    },
    "S2s": {
      "type": "list",
      "member": {}
    },
    "S2u": {
      "type": "list",
      "member": {}
    },
    "S3c": {
      "type": "structure",
      "members": {
        "Maximum": {
          "type": "integer"
        }
      }
    },
    "S48": {
      "type": "list",
      "member": {}
    },
    "S4a": {
      "type": "structure",
      "members": {
        "Duration": {
          "type": "integer"
        },
        "RequestCount": {
          "type": "integer"
        },
        "StatusCodes": {
          "type": "structure",
          "members": {
            "Status2xx": {
              "type": "integer"
            },
            "Status3xx": {
              "type": "integer"
            },
            "Status4xx": {
              "type": "integer"
            },
            "Status5xx": {
              "type": "integer"
            }
          }
        },
        "Latency": {
          "type": "structure",
          "members": {
            "P999": {
              "type": "double"
            },
            "P99": {
              "type": "double"
            },
            "P95": {
              "type": "double"
            },
            "P90": {
              "type": "double"
            },
            "P85": {
              "type": "double"
            },
            "P75": {
              "type": "double"
            },
            "P50": {
              "type": "double"
            },
            "P10": {
              "type": "double"
            }
          }
        }
      }
    },
    "S7f": {
      "type": "list",
      "member": {
        "shape": "S1g"
      }
    }
  }
}
},{}],84:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeApplicationVersions": {
      "result_key": "ApplicationVersions"
    },
    "DescribeApplications": {
      "result_key": "Applications"
    },
    "DescribeConfigurationOptions": {
      "result_key": "Options"
    },
    "DescribeEnvironments": {
      "result_key": "Environments"
    },
    "DescribeEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "Events"
    },
    "ListAvailableSolutionStacks": {
      "result_key": "SolutionStacks"
    },
    "ListPlatformBranches": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken"
    }
  }
}
},{}],85:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-02-01",
    "endpointPrefix": "elasticfilesystem",
    "protocol": "rest-json",
    "serviceAbbreviation": "EFS",
    "serviceFullName": "Amazon Elastic File System",
    "serviceId": "EFS",
    "signatureVersion": "v4",
    "uid": "elasticfilesystem-2015-02-01"
  },
  "operations": {
    "CreateAccessPoint": {
      "http": {
        "requestUri": "/2015-02-01/access-points",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "FileSystemId"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S3"
          },
          "FileSystemId": {},
          "PosixUser": {
            "shape": "S8"
          },
          "RootDirectory": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "shape": "Si"
      }
    },
    "CreateFileSystem": {
      "http": {
        "requestUri": "/2015-02-01/file-systems",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "CreationToken"
        ],
        "members": {
          "CreationToken": {
            "idempotencyToken": true
          },
          "PerformanceMode": {},
          "Encrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "ThroughputMode": {},
          "ProvisionedThroughputInMibps": {
            "type": "double"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "Sv"
      }
    },
    "CreateMountTarget": {
      "http": {
        "requestUri": "/2015-02-01/mount-targets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "SubnetId"
        ],
        "members": {
          "FileSystemId": {},
          "SubnetId": {},
          "IpAddress": {},
          "SecurityGroups": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "shape": "S16"
      }
    },
    "CreateTags": {
      "http": {
        "requestUri": "/2015-02-01/create-tags/{FileSystemId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Tags"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Use TagResource."
    },
    "DeleteAccessPoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-02-01/access-points/{AccessPointId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccessPointId"
        ],
        "members": {
          "AccessPointId": {
            "location": "uri",
            "locationName": "AccessPointId"
          }
        }
      }
    },
    "DeleteFileSystem": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          }
        }
      }
    },
    "DeleteFileSystemPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          }
        }
      }
    },
    "DeleteMountTarget": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-02-01/mount-targets/{MountTargetId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MountTargetId"
        ],
        "members": {
          "MountTargetId": {
            "location": "uri",
            "locationName": "MountTargetId"
          }
        }
      }
    },
    "DeleteTags": {
      "http": {
        "requestUri": "/2015-02-01/delete-tags/{FileSystemId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "TagKeys"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          },
          "TagKeys": {
            "shape": "S1h"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Use UntagResource."
    },
    "DescribeAccessPoints": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/access-points",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "AccessPointId": {
            "location": "querystring",
            "locationName": "AccessPointId"
          },
          "FileSystemId": {
            "location": "querystring",
            "locationName": "FileSystemId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessPoints": {
            "type": "list",
            "member": {
              "shape": "Si"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystemPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          }
        }
      },
      "output": {
        "shape": "S1o"
      }
    },
    "DescribeFileSystems": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/file-systems",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "CreationToken": {
            "location": "querystring",
            "locationName": "CreationToken"
          },
          "FileSystemId": {
            "location": "querystring",
            "locationName": "FileSystemId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "FileSystems": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeLifecycleConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}/lifecycle-configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          }
        }
      },
      "output": {
        "shape": "S1w"
      }
    },
    "DescribeMountTargetSecurityGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/mount-targets/{MountTargetId}/security-groups",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MountTargetId"
        ],
        "members": {
          "MountTargetId": {
            "location": "uri",
            "locationName": "MountTargetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SecurityGroups"
        ],
        "members": {
          "SecurityGroups": {
            "shape": "S14"
          }
        }
      }
    },
    "DescribeMountTargets": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/mount-targets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "FileSystemId": {
            "location": "querystring",
            "locationName": "FileSystemId"
          },
          "MountTargetId": {
            "location": "querystring",
            "locationName": "MountTargetId"
          },
          "AccessPointId": {
            "location": "querystring",
            "locationName": "AccessPointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "MountTargets": {
            "type": "list",
            "member": {
              "shape": "S16"
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/tags/{FileSystemId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Marker": {},
          "Tags": {
            "shape": "S3"
          },
          "NextMarker": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Use ListTagsForResource."
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-02-01/resource-tags/{ResourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3"
          },
          "NextToken": {}
        }
      }
    },
    "ModifyMountTargetSecurityGroups": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-02-01/mount-targets/{MountTargetId}/security-groups",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "MountTargetId"
        ],
        "members": {
          "MountTargetId": {
            "location": "uri",
            "locationName": "MountTargetId"
          },
          "SecurityGroups": {
            "shape": "S14"
          }
        }
      }
    },
    "PutFileSystemPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Policy"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          },
          "Policy": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1o"
      }
    },
    "PutLifecycleConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}/lifecycle-configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "LifecyclePolicies"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          },
          "LifecyclePolicies": {
            "shape": "S1x"
          }
        }
      },
      "output": {
        "shape": "S1w"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2015-02-01/resource-tags/{ResourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-02-01/resource-tags/{ResourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "TagKeys": {
            "shape": "S1h",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        }
      }
    },
    "UpdateFileSystem": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-02-01/file-systems/{FileSystemId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {
            "location": "uri",
            "locationName": "FileSystemId"
          },
          "ThroughputMode": {},
          "ProvisionedThroughputInMibps": {
            "type": "double"
          }
        }
      },
      "output": {
        "shape": "Sv"
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Uid",
        "Gid"
      ],
      "members": {
        "Uid": {
          "type": "long"
        },
        "Gid": {
          "type": "long"
        },
        "SecondaryGids": {
          "type": "list",
          "member": {
            "type": "long"
          }
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "Path": {},
        "CreationInfo": {
          "type": "structure",
          "required": [
            "OwnerUid",
            "OwnerGid",
            "Permissions"
          ],
          "members": {
            "OwnerUid": {
              "type": "long"
            },
            "OwnerGid": {
              "type": "long"
            },
            "Permissions": {}
          }
        }
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "ClientToken": {},
        "Name": {},
        "Tags": {
          "shape": "S3"
        },
        "AccessPointId": {},
        "AccessPointArn": {},
        "FileSystemId": {},
        "PosixUser": {
          "shape": "S8"
        },
        "RootDirectory": {
          "shape": "Sc"
        },
        "OwnerId": {},
        "LifeCycleState": {}
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "OwnerId",
        "CreationToken",
        "FileSystemId",
        "CreationTime",
        "LifeCycleState",
        "NumberOfMountTargets",
        "SizeInBytes",
        "PerformanceMode",
        "Tags"
      ],
      "members": {
        "OwnerId": {},
        "CreationToken": {},
        "FileSystemId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "LifeCycleState": {},
        "Name": {},
        "NumberOfMountTargets": {
          "type": "integer"
        },
        "SizeInBytes": {
          "type": "structure",
          "required": [
            "Value"
          ],
          "members": {
            "Value": {
              "type": "long"
            },
            "Timestamp": {
              "type": "timestamp"
            },
            "ValueInIA": {
              "type": "long"
            },
            "ValueInStandard": {
              "type": "long"
            }
          }
        },
        "PerformanceMode": {},
        "Encrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "ThroughputMode": {},
        "ProvisionedThroughputInMibps": {
          "type": "double"
        },
        "Tags": {
          "shape": "S3"
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {}
    },
    "S16": {
      "type": "structure",
      "required": [
        "MountTargetId",
        "FileSystemId",
        "SubnetId",
        "LifeCycleState"
      ],
      "members": {
        "OwnerId": {},
        "MountTargetId": {},
        "FileSystemId": {},
        "SubnetId": {},
        "LifeCycleState": {},
        "IpAddress": {},
        "NetworkInterfaceId": {},
        "AvailabilityZoneId": {},
        "AvailabilityZoneName": {}
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "structure",
      "members": {
        "FileSystemId": {},
        "Policy": {}
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "LifecyclePolicies": {
          "shape": "S1x"
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TransitionToIA": {}
        }
      }
    }
  }
}
},{}],86:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeAccessPoints": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribeFileSystems": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "limit_key": "MaxItems"
    },
    "DescribeTags": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "limit_key": "MaxItems"
    },
    "ListTagsForResource": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],87:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-06-01",
    "endpointPrefix": "elasticloadbalancing",
    "protocol": "query",
    "serviceFullName": "Elastic Load Balancing",
    "serviceId": "Elastic Load Balancing",
    "signatureVersion": "v4",
    "uid": "elasticloadbalancing-2012-06-01",
    "xmlNamespace": "http://elasticloadbalancing.amazonaws.com/doc/2012-06-01/"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerNames",
          "Tags"
        ],
        "members": {
          "LoadBalancerNames": {
            "shape": "S2"
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "resultWrapper": "AddTagsResult",
        "type": "structure",
        "members": {}
      }
    },
    "ApplySecurityGroupsToLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "SecurityGroups"
        ],
        "members": {
          "LoadBalancerName": {},
          "SecurityGroups": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "ApplySecurityGroupsToLoadBalancerResult",
        "type": "structure",
        "members": {
          "SecurityGroups": {
            "shape": "Sa"
          }
        }
      }
    },
    "AttachLoadBalancerToSubnets": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Subnets"
        ],
        "members": {
          "LoadBalancerName": {},
          "Subnets": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancerToSubnetsResult",
        "type": "structure",
        "members": {
          "Subnets": {
            "shape": "Se"
          }
        }
      }
    },
    "ConfigureHealthCheck": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "HealthCheck"
        ],
        "members": {
          "LoadBalancerName": {},
          "HealthCheck": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "resultWrapper": "ConfigureHealthCheckResult",
        "type": "structure",
        "members": {
          "HealthCheck": {
            "shape": "Si"
          }
        }
      }
    },
    "CreateAppCookieStickinessPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "PolicyName",
          "CookieName"
        ],
        "members": {
          "LoadBalancerName": {},
          "PolicyName": {},
          "CookieName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateAppCookieStickinessPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateLBCookieStickinessPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "PolicyName"
        ],
        "members": {
          "LoadBalancerName": {},
          "PolicyName": {},
          "CookieExpirationPeriod": {
            "type": "long"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateLBCookieStickinessPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Listeners"
        ],
        "members": {
          "LoadBalancerName": {},
          "Listeners": {
            "shape": "Sx"
          },
          "AvailabilityZones": {
            "shape": "S13"
          },
          "Subnets": {
            "shape": "Se"
          },
          "SecurityGroups": {
            "shape": "Sa"
          },
          "Scheme": {},
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateLoadBalancerResult",
        "type": "structure",
        "members": {
          "DNSName": {}
        }
      }
    },
    "CreateLoadBalancerListeners": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Listeners"
        ],
        "members": {
          "LoadBalancerName": {},
          "Listeners": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateLoadBalancerListenersResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateLoadBalancerPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "PolicyName",
          "PolicyTypeName"
        ],
        "members": {
          "LoadBalancerName": {},
          "PolicyName": {},
          "PolicyTypeName": {},
          "PolicyAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AttributeName": {},
                "AttributeValue": {}
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "CreateLoadBalancerPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName"
        ],
        "members": {
          "LoadBalancerName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLoadBalancerResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLoadBalancerListeners": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "LoadBalancerPorts"
        ],
        "members": {
          "LoadBalancerName": {},
          "LoadBalancerPorts": {
            "type": "list",
            "member": {
              "type": "integer"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteLoadBalancerListenersResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLoadBalancerPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "PolicyName"
        ],
        "members": {
          "LoadBalancerName": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLoadBalancerPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterInstancesFromLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Instances"
        ],
        "members": {
          "LoadBalancerName": {},
          "Instances": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "resultWrapper": "DeregisterInstancesFromLoadBalancerResult",
        "type": "structure",
        "members": {
          "Instances": {
            "shape": "S1p"
          }
        }
      }
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "Limits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Max": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeInstanceHealth": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName"
        ],
        "members": {
          "LoadBalancerName": {},
          "Instances": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeInstanceHealthResult",
        "type": "structure",
        "members": {
          "InstanceStates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceId": {},
                "State": {},
                "ReasonCode": {},
                "Description": {}
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancerAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName"
        ],
        "members": {
          "LoadBalancerName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerAttributesResult",
        "type": "structure",
        "members": {
          "LoadBalancerAttributes": {
            "shape": "S2a"
          }
        }
      }
    },
    "DescribeLoadBalancerPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "LoadBalancerName": {},
          "PolicyNames": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerPoliciesResult",
        "type": "structure",
        "members": {
          "PolicyDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyName": {},
                "PolicyTypeName": {},
                "PolicyAttributeDescriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AttributeName": {},
                      "AttributeValue": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancerPolicyTypes": {
      "input": {
        "type": "structure",
        "members": {
          "PolicyTypeNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerPolicyTypesResult",
        "type": "structure",
        "members": {
          "PolicyTypeDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyTypeName": {},
                "Description": {},
                "PolicyAttributeTypeDescriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AttributeName": {},
                      "AttributeType": {},
                      "Description": {},
                      "DefaultValue": {},
                      "Cardinality": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancers": {
      "input": {
        "type": "structure",
        "members": {
          "LoadBalancerNames": {
            "shape": "S2"
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancersResult",
        "type": "structure",
        "members": {
          "LoadBalancerDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "DNSName": {},
                "CanonicalHostedZoneName": {},
                "CanonicalHostedZoneNameID": {},
                "ListenerDescriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Listener": {
                        "shape": "Sy"
                      },
                      "PolicyNames": {
                        "shape": "S2s"
                      }
                    }
                  }
                },
                "Policies": {
                  "type": "structure",
                  "members": {
                    "AppCookieStickinessPolicies": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "PolicyName": {},
                          "CookieName": {}
                        }
                      }
                    },
                    "LBCookieStickinessPolicies": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "PolicyName": {},
                          "CookieExpirationPeriod": {
                            "type": "long"
                          }
                        }
                      }
                    },
                    "OtherPolicies": {
                      "shape": "S2s"
                    }
                  }
                },
                "BackendServerDescriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "InstancePort": {
                        "type": "integer"
                      },
                      "PolicyNames": {
                        "shape": "S2s"
                      }
                    }
                  }
                },
                "AvailabilityZones": {
                  "shape": "S13"
                },
                "Subnets": {
                  "shape": "Se"
                },
                "VPCId": {},
                "Instances": {
                  "shape": "S1p"
                },
                "HealthCheck": {
                  "shape": "Si"
                },
                "SourceSecurityGroup": {
                  "type": "structure",
                  "members": {
                    "OwnerAlias": {},
                    "GroupName": {}
                  }
                },
                "SecurityGroups": {
                  "shape": "Sa"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "Scheme": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerNames"
        ],
        "members": {
          "LoadBalancerNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "TagDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "Tags": {
                  "shape": "S4"
                }
              }
            }
          }
        }
      }
    },
    "DetachLoadBalancerFromSubnets": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Subnets"
        ],
        "members": {
          "LoadBalancerName": {},
          "Subnets": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancerFromSubnetsResult",
        "type": "structure",
        "members": {
          "Subnets": {
            "shape": "Se"
          }
        }
      }
    },
    "DisableAvailabilityZonesForLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "AvailabilityZones"
        ],
        "members": {
          "LoadBalancerName": {},
          "AvailabilityZones": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "resultWrapper": "DisableAvailabilityZonesForLoadBalancerResult",
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "shape": "S13"
          }
        }
      }
    },
    "EnableAvailabilityZonesForLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "AvailabilityZones"
        ],
        "members": {
          "LoadBalancerName": {},
          "AvailabilityZones": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "resultWrapper": "EnableAvailabilityZonesForLoadBalancerResult",
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "shape": "S13"
          }
        }
      }
    },
    "ModifyLoadBalancerAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "LoadBalancerAttributes"
        ],
        "members": {
          "LoadBalancerName": {},
          "LoadBalancerAttributes": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyLoadBalancerAttributesResult",
        "type": "structure",
        "members": {
          "LoadBalancerName": {},
          "LoadBalancerAttributes": {
            "shape": "S2a"
          }
        }
      }
    },
    "RegisterInstancesWithLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "Instances"
        ],
        "members": {
          "LoadBalancerName": {},
          "Instances": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "resultWrapper": "RegisterInstancesWithLoadBalancerResult",
        "type": "structure",
        "members": {
          "Instances": {
            "shape": "S1p"
          }
        }
      }
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerNames",
          "Tags"
        ],
        "members": {
          "LoadBalancerNames": {
            "shape": "S2"
          },
          "Tags": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {}
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "RemoveTagsResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetLoadBalancerListenerSSLCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "LoadBalancerPort",
          "SSLCertificateId"
        ],
        "members": {
          "LoadBalancerName": {},
          "LoadBalancerPort": {
            "type": "integer"
          },
          "SSLCertificateId": {}
        }
      },
      "output": {
        "resultWrapper": "SetLoadBalancerListenerSSLCertificateResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetLoadBalancerPoliciesForBackendServer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "InstancePort",
          "PolicyNames"
        ],
        "members": {
          "LoadBalancerName": {},
          "InstancePort": {
            "type": "integer"
          },
          "PolicyNames": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "resultWrapper": "SetLoadBalancerPoliciesForBackendServerResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetLoadBalancerPoliciesOfListener": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerName",
          "LoadBalancerPort",
          "PolicyNames"
        ],
        "members": {
          "LoadBalancerName": {},
          "LoadBalancerPort": {
            "type": "integer"
          },
          "PolicyNames": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "resultWrapper": "SetLoadBalancerPoliciesOfListenerResult",
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "required": [
        "Target",
        "Interval",
        "Timeout",
        "UnhealthyThreshold",
        "HealthyThreshold"
      ],
      "members": {
        "Target": {},
        "Interval": {
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "UnhealthyThreshold": {
          "type": "integer"
        },
        "HealthyThreshold": {
          "type": "integer"
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "shape": "Sy"
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Protocol",
        "LoadBalancerPort",
        "InstancePort"
      ],
      "members": {
        "Protocol": {},
        "LoadBalancerPort": {
          "type": "integer"
        },
        "InstanceProtocol": {},
        "InstancePort": {
          "type": "integer"
        },
        "SSLCertificateId": {}
      }
    },
    "S13": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "InstanceId": {}
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "CrossZoneLoadBalancing": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        },
        "AccessLog": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "S3BucketName": {},
            "EmitInterval": {
              "type": "integer"
            },
            "S3BucketPrefix": {}
          }
        },
        "ConnectionDraining": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "Timeout": {
              "type": "integer"
            }
          }
        },
        "ConnectionSettings": {
          "type": "structure",
          "required": [
            "IdleTimeout"
          ],
          "members": {
            "IdleTimeout": {
              "type": "integer"
            }
          }
        },
        "AdditionalAttributes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S2s": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],88:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeInstanceHealth": {
      "result_key": "InstanceStates"
    },
    "DescribeLoadBalancerPolicies": {
      "result_key": "PolicyDescriptions"
    },
    "DescribeLoadBalancerPolicyTypes": {
      "result_key": "PolicyTypeDescriptions"
    },
    "DescribeLoadBalancers": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "result_key": "LoadBalancerDescriptions"
    }
  }
}
},{}],89:[function(require,module,exports){
module.exports={
    "version":2,
    "waiters":{
        "InstanceDeregistered": {
          "delay": 15,
          "operation": "DescribeInstanceHealth",
          "maxAttempts": 40,
          "acceptors": [
            {
              "expected": "OutOfService",
              "matcher": "pathAll",
              "state": "success",
              "argument": "InstanceStates[].State"
            },
            {
              "matcher": "error",
              "expected": "InvalidInstance",
              "state": "success"
            }
          ]
        },
        "AnyInstanceInService":{
            "acceptors":[
                {
                    "argument":"InstanceStates[].State",
                    "expected":"InService",
                    "matcher":"pathAny",
                    "state":"success"
                }
            ],
            "delay":15,
            "maxAttempts":40,
            "operation":"DescribeInstanceHealth"
        },
        "InstanceInService":{
            "acceptors":[
                {
                    "argument":"InstanceStates[].State",
                    "expected":"InService",
                    "matcher":"pathAll",
                    "state":"success"
                },
                {
                    "matcher": "error",
                    "expected": "InvalidInstance",
                    "state": "retry"
                }
            ],
            "delay":15,
            "maxAttempts":40,
            "operation":"DescribeInstanceHealth"
        }
    }
}

},{}],90:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-12-01",
    "endpointPrefix": "elasticloadbalancing",
    "protocol": "query",
    "serviceAbbreviation": "Elastic Load Balancing v2",
    "serviceFullName": "Elastic Load Balancing",
    "serviceId": "Elastic Load Balancing v2",
    "signatureVersion": "v4",
    "uid": "elasticloadbalancingv2-2015-12-01",
    "xmlNamespace": "http://elasticloadbalancing.amazonaws.com/doc/2015-12-01/"
  },
  "operations": {
    "AddListenerCertificates": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "Certificates"
        ],
        "members": {
          "ListenerArn": {},
          "Certificates": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "AddListenerCertificatesResult",
        "type": "structure",
        "members": {
          "Certificates": {
            "shape": "S3"
          }
        }
      }
    },
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArns",
          "Tags"
        ],
        "members": {
          "ResourceArns": {
            "shape": "S9"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "AddTagsResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateListener": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn",
          "Protocol",
          "Port",
          "DefaultActions"
        ],
        "members": {
          "LoadBalancerArn": {},
          "Protocol": {},
          "Port": {
            "type": "integer"
          },
          "SslPolicy": {},
          "Certificates": {
            "shape": "S3"
          },
          "DefaultActions": {
            "shape": "Sl"
          },
          "AlpnPolicy": {
            "shape": "S1y"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateListenerResult",
        "type": "structure",
        "members": {
          "Listeners": {
            "shape": "S21"
          }
        }
      }
    },
    "CreateLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Subnets": {
            "shape": "S25"
          },
          "SubnetMappings": {
            "shape": "S27"
          },
          "SecurityGroups": {
            "shape": "S2b"
          },
          "Scheme": {},
          "Tags": {
            "shape": "Sb"
          },
          "Type": {},
          "IpAddressType": {}
        }
      },
      "output": {
        "resultWrapper": "CreateLoadBalancerResult",
        "type": "structure",
        "members": {
          "LoadBalancers": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateRule": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "Conditions",
          "Priority",
          "Actions"
        ],
        "members": {
          "ListenerArn": {},
          "Conditions": {
            "shape": "S2x"
          },
          "Priority": {
            "type": "integer"
          },
          "Actions": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateRuleResult",
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S3d"
          }
        }
      }
    },
    "CreateTargetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Protocol": {},
          "Port": {
            "type": "integer"
          },
          "VpcId": {},
          "HealthCheckProtocol": {},
          "HealthCheckPort": {},
          "HealthCheckEnabled": {
            "type": "boolean"
          },
          "HealthCheckPath": {},
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "HealthCheckTimeoutSeconds": {
            "type": "integer"
          },
          "HealthyThresholdCount": {
            "type": "integer"
          },
          "UnhealthyThresholdCount": {
            "type": "integer"
          },
          "Matcher": {
            "shape": "S3q"
          },
          "TargetType": {}
        }
      },
      "output": {
        "resultWrapper": "CreateTargetGroupResult",
        "type": "structure",
        "members": {
          "TargetGroups": {
            "shape": "S3u"
          }
        }
      }
    },
    "DeleteListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteListenerResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn"
        ],
        "members": {
          "LoadBalancerArn": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLoadBalancerResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleArn"
        ],
        "members": {
          "RuleArn": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTargetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn"
        ],
        "members": {
          "TargetGroupArn": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteTargetGroupResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterTargets": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn",
          "Targets"
        ],
        "members": {
          "TargetGroupArn": {},
          "Targets": {
            "shape": "S46"
          }
        }
      },
      "output": {
        "resultWrapper": "DeregisterTargetsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "Limits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Max": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeListenerCertificates": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeListenerCertificatesResult",
        "type": "structure",
        "members": {
          "Certificates": {
            "shape": "S3"
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeListeners": {
      "input": {
        "type": "structure",
        "members": {
          "LoadBalancerArn": {},
          "ListenerArns": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeListenersResult",
        "type": "structure",
        "members": {
          "Listeners": {
            "shape": "S21"
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeLoadBalancerAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn"
        ],
        "members": {
          "LoadBalancerArn": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S4p"
          }
        }
      }
    },
    "DescribeLoadBalancers": {
      "input": {
        "type": "structure",
        "members": {
          "LoadBalancerArns": {
            "shape": "S3w"
          },
          "Names": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancersResult",
        "type": "structure",
        "members": {
          "LoadBalancers": {
            "shape": "S2h"
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeRules": {
      "input": {
        "type": "structure",
        "members": {
          "ListenerArn": {},
          "RuleArns": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeRulesResult",
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S3d"
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeSSLPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSSLPoliciesResult",
        "type": "structure",
        "members": {
          "SslPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SslProtocols": {
                  "type": "list",
                  "member": {}
                },
                "Ciphers": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Priority": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "Name": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArns"
        ],
        "members": {
          "ResourceArns": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "TagDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceArn": {},
                "Tags": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTargetGroupAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn"
        ],
        "members": {
          "TargetGroupArn": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTargetGroupAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S5g"
          }
        }
      }
    },
    "DescribeTargetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "LoadBalancerArn": {},
          "TargetGroupArns": {
            "type": "list",
            "member": {}
          },
          "Names": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTargetGroupsResult",
        "type": "structure",
        "members": {
          "TargetGroups": {
            "shape": "S3u"
          },
          "NextMarker": {}
        }
      }
    },
    "DescribeTargetHealth": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn"
        ],
        "members": {
          "TargetGroupArn": {},
          "Targets": {
            "shape": "S46"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTargetHealthResult",
        "type": "structure",
        "members": {
          "TargetHealthDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Target": {
                  "shape": "S47"
                },
                "HealthCheckPort": {},
                "TargetHealth": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "Reason": {},
                    "Description": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "ModifyListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "Port": {
            "type": "integer"
          },
          "Protocol": {},
          "SslPolicy": {},
          "Certificates": {
            "shape": "S3"
          },
          "DefaultActions": {
            "shape": "Sl"
          },
          "AlpnPolicy": {
            "shape": "S1y"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyListenerResult",
        "type": "structure",
        "members": {
          "Listeners": {
            "shape": "S21"
          }
        }
      }
    },
    "ModifyLoadBalancerAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn",
          "Attributes"
        ],
        "members": {
          "LoadBalancerArn": {},
          "Attributes": {
            "shape": "S4p"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyLoadBalancerAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S4p"
          }
        }
      }
    },
    "ModifyRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleArn"
        ],
        "members": {
          "RuleArn": {},
          "Conditions": {
            "shape": "S2x"
          },
          "Actions": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyRuleResult",
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S3d"
          }
        }
      }
    },
    "ModifyTargetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn"
        ],
        "members": {
          "TargetGroupArn": {},
          "HealthCheckProtocol": {},
          "HealthCheckPort": {},
          "HealthCheckPath": {},
          "HealthCheckEnabled": {
            "type": "boolean"
          },
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "HealthCheckTimeoutSeconds": {
            "type": "integer"
          },
          "HealthyThresholdCount": {
            "type": "integer"
          },
          "UnhealthyThresholdCount": {
            "type": "integer"
          },
          "Matcher": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyTargetGroupResult",
        "type": "structure",
        "members": {
          "TargetGroups": {
            "shape": "S3u"
          }
        }
      }
    },
    "ModifyTargetGroupAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn",
          "Attributes"
        ],
        "members": {
          "TargetGroupArn": {},
          "Attributes": {
            "shape": "S5g"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyTargetGroupAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S5g"
          }
        }
      }
    },
    "RegisterTargets": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupArn",
          "Targets"
        ],
        "members": {
          "TargetGroupArn": {},
          "Targets": {
            "shape": "S46"
          }
        }
      },
      "output": {
        "resultWrapper": "RegisterTargetsResult",
        "type": "structure",
        "members": {}
      }
    },
    "RemoveListenerCertificates": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "Certificates"
        ],
        "members": {
          "ListenerArn": {},
          "Certificates": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "resultWrapper": "RemoveListenerCertificatesResult",
        "type": "structure",
        "members": {}
      }
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArns",
          "TagKeys"
        ],
        "members": {
          "ResourceArns": {
            "shape": "S9"
          },
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "RemoveTagsResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIpAddressType": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn",
          "IpAddressType"
        ],
        "members": {
          "LoadBalancerArn": {},
          "IpAddressType": {}
        }
      },
      "output": {
        "resultWrapper": "SetIpAddressTypeResult",
        "type": "structure",
        "members": {
          "IpAddressType": {}
        }
      }
    },
    "SetRulePriorities": {
      "input": {
        "type": "structure",
        "required": [
          "RulePriorities"
        ],
        "members": {
          "RulePriorities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleArn": {},
                "Priority": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "SetRulePrioritiesResult",
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S3d"
          }
        }
      }
    },
    "SetSecurityGroups": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn",
          "SecurityGroups"
        ],
        "members": {
          "LoadBalancerArn": {},
          "SecurityGroups": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "resultWrapper": "SetSecurityGroupsResult",
        "type": "structure",
        "members": {
          "SecurityGroupIds": {
            "shape": "S2b"
          }
        }
      }
    },
    "SetSubnets": {
      "input": {
        "type": "structure",
        "required": [
          "LoadBalancerArn"
        ],
        "members": {
          "LoadBalancerArn": {},
          "Subnets": {
            "shape": "S25"
          },
          "SubnetMappings": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "resultWrapper": "SetSubnetsResult",
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "shape": "S2q"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CertificateArn": {},
          "IsDefault": {
            "type": "boolean"
          }
        }
      }
    },
    "S9": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "Type": {},
          "TargetGroupArn": {},
          "AuthenticateOidcConfig": {
            "type": "structure",
            "required": [
              "Issuer",
              "AuthorizationEndpoint",
              "TokenEndpoint",
              "UserInfoEndpoint",
              "ClientId"
            ],
            "members": {
              "Issuer": {},
              "AuthorizationEndpoint": {},
              "TokenEndpoint": {},
              "UserInfoEndpoint": {},
              "ClientId": {},
              "ClientSecret": {},
              "SessionCookieName": {},
              "Scope": {},
              "SessionTimeout": {
                "type": "long"
              },
              "AuthenticationRequestExtraParams": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "OnUnauthenticatedRequest": {},
              "UseExistingClientSecret": {
                "type": "boolean"
              }
            }
          },
          "AuthenticateCognitoConfig": {
            "type": "structure",
            "required": [
              "UserPoolArn",
              "UserPoolClientId",
              "UserPoolDomain"
            ],
            "members": {
              "UserPoolArn": {},
              "UserPoolClientId": {},
              "UserPoolDomain": {},
              "SessionCookieName": {},
              "Scope": {},
              "SessionTimeout": {
                "type": "long"
              },
              "AuthenticationRequestExtraParams": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "OnUnauthenticatedRequest": {}
            }
          },
          "Order": {
            "type": "integer"
          },
          "RedirectConfig": {
            "type": "structure",
            "required": [
              "StatusCode"
            ],
            "members": {
              "Protocol": {},
              "Port": {},
              "Host": {},
              "Path": {},
              "Query": {},
              "StatusCode": {}
            }
          },
          "FixedResponseConfig": {
            "type": "structure",
            "required": [
              "StatusCode"
            ],
            "members": {
              "MessageBody": {},
              "StatusCode": {},
              "ContentType": {}
            }
          },
          "ForwardConfig": {
            "type": "structure",
            "members": {
              "TargetGroups": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "TargetGroupArn": {},
                    "Weight": {
                      "type": "integer"
                    }
                  }
                }
              },
              "TargetGroupStickinessConfig": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  },
                  "DurationSeconds": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {}
    },
    "S21": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ListenerArn": {},
          "LoadBalancerArn": {},
          "Port": {
            "type": "integer"
          },
          "Protocol": {},
          "Certificates": {
            "shape": "S3"
          },
          "SslPolicy": {},
          "DefaultActions": {
            "shape": "Sl"
          },
          "AlpnPolicy": {
            "shape": "S1y"
          }
        }
      }
    },
    "S25": {
      "type": "list",
      "member": {}
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SubnetId": {},
          "AllocationId": {},
          "PrivateIPv4Address": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LoadBalancerArn": {},
          "DNSName": {},
          "CanonicalHostedZoneId": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LoadBalancerName": {},
          "Scheme": {},
          "VpcId": {},
          "State": {
            "type": "structure",
            "members": {
              "Code": {},
              "Reason": {}
            }
          },
          "Type": {},
          "AvailabilityZones": {
            "shape": "S2q"
          },
          "SecurityGroups": {
            "shape": "S2b"
          },
          "IpAddressType": {}
        }
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ZoneName": {},
          "SubnetId": {},
          "LoadBalancerAddresses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IpAddress": {},
                "AllocationId": {},
                "PrivateIPv4Address": {}
              }
            }
          }
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Field": {},
          "Values": {
            "shape": "S30"
          },
          "HostHeaderConfig": {
            "type": "structure",
            "members": {
              "Values": {
                "shape": "S30"
              }
            }
          },
          "PathPatternConfig": {
            "type": "structure",
            "members": {
              "Values": {
                "shape": "S30"
              }
            }
          },
          "HttpHeaderConfig": {
            "type": "structure",
            "members": {
              "HttpHeaderName": {},
              "Values": {
                "shape": "S30"
              }
            }
          },
          "QueryStringConfig": {
            "type": "structure",
            "members": {
              "Values": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Key": {},
                    "Value": {}
                  }
                }
              }
            }
          },
          "HttpRequestMethodConfig": {
            "type": "structure",
            "members": {
              "Values": {
                "shape": "S30"
              }
            }
          },
          "SourceIpConfig": {
            "type": "structure",
            "members": {
              "Values": {
                "shape": "S30"
              }
            }
          }
        }
      }
    },
    "S30": {
      "type": "list",
      "member": {}
    },
    "S3d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RuleArn": {},
          "Priority": {},
          "Conditions": {
            "shape": "S2x"
          },
          "Actions": {
            "shape": "Sl"
          },
          "IsDefault": {
            "type": "boolean"
          }
        }
      }
    },
    "S3q": {
      "type": "structure",
      "required": [
        "HttpCode"
      ],
      "members": {
        "HttpCode": {}
      }
    },
    "S3u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TargetGroupArn": {},
          "TargetGroupName": {},
          "Protocol": {},
          "Port": {
            "type": "integer"
          },
          "VpcId": {},
          "HealthCheckProtocol": {},
          "HealthCheckPort": {},
          "HealthCheckEnabled": {
            "type": "boolean"
          },
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "HealthCheckTimeoutSeconds": {
            "type": "integer"
          },
          "HealthyThresholdCount": {
            "type": "integer"
          },
          "UnhealthyThresholdCount": {
            "type": "integer"
          },
          "HealthCheckPath": {},
          "Matcher": {
            "shape": "S3q"
          },
          "LoadBalancerArns": {
            "shape": "S3w"
          },
          "TargetType": {}
        }
      }
    },
    "S3w": {
      "type": "list",
      "member": {}
    },
    "S46": {
      "type": "list",
      "member": {
        "shape": "S47"
      }
    },
    "S47": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {}
      }
    },
    "S4p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S5g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}
},{}],91:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeListeners": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "result_key": "Listeners"
    },
    "DescribeLoadBalancers": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "result_key": "LoadBalancers"
    },
    "DescribeTargetGroups": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "result_key": "TargetGroups"
    }
  }
}
},{}],92:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "LoadBalancerExists": {
      "delay": 15,
      "operation": "DescribeLoadBalancers",
      "maxAttempts": 40,
      "acceptors": [
        {
          "matcher": "status",
          "expected": 200,
          "state": "success"
        },
        {
          "matcher": "error",
          "expected": "LoadBalancerNotFound",
          "state": "retry"
        }
      ]
    },
    "LoadBalancerAvailable": {
      "delay": 15,
      "operation": "DescribeLoadBalancers",
      "maxAttempts": 40,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "LoadBalancers[].State.Code",
          "expected": "active"
        },
        {
          "state": "retry",
          "matcher": "pathAny",
          "argument": "LoadBalancers[].State.Code",
          "expected": "provisioning"
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "LoadBalancerNotFound"
        }
      ]
    },
    "LoadBalancersDeleted": {
      "delay": 15,
      "operation": "DescribeLoadBalancers",
      "maxAttempts": 40,
      "acceptors": [
        {
          "state": "retry",
          "matcher": "pathAll",
          "argument": "LoadBalancers[].State.Code",
          "expected": "active"
        },
        {
          "matcher": "error",
          "expected": "LoadBalancerNotFound",
          "state": "success"
        }
      ]
    },
    "TargetInService":{
      "delay":15,
      "maxAttempts":40,
      "operation":"DescribeTargetHealth",
      "acceptors":[
        {
          "argument":"TargetHealthDescriptions[].TargetHealth.State",
          "expected":"healthy",
          "matcher":"pathAll",
          "state":"success"
        },
        {
          "matcher": "error",
          "expected": "InvalidInstance",
          "state": "retry"
        }
      ]
    },
    "TargetDeregistered": {
      "delay": 15,
      "maxAttempts": 40,
      "operation": "DescribeTargetHealth",
      "acceptors": [
        {
          "matcher": "error",
          "expected": "InvalidTarget",
          "state": "success"
        },
        {
          "argument":"TargetHealthDescriptions[].TargetHealth.State",
          "expected":"unused",
          "matcher":"pathAll",
          "state":"success"
        }
      ]
    }
  }
}

},{}],93:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2009-03-31",
    "endpointPrefix": "elasticmapreduce",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon EMR",
    "serviceFullName": "Amazon Elastic MapReduce",
    "serviceId": "EMR",
    "signatureVersion": "v4",
    "targetPrefix": "ElasticMapReduce",
    "uid": "elasticmapreduce-2009-03-31"
  },
  "operations": {
    "AddInstanceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceFleet"
        ],
        "members": {
          "ClusterId": {},
          "InstanceFleet": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceFleetId": {},
          "ClusterArn": {}
        }
      }
    },
    "AddInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceGroups",
          "JobFlowId"
        ],
        "members": {
          "InstanceGroups": {
            "shape": "Sr"
          },
          "JobFlowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {},
          "InstanceGroupIds": {
            "type": "list",
            "member": {}
          },
          "ClusterArn": {}
        }
      }
    },
    "AddJobFlowSteps": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowId",
          "Steps"
        ],
        "members": {
          "JobFlowId": {},
          "Steps": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StepIds": {
            "shape": "S1l"
          }
        }
      }
    },
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelSteps": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "StepIds"
        ],
        "members": {
          "ClusterId": {},
          "StepIds": {
            "shape": "S1l"
          },
          "StepCancellationOption": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelStepsInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StepId": {},
                "Status": {},
                "Reason": {}
              }
            }
          }
        }
      }
    },
    "CreateSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SecurityConfiguration"
        ],
        "members": {
          "Name": {},
          "SecurityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name",
          "CreationDateTime"
        ],
        "members": {
          "Name": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DeleteSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Status": {
                "shape": "S27"
              },
              "Ec2InstanceAttributes": {
                "type": "structure",
                "members": {
                  "Ec2KeyName": {},
                  "Ec2SubnetId": {},
                  "RequestedEc2SubnetIds": {
                    "shape": "S2d"
                  },
                  "Ec2AvailabilityZone": {},
                  "RequestedEc2AvailabilityZones": {
                    "shape": "S2d"
                  },
                  "IamInstanceProfile": {},
                  "EmrManagedMasterSecurityGroup": {},
                  "EmrManagedSlaveSecurityGroup": {},
                  "ServiceAccessSecurityGroup": {},
                  "AdditionalMasterSecurityGroups": {
                    "shape": "S2e"
                  },
                  "AdditionalSlaveSecurityGroups": {
                    "shape": "S2e"
                  }
                }
              },
              "InstanceCollectionType": {},
              "LogUri": {},
              "LogEncryptionKmsKeyId": {},
              "RequestedAmiVersion": {},
              "RunningAmiVersion": {},
              "ReleaseLabel": {},
              "AutoTerminate": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "VisibleToAllUsers": {
                "type": "boolean"
              },
              "Applications": {
                "shape": "S2h"
              },
              "Tags": {
                "shape": "S1o"
              },
              "ServiceRole": {},
              "NormalizedInstanceHours": {
                "type": "integer"
              },
              "MasterPublicDnsName": {},
              "Configurations": {
                "shape": "Sh"
              },
              "SecurityConfiguration": {},
              "AutoScalingRole": {},
              "ScaleDownBehavior": {},
              "CustomAmiId": {},
              "EbsRootVolumeSize": {
                "type": "integer"
              },
              "RepoUpgradeOnBoot": {},
              "KerberosAttributes": {
                "shape": "S2l"
              },
              "ClusterArn": {},
              "OutpostArn": {},
              "StepConcurrencyLevel": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "DescribeJobFlows": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "JobFlowIds": {
            "shape": "S1j"
          },
          "JobFlowStates": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlows": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobFlowId",
                "Name",
                "ExecutionStatusDetail",
                "Instances"
              ],
              "members": {
                "JobFlowId": {},
                "Name": {},
                "LogUri": {},
                "LogEncryptionKmsKeyId": {},
                "AmiVersion": {},
                "ExecutionStatusDetail": {
                  "type": "structure",
                  "required": [
                    "State",
                    "CreationDateTime"
                  ],
                  "members": {
                    "State": {},
                    "CreationDateTime": {
                      "type": "timestamp"
                    },
                    "StartDateTime": {
                      "type": "timestamp"
                    },
                    "ReadyDateTime": {
                      "type": "timestamp"
                    },
                    "EndDateTime": {
                      "type": "timestamp"
                    },
                    "LastStateChangeReason": {}
                  }
                },
                "Instances": {
                  "type": "structure",
                  "required": [
                    "MasterInstanceType",
                    "SlaveInstanceType",
                    "InstanceCount"
                  ],
                  "members": {
                    "MasterInstanceType": {},
                    "MasterPublicDnsName": {},
                    "MasterInstanceId": {},
                    "SlaveInstanceType": {},
                    "InstanceCount": {
                      "type": "integer"
                    },
                    "InstanceGroups": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "Market",
                          "InstanceRole",
                          "InstanceType",
                          "InstanceRequestCount",
                          "InstanceRunningCount",
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "InstanceGroupId": {},
                          "Name": {},
                          "Market": {},
                          "InstanceRole": {},
                          "BidPrice": {},
                          "InstanceType": {},
                          "InstanceRequestCount": {
                            "type": "integer"
                          },
                          "InstanceRunningCount": {
                            "type": "integer"
                          },
                          "State": {},
                          "LastStateChangeReason": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "ReadyDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          }
                        }
                      }
                    },
                    "NormalizedInstanceHours": {
                      "type": "integer"
                    },
                    "Ec2KeyName": {},
                    "Ec2SubnetId": {},
                    "Placement": {
                      "shape": "S2y"
                    },
                    "KeepJobFlowAliveWhenNoSteps": {
                      "type": "boolean"
                    },
                    "TerminationProtected": {
                      "type": "boolean"
                    },
                    "HadoopVersion": {}
                  }
                },
                "Steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "StepConfig",
                      "ExecutionStatusDetail"
                    ],
                    "members": {
                      "StepConfig": {
                        "shape": "S1d"
                      },
                      "ExecutionStatusDetail": {
                        "type": "structure",
                        "required": [
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "State": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          },
                          "LastStateChangeReason": {}
                        }
                      }
                    }
                  }
                },
                "BootstrapActions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "BootstrapActionConfig": {
                        "shape": "S35"
                      }
                    }
                  }
                },
                "SupportedProducts": {
                  "shape": "S37"
                },
                "VisibleToAllUsers": {
                  "type": "boolean"
                },
                "JobFlowRole": {},
                "ServiceRole": {},
                "AutoScalingRole": {},
                "ScaleDownBehavior": {}
              }
            }
          }
        }
      },
      "deprecated": true
    },
    "DescribeSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "SecurityConfiguration": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStep": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "StepId"
        ],
        "members": {
          "ClusterId": {},
          "StepId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Step": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Config": {
                "shape": "S3d"
              },
              "ActionOnFailure": {},
              "Status": {
                "shape": "S3e"
              }
            }
          }
        }
      }
    },
    "GetBlockPublicAccessConfiguration": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "BlockPublicAccessConfiguration",
          "BlockPublicAccessConfigurationMetadata"
        ],
        "members": {
          "BlockPublicAccessConfiguration": {
            "shape": "S3m"
          },
          "BlockPublicAccessConfigurationMetadata": {
            "type": "structure",
            "required": [
              "CreationDateTime",
              "CreatedByArn"
            ],
            "members": {
              "CreationDateTime": {
                "type": "timestamp"
              },
              "CreatedByArn": {}
            }
          }
        }
      }
    },
    "GetManagedScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ManagedScalingPolicy": {
            "shape": "S3t"
          }
        }
      }
    },
    "ListBootstrapActions": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BootstrapActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "ScriptPath": {},
                "Args": {
                  "shape": "S2e"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "ClusterStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Clusters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {
                  "shape": "S27"
                },
                "NormalizedInstanceHours": {
                  "type": "integer"
                },
                "ClusterArn": {},
                "OutpostArn": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceFleets": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFleets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "InstanceFleetType": {},
                "TargetOnDemandCapacity": {
                  "type": "integer"
                },
                "TargetSpotCapacity": {
                  "type": "integer"
                },
                "ProvisionedOnDemandCapacity": {
                  "type": "integer"
                },
                "ProvisionedSpotCapacity": {
                  "type": "integer"
                },
                "InstanceTypeSpecifications": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "InstanceType": {},
                      "WeightedCapacity": {
                        "type": "integer"
                      },
                      "BidPrice": {},
                      "BidPriceAsPercentageOfOnDemandPrice": {
                        "type": "double"
                      },
                      "Configurations": {
                        "shape": "Sh"
                      },
                      "EbsBlockDevices": {
                        "shape": "S4h"
                      },
                      "EbsOptimized": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "LaunchSpecifications": {
                  "shape": "Sk"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Market": {},
                "InstanceGroupType": {},
                "BidPrice": {},
                "InstanceType": {},
                "RequestedInstanceCount": {
                  "type": "integer"
                },
                "RunningInstanceCount": {
                  "type": "integer"
                },
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "Configurations": {
                  "shape": "Sh"
                },
                "ConfigurationsVersion": {
                  "type": "long"
                },
                "LastSuccessfullyAppliedConfigurations": {
                  "shape": "Sh"
                },
                "LastSuccessfullyAppliedConfigurationsVersion": {
                  "type": "long"
                },
                "EbsBlockDevices": {
                  "shape": "S4h"
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "ShrinkPolicy": {
                  "shape": "S4u"
                },
                "AutoScalingPolicy": {
                  "shape": "S4y"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "InstanceGroupTypes": {
            "type": "list",
            "member": {}
          },
          "InstanceFleetId": {},
          "InstanceFleetType": {},
          "InstanceStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Ec2InstanceId": {},
                "PublicDnsName": {},
                "PublicIpAddress": {},
                "PrivateDnsName": {},
                "PrivateIpAddress": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "InstanceGroupId": {},
                "InstanceFleetId": {},
                "Market": {},
                "InstanceType": {},
                "EbsVolumes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Device": {},
                      "VolumeId": {}
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSecurityConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "CreationDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSteps": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "StepStates": {
            "type": "list",
            "member": {}
          },
          "StepIds": {
            "shape": "S1j"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Steps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Config": {
                  "shape": "S3d"
                },
                "ActionOnFailure": {},
                "Status": {
                  "shape": "S3e"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ModifyCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "StepConcurrencyLevel": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StepConcurrencyLevel": {
            "type": "integer"
          }
        }
      }
    },
    "ModifyInstanceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceFleet"
        ],
        "members": {
          "ClusterId": {},
          "InstanceFleet": {
            "type": "structure",
            "required": [
              "InstanceFleetId"
            ],
            "members": {
              "InstanceFleetId": {},
              "TargetOnDemandCapacity": {
                "type": "integer"
              },
              "TargetSpotCapacity": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "ModifyInstanceGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceGroupId"
              ],
              "members": {
                "InstanceGroupId": {},
                "InstanceCount": {
                  "type": "integer"
                },
                "EC2InstanceIdsToTerminate": {
                  "type": "list",
                  "member": {}
                },
                "ShrinkPolicy": {
                  "shape": "S4u"
                },
                "Configurations": {
                  "shape": "Sh"
                }
              }
            }
          }
        }
      }
    },
    "PutAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId",
          "AutoScalingPolicy"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "S4y"
          },
          "ClusterArn": {}
        }
      }
    },
    "PutBlockPublicAccessConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "BlockPublicAccessConfiguration"
        ],
        "members": {
          "BlockPublicAccessConfiguration": {
            "shape": "S3m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutManagedScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "ManagedScalingPolicy"
        ],
        "members": {
          "ClusterId": {},
          "ManagedScalingPolicy": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveManagedScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RunJobFlow": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Instances"
        ],
        "members": {
          "Name": {},
          "LogUri": {},
          "LogEncryptionKmsKeyId": {},
          "AdditionalInfo": {},
          "AmiVersion": {},
          "ReleaseLabel": {},
          "Instances": {
            "type": "structure",
            "members": {
              "MasterInstanceType": {},
              "SlaveInstanceType": {},
              "InstanceCount": {
                "type": "integer"
              },
              "InstanceGroups": {
                "shape": "Sr"
              },
              "InstanceFleets": {
                "type": "list",
                "member": {
                  "shape": "S3"
                }
              },
              "Ec2KeyName": {},
              "Placement": {
                "shape": "S2y"
              },
              "KeepJobFlowAliveWhenNoSteps": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "HadoopVersion": {},
              "Ec2SubnetId": {},
              "Ec2SubnetIds": {
                "shape": "S2d"
              },
              "EmrManagedMasterSecurityGroup": {},
              "EmrManagedSlaveSecurityGroup": {},
              "ServiceAccessSecurityGroup": {},
              "AdditionalMasterSecurityGroups": {
                "shape": "S6c"
              },
              "AdditionalSlaveSecurityGroups": {
                "shape": "S6c"
              }
            }
          },
          "Steps": {
            "shape": "S1c"
          },
          "BootstrapActions": {
            "type": "list",
            "member": {
              "shape": "S35"
            }
          },
          "SupportedProducts": {
            "shape": "S37"
          },
          "NewSupportedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Args": {
                  "shape": "S1j"
                }
              }
            }
          },
          "Applications": {
            "shape": "S2h"
          },
          "Configurations": {
            "shape": "Sh"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          },
          "JobFlowRole": {},
          "ServiceRole": {},
          "Tags": {
            "shape": "S1o"
          },
          "SecurityConfiguration": {},
          "AutoScalingRole": {},
          "ScaleDownBehavior": {},
          "CustomAmiId": {},
          "EbsRootVolumeSize": {
            "type": "integer"
          },
          "RepoUpgradeOnBoot": {},
          "KerberosAttributes": {
            "shape": "S2l"
          },
          "StepConcurrencyLevel": {
            "type": "integer"
          },
          "ManagedScalingPolicy": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {},
          "ClusterArn": {}
        }
      }
    },
    "SetTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "TerminationProtected"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1j"
          },
          "TerminationProtected": {
            "type": "boolean"
          }
        }
      }
    },
    "SetVisibleToAllUsers": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "VisibleToAllUsers"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1j"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          }
        }
      }
    },
    "TerminateJobFlows": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1j"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "InstanceFleetType"
      ],
      "members": {
        "Name": {},
        "InstanceFleetType": {},
        "TargetOnDemandCapacity": {
          "type": "integer"
        },
        "TargetSpotCapacity": {
          "type": "integer"
        },
        "InstanceTypeConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "InstanceType"
            ],
            "members": {
              "InstanceType": {},
              "WeightedCapacity": {
                "type": "integer"
              },
              "BidPrice": {},
              "BidPriceAsPercentageOfOnDemandPrice": {
                "type": "double"
              },
              "EbsConfiguration": {
                "shape": "Sa"
              },
              "Configurations": {
                "shape": "Sh"
              }
            }
          }
        },
        "LaunchSpecifications": {
          "shape": "Sk"
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "EbsBlockDeviceConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "VolumeSpecification"
            ],
            "members": {
              "VolumeSpecification": {
                "shape": "Sd"
              },
              "VolumesPerInstance": {
                "type": "integer"
              }
            }
          }
        },
        "EbsOptimized": {
          "type": "boolean"
        }
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "VolumeType",
        "SizeInGB"
      ],
      "members": {
        "VolumeType": {},
        "Iops": {
          "type": "integer"
        },
        "SizeInGB": {
          "type": "integer"
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Classification": {},
          "Configurations": {
            "shape": "Sh"
          },
          "Properties": {
            "shape": "Sj"
          }
        }
      }
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sk": {
      "type": "structure",
      "required": [
        "SpotSpecification"
      ],
      "members": {
        "SpotSpecification": {
          "type": "structure",
          "required": [
            "TimeoutDurationMinutes",
            "TimeoutAction"
          ],
          "members": {
            "TimeoutDurationMinutes": {
              "type": "integer"
            },
            "TimeoutAction": {},
            "BlockDurationMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceRole",
          "InstanceType",
          "InstanceCount"
        ],
        "members": {
          "Name": {},
          "Market": {},
          "InstanceRole": {},
          "BidPrice": {},
          "InstanceType": {},
          "InstanceCount": {
            "type": "integer"
          },
          "Configurations": {
            "shape": "Sh"
          },
          "EbsConfiguration": {
            "shape": "Sa"
          },
          "AutoScalingPolicy": {
            "shape": "Sv"
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "Constraints",
        "Rules"
      ],
      "members": {
        "Constraints": {
          "shape": "Sw"
        },
        "Rules": {
          "shape": "Sx"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "MinCapacity",
        "MaxCapacity"
      ],
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Action",
          "Trigger"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Action": {
            "type": "structure",
            "required": [
              "SimpleScalingPolicyConfiguration"
            ],
            "members": {
              "Market": {},
              "SimpleScalingPolicyConfiguration": {
                "type": "structure",
                "required": [
                  "ScalingAdjustment"
                ],
                "members": {
                  "AdjustmentType": {},
                  "ScalingAdjustment": {
                    "type": "integer"
                  },
                  "CoolDown": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "Trigger": {
            "type": "structure",
            "required": [
              "CloudWatchAlarmDefinition"
            ],
            "members": {
              "CloudWatchAlarmDefinition": {
                "type": "structure",
                "required": [
                  "ComparisonOperator",
                  "MetricName",
                  "Period",
                  "Threshold"
                ],
                "members": {
                  "ComparisonOperator": {},
                  "EvaluationPeriods": {
                    "type": "integer"
                  },
                  "MetricName": {},
                  "Namespace": {},
                  "Period": {
                    "type": "integer"
                  },
                  "Statistic": {},
                  "Threshold": {
                    "type": "double"
                  },
                  "Unit": {},
                  "Dimensions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Key": {},
                        "Value": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "shape": "S1d"
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "Name",
        "HadoopJarStep"
      ],
      "members": {
        "Name": {},
        "ActionOnFailure": {},
        "HadoopJarStep": {
          "type": "structure",
          "required": [
            "Jar"
          ],
          "members": {
            "Properties": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Key": {},
                  "Value": {}
                }
              }
            },
            "Jar": {},
            "MainClass": {},
            "Args": {
              "shape": "S1j"
            }
          }
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "ReadyDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2e": {
      "type": "list",
      "member": {}
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "Args": {
            "shape": "S2e"
          },
          "AdditionalInfo": {
            "shape": "Sj"
          }
        }
      }
    },
    "S2l": {
      "type": "structure",
      "required": [
        "Realm",
        "KdcAdminPassword"
      ],
      "members": {
        "Realm": {},
        "KdcAdminPassword": {},
        "CrossRealmTrustPrincipalPassword": {},
        "ADDomainJoinUser": {},
        "ADDomainJoinPassword": {}
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {},
        "AvailabilityZones": {
          "shape": "S2d"
        }
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "Name",
        "ScriptBootstrapAction"
      ],
      "members": {
        "Name": {},
        "ScriptBootstrapAction": {
          "type": "structure",
          "required": [
            "Path"
          ],
          "members": {
            "Path": {},
            "Args": {
              "shape": "S1j"
            }
          }
        }
      }
    },
    "S37": {
      "type": "list",
      "member": {}
    },
    "S3d": {
      "type": "structure",
      "members": {
        "Jar": {},
        "Properties": {
          "shape": "Sj"
        },
        "MainClass": {},
        "Args": {
          "shape": "S2e"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Reason": {},
            "Message": {},
            "LogFile": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "StartDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S3m": {
      "type": "structure",
      "required": [
        "BlockPublicSecurityGroupRules"
      ],
      "members": {
        "BlockPublicSecurityGroupRules": {
          "type": "boolean"
        },
        "PermittedPublicSecurityGroupRuleRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "MinRange"
            ],
            "members": {
              "MinRange": {
                "type": "integer"
              },
              "MaxRange": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S3t": {
      "type": "structure",
      "members": {
        "ComputeLimits": {
          "type": "structure",
          "required": [
            "UnitType",
            "MinimumCapacityUnits",
            "MaximumCapacityUnits"
          ],
          "members": {
            "UnitType": {},
            "MinimumCapacityUnits": {
              "type": "integer"
            },
            "MaximumCapacityUnits": {
              "type": "integer"
            },
            "MaximumOnDemandCapacityUnits": {
              "type": "integer"
            },
            "MaximumCoreCapacityUnits": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "VolumeSpecification": {
            "shape": "Sd"
          },
          "Device": {}
        }
      }
    },
    "S4u": {
      "type": "structure",
      "members": {
        "DecommissionTimeout": {
          "type": "integer"
        },
        "InstanceResizePolicy": {
          "type": "structure",
          "members": {
            "InstancesToTerminate": {
              "shape": "S4w"
            },
            "InstancesToProtect": {
              "shape": "S4w"
            },
            "InstanceTerminationTimeout": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4w": {
      "type": "list",
      "member": {}
    },
    "S4y": {
      "type": "structure",
      "members": {
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateChangeReason": {
              "type": "structure",
              "members": {
                "Code": {},
                "Message": {}
              }
            }
          }
        },
        "Constraints": {
          "shape": "Sw"
        },
        "Rules": {
          "shape": "Sx"
        }
      }
    },
    "S6c": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],94:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeJobFlows": {
      "result_key": "JobFlows"
    },
    "ListBootstrapActions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "BootstrapActions"
    },
    "ListClusters": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Clusters"
    },
    "ListInstanceFleets": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "InstanceFleets"
    },
    "ListInstanceGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "InstanceGroups"
    },
    "ListInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Instances"
    },
    "ListSecurityConfigurations": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "SecurityConfigurations"
    },
    "ListSteps": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Steps"
    }
  }
}
},{}],95:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "ClusterRunning": {
      "delay": 30,
      "operation": "DescribeCluster",
      "maxAttempts": 60,
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "RUNNING"
        },
        {
          "state": "success",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "WAITING"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "TERMINATING"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "TERMINATED"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "TERMINATED_WITH_ERRORS"
        }
      ]
    },
    "StepComplete": {
      "delay": 30,
      "operation": "DescribeStep",
      "maxAttempts": 60,
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "Step.Status.State",
          "expected": "COMPLETED"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Step.Status.State",
          "expected": "FAILED"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Step.Status.State",
          "expected": "CANCELLED"
        }
      ]
    },
    "ClusterTerminated": {
      "delay": 30,
      "operation": "DescribeCluster",
      "maxAttempts": 60,
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "TERMINATED"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "Cluster.Status.State",
          "expected": "TERMINATED_WITH_ERRORS"
        }
      ]
    }
  }
}

},{}],96:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-09-25",
    "endpointPrefix": "elastictranscoder",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Elastic Transcoder",
    "serviceId": "Elastic Transcoder",
    "signatureVersion": "v4",
    "uid": "elastictranscoder-2012-09-25"
  },
  "operations": {
    "CancelJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2012-09-25/jobs/{Id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateJob": {
      "http": {
        "requestUri": "/2012-09-25/jobs",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "PipelineId"
        ],
        "members": {
          "PipelineId": {},
          "Input": {
            "shape": "S5"
          },
          "Inputs": {
            "shape": "St"
          },
          "Output": {
            "shape": "Su"
          },
          "Outputs": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "OutputKeyPrefix": {},
          "Playlists": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Format": {},
                "OutputKeys": {
                  "shape": "S1l"
                },
                "HlsContentProtection": {
                  "shape": "S1m"
                },
                "PlayReadyDrm": {
                  "shape": "S1q"
                }
              }
            }
          },
          "UserMetadata": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S1y"
          }
        }
      }
    },
    "CreatePipeline": {
      "http": {
        "requestUri": "/2012-09-25/pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InputBucket",
          "Role"
        ],
        "members": {
          "Name": {},
          "InputBucket": {},
          "OutputBucket": {},
          "Role": {},
          "AwsKmsKeyArn": {},
          "Notifications": {
            "shape": "S2a"
          },
          "ContentConfig": {
            "shape": "S2c"
          },
          "ThumbnailConfig": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipeline": {
            "shape": "S2l"
          },
          "Warnings": {
            "shape": "S2n"
          }
        }
      }
    },
    "CreatePreset": {
      "http": {
        "requestUri": "/2012-09-25/presets",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Container"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Container": {},
          "Video": {
            "shape": "S2r"
          },
          "Audio": {
            "shape": "S37"
          },
          "Thumbnails": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Preset": {
            "shape": "S3m"
          },
          "Warning": {}
        }
      }
    },
    "DeletePipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2012-09-25/pipelines/{Id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePreset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2012-09-25/presets/{Id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListJobsByPipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/jobsByPipeline/{PipelineId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PipelineId"
        ],
        "members": {
          "PipelineId": {
            "location": "uri",
            "locationName": "PipelineId"
          },
          "Ascending": {
            "location": "querystring",
            "locationName": "Ascending"
          },
          "PageToken": {
            "location": "querystring",
            "locationName": "PageToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S3v"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListJobsByStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/jobsByStatus/{Status}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {
            "location": "uri",
            "locationName": "Status"
          },
          "Ascending": {
            "location": "querystring",
            "locationName": "Ascending"
          },
          "PageToken": {
            "location": "querystring",
            "locationName": "PageToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S3v"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/pipelines"
      },
      "input": {
        "type": "structure",
        "members": {
          "Ascending": {
            "location": "querystring",
            "locationName": "Ascending"
          },
          "PageToken": {
            "location": "querystring",
            "locationName": "PageToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipelines": {
            "type": "list",
            "member": {
              "shape": "S2l"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPresets": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/presets"
      },
      "input": {
        "type": "structure",
        "members": {
          "Ascending": {
            "location": "querystring",
            "locationName": "Ascending"
          },
          "PageToken": {
            "location": "querystring",
            "locationName": "PageToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Presets": {
            "type": "list",
            "member": {
              "shape": "S3m"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ReadJob": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/jobs/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S1y"
          }
        }
      }
    },
    "ReadPipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/pipelines/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipeline": {
            "shape": "S2l"
          },
          "Warnings": {
            "shape": "S2n"
          }
        }
      }
    },
    "ReadPreset": {
      "http": {
        "method": "GET",
        "requestUri": "/2012-09-25/presets/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Preset": {
            "shape": "S3m"
          }
        }
      }
    },
    "TestRole": {
      "http": {
        "requestUri": "/2012-09-25/roleTests",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "InputBucket",
          "OutputBucket",
          "Topics"
        ],
        "members": {
          "Role": {},
          "InputBucket": {},
          "OutputBucket": {},
          "Topics": {
            "type": "list",
            "member": {}
          }
        },
        "deprecated": true
      },
      "output": {
        "type": "structure",
        "members": {
          "Success": {},
          "Messages": {
            "type": "list",
            "member": {}
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "UpdatePipeline": {
      "http": {
        "method": "PUT",
        "requestUri": "/2012-09-25/pipelines/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Name": {},
          "InputBucket": {},
          "Role": {},
          "AwsKmsKeyArn": {},
          "Notifications": {
            "shape": "S2a"
          },
          "ContentConfig": {
            "shape": "S2c"
          },
          "ThumbnailConfig": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipeline": {
            "shape": "S2l"
          },
          "Warnings": {
            "shape": "S2n"
          }
        }
      }
    },
    "UpdatePipelineNotifications": {
      "http": {
        "requestUri": "/2012-09-25/pipelines/{Id}/notifications"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "Notifications"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Notifications": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipeline": {
            "shape": "S2l"
          }
        }
      }
    },
    "UpdatePipelineStatus": {
      "http": {
        "requestUri": "/2012-09-25/pipelines/{Id}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "Status"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Pipeline": {
            "shape": "S2l"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "Key": {},
        "FrameRate": {},
        "Resolution": {},
        "AspectRatio": {},
        "Interlaced": {},
        "Container": {},
        "Encryption": {
          "shape": "Sc"
        },
        "TimeSpan": {
          "shape": "Sg"
        },
        "InputCaptions": {
          "type": "structure",
          "members": {
            "MergePolicy": {},
            "CaptionSources": {
              "shape": "Sk"
            }
          }
        },
        "DetectedProperties": {
          "type": "structure",
          "members": {
            "Width": {
              "type": "integer"
            },
            "Height": {
              "type": "integer"
            },
            "FrameRate": {},
            "FileSize": {
              "type": "long"
            },
            "DurationMillis": {
              "type": "long"
            }
          }
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "Mode": {},
        "Key": {},
        "KeyMd5": {},
        "InitializationVector": {}
      }
    },
    "Sg": {
      "type": "structure",
      "members": {
        "StartTime": {},
        "Duration": {}
      }
    },
    "Sk": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Language": {},
          "TimeOffset": {},
          "Label": {},
          "Encryption": {
            "shape": "Sc"
          }
        }
      }
    },
    "St": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "Key": {},
        "ThumbnailPattern": {},
        "ThumbnailEncryption": {
          "shape": "Sc"
        },
        "Rotate": {},
        "PresetId": {},
        "SegmentDuration": {},
        "Watermarks": {
          "shape": "Sx"
        },
        "AlbumArt": {
          "shape": "S11"
        },
        "Composition": {
          "shape": "S19",
          "deprecated": true
        },
        "Captions": {
          "shape": "S1b"
        },
        "Encryption": {
          "shape": "Sc"
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PresetWatermarkId": {},
          "InputKey": {},
          "Encryption": {
            "shape": "Sc"
          }
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "MergePolicy": {},
        "Artwork": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "InputKey": {},
              "MaxWidth": {},
              "MaxHeight": {},
              "SizingPolicy": {},
              "PaddingPolicy": {},
              "AlbumArtFormat": {},
              "Encryption": {
                "shape": "Sc"
              }
            }
          }
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TimeSpan": {
            "shape": "Sg"
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "S1b": {
      "type": "structure",
      "members": {
        "MergePolicy": {
          "deprecated": true
        },
        "CaptionSources": {
          "shape": "Sk",
          "deprecated": true
        },
        "CaptionFormats": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Format": {},
              "Pattern": {},
              "Encryption": {
                "shape": "Sc"
              }
            }
          }
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1m": {
      "type": "structure",
      "members": {
        "Method": {},
        "Key": {},
        "KeyMd5": {},
        "InitializationVector": {},
        "LicenseAcquisitionUrl": {},
        "KeyStoragePolicy": {}
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "Format": {},
        "Key": {},
        "KeyMd5": {},
        "KeyId": {},
        "InitializationVector": {},
        "LicenseAcquisitionUrl": {}
      }
    },
    "S1v": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1y": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "PipelineId": {},
        "Input": {
          "shape": "S5"
        },
        "Inputs": {
          "shape": "St"
        },
        "Output": {
          "shape": "S1z"
        },
        "Outputs": {
          "type": "list",
          "member": {
            "shape": "S1z"
          }
        },
        "OutputKeyPrefix": {},
        "Playlists": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {},
              "Format": {},
              "OutputKeys": {
                "shape": "S1l"
              },
              "HlsContentProtection": {
                "shape": "S1m"
              },
              "PlayReadyDrm": {
                "shape": "S1q"
              },
              "Status": {},
              "StatusDetail": {}
            }
          }
        },
        "Status": {},
        "UserMetadata": {
          "shape": "S1v"
        },
        "Timing": {
          "type": "structure",
          "members": {
            "SubmitTimeMillis": {
              "type": "long"
            },
            "StartTimeMillis": {
              "type": "long"
            },
            "FinishTimeMillis": {
              "type": "long"
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Id": {},
        "Key": {},
        "ThumbnailPattern": {},
        "ThumbnailEncryption": {
          "shape": "Sc"
        },
        "Rotate": {},
        "PresetId": {},
        "SegmentDuration": {},
        "Status": {},
        "StatusDetail": {},
        "Duration": {
          "type": "long"
        },
        "Width": {
          "type": "integer"
        },
        "Height": {
          "type": "integer"
        },
        "FrameRate": {},
        "FileSize": {
          "type": "long"
        },
        "DurationMillis": {
          "type": "long"
        },
        "Watermarks": {
          "shape": "Sx"
        },
        "AlbumArt": {
          "shape": "S11"
        },
        "Composition": {
          "shape": "S19",
          "deprecated": true
        },
        "Captions": {
          "shape": "S1b"
        },
        "Encryption": {
          "shape": "Sc"
        },
        "AppliedColorSpaceConversion": {}
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "Progressing": {},
        "Completed": {},
        "Warning": {},
        "Error": {}
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "StorageClass": {},
        "Permissions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "GranteeType": {},
              "Grantee": {},
              "Access": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "Status": {},
        "InputBucket": {},
        "OutputBucket": {},
        "Role": {},
        "AwsKmsKeyArn": {},
        "Notifications": {
          "shape": "S2a"
        },
        "ContentConfig": {
          "shape": "S2c"
        },
        "ThumbnailConfig": {
          "shape": "S2c"
        }
      }
    },
    "S2n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Code": {},
          "Message": {}
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "Codec": {},
        "CodecOptions": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "KeyframesMaxDist": {},
        "FixedGOP": {},
        "BitRate": {},
        "FrameRate": {},
        "MaxFrameRate": {},
        "Resolution": {},
        "AspectRatio": {},
        "MaxWidth": {},
        "MaxHeight": {},
        "DisplayAspectRatio": {},
        "SizingPolicy": {},
        "PaddingPolicy": {},
        "Watermarks": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {},
              "MaxWidth": {},
              "MaxHeight": {},
              "SizingPolicy": {},
              "HorizontalAlign": {},
              "HorizontalOffset": {},
              "VerticalAlign": {},
              "VerticalOffset": {},
              "Opacity": {},
              "Target": {}
            }
          }
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "Codec": {},
        "SampleRate": {},
        "BitRate": {},
        "Channels": {},
        "AudioPackingMode": {},
        "CodecOptions": {
          "type": "structure",
          "members": {
            "Profile": {},
            "BitDepth": {},
            "BitOrder": {},
            "Signed": {}
          }
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "Format": {},
        "Interval": {},
        "Resolution": {},
        "AspectRatio": {},
        "MaxWidth": {},
        "MaxHeight": {},
        "SizingPolicy": {},
        "PaddingPolicy": {}
      }
    },
    "S3m": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "Description": {},
        "Container": {},
        "Audio": {
          "shape": "S37"
        },
        "Video": {
          "shape": "S2r"
        },
        "Thumbnails": {
          "shape": "S3i"
        },
        "Type": {}
      }
    },
    "S3v": {
      "type": "list",
      "member": {
        "shape": "S1y"
      }
    }
  }
}
},{}],97:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListJobsByPipeline": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "result_key": "Jobs"
    },
    "ListJobsByStatus": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "result_key": "Jobs"
    },
    "ListPipelines": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "result_key": "Pipelines"
    },
    "ListPresets": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "result_key": "Presets"
    }
  }
}
},{}],98:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "JobComplete": {
      "delay": 30,
      "operation": "ReadJob",
      "maxAttempts": 120,
      "acceptors": [
        {
          "expected": "Complete",
          "matcher": "path",
          "state": "success",
          "argument": "Job.Status"
        },
        {
          "expected": "Canceled",
          "matcher": "path",
          "state": "failure",
          "argument": "Job.Status"
        },
        {
          "expected": "Error",
          "matcher": "path",
          "state": "failure",
          "argument": "Job.Status"
        }
      ]
    }
  }
}

},{}],99:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-12-01",
    "endpointPrefix": "email",
    "protocol": "query",
    "serviceAbbreviation": "Amazon SES",
    "serviceFullName": "Amazon Simple Email Service",
    "serviceId": "SES",
    "signatureVersion": "v4",
    "signingName": "ses",
    "uid": "email-2010-12-01",
    "xmlNamespace": "http://ses.amazonaws.com/doc/2010-12-01/"
  },
  "operations": {
    "CloneReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "OriginalRuleSetName"
        ],
        "members": {
          "RuleSetName": {},
          "OriginalRuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "CloneReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSet"
        ],
        "members": {
          "ConfigurationSet": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateConfigurationSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestination"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestination": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateConfigurationSetTrackingOptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "TrackingOptions"
        ],
        "members": {
          "ConfigurationSetName": {},
          "TrackingOptions": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateConfigurationSetTrackingOptionsResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateCustomVerificationEmailTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName",
          "FromEmailAddress",
          "TemplateSubject",
          "TemplateContent",
          "SuccessRedirectionURL",
          "FailureRedirectionURL"
        ],
        "members": {
          "TemplateName": {},
          "FromEmailAddress": {},
          "TemplateSubject": {},
          "TemplateContent": {},
          "SuccessRedirectionURL": {},
          "FailureRedirectionURL": {}
        }
      }
    },
    "CreateReceiptFilter": {
      "input": {
        "type": "structure",
        "required": [
          "Filter"
        ],
        "members": {
          "Filter": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptFilterResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "Rule"
        ],
        "members": {
          "RuleSetName": {},
          "After": {},
          "Rule": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "Template"
        ],
        "members": {
          "Template": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateTemplateResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteConfigurationSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestinationName"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestinationName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConfigurationSetTrackingOptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteConfigurationSetTrackingOptionsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCustomVerificationEmailTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName"
        ],
        "members": {
          "TemplateName": {}
        }
      }
    },
    "DeleteIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteIdentityResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteIdentityPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyName"
        ],
        "members": {
          "Identity": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteIdentityPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptFilter": {
      "input": {
        "type": "structure",
        "required": [
          "FilterName"
        ],
        "members": {
          "FilterName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptFilterResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName"
        ],
        "members": {
          "TemplateName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteTemplateResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVerifiedEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      }
    },
    "DescribeActiveReceiptRuleSet": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "DescribeActiveReceiptRuleSetResult",
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S2t"
          },
          "Rules": {
            "shape": "S2v"
          }
        }
      }
    },
    "DescribeConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {},
          "ConfigurationSetAttributeNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeConfigurationSetResult",
        "type": "structure",
        "members": {
          "ConfigurationSet": {
            "shape": "S5"
          },
          "EventDestinations": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          },
          "TrackingOptions": {
            "shape": "Sp"
          },
          "DeliveryOptions": {
            "shape": "S31"
          },
          "ReputationOptions": {
            "type": "structure",
            "members": {
              "SendingEnabled": {
                "type": "boolean"
              },
              "ReputationMetricsEnabled": {
                "type": "boolean"
              },
              "LastFreshStart": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReceiptRuleResult",
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "S18"
          }
        }
      }
    },
    "DescribeReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReceiptRuleSetResult",
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S2t"
          },
          "Rules": {
            "shape": "S2v"
          }
        }
      }
    },
    "GetAccountSendingEnabled": {
      "output": {
        "resultWrapper": "GetAccountSendingEnabledResult",
        "type": "structure",
        "members": {
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "GetCustomVerificationEmailTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName"
        ],
        "members": {
          "TemplateName": {}
        }
      },
      "output": {
        "resultWrapper": "GetCustomVerificationEmailTemplateResult",
        "type": "structure",
        "members": {
          "TemplateName": {},
          "FromEmailAddress": {},
          "TemplateSubject": {},
          "TemplateContent": {},
          "SuccessRedirectionURL": {},
          "FailureRedirectionURL": {}
        }
      }
    },
    "GetIdentityDkimAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityDkimAttributesResult",
        "type": "structure",
        "required": [
          "DkimAttributes"
        ],
        "members": {
          "DkimAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "DkimEnabled",
                "DkimVerificationStatus"
              ],
              "members": {
                "DkimEnabled": {
                  "type": "boolean"
                },
                "DkimVerificationStatus": {},
                "DkimTokens": {
                  "shape": "S3h"
                }
              }
            }
          }
        }
      }
    },
    "GetIdentityMailFromDomainAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityMailFromDomainAttributesResult",
        "type": "structure",
        "required": [
          "MailFromDomainAttributes"
        ],
        "members": {
          "MailFromDomainAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "MailFromDomain",
                "MailFromDomainStatus",
                "BehaviorOnMXFailure"
              ],
              "members": {
                "MailFromDomain": {},
                "MailFromDomainStatus": {},
                "BehaviorOnMXFailure": {}
              }
            }
          }
        }
      }
    },
    "GetIdentityNotificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityNotificationAttributesResult",
        "type": "structure",
        "required": [
          "NotificationAttributes"
        ],
        "members": {
          "NotificationAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "BounceTopic",
                "ComplaintTopic",
                "DeliveryTopic",
                "ForwardingEnabled"
              ],
              "members": {
                "BounceTopic": {},
                "ComplaintTopic": {},
                "DeliveryTopic": {},
                "ForwardingEnabled": {
                  "type": "boolean"
                },
                "HeadersInBounceNotificationsEnabled": {
                  "type": "boolean"
                },
                "HeadersInComplaintNotificationsEnabled": {
                  "type": "boolean"
                },
                "HeadersInDeliveryNotificationsEnabled": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "GetIdentityPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyNames"
        ],
        "members": {
          "Identity": {},
          "PolicyNames": {
            "shape": "S3w"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityPoliciesResult",
        "type": "structure",
        "required": [
          "Policies"
        ],
        "members": {
          "Policies": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "GetIdentityVerificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityVerificationAttributesResult",
        "type": "structure",
        "required": [
          "VerificationAttributes"
        ],
        "members": {
          "VerificationAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "VerificationStatus"
              ],
              "members": {
                "VerificationStatus": {},
                "VerificationToken": {}
              }
            }
          }
        }
      }
    },
    "GetSendQuota": {
      "output": {
        "resultWrapper": "GetSendQuotaResult",
        "type": "structure",
        "members": {
          "Max24HourSend": {
            "type": "double"
          },
          "MaxSendRate": {
            "type": "double"
          },
          "SentLast24Hours": {
            "type": "double"
          }
        }
      }
    },
    "GetSendStatistics": {
      "output": {
        "resultWrapper": "GetSendStatisticsResult",
        "type": "structure",
        "members": {
          "SendDataPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "DeliveryAttempts": {
                  "type": "long"
                },
                "Bounces": {
                  "type": "long"
                },
                "Complaints": {
                  "type": "long"
                },
                "Rejects": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "GetTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName"
        ],
        "members": {
          "TemplateName": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateResult",
        "type": "structure",
        "members": {
          "Template": {
            "shape": "S20"
          }
        }
      }
    },
    "ListConfigurationSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListConfigurationSetsResult",
        "type": "structure",
        "members": {
          "ConfigurationSets": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomVerificationEmailTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListCustomVerificationEmailTemplatesResult",
        "type": "structure",
        "members": {
          "CustomVerificationEmailTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TemplateName": {},
                "FromEmailAddress": {},
                "TemplateSubject": {},
                "SuccessRedirectionURL": {},
                "FailureRedirectionURL": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentities": {
      "input": {
        "type": "structure",
        "members": {
          "IdentityType": {},
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListIdentitiesResult",
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S3c"
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {}
        }
      },
      "output": {
        "resultWrapper": "ListIdentityPoliciesResult",
        "type": "structure",
        "required": [
          "PolicyNames"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S3w"
          }
        }
      }
    },
    "ListReceiptFilters": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "ListReceiptFiltersResult",
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "shape": "S10"
            }
          }
        }
      }
    },
    "ListReceiptRuleSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListReceiptRuleSetsResult",
        "type": "structure",
        "members": {
          "RuleSets": {
            "type": "list",
            "member": {
              "shape": "S2t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListTemplatesResult",
        "type": "structure",
        "members": {
          "TemplatesMetadata": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "CreatedTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListVerifiedEmailAddresses": {
      "output": {
        "resultWrapper": "ListVerifiedEmailAddressesResult",
        "type": "structure",
        "members": {
          "VerifiedEmailAddresses": {
            "shape": "S54"
          }
        }
      }
    },
    "PutConfigurationSetDeliveryOptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {},
          "DeliveryOptions": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "resultWrapper": "PutConfigurationSetDeliveryOptionsResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutIdentityPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyName",
          "Policy"
        ],
        "members": {
          "Identity": {},
          "PolicyName": {},
          "Policy": {}
        }
      },
      "output": {
        "resultWrapper": "PutIdentityPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "ReorderReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleNames"
        ],
        "members": {
          "RuleSetName": {},
          "RuleNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ReorderReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "SendBounce": {
      "input": {
        "type": "structure",
        "required": [
          "OriginalMessageId",
          "BounceSender",
          "BouncedRecipientInfoList"
        ],
        "members": {
          "OriginalMessageId": {},
          "BounceSender": {},
          "Explanation": {},
          "MessageDsn": {
            "type": "structure",
            "required": [
              "ReportingMta"
            ],
            "members": {
              "ReportingMta": {},
              "ArrivalDate": {
                "type": "timestamp"
              },
              "ExtensionFields": {
                "shape": "S5i"
              }
            }
          },
          "BouncedRecipientInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Recipient"
              ],
              "members": {
                "Recipient": {},
                "RecipientArn": {},
                "BounceType": {},
                "RecipientDsnFields": {
                  "type": "structure",
                  "required": [
                    "Action",
                    "Status"
                  ],
                  "members": {
                    "FinalRecipient": {},
                    "Action": {},
                    "RemoteMta": {},
                    "Status": {},
                    "DiagnosticCode": {},
                    "LastAttemptDate": {
                      "type": "timestamp"
                    },
                    "ExtensionFields": {
                      "shape": "S5i"
                    }
                  }
                }
              }
            }
          },
          "BounceSenderArn": {}
        }
      },
      "output": {
        "resultWrapper": "SendBounceResult",
        "type": "structure",
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendBulkTemplatedEmail": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "Template",
          "Destinations"
        ],
        "members": {
          "Source": {},
          "SourceArn": {},
          "ReplyToAddresses": {
            "shape": "S54"
          },
          "ReturnPath": {},
          "ReturnPathArn": {},
          "ConfigurationSetName": {},
          "DefaultTags": {
            "shape": "S5x"
          },
          "Template": {},
          "TemplateArn": {},
          "DefaultTemplateData": {},
          "Destinations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Destination"
              ],
              "members": {
                "Destination": {
                  "shape": "S64"
                },
                "ReplacementTags": {
                  "shape": "S5x"
                },
                "ReplacementTemplateData": {}
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "SendBulkTemplatedEmailResult",
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Status": {},
                "Error": {},
                "MessageId": {}
              }
            }
          }
        }
      }
    },
    "SendCustomVerificationEmail": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress",
          "TemplateName"
        ],
        "members": {
          "EmailAddress": {},
          "TemplateName": {},
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SendCustomVerificationEmailResult",
        "type": "structure",
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendEmail": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "Destination",
          "Message"
        ],
        "members": {
          "Source": {},
          "Destination": {
            "shape": "S64"
          },
          "Message": {
            "type": "structure",
            "required": [
              "Subject",
              "Body"
            ],
            "members": {
              "Subject": {
                "shape": "S6e"
              },
              "Body": {
                "type": "structure",
                "members": {
                  "Text": {
                    "shape": "S6e"
                  },
                  "Html": {
                    "shape": "S6e"
                  }
                }
              }
            }
          },
          "ReplyToAddresses": {
            "shape": "S54"
          },
          "ReturnPath": {},
          "SourceArn": {},
          "ReturnPathArn": {},
          "Tags": {
            "shape": "S5x"
          },
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SendEmailResult",
        "type": "structure",
        "required": [
          "MessageId"
        ],
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendRawEmail": {
      "input": {
        "type": "structure",
        "required": [
          "RawMessage"
        ],
        "members": {
          "Source": {},
          "Destinations": {
            "shape": "S54"
          },
          "RawMessage": {
            "type": "structure",
            "required": [
              "Data"
            ],
            "members": {
              "Data": {
                "type": "blob"
              }
            }
          },
          "FromArn": {},
          "SourceArn": {},
          "ReturnPathArn": {},
          "Tags": {
            "shape": "S5x"
          },
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SendRawEmailResult",
        "type": "structure",
        "required": [
          "MessageId"
        ],
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendTemplatedEmail": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "Destination",
          "Template",
          "TemplateData"
        ],
        "members": {
          "Source": {},
          "Destination": {
            "shape": "S64"
          },
          "ReplyToAddresses": {
            "shape": "S54"
          },
          "ReturnPath": {},
          "SourceArn": {},
          "ReturnPathArn": {},
          "Tags": {
            "shape": "S5x"
          },
          "ConfigurationSetName": {},
          "Template": {},
          "TemplateArn": {},
          "TemplateData": {}
        }
      },
      "output": {
        "resultWrapper": "SendTemplatedEmailResult",
        "type": "structure",
        "required": [
          "MessageId"
        ],
        "members": {
          "MessageId": {}
        }
      }
    },
    "SetActiveReceiptRuleSet": {
      "input": {
        "type": "structure",
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SetActiveReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityDkimEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "DkimEnabled"
        ],
        "members": {
          "Identity": {},
          "DkimEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityDkimEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityFeedbackForwardingEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "ForwardingEnabled"
        ],
        "members": {
          "Identity": {},
          "ForwardingEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityFeedbackForwardingEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityHeadersInNotificationsEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "NotificationType",
          "Enabled"
        ],
        "members": {
          "Identity": {},
          "NotificationType": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityHeadersInNotificationsEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityMailFromDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {},
          "MailFromDomain": {},
          "BehaviorOnMXFailure": {}
        }
      },
      "output": {
        "resultWrapper": "SetIdentityMailFromDomainResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityNotificationTopic": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "NotificationType"
        ],
        "members": {
          "Identity": {},
          "NotificationType": {},
          "SnsTopic": {}
        }
      },
      "output": {
        "resultWrapper": "SetIdentityNotificationTopicResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetReceiptRulePosition": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {},
          "After": {}
        }
      },
      "output": {
        "resultWrapper": "SetReceiptRulePositionResult",
        "type": "structure",
        "members": {}
      }
    },
    "TestRenderTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName",
          "TemplateData"
        ],
        "members": {
          "TemplateName": {},
          "TemplateData": {}
        }
      },
      "output": {
        "resultWrapper": "TestRenderTemplateResult",
        "type": "structure",
        "members": {
          "RenderedTemplate": {}
        }
      }
    },
    "UpdateAccountSendingEnabled": {
      "input": {
        "type": "structure",
        "members": {
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestination"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestination": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConfigurationSetReputationMetricsEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "Enabled"
        ],
        "members": {
          "ConfigurationSetName": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateConfigurationSetSendingEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "Enabled"
        ],
        "members": {
          "ConfigurationSetName": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateConfigurationSetTrackingOptions": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "TrackingOptions"
        ],
        "members": {
          "ConfigurationSetName": {},
          "TrackingOptions": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateConfigurationSetTrackingOptionsResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCustomVerificationEmailTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "TemplateName"
        ],
        "members": {
          "TemplateName": {},
          "FromEmailAddress": {},
          "TemplateSubject": {},
          "TemplateContent": {},
          "SuccessRedirectionURL": {},
          "FailureRedirectionURL": {}
        }
      }
    },
    "UpdateReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "Rule"
        ],
        "members": {
          "RuleSetName": {},
          "Rule": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "Template"
        ],
        "members": {
          "Template": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateTemplateResult",
        "type": "structure",
        "members": {}
      }
    },
    "VerifyDomainDkim": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyDomainDkimResult",
        "type": "structure",
        "required": [
          "DkimTokens"
        ],
        "members": {
          "DkimTokens": {
            "shape": "S3h"
          }
        }
      }
    },
    "VerifyDomainIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyDomainIdentityResult",
        "type": "structure",
        "required": [
          "VerificationToken"
        ],
        "members": {
          "VerificationToken": {}
        }
      }
    },
    "VerifyEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      }
    },
    "VerifyEmailIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyEmailIdentityResult",
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "Name",
        "MatchingEventTypes"
      ],
      "members": {
        "Name": {},
        "Enabled": {
          "type": "boolean"
        },
        "MatchingEventTypes": {
          "type": "list",
          "member": {}
        },
        "KinesisFirehoseDestination": {
          "type": "structure",
          "required": [
            "IAMRoleARN",
            "DeliveryStreamARN"
          ],
          "members": {
            "IAMRoleARN": {},
            "DeliveryStreamARN": {}
          }
        },
        "CloudWatchDestination": {
          "type": "structure",
          "required": [
            "DimensionConfigurations"
          ],
          "members": {
            "DimensionConfigurations": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "DimensionName",
                  "DimensionValueSource",
                  "DefaultDimensionValue"
                ],
                "members": {
                  "DimensionName": {},
                  "DimensionValueSource": {},
                  "DefaultDimensionValue": {}
                }
              }
            }
          }
        },
        "SNSDestination": {
          "type": "structure",
          "required": [
            "TopicARN"
          ],
          "members": {
            "TopicARN": {}
          }
        }
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "CustomRedirectDomain": {}
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "Name",
        "IpFilter"
      ],
      "members": {
        "Name": {},
        "IpFilter": {
          "type": "structure",
          "required": [
            "Policy",
            "Cidr"
          ],
          "members": {
            "Policy": {},
            "Cidr": {}
          }
        }
      }
    },
    "S18": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Enabled": {
          "type": "boolean"
        },
        "TlsPolicy": {},
        "Recipients": {
          "type": "list",
          "member": {}
        },
        "Actions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "S3Action": {
                "type": "structure",
                "required": [
                  "BucketName"
                ],
                "members": {
                  "TopicArn": {},
                  "BucketName": {},
                  "ObjectKeyPrefix": {},
                  "KmsKeyArn": {}
                }
              },
              "BounceAction": {
                "type": "structure",
                "required": [
                  "SmtpReplyCode",
                  "Message",
                  "Sender"
                ],
                "members": {
                  "TopicArn": {},
                  "SmtpReplyCode": {},
                  "StatusCode": {},
                  "Message": {},
                  "Sender": {}
                }
              },
              "WorkmailAction": {
                "type": "structure",
                "required": [
                  "OrganizationArn"
                ],
                "members": {
                  "TopicArn": {},
                  "OrganizationArn": {}
                }
              },
              "LambdaAction": {
                "type": "structure",
                "required": [
                  "FunctionArn"
                ],
                "members": {
                  "TopicArn": {},
                  "FunctionArn": {},
                  "InvocationType": {}
                }
              },
              "StopAction": {
                "type": "structure",
                "required": [
                  "Scope"
                ],
                "members": {
                  "Scope": {},
                  "TopicArn": {}
                }
              },
              "AddHeaderAction": {
                "type": "structure",
                "required": [
                  "HeaderName",
                  "HeaderValue"
                ],
                "members": {
                  "HeaderName": {},
                  "HeaderValue": {}
                }
              },
              "SNSAction": {
                "type": "structure",
                "required": [
                  "TopicArn"
                ],
                "members": {
                  "TopicArn": {},
                  "Encoding": {}
                }
              }
            }
          }
        },
        "ScanEnabled": {
          "type": "boolean"
        }
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "TemplateName"
      ],
      "members": {
        "TemplateName": {},
        "SubjectPart": {},
        "TextPart": {},
        "HtmlPart": {}
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "Name": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S2v": {
      "type": "list",
      "member": {
        "shape": "S18"
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "TlsPolicy": {}
      }
    },
    "S3c": {
      "type": "list",
      "member": {}
    },
    "S3h": {
      "type": "list",
      "member": {}
    },
    "S3w": {
      "type": "list",
      "member": {}
    },
    "S54": {
      "type": "list",
      "member": {}
    },
    "S5i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S5x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S64": {
      "type": "structure",
      "members": {
        "ToAddresses": {
          "shape": "S54"
        },
        "CcAddresses": {
          "shape": "S54"
        },
        "BccAddresses": {
          "shape": "S54"
        }
      }
    },
    "S6e": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {},
        "Charset": {}
      }
    }
  }
}
},{}],100:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListCustomVerificationEmailTemplates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListIdentities": {
      "input_token": "NextToken",
      "limit_key": "MaxItems",
      "output_token": "NextToken",
      "result_key": "Identities"
    },
    "ListVerifiedEmailAddresses": {
      "result_key": "VerifiedEmailAddresses"
    }
  }
}
},{}],101:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "IdentityExists": {
      "delay": 3,
      "operation": "GetIdentityVerificationAttributes",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": "Success",
          "matcher": "pathAll",
          "state": "success",
          "argument": "VerificationAttributes.*.VerificationStatus"
        }
      ]
    }
  }
}

},{}],102:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-10-07",
    "endpointPrefix": "events",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon CloudWatch Events",
    "serviceId": "CloudWatch Events",
    "signatureVersion": "v4",
    "targetPrefix": "AWSEvents",
    "uid": "events-2015-10-07"
  },
  "operations": {
    "ActivateEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateEventBus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventSourceName": {},
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventBusArn": {}
        }
      }
    },
    "CreatePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Account"
        ],
        "members": {
          "Name": {},
          "Account": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSourceArn": {}
        }
      }
    },
    "DeactivateEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteEventBus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeletePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Account"
        ],
        "members": {
          "Name": {},
          "Account": {}
        }
      }
    },
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {},
          "Force": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeEventBus": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Arn": {},
          "Policy": {}
        }
      }
    },
    "DescribeEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreatedBy": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "ExpirationTime": {
            "type": "timestamp"
          },
          "Name": {},
          "State": {}
        }
      }
    },
    "DescribePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {}
        }
      }
    },
    "DescribeRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Arn": {},
          "EventPattern": {},
          "ScheduleExpression": {},
          "State": {},
          "Description": {},
          "RoleArn": {},
          "ManagedBy": {},
          "EventBusName": {}
        }
      }
    },
    "DisableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      }
    },
    "EnableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      }
    },
    "ListEventBuses": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventBuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "Policy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEventSources": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreatedBy": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ExpirationTime": {
                  "type": "timestamp"
                },
                "Name": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPartnerEventSourceAccounts": {
      "input": {
        "type": "structure",
        "required": [
          "EventSourceName"
        ],
        "members": {
          "EventSourceName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartnerEventSourceAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ExpirationTime": {
                  "type": "timestamp"
                },
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPartnerEventSources": {
      "input": {
        "type": "structure",
        "required": [
          "NamePrefix"
        ],
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartnerEventSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRuleNamesByTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TargetArn"
        ],
        "members": {
          "TargetArn": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListRules": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "EventPattern": {},
                "State": {},
                "Description": {},
                "ScheduleExpression": {},
                "RoleArn": {},
                "ManagedBy": {},
                "EventBusName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S5"
          }
        }
      }
    },
    "ListTargetsByRule": {
      "input": {
        "type": "structure",
        "required": [
          "Rule"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "shape": "S20"
          },
          "NextToken": {}
        }
      }
    },
    "PutEvents": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Time": {
                  "type": "timestamp"
                },
                "Source": {},
                "Resources": {
                  "shape": "S2y"
                },
                "DetailType": {},
                "Detail": {},
                "EventBusName": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "PutPartnerEvents": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Time": {
                  "type": "timestamp"
                },
                "Source": {},
                "Resources": {
                  "shape": "S2y"
                },
                "DetailType": {},
                "Detail": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "PutPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Action",
          "Principal",
          "StatementId"
        ],
        "members": {
          "EventBusName": {},
          "Action": {},
          "Principal": {},
          "StatementId": {},
          "Condition": {
            "type": "structure",
            "required": [
              "Type",
              "Key",
              "Value"
            ],
            "members": {
              "Type": {},
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "PutRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ScheduleExpression": {},
          "EventPattern": {},
          "State": {},
          "Description": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S5"
          },
          "EventBusName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleArn": {}
        }
      }
    },
    "PutTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Targets"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "Targets": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "RemovePermission": {
      "input": {
        "type": "structure",
        "required": [
          "StatementId"
        ],
        "members": {
          "StatementId": {},
          "EventBusName": {}
        }
      }
    },
    "RemoveTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Ids"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "Ids": {
            "type": "list",
            "member": {}
          },
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TestEventPattern": {
      "input": {
        "type": "structure",
        "required": [
          "EventPattern",
          "Event"
        ],
        "members": {
          "EventPattern": {},
          "Event": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "type": "boolean"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "Arn"
        ],
        "members": {
          "Id": {},
          "Arn": {},
          "RoleArn": {},
          "Input": {},
          "InputPath": {},
          "InputTransformer": {
            "type": "structure",
            "required": [
              "InputTemplate"
            ],
            "members": {
              "InputPathsMap": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "InputTemplate": {}
            }
          },
          "KinesisParameters": {
            "type": "structure",
            "required": [
              "PartitionKeyPath"
            ],
            "members": {
              "PartitionKeyPath": {}
            }
          },
          "RunCommandParameters": {
            "type": "structure",
            "required": [
              "RunCommandTargets"
            ],
            "members": {
              "RunCommandTargets": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "Values"
                  ],
                  "members": {
                    "Key": {},
                    "Values": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "EcsParameters": {
            "type": "structure",
            "required": [
              "TaskDefinitionArn"
            ],
            "members": {
              "TaskDefinitionArn": {},
              "TaskCount": {
                "type": "integer"
              },
              "LaunchType": {},
              "NetworkConfiguration": {
                "type": "structure",
                "members": {
                  "awsvpcConfiguration": {
                    "type": "structure",
                    "required": [
                      "Subnets"
                    ],
                    "members": {
                      "Subnets": {
                        "shape": "S2m"
                      },
                      "SecurityGroups": {
                        "shape": "S2m"
                      },
                      "AssignPublicIp": {}
                    }
                  }
                }
              },
              "PlatformVersion": {},
              "Group": {}
            }
          },
          "BatchParameters": {
            "type": "structure",
            "required": [
              "JobDefinition",
              "JobName"
            ],
            "members": {
              "JobDefinition": {},
              "JobName": {},
              "ArrayProperties": {
                "type": "structure",
                "members": {
                  "Size": {
                    "type": "integer"
                  }
                }
              },
              "RetryStrategy": {
                "type": "structure",
                "members": {
                  "Attempts": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "SqsParameters": {
            "type": "structure",
            "members": {
              "MessageGroupId": {}
            }
          }
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {}
    },
    "S2y": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],103:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],104:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-08-04",
    "endpointPrefix": "firehose",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Firehose",
    "serviceFullName": "Amazon Kinesis Firehose",
    "serviceId": "Firehose",
    "signatureVersion": "v4",
    "targetPrefix": "Firehose_20150804",
    "uid": "firehose-2015-08-04"
  },
  "operations": {
    "CreateDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "DeliveryStreamType": {},
          "KinesisStreamSourceConfiguration": {
            "type": "structure",
            "required": [
              "KinesisStreamARN",
              "RoleARN"
            ],
            "members": {
              "KinesisStreamARN": {},
              "RoleARN": {}
            }
          },
          "DeliveryStreamEncryptionConfigurationInput": {
            "shape": "S7"
          },
          "S3DestinationConfiguration": {
            "shape": "Sa",
            "deprecated": true
          },
          "ExtendedS3DestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "BucketARN"
            ],
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Se"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Si"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "Sa"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sz"
              }
            }
          },
          "RedshiftDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "ClusterJDBCURL",
              "CopyCommand",
              "Username",
              "Password",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1q"
              },
              "Username": {
                "shape": "S1u"
              },
              "Password": {
                "shape": "S1v"
              },
              "RetryOptions": {
                "shape": "S1w"
              },
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "Sa"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "ElasticsearchDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "IndexName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S25"
              },
              "RetryOptions": {
                "shape": "S28"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "VpcConfiguration": {
                "type": "structure",
                "required": [
                  "SubnetIds",
                  "RoleARN",
                  "SecurityGroupIds"
                ],
                "members": {
                  "SubnetIds": {
                    "shape": "S2c"
                  },
                  "RoleARN": {},
                  "SecurityGroupIds": {
                    "shape": "S2d"
                  }
                }
              }
            }
          },
          "SplunkDestinationConfiguration": {
            "type": "structure",
            "required": [
              "HECEndpoint",
              "HECEndpointType",
              "HECToken",
              "S3Configuration"
            ],
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2j"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "Tags": {
            "shape": "S2m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryStreamARN": {}
        }
      }
    },
    "DeleteDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "AllowForceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartDestinationId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamDescription"
        ],
        "members": {
          "DeliveryStreamDescription": {
            "type": "structure",
            "required": [
              "DeliveryStreamName",
              "DeliveryStreamARN",
              "DeliveryStreamStatus",
              "DeliveryStreamType",
              "VersionId",
              "Destinations",
              "HasMoreDestinations"
            ],
            "members": {
              "DeliveryStreamName": {},
              "DeliveryStreamARN": {},
              "DeliveryStreamStatus": {},
              "FailureDescription": {
                "shape": "S30"
              },
              "DeliveryStreamEncryptionConfiguration": {
                "type": "structure",
                "members": {
                  "KeyARN": {},
                  "KeyType": {},
                  "Status": {},
                  "FailureDescription": {
                    "shape": "S30"
                  }
                }
              },
              "DeliveryStreamType": {},
              "VersionId": {},
              "CreateTimestamp": {
                "type": "timestamp"
              },
              "LastUpdateTimestamp": {
                "type": "timestamp"
              },
              "Source": {
                "type": "structure",
                "members": {
                  "KinesisStreamSourceDescription": {
                    "type": "structure",
                    "members": {
                      "KinesisStreamARN": {},
                      "RoleARN": {},
                      "DeliveryStartTimestamp": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              },
              "Destinations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DestinationId"
                  ],
                  "members": {
                    "DestinationId": {},
                    "S3DestinationDescription": {
                      "shape": "S3b"
                    },
                    "ExtendedS3DestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "BucketARN",
                        "BufferingHints",
                        "CompressionFormat",
                        "EncryptionConfiguration"
                      ],
                      "members": {
                        "RoleARN": {},
                        "BucketARN": {},
                        "Prefix": {},
                        "ErrorOutputPrefix": {},
                        "BufferingHints": {
                          "shape": "Se"
                        },
                        "CompressionFormat": {},
                        "EncryptionConfiguration": {
                          "shape": "Si"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S3b"
                        },
                        "DataFormatConversionConfiguration": {
                          "shape": "Sz"
                        }
                      }
                    },
                    "RedshiftDestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "ClusterJDBCURL",
                        "CopyCommand",
                        "Username",
                        "S3DestinationDescription"
                      ],
                      "members": {
                        "RoleARN": {},
                        "ClusterJDBCURL": {},
                        "CopyCommand": {
                          "shape": "S1q"
                        },
                        "Username": {
                          "shape": "S1u"
                        },
                        "RetryOptions": {
                          "shape": "S1w"
                        },
                        "S3DestinationDescription": {
                          "shape": "S3b"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S3b"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        }
                      }
                    },
                    "ElasticsearchDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "DomainARN": {},
                        "ClusterEndpoint": {},
                        "IndexName": {},
                        "TypeName": {},
                        "IndexRotationPeriod": {},
                        "BufferingHints": {
                          "shape": "S25"
                        },
                        "RetryOptions": {
                          "shape": "S28"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S3b"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "VpcConfigurationDescription": {
                          "type": "structure",
                          "required": [
                            "SubnetIds",
                            "RoleARN",
                            "SecurityGroupIds",
                            "VpcId"
                          ],
                          "members": {
                            "SubnetIds": {
                              "shape": "S2c"
                            },
                            "RoleARN": {},
                            "SecurityGroupIds": {
                              "shape": "S2d"
                            },
                            "VpcId": {}
                          }
                        }
                      }
                    },
                    "SplunkDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "HECEndpoint": {},
                        "HECEndpointType": {},
                        "HECToken": {},
                        "HECAcknowledgmentTimeoutInSeconds": {
                          "type": "integer"
                        },
                        "RetryOptions": {
                          "shape": "S2j"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S3b"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        }
                      }
                    }
                  }
                }
              },
              "HasMoreDestinations": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "ListDeliveryStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "DeliveryStreamType": {},
          "ExclusiveStartDeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamNames",
          "HasMoreDeliveryStreams"
        ],
        "members": {
          "DeliveryStreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreDeliveryStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "ExclusiveStartTagKey": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "HasMoreTags"
        ],
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "HasMoreTags": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Record"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Record": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RecordId"
        ],
        "members": {
          "RecordId": {},
          "Encrypted": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecordBatch": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Records"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Records": {
            "type": "list",
            "member": {
              "shape": "S3q"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedPutCount",
          "RequestResponses"
        ],
        "members": {
          "FailedPutCount": {
            "type": "integer"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "RequestResponses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecordId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "StartDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "DeliveryStreamEncryptionConfigurationInput": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Tags"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Tags": {
            "shape": "S2m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "TagKeys"
        ],
        "members": {
          "DeliveryStreamName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDestination": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "CurrentDeliveryStreamVersionId",
          "DestinationId"
        ],
        "members": {
          "DeliveryStreamName": {},
          "CurrentDeliveryStreamVersionId": {},
          "DestinationId": {},
          "S3DestinationUpdate": {
            "shape": "S4b",
            "deprecated": true
          },
          "ExtendedS3DestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Se"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Si"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S4b"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sz"
              }
            }
          },
          "RedshiftDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1q"
              },
              "Username": {
                "shape": "S1u"
              },
              "Password": {
                "shape": "S1v"
              },
              "RetryOptions": {
                "shape": "S1w"
              },
              "S3Update": {
                "shape": "S4b"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S4b"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "ElasticsearchDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S25"
              },
              "RetryOptions": {
                "shape": "S28"
              },
              "S3Update": {
                "shape": "S4b"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "SplunkDestinationUpdate": {
            "type": "structure",
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2j"
              },
              "S3BackupMode": {},
              "S3Update": {
                "shape": "S4b"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "KeyType"
      ],
      "members": {
        "KeyARN": {},
        "KeyType": {}
      }
    },
    "Sa": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "SizeInMBs": {
          "type": "integer"
        },
        "IntervalInSeconds": {
          "type": "integer"
        }
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "NoEncryptionConfig": {},
        "KMSEncryptionConfig": {
          "type": "structure",
          "required": [
            "AWSKMSKeyARN"
          ],
          "members": {
            "AWSKMSKeyARN": {}
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogGroupName": {},
        "LogStreamName": {}
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Processors": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "ParameterName",
                    "ParameterValue"
                  ],
                  "members": {
                    "ParameterName": {},
                    "ParameterValue": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "SchemaConfiguration": {
          "type": "structure",
          "members": {
            "RoleARN": {},
            "CatalogId": {},
            "DatabaseName": {},
            "TableName": {},
            "Region": {},
            "VersionId": {}
          }
        },
        "InputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Deserializer": {
              "type": "structure",
              "members": {
                "OpenXJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "ConvertDotsInJsonKeysToUnderscores": {
                      "type": "boolean"
                    },
                    "CaseInsensitive": {
                      "type": "boolean"
                    },
                    "ColumnToJsonKeyMappings": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    }
                  }
                },
                "HiveJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "TimestampFormats": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        },
        "OutputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Serializer": {
              "type": "structure",
              "members": {
                "ParquetSerDe": {
                  "type": "structure",
                  "members": {
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "PageSizeBytes": {
                      "type": "integer"
                    },
                    "Compression": {},
                    "EnableDictionaryCompression": {
                      "type": "boolean"
                    },
                    "MaxPaddingBytes": {
                      "type": "integer"
                    },
                    "WriterVersion": {}
                  }
                },
                "OrcSerDe": {
                  "type": "structure",
                  "members": {
                    "StripeSizeBytes": {
                      "type": "integer"
                    },
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "RowIndexStride": {
                      "type": "integer"
                    },
                    "EnablePadding": {
                      "type": "boolean"
                    },
                    "PaddingTolerance": {
                      "type": "double"
                    },
                    "Compression": {},
                    "BloomFilterColumns": {
                      "type": "list",
                      "member": {}
                    },
                    "BloomFilterFalsePositiveProbability": {
                      "type": "double"
                    },
                    "DictionaryKeyThreshold": {
                      "type": "double"
                    },
                    "FormatVersion": {}
                  }
                }
              }
            }
          }
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1q": {
      "type": "structure",
      "required": [
        "DataTableName"
      ],
      "members": {
        "DataTableName": {},
        "DataTableColumns": {},
        "CopyOptions": {}
      }
    },
    "S1u": {
      "type": "string",
      "sensitive": true
    },
    "S1v": {
      "type": "string",
      "sensitive": true
    },
    "S1w": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2j": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {
        "shape": "S2n"
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S30": {
      "type": "structure",
      "required": [
        "Type",
        "Details"
      ],
      "members": {
        "Type": {},
        "Details": {}
      }
    },
    "S3b": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN",
        "BufferingHints",
        "CompressionFormat",
        "EncryptionConfiguration"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    },
    "S3q": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {
          "type": "blob"
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    }
  }
}
},{}],105:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],106:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-26",
    "endpointPrefix": "forecast",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Forecast Service",
    "serviceId": "forecast",
    "signatureVersion": "v4",
    "signingName": "forecast",
    "targetPrefix": "AmazonForecast",
    "uid": "forecast-2018-06-26"
  },
  "operations": {
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetName",
          "Domain",
          "DatasetType",
          "Schema"
        ],
        "members": {
          "DatasetName": {},
          "Domain": {},
          "DatasetType": {},
          "DataFrequency": {},
          "Schema": {
            "shape": "S6"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetArn": {}
        }
      }
    },
    "CreateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupName",
          "Domain"
        ],
        "members": {
          "DatasetGroupName": {},
          "Domain": {},
          "DatasetArns": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroupArn": {}
        }
      }
    },
    "CreateDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobName",
          "DatasetArn",
          "DataSource"
        ],
        "members": {
          "DatasetImportJobName": {},
          "DatasetArn": {},
          "DataSource": {
            "shape": "Si"
          },
          "TimestampFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobArn": {}
        }
      }
    },
    "CreateForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastName",
          "PredictorArn"
        ],
        "members": {
          "ForecastName": {},
          "PredictorArn": {},
          "ForecastTypes": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastArn": {}
        }
      }
    },
    "CreateForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobName",
          "ForecastArn",
          "Destination"
        ],
        "members": {
          "ForecastExportJobName": {},
          "ForecastArn": {},
          "Destination": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobArn": {}
        }
      }
    },
    "CreatePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorName",
          "ForecastHorizon",
          "InputDataConfig",
          "FeaturizationConfig"
        ],
        "members": {
          "PredictorName": {},
          "AlgorithmArn": {},
          "ForecastHorizon": {
            "type": "integer"
          },
          "PerformAutoML": {
            "type": "boolean"
          },
          "PerformHPO": {
            "type": "boolean"
          },
          "TrainingParameters": {
            "shape": "Sx"
          },
          "EvaluationParameters": {
            "shape": "S10"
          },
          "HPOConfig": {
            "shape": "S11"
          },
          "InputDataConfig": {
            "shape": "S1d"
          },
          "FeaturizationConfig": {
            "shape": "S1g"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorArn": {}
        }
      }
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetArn"
        ],
        "members": {
          "DatasetArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn"
        ],
        "members": {
          "DatasetGroupArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobArn"
        ],
        "members": {
          "DatasetImportJobArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn"
        ],
        "members": {
          "ForecastArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobArn"
        ],
        "members": {
          "ForecastExportJobArn": {}
        }
      },
      "idempotent": true
    },
    "DeletePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "idempotent": true
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetArn"
        ],
        "members": {
          "DatasetArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetArn": {},
          "DatasetName": {},
          "Domain": {},
          "DatasetType": {},
          "DataFrequency": {},
          "Schema": {
            "shape": "S6"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn"
        ],
        "members": {
          "DatasetGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroupName": {},
          "DatasetGroupArn": {},
          "DatasetArns": {
            "shape": "Sf"
          },
          "Domain": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetImportJobArn"
        ],
        "members": {
          "DatasetImportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobName": {},
          "DatasetImportJobArn": {},
          "DatasetArn": {},
          "TimestampFormat": {},
          "DataSource": {
            "shape": "Si"
          },
          "FieldStatistics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "Count": {
                  "type": "integer"
                },
                "CountDistinct": {
                  "type": "integer"
                },
                "CountNull": {
                  "type": "integer"
                },
                "CountNan": {
                  "type": "integer"
                },
                "Min": {},
                "Max": {},
                "Avg": {
                  "type": "double"
                },
                "Stddev": {
                  "type": "double"
                }
              }
            }
          },
          "DataSize": {
            "type": "double"
          },
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn"
        ],
        "members": {
          "ForecastArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastArn": {},
          "ForecastName": {},
          "ForecastTypes": {
            "shape": "So"
          },
          "PredictorArn": {},
          "DatasetGroupArn": {},
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeForecastExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastExportJobArn"
        ],
        "members": {
          "ForecastExportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobArn": {},
          "ForecastExportJobName": {},
          "ForecastArn": {},
          "Destination": {
            "shape": "Ss"
          },
          "Message": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribePredictor": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorArn": {},
          "PredictorName": {},
          "AlgorithmArn": {},
          "ForecastHorizon": {
            "type": "integer"
          },
          "PerformAutoML": {
            "type": "boolean"
          },
          "PerformHPO": {
            "type": "boolean"
          },
          "TrainingParameters": {
            "shape": "Sx"
          },
          "EvaluationParameters": {
            "shape": "S10"
          },
          "HPOConfig": {
            "shape": "S11"
          },
          "InputDataConfig": {
            "shape": "S1d"
          },
          "FeaturizationConfig": {
            "shape": "S1g"
          },
          "EncryptionConfig": {
            "shape": "Sa"
          },
          "PredictorExecutionDetails": {
            "type": "structure",
            "members": {
              "PredictorExecutions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "AlgorithmArn": {},
                    "TestWindows": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "TestWindowStart": {
                            "type": "timestamp"
                          },
                          "TestWindowEnd": {
                            "type": "timestamp"
                          },
                          "Status": {},
                          "Message": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "DatasetImportJobArns": {
            "shape": "Sf"
          },
          "AutoMLAlgorithmArns": {
            "shape": "Sf"
          },
          "Status": {},
          "Message": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModificationTime": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "GetAccuracyMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "PredictorArn"
        ],
        "members": {
          "PredictorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredictorEvaluationResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AlgorithmArn": {},
                "TestWindows": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "TestWindowStart": {
                        "type": "timestamp"
                      },
                      "TestWindowEnd": {
                        "type": "timestamp"
                      },
                      "ItemCount": {
                        "type": "integer"
                      },
                      "EvaluationType": {},
                      "Metrics": {
                        "type": "structure",
                        "members": {
                          "RMSE": {
                            "type": "double"
                          },
                          "WeightedQuantileLosses": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Quantile": {
                                  "type": "double"
                                },
                                "LossValue": {
                                  "type": "double"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListDatasetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetGroupArn": {},
                "DatasetGroupName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetImportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DatasetImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetImportJobArn": {},
                "DatasetImportJobName": {},
                "DataSource": {
                  "shape": "Si"
                },
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetArn": {},
                "DatasetName": {},
                "DatasetType": {},
                "Domain": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListForecastExportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ForecastExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ForecastExportJobArn": {},
                "ForecastExportJobName": {},
                "Destination": {
                  "shape": "Ss"
                },
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListForecasts": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Forecasts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ForecastArn": {},
                "ForecastName": {},
                "PredictorArn": {},
                "DatasetGroupArn": {},
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListPredictors": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Predictors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PredictorArn": {},
                "PredictorName": {},
                "DatasetGroupArn": {},
                "Status": {},
                "Message": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModificationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "UpdateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DatasetGroupArn",
          "DatasetArns"
        ],
        "members": {
          "DatasetGroupArn": {},
          "DatasetArns": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "Attributes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeType": {}
            }
          }
        }
      }
    },
    "Sa": {
      "type": "structure",
      "required": [
        "RoleArn",
        "KMSKeyArn"
      ],
      "members": {
        "RoleArn": {},
        "KMSKeyArn": {}
      }
    },
    "Sf": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "required": [
        "S3Config"
      ],
      "members": {
        "S3Config": {
          "shape": "Sj"
        }
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "Path",
        "RoleArn"
      ],
      "members": {
        "Path": {},
        "RoleArn": {},
        "KMSKeyArn": {}
      }
    },
    "So": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "structure",
      "required": [
        "S3Config"
      ],
      "members": {
        "S3Config": {
          "shape": "Sj"
        }
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "structure",
      "members": {
        "NumberOfBacktestWindows": {
          "type": "integer"
        },
        "BackTestWindowOffset": {
          "type": "integer"
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "ParameterRanges": {
          "type": "structure",
          "members": {
            "CategoricalParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Values"
                ],
                "members": {
                  "Name": {},
                  "Values": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            },
            "ContinuousParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MaxValue",
                  "MinValue"
                ],
                "members": {
                  "Name": {},
                  "MaxValue": {
                    "type": "double"
                  },
                  "MinValue": {
                    "type": "double"
                  },
                  "ScalingType": {}
                }
              }
            },
            "IntegerParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MaxValue",
                  "MinValue"
                ],
                "members": {
                  "Name": {},
                  "MaxValue": {
                    "type": "integer"
                  },
                  "MinValue": {
                    "type": "integer"
                  },
                  "ScalingType": {}
                }
              }
            }
          }
        }
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "DatasetGroupArn"
      ],
      "members": {
        "DatasetGroupArn": {},
        "SupplementaryFeatures": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Value"
            ],
            "members": {
              "Name": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S1g": {
      "type": "structure",
      "required": [
        "ForecastFrequency"
      ],
      "members": {
        "ForecastFrequency": {},
        "ForecastDimensions": {
          "type": "list",
          "member": {}
        },
        "Featurizations": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AttributeName"
            ],
            "members": {
              "AttributeName": {},
              "FeaturizationPipeline": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "FeaturizationMethodName"
                  ],
                  "members": {
                    "FeaturizationMethodName": {},
                    "FeaturizationMethodParameters": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S30": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value",
          "Condition"
        ],
        "members": {
          "Key": {},
          "Value": {},
          "Condition": {}
        }
      }
    }
  }
}
},{}],107:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListDatasetGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DatasetGroups"
    },
    "ListDatasetImportJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DatasetImportJobs"
    },
    "ListDatasets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Datasets"
    },
    "ListForecastExportJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ForecastExportJobs"
    },
    "ListForecasts": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Forecasts"
    },
    "ListPredictors": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Predictors"
    }
  }
}
},{}],108:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-26",
    "endpointPrefix": "forecastquery",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Forecast Query Service",
    "serviceId": "forecastquery",
    "signatureVersion": "v4",
    "signingName": "forecast",
    "targetPrefix": "AmazonForecastRuntime",
    "uid": "forecastquery-2018-06-26"
  },
  "operations": {
    "QueryForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn",
          "Filters"
        ],
        "members": {
          "ForecastArn": {},
          "StartDate": {},
          "EndDate": {},
          "Filters": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Forecast": {
            "type": "structure",
            "members": {
              "Predictions": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Timestamp": {},
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {}
}
},{}],109:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],110:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-10-01",
    "endpointPrefix": "gamelift",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon GameLift",
    "serviceId": "GameLift",
    "signatureVersion": "v4",
    "targetPrefix": "GameLift",
    "uid": "gamelift-2015-10-01"
  },
  "operations": {
    "AcceptMatch": {
      "input": {
        "type": "structure",
        "required": [
          "TicketId",
          "PlayerIds",
          "AcceptanceType"
        ],
        "members": {
          "TicketId": {},
          "PlayerIds": {
            "shape": "S3"
          },
          "AcceptanceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ClaimGameServer": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName"
        ],
        "members": {
          "GameServerGroupName": {},
          "GameServerId": {},
          "GameServerData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServer": {
            "shape": "Sc"
          }
        }
      }
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoutingStrategy"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "Sn"
          },
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Sw"
          }
        }
      }
    },
    "CreateBuild": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "S10"
          },
          "OperatingSystem": {},
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "S14"
          },
          "UploadCredentials": {
            "shape": "S19"
          },
          "StorageLocation": {
            "shape": "S10"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "EC2InstanceType"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BuildId": {},
          "ScriptId": {},
          "ServerLaunchPath": {},
          "ServerLaunchParameters": {},
          "LogPaths": {
            "shape": "S3"
          },
          "EC2InstanceType": {},
          "EC2InboundPermissions": {
            "shape": "S1e"
          },
          "NewGameSessionProtectionPolicy": {},
          "RuntimeConfiguration": {
            "shape": "S1k"
          },
          "ResourceCreationLimitPolicy": {
            "shape": "S1q"
          },
          "MetricGroups": {
            "shape": "S1s"
          },
          "PeerVpcAwsAccountId": {},
          "PeerVpcId": {},
          "FleetType": {},
          "InstanceRoleArn": {},
          "CertificateConfiguration": {
            "shape": "S1v"
          },
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "shape": "S1y"
          }
        }
      }
    },
    "CreateGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "RoleArn",
          "MinSize",
          "MaxSize",
          "LaunchTemplate",
          "InstanceDefinitions"
        ],
        "members": {
          "GameServerGroupName": {},
          "RoleArn": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "LaunchTemplate": {
            "type": "structure",
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "InstanceDefinitions": {
            "shape": "S2b"
          },
          "AutoScalingPolicy": {
            "type": "structure",
            "required": [
              "TargetTrackingConfiguration"
            ],
            "members": {
              "EstimatedInstanceWarmup": {
                "type": "integer"
              },
              "TargetTrackingConfiguration": {
                "type": "structure",
                "required": [
                  "TargetValue"
                ],
                "members": {
                  "TargetValue": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "BalancingStrategy": {},
          "GameServerProtectionPolicy": {},
          "VpcSubnets": {
            "type": "list",
            "member": {}
          },
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "CreateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "MaximumPlayerSessionCount"
        ],
        "members": {
          "FleetId": {},
          "AliasId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "GameProperties": {
            "shape": "S2v"
          },
          "CreatorId": {},
          "GameSessionId": {},
          "IdempotencyToken": {},
          "GameSessionData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S32"
          }
        }
      }
    },
    "CreateGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "TimeoutInSeconds": {
            "type": "integer"
          },
          "PlayerLatencyPolicies": {
            "shape": "S3b"
          },
          "Destinations": {
            "shape": "S3d"
          },
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueue": {
            "shape": "S3h"
          }
        }
      }
    },
    "CreateMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "GameSessionQueueArns",
          "RequestTimeoutSeconds",
          "AcceptanceRequired",
          "RuleSetName"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "GameSessionQueueArns": {
            "shape": "S3k"
          },
          "RequestTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "RuleSetName": {},
          "NotificationTarget": {},
          "AdditionalPlayerCount": {
            "type": "integer"
          },
          "CustomEventData": {},
          "GameProperties": {
            "shape": "S2v"
          },
          "GameSessionData": {},
          "BackfillMode": {},
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S3t"
          }
        }
      }
    },
    "CreateMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RuleSetBody"
        ],
        "members": {
          "Name": {},
          "RuleSetBody": {},
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleSet"
        ],
        "members": {
          "RuleSet": {
            "shape": "S3z"
          }
        }
      }
    },
    "CreatePlayerSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerId"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerId": {},
          "PlayerData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSession": {
            "shape": "S43"
          }
        }
      }
    },
    "CreatePlayerSessions": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerIds"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerIds": {
            "type": "list",
            "member": {}
          },
          "PlayerDataMap": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S4a"
          }
        }
      }
    },
    "CreateScript": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "S10"
          },
          "ZipFile": {
            "type": "blob"
          },
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S4e"
          }
        }
      }
    },
    "CreateVpcPeeringAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "GameLiftAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "GameLiftAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringAuthorization": {
            "shape": "S4h"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "PeerVpcAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "FleetId": {},
          "PeerVpcAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      }
    },
    "DeleteBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      }
    },
    "DeleteFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      }
    },
    "DeleteGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName"
        ],
        "members": {
          "GameServerGroupName": {},
          "DeleteOption": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "DeleteGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId"
        ],
        "members": {
          "Name": {},
          "FleetId": {}
        }
      }
    },
    "DeleteScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {}
        }
      }
    },
    "DeleteVpcPeeringAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "GameLiftAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "GameLiftAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "VpcPeeringConnectionId"
        ],
        "members": {
          "FleetId": {},
          "VpcPeeringConnectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterGameServer": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "GameServerId"
        ],
        "members": {
          "GameServerGroupName": {},
          "GameServerId": {}
        }
      }
    },
    "DescribeAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Sw"
          }
        }
      }
    },
    "DescribeBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "S14"
          }
        }
      }
    },
    "DescribeEC2InstanceLimits": {
      "input": {
        "type": "structure",
        "members": {
          "EC2InstanceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EC2InstanceLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EC2InstanceType": {},
                "CurrentInstances": {
                  "type": "integer"
                },
                "InstanceLimit": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeFleetAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S5e"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetCapacity": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S5e"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetCapacity": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceType": {},
                "InstanceCounts": {
                  "type": "structure",
                  "members": {
                    "DESIRED": {
                      "type": "integer"
                    },
                    "MINIMUM": {
                      "type": "integer"
                    },
                    "MAXIMUM": {
                      "type": "integer"
                    },
                    "PENDING": {
                      "type": "integer"
                    },
                    "ACTIVE": {
                      "type": "integer"
                    },
                    "IDLE": {
                      "type": "integer"
                    },
                    "TERMINATING": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetEvents": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ResourceId": {},
                "EventCode": {},
                "Message": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "PreSignedLogUrl": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InboundPermissions": {
            "shape": "S1e"
          }
        }
      }
    },
    "DescribeFleetUtilization": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S5e"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetUtilization": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "ActiveServerProcessCount": {
                  "type": "integer"
                },
                "ActiveGameSessionCount": {
                  "type": "integer"
                },
                "CurrentPlayerSessionCount": {
                  "type": "integer"
                },
                "MaximumPlayerSessionCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameServer": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "GameServerId"
        ],
        "members": {
          "GameServerGroupName": {},
          "GameServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServer": {
            "shape": "Sc"
          }
        }
      }
    },
    "DescribeGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName"
        ],
        "members": {
          "GameServerGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "DescribeGameSessionDetails": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GameSession": {
                  "shape": "S32"
                },
                "ProtectionPolicy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId"
        ],
        "members": {
          "PlacementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S67"
          }
        }
      }
    },
    "DescribeGameSessionQueues": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueues": {
            "type": "list",
            "member": {
              "shape": "S3h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S6k"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceId": {},
                "IpAddress": {},
                "DnsName": {},
                "OperatingSystem": {},
                "Type": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "TicketIds"
        ],
        "members": {
          "TicketIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TicketList": {
            "type": "list",
            "member": {
              "shape": "S6v"
            }
          }
        }
      }
    },
    "DescribeMatchmakingConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "RuleSetName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configurations": {
            "type": "list",
            "member": {
              "shape": "S3t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMatchmakingRuleSets": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleSets"
        ],
        "members": {
          "RuleSets": {
            "type": "list",
            "member": {
              "shape": "S3z"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePlayerSessions": {
      "input": {
        "type": "structure",
        "members": {
          "GameSessionId": {},
          "PlayerId": {},
          "PlayerSessionId": {},
          "PlayerSessionStatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S4a"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "S1k"
          }
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "Name": {},
                "Status": {},
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "ScalingAdjustmentType": {},
                "ComparisonOperator": {},
                "Threshold": {
                  "type": "double"
                },
                "EvaluationPeriods": {
                  "type": "integer"
                },
                "MetricName": {},
                "PolicyType": {},
                "TargetConfiguration": {
                  "shape": "S7w"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S4e"
          }
        }
      }
    },
    "DescribeVpcPeeringAuthorizations": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringAuthorizations": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "FleetArn": {},
                "IpV4CidrBlock": {},
                "VpcPeeringConnectionId": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "Code": {},
                    "Message": {}
                  }
                },
                "PeerVpcId": {},
                "GameLiftVpcId": {}
              }
            }
          }
        }
      }
    },
    "GetGameSessionLogUrl": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreSignedUrl": {}
        }
      }
    },
    "GetInstanceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "InstanceId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceAccess": {
            "type": "structure",
            "members": {
              "FleetId": {},
              "InstanceId": {},
              "IpAddress": {},
              "OperatingSystem": {},
              "Credentials": {
                "type": "structure",
                "members": {
                  "UserName": {},
                  "Secret": {}
                },
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "RoutingStrategyType": {},
          "Name": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Sw"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBuilds": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Builds": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFleets": {
      "input": {
        "type": "structure",
        "members": {
          "BuildId": {},
          "ScriptId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListGameServerGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroups": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListGameServers": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName"
        ],
        "members": {
          "GameServerGroupName": {},
          "SortOrder": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServers": {
            "type": "list",
            "member": {
              "shape": "Sc"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListScripts": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Scripts": {
            "type": "list",
            "member": {
              "shape": "S4e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sr"
          }
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId",
          "MetricName"
        ],
        "members": {
          "Name": {},
          "FleetId": {},
          "ScalingAdjustment": {
            "type": "integer"
          },
          "ScalingAdjustmentType": {},
          "Threshold": {
            "type": "double"
          },
          "ComparisonOperator": {},
          "EvaluationPeriods": {
            "type": "integer"
          },
          "MetricName": {},
          "PolicyType": {},
          "TargetConfiguration": {
            "shape": "S7w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "RegisterGameServer": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "GameServerId",
          "InstanceId"
        ],
        "members": {
          "GameServerGroupName": {},
          "GameServerId": {},
          "InstanceId": {},
          "ConnectionInfo": {},
          "GameServerData": {},
          "CustomSortKey": {},
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServer": {
            "shape": "Sc"
          }
        }
      }
    },
    "RequestUploadCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UploadCredentials": {
            "shape": "S19"
          },
          "StorageLocation": {
            "shape": "S10"
          }
        }
      }
    },
    "ResolveAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "FleetArn": {}
        }
      }
    },
    "ResumeGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "ResumeActions"
        ],
        "members": {
          "GameServerGroupName": {},
          "ResumeActions": {
            "shape": "S2q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "SearchGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "AliasId": {},
          "FilterExpression": {},
          "SortExpression": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S6k"
          },
          "NextToken": {}
        }
      }
    },
    "StartFleetActions": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "Actions"
        ],
        "members": {
          "FleetId": {},
          "Actions": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId",
          "GameSessionQueueName",
          "MaximumPlayerSessionCount"
        ],
        "members": {
          "PlacementId": {},
          "GameSessionQueueName": {},
          "GameProperties": {
            "shape": "S2v"
          },
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "GameSessionName": {},
          "PlayerLatencies": {
            "shape": "S69"
          },
          "DesiredPlayerSessions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlayerId": {},
                "PlayerData": {}
              }
            }
          },
          "GameSessionData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S67"
          }
        }
      }
    },
    "StartMatchBackfill": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationName",
          "GameSessionArn",
          "Players"
        ],
        "members": {
          "TicketId": {},
          "ConfigurationName": {},
          "GameSessionArn": {},
          "Players": {
            "shape": "S6y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MatchmakingTicket": {
            "shape": "S6v"
          }
        }
      }
    },
    "StartMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationName",
          "Players"
        ],
        "members": {
          "TicketId": {},
          "ConfigurationName": {},
          "Players": {
            "shape": "S6y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MatchmakingTicket": {
            "shape": "S6v"
          }
        }
      }
    },
    "StopFleetActions": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "Actions"
        ],
        "members": {
          "FleetId": {},
          "Actions": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId"
        ],
        "members": {
          "PlacementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S67"
          }
        }
      }
    },
    "StopMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "TicketId"
        ],
        "members": {
          "TicketId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SuspendGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "SuspendActions"
        ],
        "members": {
          "GameServerGroupName": {},
          "SuspendActions": {
            "shape": "S2q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {},
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Sw"
          }
        }
      }
    },
    "UpdateBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {},
          "Name": {},
          "Version": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "S14"
          }
        }
      }
    },
    "UpdateFleetAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "Name": {},
          "Description": {},
          "NewGameSessionProtectionPolicy": {},
          "ResourceCreationLimitPolicy": {
            "shape": "S1q"
          },
          "MetricGroups": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "DesiredInstances": {
            "type": "integer"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InboundPermissionAuthorizations": {
            "shape": "S1e"
          },
          "InboundPermissionRevocations": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateGameServer": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName",
          "GameServerId"
        ],
        "members": {
          "GameServerGroupName": {},
          "GameServerId": {},
          "GameServerData": {},
          "CustomSortKey": {},
          "UtilizationStatus": {},
          "HealthCheck": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServer": {
            "shape": "Sc"
          }
        }
      }
    },
    "UpdateGameServerGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GameServerGroupName"
        ],
        "members": {
          "GameServerGroupName": {},
          "RoleArn": {},
          "InstanceDefinitions": {
            "shape": "S2b"
          },
          "GameServerProtectionPolicy": {},
          "BalancingStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameServerGroup": {
            "shape": "S2n"
          }
        }
      }
    },
    "UpdateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "PlayerSessionCreationPolicy": {},
          "ProtectionPolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S32"
          }
        }
      }
    },
    "UpdateGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "TimeoutInSeconds": {
            "type": "integer"
          },
          "PlayerLatencyPolicies": {
            "shape": "S3b"
          },
          "Destinations": {
            "shape": "S3d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueue": {
            "shape": "S3h"
          }
        }
      }
    },
    "UpdateMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "GameSessionQueueArns": {
            "shape": "S3k"
          },
          "RequestTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "RuleSetName": {},
          "NotificationTarget": {},
          "AdditionalPlayerCount": {
            "type": "integer"
          },
          "CustomEventData": {},
          "GameProperties": {
            "shape": "S2v"
          },
          "GameSessionData": {},
          "BackfillMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S3t"
          }
        }
      }
    },
    "UpdateRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "RuntimeConfiguration"
        ],
        "members": {
          "FleetId": {},
          "RuntimeConfiguration": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "S1k"
          }
        }
      }
    },
    "UpdateScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {},
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "S10"
          },
          "ZipFile": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S4e"
          }
        }
      }
    },
    "ValidateMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetBody"
        ],
        "members": {
          "RuleSetBody": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Valid": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "structure",
      "members": {
        "GameServerGroupName": {},
        "GameServerGroupArn": {},
        "GameServerId": {},
        "InstanceId": {},
        "ConnectionInfo": {},
        "GameServerData": {},
        "CustomSortKey": {},
        "ClaimStatus": {},
        "UtilizationStatus": {},
        "RegistrationTime": {
          "type": "timestamp"
        },
        "LastClaimTime": {
          "type": "timestamp"
        },
        "LastHealthCheckTime": {
          "type": "timestamp"
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Type": {},
        "FleetId": {},
        "Message": {}
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "AliasId": {},
        "Name": {},
        "AliasArn": {},
        "Description": {},
        "RoutingStrategy": {
          "shape": "Sn"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {},
        "RoleArn": {},
        "ObjectVersion": {}
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "BuildId": {},
        "BuildArn": {},
        "Name": {},
        "Version": {},
        "Status": {},
        "SizeOnDisk": {
          "type": "long"
        },
        "OperatingSystem": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "AccessKeyId": {},
        "SecretAccessKey": {},
        "SessionToken": {}
      },
      "sensitive": true
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FromPort",
          "ToPort",
          "IpRange",
          "Protocol"
        ],
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "IpRange": {},
          "Protocol": {}
        }
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "ServerProcesses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LaunchPath",
              "ConcurrentExecutions"
            ],
            "members": {
              "LaunchPath": {},
              "Parameters": {},
              "ConcurrentExecutions": {
                "type": "integer"
              }
            }
          }
        },
        "MaxConcurrentGameSessionActivations": {
          "type": "integer"
        },
        "GameSessionActivationTimeoutSeconds": {
          "type": "integer"
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "NewGameSessionsPerCreator": {
          "type": "integer"
        },
        "PolicyPeriodInMinutes": {
          "type": "integer"
        }
      }
    },
    "S1s": {
      "type": "list",
      "member": {}
    },
    "S1v": {
      "type": "structure",
      "required": [
        "CertificateType"
      ],
      "members": {
        "CertificateType": {}
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "FleetId": {},
        "FleetArn": {},
        "FleetType": {},
        "InstanceType": {},
        "Description": {},
        "Name": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "BuildId": {},
        "BuildArn": {},
        "ScriptId": {},
        "ScriptArn": {},
        "ServerLaunchPath": {},
        "ServerLaunchParameters": {},
        "LogPaths": {
          "shape": "S3"
        },
        "NewGameSessionProtectionPolicy": {},
        "OperatingSystem": {},
        "ResourceCreationLimitPolicy": {
          "shape": "S1q"
        },
        "MetricGroups": {
          "shape": "S1s"
        },
        "StoppedActions": {
          "shape": "S23"
        },
        "InstanceRoleArn": {},
        "CertificateConfiguration": {
          "shape": "S1v"
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {}
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceType"
        ],
        "members": {
          "InstanceType": {},
          "WeightedCapacity": {}
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "GameServerGroupName": {},
        "GameServerGroupArn": {},
        "RoleArn": {},
        "InstanceDefinitions": {
          "shape": "S2b"
        },
        "BalancingStrategy": {},
        "GameServerProtectionPolicy": {},
        "AutoScalingGroupArn": {},
        "Status": {},
        "StatusReason": {},
        "SuspendedActions": {
          "shape": "S2q"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S2q": {
      "type": "list",
      "member": {}
    },
    "S2v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "GameSessionId": {},
        "Name": {},
        "FleetId": {},
        "FleetArn": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "CurrentPlayerSessionCount": {
          "type": "integer"
        },
        "MaximumPlayerSessionCount": {
          "type": "integer"
        },
        "Status": {},
        "StatusReason": {},
        "GameProperties": {
          "shape": "S2v"
        },
        "IpAddress": {},
        "DnsName": {},
        "Port": {
          "type": "integer"
        },
        "PlayerSessionCreationPolicy": {},
        "CreatorId": {},
        "GameSessionData": {},
        "MatchmakerData": {}
      }
    },
    "S3b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "MaximumIndividualPlayerLatencyMilliseconds": {
            "type": "integer"
          },
          "PolicyDurationSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "S3d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DestinationArn": {}
        }
      }
    },
    "S3h": {
      "type": "structure",
      "members": {
        "Name": {},
        "GameSessionQueueArn": {},
        "TimeoutInSeconds": {
          "type": "integer"
        },
        "PlayerLatencyPolicies": {
          "shape": "S3b"
        },
        "Destinations": {
          "shape": "S3d"
        }
      }
    },
    "S3k": {
      "type": "list",
      "member": {}
    },
    "S3t": {
      "type": "structure",
      "members": {
        "Name": {},
        "ConfigurationArn": {},
        "Description": {},
        "GameSessionQueueArns": {
          "shape": "S3k"
        },
        "RequestTimeoutSeconds": {
          "type": "integer"
        },
        "AcceptanceTimeoutSeconds": {
          "type": "integer"
        },
        "AcceptanceRequired": {
          "type": "boolean"
        },
        "RuleSetName": {},
        "RuleSetArn": {},
        "NotificationTarget": {},
        "AdditionalPlayerCount": {
          "type": "integer"
        },
        "CustomEventData": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "GameProperties": {
          "shape": "S2v"
        },
        "GameSessionData": {},
        "BackfillMode": {}
      }
    },
    "S3z": {
      "type": "structure",
      "required": [
        "RuleSetBody"
      ],
      "members": {
        "RuleSetName": {},
        "RuleSetArn": {},
        "RuleSetBody": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S43": {
      "type": "structure",
      "members": {
        "PlayerSessionId": {},
        "PlayerId": {},
        "GameSessionId": {},
        "FleetId": {},
        "FleetArn": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "IpAddress": {},
        "DnsName": {},
        "Port": {
          "type": "integer"
        },
        "PlayerData": {}
      }
    },
    "S4a": {
      "type": "list",
      "member": {
        "shape": "S43"
      }
    },
    "S4e": {
      "type": "structure",
      "members": {
        "ScriptId": {},
        "ScriptArn": {},
        "Name": {},
        "Version": {},
        "SizeOnDisk": {
          "type": "long"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "StorageLocation": {
          "shape": "S10"
        }
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "GameLiftAwsAccountId": {},
        "PeerVpcAwsAccountId": {},
        "PeerVpcId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "ExpirationTime": {
          "type": "timestamp"
        }
      }
    },
    "S5e": {
      "type": "list",
      "member": {}
    },
    "S67": {
      "type": "structure",
      "members": {
        "PlacementId": {},
        "GameSessionQueueName": {},
        "Status": {},
        "GameProperties": {
          "shape": "S2v"
        },
        "MaximumPlayerSessionCount": {
          "type": "integer"
        },
        "GameSessionName": {},
        "GameSessionId": {},
        "GameSessionArn": {},
        "GameSessionRegion": {},
        "PlayerLatencies": {
          "shape": "S69"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "IpAddress": {},
        "DnsName": {},
        "Port": {
          "type": "integer"
        },
        "PlacedPlayerSessions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PlayerId": {},
              "PlayerSessionId": {}
            }
          }
        },
        "GameSessionData": {},
        "MatchmakerData": {}
      }
    },
    "S69": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PlayerId": {},
          "RegionIdentifier": {},
          "LatencyInMilliseconds": {
            "type": "float"
          }
        }
      }
    },
    "S6k": {
      "type": "list",
      "member": {
        "shape": "S32"
      }
    },
    "S6v": {
      "type": "structure",
      "members": {
        "TicketId": {},
        "ConfigurationName": {},
        "ConfigurationArn": {},
        "Status": {},
        "StatusReason": {},
        "StatusMessage": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "Players": {
          "shape": "S6y"
        },
        "GameSessionConnectionInfo": {
          "type": "structure",
          "members": {
            "GameSessionArn": {},
            "IpAddress": {},
            "DnsName": {},
            "Port": {
              "type": "integer"
            },
            "MatchedPlayerSessions": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "PlayerId": {},
                  "PlayerSessionId": {}
                }
              }
            }
          }
        },
        "EstimatedWaitTime": {
          "type": "integer"
        }
      }
    },
    "S6y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PlayerId": {},
          "PlayerAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "S": {},
                "N": {
                  "type": "double"
                },
                "SL": {
                  "shape": "S3"
                },
                "SDM": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "Team": {},
          "LatencyInMs": {
            "type": "map",
            "key": {},
            "value": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S7w": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "TargetValue": {
          "type": "double"
        }
      }
    }
  }
}
},{}],111:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],112:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-05-08",
    "endpointPrefix": "iam",
    "globalEndpoint": "iam.amazonaws.com",
    "protocol": "query",
    "serviceAbbreviation": "IAM",
    "serviceFullName": "AWS Identity and Access Management",
    "serviceId": "IAM",
    "signatureVersion": "v4",
    "uid": "iam-2010-05-08",
    "xmlNamespace": "https://iam.amazonaws.com/doc/2010-05-08/"
  },
  "operations": {
    "AddClientIDToOpenIDConnectProvider": {
      "input": {
        "type": "structure",
        "required": [
          "OpenIDConnectProviderArn",
          "ClientID"
        ],
        "members": {
          "OpenIDConnectProviderArn": {},
          "ClientID": {}
        }
      }
    },
    "AddRoleToInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceProfileName",
          "RoleName"
        ],
        "members": {
          "InstanceProfileName": {},
          "RoleName": {}
        }
      }
    },
    "AddUserToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserName"
        ],
        "members": {
          "GroupName": {},
          "UserName": {}
        }
      }
    },
    "AttachGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyArn"
        ],
        "members": {
          "GroupName": {},
          "PolicyArn": {}
        }
      }
    },
    "AttachRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyArn"
        ],
        "members": {
          "RoleName": {},
          "PolicyArn": {}
        }
      }
    },
    "AttachUserPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PolicyArn"
        ],
        "members": {
          "UserName": {},
          "PolicyArn": {}
        }
      }
    },
    "ChangePassword": {
      "input": {
        "type": "structure",
        "required": [
          "OldPassword",
          "NewPassword"
        ],
        "members": {
          "OldPassword": {
            "shape": "Sf"
          },
          "NewPassword": {
            "shape": "Sf"
          }
        }
      }
    },
    "CreateAccessKey": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateAccessKeyResult",
        "type": "structure",
        "required": [
          "AccessKey"
        ],
        "members": {
          "AccessKey": {
            "type": "structure",
            "required": [
              "UserName",
              "AccessKeyId",
              "Status",
              "SecretAccessKey"
            ],
            "members": {
              "UserName": {},
              "AccessKeyId": {},
              "Status": {},
              "SecretAccessKey": {
                "type": "string",
                "sensitive": true
              },
              "CreateDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "CreateAccountAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AccountAlias"
        ],
        "members": {
          "AccountAlias": {}
        }
      }
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "Path": {},
          "GroupName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateGroupResult",
        "type": "structure",
        "required": [
          "Group"
        ],
        "members": {
          "Group": {
            "shape": "Ss"
          }
        }
      }
    },
    "CreateInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceProfileName"
        ],
        "members": {
          "InstanceProfileName": {},
          "Path": {}
        }
      },
      "output": {
        "resultWrapper": "CreateInstanceProfileResult",
        "type": "structure",
        "required": [
          "InstanceProfile"
        ],
        "members": {
          "InstanceProfile": {
            "shape": "Sw"
          }
        }
      }
    },
    "CreateLoginProfile": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "Password"
        ],
        "members": {
          "UserName": {},
          "Password": {
            "shape": "Sf"
          },
          "PasswordResetRequired": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateLoginProfileResult",
        "type": "structure",
        "required": [
          "LoginProfile"
        ],
        "members": {
          "LoginProfile": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateOpenIDConnectProvider": {
      "input": {
        "type": "structure",
        "required": [
          "Url",
          "ThumbprintList"
        ],
        "members": {
          "Url": {},
          "ClientIDList": {
            "shape": "S1g"
          },
          "ThumbprintList": {
            "shape": "S1h"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateOpenIDConnectProviderResult",
        "type": "structure",
        "members": {
          "OpenIDConnectProviderArn": {}
        }
      }
    },
    "CreatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "PolicyName": {},
          "Path": {},
          "PolicyDocument": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreatePolicyResult",
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "S1p"
          }
        }
      }
    },
    "CreatePolicyVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn",
          "PolicyDocument"
        ],
        "members": {
          "PolicyArn": {},
          "PolicyDocument": {},
          "SetAsDefault": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreatePolicyVersionResult",
        "type": "structure",
        "members": {
          "PolicyVersion": {
            "shape": "S1u"
          }
        }
      }
    },
    "CreateRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "AssumeRolePolicyDocument"
        ],
        "members": {
          "Path": {},
          "RoleName": {},
          "AssumeRolePolicyDocument": {},
          "Description": {},
          "MaxSessionDuration": {
            "type": "integer"
          },
          "PermissionsBoundary": {},
          "Tags": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateRoleResult",
        "type": "structure",
        "required": [
          "Role"
        ],
        "members": {
          "Role": {
            "shape": "Sy"
          }
        }
      }
    },
    "CreateSAMLProvider": {
      "input": {
        "type": "structure",
        "required": [
          "SAMLMetadataDocument",
          "Name"
        ],
        "members": {
          "SAMLMetadataDocument": {},
          "Name": {}
        }
      },
      "output": {
        "resultWrapper": "CreateSAMLProviderResult",
        "type": "structure",
        "members": {
          "SAMLProviderArn": {}
        }
      }
    },
    "CreateServiceLinkedRole": {
      "input": {
        "type": "structure",
        "required": [
          "AWSServiceName"
        ],
        "members": {
          "AWSServiceName": {},
          "Description": {},
          "CustomSuffix": {}
        }
      },
      "output": {
        "resultWrapper": "CreateServiceLinkedRoleResult",
        "type": "structure",
        "members": {
          "Role": {
            "shape": "Sy"
          }
        }
      }
    },
    "CreateServiceSpecificCredential": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "ServiceName"
        ],
        "members": {
          "UserName": {},
          "ServiceName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateServiceSpecificCredentialResult",
        "type": "structure",
        "members": {
          "ServiceSpecificCredential": {
            "shape": "S27"
          }
        }
      }
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "Path": {},
          "UserName": {},
          "PermissionsBoundary": {},
          "Tags": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateUserResult",
        "type": "structure",
        "members": {
          "User": {
            "shape": "S2d"
          }
        }
      }
    },
    "CreateVirtualMFADevice": {
      "input": {
        "type": "structure",
        "required": [
          "VirtualMFADeviceName"
        ],
        "members": {
          "Path": {},
          "VirtualMFADeviceName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateVirtualMFADeviceResult",
        "type": "structure",
        "required": [
          "VirtualMFADevice"
        ],
        "members": {
          "VirtualMFADevice": {
            "shape": "S2h"
          }
        }
      }
    },
    "DeactivateMFADevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SerialNumber"
        ],
        "members": {
          "UserName": {},
          "SerialNumber": {}
        }
      }
    },
    "DeleteAccessKey": {
      "input": {
        "type": "structure",
        "required": [
          "AccessKeyId"
        ],
        "members": {
          "UserName": {},
          "AccessKeyId": {}
        }
      }
    },
    "DeleteAccountAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AccountAlias"
        ],
        "members": {
          "AccountAlias": {}
        }
      }
    },
    "DeleteAccountPasswordPolicy": {},
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {}
        }
      }
    },
    "DeleteGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyName"
        ],
        "members": {
          "GroupName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceProfileName"
        ],
        "members": {
          "InstanceProfileName": {}
        }
      }
    },
    "DeleteLoginProfile": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {}
        }
      }
    },
    "DeleteOpenIDConnectProvider": {
      "input": {
        "type": "structure",
        "required": [
          "OpenIDConnectProviderArn"
        ],
        "members": {
          "OpenIDConnectProviderArn": {}
        }
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn"
        ],
        "members": {
          "PolicyArn": {}
        }
      }
    },
    "DeletePolicyVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn",
          "VersionId"
        ],
        "members": {
          "PolicyArn": {},
          "VersionId": {}
        }
      }
    },
    "DeleteRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {}
        }
      }
    },
    "DeleteRolePermissionsBoundary": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {}
        }
      }
    },
    "DeleteRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyName"
        ],
        "members": {
          "RoleName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteSAMLProvider": {
      "input": {
        "type": "structure",
        "required": [
          "SAMLProviderArn"
        ],
        "members": {
          "SAMLProviderArn": {}
        }
      }
    },
    "DeleteSSHPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SSHPublicKeyId"
        ],
        "members": {
          "UserName": {},
          "SSHPublicKeyId": {}
        }
      }
    },
    "DeleteServerCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "ServerCertificateName"
        ],
        "members": {
          "ServerCertificateName": {}
        }
      }
    },
    "DeleteServiceLinkedRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteServiceLinkedRoleResult",
        "type": "structure",
        "required": [
          "DeletionTaskId"
        ],
        "members": {
          "DeletionTaskId": {}
        }
      }
    },
    "DeleteServiceSpecificCredential": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceSpecificCredentialId"
        ],
        "members": {
          "UserName": {},
          "ServiceSpecificCredentialId": {}
        }
      }
    },
    "DeleteSigningCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateId"
        ],
        "members": {
          "UserName": {},
          "CertificateId": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {}
        }
      }
    },
    "DeleteUserPermissionsBoundary": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {}
        }
      }
    },
    "DeleteUserPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PolicyName"
        ],
        "members": {
          "UserName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteVirtualMFADevice": {
      "input": {
        "type": "structure",
        "required": [
          "SerialNumber"
        ],
        "members": {
          "SerialNumber": {}
        }
      }
    },
    "DetachGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyArn"
        ],
        "members": {
          "GroupName": {},
          "PolicyArn": {}
        }
      }
    },
    "DetachRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyArn"
        ],
        "members": {
          "RoleName": {},
          "PolicyArn": {}
        }
      }
    },
    "DetachUserPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PolicyArn"
        ],
        "members": {
          "UserName": {},
          "PolicyArn": {}
        }
      }
    },
    "EnableMFADevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SerialNumber",
          "AuthenticationCode1",
          "AuthenticationCode2"
        ],
        "members": {
          "UserName": {},
          "SerialNumber": {},
          "AuthenticationCode1": {},
          "AuthenticationCode2": {}
        }
      }
    },
    "GenerateCredentialReport": {
      "output": {
        "resultWrapper": "GenerateCredentialReportResult",
        "type": "structure",
        "members": {
          "State": {},
          "Description": {}
        }
      }
    },
    "GenerateOrganizationsAccessReport": {
      "input": {
        "type": "structure",
        "required": [
          "EntityPath"
        ],
        "members": {
          "EntityPath": {},
          "OrganizationsPolicyId": {}
        }
      },
      "output": {
        "resultWrapper": "GenerateOrganizationsAccessReportResult",
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "GenerateServiceLastAccessedDetails": {
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "Granularity": {}
        }
      },
      "output": {
        "resultWrapper": "GenerateServiceLastAccessedDetailsResult",
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "GetAccessKeyLastUsed": {
      "input": {
        "type": "structure",
        "required": [
          "AccessKeyId"
        ],
        "members": {
          "AccessKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "GetAccessKeyLastUsedResult",
        "type": "structure",
        "members": {
          "UserName": {},
          "AccessKeyLastUsed": {
            "type": "structure",
            "required": [
              "LastUsedDate",
              "ServiceName",
              "Region"
            ],
            "members": {
              "LastUsedDate": {
                "type": "timestamp"
              },
              "ServiceName": {},
              "Region": {}
            }
          }
        }
      }
    },
    "GetAccountAuthorizationDetails": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "list",
            "member": {}
          },
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "GetAccountAuthorizationDetailsResult",
        "type": "structure",
        "members": {
          "UserDetailList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Path": {},
                "UserName": {},
                "UserId": {},
                "Arn": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "UserPolicyList": {
                  "shape": "S43"
                },
                "GroupList": {
                  "type": "list",
                  "member": {}
                },
                "AttachedManagedPolicies": {
                  "shape": "S46"
                },
                "PermissionsBoundary": {
                  "shape": "S12"
                },
                "Tags": {
                  "shape": "S14"
                }
              }
            }
          },
          "GroupDetailList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Path": {},
                "GroupName": {},
                "GroupId": {},
                "Arn": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "GroupPolicyList": {
                  "shape": "S43"
                },
                "AttachedManagedPolicies": {
                  "shape": "S46"
                }
              }
            }
          },
          "RoleDetailList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Path": {},
                "RoleName": {},
                "RoleId": {},
                "Arn": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "AssumeRolePolicyDocument": {},
                "InstanceProfileList": {
                  "shape": "S4c"
                },
                "RolePolicyList": {
                  "shape": "S43"
                },
                "AttachedManagedPolicies": {
                  "shape": "S46"
                },
                "PermissionsBoundary": {
                  "shape": "S12"
                },
                "Tags": {
                  "shape": "S14"
                },
                "RoleLastUsed": {
                  "shape": "S18"
                }
              }
            }
          },
          "Policies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyName": {},
                "PolicyId": {},
                "Arn": {},
                "Path": {},
                "DefaultVersionId": {},
                "AttachmentCount": {
                  "type": "integer"
                },
                "PermissionsBoundaryUsageCount": {
                  "type": "integer"
                },
                "IsAttachable": {
                  "type": "boolean"
                },
                "Description": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "UpdateDate": {
                  "type": "timestamp"
                },
                "PolicyVersionList": {
                  "shape": "S4f"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "GetAccountPasswordPolicy": {
      "output": {
        "resultWrapper": "GetAccountPasswordPolicyResult",
        "type": "structure",
        "required": [
          "PasswordPolicy"
        ],
        "members": {
          "PasswordPolicy": {
            "type": "structure",
            "members": {
              "MinimumPasswordLength": {
                "type": "integer"
              },
              "RequireSymbols": {
                "type": "boolean"
              },
              "RequireNumbers": {
                "type": "boolean"
              },
              "RequireUppercaseCharacters": {
                "type": "boolean"
              },
              "RequireLowercaseCharacters": {
                "type": "boolean"
              },
              "AllowUsersToChangePassword": {
                "type": "boolean"
              },
              "ExpirePasswords": {
                "type": "boolean"
              },
              "MaxPasswordAge": {
                "type": "integer"
              },
              "PasswordReusePrevention": {
                "type": "integer"
              },
              "HardExpiry": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "GetAccountSummary": {
      "output": {
        "resultWrapper": "GetAccountSummaryResult",
        "type": "structure",
        "members": {
          "SummaryMap": {
            "type": "map",
            "key": {},
            "value": {
              "type": "integer"
            }
          }
        }
      }
    },
    "GetContextKeysForCustomPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyInputList"
        ],
        "members": {
          "PolicyInputList": {
            "shape": "S4s"
          }
        }
      },
      "output": {
        "shape": "S4t",
        "resultWrapper": "GetContextKeysForCustomPolicyResult"
      }
    },
    "GetContextKeysForPrincipalPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicySourceArn"
        ],
        "members": {
          "PolicySourceArn": {},
          "PolicyInputList": {
            "shape": "S4s"
          }
        }
      },
      "output": {
        "shape": "S4t",
        "resultWrapper": "GetContextKeysForPrincipalPolicyResult"
      }
    },
    "GetCredentialReport": {
      "output": {
        "resultWrapper": "GetCredentialReportResult",
        "type": "structure",
        "members": {
          "Content": {
            "type": "blob"
          },
          "ReportFormat": {},
          "GeneratedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "GetGroupResult",
        "type": "structure",
        "required": [
          "Group",
          "Users"
        ],
        "members": {
          "Group": {
            "shape": "Ss"
          },
          "Users": {
            "shape": "S52"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "GetGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyName"
        ],
        "members": {
          "GroupName": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "GetGroupPolicyResult",
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "GroupName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "GetInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceProfileName"
        ],
        "members": {
          "InstanceProfileName": {}
        }
      },
      "output": {
        "resultWrapper": "GetInstanceProfileResult",
        "type": "structure",
        "required": [
          "InstanceProfile"
        ],
        "members": {
          "InstanceProfile": {
            "shape": "Sw"
          }
        }
      }
    },
    "GetLoginProfile": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {}
        }
      },
      "output": {
        "resultWrapper": "GetLoginProfileResult",
        "type": "structure",
        "required": [
          "LoginProfile"
        ],
        "members": {
          "LoginProfile": {
            "shape": "S1d"
          }
        }
      }
    },
    "GetOpenIDConnectProvider": {
      "input": {
        "type": "structure",
        "required": [
          "OpenIDConnectProviderArn"
        ],
        "members": {
          "OpenIDConnectProviderArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetOpenIDConnectProviderResult",
        "type": "structure",
        "members": {
          "Url": {},
          "ClientIDList": {
            "shape": "S1g"
          },
          "ThumbprintList": {
            "shape": "S1h"
          },
          "CreateDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetOrganizationsAccessReport": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {},
          "SortKey": {}
        }
      },
      "output": {
        "resultWrapper": "GetOrganizationsAccessReportResult",
        "type": "structure",
        "required": [
          "JobStatus",
          "JobCreationDate"
        ],
        "members": {
          "JobStatus": {},
          "JobCreationDate": {
            "type": "timestamp"
          },
          "JobCompletionDate": {
            "type": "timestamp"
          },
          "NumberOfServicesAccessible": {
            "type": "integer"
          },
          "NumberOfServicesNotAccessed": {
            "type": "integer"
          },
          "AccessDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ServiceName",
                "ServiceNamespace"
              ],
              "members": {
                "ServiceName": {},
                "ServiceNamespace": {},
                "Region": {},
                "EntityPath": {},
                "LastAuthenticatedTime": {
                  "type": "timestamp"
                },
                "TotalAuthenticatedEntities": {
                  "type": "integer"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {},
          "ErrorDetails": {
            "shape": "S5k"
          }
        }
      }
    },
    "GetPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn"
        ],
        "members": {
          "PolicyArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetPolicyResult",
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "S1p"
          }
        }
      }
    },
    "GetPolicyVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn",
          "VersionId"
        ],
        "members": {
          "PolicyArn": {},
          "VersionId": {}
        }
      },
      "output": {
        "resultWrapper": "GetPolicyVersionResult",
        "type": "structure",
        "members": {
          "PolicyVersion": {
            "shape": "S1u"
          }
        }
      }
    },
    "GetRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {}
        }
      },
      "output": {
        "resultWrapper": "GetRoleResult",
        "type": "structure",
        "required": [
          "Role"
        ],
        "members": {
          "Role": {
            "shape": "Sy"
          }
        }
      }
    },
    "GetRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyName"
        ],
        "members": {
          "RoleName": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "GetRolePolicyResult",
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "RoleName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "GetSAMLProvider": {
      "input": {
        "type": "structure",
        "required": [
          "SAMLProviderArn"
        ],
        "members": {
          "SAMLProviderArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetSAMLProviderResult",
        "type": "structure",
        "members": {
          "SAMLMetadataDocument": {},
          "CreateDate": {
            "type": "timestamp"
          },
          "ValidUntil": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetSSHPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SSHPublicKeyId",
          "Encoding"
        ],
        "members": {
          "UserName": {},
          "SSHPublicKeyId": {},
          "Encoding": {}
        }
      },
      "output": {
        "resultWrapper": "GetSSHPublicKeyResult",
        "type": "structure",
        "members": {
          "SSHPublicKey": {
            "shape": "S5y"
          }
        }
      }
    },
    "GetServerCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "ServerCertificateName"
        ],
        "members": {
          "ServerCertificateName": {}
        }
      },
      "output": {
        "resultWrapper": "GetServerCertificateResult",
        "type": "structure",
        "required": [
          "ServerCertificate"
        ],
        "members": {
          "ServerCertificate": {
            "type": "structure",
            "required": [
              "ServerCertificateMetadata",
              "CertificateBody"
            ],
            "members": {
              "ServerCertificateMetadata": {
                "shape": "S64"
              },
              "CertificateBody": {},
              "CertificateChain": {}
            }
          }
        }
      }
    },
    "GetServiceLastAccessedDetails": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "GetServiceLastAccessedDetailsResult",
        "type": "structure",
        "required": [
          "JobStatus",
          "JobCreationDate",
          "ServicesLastAccessed",
          "JobCompletionDate"
        ],
        "members": {
          "JobStatus": {},
          "JobType": {},
          "JobCreationDate": {
            "type": "timestamp"
          },
          "ServicesLastAccessed": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ServiceName",
                "ServiceNamespace"
              ],
              "members": {
                "ServiceName": {},
                "LastAuthenticated": {
                  "type": "timestamp"
                },
                "ServiceNamespace": {},
                "LastAuthenticatedEntity": {},
                "LastAuthenticatedRegion": {},
                "TotalAuthenticatedEntities": {
                  "type": "integer"
                },
                "TrackedActionsLastAccessed": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ActionName": {},
                      "LastAccessedEntity": {},
                      "LastAccessedTime": {
                        "type": "timestamp"
                      },
                      "LastAccessedRegion": {}
                    }
                  }
                }
              }
            }
          },
          "JobCompletionDate": {
            "type": "timestamp"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {},
          "Error": {
            "shape": "S5k"
          }
        }
      }
    },
    "GetServiceLastAccessedDetailsWithEntities": {
      "input": {
        "type": "structure",
        "required": [
          "JobId",
          "ServiceNamespace"
        ],
        "members": {
          "JobId": {},
          "ServiceNamespace": {},
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "GetServiceLastAccessedDetailsWithEntitiesResult",
        "type": "structure",
        "required": [
          "JobStatus",
          "JobCreationDate",
          "JobCompletionDate",
          "EntityDetailsList"
        ],
        "members": {
          "JobStatus": {},
          "JobCreationDate": {
            "type": "timestamp"
          },
          "JobCompletionDate": {
            "type": "timestamp"
          },
          "EntityDetailsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EntityInfo"
              ],
              "members": {
                "EntityInfo": {
                  "type": "structure",
                  "required": [
                    "Arn",
                    "Name",
                    "Type",
                    "Id"
                  ],
                  "members": {
                    "Arn": {},
                    "Name": {},
                    "Type": {},
                    "Id": {},
                    "Path": {}
                  }
                },
                "LastAuthenticated": {
                  "type": "timestamp"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {},
          "Error": {
            "shape": "S5k"
          }
        }
      }
    },
    "GetServiceLinkedRoleDeletionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "DeletionTaskId"
        ],
        "members": {
          "DeletionTaskId": {}
        }
      },
      "output": {
        "resultWrapper": "GetServiceLinkedRoleDeletionStatusResult",
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {},
          "Reason": {
            "type": "structure",
            "members": {
              "Reason": {},
              "RoleUsageList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Region": {},
                    "Resources": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetUser": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {}
        }
      },
      "output": {
        "resultWrapper": "GetUserResult",
        "type": "structure",
        "required": [
          "User"
        ],
        "members": {
          "User": {
            "shape": "S2d"
          }
        }
      }
    },
    "GetUserPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PolicyName"
        ],
        "members": {
          "UserName": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "GetUserPolicyResult",
        "type": "structure",
        "required": [
          "UserName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "UserName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "ListAccessKeys": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListAccessKeysResult",
        "type": "structure",
        "required": [
          "AccessKeyMetadata"
        ],
        "members": {
          "AccessKeyMetadata": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UserName": {},
                "AccessKeyId": {},
                "Status": {},
                "CreateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListAccountAliases": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListAccountAliasesResult",
        "type": "structure",
        "required": [
          "AccountAliases"
        ],
        "members": {
          "AccountAliases": {
            "type": "list",
            "member": {}
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListAttachedGroupPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListAttachedGroupPoliciesResult",
        "type": "structure",
        "members": {
          "AttachedPolicies": {
            "shape": "S46"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListAttachedRolePolicies": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {},
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListAttachedRolePoliciesResult",
        "type": "structure",
        "members": {
          "AttachedPolicies": {
            "shape": "S46"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListAttachedUserPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListAttachedUserPoliciesResult",
        "type": "structure",
        "members": {
          "AttachedPolicies": {
            "shape": "S46"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListEntitiesForPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn"
        ],
        "members": {
          "PolicyArn": {},
          "EntityFilter": {},
          "PathPrefix": {},
          "PolicyUsageFilter": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListEntitiesForPolicyResult",
        "type": "structure",
        "members": {
          "PolicyGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "GroupId": {}
              }
            }
          },
          "PolicyUsers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UserName": {},
                "UserId": {}
              }
            }
          },
          "PolicyRoles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RoleName": {},
                "RoleId": {}
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListGroupPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListGroupPoliciesResult",
        "type": "structure",
        "required": [
          "PolicyNames"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S7k"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "members": {
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListGroupsResult",
        "type": "structure",
        "required": [
          "Groups"
        ],
        "members": {
          "Groups": {
            "shape": "S7o"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListGroupsForUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListGroupsForUserResult",
        "type": "structure",
        "required": [
          "Groups"
        ],
        "members": {
          "Groups": {
            "shape": "S7o"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListInstanceProfilesResult",
        "type": "structure",
        "required": [
          "InstanceProfiles"
        ],
        "members": {
          "InstanceProfiles": {
            "shape": "S4c"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceProfilesForRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListInstanceProfilesForRoleResult",
        "type": "structure",
        "required": [
          "InstanceProfiles"
        ],
        "members": {
          "InstanceProfiles": {
            "shape": "S4c"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListMFADevices": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListMFADevicesResult",
        "type": "structure",
        "required": [
          "MFADevices"
        ],
        "members": {
          "MFADevices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "UserName",
                "SerialNumber",
                "EnableDate"
              ],
              "members": {
                "UserName": {},
                "SerialNumber": {},
                "EnableDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListOpenIDConnectProviders": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "ListOpenIDConnectProvidersResult",
        "type": "structure",
        "members": {
          "OpenIDConnectProviderList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {}
              }
            }
          }
        }
      }
    },
    "ListPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "Scope": {},
          "OnlyAttached": {
            "type": "boolean"
          },
          "PathPrefix": {},
          "PolicyUsageFilter": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListPoliciesResult",
        "type": "structure",
        "members": {
          "Policies": {
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListPoliciesGrantingServiceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "ServiceNamespaces"
        ],
        "members": {
          "Marker": {},
          "Arn": {},
          "ServiceNamespaces": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ListPoliciesGrantingServiceAccessResult",
        "type": "structure",
        "required": [
          "PoliciesGrantingServiceAccess"
        ],
        "members": {
          "PoliciesGrantingServiceAccess": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceNamespace": {},
                "Policies": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "PolicyName",
                      "PolicyType"
                    ],
                    "members": {
                      "PolicyName": {},
                      "PolicyType": {},
                      "PolicyArn": {},
                      "EntityType": {},
                      "EntityName": {}
                    }
                  }
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListPolicyVersions": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn"
        ],
        "members": {
          "PolicyArn": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListPolicyVersionsResult",
        "type": "structure",
        "members": {
          "Versions": {
            "shape": "S4f"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListRolePolicies": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListRolePoliciesResult",
        "type": "structure",
        "required": [
          "PolicyNames"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S7k"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListRoleTags": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListRoleTagsResult",
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S14"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListRoles": {
      "input": {
        "type": "structure",
        "members": {
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListRolesResult",
        "type": "structure",
        "required": [
          "Roles"
        ],
        "members": {
          "Roles": {
            "shape": "Sx"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListSAMLProviders": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "ListSAMLProvidersResult",
        "type": "structure",
        "members": {
          "SAMLProviderList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "ValidUntil": {
                  "type": "timestamp"
                },
                "CreateDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListSSHPublicKeys": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListSSHPublicKeysResult",
        "type": "structure",
        "members": {
          "SSHPublicKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "UserName",
                "SSHPublicKeyId",
                "Status",
                "UploadDate"
              ],
              "members": {
                "UserName": {},
                "SSHPublicKeyId": {},
                "Status": {},
                "UploadDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListServerCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListServerCertificatesResult",
        "type": "structure",
        "required": [
          "ServerCertificateMetadataList"
        ],
        "members": {
          "ServerCertificateMetadataList": {
            "type": "list",
            "member": {
              "shape": "S64"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListServiceSpecificCredentials": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {},
          "ServiceName": {}
        }
      },
      "output": {
        "resultWrapper": "ListServiceSpecificCredentialsResult",
        "type": "structure",
        "members": {
          "ServiceSpecificCredentials": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "UserName",
                "Status",
                "ServiceUserName",
                "CreateDate",
                "ServiceSpecificCredentialId",
                "ServiceName"
              ],
              "members": {
                "UserName": {},
                "Status": {},
                "ServiceUserName": {},
                "CreateDate": {
                  "type": "timestamp"
                },
                "ServiceSpecificCredentialId": {},
                "ServiceName": {}
              }
            }
          }
        }
      }
    },
    "ListSigningCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListSigningCertificatesResult",
        "type": "structure",
        "required": [
          "Certificates"
        ],
        "members": {
          "Certificates": {
            "type": "list",
            "member": {
              "shape": "S96"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListUserPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListUserPoliciesResult",
        "type": "structure",
        "required": [
          "PolicyNames"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S7k"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListUserTags": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListUserTagsResult",
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "S14"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "members": {
          "PathPrefix": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListUsersResult",
        "type": "structure",
        "required": [
          "Users"
        ],
        "members": {
          "Users": {
            "shape": "S52"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "ListVirtualMFADevices": {
      "input": {
        "type": "structure",
        "members": {
          "AssignmentStatus": {},
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListVirtualMFADevicesResult",
        "type": "structure",
        "required": [
          "VirtualMFADevices"
        ],
        "members": {
          "VirtualMFADevices": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {}
        }
      }
    },
    "PutGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "GroupName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "PutRolePermissionsBoundary": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PermissionsBoundary"
        ],
        "members": {
          "RoleName": {},
          "PermissionsBoundary": {}
        }
      }
    },
    "PutRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "RoleName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "PutUserPermissionsBoundary": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PermissionsBoundary"
        ],
        "members": {
          "UserName": {},
          "PermissionsBoundary": {}
        }
      }
    },
    "PutUserPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "PolicyName",
          "PolicyDocument"
        ],
        "members": {
          "UserName": {},
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "RemoveClientIDFromOpenIDConnectProvider": {
      "input": {
        "type": "structure",
        "required": [
          "OpenIDConnectProviderArn",
          "ClientID"
        ],
        "members": {
          "OpenIDConnectProviderArn": {},
          "ClientID": {}
        }
      }
    },
    "RemoveRoleFromInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceProfileName",
          "RoleName"
        ],
        "members": {
          "InstanceProfileName": {},
          "RoleName": {}
        }
      }
    },
    "RemoveUserFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserName"
        ],
        "members": {
          "GroupName": {},
          "UserName": {}
        }
      }
    },
    "ResetServiceSpecificCredential": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceSpecificCredentialId"
        ],
        "members": {
          "UserName": {},
          "ServiceSpecificCredentialId": {}
        }
      },
      "output": {
        "resultWrapper": "ResetServiceSpecificCredentialResult",
        "type": "structure",
        "members": {
          "ServiceSpecificCredential": {
            "shape": "S27"
          }
        }
      }
    },
    "ResyncMFADevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SerialNumber",
          "AuthenticationCode1",
          "AuthenticationCode2"
        ],
        "members": {
          "UserName": {},
          "SerialNumber": {},
          "AuthenticationCode1": {},
          "AuthenticationCode2": {}
        }
      }
    },
    "SetDefaultPolicyVersion": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyArn",
          "VersionId"
        ],
        "members": {
          "PolicyArn": {},
          "VersionId": {}
        }
      }
    },
    "SetSecurityTokenServicePreferences": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalEndpointTokenVersion"
        ],
        "members": {
          "GlobalEndpointTokenVersion": {}
        }
      }
    },
    "SimulateCustomPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyInputList",
          "ActionNames"
        ],
        "members": {
          "PolicyInputList": {
            "shape": "S4s"
          },
          "PermissionsBoundaryPolicyInputList": {
            "shape": "S4s"
          },
          "ActionNames": {
            "shape": "S9w"
          },
          "ResourceArns": {
            "shape": "S9y"
          },
          "ResourcePolicy": {},
          "ResourceOwner": {},
          "CallerArn": {},
          "ContextEntries": {
            "shape": "Sa0"
          },
          "ResourceHandlingOption": {},
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "shape": "Sa6",
        "resultWrapper": "SimulateCustomPolicyResult"
      }
    },
    "SimulatePrincipalPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicySourceArn",
          "ActionNames"
        ],
        "members": {
          "PolicySourceArn": {},
          "PolicyInputList": {
            "shape": "S4s"
          },
          "PermissionsBoundaryPolicyInputList": {
            "shape": "S4s"
          },
          "ActionNames": {
            "shape": "S9w"
          },
          "ResourceArns": {
            "shape": "S9y"
          },
          "ResourcePolicy": {},
          "ResourceOwner": {},
          "CallerArn": {},
          "ContextEntries": {
            "shape": "Sa0"
          },
          "ResourceHandlingOption": {},
          "MaxItems": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "shape": "Sa6",
        "resultWrapper": "SimulatePrincipalPolicyResult"
      }
    },
    "TagRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "Tags"
        ],
        "members": {
          "RoleName": {},
          "Tags": {
            "shape": "S14"
          }
        }
      }
    },
    "TagUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "Tags"
        ],
        "members": {
          "UserName": {},
          "Tags": {
            "shape": "S14"
          }
        }
      }
    },
    "UntagRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "TagKeys"
        ],
        "members": {
          "RoleName": {},
          "TagKeys": {
            "shape": "Sar"
          }
        }
      }
    },
    "UntagUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "TagKeys"
        ],
        "members": {
          "UserName": {},
          "TagKeys": {
            "shape": "Sar"
          }
        }
      }
    },
    "UpdateAccessKey": {
      "input": {
        "type": "structure",
        "required": [
          "AccessKeyId",
          "Status"
        ],
        "members": {
          "UserName": {},
          "AccessKeyId": {},
          "Status": {}
        }
      }
    },
    "UpdateAccountPasswordPolicy": {
      "input": {
        "type": "structure",
        "members": {
          "MinimumPasswordLength": {
            "type": "integer"
          },
          "RequireSymbols": {
            "type": "boolean"
          },
          "RequireNumbers": {
            "type": "boolean"
          },
          "RequireUppercaseCharacters": {
            "type": "boolean"
          },
          "RequireLowercaseCharacters": {
            "type": "boolean"
          },
          "AllowUsersToChangePassword": {
            "type": "boolean"
          },
          "MaxPasswordAge": {
            "type": "integer"
          },
          "PasswordReusePrevention": {
            "type": "integer"
          },
          "HardExpiry": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateAssumeRolePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "PolicyDocument"
        ],
        "members": {
          "RoleName": {},
          "PolicyDocument": {}
        }
      }
    },
    "UpdateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "NewPath": {},
          "NewGroupName": {}
        }
      }
    },
    "UpdateLoginProfile": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "Password": {
            "shape": "Sf"
          },
          "PasswordResetRequired": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateOpenIDConnectProviderThumbprint": {
      "input": {
        "type": "structure",
        "required": [
          "OpenIDConnectProviderArn",
          "ThumbprintList"
        ],
        "members": {
          "OpenIDConnectProviderArn": {},
          "ThumbprintList": {
            "shape": "S1h"
          }
        }
      }
    },
    "UpdateRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName"
        ],
        "members": {
          "RoleName": {},
          "Description": {},
          "MaxSessionDuration": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateRoleResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRoleDescription": {
      "input": {
        "type": "structure",
        "required": [
          "RoleName",
          "Description"
        ],
        "members": {
          "RoleName": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateRoleDescriptionResult",
        "type": "structure",
        "members": {
          "Role": {
            "shape": "Sy"
          }
        }
      }
    },
    "UpdateSAMLProvider": {
      "input": {
        "type": "structure",
        "required": [
          "SAMLMetadataDocument",
          "SAMLProviderArn"
        ],
        "members": {
          "SAMLMetadataDocument": {},
          "SAMLProviderArn": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateSAMLProviderResult",
        "type": "structure",
        "members": {
          "SAMLProviderArn": {}
        }
      }
    },
    "UpdateSSHPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SSHPublicKeyId",
          "Status"
        ],
        "members": {
          "UserName": {},
          "SSHPublicKeyId": {},
          "Status": {}
        }
      }
    },
    "UpdateServerCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "ServerCertificateName"
        ],
        "members": {
          "ServerCertificateName": {},
          "NewPath": {},
          "NewServerCertificateName": {}
        }
      }
    },
    "UpdateServiceSpecificCredential": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceSpecificCredentialId",
          "Status"
        ],
        "members": {
          "UserName": {},
          "ServiceSpecificCredentialId": {},
          "Status": {}
        }
      }
    },
    "UpdateSigningCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateId",
          "Status"
        ],
        "members": {
          "UserName": {},
          "CertificateId": {},
          "Status": {}
        }
      }
    },
    "UpdateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserName"
        ],
        "members": {
          "UserName": {},
          "NewPath": {},
          "NewUserName": {}
        }
      }
    },
    "UploadSSHPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "SSHPublicKeyBody"
        ],
        "members": {
          "UserName": {},
          "SSHPublicKeyBody": {}
        }
      },
      "output": {
        "resultWrapper": "UploadSSHPublicKeyResult",
        "type": "structure",
        "members": {
          "SSHPublicKey": {
            "shape": "S5y"
          }
        }
      }
    },
    "UploadServerCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "ServerCertificateName",
          "CertificateBody",
          "PrivateKey"
        ],
        "members": {
          "Path": {},
          "ServerCertificateName": {},
          "CertificateBody": {},
          "PrivateKey": {
            "type": "string",
            "sensitive": true
          },
          "CertificateChain": {}
        }
      },
      "output": {
        "resultWrapper": "UploadServerCertificateResult",
        "type": "structure",
        "members": {
          "ServerCertificateMetadata": {
            "shape": "S64"
          }
        }
      }
    },
    "UploadSigningCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateBody"
        ],
        "members": {
          "UserName": {},
          "CertificateBody": {}
        }
      },
      "output": {
        "resultWrapper": "UploadSigningCertificateResult",
        "type": "structure",
        "required": [
          "Certificate"
        ],
        "members": {
          "Certificate": {
            "shape": "S96"
          }
        }
      }
    }
  },
  "shapes": {
    "Sf": {
      "type": "string",
      "sensitive": true
    },
    "Ss": {
      "type": "structure",
      "required": [
        "Path",
        "GroupName",
        "GroupId",
        "Arn",
        "CreateDate"
      ],
      "members": {
        "Path": {},
        "GroupName": {},
        "GroupId": {},
        "Arn": {},
        "CreateDate": {
          "type": "timestamp"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "Path",
        "InstanceProfileName",
        "InstanceProfileId",
        "Arn",
        "CreateDate",
        "Roles"
      ],
      "members": {
        "Path": {},
        "InstanceProfileName": {},
        "InstanceProfileId": {},
        "Arn": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "Roles": {
          "shape": "Sx"
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "shape": "Sy"
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "Path",
        "RoleName",
        "RoleId",
        "Arn",
        "CreateDate"
      ],
      "members": {
        "Path": {},
        "RoleName": {},
        "RoleId": {},
        "Arn": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "AssumeRolePolicyDocument": {},
        "Description": {},
        "MaxSessionDuration": {
          "type": "integer"
        },
        "PermissionsBoundary": {
          "shape": "S12"
        },
        "Tags": {
          "shape": "S14"
        },
        "RoleLastUsed": {
          "shape": "S18"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "PermissionsBoundaryType": {},
        "PermissionsBoundaryArn": {}
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "LastUsedDate": {
          "type": "timestamp"
        },
        "Region": {}
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "UserName",
        "CreateDate"
      ],
      "members": {
        "UserName": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "PasswordResetRequired": {
          "type": "boolean"
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "members": {
        "PolicyName": {},
        "PolicyId": {},
        "Arn": {},
        "Path": {},
        "DefaultVersionId": {},
        "AttachmentCount": {
          "type": "integer"
        },
        "PermissionsBoundaryUsageCount": {
          "type": "integer"
        },
        "IsAttachable": {
          "type": "boolean"
        },
        "Description": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "UpdateDate": {
          "type": "timestamp"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "Document": {},
        "VersionId": {},
        "IsDefaultVersion": {
          "type": "boolean"
        },
        "CreateDate": {
          "type": "timestamp"
        }
      }
    },
    "S27": {
      "type": "structure",
      "required": [
        "CreateDate",
        "ServiceName",
        "ServiceUserName",
        "ServicePassword",
        "ServiceSpecificCredentialId",
        "UserName",
        "Status"
      ],
      "members": {
        "CreateDate": {
          "type": "timestamp"
        },
        "ServiceName": {},
        "ServiceUserName": {},
        "ServicePassword": {
          "type": "string",
          "sensitive": true
        },
        "ServiceSpecificCredentialId": {},
        "UserName": {},
        "Status": {}
      }
    },
    "S2d": {
      "type": "structure",
      "required": [
        "Path",
        "UserName",
        "UserId",
        "Arn",
        "CreateDate"
      ],
      "members": {
        "Path": {},
        "UserName": {},
        "UserId": {},
        "Arn": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "PasswordLastUsed": {
          "type": "timestamp"
        },
        "PermissionsBoundary": {
          "shape": "S12"
        },
        "Tags": {
          "shape": "S14"
        }
      }
    },
    "S2h": {
      "type": "structure",
      "required": [
        "SerialNumber"
      ],
      "members": {
        "SerialNumber": {},
        "Base32StringSeed": {
          "shape": "S2j"
        },
        "QRCodePNG": {
          "shape": "S2j"
        },
        "User": {
          "shape": "S2d"
        },
        "EnableDate": {
          "type": "timestamp"
        }
      }
    },
    "S2j": {
      "type": "blob",
      "sensitive": true
    },
    "S43": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PolicyName": {},
          "PolicyDocument": {}
        }
      }
    },
    "S46": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PolicyName": {},
          "PolicyArn": {}
        }
      }
    },
    "S4c": {
      "type": "list",
      "member": {
        "shape": "Sw"
      }
    },
    "S4f": {
      "type": "list",
      "member": {
        "shape": "S1u"
      }
    },
    "S4s": {
      "type": "list",
      "member": {}
    },
    "S4t": {
      "type": "structure",
      "members": {
        "ContextKeyNames": {
          "shape": "S4u"
        }
      }
    },
    "S4u": {
      "type": "list",
      "member": {}
    },
    "S52": {
      "type": "list",
      "member": {
        "shape": "S2d"
      }
    },
    "S5k": {
      "type": "structure",
      "required": [
        "Message",
        "Code"
      ],
      "members": {
        "Message": {},
        "Code": {}
      }
    },
    "S5y": {
      "type": "structure",
      "required": [
        "UserName",
        "SSHPublicKeyId",
        "Fingerprint",
        "SSHPublicKeyBody",
        "Status"
      ],
      "members": {
        "UserName": {},
        "SSHPublicKeyId": {},
        "Fingerprint": {},
        "SSHPublicKeyBody": {},
        "Status": {},
        "UploadDate": {
          "type": "timestamp"
        }
      }
    },
    "S64": {
      "type": "structure",
      "required": [
        "Path",
        "ServerCertificateName",
        "ServerCertificateId",
        "Arn"
      ],
      "members": {
        "Path": {},
        "ServerCertificateName": {},
        "ServerCertificateId": {},
        "Arn": {},
        "UploadDate": {
          "type": "timestamp"
        },
        "Expiration": {
          "type": "timestamp"
        }
      }
    },
    "S7k": {
      "type": "list",
      "member": {}
    },
    "S7o": {
      "type": "list",
      "member": {
        "shape": "Ss"
      }
    },
    "S96": {
      "type": "structure",
      "required": [
        "UserName",
        "CertificateId",
        "CertificateBody",
        "Status"
      ],
      "members": {
        "UserName": {},
        "CertificateId": {},
        "CertificateBody": {},
        "Status": {},
        "UploadDate": {
          "type": "timestamp"
        }
      }
    },
    "S9w": {
      "type": "list",
      "member": {}
    },
    "S9y": {
      "type": "list",
      "member": {}
    },
    "Sa0": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ContextKeyName": {},
          "ContextKeyValues": {
            "type": "list",
            "member": {}
          },
          "ContextKeyType": {}
        }
      }
    },
    "Sa6": {
      "type": "structure",
      "members": {
        "EvaluationResults": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "EvalActionName",
              "EvalDecision"
            ],
            "members": {
              "EvalActionName": {},
              "EvalResourceName": {},
              "EvalDecision": {},
              "MatchedStatements": {
                "shape": "Saa"
              },
              "MissingContextValues": {
                "shape": "S4u"
              },
              "OrganizationsDecisionDetail": {
                "type": "structure",
                "members": {
                  "AllowedByOrganizations": {
                    "type": "boolean"
                  }
                }
              },
              "PermissionsBoundaryDecisionDetail": {
                "shape": "Sai"
              },
              "EvalDecisionDetails": {
                "shape": "Saj"
              },
              "ResourceSpecificResults": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "EvalResourceName",
                    "EvalResourceDecision"
                  ],
                  "members": {
                    "EvalResourceName": {},
                    "EvalResourceDecision": {},
                    "MatchedStatements": {
                      "shape": "Saa"
                    },
                    "MissingContextValues": {
                      "shape": "S4u"
                    },
                    "EvalDecisionDetails": {
                      "shape": "Saj"
                    },
                    "PermissionsBoundaryDecisionDetail": {
                      "shape": "Sai"
                    }
                  }
                }
              }
            }
          }
        },
        "IsTruncated": {
          "type": "boolean"
        },
        "Marker": {}
      }
    },
    "Saa": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SourcePolicyId": {},
          "SourcePolicyType": {},
          "StartPosition": {
            "shape": "Sae"
          },
          "EndPosition": {
            "shape": "Sae"
          }
        }
      }
    },
    "Sae": {
      "type": "structure",
      "members": {
        "Line": {
          "type": "integer"
        },
        "Column": {
          "type": "integer"
        }
      }
    },
    "Sai": {
      "type": "structure",
      "members": {
        "AllowedByPermissionsBoundary": {
          "type": "boolean"
        }
      }
    },
    "Saj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sar": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],113:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetAccountAuthorizationDetails": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": [
        "UserDetailList",
        "GroupDetailList",
        "RoleDetailList",
        "Policies"
      ]
    },
    "GetGroup": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Users"
    },
    "ListAccessKeys": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "AccessKeyMetadata"
    },
    "ListAccountAliases": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "AccountAliases"
    },
    "ListAttachedGroupPolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "AttachedPolicies"
    },
    "ListAttachedRolePolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "AttachedPolicies"
    },
    "ListAttachedUserPolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "AttachedPolicies"
    },
    "ListEntitiesForPolicy": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": [
        "PolicyGroups",
        "PolicyUsers",
        "PolicyRoles"
      ]
    },
    "ListGroupPolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "PolicyNames"
    },
    "ListGroups": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Groups"
    },
    "ListGroupsForUser": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Groups"
    },
    "ListInstanceProfiles": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "InstanceProfiles"
    },
    "ListInstanceProfilesForRole": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "InstanceProfiles"
    },
    "ListMFADevices": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "MFADevices"
    },
    "ListPolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Policies"
    },
    "ListPolicyVersions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Versions"
    },
    "ListRolePolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "PolicyNames"
    },
    "ListRoles": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Roles"
    },
    "ListSAMLProviders": {
      "result_key": "SAMLProviderList"
    },
    "ListSSHPublicKeys": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "SSHPublicKeys"
    },
    "ListServerCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "ServerCertificateMetadataList"
    },
    "ListSigningCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Certificates"
    },
    "ListUserPolicies": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "PolicyNames"
    },
    "ListUsers": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "Users"
    },
    "ListVirtualMFADevices": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "VirtualMFADevices"
    },
    "SimulateCustomPolicy": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "EvaluationResults"
    },
    "SimulatePrincipalPolicy": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "Marker",
      "result_key": "EvaluationResults"
    }
  }
}
},{}],114:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "InstanceProfileExists": {
      "delay": 1,
      "operation": "GetInstanceProfile",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": 200,
          "matcher": "status",
          "state": "success"
        },
        {
          "state": "retry",
          "matcher": "status",
          "expected": 404
        }
      ]
    },
    "UserExists": {
      "delay": 1,
      "operation": "GetUser",
      "maxAttempts": 20,
      "acceptors": [
        {
          "state": "success",
          "matcher": "status",
          "expected": 200
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "NoSuchEntity"
        }
      ]
    },
    "RoleExists": {
      "delay": 1,
      "operation": "GetRole",
      "maxAttempts": 20,
      "acceptors": [
        {
          "state": "success",
          "matcher": "status",
          "expected": 200
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "NoSuchEntity"
        }
      ]
    },
    "PolicyExists": {
      "delay": 1,
      "operation": "GetPolicy",
      "maxAttempts": 20,
      "acceptors": [
        {
          "state": "success",
          "matcher": "status",
          "expected": 200
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "NoSuchEntity"
        }
      ]
    }
  }
}

},{}],115:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-02-16",
    "endpointPrefix": "inspector",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Inspector",
    "serviceId": "Inspector",
    "signatureVersion": "v4",
    "targetPrefix": "InspectorService",
    "uid": "inspector-2016-02-16"
  },
  "operations": {
    "AddAttributesToFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns",
          "attributes"
        ],
        "members": {
          "findingArns": {
            "shape": "S2"
          },
          "attributes": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "failedItems"
        ],
        "members": {
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetName"
        ],
        "members": {
          "assessmentTargetName": {},
          "resourceGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargetArn"
        ],
        "members": {
          "assessmentTargetArn": {}
        }
      }
    },
    "CreateAssessmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn",
          "assessmentTemplateName",
          "durationInSeconds",
          "rulesPackageArns"
        ],
        "members": {
          "assessmentTargetArn": {},
          "assessmentTemplateName": {},
          "durationInSeconds": {
            "type": "integer"
          },
          "rulesPackageArns": {
            "shape": "Sj"
          },
          "userAttributesForFindings": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {}
        }
      }
    },
    "CreateExclusionsPreview": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "previewToken"
        ],
        "members": {
          "previewToken": {}
        }
      }
    },
    "CreateResourceGroup": {
      "input": {
        "type": "structure",
        "required": [
          "resourceGroupTags"
        ],
        "members": {
          "resourceGroupTags": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resourceGroupArn"
        ],
        "members": {
          "resourceGroupArn": {}
        }
      }
    },
    "DeleteAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      }
    },
    "DeleteAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn"
        ],
        "members": {
          "assessmentTargetArn": {}
        }
      }
    },
    "DeleteAssessmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {}
        }
      }
    },
    "DescribeAssessmentRuns": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArns"
        ],
        "members": {
          "assessmentRunArns": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRuns",
          "failedItems"
        ],
        "members": {
          "assessmentRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "assessmentTemplateArn",
                "state",
                "durationInSeconds",
                "rulesPackageArns",
                "userAttributesForFindings",
                "createdAt",
                "stateChangedAt",
                "dataCollected",
                "stateChanges",
                "notifications",
                "findingCounts"
              ],
              "members": {
                "arn": {},
                "name": {},
                "assessmentTemplateArn": {},
                "state": {},
                "durationInSeconds": {
                  "type": "integer"
                },
                "rulesPackageArns": {
                  "type": "list",
                  "member": {}
                },
                "userAttributesForFindings": {
                  "shape": "S4"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "startedAt": {
                  "type": "timestamp"
                },
                "completedAt": {
                  "type": "timestamp"
                },
                "stateChangedAt": {
                  "type": "timestamp"
                },
                "dataCollected": {
                  "type": "boolean"
                },
                "stateChanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "stateChangedAt",
                      "state"
                    ],
                    "members": {
                      "stateChangedAt": {
                        "type": "timestamp"
                      },
                      "state": {}
                    }
                  }
                },
                "notifications": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "date",
                      "event",
                      "error"
                    ],
                    "members": {
                      "date": {
                        "type": "timestamp"
                      },
                      "event": {},
                      "message": {},
                      "error": {
                        "type": "boolean"
                      },
                      "snsTopicArn": {},
                      "snsPublishStatusCode": {}
                    }
                  }
                },
                "findingCounts": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeAssessmentTargets": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArns"
        ],
        "members": {
          "assessmentTargetArns": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargets",
          "failedItems"
        ],
        "members": {
          "assessmentTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "createdAt",
                "updatedAt"
              ],
              "members": {
                "arn": {},
                "name": {},
                "resourceGroupArn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeAssessmentTemplates": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArns"
        ],
        "members": {
          "assessmentTemplateArns": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplates",
          "failedItems"
        ],
        "members": {
          "assessmentTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "assessmentTargetArn",
                "durationInSeconds",
                "rulesPackageArns",
                "userAttributesForFindings",
                "assessmentRunCount",
                "createdAt"
              ],
              "members": {
                "arn": {},
                "name": {},
                "assessmentTargetArn": {},
                "durationInSeconds": {
                  "type": "integer"
                },
                "rulesPackageArns": {
                  "shape": "Sj"
                },
                "userAttributesForFindings": {
                  "shape": "S4"
                },
                "lastAssessmentRunArn": {},
                "assessmentRunCount": {
                  "type": "integer"
                },
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeCrossAccountAccessRole": {
      "output": {
        "type": "structure",
        "required": [
          "roleArn",
          "valid",
          "registeredAt"
        ],
        "members": {
          "roleArn": {},
          "valid": {
            "type": "boolean"
          },
          "registeredAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeExclusions": {
      "input": {
        "type": "structure",
        "required": [
          "exclusionArns"
        ],
        "members": {
          "exclusionArns": {
            "type": "list",
            "member": {}
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "exclusions",
          "failedItems"
        ],
        "members": {
          "exclusions": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "arn",
                "title",
                "description",
                "recommendation",
                "scopes"
              ],
              "members": {
                "arn": {},
                "title": {},
                "description": {},
                "recommendation": {},
                "scopes": {
                  "shape": "S1x"
                },
                "attributes": {
                  "shape": "S21"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns"
        ],
        "members": {
          "findingArns": {
            "shape": "Sy"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "findings",
          "failedItems"
        ],
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "attributes",
                "userAttributes",
                "createdAt",
                "updatedAt"
              ],
              "members": {
                "arn": {},
                "schemaVersion": {
                  "type": "integer"
                },
                "service": {},
                "serviceAttributes": {
                  "type": "structure",
                  "required": [
                    "schemaVersion"
                  ],
                  "members": {
                    "schemaVersion": {
                      "type": "integer"
                    },
                    "assessmentRunArn": {},
                    "rulesPackageArn": {}
                  }
                },
                "assetType": {},
                "assetAttributes": {
                  "type": "structure",
                  "required": [
                    "schemaVersion"
                  ],
                  "members": {
                    "schemaVersion": {
                      "type": "integer"
                    },
                    "agentId": {},
                    "autoScalingGroup": {},
                    "amiId": {},
                    "hostname": {},
                    "ipv4Addresses": {
                      "type": "list",
                      "member": {}
                    },
                    "tags": {
                      "type": "list",
                      "member": {
                        "shape": "S2i"
                      }
                    },
                    "networkInterfaces": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "networkInterfaceId": {},
                          "subnetId": {},
                          "vpcId": {},
                          "privateDnsName": {},
                          "privateIpAddress": {},
                          "privateIpAddresses": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "privateDnsName": {},
                                "privateIpAddress": {}
                              }
                            }
                          },
                          "publicDnsName": {},
                          "publicIp": {},
                          "ipv6Addresses": {
                            "type": "list",
                            "member": {}
                          },
                          "securityGroups": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "groupName": {},
                                "groupId": {}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "id": {},
                "title": {},
                "description": {},
                "recommendation": {},
                "severity": {},
                "numericSeverity": {
                  "type": "double"
                },
                "confidence": {
                  "type": "integer"
                },
                "indicatorOfCompromise": {
                  "type": "boolean"
                },
                "attributes": {
                  "shape": "S21"
                },
                "userAttributes": {
                  "shape": "S4"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeResourceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "resourceGroupArns"
        ],
        "members": {
          "resourceGroupArns": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resourceGroups",
          "failedItems"
        ],
        "members": {
          "resourceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "tags",
                "createdAt"
              ],
              "members": {
                "arn": {},
                "tags": {
                  "shape": "Sp"
                },
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeRulesPackages": {
      "input": {
        "type": "structure",
        "required": [
          "rulesPackageArns"
        ],
        "members": {
          "rulesPackageArns": {
            "shape": "Sy"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "rulesPackages",
          "failedItems"
        ],
        "members": {
          "rulesPackages": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "version",
                "provider"
              ],
              "members": {
                "arn": {},
                "name": {},
                "version": {},
                "provider": {},
                "description": {}
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "GetAssessmentReport": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn",
          "reportFileFormat",
          "reportType"
        ],
        "members": {
          "assessmentRunArn": {},
          "reportFileFormat": {},
          "reportType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {},
          "url": {}
        }
      }
    },
    "GetExclusionsPreview": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn",
          "previewToken"
        ],
        "members": {
          "assessmentTemplateArn": {},
          "previewToken": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "previewStatus"
        ],
        "members": {
          "previewStatus": {},
          "exclusionPreviews": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "title",
                "description",
                "recommendation",
                "scopes"
              ],
              "members": {
                "title": {},
                "description": {},
                "recommendation": {},
                "scopes": {
                  "shape": "S1x"
                },
                "attributes": {
                  "shape": "S21"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetTelemetryMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "telemetryMetadata"
        ],
        "members": {
          "telemetryMetadata": {
            "shape": "S3j"
          }
        }
      }
    },
    "ListAssessmentRunAgents": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {},
          "filter": {
            "type": "structure",
            "required": [
              "agentHealths",
              "agentHealthCodes"
            ],
            "members": {
              "agentHealths": {
                "type": "list",
                "member": {}
              },
              "agentHealthCodes": {
                "type": "list",
                "member": {}
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunAgents"
        ],
        "members": {
          "assessmentRunAgents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "agentId",
                "assessmentRunArn",
                "agentHealth",
                "agentHealthCode",
                "telemetryMetadata"
              ],
              "members": {
                "agentId": {},
                "assessmentRunArn": {},
                "agentHealth": {},
                "agentHealthCode": {},
                "agentHealthDetails": {},
                "autoScalingGroup": {},
                "telemetryMetadata": {
                  "shape": "S3j"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentRuns": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentTemplateArns": {
            "shape": "S3x"
          },
          "filter": {
            "type": "structure",
            "members": {
              "namePattern": {},
              "states": {
                "type": "list",
                "member": {}
              },
              "durationRange": {
                "shape": "S41"
              },
              "rulesPackageArns": {
                "shape": "S42"
              },
              "startTimeRange": {
                "shape": "S43"
              },
              "completionTimeRange": {
                "shape": "S43"
              },
              "stateChangeTimeRange": {
                "shape": "S43"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunArns"
        ],
        "members": {
          "assessmentRunArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentTargets": {
      "input": {
        "type": "structure",
        "members": {
          "filter": {
            "type": "structure",
            "members": {
              "assessmentTargetNamePattern": {}
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargetArns"
        ],
        "members": {
          "assessmentTargetArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentTargetArns": {
            "shape": "S3x"
          },
          "filter": {
            "type": "structure",
            "members": {
              "namePattern": {},
              "durationRange": {
                "shape": "S41"
              },
              "rulesPackageArns": {
                "shape": "S42"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplateArns"
        ],
        "members": {
          "assessmentTemplateArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "subscriptions"
        ],
        "members": {
          "subscriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "resourceArn",
                "topicArn",
                "eventSubscriptions"
              ],
              "members": {
                "resourceArn": {},
                "topicArn": {},
                "eventSubscriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "event",
                      "subscribedAt"
                    ],
                    "members": {
                      "event": {},
                      "subscribedAt": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListExclusions": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "exclusionArns"
        ],
        "members": {
          "exclusionArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListFindings": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentRunArns": {
            "shape": "S3x"
          },
          "filter": {
            "type": "structure",
            "members": {
              "agentIds": {
                "type": "list",
                "member": {}
              },
              "autoScalingGroups": {
                "type": "list",
                "member": {}
              },
              "ruleNames": {
                "type": "list",
                "member": {}
              },
              "severities": {
                "type": "list",
                "member": {}
              },
              "rulesPackageArns": {
                "shape": "S42"
              },
              "attributes": {
                "shape": "S21"
              },
              "userAttributes": {
                "shape": "S21"
              },
              "creationTimeRange": {
                "shape": "S43"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "findingArns"
        ],
        "members": {
          "findingArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListRulesPackages": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "rulesPackageArns"
        ],
        "members": {
          "rulesPackageArns": {
            "shape": "S45"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "S4x"
          }
        }
      }
    },
    "PreviewAgents": {
      "input": {
        "type": "structure",
        "required": [
          "previewAgentsArn"
        ],
        "members": {
          "previewAgentsArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "agentPreviews"
        ],
        "members": {
          "agentPreviews": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "agentId"
              ],
              "members": {
                "hostname": {},
                "agentId": {},
                "autoScalingGroup": {},
                "agentHealth": {},
                "agentVersion": {},
                "operatingSystem": {},
                "kernelVersion": {},
                "ipv4Address": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCrossAccountAccessRole": {
      "input": {
        "type": "structure",
        "required": [
          "roleArn"
        ],
        "members": {
          "roleArn": {}
        }
      }
    },
    "RemoveAttributesFromFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns",
          "attributeKeys"
        ],
        "members": {
          "findingArns": {
            "shape": "S2"
          },
          "attributeKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "failedItems"
        ],
        "members": {
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "SetTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S4x"
          }
        }
      }
    },
    "StartAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {},
          "assessmentRunName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      }
    },
    "StopAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {},
          "stopAction": {}
        }
      }
    },
    "SubscribeToEvent": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "event",
          "topicArn"
        ],
        "members": {
          "resourceArn": {},
          "event": {},
          "topicArn": {}
        }
      }
    },
    "UnsubscribeFromEvent": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "event",
          "topicArn"
        ],
        "members": {
          "resourceArn": {},
          "event": {},
          "topicArn": {}
        }
      }
    },
    "UpdateAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn",
          "assessmentTargetName"
        ],
        "members": {
          "assessmentTargetArn": {},
          "assessmentTargetName": {},
          "resourceGroupArn": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S4": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "key"
      ],
      "members": {
        "key": {},
        "value": {}
      }
    },
    "S9": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "failureCode",
          "retryable"
        ],
        "members": {
          "failureCode": {},
          "retryable": {
            "type": "boolean"
          }
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {}
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S21": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S2i": {
      "type": "structure",
      "required": [
        "key"
      ],
      "members": {
        "key": {},
        "value": {}
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "messageType",
          "count"
        ],
        "members": {
          "messageType": {},
          "count": {
            "type": "long"
          },
          "dataSize": {
            "type": "long"
          }
        }
      }
    },
    "S3x": {
      "type": "list",
      "member": {}
    },
    "S41": {
      "type": "structure",
      "members": {
        "minSeconds": {
          "type": "integer"
        },
        "maxSeconds": {
          "type": "integer"
        }
      }
    },
    "S42": {
      "type": "list",
      "member": {}
    },
    "S43": {
      "type": "structure",
      "members": {
        "beginDate": {
          "type": "timestamp"
        },
        "endDate": {
          "type": "timestamp"
        }
      }
    },
    "S45": {
      "type": "list",
      "member": {}
    },
    "S4x": {
      "type": "list",
      "member": {
        "shape": "S2i"
      }
    }
  }
}
},{}],116:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetExclusionsPreview": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListAssessmentRunAgents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListAssessmentRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListAssessmentTargets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListAssessmentTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListEventSubscriptions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListExclusions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListFindings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListRulesPackages": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "PreviewAgents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    }
  }
}

},{}],117:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-05-28",
    "endpointPrefix": "iot",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT",
    "serviceId": "IoT",
    "signatureVersion": "v4",
    "signingName": "execute-api",
    "uid": "iot-2015-05-28"
  },
  "operations": {
    "AcceptCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/accept-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      }
    },
    "AddThingToBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/addThingToBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AddThingToThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/addThingToThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {},
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateTargetsWithJob": {
      "http": {
        "requestUri": "/jobs/{jobId}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "targets",
          "jobId"
        ],
        "members": {
          "targets": {
            "shape": "Sg"
          },
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "comment": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "AttachPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "AttachPrincipalPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "AttachSecurityProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AttachThingPrincipal": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditMitigationActionsTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/cancel-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      }
    },
    "CancelJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "reasonCode": {},
          "comment": {},
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CancelJobExecution": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          },
          "expectedVersion": {
            "type": "long"
          },
          "statusDetails": {
            "shape": "S1b"
          }
        }
      }
    },
    "ClearDefaultAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ConfirmTopicRuleDestination": {
      "http": {
        "method": "GET",
        "requestUri": "/confirmdestination/{confirmationToken+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "confirmationToken"
        ],
        "members": {
          "confirmationToken": {
            "location": "uri",
            "locationName": "confirmationToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName",
          "authorizerFunctionArn"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S1n"
          },
          "status": {},
          "tags": {
            "shape": "S1r"
          },
          "signingDisabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "CreateBillingGroup": {
      "http": {
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S1z"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "billingGroupId": {}
        }
      }
    },
    "CreateCertificateFromCsr": {
      "http": {
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateSigningRequest"
        ],
        "members": {
          "certificateSigningRequest": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {}
        }
      }
    },
    "CreateDimension": {
      "http": {
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "type",
          "stringValues",
          "clientRequestToken"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "type": {},
          "stringValues": {
            "shape": "S2b"
          },
          "tags": {
            "shape": "S1r"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {}
        }
      }
    },
    "CreateDomainConfiguration": {
      "http": {
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          },
          "domainName": {},
          "serverCertificateArns": {
            "type": "list",
            "member": {}
          },
          "validationCertificateArn": {},
          "authorizerConfig": {
            "shape": "S2l"
          },
          "serviceType": {},
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {}
        }
      }
    },
    "CreateDynamicThingGroup": {
      "http": {
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "queryString"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S2r"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {},
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      }
    },
    "CreateJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "targets"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "targets": {
            "shape": "Sg"
          },
          "documentSource": {},
          "document": {},
          "description": {},
          "presignedUrlConfig": {
            "shape": "S36"
          },
          "targetSelection": {},
          "jobExecutionsRolloutConfig": {
            "shape": "S3a"
          },
          "abortConfig": {
            "shape": "S3h"
          },
          "timeoutConfig": {
            "shape": "S3o"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CreateKeysAndCertificate": {
      "http": {
        "requestUri": "/keys-and-certificate"
      },
      "input": {
        "type": "structure",
        "members": {
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "shape": "S3t"
          }
        }
      }
    },
    "CreateMitigationAction": {
      "http": {
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName",
          "roleArn",
          "actionParams"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S3y"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "CreateOTAUpdate": {
      "http": {
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId",
          "targets",
          "files",
          "roleArn"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "description": {},
          "targets": {
            "shape": "S4h"
          },
          "protocols": {
            "shape": "S4j"
          },
          "targetSelection": {},
          "awsJobExecutionsRolloutConfig": {
            "shape": "S4l"
          },
          "awsJobPresignedUrlConfig": {
            "shape": "S4s"
          },
          "awsJobAbortConfig": {
            "type": "structure",
            "required": [
              "abortCriteriaList"
            ],
            "members": {
              "abortCriteriaList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "failureType",
                    "action",
                    "thresholdPercentage",
                    "minNumberOfExecutedThings"
                  ],
                  "members": {
                    "failureType": {},
                    "action": {},
                    "thresholdPercentage": {
                      "type": "double"
                    },
                    "minNumberOfExecutedThings": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "awsJobTimeoutConfig": {
            "type": "structure",
            "members": {
              "inProgressTimeoutInMinutes": {
                "type": "long"
              }
            }
          },
          "files": {
            "shape": "S53"
          },
          "roleArn": {},
          "additionalParameters": {
            "shape": "S60"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateId": {},
          "awsIotJobId": {},
          "otaUpdateArn": {},
          "awsIotJobArn": {},
          "otaUpdateStatus": {}
        }
      }
    },
    "CreatePolicy": {
      "http": {
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {}
        }
      }
    },
    "CreatePolicyVersion": {
      "http": {
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateProvisioningClaim": {
      "http": {
        "requestUri": "/provisioning-templates/{templateName}/provisioning-claim"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "shape": "S3t"
          },
          "expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateProvisioningTemplate": {
      "http": {
        "requestUri": "/provisioning-templates"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateBody",
          "provisioningRoleArn"
        ],
        "members": {
          "templateName": {},
          "description": {},
          "templateBody": {},
          "enabled": {
            "type": "boolean"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S6n"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "defaultVersionId": {
            "type": "integer"
          }
        }
      }
    },
    "CreateProvisioningTemplateVersion": {
      "http": {
        "requestUri": "/provisioning-templates/{templateName}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "templateBody"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "templateBody": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "versionId": {
            "type": "integer"
          },
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateRoleAlias": {
      "http": {
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias",
          "roleArn"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "CreateScheduledAudit": {
      "http": {
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "frequency",
          "targetCheckNames",
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S73"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "CreateSecurityProfile": {
      "http": {
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S7a"
          },
          "alertTargets": {
            "shape": "S7t"
          },
          "additionalMetricsToRetain": {
            "shape": "S7x",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S7y"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {}
        }
      }
    },
    "CreateStream": {
      "http": {
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId",
          "files",
          "roleArn"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S84"
          },
          "roleArn": {},
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "CreateThing": {
      "http": {
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S2t"
          },
          "billingGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingArn": {},
          "thingId": {}
        }
      }
    },
    "CreateThingGroup": {
      "http": {
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "parentGroupName": {},
          "thingGroupProperties": {
            "shape": "S2r"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {}
        }
      }
    },
    "CreateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "thingTypeProperties": {
            "shape": "S8g"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeArn": {},
          "thingTypeId": {}
        }
      }
    },
    "CreateTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S8o"
          },
          "tags": {
            "location": "header",
            "locationName": "x-amz-tagging"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "CreateTopicRuleDestination": {
      "http": {
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "required": [
          "destinationConfiguration"
        ],
        "members": {
          "destinationConfiguration": {
            "type": "structure",
            "members": {
              "httpUrlConfiguration": {
                "type": "structure",
                "required": [
                  "confirmationUrl"
                ],
                "members": {
                  "confirmationUrl": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "topicRuleDestination": {
            "shape": "Sbb"
          }
        }
      }
    },
    "DeleteAccountAuditConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "deleteScheduledAudits": {
            "location": "querystring",
            "locationName": "deleteScheduledAudits",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteBillingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCACertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "forceDelete": {
            "location": "querystring",
            "locationName": "forceDelete",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteDimension": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDomainConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDynamicThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteJobExecution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/jobs/{jobId}/executionNumber/{executionNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName",
          "executionNumber"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "uri",
            "locationName": "executionNumber",
            "type": "long"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteMitigationAction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOTAUpdate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "deleteStream": {
            "location": "querystring",
            "locationName": "deleteStream",
            "type": "boolean"
          },
          "forceDeleteAWSJob": {
            "location": "querystring",
            "locationName": "forceDeleteAWSJob",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      }
    },
    "DeletePolicyVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "DeleteProvisioningTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisioningTemplateVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/provisioning-templates/{templateName}/versions/{versionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "versionId"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "versionId": {
            "location": "uri",
            "locationName": "versionId",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRegistrationCode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRoleAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScheduledAudit": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStream": {
      "http": {
        "method": "DELETE",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTopicRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "DeleteTopicRuleDestination": {
      "http": {
        "method": "DELETE",
        "requestUri": "/destinations/{arn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteV2LoggingLevel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetType",
          "targetName"
        ],
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "targetName": {
            "location": "querystring",
            "locationName": "targetName"
          }
        }
      }
    },
    "DeprecateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}/deprecate"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "undoDeprecate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountAuditConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sd2"
          },
          "auditCheckConfigurations": {
            "shape": "Sd5"
          }
        }
      }
    },
    "DescribeAuditFinding": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/findings/{findingId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "findingId"
        ],
        "members": {
          "findingId": {
            "location": "uri",
            "locationName": "findingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "finding": {
            "shape": "Sda"
          }
        }
      }
    },
    "DescribeAuditMitigationActionsTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "totalFindingsCount": {
                  "type": "long"
                },
                "failedFindingsCount": {
                  "type": "long"
                },
                "succeededFindingsCount": {
                  "type": "long"
                },
                "skippedFindingsCount": {
                  "type": "long"
                },
                "canceledFindingsCount": {
                  "type": "long"
                }
              }
            }
          },
          "target": {
            "shape": "Sdz"
          },
          "auditCheckToActionsMapping": {
            "shape": "Se3"
          },
          "actionsDefinition": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "id": {},
                "roleArn": {},
                "actionParams": {
                  "shape": "S3y"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAuditTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "taskType": {},
          "taskStartTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "structure",
            "members": {
              "totalChecks": {
                "type": "integer"
              },
              "inProgressChecks": {
                "type": "integer"
              },
              "waitingForDataCollectionChecks": {
                "type": "integer"
              },
              "compliantChecks": {
                "type": "integer"
              },
              "nonCompliantChecks": {
                "type": "integer"
              },
              "failedChecks": {
                "type": "integer"
              },
              "canceledChecks": {
                "type": "integer"
              }
            }
          },
          "scheduledAuditName": {},
          "auditDetails": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "checkRunStatus": {},
                "checkCompliant": {
                  "type": "boolean"
                },
                "totalResourcesCount": {
                  "type": "long"
                },
                "nonCompliantResourcesCount": {
                  "type": "long"
                },
                "errorCode": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "DescribeAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Set"
          }
        }
      }
    },
    "DescribeBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupId": {},
          "billingGroupArn": {},
          "version": {
            "type": "long"
          },
          "billingGroupProperties": {
            "shape": "S1z"
          },
          "billingGroupMetadata": {
            "type": "structure",
            "members": {
              "creationDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeCACertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "autoRegistrationStatus": {},
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "generationId": {},
              "validity": {
                "shape": "Sf6"
              }
            }
          },
          "registrationConfig": {
            "shape": "Sf7"
          }
        }
      }
    },
    "DescribeCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "caCertificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "previousOwnedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "transferData": {
                "type": "structure",
                "members": {
                  "transferMessage": {},
                  "rejectReason": {},
                  "transferDate": {
                    "type": "timestamp"
                  },
                  "acceptDate": {
                    "type": "timestamp"
                  },
                  "rejectDate": {
                    "type": "timestamp"
                  }
                }
              },
              "generationId": {},
              "validity": {
                "shape": "Sf6"
              },
              "certificateMode": {}
            }
          }
        }
      }
    },
    "DescribeDefaultAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Set"
          }
        }
      }
    },
    "DescribeDimension": {
      "http": {
        "method": "GET",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {},
          "type": {},
          "stringValues": {
            "shape": "S2b"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeDomainConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {},
          "domainName": {},
          "serverCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "serverCertificateArn": {},
                "serverCertificateStatus": {},
                "serverCertificateStatusDetail": {}
              }
            }
          },
          "authorizerConfig": {
            "shape": "S2l"
          },
          "domainConfigurationStatus": {},
          "serviceType": {},
          "domainType": {}
        }
      }
    },
    "DescribeEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/endpoint"
      },
      "input": {
        "type": "structure",
        "members": {
          "endpointType": {
            "location": "querystring",
            "locationName": "endpointType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpointAddress": {}
        }
      }
    },
    "DescribeEventConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Sfy"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/indices/{indexName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "indexName"
        ],
        "members": {
          "indexName": {
            "location": "uri",
            "locationName": "indexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexName": {},
          "indexStatus": {},
          "schema": {}
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentSource": {},
          "job": {
            "type": "structure",
            "members": {
              "jobArn": {},
              "jobId": {},
              "targetSelection": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "reasonCode": {},
              "comment": {},
              "targets": {
                "shape": "Sg"
              },
              "description": {},
              "presignedUrlConfig": {
                "shape": "S36"
              },
              "jobExecutionsRolloutConfig": {
                "shape": "S3a"
              },
              "abortConfig": {
                "shape": "S3h"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "completedAt": {
                "type": "timestamp"
              },
              "jobProcessDetails": {
                "type": "structure",
                "members": {
                  "processingTargets": {
                    "type": "list",
                    "member": {}
                  },
                  "numberOfCanceledThings": {
                    "type": "integer"
                  },
                  "numberOfSucceededThings": {
                    "type": "integer"
                  },
                  "numberOfFailedThings": {
                    "type": "integer"
                  },
                  "numberOfRejectedThings": {
                    "type": "integer"
                  },
                  "numberOfQueuedThings": {
                    "type": "integer"
                  },
                  "numberOfInProgressThings": {
                    "type": "integer"
                  },
                  "numberOfRemovedThings": {
                    "type": "integer"
                  },
                  "numberOfTimedOutThings": {
                    "type": "integer"
                  }
                }
              },
              "timeoutConfig": {
                "shape": "S3o"
              }
            }
          }
        }
      }
    },
    "DescribeJobExecution": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "querystring",
            "locationName": "executionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "execution": {
            "type": "structure",
            "members": {
              "jobId": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "statusDetails": {
                "type": "structure",
                "members": {
                  "detailsMap": {
                    "shape": "S1b"
                  }
                }
              },
              "thingArn": {},
              "queuedAt": {
                "type": "timestamp"
              },
              "startedAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "executionNumber": {
                "type": "long"
              },
              "versionNumber": {
                "type": "long"
              },
              "approximateSecondsBeforeTimedOut": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "DescribeMitigationAction": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionName": {},
          "actionType": {},
          "actionArn": {},
          "actionId": {},
          "roleArn": {},
          "actionParams": {
            "shape": "S3y"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeProvisioningTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templateArn": {},
          "templateName": {},
          "description": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "defaultVersionId": {
            "type": "integer"
          },
          "templateBody": {},
          "enabled": {
            "type": "boolean"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S6n"
          }
        }
      }
    },
    "DescribeProvisioningTemplateVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}/versions/{versionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName",
          "versionId"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "versionId": {
            "location": "uri",
            "locationName": "versionId",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "versionId": {
            "type": "integer"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "templateBody": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeRoleAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliasDescription": {
            "type": "structure",
            "members": {
              "roleAlias": {},
              "roleAliasArn": {},
              "roleArn": {},
              "owner": {},
              "credentialDurationSeconds": {
                "type": "integer"
              },
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeScheduledAudit": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S73"
          },
          "scheduledAuditName": {},
          "scheduledAuditArn": {}
        }
      }
    },
    "DescribeSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S7a"
          },
          "alertTargets": {
            "shape": "S7t"
          },
          "additionalMetricsToRetain": {
            "shape": "S7x",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S7y"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStream": {
      "http": {
        "method": "GET",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamInfo": {
            "type": "structure",
            "members": {
              "streamId": {},
              "streamArn": {},
              "streamVersion": {
                "type": "integer"
              },
              "description": {},
              "files": {
                "shape": "S84"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "roleArn": {}
            }
          }
        }
      }
    },
    "DescribeThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "defaultClientId": {},
          "thingName": {},
          "thingId": {},
          "thingArn": {},
          "thingTypeName": {},
          "attributes": {
            "shape": "S2u"
          },
          "version": {
            "type": "long"
          },
          "billingGroupName": {}
        }
      }
    },
    "DescribeThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupId": {},
          "thingGroupArn": {},
          "version": {
            "type": "long"
          },
          "thingGroupProperties": {
            "shape": "S2r"
          },
          "thingGroupMetadata": {
            "type": "structure",
            "members": {
              "parentGroupName": {},
              "rootToParentThingGroups": {
                "shape": "Shf"
              },
              "creationDate": {
                "type": "timestamp"
              }
            }
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "status": {}
        }
      }
    },
    "DescribeThingRegistrationTask": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {},
          "status": {},
          "message": {},
          "successCount": {
            "type": "integer"
          },
          "failureCount": {
            "type": "integer"
          },
          "percentageProgress": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeThingType": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeId": {},
          "thingTypeArn": {},
          "thingTypeProperties": {
            "shape": "S8g"
          },
          "thingTypeMetadata": {
            "shape": "Shs"
          }
        }
      }
    },
    "DetachPolicy": {
      "http": {
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "DetachPrincipalPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "DetachSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DetachThingPrincipal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "EnableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "GetCardinality": {
      "http": {
        "requestUri": "/indices/cardinality"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cardinality": {
            "type": "integer"
          }
        }
      }
    },
    "GetEffectivePolicies": {
      "http": {
        "requestUri": "/effective-policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "effectivePolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "policyName": {},
                "policyArn": {},
                "policyDocument": {}
              }
            }
          }
        }
      }
    },
    "GetIndexingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sic"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sij"
          }
        }
      }
    },
    "GetJobDocument": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/job-document"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "document": {}
        }
      }
    },
    "GetLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "logLevel": {}
        }
      }
    },
    "GetOTAUpdate": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateInfo": {
            "type": "structure",
            "members": {
              "otaUpdateId": {},
              "otaUpdateArn": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "description": {},
              "targets": {
                "shape": "S4h"
              },
              "protocols": {
                "shape": "S4j"
              },
              "awsJobExecutionsRolloutConfig": {
                "shape": "S4l"
              },
              "awsJobPresignedUrlConfig": {
                "shape": "S4s"
              },
              "targetSelection": {},
              "otaUpdateFiles": {
                "shape": "S53"
              },
              "otaUpdateStatus": {},
              "awsIotJobId": {},
              "awsIotJobArn": {},
              "errorInfo": {
                "type": "structure",
                "members": {
                  "code": {},
                  "message": {}
                }
              },
              "additionalParameters": {
                "shape": "S60"
              }
            }
          }
        }
      }
    },
    "GetPercentiles": {
      "http": {
        "requestUri": "/indices/percentiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {},
          "percents": {
            "type": "list",
            "member": {
              "type": "double"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "percentiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "percent": {
                  "type": "double"
                },
                "value": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "defaultVersionId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetPolicyVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyName": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetRegistrationCode": {
      "http": {
        "method": "GET",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "registrationCode": {}
        }
      }
    },
    "GetStatistics": {
      "http": {
        "requestUri": "/indices/statistics"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "statistics": {
            "type": "structure",
            "members": {
              "count": {
                "type": "integer"
              },
              "average": {
                "type": "double"
              },
              "sum": {
                "type": "double"
              },
              "minimum": {
                "type": "double"
              },
              "maximum": {
                "type": "double"
              },
              "sumOfSquares": {
                "type": "double"
              },
              "variance": {
                "type": "double"
              },
              "stdDeviation": {
                "type": "double"
              }
            }
          }
        }
      }
    },
    "GetTopicRule": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ruleArn": {},
          "rule": {
            "type": "structure",
            "members": {
              "ruleName": {},
              "sql": {},
              "description": {},
              "createdAt": {
                "type": "timestamp"
              },
              "actions": {
                "shape": "S8r"
              },
              "ruleDisabled": {
                "type": "boolean"
              },
              "awsIotSqlVersion": {},
              "errorAction": {
                "shape": "S8s"
              }
            }
          }
        }
      }
    },
    "GetTopicRuleDestination": {
      "http": {
        "method": "GET",
        "requestUri": "/destinations/{arn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {
            "location": "uri",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "topicRuleDestination": {
            "shape": "Sbb"
          }
        }
      }
    },
    "GetV2LoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "ListActiveViolations": {
      "http": {
        "method": "GET",
        "requestUri": "/active-violations"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "activeViolations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S7b"
                },
                "lastViolationValue": {
                  "shape": "S7i"
                },
                "lastViolationTime": {
                  "type": "timestamp"
                },
                "violationStartTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAttachedPolicies": {
      "http": {
        "requestUri": "/attached-policies/{target}"
      },
      "input": {
        "type": "structure",
        "required": [
          "target"
        ],
        "members": {
          "target": {
            "location": "uri",
            "locationName": "target"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sk6"
          },
          "nextMarker": {}
        }
      }
    },
    "ListAuditFindings": {
      "http": {
        "requestUri": "/audit/findings"
      },
      "input": {
        "type": "structure",
        "members": {
          "taskId": {},
          "checkName": {},
          "resourceIdentifier": {
            "shape": "Sdf"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "shape": "Sda"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsExecutions": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/executions"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "findingId"
        ],
        "members": {
          "taskId": {
            "location": "querystring",
            "locationName": "taskId"
          },
          "actionStatus": {
            "location": "querystring",
            "locationName": "actionStatus"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionsExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "findingId": {},
                "actionName": {},
                "actionId": {},
                "status": {},
                "startTime": {
                  "type": "timestamp"
                },
                "endTime": {
                  "type": "timestamp"
                },
                "errorCode": {},
                "message": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "auditTaskId": {
            "location": "querystring",
            "locationName": "auditTaskId"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "startTime": {
                  "type": "timestamp"
                },
                "taskStatus": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "taskType": {
            "location": "querystring",
            "locationName": "taskType"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "taskStatus": {},
                "taskType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuthorizers": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizers/"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authorizerName": {},
                "authorizerArn": {}
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListBillingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroups": {
            "type": "list",
            "member": {
              "shape": "Shg"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListCACertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "status": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sl3"
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificatesByCA": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-by-ca/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificateId"
        ],
        "members": {
          "caCertificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sl3"
          },
          "nextMarker": {}
        }
      }
    },
    "ListDimensions": {
      "http": {
        "method": "GET",
        "requestUri": "/dimensions"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dimensionNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListDomainConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/domainConfigurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "serviceType": {
            "location": "querystring",
            "locationName": "serviceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "domainConfigurationName": {},
                "domainConfigurationArn": {},
                "serviceType": {}
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListIndices": {
      "http": {
        "method": "GET",
        "requestUri": "/indices"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingArn": {},
                "jobExecutionSummary": {
                  "shape": "Sln"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobId": {},
                "jobExecutionSummary": {
                  "shape": "Sln"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "targetSelection": {
            "location": "querystring",
            "locationName": "targetSelection"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "thingGroupName": {
            "location": "querystring",
            "locationName": "thingGroupName"
          },
          "thingGroupId": {
            "location": "querystring",
            "locationName": "thingGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobArn": {},
                "jobId": {},
                "thingGroupId": {},
                "targetSelection": {},
                "status": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "completedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMitigationActions": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions"
      },
      "input": {
        "type": "structure",
        "members": {
          "actionType": {
            "location": "querystring",
            "locationName": "actionType"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "actionName": {},
                "actionArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOTAUpdates": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "otaUpdateStatus": {
            "location": "querystring",
            "locationName": "otaUpdateStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "otaUpdateId": {},
                "otaUpdateArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOutgoingCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-out-going"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "outgoingCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "transferredTo": {},
                "transferDate": {
                  "type": "timestamp"
                },
                "transferMessage": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sk6"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicyPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/policy-principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "header",
            "locationName": "x-amzn-iot-policy"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Smc"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPolicyVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {},
                "isDefaultVersion": {
                  "type": "boolean"
                },
                "createDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPrincipalPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/principal-policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sk6"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPrincipalThings": {
      "http": {
        "method": "GET",
        "requestUri": "/principals/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Smm"
          },
          "nextToken": {}
        }
      }
    },
    "ListProvisioningTemplateVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates/{templateName}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {
                  "type": "integer"
                },
                "creationDate": {
                  "type": "timestamp"
                },
                "isDefaultVersion": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProvisioningTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioning-templates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "templates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "templateArn": {},
                "templateName": {},
                "description": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastModifiedDate": {
                  "type": "timestamp"
                },
                "enabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRoleAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliases": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListScheduledAudits": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAudits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "scheduledAuditName": {},
                "scheduledAuditArn": {},
                "frequency": {},
                "dayOfMonth": {},
                "dayOfWeek": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "dimensionName": {
            "location": "querystring",
            "locationName": "dimensionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileIdentifiers": {
            "type": "list",
            "member": {
              "shape": "Sn5"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfilesForTarget": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-for-target"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileTargetArn"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargetMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "securityProfileIdentifier": {
                  "shape": "Sn5"
                },
                "target": {
                  "shape": "Sna"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListStreams": {
      "http": {
        "method": "GET",
        "requestUri": "/streams"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "streamId": {},
                "streamArn": {},
                "streamVersion": {
                  "type": "integer"
                },
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1r"
          },
          "nextToken": {}
        }
      }
    },
    "ListTargetsForPolicy": {
      "http": {
        "requestUri": "/policy-targets/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "targets": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListTargetsForSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargets": {
            "type": "list",
            "member": {
              "shape": "Sna"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "parentGroup": {
            "location": "querystring",
            "locationName": "parentGroup"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Shf"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroupsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/thing-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Shf"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Smc"
          }
        }
      }
    },
    "ListThingRegistrationTaskReports": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}/reports"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "reportType"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "reportType": {
            "location": "querystring",
            "locationName": "reportType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceLinks": {
            "type": "list",
            "member": {}
          },
          "reportType": {},
          "nextToken": {}
        }
      }
    },
    "ListThingRegistrationTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskIds": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListThingTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingTypeName": {},
                "thingTypeArn": {},
                "thingTypeProperties": {
                  "shape": "S8g"
                },
                "thingTypeMetadata": {
                  "shape": "Shs"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThings": {
      "http": {
        "method": "GET",
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "attributeName": {
            "location": "querystring",
            "locationName": "attributeName"
          },
          "attributeValue": {
            "location": "querystring",
            "locationName": "attributeValue"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingTypeName": {},
                "thingArn": {},
                "attributes": {
                  "shape": "S2u"
                },
                "version": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Smm"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Smm"
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRuleDestinations": {
      "http": {
        "method": "GET",
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destinationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "status": {},
                "statusReason": {},
                "httpUrlSummary": {
                  "type": "structure",
                  "members": {
                    "confirmationUrl": {}
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRules": {
      "http": {
        "method": "GET",
        "requestUri": "/rules"
      },
      "input": {
        "type": "structure",
        "members": {
          "topic": {
            "location": "querystring",
            "locationName": "topic"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ruleDisabled": {
            "location": "querystring",
            "locationName": "ruleDisabled",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ruleArn": {},
                "ruleName": {},
                "topicPattern": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "ruleDisabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListV2LoggingLevels": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logTargetConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logTarget": {
                  "shape": "Sow"
                },
                "logLevel": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListViolationEvents": {
      "http": {
        "method": "GET",
        "requestUri": "/violation-events"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "violationEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S7b"
                },
                "metricValue": {
                  "shape": "S7i"
                },
                "violationEventType": {},
                "violationEventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCACertificate": {
      "http": {
        "requestUri": "/cacertificate"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificate",
          "verificationCertificate"
        ],
        "members": {
          "caCertificate": {},
          "verificationCertificate": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "allowAutoRegistration": {
            "location": "querystring",
            "locationName": "allowAutoRegistration",
            "type": "boolean"
          },
          "registrationConfig": {
            "shape": "Sf7"
          },
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificate": {
      "http": {
        "requestUri": "/certificate/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "caCertificatePem": {},
          "setAsActive": {
            "deprecated": true,
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificateWithoutCA": {
      "http": {
        "requestUri": "/certificate/register-no-ca"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterThing": {
      "http": {
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody"
        ],
        "members": {
          "templateBody": {},
          "parameters": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificatePem": {},
          "resourceArns": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "RejectCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/reject-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "rejectReason": {}
        }
      }
    },
    "RemoveThingFromBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/removeThingFromBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveThingFromThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/removeThingFromThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ReplaceTopicRule": {
      "http": {
        "method": "PATCH",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S8o"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "SearchIndex": {
      "http": {
        "requestUri": "/indices/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingId": {},
                "thingTypeName": {},
                "thingGroupNames": {
                  "shape": "Spq"
                },
                "attributes": {
                  "shape": "S2u"
                },
                "shadow": {},
                "connectivity": {
                  "type": "structure",
                  "members": {
                    "connected": {
                      "type": "boolean"
                    },
                    "timestamp": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "thingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingGroupName": {},
                "thingGroupId": {},
                "thingGroupDescription": {},
                "attributes": {
                  "shape": "S2u"
                },
                "parentGroupNames": {
                  "shape": "Spq"
                }
              }
            }
          }
        }
      }
    },
    "SetDefaultAuthorizer": {
      "http": {
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "SetDefaultPolicyVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "SetLoggingOptions": {
      "http": {
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptionsPayload"
        ],
        "members": {
          "loggingOptionsPayload": {
            "type": "structure",
            "required": [
              "roleArn"
            ],
            "members": {
              "roleArn": {},
              "logLevel": {}
            }
          }
        },
        "payload": "loggingOptionsPayload"
      }
    },
    "SetV2LoggingLevel": {
      "http": {
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "logTarget",
          "logLevel"
        ],
        "members": {
          "logTarget": {
            "shape": "Sow"
          },
          "logLevel": {}
        }
      }
    },
    "SetV2LoggingOptions": {
      "http": {
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "StartAuditMitigationActionsTask": {
      "http": {
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "target",
          "auditCheckToActionsMapping",
          "clientRequestToken"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "target": {
            "shape": "Sdz"
          },
          "auditCheckToActionsMapping": {
            "shape": "Se3"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartOnDemandAuditTask": {
      "http": {
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetCheckNames"
        ],
        "members": {
          "targetCheckNames": {
            "shape": "S73"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartThingRegistrationTask": {
      "http": {
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody",
          "inputFileBucket",
          "inputFileKey",
          "roleArn"
        ],
        "members": {
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StopThingRegistrationTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-registration-tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TestAuthorization": {
      "http": {
        "requestUri": "/test-authorization"
      },
      "input": {
        "type": "structure",
        "required": [
          "authInfos"
        ],
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "authInfos": {
            "type": "list",
            "member": {
              "shape": "Sqf"
            }
          },
          "clientId": {
            "location": "querystring",
            "locationName": "clientId"
          },
          "policyNamesToAdd": {
            "shape": "Sqj"
          },
          "policyNamesToSkip": {
            "shape": "Sqj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authInfo": {
                  "shape": "Sqf"
                },
                "allowed": {
                  "type": "structure",
                  "members": {
                    "policies": {
                      "shape": "Sk6"
                    }
                  }
                },
                "denied": {
                  "type": "structure",
                  "members": {
                    "implicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Sk6"
                        }
                      }
                    },
                    "explicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Sk6"
                        }
                      }
                    }
                  }
                },
                "authDecision": {},
                "missingContextValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "TestInvokeAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}/test"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "token": {},
          "tokenSignature": {},
          "httpContext": {
            "type": "structure",
            "members": {
              "headers": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "queryString": {}
            }
          },
          "mqttContext": {
            "type": "structure",
            "members": {
              "username": {},
              "password": {
                "type": "blob"
              },
              "clientId": {}
            }
          },
          "tlsContext": {
            "type": "structure",
            "members": {
              "serverName": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "isAuthenticated": {
            "type": "boolean"
          },
          "principalId": {},
          "policyDocuments": {
            "type": "list",
            "member": {}
          },
          "refreshAfterInSeconds": {
            "type": "integer"
          },
          "disconnectAfterInSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "TransferCertificate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/transfer-certificate/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "targetAwsAccount"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "targetAwsAccount": {
            "location": "querystring",
            "locationName": "targetAwsAccount"
          },
          "transferMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "transferredCertificateArn": {}
        }
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untag"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAccountAuditConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sd2"
          },
          "auditCheckConfigurations": {
            "shape": "Sd5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuthorizer": {
      "http": {
        "method": "PUT",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S1n"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "UpdateBillingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName",
          "billingGroupProperties"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S1z"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateCACertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          },
          "newAutoRegistrationStatus": {
            "location": "querystring",
            "locationName": "newAutoRegistrationStatus"
          },
          "registrationConfig": {
            "shape": "Sf7"
          },
          "removeAutoRegistration": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateCertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "newStatus"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          }
        }
      }
    },
    "UpdateDimension": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dimensions/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "stringValues"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "stringValues": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {},
          "type": {},
          "stringValues": {
            "shape": "S2b"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateDomainConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/domainConfigurations/{domainConfigurationName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainConfigurationName"
        ],
        "members": {
          "domainConfigurationName": {
            "location": "uri",
            "locationName": "domainConfigurationName"
          },
          "authorizerConfig": {
            "shape": "S2l"
          },
          "domainConfigurationStatus": {},
          "removeAuthorizerConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainConfigurationName": {},
          "domainConfigurationArn": {}
        }
      }
    },
    "UpdateDynamicThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S2r"
          },
          "expectedVersion": {
            "type": "long"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateEventConfigurations": {
      "http": {
        "method": "PATCH",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Sfy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateIndexingConfiguration": {
      "http": {
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sic"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sij"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJob": {
      "http": {
        "method": "PATCH",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "description": {},
          "presignedUrlConfig": {
            "shape": "S36"
          },
          "jobExecutionsRolloutConfig": {
            "shape": "S3a"
          },
          "abortConfig": {
            "shape": "S3h"
          },
          "timeoutConfig": {
            "shape": "S3o"
          }
        }
      }
    },
    "UpdateMitigationAction": {
      "http": {
        "method": "PATCH",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S3y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "UpdateProvisioningTemplate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/provisioning-templates/{templateName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateName"
        ],
        "members": {
          "templateName": {
            "location": "uri",
            "locationName": "templateName"
          },
          "description": {},
          "enabled": {
            "type": "boolean"
          },
          "defaultVersionId": {
            "type": "integer"
          },
          "provisioningRoleArn": {},
          "preProvisioningHook": {
            "shape": "S6n"
          },
          "removePreProvisioningHook": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRoleAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "UpdateScheduledAudit": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S73"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "UpdateSecurityProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S7a"
          },
          "alertTargets": {
            "shape": "S7t"
          },
          "additionalMetricsToRetain": {
            "shape": "S7x",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S7y"
          },
          "deleteBehaviors": {
            "type": "boolean"
          },
          "deleteAlertTargets": {
            "type": "boolean"
          },
          "deleteAdditionalMetricsToRetain": {
            "type": "boolean"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S7a"
          },
          "alertTargets": {
            "shape": "S7t"
          },
          "additionalMetricsToRetain": {
            "shape": "S7x",
            "deprecated": true,
            "deprecatedMessage": "Use additionalMetricsToRetainV2."
          },
          "additionalMetricsToRetainV2": {
            "shape": "S7y"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateStream": {
      "http": {
        "method": "PUT",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S84"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateThing": {
      "http": {
        "method": "PATCH",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S2t"
          },
          "expectedVersion": {
            "type": "long"
          },
          "removeThingType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S2r"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateThingGroupsForThing": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/updateThingGroupsForThing"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingGroupsToAdd": {
            "shape": "Ssp"
          },
          "thingGroupsToRemove": {
            "shape": "Ssp"
          },
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTopicRuleDestination": {
      "http": {
        "method": "PATCH",
        "requestUri": "/destinations"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "status"
        ],
        "members": {
          "arn": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ValidateSecurityProfileBehaviors": {
      "http": {
        "requestUri": "/security-profile-behaviors/validate"
      },
      "input": {
        "type": "structure",
        "required": [
          "behaviors"
        ],
        "members": {
          "behaviors": {
            "shape": "S7a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "valid": {
            "type": "boolean"
          },
          "validationErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "errorMessage": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sg": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1n": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1r": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "billingGroupDescription": {}
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2l": {
      "type": "structure",
      "members": {
        "defaultAuthorizerName": {},
        "allowAuthorizerOverride": {
          "type": "boolean"
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "thingGroupDescription": {},
        "attributePayload": {
          "shape": "S2t"
        }
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "attributes": {
          "shape": "S2u"
        },
        "merge": {
          "type": "boolean"
        }
      }
    },
    "S2u": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S36": {
      "type": "structure",
      "members": {
        "roleArn": {},
        "expiresInSec": {
          "type": "long"
        }
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        },
        "exponentialRate": {
          "type": "structure",
          "required": [
            "baseRatePerMinute",
            "incrementFactor",
            "rateIncreaseCriteria"
          ],
          "members": {
            "baseRatePerMinute": {
              "type": "integer"
            },
            "incrementFactor": {
              "type": "double"
            },
            "rateIncreaseCriteria": {
              "type": "structure",
              "members": {
                "numberOfNotifiedThings": {
                  "type": "integer"
                },
                "numberOfSucceededThings": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S3h": {
      "type": "structure",
      "required": [
        "criteriaList"
      ],
      "members": {
        "criteriaList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "failureType",
              "action",
              "thresholdPercentage",
              "minNumberOfExecutedThings"
            ],
            "members": {
              "failureType": {},
              "action": {},
              "thresholdPercentage": {
                "type": "double"
              },
              "minNumberOfExecutedThings": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "inProgressTimeoutInMinutes": {
          "type": "long"
        }
      }
    },
    "S3t": {
      "type": "structure",
      "members": {
        "PublicKey": {},
        "PrivateKey": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S3y": {
      "type": "structure",
      "members": {
        "updateDeviceCertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "updateCACertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "addThingsToThingGroupParams": {
          "type": "structure",
          "required": [
            "thingGroupNames"
          ],
          "members": {
            "thingGroupNames": {
              "type": "list",
              "member": {}
            },
            "overrideDynamicGroups": {
              "type": "boolean"
            }
          }
        },
        "replaceDefaultPolicyVersionParams": {
          "type": "structure",
          "required": [
            "templateName"
          ],
          "members": {
            "templateName": {}
          }
        },
        "enableIoTLoggingParams": {
          "type": "structure",
          "required": [
            "roleArnForLogging",
            "logLevel"
          ],
          "members": {
            "roleArnForLogging": {},
            "logLevel": {}
          }
        },
        "publishFindingToSnsParams": {
          "type": "structure",
          "required": [
            "topicArn"
          ],
          "members": {
            "topicArn": {}
          }
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {}
    },
    "S4j": {
      "type": "list",
      "member": {}
    },
    "S4l": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        },
        "exponentialRate": {
          "type": "structure",
          "required": [
            "baseRatePerMinute",
            "incrementFactor",
            "rateIncreaseCriteria"
          ],
          "members": {
            "baseRatePerMinute": {
              "type": "integer"
            },
            "incrementFactor": {
              "type": "double"
            },
            "rateIncreaseCriteria": {
              "type": "structure",
              "members": {
                "numberOfNotifiedThings": {
                  "type": "integer"
                },
                "numberOfSucceededThings": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S4s": {
      "type": "structure",
      "members": {
        "expiresInSec": {
          "type": "long"
        }
      }
    },
    "S53": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileName": {},
          "fileVersion": {},
          "fileLocation": {
            "type": "structure",
            "members": {
              "stream": {
                "type": "structure",
                "members": {
                  "streamId": {},
                  "fileId": {
                    "type": "integer"
                  }
                }
              },
              "s3Location": {
                "shape": "S5b"
              }
            }
          },
          "codeSigning": {
            "type": "structure",
            "members": {
              "awsSignerJobId": {},
              "startSigningJobParameter": {
                "type": "structure",
                "members": {
                  "signingProfileParameter": {
                    "type": "structure",
                    "members": {
                      "certificateArn": {},
                      "platform": {},
                      "certificatePathOnDevice": {}
                    }
                  },
                  "signingProfileName": {},
                  "destination": {
                    "type": "structure",
                    "members": {
                      "s3Destination": {
                        "type": "structure",
                        "members": {
                          "bucket": {},
                          "prefix": {}
                        }
                      }
                    }
                  }
                }
              },
              "customCodeSigning": {
                "type": "structure",
                "members": {
                  "signature": {
                    "type": "structure",
                    "members": {
                      "inlineDocument": {
                        "type": "blob"
                      }
                    }
                  },
                  "certificateChain": {
                    "type": "structure",
                    "members": {
                      "certificateName": {},
                      "inlineDocument": {}
                    }
                  },
                  "hashAlgorithm": {},
                  "signatureAlgorithm": {}
                }
              }
            }
          },
          "attributes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S5b": {
      "type": "structure",
      "members": {
        "bucket": {},
        "key": {},
        "version": {}
      }
    },
    "S60": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S6n": {
      "type": "structure",
      "required": [
        "targetArn"
      ],
      "members": {
        "payloadVersion": {},
        "targetArn": {}
      }
    },
    "S73": {
      "type": "list",
      "member": {}
    },
    "S7a": {
      "type": "list",
      "member": {
        "shape": "S7b"
      }
    },
    "S7b": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "metric": {},
        "metricDimension": {
          "shape": "S7e"
        },
        "criteria": {
          "type": "structure",
          "members": {
            "comparisonOperator": {},
            "value": {
              "shape": "S7i"
            },
            "durationSeconds": {
              "type": "integer"
            },
            "consecutiveDatapointsToAlarm": {
              "type": "integer"
            },
            "consecutiveDatapointsToClear": {
              "type": "integer"
            },
            "statisticalThreshold": {
              "type": "structure",
              "members": {
                "statistic": {}
              }
            }
          }
        }
      }
    },
    "S7e": {
      "type": "structure",
      "required": [
        "dimensionName"
      ],
      "members": {
        "dimensionName": {},
        "operator": {}
      }
    },
    "S7i": {
      "type": "structure",
      "members": {
        "count": {
          "type": "long"
        },
        "cidrs": {
          "type": "list",
          "member": {}
        },
        "ports": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        }
      }
    },
    "S7t": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "alertTargetArn",
          "roleArn"
        ],
        "members": {
          "alertTargetArn": {},
          "roleArn": {}
        }
      }
    },
    "S7x": {
      "type": "list",
      "member": {}
    },
    "S7y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "metric"
        ],
        "members": {
          "metric": {},
          "metricDimension": {
            "shape": "S7e"
          }
        }
      }
    },
    "S84": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileId": {
            "type": "integer"
          },
          "s3Location": {
            "shape": "S5b"
          }
        }
      }
    },
    "S8g": {
      "type": "structure",
      "members": {
        "thingTypeDescription": {},
        "searchableAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S8o": {
      "type": "structure",
      "required": [
        "sql",
        "actions"
      ],
      "members": {
        "sql": {},
        "description": {},
        "actions": {
          "shape": "S8r"
        },
        "ruleDisabled": {
          "type": "boolean"
        },
        "awsIotSqlVersion": {},
        "errorAction": {
          "shape": "S8s"
        }
      }
    },
    "S8r": {
      "type": "list",
      "member": {
        "shape": "S8s"
      }
    },
    "S8s": {
      "type": "structure",
      "members": {
        "dynamoDB": {
          "type": "structure",
          "required": [
            "tableName",
            "roleArn",
            "hashKeyField",
            "hashKeyValue"
          ],
          "members": {
            "tableName": {},
            "roleArn": {},
            "operation": {},
            "hashKeyField": {},
            "hashKeyValue": {},
            "hashKeyType": {},
            "rangeKeyField": {},
            "rangeKeyValue": {},
            "rangeKeyType": {},
            "payloadField": {}
          }
        },
        "dynamoDBv2": {
          "type": "structure",
          "required": [
            "roleArn",
            "putItem"
          ],
          "members": {
            "roleArn": {},
            "putItem": {
              "type": "structure",
              "required": [
                "tableName"
              ],
              "members": {
                "tableName": {}
              }
            }
          }
        },
        "lambda": {
          "type": "structure",
          "required": [
            "functionArn"
          ],
          "members": {
            "functionArn": {}
          }
        },
        "sns": {
          "type": "structure",
          "required": [
            "targetArn",
            "roleArn"
          ],
          "members": {
            "targetArn": {},
            "roleArn": {},
            "messageFormat": {}
          }
        },
        "sqs": {
          "type": "structure",
          "required": [
            "roleArn",
            "queueUrl"
          ],
          "members": {
            "roleArn": {},
            "queueUrl": {},
            "useBase64": {
              "type": "boolean"
            }
          }
        },
        "kinesis": {
          "type": "structure",
          "required": [
            "roleArn",
            "streamName"
          ],
          "members": {
            "roleArn": {},
            "streamName": {},
            "partitionKey": {}
          }
        },
        "republish": {
          "type": "structure",
          "required": [
            "roleArn",
            "topic"
          ],
          "members": {
            "roleArn": {},
            "topic": {},
            "qos": {
              "type": "integer"
            }
          }
        },
        "s3": {
          "type": "structure",
          "required": [
            "roleArn",
            "bucketName",
            "key"
          ],
          "members": {
            "roleArn": {},
            "bucketName": {},
            "key": {},
            "cannedAcl": {}
          }
        },
        "firehose": {
          "type": "structure",
          "required": [
            "roleArn",
            "deliveryStreamName"
          ],
          "members": {
            "roleArn": {},
            "deliveryStreamName": {},
            "separator": {}
          }
        },
        "cloudwatchMetric": {
          "type": "structure",
          "required": [
            "roleArn",
            "metricNamespace",
            "metricName",
            "metricValue",
            "metricUnit"
          ],
          "members": {
            "roleArn": {},
            "metricNamespace": {},
            "metricName": {},
            "metricValue": {},
            "metricUnit": {},
            "metricTimestamp": {}
          }
        },
        "cloudwatchAlarm": {
          "type": "structure",
          "required": [
            "roleArn",
            "alarmName",
            "stateReason",
            "stateValue"
          ],
          "members": {
            "roleArn": {},
            "alarmName": {},
            "stateReason": {},
            "stateValue": {}
          }
        },
        "cloudwatchLogs": {
          "type": "structure",
          "required": [
            "roleArn",
            "logGroupName"
          ],
          "members": {
            "roleArn": {},
            "logGroupName": {}
          }
        },
        "elasticsearch": {
          "type": "structure",
          "required": [
            "roleArn",
            "endpoint",
            "index",
            "type",
            "id"
          ],
          "members": {
            "roleArn": {},
            "endpoint": {},
            "index": {},
            "type": {},
            "id": {}
          }
        },
        "salesforce": {
          "type": "structure",
          "required": [
            "token",
            "url"
          ],
          "members": {
            "token": {},
            "url": {}
          }
        },
        "iotAnalytics": {
          "type": "structure",
          "members": {
            "channelArn": {},
            "channelName": {},
            "roleArn": {}
          }
        },
        "iotEvents": {
          "type": "structure",
          "required": [
            "inputName",
            "roleArn"
          ],
          "members": {
            "inputName": {},
            "messageId": {},
            "roleArn": {}
          }
        },
        "iotSiteWise": {
          "type": "structure",
          "required": [
            "putAssetPropertyValueEntries",
            "roleArn"
          ],
          "members": {
            "putAssetPropertyValueEntries": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "propertyValues"
                ],
                "members": {
                  "entryId": {},
                  "assetId": {},
                  "propertyId": {},
                  "propertyAlias": {},
                  "propertyValues": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "value",
                        "timestamp"
                      ],
                      "members": {
                        "value": {
                          "type": "structure",
                          "members": {
                            "stringValue": {},
                            "integerValue": {},
                            "doubleValue": {},
                            "booleanValue": {}
                          }
                        },
                        "timestamp": {
                          "type": "structure",
                          "required": [
                            "timeInSeconds"
                          ],
                          "members": {
                            "timeInSeconds": {},
                            "offsetInNanos": {}
                          }
                        },
                        "quality": {}
                      }
                    }
                  }
                }
              }
            },
            "roleArn": {}
          }
        },
        "stepFunctions": {
          "type": "structure",
          "required": [
            "stateMachineName",
            "roleArn"
          ],
          "members": {
            "executionNamePrefix": {},
            "stateMachineName": {},
            "roleArn": {}
          }
        },
        "http": {
          "type": "structure",
          "required": [
            "url"
          ],
          "members": {
            "url": {},
            "confirmationUrl": {},
            "headers": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "key",
                  "value"
                ],
                "members": {
                  "key": {},
                  "value": {}
                }
              }
            },
            "auth": {
              "type": "structure",
              "members": {
                "sigv4": {
                  "type": "structure",
                  "required": [
                    "signingRegion",
                    "serviceName",
                    "roleArn"
                  ],
                  "members": {
                    "signingRegion": {},
                    "serviceName": {},
                    "roleArn": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sbb": {
      "type": "structure",
      "members": {
        "arn": {},
        "status": {},
        "statusReason": {},
        "httpUrlProperties": {
          "type": "structure",
          "members": {
            "confirmationUrl": {}
          }
        }
      }
    },
    "Sd2": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "targetArn": {},
          "roleArn": {},
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sd5": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sda": {
      "type": "structure",
      "members": {
        "findingId": {},
        "taskId": {},
        "checkName": {},
        "taskStartTime": {
          "type": "timestamp"
        },
        "findingTime": {
          "type": "timestamp"
        },
        "severity": {},
        "nonCompliantResource": {
          "type": "structure",
          "members": {
            "resourceType": {},
            "resourceIdentifier": {
              "shape": "Sdf"
            },
            "additionalInfo": {
              "shape": "Sdk"
            }
          }
        },
        "relatedResources": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceIdentifier": {
                "shape": "Sdf"
              },
              "additionalInfo": {
                "shape": "Sdk"
              }
            }
          }
        },
        "reasonForNonCompliance": {},
        "reasonForNonComplianceCode": {}
      }
    },
    "Sdf": {
      "type": "structure",
      "members": {
        "deviceCertificateId": {},
        "caCertificateId": {},
        "cognitoIdentityPoolId": {},
        "clientId": {},
        "policyVersionIdentifier": {
          "type": "structure",
          "members": {
            "policyName": {},
            "policyVersionId": {}
          }
        },
        "account": {},
        "iamRoleArn": {},
        "roleAliasArn": {}
      }
    },
    "Sdk": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sdz": {
      "type": "structure",
      "members": {
        "auditTaskId": {},
        "findingIds": {
          "type": "list",
          "member": {}
        },
        "auditCheckToReasonCodeFilter": {
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Se3": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Set": {
      "type": "structure",
      "members": {
        "authorizerName": {},
        "authorizerArn": {},
        "authorizerFunctionArn": {},
        "tokenKeyName": {},
        "tokenSigningPublicKeys": {
          "shape": "S1n"
        },
        "status": {},
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "signingDisabled": {
          "type": "boolean"
        }
      }
    },
    "Sf6": {
      "type": "structure",
      "members": {
        "notBefore": {
          "type": "timestamp"
        },
        "notAfter": {
          "type": "timestamp"
        }
      }
    },
    "Sf7": {
      "type": "structure",
      "members": {
        "templateBody": {},
        "roleArn": {}
      }
    },
    "Sfy": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Shf": {
      "type": "list",
      "member": {
        "shape": "Shg"
      }
    },
    "Shg": {
      "type": "structure",
      "members": {
        "groupName": {},
        "groupArn": {}
      }
    },
    "Shs": {
      "type": "structure",
      "members": {
        "deprecated": {
          "type": "boolean"
        },
        "deprecationDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        }
      }
    },
    "Sic": {
      "type": "structure",
      "required": [
        "thingIndexingMode"
      ],
      "members": {
        "thingIndexingMode": {},
        "thingConnectivityIndexingMode": {},
        "managedFields": {
          "shape": "Sif"
        },
        "customFields": {
          "shape": "Sif"
        }
      }
    },
    "Sif": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {}
        }
      }
    },
    "Sij": {
      "type": "structure",
      "required": [
        "thingGroupIndexingMode"
      ],
      "members": {
        "thingGroupIndexingMode": {},
        "managedFields": {
          "shape": "Sif"
        },
        "customFields": {
          "shape": "Sif"
        }
      }
    },
    "Sk6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {}
        }
      }
    },
    "Sl3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "status": {},
          "certificateMode": {},
          "creationDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sln": {
      "type": "structure",
      "members": {
        "status": {},
        "queuedAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "executionNumber": {
          "type": "long"
        }
      }
    },
    "Smc": {
      "type": "list",
      "member": {}
    },
    "Smm": {
      "type": "list",
      "member": {}
    },
    "Sn5": {
      "type": "structure",
      "required": [
        "name",
        "arn"
      ],
      "members": {
        "name": {},
        "arn": {}
      }
    },
    "Sna": {
      "type": "structure",
      "required": [
        "arn"
      ],
      "members": {
        "arn": {}
      }
    },
    "Sow": {
      "type": "structure",
      "required": [
        "targetType"
      ],
      "members": {
        "targetType": {},
        "targetName": {}
      }
    },
    "Spq": {
      "type": "list",
      "member": {}
    },
    "Sqf": {
      "type": "structure",
      "required": [
        "resources"
      ],
      "members": {
        "actionType": {},
        "resources": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sqj": {
      "type": "list",
      "member": {}
    },
    "Ssp": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],118:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],119:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-05-28",
    "endpointPrefix": "data.iot",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT Data Plane",
    "serviceId": "IoT Data Plane",
    "signatureVersion": "v4",
    "signingName": "iotdata",
    "uid": "iot-data-2015-05-28"
  },
  "operations": {
    "DeleteThingShadow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/shadow"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "shadowName": {
            "location": "querystring",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "payload"
        ],
        "members": {
          "payload": {
            "type": "blob"
          }
        },
        "payload": "payload"
      }
    },
    "GetThingShadow": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/shadow"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "shadowName": {
            "location": "querystring",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "payload": {
            "type": "blob"
          }
        },
        "payload": "payload"
      }
    },
    "ListNamedShadowsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/api/things/shadow/ListNamedShadowsForThing/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "results": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "timestamp": {
            "type": "long"
          }
        }
      }
    },
    "Publish": {
      "http": {
        "requestUri": "/topics/{topic}"
      },
      "input": {
        "type": "structure",
        "required": [
          "topic"
        ],
        "members": {
          "topic": {
            "location": "uri",
            "locationName": "topic"
          },
          "qos": {
            "location": "querystring",
            "locationName": "qos",
            "type": "integer"
          },
          "payload": {
            "type": "blob"
          }
        },
        "payload": "payload"
      }
    },
    "UpdateThingShadow": {
      "http": {
        "requestUri": "/things/{thingName}/shadow"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "payload"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "shadowName": {
            "location": "querystring",
            "locationName": "name"
          },
          "payload": {
            "type": "blob"
          }
        },
        "payload": "payload"
      },
      "output": {
        "type": "structure",
        "members": {
          "payload": {
            "type": "blob"
          }
        },
        "payload": "payload"
      }
    }
  },
  "shapes": {}
}
},{}],120:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],121:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "iotanalytics",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT Analytics",
    "serviceId": "IoTAnalytics",
    "signatureVersion": "v4",
    "signingName": "iotanalytics",
    "uid": "iotanalytics-2017-11-27"
  },
  "operations": {
    "BatchPutMessage": {
      "http": {
        "requestUri": "/messages/batch",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName",
          "messages"
        ],
        "members": {
          "channelName": {},
          "messages": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "messageId",
                "payload"
              ],
              "members": {
                "messageId": {},
                "payload": {
                  "type": "blob"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchPutMessageErrorEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "messageId": {},
                "errorCode": {},
                "errorMessage": {}
              }
            }
          }
        }
      }
    },
    "CancelPipelineReprocessing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/pipelines/{pipelineName}/reprocessing/{reprocessingId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "reprocessingId"
        ],
        "members": {
          "pipelineName": {
            "location": "uri",
            "locationName": "pipelineName"
          },
          "reprocessingId": {
            "location": "uri",
            "locationName": "reprocessingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateChannel": {
      "http": {
        "requestUri": "/channels",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName"
        ],
        "members": {
          "channelName": {},
          "channelStorage": {
            "shape": "Sh"
          },
          "retentionPeriod": {
            "shape": "Sn"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channelName": {},
          "channelArn": {},
          "retentionPeriod": {
            "shape": "Sn"
          }
        }
      }
    },
    "CreateDataset": {
      "http": {
        "requestUri": "/datasets",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName",
          "actions"
        ],
        "members": {
          "datasetName": {},
          "actions": {
            "shape": "Sy"
          },
          "triggers": {
            "shape": "S1l"
          },
          "contentDeliveryRules": {
            "shape": "S1q"
          },
          "retentionPeriod": {
            "shape": "Sn"
          },
          "versioningConfiguration": {
            "shape": "S21"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetName": {},
          "datasetArn": {},
          "retentionPeriod": {
            "shape": "Sn"
          }
        }
      }
    },
    "CreateDatasetContent": {
      "http": {
        "requestUri": "/datasets/{datasetName}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "versionId": {}
        }
      }
    },
    "CreateDatastore": {
      "http": {
        "requestUri": "/datastores",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "datastoreName"
        ],
        "members": {
          "datastoreName": {},
          "datastoreStorage": {
            "shape": "S2b"
          },
          "retentionPeriod": {
            "shape": "Sn"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datastoreName": {},
          "datastoreArn": {},
          "retentionPeriod": {
            "shape": "Sn"
          }
        }
      }
    },
    "CreatePipeline": {
      "http": {
        "requestUri": "/pipelines",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "pipelineActivities"
        ],
        "members": {
          "pipelineName": {},
          "pipelineActivities": {
            "shape": "S2h"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineName": {},
          "pipelineArn": {}
        }
      }
    },
    "DeleteChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{channelName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName"
        ],
        "members": {
          "channelName": {
            "location": "uri",
            "locationName": "channelName"
          }
        }
      }
    },
    "DeleteDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/datasets/{datasetName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          }
        }
      }
    },
    "DeleteDatasetContent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/datasets/{datasetName}/content",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          },
          "versionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      }
    },
    "DeleteDatastore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/datastores/{datastoreName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "datastoreName"
        ],
        "members": {
          "datastoreName": {
            "location": "uri",
            "locationName": "datastoreName"
          }
        }
      }
    },
    "DeletePipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/pipelines/{pipelineName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName"
        ],
        "members": {
          "pipelineName": {
            "location": "uri",
            "locationName": "pipelineName"
          }
        }
      }
    },
    "DescribeChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName"
        ],
        "members": {
          "channelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "includeStatistics": {
            "location": "querystring",
            "locationName": "includeStatistics",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channel": {
            "type": "structure",
            "members": {
              "name": {},
              "storage": {
                "shape": "Sh"
              },
              "arn": {},
              "status": {},
              "retentionPeriod": {
                "shape": "Sn"
              },
              "creationTime": {
                "type": "timestamp"
              },
              "lastUpdateTime": {
                "type": "timestamp"
              }
            }
          },
          "statistics": {
            "type": "structure",
            "members": {
              "size": {
                "shape": "S3f"
              }
            }
          }
        }
      }
    },
    "DescribeDataset": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataset": {
            "type": "structure",
            "members": {
              "name": {},
              "arn": {},
              "actions": {
                "shape": "Sy"
              },
              "triggers": {
                "shape": "S1l"
              },
              "contentDeliveryRules": {
                "shape": "S1q"
              },
              "status": {},
              "creationTime": {
                "type": "timestamp"
              },
              "lastUpdateTime": {
                "type": "timestamp"
              },
              "retentionPeriod": {
                "shape": "Sn"
              },
              "versioningConfiguration": {
                "shape": "S21"
              }
            }
          }
        }
      }
    },
    "DescribeDatastore": {
      "http": {
        "method": "GET",
        "requestUri": "/datastores/{datastoreName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datastoreName"
        ],
        "members": {
          "datastoreName": {
            "location": "uri",
            "locationName": "datastoreName"
          },
          "includeStatistics": {
            "location": "querystring",
            "locationName": "includeStatistics",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datastore": {
            "type": "structure",
            "members": {
              "name": {},
              "storage": {
                "shape": "S2b"
              },
              "arn": {},
              "status": {},
              "retentionPeriod": {
                "shape": "Sn"
              },
              "creationTime": {
                "type": "timestamp"
              },
              "lastUpdateTime": {
                "type": "timestamp"
              }
            }
          },
          "statistics": {
            "type": "structure",
            "members": {
              "size": {
                "shape": "S3f"
              }
            }
          }
        }
      }
    },
    "DescribeLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "loggingOptions": {
            "shape": "S3s"
          }
        }
      }
    },
    "DescribePipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/pipelines/{pipelineName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName"
        ],
        "members": {
          "pipelineName": {
            "location": "uri",
            "locationName": "pipelineName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipeline": {
            "type": "structure",
            "members": {
              "name": {},
              "arn": {},
              "activities": {
                "shape": "S2h"
              },
              "reprocessingSummaries": {
                "shape": "S3y"
              },
              "creationTime": {
                "type": "timestamp"
              },
              "lastUpdateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetDatasetContent": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetName}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          },
          "versionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "entryName": {},
                "dataURI": {}
              }
            }
          },
          "timestamp": {
            "type": "timestamp"
          },
          "status": {
            "shape": "S46"
          }
        }
      }
    },
    "ListChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/channels"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "channelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "channelName": {},
                "channelStorage": {
                  "type": "structure",
                  "members": {
                    "serviceManagedS3": {
                      "type": "structure",
                      "members": {}
                    },
                    "customerManagedS3": {
                      "type": "structure",
                      "members": {
                        "bucket": {},
                        "keyPrefix": {},
                        "roleArn": {}
                      }
                    }
                  }
                },
                "status": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDatasetContents": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetName}/contents"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "scheduledOnOrAfter": {
            "location": "querystring",
            "locationName": "scheduledOnOrAfter",
            "type": "timestamp"
          },
          "scheduledBefore": {
            "location": "querystring",
            "locationName": "scheduledBefore",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetContentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "version": {},
                "status": {
                  "shape": "S46"
                },
                "creationTime": {
                  "type": "timestamp"
                },
                "scheduleTime": {
                  "type": "timestamp"
                },
                "completionTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDatasets": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datasetName": {},
                "status": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "triggers": {
                  "shape": "S1l"
                },
                "actions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "actionName": {},
                      "actionType": {}
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDatastores": {
      "http": {
        "method": "GET",
        "requestUri": "/datastores"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datastoreSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datastoreName": {},
                "datastoreStorage": {
                  "type": "structure",
                  "members": {
                    "serviceManagedS3": {
                      "type": "structure",
                      "members": {}
                    },
                    "customerManagedS3": {
                      "type": "structure",
                      "members": {
                        "bucket": {},
                        "keyPrefix": {},
                        "roleArn": {}
                      }
                    }
                  }
                },
                "status": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPipelines": {
      "http": {
        "method": "GET",
        "requestUri": "/pipelines"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pipelineSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "pipelineName": {},
                "reprocessingSummaries": {
                  "shape": "S3y"
                },
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sq"
          }
        }
      }
    },
    "PutLoggingOptions": {
      "http": {
        "method": "PUT",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptions"
        ],
        "members": {
          "loggingOptions": {
            "shape": "S3s"
          }
        }
      }
    },
    "RunPipelineActivity": {
      "http": {
        "requestUri": "/pipelineactivities/run"
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineActivity",
          "payloads"
        ],
        "members": {
          "pipelineActivity": {
            "shape": "S2i"
          },
          "payloads": {
            "shape": "S59"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "payloads": {
            "shape": "S59"
          },
          "logResult": {}
        }
      }
    },
    "SampleChannelData": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{channelName}/sample"
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName"
        ],
        "members": {
          "channelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "maxMessages": {
            "location": "querystring",
            "locationName": "maxMessages",
            "type": "integer"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "payloads": {
            "shape": "S59"
          }
        }
      }
    },
    "StartPipelineReprocessing": {
      "http": {
        "requestUri": "/pipelines/{pipelineName}/reprocessing"
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName"
        ],
        "members": {
          "pipelineName": {
            "location": "uri",
            "locationName": "pipelineName"
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reprocessingId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{channelName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "channelName"
        ],
        "members": {
          "channelName": {
            "location": "uri",
            "locationName": "channelName"
          },
          "channelStorage": {
            "shape": "Sh"
          },
          "retentionPeriod": {
            "shape": "Sn"
          }
        }
      }
    },
    "UpdateDataset": {
      "http": {
        "method": "PUT",
        "requestUri": "/datasets/{datasetName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetName",
          "actions"
        ],
        "members": {
          "datasetName": {
            "location": "uri",
            "locationName": "datasetName"
          },
          "actions": {
            "shape": "Sy"
          },
          "triggers": {
            "shape": "S1l"
          },
          "contentDeliveryRules": {
            "shape": "S1q"
          },
          "retentionPeriod": {
            "shape": "Sn"
          },
          "versioningConfiguration": {
            "shape": "S21"
          }
        }
      }
    },
    "UpdateDatastore": {
      "http": {
        "method": "PUT",
        "requestUri": "/datastores/{datastoreName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datastoreName"
        ],
        "members": {
          "datastoreName": {
            "location": "uri",
            "locationName": "datastoreName"
          },
          "retentionPeriod": {
            "shape": "Sn"
          },
          "datastoreStorage": {
            "shape": "S2b"
          }
        }
      }
    },
    "UpdatePipeline": {
      "http": {
        "method": "PUT",
        "requestUri": "/pipelines/{pipelineName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "pipelineName",
          "pipelineActivities"
        ],
        "members": {
          "pipelineName": {
            "location": "uri",
            "locationName": "pipelineName"
          },
          "pipelineActivities": {
            "shape": "S2h"
          }
        }
      }
    }
  },
  "shapes": {
    "Sh": {
      "type": "structure",
      "members": {
        "serviceManagedS3": {
          "type": "structure",
          "members": {}
        },
        "customerManagedS3": {
          "type": "structure",
          "required": [
            "bucket",
            "roleArn"
          ],
          "members": {
            "bucket": {},
            "keyPrefix": {},
            "roleArn": {}
          }
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "unlimited": {
          "type": "boolean"
        },
        "numberOfDays": {
          "type": "integer"
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "actionName": {},
          "queryAction": {
            "type": "structure",
            "required": [
              "sqlQuery"
            ],
            "members": {
              "sqlQuery": {},
              "filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "deltaTime": {
                      "type": "structure",
                      "required": [
                        "offsetSeconds",
                        "timeExpression"
                      ],
                      "members": {
                        "offsetSeconds": {
                          "type": "integer"
                        },
                        "timeExpression": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "containerAction": {
            "type": "structure",
            "required": [
              "image",
              "executionRoleArn",
              "resourceConfiguration"
            ],
            "members": {
              "image": {},
              "executionRoleArn": {},
              "resourceConfiguration": {
                "type": "structure",
                "required": [
                  "computeType",
                  "volumeSizeInGB"
                ],
                "members": {
                  "computeType": {},
                  "volumeSizeInGB": {
                    "type": "integer"
                  }
                }
              },
              "variables": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "name"
                  ],
                  "members": {
                    "name": {},
                    "stringValue": {},
                    "doubleValue": {
                      "type": "double"
                    },
                    "datasetContentVersionValue": {
                      "type": "structure",
                      "required": [
                        "datasetName"
                      ],
                      "members": {
                        "datasetName": {}
                      }
                    },
                    "outputFileUriValue": {
                      "type": "structure",
                      "required": [
                        "fileName"
                      ],
                      "members": {
                        "fileName": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "schedule": {
            "type": "structure",
            "members": {
              "expression": {}
            }
          },
          "dataset": {
            "type": "structure",
            "required": [
              "name"
            ],
            "members": {
              "name": {}
            }
          }
        }
      }
    },
    "S1q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "destination"
        ],
        "members": {
          "entryName": {},
          "destination": {
            "type": "structure",
            "members": {
              "iotEventsDestinationConfiguration": {
                "type": "structure",
                "required": [
                  "inputName",
                  "roleArn"
                ],
                "members": {
                  "inputName": {},
                  "roleArn": {}
                }
              },
              "s3DestinationConfiguration": {
                "type": "structure",
                "required": [
                  "bucket",
                  "key",
                  "roleArn"
                ],
                "members": {
                  "bucket": {},
                  "key": {},
                  "glueConfiguration": {
                    "type": "structure",
                    "required": [
                      "tableName",
                      "databaseName"
                    ],
                    "members": {
                      "tableName": {},
                      "databaseName": {}
                    }
                  },
                  "roleArn": {}
                }
              }
            }
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "unlimited": {
          "type": "boolean"
        },
        "maxVersions": {
          "type": "integer"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "serviceManagedS3": {
          "type": "structure",
          "members": {}
        },
        "customerManagedS3": {
          "type": "structure",
          "required": [
            "bucket",
            "roleArn"
          ],
          "members": {
            "bucket": {},
            "keyPrefix": {},
            "roleArn": {}
          }
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "shape": "S2i"
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "channel": {
          "type": "structure",
          "required": [
            "name",
            "channelName"
          ],
          "members": {
            "name": {},
            "channelName": {},
            "next": {}
          }
        },
        "lambda": {
          "type": "structure",
          "required": [
            "name",
            "lambdaName",
            "batchSize"
          ],
          "members": {
            "name": {},
            "lambdaName": {},
            "batchSize": {
              "type": "integer"
            },
            "next": {}
          }
        },
        "datastore": {
          "type": "structure",
          "required": [
            "name",
            "datastoreName"
          ],
          "members": {
            "name": {},
            "datastoreName": {}
          }
        },
        "addAttributes": {
          "type": "structure",
          "required": [
            "name",
            "attributes"
          ],
          "members": {
            "name": {},
            "attributes": {
              "type": "map",
              "key": {},
              "value": {}
            },
            "next": {}
          }
        },
        "removeAttributes": {
          "type": "structure",
          "required": [
            "name",
            "attributes"
          ],
          "members": {
            "name": {},
            "attributes": {
              "shape": "S2t"
            },
            "next": {}
          }
        },
        "selectAttributes": {
          "type": "structure",
          "required": [
            "name",
            "attributes"
          ],
          "members": {
            "name": {},
            "attributes": {
              "shape": "S2t"
            },
            "next": {}
          }
        },
        "filter": {
          "type": "structure",
          "required": [
            "name",
            "filter"
          ],
          "members": {
            "name": {},
            "filter": {},
            "next": {}
          }
        },
        "math": {
          "type": "structure",
          "required": [
            "name",
            "attribute",
            "math"
          ],
          "members": {
            "name": {},
            "attribute": {},
            "math": {},
            "next": {}
          }
        },
        "deviceRegistryEnrich": {
          "type": "structure",
          "required": [
            "name",
            "attribute",
            "thingName",
            "roleArn"
          ],
          "members": {
            "name": {},
            "attribute": {},
            "thingName": {},
            "roleArn": {},
            "next": {}
          }
        },
        "deviceShadowEnrich": {
          "type": "structure",
          "required": [
            "name",
            "attribute",
            "thingName",
            "roleArn"
          ],
          "members": {
            "name": {},
            "attribute": {},
            "thingName": {},
            "roleArn": {},
            "next": {}
          }
        }
      }
    },
    "S2t": {
      "type": "list",
      "member": {}
    },
    "S3f": {
      "type": "structure",
      "members": {
        "estimatedSizeInBytes": {
          "type": "double"
        },
        "estimatedOn": {
          "type": "timestamp"
        }
      }
    },
    "S3s": {
      "type": "structure",
      "required": [
        "roleArn",
        "level",
        "enabled"
      ],
      "members": {
        "roleArn": {},
        "level": {},
        "enabled": {
          "type": "boolean"
        }
      }
    },
    "S3y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "id": {},
          "status": {},
          "creationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S46": {
      "type": "structure",
      "members": {
        "state": {},
        "reason": {}
      }
    },
    "S59": {
      "type": "list",
      "member": {
        "type": "blob"
      }
    }
  }
}
},{}],122:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListChannels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListDatasetContents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListDatasets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListDatastores": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ListPipelines": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    }
  }
}

},{}],123:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-12-02",
    "endpointPrefix": "kinesis",
    "jsonVersion": "1.1",
    "protocol": "json",
    "protocolSettings": {
      "h2": "eventstream"
    },
    "serviceAbbreviation": "Kinesis",
    "serviceFullName": "Amazon Kinesis",
    "serviceId": "Kinesis",
    "signatureVersion": "v4",
    "targetPrefix": "Kinesis_20131202",
    "uid": "kinesis-2013-12-02"
  },
  "operations": {
    "AddTagsToStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "Tags"
        ],
        "members": {
          "StreamName": {},
          "Tags": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "CreateStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardCount"
        ],
        "members": {
          "StreamName": {},
          "ShardCount": {
            "type": "integer"
          }
        }
      }
    },
    "DecreaseStreamRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "RetentionPeriodHours"
        ],
        "members": {
          "StreamName": {},
          "RetentionPeriodHours": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "EnforceConsumerDeletion": {
            "type": "boolean"
          }
        }
      }
    },
    "DeregisterStreamConsumer": {
      "input": {
        "type": "structure",
        "members": {
          "StreamARN": {},
          "ConsumerName": {},
          "ConsumerARN": {}
        }
      }
    },
    "DescribeLimits": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "ShardLimit",
          "OpenShardCount"
        ],
        "members": {
          "ShardLimit": {
            "type": "integer"
          },
          "OpenShardCount": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartShardId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamDescription"
        ],
        "members": {
          "StreamDescription": {
            "type": "structure",
            "required": [
              "StreamName",
              "StreamARN",
              "StreamStatus",
              "Shards",
              "HasMoreShards",
              "RetentionPeriodHours",
              "StreamCreationTimestamp",
              "EnhancedMonitoring"
            ],
            "members": {
              "StreamName": {},
              "StreamARN": {},
              "StreamStatus": {},
              "Shards": {
                "shape": "Sp"
              },
              "HasMoreShards": {
                "type": "boolean"
              },
              "RetentionPeriodHours": {
                "type": "integer"
              },
              "StreamCreationTimestamp": {
                "type": "timestamp"
              },
              "EnhancedMonitoring": {
                "shape": "Sw"
              },
              "EncryptionType": {},
              "KeyId": {}
            }
          }
        }
      }
    },
    "DescribeStreamConsumer": {
      "input": {
        "type": "structure",
        "members": {
          "StreamARN": {},
          "ConsumerName": {},
          "ConsumerARN": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConsumerDescription"
        ],
        "members": {
          "ConsumerDescription": {
            "type": "structure",
            "required": [
              "ConsumerName",
              "ConsumerARN",
              "ConsumerStatus",
              "ConsumerCreationTimestamp",
              "StreamARN"
            ],
            "members": {
              "ConsumerName": {},
              "ConsumerARN": {},
              "ConsumerStatus": {},
              "ConsumerCreationTimestamp": {
                "type": "timestamp"
              },
              "StreamARN": {}
            }
          }
        }
      }
    },
    "DescribeStreamSummary": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamDescriptionSummary"
        ],
        "members": {
          "StreamDescriptionSummary": {
            "type": "structure",
            "required": [
              "StreamName",
              "StreamARN",
              "StreamStatus",
              "RetentionPeriodHours",
              "StreamCreationTimestamp",
              "EnhancedMonitoring",
              "OpenShardCount"
            ],
            "members": {
              "StreamName": {},
              "StreamARN": {},
              "StreamStatus": {},
              "RetentionPeriodHours": {
                "type": "integer"
              },
              "StreamCreationTimestamp": {
                "type": "timestamp"
              },
              "EnhancedMonitoring": {
                "shape": "Sw"
              },
              "EncryptionType": {},
              "KeyId": {},
              "OpenShardCount": {
                "type": "integer"
              },
              "ConsumerCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "DisableEnhancedMonitoring": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardLevelMetrics"
        ],
        "members": {
          "StreamName": {},
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "shape": "S1b"
      }
    },
    "EnableEnhancedMonitoring": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardLevelMetrics"
        ],
        "members": {
          "StreamName": {},
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "shape": "S1b"
      }
    },
    "GetRecords": {
      "input": {
        "type": "structure",
        "required": [
          "ShardIterator"
        ],
        "members": {
          "ShardIterator": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Records"
        ],
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SequenceNumber",
                "Data",
                "PartitionKey"
              ],
              "members": {
                "SequenceNumber": {},
                "ApproximateArrivalTimestamp": {
                  "type": "timestamp"
                },
                "Data": {
                  "type": "blob"
                },
                "PartitionKey": {},
                "EncryptionType": {}
              }
            }
          },
          "NextShardIterator": {},
          "MillisBehindLatest": {
            "type": "long"
          }
        }
      }
    },
    "GetShardIterator": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardId",
          "ShardIteratorType"
        ],
        "members": {
          "StreamName": {},
          "ShardId": {},
          "ShardIteratorType": {},
          "StartingSequenceNumber": {},
          "Timestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShardIterator": {}
        }
      }
    },
    "IncreaseStreamRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "RetentionPeriodHours"
        ],
        "members": {
          "StreamName": {},
          "RetentionPeriodHours": {
            "type": "integer"
          }
        }
      }
    },
    "ListShards": {
      "input": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "NextToken": {},
          "ExclusiveStartShardId": {},
          "MaxResults": {
            "type": "integer"
          },
          "StreamCreationTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Shards": {
            "shape": "Sp"
          },
          "NextToken": {}
        }
      }
    },
    "ListStreamConsumers": {
      "input": {
        "type": "structure",
        "required": [
          "StreamARN"
        ],
        "members": {
          "StreamARN": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StreamCreationTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Consumers": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamNames",
          "HasMoreStreams"
        ],
        "members": {
          "StreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "ExclusiveStartTagKey": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "HasMoreTags"
        ],
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key"
              ],
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "HasMoreTags": {
            "type": "boolean"
          }
        }
      }
    },
    "MergeShards": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardToMerge",
          "AdjacentShardToMerge"
        ],
        "members": {
          "StreamName": {},
          "ShardToMerge": {},
          "AdjacentShardToMerge": {}
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "Data",
          "PartitionKey"
        ],
        "members": {
          "StreamName": {},
          "Data": {
            "type": "blob"
          },
          "PartitionKey": {},
          "ExplicitHashKey": {},
          "SequenceNumberForOrdering": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ShardId",
          "SequenceNumber"
        ],
        "members": {
          "ShardId": {},
          "SequenceNumber": {},
          "EncryptionType": {}
        }
      }
    },
    "PutRecords": {
      "input": {
        "type": "structure",
        "required": [
          "Records",
          "StreamName"
        ],
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Data",
                "PartitionKey"
              ],
              "members": {
                "Data": {
                  "type": "blob"
                },
                "ExplicitHashKey": {},
                "PartitionKey": {}
              }
            }
          },
          "StreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Records"
        ],
        "members": {
          "FailedRecordCount": {
            "type": "integer"
          },
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SequenceNumber": {},
                "ShardId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "EncryptionType": {}
        }
      }
    },
    "RegisterStreamConsumer": {
      "input": {
        "type": "structure",
        "required": [
          "StreamARN",
          "ConsumerName"
        ],
        "members": {
          "StreamARN": {},
          "ConsumerName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Consumer"
        ],
        "members": {
          "Consumer": {
            "shape": "S1y"
          }
        }
      }
    },
    "RemoveTagsFromStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "TagKeys"
        ],
        "members": {
          "StreamName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "SplitShard": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardToSplit",
          "NewStartingHashKey"
        ],
        "members": {
          "StreamName": {},
          "ShardToSplit": {},
          "NewStartingHashKey": {}
        }
      }
    },
    "StartStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "EncryptionType",
          "KeyId"
        ],
        "members": {
          "StreamName": {},
          "EncryptionType": {},
          "KeyId": {}
        }
      }
    },
    "StopStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "EncryptionType",
          "KeyId"
        ],
        "members": {
          "StreamName": {},
          "EncryptionType": {},
          "KeyId": {}
        }
      }
    },
    "UpdateShardCount": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "TargetShardCount",
          "ScalingType"
        ],
        "members": {
          "StreamName": {},
          "TargetShardCount": {
            "type": "integer"
          },
          "ScalingType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "CurrentShardCount": {
            "type": "integer"
          },
          "TargetShardCount": {
            "type": "integer"
          }
        }
      }
    }
  },
  "shapes": {
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ShardId",
          "HashKeyRange",
          "SequenceNumberRange"
        ],
        "members": {
          "ShardId": {},
          "ParentShardId": {},
          "AdjacentParentShardId": {},
          "HashKeyRange": {
            "type": "structure",
            "required": [
              "StartingHashKey",
              "EndingHashKey"
            ],
            "members": {
              "StartingHashKey": {},
              "EndingHashKey": {}
            }
          },
          "SequenceNumberRange": {
            "type": "structure",
            "required": [
              "StartingSequenceNumber"
            ],
            "members": {
              "StartingSequenceNumber": {},
              "EndingSequenceNumber": {}
            }
          }
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "structure",
      "members": {
        "StreamName": {},
        "CurrentShardLevelMetrics": {
          "shape": "Sy"
        },
        "DesiredShardLevelMetrics": {
          "shape": "Sy"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "required": [
        "ConsumerName",
        "ConsumerARN",
        "ConsumerStatus",
        "ConsumerCreationTimestamp"
      ],
      "members": {
        "ConsumerName": {},
        "ConsumerARN": {},
        "ConsumerStatus": {},
        "ConsumerCreationTimestamp": {
          "type": "timestamp"
        }
      }
    }
  }
}
},{}],124:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeStream": {
      "input_token": "ExclusiveStartShardId",
      "limit_key": "Limit",
      "more_results": "StreamDescription.HasMoreShards",
      "output_token": "StreamDescription.Shards[-1].ShardId",
      "result_key": "StreamDescription.Shards"
    },
    "ListStreamConsumers": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListStreams": {
      "input_token": "ExclusiveStartStreamName",
      "limit_key": "Limit",
      "more_results": "HasMoreStreams",
      "output_token": "StreamNames[-1]",
      "result_key": "StreamNames"
    }
  }
}
},{}],125:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "StreamExists": {
      "delay": 10,
      "operation": "DescribeStream",
      "maxAttempts": 18,
      "acceptors": [
        {
          "expected": "ACTIVE",
          "matcher": "path",
          "state": "success",
          "argument": "StreamDescription.StreamStatus"
        }
      ]
    },
    "StreamNotExists": {
      "delay": 10,
      "operation": "DescribeStream",
      "maxAttempts": 18,
      "acceptors": [
        {
          "expected": "ResourceNotFoundException",
          "matcher": "error",
          "state": "success"
        }
      ]
    }
  }
}

},{}],126:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-09-30",
    "endpointPrefix": "kinesisvideo",
    "protocol": "rest-json",
    "serviceAbbreviation": "Kinesis Video Archived Media",
    "serviceFullName": "Amazon Kinesis Video Streams Archived Media",
    "serviceId": "Kinesis Video Archived Media",
    "signatureVersion": "v4",
    "uid": "kinesis-video-archived-media-2017-09-30"
  },
  "operations": {
    "GetClip": {
      "http": {
        "requestUri": "/getClip"
      },
      "input": {
        "type": "structure",
        "required": [
          "ClipFragmentSelector"
        ],
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "ClipFragmentSelector": {
            "type": "structure",
            "required": [
              "FragmentSelectorType",
              "TimestampRange"
            ],
            "members": {
              "FragmentSelectorType": {},
              "TimestampRange": {
                "type": "structure",
                "required": [
                  "StartTimestamp",
                  "EndTimestamp"
                ],
                "members": {
                  "StartTimestamp": {
                    "type": "timestamp"
                  },
                  "EndTimestamp": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Payload": {
            "shape": "Sa"
          }
        },
        "payload": "Payload"
      }
    },
    "GetDASHStreamingSessionURL": {
      "http": {
        "requestUri": "/getDASHStreamingSessionURL"
      },
      "input": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "PlaybackMode": {},
          "DisplayFragmentTimestamp": {},
          "DisplayFragmentNumber": {},
          "DASHFragmentSelector": {
            "type": "structure",
            "members": {
              "FragmentSelectorType": {},
              "TimestampRange": {
                "type": "structure",
                "members": {
                  "StartTimestamp": {
                    "type": "timestamp"
                  },
                  "EndTimestamp": {
                    "type": "timestamp"
                  }
                }
              }
            }
          },
          "Expires": {
            "type": "integer"
          },
          "MaxManifestFragmentResults": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DASHStreamingSessionURL": {}
        }
      }
    },
    "GetHLSStreamingSessionURL": {
      "http": {
        "requestUri": "/getHLSStreamingSessionURL"
      },
      "input": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "PlaybackMode": {},
          "HLSFragmentSelector": {
            "type": "structure",
            "members": {
              "FragmentSelectorType": {},
              "TimestampRange": {
                "type": "structure",
                "members": {
                  "StartTimestamp": {
                    "type": "timestamp"
                  },
                  "EndTimestamp": {
                    "type": "timestamp"
                  }
                }
              }
            }
          },
          "ContainerFormat": {},
          "DiscontinuityMode": {},
          "DisplayFragmentTimestamp": {},
          "Expires": {
            "type": "integer"
          },
          "MaxMediaPlaylistFragmentResults": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HLSStreamingSessionURL": {}
        }
      }
    },
    "GetMediaForFragmentList": {
      "http": {
        "requestUri": "/getMediaForFragmentList"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "Fragments"
        ],
        "members": {
          "StreamName": {},
          "Fragments": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Payload": {
            "shape": "Sa"
          }
        },
        "payload": "Payload"
      }
    },
    "ListFragments": {
      "http": {
        "requestUri": "/listFragments"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "MaxResults": {
            "type": "long"
          },
          "NextToken": {},
          "FragmentSelector": {
            "type": "structure",
            "required": [
              "FragmentSelectorType",
              "TimestampRange"
            ],
            "members": {
              "FragmentSelectorType": {},
              "TimestampRange": {
                "type": "structure",
                "required": [
                  "StartTimestamp",
                  "EndTimestamp"
                ],
                "members": {
                  "StartTimestamp": {
                    "type": "timestamp"
                  },
                  "EndTimestamp": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Fragments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FragmentNumber": {},
                "FragmentSizeInBytes": {
                  "type": "long"
                },
                "ProducerTimestamp": {
                  "type": "timestamp"
                },
                "ServerTimestamp": {
                  "type": "timestamp"
                },
                "FragmentLengthInMilliseconds": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "blob",
      "streaming": true
    }
  }
}
},{}],127:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListFragments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Fragments"
    }
  }
}
},{}],128:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-09-30",
    "endpointPrefix": "kinesisvideo",
    "protocol": "rest-json",
    "serviceAbbreviation": "Kinesis Video Media",
    "serviceFullName": "Amazon Kinesis Video Streams Media",
    "serviceId": "Kinesis Video Media",
    "signatureVersion": "v4",
    "uid": "kinesis-video-media-2017-09-30"
  },
  "operations": {
    "GetMedia": {
      "http": {
        "requestUri": "/getMedia"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartSelector"
        ],
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "StartSelector": {
            "type": "structure",
            "required": [
              "StartSelectorType"
            ],
            "members": {
              "StartSelectorType": {},
              "AfterFragmentNumber": {},
              "StartTimestamp": {
                "type": "timestamp"
              },
              "ContinuationToken": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Payload": {
            "type": "blob",
            "streaming": true
          }
        },
        "payload": "Payload"
      }
    }
  },
  "shapes": {}
}
},{}],129:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],130:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-12-04",
    "endpointPrefix": "kinesisvideo",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon Kinesis Video Signaling Channels",
    "serviceFullName": "Amazon Kinesis Video Signaling Channels",
    "serviceId": "Kinesis Video Signaling",
    "signatureVersion": "v4",
    "uid": "kinesis-video-signaling-2019-12-04"
  },
  "operations": {
    "GetIceServerConfig": {
      "http": {
        "requestUri": "/v1/get-ice-server-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelARN"
        ],
        "members": {
          "ChannelARN": {},
          "ClientId": {},
          "Service": {},
          "Username": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IceServerList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Uris": {
                  "type": "list",
                  "member": {}
                },
                "Username": {},
                "Password": {},
                "Ttl": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "SendAlexaOfferToMaster": {
      "http": {
        "requestUri": "/v1/send-alexa-offer-to-master"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelARN",
          "SenderClientId",
          "MessagePayload"
        ],
        "members": {
          "ChannelARN": {},
          "SenderClientId": {},
          "MessagePayload": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Answer": {}
        }
      }
    }
  },
  "shapes": {}
}
},{}],131:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],132:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-09-30",
    "endpointPrefix": "kinesisvideo",
    "protocol": "rest-json",
    "serviceAbbreviation": "Kinesis Video",
    "serviceFullName": "Amazon Kinesis Video Streams",
    "serviceId": "Kinesis Video",
    "signatureVersion": "v4",
    "uid": "kinesisvideo-2017-09-30"
  },
  "operations": {
    "CreateSignalingChannel": {
      "http": {
        "requestUri": "/createSignalingChannel"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {},
          "ChannelType": {},
          "SingleMasterConfiguration": {
            "shape": "S4"
          },
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelARN": {}
        }
      }
    },
    "CreateStream": {
      "http": {
        "requestUri": "/createStream"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "DeviceName": {},
          "StreamName": {},
          "MediaType": {},
          "KmsKeyId": {},
          "DataRetentionInHours": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamARN": {}
        }
      }
    },
    "DeleteSignalingChannel": {
      "http": {
        "requestUri": "/deleteSignalingChannel"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelARN"
        ],
        "members": {
          "ChannelARN": {},
          "CurrentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStream": {
      "http": {
        "requestUri": "/deleteStream"
      },
      "input": {
        "type": "structure",
        "required": [
          "StreamARN"
        ],
        "members": {
          "StreamARN": {},
          "CurrentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeSignalingChannel": {
      "http": {
        "requestUri": "/describeSignalingChannel"
      },
      "input": {
        "type": "structure",
        "members": {
          "ChannelName": {},
          "ChannelARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelInfo": {
            "shape": "Sr"
          }
        }
      }
    },
    "DescribeStream": {
      "http": {
        "requestUri": "/describeStream"
      },
      "input": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "StreamARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamInfo": {
            "shape": "Sw"
          }
        }
      }
    },
    "GetDataEndpoint": {
      "http": {
        "requestUri": "/getDataEndpoint"
      },
      "input": {
        "type": "structure",
        "required": [
          "APIName"
        ],
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "APIName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataEndpoint": {}
        }
      }
    },
    "GetSignalingChannelEndpoint": {
      "http": {
        "requestUri": "/getSignalingChannelEndpoint"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelARN"
        ],
        "members": {
          "ChannelARN": {},
          "SingleMasterChannelEndpointConfiguration": {
            "type": "structure",
            "members": {
              "Protocols": {
                "type": "list",
                "member": {}
              },
              "Role": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceEndpointList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Protocol": {},
                "ResourceEndpoint": {}
              }
            }
          }
        }
      }
    },
    "ListSignalingChannels": {
      "http": {
        "requestUri": "/listSignalingChannels"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ChannelNameCondition": {
            "type": "structure",
            "members": {
              "ComparisonOperator": {},
              "ComparisonValue": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelInfoList": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStreams": {
      "http": {
        "requestUri": "/listStreams"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "StreamNameCondition": {
            "type": "structure",
            "members": {
              "ComparisonOperator": {},
              "ComparisonValue": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamInfoList": {
            "type": "list",
            "member": {
              "shape": "Sw"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "requestUri": "/ListTagsForResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "NextToken": {},
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Tags": {
            "shape": "Si"
          }
        }
      }
    },
    "ListTagsForStream": {
      "http": {
        "requestUri": "/listTagsForStream"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StreamARN": {},
          "StreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Tags": {
            "shape": "Si"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/TagResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagStream": {
      "http": {
        "requestUri": "/tagStream"
      },
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "StreamARN": {},
          "StreamName": {},
          "Tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/UntagResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeyList"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeyList": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagStream": {
      "http": {
        "requestUri": "/untagStream"
      },
      "input": {
        "type": "structure",
        "required": [
          "TagKeyList"
        ],
        "members": {
          "StreamARN": {},
          "StreamName": {},
          "TagKeyList": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataRetention": {
      "http": {
        "requestUri": "/updateDataRetention"
      },
      "input": {
        "type": "structure",
        "required": [
          "CurrentVersion",
          "Operation",
          "DataRetentionChangeInHours"
        ],
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "CurrentVersion": {},
          "Operation": {},
          "DataRetentionChangeInHours": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateSignalingChannel": {
      "http": {
        "requestUri": "/updateSignalingChannel"
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelARN",
          "CurrentVersion"
        ],
        "members": {
          "ChannelARN": {},
          "CurrentVersion": {},
          "SingleMasterConfiguration": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateStream": {
      "http": {
        "requestUri": "/updateStream"
      },
      "input": {
        "type": "structure",
        "required": [
          "CurrentVersion"
        ],
        "members": {
          "StreamName": {},
          "StreamARN": {},
          "CurrentVersion": {},
          "DeviceName": {},
          "MediaType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "MessageTtlSeconds": {
          "type": "integer"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sr": {
      "type": "structure",
      "members": {
        "ChannelName": {},
        "ChannelARN": {},
        "ChannelType": {},
        "ChannelStatus": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "SingleMasterConfiguration": {
          "shape": "S4"
        },
        "Version": {}
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "DeviceName": {},
        "StreamName": {},
        "StreamARN": {},
        "MediaType": {},
        "KmsKeyId": {},
        "Version": {},
        "Status": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "DataRetentionInHours": {
          "type": "integer"
        }
      }
    },
    "S1v": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],133:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListSignalingChannels": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ChannelInfoList"
    },
    "ListStreams": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StreamInfoList"
    }
  }
}
},{}],134:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-01",
    "endpointPrefix": "kms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "KMS",
    "serviceFullName": "AWS Key Management Service",
    "serviceId": "KMS",
    "signatureVersion": "v4",
    "targetPrefix": "TrentService",
    "uid": "kms-2014-11-01"
  },
  "operations": {
    "CancelKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {}
        }
      }
    },
    "ConnectCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "CreateCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreName",
          "CloudHsmClusterId",
          "TrustAnchorCertificate",
          "KeyStorePassword"
        ],
        "members": {
          "CustomKeyStoreName": {},
          "CloudHsmClusterId": {},
          "TrustAnchorCertificate": {},
          "KeyStorePassword": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomKeyStoreId": {}
        }
      }
    },
    "CreateGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GranteePrincipal",
          "Operations"
        ],
        "members": {
          "KeyId": {},
          "GranteePrincipal": {},
          "RetiringPrincipal": {},
          "Operations": {
            "shape": "Sh"
          },
          "Constraints": {
            "shape": "Sj"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "GrantId": {}
        }
      }
    },
    "CreateKey": {
      "input": {
        "type": "structure",
        "members": {
          "Policy": {},
          "Description": {},
          "KeyUsage": {},
          "CustomerMasterKeySpec": {},
          "Origin": {},
          "CustomKeyStoreId": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "S14"
          }
        }
      }
    },
    "Decrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "EncryptionContext": {
            "shape": "Sk"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "KeyId": {},
          "EncryptionAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "S1i"
          },
          "EncryptionAlgorithm": {}
        }
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName"
        ],
        "members": {
          "AliasName": {}
        }
      }
    },
    "DeleteCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteImportedKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DescribeCustomKeyStores": {
      "input": {
        "type": "structure",
        "members": {
          "CustomKeyStoreId": {},
          "CustomKeyStoreName": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomKeyStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CustomKeyStoreId": {},
                "CustomKeyStoreName": {},
                "CloudHsmClusterId": {},
                "TrustAnchorCertificate": {},
                "ConnectionState": {},
                "ConnectionErrorCode": {},
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "S14"
          }
        }
      }
    },
    "DisableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DisableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DisconnectCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "EnableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "Encrypt": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Plaintext"
        ],
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "S1i"
          },
          "EncryptionContext": {
            "shape": "Sk"
          },
          "GrantTokens": {
            "shape": "Sn"
          },
          "EncryptionAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {},
          "EncryptionAlgorithm": {}
        }
      }
    },
    "GenerateDataKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sk"
          },
          "NumberOfBytes": {
            "type": "integer"
          },
          "KeySpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "Plaintext": {
            "shape": "S1i"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateDataKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "KeyPairSpec"
        ],
        "members": {
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeyId": {},
          "KeyPairSpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrivateKeyCiphertextBlob": {
            "type": "blob"
          },
          "PrivateKeyPlaintext": {
            "shape": "S1i"
          },
          "PublicKey": {
            "type": "blob"
          },
          "KeyId": {},
          "KeyPairSpec": {}
        }
      }
    },
    "GenerateDataKeyPairWithoutPlaintext": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "KeyPairSpec"
        ],
        "members": {
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeyId": {},
          "KeyPairSpec": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrivateKeyCiphertextBlob": {
            "type": "blob"
          },
          "PublicKey": {
            "type": "blob"
          },
          "KeyId": {},
          "KeyPairSpec": {}
        }
      }
    },
    "GenerateDataKeyWithoutPlaintext": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sk"
          },
          "KeySpec": {},
          "NumberOfBytes": {
            "type": "integer"
          },
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateRandom": {
      "input": {
        "type": "structure",
        "members": {
          "NumberOfBytes": {
            "type": "integer"
          },
          "CustomKeyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Plaintext": {
            "shape": "S1i"
          }
        }
      }
    },
    "GetKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "GetKeyRotationStatus": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyRotationEnabled": {
            "type": "boolean"
          }
        }
      }
    },
    "GetParametersForImport": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "WrappingAlgorithm",
          "WrappingKeySpec"
        ],
        "members": {
          "KeyId": {},
          "WrappingAlgorithm": {},
          "WrappingKeySpec": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "PublicKey": {
            "shape": "S1i"
          },
          "ParametersValidTo": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "PublicKey": {
            "type": "blob"
          },
          "CustomerMasterKeySpec": {},
          "KeyUsage": {},
          "EncryptionAlgorithms": {
            "shape": "S1b"
          },
          "SigningAlgorithms": {
            "shape": "S1d"
          }
        }
      }
    },
    "ImportKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "ImportToken",
          "EncryptedKeyMaterial"
        ],
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "EncryptedKeyMaterial": {
            "type": "blob"
          },
          "ValidTo": {
            "type": "timestamp"
          },
          "ExpirationModel": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AliasName": {},
                "AliasArn": {},
                "TargetKeyId": {}
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListGrants": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "KeyId": {}
        }
      },
      "output": {
        "shape": "S31"
      }
    },
    "ListKeyPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListKeys": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "KeyId": {},
                "KeyArn": {}
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListResourceTags": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sz"
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListRetirableGrants": {
      "input": {
        "type": "structure",
        "required": [
          "RetiringPrincipal"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "RetiringPrincipal": {}
        }
      },
      "output": {
        "shape": "S31"
      }
    },
    "PutKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName",
          "Policy"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {},
          "Policy": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          }
        }
      }
    },
    "ReEncrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob",
          "DestinationKeyId"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceEncryptionContext": {
            "shape": "Sk"
          },
          "SourceKeyId": {},
          "DestinationKeyId": {},
          "DestinationEncryptionContext": {
            "shape": "Sk"
          },
          "SourceEncryptionAlgorithm": {},
          "DestinationEncryptionAlgorithm": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceKeyId": {},
          "KeyId": {},
          "SourceEncryptionAlgorithm": {},
          "DestinationEncryptionAlgorithm": {}
        }
      }
    },
    "RetireGrant": {
      "input": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "RevokeGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GrantId"
        ],
        "members": {
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "ScheduleKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "PendingWindowInDays": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "DeletionDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sign": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Message",
          "SigningAlgorithm"
        ],
        "members": {
          "KeyId": {},
          "Message": {
            "shape": "S1i"
          },
          "MessageType": {},
          "GrantTokens": {
            "shape": "Sn"
          },
          "SigningAlgorithm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Signature": {
            "type": "blob"
          },
          "SigningAlgorithm": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Tags"
        ],
        "members": {
          "KeyId": {},
          "Tags": {
            "shape": "Sz"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "TagKeys"
        ],
        "members": {
          "KeyId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "UpdateCustomKeyStore": {
      "input": {
        "type": "structure",
        "required": [
          "CustomKeyStoreId"
        ],
        "members": {
          "CustomKeyStoreId": {},
          "NewCustomKeyStoreName": {},
          "KeyStorePassword": {
            "shape": "Sd"
          },
          "CloudHsmClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateKeyDescription": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Description"
        ],
        "members": {
          "KeyId": {},
          "Description": {}
        }
      }
    },
    "Verify": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Message",
          "Signature",
          "SigningAlgorithm"
        ],
        "members": {
          "KeyId": {},
          "Message": {
            "shape": "S1i"
          },
          "MessageType": {},
          "Signature": {
            "type": "blob"
          },
          "SigningAlgorithm": {},
          "GrantTokens": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "SignatureValid": {
            "type": "boolean"
          },
          "SigningAlgorithm": {}
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Sh": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "structure",
      "members": {
        "EncryptionContextSubset": {
          "shape": "Sk"
        },
        "EncryptionContextEquals": {
          "shape": "Sk"
        }
      }
    },
    "Sk": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValue"
        ],
        "members": {
          "TagKey": {},
          "TagValue": {}
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "KeyId"
      ],
      "members": {
        "AWSAccountId": {},
        "KeyId": {},
        "Arn": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "Description": {},
        "KeyUsage": {},
        "KeyState": {},
        "DeletionDate": {
          "type": "timestamp"
        },
        "ValidTo": {
          "type": "timestamp"
        },
        "Origin": {},
        "CustomKeyStoreId": {},
        "CloudHsmClusterId": {},
        "ExpirationModel": {},
        "KeyManager": {},
        "CustomerMasterKeySpec": {},
        "EncryptionAlgorithms": {
          "shape": "S1b"
        },
        "SigningAlgorithms": {
          "shape": "S1d"
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "blob",
      "sensitive": true
    },
    "S31": {
      "type": "structure",
      "members": {
        "Grants": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "KeyId": {},
              "GrantId": {},
              "Name": {},
              "CreationDate": {
                "type": "timestamp"
              },
              "GranteePrincipal": {},
              "RetiringPrincipal": {},
              "IssuingAccount": {},
              "Operations": {
                "shape": "Sh"
              },
              "Constraints": {
                "shape": "Sj"
              }
            }
          }
        },
        "NextMarker": {},
        "Truncated": {
          "type": "boolean"
        }
      }
    }
  }
}
},{}],135:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListAliases": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Aliases"
    },
    "ListGrants": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Grants"
    },
    "ListKeyPolicies": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "PolicyNames"
    },
    "ListKeys": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "more_results": "Truncated",
      "output_token": "NextMarker",
      "result_key": "Keys"
    }
  }
}
},{}],136:[function(require,module,exports){
module.exports={
  "metadata": {
    "apiVersion": "2014-11-11",
    "endpointPrefix": "lambda",
    "serviceFullName": "AWS Lambda",
    "serviceId": "Lambda",
    "signatureVersion": "v4",
    "protocol": "rest-json"
  },
  "operations": {
    "AddEventSource": {
      "http": {
        "requestUri": "/2014-11-13/event-source-mappings/"
      },
      "input": {
        "type": "structure",
        "required": [
          "EventSource",
          "FunctionName",
          "Role"
        ],
        "members": {
          "EventSource": {},
          "FunctionName": {},
          "Role": {},
          "BatchSize": {
            "type": "integer"
          },
          "Parameters": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2014-11-13/functions/{FunctionName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      }
    },
    "GetEventSource": {
      "http": {
        "method": "GET",
        "requestUri": "/2014-11-13/event-source-mappings/{UUID}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2014-11-13/functions/{FunctionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "Se"
          },
          "Code": {
            "type": "structure",
            "members": {
              "RepositoryType": {},
              "Location": {}
            }
          }
        }
      }
    },
    "GetFunctionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2014-11-13/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "shape": "Se"
      }
    },
    "InvokeAsync": {
      "http": {
        "requestUri": "/2014-11-13/functions/{FunctionName}/invoke-async/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "InvokeArgs"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvokeArgs": {
            "shape": "Sq"
          }
        },
        "payload": "InvokeArgs"
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListEventSources": {
      "http": {
        "method": "GET",
        "requestUri": "/2014-11-13/event-source-mappings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSourceArn": {
            "location": "querystring",
            "locationName": "EventSource"
          },
          "FunctionName": {
            "location": "querystring",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "EventSources": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/2014-11-13/functions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Functions": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          }
        }
      }
    },
    "RemoveEventSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2014-11-13/event-source-mappings/{UUID}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      }
    },
    "UpdateFunctionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2014-11-13/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Role": {
            "location": "querystring",
            "locationName": "Role"
          },
          "Handler": {
            "location": "querystring",
            "locationName": "Handler"
          },
          "Description": {
            "location": "querystring",
            "locationName": "Description"
          },
          "Timeout": {
            "location": "querystring",
            "locationName": "Timeout",
            "type": "integer"
          },
          "MemorySize": {
            "location": "querystring",
            "locationName": "MemorySize",
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "Se"
      }
    },
    "UploadFunction": {
      "http": {
        "method": "PUT",
        "requestUri": "/2014-11-13/functions/{FunctionName}",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "FunctionZip",
          "Runtime",
          "Role",
          "Handler",
          "Mode"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "FunctionZip": {
            "shape": "Sq"
          },
          "Runtime": {
            "location": "querystring",
            "locationName": "Runtime"
          },
          "Role": {
            "location": "querystring",
            "locationName": "Role"
          },
          "Handler": {
            "location": "querystring",
            "locationName": "Handler"
          },
          "Mode": {
            "location": "querystring",
            "locationName": "Mode"
          },
          "Description": {
            "location": "querystring",
            "locationName": "Description"
          },
          "Timeout": {
            "location": "querystring",
            "locationName": "Timeout",
            "type": "integer"
          },
          "MemorySize": {
            "location": "querystring",
            "locationName": "MemorySize",
            "type": "integer"
          }
        },
        "payload": "FunctionZip"
      },
      "output": {
        "shape": "Se"
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S7": {
      "type": "structure",
      "members": {
        "UUID": {},
        "BatchSize": {
          "type": "integer"
        },
        "EventSource": {},
        "FunctionName": {},
        "Parameters": {
          "shape": "S6"
        },
        "Role": {},
        "LastModified": {
          "type": "timestamp"
        },
        "IsActive": {
          "type": "boolean"
        },
        "Status": {}
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "FunctionARN": {},
        "ConfigurationId": {},
        "Runtime": {},
        "Role": {},
        "Handler": {},
        "Mode": {},
        "CodeSize": {
          "type": "long"
        },
        "Description": {},
        "Timeout": {
          "type": "integer"
        },
        "MemorySize": {
          "type": "integer"
        },
        "LastModified": {
          "type": "timestamp"
        }
      }
    },
    "Sq": {
      "type": "blob",
      "streaming": true
    }
  }
}
},{}],137:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListEventSources": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "limit_key": "MaxItems",
      "result_key": "EventSources"
    },
    "ListFunctions": {
      "input_token": "Marker",
      "output_token": "NextMarker",
      "limit_key": "MaxItems",
      "result_key": "Functions"
    }
  }
}

},{}],138:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-03-31",
    "endpointPrefix": "lambda",
    "protocol": "rest-json",
    "serviceFullName": "AWS Lambda",
    "serviceId": "Lambda",
    "signatureVersion": "v4",
    "uid": "lambda-2015-03-31"
  },
  "operations": {
    "AddLayerVersionPermission": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "OrganizationId": {},
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {},
          "RevisionId": {}
        }
      }
    },
    "AddPermission": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "SourceArn": {},
          "SourceAccount": {},
          "EventSourceToken": {},
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {}
        }
      }
    },
    "CreateAlias": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name",
          "FunctionVersion"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {},
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "CreateEventSourceMapping": {
      "http": {
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "EventSourceArn",
          "FunctionName"
        ],
        "members": {
          "EventSourceArn": {},
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "MaximumBatchingWindowInSeconds": {
            "type": "integer"
          },
          "ParallelizationFactor": {
            "type": "integer"
          },
          "StartingPosition": {},
          "StartingPositionTimestamp": {
            "type": "timestamp"
          },
          "DestinationConfig": {
            "shape": "S10"
          },
          "MaximumRecordAgeInSeconds": {
            "type": "integer"
          },
          "BisectBatchOnFunctionError": {
            "type": "boolean"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S17"
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/2015-03-31/functions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Runtime",
          "Role",
          "Handler",
          "Code"
        ],
        "members": {
          "FunctionName": {},
          "Runtime": {},
          "Role": {},
          "Handler": {},
          "Code": {
            "type": "structure",
            "members": {
              "ZipFile": {
                "shape": "S1d"
              },
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {}
            }
          },
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "Publish": {
            "type": "boolean"
          },
          "VpcConfig": {
            "shape": "S1k"
          },
          "DeadLetterConfig": {
            "shape": "S1p"
          },
          "Environment": {
            "shape": "S1r"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S1w"
          },
          "Tags": {
            "shape": "S1y"
          },
          "Layers": {
            "shape": "S21"
          },
          "FileSystemConfigs": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "DeleteAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      }
    },
    "DeleteEventSourceMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "S17"
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "DeleteFunctionConcurrency": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      }
    },
    "DeleteFunctionEventInvokeConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "DeleteLayerVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      }
    },
    "DeleteProvisionedConcurrencyConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-08-19/account-settings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountLimit": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "CodeSizeUnzipped": {
                "type": "long"
              },
              "CodeSizeZipped": {
                "type": "long"
              },
              "ConcurrentExecutions": {
                "type": "integer"
              },
              "UnreservedConcurrentExecutions": {
                "type": "integer"
              }
            }
          },
          "AccountUsage": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "FunctionCount": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "GetEventSourceMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "S17"
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S27"
          },
          "Code": {
            "type": "structure",
            "members": {
              "RepositoryType": {},
              "Location": {}
            }
          },
          "Tags": {
            "shape": "S1y"
          },
          "Concurrency": {
            "shape": "S38"
          }
        }
      }
    },
    "GetFunctionConcurrency": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedConcurrentExecutions": {
            "type": "integer"
          }
        }
      }
    },
    "GetFunctionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "GetFunctionEventInvokeConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S3e"
      }
    },
    "GetLayerVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "shape": "S3i"
      }
    },
    "GetLayerVersionByArn": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers?find=LayerVersion",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {
            "location": "querystring",
            "locationName": "Arn"
          }
        }
      },
      "output": {
        "shape": "S3i"
      }
    },
    "GetLayerVersionPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "GetProvisionedConcurrencyConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AvailableProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AllocatedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "Status": {},
          "StatusReason": {},
          "LastModified": {}
        }
      }
    },
    "Invoke": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/invocations"
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvocationType": {
            "location": "header",
            "locationName": "X-Amz-Invocation-Type"
          },
          "LogType": {
            "location": "header",
            "locationName": "X-Amz-Log-Type"
          },
          "ClientContext": {
            "location": "header",
            "locationName": "X-Amz-Client-Context"
          },
          "Payload": {
            "shape": "S1d"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        },
        "payload": "Payload"
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "location": "statusCode",
            "type": "integer"
          },
          "FunctionError": {
            "location": "header",
            "locationName": "X-Amz-Function-Error"
          },
          "LogResult": {
            "location": "header",
            "locationName": "X-Amz-Log-Result"
          },
          "Payload": {
            "shape": "S1d"
          },
          "ExecutedVersion": {
            "location": "header",
            "locationName": "X-Amz-Executed-Version"
          }
        },
        "payload": "Payload"
      }
    },
    "InvokeAsync": {
      "http": {
        "requestUri": "/2014-11-13/functions/{FunctionName}/invoke-async/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "InvokeArgs"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvokeArgs": {
            "type": "blob",
            "streaming": true
          }
        },
        "deprecated": true,
        "payload": "InvokeArgs"
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "ListAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          }
        }
      }
    },
    "ListEventSourceMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSourceArn": {
            "location": "querystring",
            "locationName": "EventSourceArn"
          },
          "FunctionName": {
            "location": "querystring",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "EventSourceMappings": {
            "type": "list",
            "member": {
              "shape": "S17"
            }
          }
        }
      }
    },
    "ListFunctionEventInvokeConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FunctionEventInvokeConfigs": {
            "type": "list",
            "member": {
              "shape": "S3e"
            }
          },
          "NextMarker": {}
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MasterRegion": {
            "location": "querystring",
            "locationName": "MasterRegion"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Functions": {
            "shape": "S4k"
          }
        }
      }
    },
    "ListLayerVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName"
        ],
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "LayerVersions": {
            "type": "list",
            "member": {
              "shape": "S4p"
            }
          }
        }
      }
    },
    "ListLayers": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LayerName": {},
                "LayerArn": {},
                "LatestMatchingVersion": {
                  "shape": "S4p"
                }
              }
            }
          }
        }
      }
    },
    "ListProvisionedConcurrencyConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency?List=ALL",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedConcurrencyConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FunctionArn": {},
                "RequestedProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "AvailableProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "AllocatedProvisionedConcurrentExecutions": {
                  "type": "integer"
                },
                "Status": {},
                "StatusReason": {},
                "LastModified": {}
              }
            }
          },
          "NextMarker": {}
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-31/tags/{ARN}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1y"
          }
        }
      }
    },
    "ListVersionsByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Versions": {
            "shape": "S4k"
          }
        }
      }
    },
    "PublishLayerVersion": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "Content"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Description": {},
          "Content": {
            "type": "structure",
            "members": {
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {},
              "ZipFile": {
                "shape": "S1d"
              }
            }
          },
          "CompatibleRuntimes": {
            "shape": "S3l"
          },
          "LicenseInfo": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Content": {
            "shape": "S3j"
          },
          "LayerArn": {},
          "LayerVersionArn": {},
          "Description": {},
          "CreatedDate": {},
          "Version": {
            "type": "long"
          },
          "CompatibleRuntimes": {
            "shape": "S3l"
          },
          "LicenseInfo": {}
        }
      }
    },
    "PublishVersion": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "CodeSha256": {},
          "Description": {},
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "PutFunctionConcurrency": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "ReservedConcurrentExecutions"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ReservedConcurrentExecutions": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S38"
      }
    },
    "PutFunctionEventInvokeConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "MaximumEventAgeInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "shape": "S3e"
      }
    },
    "PutProvisionedConcurrencyConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/2019-09-30/functions/{FunctionName}/provisioned-concurrency",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Qualifier",
          "ProvisionedConcurrentExecutions"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "ProvisionedConcurrentExecutions": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AvailableProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "AllocatedProvisionedConcurrentExecutions": {
            "type": "integer"
          },
          "Status": {},
          "StatusReason": {},
          "LastModified": {}
        }
      }
    },
    "RemoveLayerVersionPermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "RemovePermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "Tags": {
            "shape": "S1y"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "UpdateEventSourceMapping": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          },
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "MaximumBatchingWindowInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S10"
          },
          "MaximumRecordAgeInSeconds": {
            "type": "integer"
          },
          "BisectBatchOnFunctionError": {
            "type": "boolean"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "ParallelizationFactor": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S17"
      }
    },
    "UpdateFunctionCode": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/code",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ZipFile": {
            "shape": "S1d"
          },
          "S3Bucket": {},
          "S3Key": {},
          "S3ObjectVersion": {},
          "Publish": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "UpdateFunctionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Role": {},
          "Handler": {},
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "VpcConfig": {
            "shape": "S1k"
          },
          "Environment": {
            "shape": "S1r"
          },
          "Runtime": {},
          "DeadLetterConfig": {
            "shape": "S1p"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S1w"
          },
          "RevisionId": {},
          "Layers": {
            "shape": "S21"
          },
          "FileSystemConfigs": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "shape": "S27"
      }
    },
    "UpdateFunctionEventInvokeConfig": {
      "http": {
        "requestUri": "/2019-09-25/functions/{FunctionName}/event-invoke-config",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "MaximumRetryAttempts": {
            "type": "integer"
          },
          "MaximumEventAgeInSeconds": {
            "type": "integer"
          },
          "DestinationConfig": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "shape": "S3e"
      }
    }
  },
  "shapes": {
    "Sn": {
      "type": "structure",
      "members": {
        "AdditionalVersionWeights": {
          "type": "map",
          "key": {},
          "value": {
            "type": "double"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AliasArn": {},
        "Name": {},
        "FunctionVersion": {},
        "Description": {},
        "RoutingConfig": {
          "shape": "Sn"
        },
        "RevisionId": {}
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "OnSuccess": {
          "type": "structure",
          "members": {
            "Destination": {}
          }
        },
        "OnFailure": {
          "type": "structure",
          "members": {
            "Destination": {}
          }
        }
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "UUID": {},
        "BatchSize": {
          "type": "integer"
        },
        "MaximumBatchingWindowInSeconds": {
          "type": "integer"
        },
        "ParallelizationFactor": {
          "type": "integer"
        },
        "EventSourceArn": {},
        "FunctionArn": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LastProcessingResult": {},
        "State": {},
        "StateTransitionReason": {},
        "DestinationConfig": {
          "shape": "S10"
        },
        "MaximumRecordAgeInSeconds": {
          "type": "integer"
        },
        "BisectBatchOnFunctionError": {
          "type": "boolean"
        },
        "MaximumRetryAttempts": {
          "type": "integer"
        }
      }
    },
    "S1d": {
      "type": "blob",
      "sensitive": true
    },
    "S1k": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S1l"
        },
        "SecurityGroupIds": {
          "shape": "S1n"
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1n": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "members": {
        "TargetArn": {}
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "Variables": {
          "shape": "S1s"
        }
      }
    },
    "S1s": {
      "type": "map",
      "key": {
        "type": "string",
        "sensitive": true
      },
      "value": {
        "type": "string",
        "sensitive": true
      },
      "sensitive": true
    },
    "S1w": {
      "type": "structure",
      "members": {
        "Mode": {}
      }
    },
    "S1y": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S21": {
      "type": "list",
      "member": {}
    },
    "S23": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Arn",
          "LocalMountPath"
        ],
        "members": {
          "Arn": {},
          "LocalMountPath": {}
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "FunctionArn": {},
        "Runtime": {},
        "Role": {},
        "Handler": {},
        "CodeSize": {
          "type": "long"
        },
        "Description": {},
        "Timeout": {
          "type": "integer"
        },
        "MemorySize": {
          "type": "integer"
        },
        "LastModified": {},
        "CodeSha256": {},
        "Version": {},
        "VpcConfig": {
          "type": "structure",
          "members": {
            "SubnetIds": {
              "shape": "S1l"
            },
            "SecurityGroupIds": {
              "shape": "S1n"
            },
            "VpcId": {}
          }
        },
        "DeadLetterConfig": {
          "shape": "S1p"
        },
        "Environment": {
          "type": "structure",
          "members": {
            "Variables": {
              "shape": "S1s"
            },
            "Error": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "Message": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          }
        },
        "KMSKeyArn": {},
        "TracingConfig": {
          "type": "structure",
          "members": {
            "Mode": {}
          }
        },
        "MasterArn": {},
        "RevisionId": {},
        "Layers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Arn": {},
              "CodeSize": {
                "type": "long"
              }
            }
          }
        },
        "State": {},
        "StateReason": {},
        "StateReasonCode": {},
        "LastUpdateStatus": {},
        "LastUpdateStatusReason": {},
        "LastUpdateStatusReasonCode": {},
        "FileSystemConfigs": {
          "shape": "S23"
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "ReservedConcurrentExecutions": {
          "type": "integer"
        }
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "LastModified": {
          "type": "timestamp"
        },
        "FunctionArn": {},
        "MaximumRetryAttempts": {
          "type": "integer"
        },
        "MaximumEventAgeInSeconds": {
          "type": "integer"
        },
        "DestinationConfig": {
          "shape": "S10"
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "Content": {
          "shape": "S3j"
        },
        "LayerArn": {},
        "LayerVersionArn": {},
        "Description": {},
        "CreatedDate": {},
        "Version": {
          "type": "long"
        },
        "CompatibleRuntimes": {
          "shape": "S3l"
        },
        "LicenseInfo": {}
      }
    },
    "S3j": {
      "type": "structure",
      "members": {
        "Location": {},
        "CodeSha256": {},
        "CodeSize": {
          "type": "long"
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {}
    },
    "S4k": {
      "type": "list",
      "member": {
        "shape": "S27"
      }
    },
    "S4p": {
      "type": "structure",
      "members": {
        "LayerVersionArn": {},
        "Version": {
          "type": "long"
        },
        "Description": {},
        "CreatedDate": {},
        "CompatibleRuntimes": {
          "shape": "S3l"
        },
        "LicenseInfo": {}
      }
    }
  }
}
},{}],139:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListAliases": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Aliases"
    },
    "ListEventSourceMappings": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "EventSourceMappings"
    },
    "ListFunctionEventInvokeConfigs": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "FunctionEventInvokeConfigs"
    },
    "ListFunctions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Functions"
    },
    "ListLayerVersions": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "LayerVersions"
    },
    "ListLayers": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Layers"
    },
    "ListProvisionedConcurrencyConfigs": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "ProvisionedConcurrencyConfigs"
    },
    "ListVersionsByFunction": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextMarker",
      "result_key": "Versions"
    }
  }
}
},{}],140:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "FunctionExists": {
      "delay": 1,
      "operation": "GetFunction",
      "maxAttempts": 20,
      "acceptors": [
        {
          "state": "success",
          "matcher": "status",
          "expected": 200
        },
        {
          "state": "retry",
          "matcher": "error",
          "expected": "ResourceNotFoundException"
        }
      ]
    },
    "FunctionActive": {
      "delay": 5,
      "maxAttempts": 60,
      "operation": "GetFunctionConfiguration",
      "description": "Waits for the function's State to be Active.",
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "State",
          "expected": "Active"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "State",
          "expected": "Failed"
        },
        {
          "state": "retry",
          "matcher": "path",
          "argument": "State",
          "expected": "Pending"
        }
      ]
    },
    "FunctionUpdated": {
      "delay": 5,
      "maxAttempts": 60,
      "operation": "GetFunctionConfiguration",
      "description": "Waits for the function's LastUpdateStatus to be Successful.",
      "acceptors": [
        {
          "state": "success",
          "matcher": "path",
          "argument": "LastUpdateStatus",
          "expected": "Successful"
        },
        {
          "state": "failure",
          "matcher": "path",
          "argument": "LastUpdateStatus",
          "expected": "Failed"
        },
        {
          "state": "retry",
          "matcher": "path",
          "argument": "LastUpdateStatus",
          "expected": "InProgress"
        }
      ]
    }
  }
}

},{}],141:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-04-19",
    "endpointPrefix": "models.lex",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Lex Model Building Service",
    "serviceId": "Lex Model Building Service",
    "signatureVersion": "v4",
    "signingName": "lex",
    "uid": "lex-models-2017-04-19"
  },
  "operations": {
    "CreateBotVersion": {
      "http": {
        "requestUri": "/bots/{name}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "checksum": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "intents": {
            "shape": "S6"
          },
          "clarificationPrompt": {
            "shape": "Sa"
          },
          "abortStatement": {
            "shape": "Si"
          },
          "status": {},
          "failureReason": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "voiceId": {},
          "checksum": {},
          "version": {},
          "locale": {},
          "childDirected": {
            "type": "boolean"
          },
          "detectSentiment": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateIntentVersion": {
      "http": {
        "requestUri": "/intents/{name}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "checksum": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "slots": {
            "shape": "Sq"
          },
          "sampleUtterances": {
            "shape": "Sz"
          },
          "confirmationPrompt": {
            "shape": "Sa"
          },
          "rejectionStatement": {
            "shape": "Si"
          },
          "followUpPrompt": {
            "shape": "S10"
          },
          "conclusionStatement": {
            "shape": "Si"
          },
          "dialogCodeHook": {
            "shape": "S11"
          },
          "fulfillmentActivity": {
            "shape": "S14"
          },
          "parentIntentSignature": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "kendraConfiguration": {
            "shape": "S17"
          }
        }
      }
    },
    "CreateSlotTypeVersion": {
      "http": {
        "requestUri": "/slottypes/{name}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "checksum": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "enumerationValues": {
            "shape": "S1e"
          },
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "valueSelectionStrategy": {},
          "parentSlotTypeSignature": {},
          "slotTypeConfigurations": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteBot": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      }
    },
    "DeleteBotAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botName}/aliases/{name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "botName"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "botName": {
            "location": "uri",
            "locationName": "botName"
          }
        }
      }
    },
    "DeleteBotChannelAssociation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botName}/aliases/{aliasName}/channels/{name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "botName",
          "botAlias"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "aliasName"
          }
        }
      }
    },
    "DeleteBotVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{name}/versions/{version}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {
            "location": "uri",
            "locationName": "version"
          }
        }
      }
    },
    "DeleteIntent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/intents/{name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      }
    },
    "DeleteIntentVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/intents/{name}/versions/{version}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {
            "location": "uri",
            "locationName": "version"
          }
        }
      }
    },
    "DeleteSlotType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/slottypes/{name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      }
    },
    "DeleteSlotTypeVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/slottypes/{name}/version/{version}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {
            "location": "uri",
            "locationName": "version"
          }
        }
      }
    },
    "DeleteUtterances": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botName}/utterances/{userId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      }
    },
    "GetBot": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{name}/versions/{versionoralias}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "versionOrAlias"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "versionOrAlias": {
            "location": "uri",
            "locationName": "versionoralias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "intents": {
            "shape": "S6"
          },
          "clarificationPrompt": {
            "shape": "Sa"
          },
          "abortStatement": {
            "shape": "Si"
          },
          "status": {},
          "failureReason": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "voiceId": {},
          "checksum": {},
          "version": {},
          "locale": {},
          "childDirected": {
            "type": "boolean"
          },
          "detectSentiment": {
            "type": "boolean"
          }
        }
      }
    },
    "GetBotAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botName}/aliases/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "botName"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "botName": {
            "location": "uri",
            "locationName": "botName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "botVersion": {},
          "botName": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "checksum": {},
          "conversationLogs": {
            "shape": "S24"
          }
        }
      }
    },
    "GetBotAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botName}/aliases/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botName"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BotAliases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "description": {},
                "botVersion": {},
                "botName": {},
                "lastUpdatedDate": {
                  "type": "timestamp"
                },
                "createdDate": {
                  "type": "timestamp"
                },
                "checksum": {},
                "conversationLogs": {
                  "shape": "S24"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetBotChannelAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botName}/aliases/{aliasName}/channels/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "botName",
          "botAlias"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "aliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "botAlias": {},
          "botName": {},
          "createdDate": {
            "type": "timestamp"
          },
          "type": {},
          "botConfiguration": {
            "shape": "S2m"
          },
          "status": {},
          "failureReason": {}
        }
      }
    },
    "GetBotChannelAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botName}/aliases/{aliasName}/channels/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "aliasName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botChannelAssociations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "description": {},
                "botAlias": {},
                "botName": {},
                "createdDate": {
                  "type": "timestamp"
                },
                "type": {},
                "botConfiguration": {
                  "shape": "S2m"
                },
                "status": {},
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetBotVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{name}/versions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "bots": {
            "shape": "S2v"
          },
          "nextToken": {}
        }
      }
    },
    "GetBots": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "bots": {
            "shape": "S2v"
          },
          "nextToken": {}
        }
      }
    },
    "GetBuiltinIntent": {
      "http": {
        "method": "GET",
        "requestUri": "/builtins/intents/{signature}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "signature"
        ],
        "members": {
          "signature": {
            "location": "uri",
            "locationName": "signature"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "signature": {},
          "supportedLocales": {
            "shape": "S31"
          },
          "slots": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {}
              }
            }
          }
        }
      }
    },
    "GetBuiltinIntents": {
      "http": {
        "method": "GET",
        "requestUri": "/builtins/intents/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "locale": {
            "location": "querystring",
            "locationName": "locale"
          },
          "signatureContains": {
            "location": "querystring",
            "locationName": "signatureContains"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "signature": {},
                "supportedLocales": {
                  "shape": "S31"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetBuiltinSlotTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/builtins/slottypes/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "locale": {
            "location": "querystring",
            "locationName": "locale"
          },
          "signatureContains": {
            "location": "querystring",
            "locationName": "signatureContains"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "signature": {},
                "supportedLocales": {
                  "shape": "S31"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetExport": {
      "http": {
        "method": "GET",
        "requestUri": "/exports/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version",
          "resourceType",
          "exportType"
        ],
        "members": {
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "version": {
            "location": "querystring",
            "locationName": "version"
          },
          "resourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "exportType": {
            "location": "querystring",
            "locationName": "exportType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "version": {},
          "resourceType": {},
          "exportType": {},
          "exportStatus": {},
          "failureReason": {},
          "url": {}
        }
      }
    },
    "GetImport": {
      "http": {
        "method": "GET",
        "requestUri": "/imports/{importId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "importId"
        ],
        "members": {
          "importId": {
            "location": "uri",
            "locationName": "importId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "resourceType": {},
          "mergeStrategy": {},
          "importId": {},
          "importStatus": {},
          "failureReason": {
            "type": "list",
            "member": {}
          },
          "createdDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetIntent": {
      "http": {
        "method": "GET",
        "requestUri": "/intents/{name}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {
            "location": "uri",
            "locationName": "version"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "slots": {
            "shape": "Sq"
          },
          "sampleUtterances": {
            "shape": "Sz"
          },
          "confirmationPrompt": {
            "shape": "Sa"
          },
          "rejectionStatement": {
            "shape": "Si"
          },
          "followUpPrompt": {
            "shape": "S10"
          },
          "conclusionStatement": {
            "shape": "Si"
          },
          "dialogCodeHook": {
            "shape": "S11"
          },
          "fulfillmentActivity": {
            "shape": "S14"
          },
          "parentIntentSignature": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "kendraConfiguration": {
            "shape": "S17"
          }
        }
      }
    },
    "GetIntentVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/intents/{name}/versions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intents": {
            "shape": "S3s"
          },
          "nextToken": {}
        }
      }
    },
    "GetIntents": {
      "http": {
        "method": "GET",
        "requestUri": "/intents/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intents": {
            "shape": "S3s"
          },
          "nextToken": {}
        }
      }
    },
    "GetSlotType": {
      "http": {
        "method": "GET",
        "requestUri": "/slottypes/{name}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {
            "location": "uri",
            "locationName": "version"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "enumerationValues": {
            "shape": "S1e"
          },
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "valueSelectionStrategy": {},
          "parentSlotTypeSignature": {},
          "slotTypeConfigurations": {
            "shape": "S1j"
          }
        }
      }
    },
    "GetSlotTypeVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/slottypes/{name}/versions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypes": {
            "shape": "S40"
          },
          "nextToken": {}
        }
      }
    },
    "GetSlotTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/slottypes/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypes": {
            "shape": "S40"
          },
          "nextToken": {}
        }
      }
    },
    "GetUtterancesView": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botname}/utterances?view=aggregation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botVersions",
          "statusType"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botname"
          },
          "botVersions": {
            "location": "querystring",
            "locationName": "bot_versions",
            "type": "list",
            "member": {}
          },
          "statusType": {
            "location": "querystring",
            "locationName": "status_type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botName": {},
          "utterances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "botVersion": {},
                "utterances": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "utteranceString": {},
                      "count": {
                        "type": "integer"
                      },
                      "distinctUsers": {
                        "type": "integer"
                      },
                      "firstUtteredDate": {
                        "type": "timestamp"
                      },
                      "lastUtteredDate": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S4h"
          }
        }
      }
    },
    "PutBot": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{name}/versions/$LATEST",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "locale",
          "childDirected"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "intents": {
            "shape": "S6"
          },
          "clarificationPrompt": {
            "shape": "Sa"
          },
          "abortStatement": {
            "shape": "Si"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "voiceId": {},
          "checksum": {},
          "processBehavior": {},
          "locale": {},
          "childDirected": {
            "type": "boolean"
          },
          "detectSentiment": {
            "type": "boolean"
          },
          "createVersion": {
            "type": "boolean"
          },
          "tags": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "intents": {
            "shape": "S6"
          },
          "clarificationPrompt": {
            "shape": "Sa"
          },
          "abortStatement": {
            "shape": "Si"
          },
          "status": {},
          "failureReason": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "voiceId": {},
          "checksum": {},
          "version": {},
          "locale": {},
          "childDirected": {
            "type": "boolean"
          },
          "createVersion": {
            "type": "boolean"
          },
          "detectSentiment": {
            "type": "boolean"
          },
          "tags": {
            "shape": "S4h"
          }
        }
      }
    },
    "PutBotAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botName}/aliases/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "botVersion",
          "botName"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "botVersion": {},
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "checksum": {},
          "conversationLogs": {
            "type": "structure",
            "required": [
              "logSettings",
              "iamRoleArn"
            ],
            "members": {
              "logSettings": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "logType",
                    "destination",
                    "resourceArn"
                  ],
                  "members": {
                    "logType": {},
                    "destination": {},
                    "kmsKeyArn": {},
                    "resourceArn": {}
                  }
                }
              },
              "iamRoleArn": {}
            }
          },
          "tags": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "botVersion": {},
          "botName": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "checksum": {},
          "conversationLogs": {
            "shape": "S24"
          },
          "tags": {
            "shape": "S4h"
          }
        }
      }
    },
    "PutIntent": {
      "http": {
        "method": "PUT",
        "requestUri": "/intents/{name}/versions/$LATEST",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "slots": {
            "shape": "Sq"
          },
          "sampleUtterances": {
            "shape": "Sz"
          },
          "confirmationPrompt": {
            "shape": "Sa"
          },
          "rejectionStatement": {
            "shape": "Si"
          },
          "followUpPrompt": {
            "shape": "S10"
          },
          "conclusionStatement": {
            "shape": "Si"
          },
          "dialogCodeHook": {
            "shape": "S11"
          },
          "fulfillmentActivity": {
            "shape": "S14"
          },
          "parentIntentSignature": {},
          "checksum": {},
          "createVersion": {
            "type": "boolean"
          },
          "kendraConfiguration": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "slots": {
            "shape": "Sq"
          },
          "sampleUtterances": {
            "shape": "Sz"
          },
          "confirmationPrompt": {
            "shape": "Sa"
          },
          "rejectionStatement": {
            "shape": "Si"
          },
          "followUpPrompt": {
            "shape": "S10"
          },
          "conclusionStatement": {
            "shape": "Si"
          },
          "dialogCodeHook": {
            "shape": "S11"
          },
          "fulfillmentActivity": {
            "shape": "S14"
          },
          "parentIntentSignature": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "createVersion": {
            "type": "boolean"
          },
          "kendraConfiguration": {
            "shape": "S17"
          }
        }
      }
    },
    "PutSlotType": {
      "http": {
        "method": "PUT",
        "requestUri": "/slottypes/{name}/versions/$LATEST",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "enumerationValues": {
            "shape": "S1e"
          },
          "checksum": {},
          "valueSelectionStrategy": {},
          "createVersion": {
            "type": "boolean"
          },
          "parentSlotTypeSignature": {},
          "slotTypeConfigurations": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "enumerationValues": {
            "shape": "S1e"
          },
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {},
          "checksum": {},
          "valueSelectionStrategy": {},
          "createVersion": {
            "type": "boolean"
          },
          "parentSlotTypeSignature": {},
          "slotTypeConfigurations": {
            "shape": "S1j"
          }
        }
      }
    },
    "StartImport": {
      "http": {
        "requestUri": "/imports/",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "payload",
          "resourceType",
          "mergeStrategy"
        ],
        "members": {
          "payload": {
            "type": "blob"
          },
          "resourceType": {},
          "mergeStrategy": {},
          "tags": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "resourceType": {},
          "mergeStrategy": {},
          "importId": {},
          "importStatus": {},
          "tags": {
            "shape": "S4h"
          },
          "createdDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "intentName",
          "intentVersion"
        ],
        "members": {
          "intentName": {},
          "intentVersion": {}
        }
      }
    },
    "Sa": {
      "type": "structure",
      "required": [
        "messages",
        "maxAttempts"
      ],
      "members": {
        "messages": {
          "shape": "Sb"
        },
        "maxAttempts": {
          "type": "integer"
        },
        "responseCard": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "contentType",
          "content"
        ],
        "members": {
          "contentType": {},
          "content": {},
          "groupNumber": {
            "type": "integer"
          }
        }
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "messages"
      ],
      "members": {
        "messages": {
          "shape": "Sb"
        },
        "responseCard": {}
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "slotConstraint"
        ],
        "members": {
          "name": {},
          "description": {},
          "slotConstraint": {},
          "slotType": {},
          "slotTypeVersion": {},
          "valueElicitationPrompt": {
            "shape": "Sa"
          },
          "priority": {
            "type": "integer"
          },
          "sampleUtterances": {
            "type": "list",
            "member": {}
          },
          "responseCard": {},
          "obfuscationSetting": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S10": {
      "type": "structure",
      "required": [
        "prompt",
        "rejectionStatement"
      ],
      "members": {
        "prompt": {
          "shape": "Sa"
        },
        "rejectionStatement": {
          "shape": "Si"
        }
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "uri",
        "messageVersion"
      ],
      "members": {
        "uri": {},
        "messageVersion": {}
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "codeHook": {
          "shape": "S11"
        }
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "kendraIndex",
        "role"
      ],
      "members": {
        "kendraIndex": {},
        "queryFilterString": {},
        "role": {}
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value"
        ],
        "members": {
          "value": {},
          "synonyms": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "regexConfiguration": {
            "type": "structure",
            "required": [
              "pattern"
            ],
            "members": {
              "pattern": {}
            }
          }
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "logSettings": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "logType": {},
              "destination": {},
              "kmsKeyArn": {},
              "resourceArn": {},
              "resourcePrefix": {}
            }
          }
        },
        "iamRoleArn": {}
      }
    },
    "S2m": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "S2v": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "status": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {}
        }
      }
    },
    "S31": {
      "type": "list",
      "member": {}
    },
    "S3s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {}
        }
      }
    },
    "S40": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "description": {},
          "lastUpdatedDate": {
            "type": "timestamp"
          },
          "createdDate": {
            "type": "timestamp"
          },
          "version": {}
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    }
  }
}
},{}],142:[function(require,module,exports){
module.exports={
  "pagination": {
    "GetBotAliases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetBotChannelAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetBotVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetBots": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetBuiltinIntents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetBuiltinSlotTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetIntentVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetIntents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetSlotTypeVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "GetSlotTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    }
  }
}

},{}],143:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-03-28",
    "endpointPrefix": "logs",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon CloudWatch Logs",
    "serviceId": "CloudWatch Logs",
    "signatureVersion": "v4",
    "targetPrefix": "Logs_20140328",
    "uid": "logs-2014-03-28"
  },
  "operations": {
    "AssociateKmsKey": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "kmsKeyId"
        ],
        "members": {
          "logGroupName": {},
          "kmsKeyId": {}
        }
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {}
        }
      }
    },
    "CreateExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "from",
          "to",
          "destination"
        ],
        "members": {
          "taskName": {},
          "logGroupName": {},
          "logStreamNamePrefix": {},
          "from": {
            "type": "long"
          },
          "to": {
            "type": "long"
          },
          "destination": {},
          "destinationPrefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "CreateLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateLogStream": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {}
        }
      }
    },
    "DeleteDestination": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName"
        ],
        "members": {
          "destinationName": {}
        }
      }
    },
    "DeleteLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      }
    },
    "DeleteLogStream": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {}
        }
      }
    },
    "DeleteMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {}
        }
      }
    },
    "DeleteQueryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "queryDefinitionId"
        ],
        "members": {
          "queryDefinitionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "success": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "policyName": {}
        }
      }
    },
    "DeleteRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      }
    },
    "DeleteSubscriptionFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {}
        }
      }
    },
    "DescribeDestinations": {
      "input": {
        "type": "structure",
        "members": {
          "DestinationNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destinations": {
            "type": "list",
            "member": {
              "shape": "S11"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "taskId": {},
          "statusCode": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportTasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "taskName": {},
                "logGroupName": {},
                "from": {
                  "type": "long"
                },
                "to": {
                  "type": "long"
                },
                "destination": {},
                "destinationPrefix": {},
                "status": {
                  "type": "structure",
                  "members": {
                    "code": {},
                    "message": {}
                  }
                },
                "executionInfo": {
                  "type": "structure",
                  "members": {
                    "creationTime": {
                      "type": "long"
                    },
                    "completionTime": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeLogGroups": {
      "input": {
        "type": "structure",
        "members": {
          "logGroupNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logGroupName": {},
                "creationTime": {
                  "type": "long"
                },
                "retentionInDays": {
                  "type": "integer"
                },
                "metricFilterCount": {
                  "type": "integer"
                },
                "arn": {},
                "storedBytes": {
                  "type": "long"
                },
                "kmsKeyId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeLogStreams": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamNamePrefix": {},
          "orderBy": {},
          "descending": {
            "type": "boolean"
          },
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logStreams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "creationTime": {
                  "type": "long"
                },
                "firstEventTimestamp": {
                  "type": "long"
                },
                "lastEventTimestamp": {
                  "type": "long"
                },
                "lastIngestionTime": {
                  "type": "long"
                },
                "uploadSequenceToken": {},
                "arn": {},
                "storedBytes": {
                  "deprecated": true,
                  "deprecatedMessage": "Starting on June 17, 2019, this parameter will be deprecated for log streams, and will be reported as zero. This change applies only to log streams. The storedBytes parameter for log groups is not affected.",
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeMetricFilters": {
      "input": {
        "type": "structure",
        "members": {
          "logGroupName": {},
          "filterNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "metricName": {},
          "metricNamespace": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "filterName": {},
                "filterPattern": {},
                "metricTransformations": {
                  "shape": "S1z"
                },
                "creationTime": {
                  "type": "long"
                },
                "logGroupName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeQueries": {
      "input": {
        "type": "structure",
        "members": {
          "logGroupName": {},
          "status": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "queries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "queryId": {},
                "queryString": {},
                "status": {},
                "createTime": {
                  "type": "long"
                },
                "logGroupName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeQueryDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "queryDefinitionNamePrefix": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "queryDefinitions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "queryDefinitionId": {},
                "name": {},
                "queryString": {},
                "lastModified": {
                  "type": "long"
                },
                "logGroupNames": {
                  "shape": "S2h"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeResourcePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourcePolicies": {
            "type": "list",
            "member": {
              "shape": "S2l"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeSubscriptionFilters": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "filterNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "subscriptionFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "filterName": {},
                "logGroupName": {},
                "filterPattern": {},
                "destinationArn": {},
                "roleArn": {},
                "distribution": {},
                "creationTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DisassociateKmsKey": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      }
    },
    "FilterLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamNames": {
            "type": "list",
            "member": {}
          },
          "logStreamNamePrefix": {},
          "startTime": {
            "type": "long"
          },
          "endTime": {
            "type": "long"
          },
          "filterPattern": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "interleaved": {
            "deprecated": true,
            "deprecatedMessage": "Starting on June 17, 2019, this parameter will be ignored and the value will be assumed to be true. The response from this operation will always interleave events from multiple log streams within a log group.",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "timestamp": {
                  "type": "long"
                },
                "message": {},
                "ingestionTime": {
                  "type": "long"
                },
                "eventId": {}
              }
            }
          },
          "searchedLogStreams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "searchedCompletely": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {},
          "startTime": {
            "type": "long"
          },
          "endTime": {
            "type": "long"
          },
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "startFromHead": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "timestamp": {
                  "type": "long"
                },
                "message": {},
                "ingestionTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextForwardToken": {},
          "nextBackwardToken": {}
        }
      }
    },
    "GetLogGroupFields": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "time": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logGroupFields": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "percent": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "GetLogRecord": {
      "input": {
        "type": "structure",
        "required": [
          "logRecordPointer"
        ],
        "members": {
          "logRecordPointer": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logRecord": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "GetQueryResults": {
      "input": {
        "type": "structure",
        "required": [
          "queryId"
        ],
        "members": {
          "queryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "results": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "field": {},
                  "value": {}
                }
              }
            }
          },
          "statistics": {
            "type": "structure",
            "members": {
              "recordsMatched": {
                "type": "double"
              },
              "recordsScanned": {
                "type": "double"
              },
              "bytesScanned": {
                "type": "double"
              }
            }
          },
          "status": {}
        }
      }
    },
    "ListTagsLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Se"
          }
        }
      }
    },
    "PutDestination": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "targetArn",
          "roleArn"
        ],
        "members": {
          "destinationName": {},
          "targetArn": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destination": {
            "shape": "S11"
          }
        }
      }
    },
    "PutDestinationPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "accessPolicy"
        ],
        "members": {
          "destinationName": {},
          "accessPolicy": {}
        }
      }
    },
    "PutLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName",
          "logEvents"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {},
          "logEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "timestamp",
                "message"
              ],
              "members": {
                "timestamp": {
                  "type": "long"
                },
                "message": {}
              }
            }
          },
          "sequenceToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextSequenceToken": {},
          "rejectedLogEventsInfo": {
            "type": "structure",
            "members": {
              "tooNewLogEventStartIndex": {
                "type": "integer"
              },
              "tooOldLogEventEndIndex": {
                "type": "integer"
              },
              "expiredLogEventEndIndex": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "PutMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName",
          "filterPattern",
          "metricTransformations"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {},
          "filterPattern": {},
          "metricTransformations": {
            "shape": "S1z"
          }
        }
      }
    },
    "PutQueryDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "queryString"
        ],
        "members": {
          "name": {},
          "queryDefinitionId": {},
          "logGroupNames": {
            "shape": "S2h"
          },
          "queryString": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "queryDefinitionId": {}
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyDocument": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourcePolicy": {
            "shape": "S2l"
          }
        }
      }
    },
    "PutRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "retentionInDays"
        ],
        "members": {
          "logGroupName": {},
          "retentionInDays": {
            "type": "integer"
          }
        }
      }
    },
    "PutSubscriptionFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName",
          "filterPattern",
          "destinationArn"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {},
          "filterPattern": {},
          "destinationArn": {},
          "roleArn": {},
          "distribution": {}
        }
      }
    },
    "StartQuery": {
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime",
          "queryString"
        ],
        "members": {
          "logGroupName": {},
          "logGroupNames": {
            "shape": "S2h"
          },
          "startTime": {
            "type": "long"
          },
          "endTime": {
            "type": "long"
          },
          "queryString": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "queryId": {}
        }
      }
    },
    "StopQuery": {
      "input": {
        "type": "structure",
        "required": [
          "queryId"
        ],
        "members": {
          "queryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "success": {
            "type": "boolean"
          }
        }
      }
    },
    "TagLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "tags"
        ],
        "members": {
          "logGroupName": {},
          "tags": {
            "shape": "Se"
          }
        }
      }
    },
    "TestMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterPattern",
          "logEventMessages"
        ],
        "members": {
          "filterPattern": {},
          "logEventMessages": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "matches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "eventNumber": {
                  "type": "long"
                },
                "eventMessage": {},
                "extractedValues": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          }
        }
      }
    },
    "UntagLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "tags"
        ],
        "members": {
          "logGroupName": {},
          "tags": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  },
  "shapes": {
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S11": {
      "type": "structure",
      "members": {
        "destinationName": {},
        "targetArn": {},
        "roleArn": {},
        "accessPolicy": {},
        "arn": {},
        "creationTime": {
          "type": "long"
        }
      }
    },
    "S1z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "metricName",
          "metricNamespace",
          "metricValue"
        ],
        "members": {
          "metricName": {},
          "metricNamespace": {},
          "metricValue": {},
          "defaultValue": {
            "type": "double"
          }
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {}
    },
    "S2l": {
      "type": "structure",
      "members": {
        "policyName": {},
        "policyDocument": {},
        "lastUpdatedTime": {
          "type": "long"
        }
      }
    }
  }
}
},{}],144:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeDestinations": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "destinations"
    },
    "DescribeLogGroups": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "logGroups"
    },
    "DescribeLogStreams": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "logStreams"
    },
    "DescribeMetricFilters": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "metricFilters"
    },
    "DescribeSubscriptionFilters": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "subscriptionFilters"
    },
    "FilterLogEvents": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": [
        "events",
        "searchedLogStreams"
      ]
    },
    "GetLogEvents": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextForwardToken",
      "result_key": "events"
    }
  }
}
},{}],145:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "machinelearning-2014-12-12",
    "apiVersion": "2014-12-12",
    "endpointPrefix": "machinelearning",
    "jsonVersion": "1.1",
    "serviceFullName": "Amazon Machine Learning",
    "serviceId": "Machine Learning",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonML_20141212",
    "protocol": "json"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags",
          "ResourceId",
          "ResourceType"
        ],
        "members": {
          "Tags": {
            "shape": "S2"
          },
          "ResourceId": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {}
        }
      }
    },
    "CreateBatchPrediction": {
      "input": {
        "type": "structure",
        "required": [
          "BatchPredictionId",
          "MLModelId",
          "BatchPredictionDataSourceId",
          "OutputUri"
        ],
        "members": {
          "BatchPredictionId": {},
          "BatchPredictionName": {},
          "MLModelId": {},
          "BatchPredictionDataSourceId": {},
          "OutputUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BatchPredictionId": {}
        }
      }
    },
    "CreateDataSourceFromRDS": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId",
          "RDSData",
          "RoleARN"
        ],
        "members": {
          "DataSourceId": {},
          "DataSourceName": {},
          "RDSData": {
            "type": "structure",
            "required": [
              "DatabaseInformation",
              "SelectSqlQuery",
              "DatabaseCredentials",
              "S3StagingLocation",
              "ResourceRole",
              "ServiceRole",
              "SubnetId",
              "SecurityGroupIds"
            ],
            "members": {
              "DatabaseInformation": {
                "shape": "Sf"
              },
              "SelectSqlQuery": {},
              "DatabaseCredentials": {
                "type": "structure",
                "required": [
                  "Username",
                  "Password"
                ],
                "members": {
                  "Username": {},
                  "Password": {}
                }
              },
              "S3StagingLocation": {},
              "DataRearrangement": {},
              "DataSchema": {},
              "DataSchemaUri": {},
              "ResourceRole": {},
              "ServiceRole": {},
              "SubnetId": {},
              "SecurityGroupIds": {
                "type": "list",
                "member": {}
              }
            }
          },
          "RoleARN": {},
          "ComputeStatistics": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {}
        }
      }
    },
    "CreateDataSourceFromRedshift": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId",
          "DataSpec",
          "RoleARN"
        ],
        "members": {
          "DataSourceId": {},
          "DataSourceName": {},
          "DataSpec": {
            "type": "structure",
            "required": [
              "DatabaseInformation",
              "SelectSqlQuery",
              "DatabaseCredentials",
              "S3StagingLocation"
            ],
            "members": {
              "DatabaseInformation": {
                "shape": "Sy"
              },
              "SelectSqlQuery": {},
              "DatabaseCredentials": {
                "type": "structure",
                "required": [
                  "Username",
                  "Password"
                ],
                "members": {
                  "Username": {},
                  "Password": {}
                }
              },
              "S3StagingLocation": {},
              "DataRearrangement": {},
              "DataSchema": {},
              "DataSchemaUri": {}
            }
          },
          "RoleARN": {},
          "ComputeStatistics": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {}
        }
      }
    },
    "CreateDataSourceFromS3": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId",
          "DataSpec"
        ],
        "members": {
          "DataSourceId": {},
          "DataSourceName": {},
          "DataSpec": {
            "type": "structure",
            "required": [
              "DataLocationS3"
            ],
            "members": {
              "DataLocationS3": {},
              "DataRearrangement": {},
              "DataSchema": {},
              "DataSchemaLocationS3": {}
            }
          },
          "ComputeStatistics": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {}
        }
      }
    },
    "CreateEvaluation": {
      "input": {
        "type": "structure",
        "required": [
          "EvaluationId",
          "MLModelId",
          "EvaluationDataSourceId"
        ],
        "members": {
          "EvaluationId": {},
          "EvaluationName": {},
          "MLModelId": {},
          "EvaluationDataSourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationId": {}
        }
      }
    },
    "CreateMLModel": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId",
          "MLModelType",
          "TrainingDataSourceId"
        ],
        "members": {
          "MLModelId": {},
          "MLModelName": {},
          "MLModelType": {},
          "Parameters": {
            "shape": "S1d"
          },
          "TrainingDataSourceId": {},
          "Recipe": {},
          "RecipeUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {}
        }
      }
    },
    "CreateRealtimeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId"
        ],
        "members": {
          "MLModelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {},
          "RealtimeEndpointInfo": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteBatchPrediction": {
      "input": {
        "type": "structure",
        "required": [
          "BatchPredictionId"
        ],
        "members": {
          "BatchPredictionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BatchPredictionId": {}
        }
      }
    },
    "DeleteDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId"
        ],
        "members": {
          "DataSourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {}
        }
      }
    },
    "DeleteEvaluation": {
      "input": {
        "type": "structure",
        "required": [
          "EvaluationId"
        ],
        "members": {
          "EvaluationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationId": {}
        }
      }
    },
    "DeleteMLModel": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId"
        ],
        "members": {
          "MLModelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {}
        }
      }
    },
    "DeleteRealtimeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId"
        ],
        "members": {
          "MLModelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {},
          "RealtimeEndpointInfo": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "TagKeys",
          "ResourceId",
          "ResourceType"
        ],
        "members": {
          "TagKeys": {
            "type": "list",
            "member": {}
          },
          "ResourceId": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {}
        }
      }
    },
    "DescribeBatchPredictions": {
      "input": {
        "type": "structure",
        "members": {
          "FilterVariable": {},
          "EQ": {},
          "GT": {},
          "LT": {},
          "GE": {},
          "LE": {},
          "NE": {},
          "Prefix": {},
          "SortOrder": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "BatchPredictionId": {},
                "MLModelId": {},
                "BatchPredictionDataSourceId": {},
                "InputDataLocationS3": {},
                "CreatedByIamUser": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "Name": {},
                "Status": {},
                "OutputUri": {},
                "Message": {},
                "ComputeTime": {
                  "type": "long"
                },
                "FinishedAt": {
                  "type": "timestamp"
                },
                "StartedAt": {
                  "type": "timestamp"
                },
                "TotalRecordCount": {
                  "type": "long"
                },
                "InvalidRecordCount": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDataSources": {
      "input": {
        "type": "structure",
        "members": {
          "FilterVariable": {},
          "EQ": {},
          "GT": {},
          "LT": {},
          "GE": {},
          "LE": {},
          "NE": {},
          "Prefix": {},
          "SortOrder": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DataSourceId": {},
                "DataLocationS3": {},
                "DataRearrangement": {},
                "CreatedByIamUser": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "DataSizeInBytes": {
                  "type": "long"
                },
                "NumberOfFiles": {
                  "type": "long"
                },
                "Name": {},
                "Status": {},
                "Message": {},
                "RedshiftMetadata": {
                  "shape": "S2i"
                },
                "RDSMetadata": {
                  "shape": "S2j"
                },
                "RoleARN": {},
                "ComputeStatistics": {
                  "type": "boolean"
                },
                "ComputeTime": {
                  "type": "long"
                },
                "FinishedAt": {
                  "type": "timestamp"
                },
                "StartedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeEvaluations": {
      "input": {
        "type": "structure",
        "members": {
          "FilterVariable": {},
          "EQ": {},
          "GT": {},
          "LT": {},
          "GE": {},
          "LE": {},
          "NE": {},
          "Prefix": {},
          "SortOrder": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EvaluationId": {},
                "MLModelId": {},
                "EvaluationDataSourceId": {},
                "InputDataLocationS3": {},
                "CreatedByIamUser": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "Name": {},
                "Status": {},
                "PerformanceMetrics": {
                  "shape": "S2q"
                },
                "Message": {},
                "ComputeTime": {
                  "type": "long"
                },
                "FinishedAt": {
                  "type": "timestamp"
                },
                "StartedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMLModels": {
      "input": {
        "type": "structure",
        "members": {
          "FilterVariable": {},
          "EQ": {},
          "GT": {},
          "LT": {},
          "GE": {},
          "LE": {},
          "NE": {},
          "Prefix": {},
          "SortOrder": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MLModelId": {},
                "TrainingDataSourceId": {},
                "CreatedByIamUser": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastUpdatedAt": {
                  "type": "timestamp"
                },
                "Name": {},
                "Status": {},
                "SizeInBytes": {
                  "type": "long"
                },
                "EndpointInfo": {
                  "shape": "S1j"
                },
                "TrainingParameters": {
                  "shape": "S1d"
                },
                "InputDataLocationS3": {},
                "Algorithm": {},
                "MLModelType": {},
                "ScoreThreshold": {
                  "type": "float"
                },
                "ScoreThresholdLastUpdatedAt": {
                  "type": "timestamp"
                },
                "Message": {},
                "ComputeTime": {
                  "type": "long"
                },
                "FinishedAt": {
                  "type": "timestamp"
                },
                "StartedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "ResourceType"
        ],
        "members": {
          "ResourceId": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Tags": {
            "shape": "S2"
          }
        }
      }
    },
    "GetBatchPrediction": {
      "input": {
        "type": "structure",
        "required": [
          "BatchPredictionId"
        ],
        "members": {
          "BatchPredictionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BatchPredictionId": {},
          "MLModelId": {},
          "BatchPredictionDataSourceId": {},
          "InputDataLocationS3": {},
          "CreatedByIamUser": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Name": {},
          "Status": {},
          "OutputUri": {},
          "LogUri": {},
          "Message": {},
          "ComputeTime": {
            "type": "long"
          },
          "FinishedAt": {
            "type": "timestamp"
          },
          "StartedAt": {
            "type": "timestamp"
          },
          "TotalRecordCount": {
            "type": "long"
          },
          "InvalidRecordCount": {
            "type": "long"
          }
        }
      }
    },
    "GetDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId"
        ],
        "members": {
          "DataSourceId": {},
          "Verbose": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {},
          "DataLocationS3": {},
          "DataRearrangement": {},
          "CreatedByIamUser": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "DataSizeInBytes": {
            "type": "long"
          },
          "NumberOfFiles": {
            "type": "long"
          },
          "Name": {},
          "Status": {},
          "LogUri": {},
          "Message": {},
          "RedshiftMetadata": {
            "shape": "S2i"
          },
          "RDSMetadata": {
            "shape": "S2j"
          },
          "RoleARN": {},
          "ComputeStatistics": {
            "type": "boolean"
          },
          "ComputeTime": {
            "type": "long"
          },
          "FinishedAt": {
            "type": "timestamp"
          },
          "StartedAt": {
            "type": "timestamp"
          },
          "DataSourceSchema": {}
        }
      }
    },
    "GetEvaluation": {
      "input": {
        "type": "structure",
        "required": [
          "EvaluationId"
        ],
        "members": {
          "EvaluationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationId": {},
          "MLModelId": {},
          "EvaluationDataSourceId": {},
          "InputDataLocationS3": {},
          "CreatedByIamUser": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Name": {},
          "Status": {},
          "PerformanceMetrics": {
            "shape": "S2q"
          },
          "LogUri": {},
          "Message": {},
          "ComputeTime": {
            "type": "long"
          },
          "FinishedAt": {
            "type": "timestamp"
          },
          "StartedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMLModel": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId"
        ],
        "members": {
          "MLModelId": {},
          "Verbose": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {},
          "TrainingDataSourceId": {},
          "CreatedByIamUser": {},
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastUpdatedAt": {
            "type": "timestamp"
          },
          "Name": {},
          "Status": {},
          "SizeInBytes": {
            "type": "long"
          },
          "EndpointInfo": {
            "shape": "S1j"
          },
          "TrainingParameters": {
            "shape": "S1d"
          },
          "InputDataLocationS3": {},
          "MLModelType": {},
          "ScoreThreshold": {
            "type": "float"
          },
          "ScoreThresholdLastUpdatedAt": {
            "type": "timestamp"
          },
          "LogUri": {},
          "Message": {},
          "ComputeTime": {
            "type": "long"
          },
          "FinishedAt": {
            "type": "timestamp"
          },
          "StartedAt": {
            "type": "timestamp"
          },
          "Recipe": {},
          "Schema": {}
        }
      }
    },
    "Predict": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId",
          "Record",
          "PredictEndpoint"
        ],
        "members": {
          "MLModelId": {},
          "Record": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "PredictEndpoint": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Prediction": {
            "type": "structure",
            "members": {
              "predictedLabel": {},
              "predictedValue": {
                "type": "float"
              },
              "predictedScores": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "float"
                }
              },
              "details": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    },
    "UpdateBatchPrediction": {
      "input": {
        "type": "structure",
        "required": [
          "BatchPredictionId",
          "BatchPredictionName"
        ],
        "members": {
          "BatchPredictionId": {},
          "BatchPredictionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BatchPredictionId": {}
        }
      }
    },
    "UpdateDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "DataSourceId",
          "DataSourceName"
        ],
        "members": {
          "DataSourceId": {},
          "DataSourceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceId": {}
        }
      }
    },
    "UpdateEvaluation": {
      "input": {
        "type": "structure",
        "required": [
          "EvaluationId",
          "EvaluationName"
        ],
        "members": {
          "EvaluationId": {},
          "EvaluationName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationId": {}
        }
      }
    },
    "UpdateMLModel": {
      "input": {
        "type": "structure",
        "required": [
          "MLModelId"
        ],
        "members": {
          "MLModelId": {},
          "MLModelName": {},
          "ScoreThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MLModelId": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "InstanceIdentifier",
        "DatabaseName"
      ],
      "members": {
        "InstanceIdentifier": {},
        "DatabaseName": {}
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "DatabaseName",
        "ClusterIdentifier"
      ],
      "members": {
        "DatabaseName": {},
        "ClusterIdentifier": {}
      }
    },
    "S1d": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1j": {
      "type": "structure",
      "members": {
        "PeakRequestsPerSecond": {
          "type": "integer"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "EndpointUrl": {},
        "EndpointStatus": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "RedshiftDatabase": {
          "shape": "Sy"
        },
        "DatabaseUserName": {},
        "SelectSqlQuery": {}
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "Database": {
          "shape": "Sf"
        },
        "DatabaseUserName": {},
        "SelectSqlQuery": {},
        "ResourceRole": {},
        "ServiceRole": {},
        "DataPipelineId": {}
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "Properties": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    }
  },
  "examples": {}
}
},{}],146:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeBatchPredictions": {
      "limit_key": "Limit",
      "output_token": "NextToken",
      "input_token": "NextToken",
      "result_key": "Results"
    },
    "DescribeDataSources": {
      "limit_key": "Limit",
      "output_token": "NextToken",
      "input_token": "NextToken",
      "result_key": "Results"
    },
    "DescribeEvaluations": {
      "limit_key": "Limit",
      "output_token": "NextToken",
      "input_token": "NextToken",
      "result_key": "Results"
    },
    "DescribeMLModels": {
      "limit_key": "Limit",
      "output_token": "NextToken",
      "input_token": "NextToken",
      "result_key": "Results"
    }
  }
}

},{}],147:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DataSourceAvailable": {
      "delay": 30,
      "operation": "DescribeDataSources",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "COMPLETED",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Results[].Status"
        },
        {
          "expected": "FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Results[].Status"
        }
      ]
    },
    "MLModelAvailable": {
      "delay": 30,
      "operation": "DescribeMLModels",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "COMPLETED",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Results[].Status"
        },
        {
          "expected": "FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Results[].Status"
        }
      ]
    },
    "EvaluationAvailable": {
      "delay": 30,
      "operation": "DescribeEvaluations",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "COMPLETED",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Results[].Status"
        },
        {
          "expected": "FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Results[].Status"
        }
      ]
    },
    "BatchPredictionAvailable": {
      "delay": 30,
      "operation": "DescribeBatchPredictions",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "COMPLETED",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Results[].Status"
        },
        {
          "expected": "FAILED",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Results[].Status"
        }
      ]
    }
  }
}

},{}],148:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-07-01",
    "endpointPrefix": "marketplacecommerceanalytics",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Marketplace Commerce Analytics",
    "serviceId": "Marketplace Commerce Analytics",
    "signatureVersion": "v4",
    "signingName": "marketplacecommerceanalytics",
    "targetPrefix": "MarketplaceCommerceAnalytics20150701",
    "uid": "marketplacecommerceanalytics-2015-07-01"
  },
  "operations": {
    "GenerateDataSet": {
      "input": {
        "type": "structure",
        "required": [
          "dataSetType",
          "dataSetPublicationDate",
          "roleNameArn",
          "destinationS3BucketName",
          "snsTopicArn"
        ],
        "members": {
          "dataSetType": {},
          "dataSetPublicationDate": {
            "type": "timestamp"
          },
          "roleNameArn": {},
          "destinationS3BucketName": {},
          "destinationS3Prefix": {},
          "snsTopicArn": {},
          "customerDefinedValues": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSetRequestId": {}
        }
      }
    },
    "StartSupportDataExport": {
      "input": {
        "type": "structure",
        "required": [
          "dataSetType",
          "fromDate",
          "roleNameArn",
          "destinationS3BucketName",
          "snsTopicArn"
        ],
        "members": {
          "dataSetType": {},
          "fromDate": {
            "type": "timestamp"
          },
          "roleNameArn": {},
          "destinationS3BucketName": {},
          "destinationS3Prefix": {},
          "snsTopicArn": {},
          "customerDefinedValues": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSetRequestId": {}
        }
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}
},{}],149:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],150:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-09-01",
    "endpointPrefix": "data.mediastore",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaStore Data",
    "serviceFullName": "AWS Elemental MediaStore Data Plane",
    "serviceId": "MediaStore Data",
    "signatureVersion": "v4",
    "signingName": "mediastore",
    "uid": "mediastore-data-2017-09-01"
  },
  "operations": {
    "DeleteObject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Path+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Path"
        ],
        "members": {
          "Path": {
            "location": "uri",
            "locationName": "Path"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeObject": {
      "http": {
        "method": "HEAD",
        "requestUri": "/{Path+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Path"
        ],
        "members": {
          "Path": {
            "location": "uri",
            "locationName": "Path"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          }
        }
      }
    },
    "GetObject": {
      "http": {
        "method": "GET",
        "requestUri": "/{Path+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Path"
        ],
        "members": {
          "Path": {
            "location": "uri",
            "locationName": "Path"
          },
          "Range": {
            "location": "header",
            "locationName": "Range"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StatusCode"
        ],
        "members": {
          "Body": {
            "shape": "Se"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentRange": {
            "location": "header",
            "locationName": "Content-Range"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          },
          "StatusCode": {
            "location": "statusCode",
            "type": "integer"
          }
        },
        "payload": "Body"
      }
    },
    "ListItems": {
      "http": {
        "method": "GET"
      },
      "input": {
        "type": "structure",
        "members": {
          "Path": {
            "location": "querystring",
            "locationName": "Path"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "ETag": {},
                "LastModified": {
                  "type": "timestamp"
                },
                "ContentType": {},
                "ContentLength": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Path+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Body",
          "Path"
        ],
        "members": {
          "Body": {
            "shape": "Se"
          },
          "Path": {
            "location": "uri",
            "locationName": "Path"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "UploadAvailability": {
            "location": "header",
            "locationName": "x-amz-upload-availability"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "members": {
          "ContentSHA256": {},
          "ETag": {},
          "StorageClass": {}
        }
      },
      "authtype": "v4-unsigned-body"
    }
  },
  "shapes": {
    "Se": {
      "type": "blob",
      "streaming": true
    }
  }
}
},{}],151:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListItems": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],152:[function(require,module,exports){
module.exports={
  "acm": {
    "name": "ACM",
    "cors": true
  },
  "apigateway": {
    "name": "APIGateway",
    "cors": true
  },
  "applicationautoscaling": {
    "prefix": "application-autoscaling",
    "name": "ApplicationAutoScaling",
    "cors": true
  },
  "appstream": {
    "name": "AppStream"
  },
  "autoscaling": {
    "name": "AutoScaling",
    "cors": true
  },
  "batch": {
    "name": "Batch"
  },
  "budgets": {
    "name": "Budgets"
  },
  "clouddirectory": {
    "name": "CloudDirectory",
    "versions": [
      "2016-05-10*"
    ]
  },
  "cloudformation": {
    "name": "CloudFormation",
    "cors": true
  },
  "cloudfront": {
    "name": "CloudFront",
    "versions": [
      "2013-05-12*",
      "2013-11-11*",
      "2014-05-31*",
      "2014-10-21*",
      "2014-11-06*",
      "2015-04-17*",
      "2015-07-27*",
      "2015-09-17*",
      "2016-01-13*",
      "2016-01-28*",
      "2016-08-01*",
      "2016-08-20*",
      "2016-09-07*",
      "2016-09-29*",
      "2016-11-25*",
      "2017-03-25*",
      "2017-10-30*",
      "2018-06-18*",
      "2018-11-05*"
    ],
    "cors": true
  },
  "cloudhsm": {
    "name": "CloudHSM",
    "cors": true
  },
  "cloudsearch": {
    "name": "CloudSearch"
  },
  "cloudsearchdomain": {
    "name": "CloudSearchDomain"
  },
  "cloudtrail": {
    "name": "CloudTrail",
    "cors": true
  },
  "cloudwatch": {
    "prefix": "monitoring",
    "name": "CloudWatch",
    "cors": true
  },
  "cloudwatchevents": {
    "prefix": "events",
    "name": "CloudWatchEvents",
    "versions": [
      "2014-02-03*"
    ],
    "cors": true
  },
  "cloudwatchlogs": {
    "prefix": "logs",
    "name": "CloudWatchLogs",
    "cors": true
  },
  "codebuild": {
    "name": "CodeBuild",
    "cors": true
  },
  "codecommit": {
    "name": "CodeCommit",
    "cors": true
  },
  "codedeploy": {
    "name": "CodeDeploy",
    "cors": true
  },
  "codepipeline": {
    "name": "CodePipeline",
    "cors": true
  },
  "cognitoidentity": {
    "prefix": "cognito-identity",
    "name": "CognitoIdentity",
    "cors": true
  },
  "cognitoidentityserviceprovider": {
    "prefix": "cognito-idp",
    "name": "CognitoIdentityServiceProvider",
    "cors": true
  },
  "cognitosync": {
    "prefix": "cognito-sync",
    "name": "CognitoSync",
    "cors": true
  },
  "configservice": {
    "prefix": "config",
    "name": "ConfigService",
    "cors": true
  },
  "cur": {
    "name": "CUR",
    "cors": true
  },
  "datapipeline": {
    "name": "DataPipeline"
  },
  "devicefarm": {
    "name": "DeviceFarm",
    "cors": true
  },
  "directconnect": {
    "name": "DirectConnect",
    "cors": true
  },
  "directoryservice": {
    "prefix": "ds",
    "name": "DirectoryService"
  },
  "discovery": {
    "name": "Discovery"
  },
  "dms": {
    "name": "DMS"
  },
  "dynamodb": {
    "name": "DynamoDB",
    "cors": true
  },
  "dynamodbstreams": {
    "prefix": "streams.dynamodb",
    "name": "DynamoDBStreams",
    "cors": true
  },
  "ec2": {
    "name": "EC2",
    "versions": [
      "2013-06-15*",
      "2013-10-15*",
      "2014-02-01*",
      "2014-05-01*",
      "2014-06-15*",
      "2014-09-01*",
      "2014-10-01*",
      "2015-03-01*",
      "2015-04-15*",
      "2015-10-01*",
      "2016-04-01*",
      "2016-09-15*"
    ],
    "cors": true
  },
  "ecr": {
    "name": "ECR",
    "cors": true
  },
  "ecs": {
    "name": "ECS",
    "cors": true
  },
  "efs": {
    "prefix": "elasticfilesystem",
    "name": "EFS",
    "cors": true
  },
  "elasticache": {
    "name": "ElastiCache",
    "versions": [
      "2012-11-15*",
      "2014-03-24*",
      "2014-07-15*",
      "2014-09-30*"
    ],
    "cors": true
  },
  "elasticbeanstalk": {
    "name": "ElasticBeanstalk",
    "cors": true
  },
  "elb": {
    "prefix": "elasticloadbalancing",
    "name": "ELB",
    "cors": true
  },
  "elbv2": {
    "prefix": "elasticloadbalancingv2",
    "name": "ELBv2",
    "cors": true
  },
  "emr": {
    "prefix": "elasticmapreduce",
    "name": "EMR",
    "cors": true
  },
  "es": {
    "name": "ES"
  },
  "elastictranscoder": {
    "name": "ElasticTranscoder",
    "cors": true
  },
  "firehose": {
    "name": "Firehose",
    "cors": true
  },
  "gamelift": {
    "name": "GameLift",
    "cors": true
  },
  "glacier": {
    "name": "Glacier"
  },
  "health": {
    "name": "Health"
  },
  "iam": {
    "name": "IAM",
    "cors": true
  },
  "importexport": {
    "name": "ImportExport"
  },
  "inspector": {
    "name": "Inspector",
    "versions": [
      "2015-08-18*"
    ],
    "cors": true
  },
  "iot": {
    "name": "Iot",
    "cors": true
  },
  "iotdata": {
    "prefix": "iot-data",
    "name": "IotData",
    "cors": true
  },
  "kinesis": {
    "name": "Kinesis",
    "cors": true
  },
  "kinesisanalytics": {
    "name": "KinesisAnalytics"
  },
  "kms": {
    "name": "KMS",
    "cors": true
  },
  "lambda": {
    "name": "Lambda",
    "cors": true
  },
  "lexruntime": {
    "prefix": "runtime.lex",
    "name": "LexRuntime",
    "cors": true
  },
  "lightsail": {
    "name": "Lightsail"
  },
  "machinelearning": {
    "name": "MachineLearning",
    "cors": true
  },
  "marketplacecommerceanalytics": {
    "name": "MarketplaceCommerceAnalytics",
    "cors": true
  },
  "marketplacemetering": {
    "prefix": "meteringmarketplace",
    "name": "MarketplaceMetering"
  },
  "mturk": {
    "prefix": "mturk-requester",
    "name": "MTurk",
    "cors": true
  },
  "mobileanalytics": {
    "name": "MobileAnalytics",
    "cors": true
  },
  "opsworks": {
    "name": "OpsWorks",
    "cors": true
  },
  "opsworkscm": {
    "name": "OpsWorksCM"
  },
  "organizations": {
    "name": "Organizations"
  },
  "pinpoint": {
    "name": "Pinpoint"
  },
  "polly": {
    "name": "Polly",
    "cors": true
  },
  "rds": {
    "name": "RDS",
    "versions": [
      "2014-09-01*"
    ],
    "cors": true
  },
  "redshift": {
    "name": "Redshift",
    "cors": true
  },
  "rekognition": {
    "name": "Rekognition",
    "cors": true
  },
  "resourcegroupstaggingapi": {
    "name": "ResourceGroupsTaggingAPI"
  },
  "route53": {
    "name": "Route53",
    "cors": true
  },
  "route53domains": {
    "name": "Route53Domains",
    "cors": true
  },
  "s3": {
    "name": "S3",
    "dualstackAvailable": true,
    "cors": true
  },
  "s3control": {
    "name": "S3Control",
    "dualstackAvailable": true,
    "xmlNoDefaultLists": true
  },
  "servicecatalog": {
    "name": "ServiceCatalog",
    "cors": true
  },
  "ses": {
    "prefix": "email",
    "name": "SES",
    "cors": true
  },
  "shield": {
    "name": "Shield"
  },
  "simpledb": {
    "prefix": "sdb",
    "name": "SimpleDB"
  },
  "sms": {
    "name": "SMS"
  },
  "snowball": {
    "name": "Snowball"
  },
  "sns": {
    "name": "SNS",
    "cors": true
  },
  "sqs": {
    "name": "SQS",
    "cors": true
  },
  "ssm": {
    "name": "SSM",
    "cors": true
  },
  "storagegateway": {
    "name": "StorageGateway",
    "cors": true
  },
  "stepfunctions": {
    "prefix": "states",
    "name": "StepFunctions"
  },
  "sts": {
    "name": "STS",
    "cors": true
  },
  "support": {
    "name": "Support"
  },
  "swf": {
    "name": "SWF"
  },
  "xray": {
    "name": "XRay",
    "cors": true
  },
  "waf": {
    "name": "WAF",
    "cors": true
  },
  "wafregional": {
    "prefix": "waf-regional",
    "name": "WAFRegional"
  },
  "workdocs": {
    "name": "WorkDocs",
    "cors": true
  },
  "workspaces": {
    "name": "WorkSpaces"
  },
  "codestar": {
    "name": "CodeStar"
  },
  "lexmodelbuildingservice": {
    "prefix": "lex-models",
    "name": "LexModelBuildingService",
    "cors": true
  },
  "marketplaceentitlementservice": {
    "prefix": "entitlement.marketplace",
    "name": "MarketplaceEntitlementService"
  },
  "athena": {
    "name": "Athena"
  },
  "greengrass": {
    "name": "Greengrass"
  },
  "dax": {
    "name": "DAX"
  },
  "migrationhub": {
    "prefix": "AWSMigrationHub",
    "name": "MigrationHub"
  },
  "cloudhsmv2": {
    "name": "CloudHSMV2"
  },
  "glue": {
    "name": "Glue"
  },
  "mobile": {
    "name": "Mobile"
  },
  "pricing": {
    "name": "Pricing",
    "cors": true
  },
  "costexplorer": {
    "prefix": "ce",
    "name": "CostExplorer",
    "cors": true
  },
  "mediaconvert": {
    "name": "MediaConvert"
  },
  "medialive": {
    "name": "MediaLive"
  },
  "mediapackage": {
    "name": "MediaPackage"
  },
  "mediastore": {
    "name": "MediaStore"
  },
  "mediastoredata": {
    "prefix": "mediastore-data",
    "name": "MediaStoreData",
    "cors": true
  },
  "appsync": {
    "name": "AppSync"
  },
  "guardduty": {
    "name": "GuardDuty"
  },
  "mq": {
    "name": "MQ"
  },
  "comprehend": {
    "name": "Comprehend",
    "cors": true
  },
  "iotjobsdataplane": {
    "prefix": "iot-jobs-data",
    "name": "IoTJobsDataPlane"
  },
  "kinesisvideoarchivedmedia": {
    "prefix": "kinesis-video-archived-media",
    "name": "KinesisVideoArchivedMedia",
    "cors": true
  },
  "kinesisvideomedia": {
    "prefix": "kinesis-video-media",
    "name": "KinesisVideoMedia",
    "cors": true
  },
  "kinesisvideo": {
    "name": "KinesisVideo",
    "cors": true
  },
  "sagemakerruntime": {
    "prefix": "runtime.sagemaker",
    "name": "SageMakerRuntime"
  },
  "sagemaker": {
    "name": "SageMaker"
  },
  "translate": {
    "name": "Translate",
    "cors": true
  },
  "resourcegroups": {
    "prefix": "resource-groups",
    "name": "ResourceGroups",
    "cors": true
  },
  "alexaforbusiness": {
    "name": "AlexaForBusiness"
  },
  "cloud9": {
    "name": "Cloud9"
  },
  "serverlessapplicationrepository": {
    "prefix": "serverlessrepo",
    "name": "ServerlessApplicationRepository"
  },
  "servicediscovery": {
    "name": "ServiceDiscovery"
  },
  "workmail": {
    "name": "WorkMail"
  },
  "autoscalingplans": {
    "prefix": "autoscaling-plans",
    "name": "AutoScalingPlans"
  },
  "transcribeservice": {
    "prefix": "transcribe",
    "name": "TranscribeService"
  },
  "connect": {
    "name": "Connect",
    "cors": true
  },
  "acmpca": {
    "prefix": "acm-pca",
    "name": "ACMPCA"
  },
  "fms": {
    "name": "FMS"
  },
  "secretsmanager": {
    "name": "SecretsManager",
    "cors": true
  },
  "iotanalytics": {
    "name": "IoTAnalytics",
    "cors": true
  },
  "iot1clickdevicesservice": {
    "prefix": "iot1click-devices",
    "name": "IoT1ClickDevicesService"
  },
  "iot1clickprojects": {
    "prefix": "iot1click-projects",
    "name": "IoT1ClickProjects"
  },
  "pi": {
    "name": "PI"
  },
  "neptune": {
    "name": "Neptune"
  },
  "mediatailor": {
    "name": "MediaTailor"
  },
  "eks": {
    "name": "EKS"
  },
  "macie": {
    "name": "Macie"
  },
  "dlm": {
    "name": "DLM"
  },
  "signer": {
    "name": "Signer"
  },
  "chime": {
    "name": "Chime"
  },
  "pinpointemail": {
    "prefix": "pinpoint-email",
    "name": "PinpointEmail"
  },
  "ram": {
    "name": "RAM"
  },
  "route53resolver": {
    "name": "Route53Resolver"
  },
  "pinpointsmsvoice": {
    "prefix": "sms-voice",
    "name": "PinpointSMSVoice"
  },
  "quicksight": {
    "name": "QuickSight"
  },
  "rdsdataservice": {
    "prefix": "rds-data",
    "name": "RDSDataService"
  },
  "amplify": {
    "name": "Amplify"
  },
  "datasync": {
    "name": "DataSync"
  },
  "robomaker": {
    "name": "RoboMaker"
  },
  "transfer": {
    "name": "Transfer"
  },
  "globalaccelerator": {
    "name": "GlobalAccelerator"
  },
  "comprehendmedical": {
    "name": "ComprehendMedical",
    "cors": true
  },
  "kinesisanalyticsv2": {
    "name": "KinesisAnalyticsV2"
  },
  "mediaconnect": {
    "name": "MediaConnect"
  },
  "fsx": {
    "name": "FSx"
  },
  "securityhub": {
    "name": "SecurityHub"
  },
  "appmesh": {
    "name": "AppMesh",
    "versions": [
      "2018-10-01*"
    ]
  },
  "licensemanager": {
    "prefix": "license-manager",
    "name": "LicenseManager"
  },
  "kafka": {
    "name": "Kafka"
  },
  "apigatewaymanagementapi": {
    "name": "ApiGatewayManagementApi"
  },
  "apigatewayv2": {
    "name": "ApiGatewayV2"
  },
  "docdb": {
    "name": "DocDB"
  },
  "backup": {
    "name": "Backup"
  },
  "worklink": {
    "name": "WorkLink"
  },
  "textract": {
    "name": "Textract"
  },
  "managedblockchain": {
    "name": "ManagedBlockchain"
  },
  "mediapackagevod": {
    "prefix": "mediapackage-vod",
    "name": "MediaPackageVod"
  },
  "groundstation": {
    "name": "GroundStation"
  },
  "iotthingsgraph": {
    "name": "IoTThingsGraph"
  },
  "iotevents": {
    "name": "IoTEvents"
  },
  "ioteventsdata": {
    "prefix": "iotevents-data",
    "name": "IoTEventsData"
  },
  "personalize": {
    "name": "Personalize",
    "cors": true
  },
  "personalizeevents": {
    "prefix": "personalize-events",
    "name": "PersonalizeEvents",
    "cors": true
  },
  "personalizeruntime": {
    "prefix": "personalize-runtime",
    "name": "PersonalizeRuntime",
    "cors": true
  },
  "applicationinsights": {
    "prefix": "application-insights",
    "name": "ApplicationInsights"
  },
  "servicequotas": {
    "prefix": "service-quotas",
    "name": "ServiceQuotas"
  },
  "ec2instanceconnect": {
    "prefix": "ec2-instance-connect",
    "name": "EC2InstanceConnect"
  },
  "eventbridge": {
    "name": "EventBridge"
  },
  "lakeformation": {
    "name": "LakeFormation"
  },
  "forecastservice": {
    "prefix": "forecast",
    "name": "ForecastService",
    "cors": true
  },
  "forecastqueryservice": {
    "prefix": "forecastquery",
    "name": "ForecastQueryService",
    "cors": true
  },
  "qldb": {
    "name": "QLDB"
  },
  "qldbsession": {
    "prefix": "qldb-session",
    "name": "QLDBSession"
  },
  "workmailmessageflow": {
    "name": "WorkMailMessageFlow"
  },
  "codestarnotifications": {
    "prefix": "codestar-notifications",
    "name": "CodeStarNotifications"
  },
  "savingsplans": {
    "name": "SavingsPlans"
  },
  "sso": {
    "name": "SSO"
  },
  "ssooidc": {
    "prefix": "sso-oidc",
    "name": "SSOOIDC"
  },
  "marketplacecatalog": {
    "prefix": "marketplace-catalog",
    "name": "MarketplaceCatalog"
  },
  "dataexchange": {
    "name": "DataExchange"
  },
  "sesv2": {
    "name": "SESV2"
  },
  "migrationhubconfig": {
    "prefix": "migrationhub-config",
    "name": "MigrationHubConfig"
  },
  "connectparticipant": {
    "name": "ConnectParticipant"
  },
  "appconfig": {
    "name": "AppConfig"
  },
  "iotsecuretunneling": {
    "name": "IoTSecureTunneling"
  },
  "wafv2": {
    "name": "WAFV2"
  },
  "elasticinference": {
    "prefix": "elastic-inference",
    "name": "ElasticInference"
  },
  "imagebuilder": {
    "name": "Imagebuilder"
  },
  "schemas": {
    "name": "Schemas"
  },
  "accessanalyzer": {
    "name": "AccessAnalyzer"
  },
  "codegurureviewer": {
    "prefix": "codeguru-reviewer",
    "name": "CodeGuruReviewer"
  },
  "codeguruprofiler": {
    "name": "CodeGuruProfiler"
  },
  "computeoptimizer": {
    "prefix": "compute-optimizer",
    "name": "ComputeOptimizer"
  },
  "frauddetector": {
    "name": "FraudDetector"
  },
  "kendra": {
    "name": "Kendra"
  },
  "networkmanager": {
    "name": "NetworkManager"
  },
  "outposts": {
    "name": "Outposts"
  },
  "augmentedairuntime": {
    "prefix": "sagemaker-a2i-runtime",
    "name": "AugmentedAIRuntime"
  },
  "ebs": {
    "name": "EBS"
  },
  "kinesisvideosignalingchannels": {
    "prefix": "kinesis-video-signaling",
    "name": "KinesisVideoSignalingChannels",
    "cors": true
  },
  "detective": {
    "name": "Detective"
  },
  "codestarconnections": {
    "prefix": "codestar-connections",
    "name": "CodeStarconnections"
  },
  "synthetics": {
    "name": "Synthetics"
  },
  "iotsitewise": {
    "name": "IoTSiteWise"
  },
  "macie2": {
    "name": "Macie2"
  },
  "codeartifact": {
    "name": "CodeArtifact"
  }
}
},{}],153:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-06-05",
    "endpointPrefix": "mobileanalytics",
    "serviceFullName": "Amazon Mobile Analytics",
    "serviceId": "Mobile Analytics",
    "signatureVersion": "v4",
    "protocol": "rest-json"
  },
  "operations": {
    "PutEvents": {
      "http": {
        "requestUri": "/2014-06-05/events",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "events",
          "clientContext"
        ],
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "eventType",
                "timestamp"
              ],
              "members": {
                "eventType": {},
                "timestamp": {},
                "session": {
                  "type": "structure",
                  "members": {
                    "id": {},
                    "duration": {
                      "type": "long"
                    },
                    "startTimestamp": {},
                    "stopTimestamp": {}
                  }
                },
                "version": {},
                "attributes": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "metrics": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "clientContext": {
            "location": "header",
            "locationName": "x-amz-Client-Context"
          },
          "clientContextEncoding": {
            "location": "header",
            "locationName": "x-amz-Client-Context-Encoding"
          }
        }
      }
    }
  },
  "shapes": {}
}
},{}],154:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-08-01",
    "endpointPrefix": "monitoring",
    "protocol": "query",
    "serviceAbbreviation": "CloudWatch",
    "serviceFullName": "Amazon CloudWatch",
    "serviceId": "CloudWatch",
    "signatureVersion": "v4",
    "uid": "monitoring-2010-08-01",
    "xmlNamespace": "http://monitoring.amazonaws.com/doc/2010-08-01/"
  },
  "operations": {
    "DeleteAlarms": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmNames"
        ],
        "members": {
          "AlarmNames": {
            "shape": "S2"
          }
        }
      }
    },
    "DeleteAnomalyDetector": {
      "input": {
        "type": "structure",
        "required": [
          "Namespace",
          "MetricName",
          "Stat"
        ],
        "members": {
          "Namespace": {},
          "MetricName": {},
          "Dimensions": {
            "shape": "S7"
          },
          "Stat": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteAnomalyDetectorResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDashboards": {
      "input": {
        "type": "structure",
        "required": [
          "DashboardNames"
        ],
        "members": {
          "DashboardNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteDashboardsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInsightRules": {
      "input": {
        "type": "structure",
        "required": [
          "RuleNames"
        ],
        "members": {
          "RuleNames": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteInsightRulesResult",
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sl"
          }
        }
      }
    },
    "DescribeAlarmHistory": {
      "input": {
        "type": "structure",
        "members": {
          "AlarmName": {},
          "AlarmTypes": {
            "shape": "Ss"
          },
          "HistoryItemType": {},
          "StartDate": {
            "type": "timestamp"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {},
          "ScanBy": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAlarmHistoryResult",
        "type": "structure",
        "members": {
          "AlarmHistoryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AlarmName": {},
                "AlarmType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "HistoryItemType": {},
                "HistorySummary": {},
                "HistoryData": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAlarms": {
      "input": {
        "type": "structure",
        "members": {
          "AlarmNames": {
            "shape": "S2"
          },
          "AlarmNamePrefix": {},
          "AlarmTypes": {
            "shape": "Ss"
          },
          "ChildrenOfAlarmName": {},
          "ParentsOfAlarmName": {},
          "StateValue": {},
          "ActionPrefix": {},
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAlarmsResult",
        "type": "structure",
        "members": {
          "CompositeAlarms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActionsEnabled": {
                  "type": "boolean"
                },
                "AlarmActions": {
                  "shape": "S1c"
                },
                "AlarmArn": {},
                "AlarmConfigurationUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "AlarmDescription": {},
                "AlarmName": {},
                "AlarmRule": {},
                "InsufficientDataActions": {
                  "shape": "S1c"
                },
                "OKActions": {
                  "shape": "S1c"
                },
                "StateReason": {},
                "StateReasonData": {},
                "StateUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "StateValue": {}
              },
              "xmlOrder": [
                "ActionsEnabled",
                "AlarmActions",
                "AlarmArn",
                "AlarmConfigurationUpdatedTimestamp",
                "AlarmDescription",
                "AlarmName",
                "AlarmRule",
                "InsufficientDataActions",
                "OKActions",
                "StateReason",
                "StateReasonData",
                "StateUpdatedTimestamp",
                "StateValue"
              ]
            }
          },
          "MetricAlarms": {
            "shape": "S1j"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAlarmsForMetric": {
      "input": {
        "type": "structure",
        "required": [
          "MetricName",
          "Namespace"
        ],
        "members": {
          "MetricName": {},
          "Namespace": {},
          "Statistic": {},
          "ExtendedStatistic": {},
          "Dimensions": {
            "shape": "S7"
          },
          "Period": {
            "type": "integer"
          },
          "Unit": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAlarmsForMetricResult",
        "type": "structure",
        "members": {
          "MetricAlarms": {
            "shape": "S1j"
          }
        }
      }
    },
    "DescribeAnomalyDetectors": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Namespace": {},
          "MetricName": {},
          "Dimensions": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAnomalyDetectorsResult",
        "type": "structure",
        "members": {
          "AnomalyDetectors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Namespace": {},
                "MetricName": {},
                "Dimensions": {
                  "shape": "S7"
                },
                "Stat": {},
                "Configuration": {
                  "shape": "S2b"
                },
                "StateValue": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInsightRules": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeInsightRulesResult",
        "type": "structure",
        "members": {
          "NextToken": {},
          "InsightRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "State",
                "Schema",
                "Definition"
              ],
              "members": {
                "Name": {},
                "State": {},
                "Schema": {},
                "Definition": {}
              }
            }
          }
        }
      }
    },
    "DisableAlarmActions": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmNames"
        ],
        "members": {
          "AlarmNames": {
            "shape": "S2"
          }
        }
      }
    },
    "DisableInsightRules": {
      "input": {
        "type": "structure",
        "required": [
          "RuleNames"
        ],
        "members": {
          "RuleNames": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "resultWrapper": "DisableInsightRulesResult",
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sl"
          }
        }
      }
    },
    "EnableAlarmActions": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmNames"
        ],
        "members": {
          "AlarmNames": {
            "shape": "S2"
          }
        }
      }
    },
    "EnableInsightRules": {
      "input": {
        "type": "structure",
        "required": [
          "RuleNames"
        ],
        "members": {
          "RuleNames": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "resultWrapper": "EnableInsightRulesResult",
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetDashboard": {
      "input": {
        "type": "structure",
        "required": [
          "DashboardName"
        ],
        "members": {
          "DashboardName": {}
        }
      },
      "output": {
        "resultWrapper": "GetDashboardResult",
        "type": "structure",
        "members": {
          "DashboardArn": {},
          "DashboardBody": {},
          "DashboardName": {}
        }
      }
    },
    "GetInsightRuleReport": {
      "input": {
        "type": "structure",
        "required": [
          "RuleName",
          "StartTime",
          "EndTime",
          "Period"
        ],
        "members": {
          "RuleName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Period": {
            "type": "integer"
          },
          "MaxContributorCount": {
            "type": "integer"
          },
          "Metrics": {
            "type": "list",
            "member": {}
          },
          "OrderBy": {}
        }
      },
      "output": {
        "resultWrapper": "GetInsightRuleReportResult",
        "type": "structure",
        "members": {
          "KeyLabels": {
            "type": "list",
            "member": {}
          },
          "AggregationStatistic": {},
          "AggregateValue": {
            "type": "double"
          },
          "ApproximateUniqueCount": {
            "type": "long"
          },
          "Contributors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Keys",
                "ApproximateAggregateValue",
                "Datapoints"
              ],
              "members": {
                "Keys": {
                  "type": "list",
                  "member": {}
                },
                "ApproximateAggregateValue": {
                  "type": "double"
                },
                "Datapoints": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Timestamp",
                      "ApproximateValue"
                    ],
                    "members": {
                      "Timestamp": {
                        "type": "timestamp"
                      },
                      "ApproximateValue": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "MetricDatapoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Timestamp"
              ],
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "UniqueContributors": {
                  "type": "double"
                },
                "MaxContributorValue": {
                  "type": "double"
                },
                "SampleCount": {
                  "type": "double"
                },
                "Average": {
                  "type": "double"
                },
                "Sum": {
                  "type": "double"
                },
                "Minimum": {
                  "type": "double"
                },
                "Maximum": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    },
    "GetMetricData": {
      "input": {
        "type": "structure",
        "required": [
          "MetricDataQueries",
          "StartTime",
          "EndTime"
        ],
        "members": {
          "MetricDataQueries": {
            "shape": "S1v"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {},
          "ScanBy": {},
          "MaxDatapoints": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "GetMetricDataResult",
        "type": "structure",
        "members": {
          "MetricDataResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Label": {},
                "Timestamps": {
                  "type": "list",
                  "member": {
                    "type": "timestamp"
                  }
                },
                "Values": {
                  "type": "list",
                  "member": {
                    "type": "double"
                  }
                },
                "StatusCode": {},
                "Messages": {
                  "shape": "S3q"
                }
              }
            }
          },
          "NextToken": {},
          "Messages": {
            "shape": "S3q"
          }
        }
      }
    },
    "GetMetricStatistics": {
      "input": {
        "type": "structure",
        "required": [
          "Namespace",
          "MetricName",
          "StartTime",
          "EndTime",
          "Period"
        ],
        "members": {
          "Namespace": {},
          "MetricName": {},
          "Dimensions": {
            "shape": "S7"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Period": {
            "type": "integer"
          },
          "Statistics": {
            "type": "list",
            "member": {}
          },
          "ExtendedStatistics": {
            "type": "list",
            "member": {}
          },
          "Unit": {}
        }
      },
      "output": {
        "resultWrapper": "GetMetricStatisticsResult",
        "type": "structure",
        "members": {
          "Label": {},
          "Datapoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "SampleCount": {
                  "type": "double"
                },
                "Average": {
                  "type": "double"
                },
                "Sum": {
                  "type": "double"
                },
                "Minimum": {
                  "type": "double"
                },
                "Maximum": {
                  "type": "double"
                },
                "Unit": {},
                "ExtendedStatistics": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "double"
                  }
                }
              },
              "xmlOrder": [
                "Timestamp",
                "SampleCount",
                "Average",
                "Sum",
                "Minimum",
                "Maximum",
                "Unit",
                "ExtendedStatistics"
              ]
            }
          }
        }
      }
    },
    "GetMetricWidgetImage": {
      "input": {
        "type": "structure",
        "required": [
          "MetricWidget"
        ],
        "members": {
          "MetricWidget": {},
          "OutputFormat": {}
        }
      },
      "output": {
        "resultWrapper": "GetMetricWidgetImageResult",
        "type": "structure",
        "members": {
          "MetricWidgetImage": {
            "type": "blob"
          }
        }
      }
    },
    "ListDashboards": {
      "input": {
        "type": "structure",
        "members": {
          "DashboardNamePrefix": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListDashboardsResult",
        "type": "structure",
        "members": {
          "DashboardEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DashboardName": {},
                "DashboardArn": {},
                "LastModified": {
                  "type": "timestamp"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMetrics": {
      "input": {
        "type": "structure",
        "members": {
          "Namespace": {},
          "MetricName": {},
          "Dimensions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListMetricsResult",
        "type": "structure",
        "members": {
          "Metrics": {
            "type": "list",
            "member": {
              "shape": "S1z"
            }
          },
          "NextToken": {}
        },
        "xmlOrder": [
          "Metrics",
          "NextToken"
        ]
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S4l"
          }
        }
      }
    },
    "PutAnomalyDetector": {
      "input": {
        "type": "structure",
        "required": [
          "Namespace",
          "MetricName",
          "Stat"
        ],
        "members": {
          "Namespace": {},
          "MetricName": {},
          "Dimensions": {
            "shape": "S7"
          },
          "Stat": {},
          "Configuration": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "resultWrapper": "PutAnomalyDetectorResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutCompositeAlarm": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmName",
          "AlarmRule"
        ],
        "members": {
          "ActionsEnabled": {
            "type": "boolean"
          },
          "AlarmActions": {
            "shape": "S1c"
          },
          "AlarmDescription": {},
          "AlarmName": {},
          "AlarmRule": {},
          "InsufficientDataActions": {
            "shape": "S1c"
          },
          "OKActions": {
            "shape": "S1c"
          },
          "Tags": {
            "shape": "S4l"
          }
        }
      }
    },
    "PutDashboard": {
      "input": {
        "type": "structure",
        "required": [
          "DashboardName",
          "DashboardBody"
        ],
        "members": {
          "DashboardName": {},
          "DashboardBody": {}
        }
      },
      "output": {
        "resultWrapper": "PutDashboardResult",
        "type": "structure",
        "members": {
          "DashboardValidationMessages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DataPath": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "PutInsightRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleName",
          "RuleDefinition"
        ],
        "members": {
          "RuleName": {},
          "RuleState": {},
          "RuleDefinition": {},
          "Tags": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "resultWrapper": "PutInsightRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutMetricAlarm": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmName",
          "EvaluationPeriods",
          "ComparisonOperator"
        ],
        "members": {
          "AlarmName": {},
          "AlarmDescription": {},
          "ActionsEnabled": {
            "type": "boolean"
          },
          "OKActions": {
            "shape": "S1c"
          },
          "AlarmActions": {
            "shape": "S1c"
          },
          "InsufficientDataActions": {
            "shape": "S1c"
          },
          "MetricName": {},
          "Namespace": {},
          "Statistic": {},
          "ExtendedStatistic": {},
          "Dimensions": {
            "shape": "S7"
          },
          "Period": {
            "type": "integer"
          },
          "Unit": {},
          "EvaluationPeriods": {
            "type": "integer"
          },
          "DatapointsToAlarm": {
            "type": "integer"
          },
          "Threshold": {
            "type": "double"
          },
          "ComparisonOperator": {},
          "TreatMissingData": {},
          "EvaluateLowSampleCountPercentile": {},
          "Metrics": {
            "shape": "S1v"
          },
          "Tags": {
            "shape": "S4l"
          },
          "ThresholdMetricId": {}
        }
      }
    },
    "PutMetricData": {
      "input": {
        "type": "structure",
        "required": [
          "Namespace",
          "MetricData"
        ],
        "members": {
          "Namespace": {},
          "MetricData": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "MetricName"
              ],
              "members": {
                "MetricName": {},
                "Dimensions": {
                  "shape": "S7"
                },
                "Timestamp": {
                  "type": "timestamp"
                },
                "Value": {
                  "type": "double"
                },
                "StatisticValues": {
                  "type": "structure",
                  "required": [
                    "SampleCount",
                    "Sum",
                    "Minimum",
                    "Maximum"
                  ],
                  "members": {
                    "SampleCount": {
                      "type": "double"
                    },
                    "Sum": {
                      "type": "double"
                    },
                    "Minimum": {
                      "type": "double"
                    },
                    "Maximum": {
                      "type": "double"
                    }
                  }
                },
                "Values": {
                  "type": "list",
                  "member": {
                    "type": "double"
                  }
                },
                "Counts": {
                  "type": "list",
                  "member": {
                    "type": "double"
                  }
                },
                "Unit": {},
                "StorageResolution": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "SetAlarmState": {
      "input": {
        "type": "structure",
        "required": [
          "AlarmName",
          "StateValue",
          "StateReason"
        ],
        "members": {
          "AlarmName": {},
          "StateValue": {},
          "StateReason": {},
          "StateReasonData": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "resultWrapper": "TagResourceResult",
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "UntagResourceResult",
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        },
        "xmlOrder": [
          "Name",
          "Value"
        ]
      }
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "FailureResource": {},
          "ExceptionType": {},
          "FailureCode": {},
          "FailureDescription": {}
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AlarmName": {},
          "AlarmArn": {},
          "AlarmDescription": {},
          "AlarmConfigurationUpdatedTimestamp": {
            "type": "timestamp"
          },
          "ActionsEnabled": {
            "type": "boolean"
          },
          "OKActions": {
            "shape": "S1c"
          },
          "AlarmActions": {
            "shape": "S1c"
          },
          "InsufficientDataActions": {
            "shape": "S1c"
          },
          "StateValue": {},
          "StateReason": {},
          "StateReasonData": {},
          "StateUpdatedTimestamp": {
            "type": "timestamp"
          },
          "MetricName": {},
          "Namespace": {},
          "Statistic": {},
          "ExtendedStatistic": {},
          "Dimensions": {
            "shape": "S7"
          },
          "Period": {
            "type": "integer"
          },
          "Unit": {},
          "EvaluationPeriods": {
            "type": "integer"
          },
          "DatapointsToAlarm": {
            "type": "integer"
          },
          "Threshold": {
            "type": "double"
          },
          "ComparisonOperator": {},
          "TreatMissingData": {},
          "EvaluateLowSampleCountPercentile": {},
          "Metrics": {
            "shape": "S1v"
          },
          "ThresholdMetricId": {}
        },
        "xmlOrder": [
          "AlarmName",
          "AlarmArn",
          "AlarmDescription",
          "AlarmConfigurationUpdatedTimestamp",
          "ActionsEnabled",
          "OKActions",
          "AlarmActions",
          "InsufficientDataActions",
          "StateValue",
          "StateReason",
          "StateReasonData",
          "StateUpdatedTimestamp",
          "MetricName",
          "Namespace",
          "Statistic",
          "Dimensions",
          "Period",
          "Unit",
          "EvaluationPeriods",
          "Threshold",
          "ComparisonOperator",
          "ExtendedStatistic",
          "TreatMissingData",
          "EvaluateLowSampleCountPercentile",
          "DatapointsToAlarm",
          "Metrics",
          "ThresholdMetricId"
        ]
      }
    },
    "S1v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "MetricStat": {
            "type": "structure",
            "required": [
              "Metric",
              "Period",
              "Stat"
            ],
            "members": {
              "Metric": {
                "shape": "S1z"
              },
              "Period": {
                "type": "integer"
              },
              "Stat": {},
              "Unit": {}
            }
          },
          "Expression": {},
          "Label": {},
          "ReturnData": {
            "type": "boolean"
          },
          "Period": {
            "type": "integer"
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Namespace": {},
        "MetricName": {},
        "Dimensions": {
          "shape": "S7"
        }
      },
      "xmlOrder": [
        "Namespace",
        "MetricName",
        "Dimensions"
      ]
    },
    "S2b": {
      "type": "structure",
      "members": {
        "ExcludedTimeRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "StartTime",
              "EndTime"
            ],
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            },
            "xmlOrder": [
              "StartTime",
              "EndTime"
            ]
          }
        },
        "MetricTimezone": {}
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Code": {},
          "Value": {}
        }
      }
    },
    "S4l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}
},{}],155:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeAlarmHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": "AlarmHistoryItems"
    },
    "DescribeAlarms": {
      "input_token": "NextToken",
      "limit_key": "MaxRecords",
      "output_token": "NextToken",
      "result_key": [
        "MetricAlarms",
        "CompositeAlarms"
      ]
    },
    "DescribeAlarmsForMetric": {
      "result_key": "MetricAlarms"
    },
    "DescribeInsightRules": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetMetricData": {
      "input_token": "NextToken",
      "limit_key": "MaxDatapoints",
      "output_token": "NextToken",
      "result_key": [
        "MetricDataResults",
        "Messages"
      ]
    },
    "ListDashboards": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "DashboardEntries"
    },
    "ListMetrics": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Metrics"
    }
  }
}
},{}],156:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "AlarmExists": {
      "delay": 5,
      "maxAttempts": 40,
      "operation": "DescribeAlarms",
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(MetricAlarms[]) > `0`",
          "state": "success"
        }
      ]
    },
    "CompositeAlarmExists": {
      "delay": 5,
      "maxAttempts": 40,
      "operation": "DescribeAlarms",
      "acceptors": [
        {
          "matcher": "path",
          "expected": true,
          "argument": "length(CompositeAlarms[]) > `0`",
          "state": "success"
        }
      ]
    }
  }
}

},{}],157:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-01-17",
    "endpointPrefix": "mturk-requester",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon MTurk",
    "serviceFullName": "Amazon Mechanical Turk",
    "serviceId": "MTurk",
    "signatureVersion": "v4",
    "targetPrefix": "MTurkRequesterServiceV20170117",
    "uid": "mturk-requester-2017-01-17"
  },
  "operations": {
    "AcceptQualificationRequest": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationRequestId"
        ],
        "members": {
          "QualificationRequestId": {},
          "IntegerValue": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ApproveAssignment": {
      "input": {
        "type": "structure",
        "required": [
          "AssignmentId"
        ],
        "members": {
          "AssignmentId": {},
          "RequesterFeedback": {},
          "OverrideRejection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "AssociateQualificationWithWorker": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId",
          "WorkerId"
        ],
        "members": {
          "QualificationTypeId": {},
          "WorkerId": {},
          "IntegerValue": {
            "type": "integer"
          },
          "SendNotification": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAdditionalAssignmentsForHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId",
          "NumberOfAdditionalAssignments"
        ],
        "members": {
          "HITId": {},
          "NumberOfAdditionalAssignments": {
            "type": "integer"
          },
          "UniqueRequestToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateHIT": {
      "input": {
        "type": "structure",
        "required": [
          "LifetimeInSeconds",
          "AssignmentDurationInSeconds",
          "Reward",
          "Title",
          "Description"
        ],
        "members": {
          "MaxAssignments": {
            "type": "integer"
          },
          "AutoApprovalDelayInSeconds": {
            "type": "long"
          },
          "LifetimeInSeconds": {
            "type": "long"
          },
          "AssignmentDurationInSeconds": {
            "type": "long"
          },
          "Reward": {},
          "Title": {},
          "Keywords": {},
          "Description": {},
          "Question": {},
          "RequesterAnnotation": {},
          "QualificationRequirements": {
            "shape": "Si"
          },
          "UniqueRequestToken": {},
          "AssignmentReviewPolicy": {
            "shape": "Sq"
          },
          "HITReviewPolicy": {
            "shape": "Sq"
          },
          "HITLayoutId": {},
          "HITLayoutParameters": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HIT": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateHITType": {
      "input": {
        "type": "structure",
        "required": [
          "AssignmentDurationInSeconds",
          "Reward",
          "Title",
          "Description"
        ],
        "members": {
          "AutoApprovalDelayInSeconds": {
            "type": "long"
          },
          "AssignmentDurationInSeconds": {
            "type": "long"
          },
          "Reward": {},
          "Title": {},
          "Keywords": {},
          "Description": {},
          "QualificationRequirements": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HITTypeId": {}
        }
      },
      "idempotent": true
    },
    "CreateHITWithHITType": {
      "input": {
        "type": "structure",
        "required": [
          "HITTypeId",
          "LifetimeInSeconds"
        ],
        "members": {
          "HITTypeId": {},
          "MaxAssignments": {
            "type": "integer"
          },
          "LifetimeInSeconds": {
            "type": "long"
          },
          "Question": {},
          "RequesterAnnotation": {},
          "UniqueRequestToken": {},
          "AssignmentReviewPolicy": {
            "shape": "Sq"
          },
          "HITReviewPolicy": {
            "shape": "Sq"
          },
          "HITLayoutId": {},
          "HITLayoutParameters": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HIT": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Description",
          "QualificationTypeStatus"
        ],
        "members": {
          "Name": {},
          "Keywords": {},
          "Description": {},
          "QualificationTypeStatus": {},
          "RetryDelayInSeconds": {
            "type": "long"
          },
          "Test": {},
          "AnswerKey": {},
          "TestDurationInSeconds": {
            "type": "long"
          },
          "AutoGranted": {
            "type": "boolean"
          },
          "AutoGrantedValue": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QualificationType": {
            "shape": "S1a"
          }
        }
      }
    },
    "CreateWorkerBlock": {
      "input": {
        "type": "structure",
        "required": [
          "WorkerId",
          "Reason"
        ],
        "members": {
          "WorkerId": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId"
        ],
        "members": {
          "HITId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId"
        ],
        "members": {
          "QualificationTypeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteWorkerBlock": {
      "input": {
        "type": "structure",
        "required": [
          "WorkerId"
        ],
        "members": {
          "WorkerId": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateQualificationFromWorker": {
      "input": {
        "type": "structure",
        "required": [
          "WorkerId",
          "QualificationTypeId"
        ],
        "members": {
          "WorkerId": {},
          "QualificationTypeId": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAccountBalance": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AvailableBalance": {},
          "OnHoldBalance": {}
        }
      },
      "idempotent": true
    },
    "GetAssignment": {
      "input": {
        "type": "structure",
        "required": [
          "AssignmentId"
        ],
        "members": {
          "AssignmentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Assignment": {
            "shape": "S1p"
          },
          "HIT": {
            "shape": "Sz"
          }
        }
      },
      "idempotent": true
    },
    "GetFileUploadURL": {
      "input": {
        "type": "structure",
        "required": [
          "AssignmentId",
          "QuestionIdentifier"
        ],
        "members": {
          "AssignmentId": {},
          "QuestionIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileUploadURL": {}
        }
      },
      "idempotent": true
    },
    "GetHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId"
        ],
        "members": {
          "HITId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HIT": {
            "shape": "Sz"
          }
        }
      },
      "idempotent": true
    },
    "GetQualificationScore": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId",
          "WorkerId"
        ],
        "members": {
          "QualificationTypeId": {},
          "WorkerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Qualification": {
            "shape": "S1x"
          }
        }
      },
      "idempotent": true
    },
    "GetQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId"
        ],
        "members": {
          "QualificationTypeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QualificationType": {
            "shape": "S1a"
          }
        }
      },
      "idempotent": true
    },
    "ListAssignmentsForHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId"
        ],
        "members": {
          "HITId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "AssignmentStatuses": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "Assignments": {
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          }
        }
      },
      "idempotent": true
    },
    "ListBonusPayments": {
      "input": {
        "type": "structure",
        "members": {
          "HITId": {},
          "AssignmentId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NumResults": {
            "type": "integer"
          },
          "NextToken": {},
          "BonusPayments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkerId": {},
                "BonusAmount": {},
                "AssignmentId": {},
                "Reason": {},
                "GrantTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListHITs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "HITs": {
            "shape": "S2d"
          }
        }
      },
      "idempotent": true
    },
    "ListHITsForQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId"
        ],
        "members": {
          "QualificationTypeId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "HITs": {
            "shape": "S2d"
          }
        }
      },
      "idempotent": true
    },
    "ListQualificationRequests": {
      "input": {
        "type": "structure",
        "members": {
          "QualificationTypeId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NumResults": {
            "type": "integer"
          },
          "NextToken": {},
          "QualificationRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QualificationRequestId": {},
                "QualificationTypeId": {},
                "WorkerId": {},
                "Test": {},
                "Answer": {},
                "SubmitTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListQualificationTypes": {
      "input": {
        "type": "structure",
        "required": [
          "MustBeRequestable"
        ],
        "members": {
          "Query": {},
          "MustBeRequestable": {
            "type": "boolean"
          },
          "MustBeOwnedByCaller": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NumResults": {
            "type": "integer"
          },
          "NextToken": {},
          "QualificationTypes": {
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          }
        }
      },
      "idempotent": true
    },
    "ListReviewPolicyResultsForHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId"
        ],
        "members": {
          "HITId": {},
          "PolicyLevels": {
            "type": "list",
            "member": {}
          },
          "RetrieveActions": {
            "type": "boolean"
          },
          "RetrieveResults": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HITId": {},
          "AssignmentReviewPolicy": {
            "shape": "Sq"
          },
          "HITReviewPolicy": {
            "shape": "Sq"
          },
          "AssignmentReviewReport": {
            "shape": "S2r"
          },
          "HITReviewReport": {
            "shape": "S2r"
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListReviewableHITs": {
      "input": {
        "type": "structure",
        "members": {
          "HITTypeId": {},
          "Status": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "HITs": {
            "shape": "S2d"
          }
        }
      },
      "idempotent": true
    },
    "ListWorkerBlocks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "WorkerBlocks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkerId": {},
                "Reason": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ListWorkersWithQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId"
        ],
        "members": {
          "QualificationTypeId": {},
          "Status": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NumResults": {
            "type": "integer"
          },
          "Qualifications": {
            "type": "list",
            "member": {
              "shape": "S1x"
            }
          }
        }
      },
      "idempotent": true
    },
    "NotifyWorkers": {
      "input": {
        "type": "structure",
        "required": [
          "Subject",
          "MessageText",
          "WorkerIds"
        ],
        "members": {
          "Subject": {},
          "MessageText": {},
          "WorkerIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotifyWorkersFailureStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NotifyWorkersFailureCode": {},
                "NotifyWorkersFailureMessage": {},
                "WorkerId": {}
              }
            }
          }
        }
      }
    },
    "RejectAssignment": {
      "input": {
        "type": "structure",
        "required": [
          "AssignmentId",
          "RequesterFeedback"
        ],
        "members": {
          "AssignmentId": {},
          "RequesterFeedback": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RejectQualificationRequest": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationRequestId"
        ],
        "members": {
          "QualificationRequestId": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SendBonus": {
      "input": {
        "type": "structure",
        "required": [
          "WorkerId",
          "BonusAmount",
          "AssignmentId",
          "Reason"
        ],
        "members": {
          "WorkerId": {},
          "BonusAmount": {},
          "AssignmentId": {},
          "Reason": {},
          "UniqueRequestToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SendTestEventNotification": {
      "input": {
        "type": "structure",
        "required": [
          "Notification",
          "TestEventType"
        ],
        "members": {
          "Notification": {
            "shape": "S3k"
          },
          "TestEventType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateExpirationForHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId",
          "ExpireAt"
        ],
        "members": {
          "HITId": {},
          "ExpireAt": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateHITReviewStatus": {
      "input": {
        "type": "structure",
        "required": [
          "HITId"
        ],
        "members": {
          "HITId": {},
          "Revert": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateHITTypeOfHIT": {
      "input": {
        "type": "structure",
        "required": [
          "HITId",
          "HITTypeId"
        ],
        "members": {
          "HITId": {},
          "HITTypeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateNotificationSettings": {
      "input": {
        "type": "structure",
        "required": [
          "HITTypeId"
        ],
        "members": {
          "HITTypeId": {},
          "Notification": {
            "shape": "S3k"
          },
          "Active": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateQualificationType": {
      "input": {
        "type": "structure",
        "required": [
          "QualificationTypeId"
        ],
        "members": {
          "QualificationTypeId": {},
          "Description": {},
          "QualificationTypeStatus": {},
          "Test": {},
          "AnswerKey": {},
          "TestDurationInSeconds": {
            "type": "long"
          },
          "RetryDelayInSeconds": {
            "type": "long"
          },
          "AutoGranted": {
            "type": "boolean"
          },
          "AutoGrantedValue": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QualificationType": {
            "shape": "S1a"
          }
        }
      }
    }
  },
  "shapes": {
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "QualificationTypeId",
          "Comparator"
        ],
        "members": {
          "QualificationTypeId": {},
          "Comparator": {},
          "IntegerValues": {
            "type": "list",
            "member": {
              "type": "integer"
            }
          },
          "LocaleValues": {
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          },
          "RequiredToPreview": {
            "deprecated": true,
            "type": "boolean"
          },
          "ActionsGuarded": {}
        }
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "Country"
      ],
      "members": {
        "Country": {},
        "Subdivision": {}
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "PolicyName"
      ],
      "members": {
        "PolicyName": {},
        "Parameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Values": {
                "shape": "St"
              },
              "MapEntries": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Key": {},
                    "Values": {
                      "shape": "St"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "St": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "HITId": {},
        "HITTypeId": {},
        "HITGroupId": {},
        "HITLayoutId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "Title": {},
        "Description": {},
        "Question": {},
        "Keywords": {},
        "HITStatus": {},
        "MaxAssignments": {
          "type": "integer"
        },
        "Reward": {},
        "AutoApprovalDelayInSeconds": {
          "type": "long"
        },
        "Expiration": {
          "type": "timestamp"
        },
        "AssignmentDurationInSeconds": {
          "type": "long"
        },
        "RequesterAnnotation": {},
        "QualificationRequirements": {
          "shape": "Si"
        },
        "HITReviewStatus": {},
        "NumberOfAssignmentsPending": {
          "type": "integer"
        },
        "NumberOfAssignmentsAvailable": {
          "type": "integer"
        },
        "NumberOfAssignmentsCompleted": {
          "type": "integer"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "QualificationTypeId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "Name": {},
        "Description": {},
        "Keywords": {},
        "QualificationTypeStatus": {},
        "Test": {},
        "TestDurationInSeconds": {
          "type": "long"
        },
        "AnswerKey": {},
        "RetryDelayInSeconds": {
          "type": "long"
        },
        "IsRequestable": {
          "type": "boolean"
        },
        "AutoGranted": {
          "type": "boolean"
        },
        "AutoGrantedValue": {
          "type": "integer"
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "AssignmentId": {},
        "WorkerId": {},
        "HITId": {},
        "AssignmentStatus": {},
        "AutoApprovalTime": {
          "type": "timestamp"
        },
        "AcceptTime": {
          "type": "timestamp"
        },
        "SubmitTime": {
          "type": "timestamp"
        },
        "ApprovalTime": {
          "type": "timestamp"
        },
        "RejectionTime": {
          "type": "timestamp"
        },
        "Deadline": {
          "type": "timestamp"
        },
        "Answer": {},
        "RequesterFeedback": {}
      }
    },
    "S1x": {
      "type": "structure",
      "members": {
        "QualificationTypeId": {},
        "WorkerId": {},
        "GrantTime": {
          "type": "timestamp"
        },
        "IntegerValue": {
          "type": "integer"
        },
        "LocaleValue": {
          "shape": "Sn"
        },
        "Status": {}
      }
    },
    "S2d": {
      "type": "list",
      "member": {
        "shape": "Sz"
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "ReviewResults": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ActionId": {},
              "SubjectId": {},
              "SubjectType": {},
              "QuestionId": {},
              "Key": {},
              "Value": {}
            }
          }
        },
        "ReviewActions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ActionId": {},
              "ActionName": {},
              "TargetId": {},
              "TargetType": {},
              "Status": {},
              "CompleteTime": {
                "type": "timestamp"
              },
              "Result": {},
              "ErrorCode": {}
            }
          }
        }
      }
    },
    "S3k": {
      "type": "structure",
      "required": [
        "Destination",
        "Transport",
        "Version",
        "EventTypes"
      ],
      "members": {
        "Destination": {},
        "Transport": {},
        "Version": {},
        "EventTypes": {
          "type": "list",
          "member": {}
        }
      }
    }
  }
}
},{}],158:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListAssignmentsForHIT": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListBonusPayments": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListHITs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListHITsForQualificationType": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListQualificationRequests": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListQualificationTypes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListReviewPolicyResultsForHIT": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListReviewableHITs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListWorkerBlocks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListWorkersWithQualificationType": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],159:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-02-18",
    "endpointPrefix": "opsworks",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS OpsWorks",
    "serviceId": "OpsWorks",
    "signatureVersion": "v4",
    "targetPrefix": "OpsWorks_20130218",
    "uid": "opsworks-2013-02-18"
  },
  "operations": {
    "AssignInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "LayerIds"
        ],
        "members": {
          "InstanceId": {},
          "LayerIds": {
            "shape": "S3"
          }
        }
      }
    },
    "AssignVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {},
          "InstanceId": {}
        }
      }
    },
    "AssociateElasticIp": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticIp"
        ],
        "members": {
          "ElasticIp": {},
          "InstanceId": {}
        }
      }
    },
    "AttachElasticLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticLoadBalancerName",
          "LayerId"
        ],
        "members": {
          "ElasticLoadBalancerName": {},
          "LayerId": {}
        }
      }
    },
    "CloneStack": {
      "input": {
        "type": "structure",
        "required": [
          "SourceStackId",
          "ServiceRoleArn"
        ],
        "members": {
          "SourceStackId": {},
          "Name": {},
          "Region": {},
          "VpcId": {},
          "Attributes": {
            "shape": "S8"
          },
          "ServiceRoleArn": {},
          "DefaultInstanceProfileArn": {},
          "DefaultOs": {},
          "HostnameTheme": {},
          "DefaultAvailabilityZone": {},
          "DefaultSubnetId": {},
          "CustomJson": {},
          "ConfigurationManager": {
            "shape": "Sa"
          },
          "ChefConfiguration": {
            "shape": "Sb"
          },
          "UseCustomCookbooks": {
            "type": "boolean"
          },
          "UseOpsworksSecurityGroups": {
            "type": "boolean"
          },
          "CustomCookbooksSource": {
            "shape": "Sd"
          },
          "DefaultSshKeyName": {},
          "ClonePermissions": {
            "type": "boolean"
          },
          "CloneAppIds": {
            "shape": "S3"
          },
          "DefaultRootDeviceType": {},
          "AgentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "CreateApp": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "Name",
          "Type"
        ],
        "members": {
          "StackId": {},
          "Shortname": {},
          "Name": {},
          "Description": {},
          "DataSources": {
            "shape": "Si"
          },
          "Type": {},
          "AppSource": {
            "shape": "Sd"
          },
          "Domains": {
            "shape": "S3"
          },
          "EnableSsl": {
            "type": "boolean"
          },
          "SslConfiguration": {
            "shape": "Sl"
          },
          "Attributes": {
            "shape": "Sm"
          },
          "Environment": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppId": {}
        }
      }
    },
    "CreateDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "Command"
        ],
        "members": {
          "StackId": {},
          "AppId": {},
          "InstanceIds": {
            "shape": "S3"
          },
          "LayerIds": {
            "shape": "S3"
          },
          "Command": {
            "shape": "Ss"
          },
          "Comment": {},
          "CustomJson": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeploymentId": {}
        }
      }
    },
    "CreateInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "LayerIds",
          "InstanceType"
        ],
        "members": {
          "StackId": {},
          "LayerIds": {
            "shape": "S3"
          },
          "InstanceType": {},
          "AutoScalingType": {},
          "Hostname": {},
          "Os": {},
          "AmiId": {},
          "SshKeyName": {},
          "AvailabilityZone": {},
          "VirtualizationType": {},
          "SubnetId": {},
          "Architecture": {},
          "RootDeviceType": {},
          "BlockDeviceMappings": {
            "shape": "Sz"
          },
          "InstallUpdatesOnBoot": {
            "type": "boolean"
          },
          "EbsOptimized": {
            "type": "boolean"
          },
          "AgentVersion": {},
          "Tenancy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {}
        }
      }
    },
    "CreateLayer": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "Type",
          "Name",
          "Shortname"
        ],
        "members": {
          "StackId": {},
          "Type": {},
          "Name": {},
          "Shortname": {},
          "Attributes": {
            "shape": "S17"
          },
          "CloudWatchLogsConfiguration": {
            "shape": "S19"
          },
          "CustomInstanceProfileArn": {},
          "CustomJson": {},
          "CustomSecurityGroupIds": {
            "shape": "S3"
          },
          "Packages": {
            "shape": "S3"
          },
          "VolumeConfigurations": {
            "shape": "S1f"
          },
          "EnableAutoHealing": {
            "type": "boolean"
          },
          "AutoAssignElasticIps": {
            "type": "boolean"
          },
          "AutoAssignPublicIps": {
            "type": "boolean"
          },
          "CustomRecipes": {
            "shape": "S1h"
          },
          "InstallUpdatesOnBoot": {
            "type": "boolean"
          },
          "UseEbsOptimizedInstances": {
            "type": "boolean"
          },
          "LifecycleEventConfiguration": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LayerId": {}
        }
      }
    },
    "CreateStack": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Region",
          "ServiceRoleArn",
          "DefaultInstanceProfileArn"
        ],
        "members": {
          "Name": {},
          "Region": {},
          "VpcId": {},
          "Attributes": {
            "shape": "S8"
          },
          "ServiceRoleArn": {},
          "DefaultInstanceProfileArn": {},
          "DefaultOs": {},
          "HostnameTheme": {},
          "DefaultAvailabilityZone": {},
          "DefaultSubnetId": {},
          "CustomJson": {},
          "ConfigurationManager": {
            "shape": "Sa"
          },
          "ChefConfiguration": {
            "shape": "Sb"
          },
          "UseCustomCookbooks": {
            "type": "boolean"
          },
          "UseOpsworksSecurityGroups": {
            "type": "boolean"
          },
          "CustomCookbooksSource": {
            "shape": "Sd"
          },
          "DefaultSshKeyName": {},
          "DefaultRootDeviceType": {},
          "AgentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "CreateUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamUserArn"
        ],
        "members": {
          "IamUserArn": {},
          "SshUsername": {},
          "SshPublicKey": {},
          "AllowSelfManagement": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamUserArn": {}
        }
      }
    },
    "DeleteApp": {
      "input": {
        "type": "structure",
        "required": [
          "AppId"
        ],
        "members": {
          "AppId": {}
        }
      }
    },
    "DeleteInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DeleteElasticIp": {
            "type": "boolean"
          },
          "DeleteVolumes": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLayer": {
      "input": {
        "type": "structure",
        "required": [
          "LayerId"
        ],
        "members": {
          "LayerId": {}
        }
      }
    },
    "DeleteStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {}
        }
      }
    },
    "DeleteUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamUserArn"
        ],
        "members": {
          "IamUserArn": {}
        }
      }
    },
    "DeregisterEcsCluster": {
      "input": {
        "type": "structure",
        "required": [
          "EcsClusterArn"
        ],
        "members": {
          "EcsClusterArn": {}
        }
      }
    },
    "DeregisterElasticIp": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticIp"
        ],
        "members": {
          "ElasticIp": {}
        }
      }
    },
    "DeregisterInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      }
    },
    "DeregisterRdsDbInstance": {
      "input": {
        "type": "structure",
        "required": [
          "RdsDbInstanceArn"
        ],
        "members": {
          "RdsDbInstanceArn": {}
        }
      }
    },
    "DeregisterVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {}
        }
      }
    },
    "DescribeAgentVersions": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "ConfigurationManager": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Version": {},
                "ConfigurationManager": {
                  "shape": "Sa"
                }
              }
            }
          }
        }
      }
    },
    "DescribeApps": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "AppIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Apps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AppId": {},
                "StackId": {},
                "Shortname": {},
                "Name": {},
                "Description": {},
                "DataSources": {
                  "shape": "Si"
                },
                "Type": {},
                "AppSource": {
                  "shape": "Sd"
                },
                "Domains": {
                  "shape": "S3"
                },
                "EnableSsl": {
                  "type": "boolean"
                },
                "SslConfiguration": {
                  "shape": "Sl"
                },
                "Attributes": {
                  "shape": "Sm"
                },
                "CreatedAt": {},
                "Environment": {
                  "shape": "So"
                }
              }
            }
          }
        }
      }
    },
    "DescribeCommands": {
      "input": {
        "type": "structure",
        "members": {
          "DeploymentId": {},
          "InstanceId": {},
          "CommandIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Commands": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CommandId": {},
                "InstanceId": {},
                "DeploymentId": {},
                "CreatedAt": {},
                "AcknowledgedAt": {},
                "CompletedAt": {},
                "Status": {},
                "ExitCode": {
                  "type": "integer"
                },
                "LogUrl": {},
                "Type": {}
              }
            }
          }
        }
      }
    },
    "DescribeDeployments": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "AppId": {},
          "DeploymentIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Deployments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DeploymentId": {},
                "StackId": {},
                "AppId": {},
                "CreatedAt": {},
                "CompletedAt": {},
                "Duration": {
                  "type": "integer"
                },
                "IamUserArn": {},
                "Comment": {},
                "Command": {
                  "shape": "Ss"
                },
                "Status": {},
                "CustomJson": {},
                "InstanceIds": {
                  "shape": "S3"
                }
              }
            }
          }
        }
      }
    },
    "DescribeEcsClusters": {
      "input": {
        "type": "structure",
        "members": {
          "EcsClusterArns": {
            "shape": "S3"
          },
          "StackId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EcsClusters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EcsClusterArn": {},
                "EcsClusterName": {},
                "StackId": {},
                "RegisteredAt": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeElasticIps": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "StackId": {},
          "Ips": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticIps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Ip": {},
                "Name": {},
                "Domain": {},
                "Region": {},
                "InstanceId": {}
              }
            }
          }
        }
      }
    },
    "DescribeElasticLoadBalancers": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "LayerIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticLoadBalancers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ElasticLoadBalancerName": {},
                "Region": {},
                "DnsName": {},
                "StackId": {},
                "LayerId": {},
                "VpcId": {},
                "AvailabilityZones": {
                  "shape": "S3"
                },
                "SubnetIds": {
                  "shape": "S3"
                },
                "Ec2InstanceIds": {
                  "shape": "S3"
                }
              }
            }
          }
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "LayerId": {},
          "InstanceIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AgentVersion": {},
                "AmiId": {},
                "Architecture": {},
                "Arn": {},
                "AutoScalingType": {},
                "AvailabilityZone": {},
                "BlockDeviceMappings": {
                  "shape": "Sz"
                },
                "CreatedAt": {},
                "EbsOptimized": {
                  "type": "boolean"
                },
                "Ec2InstanceId": {},
                "EcsClusterArn": {},
                "EcsContainerInstanceArn": {},
                "ElasticIp": {},
                "Hostname": {},
                "InfrastructureClass": {},
                "InstallUpdatesOnBoot": {
                  "type": "boolean"
                },
                "InstanceId": {},
                "InstanceProfileArn": {},
                "InstanceType": {},
                "LastServiceErrorId": {},
                "LayerIds": {
                  "shape": "S3"
                },
                "Os": {},
                "Platform": {},
                "PrivateDns": {},
                "PrivateIp": {},
                "PublicDns": {},
                "PublicIp": {},
                "RegisteredBy": {},
                "ReportedAgentVersion": {},
                "ReportedOs": {
                  "type": "structure",
                  "members": {
                    "Family": {},
                    "Name": {},
                    "Version": {}
                  }
                },
                "RootDeviceType": {},
                "RootDeviceVolumeId": {},
                "SecurityGroupIds": {
                  "shape": "S3"
                },
                "SshHostDsaKeyFingerprint": {},
                "SshHostRsaKeyFingerprint": {},
                "SshKeyName": {},
                "StackId": {},
                "Status": {},
                "SubnetId": {},
                "Tenancy": {},
                "VirtualizationType": {}
              }
            }
          }
        }
      }
    },
    "DescribeLayers": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "LayerIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "StackId": {},
                "LayerId": {},
                "Type": {},
                "Name": {},
                "Shortname": {},
                "Attributes": {
                  "shape": "S17"
                },
                "CloudWatchLogsConfiguration": {
                  "shape": "S19"
                },
                "CustomInstanceProfileArn": {},
                "CustomJson": {},
                "CustomSecurityGroupIds": {
                  "shape": "S3"
                },
                "DefaultSecurityGroupNames": {
                  "shape": "S3"
                },
                "Packages": {
                  "shape": "S3"
                },
                "VolumeConfigurations": {
                  "shape": "S1f"
                },
                "EnableAutoHealing": {
                  "type": "boolean"
                },
                "AutoAssignElasticIps": {
                  "type": "boolean"
                },
                "AutoAssignPublicIps": {
                  "type": "boolean"
                },
                "DefaultRecipes": {
                  "shape": "S1h"
                },
                "CustomRecipes": {
                  "shape": "S1h"
                },
                "CreatedAt": {},
                "InstallUpdatesOnBoot": {
                  "type": "boolean"
                },
                "UseEbsOptimizedInstances": {
                  "type": "boolean"
                },
                "LifecycleEventConfiguration": {
                  "shape": "S1i"
                }
              }
            }
          }
        }
      }
    },
    "DescribeLoadBasedAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "LayerIds"
        ],
        "members": {
          "LayerIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoadBasedAutoScalingConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LayerId": {},
                "Enable": {
                  "type": "boolean"
                },
                "UpScaling": {
                  "shape": "S36"
                },
                "DownScaling": {
                  "shape": "S36"
                }
              }
            }
          }
        }
      }
    },
    "DescribeMyUserProfile": {
      "output": {
        "type": "structure",
        "members": {
          "UserProfile": {
            "type": "structure",
            "members": {
              "IamUserArn": {},
              "Name": {},
              "SshUsername": {},
              "SshPublicKey": {}
            }
          }
        }
      }
    },
    "DescribeOperatingSystems": {
      "output": {
        "type": "structure",
        "members": {
          "OperatingSystems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Id": {},
                "Type": {},
                "ConfigurationManagers": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Version": {}
                    }
                  }
                },
                "ReportedName": {},
                "ReportedVersion": {},
                "Supported": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "DescribePermissions": {
      "input": {
        "type": "structure",
        "members": {
          "IamUserArn": {},
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "IamUserArn": {},
                "AllowSsh": {
                  "type": "boolean"
                },
                "AllowSudo": {
                  "type": "boolean"
                },
                "Level": {}
              }
            }
          }
        }
      }
    },
    "DescribeRaidArrays": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "StackId": {},
          "RaidArrayIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RaidArrays": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RaidArrayId": {},
                "InstanceId": {},
                "Name": {},
                "RaidLevel": {
                  "type": "integer"
                },
                "NumberOfDisks": {
                  "type": "integer"
                },
                "Size": {
                  "type": "integer"
                },
                "Device": {},
                "MountPoint": {},
                "AvailabilityZone": {},
                "CreatedAt": {},
                "StackId": {},
                "VolumeType": {},
                "Iops": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeRdsDbInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {},
          "RdsDbInstanceArns": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RdsDbInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RdsDbInstanceArn": {},
                "DbInstanceIdentifier": {},
                "DbUser": {},
                "DbPassword": {},
                "Region": {},
                "Address": {},
                "Engine": {},
                "StackId": {},
                "MissingOnRds": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "DescribeServiceErrors": {
      "input": {
        "type": "structure",
        "members": {
          "StackId": {},
          "InstanceId": {},
          "ServiceErrorIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceErrorId": {},
                "StackId": {},
                "InstanceId": {},
                "Type": {},
                "Message": {},
                "CreatedAt": {}
              }
            }
          }
        }
      }
    },
    "DescribeStackProvisioningParameters": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentInstallerUrl": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "DescribeStackSummary": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StackSummary": {
            "type": "structure",
            "members": {
              "StackId": {},
              "Name": {},
              "Arn": {},
              "LayersCount": {
                "type": "integer"
              },
              "AppsCount": {
                "type": "integer"
              },
              "InstancesCount": {
                "type": "structure",
                "members": {
                  "Assigning": {
                    "type": "integer"
                  },
                  "Booting": {
                    "type": "integer"
                  },
                  "ConnectionLost": {
                    "type": "integer"
                  },
                  "Deregistering": {
                    "type": "integer"
                  },
                  "Online": {
                    "type": "integer"
                  },
                  "Pending": {
                    "type": "integer"
                  },
                  "Rebooting": {
                    "type": "integer"
                  },
                  "Registered": {
                    "type": "integer"
                  },
                  "Registering": {
                    "type": "integer"
                  },
                  "Requested": {
                    "type": "integer"
                  },
                  "RunningSetup": {
                    "type": "integer"
                  },
                  "SetupFailed": {
                    "type": "integer"
                  },
                  "ShuttingDown": {
                    "type": "integer"
                  },
                  "StartFailed": {
                    "type": "integer"
                  },
                  "StopFailed": {
                    "type": "integer"
                  },
                  "Stopped": {
                    "type": "integer"
                  },
                  "Stopping": {
                    "type": "integer"
                  },
                  "Terminated": {
                    "type": "integer"
                  },
                  "Terminating": {
                    "type": "integer"
                  },
                  "Unassigning": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeStacks": {
      "input": {
        "type": "structure",
        "members": {
          "StackIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Stacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "Name": {},
                "Arn": {},
                "Region": {},
                "VpcId": {},
                "Attributes": {
                  "shape": "S8"
                },
                "ServiceRoleArn": {},
                "DefaultInstanceProfileArn": {},
                "DefaultOs": {},
                "HostnameTheme": {},
                "DefaultAvailabilityZone": {},
                "DefaultSubnetId": {},
                "CustomJson": {},
                "ConfigurationManager": {
                  "shape": "Sa"
                },
                "ChefConfiguration": {
                  "shape": "Sb"
                },
                "UseCustomCookbooks": {
                  "type": "boolean"
                },
                "UseOpsworksSecurityGroups": {
                  "type": "boolean"
                },
                "CustomCookbooksSource": {
                  "shape": "Sd"
                },
                "DefaultSshKeyName": {},
                "CreatedAt": {},
                "DefaultRootDeviceType": {},
                "AgentVersion": {}
              }
            }
          }
        }
      }
    },
    "DescribeTimeBasedAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeBasedAutoScalingConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceId": {},
                "AutoScalingSchedule": {
                  "shape": "S4b"
                }
              }
            }
          }
        }
      }
    },
    "DescribeUserProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "IamUserArns": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserProfiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IamUserArn": {},
                "Name": {},
                "SshUsername": {},
                "SshPublicKey": {},
                "AllowSelfManagement": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "StackId": {},
          "RaidArrayId": {},
          "VolumeIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeId": {},
                "Ec2VolumeId": {},
                "Name": {},
                "RaidArrayId": {},
                "InstanceId": {},
                "Status": {},
                "Size": {
                  "type": "integer"
                },
                "Device": {},
                "MountPoint": {},
                "Region": {},
                "AvailabilityZone": {},
                "VolumeType": {},
                "Iops": {
                  "type": "integer"
                },
                "Encrypted": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "DetachElasticLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticLoadBalancerName",
          "LayerId"
        ],
        "members": {
          "ElasticLoadBalancerName": {},
          "LayerId": {}
        }
      }
    },
    "DisassociateElasticIp": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticIp"
        ],
        "members": {
          "ElasticIp": {}
        }
      }
    },
    "GetHostnameSuggestion": {
      "input": {
        "type": "structure",
        "required": [
          "LayerId"
        ],
        "members": {
          "LayerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LayerId": {},
          "Hostname": {}
        }
      }
    },
    "GrantAccess": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "ValidForInMinutes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemporaryCredential": {
            "type": "structure",
            "members": {
              "Username": {},
              "Password": {},
              "ValidForInMinutes": {
                "type": "integer"
              },
              "InstanceId": {}
            }
          }
        }
      }
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S50"
          },
          "NextToken": {}
        }
      }
    },
    "RebootInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      }
    },
    "RegisterEcsCluster": {
      "input": {
        "type": "structure",
        "required": [
          "EcsClusterArn",
          "StackId"
        ],
        "members": {
          "EcsClusterArn": {},
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EcsClusterArn": {}
        }
      }
    },
    "RegisterElasticIp": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticIp",
          "StackId"
        ],
        "members": {
          "ElasticIp": {},
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticIp": {}
        }
      }
    },
    "RegisterInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {},
          "Hostname": {},
          "PublicIp": {},
          "PrivateIp": {},
          "RsaPublicKey": {},
          "RsaPublicKeyFingerprint": {},
          "InstanceIdentity": {
            "type": "structure",
            "members": {
              "Document": {},
              "Signature": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {}
        }
      }
    },
    "RegisterRdsDbInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "RdsDbInstanceArn",
          "DbUser",
          "DbPassword"
        ],
        "members": {
          "StackId": {},
          "RdsDbInstanceArn": {},
          "DbUser": {},
          "DbPassword": {}
        }
      }
    },
    "RegisterVolume": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "Ec2VolumeId": {},
          "StackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {}
        }
      }
    },
    "SetLoadBasedAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "LayerId"
        ],
        "members": {
          "LayerId": {},
          "Enable": {
            "type": "boolean"
          },
          "UpScaling": {
            "shape": "S36"
          },
          "DownScaling": {
            "shape": "S36"
          }
        }
      }
    },
    "SetPermission": {
      "input": {
        "type": "structure",
        "required": [
          "StackId",
          "IamUserArn"
        ],
        "members": {
          "StackId": {},
          "IamUserArn": {},
          "AllowSsh": {
            "type": "boolean"
          },
          "AllowSudo": {
            "type": "boolean"
          },
          "Level": {}
        }
      }
    },
    "SetTimeBasedAutoScaling": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "AutoScalingSchedule": {
            "shape": "S4b"
          }
        }
      }
    },
    "StartInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      }
    },
    "StartStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {}
        }
      }
    },
    "StopInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "Force": {
            "type": "boolean"
          }
        }
      }
    },
    "StopStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S50"
          }
        }
      }
    },
    "UnassignInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      }
    },
    "UnassignVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {}
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateApp": {
      "input": {
        "type": "structure",
        "required": [
          "AppId"
        ],
        "members": {
          "AppId": {},
          "Name": {},
          "Description": {},
          "DataSources": {
            "shape": "Si"
          },
          "Type": {},
          "AppSource": {
            "shape": "Sd"
          },
          "Domains": {
            "shape": "S3"
          },
          "EnableSsl": {
            "type": "boolean"
          },
          "SslConfiguration": {
            "shape": "Sl"
          },
          "Attributes": {
            "shape": "Sm"
          },
          "Environment": {
            "shape": "So"
          }
        }
      }
    },
    "UpdateElasticIp": {
      "input": {
        "type": "structure",
        "required": [
          "ElasticIp"
        ],
        "members": {
          "ElasticIp": {},
          "Name": {}
        }
      }
    },
    "UpdateInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "LayerIds": {
            "shape": "S3"
          },
          "InstanceType": {},
          "AutoScalingType": {},
          "Hostname": {},
          "Os": {},
          "AmiId": {},
          "SshKeyName": {},
          "Architecture": {},
          "InstallUpdatesOnBoot": {
            "type": "boolean"
          },
          "EbsOptimized": {
            "type": "boolean"
          },
          "AgentVersion": {}
        }
      }
    },
    "UpdateLayer": {
      "input": {
        "type": "structure",
        "required": [
          "LayerId"
        ],
        "members": {
          "LayerId": {},
          "Name": {},
          "Shortname": {},
          "Attributes": {
            "shape": "S17"
          },
          "CloudWatchLogsConfiguration": {
            "shape": "S19"
          },
          "CustomInstanceProfileArn": {},
          "CustomJson": {},
          "CustomSecurityGroupIds": {
            "shape": "S3"
          },
          "Packages": {
            "shape": "S3"
          },
          "VolumeConfigurations": {
            "shape": "S1f"
          },
          "EnableAutoHealing": {
            "type": "boolean"
          },
          "AutoAssignElasticIps": {
            "type": "boolean"
          },
          "AutoAssignPublicIps": {
            "type": "boolean"
          },
          "CustomRecipes": {
            "shape": "S1h"
          },
          "InstallUpdatesOnBoot": {
            "type": "boolean"
          },
          "UseEbsOptimizedInstances": {
            "type": "boolean"
          },
          "LifecycleEventConfiguration": {
            "shape": "S1i"
          }
        }
      }
    },
    "UpdateMyUserProfile": {
      "input": {
        "type": "structure",
        "members": {
          "SshPublicKey": {}
        }
      }
    },
    "UpdateRdsDbInstance": {
      "input": {
        "type": "structure",
        "required": [
          "RdsDbInstanceArn"
        ],
        "members": {
          "RdsDbInstanceArn": {},
          "DbUser": {},
          "DbPassword": {}
        }
      }
    },
    "UpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackId"
        ],
        "members": {
          "StackId": {},
          "Name": {},
          "Attributes": {
            "shape": "S8"
          },
          "ServiceRoleArn": {},
          "DefaultInstanceProfileArn": {},
          "DefaultOs": {},
          "HostnameTheme": {},
          "DefaultAvailabilityZone": {},
          "DefaultSubnetId": {},
          "CustomJson": {},
          "ConfigurationManager": {
            "shape": "Sa"
          },
          "ChefConfiguration": {
            "shape": "Sb"
          },
          "UseCustomCookbooks": {
            "type": "boolean"
          },
          "CustomCookbooksSource": {
            "shape": "Sd"
          },
          "DefaultSshKeyName": {},
          "DefaultRootDeviceType": {},
          "UseOpsworksSecurityGroups": {
            "type": "boolean"
          },
          "AgentVersion": {}
        }
      }
    },
    "UpdateUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamUserArn"
        ],
        "members": {
          "IamUserArn": {},
          "SshUsername": {},
          "SshPublicKey": {},
          "AllowSelfManagement": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {},
          "Name": {},
          "MountPoint": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S8": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sa": {
      "type": "structure",
      "members": {
        "Name": {},
        "Version": {}
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "ManageBerkshelf": {
          "type": "boolean"
        },
        "BerkshelfVersion": {}
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Type": {},
        "Url": {},
        "Username": {},
        "Password": {},
        "SshKey": {},
        "Revision": {}
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Arn": {},
          "DatabaseName": {}
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "Certificate",
        "PrivateKey"
      ],
      "members": {
        "Certificate": {},
        "PrivateKey": {},
        "Chain": {}
      }
    },
    "Sm": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {},
          "Secure": {
            "type": "boolean"
          }
        }
      }
    },
    "Ss": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Args": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S3"
          }
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DeviceName": {},
          "NoDevice": {},
          "VirtualName": {},
          "Ebs": {
            "type": "structure",
            "members": {
              "SnapshotId": {},
              "Iops": {
                "type": "integer"
              },
              "VolumeSize": {
                "type": "integer"
              },
              "VolumeType": {},
              "DeleteOnTermination": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S17": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S19": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogStreams": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "LogGroupName": {},
              "DatetimeFormat": {},
              "TimeZone": {},
              "File": {},
              "FileFingerprintLines": {},
              "MultiLineStartPattern": {},
              "InitialPosition": {},
              "Encoding": {},
              "BufferDuration": {
                "type": "integer"
              },
              "BatchCount": {
                "type": "integer"
              },
              "BatchSize": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "MountPoint",
          "NumberOfDisks",
          "Size"
        ],
        "members": {
          "MountPoint": {},
          "RaidLevel": {
            "type": "integer"
          },
          "NumberOfDisks": {
            "type": "integer"
          },
          "Size": {
            "type": "integer"
          },
          "VolumeType": {},
          "Iops": {
            "type": "integer"
          },
          "Encrypted": {
            "type": "boolean"
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Setup": {
          "shape": "S3"
        },
        "Configure": {
          "shape": "S3"
        },
        "Deploy": {
          "shape": "S3"
        },
        "Undeploy": {
          "shape": "S3"
        },
        "Shutdown": {
          "shape": "S3"
        }
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "Shutdown": {
          "type": "structure",
          "members": {
            "ExecutionTimeout": {
              "type": "integer"
            },
            "DelayUntilElbConnectionsDrained": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "InstanceCount": {
          "type": "integer"
        },
        "ThresholdsWaitTime": {
          "type": "integer"
        },
        "IgnoreMetricsTime": {
          "type": "integer"
        },
        "CpuThreshold": {
          "type": "double"
        },
        "MemoryThreshold": {
          "type": "double"
        },
        "LoadThreshold": {
          "type": "double"
        },
        "Alarms": {
          "shape": "S3"
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "Monday": {
          "shape": "S4c"
        },
        "Tuesday": {
          "shape": "S4c"
        },
        "Wednesday": {
          "shape": "S4c"
        },
        "Thursday": {
          "shape": "S4c"
        },
        "Friday": {
          "shape": "S4c"
        },
        "Saturday": {
          "shape": "S4c"
        },
        "Sunday": {
          "shape": "S4c"
        }
      }
    },
    "S4c": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S50": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}
},{}],160:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeApps": {
      "result_key": "Apps"
    },
    "DescribeCommands": {
      "result_key": "Commands"
    },
    "DescribeDeployments": {
      "result_key": "Deployments"
    },
    "DescribeEcsClusters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EcsClusters"
    },
    "DescribeElasticIps": {
      "result_key": "ElasticIps"
    },
    "DescribeElasticLoadBalancers": {
      "result_key": "ElasticLoadBalancers"
    },
    "DescribeInstances": {
      "result_key": "Instances"
    },
    "DescribeLayers": {
      "result_key": "Layers"
    },
    "DescribeLoadBasedAutoScaling": {
      "result_key": "LoadBasedAutoScalingConfigurations"
    },
    "DescribePermissions": {
      "result_key": "Permissions"
    },
    "DescribeRaidArrays": {
      "result_key": "RaidArrays"
    },
    "DescribeServiceErrors": {
      "result_key": "ServiceErrors"
    },
    "DescribeStacks": {
      "result_key": "Stacks"
    },
    "DescribeTimeBasedAutoScaling": {
      "result_key": "TimeBasedAutoScalingConfigurations"
    },
    "DescribeUserProfiles": {
      "result_key": "UserProfiles"
    },
    "DescribeVolumes": {
      "result_key": "Volumes"
    }
  }
}
},{}],161:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "AppExists": {
      "delay": 1,
      "operation": "DescribeApps",
      "maxAttempts": 40,
      "acceptors": [
        {
          "expected": 200,
          "matcher": "status",
          "state": "success"
        },
        {
          "matcher": "status",
          "expected": 400,
          "state": "failure"
        }
      ]
    },
    "DeploymentSuccessful": {
      "delay": 15,
      "operation": "DescribeDeployments",
      "maxAttempts": 40,
      "description": "Wait until a deployment has completed successfully.",
      "acceptors": [
        {
          "expected": "successful",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Deployments[].Status"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Deployments[].Status"
        }
      ]
    },
    "InstanceOnline": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "description": "Wait until OpsWorks instance is online.",
      "acceptors": [
        {
          "expected": "online",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Instances[].Status"
        },
        {
          "expected": "setup_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "shutting_down",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "start_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stopped",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stopping",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "terminating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "terminated",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stop_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        }
      ]
    },
    "InstanceRegistered": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "description": "Wait until OpsWorks instance is registered.",
      "acceptors": [
        {
          "expected": "registered",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Instances[].Status"
        },
        {
          "expected": "setup_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "shutting_down",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stopped",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stopping",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "terminating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "terminated",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stop_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        }
      ]
    },
    "InstanceStopped": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "description": "Wait until OpsWorks instance is stopped.",
      "acceptors": [
        {
          "expected": "stopped",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Instances[].Status"
        },
        {
          "expected": "booting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "pending",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "requested",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "running_setup",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "setup_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "start_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "stop_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        }
      ]
    },
    "InstanceTerminated": {
      "delay": 15,
      "operation": "DescribeInstances",
      "maxAttempts": 40,
      "description": "Wait until OpsWorks instance is terminated.",
      "acceptors": [
        {
          "expected": "terminated",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Instances[].Status"
        },
        {
          "expected": "ResourceNotFoundException",
          "matcher": "error",
          "state": "success"
        },
        {
          "expected": "booting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "online",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "pending",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "requested",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "running_setup",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "setup_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        },
        {
          "expected": "start_failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Instances[].Status"
        }
      ]
    }
  }
}

},{}],162:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-22",
    "endpointPrefix": "personalize",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Personalize",
    "serviceId": "Personalize",
    "signatureVersion": "v4",
    "signingName": "personalize",
    "targetPrefix": "AmazonPersonalize",
    "uid": "personalize-2018-05-22"
  },
  "operations": {
    "CreateBatchInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "solutionVersionArn",
          "jobInput",
          "jobOutput",
          "roleArn"
        ],
        "members": {
          "jobName": {},
          "solutionVersionArn": {},
          "filterArn": {},
          "numResults": {
            "type": "integer"
          },
          "jobInput": {
            "shape": "S5"
          },
          "jobOutput": {
            "shape": "S9"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJobArn": {}
        }
      }
    },
    "CreateCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "solutionVersionArn",
          "minProvisionedTPS"
        ],
        "members": {
          "name": {},
          "solutionVersionArn": {},
          "minProvisionedTPS": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    },
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "schemaArn",
          "datasetGroupArn",
          "datasetType"
        ],
        "members": {
          "name": {},
          "schemaArn": {},
          "datasetGroupArn": {},
          "datasetType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetArn": {}
        }
      },
      "idempotent": true
    },
    "CreateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "roleArn": {},
          "kmsKeyArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {}
        }
      }
    },
    "CreateDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "datasetArn",
          "dataSource",
          "roleArn"
        ],
        "members": {
          "jobName": {},
          "datasetArn": {},
          "dataSource": {
            "shape": "Sl"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJobArn": {}
        }
      }
    },
    "CreateEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn"
        ],
        "members": {
          "name": {},
          "datasetGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTrackerArn": {},
          "trackingId": {}
        }
      },
      "idempotent": true
    },
    "CreateFilter": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn",
          "filterExpression"
        ],
        "members": {
          "name": {},
          "datasetGroupArn": {},
          "filterExpression": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "filterArn": {}
        }
      }
    },
    "CreateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "schema"
        ],
        "members": {
          "name": {},
          "schema": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schemaArn": {}
        }
      },
      "idempotent": true
    },
    "CreateSolution": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn"
        ],
        "members": {
          "name": {},
          "performHPO": {
            "type": "boolean"
          },
          "performAutoML": {
            "type": "boolean"
          },
          "recipeArn": {},
          "datasetGroupArn": {},
          "eventType": {},
          "solutionConfig": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionArn": {}
        }
      }
    },
    "CreateSolutionVersion": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {},
          "trainingMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {}
        }
      }
    },
    "DeleteCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "datasetArn"
        ],
        "members": {
          "datasetArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "datasetGroupArn"
        ],
        "members": {
          "datasetGroupArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "eventTrackerArn"
        ],
        "members": {
          "eventTrackerArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterArn"
        ],
        "members": {
          "filterArn": {}
        }
      }
    },
    "DeleteSchema": {
      "input": {
        "type": "structure",
        "required": [
          "schemaArn"
        ],
        "members": {
          "schemaArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteSolution": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {}
        }
      },
      "idempotent": true
    },
    "DescribeAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "algorithmArn"
        ],
        "members": {
          "algorithmArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "algorithm": {
            "type": "structure",
            "members": {
              "name": {},
              "algorithmArn": {},
              "algorithmImage": {
                "type": "structure",
                "required": [
                  "dockerURI"
                ],
                "members": {
                  "name": {},
                  "dockerURI": {}
                }
              },
              "defaultHyperParameters": {
                "shape": "S1n"
              },
              "defaultHyperParameterRanges": {
                "type": "structure",
                "members": {
                  "integerHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "minValue": {
                          "type": "integer"
                        },
                        "maxValue": {
                          "type": "integer"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  },
                  "continuousHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "minValue": {
                          "type": "double"
                        },
                        "maxValue": {
                          "type": "double"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  },
                  "categoricalHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "values": {
                          "shape": "S1l"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              },
              "defaultResourceConfig": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "trainingInputMode": {},
              "roleArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeBatchInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "batchInferenceJobArn"
        ],
        "members": {
          "batchInferenceJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "batchInferenceJobArn": {},
              "filterArn": {},
              "failureReason": {},
              "solutionVersionArn": {},
              "numResults": {
                "type": "integer"
              },
              "jobInput": {
                "shape": "S5"
              },
              "jobOutput": {
                "shape": "S9"
              },
              "roleArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaign": {
            "type": "structure",
            "members": {
              "name": {},
              "campaignArn": {},
              "solutionVersionArn": {},
              "minProvisionedTPS": {
                "type": "integer"
              },
              "status": {},
              "failureReason": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "latestCampaignUpdate": {
                "type": "structure",
                "members": {
                  "solutionVersionArn": {},
                  "minProvisionedTPS": {
                    "type": "integer"
                  },
                  "status": {},
                  "failureReason": {},
                  "creationDateTime": {
                    "type": "timestamp"
                  },
                  "lastUpdatedDateTime": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "datasetArn"
        ],
        "members": {
          "datasetArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataset": {
            "type": "structure",
            "members": {
              "name": {},
              "datasetArn": {},
              "datasetGroupArn": {},
              "datasetType": {},
              "schemaArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "datasetGroupArn"
        ],
        "members": {
          "datasetGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroup": {
            "type": "structure",
            "members": {
              "name": {},
              "datasetGroupArn": {},
              "status": {},
              "roleArn": {},
              "kmsKeyArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "failureReason": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "datasetImportJobArn"
        ],
        "members": {
          "datasetImportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "datasetImportJobArn": {},
              "datasetArn": {},
              "dataSource": {
                "shape": "Sl"
              },
              "roleArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "failureReason": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "eventTrackerArn"
        ],
        "members": {
          "eventTrackerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTracker": {
            "type": "structure",
            "members": {
              "name": {},
              "eventTrackerArn": {},
              "accountId": {},
              "trackingId": {},
              "datasetGroupArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeFeatureTransformation": {
      "input": {
        "type": "structure",
        "required": [
          "featureTransformationArn"
        ],
        "members": {
          "featureTransformationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "featureTransformation": {
            "type": "structure",
            "members": {
              "name": {},
              "featureTransformationArn": {},
              "defaultParameters": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "status": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterArn"
        ],
        "members": {
          "filterArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "filterArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "datasetGroupArn": {},
              "failureReason": {},
              "filterExpression": {
                "shape": "Sr"
              },
              "status": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeRecipe": {
      "input": {
        "type": "structure",
        "required": [
          "recipeArn"
        ],
        "members": {
          "recipeArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recipe": {
            "type": "structure",
            "members": {
              "name": {},
              "recipeArn": {},
              "algorithmArn": {},
              "featureTransformationArn": {},
              "status": {},
              "description": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "recipeType": {},
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSchema": {
      "input": {
        "type": "structure",
        "required": [
          "schemaArn"
        ],
        "members": {
          "schemaArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schema": {
            "type": "structure",
            "members": {
              "name": {},
              "schemaArn": {},
              "schema": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSolution": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solution": {
            "type": "structure",
            "members": {
              "name": {},
              "solutionArn": {},
              "performHPO": {
                "type": "boolean"
              },
              "performAutoML": {
                "type": "boolean"
              },
              "recipeArn": {},
              "datasetGroupArn": {},
              "eventType": {},
              "solutionConfig": {
                "shape": "S10"
              },
              "autoMLResult": {
                "type": "structure",
                "members": {
                  "bestRecipeArn": {}
                }
              },
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "latestSolutionVersion": {
                "shape": "S3p"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSolutionVersion": {
      "input": {
        "type": "structure",
        "required": [
          "solutionVersionArn"
        ],
        "members": {
          "solutionVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersion": {
            "type": "structure",
            "members": {
              "solutionVersionArn": {},
              "solutionArn": {},
              "performHPO": {
                "type": "boolean"
              },
              "performAutoML": {
                "type": "boolean"
              },
              "recipeArn": {},
              "eventType": {},
              "datasetGroupArn": {},
              "solutionConfig": {
                "shape": "S10"
              },
              "trainingHours": {
                "type": "double"
              },
              "trainingMode": {},
              "tunedHPOParams": {
                "type": "structure",
                "members": {
                  "algorithmHyperParameters": {
                    "shape": "S1n"
                  }
                }
              },
              "status": {},
              "failureReason": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "GetSolutionMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "solutionVersionArn"
        ],
        "members": {
          "solutionVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {},
          "metrics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "double"
            }
          }
        }
      }
    },
    "ListBatchInferenceJobs": {
      "input": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "batchInferenceJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {},
                "solutionVersionArn": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListCampaigns": {
      "input": {
        "type": "structure",
        "members": {
          "solutionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaigns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "campaignArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "datasetGroupArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetImportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "datasetArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datasetImportJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "datasetArn": {},
                "datasetType": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListEventTrackers": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTrackers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "eventTrackerArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListFilters": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "filterArn": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "datasetGroupArn": {},
                "failureReason": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListRecipes": {
      "input": {
        "type": "structure",
        "members": {
          "recipeProvider": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recipes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "recipeArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSchemas": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "schemaArn": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSolutionVersions": {
      "input": {
        "type": "structure",
        "members": {
          "solutionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersions": {
            "type": "list",
            "member": {
              "shape": "S3p"
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSolutions": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "solutionArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "UpdateCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {},
          "solutionVersionArn": {},
          "minProvisionedTPS": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "s3DataSource"
      ],
      "members": {
        "s3DataSource": {
          "shape": "S6"
        }
      }
    },
    "S6": {
      "type": "structure",
      "required": [
        "path"
      ],
      "members": {
        "path": {},
        "kmsKeyArn": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "s3DataDestination"
      ],
      "members": {
        "s3DataDestination": {
          "shape": "S6"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "dataLocation": {}
      }
    },
    "Sr": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "structure",
      "members": {
        "eventValueThreshold": {},
        "hpoConfig": {
          "type": "structure",
          "members": {
            "hpoObjective": {
              "type": "structure",
              "members": {
                "type": {},
                "metricName": {},
                "metricRegex": {}
              }
            },
            "hpoResourceConfig": {
              "type": "structure",
              "members": {
                "maxNumberOfTrainingJobs": {},
                "maxParallelTrainingJobs": {}
              }
            },
            "algorithmHyperParameterRanges": {
              "type": "structure",
              "members": {
                "integerHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "minValue": {
                        "type": "integer"
                      },
                      "maxValue": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "continuousHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "minValue": {
                        "type": "double"
                      },
                      "maxValue": {
                        "type": "double"
                      }
                    }
                  }
                },
                "categoricalHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "values": {
                        "shape": "S1l"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "algorithmHyperParameters": {
          "shape": "S1n"
        },
        "featureTransformationParameters": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "autoMLConfig": {
          "type": "structure",
          "members": {
            "metricName": {},
            "recipeList": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1n": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3p": {
      "type": "structure",
      "members": {
        "solutionVersionArn": {},
        "status": {},
        "creationDateTime": {
          "type": "timestamp"
        },
        "lastUpdatedDateTime": {
          "type": "timestamp"
        },
        "failureReason": {}
      }
    }
  }
}
},{}],163:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListBatchInferenceJobs": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "batchInferenceJobs"
    },
    "ListCampaigns": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "campaigns"
    },
    "ListDatasetGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasetGroups"
    },
    "ListDatasetImportJobs": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasetImportJobs"
    },
    "ListDatasets": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasets"
    },
    "ListEventTrackers": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "eventTrackers"
    },
    "ListRecipes": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "recipes"
    },
    "ListSchemas": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "schemas"
    },
    "ListSolutionVersions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "solutionVersions"
    },
    "ListSolutions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "solutions"
    }
  }
}
},{}],164:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-03-22",
    "endpointPrefix": "personalize-events",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Personalize Events",
    "serviceId": "Personalize Events",
    "signatureVersion": "v4",
    "signingName": "personalize",
    "uid": "personalize-events-2018-03-22"
  },
  "operations": {
    "PutEvents": {
      "http": {
        "requestUri": "/events"
      },
      "input": {
        "type": "structure",
        "required": [
          "trackingId",
          "sessionId",
          "eventList"
        ],
        "members": {
          "trackingId": {},
          "userId": {},
          "sessionId": {},
          "eventList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "eventType",
                "properties",
                "sentAt"
              ],
              "members": {
                "eventId": {},
                "eventType": {},
                "properties": {
                  "jsonvalue": true
                },
                "sentAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {}
}
},{}],165:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],166:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-22",
    "endpointPrefix": "personalize-runtime",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Personalize Runtime",
    "serviceId": "Personalize Runtime",
    "signatureVersion": "v4",
    "signingName": "personalize",
    "uid": "personalize-runtime-2018-05-22"
  },
  "operations": {
    "GetPersonalizedRanking": {
      "http": {
        "requestUri": "/personalize-ranking"
      },
      "input": {
        "type": "structure",
        "required": [
          "campaignArn",
          "inputList",
          "userId"
        ],
        "members": {
          "campaignArn": {},
          "inputList": {
            "type": "list",
            "member": {}
          },
          "userId": {},
          "context": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "personalizedRanking": {
            "shape": "Sa"
          }
        }
      },
      "idempotent": true
    },
    "GetRecommendations": {
      "http": {
        "requestUri": "/recommendations"
      },
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {},
          "itemId": {},
          "userId": {},
          "numResults": {
            "type": "integer"
          },
          "context": {
            "shape": "S6"
          },
          "filterArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "itemList": {
            "shape": "Sa"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S6": {
      "type": "map",
      "key": {},
      "value": {
        "type": "string",
        "sensitive": true
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "itemId": {},
          "score": {
            "type": "double"
          }
        }
      }
    }
  }
}
},{}],167:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],168:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-10",
    "endpointPrefix": "polly",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Polly",
    "serviceId": "Polly",
    "signatureVersion": "v4",
    "uid": "polly-2016-06-10"
  },
  "operations": {
    "DeleteLexicon": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeVoices": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/voices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Engine": {
            "location": "querystring",
            "locationName": "Engine"
          },
          "LanguageCode": {
            "location": "querystring",
            "locationName": "LanguageCode"
          },
          "IncludeAdditionalLanguageCodes": {
            "location": "querystring",
            "locationName": "IncludeAdditionalLanguageCodes",
            "type": "boolean"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Voices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Gender": {},
                "Id": {},
                "LanguageCode": {},
                "LanguageName": {},
                "Name": {},
                "AdditionalLanguageCodes": {
                  "type": "list",
                  "member": {}
                },
                "SupportedEngines": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetLexicon": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lexicon": {
            "type": "structure",
            "members": {
              "Content": {},
              "Name": {
                "shape": "S2"
              }
            }
          },
          "LexiconAttributes": {
            "shape": "Sm"
          }
        }
      }
    },
    "GetSpeechSynthesisTask": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/synthesisTasks/{TaskId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TaskId"
        ],
        "members": {
          "TaskId": {
            "location": "uri",
            "locationName": "TaskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SynthesisTask": {
            "shape": "Sv"
          }
        }
      }
    },
    "ListLexicons": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/lexicons",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lexicons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "S2"
                },
                "Attributes": {
                  "shape": "Sm"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSpeechSynthesisTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/synthesisTasks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "Status": {
            "location": "querystring",
            "locationName": "Status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "SynthesisTasks": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          }
        }
      }
    },
    "PutLexicon": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Content"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          },
          "Content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartSpeechSynthesisTask": {
      "http": {
        "requestUri": "/v1/synthesisTasks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OutputFormat",
          "OutputS3BucketName",
          "Text",
          "VoiceId"
        ],
        "members": {
          "Engine": {},
          "LanguageCode": {},
          "LexiconNames": {
            "shape": "S12"
          },
          "OutputFormat": {},
          "OutputS3BucketName": {},
          "OutputS3KeyPrefix": {},
          "SampleRate": {},
          "SnsTopicArn": {},
          "SpeechMarkTypes": {
            "shape": "S15"
          },
          "Text": {},
          "TextType": {},
          "VoiceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SynthesisTask": {
            "shape": "Sv"
          }
        }
      }
    },
    "SynthesizeSpeech": {
      "http": {
        "requestUri": "/v1/speech",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OutputFormat",
          "Text",
          "VoiceId"
        ],
        "members": {
          "Engine": {},
          "LanguageCode": {},
          "LexiconNames": {
            "shape": "S12"
          },
          "OutputFormat": {},
          "SampleRate": {},
          "SpeechMarkTypes": {
            "shape": "S15"
          },
          "Text": {},
          "TextType": {},
          "VoiceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AudioStream": {
            "type": "blob",
            "streaming": true
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "RequestCharacters": {
            "location": "header",
            "locationName": "x-amzn-RequestCharacters",
            "type": "integer"
          }
        },
        "payload": "AudioStream"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "string",
      "sensitive": true
    },
    "Sm": {
      "type": "structure",
      "members": {
        "Alphabet": {},
        "LanguageCode": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LexiconArn": {},
        "LexemesCount": {
          "type": "integer"
        },
        "Size": {
          "type": "integer"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Engine": {},
        "TaskId": {},
        "TaskStatus": {},
        "TaskStatusReason": {},
        "OutputUri": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "RequestCharacters": {
          "type": "integer"
        },
        "SnsTopicArn": {},
        "LexiconNames": {
          "shape": "S12"
        },
        "OutputFormat": {},
        "SampleRate": {},
        "SpeechMarkTypes": {
          "shape": "S15"
        },
        "TextType": {},
        "VoiceId": {},
        "LanguageCode": {}
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "shape": "S2"
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],169:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListSpeechSynthesisTasks": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],170:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-15",
    "endpointPrefix": "api.pricing",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Pricing",
    "serviceFullName": "AWS Price List Service",
    "serviceId": "Pricing",
    "signatureVersion": "v4",
    "signingName": "pricing",
    "targetPrefix": "AWSPriceListService",
    "uid": "pricing-2017-10-15"
  },
  "operations": {
    "DescribeServices": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceCode": {},
          "FormatVersion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Services": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceCode": {},
                "AttributeNames": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "FormatVersion": {},
          "NextToken": {}
        }
      }
    },
    "GetAttributeValues": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "AttributeName"
        ],
        "members": {
          "ServiceCode": {},
          "AttributeName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttributeValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetProducts": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceCode": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Type",
                "Field",
                "Value"
              ],
              "members": {
                "Type": {},
                "Field": {},
                "Value": {}
              }
            }
          },
          "FormatVersion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FormatVersion": {},
          "PriceList": {
            "type": "list",
            "member": {
              "jsonvalue": true
            }
          },
          "NextToken": {}
        }
      }
    }
  },
  "shapes": {}
}
},{}],171:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeServices": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "GetAttributeValues": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "GetProducts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],172:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-01-10",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "serviceId": "RDS",
    "signatureVersion": "v4",
    "uid": "rds-2013-01-10",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2013-01-10/"
  },
  "operations": {
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "AllocatedStorage",
          "DBInstanceClass",
          "Engine",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "S1c"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "SourceIds": {
            "shape": "S5"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {}
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1o"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S25"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S25",
                    "locationName": "CharacterSet"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "St",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S1c",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S2f"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S11",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "DBParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2f"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S6"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S6"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S6"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "S1o",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "Vpc": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S14",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S3m",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S3o"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {}
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S9"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "shape": "S3z",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "DBSecurityGroupMemberships": {
                  "shape": "Sp"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "Sq"
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1o"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S3m"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "shape": "S3z",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBInstanceIdentifier",
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Id": {},
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S5"
        },
        "EventCategoriesList": {
          "shape": "S6"
        },
        "Enabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        }
      },
      "wrapper": true
    },
    "Sp": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "Sv"
        },
        "VpcSecurityGroups": {
          "shape": "Sx"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S11"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {}
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMembership": {
          "type": "structure",
          "members": {
            "OptionGroupName": {},
            "Status": {}
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "Sv": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S14"
              },
              "SubnetStatus": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "S14": {
      "type": "structure",
      "members": {
        "Name": {},
        "ProvisionedIopsCapable": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S1c": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {}
      },
      "wrapper": true
    },
    "S1i": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Port": {
                "type": "integer"
              },
              "DBSecurityGroupMemberships": {
                "shape": "Sv"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "Sx"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {}
      },
      "wrapper": true
    },
    "S25": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {}
        }
      }
    },
    "S3m": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S3o"
        }
      },
      "wrapper": true
    },
    "S3o": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S3z": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}
},{}],173:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeDBEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBEngineVersions"
    },
    "DescribeDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstances"
    },
    "DescribeDBParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBParameterGroups"
    },
    "DescribeDBParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSecurityGroups"
    },
    "DescribeDBSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSnapshots"
    },
    "DescribeDBSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeOptionGroupOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupOptions"
    },
    "DescribeOptionGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupsList"
    },
    "DescribeOrderableDBInstanceOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableDBInstanceOptions"
    },
    "DescribeReservedDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstances"
    },
    "DescribeReservedDBInstancesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstancesOfferings"
    },
    "ListTagsForResource": {
      "result_key": "TagList"
    }
  }
}
},{}],174:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-02-12",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "serviceId": "RDS",
    "signatureVersion": "v4",
    "uid": "rds-2013-02-12",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2013-02-12/"
  },
  "operations": {
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "AllocatedStorage",
          "DBInstanceClass",
          "Engine",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "SourceIds": {
            "shape": "S5"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {}
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1p"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S28"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S28",
                    "locationName": "CharacterSet"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "St",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBLogFiles": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "FilenameContains": {},
          "FileLastWritten": {
            "type": "long"
          },
          "FileSize": {
            "type": "long"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBLogFilesResult",
        "type": "structure",
        "members": {
          "DescribeDBLogFiles": {
            "type": "list",
            "member": {
              "locationName": "DescribeDBLogFilesDetails",
              "type": "structure",
              "members": {
                "LogFileName": {},
                "LastWritten": {
                  "type": "long"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S1d",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S2n"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S11",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "DBParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2n"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S6"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S6"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S6"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                },
                "Persistent": {
                  "type": "boolean"
                },
                "OptionGroupOptionSettings": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionGroupOptionSetting",
                    "type": "structure",
                    "members": {
                      "SettingName": {},
                      "SettingDescription": {},
                      "DefaultValue": {},
                      "ApplyType": {},
                      "AllowedValues": {},
                      "IsModifiable": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "S1p",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "Vpc": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S14",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S3w",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S3y"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DownloadDBLogFilePortion": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "LogFileName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "LogFileName": {},
          "Marker": {},
          "NumberOfLines": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DownloadDBLogFilePortionResult",
        "type": "structure",
        "members": {
          "LogFileData": {},
          "Marker": {},
          "AdditionalDataPending": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {}
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S9"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S2n"
          }
        }
      },
      "output": {
        "shape": "S4b",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "DBSecurityGroupMemberships": {
                  "shape": "Sp"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "Sq"
                },
                "OptionSettings": {
                  "type": "list",
                  "member": {
                    "shape": "S1t",
                    "locationName": "OptionSetting"
                  }
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1p"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S3w"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S2n"
          }
        }
      },
      "output": {
        "shape": "S4b",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBInstanceIdentifier",
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S5"
        },
        "EventCategoriesList": {
          "shape": "S6"
        },
        "Enabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {}
      },
      "wrapper": true
    },
    "Sp": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "Sv"
        },
        "VpcSecurityGroups": {
          "shape": "Sx"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S11"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {}
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "OptionGroupMembership",
            "type": "structure",
            "members": {
              "OptionGroupName": {},
              "Status": {}
            }
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "Sv": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S14"
              },
              "SubnetStatus": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "S14": {
      "type": "structure",
      "members": {
        "Name": {},
        "ProvisionedIopsCapable": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S1d": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {}
      },
      "wrapper": true
    },
    "S1j": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Persistent": {
                "type": "boolean"
              },
              "Port": {
                "type": "integer"
              },
              "OptionSettings": {
                "type": "list",
                "member": {
                  "shape": "S1t",
                  "locationName": "OptionSetting"
                }
              },
              "DBSecurityGroupMemberships": {
                "shape": "Sv"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "Sx"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {}
      },
      "wrapper": true
    },
    "S1t": {
      "type": "structure",
      "members": {
        "Name": {},
        "Value": {},
        "DefaultValue": {},
        "Description": {},
        "ApplyType": {},
        "DataType": {},
        "AllowedValues": {},
        "IsModifiable": {
          "type": "boolean"
        },
        "IsCollection": {
          "type": "boolean"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S2n": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {}
        }
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S3y"
        }
      },
      "wrapper": true
    },
    "S3y": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}
},{}],175:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeDBEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBEngineVersions"
    },
    "DescribeDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstances"
    },
    "DescribeDBLogFiles": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DescribeDBLogFiles"
    },
    "DescribeDBParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBParameterGroups"
    },
    "DescribeDBParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSecurityGroups"
    },
    "DescribeDBSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSnapshots"
    },
    "DescribeDBSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeOptionGroupOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupOptions"
    },
    "DescribeOptionGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupsList"
    },
    "DescribeOrderableDBInstanceOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableDBInstanceOptions"
    },
    "DescribeReservedDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstances"
    },
    "DescribeReservedDBInstancesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstancesOfferings"
    },
    "DownloadDBLogFilePortion": {
      "input_token": "Marker",
      "limit_key": "NumberOfLines",
      "more_results": "AdditionalDataPending",
      "output_token": "Marker",
      "result_key": "LogFileData"
    },
    "ListTagsForResource": {
      "result_key": "TagList"
    }
  }
}
},{}],176:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-09-09",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "serviceId": "RDS",
    "signatureVersion": "v4",
    "uid": "rds-2013-09-09",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2013-09-09/"
  },
  "operations": {
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "AllocatedStorage",
          "DBInstanceClass",
          "Engine",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          },
          "DBSubnetGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1l"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "SourceIds": {
            "shape": "S5"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1r"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sk"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S2d"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S2d",
                    "locationName": "CharacterSet"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "St",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBLogFiles": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "FilenameContains": {},
          "FileLastWritten": {
            "type": "long"
          },
          "FileSize": {
            "type": "long"
          },
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBLogFilesResult",
        "type": "structure",
        "members": {
          "DescribeDBLogFiles": {
            "type": "list",
            "member": {
              "locationName": "DescribeDBLogFilesDetails",
              "type": "structure",
              "members": {
                "LogFileName": {},
                "LastWritten": {
                  "type": "long"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S1f",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S2s"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S11",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "DBParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2s"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S6"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S6"
          },
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S6"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                },
                "Persistent": {
                  "type": "boolean"
                },
                "Permanent": {
                  "type": "boolean"
                },
                "OptionGroupOptionSettings": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionGroupOptionSetting",
                    "type": "structure",
                    "members": {
                      "SettingName": {},
                      "SettingDescription": {},
                      "DefaultValue": {},
                      "ApplyType": {},
                      "AllowedValues": {},
                      "IsModifiable": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Filters": {
            "shape": "S27"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "S1r",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "Vpc": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S14",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S41",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S27"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S43"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DownloadDBLogFilePortion": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "LogFileName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "LogFileName": {},
          "Marker": {},
          "NumberOfLines": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DownloadDBLogFilePortionResult",
        "type": "structure",
        "members": {
          "LogFileData": {},
          "Marker": {},
          "AdditionalDataPending": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {},
          "Filters": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S9"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "shape": "S4g",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S11"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "DBSecurityGroupMemberships": {
                  "shape": "Sp"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "Sq"
                },
                "OptionSettings": {
                  "type": "list",
                  "member": {
                    "shape": "S1v",
                    "locationName": "OptionSetting"
                  }
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1r"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S41"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "shape": "S4g",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBInstanceIdentifier",
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "St"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S5"
        },
        "EventCategoriesList": {
          "shape": "S6"
        },
        "Enabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {},
        "PercentProgress": {
          "type": "integer"
        },
        "SourceRegion": {}
      },
      "wrapper": true
    },
    "Sp": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "Sv"
        },
        "VpcSecurityGroups": {
          "shape": "Sx"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S11"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {}
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "OptionGroupMembership",
            "type": "structure",
            "members": {
              "OptionGroupName": {},
              "Status": {}
            }
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "StatusInfos": {
          "type": "list",
          "member": {
            "locationName": "DBInstanceStatusInfo",
            "type": "structure",
            "members": {
              "StatusType": {},
              "Normal": {
                "type": "boolean"
              },
              "Status": {},
              "Message": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sv": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S14"
              },
              "SubnetStatus": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "S14": {
      "type": "structure",
      "members": {
        "Name": {},
        "ProvisionedIopsCapable": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S1f": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {}
      },
      "wrapper": true
    },
    "S1l": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Persistent": {
                "type": "boolean"
              },
              "Permanent": {
                "type": "boolean"
              },
              "Port": {
                "type": "integer"
              },
              "OptionSettings": {
                "type": "list",
                "member": {
                  "shape": "S1v",
                  "locationName": "OptionSetting"
                }
              },
              "DBSecurityGroupMemberships": {
                "shape": "Sv"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "Sx"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {}
      },
      "wrapper": true
    },
    "S1v": {
      "type": "structure",
      "members": {
        "Name": {},
        "Value": {},
        "DefaultValue": {},
        "Description": {},
        "ApplyType": {},
        "DataType": {},
        "AllowedValues": {},
        "IsModifiable": {
          "type": "boolean"
        },
        "IsCollection": {
          "type": "boolean"
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {
              "locationName": "Value"
            }
          }
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S2s": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {}
        }
      }
    },
    "S41": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S43"
        }
      },
      "wrapper": true
    },
    "S43": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S4g": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}
},{}],177:[function(require,module,exports){
arguments[4][175][0].apply(exports,arguments)
},{"dup":175}],178:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DBInstanceAvailable": {
      "delay": 30,
      "operation": "DescribeDBInstances",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-restore",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-parameters",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-parameters",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-restore",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        }
      ]
    },
    "DBInstanceDeleted": {
      "delay": 30,
      "operation": "DescribeDBInstances",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "deleted",
          "matcher": "pathAll",
          "state": "success",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "creating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "modifying",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "resetting-master-credentials",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        }
      ]
    }
  }
}

},{}],179:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-09-01",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "serviceId": "RDS",
    "signatureVersion": "v4",
    "uid": "rds-2014-09-01",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2014-09-01/"
  },
  "operations": {
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CopyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBParameterGroupIdentifier",
          "TargetDBParameterGroupIdentifier",
          "TargetDBParameterGroupDescription"
        ],
        "members": {
          "SourceDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "Sk"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sn"
          }
        }
      }
    },
    "CopyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceOptionGroupIdentifier",
          "TargetOptionGroupIdentifier",
          "TargetOptionGroupDescription"
        ],
        "members": {
          "SourceOptionGroupIdentifier": {},
          "TargetOptionGroupIdentifier": {},
          "TargetOptionGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "St"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "AllocatedStorage",
          "DBInstanceClass",
          "Engine",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "S13"
          },
          "VpcSecurityGroupIds": {
            "shape": "S14"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          },
          "DBSubnetGroupName": {},
          "StorageType": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sn"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1u"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S1b"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "SourceIds": {
            "shape": "S5"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "St"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "Sn"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S2h"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S2h",
                    "locationName": "CharacterSet"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "S17",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBLogFiles": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "FilenameContains": {},
          "FileLastWritten": {
            "type": "long"
          },
          "FileSize": {
            "type": "long"
          },
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBLogFilesResult",
        "type": "structure",
        "members": {
          "DescribeDBLogFiles": {
            "type": "list",
            "member": {
              "locationName": "DescribeDBLogFilesDetails",
              "type": "structure",
              "members": {
                "LogFileName": {},
                "LastWritten": {
                  "type": "long"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S2w"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "Sn",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S1b",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "DBParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2w"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S6"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S6"
          },
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S6"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                },
                "Persistent": {
                  "type": "boolean"
                },
                "Permanent": {
                  "type": "boolean"
                },
                "OptionGroupOptionSettings": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionGroupOptionSetting",
                    "type": "structure",
                    "members": {
                      "SettingName": {},
                      "SettingDescription": {},
                      "DefaultValue": {},
                      "ApplyType": {},
                      "AllowedValues": {},
                      "IsModifiable": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Filters": {
            "shape": "S2b"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "St",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "Vpc": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S1e",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                },
                "StorageType": {},
                "SupportsIops": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S45",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S2b"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S47"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DownloadDBLogFilePortion": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "LogFileName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "LogFileName": {},
          "Marker": {},
          "NumberOfLines": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DownloadDBLogFilePortionResult",
        "type": "structure",
        "members": {
          "LogFileData": {},
          "Marker": {},
          "AdditionalDataPending": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {},
          "Filters": {
            "shape": "S2b"
          }
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S9"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSecurityGroups": {
            "shape": "S13"
          },
          "VpcSecurityGroupIds": {
            "shape": "S14"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {},
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S2w"
          }
        }
      },
      "output": {
        "shape": "S4k",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1u"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S1b"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S6"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "DBSecurityGroupMemberships": {
                  "shape": "S13"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "S14"
                },
                "OptionSettings": {
                  "type": "list",
                  "member": {
                    "shape": "Sx",
                    "locationName": "OptionSetting"
                  }
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "St"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S45"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S4"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S2w"
          }
        }
      },
      "output": {
        "shape": "S4k",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "S9"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBInstanceIdentifier",
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "S9"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S17"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S5"
        },
        "EventCategoriesList": {
          "shape": "S6"
        },
        "Enabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {}
      },
      "wrapper": true
    },
    "Sn": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {},
        "PercentProgress": {
          "type": "integer"
        },
        "SourceRegion": {},
        "StorageType": {},
        "TdeCredentialArn": {}
      },
      "wrapper": true
    },
    "St": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Persistent": {
                "type": "boolean"
              },
              "Permanent": {
                "type": "boolean"
              },
              "Port": {
                "type": "integer"
              },
              "OptionSettings": {
                "type": "list",
                "member": {
                  "shape": "Sx",
                  "locationName": "OptionSetting"
                }
              },
              "DBSecurityGroupMemberships": {
                "shape": "Sy"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "S10"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {}
      },
      "wrapper": true
    },
    "Sx": {
      "type": "structure",
      "members": {
        "Name": {},
        "Value": {},
        "DefaultValue": {},
        "Description": {},
        "ApplyType": {},
        "DataType": {},
        "AllowedValues": {},
        "IsModifiable": {
          "type": "boolean"
        },
        "IsCollection": {
          "type": "boolean"
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "Sy"
        },
        "VpcSecurityGroups": {
          "shape": "S10"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S1b"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {},
            "StorageType": {}
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "OptionGroupMembership",
            "type": "structure",
            "members": {
              "OptionGroupName": {},
              "Status": {}
            }
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "StatusInfos": {
          "type": "list",
          "member": {
            "locationName": "DBInstanceStatusInfo",
            "type": "structure",
            "members": {
              "StatusType": {},
              "Normal": {
                "type": "boolean"
              },
              "Status": {},
              "Message": {}
            }
          }
        },
        "StorageType": {},
        "TdeCredentialArn": {}
      },
      "wrapper": true
    },
    "S1b": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S1e"
              },
              "SubnetStatus": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Name": {}
      },
      "wrapper": true
    },
    "S1u": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {
              "locationName": "Value"
            }
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S2w": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {}
        }
      }
    },
    "S45": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S47"
        }
      },
      "wrapper": true
    },
    "S47": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S4k": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}
},{}],180:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],181:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-10-31",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "serviceId": "RDS",
    "signatureVersion": "v4",
    "uid": "rds-2014-10-31",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2014-10-31/"
  },
  "operations": {
    "AddRoleToDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "RoleArn"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "RoleArn": {},
          "FeatureName": {}
        }
      }
    },
    "AddRoleToDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "RoleArn",
          "FeatureName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "RoleArn": {},
          "FeatureName": {}
        }
      }
    },
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S6"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      }
    },
    "ApplyPendingMaintenanceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIdentifier",
          "ApplyAction",
          "OptInType"
        ],
        "members": {
          "ResourceIdentifier": {},
          "ApplyAction": {},
          "OptInType": {}
        }
      },
      "output": {
        "resultWrapper": "ApplyPendingMaintenanceActionResult",
        "type": "structure",
        "members": {
          "ResourcePendingMaintenanceActions": {
            "shape": "Sf"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sl"
          }
        }
      }
    },
    "BacktrackDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "BacktrackTo"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "BacktrackTo": {
            "type": "timestamp"
          },
          "Force": {
            "type": "boolean"
          },
          "UseEarliestTimeOnPointInTimeUnavailable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Ss",
        "resultWrapper": "BacktrackDBClusterResult"
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskIdentifier"
        ],
        "members": {
          "ExportTaskIdentifier": {}
        }
      },
      "output": {
        "shape": "Su",
        "resultWrapper": "CancelExportTaskResult"
      }
    },
    "CopyDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBClusterParameterGroupIdentifier",
          "TargetDBClusterParameterGroupIdentifier",
          "TargetDBClusterParameterGroupDescription"
        ],
        "members": {
          "SourceDBClusterParameterGroupIdentifier": {},
          "TargetDBClusterParameterGroupIdentifier": {},
          "TargetDBClusterParameterGroupDescription": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "DBClusterParameterGroup": {
            "shape": "Sz"
          }
        }
      }
    },
    "CopyDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBClusterSnapshotIdentifier",
          "TargetDBClusterSnapshotIdentifier"
        ],
        "members": {
          "SourceDBClusterSnapshotIdentifier": {},
          "TargetDBClusterSnapshotIdentifier": {},
          "KmsKeyId": {},
          "PreSignedUrl": {},
          "CopyTags": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          },
          "SourceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "CopyDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "S12"
          }
        }
      }
    },
    "CopyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBParameterGroupIdentifier",
          "TargetDBParameterGroupIdentifier",
          "TargetDBParameterGroupDescription"
        ],
        "members": {
          "SourceDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupDescription": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "S16"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "Sb"
          },
          "CopyTags": {
            "type": "boolean"
          },
          "PreSignedUrl": {},
          "OptionGroupName": {},
          "SourceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S19"
          }
        }
      }
    },
    "CopyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceOptionGroupIdentifier",
          "TargetOptionGroupIdentifier",
          "TargetOptionGroupDescription"
        ],
        "members": {
          "SourceOptionGroupIdentifier": {},
          "TargetOptionGroupIdentifier": {},
          "TargetOptionGroupDescription": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateCustomAvailabilityZone": {
      "input": {
        "type": "structure",
        "required": [
          "CustomAvailabilityZoneName"
        ],
        "members": {
          "CustomAvailabilityZoneName": {},
          "ExistingVpnId": {},
          "NewVpnTunnelName": {},
          "VpnTunnelOriginatorIP": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCustomAvailabilityZoneResult",
        "type": "structure",
        "members": {
          "CustomAvailabilityZone": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "Engine"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S13"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "CharacterSetName": {},
          "DatabaseName": {},
          "DBClusterIdentifier": {},
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "DBSubnetGroupName": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "MasterUsername": {},
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "ReplicationSourceIdentifier": {},
          "Tags": {
            "shape": "Sb"
          },
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "PreSignedUrl": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "BacktrackWindow": {
            "type": "long"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "EngineMode": {},
          "ScalingConfiguration": {
            "shape": "S1x"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "GlobalClusterIdentifier": {},
          "EnableHttpEndpoint": {
            "type": "boolean"
          },
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Domain": {},
          "DomainIAMRoleName": {},
          "EnableGlobalWriteForwarding": {
            "type": "boolean"
          },
          "SourceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "CreateDBClusterEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "DBClusterEndpointIdentifier",
          "EndpointType"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "DBClusterEndpointIdentifier": {},
          "EndpointType": {},
          "StaticMembers": {
            "shape": "Sv"
          },
          "ExcludedMembers": {
            "shape": "Sv"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "shape": "S2e",
        "resultWrapper": "CreateDBClusterEndpointResult"
      }
    },
    "CreateDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "DBClusterParameterGroup": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier",
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {},
          "DBClusterIdentifier": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBInstanceClass",
          "Engine"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "S2k"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          },
          "DBClusterIdentifier": {},
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "MonitoringRoleArn": {},
          "DomainIAMRoleName": {},
          "PromotionTier": {
            "type": "integer"
          },
          "Timezone": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "EnablePerformanceInsights": {
            "type": "boolean"
          },
          "PerformanceInsightsKMSKeyId": {},
          "PerformanceInsightsRetentionPeriod": {
            "type": "integer"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "MaxAllocatedStorage": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "DBParameterGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          },
          "DBSubnetGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "StorageType": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "MonitoringRoleArn": {},
          "KmsKeyId": {},
          "PreSignedUrl": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "EnablePerformanceInsights": {
            "type": "boolean"
          },
          "PerformanceInsightsKMSKeyId": {},
          "PerformanceInsightsRetentionPeriod": {
            "type": "integer"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "UseDefaultProcessorFeatures": {
            "type": "boolean"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "Domain": {},
          "DomainIAMRoleName": {},
          "SourceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "S16"
          }
        }
      }
    },
    "CreateDBProxy": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName",
          "EngineFamily",
          "Auth",
          "RoleArn",
          "VpcSubnetIds"
        ],
        "members": {
          "DBProxyName": {},
          "EngineFamily": {},
          "Auth": {
            "shape": "S3a"
          },
          "RoleArn": {},
          "VpcSubnetIds": {
            "shape": "Sv"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sv"
          },
          "RequireTLS": {
            "type": "boolean"
          },
          "IdleClientTimeout": {
            "type": "integer"
          },
          "DebugLogging": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBProxyResult",
        "type": "structure",
        "members": {
          "DBProxy": {
            "shape": "S3f"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sl"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S19"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S3o"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S2q"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S8"
          },
          "SourceIds": {
            "shape": "S7"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S6"
          }
        }
      }
    },
    "CreateGlobalCluster": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalClusterIdentifier": {},
          "SourceDBClusterIdentifier": {},
          "Engine": {},
          "EngineVersion": {},
          "DeletionProtection": {
            "type": "boolean"
          },
          "DatabaseName": {},
          "StorageEncrypted": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateGlobalClusterResult",
        "type": "structure",
        "members": {
          "GlobalCluster": {
            "shape": "S3u"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "DeleteCustomAvailabilityZone": {
      "input": {
        "type": "structure",
        "required": [
          "CustomAvailabilityZoneId"
        ],
        "members": {
          "CustomAvailabilityZoneId": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteCustomAvailabilityZoneResult",
        "type": "structure",
        "members": {
          "CustomAvailabilityZone": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "DeleteDBClusterEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterEndpointIdentifier"
        ],
        "members": {
          "DBClusterEndpointIdentifier": {}
        }
      },
      "output": {
        "shape": "S2e",
        "resultWrapper": "DeleteDBClusterEndpointResult"
      }
    },
    "DeleteDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {}
        }
      }
    },
    "DeleteDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "S12"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {},
          "DeleteAutomatedBackups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "DeleteDBInstanceAutomatedBackup": {
      "input": {
        "type": "structure",
        "required": [
          "DbiResourceId"
        ],
        "members": {
          "DbiResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceAutomatedBackupResult",
        "type": "structure",
        "members": {
          "DBInstanceAutomatedBackup": {
            "shape": "S4c"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBProxy": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBProxyResult",
        "type": "structure",
        "members": {
          "DBProxy": {
            "shape": "S3f"
          }
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S19"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S6"
          }
        }
      }
    },
    "DeleteGlobalCluster": {
      "input": {
        "type": "structure",
        "required": [
          "GlobalClusterIdentifier"
        ],
        "members": {
          "GlobalClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteGlobalClusterResult",
        "type": "structure",
        "members": {
          "GlobalCluster": {
            "shape": "S3u"
          }
        }
      }
    },
    "DeleteInstallationMedia": {
      "input": {
        "type": "structure",
        "required": [
          "InstallationMediaId"
        ],
        "members": {
          "InstallationMediaId": {}
        }
      },
      "output": {
        "shape": "S4q",
        "resultWrapper": "DeleteInstallationMediaResult"
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DeregisterDBProxyTargets": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {},
          "TargetGroupName": {},
          "DBInstanceIdentifiers": {
            "shape": "Sv"
          },
          "DBClusterIdentifiers": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "resultWrapper": "DeregisterDBProxyTargetsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "DescribeAccountAttributesResult",
        "type": "structure",
        "members": {
          "AccountQuotas": {
            "type": "list",
            "member": {
              "locationName": "AccountQuota",
              "type": "structure",
              "members": {
                "AccountQuotaName": {},
                "Used": {
                  "type": "long"
                },
                "Max": {
                  "type": "long"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "CertificateIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCertificatesResult",
        "type": "structure",
        "members": {
          "Certificates": {
            "type": "list",
            "member": {
              "shape": "S56",
              "locationName": "Certificate"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeCustomAvailabilityZones": {
      "input": {
        "type": "structure",
        "members": {
          "CustomAvailabilityZoneId": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCustomAvailabilityZonesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CustomAvailabilityZones": {
            "type": "list",
            "member": {
              "shape": "S1q",
              "locationName": "CustomAvailabilityZone"
            }
          }
        }
      }
    },
    "DescribeDBClusterBacktracks": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "BacktrackIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterBacktracksResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterBacktracks": {
            "type": "list",
            "member": {
              "shape": "Ss",
              "locationName": "DBClusterBacktrack"
            }
          }
        }
      }
    },
    "DescribeDBClusterEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "DBClusterEndpointIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterEndpointsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterEndpoints": {
            "type": "list",
            "member": {
              "shape": "S2e",
              "locationName": "DBClusterEndpointList"
            }
          }
        }
      }
    },
    "DescribeDBClusterParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterParameterGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterParameterGroups": {
            "type": "list",
            "member": {
              "shape": "Sz",
              "locationName": "DBClusterParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S5l"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBClusterSnapshotAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterSnapshotAttributesResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshotAttributesResult": {
            "shape": "S5r"
          }
        }
      }
    },
    "DescribeDBClusterSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "DBClusterSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "IncludeShared": {
            "type": "boolean"
          },
          "IncludePublic": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterSnapshots": {
            "type": "list",
            "member": {
              "shape": "S12",
              "locationName": "DBClusterSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBClusters": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "IncludeShared": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusters": {
            "type": "list",
            "member": {
              "shape": "S1z",
              "locationName": "DBCluster"
            }
          }
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          },
          "ListSupportedTimezones": {
            "type": "boolean"
          },
          "IncludeAll": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S65"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S65",
                    "locationName": "CharacterSet"
                  }
                },
                "ValidUpgradeTarget": {
                  "type": "list",
                  "member": {
                    "locationName": "UpgradeTarget",
                    "type": "structure",
                    "members": {
                      "Engine": {},
                      "EngineVersion": {},
                      "Description": {},
                      "AutoUpgrade": {
                        "type": "boolean"
                      },
                      "IsMajorVersionUpgrade": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "SupportedTimezones": {
                  "type": "list",
                  "member": {
                    "locationName": "Timezone",
                    "type": "structure",
                    "members": {
                      "TimezoneName": {}
                    }
                  }
                },
                "ExportableLogTypes": {
                  "shape": "S1w"
                },
                "SupportsLogExportsToCloudwatchLogs": {
                  "type": "boolean"
                },
                "SupportsReadReplica": {
                  "type": "boolean"
                },
                "SupportedEngineModes": {
                  "shape": "S5o"
                },
                "SupportedFeatureNames": {
                  "type": "list",
                  "member": {}
                },
                "Status": {}
              }
            }
          }
        }
      }
    },
    "DescribeDBInstanceAutomatedBackups": {
      "input": {
        "type": "structure",
        "members": {
          "DbiResourceId": {},
          "DBInstanceIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstanceAutomatedBackupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstanceAutomatedBackups": {
            "type": "list",
            "member": {
              "shape": "S4c",
              "locationName": "DBInstanceAutomatedBackup"
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "S2m",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBLogFiles": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "FilenameContains": {},
          "FileLastWritten": {
            "type": "long"
          },
          "FileSize": {
            "type": "long"
          },
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBLogFilesResult",
        "type": "structure",
        "members": {
          "DescribeDBLogFiles": {
            "type": "list",
            "member": {
              "locationName": "DescribeDBLogFilesDetails",
              "type": "structure",
              "members": {
                "LogFileName": {},
                "LastWritten": {
                  "type": "long"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S16",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S5l"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBProxies": {
      "input": {
        "type": "structure",
        "members": {
          "DBProxyName": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBProxiesResult",
        "type": "structure",
        "members": {
          "DBProxies": {
            "type": "list",
            "member": {
              "shape": "S3f"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBProxyTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {},
          "TargetGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBProxyTargetGroupsResult",
        "type": "structure",
        "members": {
          "TargetGroups": {
            "type": "list",
            "member": {
              "shape": "S6y"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBProxyTargets": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {},
          "TargetGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBProxyTargetsResult",
        "type": "structure",
        "members": {
          "Targets": {
            "shape": "S72"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sl",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshotAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotAttributesResult",
        "type": "structure",
        "members": {
          "DBSnapshotAttributesResult": {
            "shape": "S7d"
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "IncludeShared": {
            "type": "boolean"
          },
          "IncludePublic": {
            "type": "boolean"
          },
          "DbiResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "S19",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S2q",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultClusterParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "shape": "S7o"
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "shape": "S7o"
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S51"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S8"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S6",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S8"
          },
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S8"
                },
                "Date": {
                  "type": "timestamp"
                },
                "SourceArn": {}
              }
            }
          }
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ExportTaskIdentifier": {},
          "SourceArn": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeExportTasksResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ExportTasks": {
            "type": "list",
            "member": {
              "shape": "Su",
              "locationName": "ExportTask"
            }
          }
        }
      }
    },
    "DescribeGlobalClusters": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalClusterIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeGlobalClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "GlobalClusters": {
            "type": "list",
            "member": {
              "shape": "S3u",
              "locationName": "GlobalClusterMember"
            }
          }
        }
      }
    },
    "DescribeInstallationMedia": {
      "input": {
        "type": "structure",
        "members": {
          "InstallationMediaId": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeInstallationMediaResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "InstallationMedia": {
            "type": "list",
            "member": {
              "shape": "S4q",
              "locationName": "InstallationMedia"
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                },
                "OptionsConflictsWith": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionConflictName"
                  }
                },
                "Persistent": {
                  "type": "boolean"
                },
                "Permanent": {
                  "type": "boolean"
                },
                "RequiresAutoMinorEngineVersionUpgrade": {
                  "type": "boolean"
                },
                "VpcOnly": {
                  "type": "boolean"
                },
                "SupportsOptionVersionDowngrade": {
                  "type": "boolean"
                },
                "OptionGroupOptionSettings": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionGroupOptionSetting",
                    "type": "structure",
                    "members": {
                      "SettingName": {},
                      "SettingDescription": {},
                      "DefaultValue": {},
                      "ApplyType": {},
                      "AllowedValues": {},
                      "IsModifiable": {
                        "type": "boolean"
                      },
                      "IsRequired": {
                        "type": "boolean"
                      },
                      "MinimumEngineVersionPerAllowedValue": {
                        "type": "list",
                        "member": {
                          "locationName": "MinimumEngineVersionPerAllowedValue",
                          "type": "structure",
                          "members": {
                            "AllowedValue": {},
                            "MinimumEngineVersion": {}
                          }
                        }
                      }
                    }
                  }
                },
                "OptionGroupOptionVersions": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionVersion",
                    "type": "structure",
                    "members": {
                      "Version": {},
                      "IsDefault": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "S1f",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "AvailabilityZoneGroup": {},
          "Vpc": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZoneGroup": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S2t",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                },
                "SupportsStorageEncryption": {
                  "type": "boolean"
                },
                "StorageType": {},
                "SupportsIops": {
                  "type": "boolean"
                },
                "SupportsEnhancedMonitoring": {
                  "type": "boolean"
                },
                "SupportsIAMDatabaseAuthentication": {
                  "type": "boolean"
                },
                "SupportsPerformanceInsights": {
                  "type": "boolean"
                },
                "MinStorageSize": {
                  "type": "integer"
                },
                "MaxStorageSize": {
                  "type": "integer"
                },
                "MinIopsPerDbInstance": {
                  "type": "integer"
                },
                "MaxIopsPerDbInstance": {
                  "type": "integer"
                },
                "MinIopsPerGib": {
                  "type": "double"
                },
                "MaxIopsPerGib": {
                  "type": "double"
                },
                "AvailableProcessorFeatures": {
                  "shape": "S8x"
                },
                "SupportedEngineModes": {
                  "shape": "S5o"
                },
                "SupportsStorageAutoscaling": {
                  "type": "boolean"
                },
                "SupportsKerberosAuthentication": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribePendingMaintenanceActions": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceIdentifier": {},
          "Filters": {
            "shape": "S51"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribePendingMaintenanceActionsResult",
        "type": "structure",
        "members": {
          "PendingMaintenanceActions": {
            "type": "list",
            "member": {
              "shape": "Sf",
              "locationName": "ResourcePendingMaintenanceActions"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "LeaseId": {},
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S95",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S51"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S97"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeSourceRegions": {
      "input": {
        "type": "structure",
        "members": {
          "RegionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "Filters": {
            "shape": "S51"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSourceRegionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "SourceRegions": {
            "type": "list",
            "member": {
              "locationName": "SourceRegion",
              "type": "structure",
              "members": {
                "RegionName": {},
                "Endpoint": {},
                "Status": {}
              }
            }
          }
        }
      }
    },
    "DescribeValidDBInstanceModifications": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeValidDBInstanceModificationsResult",
        "type": "structure",
        "members": {
          "ValidDBInstanceModificationsMessage": {
            "type": "structure",
            "members": {
              "Storage": {
                "type": "list",
                "member": {
                  "locationName": "ValidStorageOptions",
                  "type": "structure",
                  "members": {
                    "StorageType": {},
                    "StorageSize": {
                      "shape": "S9m"
                    },
                    "ProvisionedIops": {
                      "shape": "S9m"
                    },
                    "IopsToStorageRatio": {
                      "type": "list",
                      "member": {
                        "locationName": "DoubleRange",
                        "type": "structure",
                        "members": {
                          "From": {
                            "type": "double"
                          },
                          "To": {
                            "type": "double"
                          }
                        }
                      }
                    },
                    "SupportsStorageAutoscaling": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "ValidProcessorFeatures": {
                "shape": "S8x"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DownloadDBLogFilePortion": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "LogFileName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "LogFileName": {},
          "Marker": {},
          "NumberOfLines": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DownloadDBLogFilePortionResult",
        "type": "structure",
        "members": {
          "LogFileData": {},
          "Marker": {},
          "AdditionalDataPending": {
            "type": "boolean"
          }
        }
      }
    },
    "FailoverDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "TargetDBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "FailoverDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "ImportInstallationMedia": {
      "input": {
        "type": "structure",
        "required": [
          "CustomAvailabilityZoneId",
          "Engine",
          "EngineVersion",
          "EngineInstallationMediaPath",
          "OSInstallationMediaPath"
        ],
        "members": {
          "CustomAvailabilityZoneId": {},
          "Engine": {},
          "EngineVersion": {},
          "EngineInstallationMediaPath": {},
          "OSInstallationMediaPath": {}
        }
      },
      "output": {
        "shape": "S4q",
        "resultWrapper": "ImportInstallationMediaResult"
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {},
          "Filters": {
            "shape": "S51"
          }
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sb"
          }
        }
      }
    },
    "ModifyCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "CertificateIdentifier": {},
          "RemoveCustomerOverride": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyCertificatesResult",
        "type": "structure",
        "members": {
          "Certificate": {
            "shape": "S56"
          }
        }
      }
    },
    "ModifyCurrentDBClusterCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "Capacity": {
            "type": "integer"
          },
          "SecondsBeforeTimeout": {
            "type": "integer"
          },
          "TimeoutAction": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyCurrentDBClusterCapacityResult",
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "PendingCapacity": {
            "type": "integer"
          },
          "CurrentCapacity": {
            "type": "integer"
          },
          "SecondsBeforeTimeout": {
            "type": "integer"
          },
          "TimeoutAction": {}
        }
      }
    },
    "ModifyDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "NewDBClusterIdentifier": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "Port": {
            "type": "integer"
          },
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "BacktrackWindow": {
            "type": "long"
          },
          "CloudwatchLogsExportConfiguration": {
            "shape": "Sa2"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "DBInstanceParameterGroupName": {},
          "Domain": {},
          "DomainIAMRoleName": {},
          "ScalingConfiguration": {
            "shape": "S1x"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "EnableHttpEndpoint": {
            "type": "boolean"
          },
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "EnableGlobalWriteForwarding": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "ModifyDBClusterEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterEndpointIdentifier"
        ],
        "members": {
          "DBClusterEndpointIdentifier": {},
          "EndpointType": {},
          "StaticMembers": {
            "shape": "Sv"
          },
          "ExcludedMembers": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "shape": "S2e",
        "resultWrapper": "ModifyDBClusterEndpointResult"
      }
    },
    "ModifyDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "Parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "shape": "Sa6",
        "resultWrapper": "ModifyDBClusterParameterGroupResult"
      }
    },
    "ModifyDBClusterSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier",
          "AttributeName"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {},
          "AttributeName": {},
          "ValuesToAdd": {
            "shape": "S5u"
          },
          "ValuesToRemove": {
            "shape": "S5u"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBClusterSnapshotAttributeResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshotAttributesResult": {
            "shape": "S5r"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSubnetGroupName": {},
          "DBSecurityGroups": {
            "shape": "S2k"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {},
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "CACertificateIdentifier": {},
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "DBPortNumber": {
            "type": "integer"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "MonitoringRoleArn": {},
          "DomainIAMRoleName": {},
          "PromotionTier": {
            "type": "integer"
          },
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "EnablePerformanceInsights": {
            "type": "boolean"
          },
          "PerformanceInsightsKMSKeyId": {},
          "PerformanceInsightsRetentionPeriod": {
            "type": "integer"
          },
          "CloudwatchLogsExportConfiguration": {
            "shape": "Sa2"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "UseDefaultProcessorFeatures": {
            "type": "boolean"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "MaxAllocatedStorage": {
            "type": "integer"
          },
          "CertificateRotationRestart": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "shape": "Sac",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBProxy": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {},
          "NewDBProxyName": {},
          "Auth": {
            "shape": "S3a"
          },
          "RequireTLS": {
            "type": "boolean"
          },
          "IdleClientTimeout": {
            "type": "integer"
          },
          "DebugLogging": {
            "type": "boolean"
          },
          "RoleArn": {},
          "SecurityGroups": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBProxyResult",
        "type": "structure",
        "members": {
          "DBProxy": {
            "shape": "S3f"
          }
        }
      }
    },
    "ModifyDBProxyTargetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "TargetGroupName",
          "DBProxyName"
        ],
        "members": {
          "TargetGroupName": {},
          "DBProxyName": {},
          "ConnectionPoolConfig": {
            "type": "structure",
            "members": {
              "MaxConnectionsPercent": {
                "type": "integer"
              },
              "MaxIdleConnectionsPercent": {
                "type": "integer"
              },
              "ConnectionBorrowTimeout": {
                "type": "integer"
              },
              "SessionPinningFilters": {
                "shape": "Sv"
              },
              "InitQuery": {}
            }
          },
          "NewName": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBProxyTargetGroupResult",
        "type": "structure",
        "members": {
          "DBProxyTargetGroup": {
            "shape": "S6y"
          }
        }
      }
    },
    "ModifyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "EngineVersion": {},
          "OptionGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S19"
          }
        }
      }
    },
    "ModifyDBSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "AttributeName"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "AttributeName": {},
          "ValuesToAdd": {
            "shape": "S5u"
          },
          "ValuesToRemove": {
            "shape": "S5u"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSnapshotAttributeResult",
        "type": "structure",
        "members": {
          "DBSnapshotAttributesResult": {
            "shape": "S7d"
          }
        }
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S3o"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S2q"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S8"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S6"
          }
        }
      }
    },
    "ModifyGlobalCluster": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalClusterIdentifier": {},
          "NewGlobalClusterIdentifier": {},
          "DeletionProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyGlobalClusterResult",
        "type": "structure",
        "members": {
          "GlobalCluster": {
            "shape": "S3u"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "OptionVersion": {},
                "DBSecurityGroupMemberships": {
                  "shape": "S2k"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "S1u"
                },
                "OptionSettings": {
                  "type": "list",
                  "member": {
                    "shape": "S1j",
                    "locationName": "OptionSetting"
                  }
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "PromoteReadReplicaDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S95"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "RegisterDBProxyTargets": {
      "input": {
        "type": "structure",
        "required": [
          "DBProxyName"
        ],
        "members": {
          "DBProxyName": {},
          "TargetGroupName": {},
          "DBInstanceIdentifiers": {
            "shape": "Sv"
          },
          "DBClusterIdentifiers": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "resultWrapper": "RegisterDBProxyTargetsResult",
        "type": "structure",
        "members": {
          "DBProxyTargets": {
            "shape": "S72"
          }
        }
      }
    },
    "RemoveFromGlobalCluster": {
      "input": {
        "type": "structure",
        "members": {
          "GlobalClusterIdentifier": {},
          "DbClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveFromGlobalClusterResult",
        "type": "structure",
        "members": {
          "GlobalCluster": {
            "shape": "S3u"
          }
        }
      }
    },
    "RemoveRoleFromDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "RoleArn"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "RoleArn": {},
          "FeatureName": {}
        }
      }
    },
    "RemoveRoleFromDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "RoleArn",
          "FeatureName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "RoleArn": {},
          "FeatureName": {}
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S6"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "shape": "Sa6",
        "resultWrapper": "ResetDBClusterParameterGroupResult"
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "shape": "Sac",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBClusterFromS3": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "Engine",
          "MasterUsername",
          "MasterUserPassword",
          "SourceEngine",
          "SourceEngineVersion",
          "S3BucketName",
          "S3IngestionRoleArn"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S13"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "CharacterSetName": {},
          "DatabaseName": {},
          "DBClusterIdentifier": {},
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "DBSubnetGroupName": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "MasterUsername": {},
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "Tags": {
            "shape": "Sb"
          },
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "SourceEngine": {},
          "SourceEngineVersion": {},
          "S3BucketName": {},
          "S3Prefix": {},
          "S3IngestionRoleArn": {},
          "BacktrackWindow": {
            "type": "long"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "DeletionProtection": {
            "type": "boolean"
          },
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Domain": {},
          "DomainIAMRoleName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterFromS3Result",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "RestoreDBClusterFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "SnapshotIdentifier",
          "Engine"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "S13"
          },
          "DBClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "DBSubnetGroupName": {},
          "DatabaseName": {},
          "OptionGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "Tags": {
            "shape": "Sb"
          },
          "KmsKeyId": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "BacktrackWindow": {
            "type": "long"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "EngineMode": {},
          "ScalingConfiguration": {
            "shape": "S1x"
          },
          "DBClusterParameterGroupName": {},
          "DeletionProtection": {
            "type": "boolean"
          },
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Domain": {},
          "DomainIAMRoleName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterFromSnapshotResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "RestoreDBClusterToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "SourceDBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "RestoreType": {},
          "SourceDBClusterIdentifier": {},
          "RestoreToTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "Port": {
            "type": "integer"
          },
          "DBSubnetGroupName": {},
          "OptionGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "Tags": {
            "shape": "Sb"
          },
          "KmsKeyId": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "BacktrackWindow": {
            "type": "long"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "DBClusterParameterGroupName": {},
          "DeletionProtection": {
            "type": "boolean"
          },
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Domain": {},
          "DomainIAMRoleName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "Sb"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "DomainIAMRoleName": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "UseDefaultProcessorFeatures": {
            "type": "boolean"
          },
          "DBParameterGroupName": {},
          "DeletionProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "RestoreDBInstanceFromS3": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBInstanceClass",
          "Engine",
          "SourceEngine",
          "SourceEngineVersion",
          "S3BucketName",
          "S3IngestionRoleArn"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "S2k"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          },
          "StorageType": {},
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "MonitoringRoleArn": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "SourceEngine": {},
          "SourceEngineVersion": {},
          "S3BucketName": {},
          "S3Prefix": {},
          "S3IngestionRoleArn": {},
          "EnablePerformanceInsights": {
            "type": "boolean"
          },
          "PerformanceInsightsKMSKeyId": {},
          "PerformanceInsightsRetentionPeriod": {
            "type": "integer"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "UseDefaultProcessorFeatures": {
            "type": "boolean"
          },
          "DeletionProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromS3Result",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sb"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "VpcSecurityGroupIds": {
            "shape": "S1u"
          },
          "Domain": {},
          "DomainIAMRoleName": {},
          "EnableIAMDatabaseAuthentication": {
            "type": "boolean"
          },
          "EnableCloudwatchLogsExports": {
            "shape": "S1w"
          },
          "ProcessorFeatures": {
            "shape": "S1b"
          },
          "UseDefaultProcessorFeatures": {
            "type": "boolean"
          },
          "DBParameterGroupName": {},
          "DeletionProtection": {
            "type": "boolean"
          },
          "SourceDbiResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sl"
          }
        }
      }
    },
    "StartActivityStream": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Mode",
          "KmsKeyId"
        ],
        "members": {
          "ResourceArn": {},
          "Mode": {},
          "KmsKeyId": {},
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "StartActivityStreamResult",
        "type": "structure",
        "members": {
          "KmsKeyId": {},
          "KinesisStreamName": {},
          "Status": {},
          "Mode": {},
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      }
    },
    "StartDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "StartDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "StartDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "StartDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    },
    "StartExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskIdentifier",
          "SourceArn",
          "S3BucketName",
          "IamRoleArn",
          "KmsKeyId"
        ],
        "members": {
          "ExportTaskIdentifier": {},
          "SourceArn": {},
          "S3BucketName": {},
          "IamRoleArn": {},
          "KmsKeyId": {},
          "S3Prefix": {},
          "ExportOnly": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "shape": "Su",
        "resultWrapper": "StartExportTaskResult"
      }
    },
    "StopActivityStream": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "StopActivityStreamResult",
        "type": "structure",
        "members": {
          "KmsKeyId": {},
          "KinesisStreamName": {},
          "Status": {}
        }
      }
    },
    "StopDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "StopDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1z"
          }
        }
      }
    },
    "StopDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "StopDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S2m"
          }
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S7"
        },
        "EventCategoriesList": {
          "shape": "S8"
        },
        "Enabled": {
          "type": "boolean"
        },
        "EventSubscriptionArn": {}
      },
      "wrapper": true
    },
    "S7": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S8": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "ResourceIdentifier": {},
        "PendingMaintenanceActionDetails": {
          "type": "list",
          "member": {
            "locationName": "PendingMaintenanceAction",
            "type": "structure",
            "members": {
              "Action": {},
              "AutoAppliedAfterDate": {
                "type": "timestamp"
              },
              "ForcedApplyDate": {
                "type": "timestamp"
              },
              "OptInStatus": {},
              "CurrentApplyDate": {
                "type": "timestamp"
              },
              "Description": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sl": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        },
        "DBSecurityGroupArn": {}
      },
      "wrapper": true
    },
    "Ss": {
      "type": "structure",
      "members": {
        "DBClusterIdentifier": {},
        "BacktrackIdentifier": {},
        "BacktrackTo": {
          "type": "timestamp"
        },
        "BacktrackedFrom": {
          "type": "timestamp"
        },
        "BacktrackRequestCreationTime": {
          "type": "timestamp"
        },
        "Status": {}
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "ExportTaskIdentifier": {},
        "SourceArn": {},
        "ExportOnly": {
          "shape": "Sv"
        },
        "SnapshotTime": {
          "type": "timestamp"
        },
        "TaskStartTime": {
          "type": "timestamp"
        },
        "TaskEndTime": {
          "type": "timestamp"
        },
        "S3Bucket": {},
        "S3Prefix": {},
        "IamRoleArn": {},
        "KmsKeyId": {},
        "Status": {},
        "PercentProgress": {
          "type": "integer"
        },
        "TotalExtractedDataInGB": {
          "type": "integer"
        },
        "FailureCause": {},
        "WarningMessage": {}
      }
    },
    "Sv": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "structure",
      "members": {
        "DBClusterParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {},
        "DBClusterParameterGroupArn": {}
      },
      "wrapper": true
    },
    "S12": {
      "type": "structure",
      "members": {
        "AvailabilityZones": {
          "shape": "S13"
        },
        "DBClusterSnapshotIdentifier": {},
        "DBClusterIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "VpcId": {},
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "PercentProgress": {
          "type": "integer"
        },
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DBClusterSnapshotArn": {},
        "SourceDBClusterSnapshotArn": {},
        "IAMDatabaseAuthenticationEnabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S13": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {},
        "DBParameterGroupArn": {}
      },
      "wrapper": true
    },
    "S19": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {},
        "PercentProgress": {
          "type": "integer"
        },
        "SourceRegion": {},
        "SourceDBSnapshotIdentifier": {},
        "StorageType": {},
        "TdeCredentialArn": {},
        "Encrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DBSnapshotArn": {},
        "Timezone": {},
        "IAMDatabaseAuthenticationEnabled": {
          "type": "boolean"
        },
        "ProcessorFeatures": {
          "shape": "S1b"
        },
        "DbiResourceId": {}
      },
      "wrapper": true
    },
    "S1b": {
      "type": "list",
      "member": {
        "locationName": "ProcessorFeature",
        "type": "structure",
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Persistent": {
                "type": "boolean"
              },
              "Permanent": {
                "type": "boolean"
              },
              "Port": {
                "type": "integer"
              },
              "OptionVersion": {},
              "OptionSettings": {
                "type": "list",
                "member": {
                  "shape": "S1j",
                  "locationName": "OptionSetting"
                }
              },
              "DBSecurityGroupMemberships": {
                "shape": "S1k"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "S1m"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {},
        "OptionGroupArn": {}
      },
      "wrapper": true
    },
    "S1j": {
      "type": "structure",
      "members": {
        "Name": {},
        "Value": {},
        "DefaultValue": {},
        "Description": {},
        "ApplyType": {},
        "DataType": {},
        "AllowedValues": {},
        "IsModifiable": {
          "type": "boolean"
        },
        "IsCollection": {
          "type": "boolean"
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "CustomAvailabilityZoneId": {},
        "CustomAvailabilityZoneName": {},
        "CustomAvailabilityZoneStatus": {},
        "VpnDetails": {
          "type": "structure",
          "members": {
            "VpnId": {},
            "VpnTunnelOriginatorIP": {},
            "VpnGatewayIp": {},
            "VpnPSK": {
              "type": "string",
              "sensitive": true
            },
            "VpnName": {},
            "VpnState": {}
          }
        }
      },
      "wrapper": true
    },
    "S1u": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "S1w": {
      "type": "list",
      "member": {}
    },
    "S1x": {
      "type": "structure",
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        },
        "AutoPause": {
          "type": "boolean"
        },
        "SecondsUntilAutoPause": {
          "type": "integer"
        },
        "TimeoutAction": {}
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "AllocatedStorage": {
          "type": "integer"
        },
        "AvailabilityZones": {
          "shape": "S13"
        },
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "CharacterSetName": {},
        "DatabaseName": {},
        "DBClusterIdentifier": {},
        "DBClusterParameterGroup": {},
        "DBSubnetGroup": {},
        "Status": {},
        "PercentProgress": {},
        "EarliestRestorableTime": {
          "type": "timestamp"
        },
        "Endpoint": {},
        "ReaderEndpoint": {},
        "CustomEndpoints": {
          "shape": "Sv"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "Engine": {},
        "EngineVersion": {},
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "Port": {
          "type": "integer"
        },
        "MasterUsername": {},
        "DBClusterOptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "DBClusterOptionGroup",
            "type": "structure",
            "members": {
              "DBClusterOptionGroupName": {},
              "Status": {}
            }
          }
        },
        "PreferredBackupWindow": {},
        "PreferredMaintenanceWindow": {},
        "ReplicationSourceIdentifier": {},
        "ReadReplicaIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaIdentifier"
          }
        },
        "DBClusterMembers": {
          "type": "list",
          "member": {
            "locationName": "DBClusterMember",
            "type": "structure",
            "members": {
              "DBInstanceIdentifier": {},
              "IsClusterWriter": {
                "type": "boolean"
              },
              "DBClusterParameterGroupStatus": {},
              "PromotionTier": {
                "type": "integer"
              }
            },
            "wrapper": true
          }
        },
        "VpcSecurityGroups": {
          "shape": "S1m"
        },
        "HostedZoneId": {},
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DbClusterResourceId": {},
        "DBClusterArn": {},
        "AssociatedRoles": {
          "type": "list",
          "member": {
            "locationName": "DBClusterRole",
            "type": "structure",
            "members": {
              "RoleArn": {},
              "Status": {},
              "FeatureName": {}
            }
          }
        },
        "IAMDatabaseAuthenticationEnabled": {
          "type": "boolean"
        },
        "CloneGroupId": {},
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "EarliestBacktrackTime": {
          "type": "timestamp"
        },
        "BacktrackWindow": {
          "type": "long"
        },
        "BacktrackConsumedChangeRecords": {
          "type": "long"
        },
        "EnabledCloudwatchLogsExports": {
          "shape": "S1w"
        },
        "Capacity": {
          "type": "integer"
        },
        "EngineMode": {},
        "ScalingConfigurationInfo": {
          "type": "structure",
          "members": {
            "MinCapacity": {
              "type": "integer"
            },
            "MaxCapacity": {
              "type": "integer"
            },
            "AutoPause": {
              "type": "boolean"
            },
            "SecondsUntilAutoPause": {
              "type": "integer"
            },
            "TimeoutAction": {}
          }
        },
        "DeletionProtection": {
          "type": "boolean"
        },
        "HttpEndpointEnabled": {
          "type": "boolean"
        },
        "ActivityStreamMode": {},
        "ActivityStreamStatus": {},
        "ActivityStreamKmsKeyId": {},
        "ActivityStreamKinesisStreamName": {},
        "CopyTagsToSnapshot": {
          "type": "boolean"
        },
        "CrossAccountClone": {
          "type": "boolean"
        },
        "DomainMemberships": {
          "shape": "S2a"
        },
        "GlobalWriteForwardingStatus": {},
        "GlobalWriteForwardingRequested": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S2a": {
      "type": "list",
      "member": {
        "locationName": "DomainMembership",
        "type": "structure",
        "members": {
          "Domain": {},
          "Status": {},
          "FQDN": {},
          "IAMRoleName": {}
        }
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "DBClusterEndpointIdentifier": {},
        "DBClusterIdentifier": {},
        "DBClusterEndpointResourceIdentifier": {},
        "Endpoint": {},
        "Status": {},
        "EndpointType": {},
        "CustomEndpointType": {},
        "StaticMembers": {
          "shape": "Sv"
        },
        "ExcludedMembers": {
          "shape": "Sv"
        },
        "DBClusterEndpointArn": {}
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "shape": "S2n"
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "S1k"
        },
        "VpcSecurityGroups": {
          "shape": "S1m"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S2q"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "LicenseModel": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {},
            "StorageType": {},
            "CACertificateIdentifier": {},
            "DBSubnetGroupName": {},
            "PendingCloudwatchLogsExports": {
              "type": "structure",
              "members": {
                "LogTypesToEnable": {
                  "shape": "S1w"
                },
                "LogTypesToDisable": {
                  "shape": "S1w"
                }
              }
            },
            "ProcessorFeatures": {
              "shape": "S1b"
            }
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "ReadReplicaDBClusterIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBClusterIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "OptionGroupMembership",
            "type": "structure",
            "members": {
              "OptionGroupName": {},
              "Status": {}
            }
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "StatusInfos": {
          "type": "list",
          "member": {
            "locationName": "DBInstanceStatusInfo",
            "type": "structure",
            "members": {
              "StatusType": {},
              "Normal": {
                "type": "boolean"
              },
              "Status": {},
              "Message": {}
            }
          }
        },
        "StorageType": {},
        "TdeCredentialArn": {},
        "DbInstancePort": {
          "type": "integer"
        },
        "DBClusterIdentifier": {},
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DbiResourceId": {},
        "CACertificateIdentifier": {},
        "DomainMemberships": {
          "shape": "S2a"
        },
        "CopyTagsToSnapshot": {
          "type": "boolean"
        },
        "MonitoringInterval": {
          "type": "integer"
        },
        "EnhancedMonitoringResourceArn": {},
        "MonitoringRoleArn": {},
        "PromotionTier": {
          "type": "integer"
        },
        "DBInstanceArn": {},
        "Timezone": {},
        "IAMDatabaseAuthenticationEnabled": {
          "type": "boolean"
        },
        "PerformanceInsightsEnabled": {
          "type": "boolean"
        },
        "PerformanceInsightsKMSKeyId": {},
        "PerformanceInsightsRetentionPeriod": {
          "type": "integer"
        },
        "EnabledCloudwatchLogsExports": {
          "shape": "S1w"
        },
        "ProcessorFeatures": {
          "shape": "S1b"
        },
        "DeletionProtection": {
          "type": "boolean"
        },
        "AssociatedRoles": {
          "type": "list",
          "member": {
            "locationName": "DBInstanceRole",
            "type": "structure",
            "members": {
              "RoleArn": {},
              "FeatureName": {},
              "Status": {}
            }
          }
        },
        "ListenerEndpoint": {
          "shape": "S2n"
        },
        "MaxAllocatedStorage": {
          "type": "integer"
        }
      },
      "wrapper": true
    },
    "S2n": {
      "type": "structure",
      "members": {
        "Address": {},
        "Port": {
          "type": "integer"
        },
        "HostedZoneId": {}
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S2t"
              },
              "SubnetStatus": {}
            }
          }
        },
        "DBSubnetGroupArn": {}
      },
      "wrapper": true
    },
    "S2t": {
      "type": "structure",
      "members": {
        "Name": {}
      },
      "wrapper": true
    },
    "S3a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Description": {},
          "UserName": {},
          "AuthScheme": {},
          "SecretArn": {},
          "IAMAuth": {}
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "DBProxyName": {},
        "DBProxyArn": {},
        "Status": {},
        "EngineFamily": {},
        "VpcSecurityGroupIds": {
          "shape": "Sv"
        },
        "VpcSubnetIds": {
          "shape": "Sv"
        },
        "Auth": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Description": {},
              "UserName": {},
              "AuthScheme": {},
              "SecretArn": {},
              "IAMAuth": {}
            }
          }
        },
        "RoleArn": {},
        "Endpoint": {},
        "RequireTLS": {
          "type": "boolean"
        },
        "IdleClientTimeout": {
          "type": "integer"
        },
        "DebugLogging": {
          "type": "boolean"
        },
        "CreatedDate": {
          "type": "timestamp"
        },
        "UpdatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S3o": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "GlobalClusterIdentifier": {},
        "GlobalClusterResourceId": {},
        "GlobalClusterArn": {},
        "Status": {},
        "Engine": {},
        "EngineVersion": {},
        "DatabaseName": {},
        "StorageEncrypted": {
          "type": "boolean"
        },
        "DeletionProtection": {
          "type": "boolean"
        },
        "GlobalClusterMembers": {
          "type": "list",
          "member": {
            "locationName": "GlobalClusterMember",
            "type": "structure",
            "members": {
              "DBClusterArn": {},
              "Readers": {
                "type": "list",
                "member": {}
              },
              "IsWriter": {
                "type": "boolean"
              },
              "GlobalWriteForwardingStatus": {}
            },
            "wrapper": true
          }
        }
      },
      "wrapper": true
    },
    "S4c": {
      "type": "structure",
      "members": {
        "DBInstanceArn": {},
        "DbiResourceId": {},
        "Region": {},
        "DBInstanceIdentifier": {},
        "RestoreWindow": {
          "type": "structure",
          "members": {
            "EarliestTime": {
              "type": "timestamp"
            },
            "LatestTime": {
              "type": "timestamp"
            }
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "Engine": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {},
        "TdeCredentialArn": {},
        "Encrypted": {
          "type": "boolean"
        },
        "StorageType": {},
        "KmsKeyId": {},
        "Timezone": {},
        "IAMDatabaseAuthenticationEnabled": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "S4q": {
      "type": "structure",
      "members": {
        "InstallationMediaId": {},
        "CustomAvailabilityZoneId": {},
        "Engine": {},
        "EngineVersion": {},
        "EngineInstallationMediaPath": {},
        "OSInstallationMediaPath": {},
        "Status": {},
        "FailureCause": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        }
      }
    },
    "S51": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {
              "locationName": "Value"
            }
          }
        }
      }
    },
    "S56": {
      "type": "structure",
      "members": {
        "CertificateIdentifier": {},
        "CertificateType": {},
        "Thumbprint": {},
        "ValidFrom": {
          "type": "timestamp"
        },
        "ValidTill": {
          "type": "timestamp"
        },
        "CertificateArn": {},
        "CustomerOverride": {
          "type": "boolean"
        },
        "CustomerOverrideValidTill": {
          "type": "timestamp"
        }
      },
      "wrapper": true
    },
    "S5l": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {},
          "SupportedEngineModes": {
            "shape": "S5o"
          }
        }
      }
    },
    "S5o": {
      "type": "list",
      "member": {}
    },
    "S5r": {
      "type": "structure",
      "members": {
        "DBClusterSnapshotIdentifier": {},
        "DBClusterSnapshotAttributes": {
          "type": "list",
          "member": {
            "locationName": "DBClusterSnapshotAttribute",
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeValues": {
                "shape": "S5u"
              }
            }
          }
        }
      },
      "wrapper": true
    },
    "S5u": {
      "type": "list",
      "member": {
        "locationName": "AttributeValue"
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S6y": {
      "type": "structure",
      "members": {
        "DBProxyName": {},
        "TargetGroupName": {},
        "TargetGroupArn": {},
        "IsDefault": {
          "type": "boolean"
        },
        "Status": {},
        "ConnectionPoolConfig": {
          "type": "structure",
          "members": {
            "MaxConnectionsPercent": {
              "type": "integer"
            },
            "MaxIdleConnectionsPercent": {
              "type": "integer"
            },
            "ConnectionBorrowTimeout": {
              "type": "integer"
            },
            "SessionPinningFilters": {
              "shape": "Sv"
            },
            "InitQuery": {}
          }
        },
        "CreatedDate": {
          "type": "timestamp"
        },
        "UpdatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S72": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TargetArn": {},
          "Endpoint": {},
          "TrackedClusterId": {},
          "RdsResourceId": {},
          "Port": {
            "type": "integer"
          },
          "Type": {},
          "TargetHealth": {
            "type": "structure",
            "members": {
              "State": {},
              "Reason": {},
              "Description": {}
            }
          }
        }
      }
    },
    "S7d": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBSnapshotAttributes": {
          "type": "list",
          "member": {
            "locationName": "DBSnapshotAttribute",
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeValues": {
                "shape": "S5u"
              }
            },
            "wrapper": true
          }
        }
      },
      "wrapper": true
    },
    "S7o": {
      "type": "structure",
      "members": {
        "DBParameterGroupFamily": {},
        "Marker": {},
        "Parameters": {
          "shape": "S5l"
        }
      },
      "wrapper": true
    },
    "S8x": {
      "type": "list",
      "member": {
        "locationName": "AvailableProcessorFeature",
        "type": "structure",
        "members": {
          "Name": {},
          "DefaultValue": {},
          "AllowedValues": {}
        }
      }
    },
    "S95": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S97"
        },
        "ReservedDBInstanceArn": {},
        "LeaseId": {}
      },
      "wrapper": true
    },
    "S97": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S9m": {
      "type": "list",
      "member": {
        "locationName": "Range",
        "type": "structure",
        "members": {
          "From": {
            "type": "integer"
          },
          "To": {
            "type": "integer"
          },
          "Step": {
            "type": "integer"
          }
        }
      }
    },
    "Sa2": {
      "type": "structure",
      "members": {
        "EnableLogTypes": {
          "shape": "S1w"
        },
        "DisableLogTypes": {
          "shape": "S1w"
        }
      }
    },
    "Sa6": {
      "type": "structure",
      "members": {
        "DBClusterParameterGroupName": {}
      }
    },
    "Sac": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}
},{}],182:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Certificates"
    },
    "DescribeCustomAvailabilityZones": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "CustomAvailabilityZones"
    },
    "DescribeDBClusterBacktracks": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterBacktracks"
    },
    "DescribeDBClusterEndpoints": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterEndpoints"
    },
    "DescribeDBClusterParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterParameterGroups"
    },
    "DescribeDBClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBClusterSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusterSnapshots"
    },
    "DescribeDBClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusters"
    },
    "DescribeDBEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBEngineVersions"
    },
    "DescribeDBInstanceAutomatedBackups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstanceAutomatedBackups"
    },
    "DescribeDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstances"
    },
    "DescribeDBLogFiles": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DescribeDBLogFiles"
    },
    "DescribeDBParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBParameterGroups"
    },
    "DescribeDBParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBProxies": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBProxies"
    },
    "DescribeDBProxyTargetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "TargetGroups"
    },
    "DescribeDBProxyTargets": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Targets"
    },
    "DescribeDBSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSecurityGroups"
    },
    "DescribeDBSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSnapshots"
    },
    "DescribeDBSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeExportTasks": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ExportTasks"
    },
    "DescribeGlobalClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "GlobalClusters"
    },
    "DescribeInstallationMedia": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "InstallationMedia"
    },
    "DescribeOptionGroupOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupOptions"
    },
    "DescribeOptionGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupsList"
    },
    "DescribeOrderableDBInstanceOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableDBInstanceOptions"
    },
    "DescribePendingMaintenanceActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "PendingMaintenanceActions"
    },
    "DescribeReservedDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstances"
    },
    "DescribeReservedDBInstancesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstancesOfferings"
    },
    "DescribeSourceRegions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "SourceRegions"
    },
    "DownloadDBLogFilePortion": {
      "input_token": "Marker",
      "limit_key": "NumberOfLines",
      "more_results": "AdditionalDataPending",
      "output_token": "Marker",
      "result_key": "LogFileData"
    },
    "ListTagsForResource": {
      "result_key": "TagList"
    }
  }
}
},{}],183:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "DBInstanceAvailable": {
      "delay": 30,
      "operation": "DescribeDBInstances",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-restore",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "incompatible-parameters",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        }
      ]
    },
    "DBInstanceDeleted": {
      "delay": 30,
      "operation": "DescribeDBInstances",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(DBInstances) == `0`"
        },
        {
          "expected": "DBInstanceNotFound",
          "matcher": "error",
          "state": "success"
        },
        {
          "expected": "creating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "modifying",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        },
        {
          "expected": "resetting-master-credentials",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBInstances[].DBInstanceStatus"
        }
      ]
    },
    "DBSnapshotAvailable": {
      "delay": 30,
      "operation": "DescribeDBSnapshots",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "incompatible-restore",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "incompatible-parameters",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        }
      ]
    },
    "DBSnapshotDeleted": {
      "delay": 30,
      "operation": "DescribeDBSnapshots",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(DBSnapshots) == `0`"
        },
        {
          "expected": "DBSnapshotNotFound",
          "matcher": "error",
          "state": "success"
        },
        {
          "expected": "creating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "modifying",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        },
        {
          "expected": "resetting-master-credentials",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBSnapshots[].Status"
        }
      ]
    },
    "DBClusterSnapshotAvailable": {
      "delay": 30,
      "operation": "DescribeDBClusterSnapshots",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "incompatible-restore",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "incompatible-parameters",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        }
      ]
    },
    "DBClusterSnapshotDeleted": {
      "delay": 30,
      "operation": "DescribeDBClusterSnapshots",
      "maxAttempts": 60,
      "acceptors": [
        {
          "expected": true,
          "matcher": "path",
          "state": "success",
          "argument": "length(DBClusterSnapshots) == `0`"
        },
        {
          "expected": "DBClusterSnapshotNotFoundFault",
          "matcher": "error",
          "state": "success"
        },
        {
          "expected": "creating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "modifying",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "rebooting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        },
        {
          "expected": "resetting-master-credentials",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "DBClusterSnapshots[].Status"
        }
      ]
    }
  }
}

},{}],184:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-12-01",
    "endpointPrefix": "redshift",
    "protocol": "query",
    "serviceFullName": "Amazon Redshift",
    "serviceId": "Redshift",
    "signatureVersion": "v4",
    "uid": "redshift-2012-12-01",
    "xmlNamespace": "http://redshift.amazonaws.com/doc/2012-12-01/"
  },
  "operations": {
    "AcceptReservedNodeExchange": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedNodeId",
          "TargetReservedNodeOfferingId"
        ],
        "members": {
          "ReservedNodeId": {},
          "TargetReservedNodeOfferingId": {}
        }
      },
      "output": {
        "resultWrapper": "AcceptReservedNodeExchangeResult",
        "type": "structure",
        "members": {
          "ExchangedReservedNode": {
            "shape": "S4"
          }
        }
      }
    },
    "AuthorizeClusterSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeClusterSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "AuthorizeSnapshotAccess": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "AccountWithRestoreAccess"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "AccountWithRestoreAccess": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeSnapshotAccessResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "BatchDeleteClusterSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "Identifiers"
        ],
        "members": {
          "Identifiers": {
            "type": "list",
            "member": {
              "shape": "Sv",
              "locationName": "DeleteClusterSnapshotMessage"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "BatchDeleteClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Resources": {
            "shape": "Sx"
          },
          "Errors": {
            "type": "list",
            "member": {
              "shape": "Sz",
              "locationName": "SnapshotErrorMessage"
            }
          }
        }
      }
    },
    "BatchModifyClusterSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifierList"
        ],
        "members": {
          "SnapshotIdentifierList": {
            "shape": "Sx"
          },
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "BatchModifyClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Resources": {
            "shape": "Sx"
          },
          "Errors": {
            "type": "list",
            "member": {
              "shape": "Sz",
              "locationName": "SnapshotErrorMessage"
            }
          }
        }
      }
    },
    "CancelResize": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S14",
        "resultWrapper": "CancelResizeResult"
      }
    },
    "CopyClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceSnapshotIdentifier",
          "TargetSnapshotIdentifier"
        ],
        "members": {
          "SourceSnapshotIdentifier": {},
          "SourceSnapshotClusterIdentifier": {},
          "TargetSnapshotIdentifier": {},
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "NodeType",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "ClusterIdentifier": {},
          "ClusterType": {},
          "NodeType": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "ClusterSecurityGroups": {
            "shape": "S1d"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1e"
          },
          "ClusterSubnetGroupName": {},
          "AvailabilityZone": {},
          "PreferredMaintenanceWindow": {},
          "ClusterParameterGroupName": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "Port": {
            "type": "integer"
          },
          "ClusterVersion": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "ElasticIp": {},
          "Tags": {
            "shape": "Sg"
          },
          "KmsKeyId": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          },
          "AdditionalInfo": {},
          "IamRoles": {
            "shape": "S1g"
          },
          "MaintenanceTrackName": {},
          "SnapshotScheduleIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CreateClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "CreateClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName",
          "ParameterGroupFamily",
          "Description"
        ],
        "members": {
          "ParameterGroupName": {},
          "ParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "ClusterParameterGroup": {
            "shape": "S29"
          }
        }
      }
    },
    "CreateClusterSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName",
          "Description"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "Description": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSecurityGroupResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "ClusterIdentifier"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "ClusterIdentifier": {},
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName",
          "Description",
          "SubnetIds"
        ],
        "members": {
          "ClusterSubnetGroupName": {},
          "Description": {},
          "SubnetIds": {
            "shape": "S2f"
          },
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSubnetGroupResult",
        "type": "structure",
        "members": {
          "ClusterSubnetGroup": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "SourceIds": {
            "shape": "S2o"
          },
          "EventCategories": {
            "shape": "S2p"
          },
          "Severity": {},
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S2r"
          }
        }
      }
    },
    "CreateHsmClientCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "HsmClientCertificateIdentifier"
        ],
        "members": {
          "HsmClientCertificateIdentifier": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateHsmClientCertificateResult",
        "type": "structure",
        "members": {
          "HsmClientCertificate": {
            "shape": "S2u"
          }
        }
      }
    },
    "CreateHsmConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "HsmConfigurationIdentifier",
          "Description",
          "HsmIpAddress",
          "HsmPartitionName",
          "HsmPartitionPassword",
          "HsmServerPublicCertificate"
        ],
        "members": {
          "HsmConfigurationIdentifier": {},
          "Description": {},
          "HsmIpAddress": {},
          "HsmPartitionName": {},
          "HsmPartitionPassword": {},
          "HsmServerPublicCertificate": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateHsmConfigurationResult",
        "type": "structure",
        "members": {
          "HsmConfiguration": {
            "shape": "S2x"
          }
        }
      }
    },
    "CreateScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduledActionName",
          "TargetAction",
          "Schedule",
          "IamRole"
        ],
        "members": {
          "ScheduledActionName": {},
          "TargetAction": {
            "shape": "S2z"
          },
          "Schedule": {},
          "IamRole": {},
          "ScheduledActionDescription": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Enable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S33",
        "resultWrapper": "CreateScheduledActionResult"
      }
    },
    "CreateSnapshotCopyGrant": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotCopyGrantName"
        ],
        "members": {
          "SnapshotCopyGrantName": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateSnapshotCopyGrantResult",
        "type": "structure",
        "members": {
          "SnapshotCopyGrant": {
            "shape": "S38"
          }
        }
      }
    },
    "CreateSnapshotSchedule": {
      "input": {
        "type": "structure",
        "members": {
          "ScheduleDefinitions": {
            "shape": "S3a"
          },
          "ScheduleIdentifier": {},
          "ScheduleDescription": {},
          "Tags": {
            "shape": "Sg"
          },
          "DryRun": {
            "type": "boolean"
          },
          "NextInvocations": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S3b",
        "resultWrapper": "CreateSnapshotScheduleResult"
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      }
    },
    "CreateUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "FeatureType",
          "LimitType",
          "Amount"
        ],
        "members": {
          "ClusterIdentifier": {},
          "FeatureType": {},
          "LimitType": {},
          "Amount": {
            "type": "long"
          },
          "Period": {},
          "BreachAction": {},
          "Tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "shape": "S3l",
        "resultWrapper": "CreateUsageLimitResult"
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SkipFinalClusterSnapshot": {
            "type": "boolean"
          },
          "FinalClusterSnapshotIdentifier": {},
          "FinalClusterSnapshotRetentionPeriod": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "DeleteClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {}
        }
      }
    },
    "DeleteClusterSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {}
        }
      }
    },
    "DeleteClusterSnapshot": {
      "input": {
        "shape": "Sv"
      },
      "output": {
        "resultWrapper": "DeleteClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "DeleteClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName"
        ],
        "members": {
          "ClusterSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      }
    },
    "DeleteHsmClientCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "HsmClientCertificateIdentifier"
        ],
        "members": {
          "HsmClientCertificateIdentifier": {}
        }
      }
    },
    "DeleteHsmConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "HsmConfigurationIdentifier"
        ],
        "members": {
          "HsmConfigurationIdentifier": {}
        }
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduledActionName"
        ],
        "members": {
          "ScheduledActionName": {}
        }
      }
    },
    "DeleteSnapshotCopyGrant": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotCopyGrantName"
        ],
        "members": {
          "SnapshotCopyGrantName": {}
        }
      }
    },
    "DeleteSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduleIdentifier"
        ],
        "members": {
          "ScheduleIdentifier": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "shape": "S3z"
          }
        }
      }
    },
    "DeleteUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "UsageLimitId"
        ],
        "members": {
          "UsageLimitId": {}
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AttributeNames": {
            "type": "list",
            "member": {
              "locationName": "AttributeName"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountAttributesResult",
        "type": "structure",
        "members": {
          "AccountAttributes": {
            "type": "list",
            "member": {
              "locationName": "AccountAttribute",
              "type": "structure",
              "members": {
                "AttributeName": {},
                "AttributeValues": {
                  "type": "list",
                  "member": {
                    "locationName": "AttributeValueTarget",
                    "type": "structure",
                    "members": {
                      "AttributeValue": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeClusterDbRevisions": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterDbRevisionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterDbRevisions": {
            "type": "list",
            "member": {
              "locationName": "ClusterDbRevision",
              "type": "structure",
              "members": {
                "ClusterIdentifier": {},
                "CurrentDatabaseRevision": {},
                "DatabaseRevisionReleaseDate": {
                  "type": "timestamp"
                },
                "RevisionTargets": {
                  "type": "list",
                  "member": {
                    "locationName": "RevisionTarget",
                    "type": "structure",
                    "members": {
                      "DatabaseRevision": {},
                      "Description": {},
                      "DatabaseRevisionReleaseDate": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeClusterParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S29",
              "locationName": "ClusterParameterGroup"
            }
          }
        }
      }
    },
    "DescribeClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S4k"
          },
          "Marker": {}
        }
      }
    },
    "DescribeClusterSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "ClusterSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeClusterSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SnapshotType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "OwnerAccount": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          },
          "ClusterExists": {
            "type": "boolean"
          },
          "SortingEntities": {
            "type": "list",
            "member": {
              "locationName": "SnapshotSortingEntity",
              "type": "structure",
              "required": [
                "Attribute"
              ],
              "members": {
                "Attribute": {},
                "SortOrder": {}
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "Sm",
              "locationName": "Snapshot"
            }
          }
        }
      }
    },
    "DescribeClusterSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S2h",
              "locationName": "ClusterSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeClusterTracks": {
      "input": {
        "type": "structure",
        "members": {
          "MaintenanceTrackName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterTracksResult",
        "type": "structure",
        "members": {
          "MaintenanceTracks": {
            "type": "list",
            "member": {
              "locationName": "MaintenanceTrack",
              "type": "structure",
              "members": {
                "MaintenanceTrackName": {},
                "DatabaseVersion": {},
                "UpdateTargets": {
                  "type": "list",
                  "member": {
                    "locationName": "UpdateTarget",
                    "type": "structure",
                    "members": {
                      "MaintenanceTrackName": {},
                      "DatabaseVersion": {},
                      "SupportedOperations": {
                        "type": "list",
                        "member": {
                          "locationName": "SupportedOperation",
                          "type": "structure",
                          "members": {
                            "OperationName": {}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeClusterVersions": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterVersion": {},
          "ClusterParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterVersions": {
            "type": "list",
            "member": {
              "locationName": "ClusterVersion",
              "type": "structure",
              "members": {
                "ClusterVersion": {},
                "ClusterParameterGroupFamily": {},
                "Description": {}
              }
            }
          }
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Clusters": {
            "type": "list",
            "member": {
              "shape": "S1i",
              "locationName": "Cluster"
            }
          }
        }
      }
    },
    "DescribeDefaultClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupFamily"
        ],
        "members": {
          "ParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDefaultClusterParametersResult",
        "type": "structure",
        "members": {
          "DefaultClusterParameters": {
            "type": "structure",
            "members": {
              "ParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S4k"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "Events": {
                  "type": "list",
                  "member": {
                    "locationName": "EventInfoMap",
                    "type": "structure",
                    "members": {
                      "EventId": {},
                      "EventCategories": {
                        "shape": "S2p"
                      },
                      "EventDescription": {},
                      "Severity": {}
                    },
                    "wrapper": true
                  }
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S2r",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S2p"
                },
                "Severity": {},
                "Date": {
                  "type": "timestamp"
                },
                "EventId": {}
              }
            }
          }
        }
      }
    },
    "DescribeHsmClientCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "HsmClientCertificateIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeHsmClientCertificatesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "HsmClientCertificates": {
            "type": "list",
            "member": {
              "shape": "S2u",
              "locationName": "HsmClientCertificate"
            }
          }
        }
      }
    },
    "DescribeHsmConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "HsmConfigurationIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeHsmConfigurationsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "HsmConfigurations": {
            "type": "list",
            "member": {
              "shape": "S2x",
              "locationName": "HsmConfiguration"
            }
          }
        }
      }
    },
    "DescribeLoggingStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S63",
        "resultWrapper": "DescribeLoggingStatusResult"
      }
    },
    "DescribeNodeConfigurationOptions": {
      "input": {
        "type": "structure",
        "required": [
          "ActionType"
        ],
        "members": {
          "ActionType": {},
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "OwnerAccount": {},
          "Filters": {
            "locationName": "Filter",
            "type": "list",
            "member": {
              "locationName": "NodeConfigurationOptionsFilter",
              "type": "structure",
              "members": {
                "Name": {},
                "Operator": {},
                "Values": {
                  "shape": "S6a",
                  "locationName": "Value"
                }
              }
            }
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeNodeConfigurationOptionsResult",
        "type": "structure",
        "members": {
          "NodeConfigurationOptionList": {
            "type": "list",
            "member": {
              "locationName": "NodeConfigurationOption",
              "type": "structure",
              "members": {
                "NodeType": {},
                "NumberOfNodes": {
                  "type": "integer"
                },
                "EstimatedDiskUtilizationPercent": {
                  "type": "double"
                },
                "Mode": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableClusterOptions": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterVersion": {},
          "NodeType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableClusterOptionsResult",
        "type": "structure",
        "members": {
          "OrderableClusterOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableClusterOption",
              "type": "structure",
              "members": {
                "ClusterVersion": {},
                "ClusterType": {},
                "NodeType": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S2k",
                    "locationName": "AvailabilityZone"
                  }
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedNodeOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedNodeOfferingId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedNodeOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedNodeOfferings": {
            "shape": "S6m"
          }
        }
      }
    },
    "DescribeReservedNodes": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedNodeId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedNodesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedNodes": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "ReservedNode"
            }
          }
        }
      }
    },
    "DescribeResize": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S14",
        "resultWrapper": "DescribeResizeResult"
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "members": {
          "ScheduledActionName": {},
          "TargetActionType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Active": {
            "type": "boolean"
          },
          "Filters": {
            "type": "list",
            "member": {
              "locationName": "ScheduledActionFilter",
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "shape": "S6a"
                }
              }
            }
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeScheduledActionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ScheduledActions": {
            "type": "list",
            "member": {
              "shape": "S33",
              "locationName": "ScheduledAction"
            }
          }
        }
      }
    },
    "DescribeSnapshotCopyGrants": {
      "input": {
        "type": "structure",
        "members": {
          "SnapshotCopyGrantName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotCopyGrantsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "SnapshotCopyGrants": {
            "type": "list",
            "member": {
              "shape": "S38",
              "locationName": "SnapshotCopyGrant"
            }
          }
        }
      }
    },
    "DescribeSnapshotSchedules": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "ScheduleIdentifier": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotSchedulesResult",
        "type": "structure",
        "members": {
          "SnapshotSchedules": {
            "type": "list",
            "member": {
              "shape": "S3b",
              "locationName": "SnapshotSchedule"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeStorage": {
      "output": {
        "resultWrapper": "DescribeStorageResult",
        "type": "structure",
        "members": {
          "TotalBackupSizeInMegaBytes": {
            "type": "double"
          },
          "TotalProvisionedStorageInMegaBytes": {
            "type": "double"
          }
        }
      }
    },
    "DescribeTableRestoreStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "TableRestoreRequestId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTableRestoreStatusResult",
        "type": "structure",
        "members": {
          "TableRestoreStatusDetails": {
            "type": "list",
            "member": {
              "shape": "S79",
              "locationName": "TableRestoreStatus"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceName": {},
          "ResourceType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "TaggedResources": {
            "type": "list",
            "member": {
              "locationName": "TaggedResource",
              "type": "structure",
              "members": {
                "Tag": {
                  "shape": "Sh"
                },
                "ResourceName": {},
                "ResourceType": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeUsageLimits": {
      "input": {
        "type": "structure",
        "members": {
          "UsageLimitId": {},
          "ClusterIdentifier": {},
          "FeatureType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S3z"
          },
          "TagValues": {
            "shape": "S4f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeUsageLimitsResult",
        "type": "structure",
        "members": {
          "UsageLimits": {
            "type": "list",
            "member": {
              "shape": "S3l"
            }
          },
          "Marker": {}
        }
      }
    },
    "DisableLogging": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S63",
        "resultWrapper": "DisableLoggingResult"
      }
    },
    "DisableSnapshotCopy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DisableSnapshotCopyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "EnableLogging": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "BucketName"
        ],
        "members": {
          "ClusterIdentifier": {},
          "BucketName": {},
          "S3KeyPrefix": {}
        }
      },
      "output": {
        "shape": "S63",
        "resultWrapper": "EnableLoggingResult"
      }
    },
    "EnableSnapshotCopy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "DestinationRegion"
        ],
        "members": {
          "ClusterIdentifier": {},
          "DestinationRegion": {},
          "RetentionPeriod": {
            "type": "integer"
          },
          "SnapshotCopyGrantName": {},
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "EnableSnapshotCopyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "GetClusterCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "DbUser",
          "ClusterIdentifier"
        ],
        "members": {
          "DbUser": {},
          "DbName": {},
          "ClusterIdentifier": {},
          "DurationSeconds": {
            "type": "integer"
          },
          "AutoCreate": {
            "type": "boolean"
          },
          "DbGroups": {
            "type": "list",
            "member": {
              "locationName": "DbGroup"
            }
          }
        }
      },
      "output": {
        "resultWrapper": "GetClusterCredentialsResult",
        "type": "structure",
        "members": {
          "DbUser": {},
          "DbPassword": {
            "type": "string",
            "sensitive": true
          },
          "Expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetReservedNodeExchangeOfferings": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedNodeId"
        ],
        "members": {
          "ReservedNodeId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "GetReservedNodeExchangeOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedNodeOfferings": {
            "shape": "S6m"
          }
        }
      }
    },
    "ModifyCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "ClusterType": {},
          "NodeType": {},
          "NumberOfNodes": {
            "type": "integer"
          },
          "ClusterSecurityGroups": {
            "shape": "S1d"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1e"
          },
          "MasterUserPassword": {},
          "ClusterParameterGroupName": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "PreferredMaintenanceWindow": {},
          "ClusterVersion": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "NewClusterIdentifier": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "ElasticIp": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          },
          "MaintenanceTrackName": {},
          "Encrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ModifyClusterDbRevision": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "RevisionTarget"
        ],
        "members": {
          "ClusterIdentifier": {},
          "RevisionTarget": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterDbRevisionResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ModifyClusterIamRoles": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "AddIamRoles": {
            "shape": "S1g"
          },
          "RemoveIamRoles": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterIamRolesResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ModifyClusterMaintenance": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "DeferMaintenance": {
            "type": "boolean"
          },
          "DeferMaintenanceIdentifier": {},
          "DeferMaintenanceStartTime": {
            "type": "timestamp"
          },
          "DeferMaintenanceEndTime": {
            "type": "timestamp"
          },
          "DeferMaintenanceDuration": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterMaintenanceResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ModifyClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName",
          "Parameters"
        ],
        "members": {
          "ParameterGroupName": {},
          "Parameters": {
            "shape": "S4k"
          }
        }
      },
      "output": {
        "shape": "S83",
        "resultWrapper": "ModifyClusterParameterGroupResult"
      }
    },
    "ModifyClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "ModifyClusterSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "ScheduleIdentifier": {},
          "DisassociateSchedule": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "ClusterSubnetGroupName": {},
          "Description": {},
          "SubnetIds": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterSubnetGroupResult",
        "type": "structure",
        "members": {
          "ClusterSubnetGroup": {
            "shape": "S2h"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "SourceIds": {
            "shape": "S2o"
          },
          "EventCategories": {
            "shape": "S2p"
          },
          "Severity": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S2r"
          }
        }
      }
    },
    "ModifyScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduledActionName"
        ],
        "members": {
          "ScheduledActionName": {},
          "TargetAction": {
            "shape": "S2z"
          },
          "Schedule": {},
          "IamRole": {},
          "ScheduledActionDescription": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Enable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S33",
        "resultWrapper": "ModifyScheduledActionResult"
      }
    },
    "ModifySnapshotCopyRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "RetentionPeriod"
        ],
        "members": {
          "ClusterIdentifier": {},
          "RetentionPeriod": {
            "type": "integer"
          },
          "Manual": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifySnapshotCopyRetentionPeriodResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ModifySnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduleIdentifier",
          "ScheduleDefinitions"
        ],
        "members": {
          "ScheduleIdentifier": {},
          "ScheduleDefinitions": {
            "shape": "S3a"
          }
        }
      },
      "output": {
        "shape": "S3b",
        "resultWrapper": "ModifySnapshotScheduleResult"
      }
    },
    "ModifyUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "UsageLimitId"
        ],
        "members": {
          "UsageLimitId": {},
          "Amount": {
            "type": "long"
          },
          "BreachAction": {}
        }
      },
      "output": {
        "shape": "S3l",
        "resultWrapper": "ModifyUsageLimitResult"
      }
    },
    "PauseCluster": {
      "input": {
        "shape": "S31"
      },
      "output": {
        "resultWrapper": "PauseClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "PurchaseReservedNodeOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedNodeOfferingId"
        ],
        "members": {
          "ReservedNodeOfferingId": {},
          "NodeCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedNodeOfferingResult",
        "type": "structure",
        "members": {
          "ReservedNode": {
            "shape": "S4"
          }
        }
      }
    },
    "RebootCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RebootClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "ResetClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S4k"
          }
        }
      },
      "output": {
        "shape": "S83",
        "resultWrapper": "ResetClusterParameterGroupResult"
      }
    },
    "ResizeCluster": {
      "input": {
        "shape": "S30"
      },
      "output": {
        "resultWrapper": "ResizeClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "RestoreFromClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "SnapshotIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "ClusterSubnetGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "OwnerAccount": {},
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "ElasticIp": {},
          "ClusterParameterGroupName": {},
          "ClusterSecurityGroups": {
            "shape": "S1d"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1e"
          },
          "PreferredMaintenanceWindow": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "ManualSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "KmsKeyId": {},
          "NodeType": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          },
          "AdditionalInfo": {},
          "IamRoles": {
            "shape": "S1g"
          },
          "MaintenanceTrackName": {},
          "SnapshotScheduleIdentifier": {},
          "NumberOfNodes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreFromClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "RestoreTableFromClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "SnapshotIdentifier",
          "SourceDatabaseName",
          "SourceTableName",
          "NewTableName"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SourceDatabaseName": {},
          "SourceSchemaName": {},
          "SourceTableName": {},
          "TargetDatabaseName": {},
          "TargetSchemaName": {},
          "NewTableName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreTableFromClusterSnapshotResult",
        "type": "structure",
        "members": {
          "TableRestoreStatus": {
            "shape": "S79"
          }
        }
      }
    },
    "ResumeCluster": {
      "input": {
        "shape": "S32"
      },
      "output": {
        "resultWrapper": "ResumeClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    },
    "RevokeClusterSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeClusterSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "Sd"
          }
        }
      }
    },
    "RevokeSnapshotAccess": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "AccountWithRestoreAccess"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "AccountWithRestoreAccess": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeSnapshotAccessResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sm"
          }
        }
      }
    },
    "RotateEncryptionKey": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RotateEncryptionKeyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "S1i"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "ReservedNodeId": {},
        "ReservedNodeOfferingId": {},
        "NodeType": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "NodeCount": {
          "type": "integer"
        },
        "State": {},
        "OfferingType": {},
        "RecurringCharges": {
          "shape": "S8"
        },
        "ReservedNodeOfferingType": {}
      },
      "wrapper": true
    },
    "S8": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "ClusterSecurityGroupName": {},
        "Description": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupOwnerId": {},
              "Tags": {
                "shape": "Sg"
              }
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {},
              "Tags": {
                "shape": "Sg"
              }
            }
          }
        },
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "Sg": {
      "type": "list",
      "member": {
        "shape": "Sh",
        "locationName": "Tag"
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "SnapshotIdentifier": {},
        "ClusterIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "ClusterVersion": {},
        "SnapshotType": {},
        "NodeType": {},
        "NumberOfNodes": {
          "type": "integer"
        },
        "DBName": {},
        "VpcId": {},
        "Encrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "EncryptedWithHSM": {
          "type": "boolean"
        },
        "AccountsWithRestoreAccess": {
          "type": "list",
          "member": {
            "locationName": "AccountWithRestoreAccess",
            "type": "structure",
            "members": {
              "AccountId": {},
              "AccountAlias": {}
            }
          }
        },
        "OwnerAccount": {},
        "TotalBackupSizeInMegaBytes": {
          "type": "double"
        },
        "ActualIncrementalBackupSizeInMegaBytes": {
          "type": "double"
        },
        "BackupProgressInMegaBytes": {
          "type": "double"
        },
        "CurrentBackupRateInMegaBytesPerSecond": {
          "type": "double"
        },
        "EstimatedSecondsToCompletion": {
          "type": "long"
        },
        "ElapsedTimeInSeconds": {
          "type": "long"
        },
        "SourceRegion": {},
        "Tags": {
          "shape": "Sg"
        },
        "RestorableNodeTypes": {
          "type": "list",
          "member": {
            "locationName": "NodeType"
          }
        },
        "EnhancedVpcRouting": {
          "type": "boolean"
        },
        "MaintenanceTrackName": {},
        "ManualSnapshotRetentionPeriod": {
          "type": "integer"
        },
        "ManualSnapshotRemainingDays": {
          "type": "integer"
        },
        "SnapshotRetentionStartTime": {
          "type": "timestamp"
        }
      },
      "wrapper": true
    },
    "Sv": {
      "type": "structure",
      "required": [
        "SnapshotIdentifier"
      ],
      "members": {
        "SnapshotIdentifier": {},
        "SnapshotClusterIdentifier": {}
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "locationName": "String"
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "SnapshotIdentifier": {},
        "SnapshotClusterIdentifier": {},
        "FailureCode": {},
        "FailureReason": {}
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "TargetNodeType": {},
        "TargetNumberOfNodes": {
          "type": "integer"
        },
        "TargetClusterType": {},
        "Status": {},
        "ImportTablesCompleted": {
          "type": "list",
          "member": {}
        },
        "ImportTablesInProgress": {
          "type": "list",
          "member": {}
        },
        "ImportTablesNotStarted": {
          "type": "list",
          "member": {}
        },
        "AvgResizeRateInMegaBytesPerSecond": {
          "type": "double"
        },
        "TotalResizeDataInMegaBytes": {
          "type": "long"
        },
        "ProgressInMegaBytes": {
          "type": "long"
        },
        "ElapsedTimeInSeconds": {
          "type": "long"
        },
        "EstimatedTimeToCompletionInSeconds": {
          "type": "long"
        },
        "ResizeType": {},
        "Message": {},
        "TargetEncryptionType": {},
        "DataTransferProgressPercent": {
          "type": "double"
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {
        "locationName": "ClusterSecurityGroupName"
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "locationName": "IamRoleArn"
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "ClusterIdentifier": {},
        "NodeType": {},
        "ClusterStatus": {},
        "ClusterAvailabilityStatus": {},
        "ModifyStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "AutomatedSnapshotRetentionPeriod": {
          "type": "integer"
        },
        "ManualSnapshotRetentionPeriod": {
          "type": "integer"
        },
        "ClusterSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "ClusterSecurityGroup",
            "type": "structure",
            "members": {
              "ClusterSecurityGroupName": {},
              "Status": {}
            }
          }
        },
        "VpcSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "VpcSecurityGroup",
            "type": "structure",
            "members": {
              "VpcSecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "ClusterParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "ClusterParameterGroup",
            "type": "structure",
            "members": {
              "ParameterGroupName": {},
              "ParameterApplyStatus": {},
              "ClusterParameterStatusList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ParameterName": {},
                    "ParameterApplyStatus": {},
                    "ParameterApplyErrorDescription": {}
                  }
                }
              }
            }
          }
        },
        "ClusterSubnetGroupName": {},
        "VpcId": {},
        "AvailabilityZone": {},
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "MasterUserPassword": {},
            "NodeType": {},
            "NumberOfNodes": {
              "type": "integer"
            },
            "ClusterType": {},
            "ClusterVersion": {},
            "AutomatedSnapshotRetentionPeriod": {
              "type": "integer"
            },
            "ClusterIdentifier": {},
            "PubliclyAccessible": {
              "type": "boolean"
            },
            "EnhancedVpcRouting": {
              "type": "boolean"
            },
            "MaintenanceTrackName": {},
            "EncryptionType": {}
          }
        },
        "ClusterVersion": {},
        "AllowVersionUpgrade": {
          "type": "boolean"
        },
        "NumberOfNodes": {
          "type": "integer"
        },
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "Encrypted": {
          "type": "boolean"
        },
        "RestoreStatus": {
          "type": "structure",
          "members": {
            "Status": {},
            "CurrentRestoreRateInMegaBytesPerSecond": {
              "type": "double"
            },
            "SnapshotSizeInMegaBytes": {
              "type": "long"
            },
            "ProgressInMegaBytes": {
              "type": "long"
            },
            "ElapsedTimeInSeconds": {
              "type": "long"
            },
            "EstimatedTimeToCompletionInSeconds": {
              "type": "long"
            }
          }
        },
        "DataTransferProgress": {
          "type": "structure",
          "members": {
            "Status": {},
            "CurrentRateInMegaBytesPerSecond": {
              "type": "double"
            },
            "TotalDataInMegaBytes": {
              "type": "long"
            },
            "DataTransferredInMegaBytes": {
              "type": "long"
            },
            "EstimatedTimeToCompletionInSeconds": {
              "type": "long"
            },
            "ElapsedTimeInSeconds": {
              "type": "long"
            }
          }
        },
        "HsmStatus": {
          "type": "structure",
          "members": {
            "HsmClientCertificateIdentifier": {},
            "HsmConfigurationIdentifier": {},
            "Status": {}
          }
        },
        "ClusterSnapshotCopyStatus": {
          "type": "structure",
          "members": {
            "DestinationRegion": {},
            "RetentionPeriod": {
              "type": "long"
            },
            "ManualSnapshotRetentionPeriod": {
              "type": "integer"
            },
            "SnapshotCopyGrantName": {}
          }
        },
        "ClusterPublicKey": {},
        "ClusterNodes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "NodeRole": {},
              "PrivateIPAddress": {},
              "PublicIPAddress": {}
            }
          }
        },
        "ElasticIpStatus": {
          "type": "structure",
          "members": {
            "ElasticIp": {},
            "Status": {}
          }
        },
        "ClusterRevisionNumber": {},
        "Tags": {
          "shape": "Sg"
        },
        "KmsKeyId": {},
        "EnhancedVpcRouting": {
          "type": "boolean"
        },
        "IamRoles": {
          "type": "list",
          "member": {
            "locationName": "ClusterIamRole",
            "type": "structure",
            "members": {
              "IamRoleArn": {},
              "ApplyStatus": {}
            }
          }
        },
        "PendingActions": {
          "type": "list",
          "member": {}
        },
        "MaintenanceTrackName": {},
        "ElasticResizeNumberOfNodeOptions": {},
        "DeferredMaintenanceWindows": {
          "type": "list",
          "member": {
            "locationName": "DeferredMaintenanceWindow",
            "type": "structure",
            "members": {
              "DeferMaintenanceIdentifier": {},
              "DeferMaintenanceStartTime": {
                "type": "timestamp"
              },
              "DeferMaintenanceEndTime": {
                "type": "timestamp"
              }
            }
          }
        },
        "SnapshotScheduleIdentifier": {},
        "SnapshotScheduleState": {},
        "ExpectedNextSnapshotScheduleTime": {
          "type": "timestamp"
        },
        "ExpectedNextSnapshotScheduleTimeStatus": {},
        "NextMaintenanceWindowStartTime": {
          "type": "timestamp"
        },
        "ResizeInfo": {
          "type": "structure",
          "members": {
            "ResizeType": {},
            "AllowCancelResize": {
              "type": "boolean"
            }
          }
        }
      },
      "wrapper": true
    },
    "S29": {
      "type": "structure",
      "members": {
        "ParameterGroupName": {},
        "ParameterGroupFamily": {},
        "Description": {},
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S2f": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "ClusterSubnetGroupName": {},
        "Description": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S2k"
              },
              "SubnetStatus": {}
            }
          }
        },
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S2k": {
      "type": "structure",
      "members": {
        "Name": {},
        "SupportedPlatforms": {
          "type": "list",
          "member": {
            "locationName": "SupportedPlatform",
            "type": "structure",
            "members": {
              "Name": {}
            },
            "wrapper": true
          }
        }
      },
      "wrapper": true
    },
    "S2o": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S2p": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {
          "type": "timestamp"
        },
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S2o"
        },
        "EventCategoriesList": {
          "shape": "S2p"
        },
        "Severity": {},
        "Enabled": {
          "type": "boolean"
        },
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S2u": {
      "type": "structure",
      "members": {
        "HsmClientCertificateIdentifier": {},
        "HsmClientCertificatePublicKey": {},
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S2x": {
      "type": "structure",
      "members": {
        "HsmConfigurationIdentifier": {},
        "Description": {},
        "HsmIpAddress": {},
        "HsmPartitionName": {},
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S2z": {
      "type": "structure",
      "members": {
        "ResizeCluster": {
          "shape": "S30"
        },
        "PauseCluster": {
          "shape": "S31"
        },
        "ResumeCluster": {
          "shape": "S32"
        }
      }
    },
    "S30": {
      "type": "structure",
      "required": [
        "ClusterIdentifier"
      ],
      "members": {
        "ClusterIdentifier": {},
        "ClusterType": {},
        "NodeType": {},
        "NumberOfNodes": {
          "type": "integer"
        },
        "Classic": {
          "type": "boolean"
        }
      }
    },
    "S31": {
      "type": "structure",
      "required": [
        "ClusterIdentifier"
      ],
      "members": {
        "ClusterIdentifier": {}
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "ClusterIdentifier"
      ],
      "members": {
        "ClusterIdentifier": {}
      }
    },
    "S33": {
      "type": "structure",
      "members": {
        "ScheduledActionName": {},
        "TargetAction": {
          "shape": "S2z"
        },
        "Schedule": {},
        "IamRole": {},
        "ScheduledActionDescription": {},
        "State": {},
        "NextInvocations": {
          "type": "list",
          "member": {
            "locationName": "ScheduledActionTime",
            "type": "timestamp"
          }
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "SnapshotCopyGrantName": {},
        "KmsKeyId": {},
        "Tags": {
          "shape": "Sg"
        }
      },
      "wrapper": true
    },
    "S3a": {
      "type": "list",
      "member": {
        "locationName": "ScheduleDefinition"
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "ScheduleDefinitions": {
          "shape": "S3a"
        },
        "ScheduleIdentifier": {},
        "ScheduleDescription": {},
        "Tags": {
          "shape": "Sg"
        },
        "NextInvocations": {
          "type": "list",
          "member": {
            "locationName": "SnapshotTime",
            "type": "timestamp"
          }
        },
        "AssociatedClusterCount": {
          "type": "integer"
        },
        "AssociatedClusters": {
          "type": "list",
          "member": {
            "locationName": "ClusterAssociatedToSchedule",
            "type": "structure",
            "members": {
              "ClusterIdentifier": {},
              "ScheduleAssociationState": {}
            }
          }
        }
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "UsageLimitId": {},
        "ClusterIdentifier": {},
        "FeatureType": {},
        "LimitType": {},
        "Amount": {
          "type": "long"
        },
        "Period": {},
        "BreachAction": {},
        "Tags": {
          "shape": "Sg"
        }
      }
    },
    "S3z": {
      "type": "list",
      "member": {
        "locationName": "TagKey"
      }
    },
    "S4f": {
      "type": "list",
      "member": {
        "locationName": "TagValue"
      }
    },
    "S4k": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "ApplyType": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {}
        }
      }
    },
    "S63": {
      "type": "structure",
      "members": {
        "LoggingEnabled": {
          "type": "boolean"
        },
        "BucketName": {},
        "S3KeyPrefix": {},
        "LastSuccessfulDeliveryTime": {
          "type": "timestamp"
        },
        "LastFailureTime": {
          "type": "timestamp"
        },
        "LastFailureMessage": {}
      }
    },
    "S6a": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S6m": {
      "type": "list",
      "member": {
        "locationName": "ReservedNodeOffering",
        "type": "structure",
        "members": {
          "ReservedNodeOfferingId": {},
          "NodeType": {},
          "Duration": {
            "type": "integer"
          },
          "FixedPrice": {
            "type": "double"
          },
          "UsagePrice": {
            "type": "double"
          },
          "CurrencyCode": {},
          "OfferingType": {},
          "RecurringCharges": {
            "shape": "S8"
          },
          "ReservedNodeOfferingType": {}
        },
        "wrapper": true
      }
    },
    "S79": {
      "type": "structure",
      "members": {
        "TableRestoreRequestId": {},
        "Status": {},
        "Message": {},
        "RequestTime": {
          "type": "timestamp"
        },
        "ProgressInMegaBytes": {
          "type": "long"
        },
        "TotalDataInMegaBytes": {
          "type": "long"
        },
        "ClusterIdentifier": {},
        "SnapshotIdentifier": {},
        "SourceDatabaseName": {},
        "SourceSchemaName": {},
        "SourceTableName": {},
        "TargetDatabaseName": {},
        "TargetSchemaName": {},
        "NewTableName": {}
      },
      "wrapper": true
    },
    "S83": {
      "type": "structure",
      "members": {
        "ParameterGroupName": {},
        "ParameterGroupStatus": {}
      }
    }
  }
}
},{}],185:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeClusterParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ParameterGroups"
    },
    "DescribeClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeClusterSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterSecurityGroups"
    },
    "DescribeClusterSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Snapshots"
    },
    "DescribeClusterSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterSubnetGroups"
    },
    "DescribeClusterVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterVersions"
    },
    "DescribeClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Clusters"
    },
    "DescribeDefaultClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "DefaultClusterParameters.Marker",
      "result_key": "DefaultClusterParameters.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeHsmClientCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "HsmClientCertificates"
    },
    "DescribeHsmConfigurations": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "HsmConfigurations"
    },
    "DescribeNodeConfigurationOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "NodeConfigurationOptionList"
    },
    "DescribeOrderableClusterOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableClusterOptions"
    },
    "DescribeReservedNodeOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodeOfferings"
    },
    "DescribeReservedNodes": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodes"
    },
    "DescribeScheduledActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ScheduledActions"
    },
    "DescribeUsageLimits": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "UsageLimits"
    }
  }
}
},{}],186:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "ClusterAvailable": {
      "delay": 60,
      "operation": "DescribeClusters",
      "maxAttempts": 30,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Clusters[].ClusterStatus"
        },
        {
          "expected": "deleting",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Clusters[].ClusterStatus"
        },
        {
          "expected": "ClusterNotFound",
          "matcher": "error",
          "state": "retry"
        }
      ]
    },
    "ClusterDeleted": {
      "delay": 60,
      "operation": "DescribeClusters",
      "maxAttempts": 30,
      "acceptors": [
        {
          "expected": "ClusterNotFound",
          "matcher": "error",
          "state": "success"
        },
        {
          "expected": "creating",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Clusters[].ClusterStatus"
        },
        {
          "expected": "modifying",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Clusters[].ClusterStatus"
        }
      ]
    },
    "ClusterRestored": {
      "operation": "DescribeClusters",
      "maxAttempts": 30,
      "delay": 60,
      "acceptors": [
        {
          "state": "success",
          "matcher": "pathAll",
          "argument": "Clusters[].RestoreStatus.Status",
          "expected": "completed"
        },
        {
          "state": "failure",
          "matcher": "pathAny",
          "argument": "Clusters[].ClusterStatus",
          "expected": "deleting"
        }
      ]
    },
    "SnapshotAvailable": {
      "delay": 15,
      "operation": "DescribeClusterSnapshots",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": "available",
          "matcher": "pathAll",
          "state": "success",
          "argument": "Snapshots[].Status"
        },
        {
          "expected": "failed",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Snapshots[].Status"
        },
        {
          "expected": "deleted",
          "matcher": "pathAny",
          "state": "failure",
          "argument": "Snapshots[].Status"
        }
      ]
    }
  }
}

},{}],187:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-27",
    "endpointPrefix": "rekognition",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Rekognition",
    "serviceId": "Rekognition",
    "signatureVersion": "v4",
    "targetPrefix": "RekognitionService",
    "uid": "rekognition-2016-06-27"
  },
  "operations": {
    "CompareFaces": {
      "input": {
        "type": "structure",
        "required": [
          "SourceImage",
          "TargetImage"
        ],
        "members": {
          "SourceImage": {
            "shape": "S2"
          },
          "TargetImage": {
            "shape": "S2"
          },
          "SimilarityThreshold": {
            "type": "float"
          },
          "QualityFilter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceImageFace": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "shape": "Sc"
              },
              "Confidence": {
                "type": "float"
              }
            }
          },
          "FaceMatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Similarity": {
                  "type": "float"
                },
                "Face": {
                  "shape": "Sg"
                }
              }
            }
          },
          "UnmatchedFaces": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          },
          "SourceImageOrientationCorrection": {},
          "TargetImageOrientationCorrection": {}
        }
      }
    },
    "CreateCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          },
          "CollectionArn": {},
          "FaceModelVersion": {}
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectName"
        ],
        "members": {
          "ProjectName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProjectArn": {}
        }
      }
    },
    "CreateProjectVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectArn",
          "VersionName",
          "OutputConfig",
          "TrainingData",
          "TestingData"
        ],
        "members": {
          "ProjectArn": {},
          "VersionName": {},
          "OutputConfig": {
            "shape": "S10"
          },
          "TrainingData": {
            "shape": "S12"
          },
          "TestingData": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProjectVersionArn": {}
        }
      }
    },
    "CreateStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Input",
          "Output",
          "Name",
          "Settings",
          "RoleArn"
        ],
        "members": {
          "Input": {
            "shape": "S1b"
          },
          "Output": {
            "shape": "S1e"
          },
          "Name": {},
          "Settings": {
            "shape": "S1i"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamProcessorArn": {}
        }
      }
    },
    "DeleteCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceIds"
        ],
        "members": {
          "CollectionId": {},
          "FaceIds": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletedFaces": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectArn"
        ],
        "members": {
          "ProjectArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteProjectVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectVersionArn"
        ],
        "members": {
          "ProjectVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceCount": {
            "type": "long"
          },
          "FaceModelVersion": {},
          "CollectionARN": {},
          "CreationTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeProjectVersions": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectArn"
        ],
        "members": {
          "ProjectArn": {},
          "VersionNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProjectVersionDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProjectVersionArn": {},
                "CreationTimestamp": {
                  "type": "timestamp"
                },
                "MinInferenceUnits": {
                  "type": "integer"
                },
                "Status": {},
                "StatusMessage": {},
                "BillableTrainingTimeInSeconds": {
                  "type": "long"
                },
                "TrainingEndTimestamp": {
                  "type": "timestamp"
                },
                "OutputConfig": {
                  "shape": "S10"
                },
                "TrainingDataResult": {
                  "type": "structure",
                  "members": {
                    "Input": {
                      "shape": "S12"
                    },
                    "Output": {
                      "shape": "S12"
                    }
                  }
                },
                "TestingDataResult": {
                  "type": "structure",
                  "members": {
                    "Input": {
                      "shape": "S16"
                    },
                    "Output": {
                      "shape": "S16"
                    }
                  }
                },
                "EvaluationResult": {
                  "type": "structure",
                  "members": {
                    "F1Score": {
                      "type": "float"
                    },
                    "Summary": {
                      "type": "structure",
                      "members": {
                        "S3Object": {
                          "shape": "S4"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeProjects": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProjectDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProjectArn": {},
                "CreationTimestamp": {
                  "type": "timestamp"
                },
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "StreamProcessorArn": {},
          "Status": {},
          "StatusMessage": {},
          "CreationTimestamp": {
            "type": "timestamp"
          },
          "LastUpdateTimestamp": {
            "type": "timestamp"
          },
          "Input": {
            "shape": "S1b"
          },
          "Output": {
            "shape": "S1e"
          },
          "RoleArn": {},
          "Settings": {
            "shape": "S1i"
          }
        }
      }
    },
    "DetectCustomLabels": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectVersionArn",
          "Image"
        ],
        "members": {
          "ProjectVersionArn": {},
          "Image": {
            "shape": "S2"
          },
          "MaxResults": {
            "type": "integer"
          },
          "MinConfidence": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomLabels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Confidence": {
                  "type": "float"
                },
                "Geometry": {
                  "shape": "S2u"
                }
              }
            }
          }
        }
      }
    },
    "DetectFaces": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "Attributes": {
            "shape": "S2y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceDetails": {
            "type": "list",
            "member": {
              "shape": "S32"
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "DetectLabels": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "MaxLabels": {
            "type": "integer"
          },
          "MinConfidence": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Labels": {
            "type": "list",
            "member": {
              "shape": "S3j"
            }
          },
          "OrientationCorrection": {},
          "LabelModelVersion": {}
        }
      }
    },
    "DetectModerationLabels": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "MinConfidence": {
            "type": "float"
          },
          "HumanLoopConfig": {
            "type": "structure",
            "required": [
              "HumanLoopName",
              "FlowDefinitionArn"
            ],
            "members": {
              "HumanLoopName": {},
              "FlowDefinitionArn": {},
              "DataAttributes": {
                "type": "structure",
                "members": {
                  "ContentClassifiers": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModerationLabels": {
            "type": "list",
            "member": {
              "shape": "S3x"
            }
          },
          "ModerationModelVersion": {},
          "HumanLoopActivationOutput": {
            "type": "structure",
            "members": {
              "HumanLoopArn": {},
              "HumanLoopActivationReasons": {
                "type": "list",
                "member": {}
              },
              "HumanLoopActivationConditionsEvaluationResults": {
                "jsonvalue": true
              }
            }
          }
        }
      }
    },
    "DetectText": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "WordFilter": {
                "shape": "S45"
              },
              "RegionsOfInterest": {
                "shape": "S48"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextDetections": {
            "type": "list",
            "member": {
              "shape": "S4c"
            }
          },
          "TextModelVersion": {}
        }
      }
    },
    "GetCelebrityInfo": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Urls": {
            "shape": "S4h"
          },
          "Name": {}
        }
      }
    },
    "GetCelebrityRecognition": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "NextToken": {},
          "Celebrities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Celebrity": {
                  "type": "structure",
                  "members": {
                    "Urls": {
                      "shape": "S4h"
                    },
                    "Name": {},
                    "Id": {},
                    "Confidence": {
                      "type": "float"
                    },
                    "BoundingBox": {
                      "shape": "Sc"
                    },
                    "Face": {
                      "shape": "S32"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetContentModeration": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "ModerationLabels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "ModerationLabel": {
                  "shape": "S3x"
                }
              }
            }
          },
          "NextToken": {},
          "ModerationModelVersion": {}
        }
      }
    },
    "GetFaceDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "NextToken": {},
          "Faces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Face": {
                  "shape": "S32"
                }
              }
            }
          }
        }
      }
    },
    "GetFaceSearch": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "NextToken": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "Persons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Person": {
                  "shape": "S59"
                },
                "FaceMatches": {
                  "shape": "S5b"
                }
              }
            }
          }
        }
      }
    },
    "GetLabelDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "NextToken": {},
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Label": {
                  "shape": "S3j"
                }
              }
            }
          },
          "LabelModelVersion": {}
        }
      }
    },
    "GetPersonTracking": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "NextToken": {},
          "Persons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Person": {
                  "shape": "S59"
                }
              }
            }
          }
        }
      }
    },
    "GetSegmentDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "type": "list",
            "member": {
              "shape": "S4q"
            }
          },
          "AudioMetadata": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Codec": {},
                "DurationMillis": {
                  "type": "long"
                },
                "SampleRate": {
                  "type": "long"
                },
                "NumberOfChannels": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {},
          "Segments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "StartTimestampMillis": {
                  "type": "long"
                },
                "EndTimestampMillis": {
                  "type": "long"
                },
                "DurationMillis": {
                  "type": "long"
                },
                "StartTimecodeSMPTE": {},
                "EndTimecodeSMPTE": {},
                "DurationSMPTE": {},
                "TechnicalCueSegment": {
                  "type": "structure",
                  "members": {
                    "Type": {},
                    "Confidence": {
                      "type": "float"
                    }
                  }
                },
                "ShotSegment": {
                  "type": "structure",
                  "members": {
                    "Index": {
                      "type": "long"
                    },
                    "Confidence": {
                      "type": "float"
                    }
                  }
                }
              }
            }
          },
          "SelectedSegmentTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ModelVersion": {}
              }
            }
          }
        }
      }
    },
    "GetTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S4q"
          },
          "TextDetections": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "TextDetection": {
                  "shape": "S4c"
                }
              }
            }
          },
          "NextToken": {},
          "TextModelVersion": {}
        }
      }
    },
    "IndexFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "ExternalImageId": {},
          "DetectionAttributes": {
            "shape": "S2y"
          },
          "MaxFaces": {
            "type": "integer"
          },
          "QualityFilter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Face": {
                  "shape": "S5d"
                },
                "FaceDetail": {
                  "shape": "S32"
                }
              }
            }
          },
          "OrientationCorrection": {},
          "FaceModelVersion": {},
          "UnindexedFaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Reasons": {
                  "type": "list",
                  "member": {}
                },
                "FaceDetail": {
                  "shape": "S32"
                }
              }
            }
          }
        }
      }
    },
    "ListCollections": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CollectionIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "FaceModelVersions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Faces": {
            "type": "list",
            "member": {
              "shape": "S5d"
            }
          },
          "NextToken": {},
          "FaceModelVersion": {}
        }
      }
    },
    "ListStreamProcessors": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StreamProcessors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Status": {}
              }
            }
          }
        }
      }
    },
    "RecognizeCelebrities": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CelebrityFaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Urls": {
                  "shape": "S4h"
                },
                "Name": {},
                "Id": {},
                "Face": {
                  "shape": "Sg"
                },
                "MatchConfidence": {
                  "type": "float"
                }
              }
            }
          },
          "UnrecognizedFaces": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "SearchFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceId"
        ],
        "members": {
          "CollectionId": {},
          "FaceId": {},
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceId": {},
          "FaceMatches": {
            "shape": "S5b"
          },
          "FaceModelVersion": {}
        }
      }
    },
    "SearchFacesByImage": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          },
          "QualityFilter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceBoundingBox": {
            "shape": "Sc"
          },
          "SearchedFaceConfidence": {
            "type": "float"
          },
          "FaceMatches": {
            "shape": "S5b"
          },
          "FaceModelVersion": {}
        }
      }
    },
    "StartCelebrityRecognition": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartContentModeration": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "MinConfidence": {
            "type": "float"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartFaceDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "FaceAttributes": {},
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartFaceSearch": {
      "input": {
        "type": "structure",
        "required": [
          "Video",
          "CollectionId"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "FaceMatchThreshold": {
            "type": "float"
          },
          "CollectionId": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartLabelDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "MinConfidence": {
            "type": "float"
          },
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartPersonTracking": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartProjectVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectVersionArn",
          "MinInferenceUnits"
        ],
        "members": {
          "ProjectVersionArn": {},
          "MinInferenceUnits": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "StartSegmentDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video",
          "SegmentTypes"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {},
          "Filters": {
            "type": "structure",
            "members": {
              "TechnicalCueFilter": {
                "type": "structure",
                "members": {
                  "MinSegmentConfidence": {
                    "type": "float"
                  }
                }
              },
              "ShotFilter": {
                "type": "structure",
                "members": {
                  "MinSegmentConfidence": {
                    "type": "float"
                  }
                }
              }
            }
          },
          "SegmentTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S75"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S77"
          },
          "JobTag": {},
          "Filters": {
            "type": "structure",
            "members": {
              "WordFilter": {
                "shape": "S45"
              },
              "RegionsOfInterest": {
                "shape": "S48"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StopProjectVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectVersionArn"
        ],
        "members": {
          "ProjectVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "StopStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Name": {},
        "Version": {}
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "Width": {
          "type": "float"
        },
        "Height": {
          "type": "float"
        },
        "Left": {
          "type": "float"
        },
        "Top": {
          "type": "float"
        }
      }
    },
    "Sg": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sc"
        },
        "Confidence": {
          "type": "float"
        },
        "Landmarks": {
          "shape": "Sh"
        },
        "Pose": {
          "shape": "Sk"
        },
        "Quality": {
          "shape": "Sm"
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "X": {
            "type": "float"
          },
          "Y": {
            "type": "float"
          }
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "Roll": {
          "type": "float"
        },
        "Yaw": {
          "type": "float"
        },
        "Pitch": {
          "type": "float"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "Brightness": {
          "type": "float"
        },
        "Sharpness": {
          "type": "float"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3KeyPrefix": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "Assets": {
          "shape": "S13"
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "GroundTruthManifest": {
            "type": "structure",
            "members": {
              "S3Object": {
                "shape": "S4"
              }
            }
          }
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "Assets": {
          "shape": "S13"
        },
        "AutoCreate": {
          "type": "boolean"
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "KinesisVideoStream": {
          "type": "structure",
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "KinesisDataStream": {
          "type": "structure",
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "FaceSearch": {
          "type": "structure",
          "members": {
            "CollectionId": {},
            "FaceMatchThreshold": {
              "type": "float"
            }
          }
        }
      }
    },
    "S1q": {
      "type": "list",
      "member": {}
    },
    "S2u": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sc"
        },
        "Polygon": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "X": {
                "type": "float"
              },
              "Y": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S2y": {
      "type": "list",
      "member": {}
    },
    "S32": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sc"
        },
        "AgeRange": {
          "type": "structure",
          "members": {
            "Low": {
              "type": "integer"
            },
            "High": {
              "type": "integer"
            }
          }
        },
        "Smile": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Eyeglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Sunglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Gender": {
          "type": "structure",
          "members": {
            "Value": {},
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Beard": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Mustache": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "EyesOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "MouthOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Emotions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Confidence": {
                "type": "float"
              }
            }
          }
        },
        "Landmarks": {
          "shape": "Sh"
        },
        "Pose": {
          "shape": "Sk"
        },
        "Quality": {
          "shape": "Sm"
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S3j": {
      "type": "structure",
      "members": {
        "Name": {},
        "Confidence": {
          "type": "float"
        },
        "Instances": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "shape": "Sc"
              },
              "Confidence": {
                "type": "float"
              }
            }
          }
        },
        "Parents": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {}
            }
          }
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "Confidence": {
          "type": "float"
        },
        "Name": {},
        "ParentName": {}
      }
    },
    "S45": {
      "type": "structure",
      "members": {
        "MinConfidence": {
          "type": "float"
        },
        "MinBoundingBoxHeight": {
          "type": "float"
        },
        "MinBoundingBoxWidth": {
          "type": "float"
        }
      }
    },
    "S48": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BoundingBox": {
            "shape": "Sc"
          }
        }
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "DetectedText": {},
        "Type": {},
        "Id": {
          "type": "integer"
        },
        "ParentId": {
          "type": "integer"
        },
        "Confidence": {
          "type": "float"
        },
        "Geometry": {
          "shape": "S2u"
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {}
    },
    "S4q": {
      "type": "structure",
      "members": {
        "Codec": {},
        "DurationMillis": {
          "type": "long"
        },
        "Format": {},
        "FrameRate": {
          "type": "float"
        },
        "FrameHeight": {
          "type": "long"
        },
        "FrameWidth": {
          "type": "long"
        }
      }
    },
    "S59": {
      "type": "structure",
      "members": {
        "Index": {
          "type": "long"
        },
        "BoundingBox": {
          "shape": "Sc"
        },
        "Face": {
          "shape": "S32"
        }
      }
    },
    "S5b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Similarity": {
            "type": "float"
          },
          "Face": {
            "shape": "S5d"
          }
        }
      }
    },
    "S5d": {
      "type": "structure",
      "members": {
        "FaceId": {},
        "BoundingBox": {
          "shape": "Sc"
        },
        "ImageId": {},
        "ExternalImageId": {},
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S75": {
      "type": "structure",
      "members": {
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S77": {
      "type": "structure",
      "required": [
        "SNSTopicArn",
        "RoleArn"
      ],
      "members": {
        "SNSTopicArn": {},
        "RoleArn": {}
      }
    }
  }
}
},{}],188:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeProjectVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ProjectVersionDescriptions"
    },
    "DescribeProjects": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ProjectDescriptions"
    },
    "GetCelebrityRecognition": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetContentModeration": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetFaceDetection": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetFaceSearch": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetLabelDetection": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetPersonTracking": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetSegmentDetection": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetTextDetection": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListCollections": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CollectionIds"
    },
    "ListFaces": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Faces"
    },
    "ListStreamProcessors": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}
},{}],189:[function(require,module,exports){
module.exports={
    "version": 2,
    "waiters": {
        "ProjectVersionTrainingCompleted": {
            "description": "Wait until the ProjectVersion training completes.",
            "operation": "DescribeProjectVersions",
            "delay": 120,
            "maxAttempts": 360,
            "acceptors": [
                {
                    "state": "success",
                    "matcher": "pathAll",
                    "argument": "ProjectVersionDescriptions[].Status",
                    "expected": "TRAINING_COMPLETED"
                },
                {
                    "state": "failure",
                    "matcher": "pathAny",
                    "argument": "ProjectVersionDescriptions[].Status",
                    "expected": "TRAINING_FAILED"
                }
            ]
        },
        "ProjectVersionRunning": {
            "description": "Wait until the ProjectVersion is running.",
            "delay": 30,
            "maxAttempts": 40,
            "operation": "DescribeProjectVersions",
            "acceptors": [
                {
                    "state": "success",
                    "matcher": "pathAll",
                    "argument": "ProjectVersionDescriptions[].Status",
                    "expected": "RUNNING"
                },
                {
                    "state": "failure",
                    "matcher": "pathAny",
                    "argument": "ProjectVersionDescriptions[].Status",
                    "expected": "FAILED"
                }
            ]
        }
    }
}

},{}],190:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "resource-groups",
    "protocol": "rest-json",
    "serviceAbbreviation": "Resource Groups",
    "serviceFullName": "AWS Resource Groups",
    "serviceId": "Resource Groups",
    "signatureVersion": "v4",
    "signingName": "resource-groups",
    "uid": "resource-groups-2017-11-27"
  },
  "operations": {
    "CreateGroup": {
      "http": {
        "requestUri": "/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ResourceQuery"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ResourceQuery": {
            "shape": "S4"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          },
          "ResourceQuery": {
            "shape": "S4"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetGroupQuery": {
      "http": {
        "method": "GET",
        "requestUri": "/groups/{GroupName}/query"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupQuery": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetTags": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "ListGroupResources": {
      "http": {
        "requestUri": "/groups/{GroupName}/resource-identifiers-list"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "shape": "Sv"
          },
          "NextToken": {},
          "QueryErrors": {
            "shape": "Sz"
          }
        }
      }
    },
    "ListGroups": {
      "http": {
        "requestUri": "/groups-list"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "GroupArn": {}
              }
            }
          },
          "Groups": {
            "deprecated": true,
            "deprecatedMessage": "This field is deprecated, use GroupIdentifiers instead.",
            "type": "list",
            "member": {
              "shape": "Sb"
            }
          },
          "NextToken": {}
        }
      }
    },
    "SearchResources": {
      "http": {
        "requestUri": "/resources/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceQuery"
        ],
        "members": {
          "ResourceQuery": {
            "shape": "S4"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "shape": "Sv"
          },
          "NextToken": {},
          "QueryErrors": {
            "shape": "Sz"
          }
        }
      }
    },
    "Tag": {
      "http": {
        "method": "PUT",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Tags"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "Untag": {
      "http": {
        "method": "PATCH",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Keys"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          },
          "Keys": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Keys": {
            "shape": "S1i"
          }
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateGroupQuery": {
      "http": {
        "method": "PUT",
        "requestUri": "/groups/{GroupName}/query"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "ResourceQuery"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "ResourceQuery": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupQuery": {
            "shape": "Sj"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "Type",
        "Query"
      ],
      "members": {
        "Type": {},
        "Query": {}
      }
    },
    "S7": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sb": {
      "type": "structure",
      "required": [
        "GroupArn",
        "Name"
      ],
      "members": {
        "GroupArn": {},
        "Name": {},
        "Description": {}
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "GroupName",
        "ResourceQuery"
      ],
      "members": {
        "GroupName": {},
        "ResourceQuery": {
          "shape": "S4"
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "ResourceType": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "Message": {}
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],191:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListGroupResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "SearchResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],192:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-04-01",
    "endpointPrefix": "route53",
    "globalEndpoint": "route53.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "Route 53",
    "serviceFullName": "Amazon Route 53",
    "serviceId": "Route 53",
    "signatureVersion": "v4",
    "uid": "route53-2013-04-01"
  },
  "operations": {
    "AssociateVPCWithHostedZone": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}/associatevpc"
      },
      "input": {
        "locationName": "AssociateVPCWithHostedZoneRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPC"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "VPC": {
            "shape": "S3"
          },
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ChangeInfo"
        ],
        "members": {
          "ChangeInfo": {
            "shape": "S8"
          }
        }
      }
    },
    "ChangeResourceRecordSets": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}/rrset/"
      },
      "input": {
        "locationName": "ChangeResourceRecordSetsRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "ChangeBatch"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "ChangeBatch": {
            "type": "structure",
            "required": [
              "Changes"
            ],
            "members": {
              "Comment": {},
              "Changes": {
                "type": "list",
                "member": {
                  "locationName": "Change",
                  "type": "structure",
                  "required": [
                    "Action",
                    "ResourceRecordSet"
                  ],
                  "members": {
                    "Action": {},
                    "ResourceRecordSet": {
                      "shape": "Sh"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ChangeInfo"
        ],
        "members": {
          "ChangeInfo": {
            "shape": "S8"
          }
        }
      }
    },
    "ChangeTagsForResource": {
      "http": {
        "requestUri": "/2013-04-01/tags/{ResourceType}/{ResourceId}"
      },
      "input": {
        "locationName": "ChangeTagsForResourceRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {
            "location": "uri",
            "locationName": "ResourceType"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "AddTags": {
            "shape": "S15"
          },
          "RemoveTagKeys": {
            "type": "list",
            "member": {
              "locationName": "Key"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateHealthCheck": {
      "http": {
        "requestUri": "/2013-04-01/healthcheck",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateHealthCheckRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "CallerReference",
          "HealthCheckConfig"
        ],
        "members": {
          "CallerReference": {},
          "HealthCheckConfig": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheck",
          "Location"
        ],
        "members": {
          "HealthCheck": {
            "shape": "S1z"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateHostedZone": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateHostedZoneRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Name",
          "CallerReference"
        ],
        "members": {
          "Name": {},
          "VPC": {
            "shape": "S3"
          },
          "CallerReference": {},
          "HostedZoneConfig": {
            "shape": "S2h"
          },
          "DelegationSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZone",
          "ChangeInfo",
          "DelegationSet",
          "Location"
        ],
        "members": {
          "HostedZone": {
            "shape": "S2k"
          },
          "ChangeInfo": {
            "shape": "S8"
          },
          "DelegationSet": {
            "shape": "S2m"
          },
          "VPC": {
            "shape": "S3"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateQueryLoggingConfig": {
      "http": {
        "requestUri": "/2013-04-01/queryloggingconfig",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateQueryLoggingConfigRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "CloudWatchLogsLogGroupArn"
        ],
        "members": {
          "HostedZoneId": {},
          "CloudWatchLogsLogGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryLoggingConfig",
          "Location"
        ],
        "members": {
          "QueryLoggingConfig": {
            "shape": "S2r"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateReusableDelegationSet": {
      "http": {
        "requestUri": "/2013-04-01/delegationset",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateReusableDelegationSetRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "CallerReference"
        ],
        "members": {
          "CallerReference": {},
          "HostedZoneId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DelegationSet",
          "Location"
        ],
        "members": {
          "DelegationSet": {
            "shape": "S2m"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateTrafficPolicy": {
      "http": {
        "requestUri": "/2013-04-01/trafficpolicy",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateTrafficPolicyRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Name",
          "Document"
        ],
        "members": {
          "Name": {},
          "Document": {},
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicy",
          "Location"
        ],
        "members": {
          "TrafficPolicy": {
            "shape": "S30"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateTrafficPolicyInstance": {
      "http": {
        "requestUri": "/2013-04-01/trafficpolicyinstance",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateTrafficPolicyInstanceRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "Name",
          "TTL",
          "TrafficPolicyId",
          "TrafficPolicyVersion"
        ],
        "members": {
          "HostedZoneId": {},
          "Name": {},
          "TTL": {
            "type": "long"
          },
          "TrafficPolicyId": {},
          "TrafficPolicyVersion": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstance",
          "Location"
        ],
        "members": {
          "TrafficPolicyInstance": {
            "shape": "S35"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateTrafficPolicyVersion": {
      "http": {
        "requestUri": "/2013-04-01/trafficpolicy/{Id}",
        "responseCode": 201
      },
      "input": {
        "locationName": "CreateTrafficPolicyVersionRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Id",
          "Document"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Document": {},
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicy",
          "Location"
        ],
        "members": {
          "TrafficPolicy": {
            "shape": "S30"
          },
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      }
    },
    "CreateVPCAssociationAuthorization": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}/authorizevpcassociation"
      },
      "input": {
        "locationName": "CreateVPCAssociationAuthorizationRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPC"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "VPC": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPC"
        ],
        "members": {
          "HostedZoneId": {},
          "VPC": {
            "shape": "S3"
          }
        }
      }
    },
    "DeleteHealthCheck": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/healthcheck/{HealthCheckId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HealthCheckId"
        ],
        "members": {
          "HealthCheckId": {
            "location": "uri",
            "locationName": "HealthCheckId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteHostedZone": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/hostedzone/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ChangeInfo"
        ],
        "members": {
          "ChangeInfo": {
            "shape": "S8"
          }
        }
      }
    },
    "DeleteQueryLoggingConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/queryloggingconfig/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReusableDelegationSet": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/delegationset/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrafficPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/trafficpolicy/{Id}/{Version}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "Version"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Version": {
            "location": "uri",
            "locationName": "Version",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrafficPolicyInstance": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2013-04-01/trafficpolicyinstance/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVPCAssociationAuthorization": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}/deauthorizevpcassociation"
      },
      "input": {
        "locationName": "DeleteVPCAssociationAuthorizationRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPC"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "VPC": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateVPCFromHostedZone": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}/disassociatevpc"
      },
      "input": {
        "locationName": "DisassociateVPCFromHostedZoneRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPC"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "VPC": {
            "shape": "S3"
          },
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ChangeInfo"
        ],
        "members": {
          "ChangeInfo": {
            "shape": "S8"
          }
        }
      }
    },
    "GetAccountLimit": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/accountlimit/{Type}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "Type": {
            "location": "uri",
            "locationName": "Type"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Limit",
          "Count"
        ],
        "members": {
          "Limit": {
            "type": "structure",
            "required": [
              "Type",
              "Value"
            ],
            "members": {
              "Type": {},
              "Value": {
                "type": "long"
              }
            }
          },
          "Count": {
            "type": "long"
          }
        }
      }
    },
    "GetChange": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/change/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ChangeInfo"
        ],
        "members": {
          "ChangeInfo": {
            "shape": "S8"
          }
        }
      }
    },
    "GetCheckerIpRanges": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/checkeripranges"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "CheckerIpRanges"
        ],
        "members": {
          "CheckerIpRanges": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetGeoLocation": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/geolocation"
      },
      "input": {
        "type": "structure",
        "members": {
          "ContinentCode": {
            "location": "querystring",
            "locationName": "continentcode"
          },
          "CountryCode": {
            "location": "querystring",
            "locationName": "countrycode"
          },
          "SubdivisionCode": {
            "location": "querystring",
            "locationName": "subdivisioncode"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "GeoLocationDetails"
        ],
        "members": {
          "GeoLocationDetails": {
            "shape": "S47"
          }
        }
      }
    },
    "GetHealthCheck": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/healthcheck/{HealthCheckId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HealthCheckId"
        ],
        "members": {
          "HealthCheckId": {
            "location": "uri",
            "locationName": "HealthCheckId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheck"
        ],
        "members": {
          "HealthCheck": {
            "shape": "S1z"
          }
        }
      }
    },
    "GetHealthCheckCount": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/healthcheckcount"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheckCount"
        ],
        "members": {
          "HealthCheckCount": {
            "type": "long"
          }
        }
      }
    },
    "GetHealthCheckLastFailureReason": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/healthcheck/{HealthCheckId}/lastfailurereason"
      },
      "input": {
        "type": "structure",
        "required": [
          "HealthCheckId"
        ],
        "members": {
          "HealthCheckId": {
            "location": "uri",
            "locationName": "HealthCheckId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheckObservations"
        ],
        "members": {
          "HealthCheckObservations": {
            "shape": "S4i"
          }
        }
      }
    },
    "GetHealthCheckStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/healthcheck/{HealthCheckId}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "HealthCheckId"
        ],
        "members": {
          "HealthCheckId": {
            "location": "uri",
            "locationName": "HealthCheckId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheckObservations"
        ],
        "members": {
          "HealthCheckObservations": {
            "shape": "S4i"
          }
        }
      }
    },
    "GetHostedZone": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzone/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZone"
        ],
        "members": {
          "HostedZone": {
            "shape": "S2k"
          },
          "DelegationSet": {
            "shape": "S2m"
          },
          "VPCs": {
            "shape": "S4q"
          }
        }
      }
    },
    "GetHostedZoneCount": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzonecount"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZoneCount"
        ],
        "members": {
          "HostedZoneCount": {
            "type": "long"
          }
        }
      }
    },
    "GetHostedZoneLimit": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzonelimit/{Id}/{Type}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "HostedZoneId"
        ],
        "members": {
          "Type": {
            "location": "uri",
            "locationName": "Type"
          },
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Limit",
          "Count"
        ],
        "members": {
          "Limit": {
            "type": "structure",
            "required": [
              "Type",
              "Value"
            ],
            "members": {
              "Type": {},
              "Value": {
                "type": "long"
              }
            }
          },
          "Count": {
            "type": "long"
          }
        }
      }
    },
    "GetQueryLoggingConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/queryloggingconfig/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryLoggingConfig"
        ],
        "members": {
          "QueryLoggingConfig": {
            "shape": "S2r"
          }
        }
      }
    },
    "GetReusableDelegationSet": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/delegationset/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DelegationSet"
        ],
        "members": {
          "DelegationSet": {
            "shape": "S2m"
          }
        }
      }
    },
    "GetReusableDelegationSetLimit": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/reusabledelegationsetlimit/{Id}/{Type}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "DelegationSetId"
        ],
        "members": {
          "Type": {
            "location": "uri",
            "locationName": "Type"
          },
          "DelegationSetId": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Limit",
          "Count"
        ],
        "members": {
          "Limit": {
            "type": "structure",
            "required": [
              "Type",
              "Value"
            ],
            "members": {
              "Type": {},
              "Value": {
                "type": "long"
              }
            }
          },
          "Count": {
            "type": "long"
          }
        }
      }
    },
    "GetTrafficPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicy/{Id}/{Version}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "Version"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Version": {
            "location": "uri",
            "locationName": "Version",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicy"
        ],
        "members": {
          "TrafficPolicy": {
            "shape": "S30"
          }
        }
      }
    },
    "GetTrafficPolicyInstance": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicyinstance/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstance"
        ],
        "members": {
          "TrafficPolicyInstance": {
            "shape": "S35"
          }
        }
      }
    },
    "GetTrafficPolicyInstanceCount": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicyinstancecount"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstanceCount"
        ],
        "members": {
          "TrafficPolicyInstanceCount": {
            "type": "integer"
          }
        }
      }
    },
    "ListGeoLocations": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/geolocations"
      },
      "input": {
        "type": "structure",
        "members": {
          "StartContinentCode": {
            "location": "querystring",
            "locationName": "startcontinentcode"
          },
          "StartCountryCode": {
            "location": "querystring",
            "locationName": "startcountrycode"
          },
          "StartSubdivisionCode": {
            "location": "querystring",
            "locationName": "startsubdivisioncode"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "GeoLocationDetailsList",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "GeoLocationDetailsList": {
            "type": "list",
            "member": {
              "shape": "S47",
              "locationName": "GeoLocationDetails"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "NextContinentCode": {},
          "NextCountryCode": {},
          "NextSubdivisionCode": {},
          "MaxItems": {}
        }
      }
    },
    "ListHealthChecks": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/healthcheck"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthChecks",
          "Marker",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "HealthChecks": {
            "type": "list",
            "member": {
              "shape": "S1z",
              "locationName": "HealthCheck"
            }
          },
          "Marker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "NextMarker": {},
          "MaxItems": {}
        }
      }
    },
    "ListHostedZones": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzone"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          },
          "DelegationSetId": {
            "location": "querystring",
            "locationName": "delegationsetid"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZones",
          "Marker",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "HostedZones": {
            "shape": "S5o"
          },
          "Marker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "NextMarker": {},
          "MaxItems": {}
        }
      }
    },
    "ListHostedZonesByName": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzonesbyname"
      },
      "input": {
        "type": "structure",
        "members": {
          "DNSName": {
            "location": "querystring",
            "locationName": "dnsname"
          },
          "HostedZoneId": {
            "location": "querystring",
            "locationName": "hostedzoneid"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZones",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "HostedZones": {
            "shape": "S5o"
          },
          "DNSName": {},
          "HostedZoneId": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "NextDNSName": {},
          "NextHostedZoneId": {},
          "MaxItems": {}
        }
      }
    },
    "ListHostedZonesByVPC": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzonesbyvpc"
      },
      "input": {
        "type": "structure",
        "required": [
          "VPCId",
          "VPCRegion"
        ],
        "members": {
          "VPCId": {
            "location": "querystring",
            "locationName": "vpcid"
          },
          "VPCRegion": {
            "location": "querystring",
            "locationName": "vpcregion"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nexttoken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZoneSummaries",
          "MaxItems"
        ],
        "members": {
          "HostedZoneSummaries": {
            "type": "list",
            "member": {
              "locationName": "HostedZoneSummary",
              "type": "structure",
              "required": [
                "HostedZoneId",
                "Name",
                "Owner"
              ],
              "members": {
                "HostedZoneId": {},
                "Name": {},
                "Owner": {
                  "type": "structure",
                  "members": {
                    "OwningAccount": {},
                    "OwningService": {}
                  }
                }
              }
            }
          },
          "MaxItems": {},
          "NextToken": {}
        }
      }
    },
    "ListQueryLoggingConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/queryloggingconfig"
      },
      "input": {
        "type": "structure",
        "members": {
          "HostedZoneId": {
            "location": "querystring",
            "locationName": "hostedzoneid"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nexttoken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxresults"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryLoggingConfigs"
        ],
        "members": {
          "QueryLoggingConfigs": {
            "type": "list",
            "member": {
              "shape": "S2r",
              "locationName": "QueryLoggingConfig"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceRecordSets": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzone/{Id}/rrset"
      },
      "input": {
        "type": "structure",
        "required": [
          "HostedZoneId"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "StartRecordName": {
            "location": "querystring",
            "locationName": "name"
          },
          "StartRecordType": {
            "location": "querystring",
            "locationName": "type"
          },
          "StartRecordIdentifier": {
            "location": "querystring",
            "locationName": "identifier"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceRecordSets",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "ResourceRecordSets": {
            "type": "list",
            "member": {
              "shape": "Sh",
              "locationName": "ResourceRecordSet"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "NextRecordName": {},
          "NextRecordType": {},
          "NextRecordIdentifier": {},
          "MaxItems": {}
        }
      }
    },
    "ListReusableDelegationSets": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/delegationset"
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DelegationSets",
          "Marker",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "DelegationSets": {
            "type": "list",
            "member": {
              "shape": "S2m",
              "locationName": "DelegationSet"
            }
          },
          "Marker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "NextMarker": {},
          "MaxItems": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/tags/{ResourceType}/{ResourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {
            "location": "uri",
            "locationName": "ResourceType"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceTagSet"
        ],
        "members": {
          "ResourceTagSet": {
            "shape": "S6b"
          }
        }
      }
    },
    "ListTagsForResources": {
      "http": {
        "requestUri": "/2013-04-01/tags/{ResourceType}"
      },
      "input": {
        "locationName": "ListTagsForResourcesRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceIds"
        ],
        "members": {
          "ResourceType": {
            "location": "uri",
            "locationName": "ResourceType"
          },
          "ResourceIds": {
            "type": "list",
            "member": {
              "locationName": "ResourceId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceTagSets"
        ],
        "members": {
          "ResourceTagSets": {
            "type": "list",
            "member": {
              "shape": "S6b",
              "locationName": "ResourceTagSet"
            }
          }
        }
      }
    },
    "ListTrafficPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicies"
      },
      "input": {
        "type": "structure",
        "members": {
          "TrafficPolicyIdMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyid"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicySummaries",
          "IsTruncated",
          "TrafficPolicyIdMarker",
          "MaxItems"
        ],
        "members": {
          "TrafficPolicySummaries": {
            "type": "list",
            "member": {
              "locationName": "TrafficPolicySummary",
              "type": "structure",
              "required": [
                "Id",
                "Name",
                "Type",
                "LatestVersion",
                "TrafficPolicyCount"
              ],
              "members": {
                "Id": {},
                "Name": {},
                "Type": {},
                "LatestVersion": {
                  "type": "integer"
                },
                "TrafficPolicyCount": {
                  "type": "integer"
                }
              }
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "TrafficPolicyIdMarker": {},
          "MaxItems": {}
        }
      }
    },
    "ListTrafficPolicyInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicyinstances"
      },
      "input": {
        "type": "structure",
        "members": {
          "HostedZoneIdMarker": {
            "location": "querystring",
            "locationName": "hostedzoneid"
          },
          "TrafficPolicyInstanceNameMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancename"
          },
          "TrafficPolicyInstanceTypeMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancetype"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstances",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "TrafficPolicyInstances": {
            "shape": "S6m"
          },
          "HostedZoneIdMarker": {},
          "TrafficPolicyInstanceNameMarker": {},
          "TrafficPolicyInstanceTypeMarker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "MaxItems": {}
        }
      }
    },
    "ListTrafficPolicyInstancesByHostedZone": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicyinstances/hostedzone"
      },
      "input": {
        "type": "structure",
        "required": [
          "HostedZoneId"
        ],
        "members": {
          "HostedZoneId": {
            "location": "querystring",
            "locationName": "id"
          },
          "TrafficPolicyInstanceNameMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancename"
          },
          "TrafficPolicyInstanceTypeMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancetype"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstances",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "TrafficPolicyInstances": {
            "shape": "S6m"
          },
          "TrafficPolicyInstanceNameMarker": {},
          "TrafficPolicyInstanceTypeMarker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "MaxItems": {}
        }
      }
    },
    "ListTrafficPolicyInstancesByPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicyinstances/trafficpolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficPolicyId",
          "TrafficPolicyVersion"
        ],
        "members": {
          "TrafficPolicyId": {
            "location": "querystring",
            "locationName": "id"
          },
          "TrafficPolicyVersion": {
            "location": "querystring",
            "locationName": "version",
            "type": "integer"
          },
          "HostedZoneIdMarker": {
            "location": "querystring",
            "locationName": "hostedzoneid"
          },
          "TrafficPolicyInstanceNameMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancename"
          },
          "TrafficPolicyInstanceTypeMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyinstancetype"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstances",
          "IsTruncated",
          "MaxItems"
        ],
        "members": {
          "TrafficPolicyInstances": {
            "shape": "S6m"
          },
          "HostedZoneIdMarker": {},
          "TrafficPolicyInstanceNameMarker": {},
          "TrafficPolicyInstanceTypeMarker": {},
          "IsTruncated": {
            "type": "boolean"
          },
          "MaxItems": {}
        }
      }
    },
    "ListTrafficPolicyVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/trafficpolicies/{Id}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "TrafficPolicyVersionMarker": {
            "location": "querystring",
            "locationName": "trafficpolicyversion"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "maxitems"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicies",
          "IsTruncated",
          "TrafficPolicyVersionMarker",
          "MaxItems"
        ],
        "members": {
          "TrafficPolicies": {
            "type": "list",
            "member": {
              "shape": "S30",
              "locationName": "TrafficPolicy"
            }
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "TrafficPolicyVersionMarker": {},
          "MaxItems": {}
        }
      }
    },
    "ListVPCAssociationAuthorizations": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/hostedzone/{Id}/authorizevpcassociation"
      },
      "input": {
        "type": "structure",
        "required": [
          "HostedZoneId"
        ],
        "members": {
          "HostedZoneId": {
            "location": "uri",
            "locationName": "Id"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nexttoken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxresults"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZoneId",
          "VPCs"
        ],
        "members": {
          "HostedZoneId": {},
          "NextToken": {},
          "VPCs": {
            "shape": "S4q"
          }
        }
      }
    },
    "TestDNSAnswer": {
      "http": {
        "method": "GET",
        "requestUri": "/2013-04-01/testdnsanswer"
      },
      "input": {
        "type": "structure",
        "required": [
          "HostedZoneId",
          "RecordName",
          "RecordType"
        ],
        "members": {
          "HostedZoneId": {
            "location": "querystring",
            "locationName": "hostedzoneid"
          },
          "RecordName": {
            "location": "querystring",
            "locationName": "recordname"
          },
          "RecordType": {
            "location": "querystring",
            "locationName": "recordtype"
          },
          "ResolverIP": {
            "location": "querystring",
            "locationName": "resolverip"
          },
          "EDNS0ClientSubnetIP": {
            "location": "querystring",
            "locationName": "edns0clientsubnetip"
          },
          "EDNS0ClientSubnetMask": {
            "location": "querystring",
            "locationName": "edns0clientsubnetmask"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Nameserver",
          "RecordName",
          "RecordType",
          "RecordData",
          "ResponseCode",
          "Protocol"
        ],
        "members": {
          "Nameserver": {},
          "RecordName": {},
          "RecordType": {},
          "RecordData": {
            "type": "list",
            "member": {
              "locationName": "RecordDataEntry"
            }
          },
          "ResponseCode": {},
          "Protocol": {}
        }
      }
    },
    "UpdateHealthCheck": {
      "http": {
        "requestUri": "/2013-04-01/healthcheck/{HealthCheckId}"
      },
      "input": {
        "locationName": "UpdateHealthCheckRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "HealthCheckId"
        ],
        "members": {
          "HealthCheckId": {
            "location": "uri",
            "locationName": "HealthCheckId"
          },
          "HealthCheckVersion": {
            "type": "long"
          },
          "IPAddress": {},
          "Port": {
            "type": "integer"
          },
          "ResourcePath": {},
          "FullyQualifiedDomainName": {},
          "SearchString": {},
          "FailureThreshold": {
            "type": "integer"
          },
          "Inverted": {
            "type": "boolean"
          },
          "Disabled": {
            "type": "boolean"
          },
          "HealthThreshold": {
            "type": "integer"
          },
          "ChildHealthChecks": {
            "shape": "S1q"
          },
          "EnableSNI": {
            "type": "boolean"
          },
          "Regions": {
            "shape": "S1s"
          },
          "AlarmIdentifier": {
            "shape": "S1u"
          },
          "InsufficientDataHealthStatus": {},
          "ResetElements": {
            "type": "list",
            "member": {
              "locationName": "ResettableElementName"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HealthCheck"
        ],
        "members": {
          "HealthCheck": {
            "shape": "S1z"
          }
        }
      }
    },
    "UpdateHostedZoneComment": {
      "http": {
        "requestUri": "/2013-04-01/hostedzone/{Id}"
      },
      "input": {
        "locationName": "UpdateHostedZoneCommentRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HostedZone"
        ],
        "members": {
          "HostedZone": {
            "shape": "S2k"
          }
        }
      }
    },
    "UpdateTrafficPolicyComment": {
      "http": {
        "requestUri": "/2013-04-01/trafficpolicy/{Id}/{Version}"
      },
      "input": {
        "locationName": "UpdateTrafficPolicyCommentRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Id",
          "Version",
          "Comment"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Version": {
            "location": "uri",
            "locationName": "Version",
            "type": "integer"
          },
          "Comment": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicy"
        ],
        "members": {
          "TrafficPolicy": {
            "shape": "S30"
          }
        }
      }
    },
    "UpdateTrafficPolicyInstance": {
      "http": {
        "requestUri": "/2013-04-01/trafficpolicyinstance/{Id}"
      },
      "input": {
        "locationName": "UpdateTrafficPolicyInstanceRequest",
        "xmlNamespace": {
          "uri": "https://route53.amazonaws.com/doc/2013-04-01/"
        },
        "type": "structure",
        "required": [
          "Id",
          "TTL",
          "TrafficPolicyId",
          "TrafficPolicyVersion"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "TTL": {
            "type": "long"
          },
          "TrafficPolicyId": {},
          "TrafficPolicyVersion": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrafficPolicyInstance"
        ],
        "members": {
          "TrafficPolicyInstance": {
            "shape": "S35"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "VPCRegion": {},
        "VPCId": {}
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Id",
        "Status",
        "SubmittedAt"
      ],
      "members": {
        "Id": {},
        "Status": {},
        "SubmittedAt": {
          "type": "timestamp"
        },
        "Comment": {}
      }
    },
    "Sh": {
      "type": "structure",
      "required": [
        "Name",
        "Type"
      ],
      "members": {
        "Name": {},
        "Type": {},
        "SetIdentifier": {},
        "Weight": {
          "type": "long"
        },
        "Region": {},
        "GeoLocation": {
          "type": "structure",
          "members": {
            "ContinentCode": {},
            "CountryCode": {},
            "SubdivisionCode": {}
          }
        },
        "Failover": {},
        "MultiValueAnswer": {
          "type": "boolean"
        },
        "TTL": {
          "type": "long"
        },
        "ResourceRecords": {
          "type": "list",
          "member": {
            "locationName": "ResourceRecord",
            "type": "structure",
            "required": [
              "Value"
            ],
            "members": {
              "Value": {}
            }
          }
        },
        "AliasTarget": {
          "type": "structure",
          "required": [
            "HostedZoneId",
            "DNSName",
            "EvaluateTargetHealth"
          ],
          "members": {
            "HostedZoneId": {},
            "DNSName": {},
            "EvaluateTargetHealth": {
              "type": "boolean"
            }
          }
        },
        "HealthCheckId": {},
        "TrafficPolicyInstanceId": {}
      }
    },
    "S15": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "IPAddress": {},
        "Port": {
          "type": "integer"
        },
        "Type": {},
        "ResourcePath": {},
        "FullyQualifiedDomainName": {},
        "SearchString": {},
        "RequestInterval": {
          "type": "integer"
        },
        "FailureThreshold": {
          "type": "integer"
        },
        "MeasureLatency": {
          "type": "boolean"
        },
        "Inverted": {
          "type": "boolean"
        },
        "Disabled": {
          "type": "boolean"
        },
        "HealthThreshold": {
          "type": "integer"
        },
        "ChildHealthChecks": {
          "shape": "S1q"
        },
        "EnableSNI": {
          "type": "boolean"
        },
        "Regions": {
          "shape": "S1s"
        },
        "AlarmIdentifier": {
          "shape": "S1u"
        },
        "InsufficientDataHealthStatus": {}
      }
    },
    "S1q": {
      "type": "list",
      "member": {
        "locationName": "ChildHealthCheck"
      }
    },
    "S1s": {
      "type": "list",
      "member": {
        "locationName": "Region"
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "Region",
        "Name"
      ],
      "members": {
        "Region": {},
        "Name": {}
      }
    },
    "S1z": {
      "type": "structure",
      "required": [
        "Id",
        "CallerReference",
        "HealthCheckConfig",
        "HealthCheckVersion"
      ],
      "members": {
        "Id": {},
        "CallerReference": {},
        "LinkedService": {
          "shape": "S20"
        },
        "HealthCheckConfig": {
          "shape": "S1d"
        },
        "HealthCheckVersion": {
          "type": "long"
        },
        "CloudWatchAlarmConfiguration": {
          "type": "structure",
          "required": [
            "EvaluationPeriods",
            "Threshold",
            "ComparisonOperator",
            "Period",
            "MetricName",
            "Namespace",
            "Statistic"
          ],
          "members": {
            "EvaluationPeriods": {
              "type": "integer"
            },
            "Threshold": {
              "type": "double"
            },
            "ComparisonOperator": {},
            "Period": {
              "type": "integer"
            },
            "MetricName": {},
            "Namespace": {},
            "Statistic": {},
            "Dimensions": {
              "type": "list",
              "member": {
                "locationName": "Dimension",
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {},
                  "Value": {}
                }
              }
            }
          }
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "ServicePrincipal": {},
        "Description": {}
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "Comment": {},
        "PrivateZone": {
          "type": "boolean"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "Id",
        "Name",
        "CallerReference"
      ],
      "members": {
        "Id": {},
        "Name": {},
        "CallerReference": {},
        "Config": {
          "shape": "S2h"
        },
        "ResourceRecordSetCount": {
          "type": "long"
        },
        "LinkedService": {
          "shape": "S20"
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "NameServers"
      ],
      "members": {
        "Id": {},
        "CallerReference": {},
        "NameServers": {
          "type": "list",
          "member": {
            "locationName": "NameServer"
          }
        }
      }
    },
    "S2r": {
      "type": "structure",
      "required": [
        "Id",
        "HostedZoneId",
        "CloudWatchLogsLogGroupArn"
      ],
      "members": {
        "Id": {},
        "HostedZoneId": {},
        "CloudWatchLogsLogGroupArn": {}
      }
    },
    "S30": {
      "type": "structure",
      "required": [
        "Id",
        "Version",
        "Name",
        "Type",
        "Document"
      ],
      "members": {
        "Id": {},
        "Version": {
          "type": "integer"
        },
        "Name": {},
        "Type": {},
        "Document": {},
        "Comment": {}
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "Id",
        "HostedZoneId",
        "Name",
        "TTL",
        "State",
        "Message",
        "TrafficPolicyId",
        "TrafficPolicyVersion",
        "TrafficPolicyType"
      ],
      "members": {
        "Id": {},
        "HostedZoneId": {},
        "Name": {},
        "TTL": {
          "type": "long"
        },
        "State": {},
        "Message": {},
        "TrafficPolicyId": {},
        "TrafficPolicyVersion": {
          "type": "integer"
        },
        "TrafficPolicyType": {}
      }
    },
    "S47": {
      "type": "structure",
      "members": {
        "ContinentCode": {},
        "ContinentName": {},
        "CountryCode": {},
        "CountryName": {},
        "SubdivisionCode": {},
        "SubdivisionName": {}
      }
    },
    "S4i": {
      "type": "list",
      "member": {
        "locationName": "HealthCheckObservation",
        "type": "structure",
        "members": {
          "Region": {},
          "IPAddress": {},
          "StatusReport": {
            "type": "structure",
            "members": {
              "Status": {},
              "CheckedTime": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "S4q": {
      "type": "list",
      "member": {
        "shape": "S3",
        "locationName": "VPC"
      }
    },
    "S5o": {
      "type": "list",
      "member": {
        "shape": "S2k",
        "locationName": "HostedZone"
      }
    },
    "S6b": {
      "type": "structure",
      "members": {
        "ResourceType": {},
        "ResourceId": {},
        "Tags": {
          "shape": "S15"
        }
      }
    },
    "S6m": {
      "type": "list",
      "member": {
        "shape": "S35",
        "locationName": "TrafficPolicyInstance"
      }
    }
  }
}
},{}],193:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListHealthChecks": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "NextMarker",
      "result_key": "HealthChecks"
    },
    "ListHostedZones": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "NextMarker",
      "result_key": "HostedZones"
    },
    "ListQueryLoggingConfigs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QueryLoggingConfigs"
    },
    "ListResourceRecordSets": {
      "input_token": [
        "StartRecordName",
        "StartRecordType",
        "StartRecordIdentifier"
      ],
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": [
        "NextRecordName",
        "NextRecordType",
        "NextRecordIdentifier"
      ],
      "result_key": "ResourceRecordSets"
    }
  }
}
},{}],194:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "ResourceRecordSetsChanged": {
      "delay": 30,
      "maxAttempts": 60,
      "operation": "GetChange",
      "acceptors": [
        {
          "matcher": "path",
          "expected": "INSYNC",
          "argument": "ChangeInfo.Status",
          "state": "success"
        }
      ]
    }
  }
}

},{}],195:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-05-15",
    "endpointPrefix": "route53domains",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Route 53 Domains",
    "serviceId": "Route 53 Domains",
    "signatureVersion": "v4",
    "targetPrefix": "Route53Domains_v20140515",
    "uid": "route53domains-2014-05-15"
  },
  "operations": {
    "AcceptDomainTransferFromAnotherAwsAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "Password"
        ],
        "members": {
          "DomainName": {},
          "Password": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "CancelDomainTransferToAnotherAwsAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "CheckDomainAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Availability"
        ],
        "members": {
          "Availability": {}
        }
      }
    },
    "CheckDomainTransferability": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "AuthCode": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Transferability"
        ],
        "members": {
          "Transferability": {
            "type": "structure",
            "members": {
              "Transferable": {}
            }
          }
        }
      }
    },
    "DeleteTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "TagsToDelete"
        ],
        "members": {
          "DomainName": {},
          "TagsToDelete": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableDomainAutoRenew": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableDomainTransferLock": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "EnableDomainAutoRenew": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableDomainTransferLock": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "GetContactReachabilityStatus": {
      "input": {
        "type": "structure",
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainName": {},
          "status": {}
        }
      }
    },
    "GetDomainDetail": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "Nameservers",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "Nameservers": {
            "shape": "Sy"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "S14"
          },
          "RegistrantContact": {
            "shape": "S14"
          },
          "TechContact": {
            "shape": "S14"
          },
          "AdminPrivacy": {
            "type": "boolean"
          },
          "RegistrantPrivacy": {
            "type": "boolean"
          },
          "TechPrivacy": {
            "type": "boolean"
          },
          "RegistrarName": {},
          "WhoIsServer": {},
          "RegistrarUrl": {},
          "AbuseContactEmail": {},
          "AbuseContactPhone": {},
          "RegistryDomainId": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "UpdatedDate": {
            "type": "timestamp"
          },
          "ExpirationDate": {
            "type": "timestamp"
          },
          "Reseller": {},
          "DnsSec": {},
          "StatusList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDomainSuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "SuggestionCount",
          "OnlyAvailable"
        ],
        "members": {
          "DomainName": {},
          "SuggestionCount": {
            "type": "integer"
          },
          "OnlyAvailable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuggestionsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "Availability": {}
              }
            }
          }
        }
      }
    },
    "GetOperationDetail": {
      "input": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {},
          "Status": {},
          "Message": {},
          "DomainName": {},
          "Type": {},
          "SubmittedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListDomains": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Domains"
        ],
        "members": {
          "Domains": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DomainName"
              ],
              "members": {
                "DomainName": {},
                "AutoRenew": {
                  "type": "boolean"
                },
                "TransferLock": {
                  "type": "boolean"
                },
                "Expiry": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextPageMarker": {}
        }
      }
    },
    "ListOperations": {
      "input": {
        "type": "structure",
        "members": {
          "SubmittedSince": {
            "type": "timestamp"
          },
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Operations"
        ],
        "members": {
          "Operations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OperationId",
                "Status",
                "Type",
                "SubmittedDate"
              ],
              "members": {
                "OperationId": {},
                "Status": {},
                "Type": {},
                "SubmittedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextPageMarker": {}
        }
      }
    },
    "ListTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagList"
        ],
        "members": {
          "TagList": {
            "shape": "S2d"
          }
        }
      }
    },
    "RegisterDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "DurationInYears",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {},
          "DurationInYears": {
            "type": "integer"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "S14"
          },
          "RegistrantContact": {
            "shape": "S14"
          },
          "TechContact": {
            "shape": "S14"
          },
          "PrivacyProtectAdminContact": {
            "type": "boolean"
          },
          "PrivacyProtectRegistrantContact": {
            "type": "boolean"
          },
          "PrivacyProtectTechContact": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "RejectDomainTransferFromAnotherAwsAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "RenewDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "CurrentExpiryYear"
        ],
        "members": {
          "DomainName": {},
          "DurationInYears": {
            "type": "integer"
          },
          "CurrentExpiryYear": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "ResendContactReachabilityEmail": {
      "input": {
        "type": "structure",
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainName": {},
          "emailAddress": {},
          "isAlreadyVerified": {
            "type": "boolean"
          }
        }
      }
    },
    "RetrieveDomainAuthCode": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AuthCode"
        ],
        "members": {
          "AuthCode": {
            "shape": "Sd"
          }
        }
      }
    },
    "TransferDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "DurationInYears",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {},
          "DurationInYears": {
            "type": "integer"
          },
          "Nameservers": {
            "shape": "Sy"
          },
          "AuthCode": {
            "shape": "Sd"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "S14"
          },
          "RegistrantContact": {
            "shape": "S14"
          },
          "TechContact": {
            "shape": "S14"
          },
          "PrivacyProtectAdminContact": {
            "type": "boolean"
          },
          "PrivacyProtectRegistrantContact": {
            "type": "boolean"
          },
          "PrivacyProtectTechContact": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "TransferDomainToAnotherAwsAccount": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "AccountId"
        ],
        "members": {
          "DomainName": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {},
          "Password": {}
        }
      }
    },
    "UpdateDomainContact": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "AdminContact": {
            "shape": "S14"
          },
          "RegistrantContact": {
            "shape": "S14"
          },
          "TechContact": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateDomainContactPrivacy": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "AdminPrivacy": {
            "type": "boolean"
          },
          "RegistrantPrivacy": {
            "type": "boolean"
          },
          "TechPrivacy": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateDomainNameservers": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "Nameservers"
        ],
        "members": {
          "DomainName": {},
          "FIAuthKey": {
            "deprecated": true
          },
          "Nameservers": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "TagsToUpdate": {
            "shape": "S2d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ViewBilling": {
      "input": {
        "type": "structure",
        "members": {
          "Start": {
            "type": "timestamp"
          },
          "End": {
            "type": "timestamp"
          },
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextPageMarker": {},
          "BillingRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "Operation": {},
                "InvoiceId": {},
                "BillDate": {
                  "type": "timestamp"
                },
                "Price": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "GlueIps": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "FirstName": {},
        "LastName": {},
        "ContactType": {},
        "OrganizationName": {},
        "AddressLine1": {},
        "AddressLine2": {},
        "City": {},
        "State": {},
        "CountryCode": {},
        "ZipCode": {},
        "PhoneNumber": {},
        "Email": {},
        "Fax": {},
        "ExtraParams": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Value"
            ],
            "members": {
              "Name": {},
              "Value": {}
            }
          }
        }
      },
      "sensitive": true
    },
    "S2d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}
},{}],196:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListDomains": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextPageMarker",
      "result_key": "Domains"
    },
    "ListOperations": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "output_token": "NextPageMarker",
      "result_key": "Operations"
    }
  }
}
},{}],197:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-28",
    "endpointPrefix": "runtime.lex",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Lex Runtime Service",
    "serviceId": "Lex Runtime Service",
    "signatureVersion": "v4",
    "signingName": "lex",
    "uid": "runtime.lex-2016-11-28"
  },
  "operations": {
    "DeleteSession": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botName": {},
          "botAlias": {},
          "userId": {},
          "sessionId": {}
        }
      }
    },
    "GetSession": {
      "http": {
        "method": "GET",
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session/"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "checkpointLabelFilter": {
            "location": "querystring",
            "locationName": "checkpointLabelFilter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recentIntentSummaryView": {
            "shape": "Sa"
          },
          "sessionAttributes": {
            "shape": "Sd"
          },
          "sessionId": {},
          "dialogAction": {
            "shape": "Sh"
          }
        }
      }
    },
    "PostContent": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "contentType",
          "inputStream"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sl",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "requestAttributes": {
            "shape": "Sl",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-request-attributes"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          },
          "inputStream": {
            "shape": "So"
          }
        },
        "payload": "inputStream"
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "intentName": {
            "location": "header",
            "locationName": "x-amz-lex-intent-name"
          },
          "slots": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-slots"
          },
          "sessionAttributes": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "sentimentResponse": {
            "location": "header",
            "locationName": "x-amz-lex-sentiment"
          },
          "message": {
            "shape": "Si",
            "location": "header",
            "locationName": "x-amz-lex-message"
          },
          "messageFormat": {
            "location": "header",
            "locationName": "x-amz-lex-message-format"
          },
          "dialogState": {
            "location": "header",
            "locationName": "x-amz-lex-dialog-state"
          },
          "slotToElicit": {
            "location": "header",
            "locationName": "x-amz-lex-slot-to-elicit"
          },
          "inputTranscript": {
            "location": "header",
            "locationName": "x-amz-lex-input-transcript"
          },
          "audioStream": {
            "shape": "So"
          },
          "sessionId": {
            "location": "header",
            "locationName": "x-amz-lex-session-id"
          }
        },
        "payload": "audioStream"
      },
      "authtype": "v4-unsigned-body"
    },
    "PostText": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/text"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "inputText"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sd"
          },
          "requestAttributes": {
            "shape": "Sd"
          },
          "inputText": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentName": {},
          "slots": {
            "shape": "Sd"
          },
          "sessionAttributes": {
            "shape": "Sd"
          },
          "message": {
            "shape": "Si"
          },
          "sentimentResponse": {
            "type": "structure",
            "members": {
              "sentimentLabel": {},
              "sentimentScore": {}
            }
          },
          "messageFormat": {},
          "dialogState": {},
          "slotToElicit": {},
          "responseCard": {
            "type": "structure",
            "members": {
              "version": {},
              "contentType": {},
              "genericAttachments": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "title": {},
                    "subTitle": {},
                    "attachmentLinkUrl": {},
                    "imageUrl": {},
                    "buttons": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "text",
                          "value"
                        ],
                        "members": {
                          "text": {},
                          "value": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "sessionId": {}
        }
      }
    },
    "PutSession": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sd"
          },
          "dialogAction": {
            "shape": "Sh"
          },
          "recentIntentSummaryView": {
            "shape": "Sa"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "intentName": {
            "location": "header",
            "locationName": "x-amz-lex-intent-name"
          },
          "slots": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-slots"
          },
          "sessionAttributes": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "message": {
            "shape": "Si",
            "location": "header",
            "locationName": "x-amz-lex-message"
          },
          "messageFormat": {
            "location": "header",
            "locationName": "x-amz-lex-message-format"
          },
          "dialogState": {
            "location": "header",
            "locationName": "x-amz-lex-dialog-state"
          },
          "slotToElicit": {
            "location": "header",
            "locationName": "x-amz-lex-slot-to-elicit"
          },
          "audioStream": {
            "shape": "So"
          },
          "sessionId": {
            "location": "header",
            "locationName": "x-amz-lex-session-id"
          }
        },
        "payload": "audioStream"
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "dialogActionType"
        ],
        "members": {
          "intentName": {},
          "checkpointLabel": {},
          "slots": {
            "shape": "Sd"
          },
          "confirmationStatus": {},
          "dialogActionType": {},
          "fulfillmentState": {},
          "slotToElicit": {}
        }
      }
    },
    "Sd": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "Sh": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "intentName": {},
        "slots": {
          "shape": "Sd"
        },
        "slotToElicit": {},
        "fulfillmentState": {},
        "message": {
          "shape": "Si"
        },
        "messageFormat": {}
      }
    },
    "Si": {
      "type": "string",
      "sensitive": true
    },
    "Sl": {
      "type": "string",
      "sensitive": true
    },
    "So": {
      "type": "blob",
      "streaming": true
    }
  }
}
},{}],198:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],199:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2006-03-01",
    "checksumFormat": "md5",
    "endpointPrefix": "s3",
    "globalEndpoint": "s3.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "Amazon S3",
    "serviceFullName": "Amazon Simple Storage Service",
    "serviceId": "S3",
    "signatureVersion": "s3",
    "uid": "s3-2006-03-01"
  },
  "operations": {
    "AbortMultipartUpload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "CompleteMultipartUpload": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MultipartUpload": {
            "locationName": "CompleteMultipartUpload",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "Parts": {
                "locationName": "Part",
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ETag": {},
                    "PartNumber": {
                      "type": "integer"
                    }
                  }
                },
                "flattened": true
              }
            }
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "MultipartUpload"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {},
          "Bucket": {},
          "Key": {},
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "ETag": {},
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "CopyObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CopySource",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "CopySource": {
            "location": "header",
            "locationName": "x-amz-copy-source"
          },
          "CopySourceIfMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-match"
          },
          "CopySourceIfModifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-modified-since",
            "type": "timestamp"
          },
          "CopySourceIfNoneMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-none-match"
          },
          "CopySourceIfUnmodifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-unmodified-since",
            "type": "timestamp"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "MetadataDirective": {
            "location": "header",
            "locationName": "x-amz-metadata-directive"
          },
          "TaggingDirective": {
            "location": "header",
            "locationName": "x-amz-tagging-directive"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "CopySourceSSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-algorithm"
          },
          "CopySourceSSECustomerKey": {
            "shape": "S1d",
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key"
          },
          "CopySourceSSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Tagging": {
            "location": "header",
            "locationName": "x-amz-tagging"
          },
          "ObjectLockMode": {
            "location": "header",
            "locationName": "x-amz-object-lock-mode"
          },
          "ObjectLockRetainUntilDate": {
            "shape": "S1h",
            "location": "header",
            "locationName": "x-amz-object-lock-retain-until-date"
          },
          "ObjectLockLegalHoldStatus": {
            "location": "header",
            "locationName": "x-amz-object-lock-legal-hold"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyObjectResult": {
            "type": "structure",
            "members": {
              "ETag": {},
              "LastModified": {
                "type": "timestamp"
              }
            }
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "CopySourceVersionId": {
            "location": "header",
            "locationName": "x-amz-copy-source-version-id"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "CopyObjectResult"
      },
      "alias": "PutObjectCopy"
    },
    "CreateBucket": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CreateBucketConfiguration": {
            "locationName": "CreateBucketConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "LocationConstraint": {}
            }
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "ObjectLockEnabledForBucket": {
            "location": "header",
            "locationName": "x-amz-bucket-object-lock-enabled",
            "type": "boolean"
          }
        },
        "payload": "CreateBucketConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      },
      "alias": "PutBucket"
    },
    "CreateMultipartUpload": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}?uploads"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Tagging": {
            "location": "header",
            "locationName": "x-amz-tagging"
          },
          "ObjectLockMode": {
            "location": "header",
            "locationName": "x-amz-object-lock-mode"
          },
          "ObjectLockRetainUntilDate": {
            "shape": "S1h",
            "location": "header",
            "locationName": "x-amz-object-lock-retain-until-date"
          },
          "ObjectLockLegalHoldStatus": {
            "location": "header",
            "locationName": "x-amz-object-lock-legal-hold"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AbortDate": {
            "location": "header",
            "locationName": "x-amz-abort-date",
            "type": "timestamp"
          },
          "AbortRuleId": {
            "location": "header",
            "locationName": "x-amz-abort-rule-id"
          },
          "Bucket": {
            "locationName": "Bucket"
          },
          "Key": {},
          "UploadId": {},
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "alias": "InitiateMultipartUpload"
    },
    "DeleteBucket": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketAnalyticsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?analytics",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketCors": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?cors",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketEncryption": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?encryption",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketInventoryConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?inventory",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketLifecycle": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?lifecycle",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketMetricsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?metrics",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?policy",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketReplication": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?replication",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?tagging",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketWebsite": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?website",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteObject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "BypassGovernanceRetention": {
            "location": "header",
            "locationName": "x-amz-bypass-governance-retention",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "DeleteObjectTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}?tagging",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          }
        }
      }
    },
    "DeleteObjects": {
      "http": {
        "requestUri": "/{Bucket}?delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Delete"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delete": {
            "locationName": "Delete",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Objects"
            ],
            "members": {
              "Objects": {
                "locationName": "Object",
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Key"
                  ],
                  "members": {
                    "Key": {},
                    "VersionId": {}
                  }
                },
                "flattened": true
              },
              "Quiet": {
                "type": "boolean"
              }
            }
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "BypassGovernanceRetention": {
            "location": "header",
            "locationName": "x-amz-bypass-governance-retention",
            "type": "boolean"
          }
        },
        "payload": "Delete"
      },
      "output": {
        "type": "structure",
        "members": {
          "Deleted": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "VersionId": {},
                "DeleteMarker": {
                  "type": "boolean"
                },
                "DeleteMarkerVersionId": {}
              }
            },
            "flattened": true
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "Errors": {
            "locationName": "Error",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "VersionId": {},
                "Code": {},
                "Message": {}
              }
            },
            "flattened": true
          }
        }
      },
      "alias": "DeleteMultipleObjects",
      "httpChecksumRequired": true
    },
    "DeletePublicAccessBlock": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?publicAccessBlock",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "GetBucketAccelerateConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?accelerate"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "GetBucketAcl": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Owner": {
            "shape": "S32"
          },
          "Grants": {
            "shape": "S35",
            "locationName": "AccessControlList"
          }
        }
      }
    },
    "GetBucketAnalyticsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalyticsConfiguration": {
            "shape": "S3e"
          }
        },
        "payload": "AnalyticsConfiguration"
      }
    },
    "GetBucketCors": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?cors"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CORSRules": {
            "shape": "S3u",
            "locationName": "CORSRule"
          }
        }
      }
    },
    "GetBucketEncryption": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?encryption"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServerSideEncryptionConfiguration": {
            "shape": "S47"
          }
        },
        "payload": "ServerSideEncryptionConfiguration"
      }
    },
    "GetBucketInventoryConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InventoryConfiguration": {
            "shape": "S4d"
          }
        },
        "payload": "InventoryConfiguration"
      }
    },
    "GetBucketLifecycle": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S4t",
            "locationName": "Rule"
          }
        }
      },
      "deprecated": true
    },
    "GetBucketLifecycleConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S58",
            "locationName": "Rule"
          }
        }
      }
    },
    "GetBucketLocation": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?location"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationConstraint": {}
        }
      }
    },
    "GetBucketLogging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingEnabled": {
            "shape": "S5i"
          }
        }
      }
    },
    "GetBucketMetricsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MetricsConfiguration": {
            "shape": "S5q"
          }
        },
        "payload": "MetricsConfiguration"
      }
    },
    "GetBucketNotification": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "shape": "S5t"
      },
      "output": {
        "shape": "S5u"
      },
      "deprecated": true
    },
    "GetBucketNotificationConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "shape": "S5t"
      },
      "output": {
        "shape": "S65"
      }
    },
    "GetBucketPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        },
        "payload": "Policy"
      }
    },
    "GetBucketPolicyStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?policyStatus"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyStatus": {
            "type": "structure",
            "members": {
              "IsPublic": {
                "locationName": "IsPublic",
                "type": "boolean"
              }
            }
          }
        },
        "payload": "PolicyStatus"
      }
    },
    "GetBucketReplication": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?replication"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationConfiguration": {
            "shape": "S6s"
          }
        },
        "payload": "ReplicationConfiguration"
      }
    },
    "GetBucketRequestPayment": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?requestPayment"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Payer": {}
        }
      }
    },
    "GetBucketTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagSet"
        ],
        "members": {
          "TagSet": {
            "shape": "S3k"
          }
        }
      }
    },
    "GetBucketVersioning": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "MFADelete": {
            "locationName": "MfaDelete"
          }
        }
      }
    },
    "GetBucketWebsite": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?website"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RedirectAllRequestsTo": {
            "shape": "S7t"
          },
          "IndexDocument": {
            "shape": "S7w"
          },
          "ErrorDocument": {
            "shape": "S7y"
          },
          "RoutingRules": {
            "shape": "S7z"
          }
        }
      }
    },
    "GetObject": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          },
          "IfModifiedSince": {
            "location": "header",
            "locationName": "If-Modified-Since",
            "type": "timestamp"
          },
          "IfNoneMatch": {
            "location": "header",
            "locationName": "If-None-Match"
          },
          "IfUnmodifiedSince": {
            "location": "header",
            "locationName": "If-Unmodified-Since",
            "type": "timestamp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Range": {
            "location": "header",
            "locationName": "Range"
          },
          "ResponseCacheControl": {
            "location": "querystring",
            "locationName": "response-cache-control"
          },
          "ResponseContentDisposition": {
            "location": "querystring",
            "locationName": "response-content-disposition"
          },
          "ResponseContentEncoding": {
            "location": "querystring",
            "locationName": "response-content-encoding"
          },
          "ResponseContentLanguage": {
            "location": "querystring",
            "locationName": "response-content-language"
          },
          "ResponseContentType": {
            "location": "querystring",
            "locationName": "response-content-type"
          },
          "ResponseExpires": {
            "location": "querystring",
            "locationName": "response-expires",
            "type": "timestamp"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "AcceptRanges": {
            "location": "header",
            "locationName": "accept-ranges"
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "Restore": {
            "location": "header",
            "locationName": "x-amz-restore"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "MissingMeta": {
            "location": "header",
            "locationName": "x-amz-missing-meta",
            "type": "integer"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentRange": {
            "location": "header",
            "locationName": "Content-Range"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "ReplicationStatus": {
            "location": "header",
            "locationName": "x-amz-replication-status"
          },
          "PartsCount": {
            "location": "header",
            "locationName": "x-amz-mp-parts-count",
            "type": "integer"
          },
          "TagCount": {
            "location": "header",
            "locationName": "x-amz-tagging-count",
            "type": "integer"
          },
          "ObjectLockMode": {
            "location": "header",
            "locationName": "x-amz-object-lock-mode"
          },
          "ObjectLockRetainUntilDate": {
            "shape": "S1h",
            "location": "header",
            "locationName": "x-amz-object-lock-retain-until-date"
          },
          "ObjectLockLegalHoldStatus": {
            "location": "header",
            "locationName": "x-amz-object-lock-legal-hold"
          }
        },
        "payload": "Body"
      }
    },
    "GetObjectAcl": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Owner": {
            "shape": "S32"
          },
          "Grants": {
            "shape": "S35",
            "locationName": "AccessControlList"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "GetObjectLegalHold": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?legal-hold"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LegalHold": {
            "shape": "S8y"
          }
        },
        "payload": "LegalHold"
      }
    },
    "GetObjectLockConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?object-lock"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ObjectLockConfiguration": {
            "shape": "S91"
          }
        },
        "payload": "ObjectLockConfiguration"
      }
    },
    "GetObjectRetention": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?retention"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Retention": {
            "shape": "S99"
          }
        },
        "payload": "Retention"
      }
    },
    "GetObjectTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagSet"
        ],
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "TagSet": {
            "shape": "S3k"
          }
        }
      }
    },
    "GetObjectTorrent": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?torrent"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "Body"
      }
    },
    "GetPublicAccessBlock": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicAccessBlockConfiguration": {
            "shape": "S9g"
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      }
    },
    "HeadBucket": {
      "http": {
        "method": "HEAD",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "HeadObject": {
      "http": {
        "method": "HEAD",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          },
          "IfModifiedSince": {
            "location": "header",
            "locationName": "If-Modified-Since",
            "type": "timestamp"
          },
          "IfNoneMatch": {
            "location": "header",
            "locationName": "If-None-Match"
          },
          "IfUnmodifiedSince": {
            "location": "header",
            "locationName": "If-Unmodified-Since",
            "type": "timestamp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Range": {
            "location": "header",
            "locationName": "Range"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "AcceptRanges": {
            "location": "header",
            "locationName": "accept-ranges"
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "Restore": {
            "location": "header",
            "locationName": "x-amz-restore"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "MissingMeta": {
            "location": "header",
            "locationName": "x-amz-missing-meta",
            "type": "integer"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "ReplicationStatus": {
            "location": "header",
            "locationName": "x-amz-replication-status"
          },
          "PartsCount": {
            "location": "header",
            "locationName": "x-amz-mp-parts-count",
            "type": "integer"
          },
          "ObjectLockMode": {
            "location": "header",
            "locationName": "x-amz-object-lock-mode"
          },
          "ObjectLockRetainUntilDate": {
            "shape": "S1h",
            "location": "header",
            "locationName": "x-amz-object-lock-retain-until-date"
          },
          "ObjectLockLegalHoldStatus": {
            "location": "header",
            "locationName": "x-amz-object-lock-legal-hold"
          }
        }
      }
    },
    "ListBucketAnalyticsConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "AnalyticsConfigurationList": {
            "locationName": "AnalyticsConfiguration",
            "type": "list",
            "member": {
              "shape": "S3e"
            },
            "flattened": true
          }
        }
      }
    },
    "ListBucketInventoryConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuationToken": {},
          "InventoryConfigurationList": {
            "locationName": "InventoryConfiguration",
            "type": "list",
            "member": {
              "shape": "S4d"
            },
            "flattened": true
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "NextContinuationToken": {}
        }
      }
    },
    "ListBucketMetricsConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "MetricsConfigurationList": {
            "locationName": "MetricsConfiguration",
            "type": "list",
            "member": {
              "shape": "S5q"
            },
            "flattened": true
          }
        }
      }
    },
    "ListBuckets": {
      "http": {
        "method": "GET"
      },
      "output": {
        "type": "structure",
        "members": {
          "Buckets": {
            "type": "list",
            "member": {
              "locationName": "Bucket",
              "type": "structure",
              "members": {
                "Name": {},
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "Owner": {
            "shape": "S32"
          }
        }
      },
      "alias": "GetService"
    },
    "ListMultipartUploads": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?uploads"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "KeyMarker": {
            "location": "querystring",
            "locationName": "key-marker"
          },
          "MaxUploads": {
            "location": "querystring",
            "locationName": "max-uploads",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "UploadIdMarker": {
            "location": "querystring",
            "locationName": "upload-id-marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bucket": {},
          "KeyMarker": {},
          "UploadIdMarker": {},
          "NextKeyMarker": {},
          "Prefix": {},
          "Delimiter": {},
          "NextUploadIdMarker": {},
          "MaxUploads": {
            "type": "integer"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Uploads": {
            "locationName": "Upload",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UploadId": {},
                "Key": {},
                "Initiated": {
                  "type": "timestamp"
                },
                "StorageClass": {},
                "Owner": {
                  "shape": "S32"
                },
                "Initiator": {
                  "shape": "Sad"
                }
              }
            },
            "flattened": true
          },
          "CommonPrefixes": {
            "shape": "Sae"
          },
          "EncodingType": {}
        }
      }
    },
    "ListObjectVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "KeyMarker": {
            "location": "querystring",
            "locationName": "key-marker"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "VersionIdMarker": {
            "location": "querystring",
            "locationName": "version-id-marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "KeyMarker": {},
          "VersionIdMarker": {},
          "NextKeyMarker": {},
          "NextVersionIdMarker": {},
          "Versions": {
            "locationName": "Version",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ETag": {},
                "Size": {
                  "type": "integer"
                },
                "StorageClass": {},
                "Key": {},
                "VersionId": {},
                "IsLatest": {
                  "type": "boolean"
                },
                "LastModified": {
                  "type": "timestamp"
                },
                "Owner": {
                  "shape": "S32"
                }
              }
            },
            "flattened": true
          },
          "DeleteMarkers": {
            "locationName": "DeleteMarker",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Owner": {
                  "shape": "S32"
                },
                "Key": {},
                "VersionId": {},
                "IsLatest": {
                  "type": "boolean"
                },
                "LastModified": {
                  "type": "timestamp"
                }
              }
            },
            "flattened": true
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "Sae"
          },
          "EncodingType": {}
        }
      },
      "alias": "GetBucketObjectVersions"
    },
    "ListObjects": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {},
          "NextMarker": {},
          "Contents": {
            "shape": "Saw"
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "Sae"
          },
          "EncodingType": {}
        }
      },
      "alias": "GetBucket"
    },
    "ListObjectsV2": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?list-type=2"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          },
          "FetchOwner": {
            "location": "querystring",
            "locationName": "fetch-owner",
            "type": "boolean"
          },
          "StartAfter": {
            "location": "querystring",
            "locationName": "start-after"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "Contents": {
            "shape": "Saw"
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "Sae"
          },
          "EncodingType": {},
          "KeyCount": {
            "type": "integer"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "StartAfter": {}
        }
      }
    },
    "ListParts": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MaxParts": {
            "location": "querystring",
            "locationName": "max-parts",
            "type": "integer"
          },
          "PartNumberMarker": {
            "location": "querystring",
            "locationName": "part-number-marker",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AbortDate": {
            "location": "header",
            "locationName": "x-amz-abort-date",
            "type": "timestamp"
          },
          "AbortRuleId": {
            "location": "header",
            "locationName": "x-amz-abort-rule-id"
          },
          "Bucket": {},
          "Key": {},
          "UploadId": {},
          "PartNumberMarker": {
            "type": "integer"
          },
          "NextPartNumberMarker": {
            "type": "integer"
          },
          "MaxParts": {
            "type": "integer"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Parts": {
            "locationName": "Part",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PartNumber": {
                  "type": "integer"
                },
                "LastModified": {
                  "type": "timestamp"
                },
                "ETag": {},
                "Size": {
                  "type": "integer"
                }
              }
            },
            "flattened": true
          },
          "Initiator": {
            "shape": "Sad"
          },
          "Owner": {
            "shape": "S32"
          },
          "StorageClass": {},
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "PutBucketAccelerateConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?accelerate"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "AccelerateConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "AccelerateConfiguration": {
            "locationName": "AccelerateConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "Status": {}
            }
          }
        },
        "payload": "AccelerateConfiguration"
      }
    },
    "PutBucketAcl": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "AccessControlPolicy": {
            "shape": "Sbe",
            "locationName": "AccessControlPolicy",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          }
        },
        "payload": "AccessControlPolicy"
      },
      "httpChecksumRequired": true
    },
    "PutBucketAnalyticsConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "AnalyticsConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "AnalyticsConfiguration": {
            "shape": "S3e",
            "locationName": "AnalyticsConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "AnalyticsConfiguration"
      }
    },
    "PutBucketCors": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?cors"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CORSConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CORSConfiguration": {
            "locationName": "CORSConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "CORSRules"
            ],
            "members": {
              "CORSRules": {
                "shape": "S3u",
                "locationName": "CORSRule"
              }
            }
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "CORSConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketEncryption": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?encryption"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "ServerSideEncryptionConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ServerSideEncryptionConfiguration": {
            "shape": "S47",
            "locationName": "ServerSideEncryptionConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "ServerSideEncryptionConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketInventoryConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "InventoryConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "InventoryConfiguration": {
            "shape": "S4d",
            "locationName": "InventoryConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "InventoryConfiguration"
      }
    },
    "PutBucketLifecycle": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "LifecycleConfiguration": {
            "locationName": "LifecycleConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Rules"
            ],
            "members": {
              "Rules": {
                "shape": "S4t",
                "locationName": "Rule"
              }
            }
          }
        },
        "payload": "LifecycleConfiguration"
      },
      "deprecated": true,
      "httpChecksumRequired": true
    },
    "PutBucketLifecycleConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "LifecycleConfiguration": {
            "locationName": "LifecycleConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Rules"
            ],
            "members": {
              "Rules": {
                "shape": "S58",
                "locationName": "Rule"
              }
            }
          }
        },
        "payload": "LifecycleConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketLogging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "BucketLoggingStatus"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "BucketLoggingStatus": {
            "locationName": "BucketLoggingStatus",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "LoggingEnabled": {
                "shape": "S5i"
              }
            }
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "BucketLoggingStatus"
      },
      "httpChecksumRequired": true
    },
    "PutBucketMetricsConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "MetricsConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "MetricsConfiguration": {
            "shape": "S5q",
            "locationName": "MetricsConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "MetricsConfiguration"
      }
    },
    "PutBucketNotification": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "NotificationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "NotificationConfiguration": {
            "shape": "S5u",
            "locationName": "NotificationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "NotificationConfiguration"
      },
      "deprecated": true,
      "httpChecksumRequired": true
    },
    "PutBucketNotificationConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "NotificationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "NotificationConfiguration": {
            "shape": "S65",
            "locationName": "NotificationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "NotificationConfiguration"
      }
    },
    "PutBucketPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Policy"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ConfirmRemoveSelfBucketAccess": {
            "location": "header",
            "locationName": "x-amz-confirm-remove-self-bucket-access",
            "type": "boolean"
          },
          "Policy": {}
        },
        "payload": "Policy"
      },
      "httpChecksumRequired": true
    },
    "PutBucketReplication": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?replication"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "ReplicationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ReplicationConfiguration": {
            "shape": "S6s",
            "locationName": "ReplicationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "Token": {
            "location": "header",
            "locationName": "x-amz-bucket-object-lock-token"
          }
        },
        "payload": "ReplicationConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketRequestPayment": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?requestPayment"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "RequestPaymentConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "RequestPaymentConfiguration": {
            "locationName": "RequestPaymentConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Payer"
            ],
            "members": {
              "Payer": {}
            }
          }
        },
        "payload": "RequestPaymentConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Tagging"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Tagging": {
            "shape": "Sc1",
            "locationName": "Tagging",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "Tagging"
      },
      "httpChecksumRequired": true
    },
    "PutBucketVersioning": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "VersioningConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "VersioningConfiguration": {
            "locationName": "VersioningConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "MFADelete": {
                "locationName": "MfaDelete"
              },
              "Status": {}
            }
          }
        },
        "payload": "VersioningConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutBucketWebsite": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?website"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "WebsiteConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "WebsiteConfiguration": {
            "locationName": "WebsiteConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "ErrorDocument": {
                "shape": "S7y"
              },
              "IndexDocument": {
                "shape": "S7w"
              },
              "RedirectAllRequestsTo": {
                "shape": "S7t"
              },
              "RoutingRules": {
                "shape": "S7z"
              }
            }
          }
        },
        "payload": "WebsiteConfiguration"
      },
      "httpChecksumRequired": true
    },
    "PutObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Tagging": {
            "location": "header",
            "locationName": "x-amz-tagging"
          },
          "ObjectLockMode": {
            "location": "header",
            "locationName": "x-amz-object-lock-mode"
          },
          "ObjectLockRetainUntilDate": {
            "shape": "S1h",
            "location": "header",
            "locationName": "x-amz-object-lock-retain-until-date"
          },
          "ObjectLockLegalHoldStatus": {
            "location": "header",
            "locationName": "x-amz-object-lock-legal-hold"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "members": {
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "SSEKMSEncryptionContext": {
            "shape": "S1b",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-context"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "PutObjectAcl": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "AccessControlPolicy": {
            "shape": "Sbe",
            "locationName": "AccessControlPolicy",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        },
        "payload": "AccessControlPolicy"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "httpChecksumRequired": true
    },
    "PutObjectLegalHold": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?legal-hold"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "LegalHold": {
            "shape": "S8y",
            "locationName": "LegalHold",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "LegalHold"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "httpChecksumRequired": true
    },
    "PutObjectLockConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?object-lock"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ObjectLockConfiguration": {
            "shape": "S91",
            "locationName": "ObjectLockConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Token": {
            "location": "header",
            "locationName": "x-amz-bucket-object-lock-token"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "ObjectLockConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "httpChecksumRequired": true
    },
    "PutObjectRetention": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?retention"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Retention": {
            "shape": "S99",
            "locationName": "Retention",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "BypassGovernanceRetention": {
            "location": "header",
            "locationName": "x-amz-bypass-governance-retention",
            "type": "boolean"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "Retention"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "httpChecksumRequired": true
    },
    "PutObjectTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "Tagging"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Tagging": {
            "shape": "Sc1",
            "locationName": "Tagging",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "Tagging"
      },
      "output": {
        "type": "structure",
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          }
        }
      },
      "httpChecksumRequired": true
    },
    "PutPublicAccessBlock": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "PublicAccessBlockConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "deprecated": true,
            "deprecatedMessage": "Content-MD5 header will now be automatically computed and injected in associated operation's Http request.",
            "location": "header",
            "locationName": "Content-MD5"
          },
          "PublicAccessBlockConfiguration": {
            "shape": "S9g",
            "locationName": "PublicAccessBlockConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      },
      "httpChecksumRequired": true
    },
    "RestoreObject": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}?restore"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RestoreRequest": {
            "locationName": "RestoreRequest",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "Days": {
                "type": "integer"
              },
              "GlacierJobParameters": {
                "type": "structure",
                "required": [
                  "Tier"
                ],
                "members": {
                  "Tier": {}
                }
              },
              "Type": {},
              "Tier": {},
              "Description": {},
              "SelectParameters": {
                "type": "structure",
                "required": [
                  "InputSerialization",
                  "ExpressionType",
                  "Expression",
                  "OutputSerialization"
                ],
                "members": {
                  "InputSerialization": {
                    "shape": "Scr"
                  },
                  "ExpressionType": {},
                  "Expression": {},
                  "OutputSerialization": {
                    "shape": "Sd6"
                  }
                }
              },
              "OutputLocation": {
                "type": "structure",
                "members": {
                  "S3": {
                    "type": "structure",
                    "required": [
                      "BucketName",
                      "Prefix"
                    ],
                    "members": {
                      "BucketName": {},
                      "Prefix": {},
                      "Encryption": {
                        "type": "structure",
                        "required": [
                          "EncryptionType"
                        ],
                        "members": {
                          "EncryptionType": {},
                          "KMSKeyId": {
                            "shape": "Sj"
                          },
                          "KMSContext": {}
                        }
                      },
                      "CannedACL": {},
                      "AccessControlList": {
                        "shape": "S35"
                      },
                      "Tagging": {
                        "shape": "Sc1"
                      },
                      "UserMetadata": {
                        "type": "list",
                        "member": {
                          "locationName": "MetadataEntry",
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Value": {}
                          }
                        }
                      },
                      "StorageClass": {}
                    }
                  }
                }
              }
            }
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "RestoreRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "RestoreOutputPath": {
            "location": "header",
            "locationName": "x-amz-restore-output-path"
          }
        }
      },
      "alias": "PostObjectRestore"
    },
    "SelectObjectContent": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}?select&select-type=2"
      },
      "input": {
        "locationName": "SelectObjectContentRequest",
        "xmlNamespace": {
          "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
        },
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "Expression",
          "ExpressionType",
          "InputSerialization",
          "OutputSerialization"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "Expression": {},
          "ExpressionType": {},
          "RequestProgress": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              }
            }
          },
          "InputSerialization": {
            "shape": "Scr"
          },
          "OutputSerialization": {
            "shape": "Sd6"
          },
          "ScanRange": {
            "type": "structure",
            "members": {
              "Start": {
                "type": "long"
              },
              "End": {
                "type": "long"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Payload": {
            "type": "structure",
            "members": {
              "Records": {
                "type": "structure",
                "members": {
                  "Payload": {
                    "eventpayload": true,
                    "type": "blob"
                  }
                },
                "event": true
              },
              "Stats": {
                "type": "structure",
                "members": {
                  "Details": {
                    "eventpayload": true,
                    "type": "structure",
                    "members": {
                      "BytesScanned": {
                        "type": "long"
                      },
                      "BytesProcessed": {
                        "type": "long"
                      },
                      "BytesReturned": {
                        "type": "long"
                      }
                    }
                  }
                },
                "event": true
              },
              "Progress": {
                "type": "structure",
                "members": {
                  "Details": {
                    "eventpayload": true,
                    "type": "structure",
                    "members": {
                      "BytesScanned": {
                        "type": "long"
                      },
                      "BytesProcessed": {
                        "type": "long"
                      },
                      "BytesReturned": {
                        "type": "long"
                      }
                    }
                  }
                },
                "event": true
              },
              "Cont": {
                "type": "structure",
                "members": {},
                "event": true
              },
              "End": {
                "type": "structure",
                "members": {},
                "event": true
              }
            },
            "eventstream": true
          }
        },
        "payload": "Payload"
      }
    },
    "UploadPart": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "PartNumber",
          "UploadId"
        ],
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "members": {
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "UploadPartCopy": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CopySource",
          "Key",
          "PartNumber",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CopySource": {
            "location": "header",
            "locationName": "x-amz-copy-source"
          },
          "CopySourceIfMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-match"
          },
          "CopySourceIfModifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-modified-since",
            "type": "timestamp"
          },
          "CopySourceIfNoneMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-none-match"
          },
          "CopySourceIfUnmodifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-unmodified-since",
            "type": "timestamp"
          },
          "CopySourceRange": {
            "location": "header",
            "locationName": "x-amz-copy-source-range"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "CopySourceSSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-algorithm"
          },
          "CopySourceSSECustomerKey": {
            "shape": "S1d",
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key"
          },
          "CopySourceSSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopySourceVersionId": {
            "location": "header",
            "locationName": "x-amz-copy-source-version-id"
          },
          "CopyPartResult": {
            "type": "structure",
            "members": {
              "ETag": {},
              "LastModified": {
                "type": "timestamp"
              }
            }
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "CopyPartResult"
      }
    }
  },
  "shapes": {
    "Sj": {
      "type": "string",
      "sensitive": true
    },
    "S11": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S19": {
      "type": "blob",
      "sensitive": true
    },
    "S1b": {
      "type": "string",
      "sensitive": true
    },
    "S1d": {
      "type": "blob",
      "sensitive": true
    },
    "S1h": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S32": {
      "type": "structure",
      "members": {
        "DisplayName": {},
        "ID": {}
      }
    },
    "S35": {
      "type": "list",
      "member": {
        "locationName": "Grant",
        "type": "structure",
        "members": {
          "Grantee": {
            "shape": "S37"
          },
          "Permission": {}
        }
      }
    },
    "S37": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "DisplayName": {},
        "EmailAddress": {},
        "ID": {},
        "Type": {
          "locationName": "xsi:type",
          "xmlAttribute": true
        },
        "URI": {}
      },
      "xmlNamespace": {
        "prefix": "xsi",
        "uri": "http://www.w3.org/2001/XMLSchema-instance"
      }
    },
    "S3e": {
      "type": "structure",
      "required": [
        "Id",
        "StorageClassAnalysis"
      ],
      "members": {
        "Id": {},
        "Filter": {
          "type": "structure",
          "members": {
            "Prefix": {},
            "Tag": {
              "shape": "S3h"
            },
            "And": {
              "type": "structure",
              "members": {
                "Prefix": {},
                "Tags": {
                  "shape": "S3k",
                  "flattened": true,
                  "locationName": "Tag"
                }
              }
            }
          }
        },
        "StorageClassAnalysis": {
          "type": "structure",
          "members": {
            "DataExport": {
              "type": "structure",
              "required": [
                "OutputSchemaVersion",
                "Destination"
              ],
              "members": {
                "OutputSchemaVersion": {},
                "Destination": {
                  "type": "structure",
                  "required": [
                    "S3BucketDestination"
                  ],
                  "members": {
                    "S3BucketDestination": {
                      "type": "structure",
                      "required": [
                        "Format",
                        "Bucket"
                      ],
                      "members": {
                        "Format": {},
                        "BucketAccountId": {},
                        "Bucket": {},
                        "Prefix": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S3h": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S3k": {
      "type": "list",
      "member": {
        "shape": "S3h",
        "locationName": "Tag"
      }
    },
    "S3u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AllowedMethods",
          "AllowedOrigins"
        ],
        "members": {
          "AllowedHeaders": {
            "locationName": "AllowedHeader",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "AllowedMethods": {
            "locationName": "AllowedMethod",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "AllowedOrigins": {
            "locationName": "AllowedOrigin",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "ExposeHeaders": {
            "locationName": "ExposeHeader",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "MaxAgeSeconds": {
            "type": "integer"
          }
        }
      },
      "flattened": true
    },
    "S47": {
      "type": "structure",
      "required": [
        "Rules"
      ],
      "members": {
        "Rules": {
          "locationName": "Rule",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ApplyServerSideEncryptionByDefault": {
                "type": "structure",
                "required": [
                  "SSEAlgorithm"
                ],
                "members": {
                  "SSEAlgorithm": {},
                  "KMSMasterKeyID": {
                    "shape": "Sj"
                  }
                }
              }
            }
          },
          "flattened": true
        }
      }
    },
    "S4d": {
      "type": "structure",
      "required": [
        "Destination",
        "IsEnabled",
        "Id",
        "IncludedObjectVersions",
        "Schedule"
      ],
      "members": {
        "Destination": {
          "type": "structure",
          "required": [
            "S3BucketDestination"
          ],
          "members": {
            "S3BucketDestination": {
              "type": "structure",
              "required": [
                "Bucket",
                "Format"
              ],
              "members": {
                "AccountId": {},
                "Bucket": {},
                "Format": {},
                "Prefix": {},
                "Encryption": {
                  "type": "structure",
                  "members": {
                    "SSES3": {
                      "locationName": "SSE-S3",
                      "type": "structure",
                      "members": {}
                    },
                    "SSEKMS": {
                      "locationName": "SSE-KMS",
                      "type": "structure",
                      "required": [
                        "KeyId"
                      ],
                      "members": {
                        "KeyId": {
                          "shape": "Sj"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "IsEnabled": {
          "type": "boolean"
        },
        "Filter": {
          "type": "structure",
          "required": [
            "Prefix"
          ],
          "members": {
            "Prefix": {}
          }
        },
        "Id": {},
        "IncludedObjectVersions": {},
        "OptionalFields": {
          "type": "list",
          "member": {
            "locationName": "Field"
          }
        },
        "Schedule": {
          "type": "structure",
          "required": [
            "Frequency"
          ],
          "members": {
            "Frequency": {}
          }
        }
      }
    },
    "S4t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Prefix",
          "Status"
        ],
        "members": {
          "Expiration": {
            "shape": "S4v"
          },
          "ID": {},
          "Prefix": {},
          "Status": {},
          "Transition": {
            "shape": "S50"
          },
          "NoncurrentVersionTransition": {
            "shape": "S52"
          },
          "NoncurrentVersionExpiration": {
            "shape": "S53"
          },
          "AbortIncompleteMultipartUpload": {
            "shape": "S54"
          }
        }
      },
      "flattened": true
    },
    "S4v": {
      "type": "structure",
      "members": {
        "Date": {
          "shape": "S4w"
        },
        "Days": {
          "type": "integer"
        },
        "ExpiredObjectDeleteMarker": {
          "type": "boolean"
        }
      }
    },
    "S4w": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S50": {
      "type": "structure",
      "members": {
        "Date": {
          "shape": "S4w"
        },
        "Days": {
          "type": "integer"
        },
        "StorageClass": {}
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "NoncurrentDays": {
          "type": "integer"
        },
        "StorageClass": {}
      }
    },
    "S53": {
      "type": "structure",
      "members": {
        "NoncurrentDays": {
          "type": "integer"
        }
      }
    },
    "S54": {
      "type": "structure",
      "members": {
        "DaysAfterInitiation": {
          "type": "integer"
        }
      }
    },
    "S58": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Expiration": {
            "shape": "S4v"
          },
          "ID": {},
          "Prefix": {
            "deprecated": true
          },
          "Filter": {
            "type": "structure",
            "members": {
              "Prefix": {},
              "Tag": {
                "shape": "S3h"
              },
              "And": {
                "type": "structure",
                "members": {
                  "Prefix": {},
                  "Tags": {
                    "shape": "S3k",
                    "flattened": true,
                    "locationName": "Tag"
                  }
                }
              }
            }
          },
          "Status": {},
          "Transitions": {
            "locationName": "Transition",
            "type": "list",
            "member": {
              "shape": "S50"
            },
            "flattened": true
          },
          "NoncurrentVersionTransitions": {
            "locationName": "NoncurrentVersionTransition",
            "type": "list",
            "member": {
              "shape": "S52"
            },
            "flattened": true
          },
          "NoncurrentVersionExpiration": {
            "shape": "S53"
          },
          "AbortIncompleteMultipartUpload": {
            "shape": "S54"
          }
        }
      },
      "flattened": true
    },
    "S5i": {
      "type": "structure",
      "required": [
        "TargetBucket",
        "TargetPrefix"
      ],
      "members": {
        "TargetBucket": {},
        "TargetGrants": {
          "type": "list",
          "member": {
            "locationName": "Grant",
            "type": "structure",
            "members": {
              "Grantee": {
                "shape": "S37"
              },
              "Permission": {}
            }
          }
        },
        "TargetPrefix": {}
      }
    },
    "S5q": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Filter": {
          "type": "structure",
          "members": {
            "Prefix": {},
            "Tag": {
              "shape": "S3h"
            },
            "And": {
              "type": "structure",
              "members": {
                "Prefix": {},
                "Tags": {
                  "shape": "S3k",
                  "flattened": true,
                  "locationName": "Tag"
                }
              }
            }
          }
        }
      }
    },
    "S5t": {
      "type": "structure",
      "required": [
        "Bucket"
      ],
      "members": {
        "Bucket": {
          "location": "uri",
          "locationName": "Bucket"
        }
      }
    },
    "S5u": {
      "type": "structure",
      "members": {
        "TopicConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Events": {
              "shape": "S5x",
              "locationName": "Event"
            },
            "Event": {
              "deprecated": true
            },
            "Topic": {}
          }
        },
        "QueueConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Event": {
              "deprecated": true
            },
            "Events": {
              "shape": "S5x",
              "locationName": "Event"
            },
            "Queue": {}
          }
        },
        "CloudFunctionConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Event": {
              "deprecated": true
            },
            "Events": {
              "shape": "S5x",
              "locationName": "Event"
            },
            "CloudFunction": {},
            "InvocationRole": {}
          }
        }
      }
    },
    "S5x": {
      "type": "list",
      "member": {},
      "flattened": true
    },
    "S65": {
      "type": "structure",
      "members": {
        "TopicConfigurations": {
          "locationName": "TopicConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "TopicArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "TopicArn": {
                "locationName": "Topic"
              },
              "Events": {
                "shape": "S5x",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S68"
              }
            }
          },
          "flattened": true
        },
        "QueueConfigurations": {
          "locationName": "QueueConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "QueueArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "QueueArn": {
                "locationName": "Queue"
              },
              "Events": {
                "shape": "S5x",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S68"
              }
            }
          },
          "flattened": true
        },
        "LambdaFunctionConfigurations": {
          "locationName": "CloudFunctionConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LambdaFunctionArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "LambdaFunctionArn": {
                "locationName": "CloudFunction"
              },
              "Events": {
                "shape": "S5x",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S68"
              }
            }
          },
          "flattened": true
        }
      }
    },
    "S68": {
      "type": "structure",
      "members": {
        "Key": {
          "locationName": "S3Key",
          "type": "structure",
          "members": {
            "FilterRules": {
              "locationName": "FilterRule",
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Name": {},
                  "Value": {}
                }
              },
              "flattened": true
            }
          }
        }
      }
    },
    "S6s": {
      "type": "structure",
      "required": [
        "Role",
        "Rules"
      ],
      "members": {
        "Role": {},
        "Rules": {
          "locationName": "Rule",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Status",
              "Destination"
            ],
            "members": {
              "ID": {},
              "Priority": {
                "type": "integer"
              },
              "Prefix": {
                "deprecated": true
              },
              "Filter": {
                "type": "structure",
                "members": {
                  "Prefix": {},
                  "Tag": {
                    "shape": "S3h"
                  },
                  "And": {
                    "type": "structure",
                    "members": {
                      "Prefix": {},
                      "Tags": {
                        "shape": "S3k",
                        "flattened": true,
                        "locationName": "Tag"
                      }
                    }
                  }
                }
              },
              "Status": {},
              "SourceSelectionCriteria": {
                "type": "structure",
                "members": {
                  "SseKmsEncryptedObjects": {
                    "type": "structure",
                    "required": [
                      "Status"
                    ],
                    "members": {
                      "Status": {}
                    }
                  }
                }
              },
              "ExistingObjectReplication": {
                "type": "structure",
                "required": [
                  "Status"
                ],
                "members": {
                  "Status": {}
                }
              },
              "Destination": {
                "type": "structure",
                "required": [
                  "Bucket"
                ],
                "members": {
                  "Bucket": {},
                  "Account": {},
                  "StorageClass": {},
                  "AccessControlTranslation": {
                    "type": "structure",
                    "required": [
                      "Owner"
                    ],
                    "members": {
                      "Owner": {}
                    }
                  },
                  "EncryptionConfiguration": {
                    "type": "structure",
                    "members": {
                      "ReplicaKmsKeyID": {}
                    }
                  },
                  "ReplicationTime": {
                    "type": "structure",
                    "required": [
                      "Status",
                      "Time"
                    ],
                    "members": {
                      "Status": {},
                      "Time": {
                        "shape": "S7c"
                      }
                    }
                  },
                  "Metrics": {
                    "type": "structure",
                    "required": [
                      "Status",
                      "EventThreshold"
                    ],
                    "members": {
                      "Status": {},
                      "EventThreshold": {
                        "shape": "S7c"
                      }
                    }
                  }
                }
              },
              "DeleteMarkerReplication": {
                "type": "structure",
                "members": {
                  "Status": {}
                }
              }
            }
          },
          "flattened": true
        }
      }
    },
    "S7c": {
      "type": "structure",
      "members": {
        "Minutes": {
          "type": "integer"
        }
      }
    },
    "S7t": {
      "type": "structure",
      "required": [
        "HostName"
      ],
      "members": {
        "HostName": {},
        "Protocol": {}
      }
    },
    "S7w": {
      "type": "structure",
      "required": [
        "Suffix"
      ],
      "members": {
        "Suffix": {}
      }
    },
    "S7y": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {}
      }
    },
    "S7z": {
      "type": "list",
      "member": {
        "locationName": "RoutingRule",
        "type": "structure",
        "required": [
          "Redirect"
        ],
        "members": {
          "Condition": {
            "type": "structure",
            "members": {
              "HttpErrorCodeReturnedEquals": {},
              "KeyPrefixEquals": {}
            }
          },
          "Redirect": {
            "type": "structure",
            "members": {
              "HostName": {},
              "HttpRedirectCode": {},
              "Protocol": {},
              "ReplaceKeyPrefixWith": {},
              "ReplaceKeyWith": {}
            }
          }
        }
      }
    },
    "S8y": {
      "type": "structure",
      "members": {
        "Status": {}
      }
    },
    "S91": {
      "type": "structure",
      "members": {
        "ObjectLockEnabled": {},
        "Rule": {
          "type": "structure",
          "members": {
            "DefaultRetention": {
              "type": "structure",
              "members": {
                "Mode": {},
                "Days": {
                  "type": "integer"
                },
                "Years": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S99": {
      "type": "structure",
      "members": {
        "Mode": {},
        "RetainUntilDate": {
          "shape": "S4w"
        }
      }
    },
    "S9g": {
      "type": "structure",
      "members": {
        "BlockPublicAcls": {
          "locationName": "BlockPublicAcls",
          "type": "boolean"
        },
        "IgnorePublicAcls": {
          "locationName": "IgnorePublicAcls",
          "type": "boolean"
        },
        "BlockPublicPolicy": {
          "locationName": "BlockPublicPolicy",
          "type": "boolean"
        },
        "RestrictPublicBuckets": {
          "locationName": "RestrictPublicBuckets",
          "type": "boolean"
        }
      }
    },
    "Sad": {
      "type": "structure",
      "members": {
        "ID": {},
        "DisplayName": {}
      }
    },
    "Sae": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Prefix": {}
        }
      },
      "flattened": true
    },
    "Saw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "LastModified": {
            "type": "timestamp"
          },
          "ETag": {},
          "Size": {
            "type": "integer"
          },
          "StorageClass": {},
          "Owner": {
            "shape": "S32"
          }
        }
      },
      "flattened": true
    },
    "Sbe": {
      "type": "structure",
      "members": {
        "Grants": {
          "shape": "S35",
          "locationName": "AccessControlList"
        },
        "Owner": {
          "shape": "S32"
        }
      }
    },
    "Sc1": {
      "type": "structure",
      "required": [
        "TagSet"
      ],
      "members": {
        "TagSet": {
          "shape": "S3k"
        }
      }
    },
    "Scr": {
      "type": "structure",
      "members": {
        "CSV": {
          "type": "structure",
          "members": {
            "FileHeaderInfo": {},
            "Comments": {},
            "QuoteEscapeCharacter": {},
            "RecordDelimiter": {},
            "FieldDelimiter": {},
            "QuoteCharacter": {},
            "AllowQuotedRecordDelimiter": {
              "type": "boolean"
            }
          }
        },
        "CompressionType": {},
        "JSON": {
          "type": "structure",
          "members": {
            "Type": {}
          }
        },
        "Parquet": {
          "type": "structure",
          "members": {}
        }
      }
    },
    "Sd6": {
      "type": "structure",
      "members": {
        "CSV": {
          "type": "structure",
          "members": {
            "QuoteFields": {},
            "QuoteEscapeCharacter": {},
            "RecordDelimiter": {},
            "FieldDelimiter": {},
            "QuoteCharacter": {}
          }
        },
        "JSON": {
          "type": "structure",
          "members": {
            "RecordDelimiter": {}
          }
        }
      }
    }
  }
}
},{}],200:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListBuckets": {
      "result_key": "Buckets"
    },
    "ListMultipartUploads": {
      "input_token": [
        "KeyMarker",
        "UploadIdMarker"
      ],
      "limit_key": "MaxUploads",
      "more_results": "IsTruncated",
      "output_token": [
        "NextKeyMarker",
        "NextUploadIdMarker"
      ],
      "result_key": [
        "Uploads",
        "CommonPrefixes"
      ]
    },
    "ListObjectVersions": {
      "input_token": [
        "KeyMarker",
        "VersionIdMarker"
      ],
      "limit_key": "MaxKeys",
      "more_results": "IsTruncated",
      "output_token": [
        "NextKeyMarker",
        "NextVersionIdMarker"
      ],
      "result_key": [
        "Versions",
        "DeleteMarkers",
        "CommonPrefixes"
      ]
    },
    "ListObjects": {
      "input_token": "Marker",
      "limit_key": "MaxKeys",
      "more_results": "IsTruncated",
      "output_token": "NextMarker || Contents[-1].Key",
      "result_key": [
        "Contents",
        "CommonPrefixes"
      ]
    },
    "ListObjectsV2": {
      "input_token": "ContinuationToken",
      "limit_key": "MaxKeys",
      "output_token": "NextContinuationToken",
      "result_key": [
        "Contents",
        "CommonPrefixes"
      ]
    },
    "ListParts": {
      "input_token": "PartNumberMarker",
      "limit_key": "MaxParts",
      "more_results": "IsTruncated",
      "output_token": "NextPartNumberMarker",
      "result_key": "Parts"
    }
  }
}
},{}],201:[function(require,module,exports){
module.exports={
  "version": 2,
  "waiters": {
    "BucketExists": {
      "delay": 5,
      "operation": "HeadBucket",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": 200,
          "matcher": "status",
          "state": "success"
        },
        {
          "expected": 301,
          "matcher": "status",
          "state": "success"
        },
        {
          "expected": 403,
          "matcher": "status",
          "state": "success"
        },
        {
          "expected": 404,
          "matcher": "status",
          "state": "retry"
        }
      ]
    },
    "BucketNotExists": {
      "delay": 5,
      "operation": "HeadBucket",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": 404,
          "matcher": "status",
          "state": "success"
        }
      ]
    },
    "ObjectExists": {
      "delay": 5,
      "operation": "HeadObject",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": 200,
          "matcher": "status",
          "state": "success"
        },
        {
          "expected": 404,
          "matcher": "status",
          "state": "retry"
        }
      ]
    },
    "ObjectNotExists": {
      "delay": 5,
      "operation": "HeadObject",
      "maxAttempts": 20,
      "acceptors": [
        {
          "expected": 404,
          "matcher": "status",
          "state": "success"
        }
      ]
    }
  }
}

},{}],202:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-17",
    "endpointPrefix": "secretsmanager",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Secrets Manager",
    "serviceId": "Secrets Manager",
    "signatureVersion": "v4",
    "signingName": "secretsmanager",
    "targetPrefix": "secretsmanager",
    "uid": "secretsmanager-2017-10-17"
  },
  "operations": {
    "CancelRotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "CreateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "Tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "DeleteSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "RecoveryWindowInDays": {
            "type": "long"
          },
          "ForceDeleteWithoutRecovery": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "DeletionDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "Description": {},
          "KmsKeyId": {},
          "RotationEnabled": {
            "type": "boolean"
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "Su"
          },
          "LastRotatedDate": {
            "type": "timestamp"
          },
          "LastChangedDate": {
            "type": "timestamp"
          },
          "LastAccessedDate": {
            "type": "timestamp"
          },
          "DeletedDate": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Se"
          },
          "VersionIdsToStages": {
            "shape": "S10"
          },
          "OwningService": {}
        }
      }
    },
    "GetRandomPassword": {
      "input": {
        "type": "structure",
        "members": {
          "PasswordLength": {
            "type": "long"
          },
          "ExcludeCharacters": {},
          "ExcludeNumbers": {
            "type": "boolean"
          },
          "ExcludePunctuation": {
            "type": "boolean"
          },
          "ExcludeUppercase": {
            "type": "boolean"
          },
          "ExcludeLowercase": {
            "type": "boolean"
          },
          "IncludeSpace": {
            "type": "boolean"
          },
          "RequireEachIncludedType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RandomPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "ResourcePolicy": {}
        }
      }
    },
    "GetSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "VersionId": {},
          "VersionStage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S11"
          },
          "CreatedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListSecretVersionIds": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IncludeDeprecated": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionId": {},
                "VersionStages": {
                  "shape": "S11"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "CreatedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "ARN": {},
          "Name": {}
        }
      }
    },
    "ListSecrets": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecretList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ARN": {},
                "Name": {},
                "Description": {},
                "KmsKeyId": {},
                "RotationEnabled": {
                  "type": "boolean"
                },
                "RotationLambdaARN": {},
                "RotationRules": {
                  "shape": "Su"
                },
                "LastRotatedDate": {
                  "type": "timestamp"
                },
                "LastChangedDate": {
                  "type": "timestamp"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "DeletedDate": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Se"
                },
                "SecretVersionsToStages": {
                  "shape": "S10"
                },
                "OwningService": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "ResourcePolicy"
        ],
        "members": {
          "SecretId": {},
          "ResourcePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "PutSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "VersionStages": {
            "shape": "S11"
          }
        }
      }
    },
    "RestoreSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "RotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "Tags"
        ],
        "members": {
          "SecretId": {},
          "Tags": {
            "shape": "Se"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "TagKeys"
        ],
        "members": {
          "SecretId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "UpdateSecretVersionStage": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "VersionStage"
        ],
        "members": {
          "SecretId": {},
          "VersionStage": {},
          "RemoveFromVersionId": {},
          "MoveToVersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "blob",
      "sensitive": true
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "AutomaticallyAfterDays": {
          "type": "long"
        }
      }
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S11"
      }
    },
    "S11": {
      "type": "list",
      "member": {}
    }
  }
}
},{}],203:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListSecretVersionIds": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListSecrets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}

},{}],204:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-12-10",
    "endpointPrefix": "servicecatalog",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Service Catalog",
    "serviceId": "Service Catalog",
    "signatureVersion": "v4",
    "targetPrefix": "AWS242ServiceCatalogService",
    "uid": "servicecatalog-2015-12-10"
  },
  "operations": {
    "AcceptPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateBudgetWithResource": {
      "input": {
        "type": "structure",
        "required": [
          "BudgetName",
          "ResourceId"
        ],
        "members": {
          "BudgetName": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociatePrincipalWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN",
          "PrincipalType"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {},
          "PrincipalType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateProductWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {},
          "SourcePortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateServiceActionWithProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ServiceActionId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateTagOptionWithResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagOptionId"
        ],
        "members": {
          "ResourceId": {},
          "TagOptionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "BatchAssociateServiceActionWithProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionAssociations"
        ],
        "members": {
          "ServiceActionAssociations": {
            "shape": "Sm"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedServiceActionAssociations": {
            "shape": "Sp"
          }
        }
      }
    },
    "BatchDisassociateServiceActionFromProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionAssociations"
        ],
        "members": {
          "ServiceActionAssociations": {
            "shape": "Sm"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedServiceActionAssociations": {
            "shape": "Sp"
          }
        }
      }
    },
    "CopyProduct": {
      "input": {
        "type": "structure",
        "required": [
          "SourceProductArn",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "SourceProductArn": {},
          "TargetProductId": {},
          "TargetProductName": {},
          "SourceProvisioningArtifactIdentifiers": {
            "type": "list",
            "member": {
              "type": "map",
              "key": {},
              "value": {}
            }
          },
          "CopyOptions": {
            "type": "list",
            "member": {}
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyProductToken": {}
        }
      }
    },
    "CreateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "ProductId",
          "Parameters",
          "Type",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "Parameters": {},
          "Type": {},
          "Description": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "CreatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "DisplayName",
          "ProviderName",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "Tags": {
            "shape": "S1i"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreatePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {},
          "OrganizationNode": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {}
        }
      }
    },
    "CreateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Owner",
          "ProductType",
          "ProvisioningArtifactParameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "ProductType": {},
          "Tags": {
            "shape": "S1i"
          },
          "ProvisioningArtifactParameters": {
            "shape": "S23"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanName",
          "PlanType",
          "ProductId",
          "ProvisionedProductName",
          "ProvisioningArtifactId",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanName": {},
          "PlanType": {},
          "NotificationArns": {
            "shape": "S2n"
          },
          "PathId": {},
          "ProductId": {},
          "ProvisionedProductName": {},
          "ProvisioningArtifactId": {},
          "ProvisioningParameters": {
            "shape": "S2q"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlanName": {},
          "PlanId": {},
          "ProvisionProductId": {},
          "ProvisionedProductName": {},
          "ProvisioningArtifactId": {}
        }
      }
    },
    "CreateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "Parameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "Parameters": {
            "shape": "S23"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "CreateServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DefinitionType",
          "Definition",
          "IdempotencyToken"
        ],
        "members": {
          "Name": {},
          "DefinitionType": {},
          "Definition": {
            "shape": "S31"
          },
          "Description": {},
          "AcceptLanguage": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "CreateTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    },
    "DeleteConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {},
          "OrganizationNode": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {}
        }
      }
    },
    "DeleteProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "IgnoreErrors": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "DescribeCopyProductStatus": {
      "input": {
        "type": "structure",
        "required": [
          "CopyProductToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "CopyProductToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyProductStatus": {},
          "TargetProductId": {},
          "StatusDetail": {}
        }
      }
    },
    "DescribePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          },
          "TagOptions": {
            "shape": "S43"
          },
          "Budgets": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribePortfolioShareStatus": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioShareToken"
        ],
        "members": {
          "PortfolioShareToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {},
          "PortfolioId": {},
          "OrganizationNodeValue": {},
          "Status": {},
          "ShareDetails": {
            "type": "structure",
            "members": {
              "SuccessfulShares": {
                "type": "list",
                "member": {}
              },
              "ShareErrors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Accounts": {
                      "type": "list",
                      "member": {}
                    },
                    "Message": {},
                    "Error": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeProduct": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S2d"
          },
          "ProvisioningArtifacts": {
            "shape": "S4i"
          },
          "Budgets": {
            "shape": "S44"
          },
          "LaunchPaths": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "DescribeProductAsAdmin": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "ProvisioningArtifactSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "ProvisioningArtifactMetadata": {
                  "shape": "S26"
                }
              }
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "TagOptions": {
            "shape": "S43"
          },
          "Budgets": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribeProductView": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S2d"
          },
          "ProvisioningArtifacts": {
            "shape": "S4i"
          }
        }
      }
    },
    "DescribeProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductDetail": {
            "shape": "S4w"
          },
          "CloudWatchDashboards": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {}
              }
            }
          }
        }
      }
    },
    "DescribeProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductPlanDetails": {
            "type": "structure",
            "members": {
              "CreatedTime": {
                "type": "timestamp"
              },
              "PathId": {},
              "ProductId": {},
              "PlanName": {},
              "PlanId": {},
              "ProvisionProductId": {},
              "ProvisionProductName": {},
              "PlanType": {},
              "ProvisioningArtifactId": {},
              "Status": {},
              "UpdatedTime": {
                "type": "timestamp"
              },
              "NotificationArns": {
                "shape": "S2n"
              },
              "ProvisioningParameters": {
                "shape": "S2q"
              },
              "Tags": {
                "shape": "S1q"
              },
              "StatusMessage": {}
            }
          },
          "ResourceChanges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Action": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Replacement": {},
                "Scope": {
                  "type": "list",
                  "member": {}
                },
                "Details": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Target": {
                        "type": "structure",
                        "members": {
                          "Attribute": {},
                          "Name": {},
                          "RequiresRecreation": {}
                        }
                      },
                      "Evaluation": {},
                      "CausingEntity": {}
                    }
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "DescribeProvisioningArtifact": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "ProvisioningArtifactId": {},
          "ProductId": {},
          "ProvisioningArtifactName": {},
          "ProductName": {},
          "Verbose": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "DescribeProvisioningParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "IsNoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "ConstraintSummaries": {
            "shape": "S68"
          },
          "UsageInstructions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Value": {}
              }
            }
          },
          "TagOptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ProvisioningArtifactPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6i"
              },
              "StackSetRegions": {
                "shape": "S6j"
              }
            }
          }
        }
      }
    },
    "DescribeRecord": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          },
          "RecordOutputs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OutputKey": {},
                "OutputValue": {},
                "Description": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "DescribeServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "DescribeServiceActionExecutionParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ServiceActionId"
        ],
        "members": {
          "ProvisionedProductId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "DefaultValues": {
                  "shape": "S7a"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    },
    "DisableAWSOrganizationsAccess": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateBudgetFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "BudgetName",
          "ResourceId"
        ],
        "members": {
          "BudgetName": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociatePrincipalFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateProductFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateServiceActionFromProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ServiceActionId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateTagOptionFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagOptionId"
        ],
        "members": {
          "ResourceId": {},
          "TagOptionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableAWSOrganizationsAccess": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ExecuteProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          }
        }
      }
    },
    "ExecuteProvisionedProductServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ServiceActionId",
          "ExecuteToken"
        ],
        "members": {
          "ProvisionedProductId": {},
          "ServiceActionId": {},
          "ExecuteToken": {
            "idempotencyToken": true
          },
          "AcceptLanguage": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S7a"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          }
        }
      }
    },
    "GetAWSOrganizationsAccessStatus": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessStatus": {}
        }
      }
    },
    "ListAcceptedPortfolioShares": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S82"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListBudgetsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ResourceId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Budgets": {
            "shape": "S44"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListConstraintsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetails": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListLaunchPaths": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchPathSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ConstraintSummaries": {
                  "shape": "S68"
                },
                "Tags": {
                  "shape": "S1q"
                },
                "Name": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListOrganizationPortfolioAccess": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "OrganizationNodeType"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "OrganizationNodeType": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationNodes": {
            "type": "list",
            "member": {
              "shape": "S1s"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolioAccess": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "OrganizationParentId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolios": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S82"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfoliosForProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S82"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPrincipalsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PrincipalARN": {},
                "PrincipalType": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisionedProductPlans": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "ProvisionProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AccessLevelFilter": {
            "shape": "S8r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductPlans": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlanName": {},
                "PlanId": {},
                "ProvisionProductId": {},
                "ProvisionProductName": {},
                "PlanType": {},
                "ProvisioningArtifactId": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisioningArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetails": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisioningArtifactsForServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionId"
        ],
        "members": {
          "ServiceActionId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactViews": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProductViewSummary": {
                  "shape": "S2d"
                },
                "ProvisioningArtifact": {
                  "shape": "S4j"
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListRecordHistory": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8r"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetails": {
            "type": "list",
            "member": {
              "shape": "S6n"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListResourcesForTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "TagOptionId"
        ],
        "members": {
          "TagOptionId": {},
          "ResourceType": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ARN": {},
                "Name": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "PageToken": {}
        }
      }
    },
    "ListServiceActions": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionSummaries": {
            "shape": "S9m"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListServiceActionsForProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionSummaries": {
            "shape": "S9m"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListStackInstancesForProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StackInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "Region": {},
                "StackInstanceStatus": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListTagOptions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {},
              "Active": {
                "type": "boolean"
              }
            }
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetails": {
            "shape": "S43"
          },
          "PageToken": {}
        }
      }
    },
    "ProvisionProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ProvisionedProductName",
          "ProvisionToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisionedProductName": {},
          "ProvisioningParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "ProvisioningPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6i"
              },
              "StackSetRegions": {
                "shape": "S6j"
              },
              "StackSetFailureToleranceCount": {
                "type": "integer"
              },
              "StackSetFailureTolerancePercentage": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyCount": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyPercentage": {
                "type": "integer"
              }
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "NotificationArns": {
            "shape": "S2n"
          },
          "ProvisionToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          }
        }
      }
    },
    "RejectPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ScanProvisionedProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8r"
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProducts": {
            "type": "list",
            "member": {
              "shape": "S4w"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "Filters": {
            "shape": "Sac"
          },
          "PageSize": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummaries": {
            "type": "list",
            "member": {
              "shape": "S2d"
            }
          },
          "ProductViewAggregations": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Value": {},
                  "ApproximateCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProductsAsAdmin": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "Filters": {
            "shape": "Sac"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "ProductSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetails": {
            "type": "list",
            "member": {
              "shape": "S2c"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProvisionedProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8r"
          },
          "Filters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {}
            }
          },
          "SortBy": {},
          "SortOrder": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "Type": {},
                "Id": {},
                "Status": {},
                "StatusMessage": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "IdempotencyToken": {},
                "LastRecordId": {},
                "Tags": {
                  "shape": "S1q"
                },
                "PhysicalId": {},
                "ProductId": {},
                "ProvisioningArtifactId": {},
                "UserArn": {},
                "UserArnSession": {}
              }
            }
          },
          "TotalResultsCount": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      }
    },
    "TerminateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "TerminateToken"
        ],
        "members": {
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "TerminateToken": {
            "idempotencyToken": true
          },
          "IgnoreErrors": {
            "type": "boolean"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          }
        }
      }
    },
    "UpdateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Description": {},
          "Parameters": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "UpdatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "AddTags": {
            "shape": "S1i"
          },
          "RemoveTags": {
            "shape": "Sbd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "UpdateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "AddTags": {
            "shape": "S1i"
          },
          "RemoveTags": {
            "shape": "Sbd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "UpdateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "UpdateToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisioningParameters": {
            "shape": "S2q"
          },
          "ProvisioningPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6i"
              },
              "StackSetRegions": {
                "shape": "S6j"
              },
              "StackSetFailureToleranceCount": {
                "type": "integer"
              },
              "StackSetFailureTolerancePercentage": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyCount": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyPercentage": {
                "type": "integer"
              },
              "StackSetOperationType": {}
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "UpdateToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6n"
          }
        }
      }
    },
    "UpdateProvisionedProductProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ProvisionedProductProperties",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductId": {},
          "ProvisionedProductProperties": {
            "shape": "Sbm"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductId": {},
          "ProvisionedProductProperties": {
            "shape": "Sbm"
          },
          "RecordId": {},
          "Status": {}
        }
      }
    },
    "UpdateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "Name": {},
          "Description": {},
          "Active": {
            "type": "boolean"
          },
          "Guidance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "UpdateServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "Name": {},
          "Definition": {
            "shape": "S31"
          },
          "Description": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "UpdateTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "Value": {},
          "Active": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    }
  },
  "shapes": {
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ServiceActionId",
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "ServiceActionId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {}
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ServiceActionId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "ConstraintId": {},
        "Type": {},
        "Description": {},
        "Owner": {},
        "ProductId": {},
        "PortfolioId": {}
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "shape": "S1j"
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Id": {},
        "ARN": {},
        "DisplayName": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "ProviderName": {}
      }
    },
    "S1q": {
      "type": "list",
      "member": {
        "shape": "S1j"
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "Info"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Info": {
          "shape": "S26"
        },
        "Type": {},
        "DisableTemplateValidation": {
          "type": "boolean"
        }
      }
    },
    "S26": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2c": {
      "type": "structure",
      "members": {
        "ProductViewSummary": {
          "shape": "S2d"
        },
        "Status": {},
        "ProductARN": {},
        "CreatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "Id": {},
        "ProductId": {},
        "Name": {},
        "Owner": {},
        "ShortDescription": {},
        "Type": {},
        "Distributor": {},
        "HasDefaultPath": {
          "type": "boolean"
        },
        "SupportEmail": {},
        "SupportDescription": {},
        "SupportUrl": {}
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "Type": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "Active": {
          "type": "boolean"
        },
        "Guidance": {}
      }
    },
    "S2n": {
      "type": "list",
      "member": {}
    },
    "S2q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "UsePreviousValue": {
            "type": "boolean"
          }
        }
      }
    },
    "S31": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S36": {
      "type": "structure",
      "members": {
        "ServiceActionSummary": {
          "shape": "S37"
        },
        "Definition": {
          "shape": "S31"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "DefinitionType": {}
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {},
        "Active": {
          "type": "boolean"
        },
        "Id": {}
      }
    },
    "S43": {
      "type": "list",
      "member": {
        "shape": "S3c"
      }
    },
    "S44": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BudgetName": {}
        }
      }
    },
    "S4i": {
      "type": "list",
      "member": {
        "shape": "S4j"
      }
    },
    "S4j": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "Guidance": {}
      }
    },
    "S4w": {
      "type": "structure",
      "members": {
        "Name": {},
        "Arn": {},
        "Type": {},
        "Id": {},
        "Status": {},
        "StatusMessage": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "IdempotencyToken": {},
        "LastRecordId": {},
        "ProductId": {},
        "ProvisioningArtifactId": {}
      }
    },
    "S68": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Description": {}
        }
      }
    },
    "S6i": {
      "type": "list",
      "member": {}
    },
    "S6j": {
      "type": "list",
      "member": {}
    },
    "S6n": {
      "type": "structure",
      "members": {
        "RecordId": {},
        "ProvisionedProductName": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "UpdatedTime": {
          "type": "timestamp"
        },
        "ProvisionedProductType": {},
        "RecordType": {},
        "ProvisionedProductId": {},
        "ProductId": {},
        "ProvisioningArtifactId": {},
        "PathId": {},
        "RecordErrors": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Code": {},
              "Description": {}
            }
          }
        },
        "RecordTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S7a": {
      "type": "list",
      "member": {}
    },
    "S82": {
      "type": "list",
      "member": {
        "shape": "S1n"
      }
    },
    "S8r": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S9m": {
      "type": "list",
      "member": {
        "shape": "S37"
      }
    },
    "Sac": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sbd": {
      "type": "list",
      "member": {}
    },
    "Sbm": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}
},{}],205:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListAcceptedPortfolioShares": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListBudgetsForResource": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListConstraintsForPortfolio": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListLaunchPaths": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListOrganizationPortfolioAccess": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPortfolioAccess": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPortfolios": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPortfoliosForProduct": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPrincipalsForPortfolio": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListProvisioningArtifactsForServiceAction": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListResourcesForTagOption": {
      "input_token": "PageToken",
      "output_token": "PageToken",
      "limit_key": "PageSize"
    },
    "ListServiceActions": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListServiceActionsForProvisioningArtifact": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListTagOptions": {
      "input_token": "PageToken",
      "output_token": "PageToken",
      "limit_key": "PageSize"
    },
    "SearchProducts": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "SearchProductsAsAdmin": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "SearchProvisionedProducts": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    }
  }
}

},{}],206:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-03-31",
    "endpointPrefix": "sns",
    "protocol": "query",
    "serviceAbbreviation": "Amazon SNS",
    "serviceFullName": "Amazon Simple Notification Service",
    "serviceId": "SNS",
    "signatureVersion": "v4",
    "uid": "sns-2010-03-31",
    "xmlNamespace": "http://sns.amazonaws.com/doc/2010-03-31/"
  },
  "operations": {
    "AddPermission": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn",
          "Label",
          "AWSAccountId",
          "ActionName"
        ],
        "members": {
          "TopicArn": {},
          "Label": {},
          "AWSAccountId": {
            "type": "list",
            "member": {}
          },
          "ActionName": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "CheckIfPhoneNumberIsOptedOut": {
      "input": {
        "type": "structure",
        "required": [
          "phoneNumber"
        ],
        "members": {
          "phoneNumber": {}
        }
      },
      "output": {
        "resultWrapper": "CheckIfPhoneNumberIsOptedOutResult",
        "type": "structure",
        "members": {
          "isOptedOut": {
            "type": "boolean"
          }
        }
      }
    },
    "ConfirmSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn",
          "Token"
        ],
        "members": {
          "TopicArn": {},
          "Token": {},
          "AuthenticateOnUnsubscribe": {}
        }
      },
      "output": {
        "resultWrapper": "ConfirmSubscriptionResult",
        "type": "structure",
        "members": {
          "SubscriptionArn": {}
        }
      }
    },
    "CreatePlatformApplication": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Platform",
          "Attributes"
        ],
        "members": {
          "Name": {},
          "Platform": {},
          "Attributes": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "resultWrapper": "CreatePlatformApplicationResult",
        "type": "structure",
        "members": {
          "PlatformApplicationArn": {}
        }
      }
    },
    "CreatePlatformEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformApplicationArn",
          "Token"
        ],
        "members": {
          "PlatformApplicationArn": {},
          "Token": {},
          "CustomUserData": {},
          "Attributes": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "resultWrapper": "CreatePlatformEndpointResult",
        "type": "structure",
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "CreateTopic": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Attributes": {
            "shape": "Sp"
          },
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateTopicResult",
        "type": "structure",
        "members": {
          "TopicArn": {}
        }
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "DeletePlatformApplication": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformApplicationArn"
        ],
        "members": {
          "PlatformApplicationArn": {}
        }
      }
    },
    "DeleteTopic": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn"
        ],
        "members": {
          "TopicArn": {}
        }
      }
    },
    "GetEndpointAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetEndpointAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetPlatformApplicationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformApplicationArn"
        ],
        "members": {
          "PlatformApplicationArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetPlatformApplicationAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetSMSAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "attributes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "GetSMSAttributesResult",
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetSubscriptionAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionArn"
        ],
        "members": {
          "SubscriptionArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetSubscriptionAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S19"
          }
        }
      }
    },
    "GetTopicAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn"
        ],
        "members": {
          "TopicArn": {}
        }
      },
      "output": {
        "resultWrapper": "GetTopicAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sp"
          }
        }
      }
    },
    "ListEndpointsByPlatformApplication": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformApplicationArn"
        ],
        "members": {
          "PlatformApplicationArn": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListEndpointsByPlatformApplicationResult",
        "type": "structure",
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EndpointArn": {},
                "Attributes": {
                  "shape": "Sj"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumbersOptedOut": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListPhoneNumbersOptedOutResult",
        "type": "structure",
        "members": {
          "phoneNumbers": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListPlatformApplications": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListPlatformApplicationsResult",
        "type": "structure",
        "members": {
          "PlatformApplications": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlatformApplicationArn": {},
                "Attributes": {
                  "shape": "Sj"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListSubscriptionsResult",
        "type": "structure",
        "members": {
          "Subscriptions": {
            "shape": "S1r"
          },
          "NextToken": {}
        }
      }
    },
    "ListSubscriptionsByTopic": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn"
        ],
        "members": {
          "TopicArn": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListSubscriptionsByTopicResult",
        "type": "structure",
        "members": {
          "Subscriptions": {
            "shape": "S1r"
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Ss"
          }
        }
      }
    },
    "ListTopics": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListTopicsResult",
        "type": "structure",
        "members": {
          "Topics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TopicArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "OptInPhoneNumber": {
      "input": {
        "type": "structure",
        "required": [
          "phoneNumber"
        ],
        "members": {
          "phoneNumber": {}
        }
      },
      "output": {
        "resultWrapper": "OptInPhoneNumberResult",
        "type": "structure",
        "members": {}
      }
    },
    "Publish": {
      "input": {
        "type": "structure",
        "required": [
          "Message"
        ],
        "members": {
          "TopicArn": {},
          "TargetArn": {},
          "PhoneNumber": {},
          "Message": {},
          "Subject": {},
          "MessageStructure": {},
          "MessageAttributes": {
            "type": "map",
            "key": {
              "locationName": "Name"
            },
            "value": {
              "locationName": "Value",
              "type": "structure",
              "required": [
                "DataType"
              ],
              "members": {
                "DataType": {},
                "StringValue": {},
                "BinaryValue": {
                  "type": "blob"
                }
              }
            }
          }
        }
      },
      "output": {
        "resultWrapper": "PublishResult",
        "type": "structure",
        "members": {
          "MessageId": {}
        }
      }
    },
    "RemovePermission": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn",
          "Label"
        ],
        "members": {
          "TopicArn": {},
          "Label": {}
        }
      }
    },
    "SetEndpointAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn",
          "Attributes"
        ],
        "members": {
          "EndpointArn": {},
          "Attributes": {
            "shape": "Sj"
          }
        }
      }
    },
    "SetPlatformApplicationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "PlatformApplicationArn",
          "Attributes"
        ],
        "members": {
          "PlatformApplicationArn": {},
          "Attributes": {
            "shape": "Sj"
          }
        }
      }
    },
    "SetSMSAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "attributes": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "resultWrapper": "SetSMSAttributesResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetSubscriptionAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionArn",
          "AttributeName"
        ],
        "members": {
          "SubscriptionArn": {},
          "AttributeName": {},
          "AttributeValue": {}
        }
      }
    },
    "SetTopicAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn",
          "AttributeName"
        ],
        "members": {
          "TopicArn": {},
          "AttributeName": {},
          "AttributeValue": {}
        }
      }
    },
    "Subscribe": {
      "input": {
        "type": "structure",
        "required": [
          "TopicArn",
          "Protocol"
        ],
        "members": {
          "TopicArn": {},
          "Protocol": {},
          "Endpoint": {},
          "Attributes": {
            "shape": "S19"
          },
          "ReturnSubscriptionArn": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SubscribeResult",
        "type": "structure",
        "members": {
          "SubscriptionArn": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "resultWrapper": "TagResourceResult",
        "type": "structure",
        "members": {}
      }
    },
    "Unsubscribe": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionArn"
        ],
        "members": {
          "SubscriptionArn": {}
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "UntagResourceResult",
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sp": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S19": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SubscriptionArn": {},
          "Owner": {},
          "Protocol": {},
          "Endpoint": {},
          "TopicArn": {}
        }
      }
    }
  }
}
},{}],207:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListEndpointsByPlatformApplication": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Endpoints"
    },
    "ListPlatformApplications": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "PlatformApplications"
    },
    "ListSubscriptions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Subscriptions"
    },
    "ListSubscriptionsByTopic": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Subscriptions"
    },
    "ListTopics": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Topics"
    }
  }
}
},{}],208:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-11-05",
    "endpointPrefix": "sqs",
    "protocol": "query",
    "serviceAbbreviation": "Amazon SQS",
    "serviceFullName": "Amazon Simple Queue Service",
    "serviceId": "SQS",
    "signatureVersion": "v4",
    "uid": "sqs-2012-11-05",
    "xmlNamespace": "http://queue.amazonaws.com/doc/2012-11-05/"
  },
  "operations": {
    "AddPermission": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Label",
          "AWSAccountIds",
          "Actions"
        ],
        "members": {
          "QueueUrl": {},
          "Label": {},
          "AWSAccountIds": {
            "type": "list",
            "member": {
              "locationName": "AWSAccountId"
            },
            "flattened": true
          },
          "Actions": {
            "type": "list",
            "member": {
              "locationName": "ActionName"
            },
            "flattened": true
          }
        }
      }
    },
    "ChangeMessageVisibility": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "ReceiptHandle",
          "VisibilityTimeout"
        ],
        "members": {
          "QueueUrl": {},
          "ReceiptHandle": {},
          "VisibilityTimeout": {
            "type": "integer"
          }
        }
      }
    },
    "ChangeMessageVisibilityBatch": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Entries"
        ],
        "members": {
          "QueueUrl": {},
          "Entries": {
            "type": "list",
            "member": {
              "locationName": "ChangeMessageVisibilityBatchRequestEntry",
              "type": "structure",
              "required": [
                "Id",
                "ReceiptHandle"
              ],
              "members": {
                "Id": {},
                "ReceiptHandle": {},
                "VisibilityTimeout": {
                  "type": "integer"
                }
              }
            },
            "flattened": true
          }
        }
      },
      "output": {
        "resultWrapper": "ChangeMessageVisibilityBatchResult",
        "type": "structure",
        "required": [
          "Successful",
          "Failed"
        ],
        "members": {
          "Successful": {
            "type": "list",
            "member": {
              "locationName": "ChangeMessageVisibilityBatchResultEntry",
              "type": "structure",
              "required": [
                "Id"
              ],
              "members": {
                "Id": {}
              }
            },
            "flattened": true
          },
          "Failed": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateQueue": {
      "input": {
        "type": "structure",
        "required": [
          "QueueName"
        ],
        "members": {
          "QueueName": {},
          "Attributes": {
            "shape": "Sh",
            "locationName": "Attribute"
          },
          "tags": {
            "shape": "Sj",
            "locationName": "Tag"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateQueueResult",
        "type": "structure",
        "members": {
          "QueueUrl": {}
        }
      }
    },
    "DeleteMessage": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "ReceiptHandle"
        ],
        "members": {
          "QueueUrl": {},
          "ReceiptHandle": {}
        }
      }
    },
    "DeleteMessageBatch": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Entries"
        ],
        "members": {
          "QueueUrl": {},
          "Entries": {
            "type": "list",
            "member": {
              "locationName": "DeleteMessageBatchRequestEntry",
              "type": "structure",
              "required": [
                "Id",
                "ReceiptHandle"
              ],
              "members": {
                "Id": {},
                "ReceiptHandle": {}
              }
            },
            "flattened": true
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteMessageBatchResult",
        "type": "structure",
        "required": [
          "Successful",
          "Failed"
        ],
        "members": {
          "Successful": {
            "type": "list",
            "member": {
              "locationName": "DeleteMessageBatchResultEntry",
              "type": "structure",
              "required": [
                "Id"
              ],
              "members": {
                "Id": {}
              }
            },
            "flattened": true
          },
          "Failed": {
            "shape": "Sd"
          }
        }
      }
    },
    "DeleteQueue": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {}
        }
      }
    },
    "GetQueueAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {},
          "AttributeNames": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "resultWrapper": "GetQueueAttributesResult",
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "Sh",
            "locationName": "Attribute"
          }
        }
      }
    },
    "GetQueueUrl": {
      "input": {
        "type": "structure",
        "required": [
          "QueueName"
        ],
        "members": {
          "QueueName": {},
          "QueueOwnerAWSAccountId": {}
        }
      },
      "output": {
        "resultWrapper": "GetQueueUrlResult",
        "type": "structure",
        "members": {
          "QueueUrl": {}
        }
      }
    },
    "ListDeadLetterSourceQueues": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListDeadLetterSourceQueuesResult",
        "type": "structure",
        "required": [
          "queueUrls"
        ],
        "members": {
          "queueUrls": {
            "shape": "S14"
          },
          "NextToken": {}
        }
      }
    },
    "ListQueueTags": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {}
        }
      },
      "output": {
        "resultWrapper": "ListQueueTagsResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sj",
            "locationName": "Tag"
          }
        }
      }
    },
    "ListQueues": {
      "input": {
        "type": "structure",
        "members": {
          "QueueNamePrefix": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListQueuesResult",
        "type": "structure",
        "members": {
          "QueueUrls": {
            "shape": "S14"
          },
          "NextToken": {}
        }
      }
    },
    "PurgeQueue": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {}
        }
      }
    },
    "ReceiveMessage": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl"
        ],
        "members": {
          "QueueUrl": {},
          "AttributeNames": {
            "shape": "Sw"
          },
          "MessageAttributeNames": {
            "type": "list",
            "member": {
              "locationName": "MessageAttributeName"
            },
            "flattened": true
          },
          "MaxNumberOfMessages": {
            "type": "integer"
          },
          "VisibilityTimeout": {
            "type": "integer"
          },
          "WaitTimeSeconds": {
            "type": "integer"
          },
          "ReceiveRequestAttemptId": {}
        }
      },
      "output": {
        "resultWrapper": "ReceiveMessageResult",
        "type": "structure",
        "members": {
          "Messages": {
            "type": "list",
            "member": {
              "locationName": "Message",
              "type": "structure",
              "members": {
                "MessageId": {},
                "ReceiptHandle": {},
                "MD5OfBody": {},
                "Body": {},
                "Attributes": {
                  "locationName": "Attribute",
                  "type": "map",
                  "key": {
                    "locationName": "Name"
                  },
                  "value": {
                    "locationName": "Value"
                  },
                  "flattened": true
                },
                "MD5OfMessageAttributes": {},
                "MessageAttributes": {
                  "shape": "S1i",
                  "locationName": "MessageAttribute"
                }
              }
            },
            "flattened": true
          }
        }
      }
    },
    "RemovePermission": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Label"
        ],
        "members": {
          "QueueUrl": {},
          "Label": {}
        }
      }
    },
    "SendMessage": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "MessageBody"
        ],
        "members": {
          "QueueUrl": {},
          "MessageBody": {},
          "DelaySeconds": {
            "type": "integer"
          },
          "MessageAttributes": {
            "shape": "S1i",
            "locationName": "MessageAttribute"
          },
          "MessageSystemAttributes": {
            "shape": "S1p",
            "locationName": "MessageSystemAttribute"
          },
          "MessageDeduplicationId": {},
          "MessageGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "SendMessageResult",
        "type": "structure",
        "members": {
          "MD5OfMessageBody": {},
          "MD5OfMessageAttributes": {},
          "MD5OfMessageSystemAttributes": {},
          "MessageId": {},
          "SequenceNumber": {}
        }
      }
    },
    "SendMessageBatch": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Entries"
        ],
        "members": {
          "QueueUrl": {},
          "Entries": {
            "type": "list",
            "member": {
              "locationName": "SendMessageBatchRequestEntry",
              "type": "structure",
              "required": [
                "Id",
                "MessageBody"
              ],
              "members": {
                "Id": {},
                "MessageBody": {},
                "DelaySeconds": {
                  "type": "integer"
                },
                "MessageAttributes": {
                  "shape": "S1i",
                  "locationName": "MessageAttribute"
                },
                "MessageSystemAttributes": {
                  "shape": "S1p",
                  "locationName": "MessageSystemAttribute"
                },
                "MessageDeduplicationId": {},
                "MessageGroupId": {}
              }
            },
            "flattened": true
          }
        }
      },
      "output": {
        "resultWrapper": "SendMessageBatchResult",
        "type": "structure",
        "required": [
          "Successful",
          "Failed"
        ],
        "members": {
          "Successful": {
            "type": "list",
            "member": {
              "locationName": "SendMessageBatchResultEntry",
              "type": "structure",
              "required": [
                "Id",
                "MessageId",
                "MD5OfMessageBody"
              ],
              "members": {
                "Id": {},
                "MessageId": {},
                "MD5OfMessageBody": {},
                "MD5OfMessageAttributes": {},
                "MD5OfMessageSystemAttributes": {},
                "SequenceNumber": {}
              }
            },
            "flattened": true
          },
          "Failed": {
            "shape": "Sd"
          }
        }
      }
    },
    "SetQueueAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Attributes"
        ],
        "members": {
          "QueueUrl": {},
          "Attributes": {
            "shape": "Sh",
            "locationName": "Attribute"
          }
        }
      }
    },
    "TagQueue": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "Tags"
        ],
        "members": {
          "QueueUrl": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      }
    },
    "UntagQueue": {
      "input": {
        "type": "structure",
        "required": [
          "QueueUrl",
          "TagKeys"
        ],
        "members": {
          "QueueUrl": {},
          "TagKeys": {
            "type": "list",
            "member": {
              "locationName": "TagKey"
            },
            "flattened": true
          }
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "list",
      "member": {
        "locationName": "BatchResultErrorEntry",
        "type": "structure",
        "required": [
          "Id",
          "SenderFault",
          "Code"
        ],
        "members": {
          "Id": {},
          "SenderFault": {
            "type": "boolean"
          },
          "Code": {},
          "Message": {}
        }
      },
      "flattened": true
    },
    "Sh": {
      "type": "map",
      "key": {
        "locationName": "Name"
      },
      "value": {
        "locationName": "Value"
      },
      "flattened": true,
      "locationName": "Attribute"
    },
    "Sj": {
      "type": "map",
      "key": {
        "locationName": "Key"
      },
      "value": {
        "locationName": "Value"
      },
      "flattened": true,
      "locationName": "Tag"
    },
    "Sw": {
      "type": "list",
      "member": {
        "locationName": "AttributeName"
      },
      "flattened": true
    },
    "S14": {
      "type": "list",
      "member": {
        "locationName": "QueueUrl"
      },
      "flattened": true
    },
    "S1i": {
      "type": "map",
      "key": {
        "locationName": "Name"
      },
      "value": {
        "locationName": "Value",
        "type": "structure",
        "required": [
          "DataType"
        ],
        "members": {
          "StringValue": {},
          "BinaryValue": {
            "type": "blob"
          },
          "StringListValues": {
            "shape": "S1l",
            "flattened": true,
            "locationName": "StringListValue"
          },
          "BinaryListValues": {
            "shape": "S1m",
            "flattened": true,
            "locationName": "BinaryListValue"
          },
          "DataType": {}
        }
      },
      "flattened": true
    },
    "S1l": {
      "type": "list",
      "member": {
        "locationName": "StringListValue"
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "locationName": "BinaryListValue",
        "type": "blob"
      }
    },
    "S1p": {
      "type": "map",
      "key": {
        "locationName": "Name"
      },
      "value": {
        "locationName": "Value",
        "type": "structure",
        "required": [
          "DataType"
        ],
        "members": {
          "StringValue": {},
          "BinaryValue": {
            "type": "blob"
          },
          "StringListValues": {
            "shape": "S1l",
            "flattened": true,
            "locationName": "StringListValue"
          },
          "BinaryListValues": {
            "shape": "S1m",
            "flattened": true,
            "locationName": "BinaryListValue"
          },
          "DataType": {}
        }
      },
      "flattened": true
    }
  }
}
},{}],209:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListDeadLetterSourceQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "queueUrls"
    },
    "ListQueues": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "QueueUrls"
    }
  }
}
},{}],210:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-06",
    "endpointPrefix": "ssm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon SSM",
    "serviceFullName": "Amazon Simple Systems Manager (SSM)",
    "serviceId": "SSM",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonSSM",
    "uid": "ssm-2014-11-06"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCommand": {
      "input": {
        "type": "structure",
        "required": [
          "CommandId"
        ],
        "members": {
          "CommandId": {},
          "InstanceIds": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelMaintenanceWindowExecution": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId"
        ],
        "members": {
          "WindowExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {}
        }
      }
    },
    "CreateActivation": {
      "input": {
        "type": "structure",
        "required": [
          "IamRole"
        ],
        "members": {
          "Description": {},
          "DefaultInstanceName": {},
          "IamRole": {},
          "RegistrationLimit": {
            "type": "integer"
          },
          "ExpirationDate": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivationId": {},
          "ActivationCode": {}
        }
      }
    },
    "CreateAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {},
          "InstanceId": {},
          "Parameters": {
            "shape": "St"
          },
          "Targets": {
            "shape": "Sx"
          },
          "ScheduleExpression": {},
          "OutputLocation": {
            "shape": "S13"
          },
          "AssociationName": {},
          "AutomationTargetParameterName": {},
          "MaxErrors": {},
          "MaxConcurrency": {},
          "ComplianceSeverity": {},
          "SyncCompliance": {},
          "ApplyOnlyAtCronInterval": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S1g"
          }
        }
      }
    },
    "CreateAssociationBatch": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "shape": "S1u"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "type": "list",
            "member": {
              "shape": "S1g"
            }
          },
          "Failed": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Entry": {
                  "shape": "S1u"
                },
                "Message": {},
                "Fault": {}
              }
            }
          }
        }
      }
    },
    "CreateDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Content",
          "Name"
        ],
        "members": {
          "Content": {},
          "Requires": {
            "shape": "S23"
          },
          "Attachments": {
            "shape": "S25"
          },
          "Name": {},
          "VersionName": {},
          "DocumentType": {},
          "DocumentFormat": {},
          "TargetType": {},
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentDescription": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Schedule",
          "Duration",
          "Cutoff",
          "AllowUnassociatedTargets"
        ],
        "members": {
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "StartDate": {},
          "EndDate": {},
          "Schedule": {},
          "ScheduleTimezone": {},
          "ScheduleOffset": {
            "type": "integer"
          },
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {}
        }
      }
    },
    "CreateOpsItem": {
      "input": {
        "type": "structure",
        "required": [
          "Description",
          "Source",
          "Title"
        ],
        "members": {
          "Description": {},
          "OperationalData": {
            "shape": "S3g"
          },
          "Notifications": {
            "shape": "S3l"
          },
          "Priority": {
            "type": "integer"
          },
          "RelatedOpsItems": {
            "shape": "S3p"
          },
          "Source": {},
          "Title": {},
          "Tags": {
            "shape": "S4"
          },
          "Category": {},
          "Severity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OpsItemId": {}
        }
      }
    },
    "CreatePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "OperatingSystem": {},
          "Name": {},
          "GlobalFilters": {
            "shape": "S3z"
          },
          "ApprovalRules": {
            "shape": "S45"
          },
          "ApprovedPatches": {
            "shape": "S4c"
          },
          "ApprovedPatchesComplianceLevel": {},
          "ApprovedPatchesEnableNonSecurity": {
            "type": "boolean"
          },
          "RejectedPatches": {
            "shape": "S4c"
          },
          "RejectedPatchesAction": {},
          "Description": {},
          "Sources": {
            "shape": "S4g"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "CreateResourceDataSync": {
      "input": {
        "type": "structure",
        "required": [
          "SyncName"
        ],
        "members": {
          "SyncName": {},
          "S3Destination": {
            "shape": "S4q"
          },
          "SyncType": {},
          "SyncSource": {
            "shape": "S4z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteActivation": {
      "input": {
        "type": "structure",
        "required": [
          "ActivationId"
        ],
        "members": {
          "ActivationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {},
          "VersionName": {},
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInventory": {
      "input": {
        "type": "structure",
        "required": [
          "TypeName"
        ],
        "members": {
          "TypeName": {},
          "SchemaDeleteOption": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletionId": {},
          "TypeName": {},
          "DeletionSummary": {
            "shape": "S5m"
          }
        }
      }
    },
    "DeleteMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {}
        }
      }
    },
    "DeleteParameter": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteParameters": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "S5z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletedParameters": {
            "shape": "S5z"
          },
          "InvalidParameters": {
            "shape": "S5z"
          }
        }
      }
    },
    "DeletePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "DeleteResourceDataSync": {
      "input": {
        "type": "structure",
        "required": [
          "SyncName"
        ],
        "members": {
          "SyncName": {},
          "SyncType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterManagedInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId",
          "PatchGroup"
        ],
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      }
    },
    "DeregisterTargetFromMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTargetId"
        ],
        "members": {
          "WindowId": {},
          "WindowTargetId": {},
          "Safe": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTargetId": {}
        }
      }
    },
    "DeregisterTaskFromMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTaskId"
        ],
        "members": {
          "WindowId": {},
          "WindowTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTaskId": {}
        }
      }
    },
    "DescribeActivations": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FilterKey": {},
                "FilterValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActivationId": {},
                "Description": {},
                "DefaultInstanceName": {},
                "IamRole": {},
                "RegistrationLimit": {
                  "type": "integer"
                },
                "RegistrationsCount": {
                  "type": "integer"
                },
                "ExpirationDate": {
                  "type": "timestamp"
                },
                "Expired": {
                  "type": "boolean"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "S4"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationId": {},
          "AssociationVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S1g"
          }
        }
      }
    },
    "DescribeAssociationExecutionTargets": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "ExecutionId"
        ],
        "members": {
          "AssociationId": {},
          "ExecutionId": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Value"
              ],
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationExecutionTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "AssociationVersion": {},
                "ExecutionId": {},
                "ResourceId": {},
                "ResourceType": {},
                "Status": {},
                "DetailedStatus": {},
                "LastExecutionDate": {
                  "type": "timestamp"
                },
                "OutputSource": {
                  "type": "structure",
                  "members": {
                    "OutputSourceId": {},
                    "OutputSourceType": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAssociationExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Value",
                "Type"
              ],
              "members": {
                "Key": {},
                "Value": {},
                "Type": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "AssociationVersion": {},
                "ExecutionId": {},
                "Status": {},
                "DetailedStatus": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastExecutionDate": {
                  "type": "timestamp"
                },
                "ResourceCountByStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutomationExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecutionMetadataList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutomationExecutionId": {},
                "DocumentName": {},
                "DocumentVersion": {},
                "AutomationExecutionStatus": {},
                "ExecutionStartTime": {
                  "type": "timestamp"
                },
                "ExecutionEndTime": {
                  "type": "timestamp"
                },
                "ExecutedBy": {},
                "LogFile": {},
                "Outputs": {
                  "shape": "S7u"
                },
                "Mode": {},
                "ParentAutomationExecutionId": {},
                "CurrentStepName": {},
                "CurrentAction": {},
                "FailureMessage": {},
                "TargetParameterName": {},
                "Targets": {
                  "shape": "Sx"
                },
                "TargetMaps": {
                  "shape": "S7z"
                },
                "ResolvedTargets": {
                  "shape": "S84"
                },
                "MaxConcurrency": {},
                "MaxErrors": {},
                "Target": {},
                "AutomationType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutomationStepExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId"
        ],
        "members": {
          "AutomationExecutionId": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ReverseOrder": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StepExecutions": {
            "shape": "S8e"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAvailablePatches": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S8u"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Patches": {
            "type": "list",
            "member": {
              "shape": "S92"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {},
          "VersionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Document": {
            "shape": "S2h"
          }
        }
      }
    },
    "DescribeDocumentPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PermissionType"
        ],
        "members": {
          "Name": {},
          "PermissionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S9j"
          },
          "AccountSharingInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "SharedDocumentVersion": {}
              }
            }
          }
        }
      }
    },
    "DescribeEffectiveInstanceAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "InstanceId": {},
                "Content": {},
                "AssociationVersion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeEffectivePatchesForPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EffectivePatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Patch": {
                  "shape": "S92"
                },
                "PatchStatus": {
                  "type": "structure",
                  "members": {
                    "DeploymentStatus": {},
                    "ComplianceLevel": {},
                    "ApprovalDate": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstanceAssociationsStatus": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceAssociationStatusInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "Name": {},
                "DocumentVersion": {},
                "AssociationVersion": {},
                "InstanceId": {},
                "ExecutionDate": {
                  "type": "timestamp"
                },
                "Status": {},
                "DetailedStatus": {},
                "ExecutionSummary": {},
                "ErrorCode": {},
                "OutputUrl": {
                  "type": "structure",
                  "members": {
                    "S3OutputUrl": {
                      "type": "structure",
                      "members": {
                        "OutputUrl": {}
                      }
                    }
                  }
                },
                "AssociationName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstanceInformation": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceInformationFilterList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "key",
                "valueSet"
              ],
              "members": {
                "key": {},
                "valueSet": {
                  "shape": "Sac"
                }
              }
            }
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "shape": "Sac"
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceInformationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceId": {},
                "PingStatus": {},
                "LastPingDateTime": {
                  "type": "timestamp"
                },
                "AgentVersion": {},
                "IsLatestVersion": {
                  "type": "boolean"
                },
                "PlatformType": {},
                "PlatformName": {},
                "PlatformVersion": {},
                "ActivationId": {},
                "IamRole": {},
                "RegistrationDate": {
                  "type": "timestamp"
                },
                "ResourceType": {},
                "Name": {},
                "IPAddress": {},
                "ComputerName": {},
                "AssociationStatus": {},
                "LastAssociationExecutionDate": {
                  "type": "timestamp"
                },
                "LastSuccessfulAssociationExecutionDate": {
                  "type": "timestamp"
                },
                "AssociationOverview": {
                  "type": "structure",
                  "members": {
                    "DetailedStatus": {},
                    "InstanceAssociationStatusAggregatedCount": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatchStates": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sb"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstancePatchStates": {
            "type": "list",
            "member": {
              "shape": "Saw"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatchStatesForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values",
                "Type"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                },
                "Type": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstancePatchStates": {
            "type": "list",
            "member": {
              "shape": "Saw"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatches": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "Filters": {
            "shape": "S8u"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Patches": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Title",
                "KBId",
                "Classification",
                "Severity",
                "State",
                "InstalledTime"
              ],
              "members": {
                "Title": {},
                "KBId": {},
                "Classification": {},
                "Severity": {},
                "State": {},
                "InstalledTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInventoryDeletions": {
      "input": {
        "type": "structure",
        "members": {
          "DeletionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InventoryDeletions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DeletionId": {},
                "TypeName": {},
                "DeletionStartTime": {
                  "type": "timestamp"
                },
                "LastStatus": {},
                "LastStatusMessage": {},
                "DeletionSummary": {
                  "shape": "S5m"
                },
                "LastStatusUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutionTaskInvocations": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId",
          "TaskId"
        ],
        "members": {
          "WindowExecutionId": {},
          "TaskId": {},
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionTaskInvocationIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowExecutionId": {},
                "TaskExecutionId": {},
                "InvocationId": {},
                "ExecutionId": {},
                "TaskType": {},
                "Parameters": {
                  "shape": "Scb"
                },
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "OwnerInformation": {
                  "shape": "Saz"
                },
                "WindowTargetId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutionTasks": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId"
        ],
        "members": {
          "WindowExecutionId": {},
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionTaskIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowExecutionId": {},
                "TaskExecutionId": {},
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "TaskArn": {},
                "TaskType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowExecutionId": {},
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowSchedule": {
      "input": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "Targets": {
            "shape": "Sx"
          },
          "ResourceType": {},
          "Filters": {
            "shape": "S8u"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledWindowExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "Name": {},
                "ExecutionTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowTargets": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowTargetId": {},
                "ResourceType": {},
                "Targets": {
                  "shape": "Sx"
                },
                "OwnerInformation": {
                  "shape": "Saz"
                },
                "Name": {},
                "Description": {
                  "shape": "S33"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowTasks": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowTaskId": {},
                "TaskArn": {},
                "Type": {},
                "Targets": {
                  "shape": "Sx"
                },
                "TaskParameters": {
                  "shape": "Sd2"
                },
                "Priority": {
                  "type": "integer"
                },
                "LoggingInfo": {
                  "shape": "Sd8"
                },
                "ServiceRoleArn": {},
                "MaxConcurrency": {},
                "MaxErrors": {},
                "Name": {},
                "Description": {
                  "shape": "S33"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindows": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sbz"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "Name": {},
                "Description": {
                  "shape": "S33"
                },
                "Enabled": {
                  "type": "boolean"
                },
                "Duration": {
                  "type": "integer"
                },
                "Cutoff": {
                  "type": "integer"
                },
                "Schedule": {},
                "ScheduleTimezone": {},
                "ScheduleOffset": {
                  "type": "integer"
                },
                "EndDate": {},
                "StartDate": {},
                "NextExecutionTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowsForTarget": {
      "input": {
        "type": "structure",
        "required": [
          "Targets",
          "ResourceType"
        ],
        "members": {
          "Targets": {
            "shape": "Sx"
          },
          "ResourceType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOpsItems": {
      "input": {
        "type": "structure",
        "members": {
          "OpsItemFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values",
                "Operator"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                },
                "Operator": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "OpsItemSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CreatedBy": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "Priority": {
                  "type": "integer"
                },
                "Source": {},
                "Status": {},
                "OpsItemId": {},
                "Title": {},
                "OperationalData": {
                  "shape": "S3g"
                },
                "Category": {},
                "Severity": {}
              }
            }
          }
        }
      }
    },
    "DescribeParameters": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ParameterFilters": {
            "shape": "Se2"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "KeyId": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedUser": {},
                "Description": {},
                "AllowedPattern": {},
                "Version": {
                  "type": "long"
                },
                "Tier": {},
                "Policies": {
                  "shape": "Seh"
                },
                "DataType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePatchBaselines": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S8u"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineIdentities": {
            "type": "list",
            "member": {
              "shape": "Sen"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePatchGroupState": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "integer"
          },
          "InstancesWithInstalledPatches": {
            "type": "integer"
          },
          "InstancesWithInstalledOtherPatches": {
            "type": "integer"
          },
          "InstancesWithInstalledPendingRebootPatches": {
            "type": "integer"
          },
          "InstancesWithInstalledRejectedPatches": {
            "type": "integer"
          },
          "InstancesWithMissingPatches": {
            "type": "integer"
          },
          "InstancesWithFailedPatches": {
            "type": "integer"
          },
          "InstancesWithNotApplicablePatches": {
            "type": "integer"
          },
          "InstancesWithUnreportedNotApplicablePatches": {
            "type": "integer"
          }
        }
      }
    },
    "DescribePatchGroups": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S8u"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Mappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PatchGroup": {},
                "BaselineIdentity": {
                  "shape": "Sen"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePatchProperties": {
      "input": {
        "type": "structure",
        "required": [
          "OperatingSystem",
          "Property"
        ],
        "members": {
          "OperatingSystem": {},
          "Property": {},
          "PatchSet": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Properties": {
            "type": "list",
            "member": {
              "type": "map",
              "key": {},
              "value": {}
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeSessions": {
      "input": {
        "type": "structure",
        "required": [
          "State"
        ],
        "members": {
          "State": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "key",
                "value"
              ],
              "members": {
                "key": {},
                "value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sessions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SessionId": {},
                "Target": {},
                "Status": {},
                "StartDate": {
                  "type": "timestamp"
                },
                "EndDate": {
                  "type": "timestamp"
                },
                "DocumentName": {},
                "Owner": {},
                "Details": {},
                "OutputUrl": {
                  "type": "structure",
                  "members": {
                    "S3OutputUrl": {},
                    "CloudWatchOutputUrl": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId"
        ],
        "members": {
          "AutomationExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecution": {
            "type": "structure",
            "members": {
              "AutomationExecutionId": {},
              "DocumentName": {},
              "DocumentVersion": {},
              "ExecutionStartTime": {
                "type": "timestamp"
              },
              "ExecutionEndTime": {
                "type": "timestamp"
              },
              "AutomationExecutionStatus": {},
              "StepExecutions": {
                "shape": "S8e"
              },
              "StepExecutionsTruncated": {
                "type": "boolean"
              },
              "Parameters": {
                "shape": "S7u"
              },
              "Outputs": {
                "shape": "S7u"
              },
              "FailureMessage": {},
              "Mode": {},
              "ParentAutomationExecutionId": {},
              "ExecutedBy": {},
              "CurrentStepName": {},
              "CurrentAction": {},
              "TargetParameterName": {},
              "Targets": {
                "shape": "Sx"
              },
              "TargetMaps": {
                "shape": "S7z"
              },
              "ResolvedTargets": {
                "shape": "S84"
              },
              "MaxConcurrency": {},
              "MaxErrors": {},
              "Target": {},
              "TargetLocations": {
                "shape": "Sfp"
              },
              "ProgressCounters": {
                "type": "structure",
                "members": {
                  "TotalSteps": {
                    "type": "integer"
                  },
                  "SuccessSteps": {
                    "type": "integer"
                  },
                  "FailedSteps": {
                    "type": "integer"
                  },
                  "CancelledSteps": {
                    "type": "integer"
                  },
                  "TimedOutSteps": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetCalendarState": {
      "input": {
        "type": "structure",
        "required": [
          "CalendarNames"
        ],
        "members": {
          "CalendarNames": {
            "type": "list",
            "member": {}
          },
          "AtTime": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "State": {},
          "AtTime": {},
          "NextTransitionTime": {}
        }
      }
    },
    "GetCommandInvocation": {
      "input": {
        "type": "structure",
        "required": [
          "CommandId",
          "InstanceId"
        ],
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "PluginName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "Comment": {},
          "DocumentName": {},
          "DocumentVersion": {},
          "PluginName": {},
          "ResponseCode": {
            "type": "integer"
          },
          "ExecutionStartDateTime": {},
          "ExecutionElapsedTime": {},
          "ExecutionEndDateTime": {},
          "Status": {},
          "StatusDetails": {},
          "StandardOutputContent": {},
          "StandardOutputUrl": {},
          "StandardErrorContent": {},
          "StandardErrorUrl": {},
          "CloudWatchOutputConfig": {
            "shape": "Sg7"
          }
        }
      }
    },
    "GetConnectionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Target"
        ],
        "members": {
          "Target": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Target": {},
          "Status": {}
        }
      }
    },
    "GetDefaultPatchBaseline": {
      "input": {
        "type": "structure",
        "members": {
          "OperatingSystem": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "OperatingSystem": {}
        }
      }
    },
    "GetDeployablePatchSnapshotForInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "SnapshotId"
        ],
        "members": {
          "InstanceId": {},
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "SnapshotId": {},
          "SnapshotDownloadUrl": {},
          "Product": {}
        }
      }
    },
    "GetDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "VersionName": {},
          "DocumentVersion": {},
          "DocumentFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "VersionName": {},
          "DocumentVersion": {},
          "Status": {},
          "StatusInformation": {},
          "Content": {},
          "DocumentType": {},
          "DocumentFormat": {},
          "Requires": {
            "shape": "S23"
          },
          "AttachmentsContent": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Size": {
                  "type": "long"
                },
                "Hash": {},
                "HashType": {},
                "Url": {}
              }
            }
          }
        }
      }
    },
    "GetInventory": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sgs"
          },
          "Aggregators": {
            "shape": "Sgy"
          },
          "ResultAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TypeName"
              ],
              "members": {
                "TypeName": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Data": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "structure",
                    "required": [
                      "TypeName",
                      "SchemaVersion",
                      "Content"
                    ],
                    "members": {
                      "TypeName": {},
                      "SchemaVersion": {},
                      "CaptureTime": {},
                      "ContentHash": {},
                      "Content": {
                        "shape": "Shf"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetInventorySchema": {
      "input": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Aggregator": {
            "type": "boolean"
          },
          "SubType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TypeName",
                "Attributes"
              ],
              "members": {
                "TypeName": {},
                "Version": {},
                "Attributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Name",
                      "DataType"
                    ],
                    "members": {
                      "Name": {},
                      "DataType": {}
                    }
                  }
                },
                "DisplayName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "StartDate": {},
          "EndDate": {},
          "Schedule": {},
          "ScheduleTimezone": {},
          "ScheduleOffset": {
            "type": "integer"
          },
          "NextExecutionTime": {},
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          },
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMaintenanceWindowExecution": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId"
        ],
        "members": {
          "WindowExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {},
          "TaskIds": {
            "type": "list",
            "member": {}
          },
          "Status": {},
          "StatusDetails": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMaintenanceWindowExecutionTask": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId",
          "TaskId"
        ],
        "members": {
          "WindowExecutionId": {},
          "TaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {},
          "TaskExecutionId": {},
          "TaskArn": {},
          "ServiceRole": {},
          "Type": {},
          "TaskParameters": {
            "type": "list",
            "member": {
              "shape": "Sd2"
            },
            "sensitive": true
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "Status": {},
          "StatusDetails": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMaintenanceWindowExecutionTaskInvocation": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId",
          "TaskId",
          "InvocationId"
        ],
        "members": {
          "WindowExecutionId": {},
          "TaskId": {},
          "InvocationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {},
          "TaskExecutionId": {},
          "InvocationId": {},
          "ExecutionId": {},
          "TaskType": {},
          "Parameters": {
            "shape": "Scb"
          },
          "Status": {},
          "StatusDetails": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "OwnerInformation": {
            "shape": "Saz"
          },
          "WindowTargetId": {}
        }
      }
    },
    "GetMaintenanceWindowTask": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTaskId"
        ],
        "members": {
          "WindowId": {},
          "WindowTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTaskId": {},
          "Targets": {
            "shape": "Sx"
          },
          "TaskArn": {},
          "ServiceRoleArn": {},
          "TaskType": {},
          "TaskParameters": {
            "shape": "Sd2"
          },
          "TaskInvocationParameters": {
            "shape": "Si6"
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "LoggingInfo": {
            "shape": "Sd8"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          }
        }
      }
    },
    "GetOpsItem": {
      "input": {
        "type": "structure",
        "required": [
          "OpsItemId"
        ],
        "members": {
          "OpsItemId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OpsItem": {
            "type": "structure",
            "members": {
              "CreatedBy": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "Description": {},
              "LastModifiedBy": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "Notifications": {
                "shape": "S3l"
              },
              "Priority": {
                "type": "integer"
              },
              "RelatedOpsItems": {
                "shape": "S3p"
              },
              "Status": {},
              "OpsItemId": {},
              "Version": {},
              "Title": {},
              "Source": {},
              "OperationalData": {
                "shape": "S3g"
              },
              "Category": {},
              "Severity": {}
            }
          }
        }
      }
    },
    "GetOpsSummary": {
      "input": {
        "type": "structure",
        "members": {
          "SyncName": {},
          "Filters": {
            "shape": "Siq"
          },
          "Aggregators": {
            "shape": "Siw"
          },
          "ResultAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TypeName"
              ],
              "members": {
                "TypeName": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Data": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "structure",
                    "members": {
                      "CaptureTime": {},
                      "Content": {
                        "type": "list",
                        "member": {
                          "type": "map",
                          "key": {},
                          "value": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetParameter": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "WithDecryption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameter": {
            "shape": "Sji"
          }
        }
      }
    },
    "GetParameterHistory": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "WithDecryption": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "KeyId": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedUser": {},
                "Description": {},
                "Value": {},
                "AllowedPattern": {},
                "Version": {
                  "type": "long"
                },
                "Labels": {
                  "shape": "Sjp"
                },
                "Tier": {},
                "Policies": {
                  "shape": "Seh"
                },
                "DataType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetParameters": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "S5z"
          },
          "WithDecryption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "Sjt"
          },
          "InvalidParameters": {
            "shape": "S5z"
          }
        }
      }
    },
    "GetParametersByPath": {
      "input": {
        "type": "structure",
        "required": [
          "Path"
        ],
        "members": {
          "Path": {},
          "Recursive": {
            "type": "boolean"
          },
          "ParameterFilters": {
            "shape": "Se2"
          },
          "WithDecryption": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "Sjt"
          },
          "NextToken": {}
        }
      }
    },
    "GetPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "Name": {},
          "OperatingSystem": {},
          "GlobalFilters": {
            "shape": "S3z"
          },
          "ApprovalRules": {
            "shape": "S45"
          },
          "ApprovedPatches": {
            "shape": "S4c"
          },
          "ApprovedPatchesComplianceLevel": {},
          "ApprovedPatchesEnableNonSecurity": {
            "type": "boolean"
          },
          "RejectedPatches": {
            "shape": "S4c"
          },
          "RejectedPatchesAction": {},
          "PatchGroups": {
            "type": "list",
            "member": {}
          },
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          },
          "Description": {},
          "Sources": {
            "shape": "S4g"
          }
        }
      }
    },
    "GetPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {},
          "OperatingSystem": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {},
          "OperatingSystem": {}
        }
      }
    },
    "GetServiceSetting": {
      "input": {
        "type": "structure",
        "required": [
          "SettingId"
        ],
        "members": {
          "SettingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSetting": {
            "shape": "Sk5"
          }
        }
      }
    },
    "LabelParameterVersion": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Labels"
        ],
        "members": {
          "Name": {},
          "ParameterVersion": {
            "type": "long"
          },
          "Labels": {
            "shape": "Sjp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InvalidLabels": {
            "shape": "Sjp"
          },
          "ParameterVersion": {
            "type": "long"
          }
        }
      }
    },
    "ListAssociationVersions": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "AssociationVersion": {},
                "CreatedDate": {
                  "type": "timestamp"
                },
                "Name": {},
                "DocumentVersion": {},
                "Parameters": {
                  "shape": "St"
                },
                "Targets": {
                  "shape": "Sx"
                },
                "ScheduleExpression": {},
                "OutputLocation": {
                  "shape": "S13"
                },
                "AssociationName": {},
                "MaxErrors": {},
                "MaxConcurrency": {},
                "ComplianceSeverity": {},
                "SyncCompliance": {},
                "ApplyOnlyAtCronInterval": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationFilterList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "key",
                "value"
              ],
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "InstanceId": {},
                "AssociationId": {},
                "AssociationVersion": {},
                "DocumentVersion": {},
                "Targets": {
                  "shape": "Sx"
                },
                "LastExecutionDate": {
                  "type": "timestamp"
                },
                "Overview": {
                  "shape": "S1n"
                },
                "ScheduleExpression": {},
                "AssociationName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCommandInvocations": {
      "input": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Skn"
          },
          "Details": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CommandInvocations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CommandId": {},
                "InstanceId": {},
                "InstanceName": {},
                "Comment": {},
                "DocumentName": {},
                "DocumentVersion": {},
                "RequestedDateTime": {
                  "type": "timestamp"
                },
                "Status": {},
                "StatusDetails": {},
                "TraceOutput": {},
                "StandardOutputUrl": {},
                "StandardErrorUrl": {},
                "CommandPlugins": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Status": {},
                      "StatusDetails": {},
                      "ResponseCode": {
                        "type": "integer"
                      },
                      "ResponseStartDateTime": {
                        "type": "timestamp"
                      },
                      "ResponseFinishDateTime": {
                        "type": "timestamp"
                      },
                      "Output": {},
                      "StandardOutputUrl": {},
                      "StandardErrorUrl": {},
                      "OutputS3Region": {},
                      "OutputS3BucketName": {},
                      "OutputS3KeyPrefix": {}
                    }
                  }
                },
                "ServiceRole": {},
                "NotificationConfig": {
                  "shape": "Si8"
                },
                "CloudWatchOutputConfig": {
                  "shape": "Sg7"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCommands": {
      "input": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Skn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Commands": {
            "type": "list",
            "member": {
              "shape": "Sl3"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListComplianceItems": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sla"
          },
          "ResourceIds": {
            "type": "list",
            "member": {}
          },
          "ResourceTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ComplianceType": {},
                "ResourceType": {},
                "ResourceId": {},
                "Id": {},
                "Title": {},
                "Status": {},
                "Severity": {},
                "ExecutionSummary": {
                  "shape": "Sls"
                },
                "Details": {
                  "shape": "Slv"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListComplianceSummaries": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sla"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ComplianceType": {},
                "CompliantSummary": {
                  "shape": "Sm0"
                },
                "NonCompliantSummary": {
                  "shape": "Sm3"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentVersions": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "DocumentVersion": {},
                "VersionName": {},
                "CreatedDate": {
                  "type": "timestamp"
                },
                "IsDefaultVersion": {
                  "type": "boolean"
                },
                "DocumentFormat": {},
                "Status": {},
                "StatusInformation": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocuments": {
      "input": {
        "type": "structure",
        "members": {
          "DocumentFilterList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "key",
                "value"
              ],
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Owner": {},
                "VersionName": {},
                "PlatformTypes": {
                  "shape": "S2v"
                },
                "DocumentVersion": {},
                "DocumentType": {},
                "SchemaVersion": {},
                "DocumentFormat": {},
                "TargetType": {},
                "Tags": {
                  "shape": "S4"
                },
                "Requires": {
                  "shape": "S23"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInventoryEntries": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "TypeName"
        ],
        "members": {
          "InstanceId": {},
          "TypeName": {},
          "Filters": {
            "shape": "Sgs"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "InstanceId": {},
          "SchemaVersion": {},
          "CaptureTime": {},
          "Entries": {
            "shape": "Shf"
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceComplianceSummaries": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sla"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceComplianceSummaryItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ComplianceType": {},
                "ResourceType": {},
                "ResourceId": {},
                "Status": {},
                "OverallSeverity": {},
                "ExecutionSummary": {
                  "shape": "Sls"
                },
                "CompliantSummary": {
                  "shape": "Sm0"
                },
                "NonCompliantSummary": {
                  "shape": "Sm3"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceDataSync": {
      "input": {
        "type": "structure",
        "members": {
          "SyncType": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceDataSyncItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SyncName": {},
                "SyncType": {},
                "SyncSource": {
                  "type": "structure",
                  "members": {
                    "SourceType": {},
                    "AwsOrganizationsSource": {
                      "shape": "S51"
                    },
                    "SourceRegions": {
                      "shape": "S56"
                    },
                    "IncludeFutureRegions": {
                      "type": "boolean"
                    },
                    "State": {}
                  }
                },
                "S3Destination": {
                  "shape": "S4q"
                },
                "LastSyncTime": {
                  "type": "timestamp"
                },
                "LastSuccessfulSyncTime": {
                  "type": "timestamp"
                },
                "SyncLastModifiedTime": {
                  "type": "timestamp"
                },
                "LastStatus": {},
                "SyncCreatedTime": {
                  "type": "timestamp"
                },
                "LastSyncStatusMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyDocumentPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PermissionType"
        ],
        "members": {
          "Name": {},
          "PermissionType": {},
          "AccountIdsToAdd": {
            "shape": "S9j"
          },
          "AccountIdsToRemove": {
            "shape": "S9j"
          },
          "SharedDocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutComplianceItems": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "ResourceType",
          "ComplianceType",
          "ExecutionSummary",
          "Items"
        ],
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "ComplianceType": {},
          "ExecutionSummary": {
            "shape": "Sls"
          },
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Severity",
                "Status"
              ],
              "members": {
                "Id": {},
                "Title": {},
                "Severity": {},
                "Status": {},
                "Details": {
                  "shape": "Slv"
                }
              }
            }
          },
          "ItemContentHash": {},
          "UploadType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutInventory": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Items"
        ],
        "members": {
          "InstanceId": {},
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TypeName",
                "SchemaVersion",
                "CaptureTime"
              ],
              "members": {
                "TypeName": {},
                "SchemaVersion": {},
                "CaptureTime": {},
                "ContentHash": {},
                "Content": {
                  "shape": "Shf"
                },
                "Context": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Message": {}
        }
      }
    },
    "PutParameter": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Value": {},
          "Type": {},
          "KeyId": {},
          "Overwrite": {
            "type": "boolean"
          },
          "AllowedPattern": {},
          "Tags": {
            "shape": "S4"
          },
          "Tier": {},
          "Policies": {},
          "DataType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Version": {
            "type": "long"
          },
          "Tier": {}
        }
      }
    },
    "RegisterDefaultPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "RegisterPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId",
          "PatchGroup"
        ],
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      }
    },
    "RegisterTargetWithMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "ResourceType",
          "Targets"
        ],
        "members": {
          "WindowId": {},
          "ResourceType": {},
          "Targets": {
            "shape": "Sx"
          },
          "OwnerInformation": {
            "shape": "Saz"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowTargetId": {}
        }
      }
    },
    "RegisterTaskWithMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "Targets",
          "TaskArn",
          "TaskType",
          "MaxConcurrency",
          "MaxErrors"
        ],
        "members": {
          "WindowId": {},
          "Targets": {
            "shape": "Sx"
          },
          "TaskArn": {},
          "ServiceRoleArn": {},
          "TaskType": {},
          "TaskParameters": {
            "shape": "Sd2"
          },
          "TaskInvocationParameters": {
            "shape": "Si6"
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "LoggingInfo": {
            "shape": "Sd8"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowTaskId": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ResetServiceSetting": {
      "input": {
        "type": "structure",
        "required": [
          "SettingId"
        ],
        "members": {
          "SettingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSetting": {
            "shape": "Sk5"
          }
        }
      }
    },
    "ResumeSession": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId"
        ],
        "members": {
          "SessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SessionId": {},
          "TokenValue": {},
          "StreamUrl": {}
        }
      }
    },
    "SendAutomationSignal": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId",
          "SignalType"
        ],
        "members": {
          "AutomationExecutionId": {},
          "SignalType": {},
          "Payload": {
            "shape": "S7u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SendCommand": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sb"
          },
          "Targets": {
            "shape": "Sx"
          },
          "DocumentName": {},
          "DocumentVersion": {},
          "DocumentHash": {},
          "DocumentHashType": {},
          "TimeoutSeconds": {
            "type": "integer"
          },
          "Comment": {},
          "Parameters": {
            "shape": "St"
          },
          "OutputS3Region": {},
          "OutputS3BucketName": {},
          "OutputS3KeyPrefix": {},
          "MaxConcurrency": {},
          "MaxErrors": {},
          "ServiceRoleArn": {},
          "NotificationConfig": {
            "shape": "Si8"
          },
          "CloudWatchOutputConfig": {
            "shape": "Sg7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Command": {
            "shape": "Sl3"
          }
        }
      }
    },
    "StartAssociationsOnce": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationIds"
        ],
        "members": {
          "AssociationIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentName"
        ],
        "members": {
          "DocumentName": {},
          "DocumentVersion": {},
          "Parameters": {
            "shape": "S7u"
          },
          "ClientToken": {},
          "Mode": {},
          "TargetParameterName": {},
          "Targets": {
            "shape": "Sx"
          },
          "TargetMaps": {
            "shape": "S7z"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "TargetLocations": {
            "shape": "Sfp"
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecutionId": {}
        }
      }
    },
    "StartSession": {
      "input": {
        "type": "structure",
        "required": [
          "Target"
        ],
        "members": {
          "Target": {},
          "DocumentName": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SessionId": {},
          "TokenValue": {},
          "StreamUrl": {}
        }
      }
    },
    "StopAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId"
        ],
        "members": {
          "AutomationExecutionId": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TerminateSession": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId"
        ],
        "members": {
          "SessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SessionId": {}
        }
      }
    },
    "UpdateAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "Parameters": {
            "shape": "St"
          },
          "DocumentVersion": {},
          "ScheduleExpression": {},
          "OutputLocation": {
            "shape": "S13"
          },
          "Name": {},
          "Targets": {
            "shape": "Sx"
          },
          "AssociationName": {},
          "AssociationVersion": {},
          "AutomationTargetParameterName": {},
          "MaxErrors": {},
          "MaxConcurrency": {},
          "ComplianceSeverity": {},
          "SyncCompliance": {},
          "ApplyOnlyAtCronInterval": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S1g"
          }
        }
      }
    },
    "UpdateAssociationStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InstanceId",
          "AssociationStatus"
        ],
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationStatus": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S1g"
          }
        }
      }
    },
    "UpdateDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Content",
          "Name"
        ],
        "members": {
          "Content": {},
          "Attachments": {
            "shape": "S25"
          },
          "Name": {},
          "VersionName": {},
          "DocumentVersion": {},
          "DocumentFormat": {},
          "TargetType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentDescription": {
            "shape": "S2h"
          }
        }
      }
    },
    "UpdateDocumentDefaultVersion": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DocumentVersion"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "type": "structure",
            "members": {
              "Name": {},
              "DefaultVersion": {},
              "DefaultVersionName": {}
            }
          }
        }
      }
    },
    "UpdateMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "StartDate": {},
          "EndDate": {},
          "Schedule": {},
          "ScheduleTimezone": {},
          "ScheduleOffset": {
            "type": "integer"
          },
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Replace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "StartDate": {},
          "EndDate": {},
          "Schedule": {},
          "ScheduleTimezone": {},
          "ScheduleOffset": {
            "type": "integer"
          },
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateMaintenanceWindowTarget": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTargetId"
        ],
        "members": {
          "WindowId": {},
          "WindowTargetId": {},
          "Targets": {
            "shape": "Sx"
          },
          "OwnerInformation": {
            "shape": "Saz"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "Replace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTargetId": {},
          "Targets": {
            "shape": "Sx"
          },
          "OwnerInformation": {
            "shape": "Saz"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          }
        }
      }
    },
    "UpdateMaintenanceWindowTask": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTaskId"
        ],
        "members": {
          "WindowId": {},
          "WindowTaskId": {},
          "Targets": {
            "shape": "Sx"
          },
          "TaskArn": {},
          "ServiceRoleArn": {},
          "TaskParameters": {
            "shape": "Sd2"
          },
          "TaskInvocationParameters": {
            "shape": "Si6"
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "LoggingInfo": {
            "shape": "Sd8"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          },
          "Replace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTaskId": {},
          "Targets": {
            "shape": "Sx"
          },
          "TaskArn": {},
          "ServiceRoleArn": {},
          "TaskParameters": {
            "shape": "Sd2"
          },
          "TaskInvocationParameters": {
            "shape": "Si6"
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "LoggingInfo": {
            "shape": "Sd8"
          },
          "Name": {},
          "Description": {
            "shape": "S33"
          }
        }
      }
    },
    "UpdateManagedInstanceRole": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IamRole"
        ],
        "members": {
          "InstanceId": {},
          "IamRole": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateOpsItem": {
      "input": {
        "type": "structure",
        "required": [
          "OpsItemId"
        ],
        "members": {
          "Description": {},
          "OperationalData": {
            "shape": "S3g"
          },
          "OperationalDataToDelete": {
            "type": "list",
            "member": {}
          },
          "Notifications": {
            "shape": "S3l"
          },
          "Priority": {
            "type": "integer"
          },
          "RelatedOpsItems": {
            "shape": "S3p"
          },
          "Status": {},
          "OpsItemId": {},
          "Title": {},
          "Category": {},
          "Severity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {},
          "Name": {},
          "GlobalFilters": {
            "shape": "S3z"
          },
          "ApprovalRules": {
            "shape": "S45"
          },
          "ApprovedPatches": {
            "shape": "S4c"
          },
          "ApprovedPatchesComplianceLevel": {},
          "ApprovedPatchesEnableNonSecurity": {
            "type": "boolean"
          },
          "RejectedPatches": {
            "shape": "S4c"
          },
          "RejectedPatchesAction": {},
          "Description": {},
          "Sources": {
            "shape": "S4g"
          },
          "Replace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "Name": {},
          "OperatingSystem": {},
          "GlobalFilters": {
            "shape": "S3z"
          },
          "ApprovalRules": {
            "shape": "S45"
          },
          "ApprovedPatches": {
            "shape": "S4c"
          },
          "ApprovedPatchesComplianceLevel": {},
          "ApprovedPatchesEnableNonSecurity": {
            "type": "boolean"
          },
          "RejectedPatches": {
            "shape": "S4c"
          },
          "RejectedPatchesAction": {},
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          },
          "Description": {},
          "Sources": {
            "shape": "S4g"
          }
        }
      }
    },
    "UpdateResourceDataSync": {
      "input": {
        "type": "structure",
        "required": [
          "SyncName",
          "SyncType",
          "SyncSource"
        ],
        "members": {
          "SyncName": {},
          "SyncType": {},
          "SyncSource": {
            "shape": "S4z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateServiceSetting": {
      "input": {
        "type": "structure",
        "required": [
          "SettingId",
          "SettingValue"
        ],
        "members": {
          "SettingId": {},
          "SettingValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "S3Location": {
          "type": "structure",
          "members": {
            "OutputS3Region": {},
            "OutputS3BucketName": {},
            "OutputS3KeyPrefix": {}
          }
        }
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "Name": {},
        "InstanceId": {},
        "AssociationVersion": {},
        "Date": {
          "type": "timestamp"
        },
        "LastUpdateAssociationDate": {
          "type": "timestamp"
        },
        "Status": {
          "shape": "S1j"
        },
        "Overview": {
          "shape": "S1n"
        },
        "DocumentVersion": {},
        "AutomationTargetParameterName": {},
        "Parameters": {
          "shape": "St"
        },
        "AssociationId": {},
        "Targets": {
          "shape": "Sx"
        },
        "ScheduleExpression": {},
        "OutputLocation": {
          "shape": "S13"
        },
        "LastExecutionDate": {
          "type": "timestamp"
        },
        "LastSuccessfulExecutionDate": {
          "type": "timestamp"
        },
        "AssociationName": {},
        "MaxErrors": {},
        "MaxConcurrency": {},
        "ComplianceSeverity": {},
        "SyncCompliance": {},
        "ApplyOnlyAtCronInterval": {
          "type": "boolean"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "Date",
        "Name",
        "Message"
      ],
      "members": {
        "Date": {
          "type": "timestamp"
        },
        "Name": {},
        "Message": {},
        "AdditionalInfo": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Status": {},
        "DetailedStatus": {},
        "AssociationStatusAggregatedCount": {
          "type": "map",
          "key": {},
          "value": {
            "type": "integer"
          }
        }
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "InstanceId": {},
        "Parameters": {
          "shape": "St"
        },
        "AutomationTargetParameterName": {},
        "DocumentVersion": {},
        "Targets": {
          "shape": "Sx"
        },
        "ScheduleExpression": {},
        "OutputLocation": {
          "shape": "S13"
        },
        "AssociationName": {},
        "MaxErrors": {},
        "MaxConcurrency": {},
        "ComplianceSeverity": {},
        "SyncCompliance": {},
        "ApplyOnlyAtCronInterval": {
          "type": "boolean"
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Version": {}
        }
      }
    },
    "S25": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          },
          "Name": {}
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "Sha1": {},
        "Hash": {},
        "HashType": {},
        "Name": {},
        "VersionName": {},
        "Owner": {},
        "CreatedDate": {
          "type": "timestamp"
        },
        "Status": {},
        "StatusInformation": {},
        "DocumentVersion": {},
        "Description": {},
        "Parameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {},
              "Type": {},
              "Description": {},
              "DefaultValue": {}
            }
          }
        },
        "PlatformTypes": {
          "shape": "S2v"
        },
        "DocumentType": {},
        "SchemaVersion": {},
        "LatestVersion": {},
        "DefaultVersion": {},
        "DocumentFormat": {},
        "TargetType": {},
        "Tags": {
          "shape": "S4"
        },
        "AttachmentsInformation": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {}
            }
          }
        },
        "Requires": {
          "shape": "S23"
        }
      }
    },
    "S2v": {
      "type": "list",
      "member": {}
    },
    "S33": {
      "type": "string",
      "sensitive": true
    },
    "S3g": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {},
          "Type": {}
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {}
        }
      }
    },
    "S3p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "OpsItemId"
        ],
        "members": {
          "OpsItemId": {}
        }
      }
    },
    "S3z": {
      "type": "structure",
      "required": [
        "PatchFilters"
      ],
      "members": {
        "PatchFilters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "Values"
            ],
            "members": {
              "Key": {},
              "Values": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S45": {
      "type": "structure",
      "required": [
        "PatchRules"
      ],
      "members": {
        "PatchRules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "PatchFilterGroup"
            ],
            "members": {
              "PatchFilterGroup": {
                "shape": "S3z"
              },
              "ComplianceLevel": {},
              "ApproveAfterDays": {
                "type": "integer"
              },
              "ApproveUntilDate": {},
              "EnableNonSecurity": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "S4c": {
      "type": "list",
      "member": {}
    },
    "S4g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Products",
          "Configuration"
        ],
        "members": {
          "Name": {},
          "Products": {
            "type": "list",
            "member": {}
          },
          "Configuration": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S4q": {
      "type": "structure",
      "required": [
        "BucketName",
        "SyncFormat",
        "Region"
      ],
      "members": {
        "BucketName": {},
        "Prefix": {},
        "SyncFormat": {},
        "Region": {},
        "AWSKMSKeyARN": {},
        "DestinationDataSharing": {
          "type": "structure",
          "members": {
            "DestinationDataSharingType": {}
          }
        }
      }
    },
    "S4z": {
      "type": "structure",
      "required": [
        "SourceType",
        "SourceRegions"
      ],
      "members": {
        "SourceType": {},
        "AwsOrganizationsSource": {
          "shape": "S51"
        },
        "SourceRegions": {
          "shape": "S56"
        },
        "IncludeFutureRegions": {
          "type": "boolean"
        }
      }
    },
    "S51": {
      "type": "structure",
      "required": [
        "OrganizationSourceType"
      ],
      "members": {
        "OrganizationSourceType": {},
        "OrganizationalUnits": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "OrganizationalUnitId": {}
            }
          }
        }
      }
    },
    "S56": {
      "type": "list",
      "member": {}
    },
    "S5m": {
      "type": "structure",
      "members": {
        "TotalCount": {
          "type": "integer"
        },
        "RemainingCount": {
          "type": "integer"
        },
        "SummaryItems": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Version": {},
              "Count": {
                "type": "integer"
              },
              "RemainingCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S5z": {
      "type": "list",
      "member": {}
    },
    "S7u": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "S7z": {
      "type": "list",
      "member": {
        "type": "map",
        "key": {},
        "value": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S84": {
      "type": "structure",
      "members": {
        "ParameterValues": {
          "type": "list",
          "member": {}
        },
        "Truncated": {
          "type": "boolean"
        }
      }
    },
    "S8e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StepName": {},
          "Action": {},
          "TimeoutSeconds": {
            "type": "long"
          },
          "OnFailure": {},
          "MaxAttempts": {
            "type": "integer"
          },
          "ExecutionStartTime": {
            "type": "timestamp"
          },
          "ExecutionEndTime": {
            "type": "timestamp"
          },
          "StepStatus": {},
          "ResponseCode": {},
          "Inputs": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "Outputs": {
            "shape": "S7u"
          },
          "Response": {},
          "FailureMessage": {},
          "FailureDetails": {
            "type": "structure",
            "members": {
              "FailureStage": {},
              "FailureType": {},
              "Details": {
                "shape": "S7u"
              }
            }
          },
          "StepExecutionId": {},
          "OverriddenParameters": {
            "shape": "S7u"
          },
          "IsEnd": {
            "type": "boolean"
          },
          "NextStep": {},
          "IsCritical": {
            "type": "boolean"
          },
          "ValidNextSteps": {
            "type": "list",
            "member": {}
          },
          "Targets": {
            "shape": "Sx"
          },
          "TargetLocation": {
            "shape": "S8n"
          }
        }
      }
    },
    "S8n": {
      "type": "structure",
      "members": {
        "Accounts": {
          "type": "list",
          "member": {}
        },
        "Regions": {
          "type": "list",
          "member": {}
        },
        "TargetLocationMaxConcurrency": {},
        "TargetLocationMaxErrors": {},
        "ExecutionRoleName": {}
      }
    },
    "S8u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S92": {
      "type": "structure",
      "members": {
        "Id": {},
        "ReleaseDate": {
          "type": "timestamp"
        },
        "Title": {},
        "Description": {},
        "ContentUrl": {},
        "Vendor": {},
        "ProductFamily": {},
        "Product": {},
        "Classification": {},
        "MsrcSeverity": {},
        "KbNumber": {},
        "MsrcNumber": {},
        "Language": {}
      }
    },
    "S9j": {
      "type": "list",
      "member": {}
    },
    "Sac": {
      "type": "list",
      "member": {}
    },
    "Saw": {
      "type": "structure",
      "required": [
        "InstanceId",
        "PatchGroup",
        "BaselineId",
        "OperationStartTime",
        "OperationEndTime",
        "Operation"
      ],
      "members": {
        "InstanceId": {},
        "PatchGroup": {},
        "BaselineId": {},
        "SnapshotId": {},
        "InstallOverrideList": {},
        "OwnerInformation": {
          "shape": "Saz"
        },
        "InstalledCount": {
          "type": "integer"
        },
        "InstalledOtherCount": {
          "type": "integer"
        },
        "InstalledPendingRebootCount": {
          "type": "integer"
        },
        "InstalledRejectedCount": {
          "type": "integer"
        },
        "MissingCount": {
          "type": "integer"
        },
        "FailedCount": {
          "type": "integer"
        },
        "UnreportedNotApplicableCount": {
          "type": "integer"
        },
        "NotApplicableCount": {
          "type": "integer"
        },
        "OperationStartTime": {
          "type": "timestamp"
        },
        "OperationEndTime": {
          "type": "timestamp"
        },
        "Operation": {},
        "LastNoRebootInstallOperationTime": {
          "type": "timestamp"
        },
        "RebootOption": {}
      }
    },
    "Saz": {
      "type": "string",
      "sensitive": true
    },
    "Sbz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Scb": {
      "type": "string",
      "sensitive": true
    },
    "Sd2": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Values": {
            "type": "list",
            "member": {
              "type": "string",
              "sensitive": true
            },
            "sensitive": true
          }
        },
        "sensitive": true
      },
      "sensitive": true
    },
    "Sd8": {
      "type": "structure",
      "required": [
        "S3BucketName",
        "S3Region"
      ],
      "members": {
        "S3BucketName": {},
        "S3KeyPrefix": {},
        "S3Region": {}
      }
    },
    "Se2": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Option": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Seh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PolicyText": {},
          "PolicyType": {},
          "PolicyStatus": {}
        }
      }
    },
    "Sen": {
      "type": "structure",
      "members": {
        "BaselineId": {},
        "BaselineName": {},
        "OperatingSystem": {},
        "BaselineDescription": {},
        "DefaultBaseline": {
          "type": "boolean"
        }
      }
    },
    "Sfp": {
      "type": "list",
      "member": {
        "shape": "S8n"
      }
    },
    "Sg7": {
      "type": "structure",
      "members": {
        "CloudWatchLogGroupName": {},
        "CloudWatchOutputEnabled": {
          "type": "boolean"
        }
      }
    },
    "Sgs": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Values"
        ],
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          },
          "Type": {}
        }
      }
    },
    "Sgy": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Expression": {},
          "Aggregators": {
            "shape": "Sgy"
          },
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Filters"
              ],
              "members": {
                "Name": {},
                "Filters": {
                  "shape": "Sgs"
                }
              }
            }
          }
        }
      }
    },
    "Shf": {
      "type": "list",
      "member": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "Si6": {
      "type": "structure",
      "members": {
        "RunCommand": {
          "type": "structure",
          "members": {
            "Comment": {},
            "CloudWatchOutputConfig": {
              "shape": "Sg7"
            },
            "DocumentHash": {},
            "DocumentHashType": {},
            "DocumentVersion": {},
            "NotificationConfig": {
              "shape": "Si8"
            },
            "OutputS3BucketName": {},
            "OutputS3KeyPrefix": {},
            "Parameters": {
              "shape": "St"
            },
            "ServiceRoleArn": {},
            "TimeoutSeconds": {
              "type": "integer"
            }
          }
        },
        "Automation": {
          "type": "structure",
          "members": {
            "DocumentVersion": {},
            "Parameters": {
              "shape": "S7u"
            }
          }
        },
        "StepFunctions": {
          "type": "structure",
          "members": {
            "Input": {
              "type": "string",
              "sensitive": true
            },
            "Name": {}
          }
        },
        "Lambda": {
          "type": "structure",
          "members": {
            "ClientContext": {},
            "Qualifier": {},
            "Payload": {
              "type": "blob",
              "sensitive": true
            }
          }
        }
      }
    },
    "Si8": {
      "type": "structure",
      "members": {
        "NotificationArn": {},
        "NotificationEvents": {
          "type": "list",
          "member": {}
        },
        "NotificationType": {}
      }
    },
    "Siq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Values"
        ],
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          },
          "Type": {}
        }
      }
    },
    "Siw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AggregatorType": {},
          "TypeName": {},
          "AttributeName": {},
          "Values": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "Filters": {
            "shape": "Siq"
          },
          "Aggregators": {
            "shape": "Siw"
          }
        }
      }
    },
    "Sji": {
      "type": "structure",
      "members": {
        "Name": {},
        "Type": {},
        "Value": {},
        "Version": {
          "type": "long"
        },
        "Selector": {},
        "SourceResult": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "ARN": {},
        "DataType": {}
      }
    },
    "Sjp": {
      "type": "list",
      "member": {}
    },
    "Sjt": {
      "type": "list",
      "member": {
        "shape": "Sji"
      }
    },
    "Sk5": {
      "type": "structure",
      "members": {
        "SettingId": {},
        "SettingValue": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "LastModifiedUser": {},
        "ARN": {},
        "Status": {}
      }
    },
    "Skn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sl3": {
      "type": "structure",
      "members": {
        "CommandId": {},
        "DocumentName": {},
        "DocumentVersion": {},
        "Comment": {},
        "ExpiresAfter": {
          "type": "timestamp"
        },
        "Parameters": {
          "shape": "St"
        },
        "InstanceIds": {
          "shape": "Sb"
        },
        "Targets": {
          "shape": "Sx"
        },
        "RequestedDateTime": {
          "type": "timestamp"
        },
        "Status": {},
        "StatusDetails": {},
        "OutputS3Region": {},
        "OutputS3BucketName": {},
        "OutputS3KeyPrefix": {},
        "MaxConcurrency": {},
        "MaxErrors": {},
        "TargetCount": {
          "type": "integer"
        },
        "CompletedCount": {
          "type": "integer"
        },
        "ErrorCount": {
          "type": "integer"
        },
        "DeliveryTimedOutCount": {
          "type": "integer"
        },
        "ServiceRole": {},
        "NotificationConfig": {
          "shape": "Si8"
        },
        "CloudWatchOutputConfig": {
          "shape": "Sg7"
        },
        "TimeoutSeconds": {
          "type": "integer"
        }
      }
    },
    "Sla": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          },
          "Type": {}
        }
      }
    },
    "Sls": {
      "type": "structure",
      "required": [
        "ExecutionTime"
      ],
      "members": {
        "ExecutionTime": {
          "type": "timestamp"
        },
        "ExecutionId": {},
        "ExecutionType": {}
      }
    },
    "Slv": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm0": {
      "type": "structure",
      "members": {
        "CompliantCount": {
          "type": "integer"
        },
        "SeveritySummary": {
          "shape": "Sm2"
        }
      }
    },
    "Sm2": {
      "type": "structure",
      "members": {
        "CriticalCount": {
          "type": "integer"
        },
        "HighCount": {
          "type": "integer"
        },
        "MediumCount": {
          "type": "integer"
        },
        "LowCount": {
          "type": "integer"
        },
        "InformationalCount": {
          "type": "integer"
        },
        "UnspecifiedCount": {
          "type": "integer"
        }
      }
    },
    "Sm3": {
      "type": "structure",
      "members": {
        "NonCompliantCount": {
          "type": "integer"
        },
        "SeveritySummary": {
          "shape": "Sm2"
        }
      }
    }
  }
}
},{}],211:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeActivations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ActivationList"
    },
    "DescribeInstanceInformation": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceInformationList"
    },
    "DescribeParameters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetParameterHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetParametersByPath": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "ListCommandInvocations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CommandInvocations"
    },
    "ListCommands": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Commands"
    },
    "ListDocuments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DocumentIdentifiers"
    }
  }
}
},{}],212:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-06-30",
    "endpointPrefix": "storagegateway",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Storage Gateway",
    "serviceId": "Storage Gateway",
    "signatureVersion": "v4",
    "targetPrefix": "StorageGateway_20130630",
    "uid": "storagegateway-2013-06-30"
  },
  "operations": {
    "ActivateGateway": {
      "input": {
        "type": "structure",
        "required": [
          "ActivationKey",
          "GatewayName",
          "GatewayTimezone",
          "GatewayRegion"
        ],
        "members": {
          "ActivationKey": {},
          "GatewayName": {},
          "GatewayTimezone": {},
          "GatewayRegion": {},
          "GatewayType": {},
          "TapeDriveType": {},
          "MediumChangerType": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {}
        }
      }
    },
    "AddUploadBuffer": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddWorkingStorage": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AssignTapePool": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "PoolId"
        ],
        "members": {
          "TapeARN": {},
          "PoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "VolumeARN",
          "NetworkInterfaceId"
        ],
        "members": {
          "GatewayARN": {},
          "TargetName": {},
          "VolumeARN": {},
          "NetworkInterfaceId": {},
          "DiskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "TargetARN": {}
        }
      }
    },
    "CancelArchival": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CancelRetrieval": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CreateCachediSCSIVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "VolumeSizeInBytes",
          "TargetName",
          "NetworkInterfaceId",
          "ClientToken"
        ],
        "members": {
          "GatewayARN": {},
          "VolumeSizeInBytes": {
            "type": "long"
          },
          "SnapshotId": {},
          "TargetName": {},
          "SourceVolumeARN": {},
          "NetworkInterfaceId": {},
          "ClientToken": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "TargetARN": {}
        }
      }
    },
    "CreateNFSFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "GatewayARN",
          "Role",
          "LocationARN"
        ],
        "members": {
          "ClientToken": {},
          "NFSFileShareDefaults": {
            "shape": "S1c"
          },
          "GatewayARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Role": {},
          "LocationARN": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ClientList": {
            "shape": "S1j"
          },
          "Squash": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "CreateSMBFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "GatewayARN",
          "Role",
          "LocationARN"
        ],
        "members": {
          "ClientToken": {},
          "GatewayARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Role": {},
          "LocationARN": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "SMBACLEnabled": {
            "type": "boolean"
          },
          "AdminUserList": {
            "shape": "S1p"
          },
          "ValidUserList": {
            "shape": "S1p"
          },
          "InvalidUserList": {
            "shape": "S1p"
          },
          "AuditDestinationARN": {},
          "Authentication": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "SnapshotDescription"
        ],
        "members": {
          "VolumeARN": {},
          "SnapshotDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "SnapshotId": {}
        }
      }
    },
    "CreateSnapshotFromVolumeRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "SnapshotDescription"
        ],
        "members": {
          "VolumeARN": {},
          "SnapshotDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {},
          "VolumeARN": {},
          "VolumeRecoveryPointTime": {}
        }
      }
    },
    "CreateStorediSCSIVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskId",
          "PreserveExistingData",
          "TargetName",
          "NetworkInterfaceId"
        ],
        "members": {
          "GatewayARN": {},
          "DiskId": {},
          "SnapshotId": {},
          "PreserveExistingData": {
            "type": "boolean"
          },
          "TargetName": {},
          "NetworkInterfaceId": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "VolumeSizeInBytes": {
            "type": "long"
          },
          "TargetARN": {}
        }
      }
    },
    "CreateTapeWithBarcode": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeSizeInBytes",
          "TapeBarcode"
        ],
        "members": {
          "GatewayARN": {},
          "TapeSizeInBytes": {
            "type": "long"
          },
          "TapeBarcode": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "PoolId": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CreateTapes": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeSizeInBytes",
          "ClientToken",
          "NumTapesToCreate",
          "TapeBarcodePrefix"
        ],
        "members": {
          "GatewayARN": {},
          "TapeSizeInBytes": {
            "type": "long"
          },
          "ClientToken": {},
          "NumTapesToCreate": {
            "type": "integer"
          },
          "TapeBarcodePrefix": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "PoolId": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeleteAutomaticTapeCreationPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "DeleteBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "BandwidthType"
        ],
        "members": {
          "GatewayARN": {},
          "BandwidthType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "DeleteChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN",
          "InitiatorName"
        ],
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      }
    },
    "DeleteFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "DeleteGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "DeleteSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DeleteTape": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "DeleteTapeArchive": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN"
        ],
        "members": {
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DescribeAvailabilityMonitorTest": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Status": {},
          "StartTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "AverageUploadRateLimitInBitsPerSec": {
            "type": "long"
          },
          "AverageDownloadRateLimitInBitsPerSec": {
            "type": "long"
          }
        }
      }
    },
    "DescribeCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "CacheAllocatedInBytes": {
            "type": "long"
          },
          "CacheUsedPercentage": {
            "type": "double"
          },
          "CacheDirtyPercentage": {
            "type": "double"
          },
          "CacheHitPercentage": {
            "type": "double"
          },
          "CacheMissPercentage": {
            "type": "double"
          }
        }
      }
    },
    "DescribeCachediSCSIVolumes": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARNs"
        ],
        "members": {
          "VolumeARNs": {
            "shape": "S38"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CachediSCSIVolumes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "VolumeType": {},
                "VolumeStatus": {},
                "VolumeAttachmentStatus": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeProgress": {
                  "type": "double"
                },
                "SourceSnapshotId": {},
                "VolumeiSCSIAttributes": {
                  "shape": "S3h"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "VolumeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "TargetName": {}
              }
            }
          }
        }
      }
    },
    "DescribeChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN"
        ],
        "members": {
          "TargetARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChapCredentials": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetARN": {},
                "SecretToAuthenticateInitiator": {
                  "shape": "S3q"
                },
                "InitiatorName": {},
                "SecretToAuthenticateTarget": {
                  "shape": "S3q"
                }
              }
            }
          }
        }
      }
    },
    "DescribeGatewayInformation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "GatewayId": {},
          "GatewayName": {},
          "GatewayTimezone": {},
          "GatewayState": {},
          "GatewayNetworkInterfaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Ipv4Address": {},
                "MacAddress": {},
                "Ipv6Address": {}
              }
            }
          },
          "GatewayType": {},
          "NextUpdateAvailabilityDate": {},
          "LastSoftwareUpdate": {},
          "Ec2InstanceId": {},
          "Ec2InstanceRegion": {},
          "Tags": {
            "shape": "S9"
          },
          "VPCEndpoint": {},
          "CloudWatchLogGroupARN": {},
          "HostEnvironment": {},
          "EndpointType": {}
        }
      }
    },
    "DescribeMaintenanceStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "HourOfDay": {
            "type": "integer"
          },
          "MinuteOfHour": {
            "type": "integer"
          },
          "DayOfWeek": {
            "type": "integer"
          },
          "DayOfMonth": {
            "type": "integer"
          },
          "Timezone": {}
        }
      }
    },
    "DescribeNFSFileShares": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARNList"
        ],
        "members": {
          "FileShareARNList": {
            "shape": "S4b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NFSFileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NFSFileShareDefaults": {
                  "shape": "S1c"
                },
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {},
                "KMSEncrypted": {
                  "type": "boolean"
                },
                "KMSKey": {},
                "Path": {},
                "Role": {},
                "LocationARN": {},
                "DefaultStorageClass": {},
                "ObjectACL": {},
                "ClientList": {
                  "shape": "S1j"
                },
                "Squash": {},
                "ReadOnly": {
                  "type": "boolean"
                },
                "GuessMIMETypeEnabled": {
                  "type": "boolean"
                },
                "RequesterPays": {
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSMBFileShares": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARNList"
        ],
        "members": {
          "FileShareARNList": {
            "shape": "S4b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SMBFileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {},
                "KMSEncrypted": {
                  "type": "boolean"
                },
                "KMSKey": {},
                "Path": {},
                "Role": {},
                "LocationARN": {},
                "DefaultStorageClass": {},
                "ObjectACL": {},
                "ReadOnly": {
                  "type": "boolean"
                },
                "GuessMIMETypeEnabled": {
                  "type": "boolean"
                },
                "RequesterPays": {
                  "type": "boolean"
                },
                "SMBACLEnabled": {
                  "type": "boolean"
                },
                "AdminUserList": {
                  "shape": "S1p"
                },
                "ValidUserList": {
                  "shape": "S1p"
                },
                "InvalidUserList": {
                  "shape": "S1p"
                },
                "AuditDestinationARN": {},
                "Authentication": {},
                "Tags": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSMBSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DomainName": {},
          "ActiveDirectoryStatus": {},
          "SMBGuestPasswordSet": {
            "type": "boolean"
          },
          "SMBSecurityStrategy": {}
        }
      }
    },
    "DescribeSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "StartAt": {
            "type": "integer"
          },
          "RecurrenceInHours": {
            "type": "integer"
          },
          "Description": {},
          "Timezone": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeStorediSCSIVolumes": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARNs"
        ],
        "members": {
          "VolumeARNs": {
            "shape": "S38"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorediSCSIVolumes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "VolumeType": {},
                "VolumeStatus": {},
                "VolumeAttachmentStatus": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeProgress": {
                  "type": "double"
                },
                "VolumeDiskId": {},
                "SourceSnapshotId": {},
                "PreservedExistingData": {
                  "type": "boolean"
                },
                "VolumeiSCSIAttributes": {
                  "shape": "S3h"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "VolumeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "TargetName": {}
              }
            }
          }
        }
      }
    },
    "DescribeTapeArchives": {
      "input": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2b"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeArchives": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeCreatedDate": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "RetrievedTo": {},
                "TapeStatus": {},
                "TapeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTapeRecoveryPoints": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "TapeRecoveryPointInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeRecoveryPointTime": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTapes": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARNs": {
            "shape": "S2b"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tapes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeCreatedDate": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {},
                "VTLDevice": {},
                "Progress": {
                  "type": "double"
                },
                "TapeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeUploadBuffer": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "UploadBufferUsedInBytes": {
            "type": "long"
          },
          "UploadBufferAllocatedInBytes": {
            "type": "long"
          }
        }
      }
    },
    "DescribeVTLDevices": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "VTLDeviceARNs": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "VTLDevices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VTLDeviceARN": {},
                "VTLDeviceType": {},
                "VTLDeviceVendor": {},
                "VTLDeviceProductIdentifier": {},
                "DeviceiSCSIAttributes": {
                  "type": "structure",
                  "members": {
                    "TargetARN": {},
                    "NetworkInterfaceId": {},
                    "NetworkInterfacePort": {
                      "type": "integer"
                    },
                    "ChapEnabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeWorkingStorage": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "WorkingStorageUsedInBytes": {
            "type": "long"
          },
          "WorkingStorageAllocatedInBytes": {
            "type": "long"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {},
          "ForceDetach": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DisableGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "JoinDomain": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DomainName",
          "UserName",
          "Password"
        ],
        "members": {
          "GatewayARN": {},
          "DomainName": {},
          "OrganizationalUnit": {},
          "DomainControllers": {
            "type": "list",
            "member": {}
          },
          "TimeoutInSeconds": {
            "type": "integer"
          },
          "UserName": {},
          "Password": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "ActiveDirectoryStatus": {}
        }
      }
    },
    "ListAutomaticTapeCreationPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomaticTapeCreationPolicyInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutomaticTapeCreationRules": {
                  "shape": "S6a"
                },
                "GatewayARN": {}
              }
            }
          }
        }
      }
    },
    "ListFileShares": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "NextMarker": {},
          "FileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FileShareType": {},
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {}
              }
            }
          }
        }
      }
    },
    "ListGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Gateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GatewayId": {},
                "GatewayARN": {},
                "GatewayType": {},
                "GatewayOperationalState": {},
                "GatewayName": {},
                "Ec2InstanceId": {},
                "Ec2InstanceRegion": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListLocalDisks": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Disks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DiskId": {},
                "DiskPath": {},
                "DiskNode": {},
                "DiskStatus": {},
                "DiskSizeInBytes": {
                  "type": "long"
                },
                "DiskAllocationType": {},
                "DiskAllocationResource": {},
                "DiskAttributeList": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {},
          "Marker": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "ListTapes": {
      "input": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2b"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {},
                "GatewayARN": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListVolumeInitiators": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Initiators": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListVolumeRecoveryPoints": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "VolumeRecoveryPointInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeUsageInBytes": {
                  "type": "long"
                },
                "VolumeRecoveryPointTime": {}
              }
            }
          }
        }
      }
    },
    "ListVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "VolumeInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "GatewayARN": {},
                "GatewayId": {},
                "VolumeType": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeAttachmentStatus": {}
              }
            }
          }
        }
      }
    },
    "NotifyWhenUploaded": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {},
          "NotificationId": {}
        }
      }
    },
    "RefreshCache": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "FolderList": {
            "type": "list",
            "member": {}
          },
          "Recursive": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {},
          "NotificationId": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {}
        }
      }
    },
    "ResetCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "RetrieveTapeArchive": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "GatewayARN"
        ],
        "members": {
          "TapeARN": {},
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "RetrieveTapeRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "GatewayARN"
        ],
        "members": {
          "TapeARN": {},
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "SetLocalConsolePassword": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "LocalConsolePassword"
        ],
        "members": {
          "GatewayARN": {},
          "LocalConsolePassword": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "SetSMBGuestPassword": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "Password"
        ],
        "members": {
          "GatewayARN": {},
          "Password": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "ShutdownGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "StartAvailabilityMonitorTest": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "StartGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateAutomaticTapeCreationPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "AutomaticTapeCreationRules",
          "GatewayARN"
        ],
        "members": {
          "AutomaticTapeCreationRules": {
            "shape": "S6a"
          },
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "AverageUploadRateLimitInBitsPerSec": {
            "type": "long"
          },
          "AverageDownloadRateLimitInBitsPerSec": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN",
          "SecretToAuthenticateInitiator",
          "InitiatorName"
        ],
        "members": {
          "TargetARN": {},
          "SecretToAuthenticateInitiator": {
            "shape": "S3q"
          },
          "InitiatorName": {},
          "SecretToAuthenticateTarget": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      }
    },
    "UpdateGatewayInformation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "GatewayName": {},
          "GatewayTimezone": {},
          "CloudWatchLogGroupARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "GatewayName": {}
        }
      }
    },
    "UpdateGatewaySoftwareNow": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateMaintenanceStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "HourOfDay",
          "MinuteOfHour"
        ],
        "members": {
          "GatewayARN": {},
          "HourOfDay": {
            "type": "integer"
          },
          "MinuteOfHour": {
            "type": "integer"
          },
          "DayOfWeek": {
            "type": "integer"
          },
          "DayOfMonth": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateNFSFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "NFSFileShareDefaults": {
            "shape": "S1c"
          },
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ClientList": {
            "shape": "S1j"
          },
          "Squash": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "UpdateSMBFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "SMBACLEnabled": {
            "type": "boolean"
          },
          "AdminUserList": {
            "shape": "S1p"
          },
          "ValidUserList": {
            "shape": "S1p"
          },
          "InvalidUserList": {
            "shape": "S1p"
          },
          "AuditDestinationARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "UpdateSMBSecurityStrategy": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "SMBSecurityStrategy"
        ],
        "members": {
          "GatewayARN": {},
          "SMBSecurityStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "StartAt",
          "RecurrenceInHours"
        ],
        "members": {
          "VolumeARN": {},
          "StartAt": {
            "type": "integer"
          },
          "RecurrenceInHours": {
            "type": "integer"
          },
          "Description": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "UpdateVTLDeviceType": {
      "input": {
        "type": "structure",
        "required": [
          "VTLDeviceARN",
          "DeviceType"
        ],
        "members": {
          "VTLDeviceARN": {},
          "DeviceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VTLDeviceARN": {}
        }
      }
    }
  },
  "shapes": {
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "FileMode": {},
        "DirectoryMode": {},
        "GroupId": {
          "type": "long"
        },
        "OwnerId": {
          "type": "long"
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "list",
      "member": {}
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S38": {
      "type": "list",
      "member": {}
    },
    "S3h": {
      "type": "structure",
      "members": {
        "TargetARN": {},
        "NetworkInterfaceId": {},
        "NetworkInterfacePort": {
          "type": "integer"
        },
        "LunNumber": {
          "type": "integer"
        },
        "ChapEnabled": {
          "type": "boolean"
        }
      }
    },
    "S3q": {
      "type": "string",
      "sensitive": true
    },
    "S4b": {
      "type": "list",
      "member": {}
    },
    "S6a": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TapeBarcodePrefix",
          "PoolId",
          "TapeSizeInBytes",
          "MinimumNumTapes"
        ],
        "members": {
          "TapeBarcodePrefix": {},
          "PoolId": {},
          "TapeSizeInBytes": {
            "type": "long"
          },
          "MinimumNumTapes": {
            "type": "integer"
          }
        }
      }
    }
  }
}
},{}],213:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeCachediSCSIVolumes": {
      "result_key": "CachediSCSIVolumes"
    },
    "DescribeStorediSCSIVolumes": {
      "result_key": "StorediSCSIVolumes"
    },
    "DescribeTapeArchives": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "TapeArchives"
    },
    "DescribeTapeRecoveryPoints": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "TapeRecoveryPointInfos"
    },
    "DescribeTapes": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "Tapes"
    },
    "DescribeVTLDevices": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "VTLDevices"
    },
    "ListFileShares": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "non_aggregate_keys": [
        "Marker"
      ],
      "output_token": "NextMarker",
      "result_key": "FileShareInfoList"
    },
    "ListGateways": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "Gateways"
    },
    "ListLocalDisks": {
      "result_key": "Disks"
    },
    "ListTagsForResource": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "non_aggregate_keys": [
        "ResourceARN"
      ],
      "output_token": "Marker",
      "result_key": "Tags"
    },
    "ListTapes": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "TapeInfos"
    },
    "ListVolumeRecoveryPoints": {
      "result_key": "VolumeRecoveryPointInfos"
    },
    "ListVolumes": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "VolumeInfos"
    }
  }
}
},{}],214:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-08-10",
    "endpointPrefix": "streams.dynamodb",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "Amazon DynamoDB Streams",
    "serviceId": "DynamoDB Streams",
    "signatureVersion": "v4",
    "signingName": "dynamodb",
    "targetPrefix": "DynamoDBStreams_20120810",
    "uid": "streams-dynamodb-2012-08-10"
  },
  "operations": {
    "DescribeStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamArn"
        ],
        "members": {
          "StreamArn": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartShardId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamDescription": {
            "type": "structure",
            "members": {
              "StreamArn": {},
              "StreamLabel": {},
              "StreamStatus": {},
              "StreamViewType": {},
              "CreationRequestDateTime": {
                "type": "timestamp"
              },
              "TableName": {},
              "KeySchema": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "AttributeName",
                    "KeyType"
                  ],
                  "members": {
                    "AttributeName": {},
                    "KeyType": {}
                  }
                }
              },
              "Shards": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ShardId": {},
                    "SequenceNumberRange": {
                      "type": "structure",
                      "members": {
                        "StartingSequenceNumber": {},
                        "EndingSequenceNumber": {}
                      }
                    },
                    "ParentShardId": {}
                  }
                }
              },
              "LastEvaluatedShardId": {}
            }
          }
        }
      }
    },
    "GetRecords": {
      "input": {
        "type": "structure",
        "required": [
          "ShardIterator"
        ],
        "members": {
          "ShardIterator": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "eventID": {},
                "eventName": {},
                "eventVersion": {},
                "eventSource": {},
                "awsRegion": {},
                "dynamodb": {
                  "type": "structure",
                  "members": {
                    "ApproximateCreationDateTime": {
                      "type": "timestamp"
                    },
                    "Keys": {
                      "shape": "Sr"
                    },
                    "NewImage": {
                      "shape": "Sr"
                    },
                    "OldImage": {
                      "shape": "Sr"
                    },
                    "SequenceNumber": {},
                    "SizeBytes": {
                      "type": "long"
                    },
                    "StreamViewType": {}
                  }
                },
                "userIdentity": {
                  "type": "structure",
                  "members": {
                    "PrincipalId": {},
                    "Type": {}
                  }
                }
              }
            }
          },
          "NextShardIterator": {}
        }
      }
    },
    "GetShardIterator": {
      "input": {
        "type": "structure",
        "required": [
          "StreamArn",
          "ShardId",
          "ShardIteratorType"
        ],
        "members": {
          "StreamArn": {},
          "ShardId": {},
          "ShardIteratorType": {},
          "SequenceNumber": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShardIterator": {}
        }
      }
    },
    "ListStreams": {
      "input": {
        "type": "structure",
        "members": {
          "TableName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartStreamArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Streams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StreamArn": {},
                "TableName": {},
                "StreamLabel": {}
              }
            }
          },
          "LastEvaluatedStreamArn": {}
        }
      }
    }
  },
  "shapes": {
    "Sr": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "St"
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "S": {},
        "N": {},
        "B": {
          "type": "blob"
        },
        "SS": {
          "type": "list",
          "member": {}
        },
        "NS": {
          "type": "list",
          "member": {}
        },
        "BS": {
          "type": "list",
          "member": {
            "type": "blob"
          }
        },
        "M": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "St"
          }
        },
        "L": {
          "type": "list",
          "member": {
            "shape": "St"
          }
        },
        "NULL": {
          "type": "boolean"
        },
        "BOOL": {
          "type": "boolean"
        }
      }
    }
  }
}
},{}],215:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],216:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2011-06-15",
    "endpointPrefix": "sts",
    "globalEndpoint": "sts.amazonaws.com",
    "protocol": "query",
    "serviceAbbreviation": "AWS STS",
    "serviceFullName": "AWS Security Token Service",
    "serviceId": "STS",
    "signatureVersion": "v4",
    "uid": "sts-2011-06-15",
    "xmlNamespace": "https://sts.amazonaws.com/doc/2011-06-15/"
  },
  "operations": {
    "AssumeRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "RoleSessionName"
        ],
        "members": {
          "RoleArn": {},
          "RoleSessionName": {},
          "PolicyArns": {
            "shape": "S4"
          },
          "Policy": {},
          "DurationSeconds": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S8"
          },
          "TransitiveTagKeys": {
            "type": "list",
            "member": {}
          },
          "ExternalId": {},
          "SerialNumber": {},
          "TokenCode": {}
        }
      },
      "output": {
        "resultWrapper": "AssumeRoleResult",
        "type": "structure",
        "members": {
          "Credentials": {
            "shape": "Sh"
          },
          "AssumedRoleUser": {
            "shape": "Sm"
          },
          "PackedPolicySize": {
            "type": "integer"
          }
        }
      }
    },
    "AssumeRoleWithSAML": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "PrincipalArn",
          "SAMLAssertion"
        ],
        "members": {
          "RoleArn": {},
          "PrincipalArn": {},
          "SAMLAssertion": {
            "type": "string",
            "sensitive": true
          },
          "PolicyArns": {
            "shape": "S4"
          },
          "Policy": {},
          "DurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "AssumeRoleWithSAMLResult",
        "type": "structure",
        "members": {
          "Credentials": {
            "shape": "Sh"
          },
          "AssumedRoleUser": {
            "shape": "Sm"
          },
          "PackedPolicySize": {
            "type": "integer"
          },
          "Subject": {},
          "SubjectType": {},
          "Issuer": {},
          "Audience": {},
          "NameQualifier": {}
        }
      }
    },
    "AssumeRoleWithWebIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "RoleSessionName",
          "WebIdentityToken"
        ],
        "members": {
          "RoleArn": {},
          "RoleSessionName": {},
          "WebIdentityToken": {
            "type": "string",
            "sensitive": true
          },
          "ProviderId": {},
          "PolicyArns": {
            "shape": "S4"
          },
          "Policy": {},
          "DurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "AssumeRoleWithWebIdentityResult",
        "type": "structure",
        "members": {
          "Credentials": {
            "shape": "Sh"
          },
          "SubjectFromWebIdentityToken": {},
          "AssumedRoleUser": {
            "shape": "Sm"
          },
          "PackedPolicySize": {
            "type": "integer"
          },
          "Provider": {},
          "Audience": {}
        }
      }
    },
    "DecodeAuthorizationMessage": {
      "input": {
        "type": "structure",
        "required": [
          "EncodedMessage"
        ],
        "members": {
          "EncodedMessage": {}
        }
      },
      "output": {
        "resultWrapper": "DecodeAuthorizationMessageResult",
        "type": "structure",
        "members": {
          "DecodedMessage": {}
        }
      }
    },
    "GetAccessKeyInfo": {
      "input": {
        "type": "structure",
        "required": [
          "AccessKeyId"
        ],
        "members": {
          "AccessKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "GetAccessKeyInfoResult",
        "type": "structure",
        "members": {
          "Account": {}
        }
      }
    },
    "GetCallerIdentity": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "GetCallerIdentityResult",
        "type": "structure",
        "members": {
          "UserId": {},
          "Account": {},
          "Arn": {}
        }
      }
    },
    "GetFederationToken": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Policy": {},
          "PolicyArns": {
            "shape": "S4"
          },
          "DurationSeconds": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "resultWrapper": "GetFederationTokenResult",
        "type": "structure",
        "members": {
          "Credentials": {
            "shape": "Sh"
          },
          "FederatedUser": {
            "type": "structure",
            "required": [
              "FederatedUserId",
              "Arn"
            ],
            "members": {
              "FederatedUserId": {},
              "Arn": {}
            }
          },
          "PackedPolicySize": {
            "type": "integer"
          }
        }
      }
    },
    "GetSessionToken": {
      "input": {
        "type": "structure",
        "members": {
          "DurationSeconds": {
            "type": "integer"
          },
          "SerialNumber": {},
          "TokenCode": {}
        }
      },
      "output": {
        "resultWrapper": "GetSessionTokenResult",
        "type": "structure",
        "members": {
          "Credentials": {
            "shape": "Sh"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sh": {
      "type": "structure",
      "required": [
        "AccessKeyId",
        "SecretAccessKey",
        "SessionToken",
        "Expiration"
      ],
      "members": {
        "AccessKeyId": {},
        "SecretAccessKey": {},
        "SessionToken": {},
        "Expiration": {
          "type": "timestamp"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "AssumedRoleId",
        "Arn"
      ],
      "members": {
        "AssumedRoleId": {},
        "Arn": {}
      }
    }
  }
}
},{}],217:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],218:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-01",
    "endpointPrefix": "translate",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Translate",
    "serviceId": "Translate",
    "signatureVersion": "v4",
    "signingName": "translate",
    "targetPrefix": "AWSShineFrontendService_20170701",
    "uid": "translate-2017-07-01"
  },
  "operations": {
    "DeleteTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DescribeTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextTranslationJobProperties": {
            "shape": "S6"
          }
        }
      }
    },
    "GetTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "TerminologyDataFormat"
        ],
        "members": {
          "Name": {},
          "TerminologyDataFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyProperties": {
            "shape": "So"
          },
          "TerminologyDataLocation": {
            "type": "structure",
            "required": [
              "RepositoryType",
              "Location"
            ],
            "members": {
              "RepositoryType": {},
              "Location": {}
            }
          }
        }
      }
    },
    "ImportTerminology": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MergeStrategy",
          "TerminologyData"
        ],
        "members": {
          "Name": {},
          "MergeStrategy": {},
          "Description": {},
          "TerminologyData": {
            "type": "structure",
            "required": [
              "File",
              "Format"
            ],
            "members": {
              "File": {
                "type": "blob",
                "sensitive": true
              },
              "Format": {}
            }
          },
          "EncryptionKey": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyProperties": {
            "shape": "So"
          }
        }
      }
    },
    "ListTerminologies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminologyPropertiesList": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTextTranslationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmittedBeforeTime": {
                "type": "timestamp"
              },
              "SubmittedAfterTime": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextTranslationJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "SourceLanguageCode",
          "TargetLanguageCodes",
          "ClientToken"
        ],
        "members": {
          "JobName": {},
          "InputDataConfig": {
            "shape": "Sg"
          },
          "OutputDataConfig": {
            "shape": "Sj"
          },
          "DataAccessRoleArn": {},
          "SourceLanguageCode": {},
          "TargetLanguageCodes": {
            "shape": "Sc"
          },
          "TerminologyNames": {
            "shape": "Sd"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTextTranslationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "TranslateText": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "SourceLanguageCode",
          "TargetLanguageCode"
        ],
        "members": {
          "Text": {},
          "TerminologyNames": {
            "shape": "Sd"
          },
          "SourceLanguageCode": {},
          "TargetLanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TranslatedText",
          "SourceLanguageCode",
          "TargetLanguageCode"
        ],
        "members": {
          "TranslatedText": {},
          "SourceLanguageCode": {},
          "TargetLanguageCode": {},
          "AppliedTerminologies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Terms": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "SourceText": {},
                      "TargetText": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "JobDetails": {
          "type": "structure",
          "members": {
            "TranslatedDocumentsCount": {
              "type": "integer"
            },
            "DocumentsWithErrorsCount": {
              "type": "integer"
            },
            "InputDocumentsCount": {
              "type": "integer"
            }
          }
        },
        "SourceLanguageCode": {},
        "TargetLanguageCodes": {
          "shape": "Sc"
        },
        "TerminologyNames": {
          "shape": "Sd"
        },
        "Message": {},
        "SubmittedTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "Sg"
        },
        "OutputDataConfig": {
          "shape": "Sj"
        },
        "DataAccessRoleArn": {}
      }
    },
    "Sc": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "structure",
      "required": [
        "S3Uri",
        "ContentType"
      ],
      "members": {
        "S3Uri": {},
        "ContentType": {}
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {}
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "Arn": {},
        "SourceLanguageCode": {},
        "TargetLanguageCodes": {
          "type": "list",
          "member": {}
        },
        "EncryptionKey": {
          "shape": "Ss"
        },
        "SizeBytes": {
          "type": "integer"
        },
        "TermCount": {
          "type": "integer"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastUpdatedAt": {
          "type": "timestamp"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "required": [
        "Type",
        "Id"
      ],
      "members": {
        "Type": {},
        "Id": {}
      }
    }
  }
}
},{}],219:[function(require,module,exports){
module.exports={
  "pagination": {
    "ListTerminologies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListTextTranslationJobs": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    }
  }
}
},{}],220:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-08-24",
    "endpointPrefix": "waf",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "WAF",
    "serviceFullName": "AWS WAF",
    "serviceId": "WAF",
    "signatureVersion": "v4",
    "targetPrefix": "AWSWAF_20150824",
    "uid": "waf-2015-08-24"
  },
  "operations": {
    "CreateByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByteMatchSet": {
            "shape": "S5"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateGeoMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GeoMatchSet": {
            "shape": "Sh"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IPSet": {
            "shape": "So"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRateBasedRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "RateKey",
          "RateLimit",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "RateKey": {},
          "RateLimit": {
            "type": "long"
          },
          "ChangeToken": {},
          "Tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "S12"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRegexMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegexMatchSet": {
            "shape": "S19"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRegexPatternSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegexPatternSet": {
            "shape": "S1e"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "ChangeToken": {},
          "Tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "S1j"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "ChangeToken": {},
          "Tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleGroup": {
            "shape": "S1m"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SizeConstraintSet": {
            "shape": "S1p"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SqlInjectionMatchSet": {
            "shape": "S1w"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "DefaultAction",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "DefaultAction": {
            "shape": "S20"
          },
          "ChangeToken": {},
          "Tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WebACL": {
            "shape": "S23"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateWebACLMigrationStack": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId",
          "S3BucketName",
          "IgnoreUnsupportedType"
        ],
        "members": {
          "WebACLId": {},
          "S3BucketName": {},
          "IgnoreUnsupportedType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "S3ObjectUrl"
        ],
        "members": {
          "S3ObjectUrl": {}
        }
      }
    },
    "CreateXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "XssMatchSet": {
            "shape": "S2k"
          },
          "ChangeToken": {}
        }
      }
    },
    "DeleteByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "ByteMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteGeoMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "GeoMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "GeoMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId",
          "ChangeToken"
        ],
        "members": {
          "IPSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteLoggingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePermissionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRateBasedRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteRegexMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "RegexMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteRegexPatternSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexPatternSetId",
          "ChangeToken"
        ],
        "members": {
          "RegexPatternSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "RuleGroupId",
          "ChangeToken"
        ],
        "members": {
          "RuleGroupId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId",
          "ChangeToken"
        ],
        "members": {
          "SizeConstraintSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "SqlInjectionMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId",
          "ChangeToken"
        ],
        "members": {
          "WebACLId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "XssMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "GetByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId"
        ],
        "members": {
          "ByteMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByteMatchSet": {
            "shape": "S5"
          }
        }
      }
    },
    "GetChangeToken": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "GetChangeTokenStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeToken"
        ],
        "members": {
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeTokenStatus": {}
        }
      }
    },
    "GetGeoMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "GeoMatchSetId"
        ],
        "members": {
          "GeoMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GeoMatchSet": {
            "shape": "Sh"
          }
        }
      }
    },
    "GetIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId"
        ],
        "members": {
          "IPSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IPSet": {
            "shape": "So"
          }
        }
      }
    },
    "GetLoggingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingConfiguration": {
            "shape": "S3s"
          }
        }
      }
    },
    "GetPermissionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "GetRateBasedRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId"
        ],
        "members": {
          "RuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "S12"
          }
        }
      }
    },
    "GetRateBasedRuleManagedKeys": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId"
        ],
        "members": {
          "RuleId": {},
          "NextMarker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ManagedKeys": {
            "type": "list",
            "member": {}
          },
          "NextMarker": {}
        }
      }
    },
    "GetRegexMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexMatchSetId"
        ],
        "members": {
          "RegexMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegexMatchSet": {
            "shape": "S19"
          }
        }
      }
    },
    "GetRegexPatternSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexPatternSetId"
        ],
        "members": {
          "RegexPatternSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegexPatternSet": {
            "shape": "S1e"
          }
        }
      }
    },
    "GetRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId"
        ],
        "members": {
          "RuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "S1j"
          }
        }
      }
    },
    "GetRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "RuleGroupId"
        ],
        "members": {
          "RuleGroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleGroup": {
            "shape": "S1m"
          }
        }
      }
    },
    "GetSampledRequests": {
      "input": {
        "type": "structure",
        "required": [
          "WebAclId",
          "RuleId",
          "TimeWindow",
          "MaxItems"
        ],
        "members": {
          "WebAclId": {},
          "RuleId": {},
          "TimeWindow": {
            "shape": "S4e"
          },
          "MaxItems": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SampledRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Request",
                "Weight"
              ],
              "members": {
                "Request": {
                  "type": "structure",
                  "members": {
                    "ClientIP": {},
                    "Country": {},
                    "URI": {},
                    "Method": {},
                    "HTTPVersion": {},
                    "Headers": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Name": {},
                          "Value": {}
                        }
                      }
                    }
                  }
                },
                "Weight": {
                  "type": "long"
                },
                "Timestamp": {
                  "type": "timestamp"
                },
                "Action": {},
                "RuleWithinRuleGroup": {}
              }
            }
          },
          "PopulationSize": {
            "type": "long"
          },
          "TimeWindow": {
            "shape": "S4e"
          }
        }
      }
    },
    "GetSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId"
        ],
        "members": {
          "SizeConstraintSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SizeConstraintSet": {
            "shape": "S1p"
          }
        }
      }
    },
    "GetSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId"
        ],
        "members": {
          "SqlInjectionMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SqlInjectionMatchSet": {
            "shape": "S1w"
          }
        }
      }
    },
    "GetWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "WebACLId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WebACL": {
            "shape": "S23"
          }
        }
      }
    },
    "GetXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId"
        ],
        "members": {
          "XssMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "XssMatchSet": {
            "shape": "S2k"
          }
        }
      }
    },
    "ListActivatedRulesInRuleGroup": {
      "input": {
        "type": "structure",
        "members": {
          "RuleGroupId": {},
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "ActivatedRules": {
            "shape": "S24"
          }
        }
      }
    },
    "ListByteMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "ByteMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ByteMatchSetId",
                "Name"
              ],
              "members": {
                "ByteMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListGeoMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "GeoMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GeoMatchSetId",
                "Name"
              ],
              "members": {
                "GeoMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListIPSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "IPSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IPSetId",
                "Name"
              ],
              "members": {
                "IPSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListLoggingConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingConfigurations": {
            "type": "list",
            "member": {
              "shape": "S3s"
            }
          },
          "NextMarker": {}
        }
      }
    },
    "ListRateBasedRules": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Rules": {
            "shape": "S5p"
          }
        }
      }
    },
    "ListRegexMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "RegexMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegexMatchSetId",
                "Name"
              ],
              "members": {
                "RegexMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListRegexPatternSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "RegexPatternSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RegexPatternSetId",
                "Name"
              ],
              "members": {
                "RegexPatternSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListRuleGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "RuleGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RuleGroupId",
                "Name"
              ],
              "members": {
                "RuleGroupId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListRules": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Rules": {
            "shape": "S5p"
          }
        }
      }
    },
    "ListSizeConstraintSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "SizeConstraintSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SizeConstraintSetId",
                "Name"
              ],
              "members": {
                "SizeConstraintSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListSqlInjectionMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "SqlInjectionMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SqlInjectionMatchSetId",
                "Name"
              ],
              "members": {
                "SqlInjectionMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListSubscribedRuleGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "RuleGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RuleGroupId",
                "Name",
                "MetricName"
              ],
              "members": {
                "RuleGroupId": {},
                "Name": {},
                "MetricName": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          },
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "TagInfoForResource": {
            "type": "structure",
            "members": {
              "ResourceARN": {},
              "TagList": {
                "shape": "Sx"
              }
            }
          }
        }
      }
    },
    "ListWebACLs": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "WebACLs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WebACLId",
                "Name"
              ],
              "members": {
                "WebACLId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListXssMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "XssMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "XssMatchSetId",
                "Name"
              ],
              "members": {
                "XssMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "PutLoggingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LoggingConfiguration"
        ],
        "members": {
          "LoggingConfiguration": {
            "shape": "S3s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingConfiguration": {
            "shape": "S3s"
          }
        }
      }
    },
    "PutPermissionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Policy"
        ],
        "members": {
          "ResourceArn": {},
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "ByteMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "ByteMatchTuple"
              ],
              "members": {
                "Action": {},
                "ByteMatchTuple": {
                  "shape": "S8"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateGeoMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "GeoMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "GeoMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "GeoMatchConstraint"
              ],
              "members": {
                "Action": {},
                "GeoMatchConstraint": {
                  "shape": "Sj"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "IPSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "IPSetDescriptor"
              ],
              "members": {
                "Action": {},
                "IPSetDescriptor": {
                  "shape": "Sq"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRateBasedRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken",
          "Updates",
          "RateLimit"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {},
          "Updates": {
            "shape": "S7f"
          },
          "RateLimit": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRegexMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexMatchSetId",
          "Updates",
          "ChangeToken"
        ],
        "members": {
          "RegexMatchSetId": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "RegexMatchTuple"
              ],
              "members": {
                "Action": {},
                "RegexMatchTuple": {
                  "shape": "S1b"
                }
              }
            }
          },
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRegexPatternSet": {
      "input": {
        "type": "structure",
        "required": [
          "RegexPatternSetId",
          "Updates",
          "ChangeToken"
        ],
        "members": {
          "RegexPatternSetId": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "RegexPatternString"
              ],
              "members": {
                "Action": {},
                "RegexPatternString": {}
              }
            }
          },
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {},
          "Updates": {
            "shape": "S7f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRuleGroup": {
      "input": {
        "type": "structure",
        "required": [
          "RuleGroupId",
          "Updates",
          "ChangeToken"
        ],
        "members": {
          "RuleGroupId": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "ActivatedRule"
              ],
              "members": {
                "Action": {},
                "ActivatedRule": {
                  "shape": "S25"
                }
              }
            }
          },
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "SizeConstraintSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "SizeConstraint"
              ],
              "members": {
                "Action": {},
                "SizeConstraint": {
                  "shape": "S1r"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "SqlInjectionMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "SqlInjectionMatchTuple"
              ],
              "members": {
                "Action": {},
                "SqlInjectionMatchTuple": {
                  "shape": "S1y"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId",
          "ChangeToken"
        ],
        "members": {
          "WebACLId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "ActivatedRule"
              ],
              "members": {
                "Action": {},
                "ActivatedRule": {
                  "shape": "S25"
                }
              }
            }
          },
          "DefaultAction": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "XssMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "XssMatchTuple"
              ],
              "members": {
                "Action": {},
                "XssMatchTuple": {
                  "shape": "S2m"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "ByteMatchSetId",
        "ByteMatchTuples"
      ],
      "members": {
        "ByteMatchSetId": {},
        "Name": {},
        "ByteMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S8"
          }
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TargetString",
        "TextTransformation",
        "PositionalConstraint"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TargetString": {
          "type": "blob"
        },
        "TextTransformation": {},
        "PositionalConstraint": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {},
        "Data": {}
      }
    },
    "Sh": {
      "type": "structure",
      "required": [
        "GeoMatchSetId",
        "GeoMatchConstraints"
      ],
      "members": {
        "GeoMatchSetId": {},
        "Name": {},
        "GeoMatchConstraints": {
          "type": "list",
          "member": {
            "shape": "Sj"
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "Type",
        "Value"
      ],
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "IPSetId",
        "IPSetDescriptors"
      ],
      "members": {
        "IPSetId": {},
        "Name": {},
        "IPSetDescriptors": {
          "type": "list",
          "member": {
            "shape": "Sq"
          }
        }
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "Type",
        "Value"
      ],
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "RuleId",
        "MatchPredicates",
        "RateKey",
        "RateLimit"
      ],
      "members": {
        "RuleId": {},
        "Name": {},
        "MetricName": {},
        "MatchPredicates": {
          "shape": "S13"
        },
        "RateKey": {},
        "RateLimit": {
          "type": "long"
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "shape": "S14"
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "Negated",
        "Type",
        "DataId"
      ],
      "members": {
        "Negated": {
          "type": "boolean"
        },
        "Type": {},
        "DataId": {}
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "RegexMatchSetId": {},
        "Name": {},
        "RegexMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S1b"
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation",
        "RegexPatternSetId"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {},
        "RegexPatternSetId": {}
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "RegexPatternSetId",
        "RegexPatternStrings"
      ],
      "members": {
        "RegexPatternSetId": {},
        "Name": {},
        "RegexPatternStrings": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "RuleId",
        "Predicates"
      ],
      "members": {
        "RuleId": {},
        "Name": {},
        "MetricName": {},
        "Predicates": {
          "shape": "S13"
        }
      }
    },
    "S1m": {
      "type": "structure",
      "required": [
        "RuleGroupId"
      ],
      "members": {
        "RuleGroupId": {},
        "Name": {},
        "MetricName": {}
      }
    },
    "S1p": {
      "type": "structure",
      "required": [
        "SizeConstraintSetId",
        "SizeConstraints"
      ],
      "members": {
        "SizeConstraintSetId": {},
        "Name": {},
        "SizeConstraints": {
          "type": "list",
          "member": {
            "shape": "S1r"
          }
        }
      }
    },
    "S1r": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation",
        "ComparisonOperator",
        "Size"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {},
        "ComparisonOperator": {},
        "Size": {
          "type": "long"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "SqlInjectionMatchSetId",
        "SqlInjectionMatchTuples"
      ],
      "members": {
        "SqlInjectionMatchSetId": {},
        "Name": {},
        "SqlInjectionMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S1y"
          }
        }
      }
    },
    "S1y": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {}
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {}
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "WebACLId",
        "DefaultAction",
        "Rules"
      ],
      "members": {
        "WebACLId": {},
        "Name": {},
        "MetricName": {},
        "DefaultAction": {
          "shape": "S20"
        },
        "Rules": {
          "shape": "S24"
        },
        "WebACLArn": {}
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "shape": "S25"
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "Priority",
        "RuleId"
      ],
      "members": {
        "Priority": {
          "type": "integer"
        },
        "RuleId": {},
        "Action": {
          "shape": "S20"
        },
        "OverrideAction": {
          "type": "structure",
          "required": [
            "Type"
          ],
          "members": {
            "Type": {}
          }
        },
        "Type": {},
        "ExcludedRules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "RuleId"
            ],
            "members": {
              "RuleId": {}
            }
          }
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "XssMatchSetId",
        "XssMatchTuples"
      ],
      "members": {
        "XssMatchSetId": {},
        "Name": {},
        "XssMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S2m"
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {}
      }
    },
    "S3s": {
      "type": "structure",
      "required": [
        "ResourceArn",
        "LogDestinationConfigs"
      ],
      "members": {
        "ResourceArn": {},
        "LogDestinationConfigs": {
          "type": "list",
          "member": {}
        },
        "RedactedFields": {
          "type": "list",
          "member": {
            "shape": "S9"
          }
        }
      }
    },
    "S4e": {
      "type": "structure",
      "required": [
        "StartTime",
        "EndTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "S5p": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RuleId",
          "Name"
        ],
        "members": {
          "RuleId": {},
          "Name": {}
        }
      }
    },
    "S7f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Action",
          "Predicate"
        ],
        "members": {
          "Action": {},
          "Predicate": {
            "shape": "S14"
          }
        }
      }
    }
  }
}
},{}],221:[function(require,module,exports){
arguments[4][34][0].apply(exports,arguments)
},{"dup":34}],222:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-05-01",
    "endpointPrefix": "workdocs",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon WorkDocs",
    "serviceId": "WorkDocs",
    "signatureVersion": "v4",
    "uid": "workdocs-2016-05-01"
  },
  "operations": {
    "AbortDocumentVersionUpload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          }
        }
      }
    },
    "ActivateUser": {
      "http": {
        "requestUri": "/api/v1/users/{UserId}/activation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S8"
          }
        }
      }
    },
    "AddResourcePermissions": {
      "http": {
        "requestUri": "/api/v1/resources/{ResourceId}/permissions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Principals"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "Principals": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id",
                "Type",
                "Role"
              ],
              "members": {
                "Id": {},
                "Type": {},
                "Role": {}
              }
            }
          },
          "NotificationOptions": {
            "type": "structure",
            "members": {
              "SendEmail": {
                "type": "boolean"
              },
              "EmailMessage": {
                "shape": "St"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PrincipalId": {},
                "InviteePrincipalId": {},
                "Role": {},
                "Status": {},
                "ShareId": {},
                "StatusMessage": {
                  "shape": "St"
                }
              }
            }
          }
        }
      }
    },
    "CreateComment": {
      "http": {
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}/comment",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId",
          "Text"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          },
          "ParentId": {},
          "ThreadId": {},
          "Text": {
            "shape": "S10"
          },
          "Visibility": {},
          "NotifyCollaborators": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Comment": {
            "shape": "S13"
          }
        }
      }
    },
    "CreateCustomMetadata": {
      "http": {
        "method": "PUT",
        "requestUri": "/api/v1/resources/{ResourceId}/customMetadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "CustomMetadata"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionid"
          },
          "CustomMetadata": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateFolder": {
      "http": {
        "requestUri": "/api/v1/folders",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ParentFolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "Name": {},
          "ParentFolderId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateLabels": {
      "http": {
        "method": "PUT",
        "requestUri": "/api/v1/resources/{ResourceId}/labels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Labels"
        ],
        "members": {
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "Labels": {
            "shape": "S1g"
          },
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateNotificationSubscription": {
      "http": {
        "requestUri": "/api/v1/organizations/{OrganizationId}/subscriptions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Endpoint",
          "Protocol",
          "SubscriptionType"
        ],
        "members": {
          "OrganizationId": {
            "location": "uri",
            "locationName": "OrganizationId"
          },
          "Endpoint": {},
          "Protocol": {},
          "SubscriptionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subscription": {
            "shape": "S1p"
          }
        }
      }
    },
    "CreateUser": {
      "http": {
        "requestUri": "/api/v1/users",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "GivenName",
          "Surname",
          "Password"
        ],
        "members": {
          "OrganizationId": {},
          "Username": {},
          "EmailAddress": {},
          "GivenName": {},
          "Surname": {},
          "Password": {
            "type": "string",
            "sensitive": true
          },
          "TimeZoneId": {},
          "StorageRule": {
            "shape": "Sj"
          },
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S8"
          }
        }
      }
    },
    "DeactivateUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/users/{UserId}/activation",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          }
        }
      }
    },
    "DeleteComment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}/comment/{CommentId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId",
          "CommentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          },
          "CommentId": {
            "location": "uri",
            "locationName": "CommentId"
          }
        }
      }
    },
    "DeleteCustomMetadata": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/resources/{ResourceId}/customMetadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "Keys": {
            "location": "querystring",
            "locationName": "keys",
            "type": "list",
            "member": {}
          },
          "DeleteAll": {
            "location": "querystring",
            "locationName": "deleteAll",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDocument": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/documents/{DocumentId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          }
        }
      }
    },
    "DeleteFolder": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/folders/{FolderId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      }
    },
    "DeleteFolderContents": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/folders/{FolderId}/contents",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      }
    },
    "DeleteLabels": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/resources/{ResourceId}/labels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "Labels": {
            "shape": "S1g",
            "location": "querystring",
            "locationName": "labels"
          },
          "DeleteAll": {
            "location": "querystring",
            "locationName": "deleteAll",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNotificationSubscription": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/organizations/{OrganizationId}/subscriptions/{SubscriptionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionId",
          "OrganizationId"
        ],
        "members": {
          "SubscriptionId": {
            "location": "uri",
            "locationName": "SubscriptionId"
          },
          "OrganizationId": {
            "location": "uri",
            "locationName": "OrganizationId"
          }
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/users/{UserId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          }
        }
      }
    },
    "DescribeActivities": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/activities",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "StartTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "OrganizationId": {
            "location": "querystring",
            "locationName": "organizationId"
          },
          "ActivityTypes": {
            "location": "querystring",
            "locationName": "activityTypes"
          },
          "ResourceId": {
            "location": "querystring",
            "locationName": "resourceId"
          },
          "UserId": {
            "location": "querystring",
            "locationName": "userId"
          },
          "IncludeIndirectActivities": {
            "location": "querystring",
            "locationName": "includeIndirectActivities",
            "type": "boolean"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserActivities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "TimeStamp": {
                  "type": "timestamp"
                },
                "IsIndirectActivity": {
                  "type": "boolean"
                },
                "OrganizationId": {},
                "Initiator": {
                  "shape": "S2d"
                },
                "Participants": {
                  "type": "structure",
                  "members": {
                    "Users": {
                      "type": "list",
                      "member": {
                        "shape": "S2d"
                      }
                    },
                    "Groups": {
                      "shape": "S2g"
                    }
                  }
                },
                "ResourceMetadata": {
                  "shape": "S2j"
                },
                "OriginalParent": {
                  "shape": "S2j"
                },
                "CommentMetadata": {
                  "type": "structure",
                  "members": {
                    "CommentId": {},
                    "Contributor": {
                      "shape": "S8"
                    },
                    "CreatedTimestamp": {
                      "type": "timestamp"
                    },
                    "CommentStatus": {},
                    "RecipientId": {}
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeComments": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}/comments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Comments": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDocumentVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/documents/{DocumentId}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Include": {
            "location": "querystring",
            "locationName": "include"
          },
          "Fields": {
            "location": "querystring",
            "locationName": "fields"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentVersions": {
            "type": "list",
            "member": {
              "shape": "S2u"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeFolderContents": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/folders/{FolderId}/contents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Sort": {
            "location": "querystring",
            "locationName": "sort"
          },
          "Order": {
            "location": "querystring",
            "locationName": "order"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          },
          "Include": {
            "location": "querystring",
            "locationName": "include"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Folders": {
            "shape": "S37"
          },
          "Documents": {
            "shape": "S38"
          },
          "Marker": {}
        }
      }
    },
    "DescribeGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/groups",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SearchQuery"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "SearchQuery": {
            "shape": "S3b",
            "location": "querystring",
            "locationName": "searchQuery"
          },
          "OrganizationId": {
            "location": "querystring",
            "locationName": "organizationId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S2g"
          },
          "Marker": {}
        }
      }
    },
    "DescribeNotificationSubscriptions": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/organizations/{OrganizationId}/subscriptions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {
            "location": "uri",
            "locationName": "OrganizationId"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subscriptions": {
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeResourcePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/resources/{ResourceId}/permissions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "PrincipalId": {
            "location": "querystring",
            "locationName": "principalId"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Type": {},
                "Roles": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Role": {},
                      "Type": {}
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeRootFolders": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/me/root",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AuthenticationToken"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Folders": {
            "shape": "S37"
          },
          "Marker": {}
        }
      }
    },
    "DescribeUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/users",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "OrganizationId": {
            "location": "querystring",
            "locationName": "organizationId"
          },
          "UserIds": {
            "location": "querystring",
            "locationName": "userIds"
          },
          "Query": {
            "shape": "S3b",
            "location": "querystring",
            "locationName": "query"
          },
          "Include": {
            "location": "querystring",
            "locationName": "include"
          },
          "Order": {
            "location": "querystring",
            "locationName": "order"
          },
          "Sort": {
            "location": "querystring",
            "locationName": "sort"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Fields": {
            "location": "querystring",
            "locationName": "fields"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "TotalNumberOfUsers": {
            "deprecated": true,
            "type": "long"
          },
          "Marker": {}
        }
      }
    },
    "GetCurrentUser": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/me",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AuthenticationToken"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S8"
          }
        }
      }
    },
    "GetDocument": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/documents/{DocumentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "IncludeCustomMetadata": {
            "location": "querystring",
            "locationName": "includeCustomMetadata",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S39"
          },
          "CustomMetadata": {
            "shape": "S16"
          }
        }
      }
    },
    "GetDocumentPath": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/documents/{DocumentId}/path",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Fields": {
            "location": "querystring",
            "locationName": "fields"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Path": {
            "shape": "S42"
          }
        }
      }
    },
    "GetDocumentVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          },
          "Fields": {
            "location": "querystring",
            "locationName": "fields"
          },
          "IncludeCustomMetadata": {
            "location": "querystring",
            "locationName": "includeCustomMetadata",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S2u"
          },
          "CustomMetadata": {
            "shape": "S16"
          }
        }
      }
    },
    "GetFolder": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/folders/{FolderId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "IncludeCustomMetadata": {
            "location": "querystring",
            "locationName": "includeCustomMetadata",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S1d"
          },
          "CustomMetadata": {
            "shape": "S16"
          }
        }
      }
    },
    "GetFolderPath": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/folders/{FolderId}/path",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Fields": {
            "location": "querystring",
            "locationName": "fields"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Path": {
            "shape": "S42"
          }
        }
      }
    },
    "GetResources": {
      "http": {
        "method": "GET",
        "requestUri": "/api/v1/resources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "UserId": {
            "location": "querystring",
            "locationName": "userId"
          },
          "CollectionType": {
            "location": "querystring",
            "locationName": "collectionType"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Folders": {
            "shape": "S37"
          },
          "Documents": {
            "shape": "S38"
          },
          "Marker": {}
        }
      }
    },
    "InitiateDocumentVersionUpload": {
      "http": {
        "requestUri": "/api/v1/documents",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ParentFolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "Id": {},
          "Name": {},
          "ContentCreatedTimestamp": {
            "type": "timestamp"
          },
          "ContentModifiedTimestamp": {
            "type": "timestamp"
          },
          "ContentType": {},
          "DocumentSizeInBytes": {
            "type": "long"
          },
          "ParentFolderId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S39"
          },
          "UploadMetadata": {
            "type": "structure",
            "members": {
              "UploadUrl": {
                "shape": "S2z"
              },
              "SignedHeaders": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    },
    "RemoveAllResourcePermissions": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/resources/{ResourceId}/permissions",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          }
        }
      }
    },
    "RemoveResourcePermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/api/v1/resources/{ResourceId}/permissions/{PrincipalId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "PrincipalId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "PrincipalId": {
            "location": "uri",
            "locationName": "PrincipalId"
          },
          "PrincipalType": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      }
    },
    "UpdateDocument": {
      "http": {
        "method": "PATCH",
        "requestUri": "/api/v1/documents/{DocumentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "Name": {},
          "ParentFolderId": {},
          "ResourceState": {}
        }
      }
    },
    "UpdateDocumentVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/api/v1/documents/{DocumentId}/versions/{VersionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DocumentId",
          "VersionId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "DocumentId": {
            "location": "uri",
            "locationName": "DocumentId"
          },
          "VersionId": {
            "location": "uri",
            "locationName": "VersionId"
          },
          "VersionStatus": {}
        }
      }
    },
    "UpdateFolder": {
      "http": {
        "method": "PATCH",
        "requestUri": "/api/v1/folders/{FolderId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FolderId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Name": {},
          "ParentFolderId": {},
          "ResourceState": {}
        }
      }
    },
    "UpdateUser": {
      "http": {
        "method": "PATCH",
        "requestUri": "/api/v1/users/{UserId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "AuthenticationToken": {
            "shape": "S2",
            "location": "header",
            "locationName": "Authentication"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "GivenName": {},
          "Surname": {},
          "Type": {},
          "StorageRule": {
            "shape": "Sj"
          },
          "TimeZoneId": {},
          "Locale": {},
          "GrantPoweruserPrivileges": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S8"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "string",
      "sensitive": true
    },
    "S8": {
      "type": "structure",
      "members": {
        "Id": {},
        "Username": {},
        "EmailAddress": {},
        "GivenName": {},
        "Surname": {},
        "OrganizationId": {},
        "RootFolderId": {},
        "RecycleBinFolderId": {},
        "Status": {},
        "Type": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "ModifiedTimestamp": {
          "type": "timestamp"
        },
        "TimeZoneId": {},
        "Locale": {},
        "Storage": {
          "type": "structure",
          "members": {
            "StorageUtilizedInBytes": {
              "type": "long"
            },
            "StorageRule": {
              "shape": "Sj"
            }
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "StorageAllocatedInBytes": {
          "type": "long"
        },
        "StorageType": {}
      }
    },
    "St": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "string",
      "sensitive": true
    },
    "S13": {
      "type": "structure",
      "required": [
        "CommentId"
      ],
      "members": {
        "CommentId": {},
        "ParentId": {},
        "ThreadId": {},
        "Text": {
          "shape": "S10"
        },
        "Contributor": {
          "shape": "S8"
        },
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "Status": {},
        "Visibility": {},
        "RecipientId": {}
      }
    },
    "S16": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1d": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "CreatorId": {},
        "ParentFolderId": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "ModifiedTimestamp": {
          "type": "timestamp"
        },
        "ResourceState": {},
        "Signature": {},
        "Labels": {
          "shape": "S1g"
        },
        "Size": {
          "type": "long"
        },
        "LatestVersionSize": {
          "type": "long"
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "members": {
        "SubscriptionId": {},
        "EndPoint": {},
        "Protocol": {}
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "Id": {},
        "Username": {},
        "GivenName": {},
        "Surname": {},
        "EmailAddress": {}
      }
    },
    "S2g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {}
        }
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "Type": {},
        "Name": {},
        "OriginalName": {},
        "Id": {},
        "VersionId": {},
        "Owner": {
          "shape": "S2d"
        },
        "ParentId": {}
      }
    },
    "S2u": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "ContentType": {},
        "Size": {
          "type": "long"
        },
        "Signature": {},
        "Status": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "ModifiedTimestamp": {
          "type": "timestamp"
        },
        "ContentCreatedTimestamp": {
          "type": "timestamp"
        },
        "ContentModifiedTimestamp": {
          "type": "timestamp"
        },
        "CreatorId": {},
        "Thumbnail": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S2z"
          }
        },
        "Source": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "S2z"
          }
        }
      }
    },
    "S2z": {
      "type": "string",
      "sensitive": true
    },
    "S37": {
      "type": "list",
      "member": {
        "shape": "S1d"
      }
    },
    "S38": {
      "type": "list",
      "member": {
        "shape": "S39"
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "Id": {},
        "CreatorId": {},
        "ParentFolderId": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "ModifiedTimestamp": {
          "type": "timestamp"
        },
        "LatestVersionMetadata": {
          "shape": "S2u"
        },
        "ResourceState": {},
        "Labels": {
          "shape": "S1g"
        }
      }
    },
    "S3b": {
      "type": "string",
      "sensitive": true
    },
    "S42": {
      "type": "structure",
      "members": {
        "Components": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {}
            }
          }
        }
      }
    }
  }
}
},{}],223:[function(require,module,exports){
module.exports={
  "pagination": {
    "DescribeDocumentVersions": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "DocumentVersions"
    },
    "DescribeFolderContents": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": [
        "Folders",
        "Documents"
      ]
    },
    "DescribeUsers": {
      "input_token": "Marker",
      "limit_key": "Limit",
      "output_token": "Marker",
      "result_key": "Users"
    }
  }
}
},{}],224:[function(require,module,exports){
module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-04-12",
    "endpointPrefix": "xray",
    "protocol": "rest-json",
    "serviceFullName": "AWS X-Ray",
    "serviceId": "XRay",
    "signatureVersion": "v4",
    "uid": "xray-2016-04-12"
  },
  "operations": {
    "BatchGetTraces": {
      "http": {
        "requestUri": "/Traces"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceIds"
        ],
        "members": {
          "TraceIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Traces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Duration": {
                  "type": "double"
                },
                "Segments": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Id": {},
                      "Document": {}
                    }
                  }
                }
              }
            }
          },
          "UnprocessedTraceIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "CreateGroup": {
      "http": {
        "requestUri": "/CreateGroup"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "FilterExpression": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "CreateSamplingRule": {
      "http": {
        "requestUri": "/CreateSamplingRule"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingRule"
        ],
        "members": {
          "SamplingRule": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "requestUri": "/DeleteGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSamplingRule": {
      "http": {
        "requestUri": "/DeleteSamplingRule"
      },
      "input": {
        "type": "structure",
        "members": {
          "RuleName": {},
          "RuleARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    },
    "GetEncryptionConfig": {
      "http": {
        "requestUri": "/EncryptionConfig"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "EncryptionConfig": {
            "shape": "S19"
          }
        }
      }
    },
    "GetGroup": {
      "http": {
        "requestUri": "/GetGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "GetGroups": {
      "http": {
        "requestUri": "/Groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "GroupARN": {},
                "FilterExpression": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingRules": {
      "http": {
        "requestUri": "/GetSamplingRules"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecords": {
            "type": "list",
            "member": {
              "shape": "S10"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingStatisticSummaries": {
      "http": {
        "requestUri": "/SamplingStatisticSummaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingStatisticSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "RequestCount": {
                  "type": "integer"
                },
                "BorrowCount": {
                  "type": "integer"
                },
                "SampledCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingTargets": {
      "http": {
        "requestUri": "/SamplingTargets"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingStatisticsDocuments"
        ],
        "members": {
          "SamplingStatisticsDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RuleName",
                "ClientID",
                "Timestamp",
                "RequestCount",
                "SampledCount"
              ],
              "members": {
                "RuleName": {},
                "ClientID": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "RequestCount": {
                  "type": "integer"
                },
                "SampledCount": {
                  "type": "integer"
                },
                "BorrowCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingTargetDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "FixedRate": {
                  "type": "double"
                },
                "ReservoirQuota": {
                  "type": "integer"
                },
                "ReservoirQuotaTTL": {
                  "type": "timestamp"
                },
                "Interval": {
                  "type": "integer"
                }
              }
            }
          },
          "LastRuleModification": {
            "type": "timestamp"
          },
          "UnprocessedStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "ErrorCode": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "GetServiceGraph": {
      "http": {
        "requestUri": "/ServiceGraph"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "GroupName": {},
          "GroupARN": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Services": {
            "shape": "S27"
          },
          "ContainsOldGroupVersions": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      }
    },
    "GetTimeSeriesServiceStatistics": {
      "http": {
        "requestUri": "/TimeSeriesServiceStatistics"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "GroupName": {},
          "GroupARN": {},
          "EntitySelectorExpression": {},
          "Period": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeSeriesServiceStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "EdgeSummaryStatistics": {
                  "shape": "S2d"
                },
                "ServiceSummaryStatistics": {
                  "shape": "S2m"
                },
                "ResponseTimeHistogram": {
                  "shape": "S2h"
                }
              }
            }
          },
          "ContainsOldGroupVersions": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      }
    },
    "GetTraceGraph": {
      "http": {
        "requestUri": "/TraceGraph"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceIds"
        ],
        "members": {
          "TraceIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Services": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "GetTraceSummaries": {
      "http": {
        "requestUri": "/TraceSummaries"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "TimeRangeType": {},
          "Sampling": {
            "type": "boolean"
          },
          "SamplingStrategy": {
            "type": "structure",
            "members": {
              "Name": {},
              "Value": {
                "type": "double"
              }
            }
          },
          "FilterExpression": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TraceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Duration": {
                  "type": "double"
                },
                "ResponseTime": {
                  "type": "double"
                },
                "HasFault": {
                  "type": "boolean"
                },
                "HasError": {
                  "type": "boolean"
                },
                "HasThrottle": {
                  "type": "boolean"
                },
                "IsPartial": {
                  "type": "boolean"
                },
                "Http": {
                  "type": "structure",
                  "members": {
                    "HttpURL": {},
                    "HttpStatus": {
                      "type": "integer"
                    },
                    "HttpMethod": {},
                    "UserAgent": {},
                    "ClientIp": {}
                  }
                },
                "Annotations": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "AnnotationValue": {
                          "type": "structure",
                          "members": {
                            "NumberValue": {
                              "type": "double"
                            },
                            "BooleanValue": {
                              "type": "boolean"
                            },
                            "StringValue": {}
                          }
                        },
                        "ServiceIds": {
                          "shape": "S38"
                        }
                      }
                    }
                  }
                },
                "Users": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "UserName": {},
                      "ServiceIds": {
                        "shape": "S38"
                      }
                    }
                  }
                },
                "ServiceIds": {
                  "shape": "S38"
                },
                "ResourceARNs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ARN": {}
                    }
                  }
                },
                "InstanceIds": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Id": {}
                    }
                  }
                },
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {}
                    }
                  }
                },
                "EntryPoint": {
                  "shape": "S39"
                },
                "FaultRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Exceptions": {
                                    "shape": "S3o"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      },
                      "ClientImpacting": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "ErrorRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Exceptions": {
                                    "shape": "S3o"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      },
                      "ClientImpacting": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "ResponseTimeRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Coverage": {
                                    "type": "double"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      },
                      "ClientImpacting": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "Revision": {
                  "type": "integer"
                },
                "MatchedEventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "ApproximateTime": {
            "type": "timestamp"
          },
          "TracesProcessedCount": {
            "type": "long"
          },
          "NextToken": {}
        }
      }
    },
    "PutEncryptionConfig": {
      "http": {
        "requestUri": "/PutEncryptionConfig"
      },
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "KeyId": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EncryptionConfig": {
            "shape": "S19"
          }
        }
      }
    },
    "PutTelemetryRecords": {
      "http": {
        "requestUri": "/TelemetryRecords"
      },
      "input": {
        "type": "structure",
        "required": [
          "TelemetryRecords"
        ],
        "members": {
          "TelemetryRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Timestamp"
              ],
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "SegmentsReceivedCount": {
                  "type": "integer"
                },
                "SegmentsSentCount": {
                  "type": "integer"
                },
                "SegmentsSpilloverCount": {
                  "type": "integer"
                },
                "SegmentsRejectedCount": {
                  "type": "integer"
                },
                "BackendConnectionErrors": {
                  "type": "structure",
                  "members": {
                    "TimeoutCount": {
                      "type": "integer"
                    },
                    "ConnectionRefusedCount": {
                      "type": "integer"
                    },
                    "HTTPCode4XXCount": {
                      "type": "integer"
                    },
                    "HTTPCode5XXCount": {
                      "type": "integer"
                    },
                    "UnknownHostCount": {
                      "type": "integer"
                    },
                    "OtherCount": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "EC2InstanceId": {},
          "Hostname": {},
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutTraceSegments": {
      "http": {
        "requestUri": "/TraceSegments"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceSegmentDocuments"
        ],
        "members": {
          "TraceSegmentDocuments": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedTraceSegments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ErrorCode": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "requestUri": "/UpdateGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {},
          "FilterExpression": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "UpdateSamplingRule": {
      "http": {
        "requestUri": "/UpdateSamplingRule"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingRuleUpdate"
        ],
        "members": {
          "SamplingRuleUpdate": {
            "type": "structure",
            "members": {
              "RuleName": {},
              "RuleARN": {},
              "ResourceARN": {},
              "Priority": {
                "type": "integer"
              },
              "FixedRate": {
                "type": "double"
              },
              "ReservoirSize": {
                "type": "integer"
              },
              "Host": {},
              "ServiceName": {},
              "ServiceType": {},
              "HTTPMethod": {},
              "URLPath": {},
              "Attributes": {
                "shape": "Sw"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "members": {
        "GroupName": {},
        "GroupARN": {},
        "FilterExpression": {}
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "ResourceARN",
        "Priority",
        "FixedRate",
        "ReservoirSize",
        "ServiceName",
        "ServiceType",
        "Host",
        "HTTPMethod",
        "URLPath",
        "Version"
      ],
      "members": {
        "RuleName": {},
        "RuleARN": {},
        "ResourceARN": {},
        "Priority": {
          "type": "integer"
        },
        "FixedRate": {
          "type": "double"
        },
        "ReservoirSize": {
          "type": "integer"
        },
        "ServiceName": {},
        "ServiceType": {},
        "Host": {},
        "HTTPMethod": {},
        "URLPath": {},
        "Version": {
          "type": "integer"
        },
        "Attributes": {
          "shape": "Sw"
        }
      }
    },
    "Sw": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "structure",
      "members": {
        "SamplingRule": {
          "shape": "Sk"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "ModifiedAt": {
          "type": "timestamp"
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "KeyId": {},
        "Status": {},
        "Type": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ReferenceId": {
            "type": "integer"
          },
          "Name": {},
          "Names": {
            "shape": "S29"
          },
          "Root": {
            "type": "boolean"
          },
          "AccountId": {},
          "Type": {},
          "State": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Edges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReferenceId": {
                  "type": "integer"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "SummaryStatistics": {
                  "shape": "S2d"
                },
                "ResponseTimeHistogram": {
                  "shape": "S2h"
                },
                "Aliases": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Names": {
                        "type": "list",
                        "member": {}
                      },
                      "Type": {}
                    }
                  }
                }
              }
            }
          },
          "SummaryStatistics": {
            "shape": "S2m"
          },
          "DurationHistogram": {
            "shape": "S2h"
          },
          "ResponseTimeHistogram": {
            "shape": "S2h"
          }
        }
      }
    },
    "S29": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "structure",
      "members": {
        "OkCount": {
          "type": "long"
        },
        "ErrorStatistics": {
          "shape": "S2f"
        },
        "FaultStatistics": {
          "shape": "S2g"
        },
        "TotalCount": {
          "type": "long"
        },
        "TotalResponseTime": {
          "type": "double"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "ThrottleCount": {
          "type": "long"
        },
        "OtherCount": {
          "type": "long"
        },
        "TotalCount": {
          "type": "long"
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "OtherCount": {
          "type": "long"
        },
        "TotalCount": {
          "type": "long"
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Value": {
            "type": "double"
          },
          "Count": {
            "type": "integer"
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "OkCount": {
          "type": "long"
        },
        "ErrorStatistics": {
          "shape": "S2f"
        },
        "FaultStatistics": {
          "shape": "S2g"
        },
        "TotalCount": {
          "type": "long"
        },
        "TotalResponseTime": {
          "type": "double"
        }
      }
    },
    "S38": {
      "type": "list",
      "member": {
        "shape": "S39"
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "Name": {},
        "Names": {
          "shape": "S29"
        },
        "AccountId": {},
        "Type": {}
      }
    },
    "S3o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Message": {}
        }
      }
    }
  }
}
},{}],225:[function(require,module,exports){
module.exports={
  "pagination": {
    "BatchGetTraces": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "UnprocessedTraceIds"
      ],
      "output_token": "NextToken",
      "result_key": "Traces"
    },
    "GetGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Groups"
    },
    "GetSamplingRules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "SamplingRuleRecords"
    },
    "GetSamplingStatisticSummaries": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "SamplingStatisticSummaries"
    },
    "GetServiceGraph": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "StartTime",
        "EndTime",
        "ContainsOldGroupVersions"
      ],
      "output_token": "NextToken",
      "result_key": "Services"
    },
    "GetTimeSeriesServiceStatistics": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "ContainsOldGroupVersions"
      ],
      "output_token": "NextToken",
      "result_key": "TimeSeriesServiceStatistics"
    },
    "GetTraceGraph": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Services"
    },
    "GetTraceSummaries": {
      "input_token": "NextToken",
      "non_aggregate_keys": [
        "TracesProcessedCount",
        "ApproximateTime"
      ],
      "output_token": "NextToken",
      "result_key": "TraceSummaries"
    }
  }
}
},{}],226:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['acm'] = {};
AWS.ACM = Service.defineService('acm', ['2015-12-08']);
Object.defineProperty(apiLoader.services['acm'], '2015-12-08', {
  get: function get() {
    var model = require('../apis/acm-2015-12-08.min.json');
    model.paginators = require('../apis/acm-2015-12-08.paginators.json').pagination;
    model.waiters = require('../apis/acm-2015-12-08.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ACM;

},{"../apis/acm-2015-12-08.min.json":1,"../apis/acm-2015-12-08.paginators.json":2,"../apis/acm-2015-12-08.waiters2.json":3,"../lib/core":324,"../lib/node_loader":320}],227:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['apigateway'] = {};
AWS.APIGateway = Service.defineService('apigateway', ['2015-07-09']);
require('../lib/services/apigateway');
Object.defineProperty(apiLoader.services['apigateway'], '2015-07-09', {
  get: function get() {
    var model = require('../apis/apigateway-2015-07-09.min.json');
    model.paginators = require('../apis/apigateway-2015-07-09.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.APIGateway;

},{"../apis/apigateway-2015-07-09.min.json":4,"../apis/apigateway-2015-07-09.paginators.json":5,"../lib/core":324,"../lib/node_loader":320,"../lib/services/apigateway":375}],228:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['applicationautoscaling'] = {};
AWS.ApplicationAutoScaling = Service.defineService('applicationautoscaling', ['2016-02-06']);
Object.defineProperty(apiLoader.services['applicationautoscaling'], '2016-02-06', {
  get: function get() {
    var model = require('../apis/application-autoscaling-2016-02-06.min.json');
    model.paginators = require('../apis/application-autoscaling-2016-02-06.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ApplicationAutoScaling;

},{"../apis/application-autoscaling-2016-02-06.min.json":6,"../apis/application-autoscaling-2016-02-06.paginators.json":7,"../lib/core":324,"../lib/node_loader":320}],229:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['autoscaling'] = {};
AWS.AutoScaling = Service.defineService('autoscaling', ['2011-01-01']);
Object.defineProperty(apiLoader.services['autoscaling'], '2011-01-01', {
  get: function get() {
    var model = require('../apis/autoscaling-2011-01-01.min.json');
    model.paginators = require('../apis/autoscaling-2011-01-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.AutoScaling;

},{"../apis/autoscaling-2011-01-01.min.json":8,"../apis/autoscaling-2011-01-01.paginators.json":9,"../lib/core":324,"../lib/node_loader":320}],230:[function(require,module,exports){
require('../lib/node_loader');
module.exports = {
  ACM: require('./acm'),
  APIGateway: require('./apigateway'),
  ApplicationAutoScaling: require('./applicationautoscaling'),
  AutoScaling: require('./autoscaling'),
  CloudFormation: require('./cloudformation'),
  CloudFront: require('./cloudfront'),
  CloudHSM: require('./cloudhsm'),
  CloudTrail: require('./cloudtrail'),
  CloudWatch: require('./cloudwatch'),
  CloudWatchEvents: require('./cloudwatchevents'),
  CloudWatchLogs: require('./cloudwatchlogs'),
  CodeBuild: require('./codebuild'),
  CodeCommit: require('./codecommit'),
  CodeDeploy: require('./codedeploy'),
  CodePipeline: require('./codepipeline'),
  CognitoIdentity: require('./cognitoidentity'),
  CognitoIdentityServiceProvider: require('./cognitoidentityserviceprovider'),
  CognitoSync: require('./cognitosync'),
  ConfigService: require('./configservice'),
  CUR: require('./cur'),
  DeviceFarm: require('./devicefarm'),
  DirectConnect: require('./directconnect'),
  DynamoDB: require('./dynamodb'),
  DynamoDBStreams: require('./dynamodbstreams'),
  EC2: require('./ec2'),
  ECR: require('./ecr'),
  ECS: require('./ecs'),
  EFS: require('./efs'),
  ElastiCache: require('./elasticache'),
  ElasticBeanstalk: require('./elasticbeanstalk'),
  ELB: require('./elb'),
  ELBv2: require('./elbv2'),
  EMR: require('./emr'),
  ElasticTranscoder: require('./elastictranscoder'),
  Firehose: require('./firehose'),
  GameLift: require('./gamelift'),
  IAM: require('./iam'),
  Inspector: require('./inspector'),
  Iot: require('./iot'),
  IotData: require('./iotdata'),
  Kinesis: require('./kinesis'),
  KMS: require('./kms'),
  Lambda: require('./lambda'),
  LexRuntime: require('./lexruntime'),
  MachineLearning: require('./machinelearning'),
  MarketplaceCommerceAnalytics: require('./marketplacecommerceanalytics'),
  MTurk: require('./mturk'),
  MobileAnalytics: require('./mobileanalytics'),
  OpsWorks: require('./opsworks'),
  Polly: require('./polly'),
  RDS: require('./rds'),
  Redshift: require('./redshift'),
  Rekognition: require('./rekognition'),
  Route53: require('./route53'),
  Route53Domains: require('./route53domains'),
  S3: require('./s3'),
  ServiceCatalog: require('./servicecatalog'),
  SES: require('./ses'),
  SNS: require('./sns'),
  SQS: require('./sqs'),
  SSM: require('./ssm'),
  StorageGateway: require('./storagegateway'),
  STS: require('./sts'),
  XRay: require('./xray'),
  WAF: require('./waf'),
  WorkDocs: require('./workdocs'),
  LexModelBuildingService: require('./lexmodelbuildingservice'),
  Pricing: require('./pricing'),
  CostExplorer: require('./costexplorer'),
  MediaStoreData: require('./mediastoredata'),
  Comprehend: require('./comprehend'),
  KinesisVideoArchivedMedia: require('./kinesisvideoarchivedmedia'),
  KinesisVideoMedia: require('./kinesisvideomedia'),
  KinesisVideo: require('./kinesisvideo'),
  Translate: require('./translate'),
  ResourceGroups: require('./resourcegroups'),
  Connect: require('./connect'),
  SecretsManager: require('./secretsmanager'),
  IoTAnalytics: require('./iotanalytics'),
  ComprehendMedical: require('./comprehendmedical'),
  Personalize: require('./personalize'),
  PersonalizeEvents: require('./personalizeevents'),
  PersonalizeRuntime: require('./personalizeruntime'),
  ForecastService: require('./forecastservice'),
  ForecastQueryService: require('./forecastqueryservice'),
  KinesisVideoSignalingChannels: require('./kinesisvideosignalingchannels')
};
},{"../lib/node_loader":320,"./acm":226,"./apigateway":227,"./applicationautoscaling":228,"./autoscaling":229,"./cloudformation":231,"./cloudfront":232,"./cloudhsm":233,"./cloudtrail":234,"./cloudwatch":235,"./cloudwatchevents":236,"./cloudwatchlogs":237,"./codebuild":238,"./codecommit":239,"./codedeploy":240,"./codepipeline":241,"./cognitoidentity":242,"./cognitoidentityserviceprovider":243,"./cognitosync":244,"./comprehend":245,"./comprehendmedical":246,"./configservice":247,"./connect":248,"./costexplorer":249,"./cur":250,"./devicefarm":251,"./directconnect":252,"./dynamodb":253,"./dynamodbstreams":254,"./ec2":255,"./ecr":256,"./ecs":257,"./efs":258,"./elasticache":259,"./elasticbeanstalk":260,"./elastictranscoder":261,"./elb":262,"./elbv2":263,"./emr":264,"./firehose":265,"./forecastqueryservice":266,"./forecastservice":267,"./gamelift":268,"./iam":269,"./inspector":270,"./iot":271,"./iotanalytics":272,"./iotdata":273,"./kinesis":274,"./kinesisvideo":275,"./kinesisvideoarchivedmedia":276,"./kinesisvideomedia":277,"./kinesisvideosignalingchannels":278,"./kms":279,"./lambda":280,"./lexmodelbuildingservice":281,"./lexruntime":282,"./machinelearning":283,"./marketplacecommerceanalytics":284,"./mediastoredata":285,"./mobileanalytics":286,"./mturk":287,"./opsworks":288,"./personalize":289,"./personalizeevents":290,"./personalizeruntime":291,"./polly":292,"./pricing":293,"./rds":294,"./redshift":295,"./rekognition":296,"./resourcegroups":297,"./route53":298,"./route53domains":299,"./s3":300,"./secretsmanager":301,"./servicecatalog":302,"./ses":303,"./sns":304,"./sqs":305,"./ssm":306,"./storagegateway":307,"./sts":308,"./translate":309,"./waf":310,"./workdocs":311,"./xray":312}],231:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudformation'] = {};
AWS.CloudFormation = Service.defineService('cloudformation', ['2010-05-15']);
Object.defineProperty(apiLoader.services['cloudformation'], '2010-05-15', {
  get: function get() {
    var model = require('../apis/cloudformation-2010-05-15.min.json');
    model.paginators = require('../apis/cloudformation-2010-05-15.paginators.json').pagination;
    model.waiters = require('../apis/cloudformation-2010-05-15.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudFormation;

},{"../apis/cloudformation-2010-05-15.min.json":12,"../apis/cloudformation-2010-05-15.paginators.json":13,"../apis/cloudformation-2010-05-15.waiters2.json":14,"../lib/core":324,"../lib/node_loader":320}],232:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudfront'] = {};
AWS.CloudFront = Service.defineService('cloudfront', ['2013-05-12*', '2013-11-11*', '2014-05-31*', '2014-10-21*', '2014-11-06*', '2015-04-17*', '2015-07-27*', '2015-09-17*', '2016-01-13*', '2016-01-28*', '2016-08-01*', '2016-08-20*', '2016-09-07*', '2016-09-29*', '2016-11-25', '2016-11-25*', '2017-03-25', '2017-03-25*', '2017-10-30', '2017-10-30*', '2018-06-18', '2018-06-18*', '2018-11-05', '2018-11-05*', '2019-03-26']);
require('../lib/services/cloudfront');
Object.defineProperty(apiLoader.services['cloudfront'], '2016-11-25', {
  get: function get() {
    var model = require('../apis/cloudfront-2016-11-25.min.json');
    model.paginators = require('../apis/cloudfront-2016-11-25.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2016-11-25.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['cloudfront'], '2017-03-25', {
  get: function get() {
    var model = require('../apis/cloudfront-2017-03-25.min.json');
    model.paginators = require('../apis/cloudfront-2017-03-25.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2017-03-25.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['cloudfront'], '2017-10-30', {
  get: function get() {
    var model = require('../apis/cloudfront-2017-10-30.min.json');
    model.paginators = require('../apis/cloudfront-2017-10-30.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2017-10-30.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['cloudfront'], '2018-06-18', {
  get: function get() {
    var model = require('../apis/cloudfront-2018-06-18.min.json');
    model.paginators = require('../apis/cloudfront-2018-06-18.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2018-06-18.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['cloudfront'], '2018-11-05', {
  get: function get() {
    var model = require('../apis/cloudfront-2018-11-05.min.json');
    model.paginators = require('../apis/cloudfront-2018-11-05.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2018-11-05.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['cloudfront'], '2019-03-26', {
  get: function get() {
    var model = require('../apis/cloudfront-2019-03-26.min.json');
    model.paginators = require('../apis/cloudfront-2019-03-26.paginators.json').pagination;
    model.waiters = require('../apis/cloudfront-2019-03-26.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudFront;

},{"../apis/cloudfront-2016-11-25.min.json":15,"../apis/cloudfront-2016-11-25.paginators.json":16,"../apis/cloudfront-2016-11-25.waiters2.json":17,"../apis/cloudfront-2017-03-25.min.json":18,"../apis/cloudfront-2017-03-25.paginators.json":19,"../apis/cloudfront-2017-03-25.waiters2.json":20,"../apis/cloudfront-2017-10-30.min.json":21,"../apis/cloudfront-2017-10-30.paginators.json":22,"../apis/cloudfront-2017-10-30.waiters2.json":23,"../apis/cloudfront-2018-06-18.min.json":24,"../apis/cloudfront-2018-06-18.paginators.json":25,"../apis/cloudfront-2018-06-18.waiters2.json":26,"../apis/cloudfront-2018-11-05.min.json":27,"../apis/cloudfront-2018-11-05.paginators.json":28,"../apis/cloudfront-2018-11-05.waiters2.json":29,"../apis/cloudfront-2019-03-26.min.json":30,"../apis/cloudfront-2019-03-26.paginators.json":31,"../apis/cloudfront-2019-03-26.waiters2.json":32,"../lib/core":324,"../lib/node_loader":320,"../lib/services/cloudfront":376}],233:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudhsm'] = {};
AWS.CloudHSM = Service.defineService('cloudhsm', ['2014-05-30']);
Object.defineProperty(apiLoader.services['cloudhsm'], '2014-05-30', {
  get: function get() {
    var model = require('../apis/cloudhsm-2014-05-30.min.json');
    model.paginators = require('../apis/cloudhsm-2014-05-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudHSM;

},{"../apis/cloudhsm-2014-05-30.min.json":33,"../apis/cloudhsm-2014-05-30.paginators.json":34,"../lib/core":324,"../lib/node_loader":320}],234:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudtrail'] = {};
AWS.CloudTrail = Service.defineService('cloudtrail', ['2013-11-01']);
Object.defineProperty(apiLoader.services['cloudtrail'], '2013-11-01', {
  get: function get() {
    var model = require('../apis/cloudtrail-2013-11-01.min.json');
    model.paginators = require('../apis/cloudtrail-2013-11-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudTrail;

},{"../apis/cloudtrail-2013-11-01.min.json":35,"../apis/cloudtrail-2013-11-01.paginators.json":36,"../lib/core":324,"../lib/node_loader":320}],235:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudwatch'] = {};
AWS.CloudWatch = Service.defineService('cloudwatch', ['2010-08-01']);
Object.defineProperty(apiLoader.services['cloudwatch'], '2010-08-01', {
  get: function get() {
    var model = require('../apis/monitoring-2010-08-01.min.json');
    model.paginators = require('../apis/monitoring-2010-08-01.paginators.json').pagination;
    model.waiters = require('../apis/monitoring-2010-08-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudWatch;

},{"../apis/monitoring-2010-08-01.min.json":154,"../apis/monitoring-2010-08-01.paginators.json":155,"../apis/monitoring-2010-08-01.waiters2.json":156,"../lib/core":324,"../lib/node_loader":320}],236:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudwatchevents'] = {};
AWS.CloudWatchEvents = Service.defineService('cloudwatchevents', ['2014-02-03*', '2015-10-07']);
Object.defineProperty(apiLoader.services['cloudwatchevents'], '2015-10-07', {
  get: function get() {
    var model = require('../apis/events-2015-10-07.min.json');
    model.paginators = require('../apis/events-2015-10-07.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudWatchEvents;

},{"../apis/events-2015-10-07.min.json":102,"../apis/events-2015-10-07.paginators.json":103,"../lib/core":324,"../lib/node_loader":320}],237:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cloudwatchlogs'] = {};
AWS.CloudWatchLogs = Service.defineService('cloudwatchlogs', ['2014-03-28']);
Object.defineProperty(apiLoader.services['cloudwatchlogs'], '2014-03-28', {
  get: function get() {
    var model = require('../apis/logs-2014-03-28.min.json');
    model.paginators = require('../apis/logs-2014-03-28.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CloudWatchLogs;

},{"../apis/logs-2014-03-28.min.json":143,"../apis/logs-2014-03-28.paginators.json":144,"../lib/core":324,"../lib/node_loader":320}],238:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['codebuild'] = {};
AWS.CodeBuild = Service.defineService('codebuild', ['2016-10-06']);
Object.defineProperty(apiLoader.services['codebuild'], '2016-10-06', {
  get: function get() {
    var model = require('../apis/codebuild-2016-10-06.min.json');
    model.paginators = require('../apis/codebuild-2016-10-06.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CodeBuild;

},{"../apis/codebuild-2016-10-06.min.json":37,"../apis/codebuild-2016-10-06.paginators.json":38,"../lib/core":324,"../lib/node_loader":320}],239:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['codecommit'] = {};
AWS.CodeCommit = Service.defineService('codecommit', ['2015-04-13']);
Object.defineProperty(apiLoader.services['codecommit'], '2015-04-13', {
  get: function get() {
    var model = require('../apis/codecommit-2015-04-13.min.json');
    model.paginators = require('../apis/codecommit-2015-04-13.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CodeCommit;

},{"../apis/codecommit-2015-04-13.min.json":39,"../apis/codecommit-2015-04-13.paginators.json":40,"../lib/core":324,"../lib/node_loader":320}],240:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['codedeploy'] = {};
AWS.CodeDeploy = Service.defineService('codedeploy', ['2014-10-06']);
Object.defineProperty(apiLoader.services['codedeploy'], '2014-10-06', {
  get: function get() {
    var model = require('../apis/codedeploy-2014-10-06.min.json');
    model.paginators = require('../apis/codedeploy-2014-10-06.paginators.json').pagination;
    model.waiters = require('../apis/codedeploy-2014-10-06.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CodeDeploy;

},{"../apis/codedeploy-2014-10-06.min.json":41,"../apis/codedeploy-2014-10-06.paginators.json":42,"../apis/codedeploy-2014-10-06.waiters2.json":43,"../lib/core":324,"../lib/node_loader":320}],241:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['codepipeline'] = {};
AWS.CodePipeline = Service.defineService('codepipeline', ['2015-07-09']);
Object.defineProperty(apiLoader.services['codepipeline'], '2015-07-09', {
  get: function get() {
    var model = require('../apis/codepipeline-2015-07-09.min.json');
    model.paginators = require('../apis/codepipeline-2015-07-09.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CodePipeline;

},{"../apis/codepipeline-2015-07-09.min.json":44,"../apis/codepipeline-2015-07-09.paginators.json":45,"../lib/core":324,"../lib/node_loader":320}],242:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cognitoidentity'] = {};
AWS.CognitoIdentity = Service.defineService('cognitoidentity', ['2014-06-30']);
require('../lib/services/cognitoidentity');
Object.defineProperty(apiLoader.services['cognitoidentity'], '2014-06-30', {
  get: function get() {
    var model = require('../apis/cognito-identity-2014-06-30.min.json');
    model.paginators = require('../apis/cognito-identity-2014-06-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CognitoIdentity;

},{"../apis/cognito-identity-2014-06-30.min.json":46,"../apis/cognito-identity-2014-06-30.paginators.json":47,"../lib/core":324,"../lib/node_loader":320,"../lib/services/cognitoidentity":377}],243:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cognitoidentityserviceprovider'] = {};
AWS.CognitoIdentityServiceProvider = Service.defineService('cognitoidentityserviceprovider', ['2016-04-18']);
Object.defineProperty(apiLoader.services['cognitoidentityserviceprovider'], '2016-04-18', {
  get: function get() {
    var model = require('../apis/cognito-idp-2016-04-18.min.json');
    model.paginators = require('../apis/cognito-idp-2016-04-18.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CognitoIdentityServiceProvider;

},{"../apis/cognito-idp-2016-04-18.min.json":48,"../apis/cognito-idp-2016-04-18.paginators.json":49,"../lib/core":324,"../lib/node_loader":320}],244:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cognitosync'] = {};
AWS.CognitoSync = Service.defineService('cognitosync', ['2014-06-30']);
Object.defineProperty(apiLoader.services['cognitosync'], '2014-06-30', {
  get: function get() {
    var model = require('../apis/cognito-sync-2014-06-30.min.json');
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CognitoSync;

},{"../apis/cognito-sync-2014-06-30.min.json":50,"../lib/core":324,"../lib/node_loader":320}],245:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['comprehend'] = {};
AWS.Comprehend = Service.defineService('comprehend', ['2017-11-27']);
Object.defineProperty(apiLoader.services['comprehend'], '2017-11-27', {
  get: function get() {
    var model = require('../apis/comprehend-2017-11-27.min.json');
    model.paginators = require('../apis/comprehend-2017-11-27.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Comprehend;

},{"../apis/comprehend-2017-11-27.min.json":51,"../apis/comprehend-2017-11-27.paginators.json":52,"../lib/core":324,"../lib/node_loader":320}],246:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['comprehendmedical'] = {};
AWS.ComprehendMedical = Service.defineService('comprehendmedical', ['2018-10-30']);
Object.defineProperty(apiLoader.services['comprehendmedical'], '2018-10-30', {
  get: function get() {
    var model = require('../apis/comprehendmedical-2018-10-30.min.json');
    model.paginators = require('../apis/comprehendmedical-2018-10-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ComprehendMedical;

},{"../apis/comprehendmedical-2018-10-30.min.json":53,"../apis/comprehendmedical-2018-10-30.paginators.json":54,"../lib/core":324,"../lib/node_loader":320}],247:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['configservice'] = {};
AWS.ConfigService = Service.defineService('configservice', ['2014-11-12']);
Object.defineProperty(apiLoader.services['configservice'], '2014-11-12', {
  get: function get() {
    var model = require('../apis/config-2014-11-12.min.json');
    model.paginators = require('../apis/config-2014-11-12.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ConfigService;

},{"../apis/config-2014-11-12.min.json":55,"../apis/config-2014-11-12.paginators.json":56,"../lib/core":324,"../lib/node_loader":320}],248:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['connect'] = {};
AWS.Connect = Service.defineService('connect', ['2017-08-08']);
Object.defineProperty(apiLoader.services['connect'], '2017-08-08', {
  get: function get() {
    var model = require('../apis/connect-2017-08-08.min.json');
    model.paginators = require('../apis/connect-2017-08-08.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Connect;

},{"../apis/connect-2017-08-08.min.json":57,"../apis/connect-2017-08-08.paginators.json":58,"../lib/core":324,"../lib/node_loader":320}],249:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['costexplorer'] = {};
AWS.CostExplorer = Service.defineService('costexplorer', ['2017-10-25']);
Object.defineProperty(apiLoader.services['costexplorer'], '2017-10-25', {
  get: function get() {
    var model = require('../apis/ce-2017-10-25.min.json');
    model.paginators = require('../apis/ce-2017-10-25.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CostExplorer;

},{"../apis/ce-2017-10-25.min.json":10,"../apis/ce-2017-10-25.paginators.json":11,"../lib/core":324,"../lib/node_loader":320}],250:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['cur'] = {};
AWS.CUR = Service.defineService('cur', ['2017-01-06']);
Object.defineProperty(apiLoader.services['cur'], '2017-01-06', {
  get: function get() {
    var model = require('../apis/cur-2017-01-06.min.json');
    model.paginators = require('../apis/cur-2017-01-06.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.CUR;

},{"../apis/cur-2017-01-06.min.json":59,"../apis/cur-2017-01-06.paginators.json":60,"../lib/core":324,"../lib/node_loader":320}],251:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['devicefarm'] = {};
AWS.DeviceFarm = Service.defineService('devicefarm', ['2015-06-23']);
Object.defineProperty(apiLoader.services['devicefarm'], '2015-06-23', {
  get: function get() {
    var model = require('../apis/devicefarm-2015-06-23.min.json');
    model.paginators = require('../apis/devicefarm-2015-06-23.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.DeviceFarm;

},{"../apis/devicefarm-2015-06-23.min.json":61,"../apis/devicefarm-2015-06-23.paginators.json":62,"../lib/core":324,"../lib/node_loader":320}],252:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['directconnect'] = {};
AWS.DirectConnect = Service.defineService('directconnect', ['2012-10-25']);
Object.defineProperty(apiLoader.services['directconnect'], '2012-10-25', {
  get: function get() {
    var model = require('../apis/directconnect-2012-10-25.min.json');
    model.paginators = require('../apis/directconnect-2012-10-25.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.DirectConnect;

},{"../apis/directconnect-2012-10-25.min.json":63,"../apis/directconnect-2012-10-25.paginators.json":64,"../lib/core":324,"../lib/node_loader":320}],253:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['dynamodb'] = {};
AWS.DynamoDB = Service.defineService('dynamodb', ['2011-12-05', '2012-08-10']);
require('../lib/services/dynamodb');
Object.defineProperty(apiLoader.services['dynamodb'], '2011-12-05', {
  get: function get() {
    var model = require('../apis/dynamodb-2011-12-05.min.json');
    model.paginators = require('../apis/dynamodb-2011-12-05.paginators.json').pagination;
    model.waiters = require('../apis/dynamodb-2011-12-05.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['dynamodb'], '2012-08-10', {
  get: function get() {
    var model = require('../apis/dynamodb-2012-08-10.min.json');
    model.paginators = require('../apis/dynamodb-2012-08-10.paginators.json').pagination;
    model.waiters = require('../apis/dynamodb-2012-08-10.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.DynamoDB;

},{"../apis/dynamodb-2011-12-05.min.json":65,"../apis/dynamodb-2011-12-05.paginators.json":66,"../apis/dynamodb-2011-12-05.waiters2.json":67,"../apis/dynamodb-2012-08-10.min.json":68,"../apis/dynamodb-2012-08-10.paginators.json":69,"../apis/dynamodb-2012-08-10.waiters2.json":70,"../lib/core":324,"../lib/node_loader":320,"../lib/services/dynamodb":378}],254:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['dynamodbstreams'] = {};
AWS.DynamoDBStreams = Service.defineService('dynamodbstreams', ['2012-08-10']);
Object.defineProperty(apiLoader.services['dynamodbstreams'], '2012-08-10', {
  get: function get() {
    var model = require('../apis/streams.dynamodb-2012-08-10.min.json');
    model.paginators = require('../apis/streams.dynamodb-2012-08-10.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.DynamoDBStreams;

},{"../apis/streams.dynamodb-2012-08-10.min.json":214,"../apis/streams.dynamodb-2012-08-10.paginators.json":215,"../lib/core":324,"../lib/node_loader":320}],255:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['ec2'] = {};
AWS.EC2 = Service.defineService('ec2', ['2013-06-15*', '2013-10-15*', '2014-02-01*', '2014-05-01*', '2014-06-15*', '2014-09-01*', '2014-10-01*', '2015-03-01*', '2015-04-15*', '2015-10-01*', '2016-04-01*', '2016-09-15*', '2016-11-15']);
require('../lib/services/ec2');
Object.defineProperty(apiLoader.services['ec2'], '2016-11-15', {
  get: function get() {
    var model = require('../apis/ec2-2016-11-15.min.json');
    model.paginators = require('../apis/ec2-2016-11-15.paginators.json').pagination;
    model.waiters = require('../apis/ec2-2016-11-15.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.EC2;

},{"../apis/ec2-2016-11-15.min.json":71,"../apis/ec2-2016-11-15.paginators.json":72,"../apis/ec2-2016-11-15.waiters2.json":73,"../lib/core":324,"../lib/node_loader":320,"../lib/services/ec2":379}],256:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['ecr'] = {};
AWS.ECR = Service.defineService('ecr', ['2015-09-21']);
Object.defineProperty(apiLoader.services['ecr'], '2015-09-21', {
  get: function get() {
    var model = require('../apis/ecr-2015-09-21.min.json');
    model.paginators = require('../apis/ecr-2015-09-21.paginators.json').pagination;
    model.waiters = require('../apis/ecr-2015-09-21.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ECR;

},{"../apis/ecr-2015-09-21.min.json":74,"../apis/ecr-2015-09-21.paginators.json":75,"../apis/ecr-2015-09-21.waiters2.json":76,"../lib/core":324,"../lib/node_loader":320}],257:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['ecs'] = {};
AWS.ECS = Service.defineService('ecs', ['2014-11-13']);
Object.defineProperty(apiLoader.services['ecs'], '2014-11-13', {
  get: function get() {
    var model = require('../apis/ecs-2014-11-13.min.json');
    model.paginators = require('../apis/ecs-2014-11-13.paginators.json').pagination;
    model.waiters = require('../apis/ecs-2014-11-13.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ECS;

},{"../apis/ecs-2014-11-13.min.json":77,"../apis/ecs-2014-11-13.paginators.json":78,"../apis/ecs-2014-11-13.waiters2.json":79,"../lib/core":324,"../lib/node_loader":320}],258:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['efs'] = {};
AWS.EFS = Service.defineService('efs', ['2015-02-01']);
Object.defineProperty(apiLoader.services['efs'], '2015-02-01', {
  get: function get() {
    var model = require('../apis/elasticfilesystem-2015-02-01.min.json');
    model.paginators = require('../apis/elasticfilesystem-2015-02-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.EFS;

},{"../apis/elasticfilesystem-2015-02-01.min.json":85,"../apis/elasticfilesystem-2015-02-01.paginators.json":86,"../lib/core":324,"../lib/node_loader":320}],259:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['elasticache'] = {};
AWS.ElastiCache = Service.defineService('elasticache', ['2012-11-15*', '2014-03-24*', '2014-07-15*', '2014-09-30*', '2015-02-02']);
Object.defineProperty(apiLoader.services['elasticache'], '2015-02-02', {
  get: function get() {
    var model = require('../apis/elasticache-2015-02-02.min.json');
    model.paginators = require('../apis/elasticache-2015-02-02.paginators.json').pagination;
    model.waiters = require('../apis/elasticache-2015-02-02.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ElastiCache;

},{"../apis/elasticache-2015-02-02.min.json":80,"../apis/elasticache-2015-02-02.paginators.json":81,"../apis/elasticache-2015-02-02.waiters2.json":82,"../lib/core":324,"../lib/node_loader":320}],260:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['elasticbeanstalk'] = {};
AWS.ElasticBeanstalk = Service.defineService('elasticbeanstalk', ['2010-12-01']);
Object.defineProperty(apiLoader.services['elasticbeanstalk'], '2010-12-01', {
  get: function get() {
    var model = require('../apis/elasticbeanstalk-2010-12-01.min.json');
    model.paginators = require('../apis/elasticbeanstalk-2010-12-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ElasticBeanstalk;

},{"../apis/elasticbeanstalk-2010-12-01.min.json":83,"../apis/elasticbeanstalk-2010-12-01.paginators.json":84,"../lib/core":324,"../lib/node_loader":320}],261:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['elastictranscoder'] = {};
AWS.ElasticTranscoder = Service.defineService('elastictranscoder', ['2012-09-25']);
Object.defineProperty(apiLoader.services['elastictranscoder'], '2012-09-25', {
  get: function get() {
    var model = require('../apis/elastictranscoder-2012-09-25.min.json');
    model.paginators = require('../apis/elastictranscoder-2012-09-25.paginators.json').pagination;
    model.waiters = require('../apis/elastictranscoder-2012-09-25.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ElasticTranscoder;

},{"../apis/elastictranscoder-2012-09-25.min.json":96,"../apis/elastictranscoder-2012-09-25.paginators.json":97,"../apis/elastictranscoder-2012-09-25.waiters2.json":98,"../lib/core":324,"../lib/node_loader":320}],262:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['elb'] = {};
AWS.ELB = Service.defineService('elb', ['2012-06-01']);
Object.defineProperty(apiLoader.services['elb'], '2012-06-01', {
  get: function get() {
    var model = require('../apis/elasticloadbalancing-2012-06-01.min.json');
    model.paginators = require('../apis/elasticloadbalancing-2012-06-01.paginators.json').pagination;
    model.waiters = require('../apis/elasticloadbalancing-2012-06-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ELB;

},{"../apis/elasticloadbalancing-2012-06-01.min.json":87,"../apis/elasticloadbalancing-2012-06-01.paginators.json":88,"../apis/elasticloadbalancing-2012-06-01.waiters2.json":89,"../lib/core":324,"../lib/node_loader":320}],263:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['elbv2'] = {};
AWS.ELBv2 = Service.defineService('elbv2', ['2015-12-01']);
Object.defineProperty(apiLoader.services['elbv2'], '2015-12-01', {
  get: function get() {
    var model = require('../apis/elasticloadbalancingv2-2015-12-01.min.json');
    model.paginators = require('../apis/elasticloadbalancingv2-2015-12-01.paginators.json').pagination;
    model.waiters = require('../apis/elasticloadbalancingv2-2015-12-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ELBv2;

},{"../apis/elasticloadbalancingv2-2015-12-01.min.json":90,"../apis/elasticloadbalancingv2-2015-12-01.paginators.json":91,"../apis/elasticloadbalancingv2-2015-12-01.waiters2.json":92,"../lib/core":324,"../lib/node_loader":320}],264:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['emr'] = {};
AWS.EMR = Service.defineService('emr', ['2009-03-31']);
Object.defineProperty(apiLoader.services['emr'], '2009-03-31', {
  get: function get() {
    var model = require('../apis/elasticmapreduce-2009-03-31.min.json');
    model.paginators = require('../apis/elasticmapreduce-2009-03-31.paginators.json').pagination;
    model.waiters = require('../apis/elasticmapreduce-2009-03-31.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.EMR;

},{"../apis/elasticmapreduce-2009-03-31.min.json":93,"../apis/elasticmapreduce-2009-03-31.paginators.json":94,"../apis/elasticmapreduce-2009-03-31.waiters2.json":95,"../lib/core":324,"../lib/node_loader":320}],265:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['firehose'] = {};
AWS.Firehose = Service.defineService('firehose', ['2015-08-04']);
Object.defineProperty(apiLoader.services['firehose'], '2015-08-04', {
  get: function get() {
    var model = require('../apis/firehose-2015-08-04.min.json');
    model.paginators = require('../apis/firehose-2015-08-04.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Firehose;

},{"../apis/firehose-2015-08-04.min.json":104,"../apis/firehose-2015-08-04.paginators.json":105,"../lib/core":324,"../lib/node_loader":320}],266:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['forecastqueryservice'] = {};
AWS.ForecastQueryService = Service.defineService('forecastqueryservice', ['2018-06-26']);
Object.defineProperty(apiLoader.services['forecastqueryservice'], '2018-06-26', {
  get: function get() {
    var model = require('../apis/forecastquery-2018-06-26.min.json');
    model.paginators = require('../apis/forecastquery-2018-06-26.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ForecastQueryService;

},{"../apis/forecastquery-2018-06-26.min.json":108,"../apis/forecastquery-2018-06-26.paginators.json":109,"../lib/core":324,"../lib/node_loader":320}],267:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['forecastservice'] = {};
AWS.ForecastService = Service.defineService('forecastservice', ['2018-06-26']);
Object.defineProperty(apiLoader.services['forecastservice'], '2018-06-26', {
  get: function get() {
    var model = require('../apis/forecast-2018-06-26.min.json');
    model.paginators = require('../apis/forecast-2018-06-26.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ForecastService;

},{"../apis/forecast-2018-06-26.min.json":106,"../apis/forecast-2018-06-26.paginators.json":107,"../lib/core":324,"../lib/node_loader":320}],268:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['gamelift'] = {};
AWS.GameLift = Service.defineService('gamelift', ['2015-10-01']);
Object.defineProperty(apiLoader.services['gamelift'], '2015-10-01', {
  get: function get() {
    var model = require('../apis/gamelift-2015-10-01.min.json');
    model.paginators = require('../apis/gamelift-2015-10-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.GameLift;

},{"../apis/gamelift-2015-10-01.min.json":110,"../apis/gamelift-2015-10-01.paginators.json":111,"../lib/core":324,"../lib/node_loader":320}],269:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['iam'] = {};
AWS.IAM = Service.defineService('iam', ['2010-05-08']);
Object.defineProperty(apiLoader.services['iam'], '2010-05-08', {
  get: function get() {
    var model = require('../apis/iam-2010-05-08.min.json');
    model.paginators = require('../apis/iam-2010-05-08.paginators.json').pagination;
    model.waiters = require('../apis/iam-2010-05-08.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.IAM;

},{"../apis/iam-2010-05-08.min.json":112,"../apis/iam-2010-05-08.paginators.json":113,"../apis/iam-2010-05-08.waiters2.json":114,"../lib/core":324,"../lib/node_loader":320}],270:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['inspector'] = {};
AWS.Inspector = Service.defineService('inspector', ['2015-08-18*', '2016-02-16']);
Object.defineProperty(apiLoader.services['inspector'], '2016-02-16', {
  get: function get() {
    var model = require('../apis/inspector-2016-02-16.min.json');
    model.paginators = require('../apis/inspector-2016-02-16.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Inspector;

},{"../apis/inspector-2016-02-16.min.json":115,"../apis/inspector-2016-02-16.paginators.json":116,"../lib/core":324,"../lib/node_loader":320}],271:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['iot'] = {};
AWS.Iot = Service.defineService('iot', ['2015-05-28']);
Object.defineProperty(apiLoader.services['iot'], '2015-05-28', {
  get: function get() {
    var model = require('../apis/iot-2015-05-28.min.json');
    model.paginators = require('../apis/iot-2015-05-28.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Iot;

},{"../apis/iot-2015-05-28.min.json":117,"../apis/iot-2015-05-28.paginators.json":118,"../lib/core":324,"../lib/node_loader":320}],272:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['iotanalytics'] = {};
AWS.IoTAnalytics = Service.defineService('iotanalytics', ['2017-11-27']);
Object.defineProperty(apiLoader.services['iotanalytics'], '2017-11-27', {
  get: function get() {
    var model = require('../apis/iotanalytics-2017-11-27.min.json');
    model.paginators = require('../apis/iotanalytics-2017-11-27.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.IoTAnalytics;

},{"../apis/iotanalytics-2017-11-27.min.json":121,"../apis/iotanalytics-2017-11-27.paginators.json":122,"../lib/core":324,"../lib/node_loader":320}],273:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['iotdata'] = {};
AWS.IotData = Service.defineService('iotdata', ['2015-05-28']);
require('../lib/services/iotdata');
Object.defineProperty(apiLoader.services['iotdata'], '2015-05-28', {
  get: function get() {
    var model = require('../apis/iot-data-2015-05-28.min.json');
    model.paginators = require('../apis/iot-data-2015-05-28.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.IotData;

},{"../apis/iot-data-2015-05-28.min.json":119,"../apis/iot-data-2015-05-28.paginators.json":120,"../lib/core":324,"../lib/node_loader":320,"../lib/services/iotdata":380}],274:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kinesis'] = {};
AWS.Kinesis = Service.defineService('kinesis', ['2013-12-02']);
Object.defineProperty(apiLoader.services['kinesis'], '2013-12-02', {
  get: function get() {
    var model = require('../apis/kinesis-2013-12-02.min.json');
    model.paginators = require('../apis/kinesis-2013-12-02.paginators.json').pagination;
    model.waiters = require('../apis/kinesis-2013-12-02.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Kinesis;

},{"../apis/kinesis-2013-12-02.min.json":123,"../apis/kinesis-2013-12-02.paginators.json":124,"../apis/kinesis-2013-12-02.waiters2.json":125,"../lib/core":324,"../lib/node_loader":320}],275:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kinesisvideo'] = {};
AWS.KinesisVideo = Service.defineService('kinesisvideo', ['2017-09-30']);
Object.defineProperty(apiLoader.services['kinesisvideo'], '2017-09-30', {
  get: function get() {
    var model = require('../apis/kinesisvideo-2017-09-30.min.json');
    model.paginators = require('../apis/kinesisvideo-2017-09-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.KinesisVideo;

},{"../apis/kinesisvideo-2017-09-30.min.json":132,"../apis/kinesisvideo-2017-09-30.paginators.json":133,"../lib/core":324,"../lib/node_loader":320}],276:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kinesisvideoarchivedmedia'] = {};
AWS.KinesisVideoArchivedMedia = Service.defineService('kinesisvideoarchivedmedia', ['2017-09-30']);
Object.defineProperty(apiLoader.services['kinesisvideoarchivedmedia'], '2017-09-30', {
  get: function get() {
    var model = require('../apis/kinesis-video-archived-media-2017-09-30.min.json');
    model.paginators = require('../apis/kinesis-video-archived-media-2017-09-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.KinesisVideoArchivedMedia;

},{"../apis/kinesis-video-archived-media-2017-09-30.min.json":126,"../apis/kinesis-video-archived-media-2017-09-30.paginators.json":127,"../lib/core":324,"../lib/node_loader":320}],277:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kinesisvideomedia'] = {};
AWS.KinesisVideoMedia = Service.defineService('kinesisvideomedia', ['2017-09-30']);
Object.defineProperty(apiLoader.services['kinesisvideomedia'], '2017-09-30', {
  get: function get() {
    var model = require('../apis/kinesis-video-media-2017-09-30.min.json');
    model.paginators = require('../apis/kinesis-video-media-2017-09-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.KinesisVideoMedia;

},{"../apis/kinesis-video-media-2017-09-30.min.json":128,"../apis/kinesis-video-media-2017-09-30.paginators.json":129,"../lib/core":324,"../lib/node_loader":320}],278:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kinesisvideosignalingchannels'] = {};
AWS.KinesisVideoSignalingChannels = Service.defineService('kinesisvideosignalingchannels', ['2019-12-04']);
Object.defineProperty(apiLoader.services['kinesisvideosignalingchannels'], '2019-12-04', {
  get: function get() {
    var model = require('../apis/kinesis-video-signaling-2019-12-04.min.json');
    model.paginators = require('../apis/kinesis-video-signaling-2019-12-04.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.KinesisVideoSignalingChannels;

},{"../apis/kinesis-video-signaling-2019-12-04.min.json":130,"../apis/kinesis-video-signaling-2019-12-04.paginators.json":131,"../lib/core":324,"../lib/node_loader":320}],279:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['kms'] = {};
AWS.KMS = Service.defineService('kms', ['2014-11-01']);
Object.defineProperty(apiLoader.services['kms'], '2014-11-01', {
  get: function get() {
    var model = require('../apis/kms-2014-11-01.min.json');
    model.paginators = require('../apis/kms-2014-11-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.KMS;

},{"../apis/kms-2014-11-01.min.json":134,"../apis/kms-2014-11-01.paginators.json":135,"../lib/core":324,"../lib/node_loader":320}],280:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['lambda'] = {};
AWS.Lambda = Service.defineService('lambda', ['2014-11-11', '2015-03-31']);
require('../lib/services/lambda');
Object.defineProperty(apiLoader.services['lambda'], '2014-11-11', {
  get: function get() {
    var model = require('../apis/lambda-2014-11-11.min.json');
    model.paginators = require('../apis/lambda-2014-11-11.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['lambda'], '2015-03-31', {
  get: function get() {
    var model = require('../apis/lambda-2015-03-31.min.json');
    model.paginators = require('../apis/lambda-2015-03-31.paginators.json').pagination;
    model.waiters = require('../apis/lambda-2015-03-31.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Lambda;

},{"../apis/lambda-2014-11-11.min.json":136,"../apis/lambda-2014-11-11.paginators.json":137,"../apis/lambda-2015-03-31.min.json":138,"../apis/lambda-2015-03-31.paginators.json":139,"../apis/lambda-2015-03-31.waiters2.json":140,"../lib/core":324,"../lib/node_loader":320,"../lib/services/lambda":381}],281:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['lexmodelbuildingservice'] = {};
AWS.LexModelBuildingService = Service.defineService('lexmodelbuildingservice', ['2017-04-19']);
Object.defineProperty(apiLoader.services['lexmodelbuildingservice'], '2017-04-19', {
  get: function get() {
    var model = require('../apis/lex-models-2017-04-19.min.json');
    model.paginators = require('../apis/lex-models-2017-04-19.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.LexModelBuildingService;

},{"../apis/lex-models-2017-04-19.min.json":141,"../apis/lex-models-2017-04-19.paginators.json":142,"../lib/core":324,"../lib/node_loader":320}],282:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['lexruntime'] = {};
AWS.LexRuntime = Service.defineService('lexruntime', ['2016-11-28']);
Object.defineProperty(apiLoader.services['lexruntime'], '2016-11-28', {
  get: function get() {
    var model = require('../apis/runtime.lex-2016-11-28.min.json');
    model.paginators = require('../apis/runtime.lex-2016-11-28.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.LexRuntime;

},{"../apis/runtime.lex-2016-11-28.min.json":197,"../apis/runtime.lex-2016-11-28.paginators.json":198,"../lib/core":324,"../lib/node_loader":320}],283:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['machinelearning'] = {};
AWS.MachineLearning = Service.defineService('machinelearning', ['2014-12-12']);
require('../lib/services/machinelearning');
Object.defineProperty(apiLoader.services['machinelearning'], '2014-12-12', {
  get: function get() {
    var model = require('../apis/machinelearning-2014-12-12.min.json');
    model.paginators = require('../apis/machinelearning-2014-12-12.paginators.json').pagination;
    model.waiters = require('../apis/machinelearning-2014-12-12.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.MachineLearning;

},{"../apis/machinelearning-2014-12-12.min.json":145,"../apis/machinelearning-2014-12-12.paginators.json":146,"../apis/machinelearning-2014-12-12.waiters2.json":147,"../lib/core":324,"../lib/node_loader":320,"../lib/services/machinelearning":382}],284:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['marketplacecommerceanalytics'] = {};
AWS.MarketplaceCommerceAnalytics = Service.defineService('marketplacecommerceanalytics', ['2015-07-01']);
Object.defineProperty(apiLoader.services['marketplacecommerceanalytics'], '2015-07-01', {
  get: function get() {
    var model = require('../apis/marketplacecommerceanalytics-2015-07-01.min.json');
    model.paginators = require('../apis/marketplacecommerceanalytics-2015-07-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.MarketplaceCommerceAnalytics;

},{"../apis/marketplacecommerceanalytics-2015-07-01.min.json":148,"../apis/marketplacecommerceanalytics-2015-07-01.paginators.json":149,"../lib/core":324,"../lib/node_loader":320}],285:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['mediastoredata'] = {};
AWS.MediaStoreData = Service.defineService('mediastoredata', ['2017-09-01']);
Object.defineProperty(apiLoader.services['mediastoredata'], '2017-09-01', {
  get: function get() {
    var model = require('../apis/mediastore-data-2017-09-01.min.json');
    model.paginators = require('../apis/mediastore-data-2017-09-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.MediaStoreData;

},{"../apis/mediastore-data-2017-09-01.min.json":150,"../apis/mediastore-data-2017-09-01.paginators.json":151,"../lib/core":324,"../lib/node_loader":320}],286:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['mobileanalytics'] = {};
AWS.MobileAnalytics = Service.defineService('mobileanalytics', ['2014-06-05']);
Object.defineProperty(apiLoader.services['mobileanalytics'], '2014-06-05', {
  get: function get() {
    var model = require('../apis/mobileanalytics-2014-06-05.min.json');
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.MobileAnalytics;

},{"../apis/mobileanalytics-2014-06-05.min.json":153,"../lib/core":324,"../lib/node_loader":320}],287:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['mturk'] = {};
AWS.MTurk = Service.defineService('mturk', ['2017-01-17']);
Object.defineProperty(apiLoader.services['mturk'], '2017-01-17', {
  get: function get() {
    var model = require('../apis/mturk-requester-2017-01-17.min.json');
    model.paginators = require('../apis/mturk-requester-2017-01-17.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.MTurk;

},{"../apis/mturk-requester-2017-01-17.min.json":157,"../apis/mturk-requester-2017-01-17.paginators.json":158,"../lib/core":324,"../lib/node_loader":320}],288:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['opsworks'] = {};
AWS.OpsWorks = Service.defineService('opsworks', ['2013-02-18']);
Object.defineProperty(apiLoader.services['opsworks'], '2013-02-18', {
  get: function get() {
    var model = require('../apis/opsworks-2013-02-18.min.json');
    model.paginators = require('../apis/opsworks-2013-02-18.paginators.json').pagination;
    model.waiters = require('../apis/opsworks-2013-02-18.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.OpsWorks;

},{"../apis/opsworks-2013-02-18.min.json":159,"../apis/opsworks-2013-02-18.paginators.json":160,"../apis/opsworks-2013-02-18.waiters2.json":161,"../lib/core":324,"../lib/node_loader":320}],289:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['personalize'] = {};
AWS.Personalize = Service.defineService('personalize', ['2018-05-22']);
Object.defineProperty(apiLoader.services['personalize'], '2018-05-22', {
  get: function get() {
    var model = require('../apis/personalize-2018-05-22.min.json');
    model.paginators = require('../apis/personalize-2018-05-22.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Personalize;

},{"../apis/personalize-2018-05-22.min.json":162,"../apis/personalize-2018-05-22.paginators.json":163,"../lib/core":324,"../lib/node_loader":320}],290:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['personalizeevents'] = {};
AWS.PersonalizeEvents = Service.defineService('personalizeevents', ['2018-03-22']);
Object.defineProperty(apiLoader.services['personalizeevents'], '2018-03-22', {
  get: function get() {
    var model = require('../apis/personalize-events-2018-03-22.min.json');
    model.paginators = require('../apis/personalize-events-2018-03-22.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.PersonalizeEvents;

},{"../apis/personalize-events-2018-03-22.min.json":164,"../apis/personalize-events-2018-03-22.paginators.json":165,"../lib/core":324,"../lib/node_loader":320}],291:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['personalizeruntime'] = {};
AWS.PersonalizeRuntime = Service.defineService('personalizeruntime', ['2018-05-22']);
Object.defineProperty(apiLoader.services['personalizeruntime'], '2018-05-22', {
  get: function get() {
    var model = require('../apis/personalize-runtime-2018-05-22.min.json');
    model.paginators = require('../apis/personalize-runtime-2018-05-22.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.PersonalizeRuntime;

},{"../apis/personalize-runtime-2018-05-22.min.json":166,"../apis/personalize-runtime-2018-05-22.paginators.json":167,"../lib/core":324,"../lib/node_loader":320}],292:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['polly'] = {};
AWS.Polly = Service.defineService('polly', ['2016-06-10']);
require('../lib/services/polly');
Object.defineProperty(apiLoader.services['polly'], '2016-06-10', {
  get: function get() {
    var model = require('../apis/polly-2016-06-10.min.json');
    model.paginators = require('../apis/polly-2016-06-10.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Polly;

},{"../apis/polly-2016-06-10.min.json":168,"../apis/polly-2016-06-10.paginators.json":169,"../lib/core":324,"../lib/node_loader":320,"../lib/services/polly":383}],293:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['pricing'] = {};
AWS.Pricing = Service.defineService('pricing', ['2017-10-15']);
Object.defineProperty(apiLoader.services['pricing'], '2017-10-15', {
  get: function get() {
    var model = require('../apis/pricing-2017-10-15.min.json');
    model.paginators = require('../apis/pricing-2017-10-15.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Pricing;

},{"../apis/pricing-2017-10-15.min.json":170,"../apis/pricing-2017-10-15.paginators.json":171,"../lib/core":324,"../lib/node_loader":320}],294:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['rds'] = {};
AWS.RDS = Service.defineService('rds', ['2013-01-10', '2013-02-12', '2013-09-09', '2014-09-01', '2014-09-01*', '2014-10-31']);
require('../lib/services/rds');
Object.defineProperty(apiLoader.services['rds'], '2013-01-10', {
  get: function get() {
    var model = require('../apis/rds-2013-01-10.min.json');
    model.paginators = require('../apis/rds-2013-01-10.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['rds'], '2013-02-12', {
  get: function get() {
    var model = require('../apis/rds-2013-02-12.min.json');
    model.paginators = require('../apis/rds-2013-02-12.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['rds'], '2013-09-09', {
  get: function get() {
    var model = require('../apis/rds-2013-09-09.min.json');
    model.paginators = require('../apis/rds-2013-09-09.paginators.json').pagination;
    model.waiters = require('../apis/rds-2013-09-09.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['rds'], '2014-09-01', {
  get: function get() {
    var model = require('../apis/rds-2014-09-01.min.json');
    model.paginators = require('../apis/rds-2014-09-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});
Object.defineProperty(apiLoader.services['rds'], '2014-10-31', {
  get: function get() {
    var model = require('../apis/rds-2014-10-31.min.json');
    model.paginators = require('../apis/rds-2014-10-31.paginators.json').pagination;
    model.waiters = require('../apis/rds-2014-10-31.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.RDS;

},{"../apis/rds-2013-01-10.min.json":172,"../apis/rds-2013-01-10.paginators.json":173,"../apis/rds-2013-02-12.min.json":174,"../apis/rds-2013-02-12.paginators.json":175,"../apis/rds-2013-09-09.min.json":176,"../apis/rds-2013-09-09.paginators.json":177,"../apis/rds-2013-09-09.waiters2.json":178,"../apis/rds-2014-09-01.min.json":179,"../apis/rds-2014-09-01.paginators.json":180,"../apis/rds-2014-10-31.min.json":181,"../apis/rds-2014-10-31.paginators.json":182,"../apis/rds-2014-10-31.waiters2.json":183,"../lib/core":324,"../lib/node_loader":320,"../lib/services/rds":384}],295:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['redshift'] = {};
AWS.Redshift = Service.defineService('redshift', ['2012-12-01']);
Object.defineProperty(apiLoader.services['redshift'], '2012-12-01', {
  get: function get() {
    var model = require('../apis/redshift-2012-12-01.min.json');
    model.paginators = require('../apis/redshift-2012-12-01.paginators.json').pagination;
    model.waiters = require('../apis/redshift-2012-12-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Redshift;

},{"../apis/redshift-2012-12-01.min.json":184,"../apis/redshift-2012-12-01.paginators.json":185,"../apis/redshift-2012-12-01.waiters2.json":186,"../lib/core":324,"../lib/node_loader":320}],296:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['rekognition'] = {};
AWS.Rekognition = Service.defineService('rekognition', ['2016-06-27']);
Object.defineProperty(apiLoader.services['rekognition'], '2016-06-27', {
  get: function get() {
    var model = require('../apis/rekognition-2016-06-27.min.json');
    model.paginators = require('../apis/rekognition-2016-06-27.paginators.json').pagination;
    model.waiters = require('../apis/rekognition-2016-06-27.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Rekognition;

},{"../apis/rekognition-2016-06-27.min.json":187,"../apis/rekognition-2016-06-27.paginators.json":188,"../apis/rekognition-2016-06-27.waiters2.json":189,"../lib/core":324,"../lib/node_loader":320}],297:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['resourcegroups'] = {};
AWS.ResourceGroups = Service.defineService('resourcegroups', ['2017-11-27']);
Object.defineProperty(apiLoader.services['resourcegroups'], '2017-11-27', {
  get: function get() {
    var model = require('../apis/resource-groups-2017-11-27.min.json');
    model.paginators = require('../apis/resource-groups-2017-11-27.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ResourceGroups;

},{"../apis/resource-groups-2017-11-27.min.json":190,"../apis/resource-groups-2017-11-27.paginators.json":191,"../lib/core":324,"../lib/node_loader":320}],298:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['route53'] = {};
AWS.Route53 = Service.defineService('route53', ['2013-04-01']);
require('../lib/services/route53');
Object.defineProperty(apiLoader.services['route53'], '2013-04-01', {
  get: function get() {
    var model = require('../apis/route53-2013-04-01.min.json');
    model.paginators = require('../apis/route53-2013-04-01.paginators.json').pagination;
    model.waiters = require('../apis/route53-2013-04-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Route53;

},{"../apis/route53-2013-04-01.min.json":192,"../apis/route53-2013-04-01.paginators.json":193,"../apis/route53-2013-04-01.waiters2.json":194,"../lib/core":324,"../lib/node_loader":320,"../lib/services/route53":385}],299:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['route53domains'] = {};
AWS.Route53Domains = Service.defineService('route53domains', ['2014-05-15']);
Object.defineProperty(apiLoader.services['route53domains'], '2014-05-15', {
  get: function get() {
    var model = require('../apis/route53domains-2014-05-15.min.json');
    model.paginators = require('../apis/route53domains-2014-05-15.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Route53Domains;

},{"../apis/route53domains-2014-05-15.min.json":195,"../apis/route53domains-2014-05-15.paginators.json":196,"../lib/core":324,"../lib/node_loader":320}],300:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['s3'] = {};
AWS.S3 = Service.defineService('s3', ['2006-03-01']);
require('../lib/services/s3');
Object.defineProperty(apiLoader.services['s3'], '2006-03-01', {
  get: function get() {
    var model = require('../apis/s3-2006-03-01.min.json');
    model.paginators = require('../apis/s3-2006-03-01.paginators.json').pagination;
    model.waiters = require('../apis/s3-2006-03-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.S3;

},{"../apis/s3-2006-03-01.min.json":199,"../apis/s3-2006-03-01.paginators.json":200,"../apis/s3-2006-03-01.waiters2.json":201,"../lib/core":324,"../lib/node_loader":320,"../lib/services/s3":386}],301:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['secretsmanager'] = {};
AWS.SecretsManager = Service.defineService('secretsmanager', ['2017-10-17']);
Object.defineProperty(apiLoader.services['secretsmanager'], '2017-10-17', {
  get: function get() {
    var model = require('../apis/secretsmanager-2017-10-17.min.json');
    model.paginators = require('../apis/secretsmanager-2017-10-17.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.SecretsManager;

},{"../apis/secretsmanager-2017-10-17.min.json":202,"../apis/secretsmanager-2017-10-17.paginators.json":203,"../lib/core":324,"../lib/node_loader":320}],302:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['servicecatalog'] = {};
AWS.ServiceCatalog = Service.defineService('servicecatalog', ['2015-12-10']);
Object.defineProperty(apiLoader.services['servicecatalog'], '2015-12-10', {
  get: function get() {
    var model = require('../apis/servicecatalog-2015-12-10.min.json');
    model.paginators = require('../apis/servicecatalog-2015-12-10.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.ServiceCatalog;

},{"../apis/servicecatalog-2015-12-10.min.json":204,"../apis/servicecatalog-2015-12-10.paginators.json":205,"../lib/core":324,"../lib/node_loader":320}],303:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['ses'] = {};
AWS.SES = Service.defineService('ses', ['2010-12-01']);
Object.defineProperty(apiLoader.services['ses'], '2010-12-01', {
  get: function get() {
    var model = require('../apis/email-2010-12-01.min.json');
    model.paginators = require('../apis/email-2010-12-01.paginators.json').pagination;
    model.waiters = require('../apis/email-2010-12-01.waiters2.json').waiters;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.SES;

},{"../apis/email-2010-12-01.min.json":99,"../apis/email-2010-12-01.paginators.json":100,"../apis/email-2010-12-01.waiters2.json":101,"../lib/core":324,"../lib/node_loader":320}],304:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['sns'] = {};
AWS.SNS = Service.defineService('sns', ['2010-03-31']);
Object.defineProperty(apiLoader.services['sns'], '2010-03-31', {
  get: function get() {
    var model = require('../apis/sns-2010-03-31.min.json');
    model.paginators = require('../apis/sns-2010-03-31.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.SNS;

},{"../apis/sns-2010-03-31.min.json":206,"../apis/sns-2010-03-31.paginators.json":207,"../lib/core":324,"../lib/node_loader":320}],305:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['sqs'] = {};
AWS.SQS = Service.defineService('sqs', ['2012-11-05']);
require('../lib/services/sqs');
Object.defineProperty(apiLoader.services['sqs'], '2012-11-05', {
  get: function get() {
    var model = require('../apis/sqs-2012-11-05.min.json');
    model.paginators = require('../apis/sqs-2012-11-05.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.SQS;

},{"../apis/sqs-2012-11-05.min.json":208,"../apis/sqs-2012-11-05.paginators.json":209,"../lib/core":324,"../lib/node_loader":320,"../lib/services/sqs":387}],306:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['ssm'] = {};
AWS.SSM = Service.defineService('ssm', ['2014-11-06']);
Object.defineProperty(apiLoader.services['ssm'], '2014-11-06', {
  get: function get() {
    var model = require('../apis/ssm-2014-11-06.min.json');
    model.paginators = require('../apis/ssm-2014-11-06.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.SSM;

},{"../apis/ssm-2014-11-06.min.json":210,"../apis/ssm-2014-11-06.paginators.json":211,"../lib/core":324,"../lib/node_loader":320}],307:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['storagegateway'] = {};
AWS.StorageGateway = Service.defineService('storagegateway', ['2013-06-30']);
Object.defineProperty(apiLoader.services['storagegateway'], '2013-06-30', {
  get: function get() {
    var model = require('../apis/storagegateway-2013-06-30.min.json');
    model.paginators = require('../apis/storagegateway-2013-06-30.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.StorageGateway;

},{"../apis/storagegateway-2013-06-30.min.json":212,"../apis/storagegateway-2013-06-30.paginators.json":213,"../lib/core":324,"../lib/node_loader":320}],308:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['sts'] = {};
AWS.STS = Service.defineService('sts', ['2011-06-15']);
require('../lib/services/sts');
Object.defineProperty(apiLoader.services['sts'], '2011-06-15', {
  get: function get() {
    var model = require('../apis/sts-2011-06-15.min.json');
    model.paginators = require('../apis/sts-2011-06-15.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.STS;

},{"../apis/sts-2011-06-15.min.json":216,"../apis/sts-2011-06-15.paginators.json":217,"../lib/core":324,"../lib/node_loader":320,"../lib/services/sts":388}],309:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['translate'] = {};
AWS.Translate = Service.defineService('translate', ['2017-07-01']);
Object.defineProperty(apiLoader.services['translate'], '2017-07-01', {
  get: function get() {
    var model = require('../apis/translate-2017-07-01.min.json');
    model.paginators = require('../apis/translate-2017-07-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.Translate;

},{"../apis/translate-2017-07-01.min.json":218,"../apis/translate-2017-07-01.paginators.json":219,"../lib/core":324,"../lib/node_loader":320}],310:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['waf'] = {};
AWS.WAF = Service.defineService('waf', ['2015-08-24']);
Object.defineProperty(apiLoader.services['waf'], '2015-08-24', {
  get: function get() {
    var model = require('../apis/waf-2015-08-24.min.json');
    model.paginators = require('../apis/waf-2015-08-24.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.WAF;

},{"../apis/waf-2015-08-24.min.json":220,"../apis/waf-2015-08-24.paginators.json":221,"../lib/core":324,"../lib/node_loader":320}],311:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['workdocs'] = {};
AWS.WorkDocs = Service.defineService('workdocs', ['2016-05-01']);
Object.defineProperty(apiLoader.services['workdocs'], '2016-05-01', {
  get: function get() {
    var model = require('../apis/workdocs-2016-05-01.min.json');
    model.paginators = require('../apis/workdocs-2016-05-01.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.WorkDocs;

},{"../apis/workdocs-2016-05-01.min.json":222,"../apis/workdocs-2016-05-01.paginators.json":223,"../lib/core":324,"../lib/node_loader":320}],312:[function(require,module,exports){
require('../lib/node_loader');
var AWS = require('../lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['xray'] = {};
AWS.XRay = Service.defineService('xray', ['2016-04-12']);
Object.defineProperty(apiLoader.services['xray'], '2016-04-12', {
  get: function get() {
    var model = require('../apis/xray-2016-04-12.min.json');
    model.paginators = require('../apis/xray-2016-04-12.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.XRay;

},{"../apis/xray-2016-04-12.min.json":224,"../apis/xray-2016-04-12.paginators.json":225,"../lib/core":324,"../lib/node_loader":320}],313:[function(require,module,exports){
function apiLoader(svc, version) {
  if (!apiLoader.services.hasOwnProperty(svc)) {
    throw new Error('InvalidService: Failed to load api for ' + svc);
  }
  return apiLoader.services[svc][version];
}

/**
 * @api private
 *
 * This member of AWS.apiLoader is private, but changing it will necessitate a
 * change to ../scripts/services-table-generator.ts
 */
apiLoader.services = {};

/**
 * @api private
 */
module.exports = apiLoader;

},{}],314:[function(require,module,exports){
var Hmac = require('./browserHmac');
var Md5 = require('./browserMd5');
var Sha1 = require('./browserSha1');
var Sha256 = require('./browserSha256');

/**
 * @api private
 */
module.exports = exports = {
    createHash: function createHash(alg) {
      alg = alg.toLowerCase();
      if (alg === 'md5') {
        return new Md5();
      } else if (alg === 'sha256') {
        return new Sha256();
      } else if (alg === 'sha1') {
        return new Sha1();
      }

      throw new Error('Hash algorithm ' + alg + ' is not supported in the browser SDK');
    },
    createHmac: function createHmac(alg, key) {
      alg = alg.toLowerCase();
      if (alg === 'md5') {
        return new Hmac(Md5, key);
      } else if (alg === 'sha256') {
        return new Hmac(Sha256, key);
      } else if (alg === 'sha1') {
        return new Hmac(Sha1, key);
      }

      throw new Error('HMAC algorithm ' + alg + ' is not supported in the browser SDK');
    },
    createSign: function() {
      throw new Error('createSign is not implemented in the browser');
    }
  };

},{"./browserHmac":316,"./browserMd5":317,"./browserSha1":318,"./browserSha256":319}],315:[function(require,module,exports){
var Buffer = require('buffer/').Buffer;

/**
 * This is a polyfill for the static method `isView` of `ArrayBuffer`, which is
 * e.g. missing in IE 10.
 *
 * @api private
 */
if (
    typeof ArrayBuffer !== 'undefined' &&
    typeof ArrayBuffer.isView === 'undefined'
) {
    ArrayBuffer.isView = function(arg) {
        return viewStrings.indexOf(Object.prototype.toString.call(arg)) > -1;
    };
}

/**
 * @api private
 */
var viewStrings = [
    '[object Int8Array]',
    '[object Uint8Array]',
    '[object Uint8ClampedArray]',
    '[object Int16Array]',
    '[object Uint16Array]',
    '[object Int32Array]',
    '[object Uint32Array]',
    '[object Float32Array]',
    '[object Float64Array]',
    '[object DataView]',
];

/**
 * @api private
 */
function isEmptyData(data) {
    if (typeof data === 'string') {
        return data.length === 0;
    }
    return data.byteLength === 0;
}

/**
 * @api private
 */
function convertToBuffer(data) {
    if (typeof data === 'string') {
        data = new Buffer(data, 'utf8');
    }

    if (ArrayBuffer.isView(data)) {
        return new Uint8Array(data.buffer, data.byteOffset, data.byteLength / Uint8Array.BYTES_PER_ELEMENT);
    }

    return new Uint8Array(data);
}

/**
 * @api private
 */
module.exports = exports = {
    isEmptyData: isEmptyData,
    convertToBuffer: convertToBuffer,
};

},{"buffer/":407}],316:[function(require,module,exports){
var hashUtils = require('./browserHashUtils');

/**
 * @api private
 */
function Hmac(hashCtor, secret) {
    this.hash = new hashCtor();
    this.outer = new hashCtor();

    var inner = bufferFromSecret(hashCtor, secret);
    var outer = new Uint8Array(hashCtor.BLOCK_SIZE);
    outer.set(inner);

    for (var i = 0; i < hashCtor.BLOCK_SIZE; i++) {
        inner[i] ^= 0x36;
        outer[i] ^= 0x5c;
    }

    this.hash.update(inner);
    this.outer.update(outer);

    // Zero out the copied key buffer.
    for (var i = 0; i < inner.byteLength; i++) {
        inner[i] = 0;
    }
}

/**
 * @api private
 */
module.exports = exports = Hmac;

Hmac.prototype.update = function (toHash) {
    if (hashUtils.isEmptyData(toHash) || this.error) {
        return this;
    }

    try {
        this.hash.update(hashUtils.convertToBuffer(toHash));
    } catch (e) {
        this.error = e;
    }

    return this;
};

Hmac.prototype.digest = function (encoding) {
    if (!this.outer.finished) {
        this.outer.update(this.hash.digest());
    }

    return this.outer.digest(encoding);
};

function bufferFromSecret(hashCtor, secret) {
    var input = hashUtils.convertToBuffer(secret);
    if (input.byteLength > hashCtor.BLOCK_SIZE) {
        var bufferHash = new hashCtor;
        bufferHash.update(input);
        input = bufferHash.digest();
    }
    var buffer = new Uint8Array(hashCtor.BLOCK_SIZE);
    buffer.set(input);
    return buffer;
}

},{"./browserHashUtils":315}],317:[function(require,module,exports){
var hashUtils = require('./browserHashUtils');
var Buffer = require('buffer/').Buffer;

var BLOCK_SIZE = 64;

var DIGEST_LENGTH = 16;

var INIT = [
    0x67452301,
    0xefcdab89,
    0x98badcfe,
    0x10325476,
];

/**
 * @api private
 */
function Md5() {
    this.state = [
        0x67452301,
        0xefcdab89,
        0x98badcfe,
        0x10325476,
    ];
    this.buffer = new DataView(new ArrayBuffer(BLOCK_SIZE));
    this.bufferLength = 0;
    this.bytesHashed = 0;
    this.finished = false;
}

/**
 * @api private
 */
module.exports = exports = Md5;

Md5.BLOCK_SIZE = BLOCK_SIZE;

Md5.prototype.update = function (sourceData) {
    if (hashUtils.isEmptyData(sourceData)) {
        return this;
    } else if (this.finished) {
        throw new Error('Attempted to update an already finished hash.');
    }

    var data = hashUtils.convertToBuffer(sourceData);
    var position = 0;
    var byteLength = data.byteLength;
    this.bytesHashed += byteLength;
    while (byteLength > 0) {
        this.buffer.setUint8(this.bufferLength++, data[position++]);
        byteLength--;
        if (this.bufferLength === BLOCK_SIZE) {
            this.hashBuffer();
            this.bufferLength = 0;
        }
    }

    return this;
};

Md5.prototype.digest = function (encoding) {
    if (!this.finished) {
        var _a = this, buffer = _a.buffer, undecoratedLength = _a.bufferLength, bytesHashed = _a.bytesHashed;
        var bitsHashed = bytesHashed * 8;
        buffer.setUint8(this.bufferLength++, 128);
        // Ensure the final block has enough room for the hashed length
        if (undecoratedLength % BLOCK_SIZE >= BLOCK_SIZE - 8) {
            for (var i = this.bufferLength; i < BLOCK_SIZE; i++) {
                buffer.setUint8(i, 0);
            }
            this.hashBuffer();
            this.bufferLength = 0;
        }
        for (var i = this.bufferLength; i < BLOCK_SIZE - 8; i++) {
            buffer.setUint8(i, 0);
        }
        buffer.setUint32(BLOCK_SIZE - 8, bitsHashed >>> 0, true);
        buffer.setUint32(BLOCK_SIZE - 4, Math.floor(bitsHashed / 0x100000000), true);
        this.hashBuffer();
        this.finished = true;
    }
    var out = new DataView(new ArrayBuffer(DIGEST_LENGTH));
    for (var i = 0; i < 4; i++) {
        out.setUint32(i * 4, this.state[i], true);
    }
    var buff = new Buffer(out.buffer, out.byteOffset, out.byteLength);
    return encoding ? buff.toString(encoding) : buff;
};

Md5.prototype.hashBuffer = function () {
    var _a = this, buffer = _a.buffer, state = _a.state;
    var a = state[0], b = state[1], c = state[2], d = state[3];
    a = ff(a, b, c, d, buffer.getUint32(0, true), 7, 0xd76aa478);
    d = ff(d, a, b, c, buffer.getUint32(4, true), 12, 0xe8c7b756);
    c = ff(c, d, a, b, buffer.getUint32(8, true), 17, 0x242070db);
    b = ff(b, c, d, a, buffer.getUint32(12, true), 22, 0xc1bdceee);
    a = ff(a, b, c, d, buffer.getUint32(16, true), 7, 0xf57c0faf);
    d = ff(d, a, b, c, buffer.getUint32(20, true), 12, 0x4787c62a);
    c = ff(c, d, a, b, buffer.getUint32(24, true), 17, 0xa8304613);
    b = ff(b, c, d, a, buffer.getUint32(28, true), 22, 0xfd469501);
    a = ff(a, b, c, d, buffer.getUint32(32, true), 7, 0x698098d8);
    d = ff(d, a, b, c, buffer.getUint32(36, true), 12, 0x8b44f7af);
    c = ff(c, d, a, b, buffer.getUint32(40, true), 17, 0xffff5bb1);
    b = ff(b, c, d, a, buffer.getUint32(44, true), 22, 0x895cd7be);
    a = ff(a, b, c, d, buffer.getUint32(48, true), 7, 0x6b901122);
    d = ff(d, a, b, c, buffer.getUint32(52, true), 12, 0xfd987193);
    c = ff(c, d, a, b, buffer.getUint32(56, true), 17, 0xa679438e);
    b = ff(b, c, d, a, buffer.getUint32(60, true), 22, 0x49b40821);
    a = gg(a, b, c, d, buffer.getUint32(4, true), 5, 0xf61e2562);
    d = gg(d, a, b, c, buffer.getUint32(24, true), 9, 0xc040b340);
    c = gg(c, d, a, b, buffer.getUint32(44, true), 14, 0x265e5a51);
    b = gg(b, c, d, a, buffer.getUint32(0, true), 20, 0xe9b6c7aa);
    a = gg(a, b, c, d, buffer.getUint32(20, true), 5, 0xd62f105d);
    d = gg(d, a, b, c, buffer.getUint32(40, true), 9, 0x02441453);
    c = gg(c, d, a, b, buffer.getUint32(60, true), 14, 0xd8a1e681);
    b = gg(b, c, d, a, buffer.getUint32(16, true), 20, 0xe7d3fbc8);
    a = gg(a, b, c, d, buffer.getUint32(36, true), 5, 0x21e1cde6);
    d = gg(d, a, b, c, buffer.getUint32(56, true), 9, 0xc33707d6);
    c = gg(c, d, a, b, buffer.getUint32(12, true), 14, 0xf4d50d87);
    b = gg(b, c, d, a, buffer.getUint32(32, true), 20, 0x455a14ed);
    a = gg(a, b, c, d, buffer.getUint32(52, true), 5, 0xa9e3e905);
    d = gg(d, a, b, c, buffer.getUint32(8, true), 9, 0xfcefa3f8);
    c = gg(c, d, a, b, buffer.getUint32(28, true), 14, 0x676f02d9);
    b = gg(b, c, d, a, buffer.getUint32(48, true), 20, 0x8d2a4c8a);
    a = hh(a, b, c, d, buffer.getUint32(20, true), 4, 0xfffa3942);
    d = hh(d, a, b, c, buffer.getUint32(32, true), 11, 0x8771f681);
    c = hh(c, d, a, b, buffer.getUint32(44, true), 16, 0x6d9d6122);
    b = hh(b, c, d, a, buffer.getUint32(56, true), 23, 0xfde5380c);
    a = hh(a, b, c, d, buffer.getUint32(4, true), 4, 0xa4beea44);
    d = hh(d, a, b, c, buffer.getUint32(16, true), 11, 0x4bdecfa9);
    c = hh(c, d, a, b, buffer.getUint32(28, true), 16, 0xf6bb4b60);
    b = hh(b, c, d, a, buffer.getUint32(40, true), 23, 0xbebfbc70);
    a = hh(a, b, c, d, buffer.getUint32(52, true), 4, 0x289b7ec6);
    d = hh(d, a, b, c, buffer.getUint32(0, true), 11, 0xeaa127fa);
    c = hh(c, d, a, b, buffer.getUint32(12, true), 16, 0xd4ef3085);
    b = hh(b, c, d, a, buffer.getUint32(24, true), 23, 0x04881d05);
    a = hh(a, b, c, d, buffer.getUint32(36, true), 4, 0xd9d4d039);
    d = hh(d, a, b, c, buffer.getUint32(48, true), 11, 0xe6db99e5);
    c = hh(c, d, a, b, buffer.getUint32(60, true), 16, 0x1fa27cf8);
    b = hh(b, c, d, a, buffer.getUint32(8, true), 23, 0xc4ac5665);
    a = ii(a, b, c, d, buffer.getUint32(0, true), 6, 0xf4292244);
    d = ii(d, a, b, c, buffer.getUint32(28, true), 10, 0x432aff97);
    c = ii(c, d, a, b, buffer.getUint32(56, true), 15, 0xab9423a7);
    b = ii(b, c, d, a, buffer.getUint32(20, true), 21, 0xfc93a039);
    a = ii(a, b, c, d, buffer.getUint32(48, true), 6, 0x655b59c3);
    d = ii(d, a, b, c, buffer.getUint32(12, true), 10, 0x8f0ccc92);
    c = ii(c, d, a, b, buffer.getUint32(40, true), 15, 0xffeff47d);
    b = ii(b, c, d, a, buffer.getUint32(4, true), 21, 0x85845dd1);
    a = ii(a, b, c, d, buffer.getUint32(32, true), 6, 0x6fa87e4f);
    d = ii(d, a, b, c, buffer.getUint32(60, true), 10, 0xfe2ce6e0);
    c = ii(c, d, a, b, buffer.getUint32(24, true), 15, 0xa3014314);
    b = ii(b, c, d, a, buffer.getUint32(52, true), 21, 0x4e0811a1);
    a = ii(a, b, c, d, buffer.getUint32(16, true), 6, 0xf7537e82);
    d = ii(d, a, b, c, buffer.getUint32(44, true), 10, 0xbd3af235);
    c = ii(c, d, a, b, buffer.getUint32(8, true), 15, 0x2ad7d2bb);
    b = ii(b, c, d, a, buffer.getUint32(36, true), 21, 0xeb86d391);
    state[0] = (a + state[0]) & 0xFFFFFFFF;
    state[1] = (b + state[1]) & 0xFFFFFFFF;
    state[2] = (c + state[2]) & 0xFFFFFFFF;
    state[3] = (d + state[3]) & 0xFFFFFFFF;
};

function cmn(q, a, b, x, s, t) {
    a = (((a + q) & 0xFFFFFFFF) + ((x + t) & 0xFFFFFFFF)) & 0xFFFFFFFF;
    return (((a << s) | (a >>> (32 - s))) + b) & 0xFFFFFFFF;
}

function ff(a, b, c, d, x, s, t) {
    return cmn((b & c) | ((~b) & d), a, b, x, s, t);
}

function gg(a, b, c, d, x, s, t) {
    return cmn((b & d) | (c & (~d)), a, b, x, s, t);
}

function hh(a, b, c, d, x, s, t) {
    return cmn(b ^ c ^ d, a, b, x, s, t);
}

function ii(a, b, c, d, x, s, t) {
    return cmn(c ^ (b | (~d)), a, b, x, s, t);
}

},{"./browserHashUtils":315,"buffer/":407}],318:[function(require,module,exports){
var Buffer = require('buffer/').Buffer;
var hashUtils = require('./browserHashUtils');

var BLOCK_SIZE = 64;

var DIGEST_LENGTH = 20;

var KEY = new Uint32Array([
    0x5a827999,
    0x6ed9eba1,
    0x8f1bbcdc | 0,
    0xca62c1d6 | 0
]);

var INIT = [
    0x6a09e667,
    0xbb67ae85,
    0x3c6ef372,
    0xa54ff53a,
    0x510e527f,
    0x9b05688c,
    0x1f83d9ab,
    0x5be0cd19,
];

var MAX_HASHABLE_LENGTH = Math.pow(2, 53) - 1;

/**
 * @api private
 */
function Sha1() {
    this.h0 = 0x67452301;
    this.h1 = 0xEFCDAB89;
    this.h2 = 0x98BADCFE;
    this.h3 = 0x10325476;
    this.h4 = 0xC3D2E1F0;
    // The first 64 bytes (16 words) is the data chunk
    this.block = new Uint32Array(80);
    this.offset = 0;
    this.shift = 24;
    this.totalLength = 0;
}

/**
 * @api private
 */
module.exports = exports = Sha1;

Sha1.BLOCK_SIZE = BLOCK_SIZE;

Sha1.prototype.update = function (data) {
    if (this.finished) {
        throw new Error('Attempted to update an already finished hash.');
    }

    if (hashUtils.isEmptyData(data)) {
        return this;
    }

    data = hashUtils.convertToBuffer(data);

    var length = data.length;
    this.totalLength += length * 8;
    for (var i = 0; i < length; i++) {
        this.write(data[i]);
    }

    return this;
};

Sha1.prototype.write = function write(byte) {
    this.block[this.offset] |= (byte & 0xff) << this.shift;
    if (this.shift) {
        this.shift -= 8;
    } else {
        this.offset++;
        this.shift = 24;
    }

    if (this.offset === 16) this.processBlock();
};

Sha1.prototype.digest = function (encoding) {
    // Pad
    this.write(0x80);
    if (this.offset > 14 || (this.offset === 14 && this.shift < 24)) {
      this.processBlock();
    }
    this.offset = 14;
    this.shift = 24;

    // 64-bit length big-endian
    this.write(0x00); // numbers this big aren't accurate in javascript anyway
    this.write(0x00); // ..So just hard-code to zero.
    this.write(this.totalLength > 0xffffffffff ? this.totalLength / 0x10000000000 : 0x00);
    this.write(this.totalLength > 0xffffffff ? this.totalLength / 0x100000000 : 0x00);
    for (var s = 24; s >= 0; s -= 8) {
        this.write(this.totalLength >> s);
    }
    // The value in state is little-endian rather than big-endian, so flip
    // each word into a new Uint8Array
    var out = new Buffer(DIGEST_LENGTH);
    var outView = new DataView(out.buffer);
    outView.setUint32(0, this.h0, false);
    outView.setUint32(4, this.h1, false);
    outView.setUint32(8, this.h2, false);
    outView.setUint32(12, this.h3, false);
    outView.setUint32(16, this.h4, false);

    return encoding ? out.toString(encoding) : out;
};

Sha1.prototype.processBlock = function processBlock() {
    // Extend the sixteen 32-bit words into eighty 32-bit words:
    for (var i = 16; i < 80; i++) {
      var w = this.block[i - 3] ^ this.block[i - 8] ^ this.block[i - 14] ^ this.block[i - 16];
      this.block[i] = (w << 1) | (w >>> 31);
    }

    // Initialize hash value for this chunk:
    var a = this.h0;
    var b = this.h1;
    var c = this.h2;
    var d = this.h3;
    var e = this.h4;
    var f, k;

    // Main loop:
    for (i = 0; i < 80; i++) {
      if (i < 20) {
        f = d ^ (b & (c ^ d));
        k = 0x5A827999;
      }
      else if (i < 40) {
        f = b ^ c ^ d;
        k = 0x6ED9EBA1;
      }
      else if (i < 60) {
        f = (b & c) | (d & (b | c));
        k = 0x8F1BBCDC;
      }
      else {
        f = b ^ c ^ d;
        k = 0xCA62C1D6;
      }
      var temp = (a << 5 | a >>> 27) + f + e + k + (this.block[i]|0);
      e = d;
      d = c;
      c = (b << 30 | b >>> 2);
      b = a;
      a = temp;
    }

    // Add this chunk's hash to result so far:
    this.h0 = (this.h0 + a) | 0;
    this.h1 = (this.h1 + b) | 0;
    this.h2 = (this.h2 + c) | 0;
    this.h3 = (this.h3 + d) | 0;
    this.h4 = (this.h4 + e) | 0;

    // The block is now reusable.
    this.offset = 0;
    for (i = 0; i < 16; i++) {
        this.block[i] = 0;
    }
};

},{"./browserHashUtils":315,"buffer/":407}],319:[function(require,module,exports){
var Buffer = require('buffer/').Buffer;
var hashUtils = require('./browserHashUtils');

var BLOCK_SIZE = 64;

var DIGEST_LENGTH = 32;

var KEY = new Uint32Array([
    0x428a2f98,
    0x71374491,
    0xb5c0fbcf,
    0xe9b5dba5,
    0x3956c25b,
    0x59f111f1,
    0x923f82a4,
    0xab1c5ed5,
    0xd807aa98,
    0x12835b01,
    0x243185be,
    0x550c7dc3,
    0x72be5d74,
    0x80deb1fe,
    0x9bdc06a7,
    0xc19bf174,
    0xe49b69c1,
    0xefbe4786,
    0x0fc19dc6,
    0x240ca1cc,
    0x2de92c6f,
    0x4a7484aa,
    0x5cb0a9dc,
    0x76f988da,
    0x983e5152,
    0xa831c66d,
    0xb00327c8,
    0xbf597fc7,
    0xc6e00bf3,
    0xd5a79147,
    0x06ca6351,
    0x14292967,
    0x27b70a85,
    0x2e1b2138,
    0x4d2c6dfc,
    0x53380d13,
    0x650a7354,
    0x766a0abb,
    0x81c2c92e,
    0x92722c85,
    0xa2bfe8a1,
    0xa81a664b,
    0xc24b8b70,
    0xc76c51a3,
    0xd192e819,
    0xd6990624,
    0xf40e3585,
    0x106aa070,
    0x19a4c116,
    0x1e376c08,
    0x2748774c,
    0x34b0bcb5,
    0x391c0cb3,
    0x4ed8aa4a,
    0x5b9cca4f,
    0x682e6ff3,
    0x748f82ee,
    0x78a5636f,
    0x84c87814,
    0x8cc70208,
    0x90befffa,
    0xa4506ceb,
    0xbef9a3f7,
    0xc67178f2
]);

var INIT = [
    0x6a09e667,
    0xbb67ae85,
    0x3c6ef372,
    0xa54ff53a,
    0x510e527f,
    0x9b05688c,
    0x1f83d9ab,
    0x5be0cd19,
];

var MAX_HASHABLE_LENGTH = Math.pow(2, 53) - 1;

/**
 * @private
 */
function Sha256() {
    this.state = [
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19,
    ];
    this.temp = new Int32Array(64);
    this.buffer = new Uint8Array(64);
    this.bufferLength = 0;
    this.bytesHashed = 0;
    /**
     * @private
     */
    this.finished = false;
}

/**
 * @api private
 */
module.exports = exports = Sha256;

Sha256.BLOCK_SIZE = BLOCK_SIZE;

Sha256.prototype.update = function (data) {
    if (this.finished) {
        throw new Error('Attempted to update an already finished hash.');
    }

    if (hashUtils.isEmptyData(data)) {
        return this;
    }

    data = hashUtils.convertToBuffer(data);

    var position = 0;
    var byteLength = data.byteLength;
    this.bytesHashed += byteLength;
    if (this.bytesHashed * 8 > MAX_HASHABLE_LENGTH) {
        throw new Error('Cannot hash more than 2^53 - 1 bits');
    }

    while (byteLength > 0) {
        this.buffer[this.bufferLength++] = data[position++];
        byteLength--;
        if (this.bufferLength === BLOCK_SIZE) {
            this.hashBuffer();
            this.bufferLength = 0;
        }
    }

    return this;
};

Sha256.prototype.digest = function (encoding) {
    if (!this.finished) {
        var bitsHashed = this.bytesHashed * 8;
        var bufferView = new DataView(this.buffer.buffer, this.buffer.byteOffset, this.buffer.byteLength);
        var undecoratedLength = this.bufferLength;
        bufferView.setUint8(this.bufferLength++, 0x80);
        // Ensure the final block has enough room for the hashed length
        if (undecoratedLength % BLOCK_SIZE >= BLOCK_SIZE - 8) {
            for (var i = this.bufferLength; i < BLOCK_SIZE; i++) {
                bufferView.setUint8(i, 0);
            }
            this.hashBuffer();
            this.bufferLength = 0;
        }
        for (var i = this.bufferLength; i < BLOCK_SIZE - 8; i++) {
            bufferView.setUint8(i, 0);
        }
        bufferView.setUint32(BLOCK_SIZE - 8, Math.floor(bitsHashed / 0x100000000), true);
        bufferView.setUint32(BLOCK_SIZE - 4, bitsHashed);
        this.hashBuffer();
        this.finished = true;
    }
    // The value in state is little-endian rather than big-endian, so flip
    // each word into a new Uint8Array
    var out = new Buffer(DIGEST_LENGTH);
    for (var i = 0; i < 8; i++) {
        out[i * 4] = (this.state[i] >>> 24) & 0xff;
        out[i * 4 + 1] = (this.state[i] >>> 16) & 0xff;
        out[i * 4 + 2] = (this.state[i] >>> 8) & 0xff;
        out[i * 4 + 3] = (this.state[i] >>> 0) & 0xff;
    }
    return encoding ? out.toString(encoding) : out;
};

Sha256.prototype.hashBuffer = function () {
    var _a = this,
        buffer = _a.buffer,
        state = _a.state;
    var state0 = state[0],
        state1 = state[1],
        state2 = state[2],
        state3 = state[3],
        state4 = state[4],
        state5 = state[5],
        state6 = state[6],
        state7 = state[7];
    for (var i = 0; i < BLOCK_SIZE; i++) {
        if (i < 16) {
            this.temp[i] = (((buffer[i * 4] & 0xff) << 24) |
                ((buffer[(i * 4) + 1] & 0xff) << 16) |
                ((buffer[(i * 4) + 2] & 0xff) << 8) |
                (buffer[(i * 4) + 3] & 0xff));
        }
        else {
            var u = this.temp[i - 2];
            var t1_1 = (u >>> 17 | u << 15) ^
                (u >>> 19 | u << 13) ^
                (u >>> 10);
            u = this.temp[i - 15];
            var t2_1 = (u >>> 7 | u << 25) ^
                (u >>> 18 | u << 14) ^
                (u >>> 3);
            this.temp[i] = (t1_1 + this.temp[i - 7] | 0) +
                (t2_1 + this.temp[i - 16] | 0);
        }
        var t1 = (((((state4 >>> 6 | state4 << 26) ^
            (state4 >>> 11 | state4 << 21) ^
            (state4 >>> 25 | state4 << 7))
            + ((state4 & state5) ^ (~state4 & state6))) | 0)
            + ((state7 + ((KEY[i] + this.temp[i]) | 0)) | 0)) | 0;
        var t2 = (((state0 >>> 2 | state0 << 30) ^
            (state0 >>> 13 | state0 << 19) ^
            (state0 >>> 22 | state0 << 10)) + ((state0 & state1) ^ (state0 & state2) ^ (state1 & state2))) | 0;
        state7 = state6;
        state6 = state5;
        state5 = state4;
        state4 = (state3 + t1) | 0;
        state3 = state2;
        state2 = state1;
        state1 = state0;
        state0 = (t1 + t2) | 0;
    }
    state[0] += state0;
    state[1] += state1;
    state[2] += state2;
    state[3] += state3;
    state[4] += state4;
    state[5] += state5;
    state[6] += state6;
    state[7] += state7;
};

},{"./browserHashUtils":315,"buffer/":407}],320:[function(require,module,exports){
(function (process){
var util = require('./util');

// browser specific modules
util.crypto.lib = require('./browserCryptoLib');
util.Buffer = require('buffer/').Buffer;
util.url = require('url/');
util.querystring = require('querystring/');
util.realClock = require('./realclock/browserClock');
util.environment = 'js';
util.createEventStream = require('./event-stream/buffered-create-event-stream').createEventStream;
util.isBrowser = function() { return true; };
util.isNode = function() { return false; };

var AWS = require('./core');

/**
 * @api private
 */
module.exports = AWS;

require('./credentials');
require('./credentials/credential_provider_chain');
require('./credentials/temporary_credentials');
require('./credentials/chainable_temporary_credentials');
require('./credentials/web_identity_credentials');
require('./credentials/cognito_identity_credentials');
require('./credentials/saml_credentials');

// Load the DOMParser XML parser
AWS.XML.Parser = require('./xml/browser_parser');

// Load the XHR HttpClient
require('./http/xhr');

if (typeof process === 'undefined') {
  var process = {
    browser: true
  };
}

}).call(this,require('_process'))
},{"./browserCryptoLib":314,"./core":324,"./credentials":325,"./credentials/chainable_temporary_credentials":326,"./credentials/cognito_identity_credentials":327,"./credentials/credential_provider_chain":328,"./credentials/saml_credentials":329,"./credentials/temporary_credentials":330,"./credentials/web_identity_credentials":331,"./event-stream/buffered-create-event-stream":339,"./http/xhr":347,"./realclock/browserClock":366,"./util":398,"./xml/browser_parser":399,"_process":412,"buffer/":407,"querystring/":419,"url/":421}],321:[function(require,module,exports){
var AWS = require('../core'),
    url = AWS.util.url,
    crypto = AWS.util.crypto.lib,
    base64Encode = AWS.util.base64.encode,
    inherit = AWS.util.inherit;

var queryEncode = function (string) {
    var replacements = {
        '+': '-',
        '=': '_',
        '/': '~'
    };
    return string.replace(/[\+=\/]/g, function (match) {
        return replacements[match];
    });
};

var signPolicy = function (policy, privateKey) {
    var sign = crypto.createSign('RSA-SHA1');
    sign.write(policy);
    return queryEncode(sign.sign(privateKey, 'base64'));
};

var signWithCannedPolicy = function (url, expires, keyPairId, privateKey) {
    var policy = JSON.stringify({
        Statement: [
            {
                Resource: url,
                Condition: { DateLessThan: { 'AWS:EpochTime': expires } }
            }
        ]
    });

    return {
        Expires: expires,
        'Key-Pair-Id': keyPairId,
        Signature: signPolicy(policy.toString(), privateKey)
    };
};

var signWithCustomPolicy = function (policy, keyPairId, privateKey) {
    policy = policy.replace(/\s/mg, '');

    return {
        Policy: queryEncode(base64Encode(policy)),
        'Key-Pair-Id': keyPairId,
        Signature: signPolicy(policy, privateKey)
    };
};

var determineScheme = function (url) {
    var parts = url.split('://');
    if (parts.length < 2) {
        throw new Error('Invalid URL.');
    }

    return parts[0].replace('*', '');
};

var getRtmpUrl = function (rtmpUrl) {
    var parsed = url.parse(rtmpUrl);
    return parsed.path.replace(/^\//, '') + (parsed.hash || '');
};

var getResource = function (url) {
    switch (determineScheme(url)) {
        case 'http':
        case 'https':
            return url;
        case 'rtmp':
            return getRtmpUrl(url);
        default:
            throw new Error('Invalid URI scheme. Scheme must be one of'
                + ' http, https, or rtmp');
    }
};

var handleError = function (err, callback) {
    if (!callback || typeof callback !== 'function') {
        throw err;
    }

    callback(err);
};

var handleSuccess = function (result, callback) {
    if (!callback || typeof callback !== 'function') {
        return result;
    }

    callback(null, result);
};

AWS.CloudFront.Signer = inherit({
    /**
     * A signer object can be used to generate signed URLs and cookies for granting
     * access to content on restricted CloudFront distributions.
     *
     * @see http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/PrivateContent.html
     *
     * @param keyPairId [String]    (Required) The ID of the CloudFront key pair
     *                              being used.
     * @param privateKey [String]   (Required) A private key in RSA format.
     */
    constructor: function Signer(keyPairId, privateKey) {
        if (keyPairId === void 0 || privateKey === void 0) {
            throw new Error('A key pair ID and private key are required');
        }

        this.keyPairId = keyPairId;
        this.privateKey = privateKey;
    },

    /**
     * Create a signed Amazon CloudFront Cookie.
     *
     * @param options [Object]            The options to create a signed cookie.
     * @option options url [String]     The URL to which the signature will grant
     *                                  access. Required unless you pass in a full
     *                                  policy.
     * @option options expires [Number] A Unix UTC timestamp indicating when the
     *                                  signature should expire. Required unless you
     *                                  pass in a full policy.
     * @option options policy [String]  A CloudFront JSON policy. Required unless
     *                                  you pass in a url and an expiry time.
     *
     * @param cb [Function] if a callback is provided, this function will
     *   pass the hash as the second parameter (after the error parameter) to
     *   the callback function.
     *
     * @return [Object] if called synchronously (with no callback), returns the
     *   signed cookie parameters.
     * @return [null] nothing is returned if a callback is provided.
     */
    getSignedCookie: function (options, cb) {
        var signatureHash = 'policy' in options
            ? signWithCustomPolicy(options.policy, this.keyPairId, this.privateKey)
            : signWithCannedPolicy(options.url, options.expires, this.keyPairId, this.privateKey);

        var cookieHash = {};
        for (var key in signatureHash) {
            if (Object.prototype.hasOwnProperty.call(signatureHash, key)) {
                cookieHash['CloudFront-' + key] = signatureHash[key];
            }
        }

        return handleSuccess(cookieHash, cb);
    },

    /**
     * Create a signed Amazon CloudFront URL.
     *
     * Keep in mind that URLs meant for use in media/flash players may have
     * different requirements for URL formats (e.g. some require that the
     * extension be removed, some require the file name to be prefixed
     * - mp4:<path>, some require you to add "/cfx/st" into your URL).
     *
     * @param options [Object]          The options to create a signed URL.
     * @option options url [String]     The URL to which the signature will grant
     *                                  access. Any query params included with
     *                                  the URL should be encoded. Required.
     * @option options expires [Number] A Unix UTC timestamp indicating when the
     *                                  signature should expire. Required unless you
     *                                  pass in a full policy.
     * @option options policy [String]  A CloudFront JSON policy. Required unless
     *                                  you pass in a url and an expiry time.
     *
     * @param cb [Function] if a callback is provided, this function will
     *   pass the URL as the second parameter (after the error parameter) to
     *   the callback function.
     *
     * @return [String] if called synchronously (with no callback), returns the
     *   signed URL.
     * @return [null] nothing is returned if a callback is provided.
     */
    getSignedUrl: function (options, cb) {
        try {
            var resource = getResource(options.url);
        } catch (err) {
            return handleError(err, cb);
        }

        var parsedUrl = url.parse(options.url, true),
            signatureHash = Object.prototype.hasOwnProperty.call(options, 'policy')
                ? signWithCustomPolicy(options.policy, this.keyPairId, this.privateKey)
                : signWithCannedPolicy(resource, options.expires, this.keyPairId, this.privateKey);

        parsedUrl.search = null;
        for (var key in signatureHash) {
            if (Object.prototype.hasOwnProperty.call(signatureHash, key)) {
                parsedUrl.query[key] = signatureHash[key];
            }
        }

        try {
            var signedUrl = determineScheme(options.url) === 'rtmp'
                    ? getRtmpUrl(url.format(parsedUrl))
                    : url.format(parsedUrl);
        } catch (err) {
            return handleError(err, cb);
        }

        return handleSuccess(signedUrl, cb);
    }
});

/**
 * @api private
 */
module.exports = AWS.CloudFront.Signer;

},{"../core":324}],322:[function(require,module,exports){
var AWS = require('./core');
require('./credentials');
require('./credentials/credential_provider_chain');
var PromisesDependency;

/**
 * The main configuration class used by all service objects to set
 * the region, credentials, and other options for requests.
 *
 * By default, credentials and region settings are left unconfigured.
 * This should be configured by the application before using any
 * AWS service APIs.
 *
 * In order to set global configuration options, properties should
 * be assigned to the global {AWS.config} object.
 *
 * @see AWS.config
 *
 * @!group General Configuration Options
 *
 * @!attribute credentials
 *   @return [AWS.Credentials] the AWS credentials to sign requests with.
 *
 * @!attribute region
 *   @example Set the global region setting to us-west-2
 *     AWS.config.update({region: 'us-west-2'});
 *   @return [AWS.Credentials] The region to send service requests to.
 *   @see http://docs.amazonwebservices.com/general/latest/gr/rande.html
 *     A list of available endpoints for each AWS service
 *
 * @!attribute maxRetries
 *   @return [Integer] the maximum amount of retries to perform for a
 *     service request. By default this value is calculated by the specific
 *     service object that the request is being made to.
 *
 * @!attribute maxRedirects
 *   @return [Integer] the maximum amount of redirects to follow for a
 *     service request. Defaults to 10.
 *
 * @!attribute paramValidation
 *   @return [Boolean|map] whether input parameters should be validated against
 *     the operation description before sending the request. Defaults to true.
 *     Pass a map to enable any of the following specific validation features:
 *
 *     * **min** [Boolean] &mdash; Validates that a value meets the min
 *       constraint. This is enabled by default when paramValidation is set
 *       to `true`.
 *     * **max** [Boolean] &mdash; Validates that a value meets the max
 *       constraint.
 *     * **pattern** [Boolean] &mdash; Validates that a string value matches a
 *       regular expression.
 *     * **enum** [Boolean] &mdash; Validates that a string value matches one
 *       of the allowable enum values.
 *
 * @!attribute computeChecksums
 *   @return [Boolean] whether to compute checksums for payload bodies when
 *     the service accepts it (currently supported in S3 only).
 *
 * @!attribute convertResponseTypes
 *   @return [Boolean] whether types are converted when parsing response data.
 *     Currently only supported for JSON based services. Turning this off may
 *     improve performance on large response payloads. Defaults to `true`.
 *
 * @!attribute correctClockSkew
 *   @return [Boolean] whether to apply a clock skew correction and retry
 *     requests that fail because of an skewed client clock. Defaults to
 *     `false`.
 *
 * @!attribute sslEnabled
 *   @return [Boolean] whether SSL is enabled for requests
 *
 * @!attribute s3ForcePathStyle
 *   @return [Boolean] whether to force path style URLs for S3 objects
 *
 * @!attribute s3BucketEndpoint
 *   @note Setting this configuration option requires an `endpoint` to be
 *     provided explicitly to the service constructor.
 *   @return [Boolean] whether the provided endpoint addresses an individual
 *     bucket (false if it addresses the root API endpoint).
 *
 * @!attribute s3DisableBodySigning
 *   @return [Boolean] whether to disable S3 body signing when using signature version `v4`.
 *     Body signing can only be disabled when using https. Defaults to `true`.
 *
 * @!attribute s3UsEast1RegionalEndpoint
 *   @return ['legacy'|'regional'] when region is set to 'us-east-1', whether to send s3
 *     request to global endpoints or 'us-east-1' regional endpoints. This config is only
 *     applicable to S3 client;
 *     Defaults to 'legacy'
 * @!attribute s3UseArnRegion
 *   @return [Boolean] whether to override the request region with the region inferred
 *     from requested resource's ARN. Only available for S3 buckets
 *     Defaults to `true`
 *
 * @!attribute useAccelerateEndpoint
 *   @note This configuration option is only compatible with S3 while accessing
 *     dns-compatible buckets.
 *   @return [Boolean] Whether to use the Accelerate endpoint with the S3 service.
 *     Defaults to `false`.
 *
 * @!attribute retryDelayOptions
 *   @example Set the base retry delay for all services to 300 ms
 *     AWS.config.update({retryDelayOptions: {base: 300}});
 *     // Delays with maxRetries = 3: 300, 600, 1200
 *   @example Set a custom backoff function to provide delay values on retries
 *     AWS.config.update({retryDelayOptions: {customBackoff: function(retryCount, err) {
 *       // returns delay in ms
 *     }}});
 *   @return [map] A set of options to configure the retry delay on retryable errors.
 *     Currently supported options are:
 *
 *     * **base** [Integer] &mdash; The base number of milliseconds to use in the
 *       exponential backoff for operation retries. Defaults to 100 ms for all services except
 *       DynamoDB, where it defaults to 50ms.
 *
 *     * **customBackoff ** [function] &mdash; A custom function that accepts a
 *       retry count and error and returns the amount of time to delay in
 *       milliseconds. If the result is a non-zero negative value, no further
 *       retry attempts will be made. The `base` option will be ignored if this
 *       option is supplied.
 *
 * @!attribute httpOptions
 *   @return [map] A set of options to pass to the low-level HTTP request.
 *     Currently supported options are:
 *
 *     * **proxy** [String] &mdash; the URL to proxy requests through
 *     * **agent** [http.Agent, https.Agent] &mdash; the Agent object to perform
 *       HTTP requests with. Used for connection pooling. Note that for
 *       SSL connections, a special Agent object is used in order to enable
 *       peer certificate verification. This feature is only supported in the
 *       Node.js environment.
 *     * **connectTimeout** [Integer] &mdash; Sets the socket to timeout after
 *       failing to establish a connection with the server after
 *       `connectTimeout` milliseconds. This timeout has no effect once a socket
 *       connection has been established.
 *     * **timeout** [Integer] &mdash; Sets the socket to timeout after timeout
 *       milliseconds of inactivity on the socket. Defaults to two minutes
 *       (120000)
 *     * **xhrAsync** [Boolean] &mdash; Whether the SDK will send asynchronous
 *       HTTP requests. Used in the browser environment only. Set to false to
 *       send requests synchronously. Defaults to true (async on).
 *     * **xhrWithCredentials** [Boolean] &mdash; Sets the "withCredentials"
 *       property of an XMLHttpRequest object. Used in the browser environment
 *       only. Defaults to false.
 * @!attribute logger
 *   @return [#write,#log] an object that responds to .write() (like a stream)
 *     or .log() (like the console object) in order to log information about
 *     requests
 *
 * @!attribute systemClockOffset
 *   @return [Number] an offset value in milliseconds to apply to all signing
 *     times. Use this to compensate for clock skew when your system may be
 *     out of sync with the service time. Note that this configuration option
 *     can only be applied to the global `AWS.config` object and cannot be
 *     overridden in service-specific configuration. Defaults to 0 milliseconds.
 *
 * @!attribute signatureVersion
 *   @return [String] the signature version to sign requests with (overriding
 *     the API configuration). Possible values are: 'v2', 'v3', 'v4'.
 *
 * @!attribute signatureCache
 *   @return [Boolean] whether the signature to sign requests with (overriding
 *     the API configuration) is cached. Only applies to the signature version 'v4'.
 *     Defaults to `true`.
 *
 * @!attribute endpointDiscoveryEnabled
 *   @return [Boolean|undefined] whether to call operations with endpoints
 *     given by service dynamically. Setting this config to `true` will enable
 *     endpoint discovery for all applicable operations. Setting it to `false`
 *     will explicitly disable endpoint discovery even though operations that
 *     require endpoint discovery will presumably fail. Leaving it to
 *     `undefined` means SDK only do endpoint discovery when it's required.
 *     Defaults to `undefined`
 *
 * @!attribute endpointCacheSize
 *   @return [Number] the size of the global cache storing endpoints from endpoint
 *     discovery operations. Once endpoint cache is created, updating this setting
 *     cannot change existing cache size.
 *     Defaults to 1000
 *
 * @!attribute hostPrefixEnabled
 *   @return [Boolean] whether to marshal request parameters to the prefix of
 *     hostname. Defaults to `true`.
 *
 * @!attribute stsRegionalEndpoints
 *   @return ['legacy'|'regional'] whether to send sts request to global endpoints or
 *     regional endpoints.
 *     Defaults to 'legacy'
 */
AWS.Config = AWS.util.inherit({
  /**
   * @!endgroup
   */

  /**
   * Creates a new configuration object. This is the object that passes
   * option data along to service requests, including credentials, security,
   * region information, and some service specific settings.
   *
   * @example Creating a new configuration object with credentials and region
   *   var config = new AWS.Config({
   *     accessKeyId: 'AKID', secretAccessKey: 'SECRET', region: 'us-west-2'
   *   });
   * @option options accessKeyId [String] your AWS access key ID.
   * @option options secretAccessKey [String] your AWS secret access key.
   * @option options sessionToken [AWS.Credentials] the optional AWS
   *   session token to sign requests with.
   * @option options credentials [AWS.Credentials] the AWS credentials
   *   to sign requests with. You can either specify this object, or
   *   specify the accessKeyId and secretAccessKey options directly.
   * @option options credentialProvider [AWS.CredentialProviderChain] the
   *   provider chain used to resolve credentials if no static `credentials`
   *   property is set.
   * @option options region [String] the region to send service requests to.
   *   See {region} for more information.
   * @option options maxRetries [Integer] the maximum amount of retries to
   *   attempt with a request. See {maxRetries} for more information.
   * @option options maxRedirects [Integer] the maximum amount of redirects to
   *   follow with a request. See {maxRedirects} for more information.
   * @option options sslEnabled [Boolean] whether to enable SSL for
   *   requests.
   * @option options paramValidation [Boolean|map] whether input parameters
   *   should be validated against the operation description before sending
   *   the request. Defaults to true. Pass a map to enable any of the
   *   following specific validation features:
   *
   *   * **min** [Boolean] &mdash; Validates that a value meets the min
   *     constraint. This is enabled by default when paramValidation is set
   *     to `true`.
   *   * **max** [Boolean] &mdash; Validates that a value meets the max
   *     constraint.
   *   * **pattern** [Boolean] &mdash; Validates that a string value matches a
   *     regular expression.
   *   * **enum** [Boolean] &mdash; Validates that a string value matches one
   *     of the allowable enum values.
   * @option options computeChecksums [Boolean] whether to compute checksums
   *   for payload bodies when the service accepts it (currently supported
   *   in S3 only)
   * @option options convertResponseTypes [Boolean] whether types are converted
   *     when parsing response data. Currently only supported for JSON based
   *     services. Turning this off may improve performance on large response
   *     payloads. Defaults to `true`.
   * @option options correctClockSkew [Boolean] whether to apply a clock skew
   *     correction and retry requests that fail because of an skewed client
   *     clock. Defaults to `false`.
   * @option options s3ForcePathStyle [Boolean] whether to force path
   *   style URLs for S3 objects.
   * @option options s3BucketEndpoint [Boolean] whether the provided endpoint
   *   addresses an individual bucket (false if it addresses the root API
   *   endpoint). Note that setting this configuration option requires an
   *   `endpoint` to be provided explicitly to the service constructor.
   * @option options s3DisableBodySigning [Boolean] whether S3 body signing
   *   should be disabled when using signature version `v4`. Body signing
   *   can only be disabled when using https. Defaults to `true`.
   * @option options s3UsEast1RegionalEndpoint ['legacy'|'regional'] when region
   *   is set to 'us-east-1', whether to send s3 request to global endpoints or
   *   'us-east-1' regional endpoints. This config is only applicable to S3 client.
   *   Defaults to `legacy`
   * @option options s3UseArnRegion [Boolean] whether to override the request region
   *   with the region inferred from requested resource's ARN. Only available for S3 buckets
   *   Defaults to `true`
   *
   * @option options retryDelayOptions [map] A set of options to configure
   *   the retry delay on retryable errors. Currently supported options are:
   *
   *   * **base** [Integer] &mdash; The base number of milliseconds to use in the
   *     exponential backoff for operation retries. Defaults to 100 ms for all
   *     services except DynamoDB, where it defaults to 50ms.
   *   * **customBackoff ** [function] &mdash; A custom function that accepts a
   *     retry count and error and returns the amount of time to delay in
   *     milliseconds. If the result is a non-zero negative value, no further
   *     retry attempts will be made. The `base` option will be ignored if this
   *     option is supplied.
   * @option options httpOptions [map] A set of options to pass to the low-level
   *   HTTP request. Currently supported options are:
   *
   *   * **proxy** [String] &mdash; the URL to proxy requests through
   *   * **agent** [http.Agent, https.Agent] &mdash; the Agent object to perform
   *     HTTP requests with. Used for connection pooling. Defaults to the global
   *     agent (`http.globalAgent`) for non-SSL connections. Note that for
   *     SSL connections, a special Agent object is used in order to enable
   *     peer certificate verification. This feature is only available in the
   *     Node.js environment.
   *   * **connectTimeout** [Integer] &mdash; Sets the socket to timeout after
   *     failing to establish a connection with the server after
   *     `connectTimeout` milliseconds. This timeout has no effect once a socket
   *     connection has been established.
   *   * **timeout** [Integer] &mdash; Sets the socket to timeout after timeout
   *     milliseconds of inactivity on the socket. Defaults to two minutes
   *     (120000).
   *   * **xhrAsync** [Boolean] &mdash; Whether the SDK will send asynchronous
   *     HTTP requests. Used in the browser environment only. Set to false to
   *     send requests synchronously. Defaults to true (async on).
   *   * **xhrWithCredentials** [Boolean] &mdash; Sets the "withCredentials"
   *     property of an XMLHttpRequest object. Used in the browser environment
   *     only. Defaults to false.
   * @option options apiVersion [String, Date] a String in YYYY-MM-DD format
   *   (or a date) that represents the latest possible API version that can be
   *   used in all services (unless overridden by `apiVersions`). Specify
   *   'latest' to use the latest possible version.
   * @option options apiVersions [map<String, String|Date>] a map of service
   *   identifiers (the lowercase service class name) with the API version to
   *   use when instantiating a service. Specify 'latest' for each individual
   *   that can use the latest available version.
   * @option options logger [#write,#log] an object that responds to .write()
   *   (like a stream) or .log() (like the console object) in order to log
   *   information about requests
   * @option options systemClockOffset [Number] an offset value in milliseconds
   *   to apply to all signing times. Use this to compensate for clock skew
   *   when your system may be out of sync with the service time. Note that
   *   this configuration option can only be applied to the global `AWS.config`
   *   object and cannot be overridden in service-specific configuration.
   *   Defaults to 0 milliseconds.
   * @option options signatureVersion [String] the signature version to sign
   *   requests with (overriding the API configuration). Possible values are:
   *   'v2', 'v3', 'v4'.
   * @option options signatureCache [Boolean] whether the signature to sign
   *   requests with (overriding the API configuration) is cached. Only applies
   *   to the signature version 'v4'. Defaults to `true`.
   * @option options dynamoDbCrc32 [Boolean] whether to validate the CRC32
   *   checksum of HTTP response bodies returned by DynamoDB. Default: `true`.
   * @option options useAccelerateEndpoint [Boolean] Whether to use the
   *   S3 Transfer Acceleration endpoint with the S3 service. Default: `false`.
   * @option options clientSideMonitoring [Boolean] whether to collect and
   *   publish this client's performance metrics of all its API requests.
   * @option options endpointDiscoveryEnabled [Boolean|undefined] whether to
   *   call operations with endpoints given by service dynamically. Setting this
   * config to `true` will enable endpoint discovery for all applicable operations.
   *   Setting it to `false` will explicitly disable endpoint discovery even though
   *   operations that require endpoint discovery will presumably fail. Leaving it
   *   to `undefined` means SDK will only do endpoint discovery when it's required.
   *   Defaults to `undefined`
   * @option options endpointCacheSize [Number] the size of the global cache storing
   *   endpoints from endpoint discovery operations. Once endpoint cache is created,
   *   updating this setting cannot change existing cache size.
   *   Defaults to 1000
   * @option options hostPrefixEnabled [Boolean] whether to marshal request
   *   parameters to the prefix of hostname.
   *   Defaults to `true`.
   * @option options stsRegionalEndpoints ['legacy'|'regional'] whether to send sts request
   *   to global endpoints or regional endpoints.
   *   Defaults to 'legacy'.
   */
  constructor: function Config(options) {
    if (options === undefined) options = {};
    options = this.extractCredentials(options);

    AWS.util.each.call(this, this.keys, function (key, value) {
      this.set(key, options[key], value);
    });
  },

  /**
   * @!group Managing Credentials
   */

  /**
   * Loads credentials from the configuration object. This is used internally
   * by the SDK to ensure that refreshable {Credentials} objects are properly
   * refreshed and loaded when sending a request. If you want to ensure that
   * your credentials are loaded prior to a request, you can use this method
   * directly to provide accurate credential data stored in the object.
   *
   * @note If you configure the SDK with static or environment credentials,
   *   the credential data should already be present in {credentials} attribute.
   *   This method is primarily necessary to load credentials from asynchronous
   *   sources, or sources that can refresh credentials periodically.
   * @example Getting your access key
   *   AWS.config.getCredentials(function(err) {
   *     if (err) console.log(err.stack); // credentials not loaded
   *     else console.log("Access Key:", AWS.config.credentials.accessKeyId);
   *   })
   * @callback callback function(err)
   *   Called when the {credentials} have been properly set on the configuration
   *   object.
   *
   *   @param err [Error] if this is set, credentials were not successfully
   *     loaded and this error provides information why.
   * @see credentials
   * @see Credentials
   */
  getCredentials: function getCredentials(callback) {
    var self = this;

    function finish(err) {
      callback(err, err ? null : self.credentials);
    }

    function credError(msg, err) {
      return new AWS.util.error(err || new Error(), {
        code: 'CredentialsError',
        message: msg,
        name: 'CredentialsError'
      });
    }

    function getAsyncCredentials() {
      self.credentials.get(function(err) {
        if (err) {
          var msg = 'Could not load credentials from ' +
            self.credentials.constructor.name;
          err = credError(msg, err);
        }
        finish(err);
      });
    }

    function getStaticCredentials() {
      var err = null;
      if (!self.credentials.accessKeyId || !self.credentials.secretAccessKey) {
        err = credError('Missing credentials');
      }
      finish(err);
    }

    if (self.credentials) {
      if (typeof self.credentials.get === 'function') {
        getAsyncCredentials();
      } else { // static credentials
        getStaticCredentials();
      }
    } else if (self.credentialProvider) {
      self.credentialProvider.resolve(function(err, creds) {
        if (err) {
          err = credError('Could not load credentials from any providers', err);
        }
        self.credentials = creds;
        finish(err);
      });
    } else {
      finish(credError('No credentials to load'));
    }
  },

  /**
   * @!group Loading and Setting Configuration Options
   */

  /**
   * @overload update(options, allowUnknownKeys = false)
   *   Updates the current configuration object with new options.
   *
   *   @example Update maxRetries property of a configuration object
   *     config.update({maxRetries: 10});
   *   @param [Object] options a map of option keys and values.
   *   @param [Boolean] allowUnknownKeys whether unknown keys can be set on
   *     the configuration object. Defaults to `false`.
   *   @see constructor
   */
  update: function update(options, allowUnknownKeys) {
    allowUnknownKeys = allowUnknownKeys || false;
    options = this.extractCredentials(options);
    AWS.util.each.call(this, options, function (key, value) {
      if (allowUnknownKeys || Object.prototype.hasOwnProperty.call(this.keys, key) ||
          AWS.Service.hasService(key)) {
        this.set(key, value);
      }
    });
  },

  /**
   * Loads configuration data from a JSON file into this config object.
   * @note Loading configuration will reset all existing configuration
   *   on the object.
   * @!macro nobrowser
   * @param path [String] the path relative to your process's current
   *    working directory to load configuration from.
   * @return [AWS.Config] the same configuration object
   */
  loadFromPath: function loadFromPath(path) {
    this.clear();

    var options = JSON.parse(AWS.util.readFileSync(path));
    var fileSystemCreds = new AWS.FileSystemCredentials(path);
    var chain = new AWS.CredentialProviderChain();
    chain.providers.unshift(fileSystemCreds);
    chain.resolve(function (err, creds) {
      if (err) throw err;
      else options.credentials = creds;
    });

    this.constructor(options);

    return this;
  },

  /**
   * Clears configuration data on this object
   *
   * @api private
   */
  clear: function clear() {
    /*jshint forin:false */
    AWS.util.each.call(this, this.keys, function (key) {
      delete this[key];
    });

    // reset credential provider
    this.set('credentials', undefined);
    this.set('credentialProvider', undefined);
  },

  /**
   * Sets a property on the configuration object, allowing for a
   * default value
   * @api private
   */
  set: function set(property, value, defaultValue) {
    if (value === undefined) {
      if (defaultValue === undefined) {
        defaultValue = this.keys[property];
      }
      if (typeof defaultValue === 'function') {
        this[property] = defaultValue.call(this);
      } else {
        this[property] = defaultValue;
      }
    } else if (property === 'httpOptions' && this[property]) {
      // deep merge httpOptions
      this[property] = AWS.util.merge(this[property], value);
    } else {
      this[property] = value;
    }
  },

  /**
   * All of the keys with their default values.
   *
   * @constant
   * @api private
   */
  keys: {
    credentials: null,
    credentialProvider: null,
    region: null,
    logger: null,
    apiVersions: {},
    apiVersion: null,
    endpoint: undefined,
    httpOptions: {
      timeout: 120000
    },
    maxRetries: undefined,
    maxRedirects: 10,
    paramValidation: true,
    sslEnabled: true,
    s3ForcePathStyle: false,
    s3BucketEndpoint: false,
    s3DisableBodySigning: true,
    s3UsEast1RegionalEndpoint: 'legacy',
    s3UseArnRegion: undefined,
    computeChecksums: true,
    convertResponseTypes: true,
    correctClockSkew: false,
    customUserAgent: null,
    dynamoDbCrc32: true,
    systemClockOffset: 0,
    signatureVersion: null,
    signatureCache: true,
    retryDelayOptions: {},
    useAccelerateEndpoint: false,
    clientSideMonitoring: false,
    endpointDiscoveryEnabled: undefined,
    endpointCacheSize: 1000,
    hostPrefixEnabled: true,
    stsRegionalEndpoints: 'legacy'
  },

  /**
   * Extracts accessKeyId, secretAccessKey and sessionToken
   * from a configuration hash.
   *
   * @api private
   */
  extractCredentials: function extractCredentials(options) {
    if (options.accessKeyId && options.secretAccessKey) {
      options = AWS.util.copy(options);
      options.credentials = new AWS.Credentials(options);
    }
    return options;
  },

  /**
   * Sets the promise dependency the SDK will use wherever Promises are returned.
   * Passing `null` will force the SDK to use native Promises if they are available.
   * If native Promises are not available, passing `null` will have no effect.
   * @param [Constructor] dep A reference to a Promise constructor
   */
  setPromisesDependency: function setPromisesDependency(dep) {
    PromisesDependency = dep;
    // if null was passed in, we should try to use native promises
    if (dep === null && typeof Promise === 'function') {
      PromisesDependency = Promise;
    }
    var constructors = [AWS.Request, AWS.Credentials, AWS.CredentialProviderChain];
    if (AWS.S3) {
      constructors.push(AWS.S3);
      if (AWS.S3.ManagedUpload) {
        constructors.push(AWS.S3.ManagedUpload);
      }
    }
    AWS.util.addPromises(constructors, PromisesDependency);
  },

  /**
   * Gets the promise dependency set by `AWS.config.setPromisesDependency`.
   */
  getPromisesDependency: function getPromisesDependency() {
    return PromisesDependency;
  }
});

/**
 * @return [AWS.Config] The global configuration object singleton instance
 * @readonly
 * @see AWS.Config
 */
AWS.config = new AWS.Config();

},{"./core":324,"./credentials":325,"./credentials/credential_provider_chain":328}],323:[function(require,module,exports){
(function (process){
var AWS = require('./core');
/**
 * @api private
 */
function validateRegionalEndpointsFlagValue(configValue, errorOptions) {
  if (typeof configValue !== 'string') return undefined;
  else if (['legacy', 'regional'].indexOf(configValue.toLowerCase()) >= 0) {
    return configValue.toLowerCase();
  } else {
    throw AWS.util.error(new Error(), errorOptions);
  }
}

/**
 * Resolve the configuration value for regional endpoint from difference sources: client
 * config, environmental variable, shared config file. Value can be case-insensitive
 * 'legacy' or 'reginal'.
 * @param originalConfig user-supplied config object to resolve
 * @param options a map of config property names from individual configuration source
 *  - env: name of environmental variable that refers to the config
 *  - sharedConfig: name of shared configuration file property that refers to the config
 *  - clientConfig: name of client configuration property that refers to the config
 *
 * @api private
 */
function resolveRegionalEndpointsFlag(originalConfig, options) {
  originalConfig = originalConfig || {};
  //validate config value
  var resolved;
  if (originalConfig[options.clientConfig]) {
    resolved = validateRegionalEndpointsFlagValue(originalConfig[options.clientConfig], {
      code: 'InvalidConfiguration',
      message: 'invalid "' + options.clientConfig + '" configuration. Expect "legacy" ' +
      ' or "regional". Got "' + originalConfig[options.clientConfig] + '".'
    });
    if (resolved) return resolved;
  }
  if (!AWS.util.isNode()) return resolved;
  //validate environmental variable
  if (Object.prototype.hasOwnProperty.call(process.env, options.env)) {
    var envFlag = process.env[options.env];
    resolved = validateRegionalEndpointsFlagValue(envFlag, {
      code: 'InvalidEnvironmentalVariable',
      message: 'invalid ' + options.env + ' environmental variable. Expect "legacy" ' +
      ' or "regional". Got "' + process.env[options.env] + '".'
    });
    if (resolved) return resolved;
  }
  //validate shared config file
  var profile = {};
  try {
    var profiles = AWS.util.getProfilesFromSharedConfig(AWS.util.iniLoader);
    profile = profiles[process.env.AWS_PROFILE || AWS.util.defaultProfile];
  } catch (e) {};
  if (profile && Object.prototype.hasOwnProperty.call(profile, options.sharedConfig)) {
    var fileFlag = profile[options.sharedConfig];
    resolved = validateRegionalEndpointsFlagValue(fileFlag, {
      code: 'InvalidConfiguration',
      message: 'invalid ' + options.sharedConfig + ' profile config. Expect "legacy" ' +
      ' or "regional". Got "' + profile[options.sharedConfig] + '".'
    });
    if (resolved) return resolved;
  }
  return resolved;
}

module.exports = resolveRegionalEndpointsFlag;

}).call(this,require('_process'))
},{"./core":324,"_process":412}],324:[function(require,module,exports){
/**
 * The main AWS namespace
 */
var AWS = { util: require('./util') };

/**
 * @api private
 * @!macro [new] nobrowser
 *   @note This feature is not supported in the browser environment of the SDK.
 */
var _hidden = {}; _hidden.toString(); // hack to parse macro

/**
 * @api private
 */
module.exports = AWS;

AWS.util.update(AWS, {

  /**
   * @constant
   */
  VERSION: '2.702.0',

  /**
   * @api private
   */
  Signers: {},

  /**
   * @api private
   */
  Protocol: {
    Json: require('./protocol/json'),
    Query: require('./protocol/query'),
    Rest: require('./protocol/rest'),
    RestJson: require('./protocol/rest_json'),
    RestXml: require('./protocol/rest_xml')
  },

  /**
   * @api private
   */
  XML: {
    Builder: require('./xml/builder'),
    Parser: null // conditionally set based on environment
  },

  /**
   * @api private
   */
  JSON: {
    Builder: require('./json/builder'),
    Parser: require('./json/parser')
  },

  /**
   * @api private
   */
  Model: {
    Api: require('./model/api'),
    Operation: require('./model/operation'),
    Shape: require('./model/shape'),
    Paginator: require('./model/paginator'),
    ResourceWaiter: require('./model/resource_waiter')
  },

  /**
   * @api private
   */
  apiLoader: require('./api_loader'),

  /**
   * @api private
   */
  EndpointCache: require('../vendor/endpoint-cache').EndpointCache
});
require('./sequential_executor');
require('./service');
require('./config');
require('./http');
require('./event_listeners');
require('./request');
require('./response');
require('./resource_waiter');
require('./signers/request_signer');
require('./param_validator');

/**
 * @readonly
 * @return [AWS.SequentialExecutor] a collection of global event listeners that
 *   are attached to every sent request.
 * @see AWS.Request AWS.Request for a list of events to listen for
 * @example Logging the time taken to send a request
 *   AWS.events.on('send', function startSend(resp) {
 *     resp.startTime = new Date().getTime();
 *   }).on('complete', function calculateTime(resp) {
 *     var time = (new Date().getTime() - resp.startTime) / 1000;
 *     console.log('Request took ' + time + ' seconds');
 *   });
 *
 *   new AWS.S3().listBuckets(); // prints 'Request took 0.285 seconds'
 */
AWS.events = new AWS.SequentialExecutor();

//create endpoint cache lazily
AWS.util.memoizedProperty(AWS, 'endpointCache', function() {
  return new AWS.EndpointCache(AWS.config.endpointCacheSize);
}, true);

},{"../vendor/endpoint-cache":430,"./api_loader":313,"./config":322,"./event_listeners":345,"./http":346,"./json/builder":348,"./json/parser":349,"./model/api":350,"./model/operation":352,"./model/paginator":353,"./model/resource_waiter":354,"./model/shape":355,"./param_validator":356,"./protocol/json":359,"./protocol/query":360,"./protocol/rest":361,"./protocol/rest_json":362,"./protocol/rest_xml":363,"./request":369,"./resource_waiter":370,"./response":371,"./sequential_executor":373,"./service":374,"./signers/request_signer":390,"./util":398,"./xml/builder":400}],325:[function(require,module,exports){
var AWS = require('./core');

/**
 * Represents your AWS security credentials, specifically the
 * {accessKeyId}, {secretAccessKey}, and optional {sessionToken}.
 * Creating a `Credentials` object allows you to pass around your
 * security information to configuration and service objects.
 *
 * Note that this class typically does not need to be constructed manually,
 * as the {AWS.Config} and {AWS.Service} classes both accept simple
 * options hashes with the three keys. These structures will be converted
 * into Credentials objects automatically.
 *
 * ## Expiring and Refreshing Credentials
 *
 * Occasionally credentials can expire in the middle of a long-running
 * application. In this case, the SDK will automatically attempt to
 * refresh the credentials from the storage location if the Credentials
 * class implements the {refresh} method.
 *
 * If you are implementing a credential storage location, you
 * will want to create a subclass of the `Credentials` class and
 * override the {refresh} method. This method allows credentials to be
 * retrieved from the backing store, be it a file system, database, or
 * some network storage. The method should reset the credential attributes
 * on the object.
 *
 * @!attribute expired
 *   @return [Boolean] whether the credentials have been expired and
 *     require a refresh. Used in conjunction with {expireTime}.
 * @!attribute expireTime
 *   @return [Date] a time when credentials should be considered expired. Used
 *     in conjunction with {expired}.
 * @!attribute accessKeyId
 *   @return [String] the AWS access key ID
 * @!attribute secretAccessKey
 *   @return [String] the AWS secret access key
 * @!attribute sessionToken
 *   @return [String] an optional AWS session token
 */
AWS.Credentials = AWS.util.inherit({
  /**
   * A credentials object can be created using positional arguments or an options
   * hash.
   *
   * @overload AWS.Credentials(accessKeyId, secretAccessKey, sessionToken=null)
   *   Creates a Credentials object with a given set of credential information
   *   as positional arguments.
   *   @param accessKeyId [String] the AWS access key ID
   *   @param secretAccessKey [String] the AWS secret access key
   *   @param sessionToken [String] the optional AWS session token
   *   @example Create a credentials object with AWS credentials
   *     var creds = new AWS.Credentials('akid', 'secret', 'session');
   * @overload AWS.Credentials(options)
   *   Creates a Credentials object with a given set of credential information
   *   as an options hash.
   *   @option options accessKeyId [String] the AWS access key ID
   *   @option options secretAccessKey [String] the AWS secret access key
   *   @option options sessionToken [String] the optional AWS session token
   *   @example Create a credentials object with AWS credentials
   *     var creds = new AWS.Credentials({
   *       accessKeyId: 'akid', secretAccessKey: 'secret', sessionToken: 'session'
   *     });
   */
  constructor: function Credentials() {
    // hide secretAccessKey from being displayed with util.inspect
    AWS.util.hideProperties(this, ['secretAccessKey']);

    this.expired = false;
    this.expireTime = null;
    this.refreshCallbacks = [];
    if (arguments.length === 1 && typeof arguments[0] === 'object') {
      var creds = arguments[0].credentials || arguments[0];
      this.accessKeyId = creds.accessKeyId;
      this.secretAccessKey = creds.secretAccessKey;
      this.sessionToken = creds.sessionToken;
    } else {
      this.accessKeyId = arguments[0];
      this.secretAccessKey = arguments[1];
      this.sessionToken = arguments[2];
    }
  },

  /**
   * @return [Integer] the number of seconds before {expireTime} during which
   *   the credentials will be considered expired.
   */
  expiryWindow: 15,

  /**
   * @return [Boolean] whether the credentials object should call {refresh}
   * @note Subclasses should override this method to provide custom refresh
   *   logic.
   */
  needsRefresh: function needsRefresh() {
    var currentTime = AWS.util.date.getDate().getTime();
    var adjustedTime = new Date(currentTime + this.expiryWindow * 1000);

    if (this.expireTime && adjustedTime > this.expireTime) {
      return true;
    } else {
      return this.expired || !this.accessKeyId || !this.secretAccessKey;
    }
  },

  /**
   * Gets the existing credentials, refreshing them if they are not yet loaded
   * or have expired. Users should call this method before using {refresh},
   * as this will not attempt to reload credentials when they are already
   * loaded into the object.
   *
   * @callback callback function(err)
   *   When this callback is called with no error, it means either credentials
   *   do not need to be refreshed or refreshed credentials information has
   *   been loaded into the object (as the `accessKeyId`, `secretAccessKey`,
   *   and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   */
  get: function get(callback) {
    var self = this;
    if (this.needsRefresh()) {
      this.refresh(function(err) {
        if (!err) self.expired = false; // reset expired flag
        if (callback) callback(err);
      });
    } else if (callback) {
      callback();
    }
  },

  /**
   * @!method  getPromise()
   *   Returns a 'thenable' promise.
   *   Gets the existing credentials, refreshing them if they are not yet loaded
   *   or have expired. Users should call this method before using {refresh},
   *   as this will not attempt to reload credentials when they are already
   *   loaded into the object.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @callback fulfilledCallback function()
   *     Called if the promise is fulfilled. When this callback is called, it
   *     means either credentials do not need to be refreshed or refreshed
   *     credentials information has been loaded into the object (as the
   *     `accessKeyId`, `secretAccessKey`, and `sessionToken` properties).
   *   @callback rejectedCallback function(err)
   *     Called if the promise is rejected.
   *     @param err [Error] if an error occurred, this value will be filled
   *   @return [Promise] A promise that represents the state of the `get` call.
   *   @example Calling the `getPromise` method.
   *     var promise = credProvider.getPromise();
   *     promise.then(function() { ... }, function(err) { ... });
   */

  /**
   * @!method  refreshPromise()
   *   Returns a 'thenable' promise.
   *   Refreshes the credentials. Users should call {get} before attempting
   *   to forcibly refresh credentials.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @callback fulfilledCallback function()
   *     Called if the promise is fulfilled. When this callback is called, it
   *     means refreshed credentials information has been loaded into the object
   *     (as the `accessKeyId`, `secretAccessKey`, and `sessionToken` properties).
   *   @callback rejectedCallback function(err)
   *     Called if the promise is rejected.
   *     @param err [Error] if an error occurred, this value will be filled
   *   @return [Promise] A promise that represents the state of the `refresh` call.
   *   @example Calling the `refreshPromise` method.
   *     var promise = credProvider.refreshPromise();
   *     promise.then(function() { ... }, function(err) { ... });
   */

  /**
   * Refreshes the credentials. Users should call {get} before attempting
   * to forcibly refresh credentials.
   *
   * @callback callback function(err)
   *   When this callback is called with no error, it means refreshed
   *   credentials information has been loaded into the object (as the
   *   `accessKeyId`, `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @note Subclasses should override this class to reset the
   *   {accessKeyId}, {secretAccessKey} and optional {sessionToken}
   *   on the credentials object and then call the callback with
   *   any error information.
   * @see get
   */
  refresh: function refresh(callback) {
    this.expired = false;
    callback();
  },

  /**
   * @api private
   * @param callback
   */
  coalesceRefresh: function coalesceRefresh(callback, sync) {
    var self = this;
    if (self.refreshCallbacks.push(callback) === 1) {
      self.load(function onLoad(err) {
        AWS.util.arrayEach(self.refreshCallbacks, function(callback) {
          if (sync) {
            callback(err);
          } else {
            // callback could throw, so defer to ensure all callbacks are notified
            AWS.util.defer(function () {
              callback(err);
            });
          }
        });
        self.refreshCallbacks.length = 0;
      });
    }
  },

  /**
   * @api private
   * @param callback
   */
  load: function load(callback) {
    callback();
  }
});

/**
 * @api private
 */
AWS.Credentials.addPromisesToClass = function addPromisesToClass(PromiseDependency) {
  this.prototype.getPromise = AWS.util.promisifyMethod('get', PromiseDependency);
  this.prototype.refreshPromise = AWS.util.promisifyMethod('refresh', PromiseDependency);
};

/**
 * @api private
 */
AWS.Credentials.deletePromisesFromClass = function deletePromisesFromClass() {
  delete this.prototype.getPromise;
  delete this.prototype.refreshPromise;
};

AWS.util.addPromises(AWS.Credentials);

},{"./core":324}],326:[function(require,module,exports){
var AWS = require('../core');
var STS = require('../../clients/sts');

/**
 * Represents temporary credentials retrieved from {AWS.STS}. Without any
 * extra parameters, credentials will be fetched from the
 * {AWS.STS.getSessionToken} operation. If an IAM role is provided, the
 * {AWS.STS.assumeRole} operation will be used to fetch credentials for the
 * role instead.
 *
 * AWS.ChainableTemporaryCredentials differs from AWS.TemporaryCredentials in
 * the way masterCredentials and refreshes are handled.
 * AWS.ChainableTemporaryCredentials refreshes expired credentials using the
 * masterCredentials passed by the user to support chaining of STS credentials.
 * However, AWS.TemporaryCredentials recursively collapses the masterCredentials
 * during instantiation, precluding the ability to refresh credentials which
 * require intermediate, temporary credentials.
 *
 * For example, if the application should use RoleA, which must be assumed from
 * RoleB, and the environment provides credentials which can assume RoleB, then
 * AWS.ChainableTemporaryCredentials must be used to support refreshing the
 * temporary credentials for RoleA:
 *
 * ```javascript
 * var roleACreds = new AWS.ChainableTemporaryCredentials({
 *   params: {RoleArn: 'RoleA'},
 *   masterCredentials: new AWS.ChainableTemporaryCredentials({
 *     params: {RoleArn: 'RoleB'},
 *     masterCredentials: new AWS.EnvironmentCredentials('AWS')
 *   })
 * });
 * ```
 *
 * If AWS.TemporaryCredentials had been used in the previous example,
 * `roleACreds` would fail to refresh because `roleACreds` would
 * use the environment credentials for the AssumeRole request.
 *
 * Another difference is that AWS.ChainableTemporaryCredentials creates the STS
 * service instance during instantiation while AWS.TemporaryCredentials creates
 * the STS service instance during the first refresh. Creating the service
 * instance during instantiation effectively captures the master credentials
 * from the global config, so that subsequent changes to the global config do
 * not affect the master credentials used to refresh the temporary credentials.
 *
 * This allows an instance of AWS.ChainableTemporaryCredentials to be assigned
 * to AWS.config.credentials:
 *
 * ```javascript
 * var envCreds = new AWS.EnvironmentCredentials('AWS');
 * AWS.config.credentials = envCreds;
 * // masterCredentials will be envCreds
 * AWS.config.credentials = new AWS.ChainableTemporaryCredentials({
 *   params: {RoleArn: '...'}
 * });
 * ```
 *
 * Similarly, to use the CredentialProviderChain's default providers as the
 * master credentials, simply create a new instance of
 * AWS.ChainableTemporaryCredentials:
 *
 * ```javascript
 * AWS.config.credentials = new ChainableTemporaryCredentials({
 *   params: {RoleArn: '...'}
 * });
 * ```
 *
 * @!attribute service
 *   @return [AWS.STS] the STS service instance used to
 *     get and refresh temporary credentials from AWS STS.
 * @note (see constructor)
 */
AWS.ChainableTemporaryCredentials = AWS.util.inherit(AWS.Credentials, {
  /**
   * Creates a new temporary credentials object.
   *
   * @param options [map] a set of options
   * @option options params [map] ({}) a map of options that are passed to the
   *   {AWS.STS.assumeRole} or {AWS.STS.getSessionToken} operations.
   *   If a `RoleArn` parameter is passed in, credentials will be based on the
   *   IAM role. If a `SerialNumber` parameter is passed in, {tokenCodeFn} must
   *   also be passed in or an error will be thrown.
   * @option options masterCredentials [AWS.Credentials] the master credentials
   *   used to get and refresh temporary credentials from AWS STS. By default,
   *   AWS.config.credentials or AWS.config.credentialProvider will be used.
   * @option options tokenCodeFn [Function] (null) Function to provide
   *   `TokenCode`, if `SerialNumber` is provided for profile in {params}. Function
   *   is called with value of `SerialNumber` and `callback`, and should provide
   *   the `TokenCode` or an error to the callback in the format
   *   `callback(err, token)`.
   * @example Creating a new credentials object for generic temporary credentials
   *   AWS.config.credentials = new AWS.ChainableTemporaryCredentials();
   * @example Creating a new credentials object for an IAM role
   *   AWS.config.credentials = new AWS.ChainableTemporaryCredentials({
   *     params: {
   *       RoleArn: 'arn:aws:iam::1234567890:role/TemporaryCredentials'
   *     }
   *   });
   * @see AWS.STS.assumeRole
   * @see AWS.STS.getSessionToken
   */
  constructor: function ChainableTemporaryCredentials(options) {
    AWS.Credentials.call(this);
    options = options || {};
    this.errorCode = 'ChainableTemporaryCredentialsProviderFailure';
    this.expired = true;
    this.tokenCodeFn = null;

    var params = AWS.util.copy(options.params) || {};
    if (params.RoleArn) {
      params.RoleSessionName = params.RoleSessionName || 'temporary-credentials';
    }
    if (params.SerialNumber) {
      if (!options.tokenCodeFn || (typeof options.tokenCodeFn !== 'function')) {
        throw new AWS.util.error(
          new Error('tokenCodeFn must be a function when params.SerialNumber is given'),
          {code: this.errorCode}
        );
      } else {
        this.tokenCodeFn = options.tokenCodeFn;
      }
    }
    var config = AWS.util.merge(
      {
        params: params,
        credentials: options.masterCredentials || AWS.config.credentials
      },
      options.stsConfig || {}
    );
    this.service = new STS(config);
  },

  /**
   * Refreshes credentials using {AWS.STS.assumeRole} or
   * {AWS.STS.getSessionToken}, depending on whether an IAM role ARN was passed
   * to the credentials {constructor}.
   *
   * @callback callback function(err)
   *   Called when the STS service responds (or fails). When
   *   this callback is called with no error, it means that the credentials
   *   information has been loaded into the object (as the `accessKeyId`,
   *   `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @see AWS.Credentials.get
   */
  refresh: function refresh(callback) {
    this.coalesceRefresh(callback || AWS.util.fn.callback);
  },

  /**
   * @api private
   * @param callback
   */
  load: function load(callback) {
    var self = this;
    var operation = self.service.config.params.RoleArn ? 'assumeRole' : 'getSessionToken';
    this.getTokenCode(function (err, tokenCode) {
      var params = {};
      if (err) {
        callback(err);
        return;
      }
      if (tokenCode) {
        params.TokenCode = tokenCode;
      }
      self.service[operation](params, function (err, data) {
        if (!err) {
          self.service.credentialsFrom(data, self);
        }
        callback(err);
      });
    });
  },

  /**
   * @api private
   */
  getTokenCode: function getTokenCode(callback) {
    var self = this;
    if (this.tokenCodeFn) {
      this.tokenCodeFn(this.service.config.params.SerialNumber, function (err, token) {
        if (err) {
          var message = err;
          if (err instanceof Error) {
            message = err.message;
          }
          callback(
            AWS.util.error(
              new Error('Error fetching MFA token: ' + message),
              { code: self.errorCode}
            )
          );
          return;
        }
        callback(null, token);
      });
    } else {
      callback(null);
    }
  }
});

},{"../../clients/sts":308,"../core":324}],327:[function(require,module,exports){
var AWS = require('../core');
var CognitoIdentity = require('../../clients/cognitoidentity');
var STS = require('../../clients/sts');

/**
 * Represents credentials retrieved from STS Web Identity Federation using
 * the Amazon Cognito Identity service.
 *
 * By default this provider gets credentials using the
 * {AWS.CognitoIdentity.getCredentialsForIdentity} service operation, which
 * requires either an `IdentityId` or an `IdentityPoolId` (Amazon Cognito
 * Identity Pool ID), which is used to call {AWS.CognitoIdentity.getId} to
 * obtain an `IdentityId`. If the identity or identity pool is not configured in
 * the Amazon Cognito Console to use IAM roles with the appropriate permissions,
 * then additionally a `RoleArn` is required containing the ARN of the IAM trust
 * policy for the Amazon Cognito role that the user will log into. If a `RoleArn`
 * is provided, then this provider gets credentials using the
 * {AWS.STS.assumeRoleWithWebIdentity} service operation, after first getting an
 * Open ID token from {AWS.CognitoIdentity.getOpenIdToken}.
 *
 * In addition, if this credential provider is used to provide authenticated
 * login, the `Logins` map may be set to the tokens provided by the respective
 * identity providers. See {constructor} for an example on creating a credentials
 * object with proper property values.
 *
 * ## Refreshing Credentials from Identity Service
 *
 * In addition to AWS credentials expiring after a given amount of time, the
 * login token from the identity provider will also expire. Once this token
 * expires, it will not be usable to refresh AWS credentials, and another
 * token will be needed. The SDK does not manage refreshing of the token value,
 * but this can be done through a "refresh token" supported by most identity
 * providers. Consult the documentation for the identity provider for refreshing
 * tokens. Once the refreshed token is acquired, you should make sure to update
 * this new token in the credentials object's {params} property. The following
 * code will update the WebIdentityToken, assuming you have retrieved an updated
 * token from the identity provider:
 *
 * ```javascript
 * AWS.config.credentials.params.Logins['graph.facebook.com'] = updatedToken;
 * ```
 *
 * Future calls to `credentials.refresh()` will now use the new token.
 *
 * @!attribute params
 *   @return [map] the map of params passed to
 *     {AWS.CognitoIdentity.getId},
 *     {AWS.CognitoIdentity.getOpenIdToken}, and
 *     {AWS.STS.assumeRoleWithWebIdentity}. To update the token, set the
 *     `params.WebIdentityToken` property.
 * @!attribute data
 *   @return [map] the raw data response from the call to
 *     {AWS.CognitoIdentity.getCredentialsForIdentity}, or
 *     {AWS.STS.assumeRoleWithWebIdentity}. Use this if you want to get
 *     access to other properties from the response.
 * @!attribute identityId
 *   @return [String] the Cognito ID returned by the last call to
 *     {AWS.CognitoIdentity.getOpenIdToken}. This ID represents the actual
 *     final resolved identity ID from Amazon Cognito.
 */
AWS.CognitoIdentityCredentials = AWS.util.inherit(AWS.Credentials, {
  /**
   * @api private
   */
  localStorageKey: {
    id: 'aws.cognito.identity-id.',
    providers: 'aws.cognito.identity-providers.'
  },

  /**
   * Creates a new credentials object.
   * @example Creating a new credentials object
   *   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
   *
   *     // either IdentityPoolId or IdentityId is required
   *     // See the IdentityPoolId param for AWS.CognitoIdentity.getID (linked below)
   *     // See the IdentityId param for AWS.CognitoIdentity.getCredentialsForIdentity
   *     // or AWS.CognitoIdentity.getOpenIdToken (linked below)
   *     IdentityPoolId: 'us-east-1:1699ebc0-7900-4099-b910-2df94f52a030',
   *     IdentityId: 'us-east-1:128d0a74-c82f-4553-916d-90053e4a8b0f'
   *
   *     // optional, only necessary when the identity pool is not configured
   *     // to use IAM roles in the Amazon Cognito Console
   *     // See the RoleArn param for AWS.STS.assumeRoleWithWebIdentity (linked below)
   *     RoleArn: 'arn:aws:iam::1234567890:role/MYAPP-CognitoIdentity',
   *
   *     // optional tokens, used for authenticated login
   *     // See the Logins param for AWS.CognitoIdentity.getID (linked below)
   *     Logins: {
   *       'graph.facebook.com': 'FBTOKEN',
   *       'www.amazon.com': 'AMAZONTOKEN',
   *       'accounts.google.com': 'GOOGLETOKEN',
   *       'api.twitter.com': 'TWITTERTOKEN',
   *       'www.digits.com': 'DIGITSTOKEN'
   *     },
   *
   *     // optional name, defaults to web-identity
   *     // See the RoleSessionName param for AWS.STS.assumeRoleWithWebIdentity (linked below)
   *     RoleSessionName: 'web',
   *
   *     // optional, only necessary when application runs in a browser
   *     // and multiple users are signed in at once, used for caching
   *     LoginId: 'example@gmail.com'
   *
   *   }, {
   *      // optionally provide configuration to apply to the underlying service clients
   *      // if configuration is not provided, then configuration will be pulled from AWS.config
   *
   *      // region should match the region your identity pool is located in
   *      region: 'us-east-1',
   *
   *      // specify timeout options
   *      httpOptions: {
   *        timeout: 100
   *      }
   *   });
   * @see AWS.CognitoIdentity.getId
   * @see AWS.CognitoIdentity.getCredentialsForIdentity
   * @see AWS.STS.assumeRoleWithWebIdentity
   * @see AWS.CognitoIdentity.getOpenIdToken
   * @see AWS.Config
   * @note If a region is not provided in the global AWS.config, or
   *   specified in the `clientConfig` to the CognitoIdentityCredentials
   *   constructor, you may encounter a 'Missing credentials in config' error
   *   when calling making a service call.
   */
  constructor: function CognitoIdentityCredentials(params, clientConfig) {
    AWS.Credentials.call(this);
    this.expired = true;
    this.params = params;
    this.data = null;
    this._identityId = null;
    this._clientConfig = AWS.util.copy(clientConfig || {});
    this.loadCachedId();
    var self = this;
    Object.defineProperty(this, 'identityId', {
      get: function() {
        self.loadCachedId();
        return self._identityId || self.params.IdentityId;
      },
      set: function(identityId) {
        self._identityId = identityId;
      }
    });
  },

  /**
   * Refreshes credentials using {AWS.CognitoIdentity.getCredentialsForIdentity},
   * or {AWS.STS.assumeRoleWithWebIdentity}.
   *
   * @callback callback function(err)
   *   Called when the STS service responds (or fails). When
   *   this callback is called with no error, it means that the credentials
   *   information has been loaded into the object (as the `accessKeyId`,
   *   `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @see AWS.Credentials.get
   */
  refresh: function refresh(callback) {
    this.coalesceRefresh(callback || AWS.util.fn.callback);
  },

  /**
   * @api private
   * @param callback
   */
  load: function load(callback) {
    var self = this;
    self.createClients();
    self.data = null;
    self._identityId = null;
    self.getId(function(err) {
      if (!err) {
        if (!self.params.RoleArn) {
          self.getCredentialsForIdentity(callback);
        } else {
          self.getCredentialsFromSTS(callback);
        }
      } else {
        self.clearIdOnNotAuthorized(err);
        callback(err);
      }
    });
  },

  /**
   * Clears the cached Cognito ID associated with the currently configured
   * identity pool ID. Use this to manually invalidate your cache if
   * the identity pool ID was deleted.
   */
  clearCachedId: function clearCache() {
    this._identityId = null;
    delete this.params.IdentityId;

    var poolId = this.params.IdentityPoolId;
    var loginId = this.params.LoginId || '';
    delete this.storage[this.localStorageKey.id + poolId + loginId];
    delete this.storage[this.localStorageKey.providers + poolId + loginId];
  },

  /**
   * @api private
   */
  clearIdOnNotAuthorized: function clearIdOnNotAuthorized(err) {
    var self = this;
    if (err.code == 'NotAuthorizedException') {
      self.clearCachedId();
    }
  },

  /**
   * Retrieves a Cognito ID, loading from cache if it was already retrieved
   * on this device.
   *
   * @callback callback function(err, identityId)
   *   @param err [Error, null] an error object if the call failed or null if
   *     it succeeded.
   *   @param identityId [String, null] if successful, the callback will return
   *     the Cognito ID.
   * @note If not loaded explicitly, the Cognito ID is loaded and stored in
   *   localStorage in the browser environment of a device.
   * @api private
   */
  getId: function getId(callback) {
    var self = this;
    if (typeof self.params.IdentityId === 'string') {
      return callback(null, self.params.IdentityId);
    }

    self.cognito.getId(function(err, data) {
      if (!err && data.IdentityId) {
        self.params.IdentityId = data.IdentityId;
        callback(null, data.IdentityId);
      } else {
        callback(err);
      }
    });
  },


  /**
   * @api private
   */
  loadCredentials: function loadCredentials(data, credentials) {
    if (!data || !credentials) return;
    credentials.expired = false;
    credentials.accessKeyId = data.Credentials.AccessKeyId;
    credentials.secretAccessKey = data.Credentials.SecretKey;
    credentials.sessionToken = data.Credentials.SessionToken;
    credentials.expireTime = data.Credentials.Expiration;
  },

  /**
   * @api private
   */
  getCredentialsForIdentity: function getCredentialsForIdentity(callback) {
    var self = this;
    self.cognito.getCredentialsForIdentity(function(err, data) {
      if (!err) {
        self.cacheId(data);
        self.data = data;
        self.loadCredentials(self.data, self);
      } else {
        self.clearIdOnNotAuthorized(err);
      }
      callback(err);
    });
  },

  /**
   * @api private
   */
  getCredentialsFromSTS: function getCredentialsFromSTS(callback) {
    var self = this;
    self.cognito.getOpenIdToken(function(err, data) {
      if (!err) {
        self.cacheId(data);
        self.params.WebIdentityToken = data.Token;
        self.webIdentityCredentials.refresh(function(webErr) {
          if (!webErr) {
            self.data = self.webIdentityCredentials.data;
            self.sts.credentialsFrom(self.data, self);
          }
          callback(webErr);
        });
      } else {
        self.clearIdOnNotAuthorized(err);
        callback(err);
      }
    });
  },

  /**
   * @api private
   */
  loadCachedId: function loadCachedId() {
    var self = this;

    // in the browser we source default IdentityId from localStorage
    if (AWS.util.isBrowser() && !self.params.IdentityId) {
      var id = self.getStorage('id');
      if (id && self.params.Logins) {
        var actualProviders = Object.keys(self.params.Logins);
        var cachedProviders =
          (self.getStorage('providers') || '').split(',');

        // only load ID if at least one provider used this ID before
        var intersect = cachedProviders.filter(function(n) {
          return actualProviders.indexOf(n) !== -1;
        });
        if (intersect.length !== 0) {
          self.params.IdentityId = id;
        }
      } else if (id) {
        self.params.IdentityId = id;
      }
    }
  },

  /**
   * @api private
   */
  createClients: function() {
    var clientConfig = this._clientConfig;
    this.webIdentityCredentials = this.webIdentityCredentials ||
      new AWS.WebIdentityCredentials(this.params, clientConfig);
    if (!this.cognito) {
      var cognitoConfig = AWS.util.merge({}, clientConfig);
      cognitoConfig.params = this.params;
      this.cognito = new CognitoIdentity(cognitoConfig);
    }
    this.sts = this.sts || new STS(clientConfig);
  },

  /**
   * @api private
   */
  cacheId: function cacheId(data) {
    this._identityId = data.IdentityId;
    this.params.IdentityId = this._identityId;

    // cache this IdentityId in browser localStorage if possible
    if (AWS.util.isBrowser()) {
      this.setStorage('id', data.IdentityId);

      if (this.params.Logins) {
        this.setStorage('providers', Object.keys(this.params.Logins).join(','));
      }
    }
  },

  /**
   * @api private
   */
  getStorage: function getStorage(key) {
    return this.storage[this.localStorageKey[key] + this.params.IdentityPoolId + (this.params.LoginId || '')];
  },

  /**
   * @api private
   */
  setStorage: function setStorage(key, val) {
    try {
      this.storage[this.localStorageKey[key] + this.params.IdentityPoolId + (this.params.LoginId || '')] = val;
    } catch (_) {}
  },

  /**
   * @api private
   */
  storage: (function() {
    try {
      var storage = AWS.util.isBrowser() && window.localStorage !== null && typeof window.localStorage === 'object' ?
          window.localStorage : {};

      // Test set/remove which would throw an error in Safari's private browsing
      storage['aws.test-storage'] = 'foobar';
      delete storage['aws.test-storage'];

      return storage;
    } catch (_) {
      return {};
    }
  })()
});

},{"../../clients/cognitoidentity":242,"../../clients/sts":308,"../core":324}],328:[function(require,module,exports){
var AWS = require('../core');

/**
 * Creates a credential provider chain that searches for AWS credentials
 * in a list of credential providers specified by the {providers} property.
 *
 * By default, the chain will use the {defaultProviders} to resolve credentials.
 * These providers will look in the environment using the
 * {AWS.EnvironmentCredentials} class with the 'AWS' and 'AMAZON' prefixes.
 *
 * ## Setting Providers
 *
 * Each provider in the {providers} list should be a function that returns
 * a {AWS.Credentials} object, or a hardcoded credentials object. The function
 * form allows for delayed execution of the credential construction.
 *
 * ## Resolving Credentials from a Chain
 *
 * Call {resolve} to return the first valid credential object that can be
 * loaded by the provider chain.
 *
 * For example, to resolve a chain with a custom provider that checks a file
 * on disk after the set of {defaultProviders}:
 *
 * ```javascript
 * var diskProvider = new AWS.FileSystemCredentials('./creds.json');
 * var chain = new AWS.CredentialProviderChain();
 * chain.providers.push(diskProvider);
 * chain.resolve();
 * ```
 *
 * The above code will return the `diskProvider` object if the
 * file contains credentials and the `defaultProviders` do not contain
 * any credential settings.
 *
 * @!attribute providers
 *   @return [Array<AWS.Credentials, Function>]
 *     a list of credentials objects or functions that return credentials
 *     objects. If the provider is a function, the function will be
 *     executed lazily when the provider needs to be checked for valid
 *     credentials. By default, this object will be set to the
 *     {defaultProviders}.
 *   @see defaultProviders
 */
AWS.CredentialProviderChain = AWS.util.inherit(AWS.Credentials, {

  /**
   * Creates a new CredentialProviderChain with a default set of providers
   * specified by {defaultProviders}.
   */
  constructor: function CredentialProviderChain(providers) {
    if (providers) {
      this.providers = providers;
    } else {
      this.providers = AWS.CredentialProviderChain.defaultProviders.slice(0);
    }
    this.resolveCallbacks = [];
  },

  /**
   * @!method  resolvePromise()
   *   Returns a 'thenable' promise.
   *   Resolves the provider chain by searching for the first set of
   *   credentials in {providers}.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @callback fulfilledCallback function(credentials)
   *     Called if the promise is fulfilled and the provider resolves the chain
   *     to a credentials object
   *     @param credentials [AWS.Credentials] the credentials object resolved
   *       by the provider chain.
   *   @callback rejectedCallback function(error)
   *     Called if the promise is rejected.
   *     @param err [Error] the error object returned if no credentials are found.
   *   @return [Promise] A promise that represents the state of the `resolve` method call.
   *   @example Calling the `resolvePromise` method.
   *     var promise = chain.resolvePromise();
   *     promise.then(function(credentials) { ... }, function(err) { ... });
   */

  /**
   * Resolves the provider chain by searching for the first set of
   * credentials in {providers}.
   *
   * @callback callback function(err, credentials)
   *   Called when the provider resolves the chain to a credentials object
   *   or null if no credentials can be found.
   *
   *   @param err [Error] the error object returned if no credentials are
   *     found.
   *   @param credentials [AWS.Credentials] the credentials object resolved
   *     by the provider chain.
   * @return [AWS.CredentialProviderChain] the provider, for chaining.
   */
  resolve: function resolve(callback) {
    var self = this;
    if (self.providers.length === 0) {
      callback(new Error('No providers'));
      return self;
    }

    if (self.resolveCallbacks.push(callback) === 1) {
      var index = 0;
      var providers = self.providers.slice(0);

      function resolveNext(err, creds) {
        if ((!err && creds) || index === providers.length) {
          AWS.util.arrayEach(self.resolveCallbacks, function (callback) {
            callback(err, creds);
          });
          self.resolveCallbacks.length = 0;
          return;
        }

        var provider = providers[index++];
        if (typeof provider === 'function') {
          creds = provider.call();
        } else {
          creds = provider;
        }

        if (creds.get) {
          creds.get(function (getErr) {
            resolveNext(getErr, getErr ? null : creds);
          });
        } else {
          resolveNext(null, creds);
        }
      }

      resolveNext();
    }

    return self;
  }
});

/**
 * The default set of providers used by a vanilla CredentialProviderChain.
 *
 * In the browser:
 *
 * ```javascript
 * AWS.CredentialProviderChain.defaultProviders = []
 * ```
 *
 * In Node.js:
 *
 * ```javascript
 * AWS.CredentialProviderChain.defaultProviders = [
 *   function () { return new AWS.EnvironmentCredentials('AWS'); },
 *   function () { return new AWS.EnvironmentCredentials('AMAZON'); },
 *   function () { return new AWS.SharedIniFileCredentials(); },
 *   function () { return new AWS.ECSCredentials(); },
 *   function () { return new AWS.ProcessCredentials(); },
 *   function () { return new AWS.TokenFileWebIdentityCredentials(); },
 *   function () { return new AWS.EC2MetadataCredentials() }
 * ]
 * ```
 */
AWS.CredentialProviderChain.defaultProviders = [];

/**
 * @api private
 */
AWS.CredentialProviderChain.addPromisesToClass = function addPromisesToClass(PromiseDependency) {
  this.prototype.resolvePromise = AWS.util.promisifyMethod('resolve', PromiseDependency);
};

/**
 * @api private
 */
AWS.CredentialProviderChain.deletePromisesFromClass = function deletePromisesFromClass() {
  delete this.prototype.resolvePromise;
};

AWS.util.addPromises(AWS.CredentialProviderChain);

},{"../core":324}],329:[function(require,module,exports){
var AWS = require('../core');
var STS = require('../../clients/sts');

/**
 * Represents credentials retrieved from STS SAML support.
 *
 * By default this provider gets credentials using the
 * {AWS.STS.assumeRoleWithSAML} service operation. This operation
 * requires a `RoleArn` containing the ARN of the IAM trust policy for the
 * application for which credentials will be given, as well as a `PrincipalArn`
 * representing the ARN for the SAML identity provider. In addition, the
 * `SAMLAssertion` must be set to the token provided by the identity
 * provider. See {constructor} for an example on creating a credentials
 * object with proper `RoleArn`, `PrincipalArn`, and `SAMLAssertion` values.
 *
 * ## Refreshing Credentials from Identity Service
 *
 * In addition to AWS credentials expiring after a given amount of time, the
 * login token from the identity provider will also expire. Once this token
 * expires, it will not be usable to refresh AWS credentials, and another
 * token will be needed. The SDK does not manage refreshing of the token value,
 * but this can be done through a "refresh token" supported by most identity
 * providers. Consult the documentation for the identity provider for refreshing
 * tokens. Once the refreshed token is acquired, you should make sure to update
 * this new token in the credentials object's {params} property. The following
 * code will update the SAMLAssertion, assuming you have retrieved an updated
 * token from the identity provider:
 *
 * ```javascript
 * AWS.config.credentials.params.SAMLAssertion = updatedToken;
 * ```
 *
 * Future calls to `credentials.refresh()` will now use the new token.
 *
 * @!attribute params
 *   @return [map] the map of params passed to
 *     {AWS.STS.assumeRoleWithSAML}. To update the token, set the
 *     `params.SAMLAssertion` property.
 */
AWS.SAMLCredentials = AWS.util.inherit(AWS.Credentials, {
  /**
   * Creates a new credentials object.
   * @param (see AWS.STS.assumeRoleWithSAML)
   * @example Creating a new credentials object
   *   AWS.config.credentials = new AWS.SAMLCredentials({
   *     RoleArn: 'arn:aws:iam::1234567890:role/SAMLRole',
   *     PrincipalArn: 'arn:aws:iam::1234567890:role/SAMLPrincipal',
   *     SAMLAssertion: 'base64-token', // base64-encoded token from IdP
   *   });
   * @see AWS.STS.assumeRoleWithSAML
   */
  constructor: function SAMLCredentials(params) {
    AWS.Credentials.call(this);
    this.expired = true;
    this.params = params;
  },

  /**
   * Refreshes credentials using {AWS.STS.assumeRoleWithSAML}
   *
   * @callback callback function(err)
   *   Called when the STS service responds (or fails). When
   *   this callback is called with no error, it means that the credentials
   *   information has been loaded into the object (as the `accessKeyId`,
   *   `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @see get
   */
  refresh: function refresh(callback) {
    this.coalesceRefresh(callback || AWS.util.fn.callback);
  },

  /**
   * @api private
   */
  load: function load(callback) {
    var self = this;
    self.createClients();
    self.service.assumeRoleWithSAML(function (err, data) {
      if (!err) {
        self.service.credentialsFrom(data, self);
      }
      callback(err);
    });
  },

  /**
   * @api private
   */
  createClients: function() {
    this.service = this.service || new STS({params: this.params});
  }

});

},{"../../clients/sts":308,"../core":324}],330:[function(require,module,exports){
var AWS = require('../core');
var STS = require('../../clients/sts');

/**
 * Represents temporary credentials retrieved from {AWS.STS}. Without any
 * extra parameters, credentials will be fetched from the
 * {AWS.STS.getSessionToken} operation. If an IAM role is provided, the
 * {AWS.STS.assumeRole} operation will be used to fetch credentials for the
 * role instead.
 *
 * @note AWS.TemporaryCredentials is deprecated, but remains available for
 *   backwards compatibility. {AWS.ChainableTemporaryCredentials} is the
 *   preferred class for temporary credentials.
 *
 * To setup temporary credentials, configure a set of master credentials
 * using the standard credentials providers (environment, EC2 instance metadata,
 * or from the filesystem), then set the global credentials to a new
 * temporary credentials object:
 *
 * ```javascript
 * // Note that environment credentials are loaded by default,
 * // the following line is shown for clarity:
 * AWS.config.credentials = new AWS.EnvironmentCredentials('AWS');
 *
 * // Now set temporary credentials seeded from the master credentials
 * AWS.config.credentials = new AWS.TemporaryCredentials();
 *
 * // subsequent requests will now use temporary credentials from AWS STS.
 * new AWS.S3().listBucket(function(err, data) { ... });
 * ```
 *
 * @!attribute masterCredentials
 *   @return [AWS.Credentials] the master (non-temporary) credentials used to
 *     get and refresh temporary credentials from AWS STS.
 * @note (see constructor)
 */
AWS.TemporaryCredentials = AWS.util.inherit(AWS.Credentials, {
  /**
   * Creates a new temporary credentials object.
   *
   * @note In order to create temporary credentials, you first need to have
   *   "master" credentials configured in {AWS.Config.credentials}. These
   *   master credentials are necessary to retrieve the temporary credentials,
   *   as well as refresh the credentials when they expire.
   * @param params [map] a map of options that are passed to the
   *   {AWS.STS.assumeRole} or {AWS.STS.getSessionToken} operations.
   *   If a `RoleArn` parameter is passed in, credentials will be based on the
   *   IAM role.
   * @param masterCredentials [AWS.Credentials] the master (non-temporary) credentials
   *  used to get and refresh temporary credentials from AWS STS.
   * @example Creating a new credentials object for generic temporary credentials
   *   AWS.config.credentials = new AWS.TemporaryCredentials();
   * @example Creating a new credentials object for an IAM role
   *   AWS.config.credentials = new AWS.TemporaryCredentials({
   *     RoleArn: 'arn:aws:iam::1234567890:role/TemporaryCredentials',
   *   });
   * @see AWS.STS.assumeRole
   * @see AWS.STS.getSessionToken
   */
  constructor: function TemporaryCredentials(params, masterCredentials) {
    AWS.Credentials.call(this);
    this.loadMasterCredentials(masterCredentials);
    this.expired = true;

    this.params = params || {};
    if (this.params.RoleArn) {
      this.params.RoleSessionName =
        this.params.RoleSessionName || 'temporary-credentials';
    }
  },

  /**
   * Refreshes credentials using {AWS.STS.assumeRole} or
   * {AWS.STS.getSessionToken}, depending on whether an IAM role ARN was passed
   * to the credentials {constructor}.
   *
   * @callback callback function(err)
   *   Called when the STS service responds (or fails). When
   *   this callback is called with no error, it means that the credentials
   *   information has been loaded into the object (as the `accessKeyId`,
   *   `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @see get
   */
  refresh: function refresh (callback) {
    this.coalesceRefresh(callback || AWS.util.fn.callback);
  },

  /**
   * @api private
   */
  load: function load (callback) {
    var self = this;
    self.createClients();
    self.masterCredentials.get(function () {
      self.service.config.credentials = self.masterCredentials;
      var operation = self.params.RoleArn ?
        self.service.assumeRole : self.service.getSessionToken;
      operation.call(self.service, function (err, data) {
        if (!err) {
          self.service.credentialsFrom(data, self);
        }
        callback(err);
      });
    });
  },

  /**
   * @api private
   */
  loadMasterCredentials: function loadMasterCredentials (masterCredentials) {
    this.masterCredentials = masterCredentials || AWS.config.credentials;
    while (this.masterCredentials.masterCredentials) {
      this.masterCredentials = this.masterCredentials.masterCredentials;
    }

    if (typeof this.masterCredentials.get !== 'function') {
      this.masterCredentials = new AWS.Credentials(this.masterCredentials);
    }
  },

  /**
   * @api private
   */
  createClients: function () {
    this.service = this.service || new STS({params: this.params});
  }

});

},{"../../clients/sts":308,"../core":324}],331:[function(require,module,exports){
var AWS = require('../core');
var STS = require('../../clients/sts');

/**
 * Represents credentials retrieved from STS Web Identity Federation support.
 *
 * By default this provider gets credentials using the
 * {AWS.STS.assumeRoleWithWebIdentity} service operation. This operation
 * requires a `RoleArn` containing the ARN of the IAM trust policy for the
 * application for which credentials will be given. In addition, the
 * `WebIdentityToken` must be set to the token provided by the identity
 * provider. See {constructor} for an example on creating a credentials
 * object with proper `RoleArn` and `WebIdentityToken` values.
 *
 * ## Refreshing Credentials from Identity Service
 *
 * In addition to AWS credentials expiring after a given amount of time, the
 * login token from the identity provider will also expire. Once this token
 * expires, it will not be usable to refresh AWS credentials, and another
 * token will be needed. The SDK does not manage refreshing of the token value,
 * but this can be done through a "refresh token" supported by most identity
 * providers. Consult the documentation for the identity provider for refreshing
 * tokens. Once the refreshed token is acquired, you should make sure to update
 * this new token in the credentials object's {params} property. The following
 * code will update the WebIdentityToken, assuming you have retrieved an updated
 * token from the identity provider:
 *
 * ```javascript
 * AWS.config.credentials.params.WebIdentityToken = updatedToken;
 * ```
 *
 * Future calls to `credentials.refresh()` will now use the new token.
 *
 * @!attribute params
 *   @return [map] the map of params passed to
 *     {AWS.STS.assumeRoleWithWebIdentity}. To update the token, set the
 *     `params.WebIdentityToken` property.
 * @!attribute data
 *   @return [map] the raw data response from the call to
 *     {AWS.STS.assumeRoleWithWebIdentity}. Use this if you want to get
 *     access to other properties from the response.
 */
AWS.WebIdentityCredentials = AWS.util.inherit(AWS.Credentials, {
  /**
   * Creates a new credentials object.
   * @param (see AWS.STS.assumeRoleWithWebIdentity)
   * @example Creating a new credentials object
   *   AWS.config.credentials = new AWS.WebIdentityCredentials({
   *     RoleArn: 'arn:aws:iam::1234567890:role/WebIdentity',
   *     WebIdentityToken: 'ABCDEFGHIJKLMNOP', // token from identity service
   *     RoleSessionName: 'web' // optional name, defaults to web-identity
   *   }, {
   *     // optionally provide configuration to apply to the underlying AWS.STS service client
   *     // if configuration is not provided, then configuration will be pulled from AWS.config
   *
   *     // specify timeout options
   *     httpOptions: {
   *       timeout: 100
   *     }
   *   });
   * @see AWS.STS.assumeRoleWithWebIdentity
   * @see AWS.Config
   */
  constructor: function WebIdentityCredentials(params, clientConfig) {
    AWS.Credentials.call(this);
    this.expired = true;
    this.params = params;
    this.params.RoleSessionName = this.params.RoleSessionName || 'web-identity';
    this.data = null;
    this._clientConfig = AWS.util.copy(clientConfig || {});
  },

  /**
   * Refreshes credentials using {AWS.STS.assumeRoleWithWebIdentity}
   *
   * @callback callback function(err)
   *   Called when the STS service responds (or fails). When
   *   this callback is called with no error, it means that the credentials
   *   information has been loaded into the object (as the `accessKeyId`,
   *   `secretAccessKey`, and `sessionToken` properties).
   *   @param err [Error] if an error occurred, this value will be filled
   * @see get
   */
  refresh: function refresh(callback) {
    this.coalesceRefresh(callback || AWS.util.fn.callback);
  },

  /**
   * @api private
   */
  load: function load(callback) {
    var self = this;
    self.createClients();
    self.service.assumeRoleWithWebIdentity(function (err, data) {
      self.data = null;
      if (!err) {
        self.data = data;
        self.service.credentialsFrom(data, self);
      }
      callback(err);
    });
  },

  /**
   * @api private
   */
  createClients: function() {
    if (!this.service) {
      var stsConfig = AWS.util.merge({}, this._clientConfig);
      stsConfig.params = this.params;
      this.service = new STS(stsConfig);
    }
  }

});

},{"../../clients/sts":308,"../core":324}],332:[function(require,module,exports){
(function (process){
var AWS = require('./core');
var util = require('./util');
var endpointDiscoveryEnabledEnvs = ['AWS_ENABLE_ENDPOINT_DISCOVERY', 'AWS_ENDPOINT_DISCOVERY_ENABLED'];

/**
 * Generate key (except resources and operation part) to index the endpoints in the cache
 * If input shape has endpointdiscoveryid trait then use
 *   accessKey + operation + resources + region + service as cache key
 * If input shape doesn't have endpointdiscoveryid trait then use
 *   accessKey + region + service as cache key
 * @return [map<String,String>] object with keys to index endpoints.
 * @api private
 */
function getCacheKey(request) {
  var service = request.service;
  var api = service.api || {};
  var operations = api.operations;
  var identifiers = {};
  if (service.config.region) {
    identifiers.region = service.config.region;
  }
  if (api.serviceId) {
    identifiers.serviceId = api.serviceId;
  }
  if (service.config.credentials.accessKeyId) {
    identifiers.accessKeyId = service.config.credentials.accessKeyId;
  }
  return identifiers;
}

/**
 * Recursive helper for marshallCustomIdentifiers().
 * Looks for required string input members that have 'endpointdiscoveryid' trait.
 * @api private
 */
function marshallCustomIdentifiersHelper(result, params, shape) {
  if (!shape || params === undefined || params === null) return;
  if (shape.type === 'structure' && shape.required && shape.required.length > 0) {
    util.arrayEach(shape.required, function(name) {
      var memberShape = shape.members[name];
      if (memberShape.endpointDiscoveryId === true) {
        var locationName = memberShape.isLocationName ? memberShape.name : name;
        result[locationName] = String(params[name]);
      } else {
        marshallCustomIdentifiersHelper(result, params[name], memberShape);
      }
    });
  }
}

/**
 * Get custom identifiers for cache key.
 * Identifies custom identifiers by checking each shape's `endpointDiscoveryId` trait.
 * @param [object] request object
 * @param [object] input shape of the given operation's api
 * @api private
 */
function marshallCustomIdentifiers(request, shape) {
  var identifiers = {};
  marshallCustomIdentifiersHelper(identifiers, request.params, shape);
  return identifiers;
}

/**
 * Call endpoint discovery operation when it's optional.
 * When endpoint is available in cache then use the cached endpoints. If endpoints
 * are unavailable then use regional endpoints and call endpoint discovery operation
 * asynchronously. This is turned off by default.
 * @param [object] request object
 * @api private
 */
function optionalDiscoverEndpoint(request) {
  var service = request.service;
  var api = service.api;
  var operationModel = api.operations ? api.operations[request.operation] : undefined;
  var inputShape = operationModel ? operationModel.input : undefined;

  var identifiers = marshallCustomIdentifiers(request, inputShape);
  var cacheKey = getCacheKey(request);
  if (Object.keys(identifiers).length > 0) {
    cacheKey = util.update(cacheKey, identifiers);
    if (operationModel) cacheKey.operation = operationModel.name;
  }
  var endpoints = AWS.endpointCache.get(cacheKey);
  if (endpoints && endpoints.length === 1 && endpoints[0].Address === '') {
    //endpoint operation is being made but response not yet received
    //or endpoint operation just failed in 1 minute
    return;
  } else if (endpoints && endpoints.length > 0) {
    //found endpoint record from cache
    request.httpRequest.updateEndpoint(endpoints[0].Address);
  } else {
    //endpoint record not in cache or outdated. make discovery operation
    var endpointRequest = service.makeRequest(api.endpointOperation, {
      Operation: operationModel.name,
      Identifiers: identifiers,
    });
    addApiVersionHeader(endpointRequest);
    endpointRequest.removeListener('validate', AWS.EventListeners.Core.VALIDATE_PARAMETERS);
    endpointRequest.removeListener('retry', AWS.EventListeners.Core.RETRY_CHECK);
    //put in a placeholder for endpoints already requested, prevent
    //too much in-flight calls
    AWS.endpointCache.put(cacheKey, [{
      Address: '',
      CachePeriodInMinutes: 1
    }]);
    endpointRequest.send(function(err, data) {
      if (data && data.Endpoints) {
        AWS.endpointCache.put(cacheKey, data.Endpoints);
      } else if (err) {
        AWS.endpointCache.put(cacheKey, [{
          Address: '',
          CachePeriodInMinutes: 1 //not to make more endpoint operation in next 1 minute
        }]);
      }
    });
  }
}

var requestQueue = {};

/**
 * Call endpoint discovery operation when it's required.
 * When endpoint is available in cache then use cached ones. If endpoints are
 * unavailable then SDK should call endpoint operation then use returned new
 * endpoint for the api call. SDK will automatically attempt to do endpoint
 * discovery. This is turned off by default
 * @param [object] request object
 * @api private
 */
function requiredDiscoverEndpoint(request, done) {
  var service = request.service;
  var api = service.api;
  var operationModel = api.operations ? api.operations[request.operation] : undefined;
  var inputShape = operationModel ? operationModel.input : undefined;

  var identifiers = marshallCustomIdentifiers(request, inputShape);
  var cacheKey = getCacheKey(request);
  if (Object.keys(identifiers).length > 0) {
    cacheKey = util.update(cacheKey, identifiers);
    if (operationModel) cacheKey.operation = operationModel.name;
  }
  var cacheKeyStr = AWS.EndpointCache.getKeyString(cacheKey);
  var endpoints = AWS.endpointCache.get(cacheKeyStr); //endpoint cache also accepts string keys
  if (endpoints && endpoints.length === 1 && endpoints[0].Address === '') {
    //endpoint operation is being made but response not yet received
    //push request object to a pending queue
    if (!requestQueue[cacheKeyStr]) requestQueue[cacheKeyStr] = [];
    requestQueue[cacheKeyStr].push({request: request, callback: done});
    return;
  } else if (endpoints && endpoints.length > 0) {
    request.httpRequest.updateEndpoint(endpoints[0].Address);
    done();
  } else {
    var endpointRequest = service.makeRequest(api.endpointOperation, {
      Operation: operationModel.name,
      Identifiers: identifiers,
    });
    endpointRequest.removeListener('validate', AWS.EventListeners.Core.VALIDATE_PARAMETERS);
    addApiVersionHeader(endpointRequest);

    //put in a placeholder for endpoints already requested, prevent
    //too much in-flight calls
    AWS.endpointCache.put(cacheKeyStr, [{
      Address: '',
      CachePeriodInMinutes: 60 //long-live cache
    }]);
    endpointRequest.send(function(err, data) {
      if (err) {
        request.response.error = util.error(err, { retryable: false });
        AWS.endpointCache.remove(cacheKey);

        //fail all the pending requests in batch
        if (requestQueue[cacheKeyStr]) {
          var pendingRequests = requestQueue[cacheKeyStr];
          util.arrayEach(pendingRequests, function(requestContext) {
            requestContext.request.response.error = util.error(err, { retryable: false });
            requestContext.callback();
          });
          delete requestQueue[cacheKeyStr];
        }
      } else if (data) {
        AWS.endpointCache.put(cacheKeyStr, data.Endpoints);
        request.httpRequest.updateEndpoint(data.Endpoints[0].Address);

        //update the endpoint for all the pending requests in batch
        if (requestQueue[cacheKeyStr]) {
          var pendingRequests = requestQueue[cacheKeyStr];
          util.arrayEach(pendingRequests, function(requestContext) {
            requestContext.request.httpRequest.updateEndpoint(data.Endpoints[0].Address);
            requestContext.callback();
          });
          delete requestQueue[cacheKeyStr];
        }
      }
      done();
    });
  }
}

/**
 * add api version header to endpoint operation
 * @api private
 */
function addApiVersionHeader(endpointRequest) {
  var api = endpointRequest.service.api;
  var apiVersion = api.apiVersion;
  if (apiVersion && !endpointRequest.httpRequest.headers['x-amz-api-version']) {
    endpointRequest.httpRequest.headers['x-amz-api-version'] = apiVersion;
  }
}

/**
 * If api call gets invalid endpoint exception, SDK should attempt to remove the invalid
 * endpoint from cache.
 * @api private
 */
function invalidateCachedEndpoints(response) {
  var error = response.error;
  var httpResponse = response.httpResponse;
  if (error &&
    (error.code === 'InvalidEndpointException' || httpResponse.statusCode === 421)
  ) {
    var request = response.request;
    var operations = request.service.api.operations || {};
    var inputShape = operations[request.operation] ? operations[request.operation].input : undefined;
    var identifiers = marshallCustomIdentifiers(request, inputShape);
    var cacheKey = getCacheKey(request);
    if (Object.keys(identifiers).length > 0) {
      cacheKey = util.update(cacheKey, identifiers);
      if (operations[request.operation]) cacheKey.operation = operations[request.operation].name;
    }
    AWS.endpointCache.remove(cacheKey);
  }
}

/**
 * If endpoint is explicitly configured, SDK should not do endpoint discovery in anytime.
 * @param [object] client Service client object.
 * @api private
 */
function hasCustomEndpoint(client) {
  //if set endpoint is set for specific client, enable endpoint discovery will raise an error.
  if (client._originalConfig && client._originalConfig.endpoint && client._originalConfig.endpointDiscoveryEnabled === true) {
    throw util.error(new Error(), {
      code: 'ConfigurationException',
      message: 'Custom endpoint is supplied; endpointDiscoveryEnabled must not be true.'
    });
  };
  var svcConfig = AWS.config[client.serviceIdentifier] || {};
  return Boolean(AWS.config.endpoint || svcConfig.endpoint || (client._originalConfig && client._originalConfig.endpoint));
}

/**
 * @api private
 */
function isFalsy(value) {
  return ['false', '0'].indexOf(value) >= 0;
}

/**
 * If endpoint discovery should perform for this request when no operation requires endpoint
 * discovery for the given service.
 * SDK performs config resolution in order like below:
 * 1. If set in client configuration.
 * 2. If set in env AWS_ENABLE_ENDPOINT_DISCOVERY.
 * 3. If set in shared ini config file with key 'endpoint_discovery_enabled'.
 * @param [object] request request object.
 * @returns [boolean|undefined] if endpoint discovery config is not set in any source, this
 *  function returns undefined
 * @api private
 */
function resolveEndpointDiscoveryConfig(request) {
  var service = request.service || {};
  if (service.config.endpointDiscoveryEnabled !== undefined) {
    return service.config.endpointDiscoveryEnabled;
  }

  //shared ini file is only available in Node
  //not to check env in browser
  if (util.isBrowser()) return undefined;

  // If any of recognized endpoint discovery config env is set
  for (var i = 0; i < endpointDiscoveryEnabledEnvs.length; i++) {
    var env = endpointDiscoveryEnabledEnvs[i];
    if (Object.prototype.hasOwnProperty.call(process.env, env)) {
      if (process.env[env] === '' || process.env[env] === undefined) {
        throw util.error(new Error(), {
          code: 'ConfigurationException',
          message: 'environmental variable ' + env + ' cannot be set to nothing'
        });
      }
      return !isFalsy(process.env[env]);
    }
  }

  var configFile = {};
  try {
    configFile = AWS.util.iniLoader ? AWS.util.iniLoader.loadFrom({
      isConfig: true,
      filename: process.env[AWS.util.sharedConfigFileEnv]
    }) : {};
  } catch (e) {}
  var sharedFileConfig = configFile[
    process.env.AWS_PROFILE || AWS.util.defaultProfile
  ] || {};
  if (Object.prototype.hasOwnProperty.call(sharedFileConfig, 'endpoint_discovery_enabled')) {
    if (sharedFileConfig.endpoint_discovery_enabled === undefined) {
      throw util.error(new Error(), {
        code: 'ConfigurationException',
        message: 'config file entry \'endpoint_discovery_enabled\' cannot be set to nothing'
      });
    }
    return !isFalsy(sharedFileConfig.endpoint_discovery_enabled);
  }
  return undefined;
}

/**
 * attach endpoint discovery logic to request object
 * @param [object] request
 * @api private
 */
function discoverEndpoint(request, done) {
  var service = request.service || {};
  if (hasCustomEndpoint(service) || request.isPresigned()) return done();

  var operations = service.api.operations || {};
  var operationModel = operations[request.operation];
  var isEndpointDiscoveryRequired = operationModel ? operationModel.endpointDiscoveryRequired : 'NULL';
  var isEnabled = resolveEndpointDiscoveryConfig(request);
  var hasRequiredEndpointDiscovery = service.api.hasRequiredEndpointDiscovery;
  if (isEnabled || hasRequiredEndpointDiscovery) {
    // Once a customer enables endpoint discovery, the SDK should start appending
    // the string endpoint-discovery to the user-agent on all requests.
    request.httpRequest.appendToUserAgent('endpoint-discovery');
  }
  switch (isEndpointDiscoveryRequired) {
    case 'OPTIONAL':
      if (isEnabled || hasRequiredEndpointDiscovery) {
        // For a given service; if at least one operation requires endpoint discovery then the SDK must enable endpoint discovery
        // by default for all operations of that service, including operations where endpoint discovery is optional.
        optionalDiscoverEndpoint(request);
        request.addNamedListener('INVALIDATE_CACHED_ENDPOINTS', 'extractError', invalidateCachedEndpoints);
      }
      done();
      break;
    case 'REQUIRED':
      if (isEnabled === false) {
        // For a given operation; if endpoint discovery is required and it has been disabled on the SDK client,
        // then the SDK must return a clear and actionable exception.
        request.response.error = util.error(new Error(), {
          code: 'ConfigurationException',
          message: 'Endpoint Discovery is disabled but ' + service.api.className + '.' + request.operation +
                    '() requires it. Please check your configurations.'
        });
        done();
        break;
      }
      request.addNamedListener('INVALIDATE_CACHED_ENDPOINTS', 'extractError', invalidateCachedEndpoints);
      requiredDiscoverEndpoint(request, done);
      break;
    case 'NULL':
    default:
      done();
      break;
  }
}

module.exports = {
  discoverEndpoint: discoverEndpoint,
  requiredDiscoverEndpoint: requiredDiscoverEndpoint,
  optionalDiscoverEndpoint: optionalDiscoverEndpoint,
  marshallCustomIdentifiers: marshallCustomIdentifiers,
  getCacheKey: getCacheKey,
  invalidateCachedEndpoint: invalidateCachedEndpoints,
};

}).call(this,require('_process'))
},{"./core":324,"./util":398,"_process":412}],333:[function(require,module,exports){
var AWS = require('../core');
var util = AWS.util;
var typeOf = require('./types').typeOf;
var DynamoDBSet = require('./set');
var NumberValue = require('./numberValue');

AWS.DynamoDB.Converter = {
  /**
   * Convert a JavaScript value to its equivalent DynamoDB AttributeValue type
   *
   * @param data [any] The data to convert to a DynamoDB AttributeValue
   * @param options [map]
   * @option options convertEmptyValues [Boolean] Whether to automatically
   *                                              convert empty strings, blobs,
   *                                              and sets to `null`
   * @option options wrapNumbers [Boolean]  Whether to return numbers as a
   *                                        NumberValue object instead of
   *                                        converting them to native JavaScript
   *                                        numbers. This allows for the safe
   *                                        round-trip transport of numbers of
   *                                        arbitrary size.
   * @return [map] An object in the Amazon DynamoDB AttributeValue format
   *
   * @see AWS.DynamoDB.Converter.marshall AWS.DynamoDB.Converter.marshall to
   *    convert entire records (rather than individual attributes)
   */
  input: function convertInput(data, options) {
    options = options || {};
    var type = typeOf(data);
    if (type === 'Object') {
      return formatMap(data, options);
    } else if (type === 'Array') {
      return formatList(data, options);
    } else if (type === 'Set') {
      return formatSet(data, options);
    } else if (type === 'String') {
      if (data.length === 0 && options.convertEmptyValues) {
        return convertInput(null);
      }
      return { S: data };
    } else if (type === 'Number' || type === 'NumberValue') {
      return { N: data.toString() };
    } else if (type === 'Binary') {
      if (data.length === 0 && options.convertEmptyValues) {
        return convertInput(null);
      }
      return { B: data };
    } else if (type === 'Boolean') {
      return { BOOL: data };
    } else if (type === 'null') {
      return { NULL: true };
    } else if (type !== 'undefined' && type !== 'Function') {
      // this value has a custom constructor
      return formatMap(data, options);
    }
  },

  /**
   * Convert a JavaScript object into a DynamoDB record.
   *
   * @param data [any] The data to convert to a DynamoDB record
   * @param options [map]
   * @option options convertEmptyValues [Boolean] Whether to automatically
   *                                              convert empty strings, blobs,
   *                                              and sets to `null`
   * @option options wrapNumbers [Boolean]  Whether to return numbers as a
   *                                        NumberValue object instead of
   *                                        converting them to native JavaScript
   *                                        numbers. This allows for the safe
   *                                        round-trip transport of numbers of
   *                                        arbitrary size.
   *
   * @return [map] An object in the DynamoDB record format.
   *
   * @example Convert a JavaScript object into a DynamoDB record
   *  var marshalled = AWS.DynamoDB.Converter.marshall({
   *    string: 'foo',
   *    list: ['fizz', 'buzz', 'pop'],
   *    map: {
   *      nestedMap: {
   *        key: 'value',
   *      }
   *    },
   *    number: 123,
   *    nullValue: null,
   *    boolValue: true,
   *    stringSet: new DynamoDBSet(['foo', 'bar', 'baz'])
   *  });
   */
  marshall: function marshallItem(data, options) {
    return AWS.DynamoDB.Converter.input(data, options).M;
  },

  /**
   * Convert a DynamoDB AttributeValue object to its equivalent JavaScript type.
   *
   * @param data [map] An object in the Amazon DynamoDB AttributeValue format
   * @param options [map]
   * @option options convertEmptyValues [Boolean] Whether to automatically
   *                                              convert empty strings, blobs,
   *                                              and sets to `null`
   * @option options wrapNumbers [Boolean]  Whether to return numbers as a
   *                                        NumberValue object instead of
   *                                        converting them to native JavaScript
   *                                        numbers. This allows for the safe
   *                                        round-trip transport of numbers of
   *                                        arbitrary size.
   *
   * @return [Object|Array|String|Number|Boolean|null]
   *
   * @see AWS.DynamoDB.Converter.unmarshall AWS.DynamoDB.Converter.unmarshall to
   *    convert entire records (rather than individual attributes)
   */
  output: function convertOutput(data, options) {
    options = options || {};
    var list, map, i;
    for (var type in data) {
      var values = data[type];
      if (type === 'M') {
        map = {};
        for (var key in values) {
          map[key] = convertOutput(values[key], options);
        }
        return map;
      } else if (type === 'L') {
        list = [];
        for (i = 0; i < values.length; i++) {
          list.push(convertOutput(values[i], options));
        }
        return list;
      } else if (type === 'SS') {
        list = [];
        for (i = 0; i < values.length; i++) {
          list.push(values[i] + '');
        }
        return new DynamoDBSet(list);
      } else if (type === 'NS') {
        list = [];
        for (i = 0; i < values.length; i++) {
          list.push(convertNumber(values[i], options.wrapNumbers));
        }
        return new DynamoDBSet(list);
      } else if (type === 'BS') {
        list = [];
        for (i = 0; i < values.length; i++) {
          list.push(AWS.util.buffer.toBuffer(values[i]));
        }
        return new DynamoDBSet(list);
      } else if (type === 'S') {
        return values + '';
      } else if (type === 'N') {
        return convertNumber(values, options.wrapNumbers);
      } else if (type === 'B') {
        return util.buffer.toBuffer(values);
      } else if (type === 'BOOL') {
        return (values === 'true' || values === 'TRUE' || values === true);
      } else if (type === 'NULL') {
        return null;
      }
    }
  },

  /**
   * Convert a DynamoDB record into a JavaScript object.
   *
   * @param data [any] The DynamoDB record
   * @param options [map]
   * @option options convertEmptyValues [Boolean] Whether to automatically
   *                                              convert empty strings, blobs,
   *                                              and sets to `null`
   * @option options wrapNumbers [Boolean]  Whether to return numbers as a
   *                                        NumberValue object instead of
   *                                        converting them to native JavaScript
   *                                        numbers. This allows for the safe
   *                                        round-trip transport of numbers of
   *                                        arbitrary size.
   *
   * @return [map] An object whose properties have been converted from
   *    DynamoDB's AttributeValue format into their corresponding native
   *    JavaScript types.
   *
   * @example Convert a record received from a DynamoDB stream
   *  var unmarshalled = AWS.DynamoDB.Converter.unmarshall({
   *    string: {S: 'foo'},
   *    list: {L: [{S: 'fizz'}, {S: 'buzz'}, {S: 'pop'}]},
   *    map: {
   *      M: {
   *        nestedMap: {
   *          M: {
   *            key: {S: 'value'}
   *          }
   *        }
   *      }
   *    },
   *    number: {N: '123'},
   *    nullValue: {NULL: true},
   *    boolValue: {BOOL: true}
   *  });
   */
  unmarshall: function unmarshall(data, options) {
    return AWS.DynamoDB.Converter.output({M: data}, options);
  }
};

/**
 * @api private
 * @param data [Array]
 * @param options [map]
 */
function formatList(data, options) {
  var list = {L: []};
  for (var i = 0; i < data.length; i++) {
    list['L'].push(AWS.DynamoDB.Converter.input(data[i], options));
  }
  return list;
}

/**
 * @api private
 * @param value [String]
 * @param wrapNumbers [Boolean]
 */
function convertNumber(value, wrapNumbers) {
  return wrapNumbers ? new NumberValue(value) : Number(value);
}

/**
 * @api private
 * @param data [map]
 * @param options [map]
 */
function formatMap(data, options) {
  var map = {M: {}};
  for (var key in data) {
    var formatted = AWS.DynamoDB.Converter.input(data[key], options);
    if (formatted !== void 0) {
      map['M'][key] = formatted;
    }
  }
  return map;
}

/**
 * @api private
 */
function formatSet(data, options) {
  options = options || {};
  var values = data.values;
  if (options.convertEmptyValues) {
    values = filterEmptySetValues(data);
    if (values.length === 0) {
      return AWS.DynamoDB.Converter.input(null);
    }
  }

  var map = {};
  switch (data.type) {
    case 'String': map['SS'] = values; break;
    case 'Binary': map['BS'] = values; break;
    case 'Number': map['NS'] = values.map(function (value) {
      return value.toString();
    });
  }
  return map;
}

/**
 * @api private
 */
function filterEmptySetValues(set) {
    var nonEmptyValues = [];
    var potentiallyEmptyTypes = {
        String: true,
        Binary: true,
        Number: false
    };
    if (potentiallyEmptyTypes[set.type]) {
        for (var i = 0; i < set.values.length; i++) {
            if (set.values[i].length === 0) {
                continue;
            }
            nonEmptyValues.push(set.values[i]);
        }

        return nonEmptyValues;
    }

    return set.values;
}

/**
 * @api private
 */
module.exports = AWS.DynamoDB.Converter;

},{"../core":324,"./numberValue":335,"./set":336,"./types":338}],334:[function(require,module,exports){
var AWS = require('../core');
var Translator = require('./translator');
var DynamoDBSet = require('./set');

/**
 * The document client simplifies working with items in Amazon DynamoDB
 * by abstracting away the notion of attribute values. This abstraction
 * annotates native JavaScript types supplied as input parameters, as well
 * as converts annotated response data to native JavaScript types.
 *
 * ## Marshalling Input and Unmarshalling Response Data
 *
 * The document client affords developers the use of native JavaScript types
 * instead of `AttributeValue`s to simplify the JavaScript development
 * experience with Amazon DynamoDB. JavaScript objects passed in as parameters
 * are marshalled into `AttributeValue` shapes required by Amazon DynamoDB.
 * Responses from DynamoDB are unmarshalled into plain JavaScript objects
 * by the `DocumentClient`. The `DocumentClient`, does not accept
 * `AttributeValue`s in favor of native JavaScript types.
 *
 * |                             JavaScript Type                            | DynamoDB AttributeValue |
 * |:----------------------------------------------------------------------:|-------------------------|
 * | String                                                                 | S                       |
 * | Number                                                                 | N                       |
 * | Boolean                                                                | BOOL                    |
 * | null                                                                   | NULL                    |
 * | Array                                                                  | L                       |
 * | Object                                                                 | M                       |
 * | Buffer, File, Blob, ArrayBuffer, DataView, and JavaScript typed arrays | B                       |
 *
 * ## Support for Sets
 *
 * The `DocumentClient` offers a convenient way to create sets from
 * JavaScript Arrays. The type of set is inferred from the first element
 * in the array. DynamoDB supports string, number, and binary sets. To
 * learn more about supported types see the
 * [Amazon DynamoDB Data Model Documentation](http://docs.aws.amazon.com/amazondynamodb/latest/developerguide/DataModel.html)
 * For more information see {AWS.DynamoDB.DocumentClient.createSet}
 *
 */
AWS.DynamoDB.DocumentClient = AWS.util.inherit({

  /**
   * Creates a DynamoDB document client with a set of configuration options.
   *
   * @option options params [map] An optional map of parameters to bind to every
   *   request sent by this service object.
   * @option options service [AWS.DynamoDB] An optional pre-configured instance
   *  of the AWS.DynamoDB service object to use for requests. The object may
   *  bound parameters used by the document client.
   * @option options convertEmptyValues [Boolean] set to true if you would like
   *  the document client to convert empty values (0-length strings, binary
   *  buffers, and sets) to be converted to NULL types when persisting to
   *  DynamoDB.
   * @see AWS.DynamoDB.constructor
   *
   */
  constructor: function DocumentClient(options) {
    var self = this;
    self.options = options || {};
    self.configure(self.options);
  },

  /**
   * @api private
   */
  configure: function configure(options) {
    var self = this;
    self.service = options.service;
    self.bindServiceObject(options);
    self.attrValue = options.attrValue =
      self.service.api.operations.putItem.input.members.Item.value.shape;
  },

  /**
   * @api private
   */
  bindServiceObject: function bindServiceObject(options) {
    var self = this;
    options = options || {};

    if (!self.service) {
      self.service = new AWS.DynamoDB(options);
    } else {
      var config = AWS.util.copy(self.service.config);
      self.service = new self.service.constructor.__super__(config);
      self.service.config.params =
        AWS.util.merge(self.service.config.params || {}, options.params);
    }
  },

  /**
   * @api private
   */
  makeServiceRequest: function(operation, params, callback) {
    var self = this;
    var request = self.service[operation](params);
    self.setupRequest(request);
    self.setupResponse(request);
    if (typeof callback === 'function') {
      request.send(callback);
    }
    return request;
  },

  /**
   * @api private
   */
  serviceClientOperationsMap: {
    batchGet: 'batchGetItem',
    batchWrite: 'batchWriteItem',
    delete: 'deleteItem',
    get: 'getItem',
    put: 'putItem',
    query: 'query',
    scan: 'scan',
    update: 'updateItem',
    transactGet: 'transactGetItems',
    transactWrite: 'transactWriteItems'
  },

  /**
   * Returns the attributes of one or more items from one or more tables
   * by delegating to `AWS.DynamoDB.batchGetItem()`.
   *
   * Supply the same parameters as {AWS.DynamoDB.batchGetItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.batchGetItem
   * @example Get items from multiple tables
   *  var params = {
   *    RequestItems: {
   *      'Table-1': {
   *        Keys: [
   *          {
   *             HashKey: 'haskey',
   *             NumberRangeKey: 1
   *          }
   *        ]
   *      },
   *      'Table-2': {
   *        Keys: [
   *          { foo: 'bar' },
   *        ]
   *      }
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.batchGet(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  batchGet: function(params, callback) {
    var operation = this.serviceClientOperationsMap['batchGet'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Puts or deletes multiple items in one or more tables by delegating
   * to `AWS.DynamoDB.batchWriteItem()`.
   *
   * Supply the same parameters as {AWS.DynamoDB.batchWriteItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.batchWriteItem
   * @example Write to and delete from a table
   *  var params = {
   *    RequestItems: {
   *      'Table-1': [
   *        {
   *          DeleteRequest: {
   *            Key: { HashKey: 'someKey' }
   *          }
   *        },
   *        {
   *          PutRequest: {
   *            Item: {
   *              HashKey: 'anotherKey',
   *              NumAttribute: 1,
   *              BoolAttribute: true,
   *              ListAttribute: [1, 'two', false],
   *              MapAttribute: { foo: 'bar' }
   *            }
   *          }
   *        }
   *      ]
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.batchWrite(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  batchWrite: function(params, callback) {
    var operation = this.serviceClientOperationsMap['batchWrite'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Deletes a single item in a table by primary key by delegating to
   * `AWS.DynamoDB.deleteItem()`
   *
   * Supply the same parameters as {AWS.DynamoDB.deleteItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.deleteItem
   * @example Delete an item from a table
   *  var params = {
   *    TableName : 'Table',
   *    Key: {
   *      HashKey: 'hashkey',
   *      NumberRangeKey: 1
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.delete(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  delete: function(params, callback) {
    var operation = this.serviceClientOperationsMap['delete'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Returns a set of attributes for the item with the given primary key
   * by delegating to `AWS.DynamoDB.getItem()`.
   *
   * Supply the same parameters as {AWS.DynamoDB.getItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.getItem
   * @example Get an item from a table
   *  var params = {
   *    TableName : 'Table',
   *    Key: {
   *      HashKey: 'hashkey'
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.get(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  get: function(params, callback) {
    var operation = this.serviceClientOperationsMap['get'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Creates a new item, or replaces an old item with a new item by
   * delegating to `AWS.DynamoDB.putItem()`.
   *
   * Supply the same parameters as {AWS.DynamoDB.putItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.putItem
   * @example Create a new item in a table
   *  var params = {
   *    TableName : 'Table',
   *    Item: {
   *       HashKey: 'haskey',
   *       NumAttribute: 1,
   *       BoolAttribute: true,
   *       ListAttribute: [1, 'two', false],
   *       MapAttribute: { foo: 'bar'},
   *       NullAttribute: null
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.put(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  put: function(params, callback) {
    var operation = this.serviceClientOperationsMap['put'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Edits an existing item's attributes, or adds a new item to the table if
   * it does not already exist by delegating to `AWS.DynamoDB.updateItem()`.
   *
   * Supply the same parameters as {AWS.DynamoDB.updateItem} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.updateItem
   * @example Update an item with expressions
   *  var params = {
   *    TableName: 'Table',
   *    Key: { HashKey : 'hashkey' },
   *    UpdateExpression: 'set #a = :x + :y',
   *    ConditionExpression: '#a < :MAX',
   *    ExpressionAttributeNames: {'#a' : 'Sum'},
   *    ExpressionAttributeValues: {
   *      ':x' : 20,
   *      ':y' : 45,
   *      ':MAX' : 100,
   *    }
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.update(params, function(err, data) {
   *     if (err) console.log(err);
   *     else console.log(data);
   *  });
   *
   */
  update: function(params, callback) {
    var operation = this.serviceClientOperationsMap['update'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Returns one or more items and item attributes by accessing every item
   * in a table or a secondary index.
   *
   * Supply the same parameters as {AWS.DynamoDB.scan} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.scan
   * @example Scan the table with a filter expression
   *  var params = {
   *    TableName : 'Table',
   *    FilterExpression : 'Year = :this_year',
   *    ExpressionAttributeValues : {':this_year' : 2015}
   *  };
   *
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  documentClient.scan(params, function(err, data) {
   *     if (err) console.log(err);
   *     else console.log(data);
   *  });
   *
   */
  scan: function(params, callback) {
    var operation = this.serviceClientOperationsMap['scan'];
    return this.makeServiceRequest(operation, params, callback);
  },

   /**
    * Directly access items from a table by primary key or a secondary index.
    *
    * Supply the same parameters as {AWS.DynamoDB.query} with
    * `AttributeValue`s substituted by native JavaScript types.
    *
    * @see AWS.DynamoDB.query
    * @example Query an index
    *  var params = {
    *    TableName: 'Table',
    *    IndexName: 'Index',
    *    KeyConditionExpression: 'HashKey = :hkey and RangeKey > :rkey',
    *    ExpressionAttributeValues: {
    *      ':hkey': 'key',
    *      ':rkey': 2015
    *    }
    *  };
    *
    *  var documentClient = new AWS.DynamoDB.DocumentClient();
    *
    *  documentClient.query(params, function(err, data) {
    *     if (err) console.log(err);
    *     else console.log(data);
    *  });
    *
    */
  query: function(params, callback) {
    var operation = this.serviceClientOperationsMap['query'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Synchronous write operation that groups up to 10 action requests
   *
   * Supply the same parameters as {AWS.DynamoDB.transactWriteItems} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.transactWriteItems
   * @example Get items from multiple tables
   *  var params = {
   *    TransactItems: [{
   *      Put: {
   *        TableName : 'Table0',
   *        Item: {
   *          HashKey: 'haskey',
   *          NumAttribute: 1,
   *          BoolAttribute: true,
   *          ListAttribute: [1, 'two', false],
   *          MapAttribute: { foo: 'bar'},
   *          NullAttribute: null
   *        }
   *      }
   *    }, {
   *      Update: {
   *        TableName: 'Table1',
   *        Key: { HashKey : 'hashkey' },
   *        UpdateExpression: 'set #a = :x + :y',
   *        ConditionExpression: '#a < :MAX',
   *        ExpressionAttributeNames: {'#a' : 'Sum'},
   *        ExpressionAttributeValues: {
   *          ':x' : 20,
   *          ':y' : 45,
   *          ':MAX' : 100,
   *        }
   *      }
   *    }]
   *  };
   *
   *  documentClient.transactWrite(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   */
  transactWrite: function(params, callback) {
    var operation = this.serviceClientOperationsMap['transactWrite'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Atomically retrieves multiple items from one or more tables (but not from indexes)
   * in a single account and region.
   *
   * Supply the same parameters as {AWS.DynamoDB.transactGetItems} with
   * `AttributeValue`s substituted by native JavaScript types.
   *
   * @see AWS.DynamoDB.transactGetItems
   * @example Get items from multiple tables
   *  var params = {
   *    TransactItems: [{
   *      Get: {
   *        TableName : 'Table0',
   *        Key: {
   *          HashKey: 'hashkey0'
   *        }
   *      }
   *    }, {
   *      Get: {
   *        TableName : 'Table1',
   *        Key: {
   *          HashKey: 'hashkey1'
   *        }
   *      }
   *    }]
   *  };
   *
   *  documentClient.transactGet(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   */
  transactGet: function(params, callback) {
    var operation = this.serviceClientOperationsMap['transactGet'];
    return this.makeServiceRequest(operation, params, callback);
  },

  /**
   * Creates a set of elements inferring the type of set from
   * the type of the first element. Amazon DynamoDB currently supports
   * the number sets, string sets, and binary sets. For more information
   * about DynamoDB data types see the documentation on the
   * [Amazon DynamoDB Data Model](http://docs.aws.amazon.com/amazondynamodb/latest/developerguide/DataModel.html#DataModel.DataTypes).
   *
   * @param list [Array] Collection to represent your DynamoDB Set
   * @param options [map]
   *  * **validate** [Boolean] set to true if you want to validate the type
   *    of each element in the set. Defaults to `false`.
   * @example Creating a number set
   *  var documentClient = new AWS.DynamoDB.DocumentClient();
   *
   *  var params = {
   *    Item: {
   *      hashkey: 'hashkey'
   *      numbers: documentClient.createSet([1, 2, 3]);
   *    }
   *  };
   *
   *  documentClient.put(params, function(err, data) {
   *    if (err) console.log(err);
   *    else console.log(data);
   *  });
   *
   */
  createSet: function(list, options) {
    options = options || {};
    return new DynamoDBSet(list, options);
  },

  /**
   * @api private
   */
  getTranslator: function() {
    return new Translator(this.options);
  },

  /**
   * @api private
   */
  setupRequest: function setupRequest(request) {
    var self = this;
    var translator = self.getTranslator();
    var operation = request.operation;
    var inputShape = request.service.api.operations[operation].input;
    request._events.validate.unshift(function(req) {
      req.rawParams = AWS.util.copy(req.params);
      req.params = translator.translateInput(req.rawParams, inputShape);
    });
  },

  /**
   * @api private
   */
  setupResponse: function setupResponse(request) {
    var self = this;
    var translator = self.getTranslator();
    var outputShape = self.service.api.operations[request.operation].output;
    request.on('extractData', function(response) {
      response.data = translator.translateOutput(response.data, outputShape);
    });

    var response = request.response;
    response.nextPage = function(cb) {
      var resp = this;
      var req = resp.request;
      var config;
      var service = req.service;
      var operation = req.operation;
      try {
        config = service.paginationConfig(operation, true);
      } catch (e) { resp.error = e; }

      if (!resp.hasNextPage()) {
        if (cb) cb(resp.error, null);
        else if (resp.error) throw resp.error;
        return null;
      }

      var params = AWS.util.copy(req.rawParams);
      if (!resp.nextPageTokens) {
        return cb ? cb(null, null) : null;
      } else {
        var inputTokens = config.inputToken;
        if (typeof inputTokens === 'string') inputTokens = [inputTokens];
        for (var i = 0; i < inputTokens.length; i++) {
          params[inputTokens[i]] = resp.nextPageTokens[i];
        }
        return self[operation](params, cb);
      }
    };
  }

});

/**
 * @api private
 */
module.exports = AWS.DynamoDB.DocumentClient;

},{"../core":324,"./set":336,"./translator":337}],335:[function(require,module,exports){
var util = require('../core').util;

/**
 * An object recognizable as a numeric value that stores the underlying number
 * as a string.
 *
 * Intended to be a deserialization target for the DynamoDB Document Client when
 * the `wrapNumbers` flag is set. This allows for numeric values that lose
 * precision when converted to JavaScript's `number` type.
 */
var DynamoDBNumberValue = util.inherit({
  constructor: function NumberValue(value) {
    this.wrapperName = 'NumberValue';
    this.value = value.toString();
  },

  /**
   * Render the underlying value as a number when converting to JSON.
   */
  toJSON: function () {
    return this.toNumber();
  },

  /**
   * Convert the underlying value to a JavaScript number.
   */
  toNumber: function () {
    return Number(this.value);
  },

  /**
   * Return a string representing the unaltered value provided to the
   * constructor.
   */
  toString: function () {
    return this.value;
  }
});

/**
 * @api private
 */
module.exports = DynamoDBNumberValue;

},{"../core":324}],336:[function(require,module,exports){
var util = require('../core').util;
var typeOf = require('./types').typeOf;

/**
 * @api private
 */
var memberTypeToSetType = {
  'String': 'String',
  'Number': 'Number',
  'NumberValue': 'Number',
  'Binary': 'Binary'
};

/**
 * @api private
 */
var DynamoDBSet = util.inherit({

  constructor: function Set(list, options) {
    options = options || {};
    this.wrapperName = 'Set';
    this.initialize(list, options.validate);
  },

  initialize: function(list, validate) {
    var self = this;
    self.values = [].concat(list);
    self.detectType();
    if (validate) {
      self.validate();
    }
  },

  detectType: function() {
    this.type = memberTypeToSetType[typeOf(this.values[0])];
    if (!this.type) {
      throw util.error(new Error(), {
        code: 'InvalidSetType',
        message: 'Sets can contain string, number, or binary values'
      });
    }
  },

  validate: function() {
    var self = this;
    var length = self.values.length;
    var values = self.values;
    for (var i = 0; i < length; i++) {
      if (memberTypeToSetType[typeOf(values[i])] !== self.type) {
        throw util.error(new Error(), {
          code: 'InvalidType',
          message: self.type + ' Set contains ' + typeOf(values[i]) + ' value'
        });
      }
    }
  },

  /**
   * Render the underlying values only when converting to JSON.
   */
  toJSON: function() {
    var self = this;
    return self.values;
  }

});

/**
 * @api private
 */
module.exports = DynamoDBSet;

},{"../core":324,"./types":338}],337:[function(require,module,exports){
var util = require('../core').util;
var convert = require('./converter');

var Translator = function(options) {
  options = options || {};
  this.attrValue = options.attrValue;
  this.convertEmptyValues = Boolean(options.convertEmptyValues);
  this.wrapNumbers = Boolean(options.wrapNumbers);
};

Translator.prototype.translateInput = function(value, shape) {
  this.mode = 'input';
  return this.translate(value, shape);
};

Translator.prototype.translateOutput = function(value, shape) {
  this.mode = 'output';
  return this.translate(value, shape);
};

Translator.prototype.translate = function(value, shape) {
  var self = this;
  if (!shape || value === undefined) return undefined;

  if (shape.shape === self.attrValue) {
    return convert[self.mode](value, {
      convertEmptyValues: self.convertEmptyValues,
      wrapNumbers: self.wrapNumbers,
    });
  }
  switch (shape.type) {
    case 'structure': return self.translateStructure(value, shape);
    case 'map': return self.translateMap(value, shape);
    case 'list': return self.translateList(value, shape);
    default: return self.translateScalar(value, shape);
  }
};

Translator.prototype.translateStructure = function(structure, shape) {
  var self = this;
  if (structure == null) return undefined;

  var struct = {};
  util.each(structure, function(name, value) {
    var memberShape = shape.members[name];
    if (memberShape) {
      var result = self.translate(value, memberShape);
      if (result !== undefined) struct[name] = result;
    }
  });
  return struct;
};

Translator.prototype.translateList = function(list, shape) {
  var self = this;
  if (list == null) return undefined;

  var out = [];
  util.arrayEach(list, function(value) {
    var result = self.translate(value, shape.member);
    if (result === undefined) out.push(null);
    else out.push(result);
  });
  return out;
};

Translator.prototype.translateMap = function(map, shape) {
  var self = this;
  if (map == null) return undefined;

  var out = {};
  util.each(map, function(key, value) {
    var result = self.translate(value, shape.value);
    if (result === undefined) out[key] = null;
    else out[key] = result;
  });
  return out;
};

Translator.prototype.translateScalar = function(value, shape) {
  return shape.toType(value);
};

/**
 * @api private
 */
module.exports = Translator;

},{"../core":324,"./converter":333}],338:[function(require,module,exports){
var util = require('../core').util;

function typeOf(data) {
  if (data === null && typeof data === 'object') {
    return 'null';
  } else if (data !== undefined && isBinary(data)) {
    return 'Binary';
  } else if (data !== undefined && data.constructor) {
    return data.wrapperName || util.typeName(data.constructor);
  } else if (data !== undefined && typeof data === 'object') {
    // this object is the result of Object.create(null), hence the absence of a
    // defined constructor
    return 'Object';
  } else {
    return 'undefined';
  }
}

function isBinary(data) {
  var types = [
    'Buffer', 'File', 'Blob', 'ArrayBuffer', 'DataView',
    'Int8Array', 'Uint8Array', 'Uint8ClampedArray',
    'Int16Array', 'Uint16Array', 'Int32Array', 'Uint32Array',
    'Float32Array', 'Float64Array'
  ];
  if (util.isNode()) {
    var Stream = util.stream.Stream;
    if (util.Buffer.isBuffer(data) || data instanceof Stream) {
      return true;
    }
  }

  for (var i = 0; i < types.length; i++) {
    if (data !== undefined && data.constructor) {
      if (util.isType(data, types[i])) return true;
      if (util.typeName(data.constructor) === types[i]) return true;
    }
  }

  return false;
}

/**
 * @api private
 */
module.exports = {
  typeOf: typeOf,
  isBinary: isBinary
};

},{"../core":324}],339:[function(require,module,exports){
var eventMessageChunker = require('../event-stream/event-message-chunker').eventMessageChunker;
var parseEvent = require('./parse-event').parseEvent;

function createEventStream(body, parser, model) {
    var eventMessages = eventMessageChunker(body);

    var events = [];

    for (var i = 0; i < eventMessages.length; i++) {
        events.push(parseEvent(parser, eventMessages[i], model));
    }

    return events;
}

/**
 * @api private
 */
module.exports = {
    createEventStream: createEventStream
};

},{"../event-stream/event-message-chunker":340,"./parse-event":342}],340:[function(require,module,exports){
/**
 * Takes in a buffer of event messages and splits them into individual messages.
 * @param {Buffer} buffer
 * @api private
 */
function eventMessageChunker(buffer) {
    /** @type Buffer[] */
    var messages = [];
    var offset = 0;

    while (offset < buffer.length) {
        var totalLength = buffer.readInt32BE(offset);

        // create new buffer for individual message (shares memory with original)
        var message = buffer.slice(offset, totalLength + offset);
        // increment offset to it starts at the next message
        offset += totalLength;

        messages.push(message);
    }

    return messages;
}

/**
 * @api private
 */
module.exports = {
    eventMessageChunker: eventMessageChunker
};

},{}],341:[function(require,module,exports){
var util = require('../core').util;
var toBuffer = util.buffer.toBuffer;

/**
 * A lossless representation of a signed, 64-bit integer. Instances of this
 * class may be used in arithmetic expressions as if they were numeric
 * primitives, but the binary representation will be preserved unchanged as the
 * `bytes` property of the object. The bytes should be encoded as big-endian,
 * two's complement integers.
 * @param {Buffer} bytes
 *
 * @api private
 */
function Int64(bytes) {
    if (bytes.length !== 8) {
        throw new Error('Int64 buffers must be exactly 8 bytes');
    }
    if (!util.Buffer.isBuffer(bytes)) bytes = toBuffer(bytes);

    this.bytes = bytes;
}

/**
 * @param {number} number
 * @returns {Int64}
 *
 * @api private
 */
Int64.fromNumber = function(number) {
    if (number > 9223372036854775807 || number < -9223372036854775808) {
        throw new Error(
            number + ' is too large (or, if negative, too small) to represent as an Int64'
        );
    }

    var bytes = new Uint8Array(8);
    for (
        var i = 7, remaining = Math.abs(Math.round(number));
        i > -1 && remaining > 0;
        i--, remaining /= 256
    ) {
        bytes[i] = remaining;
    }

    if (number < 0) {
        negate(bytes);
    }

    return new Int64(bytes);
};

/**
 * @returns {number}
 *
 * @api private
 */
Int64.prototype.valueOf = function() {
    var bytes = this.bytes.slice(0);
    var negative = bytes[0] & 128;
    if (negative) {
        negate(bytes);
    }

    return parseInt(bytes.toString('hex'), 16) * (negative ? -1 : 1);
};

Int64.prototype.toString = function() {
    return String(this.valueOf());
};

/**
 * @param {Buffer} bytes
 *
 * @api private
 */
function negate(bytes) {
    for (var i = 0; i < 8; i++) {
        bytes[i] ^= 0xFF;
    }
    for (var i = 7; i > -1; i--) {
        bytes[i]++;
        if (bytes[i] !== 0) {
            break;
        }
    }
}

/**
 * @api private
 */
module.exports = {
    Int64: Int64
};

},{"../core":324}],342:[function(require,module,exports){
var parseMessage = require('./parse-message').parseMessage;

/**
 *
 * @param {*} parser
 * @param {Buffer} message
 * @param {*} shape
 * @api private
 */
function parseEvent(parser, message, shape) {
    var parsedMessage = parseMessage(message);

    // check if message is an event or error
    var messageType = parsedMessage.headers[':message-type'];
    if (messageType) {
        if (messageType.value === 'error') {
            throw parseError(parsedMessage);
        } else if (messageType.value !== 'event') {
            // not sure how to parse non-events/non-errors, ignore for now
            return;
        }
    }

    // determine event type
    var eventType = parsedMessage.headers[':event-type'];
    // check that the event type is modeled
    var eventModel = shape.members[eventType.value];
    if (!eventModel) {
        return;
    }

    var result = {};
    // check if an event payload exists
    var eventPayloadMemberName = eventModel.eventPayloadMemberName;
    if (eventPayloadMemberName) {
        var payloadShape = eventModel.members[eventPayloadMemberName];
        // if the shape is binary, return the byte array
        if (payloadShape.type === 'binary') {
            result[eventPayloadMemberName] = parsedMessage.body;
        } else {
            result[eventPayloadMemberName] = parser.parse(parsedMessage.body.toString(), payloadShape);
        }
    }

    // read event headers
    var eventHeaderNames = eventModel.eventHeaderMemberNames;
    for (var i = 0; i < eventHeaderNames.length; i++) {
        var name = eventHeaderNames[i];
        if (parsedMessage.headers[name]) {
            // parse the header!
            result[name] = eventModel.members[name].toType(parsedMessage.headers[name].value);
        }
    }

    var output = {};
    output[eventType.value] = result;
    return output;
}

function parseError(message) {
    var errorCode = message.headers[':error-code'];
    var errorMessage = message.headers[':error-message'];
    var error = new Error(errorMessage.value || errorMessage);
    error.code = error.name = errorCode.value || errorCode;
    return error;
}

/**
 * @api private
 */
module.exports = {
    parseEvent: parseEvent
};

},{"./parse-message":343}],343:[function(require,module,exports){
var Int64 = require('./int64').Int64;

var splitMessage = require('./split-message').splitMessage;

var BOOLEAN_TAG = 'boolean';
var BYTE_TAG = 'byte';
var SHORT_TAG = 'short';
var INT_TAG = 'integer';
var LONG_TAG = 'long';
var BINARY_TAG = 'binary';
var STRING_TAG = 'string';
var TIMESTAMP_TAG = 'timestamp';
var UUID_TAG = 'uuid';

/**
 * @api private
 *
 * @param {Buffer} headers
 */
function parseHeaders(headers) {
    var out = {};
    var position = 0;
    while (position < headers.length) {
        var nameLength = headers.readUInt8(position++);
        var name = headers.slice(position, position + nameLength).toString();
        position += nameLength;
        switch (headers.readUInt8(position++)) {
            case 0 /* boolTrue */:
                out[name] = {
                    type: BOOLEAN_TAG,
                    value: true
                };
                break;
            case 1 /* boolFalse */:
                out[name] = {
                    type: BOOLEAN_TAG,
                    value: false
                };
                break;
            case 2 /* byte */:
                out[name] = {
                    type: BYTE_TAG,
                    value: headers.readInt8(position++)
                };
                break;
            case 3 /* short */:
                out[name] = {
                    type: SHORT_TAG,
                    value: headers.readInt16BE(position)
                };
                position += 2;
                break;
            case 4 /* integer */:
                out[name] = {
                    type: INT_TAG,
                    value: headers.readInt32BE(position)
                };
                position += 4;
                break;
            case 5 /* long */:
                out[name] = {
                    type: LONG_TAG,
                    value: new Int64(headers.slice(position, position + 8))
                };
                position += 8;
                break;
            case 6 /* byteArray */:
                var binaryLength = headers.readUInt16BE(position);
                position += 2;
                out[name] = {
                    type: BINARY_TAG,
                    value: headers.slice(position, position + binaryLength)
                };
                position += binaryLength;
                break;
            case 7 /* string */:
                var stringLength = headers.readUInt16BE(position);
                position += 2;
                out[name] = {
                    type: STRING_TAG,
                    value: headers.slice(
                        position,
                        position + stringLength
                    ).toString()
                };
                position += stringLength;
                break;
            case 8 /* timestamp */:
                out[name] = {
                    type: TIMESTAMP_TAG,
                    value: new Date(
                        new Int64(headers.slice(position, position + 8))
                            .valueOf()
                    )
                };
                position += 8;
                break;
            case 9 /* uuid */:
                var uuidChars = headers.slice(position, position + 16)
                    .toString('hex');
                position += 16;
                out[name] = {
                    type: UUID_TAG,
                    value: uuidChars.substr(0, 8) + '-' +
                        uuidChars.substr(8, 4) + '-' +
                        uuidChars.substr(12, 4) + '-' +
                        uuidChars.substr(16, 4) + '-' +
                        uuidChars.substr(20)
                };
                break;
            default:
                throw new Error('Unrecognized header type tag');
        }
    }
    return out;
}

function parseMessage(message) {
    var parsed = splitMessage(message);
    return { headers: parseHeaders(parsed.headers), body: parsed.body };
}

/**
 * @api private
 */
module.exports = {
    parseMessage: parseMessage
};

},{"./int64":341,"./split-message":344}],344:[function(require,module,exports){
var util = require('../core').util;
var toBuffer = util.buffer.toBuffer;

// All prelude components are unsigned, 32-bit integers
var PRELUDE_MEMBER_LENGTH = 4;
// The prelude consists of two components
var PRELUDE_LENGTH = PRELUDE_MEMBER_LENGTH * 2;
// Checksums are always CRC32 hashes.
var CHECKSUM_LENGTH = 4;
// Messages must include a full prelude, a prelude checksum, and a message checksum
var MINIMUM_MESSAGE_LENGTH = PRELUDE_LENGTH + CHECKSUM_LENGTH * 2;

/**
 * @api private
 *
 * @param {Buffer} message
 */
function splitMessage(message) {
    if (!util.Buffer.isBuffer(message)) message = toBuffer(message);

    if (message.length < MINIMUM_MESSAGE_LENGTH) {
        throw new Error('Provided message too short to accommodate event stream message overhead');
    }

    if (message.length !== message.readUInt32BE(0)) {
        throw new Error('Reported message length does not match received message length');
    }

    var expectedPreludeChecksum = message.readUInt32BE(PRELUDE_LENGTH);

    if (
        expectedPreludeChecksum !== util.crypto.crc32(
            message.slice(0, PRELUDE_LENGTH)
        )
    ) {
        throw new Error(
            'The prelude checksum specified in the message (' +
            expectedPreludeChecksum +
            ') does not match the calculated CRC32 checksum.'
        );
    }

    var expectedMessageChecksum = message.readUInt32BE(message.length - CHECKSUM_LENGTH);

    if (
        expectedMessageChecksum !== util.crypto.crc32(
            message.slice(0, message.length - CHECKSUM_LENGTH)
        )
    ) {
        throw new Error(
            'The message checksum did not match the expected value of ' +
                expectedMessageChecksum
        );
    }

    var headersStart = PRELUDE_LENGTH + CHECKSUM_LENGTH;
    var headersEnd = headersStart + message.readUInt32BE(PRELUDE_MEMBER_LENGTH);

    return {
        headers: message.slice(headersStart, headersEnd),
        body: message.slice(headersEnd, message.length - CHECKSUM_LENGTH),
    };
}

/**
 * @api private
 */
module.exports = {
    splitMessage: splitMessage
};

},{"../core":324}],345:[function(require,module,exports){
var AWS = require('./core');
var SequentialExecutor = require('./sequential_executor');
var DISCOVER_ENDPOINT = require('./discover_endpoint').discoverEndpoint;
/**
 * The namespace used to register global event listeners for request building
 * and sending.
 */
AWS.EventListeners = {
  /**
   * @!attribute VALIDATE_CREDENTIALS
   *   A request listener that validates whether the request is being
   *   sent with credentials.
   *   Handles the {AWS.Request~validate 'validate' Request event}
   *   @example Sending a request without validating credentials
   *     var listener = AWS.EventListeners.Core.VALIDATE_CREDENTIALS;
   *     request.removeListener('validate', listener);
   *   @readonly
   *   @return [Function]
   * @!attribute VALIDATE_REGION
   *   A request listener that validates whether the region is set
   *   for a request.
   *   Handles the {AWS.Request~validate 'validate' Request event}
   *   @example Sending a request without validating region configuration
   *     var listener = AWS.EventListeners.Core.VALIDATE_REGION;
   *     request.removeListener('validate', listener);
   *   @readonly
   *   @return [Function]
   * @!attribute VALIDATE_PARAMETERS
   *   A request listener that validates input parameters in a request.
   *   Handles the {AWS.Request~validate 'validate' Request event}
   *   @example Sending a request without validating parameters
   *     var listener = AWS.EventListeners.Core.VALIDATE_PARAMETERS;
   *     request.removeListener('validate', listener);
   *   @example Disable parameter validation globally
   *     AWS.EventListeners.Core.removeListener('validate',
   *       AWS.EventListeners.Core.VALIDATE_REGION);
   *   @readonly
   *   @return [Function]
   * @!attribute SEND
   *   A request listener that initiates the HTTP connection for a
   *   request being sent. Handles the {AWS.Request~send 'send' Request event}
   *   @example Replacing the HTTP handler
   *     var listener = AWS.EventListeners.Core.SEND;
   *     request.removeListener('send', listener);
   *     request.on('send', function(response) {
   *       customHandler.send(response);
   *     });
   *   @return [Function]
   *   @readonly
   * @!attribute HTTP_DATA
   *   A request listener that reads data from the HTTP connection in order
   *   to build the response data.
   *   Handles the {AWS.Request~httpData 'httpData' Request event}.
   *   Remove this handler if you are overriding the 'httpData' event and
   *   do not want extra data processing and buffering overhead.
   *   @example Disabling default data processing
   *     var listener = AWS.EventListeners.Core.HTTP_DATA;
   *     request.removeListener('httpData', listener);
   *   @return [Function]
   *   @readonly
   */
  Core: {} /* doc hack */
};

/**
 * @api private
 */
function getOperationAuthtype(req) {
  if (!req.service.api.operations) {
    return '';
  }
  var operation = req.service.api.operations[req.operation];
  return operation ? operation.authtype : '';
}

AWS.EventListeners = {
  Core: new SequentialExecutor().addNamedListeners(function(add, addAsync) {
    addAsync('VALIDATE_CREDENTIALS', 'validate',
        function VALIDATE_CREDENTIALS(req, done) {
      if (!req.service.api.signatureVersion && !req.service.config.signatureVersion) return done(); // none
      req.service.config.getCredentials(function(err) {
        if (err) {
          req.response.error = AWS.util.error(err,
            {code: 'CredentialsError', message: 'Missing credentials in config, if using AWS_CONFIG_FILE, set AWS_SDK_LOAD_CONFIG=1'});
        }
        done();
      });
    });

    add('VALIDATE_REGION', 'validate', function VALIDATE_REGION(req) {
      if (!req.service.config.region && !req.service.isGlobalEndpoint) {
        req.response.error = AWS.util.error(new Error(),
          {code: 'ConfigError', message: 'Missing region in config'});
      }
    });

    add('BUILD_IDEMPOTENCY_TOKENS', 'validate', function BUILD_IDEMPOTENCY_TOKENS(req) {
      if (!req.service.api.operations) {
        return;
      }
      var operation = req.service.api.operations[req.operation];
      if (!operation) {
        return;
      }
      var idempotentMembers = operation.idempotentMembers;
      if (!idempotentMembers.length) {
        return;
      }
      // creates a copy of params so user's param object isn't mutated
      var params = AWS.util.copy(req.params);
      for (var i = 0, iLen = idempotentMembers.length; i < iLen; i++) {
        if (!params[idempotentMembers[i]]) {
          // add the member
          params[idempotentMembers[i]] = AWS.util.uuid.v4();
        }
      }
      req.params = params;
    });

    add('VALIDATE_PARAMETERS', 'validate', function VALIDATE_PARAMETERS(req) {
      if (!req.service.api.operations) {
        return;
      }
      var rules = req.service.api.operations[req.operation].input;
      var validation = req.service.config.paramValidation;
      new AWS.ParamValidator(validation).validate(rules, req.params);
    });

    addAsync('COMPUTE_SHA256', 'afterBuild', function COMPUTE_SHA256(req, done) {
      req.haltHandlersOnError();
      if (!req.service.api.operations) {
        return;
      }
      var operation = req.service.api.operations[req.operation];
      var authtype = operation ? operation.authtype : '';
      if (!req.service.api.signatureVersion && !authtype && !req.service.config.signatureVersion) return done(); // none
      if (req.service.getSignerClass(req) === AWS.Signers.V4) {
        var body = req.httpRequest.body || '';
        if (authtype.indexOf('unsigned-body') >= 0) {
          req.httpRequest.headers['X-Amz-Content-Sha256'] = 'UNSIGNED-PAYLOAD';
          return done();
        }
        AWS.util.computeSha256(body, function(err, sha) {
          if (err) {
            done(err);
          }
          else {
            req.httpRequest.headers['X-Amz-Content-Sha256'] = sha;
            done();
          }
        });
      } else {
        done();
      }
    });

    add('SET_CONTENT_LENGTH', 'afterBuild', function SET_CONTENT_LENGTH(req) {
      var authtype = getOperationAuthtype(req);
      var payloadMember = AWS.util.getRequestPayloadShape(req);
      if (req.httpRequest.headers['Content-Length'] === undefined) {
        try {
          var length = AWS.util.string.byteLength(req.httpRequest.body);
          req.httpRequest.headers['Content-Length'] = length;
        } catch (err) {
          if (payloadMember && payloadMember.isStreaming) {
            if (payloadMember.requiresLength) {
              //streaming payload requires length(s3, glacier)
              throw err;
            } else if (authtype.indexOf('unsigned-body') >= 0) {
              //unbounded streaming payload(lex, mediastore)
              req.httpRequest.headers['Transfer-Encoding'] = 'chunked';
              return;
            } else {
              throw err;
            }
          }
          throw err;
        }
      }
    });

    add('SET_HTTP_HOST', 'afterBuild', function SET_HTTP_HOST(req) {
      req.httpRequest.headers['Host'] = req.httpRequest.endpoint.host;
    });

    add('RESTART', 'restart', function RESTART() {
      var err = this.response.error;
      if (!err || !err.retryable) return;

      this.httpRequest = new AWS.HttpRequest(
        this.service.endpoint,
        this.service.region
      );

      if (this.response.retryCount < this.service.config.maxRetries) {
        this.response.retryCount++;
      } else {
        this.response.error = null;
      }
    });

    var addToHead = true;
    addAsync('DISCOVER_ENDPOINT', 'sign', DISCOVER_ENDPOINT, addToHead);

    addAsync('SIGN', 'sign', function SIGN(req, done) {
      var service = req.service;
      var operations = req.service.api.operations || {};
      var operation = operations[req.operation];
      var authtype = operation ? operation.authtype : '';
      if (!service.api.signatureVersion && !authtype && !service.config.signatureVersion) return done(); // none

      service.config.getCredentials(function (err, credentials) {
        if (err) {
          req.response.error = err;
          return done();
        }

        try {
          var date = service.getSkewCorrectedDate();
          var SignerClass = service.getSignerClass(req);
          var signer = new SignerClass(req.httpRequest,
            service.api.signingName || service.api.endpointPrefix,
            {
              signatureCache: service.config.signatureCache,
              operation: operation,
              signatureVersion: service.api.signatureVersion
            });
          signer.setServiceClientId(service._clientId);

          // clear old authorization headers
          delete req.httpRequest.headers['Authorization'];
          delete req.httpRequest.headers['Date'];
          delete req.httpRequest.headers['X-Amz-Date'];

          // add new authorization
          signer.addAuthorization(credentials, date);
          req.signedAt = date;
        } catch (e) {
          req.response.error = e;
        }
        done();
      });
    });

    add('VALIDATE_RESPONSE', 'validateResponse', function VALIDATE_RESPONSE(resp) {
      if (this.service.successfulResponse(resp, this)) {
        resp.data = {};
        resp.error = null;
      } else {
        resp.data = null;
        resp.error = AWS.util.error(new Error(),
          {code: 'UnknownError', message: 'An unknown error occurred.'});
      }
    });

    addAsync('SEND', 'send', function SEND(resp, done) {
      resp.httpResponse._abortCallback = done;
      resp.error = null;
      resp.data = null;

      function callback(httpResp) {
        resp.httpResponse.stream = httpResp;
        var stream = resp.request.httpRequest.stream;
        var service = resp.request.service;
        var api = service.api;
        var operationName = resp.request.operation;
        var operation = api.operations[operationName] || {};

        httpResp.on('headers', function onHeaders(statusCode, headers, statusMessage) {
          resp.request.emit(
            'httpHeaders',
            [statusCode, headers, resp, statusMessage]
          );

          if (!resp.httpResponse.streaming) {
            if (AWS.HttpClient.streamsApiVersion === 2) { // streams2 API check
              // if we detect event streams, we're going to have to
              // return the stream immediately
              if (operation.hasEventOutput && service.successfulResponse(resp)) {
                // skip reading the IncomingStream
                resp.request.emit('httpDone');
                done();
                return;
              }

              httpResp.on('readable', function onReadable() {
                var data = httpResp.read();
                if (data !== null) {
                  resp.request.emit('httpData', [data, resp]);
                }
              });
            } else { // legacy streams API
              httpResp.on('data', function onData(data) {
                resp.request.emit('httpData', [data, resp]);
              });
            }
          }
        });

        httpResp.on('end', function onEnd() {
          if (!stream || !stream.didCallback) {
            if (AWS.HttpClient.streamsApiVersion === 2 && (operation.hasEventOutput && service.successfulResponse(resp))) {
              // don't concatenate response chunks when streaming event stream data when response is successful
              return;
            }
            resp.request.emit('httpDone');
            done();
          }
        });
      }

      function progress(httpResp) {
        httpResp.on('sendProgress', function onSendProgress(value) {
          resp.request.emit('httpUploadProgress', [value, resp]);
        });

        httpResp.on('receiveProgress', function onReceiveProgress(value) {
          resp.request.emit('httpDownloadProgress', [value, resp]);
        });
      }

      function error(err) {
        if (err.code !== 'RequestAbortedError') {
          var errCode = err.code === 'TimeoutError' ? err.code : 'NetworkingError';
          err = AWS.util.error(err, {
            code: errCode,
            region: resp.request.httpRequest.region,
            hostname: resp.request.httpRequest.endpoint.hostname,
            retryable: true
          });
        }
        resp.error = err;
        resp.request.emit('httpError', [resp.error, resp], function() {
          done();
        });
      }

      function executeSend() {
        var http = AWS.HttpClient.getInstance();
        var httpOptions = resp.request.service.config.httpOptions || {};
        try {
          var stream = http.handleRequest(resp.request.httpRequest, httpOptions,
                                          callback, error);
          progress(stream);
        } catch (err) {
          error(err);
        }
      }
      var timeDiff = (resp.request.service.getSkewCorrectedDate() - this.signedAt) / 1000;
      if (timeDiff >= 60 * 10) { // if we signed 10min ago, re-sign
        this.emit('sign', [this], function(err) {
          if (err) done(err);
          else executeSend();
        });
      } else {
        executeSend();
      }
    });

    add('HTTP_HEADERS', 'httpHeaders',
        function HTTP_HEADERS(statusCode, headers, resp, statusMessage) {
      resp.httpResponse.statusCode = statusCode;
      resp.httpResponse.statusMessage = statusMessage;
      resp.httpResponse.headers = headers;
      resp.httpResponse.body = AWS.util.buffer.toBuffer('');
      resp.httpResponse.buffers = [];
      resp.httpResponse.numBytes = 0;
      var dateHeader = headers.date || headers.Date;
      var service = resp.request.service;
      if (dateHeader) {
        var serverTime = Date.parse(dateHeader);
        if (service.config.correctClockSkew
            && service.isClockSkewed(serverTime)) {
          service.applyClockOffset(serverTime);
        }
      }
    });

    add('HTTP_DATA', 'httpData', function HTTP_DATA(chunk, resp) {
      if (chunk) {
        if (AWS.util.isNode()) {
          resp.httpResponse.numBytes += chunk.length;

          var total = resp.httpResponse.headers['content-length'];
          var progress = { loaded: resp.httpResponse.numBytes, total: total };
          resp.request.emit('httpDownloadProgress', [progress, resp]);
        }

        resp.httpResponse.buffers.push(AWS.util.buffer.toBuffer(chunk));
      }
    });

    add('HTTP_DONE', 'httpDone', function HTTP_DONE(resp) {
      // convert buffers array into single buffer
      if (resp.httpResponse.buffers && resp.httpResponse.buffers.length > 0) {
        var body = AWS.util.buffer.concat(resp.httpResponse.buffers);
        resp.httpResponse.body = body;
      }
      delete resp.httpResponse.numBytes;
      delete resp.httpResponse.buffers;
    });

    add('FINALIZE_ERROR', 'retry', function FINALIZE_ERROR(resp) {
      if (resp.httpResponse.statusCode) {
        resp.error.statusCode = resp.httpResponse.statusCode;
        if (resp.error.retryable === undefined) {
          resp.error.retryable = this.service.retryableError(resp.error, this);
        }
      }
    });

    add('INVALIDATE_CREDENTIALS', 'retry', function INVALIDATE_CREDENTIALS(resp) {
      if (!resp.error) return;
      switch (resp.error.code) {
        case 'RequestExpired': // EC2 only
        case 'ExpiredTokenException':
        case 'ExpiredToken':
          resp.error.retryable = true;
          resp.request.service.config.credentials.expired = true;
      }
    });

    add('EXPIRED_SIGNATURE', 'retry', function EXPIRED_SIGNATURE(resp) {
      var err = resp.error;
      if (!err) return;
      if (typeof err.code === 'string' && typeof err.message === 'string') {
        if (err.code.match(/Signature/) && err.message.match(/expired/)) {
          resp.error.retryable = true;
        }
      }
    });

    add('CLOCK_SKEWED', 'retry', function CLOCK_SKEWED(resp) {
      if (!resp.error) return;
      if (this.service.clockSkewError(resp.error)
          && this.service.config.correctClockSkew) {
        resp.error.retryable = true;
      }
    });

    add('REDIRECT', 'retry', function REDIRECT(resp) {
      if (resp.error && resp.error.statusCode >= 300 &&
          resp.error.statusCode < 400 && resp.httpResponse.headers['location']) {
        this.httpRequest.endpoint =
          new AWS.Endpoint(resp.httpResponse.headers['location']);
        this.httpRequest.headers['Host'] = this.httpRequest.endpoint.host;
        resp.error.redirect = true;
        resp.error.retryable = true;
      }
    });

    add('RETRY_CHECK', 'retry', function RETRY_CHECK(resp) {
      if (resp.error) {
        if (resp.error.redirect && resp.redirectCount < resp.maxRedirects) {
          resp.error.retryDelay = 0;
        } else if (resp.retryCount < resp.maxRetries) {
          resp.error.retryDelay = this.service.retryDelays(resp.retryCount, resp.error) || 0;
        }
      }
    });

    addAsync('RESET_RETRY_STATE', 'afterRetry', function RESET_RETRY_STATE(resp, done) {
      var delay, willRetry = false;

      if (resp.error) {
        delay = resp.error.retryDelay || 0;
        if (resp.error.retryable && resp.retryCount < resp.maxRetries) {
          resp.retryCount++;
          willRetry = true;
        } else if (resp.error.redirect && resp.redirectCount < resp.maxRedirects) {
          resp.redirectCount++;
          willRetry = true;
        }
      }

      // delay < 0 is a signal from customBackoff to skip retries
      if (willRetry && delay >= 0) {
        resp.error = null;
        setTimeout(done, delay);
      } else {
        done();
      }
    });
  }),

  CorePost: new SequentialExecutor().addNamedListeners(function(add) {
    add('EXTRACT_REQUEST_ID', 'extractData', AWS.util.extractRequestId);
    add('EXTRACT_REQUEST_ID', 'extractError', AWS.util.extractRequestId);

    add('ENOTFOUND_ERROR', 'httpError', function ENOTFOUND_ERROR(err) {
      if (err.code === 'NetworkingError' && err.errno === 'ENOTFOUND') {
        var message = 'Inaccessible host: `' + err.hostname +
          '\'. This service may not be available in the `' + err.region +
          '\' region.';
        this.response.error = AWS.util.error(new Error(message), {
          code: 'UnknownEndpoint',
          region: err.region,
          hostname: err.hostname,
          retryable: true,
          originalError: err
        });
      }
    });
  }),

  Logger: new SequentialExecutor().addNamedListeners(function(add) {
    add('LOG_REQUEST', 'complete', function LOG_REQUEST(resp) {
      var req = resp.request;
      var logger = req.service.config.logger;
      if (!logger) return;
      function filterSensitiveLog(inputShape, shape) {
        if (!shape) {
          return shape;
        }
        switch (inputShape.type) {
          case 'structure':
            var struct = {};
            AWS.util.each(shape, function(subShapeName, subShape) {
              if (Object.prototype.hasOwnProperty.call(inputShape.members, subShapeName)) {
                struct[subShapeName] = filterSensitiveLog(inputShape.members[subShapeName], subShape);
              } else {
                struct[subShapeName] = subShape;
              }
            });
            return struct;
          case 'list':
            var list = [];
            AWS.util.arrayEach(shape, function(subShape, index) {
              list.push(filterSensitiveLog(inputShape.member, subShape));
            });
            return list;
          case 'map':
            var map = {};
            AWS.util.each(shape, function(key, value) {
              map[key] = filterSensitiveLog(inputShape.value, value);
            });
            return map;
          default:
            if (inputShape.isSensitive) {
              return '***SensitiveInformation***';
            } else {
              return shape;
            }
        }
      }

      function buildMessage() {
        var time = resp.request.service.getSkewCorrectedDate().getTime();
        var delta = (time - req.startTime.getTime()) / 1000;
        var ansi = logger.isTTY ? true : false;
        var status = resp.httpResponse.statusCode;
        var censoredParams = req.params;
        if (
          req.service.api.operations &&
              req.service.api.operations[req.operation] &&
              req.service.api.operations[req.operation].input
        ) {
          var inputShape = req.service.api.operations[req.operation].input;
          censoredParams = filterSensitiveLog(inputShape, req.params);
        }
        var params = require('util').inspect(censoredParams, true, null);
        var message = '';
        if (ansi) message += '\x1B[33m';
        message += '[AWS ' + req.service.serviceIdentifier + ' ' + status;
        message += ' ' + delta.toString() + 's ' + resp.retryCount + ' retries]';
        if (ansi) message += '\x1B[0;1m';
        message += ' ' + AWS.util.string.lowerFirst(req.operation);
        message += '(' + params + ')';
        if (ansi) message += '\x1B[0m';
        return message;
      }

      var line = buildMessage();
      if (typeof logger.log === 'function') {
        logger.log(line);
      } else if (typeof logger.write === 'function') {
        logger.write(line + '\n');
      }
    });
  }),

  Json: new SequentialExecutor().addNamedListeners(function(add) {
    var svc = require('./protocol/json');
    add('BUILD', 'build', svc.buildRequest);
    add('EXTRACT_DATA', 'extractData', svc.extractData);
    add('EXTRACT_ERROR', 'extractError', svc.extractError);
  }),

  Rest: new SequentialExecutor().addNamedListeners(function(add) {
    var svc = require('./protocol/rest');
    add('BUILD', 'build', svc.buildRequest);
    add('EXTRACT_DATA', 'extractData', svc.extractData);
    add('EXTRACT_ERROR', 'extractError', svc.extractError);
  }),

  RestJson: new SequentialExecutor().addNamedListeners(function(add) {
    var svc = require('./protocol/rest_json');
    add('BUILD', 'build', svc.buildRequest);
    add('EXTRACT_DATA', 'extractData', svc.extractData);
    add('EXTRACT_ERROR', 'extractError', svc.extractError);
  }),

  RestXml: new SequentialExecutor().addNamedListeners(function(add) {
    var svc = require('./protocol/rest_xml');
    add('BUILD', 'build', svc.buildRequest);
    add('EXTRACT_DATA', 'extractData', svc.extractData);
    add('EXTRACT_ERROR', 'extractError', svc.extractError);
  }),

  Query: new SequentialExecutor().addNamedListeners(function(add) {
    var svc = require('./protocol/query');
    add('BUILD', 'build', svc.buildRequest);
    add('EXTRACT_DATA', 'extractData', svc.extractData);
    add('EXTRACT_ERROR', 'extractError', svc.extractError);
  })
};

},{"./core":324,"./discover_endpoint":332,"./protocol/json":359,"./protocol/query":360,"./protocol/rest":361,"./protocol/rest_json":362,"./protocol/rest_xml":363,"./sequential_executor":373,"util":424}],346:[function(require,module,exports){
var AWS = require('./core');
var inherit = AWS.util.inherit;

/**
 * The endpoint that a service will talk to, for example,
 * `'https://ec2.ap-southeast-1.amazonaws.com'`. If
 * you need to override an endpoint for a service, you can
 * set the endpoint on a service by passing the endpoint
 * object with the `endpoint` option key:
 *
 * ```javascript
 * var ep = new AWS.Endpoint('awsproxy.example.com');
 * var s3 = new AWS.S3({endpoint: ep});
 * s3.service.endpoint.hostname == 'awsproxy.example.com'
 * ```
 *
 * Note that if you do not specify a protocol, the protocol will
 * be selected based on your current {AWS.config} configuration.
 *
 * @!attribute protocol
 *   @return [String] the protocol (http or https) of the endpoint
 *     URL
 * @!attribute hostname
 *   @return [String] the host portion of the endpoint, e.g.,
 *     example.com
 * @!attribute host
 *   @return [String] the host portion of the endpoint including
 *     the port, e.g., example.com:80
 * @!attribute port
 *   @return [Integer] the port of the endpoint
 * @!attribute href
 *   @return [String] the full URL of the endpoint
 */
AWS.Endpoint = inherit({

  /**
   * @overload Endpoint(endpoint)
   *   Constructs a new endpoint given an endpoint URL. If the
   *   URL omits a protocol (http or https), the default protocol
   *   set in the global {AWS.config} will be used.
   *   @param endpoint [String] the URL to construct an endpoint from
   */
  constructor: function Endpoint(endpoint, config) {
    AWS.util.hideProperties(this, ['slashes', 'auth', 'hash', 'search', 'query']);

    if (typeof endpoint === 'undefined' || endpoint === null) {
      throw new Error('Invalid endpoint: ' + endpoint);
    } else if (typeof endpoint !== 'string') {
      return AWS.util.copy(endpoint);
    }

    if (!endpoint.match(/^http/)) {
      var useSSL = config && config.sslEnabled !== undefined ?
        config.sslEnabled : AWS.config.sslEnabled;
      endpoint = (useSSL ? 'https' : 'http') + '://' + endpoint;
    }

    AWS.util.update(this, AWS.util.urlParse(endpoint));

    // Ensure the port property is set as an integer
    if (this.port) {
      this.port = parseInt(this.port, 10);
    } else {
      this.port = this.protocol === 'https:' ? 443 : 80;
    }
  }

});

/**
 * The low level HTTP request object, encapsulating all HTTP header
 * and body data sent by a service request.
 *
 * @!attribute method
 *   @return [String] the HTTP method of the request
 * @!attribute path
 *   @return [String] the path portion of the URI, e.g.,
 *     "/list/?start=5&num=10"
 * @!attribute headers
 *   @return [map<String,String>]
 *     a map of header keys and their respective values
 * @!attribute body
 *   @return [String] the request body payload
 * @!attribute endpoint
 *   @return [AWS.Endpoint] the endpoint for the request
 * @!attribute region
 *   @api private
 *   @return [String] the region, for signing purposes only.
 */
AWS.HttpRequest = inherit({

  /**
   * @api private
   */
  constructor: function HttpRequest(endpoint, region) {
    endpoint = new AWS.Endpoint(endpoint);
    this.method = 'POST';
    this.path = endpoint.path || '/';
    this.headers = {};
    this.body = '';
    this.endpoint = endpoint;
    this.region = region;
    this._userAgent = '';
    this.setUserAgent();
  },

  /**
   * @api private
   */
  setUserAgent: function setUserAgent() {
    this._userAgent = this.headers[this.getUserAgentHeaderName()] = AWS.util.userAgent();
  },

  getUserAgentHeaderName: function getUserAgentHeaderName() {
    var prefix = AWS.util.isBrowser() ? 'X-Amz-' : '';
    return prefix + 'User-Agent';
  },

  /**
   * @api private
   */
  appendToUserAgent: function appendToUserAgent(agentPartial) {
    if (typeof agentPartial === 'string' && agentPartial) {
      this._userAgent += ' ' + agentPartial;
    }
    this.headers[this.getUserAgentHeaderName()] = this._userAgent;
  },

  /**
   * @api private
   */
  getUserAgent: function getUserAgent() {
    return this._userAgent;
  },

  /**
   * @return [String] the part of the {path} excluding the
   *   query string
   */
  pathname: function pathname() {
    return this.path.split('?', 1)[0];
  },

  /**
   * @return [String] the query string portion of the {path}
   */
  search: function search() {
    var query = this.path.split('?', 2)[1];
    if (query) {
      query = AWS.util.queryStringParse(query);
      return AWS.util.queryParamsToString(query);
    }
    return '';
  },

  /**
   * @api private
   * update httpRequest endpoint with endpoint string
   */
  updateEndpoint: function updateEndpoint(endpointStr) {
    var newEndpoint = new AWS.Endpoint(endpointStr);
    this.endpoint = newEndpoint;
    this.path = newEndpoint.path || '/';
    if (this.headers['Host']) {
      this.headers['Host'] = newEndpoint.host;
    }
  }
});

/**
 * The low level HTTP response object, encapsulating all HTTP header
 * and body data returned from the request.
 *
 * @!attribute statusCode
 *   @return [Integer] the HTTP status code of the response (e.g., 200, 404)
 * @!attribute headers
 *   @return [map<String,String>]
 *      a map of response header keys and their respective values
 * @!attribute body
 *   @return [String] the response body payload
 * @!attribute [r] streaming
 *   @return [Boolean] whether this response is being streamed at a low-level.
 *     Defaults to `false` (buffered reads). Do not modify this manually, use
 *     {createUnbufferedStream} to convert the stream to unbuffered mode
 *     instead.
 */
AWS.HttpResponse = inherit({

  /**
   * @api private
   */
  constructor: function HttpResponse() {
    this.statusCode = undefined;
    this.headers = {};
    this.body = undefined;
    this.streaming = false;
    this.stream = null;
  },

  /**
   * Disables buffering on the HTTP response and returns the stream for reading.
   * @return [Stream, XMLHttpRequest, null] the underlying stream object.
   *   Use this object to directly read data off of the stream.
   * @note This object is only available after the {AWS.Request~httpHeaders}
   *   event has fired. This method must be called prior to
   *   {AWS.Request~httpData}.
   * @example Taking control of a stream
   *   request.on('httpHeaders', function(statusCode, headers) {
   *     if (statusCode < 300) {
   *       if (headers.etag === 'xyz') {
   *         // pipe the stream, disabling buffering
   *         var stream = this.response.httpResponse.createUnbufferedStream();
   *         stream.pipe(process.stdout);
   *       } else { // abort this request and set a better error message
   *         this.abort();
   *         this.response.error = new Error('Invalid ETag');
   *       }
   *     }
   *   }).send(console.log);
   */
  createUnbufferedStream: function createUnbufferedStream() {
    this.streaming = true;
    return this.stream;
  }
});


AWS.HttpClient = inherit({});

/**
 * @api private
 */
AWS.HttpClient.getInstance = function getInstance() {
  if (this.singleton === undefined) {
    this.singleton = new this();
  }
  return this.singleton;
};

},{"./core":324}],347:[function(require,module,exports){
var AWS = require('../core');
var EventEmitter = require('events').EventEmitter;
require('../http');

/**
 * @api private
 */
AWS.XHRClient = AWS.util.inherit({
  handleRequest: function handleRequest(httpRequest, httpOptions, callback, errCallback) {
    var self = this;
    var endpoint = httpRequest.endpoint;
    var emitter = new EventEmitter();
    var href = endpoint.protocol + '//' + endpoint.hostname;
    if (endpoint.port !== 80 && endpoint.port !== 443) {
      href += ':' + endpoint.port;
    }
    href += httpRequest.path;

    var xhr = new XMLHttpRequest(), headersEmitted = false;
    httpRequest.stream = xhr;

    xhr.addEventListener('readystatechange', function() {
      try {
        if (xhr.status === 0) return; // 0 code is invalid
      } catch (e) { return; }

      if (this.readyState >= this.HEADERS_RECEIVED && !headersEmitted) {
        emitter.statusCode = xhr.status;
        emitter.headers = self.parseHeaders(xhr.getAllResponseHeaders());
        emitter.emit(
          'headers',
          emitter.statusCode,
          emitter.headers,
          xhr.statusText
        );
        headersEmitted = true;
      }
      if (this.readyState === this.DONE) {
        self.finishRequest(xhr, emitter);
      }
    }, false);
    xhr.upload.addEventListener('progress', function (evt) {
      emitter.emit('sendProgress', evt);
    });
    xhr.addEventListener('progress', function (evt) {
      emitter.emit('receiveProgress', evt);
    }, false);
    xhr.addEventListener('timeout', function () {
      errCallback(AWS.util.error(new Error('Timeout'), {code: 'TimeoutError'}));
    }, false);
    xhr.addEventListener('error', function () {
      errCallback(AWS.util.error(new Error('Network Failure'), {
        code: 'NetworkingError'
      }));
    }, false);
    xhr.addEventListener('abort', function () {
      errCallback(AWS.util.error(new Error('Request aborted'), {
        code: 'RequestAbortedError'
      }));
    }, false);

    callback(emitter);
    xhr.open(httpRequest.method, href, httpOptions.xhrAsync !== false);
    AWS.util.each(httpRequest.headers, function (key, value) {
      if (key !== 'Content-Length' && key !== 'User-Agent' && key !== 'Host') {
        xhr.setRequestHeader(key, value);
      }
    });

    if (httpOptions.timeout && httpOptions.xhrAsync !== false) {
      xhr.timeout = httpOptions.timeout;
    }

    if (httpOptions.xhrWithCredentials) {
      xhr.withCredentials = true;
    }
    try { xhr.responseType = 'arraybuffer'; } catch (e) {}

    try {
      if (httpRequest.body) {
        xhr.send(httpRequest.body);
      } else {
        xhr.send();
      }
    } catch (err) {
      if (httpRequest.body && typeof httpRequest.body.buffer === 'object') {
        xhr.send(httpRequest.body.buffer); // send ArrayBuffer directly
      } else {
        throw err;
      }
    }

    return emitter;
  },

  parseHeaders: function parseHeaders(rawHeaders) {
    var headers = {};
    AWS.util.arrayEach(rawHeaders.split(/\r?\n/), function (line) {
      var key = line.split(':', 1)[0];
      var value = line.substring(key.length + 2);
      if (key.length > 0) headers[key.toLowerCase()] = value;
    });
    return headers;
  },

  finishRequest: function finishRequest(xhr, emitter) {
    var buffer;
    if (xhr.responseType === 'arraybuffer' && xhr.response) {
      var ab = xhr.response;
      buffer = new AWS.util.Buffer(ab.byteLength);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buffer.length; ++i) {
        buffer[i] = view[i];
      }
    }

    try {
      if (!buffer && typeof xhr.responseText === 'string') {
        buffer = new AWS.util.Buffer(xhr.responseText);
      }
    } catch (e) {}

    if (buffer) emitter.emit('data', buffer);
    emitter.emit('end');
  }
});

/**
 * @api private
 */
AWS.HttpClient.prototype = AWS.XHRClient.prototype;

/**
 * @api private
 */
AWS.HttpClient.streamsApiVersion = 1;

},{"../core":324,"../http":346,"events":408}],348:[function(require,module,exports){
var util = require('../util');

function JsonBuilder() { }

JsonBuilder.prototype.build = function(value, shape) {
  return JSON.stringify(translate(value, shape));
};

function translate(value, shape) {
  if (!shape || value === undefined || value === null) return undefined;

  switch (shape.type) {
    case 'structure': return translateStructure(value, shape);
    case 'map': return translateMap(value, shape);
    case 'list': return translateList(value, shape);
    default: return translateScalar(value, shape);
  }
}

function translateStructure(structure, shape) {
  var struct = {};
  util.each(structure, function(name, value) {
    var memberShape = shape.members[name];
    if (memberShape) {
      if (memberShape.location !== 'body') return;
      var locationName = memberShape.isLocationName ? memberShape.name : name;
      var result = translate(value, memberShape);
      if (result !== undefined) struct[locationName] = result;
    }
  });
  return struct;
}

function translateList(list, shape) {
  var out = [];
  util.arrayEach(list, function(value) {
    var result = translate(value, shape.member);
    if (result !== undefined) out.push(result);
  });
  return out;
}

function translateMap(map, shape) {
  var out = {};
  util.each(map, function(key, value) {
    var result = translate(value, shape.value);
    if (result !== undefined) out[key] = result;
  });
  return out;
}

function translateScalar(value, shape) {
  return shape.toWireFormat(value);
}

/**
 * @api private
 */
module.exports = JsonBuilder;

},{"../util":398}],349:[function(require,module,exports){
var util = require('../util');

function JsonParser() { }

JsonParser.prototype.parse = function(value, shape) {
  return translate(JSON.parse(value), shape);
};

function translate(value, shape) {
  if (!shape || value === undefined) return undefined;

  switch (shape.type) {
    case 'structure': return translateStructure(value, shape);
    case 'map': return translateMap(value, shape);
    case 'list': return translateList(value, shape);
    default: return translateScalar(value, shape);
  }
}

function translateStructure(structure, shape) {
  if (structure == null) return undefined;

  var struct = {};
  var shapeMembers = shape.members;
  util.each(shapeMembers, function(name, memberShape) {
    var locationName = memberShape.isLocationName ? memberShape.name : name;
    if (Object.prototype.hasOwnProperty.call(structure, locationName)) {
      var value = structure[locationName];
      var result = translate(value, memberShape);
      if (result !== undefined) struct[name] = result;
    }
  });
  return struct;
}

function translateList(list, shape) {
  if (list == null) return undefined;

  var out = [];
  util.arrayEach(list, function(value) {
    var result = translate(value, shape.member);
    if (result === undefined) out.push(null);
    else out.push(result);
  });
  return out;
}

function translateMap(map, shape) {
  if (map == null) return undefined;

  var out = {};
  util.each(map, function(key, value) {
    var result = translate(value, shape.value);
    if (result === undefined) out[key] = null;
    else out[key] = result;
  });
  return out;
}

function translateScalar(value, shape) {
  return shape.toType(value);
}

/**
 * @api private
 */
module.exports = JsonParser;

},{"../util":398}],350:[function(require,module,exports){
var Collection = require('./collection');
var Operation = require('./operation');
var Shape = require('./shape');
var Paginator = require('./paginator');
var ResourceWaiter = require('./resource_waiter');
var metadata = require('../../apis/metadata.json');

var util = require('../util');
var property = util.property;
var memoizedProperty = util.memoizedProperty;

function Api(api, options) {
  var self = this;
  api = api || {};
  options = options || {};
  options.api = this;

  api.metadata = api.metadata || {};

  var serviceIdentifier = options.serviceIdentifier;
  delete options.serviceIdentifier;

  property(this, 'isApi', true, false);
  property(this, 'apiVersion', api.metadata.apiVersion);
  property(this, 'endpointPrefix', api.metadata.endpointPrefix);
  property(this, 'signingName', api.metadata.signingName);
  property(this, 'globalEndpoint', api.metadata.globalEndpoint);
  property(this, 'signatureVersion', api.metadata.signatureVersion);
  property(this, 'jsonVersion', api.metadata.jsonVersion);
  property(this, 'targetPrefix', api.metadata.targetPrefix);
  property(this, 'protocol', api.metadata.protocol);
  property(this, 'timestampFormat', api.metadata.timestampFormat);
  property(this, 'xmlNamespaceUri', api.metadata.xmlNamespace);
  property(this, 'abbreviation', api.metadata.serviceAbbreviation);
  property(this, 'fullName', api.metadata.serviceFullName);
  property(this, 'serviceId', api.metadata.serviceId);
  if (serviceIdentifier && metadata[serviceIdentifier]) {
      property(this, 'xmlNoDefaultLists', metadata[serviceIdentifier].xmlNoDefaultLists, false);
  }

  memoizedProperty(this, 'className', function() {
    var name = api.metadata.serviceAbbreviation || api.metadata.serviceFullName;
    if (!name) return null;

    name = name.replace(/^Amazon|AWS\s*|\(.*|\s+|\W+/g, '');
    if (name === 'ElasticLoadBalancing') name = 'ELB';
    return name;
  });

  function addEndpointOperation(name, operation) {
    if (operation.endpointoperation === true) {
      property(self, 'endpointOperation', util.string.lowerFirst(name));
    }
    if (operation.endpointdiscovery && !self.hasRequiredEndpointDiscovery) {
      property(
        self,
        'hasRequiredEndpointDiscovery',
        operation.endpointdiscovery.required === true
      );
    }
  }

  property(this, 'operations', new Collection(api.operations, options, function(name, operation) {
    return new Operation(name, operation, options);
  }, util.string.lowerFirst, addEndpointOperation));

  property(this, 'shapes', new Collection(api.shapes, options, function(name, shape) {
    return Shape.create(shape, options);
  }));

  property(this, 'paginators', new Collection(api.paginators, options, function(name, paginator) {
    return new Paginator(name, paginator, options);
  }));

  property(this, 'waiters', new Collection(api.waiters, options, function(name, waiter) {
    return new ResourceWaiter(name, waiter, options);
  }, util.string.lowerFirst));

  if (options.documentation) {
    property(this, 'documentation', api.documentation);
    property(this, 'documentationUrl', api.documentationUrl);
  }
}

/**
 * @api private
 */
module.exports = Api;

},{"../../apis/metadata.json":152,"../util":398,"./collection":351,"./operation":352,"./paginator":353,"./resource_waiter":354,"./shape":355}],351:[function(require,module,exports){
var memoizedProperty = require('../util').memoizedProperty;

function memoize(name, value, factory, nameTr) {
  memoizedProperty(this, nameTr(name), function() {
    return factory(name, value);
  });
}

function Collection(iterable, options, factory, nameTr, callback) {
  nameTr = nameTr || String;
  var self = this;

  for (var id in iterable) {
    if (Object.prototype.hasOwnProperty.call(iterable, id)) {
      memoize.call(self, id, iterable[id], factory, nameTr);
      if (callback) callback(id, iterable[id]);
    }
  }
}

/**
 * @api private
 */
module.exports = Collection;

},{"../util":398}],352:[function(require,module,exports){
var Shape = require('./shape');

var util = require('../util');
var property = util.property;
var memoizedProperty = util.memoizedProperty;

function Operation(name, operation, options) {
  var self = this;
  options = options || {};

  property(this, 'name', operation.name || name);
  property(this, 'api', options.api, false);

  operation.http = operation.http || {};
  property(this, 'endpoint', operation.endpoint);
  property(this, 'httpMethod', operation.http.method || 'POST');
  property(this, 'httpPath', operation.http.requestUri || '/');
  property(this, 'authtype', operation.authtype || '');
  property(
    this,
    'endpointDiscoveryRequired',
    operation.endpointdiscovery ?
      (operation.endpointdiscovery.required ? 'REQUIRED' : 'OPTIONAL') :
    'NULL'
  );

  memoizedProperty(this, 'input', function() {
    if (!operation.input) {
      return new Shape.create({type: 'structure'}, options);
    }
    return Shape.create(operation.input, options);
  });

  memoizedProperty(this, 'output', function() {
    if (!operation.output) {
      return new Shape.create({type: 'structure'}, options);
    }
    return Shape.create(operation.output, options);
  });

  memoizedProperty(this, 'errors', function() {
    var list = [];
    if (!operation.errors) return null;

    for (var i = 0; i < operation.errors.length; i++) {
      list.push(Shape.create(operation.errors[i], options));
    }

    return list;
  });

  memoizedProperty(this, 'paginator', function() {
    return options.api.paginators[name];
  });

  if (options.documentation) {
    property(this, 'documentation', operation.documentation);
    property(this, 'documentationUrl', operation.documentationUrl);
  }

  // idempotentMembers only tracks top-level input shapes
  memoizedProperty(this, 'idempotentMembers', function() {
    var idempotentMembers = [];
    var input = self.input;
    var members = input.members;
    if (!input.members) {
      return idempotentMembers;
    }
    for (var name in members) {
      if (!members.hasOwnProperty(name)) {
        continue;
      }
      if (members[name].isIdempotent === true) {
        idempotentMembers.push(name);
      }
    }
    return idempotentMembers;
  });

  memoizedProperty(this, 'hasEventOutput', function() {
    var output = self.output;
    return hasEventStream(output);
  });
}

function hasEventStream(topLevelShape) {
  var members = topLevelShape.members;
  var payload = topLevelShape.payload;

  if (!topLevelShape.members) {
    return false;
  }

  if (payload) {
    var payloadMember = members[payload];
    return payloadMember.isEventStream;
  }

  // check if any member is an event stream
  for (var name in members) {
    if (!members.hasOwnProperty(name)) {
      if (members[name].isEventStream === true) {
        return true;
      }
    }
  }
  return false;
}

/**
 * @api private
 */
module.exports = Operation;

},{"../util":398,"./shape":355}],353:[function(require,module,exports){
var property = require('../util').property;

function Paginator(name, paginator) {
  property(this, 'inputToken', paginator.input_token);
  property(this, 'limitKey', paginator.limit_key);
  property(this, 'moreResults', paginator.more_results);
  property(this, 'outputToken', paginator.output_token);
  property(this, 'resultKey', paginator.result_key);
}

/**
 * @api private
 */
module.exports = Paginator;

},{"../util":398}],354:[function(require,module,exports){
var util = require('../util');
var property = util.property;

function ResourceWaiter(name, waiter, options) {
  options = options || {};
  property(this, 'name', name);
  property(this, 'api', options.api, false);

  if (waiter.operation) {
    property(this, 'operation', util.string.lowerFirst(waiter.operation));
  }

  var self = this;
  var keys = [
    'type',
    'description',
    'delay',
    'maxAttempts',
    'acceptors'
  ];

  keys.forEach(function(key) {
    var value = waiter[key];
    if (value) {
      property(self, key, value);
    }
  });
}

/**
 * @api private
 */
module.exports = ResourceWaiter;

},{"../util":398}],355:[function(require,module,exports){
var Collection = require('./collection');

var util = require('../util');

function property(obj, name, value) {
  if (value !== null && value !== undefined) {
    util.property.apply(this, arguments);
  }
}

function memoizedProperty(obj, name) {
  if (!obj.constructor.prototype[name]) {
    util.memoizedProperty.apply(this, arguments);
  }
}

function Shape(shape, options, memberName) {
  options = options || {};

  property(this, 'shape', shape.shape);
  property(this, 'api', options.api, false);
  property(this, 'type', shape.type);
  property(this, 'enum', shape.enum);
  property(this, 'min', shape.min);
  property(this, 'max', shape.max);
  property(this, 'pattern', shape.pattern);
  property(this, 'location', shape.location || this.location || 'body');
  property(this, 'name', this.name || shape.xmlName || shape.queryName ||
    shape.locationName || memberName);
  property(this, 'isStreaming', shape.streaming || this.isStreaming || false);
  property(this, 'requiresLength', shape.requiresLength, false);
  property(this, 'isComposite', shape.isComposite || false);
  property(this, 'isShape', true, false);
  property(this, 'isQueryName', Boolean(shape.queryName), false);
  property(this, 'isLocationName', Boolean(shape.locationName), false);
  property(this, 'isIdempotent', shape.idempotencyToken === true);
  property(this, 'isJsonValue', shape.jsonvalue === true);
  property(this, 'isSensitive', shape.sensitive === true || shape.prototype && shape.prototype.sensitive === true);
  property(this, 'isEventStream', Boolean(shape.eventstream), false);
  property(this, 'isEvent', Boolean(shape.event), false);
  property(this, 'isEventPayload', Boolean(shape.eventpayload), false);
  property(this, 'isEventHeader', Boolean(shape.eventheader), false);
  property(this, 'isTimestampFormatSet', Boolean(shape.timestampFormat) || shape.prototype && shape.prototype.isTimestampFormatSet === true, false);
  property(this, 'endpointDiscoveryId', Boolean(shape.endpointdiscoveryid), false);
  property(this, 'hostLabel', Boolean(shape.hostLabel), false);

  if (options.documentation) {
    property(this, 'documentation', shape.documentation);
    property(this, 'documentationUrl', shape.documentationUrl);
  }

  if (shape.xmlAttribute) {
    property(this, 'isXmlAttribute', shape.xmlAttribute || false);
  }

  // type conversion and parsing
  property(this, 'defaultValue', null);
  this.toWireFormat = function(value) {
    if (value === null || value === undefined) return '';
    return value;
  };
  this.toType = function(value) { return value; };
}

/**
 * @api private
 */
Shape.normalizedTypes = {
  character: 'string',
  double: 'float',
  long: 'integer',
  short: 'integer',
  biginteger: 'integer',
  bigdecimal: 'float',
  blob: 'binary'
};

/**
 * @api private
 */
Shape.types = {
  'structure': StructureShape,
  'list': ListShape,
  'map': MapShape,
  'boolean': BooleanShape,
  'timestamp': TimestampShape,
  'float': FloatShape,
  'integer': IntegerShape,
  'string': StringShape,
  'base64': Base64Shape,
  'binary': BinaryShape
};

Shape.resolve = function resolve(shape, options) {
  if (shape.shape) {
    var refShape = options.api.shapes[shape.shape];
    if (!refShape) {
      throw new Error('Cannot find shape reference: ' + shape.shape);
    }

    return refShape;
  } else {
    return null;
  }
};

Shape.create = function create(shape, options, memberName) {
  if (shape.isShape) return shape;

  var refShape = Shape.resolve(shape, options);
  if (refShape) {
    var filteredKeys = Object.keys(shape);
    if (!options.documentation) {
      filteredKeys = filteredKeys.filter(function(name) {
        return !name.match(/documentation/);
      });
    }

    // create an inline shape with extra members
    var InlineShape = function() {
      refShape.constructor.call(this, shape, options, memberName);
    };
    InlineShape.prototype = refShape;
    return new InlineShape();
  } else {
    // set type if not set
    if (!shape.type) {
      if (shape.members) shape.type = 'structure';
      else if (shape.member) shape.type = 'list';
      else if (shape.key) shape.type = 'map';
      else shape.type = 'string';
    }

    // normalize types
    var origType = shape.type;
    if (Shape.normalizedTypes[shape.type]) {
      shape.type = Shape.normalizedTypes[shape.type];
    }

    if (Shape.types[shape.type]) {
      return new Shape.types[shape.type](shape, options, memberName);
    } else {
      throw new Error('Unrecognized shape type: ' + origType);
    }
  }
};

function CompositeShape(shape) {
  Shape.apply(this, arguments);
  property(this, 'isComposite', true);

  if (shape.flattened) {
    property(this, 'flattened', shape.flattened || false);
  }
}

function StructureShape(shape, options) {
  var self = this;
  var requiredMap = null, firstInit = !this.isShape;

  CompositeShape.apply(this, arguments);

  if (firstInit) {
    property(this, 'defaultValue', function() { return {}; });
    property(this, 'members', {});
    property(this, 'memberNames', []);
    property(this, 'required', []);
    property(this, 'isRequired', function() { return false; });
  }

  if (shape.members) {
    property(this, 'members', new Collection(shape.members, options, function(name, member) {
      return Shape.create(member, options, name);
    }));
    memoizedProperty(this, 'memberNames', function() {
      return shape.xmlOrder || Object.keys(shape.members);
    });

    if (shape.event) {
      memoizedProperty(this, 'eventPayloadMemberName', function() {
        var members = self.members;
        var memberNames = self.memberNames;
        // iterate over members to find ones that are event payloads
        for (var i = 0, iLen = memberNames.length; i < iLen; i++) {
          if (members[memberNames[i]].isEventPayload) {
            return memberNames[i];
          }
        }
      });

      memoizedProperty(this, 'eventHeaderMemberNames', function() {
        var members = self.members;
        var memberNames = self.memberNames;
        var eventHeaderMemberNames = [];
        // iterate over members to find ones that are event headers
        for (var i = 0, iLen = memberNames.length; i < iLen; i++) {
          if (members[memberNames[i]].isEventHeader) {
            eventHeaderMemberNames.push(memberNames[i]);
          }
        }
        return eventHeaderMemberNames;
      });
    }
  }

  if (shape.required) {
    property(this, 'required', shape.required);
    property(this, 'isRequired', function(name) {
      if (!requiredMap) {
        requiredMap = {};
        for (var i = 0; i < shape.required.length; i++) {
          requiredMap[shape.required[i]] = true;
        }
      }

      return requiredMap[name];
    }, false, true);
  }

  property(this, 'resultWrapper', shape.resultWrapper || null);

  if (shape.payload) {
    property(this, 'payload', shape.payload);
  }

  if (typeof shape.xmlNamespace === 'string') {
    property(this, 'xmlNamespaceUri', shape.xmlNamespace);
  } else if (typeof shape.xmlNamespace === 'object') {
    property(this, 'xmlNamespacePrefix', shape.xmlNamespace.prefix);
    property(this, 'xmlNamespaceUri', shape.xmlNamespace.uri);
  }
}

function ListShape(shape, options) {
  var self = this, firstInit = !this.isShape;
  CompositeShape.apply(this, arguments);

  if (firstInit) {
    property(this, 'defaultValue', function() { return []; });
  }

  if (shape.member) {
    memoizedProperty(this, 'member', function() {
      return Shape.create(shape.member, options);
    });
  }

  if (this.flattened) {
    var oldName = this.name;
    memoizedProperty(this, 'name', function() {
      return self.member.name || oldName;
    });
  }
}

function MapShape(shape, options) {
  var firstInit = !this.isShape;
  CompositeShape.apply(this, arguments);

  if (firstInit) {
    property(this, 'defaultValue', function() { return {}; });
    property(this, 'key', Shape.create({type: 'string'}, options));
    property(this, 'value', Shape.create({type: 'string'}, options));
  }

  if (shape.key) {
    memoizedProperty(this, 'key', function() {
      return Shape.create(shape.key, options);
    });
  }
  if (shape.value) {
    memoizedProperty(this, 'value', function() {
      return Shape.create(shape.value, options);
    });
  }
}

function TimestampShape(shape) {
  var self = this;
  Shape.apply(this, arguments);

  if (shape.timestampFormat) {
    property(this, 'timestampFormat', shape.timestampFormat);
  } else if (self.isTimestampFormatSet && this.timestampFormat) {
    property(this, 'timestampFormat', this.timestampFormat);
  } else if (this.location === 'header') {
    property(this, 'timestampFormat', 'rfc822');
  } else if (this.location === 'querystring') {
    property(this, 'timestampFormat', 'iso8601');
  } else if (this.api) {
    switch (this.api.protocol) {
      case 'json':
      case 'rest-json':
        property(this, 'timestampFormat', 'unixTimestamp');
        break;
      case 'rest-xml':
      case 'query':
      case 'ec2':
        property(this, 'timestampFormat', 'iso8601');
        break;
    }
  }

  this.toType = function(value) {
    if (value === null || value === undefined) return null;
    if (typeof value.toUTCString === 'function') return value;
    return typeof value === 'string' || typeof value === 'number' ?
           util.date.parseTimestamp(value) : null;
  };

  this.toWireFormat = function(value) {
    return util.date.format(value, self.timestampFormat);
  };
}

function StringShape() {
  Shape.apply(this, arguments);

  var nullLessProtocols = ['rest-xml', 'query', 'ec2'];
  this.toType = function(value) {
    value = this.api && nullLessProtocols.indexOf(this.api.protocol) > -1 ?
      value || '' : value;
    if (this.isJsonValue) {
      return JSON.parse(value);
    }

    return value && typeof value.toString === 'function' ?
      value.toString() : value;
  };

  this.toWireFormat = function(value) {
    return this.isJsonValue ? JSON.stringify(value) : value;
  };
}

function FloatShape() {
  Shape.apply(this, arguments);

  this.toType = function(value) {
    if (value === null || value === undefined) return null;
    return parseFloat(value);
  };
  this.toWireFormat = this.toType;
}

function IntegerShape() {
  Shape.apply(this, arguments);

  this.toType = function(value) {
    if (value === null || value === undefined) return null;
    return parseInt(value, 10);
  };
  this.toWireFormat = this.toType;
}

function BinaryShape() {
  Shape.apply(this, arguments);
  this.toType = function(value) {
    var buf = util.base64.decode(value);
    if (this.isSensitive && util.isNode() && typeof util.Buffer.alloc === 'function') {
  /* Node.js can create a Buffer that is not isolated.
   * i.e. buf.byteLength !== buf.buffer.byteLength
   * This means that the sensitive data is accessible to anyone with access to buf.buffer.
   * If this is the node shared Buffer, then other code within this process _could_ find this secret.
   * Copy sensitive data to an isolated Buffer and zero the sensitive data.
   * While this is safe to do here, copying this code somewhere else may produce unexpected results.
   */
      var secureBuf = util.Buffer.alloc(buf.length, buf);
      buf.fill(0);
      buf = secureBuf;
    }
    return buf;
  };
  this.toWireFormat = util.base64.encode;
}

function Base64Shape() {
  BinaryShape.apply(this, arguments);
}

function BooleanShape() {
  Shape.apply(this, arguments);

  this.toType = function(value) {
    if (typeof value === 'boolean') return value;
    if (value === null || value === undefined) return null;
    return value === 'true';
  };
}

/**
 * @api private
 */
Shape.shapes = {
  StructureShape: StructureShape,
  ListShape: ListShape,
  MapShape: MapShape,
  StringShape: StringShape,
  BooleanShape: BooleanShape,
  Base64Shape: Base64Shape
};

/**
 * @api private
 */
module.exports = Shape;

},{"../util":398,"./collection":351}],356:[function(require,module,exports){
var AWS = require('./core');

/**
 * @api private
 */
AWS.ParamValidator = AWS.util.inherit({
  /**
   * Create a new validator object.
   *
   * @param validation [Boolean|map] whether input parameters should be
   *     validated against the operation description before sending the
   *     request. Pass a map to enable any of the following specific
   *     validation features:
   *
   *     * **min** [Boolean] &mdash; Validates that a value meets the min
   *       constraint. This is enabled by default when paramValidation is set
   *       to `true`.
   *     * **max** [Boolean] &mdash; Validates that a value meets the max
   *       constraint.
   *     * **pattern** [Boolean] &mdash; Validates that a string value matches a
   *       regular expression.
   *     * **enum** [Boolean] &mdash; Validates that a string value matches one
   *       of the allowable enum values.
   */
  constructor: function ParamValidator(validation) {
    if (validation === true || validation === undefined) {
      validation = {'min': true};
    }
    this.validation = validation;
  },

  validate: function validate(shape, params, context) {
    this.errors = [];
    this.validateMember(shape, params || {}, context || 'params');

    if (this.errors.length > 1) {
      var msg = this.errors.join('\n* ');
      msg = 'There were ' + this.errors.length +
        ' validation errors:\n* ' + msg;
      throw AWS.util.error(new Error(msg),
        {code: 'MultipleValidationErrors', errors: this.errors});
    } else if (this.errors.length === 1) {
      throw this.errors[0];
    } else {
      return true;
    }
  },

  fail: function fail(code, message) {
    this.errors.push(AWS.util.error(new Error(message), {code: code}));
  },

  validateStructure: function validateStructure(shape, params, context) {
    this.validateType(params, context, ['object'], 'structure');

    var paramName;
    for (var i = 0; shape.required && i < shape.required.length; i++) {
      paramName = shape.required[i];
      var value = params[paramName];
      if (value === undefined || value === null) {
        this.fail('MissingRequiredParameter',
          'Missing required key \'' + paramName + '\' in ' + context);
      }
    }

    // validate hash members
    for (paramName in params) {
      if (!Object.prototype.hasOwnProperty.call(params, paramName)) continue;

      var paramValue = params[paramName],
          memberShape = shape.members[paramName];

      if (memberShape !== undefined) {
        var memberContext = [context, paramName].join('.');
        this.validateMember(memberShape, paramValue, memberContext);
      } else {
        this.fail('UnexpectedParameter',
          'Unexpected key \'' + paramName + '\' found in ' + context);
      }
    }

    return true;
  },

  validateMember: function validateMember(shape, param, context) {
    switch (shape.type) {
      case 'structure':
        return this.validateStructure(shape, param, context);
      case 'list':
        return this.validateList(shape, param, context);
      case 'map':
        return this.validateMap(shape, param, context);
      default:
        return this.validateScalar(shape, param, context);
    }
  },

  validateList: function validateList(shape, params, context) {
    if (this.validateType(params, context, [Array])) {
      this.validateRange(shape, params.length, context, 'list member count');
      // validate array members
      for (var i = 0; i < params.length; i++) {
        this.validateMember(shape.member, params[i], context + '[' + i + ']');
      }
    }
  },

  validateMap: function validateMap(shape, params, context) {
    if (this.validateType(params, context, ['object'], 'map')) {
      // Build up a count of map members to validate range traits.
      var mapCount = 0;
      for (var param in params) {
        if (!Object.prototype.hasOwnProperty.call(params, param)) continue;
        // Validate any map key trait constraints
        this.validateMember(shape.key, param,
                            context + '[key=\'' + param + '\']');
        this.validateMember(shape.value, params[param],
                            context + '[\'' + param + '\']');
        mapCount++;
      }
      this.validateRange(shape, mapCount, context, 'map member count');
    }
  },

  validateScalar: function validateScalar(shape, value, context) {
    switch (shape.type) {
      case null:
      case undefined:
      case 'string':
        return this.validateString(shape, value, context);
      case 'base64':
      case 'binary':
        return this.validatePayload(value, context);
      case 'integer':
      case 'float':
        return this.validateNumber(shape, value, context);
      case 'boolean':
        return this.validateType(value, context, ['boolean']);
      case 'timestamp':
        return this.validateType(value, context, [Date,
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/, 'number'],
          'Date object, ISO-8601 string, or a UNIX timestamp');
      default:
        return this.fail('UnkownType', 'Unhandled type ' +
                         shape.type + ' for ' + context);
    }
  },

  validateString: function validateString(shape, value, context) {
    var validTypes = ['string'];
    if (shape.isJsonValue) {
      validTypes = validTypes.concat(['number', 'object', 'boolean']);
    }
    if (value !== null && this.validateType(value, context, validTypes)) {
      this.validateEnum(shape, value, context);
      this.validateRange(shape, value.length, context, 'string length');
      this.validatePattern(shape, value, context);
      this.validateUri(shape, value, context);
    }
  },

  validateUri: function validateUri(shape, value, context) {
    if (shape['location'] === 'uri') {
      if (value.length === 0) {
        this.fail('UriParameterError', 'Expected uri parameter to have length >= 1,'
          + ' but found "' + value +'" for ' + context);
      }
    }
  },

  validatePattern: function validatePattern(shape, value, context) {
    if (this.validation['pattern'] && shape['pattern'] !== undefined) {
      if (!(new RegExp(shape['pattern'])).test(value)) {
        this.fail('PatternMatchError', 'Provided value "' + value + '" '
          + 'does not match regex pattern /' + shape['pattern'] + '/ for '
          + context);
      }
    }
  },

  validateRange: function validateRange(shape, value, context, descriptor) {
    if (this.validation['min']) {
      if (shape['min'] !== undefined && value < shape['min']) {
        this.fail('MinRangeError', 'Expected ' + descriptor + ' >= '
          + shape['min'] + ', but found ' + value + ' for ' + context);
      }
    }
    if (this.validation['max']) {
      if (shape['max'] !== undefined && value > shape['max']) {
        this.fail('MaxRangeError', 'Expected ' + descriptor + ' <= '
          + shape['max'] + ', but found ' + value + ' for ' + context);
      }
    }
  },

  validateEnum: function validateRange(shape, value, context) {
    if (this.validation['enum'] && shape['enum'] !== undefined) {
      // Fail if the string value is not present in the enum list
      if (shape['enum'].indexOf(value) === -1) {
        this.fail('EnumError', 'Found string value of ' + value + ', but '
          + 'expected ' + shape['enum'].join('|') + ' for ' + context);
      }
    }
  },

  validateType: function validateType(value, context, acceptedTypes, type) {
    // We will not log an error for null or undefined, but we will return
    // false so that callers know that the expected type was not strictly met.
    if (value === null || value === undefined) return false;

    var foundInvalidType = false;
    for (var i = 0; i < acceptedTypes.length; i++) {
      if (typeof acceptedTypes[i] === 'string') {
        if (typeof value === acceptedTypes[i]) return true;
      } else if (acceptedTypes[i] instanceof RegExp) {
        if ((value || '').toString().match(acceptedTypes[i])) return true;
      } else {
        if (value instanceof acceptedTypes[i]) return true;
        if (AWS.util.isType(value, acceptedTypes[i])) return true;
        if (!type && !foundInvalidType) acceptedTypes = acceptedTypes.slice();
        acceptedTypes[i] = AWS.util.typeName(acceptedTypes[i]);
      }
      foundInvalidType = true;
    }

    var acceptedType = type;
    if (!acceptedType) {
      acceptedType = acceptedTypes.join(', ').replace(/,([^,]+)$/, ', or$1');
    }

    var vowel = acceptedType.match(/^[aeiou]/i) ? 'n' : '';
    this.fail('InvalidParameterType', 'Expected ' + context + ' to be a' +
              vowel + ' ' + acceptedType);
    return false;
  },

  validateNumber: function validateNumber(shape, value, context) {
    if (value === null || value === undefined) return;
    if (typeof value === 'string') {
      var castedValue = parseFloat(value);
      if (castedValue.toString() === value) value = castedValue;
    }
    if (this.validateType(value, context, ['number'])) {
      this.validateRange(shape, value, context, 'numeric value');
    }
  },

  validatePayload: function validatePayload(value, context) {
    if (value === null || value === undefined) return;
    if (typeof value === 'string') return;
    if (value && typeof value.byteLength === 'number') return; // typed arrays
    if (AWS.util.isNode()) { // special check for buffer/stream in Node.js
      var Stream = AWS.util.stream.Stream;
      if (AWS.util.Buffer.isBuffer(value) || value instanceof Stream) return;
    } else {
      if (typeof Blob !== void 0 && value instanceof Blob) return;
    }

    var types = ['Buffer', 'Stream', 'File', 'Blob', 'ArrayBuffer', 'DataView'];
    if (value) {
      for (var i = 0; i < types.length; i++) {
        if (AWS.util.isType(value, types[i])) return;
        if (AWS.util.typeName(value.constructor) === types[i]) return;
      }
    }

    this.fail('InvalidParameterType', 'Expected ' + context + ' to be a ' +
      'string, Buffer, Stream, Blob, or typed array object');
  }
});

},{"./core":324}],357:[function(require,module,exports){
var AWS = require('../core');
var rest = AWS.Protocol.Rest;

/**
 * A presigner object can be used to generate presigned urls for the Polly service.
 */
AWS.Polly.Presigner = AWS.util.inherit({
    /**
     * Creates a presigner object with a set of configuration options.
     *
     * @option options params [map] An optional map of parameters to bind to every
     *   request sent by this service object.
     * @option options service [AWS.Polly] An optional pre-configured instance
     *  of the AWS.Polly service object to use for requests. The object may
     *  bound parameters used by the presigner.
     * @see AWS.Polly.constructor
     */
    constructor: function Signer(options) {
        options = options || {};
        this.options = options;
        this.service = options.service;
        this.bindServiceObject(options);
        this._operations = {};
    },

    /**
     * @api private
     */
    bindServiceObject: function bindServiceObject(options) {
        options = options || {};
        if (!this.service) {
            this.service = new AWS.Polly(options);
        } else {
            var config = AWS.util.copy(this.service.config);
            this.service = new this.service.constructor.__super__(config);
            this.service.config.params = AWS.util.merge(this.service.config.params || {}, options.params);
        }
    },

    /**
     * @api private
     */
    modifyInputMembers: function modifyInputMembers(input) {
        // make copies of the input so we don't overwrite the api
        // need to be careful to copy anything we access/modify
        var modifiedInput = AWS.util.copy(input);
        modifiedInput.members = AWS.util.copy(input.members);
        AWS.util.each(input.members, function(name, member) {
            modifiedInput.members[name] = AWS.util.copy(member);
            // update location and locationName
            if (!member.location || member.location === 'body') {
                modifiedInput.members[name].location = 'querystring';
                modifiedInput.members[name].locationName = name;
            }
        });
        return modifiedInput;
    },

    /**
     * @api private
     */
    convertPostToGet: function convertPostToGet(req) {
        // convert method
        req.httpRequest.method = 'GET';

        var operation = req.service.api.operations[req.operation];
        // get cached operation input first
        var input = this._operations[req.operation];
        if (!input) {
            // modify the original input
            this._operations[req.operation] = input = this.modifyInputMembers(operation.input);
        }

        var uri = rest.generateURI(req.httpRequest.endpoint.path, operation.httpPath, input, req.params);

        req.httpRequest.path = uri;
        req.httpRequest.body = '';

        // don't need these headers on a GET request
        delete req.httpRequest.headers['Content-Length'];
        delete req.httpRequest.headers['Content-Type'];
    },

    /**
     * @overload getSynthesizeSpeechUrl(params = {}, [expires = 3600], [callback])
     *   Generate a presigned url for {AWS.Polly.synthesizeSpeech}.
     *   @note You must ensure that you have static or previously resolved
     *     credentials if you call this method synchronously (with no callback),
     *     otherwise it may not properly sign the request. If you cannot guarantee
     *     this (you are using an asynchronous credential provider, i.e., EC2
     *     IAM roles), you should always call this method with an asynchronous
     *     callback.
     *   @param params [map] parameters to pass to the operation. See the {AWS.Polly.synthesizeSpeech}
     *     operation for the expected operation parameters.
     *   @param expires [Integer] (3600) the number of seconds to expire the pre-signed URL operation in.
     *     Defaults to 1 hour.
     *   @return [string] if called synchronously (with no callback), returns the signed URL.
     *   @return [null] nothing is returned if a callback is provided.
     *   @callback callback function (err, url)
     *     If a callback is supplied, it is called when a signed URL has been generated.
     *     @param err [Error] the error object returned from the presigner.
     *     @param url [String] the signed URL.
     *   @see AWS.Polly.synthesizeSpeech
     */
    getSynthesizeSpeechUrl: function getSynthesizeSpeechUrl(params, expires, callback) {
        var self = this;
        var request = this.service.makeRequest('synthesizeSpeech', params);
        // remove existing build listeners
        request.removeAllListeners('build');
        request.on('build', function(req) {
            self.convertPostToGet(req);
        });
        return request.presign(expires, callback);
    }
});

},{"../core":324}],358:[function(require,module,exports){
var util =  require('../util');
var AWS = require('../core');

/**
 * Prepend prefix defined by API model to endpoint that's already
 * constructed. This feature does not apply to operations using
 * endpoint discovery and can be disabled.
 * @api private
 */
function populateHostPrefix(request)  {
  var enabled = request.service.config.hostPrefixEnabled;
  if (!enabled) return request;
  var operationModel = request.service.api.operations[request.operation];
  //don't marshal host prefix when operation has endpoint discovery traits
  if (hasEndpointDiscover(request)) return request;
  if (operationModel.endpoint && operationModel.endpoint.hostPrefix) {
    var hostPrefixNotation = operationModel.endpoint.hostPrefix;
    var hostPrefix = expandHostPrefix(hostPrefixNotation, request.params, operationModel.input);
    prependEndpointPrefix(request.httpRequest.endpoint, hostPrefix);
    validateHostname(request.httpRequest.endpoint.hostname);
  }
  return request;
}

/**
 * @api private
 */
function hasEndpointDiscover(request) {
  var api = request.service.api;
  var operationModel = api.operations[request.operation];
  var isEndpointOperation = api.endpointOperation && (api.endpointOperation === util.string.lowerFirst(operationModel.name));
  return (operationModel.endpointDiscoveryRequired !== 'NULL' || isEndpointOperation === true);
}

/**
 * @api private
 */
function expandHostPrefix(hostPrefixNotation, params, shape) {
  util.each(shape.members, function(name, member) {
    if (member.hostLabel === true) {
      if (typeof params[name] !== 'string' || params[name] === '') {
        throw util.error(new Error(), {
          message: 'Parameter ' + name + ' should be a non-empty string.',
          code: 'InvalidParameter'
        });
      }
      var regex = new RegExp('\\{' + name + '\\}', 'g');
      hostPrefixNotation = hostPrefixNotation.replace(regex, params[name]);
    }
  });
  return hostPrefixNotation;
}

/**
 * @api private
 */
function prependEndpointPrefix(endpoint, prefix) {
  if (endpoint.host) {
    endpoint.host = prefix + endpoint.host;
  }
  if (endpoint.hostname) {
    endpoint.hostname = prefix + endpoint.hostname;
  }
}

/**
 * @api private
 */
function validateHostname(hostname) {
  var labels = hostname.split('.');
  //Reference: https://tools.ietf.org/html/rfc1123#section-2
  var hostPattern = /^[a-zA-Z0-9]{1}$|^[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9]$/;
  util.arrayEach(labels, function(label) {
    if (!label.length || label.length < 1 || label.length > 63) {
      throw util.error(new Error(), {
        code: 'ValidationError',
        message: 'Hostname label length should be between 1 to 63 characters, inclusive.'
      });
    }
    if (!hostPattern.test(label)) {
      throw AWS.util.error(new Error(),
        {code: 'ValidationError', message: label + ' is not hostname compatible.'});
    }
  });
}

module.exports = {
  populateHostPrefix: populateHostPrefix
};

},{"../core":324,"../util":398}],359:[function(require,module,exports){
var util = require('../util');
var JsonBuilder = require('../json/builder');
var JsonParser = require('../json/parser');
var populateHostPrefix = require('./helpers').populateHostPrefix;

function buildRequest(req) {
  var httpRequest = req.httpRequest;
  var api = req.service.api;
  var target = api.targetPrefix + '.' + api.operations[req.operation].name;
  var version = api.jsonVersion || '1.0';
  var input = api.operations[req.operation].input;
  var builder = new JsonBuilder();

  if (version === 1) version = '1.0';
  httpRequest.body = builder.build(req.params || {}, input);
  httpRequest.headers['Content-Type'] = 'application/x-amz-json-' + version;
  httpRequest.headers['X-Amz-Target'] = target;

  populateHostPrefix(req);
}

function extractError(resp) {
  var error = {};
  var httpResponse = resp.httpResponse;

  error.code = httpResponse.headers['x-amzn-errortype'] || 'UnknownError';
  if (typeof error.code === 'string') {
    error.code = error.code.split(':')[0];
  }

  if (httpResponse.body.length > 0) {
    try {
      var e = JSON.parse(httpResponse.body.toString());
      if (e.__type || e.code) {
        error.code = (e.__type || e.code).split('#').pop();
      }
      if (error.code === 'RequestEntityTooLarge') {
        error.message = 'Request body must be less than 1 MB';
      } else {
        error.message = (e.message || e.Message || null);
      }
    } catch (e) {
      error.statusCode = httpResponse.statusCode;
      error.message = httpResponse.statusMessage;
    }
  } else {
    error.statusCode = httpResponse.statusCode;
    error.message = httpResponse.statusCode.toString();
  }

  resp.error = util.error(new Error(), error);
}

function extractData(resp) {
  var body = resp.httpResponse.body.toString() || '{}';
  if (resp.request.service.config.convertResponseTypes === false) {
    resp.data = JSON.parse(body);
  } else {
    var operation = resp.request.service.api.operations[resp.request.operation];
    var shape = operation.output || {};
    var parser = new JsonParser();
    resp.data = parser.parse(body, shape);
  }
}

/**
 * @api private
 */
module.exports = {
  buildRequest: buildRequest,
  extractError: extractError,
  extractData: extractData
};

},{"../json/builder":348,"../json/parser":349,"../util":398,"./helpers":358}],360:[function(require,module,exports){
var AWS = require('../core');
var util = require('../util');
var QueryParamSerializer = require('../query/query_param_serializer');
var Shape = require('../model/shape');
var populateHostPrefix = require('./helpers').populateHostPrefix;

function buildRequest(req) {
  var operation = req.service.api.operations[req.operation];
  var httpRequest = req.httpRequest;
  httpRequest.headers['Content-Type'] =
    'application/x-www-form-urlencoded; charset=utf-8';
  httpRequest.params = {
    Version: req.service.api.apiVersion,
    Action: operation.name
  };

  // convert the request parameters into a list of query params,
  // e.g. Deeply.NestedParam.0.Name=value
  var builder = new QueryParamSerializer();
  builder.serialize(req.params, operation.input, function(name, value) {
    httpRequest.params[name] = value;
  });
  httpRequest.body = util.queryParamsToString(httpRequest.params);

  populateHostPrefix(req);
}

function extractError(resp) {
  var data, body = resp.httpResponse.body.toString();
  if (body.match('<UnknownOperationException')) {
    data = {
      Code: 'UnknownOperation',
      Message: 'Unknown operation ' + resp.request.operation
    };
  } else {
    try {
      data = new AWS.XML.Parser().parse(body);
    } catch (e) {
      data = {
        Code: resp.httpResponse.statusCode,
        Message: resp.httpResponse.statusMessage
      };
    }
  }

  if (data.requestId && !resp.requestId) resp.requestId = data.requestId;
  if (data.Errors) data = data.Errors;
  if (data.Error) data = data.Error;
  if (data.Code) {
    resp.error = util.error(new Error(), {
      code: data.Code,
      message: data.Message
    });
  } else {
    resp.error = util.error(new Error(), {
      code: resp.httpResponse.statusCode,
      message: null
    });
  }
}

function extractData(resp) {
  var req = resp.request;
  var operation = req.service.api.operations[req.operation];
  var shape = operation.output || {};
  var origRules = shape;

  if (origRules.resultWrapper) {
    var tmp = Shape.create({type: 'structure'});
    tmp.members[origRules.resultWrapper] = shape;
    tmp.memberNames = [origRules.resultWrapper];
    util.property(shape, 'name', shape.resultWrapper);
    shape = tmp;
  }

  var parser = new AWS.XML.Parser();

  // TODO: Refactor XML Parser to parse RequestId from response.
  if (shape && shape.members && !shape.members._XAMZRequestId) {
    var requestIdShape = Shape.create(
      { type: 'string' },
      { api: { protocol: 'query' } },
      'requestId'
    );
    shape.members._XAMZRequestId = requestIdShape;
  }

  var data = parser.parse(resp.httpResponse.body.toString(), shape);
  resp.requestId = data._XAMZRequestId || data.requestId;

  if (data._XAMZRequestId) delete data._XAMZRequestId;

  if (origRules.resultWrapper) {
    if (data[origRules.resultWrapper]) {
      util.update(data, data[origRules.resultWrapper]);
      delete data[origRules.resultWrapper];
    }
  }

  resp.data = data;
}

/**
 * @api private
 */
module.exports = {
  buildRequest: buildRequest,
  extractError: extractError,
  extractData: extractData
};

},{"../core":324,"../model/shape":355,"../query/query_param_serializer":364,"../util":398,"./helpers":358}],361:[function(require,module,exports){
var util = require('../util');
var populateHostPrefix = require('./helpers').populateHostPrefix;

function populateMethod(req) {
  req.httpRequest.method = req.service.api.operations[req.operation].httpMethod;
}

function generateURI(endpointPath, operationPath, input, params) {
  var uri = [endpointPath, operationPath].join('/');
  uri = uri.replace(/\/+/g, '/');

  var queryString = {}, queryStringSet = false;
  util.each(input.members, function (name, member) {
    var paramValue = params[name];
    if (paramValue === null || paramValue === undefined) return;
    if (member.location === 'uri') {
      var regex = new RegExp('\\{' + member.name + '(\\+)?\\}');
      uri = uri.replace(regex, function(_, plus) {
        var fn = plus ? util.uriEscapePath : util.uriEscape;
        return fn(String(paramValue));
      });
    } else if (member.location === 'querystring') {
      queryStringSet = true;

      if (member.type === 'list') {
        queryString[member.name] = paramValue.map(function(val) {
          return util.uriEscape(member.member.toWireFormat(val).toString());
        });
      } else if (member.type === 'map') {
        util.each(paramValue, function(key, value) {
          if (Array.isArray(value)) {
            queryString[key] = value.map(function(val) {
              return util.uriEscape(String(val));
            });
          } else {
            queryString[key] = util.uriEscape(String(value));
          }
        });
      } else {
        queryString[member.name] = util.uriEscape(member.toWireFormat(paramValue).toString());
      }
    }
  });

  if (queryStringSet) {
    uri += (uri.indexOf('?') >= 0 ? '&' : '?');
    var parts = [];
    util.arrayEach(Object.keys(queryString).sort(), function(key) {
      if (!Array.isArray(queryString[key])) {
        queryString[key] = [queryString[key]];
      }
      for (var i = 0; i < queryString[key].length; i++) {
        parts.push(util.uriEscape(String(key)) + '=' + queryString[key][i]);
      }
    });
    uri += parts.join('&');
  }

  return uri;
}

function populateURI(req) {
  var operation = req.service.api.operations[req.operation];
  var input = operation.input;

  var uri = generateURI(req.httpRequest.endpoint.path, operation.httpPath, input, req.params);
  req.httpRequest.path = uri;
}

function populateHeaders(req) {
  var operation = req.service.api.operations[req.operation];
  util.each(operation.input.members, function (name, member) {
    var value = req.params[name];
    if (value === null || value === undefined) return;

    if (member.location === 'headers' && member.type === 'map') {
      util.each(value, function(key, memberValue) {
        req.httpRequest.headers[member.name + key] = memberValue;
      });
    } else if (member.location === 'header') {
      value = member.toWireFormat(value).toString();
      if (member.isJsonValue) {
        value = util.base64.encode(value);
      }
      req.httpRequest.headers[member.name] = value;
    }
  });
}

function buildRequest(req) {
  populateMethod(req);
  populateURI(req);
  populateHeaders(req);
  populateHostPrefix(req);
}

function extractError() {
}

function extractData(resp) {
  var req = resp.request;
  var data = {};
  var r = resp.httpResponse;
  var operation = req.service.api.operations[req.operation];
  var output = operation.output;

  // normalize headers names to lower-cased keys for matching
  var headers = {};
  util.each(r.headers, function (k, v) {
    headers[k.toLowerCase()] = v;
  });

  util.each(output.members, function(name, member) {
    var header = (member.name || name).toLowerCase();
    if (member.location === 'headers' && member.type === 'map') {
      data[name] = {};
      var location = member.isLocationName ? member.name : '';
      var pattern = new RegExp('^' + location + '(.+)', 'i');
      util.each(r.headers, function (k, v) {
        var result = k.match(pattern);
        if (result !== null) {
          data[name][result[1]] = v;
        }
      });
    } else if (member.location === 'header') {
      if (headers[header] !== undefined) {
        var value = member.isJsonValue ?
          util.base64.decode(headers[header]) :
          headers[header];
        data[name] = member.toType(value);
      }
    } else if (member.location === 'statusCode') {
      data[name] = parseInt(r.statusCode, 10);
    }
  });

  resp.data = data;
}

/**
 * @api private
 */
module.exports = {
  buildRequest: buildRequest,
  extractError: extractError,
  extractData: extractData,
  generateURI: generateURI
};

},{"../util":398,"./helpers":358}],362:[function(require,module,exports){
var util = require('../util');
var Rest = require('./rest');
var Json = require('./json');
var JsonBuilder = require('../json/builder');
var JsonParser = require('../json/parser');

function populateBody(req) {
  var builder = new JsonBuilder();
  var input = req.service.api.operations[req.operation].input;

  if (input.payload) {
    var params = {};
    var payloadShape = input.members[input.payload];
    params = req.params[input.payload];
    if (params === undefined) return;

    if (payloadShape.type === 'structure') {
      req.httpRequest.body = builder.build(params, payloadShape);
      applyContentTypeHeader(req);
    } else { // non-JSON payload
      req.httpRequest.body = params;
      if (payloadShape.type === 'binary' || payloadShape.isStreaming) {
        applyContentTypeHeader(req, true);
      }
    }
  } else {
    var body = builder.build(req.params, input);
    if (body !== '{}' || req.httpRequest.method !== 'GET') { //don't send empty body for GET method
      req.httpRequest.body = body;
    }
    applyContentTypeHeader(req);
  }
}

function applyContentTypeHeader(req, isBinary) {
  var operation = req.service.api.operations[req.operation];
  var input = operation.input;

  if (!req.httpRequest.headers['Content-Type']) {
    var type = isBinary ? 'binary/octet-stream' : 'application/json';
    req.httpRequest.headers['Content-Type'] = type;
  }
}

function buildRequest(req) {
  Rest.buildRequest(req);

  // never send body payload on HEAD/DELETE
  if (['HEAD', 'DELETE'].indexOf(req.httpRequest.method) < 0) {
    populateBody(req);
  }
}

function extractError(resp) {
  Json.extractError(resp);
}

function extractData(resp) {
  Rest.extractData(resp);

  var req = resp.request;
  var operation = req.service.api.operations[req.operation];
  var rules = req.service.api.operations[req.operation].output || {};
  var parser;
  var hasEventOutput = operation.hasEventOutput;

  if (rules.payload) {
    var payloadMember = rules.members[rules.payload];
    var body = resp.httpResponse.body;
    if (payloadMember.isEventStream) {
      parser = new JsonParser();
      resp.data[payload] = util.createEventStream(
        AWS.HttpClient.streamsApiVersion === 2 ? resp.httpResponse.stream : body,
        parser,
        payloadMember
      );
    } else if (payloadMember.type === 'structure' || payloadMember.type === 'list') {
      var parser = new JsonParser();
      resp.data[rules.payload] = parser.parse(body, payloadMember);
    } else if (payloadMember.type === 'binary' || payloadMember.isStreaming) {
      resp.data[rules.payload] = body;
    } else {
      resp.data[rules.payload] = payloadMember.toType(body);
    }
  } else {
    var data = resp.data;
    Json.extractData(resp);
    resp.data = util.merge(data, resp.data);
  }
}

/**
 * @api private
 */
module.exports = {
  buildRequest: buildRequest,
  extractError: extractError,
  extractData: extractData
};

},{"../json/builder":348,"../json/parser":349,"../util":398,"./json":359,"./rest":361}],363:[function(require,module,exports){
var AWS = require('../core');
var util = require('../util');
var Rest = require('./rest');

function populateBody(req) {
  var input = req.service.api.operations[req.operation].input;
  var builder = new AWS.XML.Builder();
  var params = req.params;

  var payload = input.payload;
  if (payload) {
    var payloadMember = input.members[payload];
    params = params[payload];
    if (params === undefined) return;

    if (payloadMember.type === 'structure') {
      var rootElement = payloadMember.name;
      req.httpRequest.body = builder.toXML(params, payloadMember, rootElement, true);
    } else { // non-xml payload
      req.httpRequest.body = params;
    }
  } else {
    req.httpRequest.body = builder.toXML(params, input, input.name ||
      input.shape || util.string.upperFirst(req.operation) + 'Request');
  }
}

function buildRequest(req) {
  Rest.buildRequest(req);

  // never send body payload on GET/HEAD
  if (['GET', 'HEAD'].indexOf(req.httpRequest.method) < 0) {
    populateBody(req);
  }
}

function extractError(resp) {
  Rest.extractError(resp);

  var data;
  try {
    data = new AWS.XML.Parser().parse(resp.httpResponse.body.toString());
  } catch (e) {
    data = {
      Code: resp.httpResponse.statusCode,
      Message: resp.httpResponse.statusMessage
    };
  }

  if (data.Errors) data = data.Errors;
  if (data.Error) data = data.Error;
  if (data.Code) {
    resp.error = util.error(new Error(), {
      code: data.Code,
      message: data.Message
    });
  } else {
    resp.error = util.error(new Error(), {
      code: resp.httpResponse.statusCode,
      message: null
    });
  }
}

function extractData(resp) {
  Rest.extractData(resp);

  var parser;
  var req = resp.request;
  var body = resp.httpResponse.body;
  var operation = req.service.api.operations[req.operation];
  var output = operation.output;

  var hasEventOutput = operation.hasEventOutput;

  var payload = output.payload;
  if (payload) {
    var payloadMember = output.members[payload];
    if (payloadMember.isEventStream) {
      parser = new AWS.XML.Parser();
      resp.data[payload] = util.createEventStream(
        AWS.HttpClient.streamsApiVersion === 2 ? resp.httpResponse.stream : resp.httpResponse.body,
        parser,
        payloadMember
      );
    } else if (payloadMember.type === 'structure') {
      parser = new AWS.XML.Parser();
      resp.data[payload] = parser.parse(body.toString(), payloadMember);
    } else if (payloadMember.type === 'binary' || payloadMember.isStreaming) {
      resp.data[payload] = body;
    } else {
      resp.data[payload] = payloadMember.toType(body);
    }
  } else if (body.length > 0) {
    parser = new AWS.XML.Parser();
    var data = parser.parse(body.toString(), output);
    util.update(resp.data, data);
  }
}

/**
 * @api private
 */
module.exports = {
  buildRequest: buildRequest,
  extractError: extractError,
  extractData: extractData
};

},{"../core":324,"../util":398,"./rest":361}],364:[function(require,module,exports){
var util = require('../util');

function QueryParamSerializer() {
}

QueryParamSerializer.prototype.serialize = function(params, shape, fn) {
  serializeStructure('', params, shape, fn);
};

function ucfirst(shape) {
  if (shape.isQueryName || shape.api.protocol !== 'ec2') {
    return shape.name;
  } else {
    return shape.name[0].toUpperCase() + shape.name.substr(1);
  }
}

function serializeStructure(prefix, struct, rules, fn) {
  util.each(rules.members, function(name, member) {
    var value = struct[name];
    if (value === null || value === undefined) return;

    var memberName = ucfirst(member);
    memberName = prefix ? prefix + '.' + memberName : memberName;
    serializeMember(memberName, value, member, fn);
  });
}

function serializeMap(name, map, rules, fn) {
  var i = 1;
  util.each(map, function (key, value) {
    var prefix = rules.flattened ? '.' : '.entry.';
    var position = prefix + (i++) + '.';
    var keyName = position + (rules.key.name || 'key');
    var valueName = position + (rules.value.name || 'value');
    serializeMember(name + keyName, key, rules.key, fn);
    serializeMember(name + valueName, value, rules.value, fn);
  });
}

function serializeList(name, list, rules, fn) {
  var memberRules = rules.member || {};

  if (list.length === 0) {
    fn.call(this, name, null);
    return;
  }

  util.arrayEach(list, function (v, n) {
    var suffix = '.' + (n + 1);
    if (rules.api.protocol === 'ec2') {
      // Do nothing for EC2
      suffix = suffix + ''; // make linter happy
    } else if (rules.flattened) {
      if (memberRules.name) {
        var parts = name.split('.');
        parts.pop();
        parts.push(ucfirst(memberRules));
        name = parts.join('.');
      }
    } else {
      suffix = '.' + (memberRules.name ? memberRules.name : 'member') + suffix;
    }
    serializeMember(name + suffix, v, memberRules, fn);
  });
}

function serializeMember(name, value, rules, fn) {
  if (value === null || value === undefined) return;
  if (rules.type === 'structure') {
    serializeStructure(name, value, rules, fn);
  } else if (rules.type === 'list') {
    serializeList(name, value, rules, fn);
  } else if (rules.type === 'map') {
    serializeMap(name, value, rules, fn);
  } else {
    fn(name, rules.toWireFormat(value).toString());
  }
}

/**
 * @api private
 */
module.exports = QueryParamSerializer;

},{"../util":398}],365:[function(require,module,exports){
var AWS = require('../core');

/**
 * @api private
 */
var service = null;

/**
 * @api private
 */
var api = {
    signatureVersion: 'v4',
    signingName: 'rds-db',
    operations: {}
};

/**
 * @api private
 */
var requiredAuthTokenOptions = {
    region: 'string',
    hostname: 'string',
    port: 'number',
    username: 'string'
};

/**
 * A signer object can be used to generate an auth token to a database.
 */
AWS.RDS.Signer = AWS.util.inherit({
    /**
     * Creates a signer object can be used to generate an auth token.
     *
     * @option options credentials [AWS.Credentials] the AWS credentials
     *   to sign requests with. Uses the default credential provider chain
     *   if not specified.
     * @option options hostname [String] the hostname of the database to connect to.
     * @option options port [Number] the port number the database is listening on.
     * @option options region [String] the region the database is located in.
     * @option options username [String] the username to login as.
     * @example Passing in options to constructor
     *   var signer = new AWS.RDS.Signer({
     *     credentials: new AWS.SharedIniFileCredentials({profile: 'default'}),
     *     region: 'us-east-1',
     *     hostname: 'db.us-east-1.rds.amazonaws.com',
     *     port: 8000,
     *     username: 'name'
     *   });
     */
    constructor: function Signer(options) {
        this.options = options || {};
    },

    /**
     * @api private
     * Strips the protocol from a url.
     */
    convertUrlToAuthToken: function convertUrlToAuthToken(url) {
        // we are always using https as the protocol
        var protocol = 'https://';
        if (url.indexOf(protocol) === 0) {
            return url.substring(protocol.length);
        }
    },

    /**
     * @overload getAuthToken(options = {}, [callback])
     *   Generate an auth token to a database.
     *   @note You must ensure that you have static or previously resolved
     *     credentials if you call this method synchronously (with no callback),
     *     otherwise it may not properly sign the request. If you cannot guarantee
     *     this (you are using an asynchronous credential provider, i.e., EC2
     *     IAM roles), you should always call this method with an asynchronous
     *     callback.
     *
     *   @param options [map] The fields to use when generating an auth token.
     *     Any options specified here will be merged on top of any options passed
     *     to AWS.RDS.Signer:
     *
     *     * **credentials** (AWS.Credentials) &mdash; the AWS credentials
     *         to sign requests with. Uses the default credential provider chain
     *         if not specified.
     *     * **hostname** (String) &mdash; the hostname of the database to connect to.
     *     * **port** (Number) &mdash; the port number the database is listening on.
     *     * **region** (String) &mdash; the region the database is located in.
     *     * **username** (String) &mdash; the username to login as.
     *   @return [String] if called synchronously (with no callback), returns the
     *     auth token.
     *   @return [null] nothing is returned if a callback is provided.
     *   @callback callback function (err, token)
     *     If a callback is supplied, it is called when an auth token has been generated.
     *     @param err [Error] the error object returned from the signer.
     *     @param token [String] the auth token.
     *
     *   @example Generating an auth token synchronously
     *     var signer = new AWS.RDS.Signer({
     *       // configure options
     *       region: 'us-east-1',
     *       username: 'default',
     *       hostname: 'db.us-east-1.amazonaws.com',
     *       port: 8000
     *     });
     *     var token = signer.getAuthToken({
     *       // these options are merged with those defined when creating the signer, overriding in the case of a duplicate option
     *       // credentials are not specified here or when creating the signer, so default credential provider will be used
     *       username: 'test' // overriding username
     *     });
     *   @example Generating an auth token asynchronously
     *     var signer = new AWS.RDS.Signer({
     *       // configure options
     *       region: 'us-east-1',
     *       username: 'default',
     *       hostname: 'db.us-east-1.amazonaws.com',
     *       port: 8000
     *     });
     *     signer.getAuthToken({
     *       // these options are merged with those defined when creating the signer, overriding in the case of a duplicate option
     *       // credentials are not specified here or when creating the signer, so default credential provider will be used
     *       username: 'test' // overriding username
     *     }, function(err, token) {
     *       if (err) {
     *         // handle error
     *       } else {
     *         // use token
     *       }
     *     });
     *
     */
    getAuthToken: function getAuthToken(options, callback) {
        if (typeof options === 'function' && callback === undefined) {
            callback = options;
            options = {};
        }
        var self = this;
        var hasCallback = typeof callback === 'function';
        // merge options with existing options
        options = AWS.util.merge(this.options, options);
        // validate options
        var optionsValidation = this.validateAuthTokenOptions(options);
        if (optionsValidation !== true) {
            if (hasCallback) {
                return callback(optionsValidation, null);
            }
            throw optionsValidation;
        }

        // 15 minutes
        var expires = 900;
        // create service to generate a request from
        var serviceOptions = {
            region: options.region,
            endpoint: new AWS.Endpoint(options.hostname + ':' + options.port),
            paramValidation: false,
            signatureVersion: 'v4'
        };
        if (options.credentials) {
            serviceOptions.credentials = options.credentials;
        }
        service = new AWS.Service(serviceOptions);
        // ensure the SDK is using sigv4 signing (config is not enough)
        service.api = api;

        var request = service.makeRequest();
        // add listeners to request to properly build auth token
        this.modifyRequestForAuthToken(request, options);

        if (hasCallback) {
            request.presign(expires, function(err, url) {
                if (url) {
                    url = self.convertUrlToAuthToken(url);
                }
                callback(err, url);
            });
        } else {
            var url = request.presign(expires);
            return this.convertUrlToAuthToken(url);
        }
    },

    /**
     * @api private
     * Modifies a request to allow the presigner to generate an auth token.
     */
    modifyRequestForAuthToken: function modifyRequestForAuthToken(request, options) {
        request.on('build', request.buildAsGet);
        var httpRequest = request.httpRequest;
        httpRequest.body = AWS.util.queryParamsToString({
            Action: 'connect',
            DBUser: options.username
        });
    },

    /**
     * @api private
     * Validates that the options passed in contain all the keys with values of the correct type that
     *   are needed to generate an auth token.
     */
    validateAuthTokenOptions: function validateAuthTokenOptions(options) {
        // iterate over all keys in options
        var message = '';
        options = options || {};
        for (var key in requiredAuthTokenOptions) {
            if (!Object.prototype.hasOwnProperty.call(requiredAuthTokenOptions, key)) {
                continue;
            }
            if (typeof options[key] !== requiredAuthTokenOptions[key]) {
                message += 'option \'' + key + '\' should have been type \'' + requiredAuthTokenOptions[key] + '\', was \'' + typeof options[key] + '\'.\n';
            }
        }
        if (message.length) {
            return AWS.util.error(new Error(), {
                code: 'InvalidParameter',
                message: message
            });
        }
        return true;
    }
});

},{"../core":324}],366:[function(require,module,exports){
module.exports = {
  //provide realtime clock for performance measurement
  now: function now() {
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      return performance.now();
    }
    return Date.now();
  }
};

},{}],367:[function(require,module,exports){
var util = require('./util');
var regionConfig = require('./region_config_data.json');

function generateRegionPrefix(region) {
  if (!region) return null;

  var parts = region.split('-');
  if (parts.length < 3) return null;
  return parts.slice(0, parts.length - 2).join('-') + '-*';
}

function derivedKeys(service) {
  var region = service.config.region;
  var regionPrefix = generateRegionPrefix(region);
  var endpointPrefix = service.api.endpointPrefix;

  return [
    [region, endpointPrefix],
    [regionPrefix, endpointPrefix],
    [region, '*'],
    [regionPrefix, '*'],
    ['*', endpointPrefix],
    ['*', '*']
  ].map(function(item) {
    return item[0] && item[1] ? item.join('/') : null;
  });
}

function applyConfig(service, config) {
  util.each(config, function(key, value) {
    if (key === 'globalEndpoint') return;
    if (service.config[key] === undefined || service.config[key] === null) {
      service.config[key] = value;
    }
  });
}

function configureEndpoint(service) {
  var keys = derivedKeys(service);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (!key) continue;

    if (Object.prototype.hasOwnProperty.call(regionConfig.rules, key)) {
      var config = regionConfig.rules[key];
      if (typeof config === 'string') {
        config = regionConfig.patterns[config];
      }

      // set dualstack endpoint
      if (service.config.useDualstack && util.isDualstackAvailable(service)) {
        config = util.copy(config);
        config.endpoint = config.endpoint.replace(
          /{service}\.({region}\.)?/,
          '{service}.dualstack.{region}.'
        );
      }

      // set global endpoint
      service.isGlobalEndpoint = !!config.globalEndpoint;
      if (config.signingRegion) {
        service.signingRegion = config.signingRegion;
      }

      // signature version
      if (!config.signatureVersion) config.signatureVersion = 'v4';

      // merge config
      applyConfig(service, config);
      return;
    }
  }
}

function getEndpointSuffix(region) {
  var regionRegexes = {
    '^(us|eu|ap|sa|ca|me)\\-\\w+\\-\\d+$': 'amazonaws.com',
    '^cn\\-\\w+\\-\\d+$': 'amazonaws.com.cn',
    '^us\\-gov\\-\\w+\\-\\d+$': 'amazonaws.com',
    '^us\\-iso\\-\\w+\\-\\d+$': 'c2s.ic.gov',
    '^us\\-isob\\-\\w+\\-\\d+$': 'sc2s.sgov.gov'
  };
  var defaultSuffix = 'amazonaws.com';
  var regexes = Object.keys(regionRegexes);
  for (var i = 0; i < regexes.length; i++) {
    var regionPattern = RegExp(regexes[i]);
    var dnsSuffix = regionRegexes[regexes[i]];
    if (regionPattern.test(region)) return dnsSuffix;
  }
  return defaultSuffix;
}

/**
 * @api private
 */
module.exports = {
  configureEndpoint: configureEndpoint,
  getEndpointSuffix: getEndpointSuffix
};

},{"./region_config_data.json":368,"./util":398}],368:[function(require,module,exports){
module.exports={
  "rules": {
    "*/*": {
      "endpoint": "{service}.{region}.amazonaws.com"
    },
    "cn-*/*": {
      "endpoint": "{service}.{region}.amazonaws.com.cn"
    },
    "us-iso-*/*": {
      "endpoint": "{service}.{region}.c2s.ic.gov"
    },
    "us-isob-*/*": {
      "endpoint": "{service}.{region}.sc2s.sgov.gov"
    },
    "*/budgets": "globalSSL",
    "*/cloudfront": "globalSSL",
    "*/sts": "globalSSL",
    "*/importexport": {
      "endpoint": "{service}.amazonaws.com",
      "signatureVersion": "v2",
      "globalEndpoint": true
    },

    "*/route53": "globalSSL",
    "cn-*/route53": {
      "endpoint": "{service}.amazonaws.com.cn",
      "globalEndpoint": true,
      "signingRegion": "cn-northwest-1"
    },
    "us-gov-*/route53": "globalGovCloud",

    "*/waf": "globalSSL",

    "*/iam": "globalSSL",
    "cn-*/iam": {
      "endpoint": "{service}.cn-north-1.amazonaws.com.cn",
      "globalEndpoint": true,
      "signingRegion": "cn-north-1"
    },
    "us-gov-*/iam": "globalGovCloud",

    "us-gov-*/sts": {
      "endpoint": "{service}.{region}.amazonaws.com"
    },
    "us-gov-west-1/s3": "s3signature",
    "us-west-1/s3": "s3signature",
    "us-west-2/s3": "s3signature",
    "eu-west-1/s3": "s3signature",
    "ap-southeast-1/s3": "s3signature",
    "ap-southeast-2/s3": "s3signature",
    "ap-northeast-1/s3": "s3signature",
    "sa-east-1/s3": "s3signature",
    "us-east-1/s3": {
      "endpoint": "{service}.amazonaws.com",
      "signatureVersion": "s3"
    },
    "us-east-1/sdb": {
      "endpoint": "{service}.amazonaws.com",
      "signatureVersion": "v2"
    },
    "*/sdb": {
      "endpoint": "{service}.{region}.amazonaws.com",
      "signatureVersion": "v2"
    }
  },

  "patterns": {
    "globalSSL": {
      "endpoint": "https://{service}.amazonaws.com",
      "globalEndpoint": true,
      "signingRegion": "us-east-1"
    },
    "globalGovCloud": {
      "endpoint": "{service}.us-gov.amazonaws.com",
      "globalEndpoint": true,
      "signingRegion": "us-gov-west-1"
    },
    "s3signature": {
      "endpoint": "{service}.{region}.amazonaws.com",
      "signatureVersion": "s3"
    }
  }
}

},{}],369:[function(require,module,exports){
(function (process){
var AWS = require('./core');
var AcceptorStateMachine = require('./state_machine');
var inherit = AWS.util.inherit;
var domain = AWS.util.domain;
var jmespath = require('jmespath');

/**
 * @api private
 */
var hardErrorStates = {success: 1, error: 1, complete: 1};

function isTerminalState(machine) {
  return Object.prototype.hasOwnProperty.call(hardErrorStates, machine._asm.currentState);
}

var fsm = new AcceptorStateMachine();
fsm.setupStates = function() {
  var transition = function(_, done) {
    var self = this;
    self._haltHandlersOnError = false;

    self.emit(self._asm.currentState, function(err) {
      if (err) {
        if (isTerminalState(self)) {
          if (domain && self.domain instanceof domain.Domain) {
            err.domainEmitter = self;
            err.domain = self.domain;
            err.domainThrown = false;
            self.domain.emit('error', err);
          } else {
            throw err;
          }
        } else {
          self.response.error = err;
          done(err);
        }
      } else {
        done(self.response.error);
      }
    });

  };

  this.addState('validate', 'build', 'error', transition);
  this.addState('build', 'afterBuild', 'restart', transition);
  this.addState('afterBuild', 'sign', 'restart', transition);
  this.addState('sign', 'send', 'retry', transition);
  this.addState('retry', 'afterRetry', 'afterRetry', transition);
  this.addState('afterRetry', 'sign', 'error', transition);
  this.addState('send', 'validateResponse', 'retry', transition);
  this.addState('validateResponse', 'extractData', 'extractError', transition);
  this.addState('extractError', 'extractData', 'retry', transition);
  this.addState('extractData', 'success', 'retry', transition);
  this.addState('restart', 'build', 'error', transition);
  this.addState('success', 'complete', 'complete', transition);
  this.addState('error', 'complete', 'complete', transition);
  this.addState('complete', null, null, transition);
};
fsm.setupStates();

/**
 * ## Asynchronous Requests
 *
 * All requests made through the SDK are asynchronous and use a
 * callback interface. Each service method that kicks off a request
 * returns an `AWS.Request` object that you can use to register
 * callbacks.
 *
 * For example, the following service method returns the request
 * object as "request", which can be used to register callbacks:
 *
 * ```javascript
 * // request is an AWS.Request object
 * var request = ec2.describeInstances();
 *
 * // register callbacks on request to retrieve response data
 * request.on('success', function(response) {
 *   console.log(response.data);
 * });
 * ```
 *
 * When a request is ready to be sent, the {send} method should
 * be called:
 *
 * ```javascript
 * request.send();
 * ```
 *
 * Since registered callbacks may or may not be idempotent, requests should only
 * be sent once. To perform the same operation multiple times, you will need to
 * create multiple request objects, each with its own registered callbacks.
 *
 * ## Removing Default Listeners for Events
 *
 * Request objects are built with default listeners for the various events,
 * depending on the service type. In some cases, you may want to remove
 * some built-in listeners to customize behaviour. Doing this requires
 * access to the built-in listener functions, which are exposed through
 * the {AWS.EventListeners.Core} namespace. For instance, you may
 * want to customize the HTTP handler used when sending a request. In this
 * case, you can remove the built-in listener associated with the 'send'
 * event, the {AWS.EventListeners.Core.SEND} listener and add your own.
 *
 * ## Multiple Callbacks and Chaining
 *
 * You can register multiple callbacks on any request object. The
 * callbacks can be registered for different events, or all for the
 * same event. In addition, you can chain callback registration, for
 * example:
 *
 * ```javascript
 * request.
 *   on('success', function(response) {
 *     console.log("Success!");
 *   }).
 *   on('error', function(error, response) {
 *     console.log("Error!");
 *   }).
 *   on('complete', function(response) {
 *     console.log("Always!");
 *   }).
 *   send();
 * ```
 *
 * The above example will print either "Success! Always!", or "Error! Always!",
 * depending on whether the request succeeded or not.
 *
 * @!attribute httpRequest
 *   @readonly
 *   @!group HTTP Properties
 *   @return [AWS.HttpRequest] the raw HTTP request object
 *     containing request headers and body information
 *     sent by the service.
 *
 * @!attribute startTime
 *   @readonly
 *   @!group Operation Properties
 *   @return [Date] the time that the request started
 *
 * @!group Request Building Events
 *
 * @!event validate(request)
 *   Triggered when a request is being validated. Listeners
 *   should throw an error if the request should not be sent.
 *   @param request [Request] the request object being sent
 *   @see AWS.EventListeners.Core.VALIDATE_CREDENTIALS
 *   @see AWS.EventListeners.Core.VALIDATE_REGION
 *   @example Ensuring that a certain parameter is set before sending a request
 *     var req = s3.putObject(params);
 *     req.on('validate', function() {
 *       if (!req.params.Body.match(/^Hello\s/)) {
 *         throw new Error('Body must start with "Hello "');
 *       }
 *     });
 *     req.send(function(err, data) { ... });
 *
 * @!event build(request)
 *   Triggered when the request payload is being built. Listeners
 *   should fill the necessary information to send the request
 *   over HTTP.
 *   @param (see AWS.Request~validate)
 *   @example Add a custom HTTP header to a request
 *     var req = s3.putObject(params);
 *     req.on('build', function() {
 *       req.httpRequest.headers['Custom-Header'] = 'value';
 *     });
 *     req.send(function(err, data) { ... });
 *
 * @!event sign(request)
 *   Triggered when the request is being signed. Listeners should
 *   add the correct authentication headers and/or adjust the body,
 *   depending on the authentication mechanism being used.
 *   @param (see AWS.Request~validate)
 *
 * @!group Request Sending Events
 *
 * @!event send(response)
 *   Triggered when the request is ready to be sent. Listeners
 *   should call the underlying transport layer to initiate
 *   the sending of the request.
 *   @param response [Response] the response object
 *   @context [Request] the request object that was sent
 *   @see AWS.EventListeners.Core.SEND
 *
 * @!event retry(response)
 *   Triggered when a request failed and might need to be retried or redirected.
 *   If the response is retryable, the listener should set the
 *   `response.error.retryable` property to `true`, and optionally set
 *   `response.error.retryDelay` to the millisecond delay for the next attempt.
 *   In the case of a redirect, `response.error.redirect` should be set to
 *   `true` with `retryDelay` set to an optional delay on the next request.
 *
 *   If a listener decides that a request should not be retried,
 *   it should set both `retryable` and `redirect` to false.
 *
 *   Note that a retryable error will be retried at most
 *   {AWS.Config.maxRetries} times (based on the service object's config).
 *   Similarly, a request that is redirected will only redirect at most
 *   {AWS.Config.maxRedirects} times.
 *
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *   @example Adding a custom retry for a 404 response
 *     request.on('retry', function(response) {
 *       // this resource is not yet available, wait 10 seconds to get it again
 *       if (response.httpResponse.statusCode === 404 && response.error) {
 *         response.error.retryable = true;   // retry this error
 *         response.error.retryDelay = 10000; // wait 10 seconds
 *       }
 *     });
 *
 * @!group Data Parsing Events
 *
 * @!event extractError(response)
 *   Triggered on all non-2xx requests so that listeners can extract
 *   error details from the response body. Listeners to this event
 *   should set the `response.error` property.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!event extractData(response)
 *   Triggered in successful requests to allow listeners to
 *   de-serialize the response body into `response.data`.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!group Completion Events
 *
 * @!event success(response)
 *   Triggered when the request completed successfully.
 *   `response.data` will contain the response data and
 *   `response.error` will be null.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!event error(error, response)
 *   Triggered when an error occurs at any point during the
 *   request. `response.error` will contain details about the error
 *   that occurred. `response.data` will be null.
 *   @param error [Error] the error object containing details about
 *     the error that occurred.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!event complete(response)
 *   Triggered whenever a request cycle completes. `response.error`
 *   should be checked, since the request may have failed.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!group HTTP Events
 *
 * @!event httpHeaders(statusCode, headers, response, statusMessage)
 *   Triggered when headers are sent by the remote server
 *   @param statusCode [Integer] the HTTP response code
 *   @param headers [map<String,String>] the response headers
 *   @param (see AWS.Request~send)
 *   @param statusMessage [String] A status message corresponding to the HTTP
 *                                 response code
 *   @context (see AWS.Request~send)
 *
 * @!event httpData(chunk, response)
 *   Triggered when data is sent by the remote server
 *   @param chunk [Buffer] the buffer data containing the next data chunk
 *     from the server
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *   @see AWS.EventListeners.Core.HTTP_DATA
 *
 * @!event httpUploadProgress(progress, response)
 *   Triggered when the HTTP request has uploaded more data
 *   @param progress [map] An object containing the `loaded` and `total` bytes
 *     of the request.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *   @note This event will not be emitted in Node.js 0.8.x.
 *
 * @!event httpDownloadProgress(progress, response)
 *   Triggered when the HTTP request has downloaded more data
 *   @param progress [map] An object containing the `loaded` and `total` bytes
 *     of the request.
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *   @note This event will not be emitted in Node.js 0.8.x.
 *
 * @!event httpError(error, response)
 *   Triggered when the HTTP request failed
 *   @param error [Error] the error object that was thrown
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @!event httpDone(response)
 *   Triggered when the server is finished sending data
 *   @param (see AWS.Request~send)
 *   @context (see AWS.Request~send)
 *
 * @see AWS.Response
 */
AWS.Request = inherit({

  /**
   * Creates a request for an operation on a given service with
   * a set of input parameters.
   *
   * @param service [AWS.Service] the service to perform the operation on
   * @param operation [String] the operation to perform on the service
   * @param params [Object] parameters to send to the operation.
   *   See the operation's documentation for the format of the
   *   parameters.
   */
  constructor: function Request(service, operation, params) {
    var endpoint = service.endpoint;
    var region = service.config.region;
    var customUserAgent = service.config.customUserAgent;

    if (service.isGlobalEndpoint) {
      if (service.signingRegion) {
        region = service.signingRegion;
      } else {
        region = 'us-east-1';
      }
    }

    this.domain = domain && domain.active;
    this.service = service;
    this.operation = operation;
    this.params = params || {};
    this.httpRequest = new AWS.HttpRequest(endpoint, region);
    this.httpRequest.appendToUserAgent(customUserAgent);
    this.startTime = service.getSkewCorrectedDate();

    this.response = new AWS.Response(this);
    this._asm = new AcceptorStateMachine(fsm.states, 'validate');
    this._haltHandlersOnError = false;

    AWS.SequentialExecutor.call(this);
    this.emit = this.emitEvent;
  },

  /**
   * @!group Sending a Request
   */

  /**
   * @overload send(callback = null)
   *   Sends the request object.
   *
   *   @callback callback function(err, data)
   *     If a callback is supplied, it is called when a response is returned
   *     from the service.
   *     @context [AWS.Request] the request object being sent.
   *     @param err [Error] the error object returned from the request.
   *       Set to `null` if the request is successful.
   *     @param data [Object] the de-serialized data returned from
   *       the request. Set to `null` if a request error occurs.
   *   @example Sending a request with a callback
   *     request = s3.putObject({Bucket: 'bucket', Key: 'key'});
   *     request.send(function(err, data) { console.log(err, data); });
   *   @example Sending a request with no callback (using event handlers)
   *     request = s3.putObject({Bucket: 'bucket', Key: 'key'});
   *     request.on('complete', function(response) { ... }); // register a callback
   *     request.send();
   */
  send: function send(callback) {
    if (callback) {
      // append to user agent
      this.httpRequest.appendToUserAgent('callback');
      this.on('complete', function (resp) {
        callback.call(resp, resp.error, resp.data);
      });
    }
    this.runTo();

    return this.response;
  },

  /**
   * @!method  promise()
   *   Sends the request and returns a 'thenable' promise.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @callback fulfilledCallback function(data)
   *     Called if the promise is fulfilled.
   *     @param data [Object] the de-serialized data returned from the request.
   *   @callback rejectedCallback function(error)
   *     Called if the promise is rejected.
   *     @param error [Error] the error object returned from the request.
   *   @return [Promise] A promise that represents the state of the request.
   *   @example Sending a request using promises.
   *     var request = s3.putObject({Bucket: 'bucket', Key: 'key'});
   *     var result = request.promise();
   *     result.then(function(data) { ... }, function(error) { ... });
   */

  /**
   * @api private
   */
  build: function build(callback) {
    return this.runTo('send', callback);
  },

  /**
   * @api private
   */
  runTo: function runTo(state, done) {
    this._asm.runTo(state, done, this);
    return this;
  },

  /**
   * Aborts a request, emitting the error and complete events.
   *
   * @!macro nobrowser
   * @example Aborting a request after sending
   *   var params = {
   *     Bucket: 'bucket', Key: 'key',
   *     Body: Buffer.alloc(1024 * 1024 * 5) // 5MB payload
   *   };
   *   var request = s3.putObject(params);
   *   request.send(function (err, data) {
   *     if (err) console.log("Error:", err.code, err.message);
   *     else console.log(data);
   *   });
   *
   *   // abort request in 1 second
   *   setTimeout(request.abort.bind(request), 1000);
   *
   *   // prints "Error: RequestAbortedError Request aborted by user"
   * @return [AWS.Request] the same request object, for chaining.
   * @since v1.4.0
   */
  abort: function abort() {
    this.removeAllListeners('validateResponse');
    this.removeAllListeners('extractError');
    this.on('validateResponse', function addAbortedError(resp) {
      resp.error = AWS.util.error(new Error('Request aborted by user'), {
         code: 'RequestAbortedError', retryable: false
      });
    });

    if (this.httpRequest.stream && !this.httpRequest.stream.didCallback) { // abort HTTP stream
      this.httpRequest.stream.abort();
      if (this.httpRequest._abortCallback) {
         this.httpRequest._abortCallback();
      } else {
        this.removeAllListeners('send'); // haven't sent yet, so let's not
      }
    }

    return this;
  },

  /**
   * Iterates over each page of results given a pageable request, calling
   * the provided callback with each page of data. After all pages have been
   * retrieved, the callback is called with `null` data.
   *
   * @note This operation can generate multiple requests to a service.
   * @example Iterating over multiple pages of objects in an S3 bucket
   *   var pages = 1;
   *   s3.listObjects().eachPage(function(err, data) {
   *     if (err) return;
   *     console.log("Page", pages++);
   *     console.log(data);
   *   });
   * @example Iterating over multiple pages with an asynchronous callback
   *   s3.listObjects(params).eachPage(function(err, data, done) {
   *     doSomethingAsyncAndOrExpensive(function() {
   *       // The next page of results isn't fetched until done is called
   *       done();
   *     });
   *   });
   * @callback callback function(err, data, [doneCallback])
   *   Called with each page of resulting data from the request. If the
   *   optional `doneCallback` is provided in the function, it must be called
   *   when the callback is complete.
   *
   *   @param err [Error] an error object, if an error occurred.
   *   @param data [Object] a single page of response data. If there is no
   *     more data, this object will be `null`.
   *   @param doneCallback [Function] an optional done callback. If this
   *     argument is defined in the function declaration, it should be called
   *     when the next page is ready to be retrieved. This is useful for
   *     controlling serial pagination across asynchronous operations.
   *   @return [Boolean] if the callback returns `false`, pagination will
   *     stop.
   *
   * @see AWS.Request.eachItem
   * @see AWS.Response.nextPage
   * @since v1.4.0
   */
  eachPage: function eachPage(callback) {
    // Make all callbacks async-ish
    callback = AWS.util.fn.makeAsync(callback, 3);

    function wrappedCallback(response) {
      callback.call(response, response.error, response.data, function (result) {
        if (result === false) return;

        if (response.hasNextPage()) {
          response.nextPage().on('complete', wrappedCallback).send();
        } else {
          callback.call(response, null, null, AWS.util.fn.noop);
        }
      });
    }

    this.on('complete', wrappedCallback).send();
  },

  /**
   * Enumerates over individual items of a request, paging the responses if
   * necessary.
   *
   * @api experimental
   * @since v1.4.0
   */
  eachItem: function eachItem(callback) {
    var self = this;
    function wrappedCallback(err, data) {
      if (err) return callback(err, null);
      if (data === null) return callback(null, null);

      var config = self.service.paginationConfig(self.operation);
      var resultKey = config.resultKey;
      if (Array.isArray(resultKey)) resultKey = resultKey[0];
      var items = jmespath.search(data, resultKey);
      var continueIteration = true;
      AWS.util.arrayEach(items, function(item) {
        continueIteration = callback(null, item);
        if (continueIteration === false) {
          return AWS.util.abort;
        }
      });
      return continueIteration;
    }

    this.eachPage(wrappedCallback);
  },

  /**
   * @return [Boolean] whether the operation can return multiple pages of
   *   response data.
   * @see AWS.Response.eachPage
   * @since v1.4.0
   */
  isPageable: function isPageable() {
    return this.service.paginationConfig(this.operation) ? true : false;
  },

  /**
   * Sends the request and converts the request object into a readable stream
   * that can be read from or piped into a writable stream.
   *
   * @note The data read from a readable stream contains only
   *   the raw HTTP body contents.
   * @example Manually reading from a stream
   *   request.createReadStream().on('data', function(data) {
   *     console.log("Got data:", data.toString());
   *   });
   * @example Piping a request body into a file
   *   var out = fs.createWriteStream('/path/to/outfile.jpg');
   *   s3.service.getObject(params).createReadStream().pipe(out);
   * @return [Stream] the readable stream object that can be piped
   *   or read from (by registering 'data' event listeners).
   * @!macro nobrowser
   */
  createReadStream: function createReadStream() {
    var streams = AWS.util.stream;
    var req = this;
    var stream = null;

    if (AWS.HttpClient.streamsApiVersion === 2) {
      stream = new streams.PassThrough();
      process.nextTick(function() { req.send(); });
    } else {
      stream = new streams.Stream();
      stream.readable = true;

      stream.sent = false;
      stream.on('newListener', function(event) {
        if (!stream.sent && event === 'data') {
          stream.sent = true;
          process.nextTick(function() { req.send(); });
        }
      });
    }

    this.on('error', function(err) {
      stream.emit('error', err);
    });

    this.on('httpHeaders', function streamHeaders(statusCode, headers, resp) {
      if (statusCode < 300) {
        req.removeListener('httpData', AWS.EventListeners.Core.HTTP_DATA);
        req.removeListener('httpError', AWS.EventListeners.Core.HTTP_ERROR);
        req.on('httpError', function streamHttpError(error) {
          resp.error = error;
          resp.error.retryable = false;
        });

        var shouldCheckContentLength = false;
        var expectedLen;
        if (req.httpRequest.method !== 'HEAD') {
          expectedLen = parseInt(headers['content-length'], 10);
        }
        if (expectedLen !== undefined && !isNaN(expectedLen) && expectedLen >= 0) {
          shouldCheckContentLength = true;
          var receivedLen = 0;
        }

        var checkContentLengthAndEmit = function checkContentLengthAndEmit() {
          if (shouldCheckContentLength && receivedLen !== expectedLen) {
            stream.emit('error', AWS.util.error(
              new Error('Stream content length mismatch. Received ' +
                receivedLen + ' of ' + expectedLen + ' bytes.'),
              { code: 'StreamContentLengthMismatch' }
            ));
          } else if (AWS.HttpClient.streamsApiVersion === 2) {
            stream.end();
          } else {
            stream.emit('end');
          }
        };

        var httpStream = resp.httpResponse.createUnbufferedStream();

        if (AWS.HttpClient.streamsApiVersion === 2) {
          if (shouldCheckContentLength) {
            var lengthAccumulator = new streams.PassThrough();
            lengthAccumulator._write = function(chunk) {
              if (chunk && chunk.length) {
                receivedLen += chunk.length;
              }
              return streams.PassThrough.prototype._write.apply(this, arguments);
            };

            lengthAccumulator.on('end', checkContentLengthAndEmit);
            stream.on('error', function(err) {
              shouldCheckContentLength = false;
              httpStream.unpipe(lengthAccumulator);
              lengthAccumulator.emit('end');
              lengthAccumulator.end();
            });
            httpStream.pipe(lengthAccumulator).pipe(stream, { end: false });
          } else {
            httpStream.pipe(stream);
          }
        } else {

          if (shouldCheckContentLength) {
            httpStream.on('data', function(arg) {
              if (arg && arg.length) {
                receivedLen += arg.length;
              }
            });
          }

          httpStream.on('data', function(arg) {
            stream.emit('data', arg);
          });
          httpStream.on('end', checkContentLengthAndEmit);
        }

        httpStream.on('error', function(err) {
          shouldCheckContentLength = false;
          stream.emit('error', err);
        });
      }
    });

    return stream;
  },

  /**
   * @param [Array,Response] args This should be the response object,
   *   or an array of args to send to the event.
   * @api private
   */
  emitEvent: function emit(eventName, args, done) {
    if (typeof args === 'function') { done = args; args = null; }
    if (!done) done = function() { };
    if (!args) args = this.eventParameters(eventName, this.response);

    var origEmit = AWS.SequentialExecutor.prototype.emit;
    origEmit.call(this, eventName, args, function (err) {
      if (err) this.response.error = err;
      done.call(this, err);
    });
  },

  /**
   * @api private
   */
  eventParameters: function eventParameters(eventName) {
    switch (eventName) {
      case 'restart':
      case 'validate':
      case 'sign':
      case 'build':
      case 'afterValidate':
      case 'afterBuild':
        return [this];
      case 'error':
        return [this.response.error, this.response];
      default:
        return [this.response];
    }
  },

  /**
   * @api private
   */
  presign: function presign(expires, callback) {
    if (!callback && typeof expires === 'function') {
      callback = expires;
      expires = null;
    }
    return new AWS.Signers.Presign().sign(this.toGet(), expires, callback);
  },

  /**
   * @api private
   */
  isPresigned: function isPresigned() {
    return Object.prototype.hasOwnProperty.call(this.httpRequest.headers, 'presigned-expires');
  },

  /**
   * @api private
   */
  toUnauthenticated: function toUnauthenticated() {
    this._unAuthenticated = true;
    this.removeListener('validate', AWS.EventListeners.Core.VALIDATE_CREDENTIALS);
    this.removeListener('sign', AWS.EventListeners.Core.SIGN);
    return this;
  },

  /**
   * @api private
   */
  toGet: function toGet() {
    if (this.service.api.protocol === 'query' ||
        this.service.api.protocol === 'ec2') {
      this.removeListener('build', this.buildAsGet);
      this.addListener('build', this.buildAsGet);
    }
    return this;
  },

  /**
   * @api private
   */
  buildAsGet: function buildAsGet(request) {
    request.httpRequest.method = 'GET';
    request.httpRequest.path = request.service.endpoint.path +
                               '?' + request.httpRequest.body;
    request.httpRequest.body = '';

    // don't need these headers on a GET request
    delete request.httpRequest.headers['Content-Length'];
    delete request.httpRequest.headers['Content-Type'];
  },

  /**
   * @api private
   */
  haltHandlersOnError: function haltHandlersOnError() {
    this._haltHandlersOnError = true;
  }
});

/**
 * @api private
 */
AWS.Request.addPromisesToClass = function addPromisesToClass(PromiseDependency) {
  this.prototype.promise = function promise() {
    var self = this;
    // append to user agent
    this.httpRequest.appendToUserAgent('promise');
    return new PromiseDependency(function(resolve, reject) {
      self.on('complete', function(resp) {
        if (resp.error) {
          reject(resp.error);
        } else {
          // define $response property so that it is not enumberable
          // this prevents circular reference errors when stringifying the JSON object
          resolve(Object.defineProperty(
            resp.data || {},
            '$response',
            {value: resp}
          ));
        }
      });
      self.runTo();
    });
  };
};

/**
 * @api private
 */
AWS.Request.deletePromisesFromClass = function deletePromisesFromClass() {
  delete this.prototype.promise;
};

AWS.util.addPromises(AWS.Request);

AWS.util.mixin(AWS.Request, AWS.SequentialExecutor);

}).call(this,require('_process'))
},{"./core":324,"./state_machine":397,"_process":412,"jmespath":411}],370:[function(require,module,exports){
/**
 * Copyright 2012-2013 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You
 * may not use this file except in compliance with the License. A copy of
 * the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is
 * distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF
 * ANY KIND, either express or implied. See the License for the specific
 * language governing permissions and limitations under the License.
 */

var AWS = require('./core');
var inherit = AWS.util.inherit;
var jmespath = require('jmespath');

/**
 * @api private
 */
function CHECK_ACCEPTORS(resp) {
  var waiter = resp.request._waiter;
  var acceptors = waiter.config.acceptors;
  var acceptorMatched = false;
  var state = 'retry';

  acceptors.forEach(function(acceptor) {
    if (!acceptorMatched) {
      var matcher = waiter.matchers[acceptor.matcher];
      if (matcher && matcher(resp, acceptor.expected, acceptor.argument)) {
        acceptorMatched = true;
        state = acceptor.state;
      }
    }
  });

  if (!acceptorMatched && resp.error) state = 'failure';

  if (state === 'success') {
    waiter.setSuccess(resp);
  } else {
    waiter.setError(resp, state === 'retry');
  }
}

/**
 * @api private
 */
AWS.ResourceWaiter = inherit({
  /**
   * Waits for a given state on a service object
   * @param service [Service] the service object to wait on
   * @param state [String] the state (defined in waiter configuration) to wait
   *   for.
   * @example Create a waiter for running EC2 instances
   *   var ec2 = new AWS.EC2;
   *   var waiter = new AWS.ResourceWaiter(ec2, 'instanceRunning');
   */
  constructor: function constructor(service, state) {
    this.service = service;
    this.state = state;
    this.loadWaiterConfig(this.state);
  },

  service: null,

  state: null,

  config: null,

  matchers: {
    path: function(resp, expected, argument) {
      try {
        var result = jmespath.search(resp.data, argument);
      } catch (err) {
        return false;
      }

      return jmespath.strictDeepEqual(result,expected);
    },

    pathAll: function(resp, expected, argument) {
      try {
        var results = jmespath.search(resp.data, argument);
      } catch (err) {
        return false;
      }

      if (!Array.isArray(results)) results = [results];
      var numResults = results.length;
      if (!numResults) return false;
      for (var ind = 0 ; ind < numResults; ind++) {
        if (!jmespath.strictDeepEqual(results[ind], expected)) {
          return false;
        }
      }
      return true;
    },

    pathAny: function(resp, expected, argument) {
      try {
        var results = jmespath.search(resp.data, argument);
      } catch (err) {
        return false;
      }

      if (!Array.isArray(results)) results = [results];
      var numResults = results.length;
      for (var ind = 0 ; ind < numResults; ind++) {
        if (jmespath.strictDeepEqual(results[ind], expected)) {
          return true;
        }
      }
      return false;
    },

    status: function(resp, expected) {
      var statusCode = resp.httpResponse.statusCode;
      return (typeof statusCode === 'number') && (statusCode === expected);
    },

    error: function(resp, expected) {
      if (typeof expected === 'string' && resp.error) {
        return expected === resp.error.code;
      }
      // if expected is not string, can be boolean indicating presence of error
      return expected === !!resp.error;
    }
  },

  listeners: new AWS.SequentialExecutor().addNamedListeners(function(add) {
    add('RETRY_CHECK', 'retry', function(resp) {
      var waiter = resp.request._waiter;
      if (resp.error && resp.error.code === 'ResourceNotReady') {
        resp.error.retryDelay = (waiter.config.delay || 0) * 1000;
      }
    });

    add('CHECK_OUTPUT', 'extractData', CHECK_ACCEPTORS);

    add('CHECK_ERROR', 'extractError', CHECK_ACCEPTORS);
  }),

  /**
   * @return [AWS.Request]
   */
  wait: function wait(params, callback) {
    if (typeof params === 'function') {
      callback = params; params = undefined;
    }

    if (params && params.$waiter) {
      params = AWS.util.copy(params);
      if (typeof params.$waiter.delay === 'number') {
        this.config.delay = params.$waiter.delay;
      }
      if (typeof params.$waiter.maxAttempts === 'number') {
        this.config.maxAttempts = params.$waiter.maxAttempts;
      }
      delete params.$waiter;
    }

    var request = this.service.makeRequest(this.config.operation, params);
    request._waiter = this;
    request.response.maxRetries = this.config.maxAttempts;
    request.addListeners(this.listeners);

    if (callback) request.send(callback);
    return request;
  },

  setSuccess: function setSuccess(resp) {
    resp.error = null;
    resp.data = resp.data || {};
    resp.request.removeAllListeners('extractData');
  },

  setError: function setError(resp, retryable) {
    resp.data = null;
    resp.error = AWS.util.error(resp.error || new Error(), {
      code: 'ResourceNotReady',
      message: 'Resource is not in the state ' + this.state,
      retryable: retryable
    });
  },

  /**
   * Loads waiter configuration from API configuration
   *
   * @api private
   */
  loadWaiterConfig: function loadWaiterConfig(state) {
    if (!this.service.api.waiters[state]) {
      throw new AWS.util.error(new Error(), {
        code: 'StateNotFoundError',
        message: 'State ' + state + ' not found.'
      });
    }

    this.config = AWS.util.copy(this.service.api.waiters[state]);
  }
});

},{"./core":324,"jmespath":411}],371:[function(require,module,exports){
var AWS = require('./core');
var inherit = AWS.util.inherit;
var jmespath = require('jmespath');

/**
 * This class encapsulates the response information
 * from a service request operation sent through {AWS.Request}.
 * The response object has two main properties for getting information
 * back from a request:
 *
 * ## The `data` property
 *
 * The `response.data` property contains the serialized object data
 * retrieved from the service request. For instance, for an
 * Amazon DynamoDB `listTables` method call, the response data might
 * look like:
 *
 * ```
 * > resp.data
 * { TableNames:
 *    [ 'table1', 'table2', ... ] }
 * ```
 *
 * The `data` property can be null if an error occurs (see below).
 *
 * ## The `error` property
 *
 * In the event of a service error (or transfer error), the
 * `response.error` property will be filled with the given
 * error data in the form:
 *
 * ```
 * { code: 'SHORT_UNIQUE_ERROR_CODE',
 *   message: 'Some human readable error message' }
 * ```
 *
 * In the case of an error, the `data` property will be `null`.
 * Note that if you handle events that can be in a failure state,
 * you should always check whether `response.error` is set
 * before attempting to access the `response.data` property.
 *
 * @!attribute data
 *   @readonly
 *   @!group Data Properties
 *   @note Inside of a {AWS.Request~httpData} event, this
 *     property contains a single raw packet instead of the
 *     full de-serialized service response.
 *   @return [Object] the de-serialized response data
 *     from the service.
 *
 * @!attribute error
 *   An structure containing information about a service
 *   or networking error.
 *   @readonly
 *   @!group Data Properties
 *   @note This attribute is only filled if a service or
 *     networking error occurs.
 *   @return [Error]
 *     * code [String] a unique short code representing the
 *       error that was emitted.
 *     * message [String] a longer human readable error message
 *     * retryable [Boolean] whether the error message is
 *       retryable.
 *     * statusCode [Numeric] in the case of a request that reached the service,
 *       this value contains the response status code.
 *     * time [Date] the date time object when the error occurred.
 *     * hostname [String] set when a networking error occurs to easily
 *       identify the endpoint of the request.
 *     * region [String] set when a networking error occurs to easily
 *       identify the region of the request.
 *
 * @!attribute requestId
 *   @readonly
 *   @!group Data Properties
 *   @return [String] the unique request ID associated with the response.
 *     Log this value when debugging requests for AWS support.
 *
 * @!attribute retryCount
 *   @readonly
 *   @!group Operation Properties
 *   @return [Integer] the number of retries that were
 *     attempted before the request was completed.
 *
 * @!attribute redirectCount
 *   @readonly
 *   @!group Operation Properties
 *   @return [Integer] the number of redirects that were
 *     followed before the request was completed.
 *
 * @!attribute httpResponse
 *   @readonly
 *   @!group HTTP Properties
 *   @return [AWS.HttpResponse] the raw HTTP response object
 *     containing the response headers and body information
 *     from the server.
 *
 * @see AWS.Request
 */
AWS.Response = inherit({

  /**
   * @api private
   */
  constructor: function Response(request) {
    this.request = request;
    this.data = null;
    this.error = null;
    this.retryCount = 0;
    this.redirectCount = 0;
    this.httpResponse = new AWS.HttpResponse();
    if (request) {
      this.maxRetries = request.service.numRetries();
      this.maxRedirects = request.service.config.maxRedirects;
    }
  },

  /**
   * Creates a new request for the next page of response data, calling the
   * callback with the page data if a callback is provided.
   *
   * @callback callback function(err, data)
   *   Called when a page of data is returned from the next request.
   *
   *   @param err [Error] an error object, if an error occurred in the request
   *   @param data [Object] the next page of data, or null, if there are no
   *     more pages left.
   * @return [AWS.Request] the request object for the next page of data
   * @return [null] if no callback is provided and there are no pages left
   *   to retrieve.
   * @since v1.4.0
   */
  nextPage: function nextPage(callback) {
    var config;
    var service = this.request.service;
    var operation = this.request.operation;
    try {
      config = service.paginationConfig(operation, true);
    } catch (e) { this.error = e; }

    if (!this.hasNextPage()) {
      if (callback) callback(this.error, null);
      else if (this.error) throw this.error;
      return null;
    }

    var params = AWS.util.copy(this.request.params);
    if (!this.nextPageTokens) {
      return callback ? callback(null, null) : null;
    } else {
      var inputTokens = config.inputToken;
      if (typeof inputTokens === 'string') inputTokens = [inputTokens];
      for (var i = 0; i < inputTokens.length; i++) {
        params[inputTokens[i]] = this.nextPageTokens[i];
      }
      return service.makeRequest(this.request.operation, params, callback);
    }
  },

  /**
   * @return [Boolean] whether more pages of data can be returned by further
   *   requests
   * @since v1.4.0
   */
  hasNextPage: function hasNextPage() {
    this.cacheNextPageTokens();
    if (this.nextPageTokens) return true;
    if (this.nextPageTokens === undefined) return undefined;
    else return false;
  },

  /**
   * @api private
   */
  cacheNextPageTokens: function cacheNextPageTokens() {
    if (Object.prototype.hasOwnProperty.call(this, 'nextPageTokens')) return this.nextPageTokens;
    this.nextPageTokens = undefined;

    var config = this.request.service.paginationConfig(this.request.operation);
    if (!config) return this.nextPageTokens;

    this.nextPageTokens = null;
    if (config.moreResults) {
      if (!jmespath.search(this.data, config.moreResults)) {
        return this.nextPageTokens;
      }
    }

    var exprs = config.outputToken;
    if (typeof exprs === 'string') exprs = [exprs];
    AWS.util.arrayEach.call(this, exprs, function (expr) {
      var output = jmespath.search(this.data, expr);
      if (output) {
        this.nextPageTokens = this.nextPageTokens || [];
        this.nextPageTokens.push(output);
      }
    });

    return this.nextPageTokens;
  }

});

},{"./core":324,"jmespath":411}],372:[function(require,module,exports){
var AWS = require('../core');
var byteLength = AWS.util.string.byteLength;
var Buffer = AWS.util.Buffer;

/**
 * The managed uploader allows for easy and efficient uploading of buffers,
 * blobs, or streams, using a configurable amount of concurrency to perform
 * multipart uploads where possible. This abstraction also enables uploading
 * streams of unknown size due to the use of multipart uploads.
 *
 * To construct a managed upload object, see the {constructor} function.
 *
 * ## Tracking upload progress
 *
 * The managed upload object can also track progress by attaching an
 * 'httpUploadProgress' listener to the upload manager. This event is similar
 * to {AWS.Request~httpUploadProgress} but groups all concurrent upload progress
 * into a single event. See {AWS.S3.ManagedUpload~httpUploadProgress} for more
 * information.
 *
 * ## Handling Multipart Cleanup
 *
 * By default, this class will automatically clean up any multipart uploads
 * when an individual part upload fails. This behavior can be disabled in order
 * to manually handle failures by setting the `leavePartsOnError` configuration
 * option to `true` when initializing the upload object.
 *
 * @!event httpUploadProgress(progress)
 *   Triggered when the uploader has uploaded more data.
 *   @note The `total` property may not be set if the stream being uploaded has
 *     not yet finished chunking. In this case the `total` will be undefined
 *     until the total stream size is known.
 *   @note This event will not be emitted in Node.js 0.8.x.
 *   @param progress [map] An object containing the `loaded` and `total` bytes
 *     of the request and the `key` of the S3 object. Note that `total` may be undefined until the payload
 *     size is known.
 *   @context (see AWS.Request~send)
 */
AWS.S3.ManagedUpload = AWS.util.inherit({
  /**
   * Creates a managed upload object with a set of configuration options.
   *
   * @note A "Body" parameter is required to be set prior to calling {send}.
   * @note In Node.js, sending "Body" as {https://nodejs.org/dist/latest/docs/api/stream.html#stream_object_mode object-mode stream}
   *   may result in upload hangs. Using buffer stream is preferable.
   * @option options params [map] a map of parameters to pass to the upload
   *   requests. The "Body" parameter is required to be specified either on
   *   the service or in the params option.
   * @note ContentMD5 should not be provided when using the managed upload object.
   *   Instead, setting "computeChecksums" to true will enable automatic ContentMD5 generation
   *   by the managed upload object.
   * @option options queueSize [Number] (4) the size of the concurrent queue
   *   manager to upload parts in parallel. Set to 1 for synchronous uploading
   *   of parts. Note that the uploader will buffer at most queueSize * partSize
   *   bytes into memory at any given time.
   * @option options partSize [Number] (5mb) the size in bytes for each
   *   individual part to be uploaded. Adjust the part size to ensure the number
   *   of parts does not exceed {maxTotalParts}. See {minPartSize} for the
   *   minimum allowed part size.
   * @option options leavePartsOnError [Boolean] (false) whether to abort the
   *   multipart upload if an error occurs. Set to true if you want to handle
   *   failures manually.
   * @option options service [AWS.S3] an optional S3 service object to use for
   *   requests. This object might have bound parameters used by the uploader.
   * @option options tags [Array<map>] The tags to apply to the uploaded object.
   *   Each tag should have a `Key` and `Value` keys.
   * @example Creating a default uploader for a stream object
   *   var upload = new AWS.S3.ManagedUpload({
   *     params: {Bucket: 'bucket', Key: 'key', Body: stream}
   *   });
   * @example Creating an uploader with concurrency of 1 and partSize of 10mb
   *   var upload = new AWS.S3.ManagedUpload({
   *     partSize: 10 * 1024 * 1024, queueSize: 1,
   *     params: {Bucket: 'bucket', Key: 'key', Body: stream}
   *   });
   * @example Creating an uploader with tags
   *   var upload = new AWS.S3.ManagedUpload({
   *     params: {Bucket: 'bucket', Key: 'key', Body: stream},
   *     tags: [{Key: 'tag1', Value: 'value1'}, {Key: 'tag2', Value: 'value2'}]
   *   });
   * @see send
   */
  constructor: function ManagedUpload(options) {
    var self = this;
    AWS.SequentialExecutor.call(self);
    self.body = null;
    self.sliceFn = null;
    self.callback = null;
    self.parts = {};
    self.completeInfo = [];
    self.fillQueue = function() {
      self.callback(new Error('Unsupported body payload ' + typeof self.body));
    };

    self.configure(options);
  },

  /**
   * @api private
   */
  configure: function configure(options) {
    options = options || {};
    this.partSize = this.minPartSize;

    if (options.queueSize) this.queueSize = options.queueSize;
    if (options.partSize) this.partSize = options.partSize;
    if (options.leavePartsOnError) this.leavePartsOnError = true;
    if (options.tags) {
      if (!Array.isArray(options.tags)) {
        throw new Error('Tags must be specified as an array; ' +
          typeof options.tags + ' provided.');
      }
      this.tags = options.tags;
    }

    if (this.partSize < this.minPartSize) {
      throw new Error('partSize must be greater than ' +
                      this.minPartSize);
    }

    this.service = options.service;
    this.bindServiceObject(options.params);
    this.validateBody();
    this.adjustTotalBytes();
  },

  /**
   * @api private
   */
  leavePartsOnError: false,

  /**
   * @api private
   */
  queueSize: 4,

  /**
   * @api private
   */
  partSize: null,

  /**
   * @readonly
   * @return [Number] the minimum number of bytes for an individual part
   *   upload.
   */
  minPartSize: 1024 * 1024 * 5,

  /**
   * @readonly
   * @return [Number] the maximum allowed number of parts in a multipart upload.
   */
  maxTotalParts: 10000,

  /**
   * Initiates the managed upload for the payload.
   *
   * @callback callback function(err, data)
   *   @param err [Error] an error or null if no error occurred.
   *   @param data [map] The response data from the successful upload:
   *     * `Location` (String) the URL of the uploaded object
   *     * `ETag` (String) the ETag of the uploaded object
   *     * `Bucket` (String) the bucket to which the object was uploaded
   *     * `Key` (String) the key to which the object was uploaded
   * @example Sending a managed upload object
   *   var params = {Bucket: 'bucket', Key: 'key', Body: stream};
   *   var upload = new AWS.S3.ManagedUpload({params: params});
   *   upload.send(function(err, data) {
   *     console.log(err, data);
   *   });
   */
  send: function(callback) {
    var self = this;
    self.failed = false;
    self.callback = callback || function(err) { if (err) throw err; };

    var runFill = true;
    if (self.sliceFn) {
      self.fillQueue = self.fillBuffer;
    } else if (AWS.util.isNode()) {
      var Stream = AWS.util.stream.Stream;
      if (self.body instanceof Stream) {
        runFill = false;
        self.fillQueue = self.fillStream;
        self.partBuffers = [];
        self.body.
          on('error', function(err) { self.cleanup(err); }).
          on('readable', function() { self.fillQueue(); }).
          on('end', function() {
            self.isDoneChunking = true;
            self.numParts = self.totalPartNumbers;
            self.fillQueue.call(self);

            if (self.isDoneChunking && self.totalPartNumbers >= 1 && self.doneParts === self.numParts) {
              self.finishMultiPart();
            }
          });
      }
    }

    if (runFill) self.fillQueue.call(self);
  },

  /**
   * @!method  promise()
   *   Returns a 'thenable' promise.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @callback fulfilledCallback function(data)
   *     Called if the promise is fulfilled.
   *     @param data [map] The response data from the successful upload:
   *       `Location` (String) the URL of the uploaded object
   *       `ETag` (String) the ETag of the uploaded object
   *       `Bucket` (String) the bucket to which the object was uploaded
   *       `Key` (String) the key to which the object was uploaded
   *   @callback rejectedCallback function(err)
   *     Called if the promise is rejected.
   *     @param err [Error] an error or null if no error occurred.
   *   @return [Promise] A promise that represents the state of the upload request.
   *   @example Sending an upload request using promises.
   *     var upload = s3.upload({Bucket: 'bucket', Key: 'key', Body: stream});
   *     var promise = upload.promise();
   *     promise.then(function(data) { ... }, function(err) { ... });
   */

  /**
   * Aborts a managed upload, including all concurrent upload requests.
   * @note By default, calling this function will cleanup a multipart upload
   *   if one was created. To leave the multipart upload around after aborting
   *   a request, configure `leavePartsOnError` to `true` in the {constructor}.
   * @note Calling {abort} in the browser environment will not abort any requests
   *   that are already in flight. If a multipart upload was created, any parts
   *   not yet uploaded will not be sent, and the multipart upload will be cleaned up.
   * @example Aborting an upload
   *   var params = {
   *     Bucket: 'bucket', Key: 'key',
   *     Body: Buffer.alloc(1024 * 1024 * 25) // 25MB payload
   *   };
   *   var upload = s3.upload(params);
   *   upload.send(function (err, data) {
   *     if (err) console.log("Error:", err.code, err.message);
   *     else console.log(data);
   *   });
   *
   *   // abort request in 1 second
   *   setTimeout(upload.abort.bind(upload), 1000);
   */
  abort: function() {
    var self = this;
    //abort putObject request
    if (self.isDoneChunking === true && self.totalPartNumbers === 1 && self.singlePart) {
      self.singlePart.abort();
    } else {
      self.cleanup(AWS.util.error(new Error('Request aborted by user'), {
        code: 'RequestAbortedError', retryable: false
      }));
    }
  },

  /**
   * @api private
   */
  validateBody: function validateBody() {
    var self = this;
    self.body = self.service.config.params.Body;
    if (typeof self.body === 'string') {
      self.body = AWS.util.buffer.toBuffer(self.body);
    } else if (!self.body) {
      throw new Error('params.Body is required');
    }
    self.sliceFn = AWS.util.arraySliceFn(self.body);
  },

  /**
   * @api private
   */
  bindServiceObject: function bindServiceObject(params) {
    params = params || {};
    var self = this;
    // bind parameters to new service object
    if (!self.service) {
      self.service = new AWS.S3({params: params});
    } else {
      var service = self.service;
      var config = AWS.util.copy(service._originalConfig || {});
      config.signatureVersion = service.getSignatureVersion();
      self.service = new service.constructor.__super__(config);
      self.service.config.params =
        AWS.util.merge(self.service.config.params || {}, params);
    }
  },

  /**
   * @api private
   */
  adjustTotalBytes: function adjustTotalBytes() {
    var self = this;
    try { // try to get totalBytes
      self.totalBytes = byteLength(self.body);
    } catch (e) { }

    // try to adjust partSize if we know payload length
    if (self.totalBytes) {
      var newPartSize = Math.ceil(self.totalBytes / self.maxTotalParts);
      if (newPartSize > self.partSize) self.partSize = newPartSize;
    } else {
      self.totalBytes = undefined;
    }
  },

  /**
   * @api private
   */
  isDoneChunking: false,

  /**
   * @api private
   */
  partPos: 0,

  /**
   * @api private
   */
  totalChunkedBytes: 0,

  /**
   * @api private
   */
  totalUploadedBytes: 0,

  /**
   * @api private
   */
  totalBytes: undefined,

  /**
   * @api private
   */
  numParts: 0,

  /**
   * @api private
   */
  totalPartNumbers: 0,

  /**
   * @api private
   */
  activeParts: 0,

  /**
   * @api private
   */
  doneParts: 0,

  /**
   * @api private
   */
  parts: null,

  /**
   * @api private
   */
  completeInfo: null,

  /**
   * @api private
   */
  failed: false,

  /**
   * @api private
   */
  multipartReq: null,

  /**
   * @api private
   */
  partBuffers: null,

  /**
   * @api private
   */
  partBufferLength: 0,

  /**
   * @api private
   */
  fillBuffer: function fillBuffer() {
    var self = this;
    var bodyLen = byteLength(self.body);

    if (bodyLen === 0) {
      self.isDoneChunking = true;
      self.numParts = 1;
      self.nextChunk(self.body);
      return;
    }

    while (self.activeParts < self.queueSize && self.partPos < bodyLen) {
      var endPos = Math.min(self.partPos + self.partSize, bodyLen);
      var buf = self.sliceFn.call(self.body, self.partPos, endPos);
      self.partPos += self.partSize;

      if (byteLength(buf) < self.partSize || self.partPos === bodyLen) {
        self.isDoneChunking = true;
        self.numParts = self.totalPartNumbers + 1;
      }
      self.nextChunk(buf);
    }
  },

  /**
   * @api private
   */
  fillStream: function fillStream() {
    var self = this;
    if (self.activeParts >= self.queueSize) return;

    var buf = self.body.read(self.partSize - self.partBufferLength) ||
              self.body.read();
    if (buf) {
      self.partBuffers.push(buf);
      self.partBufferLength += buf.length;
      self.totalChunkedBytes += buf.length;
    }

    if (self.partBufferLength >= self.partSize) {
      // if we have single buffer we avoid copyfull concat
      var pbuf = self.partBuffers.length === 1 ?
        self.partBuffers[0] : Buffer.concat(self.partBuffers);
      self.partBuffers = [];
      self.partBufferLength = 0;

      // if we have more than partSize, push the rest back on the queue
      if (pbuf.length > self.partSize) {
        var rest = pbuf.slice(self.partSize);
        self.partBuffers.push(rest);
        self.partBufferLength += rest.length;
        pbuf = pbuf.slice(0, self.partSize);
      }

      self.nextChunk(pbuf);
    }

    if (self.isDoneChunking && !self.isDoneSending) {
      // if we have single buffer we avoid copyfull concat
      pbuf = self.partBuffers.length === 1 ?
          self.partBuffers[0] : Buffer.concat(self.partBuffers);
      self.partBuffers = [];
      self.partBufferLength = 0;
      self.totalBytes = self.totalChunkedBytes;
      self.isDoneSending = true;

      if (self.numParts === 0 || pbuf.length > 0) {
        self.numParts++;
        self.nextChunk(pbuf);
      }
    }

    self.body.read(0);
  },

  /**
   * @api private
   */
  nextChunk: function nextChunk(chunk) {
    var self = this;
    if (self.failed) return null;

    var partNumber = ++self.totalPartNumbers;
    if (self.isDoneChunking && partNumber === 1) {
      var params = {Body: chunk};
      if (this.tags) {
        params.Tagging = this.getTaggingHeader();
      }
      var req = self.service.putObject(params);
      req._managedUpload = self;
      req.on('httpUploadProgress', self.progress).send(self.finishSinglePart);
      self.singlePart = req; //save the single part request
      return null;
    } else if (self.service.config.params.ContentMD5) {
      var err = AWS.util.error(new Error('The Content-MD5 you specified is invalid for multi-part uploads.'), {
        code: 'InvalidDigest', retryable: false
      });

      self.cleanup(err);
      return null;
    }

    if (self.completeInfo[partNumber] && self.completeInfo[partNumber].ETag !== null) {
      return null; // Already uploaded this part.
    }

    self.activeParts++;
    if (!self.service.config.params.UploadId) {

      if (!self.multipartReq) { // create multipart
        self.multipartReq = self.service.createMultipartUpload();
        self.multipartReq.on('success', function(resp) {
          self.service.config.params.UploadId = resp.data.UploadId;
          self.multipartReq = null;
        });
        self.queueChunks(chunk, partNumber);
        self.multipartReq.on('error', function(err) {
          self.cleanup(err);
        });
        self.multipartReq.send();
      } else {
        self.queueChunks(chunk, partNumber);
      }
    } else { // multipart is created, just send
      self.uploadPart(chunk, partNumber);
    }
  },

  /**
   * @api private
   */
  getTaggingHeader: function getTaggingHeader() {
    var kvPairStrings = [];
    for (var i = 0; i < this.tags.length; i++) {
      kvPairStrings.push(AWS.util.uriEscape(this.tags[i].Key) + '=' +
        AWS.util.uriEscape(this.tags[i].Value));
    }

    return kvPairStrings.join('&');
  },

  /**
   * @api private
   */
  uploadPart: function uploadPart(chunk, partNumber) {
    var self = this;

    var partParams = {
      Body: chunk,
      ContentLength: AWS.util.string.byteLength(chunk),
      PartNumber: partNumber
    };

    var partInfo = {ETag: null, PartNumber: partNumber};
    self.completeInfo[partNumber] = partInfo;

    var req = self.service.uploadPart(partParams);
    self.parts[partNumber] = req;
    req._lastUploadedBytes = 0;
    req._managedUpload = self;
    req.on('httpUploadProgress', self.progress);
    req.send(function(err, data) {
      delete self.parts[partParams.PartNumber];
      self.activeParts--;

      if (!err && (!data || !data.ETag)) {
        var message = 'No access to ETag property on response.';
        if (AWS.util.isBrowser()) {
          message += ' Check CORS configuration to expose ETag header.';
        }

        err = AWS.util.error(new Error(message), {
          code: 'ETagMissing', retryable: false
        });
      }
      if (err) return self.cleanup(err);
      //prevent sending part being returned twice (https://github.com/aws/aws-sdk-js/issues/2304)
      if (self.completeInfo[partNumber] && self.completeInfo[partNumber].ETag !== null) return null;
      partInfo.ETag = data.ETag;
      self.doneParts++;
      if (self.isDoneChunking && self.doneParts === self.numParts) {
        self.finishMultiPart();
      } else {
        self.fillQueue.call(self);
      }
    });
  },

  /**
   * @api private
   */
  queueChunks: function queueChunks(chunk, partNumber) {
    var self = this;
    self.multipartReq.on('success', function() {
      self.uploadPart(chunk, partNumber);
    });
  },

  /**
   * @api private
   */
  cleanup: function cleanup(err) {
    var self = this;
    if (self.failed) return;

    // clean up stream
    if (typeof self.body.removeAllListeners === 'function' &&
        typeof self.body.resume === 'function') {
      self.body.removeAllListeners('readable');
      self.body.removeAllListeners('end');
      self.body.resume();
    }

    // cleanup multipartReq listeners
    if (self.multipartReq) {
      self.multipartReq.removeAllListeners('success');
      self.multipartReq.removeAllListeners('error');
      self.multipartReq.removeAllListeners('complete');
      delete self.multipartReq;
    }

    if (self.service.config.params.UploadId && !self.leavePartsOnError) {
      self.service.abortMultipartUpload().send();
    } else if (self.leavePartsOnError) {
      self.isDoneChunking = false;
    }

    AWS.util.each(self.parts, function(partNumber, part) {
      part.removeAllListeners('complete');
      part.abort();
    });

    self.activeParts = 0;
    self.partPos = 0;
    self.numParts = 0;
    self.totalPartNumbers = 0;
    self.parts = {};
    self.failed = true;
    self.callback(err);
  },

  /**
   * @api private
   */
  finishMultiPart: function finishMultiPart() {
    var self = this;
    var completeParams = { MultipartUpload: { Parts: self.completeInfo.slice(1) } };
    self.service.completeMultipartUpload(completeParams, function(err, data) {
      if (err) {
        return self.cleanup(err);
      }

      if (data && typeof data.Location === 'string') {
        data.Location = data.Location.replace(/%2F/g, '/');
      }

      if (Array.isArray(self.tags)) {
        for (var i = 0; i < self.tags.length; i++) {
          self.tags[i].Value = String(self.tags[i].Value);
        }
        self.service.putObjectTagging(
          {Tagging: {TagSet: self.tags}},
          function(e, d) {
            if (e) {
              self.callback(e);
            } else {
              self.callback(e, data);
            }
          }
        );
      } else {
        self.callback(err, data);
      }
    });
  },

  /**
   * @api private
   */
  finishSinglePart: function finishSinglePart(err, data) {
    var upload = this.request._managedUpload;
    var httpReq = this.request.httpRequest;
    var endpoint = httpReq.endpoint;
    if (err) return upload.callback(err);
    data.Location =
      [endpoint.protocol, '//', endpoint.host, httpReq.path].join('');
    data.key = this.request.params.Key; // will stay undocumented
    data.Key = this.request.params.Key;
    data.Bucket = this.request.params.Bucket;
    upload.callback(err, data);
  },

  /**
   * @api private
   */
  progress: function progress(info) {
    var upload = this._managedUpload;
    if (this.operation === 'putObject') {
      info.part = 1;
      info.key = this.params.Key;
    } else {
      upload.totalUploadedBytes += info.loaded - this._lastUploadedBytes;
      this._lastUploadedBytes = info.loaded;
      info = {
        loaded: upload.totalUploadedBytes,
        total: upload.totalBytes,
        part: this.params.PartNumber,
        key: this.params.Key
      };
    }
    upload.emit('httpUploadProgress', [info]);
  }
});

AWS.util.mixin(AWS.S3.ManagedUpload, AWS.SequentialExecutor);

/**
 * @api private
 */
AWS.S3.ManagedUpload.addPromisesToClass = function addPromisesToClass(PromiseDependency) {
  this.prototype.promise = AWS.util.promisifyMethod('send', PromiseDependency);
};

/**
 * @api private
 */
AWS.S3.ManagedUpload.deletePromisesFromClass = function deletePromisesFromClass() {
  delete this.prototype.promise;
};

AWS.util.addPromises(AWS.S3.ManagedUpload);

/**
 * @api private
 */
module.exports = AWS.S3.ManagedUpload;

},{"../core":324}],373:[function(require,module,exports){
var AWS = require('./core');

/**
 * @api private
 * @!method on(eventName, callback)
 *   Registers an event listener callback for the event given by `eventName`.
 *   Parameters passed to the callback function depend on the individual event
 *   being triggered. See the event documentation for those parameters.
 *
 *   @param eventName [String] the event name to register the listener for
 *   @param callback [Function] the listener callback function
 *   @param toHead [Boolean] attach the listener callback to the head of callback array if set to true.
 *     Default to be false.
 *   @return [AWS.SequentialExecutor] the same object for chaining
 */
AWS.SequentialExecutor = AWS.util.inherit({

  constructor: function SequentialExecutor() {
    this._events = {};
  },

  /**
   * @api private
   */
  listeners: function listeners(eventName) {
    return this._events[eventName] ? this._events[eventName].slice(0) : [];
  },

  on: function on(eventName, listener, toHead) {
    if (this._events[eventName]) {
      toHead ?
        this._events[eventName].unshift(listener) :
        this._events[eventName].push(listener);
    } else {
      this._events[eventName] = [listener];
    }
    return this;
  },

  onAsync: function onAsync(eventName, listener, toHead) {
    listener._isAsync = true;
    return this.on(eventName, listener, toHead);
  },

  removeListener: function removeListener(eventName, listener) {
    var listeners = this._events[eventName];
    if (listeners) {
      var length = listeners.length;
      var position = -1;
      for (var i = 0; i < length; ++i) {
        if (listeners[i] === listener) {
          position = i;
        }
      }
      if (position > -1) {
        listeners.splice(position, 1);
      }
    }
    return this;
  },

  removeAllListeners: function removeAllListeners(eventName) {
    if (eventName) {
      delete this._events[eventName];
    } else {
      this._events = {};
    }
    return this;
  },

  /**
   * @api private
   */
  emit: function emit(eventName, eventArgs, doneCallback) {
    if (!doneCallback) doneCallback = function() { };
    var listeners = this.listeners(eventName);
    var count = listeners.length;
    this.callListeners(listeners, eventArgs, doneCallback);
    return count > 0;
  },

  /**
   * @api private
   */
  callListeners: function callListeners(listeners, args, doneCallback, prevError) {
    var self = this;
    var error = prevError || null;

    function callNextListener(err) {
      if (err) {
        error = AWS.util.error(error || new Error(), err);
        if (self._haltHandlersOnError) {
          return doneCallback.call(self, error);
        }
      }
      self.callListeners(listeners, args, doneCallback, error);
    }

    while (listeners.length > 0) {
      var listener = listeners.shift();
      if (listener._isAsync) { // asynchronous listener
        listener.apply(self, args.concat([callNextListener]));
        return; // stop here, callNextListener will continue
      } else { // synchronous listener
        try {
          listener.apply(self, args);
        } catch (err) {
          error = AWS.util.error(error || new Error(), err);
        }
        if (error && self._haltHandlersOnError) {
          doneCallback.call(self, error);
          return;
        }
      }
    }
    doneCallback.call(self, error);
  },

  /**
   * Adds or copies a set of listeners from another list of
   * listeners or SequentialExecutor object.
   *
   * @param listeners [map<String,Array<Function>>, AWS.SequentialExecutor]
   *   a list of events and callbacks, or an event emitter object
   *   containing listeners to add to this emitter object.
   * @return [AWS.SequentialExecutor] the emitter object, for chaining.
   * @example Adding listeners from a map of listeners
   *   emitter.addListeners({
   *     event1: [function() { ... }, function() { ... }],
   *     event2: [function() { ... }]
   *   });
   *   emitter.emit('event1'); // emitter has event1
   *   emitter.emit('event2'); // emitter has event2
   * @example Adding listeners from another emitter object
   *   var emitter1 = new AWS.SequentialExecutor();
   *   emitter1.on('event1', function() { ... });
   *   emitter1.on('event2', function() { ... });
   *   var emitter2 = new AWS.SequentialExecutor();
   *   emitter2.addListeners(emitter1);
   *   emitter2.emit('event1'); // emitter2 has event1
   *   emitter2.emit('event2'); // emitter2 has event2
   */
  addListeners: function addListeners(listeners) {
    var self = this;

    // extract listeners if parameter is an SequentialExecutor object
    if (listeners._events) listeners = listeners._events;

    AWS.util.each(listeners, function(event, callbacks) {
      if (typeof callbacks === 'function') callbacks = [callbacks];
      AWS.util.arrayEach(callbacks, function(callback) {
        self.on(event, callback);
      });
    });

    return self;
  },

  /**
   * Registers an event with {on} and saves the callback handle function
   * as a property on the emitter object using a given `name`.
   *
   * @param name [String] the property name to set on this object containing
   *   the callback function handle so that the listener can be removed in
   *   the future.
   * @param (see on)
   * @return (see on)
   * @example Adding a named listener DATA_CALLBACK
   *   var listener = function() { doSomething(); };
   *   emitter.addNamedListener('DATA_CALLBACK', 'data', listener);
   *
   *   // the following prints: true
   *   console.log(emitter.DATA_CALLBACK == listener);
   */
  addNamedListener: function addNamedListener(name, eventName, callback, toHead) {
    this[name] = callback;
    this.addListener(eventName, callback, toHead);
    return this;
  },

  /**
   * @api private
   */
  addNamedAsyncListener: function addNamedAsyncListener(name, eventName, callback, toHead) {
    callback._isAsync = true;
    return this.addNamedListener(name, eventName, callback, toHead);
  },

  /**
   * Helper method to add a set of named listeners using
   * {addNamedListener}. The callback contains a parameter
   * with a handle to the `addNamedListener` method.
   *
   * @callback callback function(add)
   *   The callback function is called immediately in order to provide
   *   the `add` function to the block. This simplifies the addition of
   *   a large group of named listeners.
   *   @param add [Function] the {addNamedListener} function to call
   *     when registering listeners.
   * @example Adding a set of named listeners
   *   emitter.addNamedListeners(function(add) {
   *     add('DATA_CALLBACK', 'data', function() { ... });
   *     add('OTHER', 'otherEvent', function() { ... });
   *     add('LAST', 'lastEvent', function() { ... });
   *   });
   *
   *   // these properties are now set:
   *   emitter.DATA_CALLBACK;
   *   emitter.OTHER;
   *   emitter.LAST;
   */
  addNamedListeners: function addNamedListeners(callback) {
    var self = this;
    callback(
      function() {
        self.addNamedListener.apply(self, arguments);
      },
      function() {
        self.addNamedAsyncListener.apply(self, arguments);
      }
    );
    return this;
  }
});

/**
 * {on} is the prefered method.
 * @api private
 */
AWS.SequentialExecutor.prototype.addListener = AWS.SequentialExecutor.prototype.on;

/**
 * @api private
 */
module.exports = AWS.SequentialExecutor;

},{"./core":324}],374:[function(require,module,exports){
(function (process){
var AWS = require('./core');
var Api = require('./model/api');
var regionConfig = require('./region_config');

var inherit = AWS.util.inherit;
var clientCount = 0;

/**
 * The service class representing an AWS service.
 *
 * @class_abstract This class is an abstract class.
 *
 * @!attribute apiVersions
 *   @return [Array<String>] the list of API versions supported by this service.
 *   @readonly
 */
AWS.Service = inherit({
  /**
   * Create a new service object with a configuration object
   *
   * @param config [map] a map of configuration options
   */
  constructor: function Service(config) {
    if (!this.loadServiceClass) {
      throw AWS.util.error(new Error(),
        'Service must be constructed with `new\' operator');
    }
    var ServiceClass = this.loadServiceClass(config || {});
    if (ServiceClass) {
      var originalConfig = AWS.util.copy(config);
      var svc = new ServiceClass(config);
      Object.defineProperty(svc, '_originalConfig', {
        get: function() { return originalConfig; },
        enumerable: false,
        configurable: true
      });
      svc._clientId = ++clientCount;
      return svc;
    }
    this.initialize(config);
  },

  /**
   * @api private
   */
  initialize: function initialize(config) {
    var svcConfig = AWS.config[this.serviceIdentifier];
    this.config = new AWS.Config(AWS.config);
    if (svcConfig) this.config.update(svcConfig, true);
    if (config) this.config.update(config, true);

    this.validateService();
    if (!this.config.endpoint) regionConfig.configureEndpoint(this);

    this.config.endpoint = this.endpointFromTemplate(this.config.endpoint);
    this.setEndpoint(this.config.endpoint);
    //enable attaching listeners to service client
    AWS.SequentialExecutor.call(this);
    AWS.Service.addDefaultMonitoringListeners(this);
    if ((this.config.clientSideMonitoring || AWS.Service._clientSideMonitoring) && this.publisher) {
      var publisher = this.publisher;
      this.addNamedListener('PUBLISH_API_CALL', 'apiCall', function PUBLISH_API_CALL(event) {
        process.nextTick(function() {publisher.eventHandler(event);});
      });
      this.addNamedListener('PUBLISH_API_ATTEMPT', 'apiCallAttempt', function PUBLISH_API_ATTEMPT(event) {
        process.nextTick(function() {publisher.eventHandler(event);});
      });
    }
  },

  /**
   * @api private
   */
  validateService: function validateService() {
  },

  /**
   * @api private
   */
  loadServiceClass: function loadServiceClass(serviceConfig) {
    var config = serviceConfig;
    if (!AWS.util.isEmpty(this.api)) {
      return null;
    } else if (config.apiConfig) {
      return AWS.Service.defineServiceApi(this.constructor, config.apiConfig);
    } else if (!this.constructor.services) {
      return null;
    } else {
      config = new AWS.Config(AWS.config);
      config.update(serviceConfig, true);
      var version = config.apiVersions[this.constructor.serviceIdentifier];
      version = version || config.apiVersion;
      return this.getLatestServiceClass(version);
    }
  },

  /**
   * @api private
   */
  getLatestServiceClass: function getLatestServiceClass(version) {
    version = this.getLatestServiceVersion(version);
    if (this.constructor.services[version] === null) {
      AWS.Service.defineServiceApi(this.constructor, version);
    }

    return this.constructor.services[version];
  },

  /**
   * @api private
   */
  getLatestServiceVersion: function getLatestServiceVersion(version) {
    if (!this.constructor.services || this.constructor.services.length === 0) {
      throw new Error('No services defined on ' +
                      this.constructor.serviceIdentifier);
    }

    if (!version) {
      version = 'latest';
    } else if (AWS.util.isType(version, Date)) {
      version = AWS.util.date.iso8601(version).split('T')[0];
    }

    if (Object.hasOwnProperty(this.constructor.services, version)) {
      return version;
    }

    var keys = Object.keys(this.constructor.services).sort();
    var selectedVersion = null;
    for (var i = keys.length - 1; i >= 0; i--) {
      // versions that end in "*" are not available on disk and can be
      // skipped, so do not choose these as selectedVersions
      if (keys[i][keys[i].length - 1] !== '*') {
        selectedVersion = keys[i];
      }
      if (keys[i].substr(0, 10) <= version) {
        return selectedVersion;
      }
    }

    throw new Error('Could not find ' + this.constructor.serviceIdentifier +
                    ' API to satisfy version constraint `' + version + '\'');
  },

  /**
   * @api private
   */
  api: {},

  /**
   * @api private
   */
  defaultRetryCount: 3,

  /**
   * @api private
   */
  customizeRequests: function customizeRequests(callback) {
    if (!callback) {
      this.customRequestHandler = null;
    } else if (typeof callback === 'function') {
      this.customRequestHandler = callback;
    } else {
      throw new Error('Invalid callback type \'' + typeof callback + '\' provided in customizeRequests');
    }
  },

  /**
   * Calls an operation on a service with the given input parameters.
   *
   * @param operation [String] the name of the operation to call on the service.
   * @param params [map] a map of input options for the operation
   * @callback callback function(err, data)
   *   If a callback is supplied, it is called when a response is returned
   *   from the service.
   *   @param err [Error] the error object returned from the request.
   *     Set to `null` if the request is successful.
   *   @param data [Object] the de-serialized data returned from
   *     the request. Set to `null` if a request error occurs.
   */
  makeRequest: function makeRequest(operation, params, callback) {
    if (typeof params === 'function') {
      callback = params;
      params = null;
    }

    params = params || {};
    if (this.config.params) { // copy only toplevel bound params
      var rules = this.api.operations[operation];
      if (rules) {
        params = AWS.util.copy(params);
        AWS.util.each(this.config.params, function(key, value) {
          if (rules.input.members[key]) {
            if (params[key] === undefined || params[key] === null) {
              params[key] = value;
            }
          }
        });
      }
    }

    var request = new AWS.Request(this, operation, params);
    this.addAllRequestListeners(request);
    this.attachMonitoringEmitter(request);
    if (callback) request.send(callback);
    return request;
  },

  /**
   * Calls an operation on a service with the given input parameters, without
   * any authentication data. This method is useful for "public" API operations.
   *
   * @param operation [String] the name of the operation to call on the service.
   * @param params [map] a map of input options for the operation
   * @callback callback function(err, data)
   *   If a callback is supplied, it is called when a response is returned
   *   from the service.
   *   @param err [Error] the error object returned from the request.
   *     Set to `null` if the request is successful.
   *   @param data [Object] the de-serialized data returned from
   *     the request. Set to `null` if a request error occurs.
   */
  makeUnauthenticatedRequest: function makeUnauthenticatedRequest(operation, params, callback) {
    if (typeof params === 'function') {
      callback = params;
      params = {};
    }

    var request = this.makeRequest(operation, params).toUnauthenticated();
    return callback ? request.send(callback) : request;
  },

  /**
   * Waits for a given state
   *
   * @param state [String] the state on the service to wait for
   * @param params [map] a map of parameters to pass with each request
   * @option params $waiter [map] a map of configuration options for the waiter
   * @option params $waiter.delay [Number] The number of seconds to wait between
   *                                       requests
   * @option params $waiter.maxAttempts [Number] The maximum number of requests
   *                                             to send while waiting
   * @callback callback function(err, data)
   *   If a callback is supplied, it is called when a response is returned
   *   from the service.
   *   @param err [Error] the error object returned from the request.
   *     Set to `null` if the request is successful.
   *   @param data [Object] the de-serialized data returned from
   *     the request. Set to `null` if a request error occurs.
   */
  waitFor: function waitFor(state, params, callback) {
    var waiter = new AWS.ResourceWaiter(this, state);
    return waiter.wait(params, callback);
  },

  /**
   * @api private
   */
  addAllRequestListeners: function addAllRequestListeners(request) {
    var list = [AWS.events, AWS.EventListeners.Core, this.serviceInterface(),
                AWS.EventListeners.CorePost];
    for (var i = 0; i < list.length; i++) {
      if (list[i]) request.addListeners(list[i]);
    }

    // disable parameter validation
    if (!this.config.paramValidation) {
      request.removeListener('validate',
        AWS.EventListeners.Core.VALIDATE_PARAMETERS);
    }

    if (this.config.logger) { // add logging events
      request.addListeners(AWS.EventListeners.Logger);
    }

    this.setupRequestListeners(request);
    // call prototype's customRequestHandler
    if (typeof this.constructor.prototype.customRequestHandler === 'function') {
      this.constructor.prototype.customRequestHandler(request);
    }
    // call instance's customRequestHandler
    if (Object.prototype.hasOwnProperty.call(this, 'customRequestHandler') && typeof this.customRequestHandler === 'function') {
      this.customRequestHandler(request);
    }
  },

  /**
   * Event recording metrics for a whole API call.
   * @returns {object} a subset of api call metrics
   * @api private
   */
  apiCallEvent: function apiCallEvent(request) {
    var api = request.service.api.operations[request.operation];
    var monitoringEvent = {
      Type: 'ApiCall',
      Api: api ? api.name : request.operation,
      Version: 1,
      Service: request.service.api.serviceId || request.service.api.endpointPrefix,
      Region: request.httpRequest.region,
      MaxRetriesExceeded: 0,
      UserAgent: request.httpRequest.getUserAgent(),
    };
    var response = request.response;
    if (response.httpResponse.statusCode) {
      monitoringEvent.FinalHttpStatusCode = response.httpResponse.statusCode;
    }
    if (response.error) {
      var error = response.error;
      var statusCode = response.httpResponse.statusCode;
      if (statusCode > 299) {
        if (error.code) monitoringEvent.FinalAwsException = error.code;
        if (error.message) monitoringEvent.FinalAwsExceptionMessage = error.message;
      } else {
        if (error.code || error.name) monitoringEvent.FinalSdkException = error.code || error.name;
        if (error.message) monitoringEvent.FinalSdkExceptionMessage = error.message;
      }
    }
    return monitoringEvent;
  },

  /**
   * Event recording metrics for an API call attempt.
   * @returns {object} a subset of api call attempt metrics
   * @api private
   */
  apiAttemptEvent: function apiAttemptEvent(request) {
    var api = request.service.api.operations[request.operation];
    var monitoringEvent = {
      Type: 'ApiCallAttempt',
      Api: api ? api.name : request.operation,
      Version: 1,
      Service: request.service.api.serviceId || request.service.api.endpointPrefix,
      Fqdn: request.httpRequest.endpoint.hostname,
      UserAgent: request.httpRequest.getUserAgent(),
    };
    var response = request.response;
    if (response.httpResponse.statusCode) {
      monitoringEvent.HttpStatusCode = response.httpResponse.statusCode;
    }
    if (
      !request._unAuthenticated &&
      request.service.config.credentials &&
      request.service.config.credentials.accessKeyId
    ) {
      monitoringEvent.AccessKey = request.service.config.credentials.accessKeyId;
    }
    if (!response.httpResponse.headers) return monitoringEvent;
    if (request.httpRequest.headers['x-amz-security-token']) {
      monitoringEvent.SessionToken = request.httpRequest.headers['x-amz-security-token'];
    }
    if (response.httpResponse.headers['x-amzn-requestid']) {
      monitoringEvent.XAmznRequestId = response.httpResponse.headers['x-amzn-requestid'];
    }
    if (response.httpResponse.headers['x-amz-request-id']) {
      monitoringEvent.XAmzRequestId = response.httpResponse.headers['x-amz-request-id'];
    }
    if (response.httpResponse.headers['x-amz-id-2']) {
      monitoringEvent.XAmzId2 = response.httpResponse.headers['x-amz-id-2'];
    }
    return monitoringEvent;
  },

  /**
   * Add metrics of failed request.
   * @api private
   */
  attemptFailEvent: function attemptFailEvent(request) {
    var monitoringEvent = this.apiAttemptEvent(request);
    var response = request.response;
    var error = response.error;
    if (response.httpResponse.statusCode > 299 ) {
      if (error.code) monitoringEvent.AwsException = error.code;
      if (error.message) monitoringEvent.AwsExceptionMessage = error.message;
    } else {
      if (error.code || error.name) monitoringEvent.SdkException = error.code || error.name;
      if (error.message) monitoringEvent.SdkExceptionMessage = error.message;
    }
    return monitoringEvent;
  },

  /**
   * Attach listeners to request object to fetch metrics of each request
   * and emit data object through \'ApiCall\' and \'ApiCallAttempt\' events.
   * @api private
   */
  attachMonitoringEmitter: function attachMonitoringEmitter(request) {
    var attemptTimestamp; //timestamp marking the beginning of a request attempt
    var attemptStartRealTime; //Start time of request attempt. Used to calculating attemptLatency
    var attemptLatency; //latency from request sent out to http response reaching SDK
    var callStartRealTime; //Start time of API call. Used to calculating API call latency
    var attemptCount = 0; //request.retryCount is not reliable here
    var region; //region cache region for each attempt since it can be updated in plase (e.g. s3)
    var callTimestamp; //timestamp when the request is created
    var self = this;
    var addToHead = true;

    request.on('validate', function () {
      callStartRealTime = AWS.util.realClock.now();
      callTimestamp = Date.now();
    }, addToHead);
    request.on('sign', function () {
      attemptStartRealTime = AWS.util.realClock.now();
      attemptTimestamp = Date.now();
      region = request.httpRequest.region;
      attemptCount++;
    }, addToHead);
    request.on('validateResponse', function() {
      attemptLatency = Math.round(AWS.util.realClock.now() - attemptStartRealTime);
    });
    request.addNamedListener('API_CALL_ATTEMPT', 'success', function API_CALL_ATTEMPT() {
      var apiAttemptEvent = self.apiAttemptEvent(request);
      apiAttemptEvent.Timestamp = attemptTimestamp;
      apiAttemptEvent.AttemptLatency = attemptLatency >= 0 ? attemptLatency : 0;
      apiAttemptEvent.Region = region;
      self.emit('apiCallAttempt', [apiAttemptEvent]);
    });
    request.addNamedListener('API_CALL_ATTEMPT_RETRY', 'retry', function API_CALL_ATTEMPT_RETRY() {
      var apiAttemptEvent = self.attemptFailEvent(request);
      apiAttemptEvent.Timestamp = attemptTimestamp;
      //attemptLatency may not be available if fail before response
      attemptLatency = attemptLatency ||
        Math.round(AWS.util.realClock.now() - attemptStartRealTime);
      apiAttemptEvent.AttemptLatency = attemptLatency >= 0 ? attemptLatency : 0;
      apiAttemptEvent.Region = region;
      self.emit('apiCallAttempt', [apiAttemptEvent]);
    });
    request.addNamedListener('API_CALL', 'complete', function API_CALL() {
      var apiCallEvent = self.apiCallEvent(request);
      apiCallEvent.AttemptCount = attemptCount;
      if (apiCallEvent.AttemptCount <= 0) return;
      apiCallEvent.Timestamp = callTimestamp;
      var latency = Math.round(AWS.util.realClock.now() - callStartRealTime);
      apiCallEvent.Latency = latency >= 0 ? latency : 0;
      var response = request.response;
      if (
        response.error &&
        response.error.retryable &&
        typeof response.retryCount === 'number' &&
        typeof response.maxRetries === 'number' &&
        (response.retryCount >= response.maxRetries)
      ) {
        apiCallEvent.MaxRetriesExceeded = 1;
      }
      self.emit('apiCall', [apiCallEvent]);
    });
  },

  /**
   * Override this method to setup any custom request listeners for each
   * new request to the service.
   *
   * @method_abstract This is an abstract method.
   */
  setupRequestListeners: function setupRequestListeners(request) {
  },

  /**
   * Gets the signer class for a given request
   * @api private
   */
  getSignerClass: function getSignerClass(request) {
    var version;
    // get operation authtype if present
    var operation = null;
    var authtype = '';
    if (request) {
      var operations = request.service.api.operations || {};
      operation = operations[request.operation] || null;
      authtype = operation ? operation.authtype : '';
    }
    if (this.config.signatureVersion) {
      version = this.config.signatureVersion;
    } else if (authtype === 'v4' || authtype === 'v4-unsigned-body') {
      version = 'v4';
    } else {
      version = this.api.signatureVersion;
    }
    return AWS.Signers.RequestSigner.getVersion(version);
  },

  /**
   * @api private
   */
  serviceInterface: function serviceInterface() {
    switch (this.api.protocol) {
      case 'ec2': return AWS.EventListeners.Query;
      case 'query': return AWS.EventListeners.Query;
      case 'json': return AWS.EventListeners.Json;
      case 'rest-json': return AWS.EventListeners.RestJson;
      case 'rest-xml': return AWS.EventListeners.RestXml;
    }
    if (this.api.protocol) {
      throw new Error('Invalid service `protocol\' ' +
        this.api.protocol + ' in API config');
    }
  },

  /**
   * @api private
   */
  successfulResponse: function successfulResponse(resp) {
    return resp.httpResponse.statusCode < 300;
  },

  /**
   * How many times a failed request should be retried before giving up.
   * the defaultRetryCount can be overriden by service classes.
   *
   * @api private
   */
  numRetries: function numRetries() {
    if (this.config.maxRetries !== undefined) {
      return this.config.maxRetries;
    } else {
      return this.defaultRetryCount;
    }
  },

  /**
   * @api private
   */
  retryDelays: function retryDelays(retryCount, err) {
    return AWS.util.calculateRetryDelay(retryCount, this.config.retryDelayOptions, err);
  },

  /**
   * @api private
   */
  retryableError: function retryableError(error) {
    if (this.timeoutError(error)) return true;
    if (this.networkingError(error)) return true;
    if (this.expiredCredentialsError(error)) return true;
    if (this.throttledError(error)) return true;
    if (error.statusCode >= 500) return true;
    return false;
  },

  /**
   * @api private
   */
  networkingError: function networkingError(error) {
    return error.code === 'NetworkingError';
  },

  /**
   * @api private
   */
  timeoutError: function timeoutError(error) {
    return error.code === 'TimeoutError';
  },

  /**
   * @api private
   */
  expiredCredentialsError: function expiredCredentialsError(error) {
    // TODO : this only handles *one* of the expired credential codes
    return (error.code === 'ExpiredTokenException');
  },

  /**
   * @api private
   */
  clockSkewError: function clockSkewError(error) {
    switch (error.code) {
      case 'RequestTimeTooSkewed':
      case 'RequestExpired':
      case 'InvalidSignatureException':
      case 'SignatureDoesNotMatch':
      case 'AuthFailure':
      case 'RequestInTheFuture':
        return true;
      default: return false;
    }
  },

  /**
   * @api private
   */
  getSkewCorrectedDate: function getSkewCorrectedDate() {
    return new Date(Date.now() + this.config.systemClockOffset);
  },

  /**
   * @api private
   */
  applyClockOffset: function applyClockOffset(newServerTime) {
    if (newServerTime) {
      this.config.systemClockOffset = newServerTime - Date.now();
    }
  },

  /**
   * @api private
   */
  isClockSkewed: function isClockSkewed(newServerTime) {
    if (newServerTime) {
      return Math.abs(this.getSkewCorrectedDate().getTime() - newServerTime) >= 300000;
    }
  },

  /**
   * @api private
   */
  throttledError: function throttledError(error) {
    // this logic varies between services
    if (error.statusCode === 429) return true;
    switch (error.code) {
      case 'ProvisionedThroughputExceededException':
      case 'Throttling':
      case 'ThrottlingException':
      case 'RequestLimitExceeded':
      case 'RequestThrottled':
      case 'RequestThrottledException':
      case 'TooManyRequestsException':
      case 'TransactionInProgressException': //dynamodb
      case 'EC2ThrottledException':
        return true;
      default:
        return false;
    }
  },

  /**
   * @api private
   */
  endpointFromTemplate: function endpointFromTemplate(endpoint) {
    if (typeof endpoint !== 'string') return endpoint;

    var e = endpoint;
    e = e.replace(/\{service\}/g, this.api.endpointPrefix);
    e = e.replace(/\{region\}/g, this.config.region);
    e = e.replace(/\{scheme\}/g, this.config.sslEnabled ? 'https' : 'http');
    return e;
  },

  /**
   * @api private
   */
  setEndpoint: function setEndpoint(endpoint) {
    this.endpoint = new AWS.Endpoint(endpoint, this.config);
  },

  /**
   * @api private
   */
  paginationConfig: function paginationConfig(operation, throwException) {
    var paginator = this.api.operations[operation].paginator;
    if (!paginator) {
      if (throwException) {
        var e = new Error();
        throw AWS.util.error(e, 'No pagination configuration for ' + operation);
      }
      return null;
    }

    return paginator;
  }
});

AWS.util.update(AWS.Service, {

  /**
   * Adds one method for each operation described in the api configuration
   *
   * @api private
   */
  defineMethods: function defineMethods(svc) {
    AWS.util.each(svc.prototype.api.operations, function iterator(method) {
      if (svc.prototype[method]) return;
      var operation = svc.prototype.api.operations[method];
      if (operation.authtype === 'none') {
        svc.prototype[method] = function (params, callback) {
          return this.makeUnauthenticatedRequest(method, params, callback);
        };
      } else {
        svc.prototype[method] = function (params, callback) {
          return this.makeRequest(method, params, callback);
        };
      }
    });
  },

  /**
   * Defines a new Service class using a service identifier and list of versions
   * including an optional set of features (functions) to apply to the class
   * prototype.
   *
   * @param serviceIdentifier [String] the identifier for the service
   * @param versions [Array<String>] a list of versions that work with this
   *   service
   * @param features [Object] an object to attach to the prototype
   * @return [Class<Service>] the service class defined by this function.
   */
  defineService: function defineService(serviceIdentifier, versions, features) {
    AWS.Service._serviceMap[serviceIdentifier] = true;
    if (!Array.isArray(versions)) {
      features = versions;
      versions = [];
    }

    var svc = inherit(AWS.Service, features || {});

    if (typeof serviceIdentifier === 'string') {
      AWS.Service.addVersions(svc, versions);

      var identifier = svc.serviceIdentifier || serviceIdentifier;
      svc.serviceIdentifier = identifier;
    } else { // defineService called with an API
      svc.prototype.api = serviceIdentifier;
      AWS.Service.defineMethods(svc);
    }
    AWS.SequentialExecutor.call(this.prototype);
    //util.clientSideMonitoring is only available in node
    if (!this.prototype.publisher && AWS.util.clientSideMonitoring) {
      var Publisher = AWS.util.clientSideMonitoring.Publisher;
      var configProvider = AWS.util.clientSideMonitoring.configProvider;
      var publisherConfig = configProvider();
      this.prototype.publisher = new Publisher(publisherConfig);
      if (publisherConfig.enabled) {
        //if csm is enabled in environment, SDK should send all metrics
        AWS.Service._clientSideMonitoring = true;
      }
    }
    AWS.SequentialExecutor.call(svc.prototype);
    AWS.Service.addDefaultMonitoringListeners(svc.prototype);
    return svc;
  },

  /**
   * @api private
   */
  addVersions: function addVersions(svc, versions) {
    if (!Array.isArray(versions)) versions = [versions];

    svc.services = svc.services || {};
    for (var i = 0; i < versions.length; i++) {
      if (svc.services[versions[i]] === undefined) {
        svc.services[versions[i]] = null;
      }
    }

    svc.apiVersions = Object.keys(svc.services).sort();
  },

  /**
   * @api private
   */
  defineServiceApi: function defineServiceApi(superclass, version, apiConfig) {
    var svc = inherit(superclass, {
      serviceIdentifier: superclass.serviceIdentifier
    });

    function setApi(api) {
      if (api.isApi) {
        svc.prototype.api = api;
      } else {
        svc.prototype.api = new Api(api, {
          serviceIdentifier: superclass.serviceIdentifier
        });
      }
    }

    if (typeof version === 'string') {
      if (apiConfig) {
        setApi(apiConfig);
      } else {
        try {
          setApi(AWS.apiLoader(superclass.serviceIdentifier, version));
        } catch (err) {
          throw AWS.util.error(err, {
            message: 'Could not find API configuration ' +
              superclass.serviceIdentifier + '-' + version
          });
        }
      }
      if (!Object.prototype.hasOwnProperty.call(superclass.services, version)) {
        superclass.apiVersions = superclass.apiVersions.concat(version).sort();
      }
      superclass.services[version] = svc;
    } else {
      setApi(version);
    }

    AWS.Service.defineMethods(svc);
    return svc;
  },

  /**
   * @api private
   */
  hasService: function(identifier) {
    return Object.prototype.hasOwnProperty.call(AWS.Service._serviceMap, identifier);
  },

  /**
   * @param attachOn attach default monitoring listeners to object
   *
   * Each monitoring event should be emitted from service client to service constructor prototype and then
   * to global service prototype like bubbling up. These default monitoring events listener will transfer
   * the monitoring events to the upper layer.
   * @api private
   */
  addDefaultMonitoringListeners: function addDefaultMonitoringListeners(attachOn) {
    attachOn.addNamedListener('MONITOR_EVENTS_BUBBLE', 'apiCallAttempt', function EVENTS_BUBBLE(event) {
      var baseClass = Object.getPrototypeOf(attachOn);
      if (baseClass._events) baseClass.emit('apiCallAttempt', [event]);
    });
    attachOn.addNamedListener('CALL_EVENTS_BUBBLE', 'apiCall', function CALL_EVENTS_BUBBLE(event) {
      var baseClass = Object.getPrototypeOf(attachOn);
      if (baseClass._events) baseClass.emit('apiCall', [event]);
    });
  },

  /**
   * @api private
   */
  _serviceMap: {}
});

AWS.util.mixin(AWS.Service, AWS.SequentialExecutor);

/**
 * @api private
 */
module.exports = AWS.Service;

}).call(this,require('_process'))
},{"./core":324,"./model/api":350,"./region_config":367,"_process":412}],375:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.APIGateway.prototype, {
/**
 * Sets the Accept header to application/json.
 *
 * @api private
 */
  setAcceptHeader: function setAcceptHeader(req) {
    var httpRequest = req.httpRequest;
    if (!httpRequest.headers.Accept) {
      httpRequest.headers['Accept'] = 'application/json';
    }
  },

  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    request.addListener('build', this.setAcceptHeader);
    if (request.operation === 'getExport') {
      var params = request.params || {};
      if (params.exportType === 'swagger') {
        request.addListener('extractData', AWS.util.convertPayloadToString);
      }
    }
  }
});


},{"../core":324}],376:[function(require,module,exports){
var AWS = require('../core');

// pull in CloudFront signer
require('../cloudfront/signer');

AWS.util.update(AWS.CloudFront.prototype, {

  setupRequestListeners: function setupRequestListeners(request) {
    request.addListener('extractData', AWS.util.hoistPayloadMember);
  }

});

},{"../cloudfront/signer":321,"../core":324}],377:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.CognitoIdentity.prototype, {
  getOpenIdToken: function getOpenIdToken(params, callback) {
    return this.makeUnauthenticatedRequest('getOpenIdToken', params, callback);
  },

  getId: function getId(params, callback) {
    return this.makeUnauthenticatedRequest('getId', params, callback);
  },

  getCredentialsForIdentity: function getCredentialsForIdentity(params, callback) {
    return this.makeUnauthenticatedRequest('getCredentialsForIdentity', params, callback);
  }
});

},{"../core":324}],378:[function(require,module,exports){
var AWS = require('../core');
require('../dynamodb/document_client');

AWS.util.update(AWS.DynamoDB.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    if (request.service.config.dynamoDbCrc32) {
      request.removeListener('extractData', AWS.EventListeners.Json.EXTRACT_DATA);
      request.addListener('extractData', this.checkCrc32);
      request.addListener('extractData', AWS.EventListeners.Json.EXTRACT_DATA);
    }
  },

  /**
   * @api private
   */
  checkCrc32: function checkCrc32(resp) {
    if (!resp.httpResponse.streaming && !resp.request.service.crc32IsValid(resp)) {
      resp.data = null;
      resp.error = AWS.util.error(new Error(), {
        code: 'CRC32CheckFailed',
        message: 'CRC32 integrity check failed',
        retryable: true
      });
      resp.request.haltHandlersOnError();
      throw (resp.error);
    }
  },

  /**
   * @api private
   */
  crc32IsValid: function crc32IsValid(resp) {
    var crc = resp.httpResponse.headers['x-amz-crc32'];
    if (!crc) return true; // no (valid) CRC32 header
    return parseInt(crc, 10) === AWS.util.crypto.crc32(resp.httpResponse.body);
  },

  /**
   * @api private
   */
  defaultRetryCount: 10,

  /**
   * @api private
   */
  retryDelays: function retryDelays(retryCount, err) {
    var retryDelayOptions = AWS.util.copy(this.config.retryDelayOptions);

    if (typeof retryDelayOptions.base !== 'number') {
        retryDelayOptions.base = 50; // default for dynamodb
    }
    var delay = AWS.util.calculateRetryDelay(retryCount, retryDelayOptions, err);
    return delay;
  }
});

},{"../core":324,"../dynamodb/document_client":334}],379:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.EC2.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    request.removeListener('extractError', AWS.EventListeners.Query.EXTRACT_ERROR);
    request.addListener('extractError', this.extractError);

    if (request.operation === 'copySnapshot') {
      request.onAsync('validate', this.buildCopySnapshotPresignedUrl);
    }
  },

  /**
   * @api private
   */
  buildCopySnapshotPresignedUrl: function buildCopySnapshotPresignedUrl(req, done) {
    if (req.params.PresignedUrl || req._subRequest) {
      return done();
    }

    req.params = AWS.util.copy(req.params);
    req.params.DestinationRegion = req.service.config.region;

    var config = AWS.util.copy(req.service.config);
    delete config.endpoint;
    config.region = req.params.SourceRegion;
    var svc = new req.service.constructor(config);
    var newReq = svc[req.operation](req.params);
    newReq._subRequest = true;
    newReq.presign(function(err, url) {
      if (err) done(err);
      else {
        req.params.PresignedUrl = url;
        done();
      }
    });
  },

  /**
   * @api private
   */
  extractError: function extractError(resp) {
    // EC2 nests the error code and message deeper than other AWS Query services.
    var httpResponse = resp.httpResponse;
    var data = new AWS.XML.Parser().parse(httpResponse.body.toString() || '');
    if (data.Errors) {
      resp.error = AWS.util.error(new Error(), {
        code: data.Errors.Error.Code,
        message: data.Errors.Error.Message
      });
    } else {
      resp.error = AWS.util.error(new Error(), {
        code: httpResponse.statusCode,
        message: null
      });
    }
    resp.error.requestId = data.RequestID || null;
  }
});

},{"../core":324}],380:[function(require,module,exports){
var AWS = require('../core');

/**
 * @api private
 */
var blobPayloadOutputOps = [
  'deleteThingShadow',
  'getThingShadow',
  'updateThingShadow'
];

/**
 * Constructs a service interface object. Each API operation is exposed as a
 * function on service.
 *
 * ### Sending a Request Using IotData
 *
 * ```javascript
 * var iotdata = new AWS.IotData({endpoint: 'my.host.tld'});
 * iotdata.getThingShadow(params, function (err, data) {
 *   if (err) console.log(err, err.stack); // an error occurred
 *   else     console.log(data);           // successful response
 * });
 * ```
 *
 * ### Locking the API Version
 *
 * In order to ensure that the IotData object uses this specific API,
 * you can construct the object by passing the `apiVersion` option to the
 * constructor:
 *
 * ```javascript
 * var iotdata = new AWS.IotData({
 *   endpoint: 'my.host.tld',
 *   apiVersion: '2015-05-28'
 * });
 * ```
 *
 * You can also set the API version globally in `AWS.config.apiVersions` using
 * the **iotdata** service identifier:
 *
 * ```javascript
 * AWS.config.apiVersions = {
 *   iotdata: '2015-05-28',
 *   // other service API versions
 * };
 *
 * var iotdata = new AWS.IotData({endpoint: 'my.host.tld'});
 * ```
 *
 * @note You *must* provide an `endpoint` configuration parameter when
 *   constructing this service. See {constructor} for more information.
 *
 * @!method constructor(options = {})
 *   Constructs a service object. This object has one method for each
 *   API operation.
 *
 *   @example Constructing a IotData object
 *     var iotdata = new AWS.IotData({endpoint: 'my.host.tld'});
 *   @note You *must* provide an `endpoint` when constructing this service.
 *   @option (see AWS.Config.constructor)
 *
 * @service iotdata
 * @version 2015-05-28
 */
AWS.util.update(AWS.IotData.prototype, {
    /**
     * @api private
     */
    validateService: function validateService() {
        if (!this.config.endpoint || this.config.endpoint.indexOf('{') >= 0) {
            var msg = 'AWS.IotData requires an explicit ' +
                '`endpoint\' configuration option.';
            throw AWS.util.error(new Error(),
                {name: 'InvalidEndpoint', message: msg});
        }
    },

    /**
     * @api private
     */
    setupRequestListeners: function setupRequestListeners(request) {
        request.addListener('validateResponse', this.validateResponseBody);
        if (blobPayloadOutputOps.indexOf(request.operation) > -1) {
            request.addListener('extractData', AWS.util.convertPayloadToString);
        }
    },

    /**
     * @api private
     */
    validateResponseBody: function validateResponseBody(resp) {
        var body = resp.httpResponse.body.toString() || '{}';
        var bodyCheck = body.trim();
        if (!bodyCheck || bodyCheck.charAt(0) !== '{') {
            resp.httpResponse.body = '';
        }
    }

});

},{"../core":324}],381:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.Lambda.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    if (request.operation === 'invoke') {
      request.addListener('extractData', AWS.util.convertPayloadToString);
    }
  }
});


},{"../core":324}],382:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.MachineLearning.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    if (request.operation === 'predict') {
      request.addListener('build', this.buildEndpoint);
    }
  },

  /**
   * Updates request endpoint from PredictEndpoint
   * @api private
   */
  buildEndpoint: function buildEndpoint(request) {
    var url = request.params.PredictEndpoint;
    if (url) {
      request.httpRequest.endpoint = new AWS.Endpoint(url);
    }
  }

});

},{"../core":324}],383:[function(require,module,exports){
require('../polly/presigner');

},{"../polly/presigner":357}],384:[function(require,module,exports){
var AWS = require('../core');
require('../rds/signer');
 /**
  * @api private
  */
 var crossRegionOperations = ['copyDBSnapshot', 'createDBInstanceReadReplica', 'createDBCluster', 'copyDBClusterSnapshot'];

 AWS.util.update(AWS.RDS.prototype, {
   /**
    * @api private
    */
   setupRequestListeners: function setupRequestListeners(request) {
     if (crossRegionOperations.indexOf(request.operation) !== -1 &&
         request.params.SourceRegion) {
       request.params = AWS.util.copy(request.params);
       if (request.params.PreSignedUrl ||
           request.params.SourceRegion === this.config.region) {
         delete request.params.SourceRegion;
       } else {
         var doesParamValidation = !!this.config.paramValidation;
         // remove the validate parameters listener so we can re-add it after we build the URL
         if (doesParamValidation) {
           request.removeListener('validate', AWS.EventListeners.Core.VALIDATE_PARAMETERS);
         }
         request.onAsync('validate', this.buildCrossRegionPresignedUrl);
         if (doesParamValidation) {
           request.addListener('validate', AWS.EventListeners.Core.VALIDATE_PARAMETERS);
         }
       }
     }
   },

   /**
    * @api private
    */
   buildCrossRegionPresignedUrl: function buildCrossRegionPresignedUrl(req, done) {
     var config = AWS.util.copy(req.service.config);
     config.region = req.params.SourceRegion;
     delete req.params.SourceRegion;
     delete config.endpoint;
     // relevant params for the operation will already be in req.params
     delete config.params;
     config.signatureVersion = 'v4';
     var destinationRegion = req.service.config.region;

     var svc = new req.service.constructor(config);
     var newReq = svc[req.operation](AWS.util.copy(req.params));
     newReq.on('build', function addDestinationRegionParam(request) {
       var httpRequest = request.httpRequest;
       httpRequest.params.DestinationRegion = destinationRegion;
       httpRequest.body = AWS.util.queryParamsToString(httpRequest.params);
     });
     newReq.presign(function(err, url) {
       if (err) done(err);
       else {
         req.params.PreSignedUrl = url;
         done();
       }
     });
   }
 });

},{"../core":324,"../rds/signer":365}],385:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.Route53.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    request.on('build', this.sanitizeUrl);
  },

  /**
   * @api private
   */
  sanitizeUrl: function sanitizeUrl(request) {
    var path = request.httpRequest.path;
    request.httpRequest.path = path.replace(/\/%2F\w+%2F/, '/');
  },

  /**
   * @return [Boolean] whether the error can be retried
   * @api private
   */
  retryableError: function retryableError(error) {
    if (error.code === 'PriorRequestNotComplete' &&
        error.statusCode === 400) {
      return true;
    } else {
      var _super = AWS.Service.prototype.retryableError;
      return _super.call(this, error);
    }
  }
});

},{"../core":324}],386:[function(require,module,exports){
(function (process){
var AWS = require('../core');
var v4Credentials = require('../signers/v4_credentials');
var resolveRegionalEndpointsFlag = require('../config_regional_endpoint');
var regionUtil = require('../region_config');

// Pull in managed upload extension
require('../s3/managed_upload');

/**
 * @api private
 */
var operationsWith200StatusCodeError = {
  'completeMultipartUpload': true,
  'copyObject': true,
  'uploadPartCopy': true
};

/**
 * @api private
 */
 var regionRedirectErrorCodes = [
  'AuthorizationHeaderMalformed', // non-head operations on virtual-hosted global bucket endpoints
  'BadRequest', // head operations on virtual-hosted global bucket endpoints
  'PermanentRedirect', // non-head operations on path-style or regional endpoints
  301 // head operations on path-style or regional endpoints
 ];

AWS.util.update(AWS.S3.prototype, {
  /**
   * @api private
   */
  getSignatureVersion: function getSignatureVersion(request) {
    var defaultApiVersion = this.api.signatureVersion;
    var userDefinedVersion = this._originalConfig ? this._originalConfig.signatureVersion : null;
    var regionDefinedVersion = this.config.signatureVersion;
    var isPresigned = request ? request.isPresigned() : false;
    /*
      1) User defined version specified:
        a) always return user defined version
      2) No user defined version specified:
        a) If not using presigned urls, default to V4
        b) If using presigned urls, default to lowest version the region supports
    */
    if (userDefinedVersion) {
      userDefinedVersion = userDefinedVersion === 'v2' ? 's3' : userDefinedVersion;
      return userDefinedVersion;
    }
    if (isPresigned !== true) {
      defaultApiVersion = 'v4';
    } else if (regionDefinedVersion) {
      defaultApiVersion = regionDefinedVersion;
    }
    return defaultApiVersion;
  },

  /**
   * @api private
   */
  getSignerClass: function getSignerClass(request) {
    var signatureVersion = this.getSignatureVersion(request);
    return AWS.Signers.RequestSigner.getVersion(signatureVersion);
  },

  /**
   * @api private
   */
  validateService: function validateService() {
    var msg;
    var messages = [];

    // default to us-east-1 when no region is provided
    if (!this.config.region) this.config.region = 'us-east-1';

    if (!this.config.endpoint && this.config.s3BucketEndpoint) {
      messages.push('An endpoint must be provided when configuring ' +
                    '`s3BucketEndpoint` to true.');
    }
    if (messages.length === 1) {
      msg = messages[0];
    } else if (messages.length > 1) {
      msg = 'Multiple configuration errors:\n' + messages.join('\n');
    }
    if (msg) {
      throw AWS.util.error(new Error(),
        {name: 'InvalidEndpoint', message: msg});
    }
  },

  /**
   * @api private
   */
  shouldDisableBodySigning: function shouldDisableBodySigning(request) {
    var signerClass = this.getSignerClass();
    if (this.config.s3DisableBodySigning === true && signerClass === AWS.Signers.V4
        && request.httpRequest.endpoint.protocol === 'https:') {
      return true;
    }
    return false;
  },

  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    var prependListener = true;
    request.addListener('validate', this.validateScheme);
    request.addListener('validate', this.validateBucketName, prependListener);
    request.addListener('validate', this.optInUsEast1RegionalEndpoint, prependListener);

    request.removeListener('validate',
      AWS.EventListeners.Core.VALIDATE_REGION);
    request.addListener('build', this.addContentType);
    request.addListener('build', this.computeContentMd5);
    request.addListener('build', this.computeSseCustomerKeyMd5);
    request.addListener('build', this.populateURI);
    request.addListener('afterBuild', this.addExpect100Continue);
    request.addListener('extractError', this.extractError);
    request.addListener('extractData', AWS.util.hoistPayloadMember);
    request.addListener('extractData', this.extractData);
    request.addListener('extractData', this.extractErrorFrom200Response);
    request.addListener('beforePresign', this.prepareSignedUrl);
    if (this.shouldDisableBodySigning(request))  {
      request.removeListener('afterBuild', AWS.EventListeners.Core.COMPUTE_SHA256);
      request.addListener('afterBuild', this.disableBodySigning);
    }
    //deal with ARNs supplied to Bucket
    if (this.isAccessPointApplicable(request)) {
      request.removeListener('validate', this.validateBucketName);
      request.addListener('validate', this.validateAccessPointArn, prependListener);
      request.addListener('validate', this.validateArnRegion);
      request.removeListener('build', this.populateURI);
      request.addListener('build', this.populateUriFromAccessPoint);
      return;
    }
    //listeners regarding region inference
    request.addListener('validate', this.validateBucketEndpoint);
    request.addListener('validate', this.correctBucketRegionFromCache);
    request.onAsync('extractError', this.requestBucketRegion);
    if (AWS.util.isBrowser()) {
      request.onAsync('retry', this.reqRegionForNetworkingError);
    }
  },

  /**
   * @api private
   */
  validateScheme: function(req) {
    var params = req.params,
        scheme = req.httpRequest.endpoint.protocol,
        sensitive = params.SSECustomerKey || params.CopySourceSSECustomerKey;
    if (sensitive && scheme !== 'https:') {
      var msg = 'Cannot send SSE keys over HTTP. Set \'sslEnabled\'' +
        'to \'true\' in your configuration';
      throw AWS.util.error(new Error(),
        { code: 'ConfigError', message: msg });
    }
  },

  /**
   * @api private
   */
  validateBucketEndpoint: function(req) {
    if (!req.params.Bucket && req.service.config.s3BucketEndpoint) {
      var msg = 'Cannot send requests to root API with `s3BucketEndpoint` set.';
      throw AWS.util.error(new Error(),
        { code: 'ConfigError', message: msg });
    }
  },

  /**
   * @api private
   */
  isAccessPointApplicable: function hasBucketInParams(req) {
    var inputShape = (req.service.api.operations[req.operation] || {}).input || {};
    var inputMembers = inputShape.members || {};
    if (
      req.operation === 'createBucket' ||
      !req.params.Bucket ||
      !inputMembers.Bucket
    ) return false;
    if (!AWS.util.ARN.validate(req.params.Bucket)) return false;
    return true;
  },

  /**
   * Validate ARN supplied in Bucket parameter is a valid access point ARN
   *
   * @api private
   */
  validateAccessPointArn: function validateAccessPointArn(req) {
    var parsedArn = AWS.util.ARN.parse(req.params.Bucket);
    //avoid duplicated parsing in the future
    req._parsedAccessPointArn = parsedArn;
    var parsedArn = req._parsedAccessPointArn;
    if (parsedArn.service !== 's3') {
      throw AWS.util.error(new Error(), {
        code: 'InvalidAccessPointARN',
        message: 'expect \'s3\' in access point ARN service component'
      });
    }
    if (!parsedArn.region) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidAccessPointARN',
        message: 'Access point ARN region is empty'
      });
    }
    if (
      parsedArn.resource.indexOf('accesspoint:') !== 0 &&
      parsedArn.resource.indexOf('accesspoint/') !== 0
    ) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidAccessPointARN',
        message: 'Access point ARN resource should begin with \'accesspoint/\''
      });
    }
    var delimiter = parsedArn.resource['accesspoint'.length]; //can be ':' or '/'
    if (parsedArn.resource.split(delimiter).length !== 2) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidAccessPointARN',
        message: 'Too many resource parameters in access point ARN'
      });
    }
    var accessPoint = parsedArn.resource.split(delimiter)[1];
    var accessPointPrefix = accessPoint + '-' + parsedArn.accountId;
    if (!req.service.isDnsCompatible(accessPointPrefix) || accessPointPrefix.match(/\./)) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidAccessPointARN',
        message: 'Access point ARN is not DNS compatible. Got ' + accessPoint
      });
    }
    //set parsed valid access point
    req._parsedAccessPointArn.accessPoint = accessPoint;
  },

  /**
   * @api private
   */
  validateArnRegion: function validateArnRegion(req) {
    var useArnRegion = req.service.loadUseArnRegionConfig(req);
    var regionFromArn = req._parsedAccessPointArn.region;
    var clientRegion = req.service.config.region;
    if (
      clientRegion.indexOf('fips') >= 0 ||
      regionFromArn.indexOf('fips') >= 0
    ) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'Access point endpoint is not compatible with FIPS region'
      });
    }
    if (!useArnRegion && regionFromArn !== clientRegion) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'Configured region conflicts with access point region'
      });
    } else if (
      useArnRegion &&
      regionUtil.getEndpointSuffix(regionFromArn) !== regionUtil.getEndpointSuffix(clientRegion)
    ) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'Configured region and access point region not in same partition'
      });
    }
    if (req.service.config.useAccelerateEndpoint) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'useAccelerateEndpoint config is not supported with access point ARN'
      });
    }
  },

  /**
   * @api private
   */
  loadUseArnRegionConfig: function loadUseArnRegionConfig(req) {
    var envName = 'AWS_S3_USE_ARN_REGION';
    var configName = 's3_use_arn_region';
    var useArnRegion = true;
    var originalConfig = req.service._originalConfig || {};
    if (req.service.config.s3UseArnRegion !== undefined) {
      return req.service.config.s3UseArnRegion;
    } else if (originalConfig.s3UseArnRegion !== undefined) {
      useArnRegion = originalConfig.s3UseArnRegion === true;
    } else if (AWS.util.isNode()) {
      //load from environmental variable AWS_USE_ARN_REGION
      if (process.env[envName]) {
        var value = process.env[envName].trim().toLowerCase();
        if (['false', 'true'].indexOf(value) < 0) {
          throw AWS.util.error(new Error(), {
            code: 'InvalidConfiguration',
            message: envName + ' only accepts true or false. Got ' + process.env[envName],
            retryable: false
          });
        }
        useArnRegion = value === 'true';
      } else {  //load from shared config property use_arn_region
        var profiles = {};
        var profile = {};
        try {
          profiles = AWS.util.getProfilesFromSharedConfig(AWS.util.iniLoader);
          profile = profiles[process.env.AWS_PROFILE || AWS.util.defaultProfile];
        } catch (e) {}
        if (profile[configName]) {
          if (['false', 'true'].indexOf(profile[configName].trim().toLowerCase()) < 0) {
            throw AWS.util.error(new Error(), {
              code: 'InvalidConfiguration',
              message: configName + ' only accepts true or false. Got ' + profile[configName],
              retryable: false
            });
          }
          useArnRegion = profile[configName].trim().toLowerCase() === 'true';
        }
      }
    }
    req.service.config.s3UseArnRegion = useArnRegion;
    return useArnRegion;
  },

  /**
   * @api private
   */
  validateBucketName: function validateBucketName(req) {
    var service = req.service;
    var signatureVersion = service.getSignatureVersion(req);
    var bucket = req.params && req.params.Bucket;
    var key = req.params && req.params.Key;
    var slashIndex = bucket && bucket.indexOf('/');
    if (bucket && slashIndex >= 0) {
      if (typeof key === 'string' && slashIndex > 0) {
        req.params = AWS.util.copy(req.params);
        // Need to include trailing slash to match sigv2 behavior
        var prefix = bucket.substr(slashIndex + 1) || '';
        req.params.Key = prefix + '/' + key;
        req.params.Bucket = bucket.substr(0, slashIndex);
      } else if (signatureVersion === 'v4') {
        var msg = 'Bucket names cannot contain forward slashes. Bucket: ' + bucket;
        throw AWS.util.error(new Error(),
          { code: 'InvalidBucket', message: msg });
      }
    }
  },

  /**
   * @api private
   */
  isValidAccelerateOperation: function isValidAccelerateOperation(operation) {
    var invalidOperations = [
      'createBucket',
      'deleteBucket',
      'listBuckets'
    ];
    return invalidOperations.indexOf(operation) === -1;
  },

  /**
   * When us-east-1 region endpoint configuration is set, in stead of sending request to
   * global endpoint(e.g. 's3.amazonaws.com'), we will send request to
   * 's3.us-east-1.amazonaws.com'.
   * @api private
   */
  optInUsEast1RegionalEndpoint: function optInUsEast1RegionalEndpoint(req) {
    var service = req.service;
    var config = service.config;
    config.s3UsEast1RegionalEndpoint = resolveRegionalEndpointsFlag(service._originalConfig, {
      env: 'AWS_S3_US_EAST_1_REGIONAL_ENDPOINT',
      sharedConfig: 's3_us_east_1_regional_endpoint',
      clientConfig: 's3UsEast1RegionalEndpoint'
    });
    if (
      !(service._originalConfig || {}).endpoint &&
      req.httpRequest.region === 'us-east-1' &&
      config.s3UsEast1RegionalEndpoint === 'regional' &&
      req.httpRequest.endpoint.hostname.indexOf('s3.amazonaws.com') >= 0
    ) {
      var insertPoint = config.endpoint.indexOf('.amazonaws.com');
      regionalEndpoint = config.endpoint.substring(0, insertPoint) +
        '.us-east-1' + config.endpoint.substring(insertPoint);
      req.httpRequest.updateEndpoint(regionalEndpoint);
    }
  },

  /**
   * S3 prefers dns-compatible bucket names to be moved from the uri path
   * to the hostname as a sub-domain.  This is not possible, even for dns-compat
   * buckets when using SSL and the bucket name contains a dot ('.').  The
   * ssl wildcard certificate is only 1-level deep.
   *
   * @api private
   */
  populateURI: function populateURI(req) {
    var httpRequest = req.httpRequest;
    var b = req.params.Bucket;
    var service = req.service;
    var endpoint = httpRequest.endpoint;
    if (b) {
      if (!service.pathStyleBucketName(b)) {
        if (service.config.useAccelerateEndpoint && service.isValidAccelerateOperation(req.operation)) {
          if (service.config.useDualstack) {
            endpoint.hostname = b + '.s3-accelerate.dualstack.amazonaws.com';
          } else {
            endpoint.hostname = b + '.s3-accelerate.amazonaws.com';
          }
        } else if (!service.config.s3BucketEndpoint) {
          endpoint.hostname =
            b + '.' + endpoint.hostname;
        }

        var port = endpoint.port;
        if (port !== 80 && port !== 443) {
          endpoint.host = endpoint.hostname + ':' +
            endpoint.port;
        } else {
          endpoint.host = endpoint.hostname;
        }

        httpRequest.virtualHostedBucket = b; // needed for signing the request
        service.removeVirtualHostedBucketFromPath(req);
      }
    }
  },

  /**
   * Takes the bucket name out of the path if bucket is virtual-hosted
   *
   * @api private
   */
  removeVirtualHostedBucketFromPath: function removeVirtualHostedBucketFromPath(req) {
    var httpRequest = req.httpRequest;
    var bucket = httpRequest.virtualHostedBucket;
    if (bucket && httpRequest.path) {
      if (req.params && req.params.Key) {
        var encodedS3Key = '/' + AWS.util.uriEscapePath(req.params.Key);
        if (httpRequest.path.indexOf(encodedS3Key) === 0 && (httpRequest.path.length === encodedS3Key.length || httpRequest.path[encodedS3Key.length] === '?')) {
          //path only contains key or path contains only key and querystring
          return;
        }
      }
      httpRequest.path = httpRequest.path.replace(new RegExp('/' + bucket), '');
      if (httpRequest.path[0] !== '/') {
        httpRequest.path = '/' + httpRequest.path;
      }
    }
  },

  /**
   * When user supply an access point ARN in the Bucket parameter, we need to
   * populate the URI according to the ARN.
   * @api private
   */
  populateUriFromAccessPoint: function populateUriFromAccessPoint(req) {
    if (req.service._originalConfig.endpoint) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'Custom endpoint is not compatible with access point ARN'
      });
    }
    if (req.service.config.s3ForcePathStyle) {
      throw AWS.util.error(new Error(), {
        code: 'InvalidConfiguration',
        message: 'Cannot construct path-style endpoint with access point'
      });
    }
    var accessPointArn = req._parsedAccessPointArn;
    var serviceName = req.service.config.useDualstack ?
      's3-accesspoint.dualstack':
      's3-accesspoint';
    var endpoint = req.httpRequest.endpoint;
    var dnsSuffix = regionUtil.getEndpointSuffix(accessPointArn.region);
    var useArnRegion = req.service.config.s3UseArnRegion;
    endpoint.hostname = [
      accessPointArn.accessPoint + '-' + accessPointArn.accountId,
      serviceName,
      useArnRegion ? accessPointArn.region : req.service.config.region,
      dnsSuffix
    ].join('.');
    endpoint.host = endpoint.hostname;
    var encodedArn = AWS.util.uriEscape(req.params.Bucket);
    var path = req.httpRequest.path;
    //remove the Bucket value from path
    req.httpRequest.path = path.replace(new RegExp('/' + encodedArn), '');
    if (req.httpRequest.path[0] !== '/') {
      req.httpRequest.path = '/' + req.httpRequest.path;
    }
    req.httpRequest.region = accessPointArn.region; //region used to sign
  },

  /**
   * Adds Expect: 100-continue header if payload is greater-or-equal 1MB
   * @api private
   */
  addExpect100Continue: function addExpect100Continue(req) {
    var len = req.httpRequest.headers['Content-Length'];
    if (AWS.util.isNode() && (len >= 1024 * 1024 || req.params.Body instanceof AWS.util.stream.Stream)) {
      req.httpRequest.headers['Expect'] = '100-continue';
    }
  },

  /**
   * Adds a default content type if none is supplied.
   *
   * @api private
   */
  addContentType: function addContentType(req) {
    var httpRequest = req.httpRequest;
    if (httpRequest.method === 'GET' || httpRequest.method === 'HEAD') {
      // Content-Type is not set in GET/HEAD requests
      delete httpRequest.headers['Content-Type'];
      return;
    }

    if (!httpRequest.headers['Content-Type']) { // always have a Content-Type
      httpRequest.headers['Content-Type'] = 'application/octet-stream';
    }

    var contentType = httpRequest.headers['Content-Type'];
    if (AWS.util.isBrowser()) {
      if (typeof httpRequest.body === 'string' && !contentType.match(/;\s*charset=/)) {
        var charset = '; charset=UTF-8';
        httpRequest.headers['Content-Type'] += charset;
      } else {
        var replaceFn = function(_, prefix, charsetName) {
          return prefix + charsetName.toUpperCase();
        };

        httpRequest.headers['Content-Type'] =
          contentType.replace(/(;\s*charset=)(.+)$/, replaceFn);
      }
    }
  },

  /**
   * @api private
   */
  computableChecksumOperations: {
    putBucketCors: true,
    putBucketLifecycle: true,
    putBucketLifecycleConfiguration: true,
    putBucketTagging: true,
    deleteObjects: true,
    putBucketReplication: true,
    putObjectLegalHold: true,
    putObjectRetention: true,
    putObjectLockConfiguration: true
  },

  /**
   * Checks whether checksums should be computed for the request.
   * If the request requires checksums to be computed, this will always
   * return true, otherwise it depends on whether {AWS.Config.computeChecksums}
   * is set.
   *
   * @param req [AWS.Request] the request to check against
   * @return [Boolean] whether to compute checksums for a request.
   * @api private
   */
  willComputeChecksums: function willComputeChecksums(req) {
    if (this.computableChecksumOperations[req.operation]) return true;
    if (!this.config.computeChecksums) return false;

    // TODO: compute checksums for Stream objects
    if (!AWS.util.Buffer.isBuffer(req.httpRequest.body) &&
        typeof req.httpRequest.body !== 'string') {
      return false;
    }

    var rules = req.service.api.operations[req.operation].input.members;

    // Sha256 signing disabled, and not a presigned url
    if (req.service.shouldDisableBodySigning(req) && !Object.prototype.hasOwnProperty.call(req.httpRequest.headers, 'presigned-expires')) {
      if (rules.ContentMD5 && !req.params.ContentMD5) {
        return true;
      }
    }

    // V4 signer uses SHA256 signatures so only compute MD5 if it is required
    if (req.service.getSignerClass(req) === AWS.Signers.V4) {
      if (rules.ContentMD5 && !rules.ContentMD5.required) return false;
    }

    if (rules.ContentMD5 && !req.params.ContentMD5) return true;
  },

  /**
   * A listener that computes the Content-MD5 and sets it in the header.
   * @see AWS.S3.willComputeChecksums
   * @api private
   */
  computeContentMd5: function computeContentMd5(req) {
    if (req.service.willComputeChecksums(req)) {
      var md5 = AWS.util.crypto.md5(req.httpRequest.body, 'base64');
      req.httpRequest.headers['Content-MD5'] = md5;
    }
  },

  /**
   * @api private
   */
  computeSseCustomerKeyMd5: function computeSseCustomerKeyMd5(req) {
    var keys = {
      SSECustomerKey: 'x-amz-server-side-encryption-customer-key-MD5',
      CopySourceSSECustomerKey: 'x-amz-copy-source-server-side-encryption-customer-key-MD5'
    };
    AWS.util.each(keys, function(key, header) {
      if (req.params[key]) {
        var value = AWS.util.crypto.md5(req.params[key], 'base64');
        req.httpRequest.headers[header] = value;
      }
    });
  },

  /**
   * Returns true if the bucket name should be left in the URI path for
   * a request to S3.  This function takes into account the current
   * endpoint protocol (e.g. http or https).
   *
   * @api private
   */
  pathStyleBucketName: function pathStyleBucketName(bucketName) {
    // user can force path style requests via the configuration
    if (this.config.s3ForcePathStyle) return true;
    if (this.config.s3BucketEndpoint) return false;

    if (this.isDnsCompatible(bucketName)) {
      return (this.config.sslEnabled && bucketName.match(/\./)) ? true : false;
    } else {
      return true; // not dns compatible names must always use path style
    }
  },

  /**
   * Returns true if the bucket name is DNS compatible.  Buckets created
   * outside of the classic region MUST be DNS compatible.
   *
   * @api private
   */
  isDnsCompatible: function isDnsCompatible(bucketName) {
    var b = bucketName;
    var domain = new RegExp(/^[a-z0-9][a-z0-9\.\-]{1,61}[a-z0-9]$/);
    var ipAddress = new RegExp(/(\d+\.){3}\d+/);
    var dots = new RegExp(/\.\./);
    return (b.match(domain) && !b.match(ipAddress) && !b.match(dots)) ? true : false;
  },

  /**
   * For COPY operations, some can be error even with status code 200.
   * SDK treats the response as exception when response body indicates
   * an exception or body is empty.
   *
   * @api private
   */
  extractErrorFrom200Response: function extractErrorFrom200Response(resp) {
    if (!operationsWith200StatusCodeError[resp.request.operation]) return;
    var httpResponse = resp.httpResponse;
    if (httpResponse.body && httpResponse.body.toString().match('<Error>')) {
      // Response body with '<Error>...</Error>' indicates an exception.
      // Get S3 client object. In ManagedUpload, this.service refers to
      // S3 client object.
      resp.data = null;
      var service = this.service ? this.service : this;
      service.extractError(resp);
      throw resp.error;
    } else if (!httpResponse.body || !httpResponse.body.toString().match(/<[\w_]/)) {
      // When body is empty or incomplete, S3 might stop the request on detecting client
      // side aborting the request.
      resp.data = null;
      throw AWS.util.error(new Error(), {
        code: 'InternalError',
        message: 'S3 aborted request'
      });
    }
  },

  /**
   * @return [Boolean] whether the error can be retried
   * @api private
   */
  retryableError: function retryableError(error, request) {
    if (operationsWith200StatusCodeError[request.operation] &&
        error.statusCode === 200) {
      return true;
    } else if (request._requestRegionForBucket &&
        request.service.bucketRegionCache[request._requestRegionForBucket]) {
      return false;
    } else if (error && error.code === 'RequestTimeout') {
      return true;
    } else if (error &&
        regionRedirectErrorCodes.indexOf(error.code) != -1 &&
        error.region && error.region != request.httpRequest.region) {
      request.httpRequest.region = error.region;
      if (error.statusCode === 301) {
        request.service.updateReqBucketRegion(request);
      }
      return true;
    } else {
      var _super = AWS.Service.prototype.retryableError;
      return _super.call(this, error, request);
    }
  },

  /**
   * Updates httpRequest with region. If region is not provided, then
   * the httpRequest will be updated based on httpRequest.region
   *
   * @api private
   */
  updateReqBucketRegion: function updateReqBucketRegion(request, region) {
    var httpRequest = request.httpRequest;
    if (typeof region === 'string' && region.length) {
      httpRequest.region = region;
    }
    if (!httpRequest.endpoint.host.match(/s3(?!-accelerate).*\.amazonaws\.com$/)) {
      return;
    }
    var service = request.service;
    var s3Config = service.config;
    var s3BucketEndpoint = s3Config.s3BucketEndpoint;
    if (s3BucketEndpoint) {
      delete s3Config.s3BucketEndpoint;
    }
    var newConfig = AWS.util.copy(s3Config);
    delete newConfig.endpoint;
    newConfig.region = httpRequest.region;

    httpRequest.endpoint = (new AWS.S3(newConfig)).endpoint;
    service.populateURI(request);
    s3Config.s3BucketEndpoint = s3BucketEndpoint;
    httpRequest.headers.Host = httpRequest.endpoint.host;

    if (request._asm.currentState === 'validate') {
      request.removeListener('build', service.populateURI);
      request.addListener('build', service.removeVirtualHostedBucketFromPath);
    }
  },

  /**
   * Provides a specialized parser for getBucketLocation -- all other
   * operations are parsed by the super class.
   *
   * @api private
   */
  extractData: function extractData(resp) {
    var req = resp.request;
    if (req.operation === 'getBucketLocation') {
      var match = resp.httpResponse.body.toString().match(/>(.+)<\/Location/);
      delete resp.data['_'];
      if (match) {
        resp.data.LocationConstraint = match[1];
      } else {
        resp.data.LocationConstraint = '';
      }
    }
    var bucket = req.params.Bucket || null;
    if (req.operation === 'deleteBucket' && typeof bucket === 'string' && !resp.error) {
      req.service.clearBucketRegionCache(bucket);
    } else {
      var headers = resp.httpResponse.headers || {};
      var region = headers['x-amz-bucket-region'] || null;
      if (!region && req.operation === 'createBucket' && !resp.error) {
        var createBucketConfiguration = req.params.CreateBucketConfiguration;
        if (!createBucketConfiguration) {
          region = 'us-east-1';
        } else if (createBucketConfiguration.LocationConstraint === 'EU') {
          region = 'eu-west-1';
        } else {
          region = createBucketConfiguration.LocationConstraint;
        }
      }
      if (region) {
          if (bucket && region !== req.service.bucketRegionCache[bucket]) {
            req.service.bucketRegionCache[bucket] = region;
          }
      }
    }
    req.service.extractRequestIds(resp);
  },

  /**
   * Extracts an error object from the http response.
   *
   * @api private
   */
  extractError: function extractError(resp) {
    var codes = {
      304: 'NotModified',
      403: 'Forbidden',
      400: 'BadRequest',
      404: 'NotFound'
    };

    var req = resp.request;
    var code = resp.httpResponse.statusCode;
    var body = resp.httpResponse.body || '';

    var headers = resp.httpResponse.headers || {};
    var region = headers['x-amz-bucket-region'] || null;
    var bucket = req.params.Bucket || null;
    var bucketRegionCache = req.service.bucketRegionCache;
    if (region && bucket && region !== bucketRegionCache[bucket]) {
      bucketRegionCache[bucket] = region;
    }

    var cachedRegion;
    if (codes[code] && body.length === 0) {
      if (bucket && !region) {
        cachedRegion = bucketRegionCache[bucket] || null;
        if (cachedRegion !== req.httpRequest.region) {
          region = cachedRegion;
        }
      }
      resp.error = AWS.util.error(new Error(), {
        code: codes[code],
        message: null,
        region: region
      });
    } else {
      var data = new AWS.XML.Parser().parse(body.toString());

      if (data.Region && !region) {
        region = data.Region;
        if (bucket && region !== bucketRegionCache[bucket]) {
          bucketRegionCache[bucket] = region;
        }
      } else if (bucket && !region && !data.Region) {
        cachedRegion = bucketRegionCache[bucket] || null;
        if (cachedRegion !== req.httpRequest.region) {
          region = cachedRegion;
        }
      }

      resp.error = AWS.util.error(new Error(), {
        code: data.Code || code,
        message: data.Message || null,
        region: region
      });
    }
    req.service.extractRequestIds(resp);
  },

  /**
   * If region was not obtained synchronously, then send async request
   * to get bucket region for errors resulting from wrong region.
   *
   * @api private
   */
  requestBucketRegion: function requestBucketRegion(resp, done) {
    var error = resp.error;
    var req = resp.request;
    var bucket = req.params.Bucket || null;

    if (!error || !bucket || error.region || req.operation === 'listObjects' ||
        (AWS.util.isNode() && req.operation === 'headBucket') ||
        (error.statusCode === 400 && req.operation !== 'headObject') ||
        regionRedirectErrorCodes.indexOf(error.code) === -1) {
      return done();
    }
    var reqOperation = AWS.util.isNode() ? 'headBucket' : 'listObjects';
    var reqParams = {Bucket: bucket};
    if (reqOperation === 'listObjects') reqParams.MaxKeys = 0;
    var regionReq = req.service[reqOperation](reqParams);
    regionReq._requestRegionForBucket = bucket;
    regionReq.send(function() {
      var region = req.service.bucketRegionCache[bucket] || null;
      error.region = region;
      done();
    });
  },

   /**
   * For browser only. If NetworkingError received, will attempt to obtain
   * the bucket region.
   *
   * @api private
   */
   reqRegionForNetworkingError: function reqRegionForNetworkingError(resp, done) {
    if (!AWS.util.isBrowser()) {
      return done();
    }
    var error = resp.error;
    var request = resp.request;
    var bucket = request.params.Bucket;
    if (!error || error.code !== 'NetworkingError' || !bucket ||
        request.httpRequest.region === 'us-east-1') {
      return done();
    }
    var service = request.service;
    var bucketRegionCache = service.bucketRegionCache;
    var cachedRegion = bucketRegionCache[bucket] || null;

    if (cachedRegion && cachedRegion !== request.httpRequest.region) {
      service.updateReqBucketRegion(request, cachedRegion);
      done();
    } else if (!service.isDnsCompatible(bucket)) {
      service.updateReqBucketRegion(request, 'us-east-1');
      if (bucketRegionCache[bucket] !== 'us-east-1') {
        bucketRegionCache[bucket] = 'us-east-1';
      }
      done();
    } else if (request.httpRequest.virtualHostedBucket) {
      var getRegionReq = service.listObjects({Bucket: bucket, MaxKeys: 0});
      service.updateReqBucketRegion(getRegionReq, 'us-east-1');
      getRegionReq._requestRegionForBucket = bucket;

      getRegionReq.send(function() {
        var region = service.bucketRegionCache[bucket] || null;
        if (region && region !== request.httpRequest.region) {
          service.updateReqBucketRegion(request, region);
        }
        done();
      });
    } else {
      // DNS-compatible path-style
      // (s3ForcePathStyle or bucket name with dot over https)
      // Cannot obtain region information for this case
      done();
    }
   },

  /**
   * Cache for bucket region.
   *
   * @api private
   */
   bucketRegionCache: {},

  /**
   * Clears bucket region cache.
   *
   * @api private
   */
   clearBucketRegionCache: function(buckets) {
    var bucketRegionCache = this.bucketRegionCache;
    if (!buckets) {
      buckets = Object.keys(bucketRegionCache);
    } else if (typeof buckets === 'string') {
      buckets = [buckets];
    }
    for (var i = 0; i < buckets.length; i++) {
      delete bucketRegionCache[buckets[i]];
    }
    return bucketRegionCache;
   },

   /**
    * Corrects request region if bucket's cached region is different
    *
    * @api private
    */
  correctBucketRegionFromCache: function correctBucketRegionFromCache(req) {
    var bucket = req.params.Bucket || null;
    if (bucket) {
      var service = req.service;
      var requestRegion = req.httpRequest.region;
      var cachedRegion = service.bucketRegionCache[bucket];
      if (cachedRegion && cachedRegion !== requestRegion) {
        service.updateReqBucketRegion(req, cachedRegion);
      }
    }
  },

  /**
   * Extracts S3 specific request ids from the http response.
   *
   * @api private
   */
  extractRequestIds: function extractRequestIds(resp) {
    var extendedRequestId = resp.httpResponse.headers ? resp.httpResponse.headers['x-amz-id-2'] : null;
    var cfId = resp.httpResponse.headers ? resp.httpResponse.headers['x-amz-cf-id'] : null;
    resp.extendedRequestId = extendedRequestId;
    resp.cfId = cfId;

    if (resp.error) {
      resp.error.requestId = resp.requestId || null;
      resp.error.extendedRequestId = extendedRequestId;
      resp.error.cfId = cfId;
    }
  },

  /**
   * Get a pre-signed URL for a given operation name.
   *
   * @note You must ensure that you have static or previously resolved
   *   credentials if you call this method synchronously (with no callback),
   *   otherwise it may not properly sign the request. If you cannot guarantee
   *   this (you are using an asynchronous credential provider, i.e., EC2
   *   IAM roles), you should always call this method with an asynchronous
   *   callback.
   * @note Not all operation parameters are supported when using pre-signed
   *   URLs. Certain parameters, such as `SSECustomerKey`, `ACL`, `Expires`,
   *   `ContentLength`, or `Tagging` must be provided as headers when sending a
   *   request. If you are using pre-signed URLs to upload from a browser and
   *   need to use these fields, see {createPresignedPost}.
   * @note The default signer allows altering the request by adding corresponding
   *   headers to set some parameters (e.g. Range) and these added parameters
   *   won't be signed. You must use signatureVersion v4 to to include these
   *   parameters in the signed portion of the URL and enforce exact matching
   *   between headers and signed params in the URL.
   * @note This operation cannot be used with a promise. See note above regarding
   *   asynchronous credentials and use with a callback.
   * @param operation [String] the name of the operation to call
   * @param params [map] parameters to pass to the operation. See the given
   *   operation for the expected operation parameters. In addition, you can
   *   also pass the "Expires" parameter to inform S3 how long the URL should
   *   work for.
   * @option params Expires [Integer] (900) the number of seconds to expire
   *   the pre-signed URL operation in. Defaults to 15 minutes.
   * @param callback [Function] if a callback is provided, this function will
   *   pass the URL as the second parameter (after the error parameter) to
   *   the callback function.
   * @return [String] if called synchronously (with no callback), returns the
   *   signed URL.
   * @return [null] nothing is returned if a callback is provided.
   * @example Pre-signing a getObject operation (synchronously)
   *   var params = {Bucket: 'bucket', Key: 'key'};
   *   var url = s3.getSignedUrl('getObject', params);
   *   console.log('The URL is', url);
   * @example Pre-signing a putObject (asynchronously)
   *   var params = {Bucket: 'bucket', Key: 'key'};
   *   s3.getSignedUrl('putObject', params, function (err, url) {
   *     console.log('The URL is', url);
   *   });
   * @example Pre-signing a putObject operation with a specific payload
   *   var params = {Bucket: 'bucket', Key: 'key', Body: 'body'};
   *   var url = s3.getSignedUrl('putObject', params);
   *   console.log('The URL is', url);
   * @example Passing in a 1-minute expiry time for a pre-signed URL
   *   var params = {Bucket: 'bucket', Key: 'key', Expires: 60};
   *   var url = s3.getSignedUrl('getObject', params);
   *   console.log('The URL is', url); // expires in 60 seconds
   */
  getSignedUrl: function getSignedUrl(operation, params, callback) {
    params = AWS.util.copy(params || {});
    var expires = params.Expires || 900;

    if (typeof expires !== 'number') {
      throw AWS.util.error(new Error(),
        { code: 'InvalidParameterException', message: 'The expiration must be a number, received ' + typeof expires });
    }

    delete params.Expires; // we can't validate this
    var request = this.makeRequest(operation, params);

    if (callback) {
      AWS.util.defer(function() {
        request.presign(expires, callback);
      });
    } else {
      return request.presign(expires, callback);
    }
  },

  /**
   * @!method  getSignedUrlPromise()
   *   Returns a 'thenable' promise that will be resolved with a pre-signed URL
   *   for a given operation name.
   *
   *   Two callbacks can be provided to the `then` method on the returned promise.
   *   The first callback will be called if the promise is fulfilled, and the second
   *   callback will be called if the promise is rejected.
   *   @note Not all operation parameters are supported when using pre-signed
   *      URLs. Certain parameters, such as `SSECustomerKey`, `ACL`, `Expires`,
   *      `ContentLength`, or `Tagging` must be provided as headers when sending a
   *      request. If you are using pre-signed URLs to upload from a browser and
   *      need to use these fields, see {createPresignedPost}.
   *   @param operation [String] the name of the operation to call
   *   @param params [map] parameters to pass to the operation. See the given
   *      operation for the expected operation parameters. In addition, you can
   *      also pass the "Expires" parameter to inform S3 how long the URL should
   *      work for.
   *   @option params Expires [Integer] (900) the number of seconds to expire
   *      the pre-signed URL operation in. Defaults to 15 minutes.
   *   @callback fulfilledCallback function(url)
   *     Called if the promise is fulfilled.
   *     @param url [String] the signed url
   *   @callback rejectedCallback function(err)
   *     Called if the promise is rejected.
   *     @param err [Error] if an error occurred, this value will be filled
   *   @return [Promise] A promise that represents the state of the `refresh` call.
   *   @example Pre-signing a getObject operation
   *      var params = {Bucket: 'bucket', Key: 'key'};
   *      var promise = s3.getSignedUrlPromise('getObject', params);
   *      promise.then(function(url) {
   *        console.log('The URL is', url);
   *      }, function(err) { ... });
   *   @example Pre-signing a putObject operation with a specific payload
   *      var params = {Bucket: 'bucket', Key: 'key', Body: 'body'};
   *      var promise = s3.getSignedUrlPromise('putObject', params);
   *      promise.then(function(url) {
   *        console.log('The URL is', url);
   *      }, function(err) { ... });
   *   @example Passing in a 1-minute expiry time for a pre-signed URL
   *      var params = {Bucket: 'bucket', Key: 'key', Expires: 60};
   *      var promise = s3.getSignedUrlPromise('getObject', params);
   *      promise.then(function(url) {
   *        console.log('The URL is', url);
   *      }, function(err) { ... });
   */

  /**
   * Get a pre-signed POST policy to support uploading to S3 directly from an
   * HTML form.
   *
   * @param params [map]
   * @option params Bucket [String]     The bucket to which the post should be
   *                                    uploaded
   * @option params Expires [Integer]   (3600) The number of seconds for which
   *                                    the presigned policy should be valid.
   * @option params Conditions [Array]  An array of conditions that must be met
   *                                    for the presigned policy to allow the
   *                                    upload. This can include required tags,
   *                                    the accepted range for content lengths,
   *                                    etc.
   * @see http://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-HTTPPOSTConstructPolicy.html
   * @option params Fields [map]        Fields to include in the form. All
   *                                    values passed in as fields will be
   *                                    signed as exact match conditions.
   * @param callback [Function]
   *
   * @note All fields passed in when creating presigned post data will be signed
   *   as exact match conditions. Any fields that will be interpolated by S3
   *   must be added to the fields hash after signing, and an appropriate
   *   condition for such fields must be explicitly added to the Conditions
   *   array passed to this function before signing.
   *
   * @example Presiging post data with a known key
   *   var params = {
   *     Bucket: 'bucket',
   *     Fields: {
   *       key: 'key'
   *     }
   *   };
   *   s3.createPresignedPost(params, function(err, data) {
   *     if (err) {
   *       console.error('Presigning post data encountered an error', err);
   *     } else {
   *       console.log('The post data is', data);
   *     }
   *   });
   *
   * @example Presigning post data with an interpolated key
   *   var params = {
   *     Bucket: 'bucket',
   *     Conditions: [
   *       ['starts-with', '$key', 'path/to/uploads/']
   *     ]
   *   };
   *   s3.createPresignedPost(params, function(err, data) {
   *     if (err) {
   *       console.error('Presigning post data encountered an error', err);
   *     } else {
   *       data.Fields.key = 'path/to/uploads/${filename}';
   *       console.log('The post data is', data);
   *     }
   *   });
   *
   * @note You must ensure that you have static or previously resolved
   *   credentials if you call this method synchronously (with no callback),
   *   otherwise it may not properly sign the request. If you cannot guarantee
   *   this (you are using an asynchronous credential provider, i.e., EC2
   *   IAM roles), you should always call this method with an asynchronous
   *   callback.
   *
   * @return [map]  If called synchronously (with no callback), returns a hash
   *                with the url to set as the form action and a hash of fields
   *                to include in the form.
   * @return [null] Nothing is returned if a callback is provided.
   *
   * @callback callback function (err, data)
   *  @param err [Error] the error object returned from the policy signer
   *  @param data [map] The data necessary to construct an HTML form
   *  @param data.url [String] The URL to use as the action of the form
   *  @param data.fields [map] A hash of fields that must be included in the
   *                           form for the upload to succeed. This hash will
   *                           include the signed POST policy, your access key
   *                           ID and security token (if present), etc. These
   *                           may be safely included as input elements of type
   *                           'hidden.'
   */
  createPresignedPost: function createPresignedPost(params, callback) {
    if (typeof params === 'function' && callback === undefined) {
      callback = params;
      params = null;
    }

    params = AWS.util.copy(params || {});
    var boundParams = this.config.params || {};
    var bucket = params.Bucket || boundParams.Bucket,
      self = this,
      config = this.config,
      endpoint = AWS.util.copy(this.endpoint);
    if (!config.s3BucketEndpoint) {
      endpoint.pathname = '/' + bucket;
    }

    function finalizePost() {
      return {
        url: AWS.util.urlFormat(endpoint),
        fields: self.preparePostFields(
          config.credentials,
          config.region,
          bucket,
          params.Fields,
          params.Conditions,
          params.Expires
        )
      };
    }

    if (callback) {
      config.getCredentials(function (err) {
        if (err) {
          callback(err);
        }

        callback(null, finalizePost());
      });
    } else {
      return finalizePost();
    }
  },

  /**
   * @api private
   */
  preparePostFields: function preparePostFields(
    credentials,
    region,
    bucket,
    fields,
    conditions,
    expiresInSeconds
  ) {
    var now = this.getSkewCorrectedDate();
    if (!credentials || !region || !bucket) {
      throw new Error('Unable to create a POST object policy without a bucket,'
        + ' region, and credentials');
    }
    fields = AWS.util.copy(fields || {});
    conditions = (conditions || []).slice(0);
    expiresInSeconds = expiresInSeconds || 3600;

    var signingDate = AWS.util.date.iso8601(now).replace(/[:\-]|\.\d{3}/g, '');
    var shortDate = signingDate.substr(0, 8);
    var scope = v4Credentials.createScope(shortDate, region, 's3');
    var credential = credentials.accessKeyId + '/' + scope;

    fields['bucket'] = bucket;
    fields['X-Amz-Algorithm'] = 'AWS4-HMAC-SHA256';
    fields['X-Amz-Credential'] = credential;
    fields['X-Amz-Date'] = signingDate;
    if (credentials.sessionToken) {
      fields['X-Amz-Security-Token'] = credentials.sessionToken;
    }
    for (var field in fields) {
      if (fields.hasOwnProperty(field)) {
        var condition = {};
        condition[field] = fields[field];
        conditions.push(condition);
      }
    }

    fields.Policy = this.preparePostPolicy(
      new Date(now.valueOf() + expiresInSeconds * 1000),
      conditions
    );
    fields['X-Amz-Signature'] = AWS.util.crypto.hmac(
      v4Credentials.getSigningKey(credentials, shortDate, region, 's3', true),
      fields.Policy,
      'hex'
    );

    return fields;
  },

  /**
   * @api private
   */
  preparePostPolicy: function preparePostPolicy(expiration, conditions) {
    return AWS.util.base64.encode(JSON.stringify({
      expiration: AWS.util.date.iso8601(expiration),
      conditions: conditions
    }));
  },

  /**
   * @api private
   */
  prepareSignedUrl: function prepareSignedUrl(request) {
    request.addListener('validate', request.service.noPresignedContentLength);
    request.removeListener('build', request.service.addContentType);
    if (!request.params.Body) {
      // no Content-MD5/SHA-256 if body is not provided
      request.removeListener('build', request.service.computeContentMd5);
    } else {
      request.addListener('afterBuild', AWS.EventListeners.Core.COMPUTE_SHA256);
    }
  },

  /**
   * @api private
   * @param request
   */
  disableBodySigning: function disableBodySigning(request) {
    var headers = request.httpRequest.headers;
    // Add the header to anything that isn't a presigned url, unless that presigned url had a body defined
    if (!Object.prototype.hasOwnProperty.call(headers, 'presigned-expires')) {
      headers['X-Amz-Content-Sha256'] = 'UNSIGNED-PAYLOAD';
    }
  },

  /**
   * @api private
   */
  noPresignedContentLength: function noPresignedContentLength(request) {
    if (request.params.ContentLength !== undefined) {
      throw AWS.util.error(new Error(), {code: 'UnexpectedParameter',
        message: 'ContentLength is not supported in pre-signed URLs.'});
    }
  },

  createBucket: function createBucket(params, callback) {
    // When creating a bucket *outside* the classic region, the location
    // constraint must be set for the bucket and it must match the endpoint.
    // This chunk of code will set the location constraint param based
    // on the region (when possible), but it will not override a passed-in
    // location constraint.
    if (typeof params === 'function' || !params) {
      callback = callback || params;
      params = {};
    }
    var hostname = this.endpoint.hostname;
    // copy params so that appending keys does not unintentioinallly
    // mutate params object argument passed in by user
    var copiedParams = AWS.util.copy(params);

    if (hostname !== this.api.globalEndpoint && !params.CreateBucketConfiguration) {
      copiedParams.CreateBucketConfiguration = { LocationConstraint: this.config.region };
    }
    return this.makeRequest('createBucket', copiedParams, callback);
  },

  /**
   * @see AWS.S3.ManagedUpload
   * @overload upload(params = {}, [options], [callback])
   *   Uploads an arbitrarily sized buffer, blob, or stream, using intelligent
   *   concurrent handling of parts if the payload is large enough. You can
   *   configure the concurrent queue size by setting `options`. Note that this
   *   is the only operation for which the SDK can retry requests with stream
   *   bodies.
   *
   *   @param (see AWS.S3.putObject)
   *   @option (see AWS.S3.ManagedUpload.constructor)
   *   @return [AWS.S3.ManagedUpload] the managed upload object that can call
   *     `send()` or track progress.
   *   @example Uploading a stream object
   *     var params = {Bucket: 'bucket', Key: 'key', Body: stream};
   *     s3.upload(params, function(err, data) {
   *       console.log(err, data);
   *     });
   *   @example Uploading a stream with concurrency of 1 and partSize of 10mb
   *     var params = {Bucket: 'bucket', Key: 'key', Body: stream};
   *     var options = {partSize: 10 * 1024 * 1024, queueSize: 1};
   *     s3.upload(params, options, function(err, data) {
   *       console.log(err, data);
   *     });
   * @callback callback function(err, data)
   *   @param err [Error] an error or null if no error occurred.
   *   @param data [map] The response data from the successful upload:
   *   @param data.Location [String] the URL of the uploaded object
   *   @param data.ETag [String] the ETag of the uploaded object
   *   @param data.Bucket [String]  the bucket to which the object was uploaded
   *   @param data.Key [String] the key to which the object was uploaded
   */
  upload: function upload(params, options, callback) {
    if (typeof options === 'function' && callback === undefined) {
      callback = options;
      options = null;
    }

    options = options || {};
    options = AWS.util.merge(options || {}, {service: this, params: params});

    var uploader = new AWS.S3.ManagedUpload(options);
    if (typeof callback === 'function') uploader.send(callback);
    return uploader;
  }
});

/**
 * @api private
 */
AWS.S3.addPromisesToClass = function addPromisesToClass(PromiseDependency) {
  this.prototype.getSignedUrlPromise = AWS.util.promisifyMethod('getSignedUrl', PromiseDependency);
};

/**
 * @api private
 */
AWS.S3.deletePromisesFromClass = function deletePromisesFromClass() {
  delete this.prototype.getSignedUrlPromise;
};

AWS.util.addPromises(AWS.S3);

}).call(this,require('_process'))
},{"../config_regional_endpoint":323,"../core":324,"../region_config":367,"../s3/managed_upload":372,"../signers/v4_credentials":396,"_process":412}],387:[function(require,module,exports){
var AWS = require('../core');

AWS.util.update(AWS.SQS.prototype, {
  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    request.addListener('build', this.buildEndpoint);

    if (request.service.config.computeChecksums) {
      if (request.operation === 'sendMessage') {
        request.addListener('extractData', this.verifySendMessageChecksum);
      } else if (request.operation === 'sendMessageBatch') {
        request.addListener('extractData', this.verifySendMessageBatchChecksum);
      } else if (request.operation === 'receiveMessage') {
        request.addListener('extractData', this.verifyReceiveMessageChecksum);
      }
    }
  },

  /**
   * @api private
   */
  verifySendMessageChecksum: function verifySendMessageChecksum(response) {
    if (!response.data) return;

    var md5 = response.data.MD5OfMessageBody;
    var body = this.params.MessageBody;
    var calculatedMd5 = this.service.calculateChecksum(body);
    if (calculatedMd5 !== md5) {
      var msg = 'Got "' + response.data.MD5OfMessageBody +
        '", expecting "' + calculatedMd5 + '".';
      this.service.throwInvalidChecksumError(response,
        [response.data.MessageId], msg);
    }
  },

  /**
   * @api private
   */
  verifySendMessageBatchChecksum: function verifySendMessageBatchChecksum(response) {
    if (!response.data) return;

    var service = this.service;
    var entries = {};
    var errors = [];
    var messageIds = [];
    AWS.util.arrayEach(response.data.Successful, function (entry) {
      entries[entry.Id] = entry;
    });
    AWS.util.arrayEach(this.params.Entries, function (entry) {
      if (entries[entry.Id]) {
        var md5 = entries[entry.Id].MD5OfMessageBody;
        var body = entry.MessageBody;
        if (!service.isChecksumValid(md5, body)) {
          errors.push(entry.Id);
          messageIds.push(entries[entry.Id].MessageId);
        }
      }
    });

    if (errors.length > 0) {
      service.throwInvalidChecksumError(response, messageIds,
        'Invalid messages: ' + errors.join(', '));
    }
  },

  /**
   * @api private
   */
  verifyReceiveMessageChecksum: function verifyReceiveMessageChecksum(response) {
    if (!response.data) return;

    var service = this.service;
    var messageIds = [];
    AWS.util.arrayEach(response.data.Messages, function(message) {
      var md5 = message.MD5OfBody;
      var body = message.Body;
      if (!service.isChecksumValid(md5, body)) {
        messageIds.push(message.MessageId);
      }
    });

    if (messageIds.length > 0) {
      service.throwInvalidChecksumError(response, messageIds,
        'Invalid messages: ' + messageIds.join(', '));
    }
  },

  /**
   * @api private
   */
  throwInvalidChecksumError: function throwInvalidChecksumError(response, ids, message) {
    response.error = AWS.util.error(new Error(), {
      retryable: true,
      code: 'InvalidChecksum',
      messageIds: ids,
      message: response.request.operation +
               ' returned an invalid MD5 response. ' + message
    });
  },

  /**
   * @api private
   */
  isChecksumValid: function isChecksumValid(checksum, data) {
    return this.calculateChecksum(data) === checksum;
  },

  /**
   * @api private
   */
  calculateChecksum: function calculateChecksum(data) {
    return AWS.util.crypto.md5(data, 'hex');
  },

  /**
   * @api private
   */
  buildEndpoint: function buildEndpoint(request) {
    var url = request.httpRequest.params.QueueUrl;
    if (url) {
      request.httpRequest.endpoint = new AWS.Endpoint(url);

      // signature version 4 requires the region name to be set,
      // sqs queue urls contain the region name
      var matches = request.httpRequest.endpoint.host.match(/^sqs\.(.+?)\./);
      if (matches) request.httpRequest.region = matches[1];
    }
  }
});

},{"../core":324}],388:[function(require,module,exports){
var AWS = require('../core');
var resolveRegionalEndpointsFlag = require('../config_regional_endpoint');
var ENV_REGIONAL_ENDPOINT_ENABLED = 'AWS_STS_REGIONAL_ENDPOINTS';
var CONFIG_REGIONAL_ENDPOINT_ENABLED = 'sts_regional_endpoints';

AWS.util.update(AWS.STS.prototype, {
  /**
   * @overload credentialsFrom(data, credentials = null)
   *   Creates a credentials object from STS response data containing
   *   credentials information. Useful for quickly setting AWS credentials.
   *
   *   @note This is a low-level utility function. If you want to load temporary
   *     credentials into your process for subsequent requests to AWS resources,
   *     you should use {AWS.TemporaryCredentials} instead.
   *   @param data [map] data retrieved from a call to {getFederatedToken},
   *     {getSessionToken}, {assumeRole}, or {assumeRoleWithWebIdentity}.
   *   @param credentials [AWS.Credentials] an optional credentials object to
   *     fill instead of creating a new object. Useful when modifying an
   *     existing credentials object from a refresh call.
   *   @return [AWS.TemporaryCredentials] the set of temporary credentials
   *     loaded from a raw STS operation response.
   *   @example Using credentialsFrom to load global AWS credentials
   *     var sts = new AWS.STS();
   *     sts.getSessionToken(function (err, data) {
   *       if (err) console.log("Error getting credentials");
   *       else {
   *         AWS.config.credentials = sts.credentialsFrom(data);
   *       }
   *     });
   *   @see AWS.TemporaryCredentials
   */
  credentialsFrom: function credentialsFrom(data, credentials) {
    if (!data) return null;
    if (!credentials) credentials = new AWS.TemporaryCredentials();
    credentials.expired = false;
    credentials.accessKeyId = data.Credentials.AccessKeyId;
    credentials.secretAccessKey = data.Credentials.SecretAccessKey;
    credentials.sessionToken = data.Credentials.SessionToken;
    credentials.expireTime = data.Credentials.Expiration;
    return credentials;
  },

  assumeRoleWithWebIdentity: function assumeRoleWithWebIdentity(params, callback) {
    return this.makeUnauthenticatedRequest('assumeRoleWithWebIdentity', params, callback);
  },

  assumeRoleWithSAML: function assumeRoleWithSAML(params, callback) {
    return this.makeUnauthenticatedRequest('assumeRoleWithSAML', params, callback);
  },

  /**
   * @api private
   */
  setupRequestListeners: function setupRequestListeners(request) {
    request.addListener('validate', this.optInRegionalEndpoint, true);
  },

  /**
   * @api private
   */
  optInRegionalEndpoint: function optInRegionalEndpoint(req) {
    var service = req.service;
    var config = service.config;
    config.stsRegionalEndpoints = resolveRegionalEndpointsFlag(service._originalConfig, {
      env: ENV_REGIONAL_ENDPOINT_ENABLED,
      sharedConfig: CONFIG_REGIONAL_ENDPOINT_ENABLED,
      clientConfig: 'stsRegionalEndpoints'
    });
    if (
      config.stsRegionalEndpoints === 'regional' &&
      service.isGlobalEndpoint
    ) {
      //client will throw if region is not supplied; request will be signed with specified region
      if (!config.region) {
        throw AWS.util.error(new Error(),
          {code: 'ConfigError', message: 'Missing region in config'});
      }
      var insertPoint = config.endpoint.indexOf('.amazonaws.com');
      var regionalEndpoint = config.endpoint.substring(0, insertPoint) +
        '.' + config.region + config.endpoint.substring(insertPoint);
      req.httpRequest.updateEndpoint(regionalEndpoint);
      req.httpRequest.region = config.region;
    }
  }

});

},{"../config_regional_endpoint":323,"../core":324}],389:[function(require,module,exports){
var AWS = require('../core');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
var expiresHeader = 'presigned-expires';

/**
 * @api private
 */
function signedUrlBuilder(request) {
  var expires = request.httpRequest.headers[expiresHeader];
  var signerClass = request.service.getSignerClass(request);

  delete request.httpRequest.headers['User-Agent'];
  delete request.httpRequest.headers['X-Amz-User-Agent'];

  if (signerClass === AWS.Signers.V4) {
    if (expires > 604800) { // one week expiry is invalid
      var message = 'Presigning does not support expiry time greater ' +
                    'than a week with SigV4 signing.';
      throw AWS.util.error(new Error(), {
        code: 'InvalidExpiryTime', message: message, retryable: false
      });
    }
    request.httpRequest.headers[expiresHeader] = expires;
  } else if (signerClass === AWS.Signers.S3) {
    var now = request.service ? request.service.getSkewCorrectedDate() : AWS.util.date.getDate();
    request.httpRequest.headers[expiresHeader] = parseInt(
      AWS.util.date.unixTimestamp(now) + expires, 10).toString();
  } else {
    throw AWS.util.error(new Error(), {
      message: 'Presigning only supports S3 or SigV4 signing.',
      code: 'UnsupportedSigner', retryable: false
    });
  }
}

/**
 * @api private
 */
function signedUrlSigner(request) {
  var endpoint = request.httpRequest.endpoint;
  var parsedUrl = AWS.util.urlParse(request.httpRequest.path);
  var queryParams = {};

  if (parsedUrl.search) {
    queryParams = AWS.util.queryStringParse(parsedUrl.search.substr(1));
  }

  var auth = request.httpRequest.headers['Authorization'].split(' ');
  if (auth[0] === 'AWS') {
    auth = auth[1].split(':');
    queryParams['Signature'] = auth.pop();
    queryParams['AWSAccessKeyId'] = auth.join(':');

    AWS.util.each(request.httpRequest.headers, function (key, value) {
      if (key === expiresHeader) key = 'Expires';
      if (key.indexOf('x-amz-meta-') === 0) {
        // Delete existing, potentially not normalized key
        delete queryParams[key];
        key = key.toLowerCase();
      }
      queryParams[key] = value;
    });
    delete request.httpRequest.headers[expiresHeader];
    delete queryParams['Authorization'];
    delete queryParams['Host'];
  } else if (auth[0] === 'AWS4-HMAC-SHA256') { // SigV4 signing
    auth.shift();
    var rest = auth.join(' ');
    var signature = rest.match(/Signature=(.*?)(?:,|\s|\r?\n|$)/)[1];
    queryParams['X-Amz-Signature'] = signature;
    delete queryParams['Expires'];
  }

  // build URL
  endpoint.pathname = parsedUrl.pathname;
  endpoint.search = AWS.util.queryParamsToString(queryParams);
}

/**
 * @api private
 */
AWS.Signers.Presign = inherit({
  /**
   * @api private
   */
  sign: function sign(request, expireTime, callback) {
    request.httpRequest.headers[expiresHeader] = expireTime || 3600;
    request.on('build', signedUrlBuilder);
    request.on('sign', signedUrlSigner);
    request.removeListener('afterBuild',
      AWS.EventListeners.Core.SET_CONTENT_LENGTH);
    request.removeListener('afterBuild',
      AWS.EventListeners.Core.COMPUTE_SHA256);

    request.emit('beforePresign', [request]);

    if (callback) {
      request.build(function() {
        if (this.response.error) callback(this.response.error);
        else {
          callback(null, AWS.util.urlFormat(request.httpRequest.endpoint));
        }
      });
    } else {
      request.build();
      if (request.response.error) throw request.response.error;
      return AWS.util.urlFormat(request.httpRequest.endpoint);
    }
  }
});

/**
 * @api private
 */
module.exports = AWS.Signers.Presign;

},{"../core":324}],390:[function(require,module,exports){
var AWS = require('../core');

var inherit = AWS.util.inherit;

/**
 * @api private
 */
AWS.Signers.RequestSigner = inherit({
  constructor: function RequestSigner(request) {
    this.request = request;
  },

  setServiceClientId: function setServiceClientId(id) {
    this.serviceClientId = id;
  },

  getServiceClientId: function getServiceClientId() {
    return this.serviceClientId;
  }
});

AWS.Signers.RequestSigner.getVersion = function getVersion(version) {
  switch (version) {
    case 'v2': return AWS.Signers.V2;
    case 'v3': return AWS.Signers.V3;
    case 's3v4': return AWS.Signers.V4;
    case 'v4': return AWS.Signers.V4;
    case 's3': return AWS.Signers.S3;
    case 'v3https': return AWS.Signers.V3Https;
  }
  throw new Error('Unknown signing version ' + version);
};

require('./v2');
require('./v3');
require('./v3https');
require('./v4');
require('./s3');
require('./presign');

},{"../core":324,"./presign":389,"./s3":391,"./v2":392,"./v3":393,"./v3https":394,"./v4":395}],391:[function(require,module,exports){
var AWS = require('../core');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
AWS.Signers.S3 = inherit(AWS.Signers.RequestSigner, {
  /**
   * When building the stringToSign, these sub resource params should be
   * part of the canonical resource string with their NON-decoded values
   */
  subResources: {
    'acl': 1,
    'accelerate': 1,
    'analytics': 1,
    'cors': 1,
    'lifecycle': 1,
    'delete': 1,
    'inventory': 1,
    'location': 1,
    'logging': 1,
    'metrics': 1,
    'notification': 1,
    'partNumber': 1,
    'policy': 1,
    'requestPayment': 1,
    'replication': 1,
    'restore': 1,
    'tagging': 1,
    'torrent': 1,
    'uploadId': 1,
    'uploads': 1,
    'versionId': 1,
    'versioning': 1,
    'versions': 1,
    'website': 1
  },

  // when building the stringToSign, these querystring params should be
  // part of the canonical resource string with their NON-encoded values
  responseHeaders: {
    'response-content-type': 1,
    'response-content-language': 1,
    'response-expires': 1,
    'response-cache-control': 1,
    'response-content-disposition': 1,
    'response-content-encoding': 1
  },

  addAuthorization: function addAuthorization(credentials, date) {
    if (!this.request.headers['presigned-expires']) {
      this.request.headers['X-Amz-Date'] = AWS.util.date.rfc822(date);
    }

    if (credentials.sessionToken) {
      // presigned URLs require this header to be lowercased
      this.request.headers['x-amz-security-token'] = credentials.sessionToken;
    }

    var signature = this.sign(credentials.secretAccessKey, this.stringToSign());
    var auth = 'AWS ' + credentials.accessKeyId + ':' + signature;

    this.request.headers['Authorization'] = auth;
  },

  stringToSign: function stringToSign() {
    var r = this.request;

    var parts = [];
    parts.push(r.method);
    parts.push(r.headers['Content-MD5'] || '');
    parts.push(r.headers['Content-Type'] || '');

    // This is the "Date" header, but we use X-Amz-Date.
    // The S3 signing mechanism requires us to pass an empty
    // string for this Date header regardless.
    parts.push(r.headers['presigned-expires'] || '');

    var headers = this.canonicalizedAmzHeaders();
    if (headers) parts.push(headers);
    parts.push(this.canonicalizedResource());

    return parts.join('\n');

  },

  canonicalizedAmzHeaders: function canonicalizedAmzHeaders() {

    var amzHeaders = [];

    AWS.util.each(this.request.headers, function (name) {
      if (name.match(/^x-amz-/i))
        amzHeaders.push(name);
    });

    amzHeaders.sort(function (a, b) {
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    });

    var parts = [];
    AWS.util.arrayEach.call(this, amzHeaders, function (name) {
      parts.push(name.toLowerCase() + ':' + String(this.request.headers[name]));
    });

    return parts.join('\n');

  },

  canonicalizedResource: function canonicalizedResource() {

    var r = this.request;

    var parts = r.path.split('?');
    var path = parts[0];
    var querystring = parts[1];

    var resource = '';

    if (r.virtualHostedBucket)
      resource += '/' + r.virtualHostedBucket;

    resource += path;

    if (querystring) {

      // collect a list of sub resources and query params that need to be signed
      var resources = [];

      AWS.util.arrayEach.call(this, querystring.split('&'), function (param) {
        var name = param.split('=')[0];
        var value = param.split('=')[1];
        if (this.subResources[name] || this.responseHeaders[name]) {
          var subresource = { name: name };
          if (value !== undefined) {
            if (this.subResources[name]) {
              subresource.value = value;
            } else {
              subresource.value = decodeURIComponent(value);
            }
          }
          resources.push(subresource);
        }
      });

      resources.sort(function (a, b) { return a.name < b.name ? -1 : 1; });

      if (resources.length) {

        querystring = [];
        AWS.util.arrayEach(resources, function (res) {
          if (res.value === undefined) {
            querystring.push(res.name);
          } else {
            querystring.push(res.name + '=' + res.value);
          }
        });

        resource += '?' + querystring.join('&');
      }

    }

    return resource;

  },

  sign: function sign(secret, string) {
    return AWS.util.crypto.hmac(secret, string, 'base64', 'sha1');
  }
});

/**
 * @api private
 */
module.exports = AWS.Signers.S3;

},{"../core":324}],392:[function(require,module,exports){
var AWS = require('../core');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
AWS.Signers.V2 = inherit(AWS.Signers.RequestSigner, {
  addAuthorization: function addAuthorization(credentials, date) {

    if (!date) date = AWS.util.date.getDate();

    var r = this.request;

    r.params.Timestamp = AWS.util.date.iso8601(date);
    r.params.SignatureVersion = '2';
    r.params.SignatureMethod = 'HmacSHA256';
    r.params.AWSAccessKeyId = credentials.accessKeyId;

    if (credentials.sessionToken) {
      r.params.SecurityToken = credentials.sessionToken;
    }

    delete r.params.Signature; // delete old Signature for re-signing
    r.params.Signature = this.signature(credentials);

    r.body = AWS.util.queryParamsToString(r.params);
    r.headers['Content-Length'] = r.body.length;
  },

  signature: function signature(credentials) {
    return AWS.util.crypto.hmac(credentials.secretAccessKey, this.stringToSign(), 'base64');
  },

  stringToSign: function stringToSign() {
    var parts = [];
    parts.push(this.request.method);
    parts.push(this.request.endpoint.host.toLowerCase());
    parts.push(this.request.pathname());
    parts.push(AWS.util.queryParamsToString(this.request.params));
    return parts.join('\n');
  }

});

/**
 * @api private
 */
module.exports = AWS.Signers.V2;

},{"../core":324}],393:[function(require,module,exports){
var AWS = require('../core');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
AWS.Signers.V3 = inherit(AWS.Signers.RequestSigner, {
  addAuthorization: function addAuthorization(credentials, date) {

    var datetime = AWS.util.date.rfc822(date);

    this.request.headers['X-Amz-Date'] = datetime;

    if (credentials.sessionToken) {
      this.request.headers['x-amz-security-token'] = credentials.sessionToken;
    }

    this.request.headers['X-Amzn-Authorization'] =
      this.authorization(credentials, datetime);

  },

  authorization: function authorization(credentials) {
    return 'AWS3 ' +
      'AWSAccessKeyId=' + credentials.accessKeyId + ',' +
      'Algorithm=HmacSHA256,' +
      'SignedHeaders=' + this.signedHeaders() + ',' +
      'Signature=' + this.signature(credentials);
  },

  signedHeaders: function signedHeaders() {
    var headers = [];
    AWS.util.arrayEach(this.headersToSign(), function iterator(h) {
      headers.push(h.toLowerCase());
    });
    return headers.sort().join(';');
  },

  canonicalHeaders: function canonicalHeaders() {
    var headers = this.request.headers;
    var parts = [];
    AWS.util.arrayEach(this.headersToSign(), function iterator(h) {
      parts.push(h.toLowerCase().trim() + ':' + String(headers[h]).trim());
    });
    return parts.sort().join('\n') + '\n';
  },

  headersToSign: function headersToSign() {
    var headers = [];
    AWS.util.each(this.request.headers, function iterator(k) {
      if (k === 'Host' || k === 'Content-Encoding' || k.match(/^X-Amz/i)) {
        headers.push(k);
      }
    });
    return headers;
  },

  signature: function signature(credentials) {
    return AWS.util.crypto.hmac(credentials.secretAccessKey, this.stringToSign(), 'base64');
  },

  stringToSign: function stringToSign() {
    var parts = [];
    parts.push(this.request.method);
    parts.push('/');
    parts.push('');
    parts.push(this.canonicalHeaders());
    parts.push(this.request.body);
    return AWS.util.crypto.sha256(parts.join('\n'));
  }

});

/**
 * @api private
 */
module.exports = AWS.Signers.V3;

},{"../core":324}],394:[function(require,module,exports){
var AWS = require('../core');
var inherit = AWS.util.inherit;

require('./v3');

/**
 * @api private
 */
AWS.Signers.V3Https = inherit(AWS.Signers.V3, {
  authorization: function authorization(credentials) {
    return 'AWS3-HTTPS ' +
      'AWSAccessKeyId=' + credentials.accessKeyId + ',' +
      'Algorithm=HmacSHA256,' +
      'Signature=' + this.signature(credentials);
  },

  stringToSign: function stringToSign() {
    return this.request.headers['X-Amz-Date'];
  }
});

/**
 * @api private
 */
module.exports = AWS.Signers.V3Https;

},{"../core":324,"./v3":393}],395:[function(require,module,exports){
var AWS = require('../core');
var v4Credentials = require('./v4_credentials');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
var expiresHeader = 'presigned-expires';

/**
 * @api private
 */
AWS.Signers.V4 = inherit(AWS.Signers.RequestSigner, {
  constructor: function V4(request, serviceName, options) {
    AWS.Signers.RequestSigner.call(this, request);
    this.serviceName = serviceName;
    options = options || {};
    this.signatureCache = typeof options.signatureCache === 'boolean' ? options.signatureCache : true;
    this.operation = options.operation;
    this.signatureVersion = options.signatureVersion;
  },

  algorithm: 'AWS4-HMAC-SHA256',

  addAuthorization: function addAuthorization(credentials, date) {
    var datetime = AWS.util.date.iso8601(date).replace(/[:\-]|\.\d{3}/g, '');

    if (this.isPresigned()) {
      this.updateForPresigned(credentials, datetime);
    } else {
      this.addHeaders(credentials, datetime);
    }

    this.request.headers['Authorization'] =
      this.authorization(credentials, datetime);
  },

  addHeaders: function addHeaders(credentials, datetime) {
    this.request.headers['X-Amz-Date'] = datetime;
    if (credentials.sessionToken) {
      this.request.headers['x-amz-security-token'] = credentials.sessionToken;
    }
  },

  updateForPresigned: function updateForPresigned(credentials, datetime) {
    var credString = this.credentialString(datetime);
    var qs = {
      'X-Amz-Date': datetime,
      'X-Amz-Algorithm': this.algorithm,
      'X-Amz-Credential': credentials.accessKeyId + '/' + credString,
      'X-Amz-Expires': this.request.headers[expiresHeader],
      'X-Amz-SignedHeaders': this.signedHeaders()
    };

    if (credentials.sessionToken) {
      qs['X-Amz-Security-Token'] = credentials.sessionToken;
    }

    if (this.request.headers['Content-Type']) {
      qs['Content-Type'] = this.request.headers['Content-Type'];
    }
    if (this.request.headers['Content-MD5']) {
      qs['Content-MD5'] = this.request.headers['Content-MD5'];
    }
    if (this.request.headers['Cache-Control']) {
      qs['Cache-Control'] = this.request.headers['Cache-Control'];
    }

    // need to pull in any other X-Amz-* headers
    AWS.util.each.call(this, this.request.headers, function(key, value) {
      if (key === expiresHeader) return;
      if (this.isSignableHeader(key)) {
        var lowerKey = key.toLowerCase();
        // Metadata should be normalized
        if (lowerKey.indexOf('x-amz-meta-') === 0) {
          qs[lowerKey] = value;
        } else if (lowerKey.indexOf('x-amz-') === 0) {
          qs[key] = value;
        }
      }
    });

    var sep = this.request.path.indexOf('?') >= 0 ? '&' : '?';
    this.request.path += sep + AWS.util.queryParamsToString(qs);
  },

  authorization: function authorization(credentials, datetime) {
    var parts = [];
    var credString = this.credentialString(datetime);
    parts.push(this.algorithm + ' Credential=' +
      credentials.accessKeyId + '/' + credString);
    parts.push('SignedHeaders=' + this.signedHeaders());
    parts.push('Signature=' + this.signature(credentials, datetime));
    return parts.join(', ');
  },

  signature: function signature(credentials, datetime) {
    var signingKey = v4Credentials.getSigningKey(
      credentials,
      datetime.substr(0, 8),
      this.request.region,
      this.serviceName,
      this.signatureCache
    );
    return AWS.util.crypto.hmac(signingKey, this.stringToSign(datetime), 'hex');
  },

  stringToSign: function stringToSign(datetime) {
    var parts = [];
    parts.push('AWS4-HMAC-SHA256');
    parts.push(datetime);
    parts.push(this.credentialString(datetime));
    parts.push(this.hexEncodedHash(this.canonicalString()));
    return parts.join('\n');
  },

  canonicalString: function canonicalString() {
    var parts = [], pathname = this.request.pathname();
    if (this.serviceName !== 's3' && this.signatureVersion !== 's3v4') pathname = AWS.util.uriEscapePath(pathname);

    parts.push(this.request.method);
    parts.push(pathname);
    parts.push(this.request.search());
    parts.push(this.canonicalHeaders() + '\n');
    parts.push(this.signedHeaders());
    parts.push(this.hexEncodedBodyHash());
    return parts.join('\n');
  },

  canonicalHeaders: function canonicalHeaders() {
    var headers = [];
    AWS.util.each.call(this, this.request.headers, function (key, item) {
      headers.push([key, item]);
    });
    headers.sort(function (a, b) {
      return a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1;
    });
    var parts = [];
    AWS.util.arrayEach.call(this, headers, function (item) {
      var key = item[0].toLowerCase();
      if (this.isSignableHeader(key)) {
        var value = item[1];
        if (typeof value === 'undefined' || value === null || typeof value.toString !== 'function') {
          throw AWS.util.error(new Error('Header ' + key + ' contains invalid value'), {
            code: 'InvalidHeader'
          });
        }
        parts.push(key + ':' +
          this.canonicalHeaderValues(value.toString()));
      }
    });
    return parts.join('\n');
  },

  canonicalHeaderValues: function canonicalHeaderValues(values) {
    return values.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
  },

  signedHeaders: function signedHeaders() {
    var keys = [];
    AWS.util.each.call(this, this.request.headers, function (key) {
      key = key.toLowerCase();
      if (this.isSignableHeader(key)) keys.push(key);
    });
    return keys.sort().join(';');
  },

  credentialString: function credentialString(datetime) {
    return v4Credentials.createScope(
      datetime.substr(0, 8),
      this.request.region,
      this.serviceName
    );
  },

  hexEncodedHash: function hash(string) {
    return AWS.util.crypto.sha256(string, 'hex');
  },

  hexEncodedBodyHash: function hexEncodedBodyHash() {
    var request = this.request;
    if (this.isPresigned() && this.serviceName === 's3' && !request.body) {
      return 'UNSIGNED-PAYLOAD';
    } else if (request.headers['X-Amz-Content-Sha256']) {
      return request.headers['X-Amz-Content-Sha256'];
    } else {
      return this.hexEncodedHash(this.request.body || '');
    }
  },

  unsignableHeaders: [
    'authorization',
    'content-type',
    'content-length',
    'user-agent',
    expiresHeader,
    'expect',
    'x-amzn-trace-id'
  ],

  isSignableHeader: function isSignableHeader(key) {
    if (key.toLowerCase().indexOf('x-amz-') === 0) return true;
    return this.unsignableHeaders.indexOf(key) < 0;
  },

  isPresigned: function isPresigned() {
    return this.request.headers[expiresHeader] ? true : false;
  }

});

/**
 * @api private
 */
module.exports = AWS.Signers.V4;

},{"../core":324,"./v4_credentials":396}],396:[function(require,module,exports){
var AWS = require('../core');

/**
 * @api private
 */
var cachedSecret = {};

/**
 * @api private
 */
var cacheQueue = [];

/**
 * @api private
 */
var maxCacheEntries = 50;

/**
 * @api private
 */
var v4Identifier = 'aws4_request';

/**
 * @api private
 */
module.exports = {
  /**
   * @api private
   *
   * @param date [String]
   * @param region [String]
   * @param serviceName [String]
   * @return [String]
   */
  createScope: function createScope(date, region, serviceName) {
    return [
      date.substr(0, 8),
      region,
      serviceName,
      v4Identifier
    ].join('/');
  },

  /**
   * @api private
   *
   * @param credentials [Credentials]
   * @param date [String]
   * @param region [String]
   * @param service [String]
   * @param shouldCache [Boolean]
   * @return [String]
   */
  getSigningKey: function getSigningKey(
    credentials,
    date,
    region,
    service,
    shouldCache
  ) {
    var credsIdentifier = AWS.util.crypto
      .hmac(credentials.secretAccessKey, credentials.accessKeyId, 'base64');
    var cacheKey = [credsIdentifier, date, region, service].join('_');
    shouldCache = shouldCache !== false;
    if (shouldCache && (cacheKey in cachedSecret)) {
      return cachedSecret[cacheKey];
    }

    var kDate = AWS.util.crypto.hmac(
      'AWS4' + credentials.secretAccessKey,
      date,
      'buffer'
    );
    var kRegion = AWS.util.crypto.hmac(kDate, region, 'buffer');
    var kService = AWS.util.crypto.hmac(kRegion, service, 'buffer');

    var signingKey = AWS.util.crypto.hmac(kService, v4Identifier, 'buffer');
    if (shouldCache) {
      cachedSecret[cacheKey] = signingKey;
      cacheQueue.push(cacheKey);
      if (cacheQueue.length > maxCacheEntries) {
        // remove the oldest entry (not the least recently used)
        delete cachedSecret[cacheQueue.shift()];
      }
    }

    return signingKey;
  },

  /**
   * @api private
   *
   * Empties the derived signing key cache. Made available for testing purposes
   * only.
   */
  emptyCache: function emptyCache() {
    cachedSecret = {};
    cacheQueue = [];
  }
};

},{"../core":324}],397:[function(require,module,exports){
function AcceptorStateMachine(states, state) {
  this.currentState = state || null;
  this.states = states || {};
}

AcceptorStateMachine.prototype.runTo = function runTo(finalState, done, bindObject, inputError) {
  if (typeof finalState === 'function') {
    inputError = bindObject; bindObject = done;
    done = finalState; finalState = null;
  }

  var self = this;
  var state = self.states[self.currentState];
  state.fn.call(bindObject || self, inputError, function(err) {
    if (err) {
      if (state.fail) self.currentState = state.fail;
      else return done ? done.call(bindObject, err) : null;
    } else {
      if (state.accept) self.currentState = state.accept;
      else return done ? done.call(bindObject) : null;
    }
    if (self.currentState === finalState) {
      return done ? done.call(bindObject, err) : null;
    }

    self.runTo(finalState, done, bindObject, err);
  });
};

AcceptorStateMachine.prototype.addState = function addState(name, acceptState, failState, fn) {
  if (typeof acceptState === 'function') {
    fn = acceptState; acceptState = null; failState = null;
  } else if (typeof failState === 'function') {
    fn = failState; failState = null;
  }

  if (!this.currentState) this.currentState = name;
  this.states[name] = { accept: acceptState, fail: failState, fn: fn };
  return this;
};

/**
 * @api private
 */
module.exports = AcceptorStateMachine;

},{}],398:[function(require,module,exports){
(function (process,setImmediate){
/* eslint guard-for-in:0 */
var AWS;

/**
 * A set of utility methods for use with the AWS SDK.
 *
 * @!attribute abort
 *   Return this value from an iterator function {each} or {arrayEach}
 *   to break out of the iteration.
 *   @example Breaking out of an iterator function
 *     AWS.util.each({a: 1, b: 2, c: 3}, function(key, value) {
 *       if (key == 'b') return AWS.util.abort;
 *     });
 *   @see each
 *   @see arrayEach
 * @api private
 */
var util = {
  environment: 'nodejs',
  engine: function engine() {
    if (util.isBrowser() && typeof navigator !== 'undefined') {
      return navigator.userAgent;
    } else {
      var engine = process.platform + '/' + process.version;
      if (process.env.AWS_EXECUTION_ENV) {
        engine += ' exec-env/' + process.env.AWS_EXECUTION_ENV;
      }
      return engine;
    }
  },

  userAgent: function userAgent() {
    var name = util.environment;
    var agent = 'aws-sdk-' + name + '/' + require('./core').VERSION;
    if (name === 'nodejs') agent += ' ' + util.engine();
    return agent;
  },

  uriEscape: function uriEscape(string) {
    var output = encodeURIComponent(string);
    output = output.replace(/[^A-Za-z0-9_.~\-%]+/g, escape);

    // AWS percent-encodes some extra non-standard characters in a URI
    output = output.replace(/[*]/g, function(ch) {
      return '%' + ch.charCodeAt(0).toString(16).toUpperCase();
    });

    return output;
  },

  uriEscapePath: function uriEscapePath(string) {
    var parts = [];
    util.arrayEach(string.split('/'), function (part) {
      parts.push(util.uriEscape(part));
    });
    return parts.join('/');
  },

  urlParse: function urlParse(url) {
    return util.url.parse(url);
  },

  urlFormat: function urlFormat(url) {
    return util.url.format(url);
  },

  queryStringParse: function queryStringParse(qs) {
    return util.querystring.parse(qs);
  },

  queryParamsToString: function queryParamsToString(params) {
    var items = [];
    var escape = util.uriEscape;
    var sortedKeys = Object.keys(params).sort();

    util.arrayEach(sortedKeys, function(name) {
      var value = params[name];
      var ename = escape(name);
      var result = ename + '=';
      if (Array.isArray(value)) {
        var vals = [];
        util.arrayEach(value, function(item) { vals.push(escape(item)); });
        result = ename + '=' + vals.sort().join('&' + ename + '=');
      } else if (value !== undefined && value !== null) {
        result = ename + '=' + escape(value);
      }
      items.push(result);
    });

    return items.join('&');
  },

  readFileSync: function readFileSync(path) {
    if (util.isBrowser()) return null;
    return require('fs').readFileSync(path, 'utf-8');
  },

  base64: {
    encode: function encode64(string) {
      if (typeof string === 'number') {
        throw util.error(new Error('Cannot base64 encode number ' + string));
      }
      if (string === null || typeof string === 'undefined') {
        return string;
      }
      var buf = util.buffer.toBuffer(string);
      return buf.toString('base64');
    },

    decode: function decode64(string) {
      if (typeof string === 'number') {
        throw util.error(new Error('Cannot base64 decode number ' + string));
      }
      if (string === null || typeof string === 'undefined') {
        return string;
      }
      return util.buffer.toBuffer(string, 'base64');
    }

  },

  buffer: {
    /**
     * Buffer constructor for Node buffer and buffer pollyfill
     */
    toBuffer: function(data, encoding) {
      return (typeof util.Buffer.from === 'function' && util.Buffer.from !== Uint8Array.from) ?
        util.Buffer.from(data, encoding) : new util.Buffer(data, encoding);
    },

    alloc: function(size, fill, encoding) {
      if (typeof size !== 'number') {
        throw new Error('size passed to alloc must be a number.');
      }
      if (typeof util.Buffer.alloc === 'function') {
        return util.Buffer.alloc(size, fill, encoding);
      } else {
        var buf = new util.Buffer(size);
        if (fill !== undefined && typeof buf.fill === 'function') {
          buf.fill(fill, undefined, undefined, encoding);
        }
        return buf;
      }
    },

    toStream: function toStream(buffer) {
      if (!util.Buffer.isBuffer(buffer)) buffer =  util.buffer.toBuffer(buffer);

      var readable = new (util.stream.Readable)();
      var pos = 0;
      readable._read = function(size) {
        if (pos >= buffer.length) return readable.push(null);

        var end = pos + size;
        if (end > buffer.length) end = buffer.length;
        readable.push(buffer.slice(pos, end));
        pos = end;
      };

      return readable;
    },

    /**
     * Concatenates a list of Buffer objects.
     */
    concat: function(buffers) {
      var length = 0,
          offset = 0,
          buffer = null, i;

      for (i = 0; i < buffers.length; i++) {
        length += buffers[i].length;
      }

      buffer = util.buffer.alloc(length);

      for (i = 0; i < buffers.length; i++) {
        buffers[i].copy(buffer, offset);
        offset += buffers[i].length;
      }

      return buffer;
    }
  },

  string: {
    byteLength: function byteLength(string) {
      if (string === null || string === undefined) return 0;
      if (typeof string === 'string') string = util.buffer.toBuffer(string);

      if (typeof string.byteLength === 'number') {
        return string.byteLength;
      } else if (typeof string.length === 'number') {
        return string.length;
      } else if (typeof string.size === 'number') {
        return string.size;
      } else if (typeof string.path === 'string') {
        return require('fs').lstatSync(string.path).size;
      } else {
        throw util.error(new Error('Cannot determine length of ' + string),
          { object: string });
      }
    },

    upperFirst: function upperFirst(string) {
      return string[0].toUpperCase() + string.substr(1);
    },

    lowerFirst: function lowerFirst(string) {
      return string[0].toLowerCase() + string.substr(1);
    }
  },

  ini: {
    parse: function string(ini) {
      var currentSection, map = {};
      util.arrayEach(ini.split(/\r?\n/), function(line) {
        line = line.split(/(^|\s)[;#]/)[0]; // remove comments
        var section = line.match(/^\s*\[([^\[\]]+)\]\s*$/);
        if (section) {
          currentSection = section[1];
        } else if (currentSection) {
          var item = line.match(/^\s*(.+?)\s*=\s*(.+?)\s*$/);
          if (item) {
            map[currentSection] = map[currentSection] || {};
            map[currentSection][item[1]] = item[2];
          }
        }
      });

      return map;
    }
  },

  fn: {
    noop: function() {},
    callback: function (err) { if (err) throw err; },

    /**
     * Turn a synchronous function into as "async" function by making it call
     * a callback. The underlying function is called with all but the last argument,
     * which is treated as the callback. The callback is passed passed a first argument
     * of null on success to mimick standard node callbacks.
     */
    makeAsync: function makeAsync(fn, expectedArgs) {
      if (expectedArgs && expectedArgs <= fn.length) {
        return fn;
      }

      return function() {
        var args = Array.prototype.slice.call(arguments, 0);
        var callback = args.pop();
        var result = fn.apply(null, args);
        callback(result);
      };
    }
  },

  /**
   * Date and time utility functions.
   */
  date: {

    /**
     * @return [Date] the current JavaScript date object. Since all
     *   AWS services rely on this date object, you can override
     *   this function to provide a special time value to AWS service
     *   requests.
     */
    getDate: function getDate() {
      if (!AWS) AWS = require('./core');
      if (AWS.config.systemClockOffset) { // use offset when non-zero
        return new Date(new Date().getTime() + AWS.config.systemClockOffset);
      } else {
        return new Date();
      }
    },

    /**
     * @return [String] the date in ISO-8601 format
     */
    iso8601: function iso8601(date) {
      if (date === undefined) { date = util.date.getDate(); }
      return date.toISOString().replace(/\.\d{3}Z$/, 'Z');
    },

    /**
     * @return [String] the date in RFC 822 format
     */
    rfc822: function rfc822(date) {
      if (date === undefined) { date = util.date.getDate(); }
      return date.toUTCString();
    },

    /**
     * @return [Integer] the UNIX timestamp value for the current time
     */
    unixTimestamp: function unixTimestamp(date) {
      if (date === undefined) { date = util.date.getDate(); }
      return date.getTime() / 1000;
    },

    /**
     * @param [String,number,Date] date
     * @return [Date]
     */
    from: function format(date) {
      if (typeof date === 'number') {
        return new Date(date * 1000); // unix timestamp
      } else {
        return new Date(date);
      }
    },

    /**
     * Given a Date or date-like value, this function formats the
     * date into a string of the requested value.
     * @param [String,number,Date] date
     * @param [String] formatter Valid formats are:
     #   * 'iso8601'
     #   * 'rfc822'
     #   * 'unixTimestamp'
     * @return [String]
     */
    format: function format(date, formatter) {
      if (!formatter) formatter = 'iso8601';
      return util.date[formatter](util.date.from(date));
    },

    parseTimestamp: function parseTimestamp(value) {
      if (typeof value === 'number') { // unix timestamp (number)
        return new Date(value * 1000);
      } else if (value.match(/^\d+$/)) { // unix timestamp
        return new Date(value * 1000);
      } else if (value.match(/^\d{4}/)) { // iso8601
        return new Date(value);
      } else if (value.match(/^\w{3},/)) { // rfc822
        return new Date(value);
      } else {
        throw util.error(
          new Error('unhandled timestamp format: ' + value),
          {code: 'TimestampParserError'});
      }
    }

  },

  crypto: {
    crc32Table: [
     0x00000000, 0x77073096, 0xEE0E612C, 0x990951BA, 0x076DC419,
     0x706AF48F, 0xE963A535, 0x9E6495A3, 0x0EDB8832, 0x79DCB8A4,
     0xE0D5E91E, 0x97D2D988, 0x09B64C2B, 0x7EB17CBD, 0xE7B82D07,
     0x90BF1D91, 0x1DB71064, 0x6AB020F2, 0xF3B97148, 0x84BE41DE,
     0x1ADAD47D, 0x6DDDE4EB, 0xF4D4B551, 0x83D385C7, 0x136C9856,
     0x646BA8C0, 0xFD62F97A, 0x8A65C9EC, 0x14015C4F, 0x63066CD9,
     0xFA0F3D63, 0x8D080DF5, 0x3B6E20C8, 0x4C69105E, 0xD56041E4,
     0xA2677172, 0x3C03E4D1, 0x4B04D447, 0xD20D85FD, 0xA50AB56B,
     0x35B5A8FA, 0x42B2986C, 0xDBBBC9D6, 0xACBCF940, 0x32D86CE3,
     0x45DF5C75, 0xDCD60DCF, 0xABD13D59, 0x26D930AC, 0x51DE003A,
     0xC8D75180, 0xBFD06116, 0x21B4F4B5, 0x56B3C423, 0xCFBA9599,
     0xB8BDA50F, 0x2802B89E, 0x5F058808, 0xC60CD9B2, 0xB10BE924,
     0x2F6F7C87, 0x58684C11, 0xC1611DAB, 0xB6662D3D, 0x76DC4190,
     0x01DB7106, 0x98D220BC, 0xEFD5102A, 0x71B18589, 0x06B6B51F,
     0x9FBFE4A5, 0xE8B8D433, 0x7807C9A2, 0x0F00F934, 0x9609A88E,
     0xE10E9818, 0x7F6A0DBB, 0x086D3D2D, 0x91646C97, 0xE6635C01,
     0x6B6B51F4, 0x1C6C6162, 0x856530D8, 0xF262004E, 0x6C0695ED,
     0x1B01A57B, 0x8208F4C1, 0xF50FC457, 0x65B0D9C6, 0x12B7E950,
     0x8BBEB8EA, 0xFCB9887C, 0x62DD1DDF, 0x15DA2D49, 0x8CD37CF3,
     0xFBD44C65, 0x4DB26158, 0x3AB551CE, 0xA3BC0074, 0xD4BB30E2,
     0x4ADFA541, 0x3DD895D7, 0xA4D1C46D, 0xD3D6F4FB, 0x4369E96A,
     0x346ED9FC, 0xAD678846, 0xDA60B8D0, 0x44042D73, 0x33031DE5,
     0xAA0A4C5F, 0xDD0D7CC9, 0x5005713C, 0x270241AA, 0xBE0B1010,
     0xC90C2086, 0x5768B525, 0x206F85B3, 0xB966D409, 0xCE61E49F,
     0x5EDEF90E, 0x29D9C998, 0xB0D09822, 0xC7D7A8B4, 0x59B33D17,
     0x2EB40D81, 0xB7BD5C3B, 0xC0BA6CAD, 0xEDB88320, 0x9ABFB3B6,
     0x03B6E20C, 0x74B1D29A, 0xEAD54739, 0x9DD277AF, 0x04DB2615,
     0x73DC1683, 0xE3630B12, 0x94643B84, 0x0D6D6A3E, 0x7A6A5AA8,
     0xE40ECF0B, 0x9309FF9D, 0x0A00AE27, 0x7D079EB1, 0xF00F9344,
     0x8708A3D2, 0x1E01F268, 0x6906C2FE, 0xF762575D, 0x806567CB,
     0x196C3671, 0x6E6B06E7, 0xFED41B76, 0x89D32BE0, 0x10DA7A5A,
     0x67DD4ACC, 0xF9B9DF6F, 0x8EBEEFF9, 0x17B7BE43, 0x60B08ED5,
     0xD6D6A3E8, 0xA1D1937E, 0x38D8C2C4, 0x4FDFF252, 0xD1BB67F1,
     0xA6BC5767, 0x3FB506DD, 0x48B2364B, 0xD80D2BDA, 0xAF0A1B4C,
     0x36034AF6, 0x41047A60, 0xDF60EFC3, 0xA867DF55, 0x316E8EEF,
     0x4669BE79, 0xCB61B38C, 0xBC66831A, 0x256FD2A0, 0x5268E236,
     0xCC0C7795, 0xBB0B4703, 0x220216B9, 0x5505262F, 0xC5BA3BBE,
     0xB2BD0B28, 0x2BB45A92, 0x5CB36A04, 0xC2D7FFA7, 0xB5D0CF31,
     0x2CD99E8B, 0x5BDEAE1D, 0x9B64C2B0, 0xEC63F226, 0x756AA39C,
     0x026D930A, 0x9C0906A9, 0xEB0E363F, 0x72076785, 0x05005713,
     0x95BF4A82, 0xE2B87A14, 0x7BB12BAE, 0x0CB61B38, 0x92D28E9B,
     0xE5D5BE0D, 0x7CDCEFB7, 0x0BDBDF21, 0x86D3D2D4, 0xF1D4E242,
     0x68DDB3F8, 0x1FDA836E, 0x81BE16CD, 0xF6B9265B, 0x6FB077E1,
     0x18B74777, 0x88085AE6, 0xFF0F6A70, 0x66063BCA, 0x11010B5C,
     0x8F659EFF, 0xF862AE69, 0x616BFFD3, 0x166CCF45, 0xA00AE278,
     0xD70DD2EE, 0x4E048354, 0x3903B3C2, 0xA7672661, 0xD06016F7,
     0x4969474D, 0x3E6E77DB, 0xAED16A4A, 0xD9D65ADC, 0x40DF0B66,
     0x37D83BF0, 0xA9BCAE53, 0xDEBB9EC5, 0x47B2CF7F, 0x30B5FFE9,
     0xBDBDF21C, 0xCABAC28A, 0x53B39330, 0x24B4A3A6, 0xBAD03605,
     0xCDD70693, 0x54DE5729, 0x23D967BF, 0xB3667A2E, 0xC4614AB8,
     0x5D681B02, 0x2A6F2B94, 0xB40BBE37, 0xC30C8EA1, 0x5A05DF1B,
     0x2D02EF8D],

    crc32: function crc32(data) {
      var tbl = util.crypto.crc32Table;
      var crc = 0 ^ -1;

      if (typeof data === 'string') {
        data = util.buffer.toBuffer(data);
      }

      for (var i = 0; i < data.length; i++) {
        var code = data.readUInt8(i);
        crc = (crc >>> 8) ^ tbl[(crc ^ code) & 0xFF];
      }
      return (crc ^ -1) >>> 0;
    },

    hmac: function hmac(key, string, digest, fn) {
      if (!digest) digest = 'binary';
      if (digest === 'buffer') { digest = undefined; }
      if (!fn) fn = 'sha256';
      if (typeof string === 'string') string = util.buffer.toBuffer(string);
      return util.crypto.lib.createHmac(fn, key).update(string).digest(digest);
    },

    md5: function md5(data, digest, callback) {
      return util.crypto.hash('md5', data, digest, callback);
    },

    sha256: function sha256(data, digest, callback) {
      return util.crypto.hash('sha256', data, digest, callback);
    },

    hash: function(algorithm, data, digest, callback) {
      var hash = util.crypto.createHash(algorithm);
      if (!digest) { digest = 'binary'; }
      if (digest === 'buffer') { digest = undefined; }
      if (typeof data === 'string') data = util.buffer.toBuffer(data);
      var sliceFn = util.arraySliceFn(data);
      var isBuffer = util.Buffer.isBuffer(data);
      //Identifying objects with an ArrayBuffer as buffers
      if (util.isBrowser() && typeof ArrayBuffer !== 'undefined' && data && data.buffer instanceof ArrayBuffer) isBuffer = true;

      if (callback && typeof data === 'object' &&
          typeof data.on === 'function' && !isBuffer) {
        data.on('data', function(chunk) { hash.update(chunk); });
        data.on('error', function(err) { callback(err); });
        data.on('end', function() { callback(null, hash.digest(digest)); });
      } else if (callback && sliceFn && !isBuffer &&
                 typeof FileReader !== 'undefined') {
        // this might be a File/Blob
        var index = 0, size = 1024 * 512;
        var reader = new FileReader();
        reader.onerror = function() {
          callback(new Error('Failed to read data.'));
        };
        reader.onload = function() {
          var buf = new util.Buffer(new Uint8Array(reader.result));
          hash.update(buf);
          index += buf.length;
          reader._continueReading();
        };
        reader._continueReading = function() {
          if (index >= data.size) {
            callback(null, hash.digest(digest));
            return;
          }

          var back = index + size;
          if (back > data.size) back = data.size;
          reader.readAsArrayBuffer(sliceFn.call(data, index, back));
        };

        reader._continueReading();
      } else {
        if (util.isBrowser() && typeof data === 'object' && !isBuffer) {
          data = new util.Buffer(new Uint8Array(data));
        }
        var out = hash.update(data).digest(digest);
        if (callback) callback(null, out);
        return out;
      }
    },

    toHex: function toHex(data) {
      var out = [];
      for (var i = 0; i < data.length; i++) {
        out.push(('0' + data.charCodeAt(i).toString(16)).substr(-2, 2));
      }
      return out.join('');
    },

    createHash: function createHash(algorithm) {
      return util.crypto.lib.createHash(algorithm);
    }

  },

  /** @!ignore */

  /* Abort constant */
  abort: {},

  each: function each(object, iterFunction) {
    for (var key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        var ret = iterFunction.call(this, key, object[key]);
        if (ret === util.abort) break;
      }
    }
  },

  arrayEach: function arrayEach(array, iterFunction) {
    for (var idx in array) {
      if (Object.prototype.hasOwnProperty.call(array, idx)) {
        var ret = iterFunction.call(this, array[idx], parseInt(idx, 10));
        if (ret === util.abort) break;
      }
    }
  },

  update: function update(obj1, obj2) {
    util.each(obj2, function iterator(key, item) {
      obj1[key] = item;
    });
    return obj1;
  },

  merge: function merge(obj1, obj2) {
    return util.update(util.copy(obj1), obj2);
  },

  copy: function copy(object) {
    if (object === null || object === undefined) return object;
    var dupe = {};
    // jshint forin:false
    for (var key in object) {
      dupe[key] = object[key];
    }
    return dupe;
  },

  isEmpty: function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  },

  arraySliceFn: function arraySliceFn(obj) {
    var fn = obj.slice || obj.webkitSlice || obj.mozSlice;
    return typeof fn === 'function' ? fn : null;
  },

  isType: function isType(obj, type) {
    // handle cross-"frame" objects
    if (typeof type === 'function') type = util.typeName(type);
    return Object.prototype.toString.call(obj) === '[object ' + type + ']';
  },

  typeName: function typeName(type) {
    if (Object.prototype.hasOwnProperty.call(type, 'name')) return type.name;
    var str = type.toString();
    var match = str.match(/^\s*function (.+)\(/);
    return match ? match[1] : str;
  },

  error: function error(err, options) {
    var originalError = null;
    if (typeof err.message === 'string' && err.message !== '') {
      if (typeof options === 'string' || (options && options.message)) {
        originalError = util.copy(err);
        originalError.message = err.message;
      }
    }
    err.message = err.message || null;

    if (typeof options === 'string') {
      err.message = options;
    } else if (typeof options === 'object' && options !== null) {
      util.update(err, options);
      if (options.message)
        err.message = options.message;
      if (options.code || options.name)
        err.code = options.code || options.name;
      if (options.stack)
        err.stack = options.stack;
    }

    if (typeof Object.defineProperty === 'function') {
      Object.defineProperty(err, 'name', {writable: true, enumerable: false});
      Object.defineProperty(err, 'message', {enumerable: true});
    }

    err.name = String(options && options.name || err.name || err.code || 'Error');
    err.time = new Date();

    if (originalError) err.originalError = originalError;

    return err;
  },

  /**
   * @api private
   */
  inherit: function inherit(klass, features) {
    var newObject = null;
    if (features === undefined) {
      features = klass;
      klass = Object;
      newObject = {};
    } else {
      var ctor = function ConstructorWrapper() {};
      ctor.prototype = klass.prototype;
      newObject = new ctor();
    }

    // constructor not supplied, create pass-through ctor
    if (features.constructor === Object) {
      features.constructor = function() {
        if (klass !== Object) {
          return klass.apply(this, arguments);
        }
      };
    }

    features.constructor.prototype = newObject;
    util.update(features.constructor.prototype, features);
    features.constructor.__super__ = klass;
    return features.constructor;
  },

  /**
   * @api private
   */
  mixin: function mixin() {
    var klass = arguments[0];
    for (var i = 1; i < arguments.length; i++) {
      // jshint forin:false
      for (var prop in arguments[i].prototype) {
        var fn = arguments[i].prototype[prop];
        if (prop !== 'constructor') {
          klass.prototype[prop] = fn;
        }
      }
    }
    return klass;
  },

  /**
   * @api private
   */
  hideProperties: function hideProperties(obj, props) {
    if (typeof Object.defineProperty !== 'function') return;

    util.arrayEach(props, function (key) {
      Object.defineProperty(obj, key, {
        enumerable: false, writable: true, configurable: true });
    });
  },

  /**
   * @api private
   */
  property: function property(obj, name, value, enumerable, isValue) {
    var opts = {
      configurable: true,
      enumerable: enumerable !== undefined ? enumerable : true
    };
    if (typeof value === 'function' && !isValue) {
      opts.get = value;
    }
    else {
      opts.value = value; opts.writable = true;
    }

    Object.defineProperty(obj, name, opts);
  },

  /**
   * @api private
   */
  memoizedProperty: function memoizedProperty(obj, name, get, enumerable) {
    var cachedValue = null;

    // build enumerable attribute for each value with lazy accessor.
    util.property(obj, name, function() {
      if (cachedValue === null) {
        cachedValue = get();
      }
      return cachedValue;
    }, enumerable);
  },

  /**
   * TODO Remove in major version revision
   * This backfill populates response data without the
   * top-level payload name.
   *
   * @api private
   */
  hoistPayloadMember: function hoistPayloadMember(resp) {
    var req = resp.request;
    var operationName = req.operation;
    var operation = req.service.api.operations[operationName];
    var output = operation.output;
    if (output.payload && !operation.hasEventOutput) {
      var payloadMember = output.members[output.payload];
      var responsePayload = resp.data[output.payload];
      if (payloadMember.type === 'structure') {
        util.each(responsePayload, function(key, value) {
          util.property(resp.data, key, value, false);
        });
      }
    }
  },

  /**
   * Compute SHA-256 checksums of streams
   *
   * @api private
   */
  computeSha256: function computeSha256(body, done) {
    if (util.isNode()) {
      var Stream = util.stream.Stream;
      var fs = require('fs');
      if (typeof Stream === 'function' && body instanceof Stream) {
        if (typeof body.path === 'string') { // assume file object
          var settings = {};
          if (typeof body.start === 'number') {
            settings.start = body.start;
          }
          if (typeof body.end === 'number') {
            settings.end = body.end;
          }
          body = fs.createReadStream(body.path, settings);
        } else { // TODO support other stream types
          return done(new Error('Non-file stream objects are ' +
                                'not supported with SigV4'));
        }
      }
    }

    util.crypto.sha256(body, 'hex', function(err, sha) {
      if (err) done(err);
      else done(null, sha);
    });
  },

  /**
   * @api private
   */
  isClockSkewed: function isClockSkewed(serverTime) {
    if (serverTime) {
      util.property(AWS.config, 'isClockSkewed',
        Math.abs(new Date().getTime() - serverTime) >= 300000, false);
      return AWS.config.isClockSkewed;
    }
  },

  applyClockOffset: function applyClockOffset(serverTime) {
    if (serverTime)
      AWS.config.systemClockOffset = serverTime - new Date().getTime();
  },

  /**
   * @api private
   */
  extractRequestId: function extractRequestId(resp) {
    var requestId = resp.httpResponse.headers['x-amz-request-id'] ||
                     resp.httpResponse.headers['x-amzn-requestid'];

    if (!requestId && resp.data && resp.data.ResponseMetadata) {
      requestId = resp.data.ResponseMetadata.RequestId;
    }

    if (requestId) {
      resp.requestId = requestId;
    }

    if (resp.error) {
      resp.error.requestId = requestId;
    }
  },

  /**
   * @api private
   */
  addPromises: function addPromises(constructors, PromiseDependency) {
    var deletePromises = false;
    if (PromiseDependency === undefined && AWS && AWS.config) {
      PromiseDependency = AWS.config.getPromisesDependency();
    }
    if (PromiseDependency === undefined && typeof Promise !== 'undefined') {
      PromiseDependency = Promise;
    }
    if (typeof PromiseDependency !== 'function') deletePromises = true;
    if (!Array.isArray(constructors)) constructors = [constructors];

    for (var ind = 0; ind < constructors.length; ind++) {
      var constructor = constructors[ind];
      if (deletePromises) {
        if (constructor.deletePromisesFromClass) {
          constructor.deletePromisesFromClass();
        }
      } else if (constructor.addPromisesToClass) {
        constructor.addPromisesToClass(PromiseDependency);
      }
    }
  },

  /**
   * @api private
   * Return a function that will return a promise whose fate is decided by the
   * callback behavior of the given method with `methodName`. The method to be
   * promisified should conform to node.js convention of accepting a callback as
   * last argument and calling that callback with error as the first argument
   * and success value on the second argument.
   */
  promisifyMethod: function promisifyMethod(methodName, PromiseDependency) {
    return function promise() {
      var self = this;
      var args = Array.prototype.slice.call(arguments);
      return new PromiseDependency(function(resolve, reject) {
        args.push(function(err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
        self[methodName].apply(self, args);
      });
    };
  },

  /**
   * @api private
   */
  isDualstackAvailable: function isDualstackAvailable(service) {
    if (!service) return false;
    var metadata = require('../apis/metadata.json');
    if (typeof service !== 'string') service = service.serviceIdentifier;
    if (typeof service !== 'string' || !metadata.hasOwnProperty(service)) return false;
    return !!metadata[service].dualstackAvailable;
  },

  /**
   * @api private
   */
  calculateRetryDelay: function calculateRetryDelay(retryCount, retryDelayOptions, err) {
    if (!retryDelayOptions) retryDelayOptions = {};
    var customBackoff = retryDelayOptions.customBackoff || null;
    if (typeof customBackoff === 'function') {
      return customBackoff(retryCount, err);
    }
    var base = typeof retryDelayOptions.base === 'number' ? retryDelayOptions.base : 100;
    var delay = Math.random() * (Math.pow(2, retryCount) * base);
    return delay;
  },

  /**
   * @api private
   */
  handleRequestWithRetries: function handleRequestWithRetries(httpRequest, options, cb) {
    if (!options) options = {};
    var http = AWS.HttpClient.getInstance();
    var httpOptions = options.httpOptions || {};
    var retryCount = 0;

    var errCallback = function(err) {
      var maxRetries = options.maxRetries || 0;
      if (err && err.code === 'TimeoutError') err.retryable = true;
      var delay = util.calculateRetryDelay(retryCount, options.retryDelayOptions, err);
      if (err && err.retryable && retryCount < maxRetries && delay >= 0) {
        retryCount++;
        setTimeout(sendRequest, delay + (err.retryAfter || 0));
      } else {
        cb(err);
      }
    };

    var sendRequest = function() {
      var data = '';
      http.handleRequest(httpRequest, httpOptions, function(httpResponse) {
        httpResponse.on('data', function(chunk) { data += chunk.toString(); });
        httpResponse.on('end', function() {
          var statusCode = httpResponse.statusCode;
          if (statusCode < 300) {
            cb(null, data);
          } else {
            var retryAfter = parseInt(httpResponse.headers['retry-after'], 10) * 1000 || 0;
            var err = util.error(new Error(),
              {
                statusCode: statusCode,
                retryable: statusCode >= 500 || statusCode === 429
              }
            );
            if (retryAfter && err.retryable) err.retryAfter = retryAfter;
            errCallback(err);
          }
        });
      }, errCallback);
    };

    AWS.util.defer(sendRequest);
  },

  /**
   * @api private
   */
  uuid: {
    v4: function uuidV4() {
      return require('uuid').v4();
    }
  },

  /**
   * @api private
   */
  convertPayloadToString: function convertPayloadToString(resp) {
    var req = resp.request;
    var operation = req.operation;
    var rules = req.service.api.operations[operation].output || {};
    if (rules.payload && resp.data[rules.payload]) {
      resp.data[rules.payload] = resp.data[rules.payload].toString();
    }
  },

  /**
   * @api private
   */
  defer: function defer(callback) {
    if (typeof process === 'object' && typeof process.nextTick === 'function') {
      process.nextTick(callback);
    } else if (typeof setImmediate === 'function') {
      setImmediate(callback);
    } else {
      setTimeout(callback, 0);
    }
  },

  /**
   * @api private
   */
  getRequestPayloadShape: function getRequestPayloadShape(req) {
    var operations = req.service.api.operations;
    if (!operations) return undefined;
    var operation = (operations || {})[req.operation];
    if (!operation || !operation.input || !operation.input.payload) return undefined;
    return operation.input.members[operation.input.payload];
  },

  getProfilesFromSharedConfig: function getProfilesFromSharedConfig(iniLoader, filename) {
    var profiles = {};
    var profilesFromConfig = {};
    if (process.env[util.configOptInEnv]) {
      var profilesFromConfig = iniLoader.loadFrom({
        isConfig: true,
        filename: process.env[util.sharedConfigFileEnv]
      });
    }
    var profilesFromCreds = iniLoader.loadFrom({
      filename: filename ||
        (process.env[util.configOptInEnv] && process.env[util.sharedCredentialsFileEnv])
    });
    for (var i = 0, profileNames = Object.keys(profilesFromConfig); i < profileNames.length; i++) {
      profiles[profileNames[i]] = profilesFromConfig[profileNames[i]];
    }
    for (var i = 0, profileNames = Object.keys(profilesFromCreds); i < profileNames.length; i++) {
      profiles[profileNames[i]] = profilesFromCreds[profileNames[i]];
    }
    return profiles;
  },

  /**
   * @api private
   */
  ARN: {
    validate: function validateARN(str) {
      return str && str.indexOf('arn:') === 0 && str.split(':').length >= 6;
    },
    parse: function parseARN(arn) {
      var matched = arn.split(':');
      return {
        partition: matched[1],
        service: matched[2],
        region: matched[3],
        accountId: matched[4],
        resource: matched.slice(5).join(':')
      };
    },
    build: function buildARN(arnObject) {
      if (
        arnObject.service === undefined ||
        arnObject.region === undefined ||
        arnObject.accountId === undefined ||
        arnObject.resource === undefined
      ) throw util.error(new Error('Input ARN object is invalid'));
      return 'arn:'+ (arnObject.partition || 'aws') + ':' + arnObject.service +
        ':' + arnObject.region + ':' + arnObject.accountId + ':' + arnObject.resource;
    }
  },

  /**
   * @api private
   */
  defaultProfile: 'default',

  /**
   * @api private
   */
  configOptInEnv: 'AWS_SDK_LOAD_CONFIG',

  /**
   * @api private
   */
  sharedCredentialsFileEnv: 'AWS_SHARED_CREDENTIALS_FILE',

  /**
   * @api private
   */
  sharedConfigFileEnv: 'AWS_CONFIG_FILE',

  /**
   * @api private
   */
  imdsDisabledEnv: 'AWS_EC2_METADATA_DISABLED'
};

/**
 * @api private
 */
module.exports = util;

}).call(this,require('_process'),require("timers").setImmediate)
},{"../apis/metadata.json":152,"./core":324,"_process":412,"fs":406,"timers":420,"uuid":425}],399:[function(require,module,exports){
var util = require('../util');
var Shape = require('../model/shape');

function DomXmlParser() { }

DomXmlParser.prototype.parse = function(xml, shape) {
  if (xml.replace(/^\s+/, '') === '') return {};

  var result, error;
  try {
    if (window.DOMParser) {
      try {
        var parser = new DOMParser();
        result = parser.parseFromString(xml, 'text/xml');
      } catch (syntaxError) {
        throw util.error(new Error('Parse error in document'),
          {
            originalError: syntaxError,
            code: 'XMLParserError',
            retryable: true
          });
      }

      if (result.documentElement === null) {
        throw util.error(new Error('Cannot parse empty document.'),
          {
            code: 'XMLParserError',
            retryable: true
          });
      }

      var isError = result.getElementsByTagName('parsererror')[0];
      if (isError && (isError.parentNode === result ||
          isError.parentNode.nodeName === 'body' ||
          isError.parentNode.parentNode === result ||
          isError.parentNode.parentNode.nodeName === 'body')) {
        var errorElement = isError.getElementsByTagName('div')[0] || isError;
        throw util.error(new Error(errorElement.textContent || 'Parser error in document'),
          {
            code: 'XMLParserError',
            retryable: true
          });
      }
    } else if (window.ActiveXObject) {
      result = new window.ActiveXObject('Microsoft.XMLDOM');
      result.async = false;

      if (!result.loadXML(xml)) {
        throw util.error(new Error('Parse error in document'),
          {
            code: 'XMLParserError',
            retryable: true
          });
      }
    } else {
      throw new Error('Cannot load XML parser');
    }
  } catch (e) {
    error = e;
  }

  if (result && result.documentElement && !error) {
    var data = parseXml(result.documentElement, shape);
    var metadata = getElementByTagName(result.documentElement, 'ResponseMetadata');
    if (metadata) {
      data.ResponseMetadata = parseXml(metadata, {});
    }
    return data;
  } else if (error) {
    throw util.error(error || new Error(), {code: 'XMLParserError', retryable: true});
  } else { // empty xml document
    return {};
  }
};

function getElementByTagName(xml, tag) {
  var elements = xml.getElementsByTagName(tag);
  for (var i = 0, iLen = elements.length; i < iLen; i++) {
    if (elements[i].parentNode === xml) {
      return elements[i];
    }
  }
}

function parseXml(xml, shape) {
  if (!shape) shape = {};
  switch (shape.type) {
    case 'structure': return parseStructure(xml, shape);
    case 'map': return parseMap(xml, shape);
    case 'list': return parseList(xml, shape);
    case undefined: case null: return parseUnknown(xml);
    default: return parseScalar(xml, shape);
  }
}

function parseStructure(xml, shape) {
  var data = {};
  if (xml === null) return data;

  util.each(shape.members, function(memberName, memberShape) {
    if (memberShape.isXmlAttribute) {
      if (Object.prototype.hasOwnProperty.call(xml.attributes, memberShape.name)) {
        var value = xml.attributes[memberShape.name].value;
        data[memberName] = parseXml({textContent: value}, memberShape);
      }
    } else {
      var xmlChild = memberShape.flattened ? xml :
        getElementByTagName(xml, memberShape.name);
      if (xmlChild) {
        data[memberName] = parseXml(xmlChild, memberShape);
      } else if (
        !memberShape.flattened &&
        memberShape.type === 'list' &&
        !shape.api.xmlNoDefaultLists) {
        data[memberName] = memberShape.defaultValue;
      }
    }
  });

  return data;
}

function parseMap(xml, shape) {
  var data = {};
  var xmlKey = shape.key.name || 'key';
  var xmlValue = shape.value.name || 'value';
  var tagName = shape.flattened ? shape.name : 'entry';

  var child = xml.firstElementChild;
  while (child) {
    if (child.nodeName === tagName) {
      var key = getElementByTagName(child, xmlKey).textContent;
      var value = getElementByTagName(child, xmlValue);
      data[key] = parseXml(value, shape.value);
    }
    child = child.nextElementSibling;
  }
  return data;
}

function parseList(xml, shape) {
  var data = [];
  var tagName = shape.flattened ? shape.name : (shape.member.name || 'member');

  var child = xml.firstElementChild;
  while (child) {
    if (child.nodeName === tagName) {
      data.push(parseXml(child, shape.member));
    }
    child = child.nextElementSibling;
  }
  return data;
}

function parseScalar(xml, shape) {
  if (xml.getAttribute) {
    var encoding = xml.getAttribute('encoding');
    if (encoding === 'base64') {
      shape = new Shape.create({type: encoding});
    }
  }

  var text = xml.textContent;
  if (text === '') text = null;
  if (typeof shape.toType === 'function') {
    return shape.toType(text);
  } else {
    return text;
  }
}

function parseUnknown(xml) {
  if (xml === undefined || xml === null) return '';

  // empty object
  if (!xml.firstElementChild) {
    if (xml.parentNode.parentNode === null) return {};
    if (xml.childNodes.length === 0) return '';
    else return xml.textContent;
  }

  // object, parse as structure
  var shape = {type: 'structure', members: {}};
  var child = xml.firstElementChild;
  while (child) {
    var tag = child.nodeName;
    if (Object.prototype.hasOwnProperty.call(shape.members, tag)) {
      // multiple tags of the same name makes it a list
      shape.members[tag].type = 'list';
    } else {
      shape.members[tag] = {name: tag};
    }
    child = child.nextElementSibling;
  }
  return parseStructure(xml, shape);
}

/**
 * @api private
 */
module.exports = DomXmlParser;

},{"../model/shape":355,"../util":398}],400:[function(require,module,exports){
var util = require('../util');
var XmlNode = require('./xml-node').XmlNode;
var XmlText = require('./xml-text').XmlText;

function XmlBuilder() { }

XmlBuilder.prototype.toXML = function(params, shape, rootElement, noEmpty) {
  var xml = new XmlNode(rootElement);
  applyNamespaces(xml, shape, true);
  serialize(xml, params, shape);
  return xml.children.length > 0 || noEmpty ? xml.toString() : '';
};

function serialize(xml, value, shape) {
  switch (shape.type) {
    case 'structure': return serializeStructure(xml, value, shape);
    case 'map': return serializeMap(xml, value, shape);
    case 'list': return serializeList(xml, value, shape);
    default: return serializeScalar(xml, value, shape);
  }
}

function serializeStructure(xml, params, shape) {
  util.arrayEach(shape.memberNames, function(memberName) {
    var memberShape = shape.members[memberName];
    if (memberShape.location !== 'body') return;

    var value = params[memberName];
    var name = memberShape.name;
    if (value !== undefined && value !== null) {
      if (memberShape.isXmlAttribute) {
        xml.addAttribute(name, value);
      } else if (memberShape.flattened) {
        serialize(xml, value, memberShape);
      } else {
        var element = new XmlNode(name);
        xml.addChildNode(element);
        applyNamespaces(element, memberShape);
        serialize(element, value, memberShape);
      }
    }
  });
}

function serializeMap(xml, map, shape) {
  var xmlKey = shape.key.name || 'key';
  var xmlValue = shape.value.name || 'value';

  util.each(map, function(key, value) {
    var entry = new XmlNode(shape.flattened ? shape.name : 'entry');
    xml.addChildNode(entry);

    var entryKey = new XmlNode(xmlKey);
    var entryValue = new XmlNode(xmlValue);
    entry.addChildNode(entryKey);
    entry.addChildNode(entryValue);

    serialize(entryKey, key, shape.key);
    serialize(entryValue, value, shape.value);
  });
}

function serializeList(xml, list, shape) {
  if (shape.flattened) {
    util.arrayEach(list, function(value) {
      var name = shape.member.name || shape.name;
      var element = new XmlNode(name);
      xml.addChildNode(element);
      serialize(element, value, shape.member);
    });
  } else {
    util.arrayEach(list, function(value) {
      var name = shape.member.name || 'member';
      var element = new XmlNode(name);
      xml.addChildNode(element);
      serialize(element, value, shape.member);
    });
  }
}

function serializeScalar(xml, value, shape) {
  xml.addChildNode(
    new XmlText(shape.toWireFormat(value))
  );
}

function applyNamespaces(xml, shape, isRoot) {
  var uri, prefix = 'xmlns';
  if (shape.xmlNamespaceUri) {
    uri = shape.xmlNamespaceUri;
    if (shape.xmlNamespacePrefix) prefix += ':' + shape.xmlNamespacePrefix;
  } else if (isRoot && shape.api.xmlNamespaceUri) {
    uri = shape.api.xmlNamespaceUri;
  }

  if (uri) xml.addAttribute(prefix, uri);
}

/**
 * @api private
 */
module.exports = XmlBuilder;

},{"../util":398,"./xml-node":403,"./xml-text":404}],401:[function(require,module,exports){
/**
 * Escapes characters that can not be in an XML attribute.
 */
function escapeAttribute(value) {
    return value.replace(/&/g, '&amp;').replace(/'/g, '&apos;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

/**
 * @api private
 */
module.exports = {
    escapeAttribute: escapeAttribute
};

},{}],402:[function(require,module,exports){
/**
 * Escapes characters that can not be in an XML element.
 */
function escapeElement(value) {
    return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

/**
 * @api private
 */
module.exports = {
    escapeElement: escapeElement
};

},{}],403:[function(require,module,exports){
var escapeAttribute = require('./escape-attribute').escapeAttribute;

/**
 * Represents an XML node.
 * @api private
 */
function XmlNode(name, children) {
    if (children === void 0) { children = []; }
    this.name = name;
    this.children = children;
    this.attributes = {};
}
XmlNode.prototype.addAttribute = function (name, value) {
    this.attributes[name] = value;
    return this;
};
XmlNode.prototype.addChildNode = function (child) {
    this.children.push(child);
    return this;
};
XmlNode.prototype.removeAttribute = function (name) {
    delete this.attributes[name];
    return this;
};
XmlNode.prototype.toString = function () {
    var hasChildren = Boolean(this.children.length);
    var xmlText = '<' + this.name;
    // add attributes
    var attributes = this.attributes;
    for (var i = 0, attributeNames = Object.keys(attributes); i < attributeNames.length; i++) {
        var attributeName = attributeNames[i];
        var attribute = attributes[attributeName];
        if (typeof attribute !== 'undefined' && attribute !== null) {
            xmlText += ' ' + attributeName + '=\"' + escapeAttribute('' + attribute) + '\"';
        }
    }
    return xmlText += !hasChildren ? '/>' : '>' + this.children.map(function (c) { return c.toString(); }).join('') + '</' + this.name + '>';
};

/**
 * @api private
 */
module.exports = {
    XmlNode: XmlNode
};

},{"./escape-attribute":401}],404:[function(require,module,exports){
var escapeElement = require('./escape-element').escapeElement;

/**
 * Represents an XML text value.
 * @api private
 */
function XmlText(value) {
    this.value = value;
}

XmlText.prototype.toString = function () {
    return escapeElement('' + this.value);
};

/**
 * @api private
 */
module.exports = {
    XmlText: XmlText
};

},{"./escape-element":402}],405:[function(require,module,exports){
'use strict'

exports.byteLength = byteLength
exports.toByteArray = toByteArray
exports.fromByteArray = fromByteArray

var lookup = []
var revLookup = []
var Arr = typeof Uint8Array !== 'undefined' ? Uint8Array : Array

var code = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
for (var i = 0, len = code.length; i < len; ++i) {
  lookup[i] = code[i]
  revLookup[code.charCodeAt(i)] = i
}

// Support decoding URL-safe base64 strings, as Node.js does.
// See: https://en.wikipedia.org/wiki/Base64#URL_applications
revLookup['-'.charCodeAt(0)] = 62
revLookup['_'.charCodeAt(0)] = 63

function getLens (b64) {
  var len = b64.length

  if (len % 4 > 0) {
    throw new Error('Invalid string. Length must be a multiple of 4')
  }

  // Trim off extra bytes after placeholder bytes are found
  // See: https://github.com/beatgammit/base64-js/issues/42
  var validLen = b64.indexOf('=')
  if (validLen === -1) validLen = len

  var placeHoldersLen = validLen === len
    ? 0
    : 4 - (validLen % 4)

  return [validLen, placeHoldersLen]
}

// base64 is 4/3 + up to two characters of the original data
function byteLength (b64) {
  var lens = getLens(b64)
  var validLen = lens[0]
  var placeHoldersLen = lens[1]
  return ((validLen + placeHoldersLen) * 3 / 4) - placeHoldersLen
}

function _byteLength (b64, validLen, placeHoldersLen) {
  return ((validLen + placeHoldersLen) * 3 / 4) - placeHoldersLen
}

function toByteArray (b64) {
  var tmp
  var lens = getLens(b64)
  var validLen = lens[0]
  var placeHoldersLen = lens[1]

  var arr = new Arr(_byteLength(b64, validLen, placeHoldersLen))

  var curByte = 0

  // if there are placeholders, only get up to the last complete 4 chars
  var len = placeHoldersLen > 0
    ? validLen - 4
    : validLen

  var i
  for (i = 0; i < len; i += 4) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 18) |
      (revLookup[b64.charCodeAt(i + 1)] << 12) |
      (revLookup[b64.charCodeAt(i + 2)] << 6) |
      revLookup[b64.charCodeAt(i + 3)]
    arr[curByte++] = (tmp >> 16) & 0xFF
    arr[curByte++] = (tmp >> 8) & 0xFF
    arr[curByte++] = tmp & 0xFF
  }

  if (placeHoldersLen === 2) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 2) |
      (revLookup[b64.charCodeAt(i + 1)] >> 4)
    arr[curByte++] = tmp & 0xFF
  }

  if (placeHoldersLen === 1) {
    tmp =
      (revLookup[b64.charCodeAt(i)] << 10) |
      (revLookup[b64.charCodeAt(i + 1)] << 4) |
      (revLookup[b64.charCodeAt(i + 2)] >> 2)
    arr[curByte++] = (tmp >> 8) & 0xFF
    arr[curByte++] = tmp & 0xFF
  }

  return arr
}

function tripletToBase64 (num) {
  return lookup[num >> 18 & 0x3F] +
    lookup[num >> 12 & 0x3F] +
    lookup[num >> 6 & 0x3F] +
    lookup[num & 0x3F]
}

function encodeChunk (uint8, start, end) {
  var tmp
  var output = []
  for (var i = start; i < end; i += 3) {
    tmp =
      ((uint8[i] << 16) & 0xFF0000) +
      ((uint8[i + 1] << 8) & 0xFF00) +
      (uint8[i + 2] & 0xFF)
    output.push(tripletToBase64(tmp))
  }
  return output.join('')
}

function fromByteArray (uint8) {
  var tmp
  var len = uint8.length
  var extraBytes = len % 3 // if we have 1 byte left, pad 2 bytes
  var parts = []
  var maxChunkLength = 16383 // must be multiple of 3

  // go through the array every three bytes, we'll deal with trailing stuff later
  for (var i = 0, len2 = len - extraBytes; i < len2; i += maxChunkLength) {
    parts.push(encodeChunk(
      uint8, i, (i + maxChunkLength) > len2 ? len2 : (i + maxChunkLength)
    ))
  }

  // pad the end with zeros, but make sure to not forget the extra bytes
  if (extraBytes === 1) {
    tmp = uint8[len - 1]
    parts.push(
      lookup[tmp >> 2] +
      lookup[(tmp << 4) & 0x3F] +
      '=='
    )
  } else if (extraBytes === 2) {
    tmp = (uint8[len - 2] << 8) + uint8[len - 1]
    parts.push(
      lookup[tmp >> 10] +
      lookup[(tmp >> 4) & 0x3F] +
      lookup[(tmp << 2) & 0x3F] +
      '='
    )
  }

  return parts.join('')
}

},{}],406:[function(require,module,exports){

},{}],407:[function(require,module,exports){
(function (global,Buffer){
/*!
 * The buffer module from node.js, for the browser.
 *
 * @author   Feross Aboukhadijeh <http://feross.org>
 * @license  MIT
 */
/* eslint-disable no-proto */

'use strict'

var base64 = require('base64-js')
var ieee754 = require('ieee754')
var isArray = require('isarray')

exports.Buffer = Buffer
exports.SlowBuffer = SlowBuffer
exports.INSPECT_MAX_BYTES = 50

/**
 * If `Buffer.TYPED_ARRAY_SUPPORT`:
 *   === true    Use Uint8Array implementation (fastest)
 *   === false   Use Object implementation (most compatible, even IE6)
 *
 * Browsers that support typed arrays are IE 10+, Firefox 4+, Chrome 7+, Safari 5.1+,
 * Opera 11.6+, iOS 4.2+.
 *
 * Due to various browser bugs, sometimes the Object implementation will be used even
 * when the browser supports typed arrays.
 *
 * Note:
 *
 *   - Firefox 4-29 lacks support for adding new properties to `Uint8Array` instances,
 *     See: https://bugzilla.mozilla.org/show_bug.cgi?id=695438.
 *
 *   - Chrome 9-10 is missing the `TypedArray.prototype.subarray` function.
 *
 *   - IE10 has a broken `TypedArray.prototype.subarray` function which returns arrays of
 *     incorrect length in some situations.

 * We detect these buggy browsers and set `Buffer.TYPED_ARRAY_SUPPORT` to `false` so they
 * get the Object implementation, which is slower but behaves correctly.
 */
Buffer.TYPED_ARRAY_SUPPORT = global.TYPED_ARRAY_SUPPORT !== undefined
  ? global.TYPED_ARRAY_SUPPORT
  : typedArraySupport()

/*
 * Export kMaxLength after typed array support is determined.
 */
exports.kMaxLength = kMaxLength()

function typedArraySupport () {
  try {
    var arr = new Uint8Array(1)
    arr.__proto__ = {__proto__: Uint8Array.prototype, foo: function () { return 42 }}
    return arr.foo() === 42 && // typed array instances can be augmented
        typeof arr.subarray === 'function' && // chrome 9-10 lack `subarray`
        arr.subarray(1, 1).byteLength === 0 // ie10 has broken `subarray`
  } catch (e) {
    return false
  }
}

function kMaxLength () {
  return Buffer.TYPED_ARRAY_SUPPORT
    ? 0x7fffffff
    : 0x3fffffff
}

function createBuffer (that, length) {
  if (kMaxLength() < length) {
    throw new RangeError('Invalid typed array length')
  }
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    // Return an augmented `Uint8Array` instance, for best performance
    that = new Uint8Array(length)
    that.__proto__ = Buffer.prototype
  } else {
    // Fallback: Return an object instance of the Buffer class
    if (that === null) {
      that = new Buffer(length)
    }
    that.length = length
  }

  return that
}

/**
 * The Buffer constructor returns instances of `Uint8Array` that have their
 * prototype changed to `Buffer.prototype`. Furthermore, `Buffer` is a subclass of
 * `Uint8Array`, so the returned instances will have all the node `Buffer` methods
 * and the `Uint8Array` methods. Square bracket notation works as expected -- it
 * returns a single octet.
 *
 * The `Uint8Array` prototype remains unmodified.
 */

function Buffer (arg, encodingOrOffset, length) {
  if (!Buffer.TYPED_ARRAY_SUPPORT && !(this instanceof Buffer)) {
    return new Buffer(arg, encodingOrOffset, length)
  }

  // Common case.
  if (typeof arg === 'number') {
    if (typeof encodingOrOffset === 'string') {
      throw new Error(
        'If encoding is specified then the first argument must be a string'
      )
    }
    return allocUnsafe(this, arg)
  }
  return from(this, arg, encodingOrOffset, length)
}

Buffer.poolSize = 8192 // not used by this implementation

// TODO: Legacy, not needed anymore. Remove in next major version.
Buffer._augment = function (arr) {
  arr.__proto__ = Buffer.prototype
  return arr
}

function from (that, value, encodingOrOffset, length) {
  if (typeof value === 'number') {
    throw new TypeError('"value" argument must not be a number')
  }

  if (typeof ArrayBuffer !== 'undefined' && value instanceof ArrayBuffer) {
    return fromArrayBuffer(that, value, encodingOrOffset, length)
  }

  if (typeof value === 'string') {
    return fromString(that, value, encodingOrOffset)
  }

  return fromObject(that, value)
}

/**
 * Functionally equivalent to Buffer(arg, encoding) but throws a TypeError
 * if value is a number.
 * Buffer.from(str[, encoding])
 * Buffer.from(array)
 * Buffer.from(buffer)
 * Buffer.from(arrayBuffer[, byteOffset[, length]])
 **/
Buffer.from = function (value, encodingOrOffset, length) {
  return from(null, value, encodingOrOffset, length)
}

if (Buffer.TYPED_ARRAY_SUPPORT) {
  Buffer.prototype.__proto__ = Uint8Array.prototype
  Buffer.__proto__ = Uint8Array
  if (typeof Symbol !== 'undefined' && Symbol.species &&
      Buffer[Symbol.species] === Buffer) {
    // Fix subarray() in ES2016. See: https://github.com/feross/buffer/pull/97
    Object.defineProperty(Buffer, Symbol.species, {
      value: null,
      configurable: true
    })
  }
}

function assertSize (size) {
  if (typeof size !== 'number') {
    throw new TypeError('"size" argument must be a number')
  } else if (size < 0) {
    throw new RangeError('"size" argument must not be negative')
  }
}

function alloc (that, size, fill, encoding) {
  assertSize(size)
  if (size <= 0) {
    return createBuffer(that, size)
  }
  if (fill !== undefined) {
    // Only pay attention to encoding if it's a string. This
    // prevents accidentally sending in a number that would
    // be interpretted as a start offset.
    return typeof encoding === 'string'
      ? createBuffer(that, size).fill(fill, encoding)
      : createBuffer(that, size).fill(fill)
  }
  return createBuffer(that, size)
}

/**
 * Creates a new filled Buffer instance.
 * alloc(size[, fill[, encoding]])
 **/
Buffer.alloc = function (size, fill, encoding) {
  return alloc(null, size, fill, encoding)
}

function allocUnsafe (that, size) {
  assertSize(size)
  that = createBuffer(that, size < 0 ? 0 : checked(size) | 0)
  if (!Buffer.TYPED_ARRAY_SUPPORT) {
    for (var i = 0; i < size; ++i) {
      that[i] = 0
    }
  }
  return that
}

/**
 * Equivalent to Buffer(num), by default creates a non-zero-filled Buffer instance.
 * */
Buffer.allocUnsafe = function (size) {
  return allocUnsafe(null, size)
}
/**
 * Equivalent to SlowBuffer(num), by default creates a non-zero-filled Buffer instance.
 */
Buffer.allocUnsafeSlow = function (size) {
  return allocUnsafe(null, size)
}

function fromString (that, string, encoding) {
  if (typeof encoding !== 'string' || encoding === '') {
    encoding = 'utf8'
  }

  if (!Buffer.isEncoding(encoding)) {
    throw new TypeError('"encoding" must be a valid string encoding')
  }

  var length = byteLength(string, encoding) | 0
  that = createBuffer(that, length)

  var actual = that.write(string, encoding)

  if (actual !== length) {
    // Writing a hex string, for example, that contains invalid characters will
    // cause everything after the first invalid character to be ignored. (e.g.
    // 'abxxcd' will be treated as 'ab')
    that = that.slice(0, actual)
  }

  return that
}

function fromArrayLike (that, array) {
  var length = array.length < 0 ? 0 : checked(array.length) | 0
  that = createBuffer(that, length)
  for (var i = 0; i < length; i += 1) {
    that[i] = array[i] & 255
  }
  return that
}

function fromArrayBuffer (that, array, byteOffset, length) {
  array.byteLength // this throws if `array` is not a valid ArrayBuffer

  if (byteOffset < 0 || array.byteLength < byteOffset) {
    throw new RangeError('\'offset\' is out of bounds')
  }

  if (array.byteLength < byteOffset + (length || 0)) {
    throw new RangeError('\'length\' is out of bounds')
  }

  if (byteOffset === undefined && length === undefined) {
    array = new Uint8Array(array)
  } else if (length === undefined) {
    array = new Uint8Array(array, byteOffset)
  } else {
    array = new Uint8Array(array, byteOffset, length)
  }

  if (Buffer.TYPED_ARRAY_SUPPORT) {
    // Return an augmented `Uint8Array` instance, for best performance
    that = array
    that.__proto__ = Buffer.prototype
  } else {
    // Fallback: Return an object instance of the Buffer class
    that = fromArrayLike(that, array)
  }
  return that
}

function fromObject (that, obj) {
  if (Buffer.isBuffer(obj)) {
    var len = checked(obj.length) | 0
    that = createBuffer(that, len)

    if (that.length === 0) {
      return that
    }

    obj.copy(that, 0, 0, len)
    return that
  }

  if (obj) {
    if ((typeof ArrayBuffer !== 'undefined' &&
        obj.buffer instanceof ArrayBuffer) || 'length' in obj) {
      if (typeof obj.length !== 'number' || isnan(obj.length)) {
        return createBuffer(that, 0)
      }
      return fromArrayLike(that, obj)
    }

    if (obj.type === 'Buffer' && isArray(obj.data)) {
      return fromArrayLike(that, obj.data)
    }
  }

  throw new TypeError('First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.')
}

function checked (length) {
  // Note: cannot use `length < kMaxLength()` here because that fails when
  // length is NaN (which is otherwise coerced to zero.)
  if (length >= kMaxLength()) {
    throw new RangeError('Attempt to allocate Buffer larger than maximum ' +
                         'size: 0x' + kMaxLength().toString(16) + ' bytes')
  }
  return length | 0
}

function SlowBuffer (length) {
  if (+length != length) { // eslint-disable-line eqeqeq
    length = 0
  }
  return Buffer.alloc(+length)
}

Buffer.isBuffer = function isBuffer (b) {
  return !!(b != null && b._isBuffer)
}

Buffer.compare = function compare (a, b) {
  if (!Buffer.isBuffer(a) || !Buffer.isBuffer(b)) {
    throw new TypeError('Arguments must be Buffers')
  }

  if (a === b) return 0

  var x = a.length
  var y = b.length

  for (var i = 0, len = Math.min(x, y); i < len; ++i) {
    if (a[i] !== b[i]) {
      x = a[i]
      y = b[i]
      break
    }
  }

  if (x < y) return -1
  if (y < x) return 1
  return 0
}

Buffer.isEncoding = function isEncoding (encoding) {
  switch (String(encoding).toLowerCase()) {
    case 'hex':
    case 'utf8':
    case 'utf-8':
    case 'ascii':
    case 'latin1':
    case 'binary':
    case 'base64':
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      return true
    default:
      return false
  }
}

Buffer.concat = function concat (list, length) {
  if (!isArray(list)) {
    throw new TypeError('"list" argument must be an Array of Buffers')
  }

  if (list.length === 0) {
    return Buffer.alloc(0)
  }

  var i
  if (length === undefined) {
    length = 0
    for (i = 0; i < list.length; ++i) {
      length += list[i].length
    }
  }

  var buffer = Buffer.allocUnsafe(length)
  var pos = 0
  for (i = 0; i < list.length; ++i) {
    var buf = list[i]
    if (!Buffer.isBuffer(buf)) {
      throw new TypeError('"list" argument must be an Array of Buffers')
    }
    buf.copy(buffer, pos)
    pos += buf.length
  }
  return buffer
}

function byteLength (string, encoding) {
  if (Buffer.isBuffer(string)) {
    return string.length
  }
  if (typeof ArrayBuffer !== 'undefined' && typeof ArrayBuffer.isView === 'function' &&
      (ArrayBuffer.isView(string) || string instanceof ArrayBuffer)) {
    return string.byteLength
  }
  if (typeof string !== 'string') {
    string = '' + string
  }

  var len = string.length
  if (len === 0) return 0

  // Use a for loop to avoid recursion
  var loweredCase = false
  for (;;) {
    switch (encoding) {
      case 'ascii':
      case 'latin1':
      case 'binary':
        return len
      case 'utf8':
      case 'utf-8':
      case undefined:
        return utf8ToBytes(string).length
      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return len * 2
      case 'hex':
        return len >>> 1
      case 'base64':
        return base64ToBytes(string).length
      default:
        if (loweredCase) return utf8ToBytes(string).length // assume utf8
        encoding = ('' + encoding).toLowerCase()
        loweredCase = true
    }
  }
}
Buffer.byteLength = byteLength

function slowToString (encoding, start, end) {
  var loweredCase = false

  // No need to verify that "this.length <= MAX_UINT32" since it's a read-only
  // property of a typed array.

  // This behaves neither like String nor Uint8Array in that we set start/end
  // to their upper/lower bounds if the value passed is out of range.
  // undefined is handled specially as per ECMA-262 6th Edition,
  // Section 13.3.3.7 Runtime Semantics: KeyedBindingInitialization.
  if (start === undefined || start < 0) {
    start = 0
  }
  // Return early if start > this.length. Done here to prevent potential uint32
  // coercion fail below.
  if (start > this.length) {
    return ''
  }

  if (end === undefined || end > this.length) {
    end = this.length
  }

  if (end <= 0) {
    return ''
  }

  // Force coersion to uint32. This will also coerce falsey/NaN values to 0.
  end >>>= 0
  start >>>= 0

  if (end <= start) {
    return ''
  }

  if (!encoding) encoding = 'utf8'

  while (true) {
    switch (encoding) {
      case 'hex':
        return hexSlice(this, start, end)

      case 'utf8':
      case 'utf-8':
        return utf8Slice(this, start, end)

      case 'ascii':
        return asciiSlice(this, start, end)

      case 'latin1':
      case 'binary':
        return latin1Slice(this, start, end)

      case 'base64':
        return base64Slice(this, start, end)

      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return utf16leSlice(this, start, end)

      default:
        if (loweredCase) throw new TypeError('Unknown encoding: ' + encoding)
        encoding = (encoding + '').toLowerCase()
        loweredCase = true
    }
  }
}

// The property is used by `Buffer.isBuffer` and `is-buffer` (in Safari 5-7) to detect
// Buffer instances.
Buffer.prototype._isBuffer = true

function swap (b, n, m) {
  var i = b[n]
  b[n] = b[m]
  b[m] = i
}

Buffer.prototype.swap16 = function swap16 () {
  var len = this.length
  if (len % 2 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 16-bits')
  }
  for (var i = 0; i < len; i += 2) {
    swap(this, i, i + 1)
  }
  return this
}

Buffer.prototype.swap32 = function swap32 () {
  var len = this.length
  if (len % 4 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 32-bits')
  }
  for (var i = 0; i < len; i += 4) {
    swap(this, i, i + 3)
    swap(this, i + 1, i + 2)
  }
  return this
}

Buffer.prototype.swap64 = function swap64 () {
  var len = this.length
  if (len % 8 !== 0) {
    throw new RangeError('Buffer size must be a multiple of 64-bits')
  }
  for (var i = 0; i < len; i += 8) {
    swap(this, i, i + 7)
    swap(this, i + 1, i + 6)
    swap(this, i + 2, i + 5)
    swap(this, i + 3, i + 4)
  }
  return this
}

Buffer.prototype.toString = function toString () {
  var length = this.length | 0
  if (length === 0) return ''
  if (arguments.length === 0) return utf8Slice(this, 0, length)
  return slowToString.apply(this, arguments)
}

Buffer.prototype.equals = function equals (b) {
  if (!Buffer.isBuffer(b)) throw new TypeError('Argument must be a Buffer')
  if (this === b) return true
  return Buffer.compare(this, b) === 0
}

Buffer.prototype.inspect = function inspect () {
  var str = ''
  var max = exports.INSPECT_MAX_BYTES
  if (this.length > 0) {
    str = this.toString('hex', 0, max).match(/.{2}/g).join(' ')
    if (this.length > max) str += ' ... '
  }
  return '<Buffer ' + str + '>'
}

Buffer.prototype.compare = function compare (target, start, end, thisStart, thisEnd) {
  if (!Buffer.isBuffer(target)) {
    throw new TypeError('Argument must be a Buffer')
  }

  if (start === undefined) {
    start = 0
  }
  if (end === undefined) {
    end = target ? target.length : 0
  }
  if (thisStart === undefined) {
    thisStart = 0
  }
  if (thisEnd === undefined) {
    thisEnd = this.length
  }

  if (start < 0 || end > target.length || thisStart < 0 || thisEnd > this.length) {
    throw new RangeError('out of range index')
  }

  if (thisStart >= thisEnd && start >= end) {
    return 0
  }
  if (thisStart >= thisEnd) {
    return -1
  }
  if (start >= end) {
    return 1
  }

  start >>>= 0
  end >>>= 0
  thisStart >>>= 0
  thisEnd >>>= 0

  if (this === target) return 0

  var x = thisEnd - thisStart
  var y = end - start
  var len = Math.min(x, y)

  var thisCopy = this.slice(thisStart, thisEnd)
  var targetCopy = target.slice(start, end)

  for (var i = 0; i < len; ++i) {
    if (thisCopy[i] !== targetCopy[i]) {
      x = thisCopy[i]
      y = targetCopy[i]
      break
    }
  }

  if (x < y) return -1
  if (y < x) return 1
  return 0
}

// Finds either the first index of `val` in `buffer` at offset >= `byteOffset`,
// OR the last index of `val` in `buffer` at offset <= `byteOffset`.
//
// Arguments:
// - buffer - a Buffer to search
// - val - a string, Buffer, or number
// - byteOffset - an index into `buffer`; will be clamped to an int32
// - encoding - an optional encoding, relevant is val is a string
// - dir - true for indexOf, false for lastIndexOf
function bidirectionalIndexOf (buffer, val, byteOffset, encoding, dir) {
  // Empty buffer means no match
  if (buffer.length === 0) return -1

  // Normalize byteOffset
  if (typeof byteOffset === 'string') {
    encoding = byteOffset
    byteOffset = 0
  } else if (byteOffset > 0x7fffffff) {
    byteOffset = 0x7fffffff
  } else if (byteOffset < -0x80000000) {
    byteOffset = -0x80000000
  }
  byteOffset = +byteOffset  // Coerce to Number.
  if (isNaN(byteOffset)) {
    // byteOffset: it it's undefined, null, NaN, "foo", etc, search whole buffer
    byteOffset = dir ? 0 : (buffer.length - 1)
  }

  // Normalize byteOffset: negative offsets start from the end of the buffer
  if (byteOffset < 0) byteOffset = buffer.length + byteOffset
  if (byteOffset >= buffer.length) {
    if (dir) return -1
    else byteOffset = buffer.length - 1
  } else if (byteOffset < 0) {
    if (dir) byteOffset = 0
    else return -1
  }

  // Normalize val
  if (typeof val === 'string') {
    val = Buffer.from(val, encoding)
  }

  // Finally, search either indexOf (if dir is true) or lastIndexOf
  if (Buffer.isBuffer(val)) {
    // Special case: looking for empty string/buffer always fails
    if (val.length === 0) {
      return -1
    }
    return arrayIndexOf(buffer, val, byteOffset, encoding, dir)
  } else if (typeof val === 'number') {
    val = val & 0xFF // Search for a byte value [0-255]
    if (Buffer.TYPED_ARRAY_SUPPORT &&
        typeof Uint8Array.prototype.indexOf === 'function') {
      if (dir) {
        return Uint8Array.prototype.indexOf.call(buffer, val, byteOffset)
      } else {
        return Uint8Array.prototype.lastIndexOf.call(buffer, val, byteOffset)
      }
    }
    return arrayIndexOf(buffer, [ val ], byteOffset, encoding, dir)
  }

  throw new TypeError('val must be string, number or Buffer')
}

function arrayIndexOf (arr, val, byteOffset, encoding, dir) {
  var indexSize = 1
  var arrLength = arr.length
  var valLength = val.length

  if (encoding !== undefined) {
    encoding = String(encoding).toLowerCase()
    if (encoding === 'ucs2' || encoding === 'ucs-2' ||
        encoding === 'utf16le' || encoding === 'utf-16le') {
      if (arr.length < 2 || val.length < 2) {
        return -1
      }
      indexSize = 2
      arrLength /= 2
      valLength /= 2
      byteOffset /= 2
    }
  }

  function read (buf, i) {
    if (indexSize === 1) {
      return buf[i]
    } else {
      return buf.readUInt16BE(i * indexSize)
    }
  }

  var i
  if (dir) {
    var foundIndex = -1
    for (i = byteOffset; i < arrLength; i++) {
      if (read(arr, i) === read(val, foundIndex === -1 ? 0 : i - foundIndex)) {
        if (foundIndex === -1) foundIndex = i
        if (i - foundIndex + 1 === valLength) return foundIndex * indexSize
      } else {
        if (foundIndex !== -1) i -= i - foundIndex
        foundIndex = -1
      }
    }
  } else {
    if (byteOffset + valLength > arrLength) byteOffset = arrLength - valLength
    for (i = byteOffset; i >= 0; i--) {
      var found = true
      for (var j = 0; j < valLength; j++) {
        if (read(arr, i + j) !== read(val, j)) {
          found = false
          break
        }
      }
      if (found) return i
    }
  }

  return -1
}

Buffer.prototype.includes = function includes (val, byteOffset, encoding) {
  return this.indexOf(val, byteOffset, encoding) !== -1
}

Buffer.prototype.indexOf = function indexOf (val, byteOffset, encoding) {
  return bidirectionalIndexOf(this, val, byteOffset, encoding, true)
}

Buffer.prototype.lastIndexOf = function lastIndexOf (val, byteOffset, encoding) {
  return bidirectionalIndexOf(this, val, byteOffset, encoding, false)
}

function hexWrite (buf, string, offset, length) {
  offset = Number(offset) || 0
  var remaining = buf.length - offset
  if (!length) {
    length = remaining
  } else {
    length = Number(length)
    if (length > remaining) {
      length = remaining
    }
  }

  // must be an even number of digits
  var strLen = string.length
  if (strLen % 2 !== 0) throw new TypeError('Invalid hex string')

  if (length > strLen / 2) {
    length = strLen / 2
  }
  for (var i = 0; i < length; ++i) {
    var parsed = parseInt(string.substr(i * 2, 2), 16)
    if (isNaN(parsed)) return i
    buf[offset + i] = parsed
  }
  return i
}

function utf8Write (buf, string, offset, length) {
  return blitBuffer(utf8ToBytes(string, buf.length - offset), buf, offset, length)
}

function asciiWrite (buf, string, offset, length) {
  return blitBuffer(asciiToBytes(string), buf, offset, length)
}

function latin1Write (buf, string, offset, length) {
  return asciiWrite(buf, string, offset, length)
}

function base64Write (buf, string, offset, length) {
  return blitBuffer(base64ToBytes(string), buf, offset, length)
}

function ucs2Write (buf, string, offset, length) {
  return blitBuffer(utf16leToBytes(string, buf.length - offset), buf, offset, length)
}

Buffer.prototype.write = function write (string, offset, length, encoding) {
  // Buffer#write(string)
  if (offset === undefined) {
    encoding = 'utf8'
    length = this.length
    offset = 0
  // Buffer#write(string, encoding)
  } else if (length === undefined && typeof offset === 'string') {
    encoding = offset
    length = this.length
    offset = 0
  // Buffer#write(string, offset[, length][, encoding])
  } else if (isFinite(offset)) {
    offset = offset | 0
    if (isFinite(length)) {
      length = length | 0
      if (encoding === undefined) encoding = 'utf8'
    } else {
      encoding = length
      length = undefined
    }
  // legacy write(string, encoding, offset, length) - remove in v0.13
  } else {
    throw new Error(
      'Buffer.write(string, encoding, offset[, length]) is no longer supported'
    )
  }

  var remaining = this.length - offset
  if (length === undefined || length > remaining) length = remaining

  if ((string.length > 0 && (length < 0 || offset < 0)) || offset > this.length) {
    throw new RangeError('Attempt to write outside buffer bounds')
  }

  if (!encoding) encoding = 'utf8'

  var loweredCase = false
  for (;;) {
    switch (encoding) {
      case 'hex':
        return hexWrite(this, string, offset, length)

      case 'utf8':
      case 'utf-8':
        return utf8Write(this, string, offset, length)

      case 'ascii':
        return asciiWrite(this, string, offset, length)

      case 'latin1':
      case 'binary':
        return latin1Write(this, string, offset, length)

      case 'base64':
        // Warning: maxLength not taken into account in base64Write
        return base64Write(this, string, offset, length)

      case 'ucs2':
      case 'ucs-2':
      case 'utf16le':
      case 'utf-16le':
        return ucs2Write(this, string, offset, length)

      default:
        if (loweredCase) throw new TypeError('Unknown encoding: ' + encoding)
        encoding = ('' + encoding).toLowerCase()
        loweredCase = true
    }
  }
}

Buffer.prototype.toJSON = function toJSON () {
  return {
    type: 'Buffer',
    data: Array.prototype.slice.call(this._arr || this, 0)
  }
}

function base64Slice (buf, start, end) {
  if (start === 0 && end === buf.length) {
    return base64.fromByteArray(buf)
  } else {
    return base64.fromByteArray(buf.slice(start, end))
  }
}

function utf8Slice (buf, start, end) {
  end = Math.min(buf.length, end)
  var res = []

  var i = start
  while (i < end) {
    var firstByte = buf[i]
    var codePoint = null
    var bytesPerSequence = (firstByte > 0xEF) ? 4
      : (firstByte > 0xDF) ? 3
      : (firstByte > 0xBF) ? 2
      : 1

    if (i + bytesPerSequence <= end) {
      var secondByte, thirdByte, fourthByte, tempCodePoint

      switch (bytesPerSequence) {
        case 1:
          if (firstByte < 0x80) {
            codePoint = firstByte
          }
          break
        case 2:
          secondByte = buf[i + 1]
          if ((secondByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0x1F) << 0x6 | (secondByte & 0x3F)
            if (tempCodePoint > 0x7F) {
              codePoint = tempCodePoint
            }
          }
          break
        case 3:
          secondByte = buf[i + 1]
          thirdByte = buf[i + 2]
          if ((secondByte & 0xC0) === 0x80 && (thirdByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0xF) << 0xC | (secondByte & 0x3F) << 0x6 | (thirdByte & 0x3F)
            if (tempCodePoint > 0x7FF && (tempCodePoint < 0xD800 || tempCodePoint > 0xDFFF)) {
              codePoint = tempCodePoint
            }
          }
          break
        case 4:
          secondByte = buf[i + 1]
          thirdByte = buf[i + 2]
          fourthByte = buf[i + 3]
          if ((secondByte & 0xC0) === 0x80 && (thirdByte & 0xC0) === 0x80 && (fourthByte & 0xC0) === 0x80) {
            tempCodePoint = (firstByte & 0xF) << 0x12 | (secondByte & 0x3F) << 0xC | (thirdByte & 0x3F) << 0x6 | (fourthByte & 0x3F)
            if (tempCodePoint > 0xFFFF && tempCodePoint < 0x110000) {
              codePoint = tempCodePoint
            }
          }
      }
    }

    if (codePoint === null) {
      // we did not generate a valid codePoint so insert a
      // replacement char (U+FFFD) and advance only 1 byte
      codePoint = 0xFFFD
      bytesPerSequence = 1
    } else if (codePoint > 0xFFFF) {
      // encode to utf16 (surrogate pair dance)
      codePoint -= 0x10000
      res.push(codePoint >>> 10 & 0x3FF | 0xD800)
      codePoint = 0xDC00 | codePoint & 0x3FF
    }

    res.push(codePoint)
    i += bytesPerSequence
  }

  return decodeCodePointsArray(res)
}

// Based on http://stackoverflow.com/a/22747272/680742, the browser with
// the lowest limit is Chrome, with 0x10000 args.
// We go 1 magnitude less, for safety
var MAX_ARGUMENTS_LENGTH = 0x1000

function decodeCodePointsArray (codePoints) {
  var len = codePoints.length
  if (len <= MAX_ARGUMENTS_LENGTH) {
    return String.fromCharCode.apply(String, codePoints) // avoid extra slice()
  }

  // Decode in chunks to avoid "call stack size exceeded".
  var res = ''
  var i = 0
  while (i < len) {
    res += String.fromCharCode.apply(
      String,
      codePoints.slice(i, i += MAX_ARGUMENTS_LENGTH)
    )
  }
  return res
}

function asciiSlice (buf, start, end) {
  var ret = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; ++i) {
    ret += String.fromCharCode(buf[i] & 0x7F)
  }
  return ret
}

function latin1Slice (buf, start, end) {
  var ret = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; ++i) {
    ret += String.fromCharCode(buf[i])
  }
  return ret
}

function hexSlice (buf, start, end) {
  var len = buf.length

  if (!start || start < 0) start = 0
  if (!end || end < 0 || end > len) end = len

  var out = ''
  for (var i = start; i < end; ++i) {
    out += toHex(buf[i])
  }
  return out
}

function utf16leSlice (buf, start, end) {
  var bytes = buf.slice(start, end)
  var res = ''
  for (var i = 0; i < bytes.length; i += 2) {
    res += String.fromCharCode(bytes[i] + bytes[i + 1] * 256)
  }
  return res
}

Buffer.prototype.slice = function slice (start, end) {
  var len = this.length
  start = ~~start
  end = end === undefined ? len : ~~end

  if (start < 0) {
    start += len
    if (start < 0) start = 0
  } else if (start > len) {
    start = len
  }

  if (end < 0) {
    end += len
    if (end < 0) end = 0
  } else if (end > len) {
    end = len
  }

  if (end < start) end = start

  var newBuf
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    newBuf = this.subarray(start, end)
    newBuf.__proto__ = Buffer.prototype
  } else {
    var sliceLen = end - start
    newBuf = new Buffer(sliceLen, undefined)
    for (var i = 0; i < sliceLen; ++i) {
      newBuf[i] = this[i + start]
    }
  }

  return newBuf
}

/*
 * Need to make sure that buffer isn't trying to write out of bounds.
 */
function checkOffset (offset, ext, length) {
  if ((offset % 1) !== 0 || offset < 0) throw new RangeError('offset is not uint')
  if (offset + ext > length) throw new RangeError('Trying to access beyond buffer length')
}

Buffer.prototype.readUIntLE = function readUIntLE (offset, byteLength, noAssert) {
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var val = this[offset]
  var mul = 1
  var i = 0
  while (++i < byteLength && (mul *= 0x100)) {
    val += this[offset + i] * mul
  }

  return val
}

Buffer.prototype.readUIntBE = function readUIntBE (offset, byteLength, noAssert) {
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) {
    checkOffset(offset, byteLength, this.length)
  }

  var val = this[offset + --byteLength]
  var mul = 1
  while (byteLength > 0 && (mul *= 0x100)) {
    val += this[offset + --byteLength] * mul
  }

  return val
}

Buffer.prototype.readUInt8 = function readUInt8 (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 1, this.length)
  return this[offset]
}

Buffer.prototype.readUInt16LE = function readUInt16LE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 2, this.length)
  return this[offset] | (this[offset + 1] << 8)
}

Buffer.prototype.readUInt16BE = function readUInt16BE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 2, this.length)
  return (this[offset] << 8) | this[offset + 1]
}

Buffer.prototype.readUInt32LE = function readUInt32LE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)

  return ((this[offset]) |
      (this[offset + 1] << 8) |
      (this[offset + 2] << 16)) +
      (this[offset + 3] * 0x1000000)
}

Buffer.prototype.readUInt32BE = function readUInt32BE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset] * 0x1000000) +
    ((this[offset + 1] << 16) |
    (this[offset + 2] << 8) |
    this[offset + 3])
}

Buffer.prototype.readIntLE = function readIntLE (offset, byteLength, noAssert) {
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var val = this[offset]
  var mul = 1
  var i = 0
  while (++i < byteLength && (mul *= 0x100)) {
    val += this[offset + i] * mul
  }
  mul *= 0x80

  if (val >= mul) val -= Math.pow(2, 8 * byteLength)

  return val
}

Buffer.prototype.readIntBE = function readIntBE (offset, byteLength, noAssert) {
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) checkOffset(offset, byteLength, this.length)

  var i = byteLength
  var mul = 1
  var val = this[offset + --i]
  while (i > 0 && (mul *= 0x100)) {
    val += this[offset + --i] * mul
  }
  mul *= 0x80

  if (val >= mul) val -= Math.pow(2, 8 * byteLength)

  return val
}

Buffer.prototype.readInt8 = function readInt8 (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 1, this.length)
  if (!(this[offset] & 0x80)) return (this[offset])
  return ((0xff - this[offset] + 1) * -1)
}

Buffer.prototype.readInt16LE = function readInt16LE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 2, this.length)
  var val = this[offset] | (this[offset + 1] << 8)
  return (val & 0x8000) ? val | 0xFFFF0000 : val
}

Buffer.prototype.readInt16BE = function readInt16BE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 2, this.length)
  var val = this[offset + 1] | (this[offset] << 8)
  return (val & 0x8000) ? val | 0xFFFF0000 : val
}

Buffer.prototype.readInt32LE = function readInt32LE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset]) |
    (this[offset + 1] << 8) |
    (this[offset + 2] << 16) |
    (this[offset + 3] << 24)
}

Buffer.prototype.readInt32BE = function readInt32BE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)

  return (this[offset] << 24) |
    (this[offset + 1] << 16) |
    (this[offset + 2] << 8) |
    (this[offset + 3])
}

Buffer.prototype.readFloatLE = function readFloatLE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)
  return ieee754.read(this, offset, true, 23, 4)
}

Buffer.prototype.readFloatBE = function readFloatBE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 4, this.length)
  return ieee754.read(this, offset, false, 23, 4)
}

Buffer.prototype.readDoubleLE = function readDoubleLE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 8, this.length)
  return ieee754.read(this, offset, true, 52, 8)
}

Buffer.prototype.readDoubleBE = function readDoubleBE (offset, noAssert) {
  if (!noAssert) checkOffset(offset, 8, this.length)
  return ieee754.read(this, offset, false, 52, 8)
}

function checkInt (buf, value, offset, ext, max, min) {
  if (!Buffer.isBuffer(buf)) throw new TypeError('"buffer" argument must be a Buffer instance')
  if (value > max || value < min) throw new RangeError('"value" argument is out of bounds')
  if (offset + ext > buf.length) throw new RangeError('Index out of range')
}

Buffer.prototype.writeUIntLE = function writeUIntLE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) {
    var maxBytes = Math.pow(2, 8 * byteLength) - 1
    checkInt(this, value, offset, byteLength, maxBytes, 0)
  }

  var mul = 1
  var i = 0
  this[offset] = value & 0xFF
  while (++i < byteLength && (mul *= 0x100)) {
    this[offset + i] = (value / mul) & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeUIntBE = function writeUIntBE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset | 0
  byteLength = byteLength | 0
  if (!noAssert) {
    var maxBytes = Math.pow(2, 8 * byteLength) - 1
    checkInt(this, value, offset, byteLength, maxBytes, 0)
  }

  var i = byteLength - 1
  var mul = 1
  this[offset + i] = value & 0xFF
  while (--i >= 0 && (mul *= 0x100)) {
    this[offset + i] = (value / mul) & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeUInt8 = function writeUInt8 (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 1, 0xff, 0)
  if (!Buffer.TYPED_ARRAY_SUPPORT) value = Math.floor(value)
  this[offset] = (value & 0xff)
  return offset + 1
}

function objectWriteUInt16 (buf, value, offset, littleEndian) {
  if (value < 0) value = 0xffff + value + 1
  for (var i = 0, j = Math.min(buf.length - offset, 2); i < j; ++i) {
    buf[offset + i] = (value & (0xff << (8 * (littleEndian ? i : 1 - i)))) >>>
      (littleEndian ? i : 1 - i) * 8
  }
}

Buffer.prototype.writeUInt16LE = function writeUInt16LE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value & 0xff)
    this[offset + 1] = (value >>> 8)
  } else {
    objectWriteUInt16(this, value, offset, true)
  }
  return offset + 2
}

Buffer.prototype.writeUInt16BE = function writeUInt16BE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value >>> 8)
    this[offset + 1] = (value & 0xff)
  } else {
    objectWriteUInt16(this, value, offset, false)
  }
  return offset + 2
}

function objectWriteUInt32 (buf, value, offset, littleEndian) {
  if (value < 0) value = 0xffffffff + value + 1
  for (var i = 0, j = Math.min(buf.length - offset, 4); i < j; ++i) {
    buf[offset + i] = (value >>> (littleEndian ? i : 3 - i) * 8) & 0xff
  }
}

Buffer.prototype.writeUInt32LE = function writeUInt32LE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset + 3] = (value >>> 24)
    this[offset + 2] = (value >>> 16)
    this[offset + 1] = (value >>> 8)
    this[offset] = (value & 0xff)
  } else {
    objectWriteUInt32(this, value, offset, true)
  }
  return offset + 4
}

Buffer.prototype.writeUInt32BE = function writeUInt32BE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value >>> 24)
    this[offset + 1] = (value >>> 16)
    this[offset + 2] = (value >>> 8)
    this[offset + 3] = (value & 0xff)
  } else {
    objectWriteUInt32(this, value, offset, false)
  }
  return offset + 4
}

Buffer.prototype.writeIntLE = function writeIntLE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) {
    var limit = Math.pow(2, 8 * byteLength - 1)

    checkInt(this, value, offset, byteLength, limit - 1, -limit)
  }

  var i = 0
  var mul = 1
  var sub = 0
  this[offset] = value & 0xFF
  while (++i < byteLength && (mul *= 0x100)) {
    if (value < 0 && sub === 0 && this[offset + i - 1] !== 0) {
      sub = 1
    }
    this[offset + i] = ((value / mul) >> 0) - sub & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeIntBE = function writeIntBE (value, offset, byteLength, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) {
    var limit = Math.pow(2, 8 * byteLength - 1)

    checkInt(this, value, offset, byteLength, limit - 1, -limit)
  }

  var i = byteLength - 1
  var mul = 1
  var sub = 0
  this[offset + i] = value & 0xFF
  while (--i >= 0 && (mul *= 0x100)) {
    if (value < 0 && sub === 0 && this[offset + i + 1] !== 0) {
      sub = 1
    }
    this[offset + i] = ((value / mul) >> 0) - sub & 0xFF
  }

  return offset + byteLength
}

Buffer.prototype.writeInt8 = function writeInt8 (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 1, 0x7f, -0x80)
  if (!Buffer.TYPED_ARRAY_SUPPORT) value = Math.floor(value)
  if (value < 0) value = 0xff + value + 1
  this[offset] = (value & 0xff)
  return offset + 1
}

Buffer.prototype.writeInt16LE = function writeInt16LE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value & 0xff)
    this[offset + 1] = (value >>> 8)
  } else {
    objectWriteUInt16(this, value, offset, true)
  }
  return offset + 2
}

Buffer.prototype.writeInt16BE = function writeInt16BE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value >>> 8)
    this[offset + 1] = (value & 0xff)
  } else {
    objectWriteUInt16(this, value, offset, false)
  }
  return offset + 2
}

Buffer.prototype.writeInt32LE = function writeInt32LE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000)
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value & 0xff)
    this[offset + 1] = (value >>> 8)
    this[offset + 2] = (value >>> 16)
    this[offset + 3] = (value >>> 24)
  } else {
    objectWriteUInt32(this, value, offset, true)
  }
  return offset + 4
}

Buffer.prototype.writeInt32BE = function writeInt32BE (value, offset, noAssert) {
  value = +value
  offset = offset | 0
  if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000)
  if (value < 0) value = 0xffffffff + value + 1
  if (Buffer.TYPED_ARRAY_SUPPORT) {
    this[offset] = (value >>> 24)
    this[offset + 1] = (value >>> 16)
    this[offset + 2] = (value >>> 8)
    this[offset + 3] = (value & 0xff)
  } else {
    objectWriteUInt32(this, value, offset, false)
  }
  return offset + 4
}

function checkIEEE754 (buf, value, offset, ext, max, min) {
  if (offset + ext > buf.length) throw new RangeError('Index out of range')
  if (offset < 0) throw new RangeError('Index out of range')
}

function writeFloat (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    checkIEEE754(buf, value, offset, 4, 3.4028234663852886e+38, -3.4028234663852886e+38)
  }
  ieee754.write(buf, value, offset, littleEndian, 23, 4)
  return offset + 4
}

Buffer.prototype.writeFloatLE = function writeFloatLE (value, offset, noAssert) {
  return writeFloat(this, value, offset, true, noAssert)
}

Buffer.prototype.writeFloatBE = function writeFloatBE (value, offset, noAssert) {
  return writeFloat(this, value, offset, false, noAssert)
}

function writeDouble (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    checkIEEE754(buf, value, offset, 8, 1.7976931348623157E+308, -1.7976931348623157E+308)
  }
  ieee754.write(buf, value, offset, littleEndian, 52, 8)
  return offset + 8
}

Buffer.prototype.writeDoubleLE = function writeDoubleLE (value, offset, noAssert) {
  return writeDouble(this, value, offset, true, noAssert)
}

Buffer.prototype.writeDoubleBE = function writeDoubleBE (value, offset, noAssert) {
  return writeDouble(this, value, offset, false, noAssert)
}

// copy(targetBuffer, targetStart=0, sourceStart=0, sourceEnd=buffer.length)
Buffer.prototype.copy = function copy (target, targetStart, start, end) {
  if (!start) start = 0
  if (!end && end !== 0) end = this.length
  if (targetStart >= target.length) targetStart = target.length
  if (!targetStart) targetStart = 0
  if (end > 0 && end < start) end = start

  // Copy 0 bytes; we're done
  if (end === start) return 0
  if (target.length === 0 || this.length === 0) return 0

  // Fatal error conditions
  if (targetStart < 0) {
    throw new RangeError('targetStart out of bounds')
  }
  if (start < 0 || start >= this.length) throw new RangeError('sourceStart out of bounds')
  if (end < 0) throw new RangeError('sourceEnd out of bounds')

  // Are we oob?
  if (end > this.length) end = this.length
  if (target.length - targetStart < end - start) {
    end = target.length - targetStart + start
  }

  var len = end - start
  var i

  if (this === target && start < targetStart && targetStart < end) {
    // descending copy from end
    for (i = len - 1; i >= 0; --i) {
      target[i + targetStart] = this[i + start]
    }
  } else if (len < 1000 || !Buffer.TYPED_ARRAY_SUPPORT) {
    // ascending copy from start
    for (i = 0; i < len; ++i) {
      target[i + targetStart] = this[i + start]
    }
  } else {
    Uint8Array.prototype.set.call(
      target,
      this.subarray(start, start + len),
      targetStart
    )
  }

  return len
}

// Usage:
//    buffer.fill(number[, offset[, end]])
//    buffer.fill(buffer[, offset[, end]])
//    buffer.fill(string[, offset[, end]][, encoding])
Buffer.prototype.fill = function fill (val, start, end, encoding) {
  // Handle string cases:
  if (typeof val === 'string') {
    if (typeof start === 'string') {
      encoding = start
      start = 0
      end = this.length
    } else if (typeof end === 'string') {
      encoding = end
      end = this.length
    }
    if (val.length === 1) {
      var code = val.charCodeAt(0)
      if (code < 256) {
        val = code
      }
    }
    if (encoding !== undefined && typeof encoding !== 'string') {
      throw new TypeError('encoding must be a string')
    }
    if (typeof encoding === 'string' && !Buffer.isEncoding(encoding)) {
      throw new TypeError('Unknown encoding: ' + encoding)
    }
  } else if (typeof val === 'number') {
    val = val & 255
  }

  // Invalid ranges are not set to a default, so can range check early.
  if (start < 0 || this.length < start || this.length < end) {
    throw new RangeError('Out of range index')
  }

  if (end <= start) {
    return this
  }

  start = start >>> 0
  end = end === undefined ? this.length : end >>> 0

  if (!val) val = 0

  var i
  if (typeof val === 'number') {
    for (i = start; i < end; ++i) {
      this[i] = val
    }
  } else {
    var bytes = Buffer.isBuffer(val)
      ? val
      : utf8ToBytes(new Buffer(val, encoding).toString())
    var len = bytes.length
    for (i = 0; i < end - start; ++i) {
      this[i + start] = bytes[i % len]
    }
  }

  return this
}

// HELPER FUNCTIONS
// ================

var INVALID_BASE64_RE = /[^+\/0-9A-Za-z-_]/g

function base64clean (str) {
  // Node strips out invalid characters like \n and \t from the string, base64-js does not
  str = stringtrim(str).replace(INVALID_BASE64_RE, '')
  // Node converts strings with length < 2 to ''
  if (str.length < 2) return ''
  // Node allows for non-padded base64 strings (missing trailing ===), base64-js does not
  while (str.length % 4 !== 0) {
    str = str + '='
  }
  return str
}

function stringtrim (str) {
  if (str.trim) return str.trim()
  return str.replace(/^\s+|\s+$/g, '')
}

function toHex (n) {
  if (n < 16) return '0' + n.toString(16)
  return n.toString(16)
}

function utf8ToBytes (string, units) {
  units = units || Infinity
  var codePoint
  var length = string.length
  var leadSurrogate = null
  var bytes = []

  for (var i = 0; i < length; ++i) {
    codePoint = string.charCodeAt(i)

    // is surrogate component
    if (codePoint > 0xD7FF && codePoint < 0xE000) {
      // last char was a lead
      if (!leadSurrogate) {
        // no lead yet
        if (codePoint > 0xDBFF) {
          // unexpected trail
          if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
          continue
        } else if (i + 1 === length) {
          // unpaired lead
          if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
          continue
        }

        // valid lead
        leadSurrogate = codePoint

        continue
      }

      // 2 leads in a row
      if (codePoint < 0xDC00) {
        if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
        leadSurrogate = codePoint
        continue
      }

      // valid surrogate pair
      codePoint = (leadSurrogate - 0xD800 << 10 | codePoint - 0xDC00) + 0x10000
    } else if (leadSurrogate) {
      // valid bmp char, but last char was a lead
      if ((units -= 3) > -1) bytes.push(0xEF, 0xBF, 0xBD)
    }

    leadSurrogate = null

    // encode utf8
    if (codePoint < 0x80) {
      if ((units -= 1) < 0) break
      bytes.push(codePoint)
    } else if (codePoint < 0x800) {
      if ((units -= 2) < 0) break
      bytes.push(
        codePoint >> 0x6 | 0xC0,
        codePoint & 0x3F | 0x80
      )
    } else if (codePoint < 0x10000) {
      if ((units -= 3) < 0) break
      bytes.push(
        codePoint >> 0xC | 0xE0,
        codePoint >> 0x6 & 0x3F | 0x80,
        codePoint & 0x3F | 0x80
      )
    } else if (codePoint < 0x110000) {
      if ((units -= 4) < 0) break
      bytes.push(
        codePoint >> 0x12 | 0xF0,
        codePoint >> 0xC & 0x3F | 0x80,
        codePoint >> 0x6 & 0x3F | 0x80,
        codePoint & 0x3F | 0x80
      )
    } else {
      throw new Error('Invalid code point')
    }
  }

  return bytes
}

function asciiToBytes (str) {
  var byteArray = []
  for (var i = 0; i < str.length; ++i) {
    // Node's code seems to be doing this and not & 0x7F..
    byteArray.push(str.charCodeAt(i) & 0xFF)
  }
  return byteArray
}

function utf16leToBytes (str, units) {
  var c, hi, lo
  var byteArray = []
  for (var i = 0; i < str.length; ++i) {
    if ((units -= 2) < 0) break

    c = str.charCodeAt(i)
    hi = c >> 8
    lo = c % 256
    byteArray.push(lo)
    byteArray.push(hi)
  }

  return byteArray
}

function base64ToBytes (str) {
  return base64.toByteArray(base64clean(str))
}

function blitBuffer (src, dst, offset, length) {
  for (var i = 0; i < length; ++i) {
    if ((i + offset >= dst.length) || (i >= src.length)) break
    dst[i + offset] = src[i]
  }
  return i
}

function isnan (val) {
  return val !== val // eslint-disable-line no-self-compare
}

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer)
},{"base64-js":405,"buffer":407,"ieee754":409,"isarray":410}],408:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

function EventEmitter() {
  this._events = this._events || {};
  this._maxListeners = this._maxListeners || undefined;
}
module.exports = EventEmitter;

// Backwards-compat with node 0.10.x
EventEmitter.EventEmitter = EventEmitter;

EventEmitter.prototype._events = undefined;
EventEmitter.prototype._maxListeners = undefined;

// By default EventEmitters will print a warning if more than 10 listeners are
// added to it. This is a useful default which helps finding memory leaks.
EventEmitter.defaultMaxListeners = 10;

// Obviously not all Emitters should be limited to 10. This function allows
// that to be increased. Set to zero for unlimited.
EventEmitter.prototype.setMaxListeners = function(n) {
  if (!isNumber(n) || n < 0 || isNaN(n))
    throw TypeError('n must be a positive number');
  this._maxListeners = n;
  return this;
};

EventEmitter.prototype.emit = function(type) {
  var er, handler, len, args, i, listeners;

  if (!this._events)
    this._events = {};

  // If there is no 'error' event listener then throw.
  if (type === 'error') {
    if (!this._events.error ||
        (isObject(this._events.error) && !this._events.error.length)) {
      er = arguments[1];
      if (er instanceof Error) {
        throw er; // Unhandled 'error' event
      } else {
        // At least give some kind of context to the user
        var err = new Error('Uncaught, unspecified "error" event. (' + er + ')');
        err.context = er;
        throw err;
      }
    }
  }

  handler = this._events[type];

  if (isUndefined(handler))
    return false;

  if (isFunction(handler)) {
    switch (arguments.length) {
      // fast cases
      case 1:
        handler.call(this);
        break;
      case 2:
        handler.call(this, arguments[1]);
        break;
      case 3:
        handler.call(this, arguments[1], arguments[2]);
        break;
      // slower
      default:
        args = Array.prototype.slice.call(arguments, 1);
        handler.apply(this, args);
    }
  } else if (isObject(handler)) {
    args = Array.prototype.slice.call(arguments, 1);
    listeners = handler.slice();
    len = listeners.length;
    for (i = 0; i < len; i++)
      listeners[i].apply(this, args);
  }

  return true;
};

EventEmitter.prototype.addListener = function(type, listener) {
  var m;

  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  if (!this._events)
    this._events = {};

  // To avoid recursion in the case that type === "newListener"! Before
  // adding it to the listeners, first emit "newListener".
  if (this._events.newListener)
    this.emit('newListener', type,
              isFunction(listener.listener) ?
              listener.listener : listener);

  if (!this._events[type])
    // Optimize the case of one listener. Don't need the extra array object.
    this._events[type] = listener;
  else if (isObject(this._events[type]))
    // If we've already got an array, just append.
    this._events[type].push(listener);
  else
    // Adding the second element, need to change to array.
    this._events[type] = [this._events[type], listener];

  // Check for listener leak
  if (isObject(this._events[type]) && !this._events[type].warned) {
    if (!isUndefined(this._maxListeners)) {
      m = this._maxListeners;
    } else {
      m = EventEmitter.defaultMaxListeners;
    }

    if (m && m > 0 && this._events[type].length > m) {
      this._events[type].warned = true;
      console.error('(node) warning: possible EventEmitter memory ' +
                    'leak detected. %d listeners added. ' +
                    'Use emitter.setMaxListeners() to increase limit.',
                    this._events[type].length);
      if (typeof console.trace === 'function') {
        // not supported in IE 10
        console.trace();
      }
    }
  }

  return this;
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.once = function(type, listener) {
  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  var fired = false;

  function g() {
    this.removeListener(type, g);

    if (!fired) {
      fired = true;
      listener.apply(this, arguments);
    }
  }

  g.listener = listener;
  this.on(type, g);

  return this;
};

// emits a 'removeListener' event iff the listener was removed
EventEmitter.prototype.removeListener = function(type, listener) {
  var list, position, length, i;

  if (!isFunction(listener))
    throw TypeError('listener must be a function');

  if (!this._events || !this._events[type])
    return this;

  list = this._events[type];
  length = list.length;
  position = -1;

  if (list === listener ||
      (isFunction(list.listener) && list.listener === listener)) {
    delete this._events[type];
    if (this._events.removeListener)
      this.emit('removeListener', type, listener);

  } else if (isObject(list)) {
    for (i = length; i-- > 0;) {
      if (list[i] === listener ||
          (list[i].listener && list[i].listener === listener)) {
        position = i;
        break;
      }
    }

    if (position < 0)
      return this;

    if (list.length === 1) {
      list.length = 0;
      delete this._events[type];
    } else {
      list.splice(position, 1);
    }

    if (this._events.removeListener)
      this.emit('removeListener', type, listener);
  }

  return this;
};

EventEmitter.prototype.removeAllListeners = function(type) {
  var key, listeners;

  if (!this._events)
    return this;

  // not listening for removeListener, no need to emit
  if (!this._events.removeListener) {
    if (arguments.length === 0)
      this._events = {};
    else if (this._events[type])
      delete this._events[type];
    return this;
  }

  // emit removeListener for all listeners on all events
  if (arguments.length === 0) {
    for (key in this._events) {
      if (key === 'removeListener') continue;
      this.removeAllListeners(key);
    }
    this.removeAllListeners('removeListener');
    this._events = {};
    return this;
  }

  listeners = this._events[type];

  if (isFunction(listeners)) {
    this.removeListener(type, listeners);
  } else if (listeners) {
    // LIFO order
    while (listeners.length)
      this.removeListener(type, listeners[listeners.length - 1]);
  }
  delete this._events[type];

  return this;
};

EventEmitter.prototype.listeners = function(type) {
  var ret;
  if (!this._events || !this._events[type])
    ret = [];
  else if (isFunction(this._events[type]))
    ret = [this._events[type]];
  else
    ret = this._events[type].slice();
  return ret;
};

EventEmitter.prototype.listenerCount = function(type) {
  if (this._events) {
    var evlistener = this._events[type];

    if (isFunction(evlistener))
      return 1;
    else if (evlistener)
      return evlistener.length;
  }
  return 0;
};

EventEmitter.listenerCount = function(emitter, type) {
  return emitter.listenerCount(type);
};

function isFunction(arg) {
  return typeof arg === 'function';
}

function isNumber(arg) {
  return typeof arg === 'number';
}

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}

function isUndefined(arg) {
  return arg === void 0;
}

},{}],409:[function(require,module,exports){
exports.read = function (buffer, offset, isLE, mLen, nBytes) {
  var e, m
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var nBits = -7
  var i = isLE ? (nBytes - 1) : 0
  var d = isLE ? -1 : 1
  var s = buffer[offset + i]

  i += d

  e = s & ((1 << (-nBits)) - 1)
  s >>= (-nBits)
  nBits += eLen
  for (; nBits > 0; e = (e * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  m = e & ((1 << (-nBits)) - 1)
  e >>= (-nBits)
  nBits += mLen
  for (; nBits > 0; m = (m * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  if (e === 0) {
    e = 1 - eBias
  } else if (e === eMax) {
    return m ? NaN : ((s ? -1 : 1) * Infinity)
  } else {
    m = m + Math.pow(2, mLen)
    e = e - eBias
  }
  return (s ? -1 : 1) * m * Math.pow(2, e - mLen)
}

exports.write = function (buffer, value, offset, isLE, mLen, nBytes) {
  var e, m, c
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var rt = (mLen === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0)
  var i = isLE ? 0 : (nBytes - 1)
  var d = isLE ? 1 : -1
  var s = value < 0 || (value === 0 && 1 / value < 0) ? 1 : 0

  value = Math.abs(value)

  if (isNaN(value) || value === Infinity) {
    m = isNaN(value) ? 1 : 0
    e = eMax
  } else {
    e = Math.floor(Math.log(value) / Math.LN2)
    if (value * (c = Math.pow(2, -e)) < 1) {
      e--
      c *= 2
    }
    if (e + eBias >= 1) {
      value += rt / c
    } else {
      value += rt * Math.pow(2, 1 - eBias)
    }
    if (value * c >= 2) {
      e++
      c /= 2
    }

    if (e + eBias >= eMax) {
      m = 0
      e = eMax
    } else if (e + eBias >= 1) {
      m = ((value * c) - 1) * Math.pow(2, mLen)
      e = e + eBias
    } else {
      m = value * Math.pow(2, eBias - 1) * Math.pow(2, mLen)
      e = 0
    }
  }

  for (; mLen >= 8; buffer[offset + i] = m & 0xff, i += d, m /= 256, mLen -= 8) {}

  e = (e << mLen) | m
  eLen += mLen
  for (; eLen > 0; buffer[offset + i] = e & 0xff, i += d, e /= 256, eLen -= 8) {}

  buffer[offset + i - d] |= s * 128
}

},{}],410:[function(require,module,exports){
var toString = {}.toString;

module.exports = Array.isArray || function (arr) {
  return toString.call(arr) == '[object Array]';
};

},{}],411:[function(require,module,exports){
(function(exports) {
  "use strict";

  function isArray(obj) {
    if (obj !== null) {
      return Object.prototype.toString.call(obj) === "[object Array]";
    } else {
      return false;
    }
  }

  function isObject(obj) {
    if (obj !== null) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    } else {
      return false;
    }
  }

  function strictDeepEqual(first, second) {
    // Check the scalar case first.
    if (first === second) {
      return true;
    }

    // Check if they are the same type.
    var firstType = Object.prototype.toString.call(first);
    if (firstType !== Object.prototype.toString.call(second)) {
      return false;
    }
    // We know that first and second have the same type so we can just check the
    // first type from now on.
    if (isArray(first) === true) {
      // Short circuit if they're not the same length;
      if (first.length !== second.length) {
        return false;
      }
      for (var i = 0; i < first.length; i++) {
        if (strictDeepEqual(first[i], second[i]) === false) {
          return false;
        }
      }
      return true;
    }
    if (isObject(first) === true) {
      // An object is equal if it has the same key/value pairs.
      var keysSeen = {};
      for (var key in first) {
        if (hasOwnProperty.call(first, key)) {
          if (strictDeepEqual(first[key], second[key]) === false) {
            return false;
          }
          keysSeen[key] = true;
        }
      }
      // Now check that there aren't any keys in second that weren't
      // in first.
      for (var key2 in second) {
        if (hasOwnProperty.call(second, key2)) {
          if (keysSeen[key2] !== true) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  }

  function isFalse(obj) {
    // From the spec:
    // A false value corresponds to the following values:
    // Empty list
    // Empty object
    // Empty string
    // False boolean
    // null value

    // First check the scalar values.
    if (obj === "" || obj === false || obj === null) {
        return true;
    } else if (isArray(obj) && obj.length === 0) {
        // Check for an empty array.
        return true;
    } else if (isObject(obj)) {
        // Check for an empty object.
        for (var key in obj) {
            // If there are any keys, then
            // the object is not empty so the object
            // is not false.
            if (obj.hasOwnProperty(key)) {
              return false;
            }
        }
        return true;
    } else {
        return false;
    }
  }

  function objValues(obj) {
    var keys = Object.keys(obj);
    var values = [];
    for (var i = 0; i < keys.length; i++) {
      values.push(obj[keys[i]]);
    }
    return values;
  }

  function merge(a, b) {
      var merged = {};
      for (var key in a) {
          merged[key] = a[key];
      }
      for (var key2 in b) {
          merged[key2] = b[key2];
      }
      return merged;
  }

  var trimLeft;
  if (typeof String.prototype.trimLeft === "function") {
    trimLeft = function(str) {
      return str.trimLeft();
    };
  } else {
    trimLeft = function(str) {
      return str.match(/^\s*(.*)/)[1];
    };
  }

  // Type constants used to define functions.
  var TYPE_NUMBER = 0;
  var TYPE_ANY = 1;
  var TYPE_STRING = 2;
  var TYPE_ARRAY = 3;
  var TYPE_OBJECT = 4;
  var TYPE_BOOLEAN = 5;
  var TYPE_EXPREF = 6;
  var TYPE_NULL = 7;
  var TYPE_ARRAY_NUMBER = 8;
  var TYPE_ARRAY_STRING = 9;

  var TOK_EOF = "EOF";
  var TOK_UNQUOTEDIDENTIFIER = "UnquotedIdentifier";
  var TOK_QUOTEDIDENTIFIER = "QuotedIdentifier";
  var TOK_RBRACKET = "Rbracket";
  var TOK_RPAREN = "Rparen";
  var TOK_COMMA = "Comma";
  var TOK_COLON = "Colon";
  var TOK_RBRACE = "Rbrace";
  var TOK_NUMBER = "Number";
  var TOK_CURRENT = "Current";
  var TOK_EXPREF = "Expref";
  var TOK_PIPE = "Pipe";
  var TOK_OR = "Or";
  var TOK_AND = "And";
  var TOK_EQ = "EQ";
  var TOK_GT = "GT";
  var TOK_LT = "LT";
  var TOK_GTE = "GTE";
  var TOK_LTE = "LTE";
  var TOK_NE = "NE";
  var TOK_FLATTEN = "Flatten";
  var TOK_STAR = "Star";
  var TOK_FILTER = "Filter";
  var TOK_DOT = "Dot";
  var TOK_NOT = "Not";
  var TOK_LBRACE = "Lbrace";
  var TOK_LBRACKET = "Lbracket";
  var TOK_LPAREN= "Lparen";
  var TOK_LITERAL= "Literal";

  // The "&", "[", "<", ">" tokens
  // are not in basicToken because
  // there are two token variants
  // ("&&", "[?", "<=", ">=").  This is specially handled
  // below.

  var basicTokens = {
    ".": TOK_DOT,
    "*": TOK_STAR,
    ",": TOK_COMMA,
    ":": TOK_COLON,
    "{": TOK_LBRACE,
    "}": TOK_RBRACE,
    "]": TOK_RBRACKET,
    "(": TOK_LPAREN,
    ")": TOK_RPAREN,
    "@": TOK_CURRENT
  };

  var operatorStartToken = {
      "<": true,
      ">": true,
      "=": true,
      "!": true
  };

  var skipChars = {
      " ": true,
      "\t": true,
      "\n": true
  };


  function isAlpha(ch) {
      return (ch >= "a" && ch <= "z") ||
             (ch >= "A" && ch <= "Z") ||
             ch === "_";
  }

  function isNum(ch) {
      return (ch >= "0" && ch <= "9") ||
             ch === "-";
  }
  function isAlphaNum(ch) {
      return (ch >= "a" && ch <= "z") ||
             (ch >= "A" && ch <= "Z") ||
             (ch >= "0" && ch <= "9") ||
             ch === "_";
  }

  function Lexer() {
  }
  Lexer.prototype = {
      tokenize: function(stream) {
          var tokens = [];
          this._current = 0;
          var start;
          var identifier;
          var token;
          while (this._current < stream.length) {
              if (isAlpha(stream[this._current])) {
                  start = this._current;
                  identifier = this._consumeUnquotedIdentifier(stream);
                  tokens.push({type: TOK_UNQUOTEDIDENTIFIER,
                               value: identifier,
                               start: start});
              } else if (basicTokens[stream[this._current]] !== undefined) {
                  tokens.push({type: basicTokens[stream[this._current]],
                              value: stream[this._current],
                              start: this._current});
                  this._current++;
              } else if (isNum(stream[this._current])) {
                  token = this._consumeNumber(stream);
                  tokens.push(token);
              } else if (stream[this._current] === "[") {
                  // No need to increment this._current.  This happens
                  // in _consumeLBracket
                  token = this._consumeLBracket(stream);
                  tokens.push(token);
              } else if (stream[this._current] === "\"") {
                  start = this._current;
                  identifier = this._consumeQuotedIdentifier(stream);
                  tokens.push({type: TOK_QUOTEDIDENTIFIER,
                               value: identifier,
                               start: start});
              } else if (stream[this._current] === "'") {
                  start = this._current;
                  identifier = this._consumeRawStringLiteral(stream);
                  tokens.push({type: TOK_LITERAL,
                               value: identifier,
                               start: start});
              } else if (stream[this._current] === "`") {
                  start = this._current;
                  var literal = this._consumeLiteral(stream);
                  tokens.push({type: TOK_LITERAL,
                               value: literal,
                               start: start});
              } else if (operatorStartToken[stream[this._current]] !== undefined) {
                  tokens.push(this._consumeOperator(stream));
              } else if (skipChars[stream[this._current]] !== undefined) {
                  // Ignore whitespace.
                  this._current++;
              } else if (stream[this._current] === "&") {
                  start = this._current;
                  this._current++;
                  if (stream[this._current] === "&") {
                      this._current++;
                      tokens.push({type: TOK_AND, value: "&&", start: start});
                  } else {
                      tokens.push({type: TOK_EXPREF, value: "&", start: start});
                  }
              } else if (stream[this._current] === "|") {
                  start = this._current;
                  this._current++;
                  if (stream[this._current] === "|") {
                      this._current++;
                      tokens.push({type: TOK_OR, value: "||", start: start});
                  } else {
                      tokens.push({type: TOK_PIPE, value: "|", start: start});
                  }
              } else {
                  var error = new Error("Unknown character:" + stream[this._current]);
                  error.name = "LexerError";
                  throw error;
              }
          }
          return tokens;
      },

      _consumeUnquotedIdentifier: function(stream) {
          var start = this._current;
          this._current++;
          while (this._current < stream.length && isAlphaNum(stream[this._current])) {
              this._current++;
          }
          return stream.slice(start, this._current);
      },

      _consumeQuotedIdentifier: function(stream) {
          var start = this._current;
          this._current++;
          var maxLength = stream.length;
          while (stream[this._current] !== "\"" && this._current < maxLength) {
              // You can escape a double quote and you can escape an escape.
              var current = this._current;
              if (stream[current] === "\\" && (stream[current + 1] === "\\" ||
                                               stream[current + 1] === "\"")) {
                  current += 2;
              } else {
                  current++;
              }
              this._current = current;
          }
          this._current++;
          return JSON.parse(stream.slice(start, this._current));
      },

      _consumeRawStringLiteral: function(stream) {
          var start = this._current;
          this._current++;
          var maxLength = stream.length;
          while (stream[this._current] !== "'" && this._current < maxLength) {
              // You can escape a single quote and you can escape an escape.
              var current = this._current;
              if (stream[current] === "\\" && (stream[current + 1] === "\\" ||
                                               stream[current + 1] === "'")) {
                  current += 2;
              } else {
                  current++;
              }
              this._current = current;
          }
          this._current++;
          var literal = stream.slice(start + 1, this._current - 1);
          return literal.replace("\\'", "'");
      },

      _consumeNumber: function(stream) {
          var start = this._current;
          this._current++;
          var maxLength = stream.length;
          while (isNum(stream[this._current]) && this._current < maxLength) {
              this._current++;
          }
          var value = parseInt(stream.slice(start, this._current));
          return {type: TOK_NUMBER, value: value, start: start};
      },

      _consumeLBracket: function(stream) {
          var start = this._current;
          this._current++;
          if (stream[this._current] === "?") {
              this._current++;
              return {type: TOK_FILTER, value: "[?", start: start};
          } else if (stream[this._current] === "]") {
              this._current++;
              return {type: TOK_FLATTEN, value: "[]", start: start};
          } else {
              return {type: TOK_LBRACKET, value: "[", start: start};
          }
      },

      _consumeOperator: function(stream) {
          var start = this._current;
          var startingChar = stream[start];
          this._current++;
          if (startingChar === "!") {
              if (stream[this._current] === "=") {
                  this._current++;
                  return {type: TOK_NE, value: "!=", start: start};
              } else {
                return {type: TOK_NOT, value: "!", start: start};
              }
          } else if (startingChar === "<") {
              if (stream[this._current] === "=") {
                  this._current++;
                  return {type: TOK_LTE, value: "<=", start: start};
              } else {
                  return {type: TOK_LT, value: "<", start: start};
              }
          } else if (startingChar === ">") {
              if (stream[this._current] === "=") {
                  this._current++;
                  return {type: TOK_GTE, value: ">=", start: start};
              } else {
                  return {type: TOK_GT, value: ">", start: start};
              }
          } else if (startingChar === "=") {
              if (stream[this._current] === "=") {
                  this._current++;
                  return {type: TOK_EQ, value: "==", start: start};
              }
          }
      },

      _consumeLiteral: function(stream) {
          this._current++;
          var start = this._current;
          var maxLength = stream.length;
          var literal;
          while(stream[this._current] !== "`" && this._current < maxLength) {
              // You can escape a literal char or you can escape the escape.
              var current = this._current;
              if (stream[current] === "\\" && (stream[current + 1] === "\\" ||
                                               stream[current + 1] === "`")) {
                  current += 2;
              } else {
                  current++;
              }
              this._current = current;
          }
          var literalString = trimLeft(stream.slice(start, this._current));
          literalString = literalString.replace("\\`", "`");
          if (this._looksLikeJSON(literalString)) {
              literal = JSON.parse(literalString);
          } else {
              // Try to JSON parse it as "<literal>"
              literal = JSON.parse("\"" + literalString + "\"");
          }
          // +1 gets us to the ending "`", +1 to move on to the next char.
          this._current++;
          return literal;
      },

      _looksLikeJSON: function(literalString) {
          var startingChars = "[{\"";
          var jsonLiterals = ["true", "false", "null"];
          var numberLooking = "-0123456789";

          if (literalString === "") {
              return false;
          } else if (startingChars.indexOf(literalString[0]) >= 0) {
              return true;
          } else if (jsonLiterals.indexOf(literalString) >= 0) {
              return true;
          } else if (numberLooking.indexOf(literalString[0]) >= 0) {
              try {
                  JSON.parse(literalString);
                  return true;
              } catch (ex) {
                  return false;
              }
          } else {
              return false;
          }
      }
  };

      var bindingPower = {};
      bindingPower[TOK_EOF] = 0;
      bindingPower[TOK_UNQUOTEDIDENTIFIER] = 0;
      bindingPower[TOK_QUOTEDIDENTIFIER] = 0;
      bindingPower[TOK_RBRACKET] = 0;
      bindingPower[TOK_RPAREN] = 0;
      bindingPower[TOK_COMMA] = 0;
      bindingPower[TOK_RBRACE] = 0;
      bindingPower[TOK_NUMBER] = 0;
      bindingPower[TOK_CURRENT] = 0;
      bindingPower[TOK_EXPREF] = 0;
      bindingPower[TOK_PIPE] = 1;
      bindingPower[TOK_OR] = 2;
      bindingPower[TOK_AND] = 3;
      bindingPower[TOK_EQ] = 5;
      bindingPower[TOK_GT] = 5;
      bindingPower[TOK_LT] = 5;
      bindingPower[TOK_GTE] = 5;
      bindingPower[TOK_LTE] = 5;
      bindingPower[TOK_NE] = 5;
      bindingPower[TOK_FLATTEN] = 9;
      bindingPower[TOK_STAR] = 20;
      bindingPower[TOK_FILTER] = 21;
      bindingPower[TOK_DOT] = 40;
      bindingPower[TOK_NOT] = 45;
      bindingPower[TOK_LBRACE] = 50;
      bindingPower[TOK_LBRACKET] = 55;
      bindingPower[TOK_LPAREN] = 60;

  function Parser() {
  }

  Parser.prototype = {
      parse: function(expression) {
          this._loadTokens(expression);
          this.index = 0;
          var ast = this.expression(0);
          if (this._lookahead(0) !== TOK_EOF) {
              var t = this._lookaheadToken(0);
              var error = new Error(
                  "Unexpected token type: " + t.type + ", value: " + t.value);
              error.name = "ParserError";
              throw error;
          }
          return ast;
      },

      _loadTokens: function(expression) {
          var lexer = new Lexer();
          var tokens = lexer.tokenize(expression);
          tokens.push({type: TOK_EOF, value: "", start: expression.length});
          this.tokens = tokens;
      },

      expression: function(rbp) {
          var leftToken = this._lookaheadToken(0);
          this._advance();
          var left = this.nud(leftToken);
          var currentToken = this._lookahead(0);
          while (rbp < bindingPower[currentToken]) {
              this._advance();
              left = this.led(currentToken, left);
              currentToken = this._lookahead(0);
          }
          return left;
      },

      _lookahead: function(number) {
          return this.tokens[this.index + number].type;
      },

      _lookaheadToken: function(number) {
          return this.tokens[this.index + number];
      },

      _advance: function() {
          this.index++;
      },

      nud: function(token) {
        var left;
        var right;
        var expression;
        switch (token.type) {
          case TOK_LITERAL:
            return {type: "Literal", value: token.value};
          case TOK_UNQUOTEDIDENTIFIER:
            return {type: "Field", name: token.value};
          case TOK_QUOTEDIDENTIFIER:
            var node = {type: "Field", name: token.value};
            if (this._lookahead(0) === TOK_LPAREN) {
                throw new Error("Quoted identifier not allowed for function names.");
            } else {
                return node;
            }
            break;
          case TOK_NOT:
            right = this.expression(bindingPower.Not);
            return {type: "NotExpression", children: [right]};
          case TOK_STAR:
            left = {type: "Identity"};
            right = null;
            if (this._lookahead(0) === TOK_RBRACKET) {
                // This can happen in a multiselect,
                // [a, b, *]
                right = {type: "Identity"};
            } else {
                right = this._parseProjectionRHS(bindingPower.Star);
            }
            return {type: "ValueProjection", children: [left, right]};
          case TOK_FILTER:
            return this.led(token.type, {type: "Identity"});
          case TOK_LBRACE:
            return this._parseMultiselectHash();
          case TOK_FLATTEN:
            left = {type: TOK_FLATTEN, children: [{type: "Identity"}]};
            right = this._parseProjectionRHS(bindingPower.Flatten);
            return {type: "Projection", children: [left, right]};
          case TOK_LBRACKET:
            if (this._lookahead(0) === TOK_NUMBER || this._lookahead(0) === TOK_COLON) {
                right = this._parseIndexExpression();
                return this._projectIfSlice({type: "Identity"}, right);
            } else if (this._lookahead(0) === TOK_STAR &&
                       this._lookahead(1) === TOK_RBRACKET) {
                this._advance();
                this._advance();
                right = this._parseProjectionRHS(bindingPower.Star);
                return {type: "Projection",
                        children: [{type: "Identity"}, right]};
            } else {
                return this._parseMultiselectList();
            }
            break;
          case TOK_CURRENT:
            return {type: TOK_CURRENT};
          case TOK_EXPREF:
            expression = this.expression(bindingPower.Expref);
            return {type: "ExpressionReference", children: [expression]};
          case TOK_LPAREN:
            var args = [];
            while (this._lookahead(0) !== TOK_RPAREN) {
              if (this._lookahead(0) === TOK_CURRENT) {
                expression = {type: TOK_CURRENT};
                this._advance();
              } else {
                expression = this.expression(0);
              }
              args.push(expression);
            }
            this._match(TOK_RPAREN);
            return args[0];
          default:
            this._errorToken(token);
        }
      },

      led: function(tokenName, left) {
        var right;
        switch(tokenName) {
          case TOK_DOT:
            var rbp = bindingPower.Dot;
            if (this._lookahead(0) !== TOK_STAR) {
                right = this._parseDotRHS(rbp);
                return {type: "Subexpression", children: [left, right]};
            } else {
                // Creating a projection.
                this._advance();
                right = this._parseProjectionRHS(rbp);
                return {type: "ValueProjection", children: [left, right]};
            }
            break;
          case TOK_PIPE:
            right = this.expression(bindingPower.Pipe);
            return {type: TOK_PIPE, children: [left, right]};
          case TOK_OR:
            right = this.expression(bindingPower.Or);
            return {type: "OrExpression", children: [left, right]};
          case TOK_AND:
            right = this.expression(bindingPower.And);
            return {type: "AndExpression", children: [left, right]};
          case TOK_LPAREN:
            var name = left.name;
            var args = [];
            var expression, node;
            while (this._lookahead(0) !== TOK_RPAREN) {
              if (this._lookahead(0) === TOK_CURRENT) {
                expression = {type: TOK_CURRENT};
                this._advance();
              } else {
                expression = this.expression(0);
              }
              if (this._lookahead(0) === TOK_COMMA) {
                this._match(TOK_COMMA);
              }
              args.push(expression);
            }
            this._match(TOK_RPAREN);
            node = {type: "Function", name: name, children: args};
            return node;
          case TOK_FILTER:
            var condition = this.expression(0);
            this._match(TOK_RBRACKET);
            if (this._lookahead(0) === TOK_FLATTEN) {
              right = {type: "Identity"};
            } else {
              right = this._parseProjectionRHS(bindingPower.Filter);
            }
            return {type: "FilterProjection", children: [left, right, condition]};
          case TOK_FLATTEN:
            var leftNode = {type: TOK_FLATTEN, children: [left]};
            var rightNode = this._parseProjectionRHS(bindingPower.Flatten);
            return {type: "Projection", children: [leftNode, rightNode]};
          case TOK_EQ:
          case TOK_NE:
          case TOK_GT:
          case TOK_GTE:
          case TOK_LT:
          case TOK_LTE:
            return this._parseComparator(left, tokenName);
          case TOK_LBRACKET:
            var token = this._lookaheadToken(0);
            if (token.type === TOK_NUMBER || token.type === TOK_COLON) {
                right = this._parseIndexExpression();
                return this._projectIfSlice(left, right);
            } else {
                this._match(TOK_STAR);
                this._match(TOK_RBRACKET);
                right = this._parseProjectionRHS(bindingPower.Star);
                return {type: "Projection", children: [left, right]};
            }
            break;
          default:
            this._errorToken(this._lookaheadToken(0));
        }
      },

      _match: function(tokenType) {
          if (this._lookahead(0) === tokenType) {
              this._advance();
          } else {
              var t = this._lookaheadToken(0);
              var error = new Error("Expected " + tokenType + ", got: " + t.type);
              error.name = "ParserError";
              throw error;
          }
      },

      _errorToken: function(token) {
          var error = new Error("Invalid token (" +
                                token.type + "): \"" +
                                token.value + "\"");
          error.name = "ParserError";
          throw error;
      },


      _parseIndexExpression: function() {
          if (this._lookahead(0) === TOK_COLON || this._lookahead(1) === TOK_COLON) {
              return this._parseSliceExpression();
          } else {
              var node = {
                  type: "Index",
                  value: this._lookaheadToken(0).value};
              this._advance();
              this._match(TOK_RBRACKET);
              return node;
          }
      },

      _projectIfSlice: function(left, right) {
          var indexExpr = {type: "IndexExpression", children: [left, right]};
          if (right.type === "Slice") {
              return {
                  type: "Projection",
                  children: [indexExpr, this._parseProjectionRHS(bindingPower.Star)]
              };
          } else {
              return indexExpr;
          }
      },

      _parseSliceExpression: function() {
          // [start:end:step] where each part is optional, as well as the last
          // colon.
          var parts = [null, null, null];
          var index = 0;
          var currentToken = this._lookahead(0);
          while (currentToken !== TOK_RBRACKET && index < 3) {
              if (currentToken === TOK_COLON) {
                  index++;
                  this._advance();
              } else if (currentToken === TOK_NUMBER) {
                  parts[index] = this._lookaheadToken(0).value;
                  this._advance();
              } else {
                  var t = this._lookahead(0);
                  var error = new Error("Syntax error, unexpected token: " +
                                        t.value + "(" + t.type + ")");
                  error.name = "Parsererror";
                  throw error;
              }
              currentToken = this._lookahead(0);
          }
          this._match(TOK_RBRACKET);
          return {
              type: "Slice",
              children: parts
          };
      },

      _parseComparator: function(left, comparator) {
        var right = this.expression(bindingPower[comparator]);
        return {type: "Comparator", name: comparator, children: [left, right]};
      },

      _parseDotRHS: function(rbp) {
          var lookahead = this._lookahead(0);
          var exprTokens = [TOK_UNQUOTEDIDENTIFIER, TOK_QUOTEDIDENTIFIER, TOK_STAR];
          if (exprTokens.indexOf(lookahead) >= 0) {
              return this.expression(rbp);
          } else if (lookahead === TOK_LBRACKET) {
              this._match(TOK_LBRACKET);
              return this._parseMultiselectList();
          } else if (lookahead === TOK_LBRACE) {
              this._match(TOK_LBRACE);
              return this._parseMultiselectHash();
          }
      },

      _parseProjectionRHS: function(rbp) {
          var right;
          if (bindingPower[this._lookahead(0)] < 10) {
              right = {type: "Identity"};
          } else if (this._lookahead(0) === TOK_LBRACKET) {
              right = this.expression(rbp);
          } else if (this._lookahead(0) === TOK_FILTER) {
              right = this.expression(rbp);
          } else if (this._lookahead(0) === TOK_DOT) {
              this._match(TOK_DOT);
              right = this._parseDotRHS(rbp);
          } else {
              var t = this._lookaheadToken(0);
              var error = new Error("Sytanx error, unexpected token: " +
                                    t.value + "(" + t.type + ")");
              error.name = "ParserError";
              throw error;
          }
          return right;
      },

      _parseMultiselectList: function() {
          var expressions = [];
          while (this._lookahead(0) !== TOK_RBRACKET) {
              var expression = this.expression(0);
              expressions.push(expression);
              if (this._lookahead(0) === TOK_COMMA) {
                  this._match(TOK_COMMA);
                  if (this._lookahead(0) === TOK_RBRACKET) {
                    throw new Error("Unexpected token Rbracket");
                  }
              }
          }
          this._match(TOK_RBRACKET);
          return {type: "MultiSelectList", children: expressions};
      },

      _parseMultiselectHash: function() {
        var pairs = [];
        var identifierTypes = [TOK_UNQUOTEDIDENTIFIER, TOK_QUOTEDIDENTIFIER];
        var keyToken, keyName, value, node;
        for (;;) {
          keyToken = this._lookaheadToken(0);
          if (identifierTypes.indexOf(keyToken.type) < 0) {
            throw new Error("Expecting an identifier token, got: " +
                            keyToken.type);
          }
          keyName = keyToken.value;
          this._advance();
          this._match(TOK_COLON);
          value = this.expression(0);
          node = {type: "KeyValuePair", name: keyName, value: value};
          pairs.push(node);
          if (this._lookahead(0) === TOK_COMMA) {
            this._match(TOK_COMMA);
          } else if (this._lookahead(0) === TOK_RBRACE) {
            this._match(TOK_RBRACE);
            break;
          }
        }
        return {type: "MultiSelectHash", children: pairs};
      }
  };


  function TreeInterpreter(runtime) {
    this.runtime = runtime;
  }

  TreeInterpreter.prototype = {
      search: function(node, value) {
          return this.visit(node, value);
      },

      visit: function(node, value) {
          var matched, current, result, first, second, field, left, right, collected, i;
          switch (node.type) {
            case "Field":
              if (value === null ) {
                  return null;
              } else if (isObject(value)) {
                  field = value[node.name];
                  if (field === undefined) {
                      return null;
                  } else {
                      return field;
                  }
              } else {
                return null;
              }
              break;
            case "Subexpression":
              result = this.visit(node.children[0], value);
              for (i = 1; i < node.children.length; i++) {
                  result = this.visit(node.children[1], result);
                  if (result === null) {
                      return null;
                  }
              }
              return result;
            case "IndexExpression":
              left = this.visit(node.children[0], value);
              right = this.visit(node.children[1], left);
              return right;
            case "Index":
              if (!isArray(value)) {
                return null;
              }
              var index = node.value;
              if (index < 0) {
                index = value.length + index;
              }
              result = value[index];
              if (result === undefined) {
                result = null;
              }
              return result;
            case "Slice":
              if (!isArray(value)) {
                return null;
              }
              var sliceParams = node.children.slice(0);
              var computed = this.computeSliceParams(value.length, sliceParams);
              var start = computed[0];
              var stop = computed[1];
              var step = computed[2];
              result = [];
              if (step > 0) {
                  for (i = start; i < stop; i += step) {
                      result.push(value[i]);
                  }
              } else {
                  for (i = start; i > stop; i += step) {
                      result.push(value[i]);
                  }
              }
              return result;
            case "Projection":
              // Evaluate left child.
              var base = this.visit(node.children[0], value);
              if (!isArray(base)) {
                return null;
              }
              collected = [];
              for (i = 0; i < base.length; i++) {
                current = this.visit(node.children[1], base[i]);
                if (current !== null) {
                  collected.push(current);
                }
              }
              return collected;
            case "ValueProjection":
              // Evaluate left child.
              base = this.visit(node.children[0], value);
              if (!isObject(base)) {
                return null;
              }
              collected = [];
              var values = objValues(base);
              for (i = 0; i < values.length; i++) {
                current = this.visit(node.children[1], values[i]);
                if (current !== null) {
                  collected.push(current);
                }
              }
              return collected;
            case "FilterProjection":
              base = this.visit(node.children[0], value);
              if (!isArray(base)) {
                return null;
              }
              var filtered = [];
              var finalResults = [];
              for (i = 0; i < base.length; i++) {
                matched = this.visit(node.children[2], base[i]);
                if (!isFalse(matched)) {
                  filtered.push(base[i]);
                }
              }
              for (var j = 0; j < filtered.length; j++) {
                current = this.visit(node.children[1], filtered[j]);
                if (current !== null) {
                  finalResults.push(current);
                }
              }
              return finalResults;
            case "Comparator":
              first = this.visit(node.children[0], value);
              second = this.visit(node.children[1], value);
              switch(node.name) {
                case TOK_EQ:
                  result = strictDeepEqual(first, second);
                  break;
                case TOK_NE:
                  result = !strictDeepEqual(first, second);
                  break;
                case TOK_GT:
                  result = first > second;
                  break;
                case TOK_GTE:
                  result = first >= second;
                  break;
                case TOK_LT:
                  result = first < second;
                  break;
                case TOK_LTE:
                  result = first <= second;
                  break;
                default:
                  throw new Error("Unknown comparator: " + node.name);
              }
              return result;
            case TOK_FLATTEN:
              var original = this.visit(node.children[0], value);
              if (!isArray(original)) {
                return null;
              }
              var merged = [];
              for (i = 0; i < original.length; i++) {
                current = original[i];
                if (isArray(current)) {
                  merged.push.apply(merged, current);
                } else {
                  merged.push(current);
                }
              }
              return merged;
            case "Identity":
              return value;
            case "MultiSelectList":
              if (value === null) {
                return null;
              }
              collected = [];
              for (i = 0; i < node.children.length; i++) {
                  collected.push(this.visit(node.children[i], value));
              }
              return collected;
            case "MultiSelectHash":
              if (value === null) {
                return null;
              }
              collected = {};
              var child;
              for (i = 0; i < node.children.length; i++) {
                child = node.children[i];
                collected[child.name] = this.visit(child.value, value);
              }
              return collected;
            case "OrExpression":
              matched = this.visit(node.children[0], value);
              if (isFalse(matched)) {
                  matched = this.visit(node.children[1], value);
              }
              return matched;
            case "AndExpression":
              first = this.visit(node.children[0], value);

              if (isFalse(first) === true) {
                return first;
              }
              return this.visit(node.children[1], value);
            case "NotExpression":
              first = this.visit(node.children[0], value);
              return isFalse(first);
            case "Literal":
              return node.value;
            case TOK_PIPE:
              left = this.visit(node.children[0], value);
              return this.visit(node.children[1], left);
            case TOK_CURRENT:
              return value;
            case "Function":
              var resolvedArgs = [];
              for (i = 0; i < node.children.length; i++) {
                  resolvedArgs.push(this.visit(node.children[i], value));
              }
              return this.runtime.callFunction(node.name, resolvedArgs);
            case "ExpressionReference":
              var refNode = node.children[0];
              // Tag the node with a specific attribute so the type
              // checker verify the type.
              refNode.jmespathType = TOK_EXPREF;
              return refNode;
            default:
              throw new Error("Unknown node type: " + node.type);
          }
      },

      computeSliceParams: function(arrayLength, sliceParams) {
        var start = sliceParams[0];
        var stop = sliceParams[1];
        var step = sliceParams[2];
        var computed = [null, null, null];
        if (step === null) {
          step = 1;
        } else if (step === 0) {
          var error = new Error("Invalid slice, step cannot be 0");
          error.name = "RuntimeError";
          throw error;
        }
        var stepValueNegative = step < 0 ? true : false;

        if (start === null) {
            start = stepValueNegative ? arrayLength - 1 : 0;
        } else {
            start = this.capSliceRange(arrayLength, start, step);
        }

        if (stop === null) {
            stop = stepValueNegative ? -1 : arrayLength;
        } else {
            stop = this.capSliceRange(arrayLength, stop, step);
        }
        computed[0] = start;
        computed[1] = stop;
        computed[2] = step;
        return computed;
      },

      capSliceRange: function(arrayLength, actualValue, step) {
          if (actualValue < 0) {
              actualValue += arrayLength;
              if (actualValue < 0) {
                  actualValue = step < 0 ? -1 : 0;
              }
          } else if (actualValue >= arrayLength) {
              actualValue = step < 0 ? arrayLength - 1 : arrayLength;
          }
          return actualValue;
      }

  };

  function Runtime(interpreter) {
    this._interpreter = interpreter;
    this.functionTable = {
        // name: [function, <signature>]
        // The <signature> can be:
        //
        // {
        //   args: [[type1, type2], [type1, type2]],
        //   variadic: true|false
        // }
        //
        // Each arg in the arg list is a list of valid types
        // (if the function is overloaded and supports multiple
        // types.  If the type is "any" then no type checking
        // occurs on the argument.  Variadic is optional
        // and if not provided is assumed to be false.
        abs: {_func: this._functionAbs, _signature: [{types: [TYPE_NUMBER]}]},
        avg: {_func: this._functionAvg, _signature: [{types: [TYPE_ARRAY_NUMBER]}]},
        ceil: {_func: this._functionCeil, _signature: [{types: [TYPE_NUMBER]}]},
        contains: {
            _func: this._functionContains,
            _signature: [{types: [TYPE_STRING, TYPE_ARRAY]},
                        {types: [TYPE_ANY]}]},
        "ends_with": {
            _func: this._functionEndsWith,
            _signature: [{types: [TYPE_STRING]}, {types: [TYPE_STRING]}]},
        floor: {_func: this._functionFloor, _signature: [{types: [TYPE_NUMBER]}]},
        length: {
            _func: this._functionLength,
            _signature: [{types: [TYPE_STRING, TYPE_ARRAY, TYPE_OBJECT]}]},
        map: {
            _func: this._functionMap,
            _signature: [{types: [TYPE_EXPREF]}, {types: [TYPE_ARRAY]}]},
        max: {
            _func: this._functionMax,
            _signature: [{types: [TYPE_ARRAY_NUMBER, TYPE_ARRAY_STRING]}]},
        "merge": {
            _func: this._functionMerge,
            _signature: [{types: [TYPE_OBJECT], variadic: true}]
        },
        "max_by": {
          _func: this._functionMaxBy,
          _signature: [{types: [TYPE_ARRAY]}, {types: [TYPE_EXPREF]}]
        },
        sum: {_func: this._functionSum, _signature: [{types: [TYPE_ARRAY_NUMBER]}]},
        "starts_with": {
            _func: this._functionStartsWith,
            _signature: [{types: [TYPE_STRING]}, {types: [TYPE_STRING]}]},
        min: {
            _func: this._functionMin,
            _signature: [{types: [TYPE_ARRAY_NUMBER, TYPE_ARRAY_STRING]}]},
        "min_by": {
          _func: this._functionMinBy,
          _signature: [{types: [TYPE_ARRAY]}, {types: [TYPE_EXPREF]}]
        },
        type: {_func: this._functionType, _signature: [{types: [TYPE_ANY]}]},
        keys: {_func: this._functionKeys, _signature: [{types: [TYPE_OBJECT]}]},
        values: {_func: this._functionValues, _signature: [{types: [TYPE_OBJECT]}]},
        sort: {_func: this._functionSort, _signature: [{types: [TYPE_ARRAY_STRING, TYPE_ARRAY_NUMBER]}]},
        "sort_by": {
          _func: this._functionSortBy,
          _signature: [{types: [TYPE_ARRAY]}, {types: [TYPE_EXPREF]}]
        },
        join: {
            _func: this._functionJoin,
            _signature: [
                {types: [TYPE_STRING]},
                {types: [TYPE_ARRAY_STRING]}
            ]
        },
        reverse: {
            _func: this._functionReverse,
            _signature: [{types: [TYPE_STRING, TYPE_ARRAY]}]},
        "to_array": {_func: this._functionToArray, _signature: [{types: [TYPE_ANY]}]},
        "to_string": {_func: this._functionToString, _signature: [{types: [TYPE_ANY]}]},
        "to_number": {_func: this._functionToNumber, _signature: [{types: [TYPE_ANY]}]},
        "not_null": {
            _func: this._functionNotNull,
            _signature: [{types: [TYPE_ANY], variadic: true}]
        }
    };
  }

  Runtime.prototype = {
    callFunction: function(name, resolvedArgs) {
      var functionEntry = this.functionTable[name];
      if (functionEntry === undefined) {
          throw new Error("Unknown function: " + name + "()");
      }
      this._validateArgs(name, resolvedArgs, functionEntry._signature);
      return functionEntry._func.call(this, resolvedArgs);
    },

    _validateArgs: function(name, args, signature) {
        // Validating the args requires validating
        // the correct arity and the correct type of each arg.
        // If the last argument is declared as variadic, then we need
        // a minimum number of args to be required.  Otherwise it has to
        // be an exact amount.
        var pluralized;
        if (signature[signature.length - 1].variadic) {
            if (args.length < signature.length) {
                pluralized = signature.length === 1 ? " argument" : " arguments";
                throw new Error("ArgumentError: " + name + "() " +
                                "takes at least" + signature.length + pluralized +
                                " but received " + args.length);
            }
        } else if (args.length !== signature.length) {
            pluralized = signature.length === 1 ? " argument" : " arguments";
            throw new Error("ArgumentError: " + name + "() " +
                            "takes " + signature.length + pluralized +
                            " but received " + args.length);
        }
        var currentSpec;
        var actualType;
        var typeMatched;
        for (var i = 0; i < signature.length; i++) {
            typeMatched = false;
            currentSpec = signature[i].types;
            actualType = this._getTypeName(args[i]);
            for (var j = 0; j < currentSpec.length; j++) {
                if (this._typeMatches(actualType, currentSpec[j], args[i])) {
                    typeMatched = true;
                    break;
                }
            }
            if (!typeMatched) {
                throw new Error("TypeError: " + name + "() " +
                                "expected argument " + (i + 1) +
                                " to be type " + currentSpec +
                                " but received type " + actualType +
                                " instead.");
            }
        }
    },

    _typeMatches: function(actual, expected, argValue) {
        if (expected === TYPE_ANY) {
            return true;
        }
        if (expected === TYPE_ARRAY_STRING ||
            expected === TYPE_ARRAY_NUMBER ||
            expected === TYPE_ARRAY) {
            // The expected type can either just be array,
            // or it can require a specific subtype (array of numbers).
            //
            // The simplest case is if "array" with no subtype is specified.
            if (expected === TYPE_ARRAY) {
                return actual === TYPE_ARRAY;
            } else if (actual === TYPE_ARRAY) {
                // Otherwise we need to check subtypes.
                // I think this has potential to be improved.
                var subtype;
                if (expected === TYPE_ARRAY_NUMBER) {
                  subtype = TYPE_NUMBER;
                } else if (expected === TYPE_ARRAY_STRING) {
                  subtype = TYPE_STRING;
                }
                for (var i = 0; i < argValue.length; i++) {
                    if (!this._typeMatches(
                            this._getTypeName(argValue[i]), subtype,
                                             argValue[i])) {
                        return false;
                    }
                }
                return true;
            }
        } else {
            return actual === expected;
        }
    },
    _getTypeName: function(obj) {
        switch (Object.prototype.toString.call(obj)) {
            case "[object String]":
              return TYPE_STRING;
            case "[object Number]":
              return TYPE_NUMBER;
            case "[object Array]":
              return TYPE_ARRAY;
            case "[object Boolean]":
              return TYPE_BOOLEAN;
            case "[object Null]":
              return TYPE_NULL;
            case "[object Object]":
              // Check if it's an expref.  If it has, it's been
              // tagged with a jmespathType attr of 'Expref';
              if (obj.jmespathType === TOK_EXPREF) {
                return TYPE_EXPREF;
              } else {
                return TYPE_OBJECT;
              }
        }
    },

    _functionStartsWith: function(resolvedArgs) {
        return resolvedArgs[0].lastIndexOf(resolvedArgs[1]) === 0;
    },

    _functionEndsWith: function(resolvedArgs) {
        var searchStr = resolvedArgs[0];
        var suffix = resolvedArgs[1];
        return searchStr.indexOf(suffix, searchStr.length - suffix.length) !== -1;
    },

    _functionReverse: function(resolvedArgs) {
        var typeName = this._getTypeName(resolvedArgs[0]);
        if (typeName === TYPE_STRING) {
          var originalStr = resolvedArgs[0];
          var reversedStr = "";
          for (var i = originalStr.length - 1; i >= 0; i--) {
              reversedStr += originalStr[i];
          }
          return reversedStr;
        } else {
          var reversedArray = resolvedArgs[0].slice(0);
          reversedArray.reverse();
          return reversedArray;
        }
    },

    _functionAbs: function(resolvedArgs) {
      return Math.abs(resolvedArgs[0]);
    },

    _functionCeil: function(resolvedArgs) {
        return Math.ceil(resolvedArgs[0]);
    },

    _functionAvg: function(resolvedArgs) {
        var sum = 0;
        var inputArray = resolvedArgs[0];
        for (var i = 0; i < inputArray.length; i++) {
            sum += inputArray[i];
        }
        return sum / inputArray.length;
    },

    _functionContains: function(resolvedArgs) {
        return resolvedArgs[0].indexOf(resolvedArgs[1]) >= 0;
    },

    _functionFloor: function(resolvedArgs) {
        return Math.floor(resolvedArgs[0]);
    },

    _functionLength: function(resolvedArgs) {
       if (!isObject(resolvedArgs[0])) {
         return resolvedArgs[0].length;
       } else {
         // As far as I can tell, there's no way to get the length
         // of an object without O(n) iteration through the object.
         return Object.keys(resolvedArgs[0]).length;
       }
    },

    _functionMap: function(resolvedArgs) {
      var mapped = [];
      var interpreter = this._interpreter;
      var exprefNode = resolvedArgs[0];
      var elements = resolvedArgs[1];
      for (var i = 0; i < elements.length; i++) {
          mapped.push(interpreter.visit(exprefNode, elements[i]));
      }
      return mapped;
    },

    _functionMerge: function(resolvedArgs) {
      var merged = {};
      for (var i = 0; i < resolvedArgs.length; i++) {
        var current = resolvedArgs[i];
        for (var key in current) {
          merged[key] = current[key];
        }
      }
      return merged;
    },

    _functionMax: function(resolvedArgs) {
      if (resolvedArgs[0].length > 0) {
        var typeName = this._getTypeName(resolvedArgs[0][0]);
        if (typeName === TYPE_NUMBER) {
          return Math.max.apply(Math, resolvedArgs[0]);
        } else {
          var elements = resolvedArgs[0];
          var maxElement = elements[0];
          for (var i = 1; i < elements.length; i++) {
              if (maxElement.localeCompare(elements[i]) < 0) {
                  maxElement = elements[i];
              }
          }
          return maxElement;
        }
      } else {
          return null;
      }
    },

    _functionMin: function(resolvedArgs) {
      if (resolvedArgs[0].length > 0) {
        var typeName = this._getTypeName(resolvedArgs[0][0]);
        if (typeName === TYPE_NUMBER) {
          return Math.min.apply(Math, resolvedArgs[0]);
        } else {
          var elements = resolvedArgs[0];
          var minElement = elements[0];
          for (var i = 1; i < elements.length; i++) {
              if (elements[i].localeCompare(minElement) < 0) {
                  minElement = elements[i];
              }
          }
          return minElement;
        }
      } else {
        return null;
      }
    },

    _functionSum: function(resolvedArgs) {
      var sum = 0;
      var listToSum = resolvedArgs[0];
      for (var i = 0; i < listToSum.length; i++) {
        sum += listToSum[i];
      }
      return sum;
    },

    _functionType: function(resolvedArgs) {
        switch (this._getTypeName(resolvedArgs[0])) {
          case TYPE_NUMBER:
            return "number";
          case TYPE_STRING:
            return "string";
          case TYPE_ARRAY:
            return "array";
          case TYPE_OBJECT:
            return "object";
          case TYPE_BOOLEAN:
            return "boolean";
          case TYPE_EXPREF:
            return "expref";
          case TYPE_NULL:
            return "null";
        }
    },

    _functionKeys: function(resolvedArgs) {
        return Object.keys(resolvedArgs[0]);
    },

    _functionValues: function(resolvedArgs) {
        var obj = resolvedArgs[0];
        var keys = Object.keys(obj);
        var values = [];
        for (var i = 0; i < keys.length; i++) {
            values.push(obj[keys[i]]);
        }
        return values;
    },

    _functionJoin: function(resolvedArgs) {
        var joinChar = resolvedArgs[0];
        var listJoin = resolvedArgs[1];
        return listJoin.join(joinChar);
    },

    _functionToArray: function(resolvedArgs) {
        if (this._getTypeName(resolvedArgs[0]) === TYPE_ARRAY) {
            return resolvedArgs[0];
        } else {
            return [resolvedArgs[0]];
        }
    },

    _functionToString: function(resolvedArgs) {
        if (this._getTypeName(resolvedArgs[0]) === TYPE_STRING) {
            return resolvedArgs[0];
        } else {
            return JSON.stringify(resolvedArgs[0]);
        }
    },

    _functionToNumber: function(resolvedArgs) {
        var typeName = this._getTypeName(resolvedArgs[0]);
        var convertedValue;
        if (typeName === TYPE_NUMBER) {
            return resolvedArgs[0];
        } else if (typeName === TYPE_STRING) {
            convertedValue = +resolvedArgs[0];
            if (!isNaN(convertedValue)) {
                return convertedValue;
            }
        }
        return null;
    },

    _functionNotNull: function(resolvedArgs) {
        for (var i = 0; i < resolvedArgs.length; i++) {
            if (this._getTypeName(resolvedArgs[i]) !== TYPE_NULL) {
                return resolvedArgs[i];
            }
        }
        return null;
    },

    _functionSort: function(resolvedArgs) {
        var sortedArray = resolvedArgs[0].slice(0);
        sortedArray.sort();
        return sortedArray;
    },

    _functionSortBy: function(resolvedArgs) {
        var sortedArray = resolvedArgs[0].slice(0);
        if (sortedArray.length === 0) {
            return sortedArray;
        }
        var interpreter = this._interpreter;
        var exprefNode = resolvedArgs[1];
        var requiredType = this._getTypeName(
            interpreter.visit(exprefNode, sortedArray[0]));
        if ([TYPE_NUMBER, TYPE_STRING].indexOf(requiredType) < 0) {
            throw new Error("TypeError");
        }
        var that = this;
        // In order to get a stable sort out of an unstable
        // sort algorithm, we decorate/sort/undecorate (DSU)
        // by creating a new list of [index, element] pairs.
        // In the cmp function, if the evaluated elements are
        // equal, then the index will be used as the tiebreaker.
        // After the decorated list has been sorted, it will be
        // undecorated to extract the original elements.
        var decorated = [];
        for (var i = 0; i < sortedArray.length; i++) {
          decorated.push([i, sortedArray[i]]);
        }
        decorated.sort(function(a, b) {
          var exprA = interpreter.visit(exprefNode, a[1]);
          var exprB = interpreter.visit(exprefNode, b[1]);
          if (that._getTypeName(exprA) !== requiredType) {
              throw new Error(
                  "TypeError: expected " + requiredType + ", received " +
                  that._getTypeName(exprA));
          } else if (that._getTypeName(exprB) !== requiredType) {
              throw new Error(
                  "TypeError: expected " + requiredType + ", received " +
                  that._getTypeName(exprB));
          }
          if (exprA > exprB) {
            return 1;
          } else if (exprA < exprB) {
            return -1;
          } else {
            // If they're equal compare the items by their
            // order to maintain relative order of equal keys
            // (i.e. to get a stable sort).
            return a[0] - b[0];
          }
        });
        // Undecorate: extract out the original list elements.
        for (var j = 0; j < decorated.length; j++) {
          sortedArray[j] = decorated[j][1];
        }
        return sortedArray;
    },

    _functionMaxBy: function(resolvedArgs) {
      var exprefNode = resolvedArgs[1];
      var resolvedArray = resolvedArgs[0];
      var keyFunction = this.createKeyFunction(exprefNode, [TYPE_NUMBER, TYPE_STRING]);
      var maxNumber = -Infinity;
      var maxRecord;
      var current;
      for (var i = 0; i < resolvedArray.length; i++) {
        current = keyFunction(resolvedArray[i]);
        if (current > maxNumber) {
          maxNumber = current;
          maxRecord = resolvedArray[i];
        }
      }
      return maxRecord;
    },

    _functionMinBy: function(resolvedArgs) {
      var exprefNode = resolvedArgs[1];
      var resolvedArray = resolvedArgs[0];
      var keyFunction = this.createKeyFunction(exprefNode, [TYPE_NUMBER, TYPE_STRING]);
      var minNumber = Infinity;
      var minRecord;
      var current;
      for (var i = 0; i < resolvedArray.length; i++) {
        current = keyFunction(resolvedArray[i]);
        if (current < minNumber) {
          minNumber = current;
          minRecord = resolvedArray[i];
        }
      }
      return minRecord;
    },

    createKeyFunction: function(exprefNode, allowedTypes) {
      var that = this;
      var interpreter = this._interpreter;
      var keyFunc = function(x) {
        var current = interpreter.visit(exprefNode, x);
        if (allowedTypes.indexOf(that._getTypeName(current)) < 0) {
          var msg = "TypeError: expected one of " + allowedTypes +
                    ", received " + that._getTypeName(current);
          throw new Error(msg);
        }
        return current;
      };
      return keyFunc;
    }

  };

  function compile(stream) {
    var parser = new Parser();
    var ast = parser.parse(stream);
    return ast;
  }

  function tokenize(stream) {
      var lexer = new Lexer();
      return lexer.tokenize(stream);
  }

  function search(data, expression) {
      var parser = new Parser();
      // This needs to be improved.  Both the interpreter and runtime depend on
      // each other.  The runtime needs the interpreter to support exprefs.
      // There's likely a clean way to avoid the cyclic dependency.
      var runtime = new Runtime();
      var interpreter = new TreeInterpreter(runtime);
      runtime._interpreter = interpreter;
      var node = parser.parse(expression);
      return interpreter.search(node, data);
  }

  exports.tokenize = tokenize;
  exports.compile = compile;
  exports.search = search;
  exports.strictDeepEqual = strictDeepEqual;
})(typeof exports === "undefined" ? this.jmespath = {} : exports);

},{}],412:[function(require,module,exports){
// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

function defaultSetTimout() {
    throw new Error('setTimeout has not been defined');
}
function defaultClearTimeout () {
    throw new Error('clearTimeout has not been defined');
}
(function () {
    try {
        if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
        } else {
            cachedSetTimeout = defaultSetTimout;
        }
    } catch (e) {
        cachedSetTimeout = defaultSetTimout;
    }
    try {
        if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
        } else {
            cachedClearTimeout = defaultClearTimeout;
        }
    } catch (e) {
        cachedClearTimeout = defaultClearTimeout;
    }
} ())
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        //normal enviroments in sane situations
        return setTimeout(fun, 0);
    }
    // if setTimeout wasn't available but was latter defined
    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
        cachedSetTimeout = setTimeout;
        return setTimeout(fun, 0);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedSetTimeout(fun, 0);
    } catch(e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
        } catch(e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
        }
    }


}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        //normal enviroments in sane situations
        return clearTimeout(marker);
    }
    // if clearTimeout wasn't available but was latter defined
    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
        cachedClearTimeout = clearTimeout;
        return clearTimeout(marker);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedClearTimeout(marker);
    } catch (e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
        } catch (e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
        }
    }



}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while(len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;
process.prependListener = noop;
process.prependOnceListener = noop;

process.listeners = function (name) { return [] }

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function() { return 0; };

},{}],413:[function(require,module,exports){
(function (global){
/*! https://mths.be/punycode v1.3.2 by @mathias */
;(function(root) {

  /** Detect free variables */
  var freeExports = typeof exports == 'object' && exports &&
    !exports.nodeType && exports;
  var freeModule = typeof module == 'object' && module &&
    !module.nodeType && module;
  var freeGlobal = typeof global == 'object' && global;
  if (
    freeGlobal.global === freeGlobal ||
    freeGlobal.window === freeGlobal ||
    freeGlobal.self === freeGlobal
  ) {
    root = freeGlobal;
  }

  /**
   * The `punycode` object.
   * @name punycode
   * @type Object
   */
  var punycode,

  /** Highest positive signed 32-bit float value */
  maxInt = 2147483647, // aka. 0x7FFFFFFF or 2^31-1

  /** Bootstring parameters */
  base = 36,
  tMin = 1,
  tMax = 26,
  skew = 38,
  damp = 700,
  initialBias = 72,
  initialN = 128, // 0x80
  delimiter = '-', // '\x2D'

  /** Regular expressions */
  regexPunycode = /^xn--/,
  regexNonASCII = /[^\x20-\x7E]/, // unprintable ASCII chars + non-ASCII chars
  regexSeparators = /[\x2E\u3002\uFF0E\uFF61]/g, // RFC 3490 separators

  /** Error messages */
  errors = {
    'overflow': 'Overflow: input needs wider integers to process',
    'not-basic': 'Illegal input >= 0x80 (not a basic code point)',
    'invalid-input': 'Invalid input'
  },

  /** Convenience shortcuts */
  baseMinusTMin = base - tMin,
  floor = Math.floor,
  stringFromCharCode = String.fromCharCode,

  /** Temporary variable */
  key;

  /*--------------------------------------------------------------------------*/

  /**
   * A generic error utility function.
   * @private
   * @param {String} type The error type.
   * @returns {Error} Throws a `RangeError` with the applicable error message.
   */
  function error(type) {
    throw RangeError(errors[type]);
  }

  /**
   * A generic `Array#map` utility function.
   * @private
   * @param {Array} array The array to iterate over.
   * @param {Function} callback The function that gets called for every array
   * item.
   * @returns {Array} A new array of values returned by the callback function.
   */
  function map(array, fn) {
    var length = array.length;
    var result = [];
    while (length--) {
      result[length] = fn(array[length]);
    }
    return result;
  }

  /**
   * A simple `Array#map`-like wrapper to work with domain name strings or email
   * addresses.
   * @private
   * @param {String} domain The domain name or email address.
   * @param {Function} callback The function that gets called for every
   * character.
   * @returns {Array} A new string of characters returned by the callback
   * function.
   */
  function mapDomain(string, fn) {
    var parts = string.split('@');
    var result = '';
    if (parts.length > 1) {
      // In email addresses, only the domain name should be punycoded. Leave
      // the local part (i.e. everything up to `@`) intact.
      result = parts[0] + '@';
      string = parts[1];
    }
    // Avoid `split(regex)` for IE8 compatibility. See #17.
    string = string.replace(regexSeparators, '\x2E');
    var labels = string.split('.');
    var encoded = map(labels, fn).join('.');
    return result + encoded;
  }

  /**
   * Creates an array containing the numeric code points of each Unicode
   * character in the string. While JavaScript uses UCS-2 internally,
   * this function will convert a pair of surrogate halves (each of which
   * UCS-2 exposes as separate characters) into a single code point,
   * matching UTF-16.
   * @see `punycode.ucs2.encode`
   * @see <https://mathiasbynens.be/notes/javascript-encoding>
   * @memberOf punycode.ucs2
   * @name decode
   * @param {String} string The Unicode input string (UCS-2).
   * @returns {Array} The new array of code points.
   */
  function ucs2decode(string) {
    var output = [],
        counter = 0,
        length = string.length,
        value,
        extra;
    while (counter < length) {
      value = string.charCodeAt(counter++);
      if (value >= 0xD800 && value <= 0xDBFF && counter < length) {
        // high surrogate, and there is a next character
        extra = string.charCodeAt(counter++);
        if ((extra & 0xFC00) == 0xDC00) { // low surrogate
          output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
        } else {
          // unmatched surrogate; only append this code unit, in case the next
          // code unit is the high surrogate of a surrogate pair
          output.push(value);
          counter--;
        }
      } else {
        output.push(value);
      }
    }
    return output;
  }

  /**
   * Creates a string based on an array of numeric code points.
   * @see `punycode.ucs2.decode`
   * @memberOf punycode.ucs2
   * @name encode
   * @param {Array} codePoints The array of numeric code points.
   * @returns {String} The new Unicode string (UCS-2).
   */
  function ucs2encode(array) {
    return map(array, function(value) {
      var output = '';
      if (value > 0xFFFF) {
        value -= 0x10000;
        output += stringFromCharCode(value >>> 10 & 0x3FF | 0xD800);
        value = 0xDC00 | value & 0x3FF;
      }
      output += stringFromCharCode(value);
      return output;
    }).join('');
  }

  /**
   * Converts a basic code point into a digit/integer.
   * @see `digitToBasic()`
   * @private
   * @param {Number} codePoint The basic numeric code point value.
   * @returns {Number} The numeric value of a basic code point (for use in
   * representing integers) in the range `0` to `base - 1`, or `base` if
   * the code point does not represent a value.
   */
  function basicToDigit(codePoint) {
    if (codePoint - 48 < 10) {
      return codePoint - 22;
    }
    if (codePoint - 65 < 26) {
      return codePoint - 65;
    }
    if (codePoint - 97 < 26) {
      return codePoint - 97;
    }
    return base;
  }

  /**
   * Converts a digit/integer into a basic code point.
   * @see `basicToDigit()`
   * @private
   * @param {Number} digit The numeric value of a basic code point.
   * @returns {Number} The basic code point whose value (when used for
   * representing integers) is `digit`, which needs to be in the range
   * `0` to `base - 1`. If `flag` is non-zero, the uppercase form is
   * used; else, the lowercase form is used. The behavior is undefined
   * if `flag` is non-zero and `digit` has no uppercase form.
   */
  function digitToBasic(digit, flag) {
    //  0..25 map to ASCII a..z or A..Z
    // 26..35 map to ASCII 0..9
    return digit + 22 + 75 * (digit < 26) - ((flag != 0) << 5);
  }

  /**
   * Bias adaptation function as per section 3.4 of RFC 3492.
   * http://tools.ietf.org/html/rfc3492#section-3.4
   * @private
   */
  function adapt(delta, numPoints, firstTime) {
    var k = 0;
    delta = firstTime ? floor(delta / damp) : delta >> 1;
    delta += floor(delta / numPoints);
    for (/* no initialization */; delta > baseMinusTMin * tMax >> 1; k += base) {
      delta = floor(delta / baseMinusTMin);
    }
    return floor(k + (baseMinusTMin + 1) * delta / (delta + skew));
  }

  /**
   * Converts a Punycode string of ASCII-only symbols to a string of Unicode
   * symbols.
   * @memberOf punycode
   * @param {String} input The Punycode string of ASCII-only symbols.
   * @returns {String} The resulting string of Unicode symbols.
   */
  function decode(input) {
    // Don't use UCS-2
    var output = [],
        inputLength = input.length,
        out,
        i = 0,
        n = initialN,
        bias = initialBias,
        basic,
        j,
        index,
        oldi,
        w,
        k,
        digit,
        t,
        /** Cached calculation results */
        baseMinusT;

    // Handle the basic code points: let `basic` be the number of input code
    // points before the last delimiter, or `0` if there is none, then copy
    // the first basic code points to the output.

    basic = input.lastIndexOf(delimiter);
    if (basic < 0) {
      basic = 0;
    }

    for (j = 0; j < basic; ++j) {
      // if it's not a basic code point
      if (input.charCodeAt(j) >= 0x80) {
        error('not-basic');
      }
      output.push(input.charCodeAt(j));
    }

    // Main decoding loop: start just after the last delimiter if any basic code
    // points were copied; start at the beginning otherwise.

    for (index = basic > 0 ? basic + 1 : 0; index < inputLength; /* no final expression */) {

      // `index` is the index of the next character to be consumed.
      // Decode a generalized variable-length integer into `delta`,
      // which gets added to `i`. The overflow checking is easier
      // if we increase `i` as we go, then subtract off its starting
      // value at the end to obtain `delta`.
      for (oldi = i, w = 1, k = base; /* no condition */; k += base) {

        if (index >= inputLength) {
          error('invalid-input');
        }

        digit = basicToDigit(input.charCodeAt(index++));

        if (digit >= base || digit > floor((maxInt - i) / w)) {
          error('overflow');
        }

        i += digit * w;
        t = k <= bias ? tMin : (k >= bias + tMax ? tMax : k - bias);

        if (digit < t) {
          break;
        }

        baseMinusT = base - t;
        if (w > floor(maxInt / baseMinusT)) {
          error('overflow');
        }

        w *= baseMinusT;

      }

      out = output.length + 1;
      bias = adapt(i - oldi, out, oldi == 0);

      // `i` was supposed to wrap around from `out` to `0`,
      // incrementing `n` each time, so we'll fix that now:
      if (floor(i / out) > maxInt - n) {
        error('overflow');
      }

      n += floor(i / out);
      i %= out;

      // Insert `n` at position `i` of the output
      output.splice(i++, 0, n);

    }

    return ucs2encode(output);
  }

  /**
   * Converts a string of Unicode symbols (e.g. a domain name label) to a
   * Punycode string of ASCII-only symbols.
   * @memberOf punycode
   * @param {String} input The string of Unicode symbols.
   * @returns {String} The resulting Punycode string of ASCII-only symbols.
   */
  function encode(input) {
    var n,
        delta,
        handledCPCount,
        basicLength,
        bias,
        j,
        m,
        q,
        k,
        t,
        currentValue,
        output = [],
        /** `inputLength` will hold the number of code points in `input`. */
        inputLength,
        /** Cached calculation results */
        handledCPCountPlusOne,
        baseMinusT,
        qMinusT;

    // Convert the input in UCS-2 to Unicode
    input = ucs2decode(input);

    // Cache the length
    inputLength = input.length;

    // Initialize the state
    n = initialN;
    delta = 0;
    bias = initialBias;

    // Handle the basic code points
    for (j = 0; j < inputLength; ++j) {
      currentValue = input[j];
      if (currentValue < 0x80) {
        output.push(stringFromCharCode(currentValue));
      }
    }

    handledCPCount = basicLength = output.length;

    // `handledCPCount` is the number of code points that have been handled;
    // `basicLength` is the number of basic code points.

    // Finish the basic string - if it is not empty - with a delimiter
    if (basicLength) {
      output.push(delimiter);
    }

    // Main encoding loop:
    while (handledCPCount < inputLength) {

      // All non-basic code points < n have been handled already. Find the next
      // larger one:
      for (m = maxInt, j = 0; j < inputLength; ++j) {
        currentValue = input[j];
        if (currentValue >= n && currentValue < m) {
          m = currentValue;
        }
      }

      // Increase `delta` enough to advance the decoder's <n,i> state to <m,0>,
      // but guard against overflow
      handledCPCountPlusOne = handledCPCount + 1;
      if (m - n > floor((maxInt - delta) / handledCPCountPlusOne)) {
        error('overflow');
      }

      delta += (m - n) * handledCPCountPlusOne;
      n = m;

      for (j = 0; j < inputLength; ++j) {
        currentValue = input[j];

        if (currentValue < n && ++delta > maxInt) {
          error('overflow');
        }

        if (currentValue == n) {
          // Represent delta as a generalized variable-length integer
          for (q = delta, k = base; /* no condition */; k += base) {
            t = k <= bias ? tMin : (k >= bias + tMax ? tMax : k - bias);
            if (q < t) {
              break;
            }
            qMinusT = q - t;
            baseMinusT = base - t;
            output.push(
              stringFromCharCode(digitToBasic(t + qMinusT % baseMinusT, 0))
            );
            q = floor(qMinusT / baseMinusT);
          }

          output.push(stringFromCharCode(digitToBasic(q, 0)));
          bias = adapt(delta, handledCPCountPlusOne, handledCPCount == basicLength);
          delta = 0;
          ++handledCPCount;
        }
      }

      ++delta;
      ++n;

    }
    return output.join('');
  }

  /**
   * Converts a Punycode string representing a domain name or an email address
   * to Unicode. Only the Punycoded parts of the input will be converted, i.e.
   * it doesn't matter if you call it on a string that has already been
   * converted to Unicode.
   * @memberOf punycode
   * @param {String} input The Punycoded domain name or email address to
   * convert to Unicode.
   * @returns {String} The Unicode representation of the given Punycode
   * string.
   */
  function toUnicode(input) {
    return mapDomain(input, function(string) {
      return regexPunycode.test(string)
        ? decode(string.slice(4).toLowerCase())
        : string;
    });
  }

  /**
   * Converts a Unicode string representing a domain name or an email address to
   * Punycode. Only the non-ASCII parts of the domain name will be converted,
   * i.e. it doesn't matter if you call it with a domain that's already in
   * ASCII.
   * @memberOf punycode
   * @param {String} input The domain name or email address to convert, as a
   * Unicode string.
   * @returns {String} The Punycode representation of the given domain name or
   * email address.
   */
  function toASCII(input) {
    return mapDomain(input, function(string) {
      return regexNonASCII.test(string)
        ? 'xn--' + encode(string)
        : string;
    });
  }

  /*--------------------------------------------------------------------------*/

  /** Define the public API */
  punycode = {
    /**
     * A string representing the current Punycode.js version number.
     * @memberOf punycode
     * @type String
     */
    'version': '1.3.2',
    /**
     * An object of methods to convert from JavaScript's internal character
     * representation (UCS-2) to Unicode code points, and back.
     * @see <https://mathiasbynens.be/notes/javascript-encoding>
     * @memberOf punycode
     * @type Object
     */
    'ucs2': {
      'decode': ucs2decode,
      'encode': ucs2encode
    },
    'decode': decode,
    'encode': encode,
    'toASCII': toASCII,
    'toUnicode': toUnicode
  };

  /** Expose `punycode` */
  // Some AMD build optimizers, like r.js, check for specific condition patterns
  // like the following:
  if (
    typeof define == 'function' &&
    typeof define.amd == 'object' &&
    define.amd
  ) {
    define('punycode', function() {
      return punycode;
    });
  } else if (freeExports && freeModule) {
    if (module.exports == freeExports) { // in Node.js or RingoJS v0.8.0+
      freeModule.exports = punycode;
    } else { // in Narwhal or RingoJS v0.7.0-
      for (key in punycode) {
        punycode.hasOwnProperty(key) && (freeExports[key] = punycode[key]);
      }
    }
  } else { // in Rhino or a web browser
    root.punycode = punycode;
  }

}(this));

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],414:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

// If obj.hasOwnProperty has been overridden, then calling
// obj.hasOwnProperty(prop) will break.
// See: https://github.com/joyent/node/issues/1707
function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

module.exports = function(qs, sep, eq, options) {
  sep = sep || '&';
  eq = eq || '=';
  var obj = {};

  if (typeof qs !== 'string' || qs.length === 0) {
    return obj;
  }

  var regexp = /\+/g;
  qs = qs.split(sep);

  var maxKeys = 1000;
  if (options && typeof options.maxKeys === 'number') {
    maxKeys = options.maxKeys;
  }

  var len = qs.length;
  // maxKeys <= 0 means that we should not limit keys count
  if (maxKeys > 0 && len > maxKeys) {
    len = maxKeys;
  }

  for (var i = 0; i < len; ++i) {
    var x = qs[i].replace(regexp, '%20'),
        idx = x.indexOf(eq),
        kstr, vstr, k, v;

    if (idx >= 0) {
      kstr = x.substr(0, idx);
      vstr = x.substr(idx + 1);
    } else {
      kstr = x;
      vstr = '';
    }

    k = decodeURIComponent(kstr);
    v = decodeURIComponent(vstr);

    if (!hasOwnProperty(obj, k)) {
      obj[k] = v;
    } else if (isArray(obj[k])) {
      obj[k].push(v);
    } else {
      obj[k] = [obj[k], v];
    }
  }

  return obj;
};

var isArray = Array.isArray || function (xs) {
  return Object.prototype.toString.call(xs) === '[object Array]';
};

},{}],415:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

var stringifyPrimitive = function(v) {
  switch (typeof v) {
    case 'string':
      return v;

    case 'boolean':
      return v ? 'true' : 'false';

    case 'number':
      return isFinite(v) ? v : '';

    default:
      return '';
  }
};

module.exports = function(obj, sep, eq, name) {
  sep = sep || '&';
  eq = eq || '=';
  if (obj === null) {
    obj = undefined;
  }

  if (typeof obj === 'object') {
    return map(objectKeys(obj), function(k) {
      var ks = encodeURIComponent(stringifyPrimitive(k)) + eq;
      if (isArray(obj[k])) {
        return map(obj[k], function(v) {
          return ks + encodeURIComponent(stringifyPrimitive(v));
        }).join(sep);
      } else {
        return ks + encodeURIComponent(stringifyPrimitive(obj[k]));
      }
    }).join(sep);

  }

  if (!name) return '';
  return encodeURIComponent(stringifyPrimitive(name)) + eq +
         encodeURIComponent(stringifyPrimitive(obj));
};

var isArray = Array.isArray || function (xs) {
  return Object.prototype.toString.call(xs) === '[object Array]';
};

function map (xs, f) {
  if (xs.map) return xs.map(f);
  var res = [];
  for (var i = 0; i < xs.length; i++) {
    res.push(f(xs[i], i));
  }
  return res;
}

var objectKeys = Object.keys || function (obj) {
  var res = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) res.push(key);
  }
  return res;
};

},{}],416:[function(require,module,exports){
'use strict';

exports.decode = exports.parse = require('./decode');
exports.encode = exports.stringify = require('./encode');

},{"./decode":414,"./encode":415}],417:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

// If obj.hasOwnProperty has been overridden, then calling
// obj.hasOwnProperty(prop) will break.
// See: https://github.com/joyent/node/issues/1707
function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

module.exports = function(qs, sep, eq, options) {
  sep = sep || '&';
  eq = eq || '=';
  var obj = {};

  if (typeof qs !== 'string' || qs.length === 0) {
    return obj;
  }

  var regexp = /\+/g;
  qs = qs.split(sep);

  var maxKeys = 1000;
  if (options && typeof options.maxKeys === 'number') {
    maxKeys = options.maxKeys;
  }

  var len = qs.length;
  // maxKeys <= 0 means that we should not limit keys count
  if (maxKeys > 0 && len > maxKeys) {
    len = maxKeys;
  }

  for (var i = 0; i < len; ++i) {
    var x = qs[i].replace(regexp, '%20'),
        idx = x.indexOf(eq),
        kstr, vstr, k, v;

    if (idx >= 0) {
      kstr = x.substr(0, idx);
      vstr = x.substr(idx + 1);
    } else {
      kstr = x;
      vstr = '';
    }

    k = decodeURIComponent(kstr);
    v = decodeURIComponent(vstr);

    if (!hasOwnProperty(obj, k)) {
      obj[k] = v;
    } else if (Array.isArray(obj[k])) {
      obj[k].push(v);
    } else {
      obj[k] = [obj[k], v];
    }
  }

  return obj;
};

},{}],418:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

var stringifyPrimitive = function(v) {
  switch (typeof v) {
    case 'string':
      return v;

    case 'boolean':
      return v ? 'true' : 'false';

    case 'number':
      return isFinite(v) ? v : '';

    default:
      return '';
  }
};

module.exports = function(obj, sep, eq, name) {
  sep = sep || '&';
  eq = eq || '=';
  if (obj === null) {
    obj = undefined;
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).map(function(k) {
      var ks = encodeURIComponent(stringifyPrimitive(k)) + eq;
      if (Array.isArray(obj[k])) {
        return obj[k].map(function(v) {
          return ks + encodeURIComponent(stringifyPrimitive(v));
        }).join(sep);
      } else {
        return ks + encodeURIComponent(stringifyPrimitive(obj[k]));
      }
    }).join(sep);

  }

  if (!name) return '';
  return encodeURIComponent(stringifyPrimitive(name)) + eq +
         encodeURIComponent(stringifyPrimitive(obj));
};

},{}],419:[function(require,module,exports){
arguments[4][416][0].apply(exports,arguments)
},{"./decode":417,"./encode":418,"dup":416}],420:[function(require,module,exports){
(function (setImmediate,clearImmediate){
var nextTick = require('process/browser.js').nextTick;
var apply = Function.prototype.apply;
var slice = Array.prototype.slice;
var immediateIds = {};
var nextImmediateId = 0;

// DOM APIs, for completeness

exports.setTimeout = function() {
  return new Timeout(apply.call(setTimeout, window, arguments), clearTimeout);
};
exports.setInterval = function() {
  return new Timeout(apply.call(setInterval, window, arguments), clearInterval);
};
exports.clearTimeout =
exports.clearInterval = function(timeout) { timeout.close(); };

function Timeout(id, clearFn) {
  this._id = id;
  this._clearFn = clearFn;
}
Timeout.prototype.unref = Timeout.prototype.ref = function() {};
Timeout.prototype.close = function() {
  this._clearFn.call(window, this._id);
};

// Does not start the time, just sets up the members needed.
exports.enroll = function(item, msecs) {
  clearTimeout(item._idleTimeoutId);
  item._idleTimeout = msecs;
};

exports.unenroll = function(item) {
  clearTimeout(item._idleTimeoutId);
  item._idleTimeout = -1;
};

exports._unrefActive = exports.active = function(item) {
  clearTimeout(item._idleTimeoutId);

  var msecs = item._idleTimeout;
  if (msecs >= 0) {
    item._idleTimeoutId = setTimeout(function onTimeout() {
      if (item._onTimeout)
        item._onTimeout();
    }, msecs);
  }
};

// That's not how node.js implements it but the exposed api is the same.
exports.setImmediate = typeof setImmediate === "function" ? setImmediate : function(fn) {
  var id = nextImmediateId++;
  var args = arguments.length < 2 ? false : slice.call(arguments, 1);

  immediateIds[id] = true;

  nextTick(function onNextTick() {
    if (immediateIds[id]) {
      // fn.call() is faster so we optimize for the common use-case
      // @see http://jsperf.com/call-apply-segu
      if (args) {
        fn.apply(null, args);
      } else {
        fn.call(null);
      }
      // Prevent ids from leaking
      exports.clearImmediate(id);
    }
  });

  return id;
};

exports.clearImmediate = typeof clearImmediate === "function" ? clearImmediate : function(id) {
  delete immediateIds[id];
};
}).call(this,require("timers").setImmediate,require("timers").clearImmediate)
},{"process/browser.js":412,"timers":420}],421:[function(require,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

var punycode = require('punycode');

exports.parse = urlParse;
exports.resolve = urlResolve;
exports.resolveObject = urlResolveObject;
exports.format = urlFormat;

exports.Url = Url;

function Url() {
  this.protocol = null;
  this.slashes = null;
  this.auth = null;
  this.host = null;
  this.port = null;
  this.hostname = null;
  this.hash = null;
  this.search = null;
  this.query = null;
  this.pathname = null;
  this.path = null;
  this.href = null;
}

// Reference: RFC 3986, RFC 1808, RFC 2396

// define these here so at least they only have to be
// compiled once on the first module load.
var protocolPattern = /^([a-z0-9.+-]+:)/i,
    portPattern = /:[0-9]*$/,

    // RFC 2396: characters reserved for delimiting URLs.
    // We actually just auto-escape these.
    delims = ['<', '>', '"', '`', ' ', '\r', '\n', '\t'],

    // RFC 2396: characters not allowed for various reasons.
    unwise = ['{', '}', '|', '\\', '^', '`'].concat(delims),

    // Allowed by RFCs, but cause of XSS attacks.  Always escape these.
    autoEscape = ['\''].concat(unwise),
    // Characters that are never ever allowed in a hostname.
    // Note that any invalid chars are also handled, but these
    // are the ones that are *expected* to be seen, so we fast-path
    // them.
    nonHostChars = ['%', '/', '?', ';', '#'].concat(autoEscape),
    hostEndingChars = ['/', '?', '#'],
    hostnameMaxLen = 255,
    hostnamePartPattern = /^[a-z0-9A-Z_-]{0,63}$/,
    hostnamePartStart = /^([a-z0-9A-Z_-]{0,63})(.*)$/,
    // protocols that can allow "unsafe" and "unwise" chars.
    unsafeProtocol = {
      'javascript': true,
      'javascript:': true
    },
    // protocols that never have a hostname.
    hostlessProtocol = {
      'javascript': true,
      'javascript:': true
    },
    // protocols that always contain a // bit.
    slashedProtocol = {
      'http': true,
      'https': true,
      'ftp': true,
      'gopher': true,
      'file': true,
      'http:': true,
      'https:': true,
      'ftp:': true,
      'gopher:': true,
      'file:': true
    },
    querystring = require('querystring');

function urlParse(url, parseQueryString, slashesDenoteHost) {
  if (url && isObject(url) && url instanceof Url) return url;

  var u = new Url;
  u.parse(url, parseQueryString, slashesDenoteHost);
  return u;
}

Url.prototype.parse = function(url, parseQueryString, slashesDenoteHost) {
  if (!isString(url)) {
    throw new TypeError("Parameter 'url' must be a string, not " + typeof url);
  }

  var rest = url;

  // trim before proceeding.
  // This is to support parse stuff like "  http://foo.com  \n"
  rest = rest.trim();

  var proto = protocolPattern.exec(rest);
  if (proto) {
    proto = proto[0];
    var lowerProto = proto.toLowerCase();
    this.protocol = lowerProto;
    rest = rest.substr(proto.length);
  }

  // figure out if it's got a host
  // user@server is *always* interpreted as a hostname, and url
  // resolution will treat //foo/bar as host=foo,path=bar because that's
  // how the browser resolves relative URLs.
  if (slashesDenoteHost || proto || rest.match(/^\/\/[^@\/]+@[^@\/]+/)) {
    var slashes = rest.substr(0, 2) === '//';
    if (slashes && !(proto && hostlessProtocol[proto])) {
      rest = rest.substr(2);
      this.slashes = true;
    }
  }

  if (!hostlessProtocol[proto] &&
      (slashes || (proto && !slashedProtocol[proto]))) {

    // there's a hostname.
    // the first instance of /, ?, ;, or # ends the host.
    //
    // If there is an @ in the hostname, then non-host chars *are* allowed
    // to the left of the last @ sign, unless some host-ending character
    // comes *before* the @-sign.
    // URLs are obnoxious.
    //
    // ex:
    // http://a@b@c/ => user:a@b host:c
    // http://a@b?@c => user:a host:c path:/?@c

    // v0.12 TODO(isaacs): This is not quite how Chrome does things.
    // Review our test case against browsers more comprehensively.

    // find the first instance of any hostEndingChars
    var hostEnd = -1;
    for (var i = 0; i < hostEndingChars.length; i++) {
      var hec = rest.indexOf(hostEndingChars[i]);
      if (hec !== -1 && (hostEnd === -1 || hec < hostEnd))
        hostEnd = hec;
    }

    // at this point, either we have an explicit point where the
    // auth portion cannot go past, or the last @ char is the decider.
    var auth, atSign;
    if (hostEnd === -1) {
      // atSign can be anywhere.
      atSign = rest.lastIndexOf('@');
    } else {
      // atSign must be in auth portion.
      // http://a@b/c@d => host:b auth:a path:/c@d
      atSign = rest.lastIndexOf('@', hostEnd);
    }

    // Now we have a portion which is definitely the auth.
    // Pull that off.
    if (atSign !== -1) {
      auth = rest.slice(0, atSign);
      rest = rest.slice(atSign + 1);
      this.auth = decodeURIComponent(auth);
    }

    // the host is the remaining to the left of the first non-host char
    hostEnd = -1;
    for (var i = 0; i < nonHostChars.length; i++) {
      var hec = rest.indexOf(nonHostChars[i]);
      if (hec !== -1 && (hostEnd === -1 || hec < hostEnd))
        hostEnd = hec;
    }
    // if we still have not hit it, then the entire thing is a host.
    if (hostEnd === -1)
      hostEnd = rest.length;

    this.host = rest.slice(0, hostEnd);
    rest = rest.slice(hostEnd);

    // pull out port.
    this.parseHost();

    // we've indicated that there is a hostname,
    // so even if it's empty, it has to be present.
    this.hostname = this.hostname || '';

    // if hostname begins with [ and ends with ]
    // assume that it's an IPv6 address.
    var ipv6Hostname = this.hostname[0] === '[' &&
        this.hostname[this.hostname.length - 1] === ']';

    // validate a little.
    if (!ipv6Hostname) {
      var hostparts = this.hostname.split(/\./);
      for (var i = 0, l = hostparts.length; i < l; i++) {
        var part = hostparts[i];
        if (!part) continue;
        if (!part.match(hostnamePartPattern)) {
          var newpart = '';
          for (var j = 0, k = part.length; j < k; j++) {
            if (part.charCodeAt(j) > 127) {
              // we replace non-ASCII char with a temporary placeholder
              // we need this to make sure size of hostname is not
              // broken by replacing non-ASCII by nothing
              newpart += 'x';
            } else {
              newpart += part[j];
            }
          }
          // we test again with ASCII char only
          if (!newpart.match(hostnamePartPattern)) {
            var validParts = hostparts.slice(0, i);
            var notHost = hostparts.slice(i + 1);
            var bit = part.match(hostnamePartStart);
            if (bit) {
              validParts.push(bit[1]);
              notHost.unshift(bit[2]);
            }
            if (notHost.length) {
              rest = '/' + notHost.join('.') + rest;
            }
            this.hostname = validParts.join('.');
            break;
          }
        }
      }
    }

    if (this.hostname.length > hostnameMaxLen) {
      this.hostname = '';
    } else {
      // hostnames are always lower case.
      this.hostname = this.hostname.toLowerCase();
    }

    if (!ipv6Hostname) {
      // IDNA Support: Returns a puny coded representation of "domain".
      // It only converts the part of the domain name that
      // has non ASCII characters. I.e. it dosent matter if
      // you call it with a domain that already is in ASCII.
      var domainArray = this.hostname.split('.');
      var newOut = [];
      for (var i = 0; i < domainArray.length; ++i) {
        var s = domainArray[i];
        newOut.push(s.match(/[^A-Za-z0-9_-]/) ?
            'xn--' + punycode.encode(s) : s);
      }
      this.hostname = newOut.join('.');
    }

    var p = this.port ? ':' + this.port : '';
    var h = this.hostname || '';
    this.host = h + p;
    this.href += this.host;

    // strip [ and ] from the hostname
    // the host field still retains them, though
    if (ipv6Hostname) {
      this.hostname = this.hostname.substr(1, this.hostname.length - 2);
      if (rest[0] !== '/') {
        rest = '/' + rest;
      }
    }
  }

  // now rest is set to the post-host stuff.
  // chop off any delim chars.
  if (!unsafeProtocol[lowerProto]) {

    // First, make 100% sure that any "autoEscape" chars get
    // escaped, even if encodeURIComponent doesn't think they
    // need to be.
    for (var i = 0, l = autoEscape.length; i < l; i++) {
      var ae = autoEscape[i];
      var esc = encodeURIComponent(ae);
      if (esc === ae) {
        esc = escape(ae);
      }
      rest = rest.split(ae).join(esc);
    }
  }


  // chop off from the tail first.
  var hash = rest.indexOf('#');
  if (hash !== -1) {
    // got a fragment string.
    this.hash = rest.substr(hash);
    rest = rest.slice(0, hash);
  }
  var qm = rest.indexOf('?');
  if (qm !== -1) {
    this.search = rest.substr(qm);
    this.query = rest.substr(qm + 1);
    if (parseQueryString) {
      this.query = querystring.parse(this.query);
    }
    rest = rest.slice(0, qm);
  } else if (parseQueryString) {
    // no query string, but parseQueryString still requested
    this.search = '';
    this.query = {};
  }
  if (rest) this.pathname = rest;
  if (slashedProtocol[lowerProto] &&
      this.hostname && !this.pathname) {
    this.pathname = '/';
  }

  //to support http.request
  if (this.pathname || this.search) {
    var p = this.pathname || '';
    var s = this.search || '';
    this.path = p + s;
  }

  // finally, reconstruct the href based on what has been validated.
  this.href = this.format();
  return this;
};

// format a parsed object into a url string
function urlFormat(obj) {
  // ensure it's an object, and not a string url.
  // If it's an obj, this is a no-op.
  // this way, you can call url_format() on strings
  // to clean up potentially wonky urls.
  if (isString(obj)) obj = urlParse(obj);
  if (!(obj instanceof Url)) return Url.prototype.format.call(obj);
  return obj.format();
}

Url.prototype.format = function() {
  var auth = this.auth || '';
  if (auth) {
    auth = encodeURIComponent(auth);
    auth = auth.replace(/%3A/i, ':');
    auth += '@';
  }

  var protocol = this.protocol || '',
      pathname = this.pathname || '',
      hash = this.hash || '',
      host = false,
      query = '';

  if (this.host) {
    host = auth + this.host;
  } else if (this.hostname) {
    host = auth + (this.hostname.indexOf(':') === -1 ?
        this.hostname :
        '[' + this.hostname + ']');
    if (this.port) {
      host += ':' + this.port;
    }
  }

  if (this.query &&
      isObject(this.query) &&
      Object.keys(this.query).length) {
    query = querystring.stringify(this.query);
  }

  var search = this.search || (query && ('?' + query)) || '';

  if (protocol && protocol.substr(-1) !== ':') protocol += ':';

  // only the slashedProtocols get the //.  Not mailto:, xmpp:, etc.
  // unless they had them to begin with.
  if (this.slashes ||
      (!protocol || slashedProtocol[protocol]) && host !== false) {
    host = '//' + (host || '');
    if (pathname && pathname.charAt(0) !== '/') pathname = '/' + pathname;
  } else if (!host) {
    host = '';
  }

  if (hash && hash.charAt(0) !== '#') hash = '#' + hash;
  if (search && search.charAt(0) !== '?') search = '?' + search;

  pathname = pathname.replace(/[?#]/g, function(match) {
    return encodeURIComponent(match);
  });
  search = search.replace('#', '%23');

  return protocol + host + pathname + search + hash;
};

function urlResolve(source, relative) {
  return urlParse(source, false, true).resolve(relative);
}

Url.prototype.resolve = function(relative) {
  return this.resolveObject(urlParse(relative, false, true)).format();
};

function urlResolveObject(source, relative) {
  if (!source) return relative;
  return urlParse(source, false, true).resolveObject(relative);
}

Url.prototype.resolveObject = function(relative) {
  if (isString(relative)) {
    var rel = new Url();
    rel.parse(relative, false, true);
    relative = rel;
  }

  var result = new Url();
  Object.keys(this).forEach(function(k) {
    result[k] = this[k];
  }, this);

  // hash is always overridden, no matter what.
  // even href="" will remove it.
  result.hash = relative.hash;

  // if the relative url is empty, then there's nothing left to do here.
  if (relative.href === '') {
    result.href = result.format();
    return result;
  }

  // hrefs like //foo/bar always cut to the protocol.
  if (relative.slashes && !relative.protocol) {
    // take everything except the protocol from relative
    Object.keys(relative).forEach(function(k) {
      if (k !== 'protocol')
        result[k] = relative[k];
    });

    //urlParse appends trailing / to urls like http://www.example.com
    if (slashedProtocol[result.protocol] &&
        result.hostname && !result.pathname) {
      result.path = result.pathname = '/';
    }

    result.href = result.format();
    return result;
  }

  if (relative.protocol && relative.protocol !== result.protocol) {
    // if it's a known url protocol, then changing
    // the protocol does weird things
    // first, if it's not file:, then we MUST have a host,
    // and if there was a path
    // to begin with, then we MUST have a path.
    // if it is file:, then the host is dropped,
    // because that's known to be hostless.
    // anything else is assumed to be absolute.
    if (!slashedProtocol[relative.protocol]) {
      Object.keys(relative).forEach(function(k) {
        result[k] = relative[k];
      });
      result.href = result.format();
      return result;
    }

    result.protocol = relative.protocol;
    if (!relative.host && !hostlessProtocol[relative.protocol]) {
      var relPath = (relative.pathname || '').split('/');
      while (relPath.length && !(relative.host = relPath.shift()));
      if (!relative.host) relative.host = '';
      if (!relative.hostname) relative.hostname = '';
      if (relPath[0] !== '') relPath.unshift('');
      if (relPath.length < 2) relPath.unshift('');
      result.pathname = relPath.join('/');
    } else {
      result.pathname = relative.pathname;
    }
    result.search = relative.search;
    result.query = relative.query;
    result.host = relative.host || '';
    result.auth = relative.auth;
    result.hostname = relative.hostname || relative.host;
    result.port = relative.port;
    // to support http.request
    if (result.pathname || result.search) {
      var p = result.pathname || '';
      var s = result.search || '';
      result.path = p + s;
    }
    result.slashes = result.slashes || relative.slashes;
    result.href = result.format();
    return result;
  }

  var isSourceAbs = (result.pathname && result.pathname.charAt(0) === '/'),
      isRelAbs = (
          relative.host ||
          relative.pathname && relative.pathname.charAt(0) === '/'
      ),
      mustEndAbs = (isRelAbs || isSourceAbs ||
                    (result.host && relative.pathname)),
      removeAllDots = mustEndAbs,
      srcPath = result.pathname && result.pathname.split('/') || [],
      relPath = relative.pathname && relative.pathname.split('/') || [],
      psychotic = result.protocol && !slashedProtocol[result.protocol];

  // if the url is a non-slashed url, then relative
  // links like ../.. should be able
  // to crawl up to the hostname, as well.  This is strange.
  // result.protocol has already been set by now.
  // Later on, put the first path part into the host field.
  if (psychotic) {
    result.hostname = '';
    result.port = null;
    if (result.host) {
      if (srcPath[0] === '') srcPath[0] = result.host;
      else srcPath.unshift(result.host);
    }
    result.host = '';
    if (relative.protocol) {
      relative.hostname = null;
      relative.port = null;
      if (relative.host) {
        if (relPath[0] === '') relPath[0] = relative.host;
        else relPath.unshift(relative.host);
      }
      relative.host = null;
    }
    mustEndAbs = mustEndAbs && (relPath[0] === '' || srcPath[0] === '');
  }

  if (isRelAbs) {
    // it's absolute.
    result.host = (relative.host || relative.host === '') ?
                  relative.host : result.host;
    result.hostname = (relative.hostname || relative.hostname === '') ?
                      relative.hostname : result.hostname;
    result.search = relative.search;
    result.query = relative.query;
    srcPath = relPath;
    // fall through to the dot-handling below.
  } else if (relPath.length) {
    // it's relative
    // throw away the existing file, and take the new path instead.
    if (!srcPath) srcPath = [];
    srcPath.pop();
    srcPath = srcPath.concat(relPath);
    result.search = relative.search;
    result.query = relative.query;
  } else if (!isNullOrUndefined(relative.search)) {
    // just pull out the search.
    // like href='?foo'.
    // Put this after the other two cases because it simplifies the booleans
    if (psychotic) {
      result.hostname = result.host = srcPath.shift();
      //occationaly the auth can get stuck only in host
      //this especialy happens in cases like
      //url.resolveObject('mailto:local1@domain1', 'local2@domain2')
      var authInHost = result.host && result.host.indexOf('@') > 0 ?
                       result.host.split('@') : false;
      if (authInHost) {
        result.auth = authInHost.shift();
        result.host = result.hostname = authInHost.shift();
      }
    }
    result.search = relative.search;
    result.query = relative.query;
    //to support http.request
    if (!isNull(result.pathname) || !isNull(result.search)) {
      result.path = (result.pathname ? result.pathname : '') +
                    (result.search ? result.search : '');
    }
    result.href = result.format();
    return result;
  }

  if (!srcPath.length) {
    // no path at all.  easy.
    // we've already handled the other stuff above.
    result.pathname = null;
    //to support http.request
    if (result.search) {
      result.path = '/' + result.search;
    } else {
      result.path = null;
    }
    result.href = result.format();
    return result;
  }

  // if a url ENDs in . or .., then it must get a trailing slash.
  // however, if it ends in anything else non-slashy,
  // then it must NOT get a trailing slash.
  var last = srcPath.slice(-1)[0];
  var hasTrailingSlash = (
      (result.host || relative.host) && (last === '.' || last === '..') ||
      last === '');

  // strip single dots, resolve double dots to parent dir
  // if the path tries to go above the root, `up` ends up > 0
  var up = 0;
  for (var i = srcPath.length; i >= 0; i--) {
    last = srcPath[i];
    if (last == '.') {
      srcPath.splice(i, 1);
    } else if (last === '..') {
      srcPath.splice(i, 1);
      up++;
    } else if (up) {
      srcPath.splice(i, 1);
      up--;
    }
  }

  // if the path is allowed to go above the root, restore leading ..s
  if (!mustEndAbs && !removeAllDots) {
    for (; up--; up) {
      srcPath.unshift('..');
    }
  }

  if (mustEndAbs && srcPath[0] !== '' &&
      (!srcPath[0] || srcPath[0].charAt(0) !== '/')) {
    srcPath.unshift('');
  }

  if (hasTrailingSlash && (srcPath.join('/').substr(-1) !== '/')) {
    srcPath.push('');
  }

  var isAbsolute = srcPath[0] === '' ||
      (srcPath[0] && srcPath[0].charAt(0) === '/');

  // put the host back
  if (psychotic) {
    result.hostname = result.host = isAbsolute ? '' :
                                    srcPath.length ? srcPath.shift() : '';
    //occationaly the auth can get stuck only in host
    //this especialy happens in cases like
    //url.resolveObject('mailto:local1@domain1', 'local2@domain2')
    var authInHost = result.host && result.host.indexOf('@') > 0 ?
                     result.host.split('@') : false;
    if (authInHost) {
      result.auth = authInHost.shift();
      result.host = result.hostname = authInHost.shift();
    }
  }

  mustEndAbs = mustEndAbs || (result.host && srcPath.length);

  if (mustEndAbs && !isAbsolute) {
    srcPath.unshift('');
  }

  if (!srcPath.length) {
    result.pathname = null;
    result.path = null;
  } else {
    result.pathname = srcPath.join('/');
  }

  //to support request.http
  if (!isNull(result.pathname) || !isNull(result.search)) {
    result.path = (result.pathname ? result.pathname : '') +
                  (result.search ? result.search : '');
  }
  result.auth = relative.auth || result.auth;
  result.slashes = result.slashes || relative.slashes;
  result.href = result.format();
  return result;
};

Url.prototype.parseHost = function() {
  var host = this.host;
  var port = portPattern.exec(host);
  if (port) {
    port = port[0];
    if (port !== ':') {
      this.port = port.substr(1);
    }
    host = host.substr(0, host.length - port.length);
  }
  if (host) this.hostname = host;
};

function isString(arg) {
  return typeof arg === "string";
}

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}

function isNull(arg) {
  return arg === null;
}
function isNullOrUndefined(arg) {
  return  arg == null;
}

},{"punycode":413,"querystring":416}],422:[function(require,module,exports){
if (typeof Object.create === 'function') {
  // implementation from standard node.js 'util' module
  module.exports = function inherits(ctor, superCtor) {
    ctor.super_ = superCtor
    ctor.prototype = Object.create(superCtor.prototype, {
      constructor: {
        value: ctor,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
  };
} else {
  // old school shim for old browsers
  module.exports = function inherits(ctor, superCtor) {
    ctor.super_ = superCtor
    var TempCtor = function () {}
    TempCtor.prototype = superCtor.prototype
    ctor.prototype = new TempCtor()
    ctor.prototype.constructor = ctor
  }
}

},{}],423:[function(require,module,exports){
module.exports = function isBuffer(arg) {
  return arg && typeof arg === 'object'
    && typeof arg.copy === 'function'
    && typeof arg.fill === 'function'
    && typeof arg.readUInt8 === 'function';
}
},{}],424:[function(require,module,exports){
(function (process,global){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

var formatRegExp = /%[sdj%]/g;
exports.format = function(f) {
  if (!isString(f)) {
    var objects = [];
    for (var i = 0; i < arguments.length; i++) {
      objects.push(inspect(arguments[i]));
    }
    return objects.join(' ');
  }

  var i = 1;
  var args = arguments;
  var len = args.length;
  var str = String(f).replace(formatRegExp, function(x) {
    if (x === '%%') return '%';
    if (i >= len) return x;
    switch (x) {
      case '%s': return String(args[i++]);
      case '%d': return Number(args[i++]);
      case '%j':
        try {
          return JSON.stringify(args[i++]);
        } catch (_) {
          return '[Circular]';
        }
      default:
        return x;
    }
  });
  for (var x = args[i]; i < len; x = args[++i]) {
    if (isNull(x) || !isObject(x)) {
      str += ' ' + x;
    } else {
      str += ' ' + inspect(x);
    }
  }
  return str;
};


// Mark that a method should not be used.
// Returns a modified function which warns once by default.
// If --no-deprecation is set, then it is a no-op.
exports.deprecate = function(fn, msg) {
  // Allow for deprecating things in the process of starting up.
  if (isUndefined(global.process)) {
    return function() {
      return exports.deprecate(fn, msg).apply(this, arguments);
    };
  }

  if (process.noDeprecation === true) {
    return fn;
  }

  var warned = false;
  function deprecated() {
    if (!warned) {
      if (process.throwDeprecation) {
        throw new Error(msg);
      } else if (process.traceDeprecation) {
        console.trace(msg);
      } else {
        console.error(msg);
      }
      warned = true;
    }
    return fn.apply(this, arguments);
  }

  return deprecated;
};


var debugs = {};
var debugEnviron;
exports.debuglog = function(set) {
  if (isUndefined(debugEnviron))
    debugEnviron = process.env.NODE_DEBUG || '';
  set = set.toUpperCase();
  if (!debugs[set]) {
    if (new RegExp('\\b' + set + '\\b', 'i').test(debugEnviron)) {
      var pid = process.pid;
      debugs[set] = function() {
        var msg = exports.format.apply(exports, arguments);
        console.error('%s %d: %s', set, pid, msg);
      };
    } else {
      debugs[set] = function() {};
    }
  }
  return debugs[set];
};


/**
 * Echos the value of a value. Trys to print the value out
 * in the best way possible given the different types.
 *
 * @param {Object} obj The object to print out.
 * @param {Object} opts Optional options object that alters the output.
 */
/* legacy: obj, showHidden, depth, colors*/
function inspect(obj, opts) {
  // default options
  var ctx = {
    seen: [],
    stylize: stylizeNoColor
  };
  // legacy...
  if (arguments.length >= 3) ctx.depth = arguments[2];
  if (arguments.length >= 4) ctx.colors = arguments[3];
  if (isBoolean(opts)) {
    // legacy...
    ctx.showHidden = opts;
  } else if (opts) {
    // got an "options" object
    exports._extend(ctx, opts);
  }
  // set default options
  if (isUndefined(ctx.showHidden)) ctx.showHidden = false;
  if (isUndefined(ctx.depth)) ctx.depth = 2;
  if (isUndefined(ctx.colors)) ctx.colors = false;
  if (isUndefined(ctx.customInspect)) ctx.customInspect = true;
  if (ctx.colors) ctx.stylize = stylizeWithColor;
  return formatValue(ctx, obj, ctx.depth);
}
exports.inspect = inspect;


// http://en.wikipedia.org/wiki/ANSI_escape_code#graphics
inspect.colors = {
  'bold' : [1, 22],
  'italic' : [3, 23],
  'underline' : [4, 24],
  'inverse' : [7, 27],
  'white' : [37, 39],
  'grey' : [90, 39],
  'black' : [30, 39],
  'blue' : [34, 39],
  'cyan' : [36, 39],
  'green' : [32, 39],
  'magenta' : [35, 39],
  'red' : [31, 39],
  'yellow' : [33, 39]
};

// Don't use 'blue' not visible on cmd.exe
inspect.styles = {
  'special': 'cyan',
  'number': 'yellow',
  'boolean': 'yellow',
  'undefined': 'grey',
  'null': 'bold',
  'string': 'green',
  'date': 'magenta',
  // "name": intentionally not styling
  'regexp': 'red'
};


function stylizeWithColor(str, styleType) {
  var style = inspect.styles[styleType];

  if (style) {
    return '\u001b[' + inspect.colors[style][0] + 'm' + str +
           '\u001b[' + inspect.colors[style][1] + 'm';
  } else {
    return str;
  }
}


function stylizeNoColor(str, styleType) {
  return str;
}


function arrayToHash(array) {
  var hash = {};

  array.forEach(function(val, idx) {
    hash[val] = true;
  });

  return hash;
}


function formatValue(ctx, value, recurseTimes) {
  // Provide a hook for user-specified inspect functions.
  // Check that value is an object with an inspect function on it
  if (ctx.customInspect &&
      value &&
      isFunction(value.inspect) &&
      // Filter out the util module, it's inspect function is special
      value.inspect !== exports.inspect &&
      // Also filter out any prototype objects using the circular check.
      !(value.constructor && value.constructor.prototype === value)) {
    var ret = value.inspect(recurseTimes, ctx);
    if (!isString(ret)) {
      ret = formatValue(ctx, ret, recurseTimes);
    }
    return ret;
  }

  // Primitive types cannot have properties
  var primitive = formatPrimitive(ctx, value);
  if (primitive) {
    return primitive;
  }

  // Look up the keys of the object.
  var keys = Object.keys(value);
  var visibleKeys = arrayToHash(keys);

  if (ctx.showHidden) {
    keys = Object.getOwnPropertyNames(value);
  }

  // IE doesn't make error fields non-enumerable
  // http://msdn.microsoft.com/en-us/library/ie/dww52sbt(v=vs.94).aspx
  if (isError(value)
      && (keys.indexOf('message') >= 0 || keys.indexOf('description') >= 0)) {
    return formatError(value);
  }

  // Some type of object without properties can be shortcutted.
  if (keys.length === 0) {
    if (isFunction(value)) {
      var name = value.name ? ': ' + value.name : '';
      return ctx.stylize('[Function' + name + ']', 'special');
    }
    if (isRegExp(value)) {
      return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
    }
    if (isDate(value)) {
      return ctx.stylize(Date.prototype.toString.call(value), 'date');
    }
    if (isError(value)) {
      return formatError(value);
    }
  }

  var base = '', array = false, braces = ['{', '}'];

  // Make Array say that they are Array
  if (isArray(value)) {
    array = true;
    braces = ['[', ']'];
  }

  // Make functions say that they are functions
  if (isFunction(value)) {
    var n = value.name ? ': ' + value.name : '';
    base = ' [Function' + n + ']';
  }

  // Make RegExps say that they are RegExps
  if (isRegExp(value)) {
    base = ' ' + RegExp.prototype.toString.call(value);
  }

  // Make dates with properties first say the date
  if (isDate(value)) {
    base = ' ' + Date.prototype.toUTCString.call(value);
  }

  // Make error with message first say the error
  if (isError(value)) {
    base = ' ' + formatError(value);
  }

  if (keys.length === 0 && (!array || value.length == 0)) {
    return braces[0] + base + braces[1];
  }

  if (recurseTimes < 0) {
    if (isRegExp(value)) {
      return ctx.stylize(RegExp.prototype.toString.call(value), 'regexp');
    } else {
      return ctx.stylize('[Object]', 'special');
    }
  }

  ctx.seen.push(value);

  var output;
  if (array) {
    output = formatArray(ctx, value, recurseTimes, visibleKeys, keys);
  } else {
    output = keys.map(function(key) {
      return formatProperty(ctx, value, recurseTimes, visibleKeys, key, array);
    });
  }

  ctx.seen.pop();

  return reduceToSingleString(output, base, braces);
}


function formatPrimitive(ctx, value) {
  if (isUndefined(value))
    return ctx.stylize('undefined', 'undefined');
  if (isString(value)) {
    var simple = '\'' + JSON.stringify(value).replace(/^"|"$/g, '')
                                             .replace(/'/g, "\\'")
                                             .replace(/\\"/g, '"') + '\'';
    return ctx.stylize(simple, 'string');
  }
  if (isNumber(value))
    return ctx.stylize('' + value, 'number');
  if (isBoolean(value))
    return ctx.stylize('' + value, 'boolean');
  // For some reason typeof null is "object", so special case here.
  if (isNull(value))
    return ctx.stylize('null', 'null');
}


function formatError(value) {
  return '[' + Error.prototype.toString.call(value) + ']';
}


function formatArray(ctx, value, recurseTimes, visibleKeys, keys) {
  var output = [];
  for (var i = 0, l = value.length; i < l; ++i) {
    if (hasOwnProperty(value, String(i))) {
      output.push(formatProperty(ctx, value, recurseTimes, visibleKeys,
          String(i), true));
    } else {
      output.push('');
    }
  }
  keys.forEach(function(key) {
    if (!key.match(/^\d+$/)) {
      output.push(formatProperty(ctx, value, recurseTimes, visibleKeys,
          key, true));
    }
  });
  return output;
}


function formatProperty(ctx, value, recurseTimes, visibleKeys, key, array) {
  var name, str, desc;
  desc = Object.getOwnPropertyDescriptor(value, key) || { value: value[key] };
  if (desc.get) {
    if (desc.set) {
      str = ctx.stylize('[Getter/Setter]', 'special');
    } else {
      str = ctx.stylize('[Getter]', 'special');
    }
  } else {
    if (desc.set) {
      str = ctx.stylize('[Setter]', 'special');
    }
  }
  if (!hasOwnProperty(visibleKeys, key)) {
    name = '[' + key + ']';
  }
  if (!str) {
    if (ctx.seen.indexOf(desc.value) < 0) {
      if (isNull(recurseTimes)) {
        str = formatValue(ctx, desc.value, null);
      } else {
        str = formatValue(ctx, desc.value, recurseTimes - 1);
      }
      if (str.indexOf('\n') > -1) {
        if (array) {
          str = str.split('\n').map(function(line) {
            return '  ' + line;
          }).join('\n').substr(2);
        } else {
          str = '\n' + str.split('\n').map(function(line) {
            return '   ' + line;
          }).join('\n');
        }
      }
    } else {
      str = ctx.stylize('[Circular]', 'special');
    }
  }
  if (isUndefined(name)) {
    if (array && key.match(/^\d+$/)) {
      return str;
    }
    name = JSON.stringify('' + key);
    if (name.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/)) {
      name = name.substr(1, name.length - 2);
      name = ctx.stylize(name, 'name');
    } else {
      name = name.replace(/'/g, "\\'")
                 .replace(/\\"/g, '"')
                 .replace(/(^"|"$)/g, "'");
      name = ctx.stylize(name, 'string');
    }
  }

  return name + ': ' + str;
}


function reduceToSingleString(output, base, braces) {
  var numLinesEst = 0;
  var length = output.reduce(function(prev, cur) {
    numLinesEst++;
    if (cur.indexOf('\n') >= 0) numLinesEst++;
    return prev + cur.replace(/\u001b\[\d\d?m/g, '').length + 1;
  }, 0);

  if (length > 60) {
    return braces[0] +
           (base === '' ? '' : base + '\n ') +
           ' ' +
           output.join(',\n  ') +
           ' ' +
           braces[1];
  }

  return braces[0] + base + ' ' + output.join(', ') + ' ' + braces[1];
}


// NOTE: These type checking functions intentionally don't use `instanceof`
// because it is fragile and can be easily faked with `Object.create()`.
function isArray(ar) {
  return Array.isArray(ar);
}
exports.isArray = isArray;

function isBoolean(arg) {
  return typeof arg === 'boolean';
}
exports.isBoolean = isBoolean;

function isNull(arg) {
  return arg === null;
}
exports.isNull = isNull;

function isNullOrUndefined(arg) {
  return arg == null;
}
exports.isNullOrUndefined = isNullOrUndefined;

function isNumber(arg) {
  return typeof arg === 'number';
}
exports.isNumber = isNumber;

function isString(arg) {
  return typeof arg === 'string';
}
exports.isString = isString;

function isSymbol(arg) {
  return typeof arg === 'symbol';
}
exports.isSymbol = isSymbol;

function isUndefined(arg) {
  return arg === void 0;
}
exports.isUndefined = isUndefined;

function isRegExp(re) {
  return isObject(re) && objectToString(re) === '[object RegExp]';
}
exports.isRegExp = isRegExp;

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}
exports.isObject = isObject;

function isDate(d) {
  return isObject(d) && objectToString(d) === '[object Date]';
}
exports.isDate = isDate;

function isError(e) {
  return isObject(e) &&
      (objectToString(e) === '[object Error]' || e instanceof Error);
}
exports.isError = isError;

function isFunction(arg) {
  return typeof arg === 'function';
}
exports.isFunction = isFunction;

function isPrimitive(arg) {
  return arg === null ||
         typeof arg === 'boolean' ||
         typeof arg === 'number' ||
         typeof arg === 'string' ||
         typeof arg === 'symbol' ||  // ES6 symbol
         typeof arg === 'undefined';
}
exports.isPrimitive = isPrimitive;

exports.isBuffer = require('./support/isBuffer');

function objectToString(o) {
  return Object.prototype.toString.call(o);
}


function pad(n) {
  return n < 10 ? '0' + n.toString(10) : n.toString(10);
}


var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
              'Oct', 'Nov', 'Dec'];

// 26 Feb 16:19:34
function timestamp() {
  var d = new Date();
  var time = [pad(d.getHours()),
              pad(d.getMinutes()),
              pad(d.getSeconds())].join(':');
  return [d.getDate(), months[d.getMonth()], time].join(' ');
}


// log is just a thin wrapper to console.log that prepends a timestamp
exports.log = function() {
  console.log('%s - %s', timestamp(), exports.format.apply(exports, arguments));
};


/**
 * Inherit the prototype methods from one constructor into another.
 *
 * The Function.prototype.inherits from lang.js rewritten as a standalone
 * function (not on Function.prototype). NOTE: If this file is to be loaded
 * during bootstrapping this function needs to be rewritten using some native
 * functions as prototype setup using normal JavaScript does not work as
 * expected during bootstrapping (see mirror.js in r114903).
 *
 * @param {function} ctor Constructor function which needs to inherit the
 *     prototype.
 * @param {function} superCtor Constructor function to inherit prototype from.
 */
exports.inherits = require('inherits');

exports._extend = function(origin, add) {
  // Don't do anything if add isn't an object
  if (!add || !isObject(add)) return origin;

  var keys = Object.keys(add);
  var i = keys.length;
  while (i--) {
    origin[keys[i]] = add[keys[i]];
  }
  return origin;
};

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

}).call(this,require('_process'),typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./support/isBuffer":423,"_process":412,"inherits":422}],425:[function(require,module,exports){
var v1 = require('./v1');
var v4 = require('./v4');

var uuid = v4;
uuid.v1 = v1;
uuid.v4 = v4;

module.exports = uuid;

},{"./v1":428,"./v4":429}],426:[function(require,module,exports){
/**
 * Convert array of 16 byte values to UUID string format of the form:
 * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
 */
var byteToHex = [];
for (var i = 0; i < 256; ++i) {
  byteToHex[i] = (i + 0x100).toString(16).substr(1);
}

function bytesToUuid(buf, offset) {
  var i = offset || 0;
  var bth = byteToHex;
  // join used to fix memory issue caused by concatenation: https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4
  return ([bth[buf[i++]], bth[buf[i++]],
  bth[buf[i++]], bth[buf[i++]], '-',
  bth[buf[i++]], bth[buf[i++]], '-',
  bth[buf[i++]], bth[buf[i++]], '-',
  bth[buf[i++]], bth[buf[i++]], '-',
  bth[buf[i++]], bth[buf[i++]],
  bth[buf[i++]], bth[buf[i++]],
  bth[buf[i++]], bth[buf[i++]]]).join('');
}

module.exports = bytesToUuid;

},{}],427:[function(require,module,exports){
// Unique ID creation requires a high quality random # generator.  In the
// browser this is a little complicated due to unknown quality of Math.random()
// and inconsistent support for the `crypto` API.  We do the best we can via
// feature-detection

// getRandomValues needs to be invoked in a context where "this" is a Crypto
// implementation. Also, find the complete implementation of crypto on IE11.
var getRandomValues = (typeof(crypto) != 'undefined' && crypto.getRandomValues && crypto.getRandomValues.bind(crypto)) ||
                      (typeof(msCrypto) != 'undefined' && typeof window.msCrypto.getRandomValues == 'function' && msCrypto.getRandomValues.bind(msCrypto));

if (getRandomValues) {
  // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto
  var rnds8 = new Uint8Array(16); // eslint-disable-line no-undef

  module.exports = function whatwgRNG() {
    getRandomValues(rnds8);
    return rnds8;
  };
} else {
  // Math.random()-based (RNG)
  //
  // If all else fails, use Math.random().  It's fast, but is of unspecified
  // quality.
  var rnds = new Array(16);

  module.exports = function mathRNG() {
    for (var i = 0, r; i < 16; i++) {
      if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
      rnds[i] = r >>> ((i & 0x03) << 3) & 0xff;
    }

    return rnds;
  };
}

},{}],428:[function(require,module,exports){
var rng = require('./lib/rng');
var bytesToUuid = require('./lib/bytesToUuid');

// **`v1()` - Generate time-based UUID**
//
// Inspired by https://github.com/LiosK/UUID.js
// and http://docs.python.org/library/uuid.html

var _nodeId;
var _clockseq;

// Previous uuid creation time
var _lastMSecs = 0;
var _lastNSecs = 0;

// See https://github.com/broofa/node-uuid for API details
function v1(options, buf, offset) {
  var i = buf && offset || 0;
  var b = buf || [];

  options = options || {};
  var node = options.node || _nodeId;
  var clockseq = options.clockseq !== undefined ? options.clockseq : _clockseq;

  // node and clockseq need to be initialized to random values if they're not
  // specified.  We do this lazily to minimize issues related to insufficient
  // system entropy.  See #189
  if (node == null || clockseq == null) {
    var seedBytes = rng();
    if (node == null) {
      // Per 4.5, create and 48-bit node id, (47 random bits + multicast bit = 1)
      node = _nodeId = [
        seedBytes[0] | 0x01,
        seedBytes[1], seedBytes[2], seedBytes[3], seedBytes[4], seedBytes[5]
      ];
    }
    if (clockseq == null) {
      // Per 4.2.2, randomize (14 bit) clockseq
      clockseq = _clockseq = (seedBytes[6] << 8 | seedBytes[7]) & 0x3fff;
    }
  }

  // UUID timestamps are 100 nano-second units since the Gregorian epoch,
  // (1582-10-15 00:00).  JSNumbers aren't precise enough for this, so
  // time is handled internally as 'msecs' (integer milliseconds) and 'nsecs'
  // (100-nanoseconds offset from msecs) since unix epoch, 1970-01-01 00:00.
  var msecs = options.msecs !== undefined ? options.msecs : new Date().getTime();

  // Per 4.2.1.2, use count of uuid's generated during the current clock
  // cycle to simulate higher resolution clock
  var nsecs = options.nsecs !== undefined ? options.nsecs : _lastNSecs + 1;

  // Time since last uuid creation (in msecs)
  var dt = (msecs - _lastMSecs) + (nsecs - _lastNSecs)/10000;

  // Per 4.2.1.2, Bump clockseq on clock regression
  if (dt < 0 && options.clockseq === undefined) {
    clockseq = clockseq + 1 & 0x3fff;
  }

  // Reset nsecs if clock regresses (new clockseq) or we've moved onto a new
  // time interval
  if ((dt < 0 || msecs > _lastMSecs) && options.nsecs === undefined) {
    nsecs = 0;
  }

  // Per 4.2.1.2 Throw error if too many uuids are requested
  if (nsecs >= 10000) {
    throw new Error('uuid.v1(): Can\'t create more than 10M uuids/sec');
  }

  _lastMSecs = msecs;
  _lastNSecs = nsecs;
  _clockseq = clockseq;

  // Per 4.1.4 - Convert from unix epoch to Gregorian epoch
  msecs += 12219292800000;

  // `time_low`
  var tl = ((msecs & 0xfffffff) * 10000 + nsecs) % 0x100000000;
  b[i++] = tl >>> 24 & 0xff;
  b[i++] = tl >>> 16 & 0xff;
  b[i++] = tl >>> 8 & 0xff;
  b[i++] = tl & 0xff;

  // `time_mid`
  var tmh = (msecs / 0x100000000 * 10000) & 0xfffffff;
  b[i++] = tmh >>> 8 & 0xff;
  b[i++] = tmh & 0xff;

  // `time_high_and_version`
  b[i++] = tmh >>> 24 & 0xf | 0x10; // include version
  b[i++] = tmh >>> 16 & 0xff;

  // `clock_seq_hi_and_reserved` (Per 4.2.2 - include variant)
  b[i++] = clockseq >>> 8 | 0x80;

  // `clock_seq_low`
  b[i++] = clockseq & 0xff;

  // `node`
  for (var n = 0; n < 6; ++n) {
    b[i + n] = node[n];
  }

  return buf ? buf : bytesToUuid(b);
}

module.exports = v1;

},{"./lib/bytesToUuid":426,"./lib/rng":427}],429:[function(require,module,exports){
var rng = require('./lib/rng');
var bytesToUuid = require('./lib/bytesToUuid');

function v4(options, buf, offset) {
  var i = buf && offset || 0;

  if (typeof(options) == 'string') {
    buf = options === 'binary' ? new Array(16) : null;
    options = null;
  }
  options = options || {};

  var rnds = options.random || (options.rng || rng)();

  // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
  rnds[6] = (rnds[6] & 0x0f) | 0x40;
  rnds[8] = (rnds[8] & 0x3f) | 0x80;

  // Copy bytes to buffer, if provided
  if (buf) {
    for (var ii = 0; ii < 16; ++ii) {
      buf[i + ii] = rnds[ii];
    }
  }

  return buf || bytesToUuid(rnds);
}

module.exports = v4;

},{"./lib/bytesToUuid":426,"./lib/rng":427}],430:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LRU_1 = require("./utils/LRU");
var CACHE_SIZE = 1000;
/**
 * Inspired node-lru-cache[https://github.com/isaacs/node-lru-cache]
 */
var EndpointCache = /** @class */ (function () {
    function EndpointCache(maxSize) {
        if (maxSize === void 0) { maxSize = CACHE_SIZE; }
        this.maxSize = maxSize;
        this.cache = new LRU_1.LRUCache(maxSize);
    }
    ;
    Object.defineProperty(EndpointCache.prototype, "size", {
        get: function () {
            return this.cache.length;
        },
        enumerable: true,
        configurable: true
    });
    EndpointCache.prototype.put = function (key, value) {
      var keyString = typeof key !== 'string' ? EndpointCache.getKeyString(key) : key;
        var endpointRecord = this.populateValue(value);
        this.cache.put(keyString, endpointRecord);
    };
    EndpointCache.prototype.get = function (key) {
      var keyString = typeof key !== 'string' ? EndpointCache.getKeyString(key) : key;
        var now = Date.now();
        var records = this.cache.get(keyString);
        if (records) {
            for (var i = 0; i < records.length; i++) {
                var record = records[i];
                if (record.Expire < now) {
                    this.cache.remove(keyString);
                    return undefined;
                }
            }
        }
        return records;
    };
    EndpointCache.getKeyString = function (key) {
        var identifiers = [];
        var identifierNames = Object.keys(key).sort();
        for (var i = 0; i < identifierNames.length; i++) {
            var identifierName = identifierNames[i];
            if (key[identifierName] === undefined)
                continue;
            identifiers.push(key[identifierName]);
        }
        return identifiers.join(' ');
    };
    EndpointCache.prototype.populateValue = function (endpoints) {
        var now = Date.now();
        return endpoints.map(function (endpoint) { return ({
            Address: endpoint.Address || '',
            Expire: now + (endpoint.CachePeriodInMinutes || 1) * 60 * 1000
        }); });
    };
    EndpointCache.prototype.empty = function () {
        this.cache.empty();
    };
    EndpointCache.prototype.remove = function (key) {
      var keyString = typeof key !== 'string' ? EndpointCache.getKeyString(key) : key;
        this.cache.remove(keyString);
    };
    return EndpointCache;
}());
exports.EndpointCache = EndpointCache;
},{"./utils/LRU":431}],431:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LinkedListNode = /** @class */ (function () {
    function LinkedListNode(key, value) {
        this.key = key;
        this.value = value;
    }
    return LinkedListNode;
}());
var LRUCache = /** @class */ (function () {
    function LRUCache(size) {
        this.nodeMap = {};
        this.size = 0;
        if (typeof size !== 'number' || size < 1) {
            throw new Error('Cache size can only be positive number');
        }
        this.sizeLimit = size;
    }
    Object.defineProperty(LRUCache.prototype, "length", {
        get: function () {
            return this.size;
        },
        enumerable: true,
        configurable: true
    });
    LRUCache.prototype.prependToList = function (node) {
        if (!this.headerNode) {
            this.tailNode = node;
        }
        else {
            this.headerNode.prev = node;
            node.next = this.headerNode;
        }
        this.headerNode = node;
        this.size++;
    };
    LRUCache.prototype.removeFromTail = function () {
        if (!this.tailNode) {
            return undefined;
        }
        var node = this.tailNode;
        var prevNode = node.prev;
        if (prevNode) {
            prevNode.next = undefined;
        }
        node.prev = undefined;
        this.tailNode = prevNode;
        this.size--;
        return node;
    };
    LRUCache.prototype.detachFromList = function (node) {
        if (this.headerNode === node) {
            this.headerNode = node.next;
        }
        if (this.tailNode === node) {
            this.tailNode = node.prev;
        }
        if (node.prev) {
            node.prev.next = node.next;
        }
        if (node.next) {
            node.next.prev = node.prev;
        }
        node.next = undefined;
        node.prev = undefined;
        this.size--;
    };
    LRUCache.prototype.get = function (key) {
        if (this.nodeMap[key]) {
            var node = this.nodeMap[key];
            this.detachFromList(node);
            this.prependToList(node);
            return node.value;
        }
    };
    LRUCache.prototype.remove = function (key) {
        if (this.nodeMap[key]) {
            var node = this.nodeMap[key];
            this.detachFromList(node);
            delete this.nodeMap[key];
        }
    };
    LRUCache.prototype.put = function (key, value) {
        if (this.nodeMap[key]) {
            this.remove(key);
        }
        else if (this.size === this.sizeLimit) {
            var tailNode = this.removeFromTail();
            var key_1 = tailNode.key;
            delete this.nodeMap[key_1];
        }
        var newNode = new LinkedListNode(key, value);
        this.nodeMap[key] = newNode;
        this.prependToList(newNode);
    };
    LRUCache.prototype.empty = function () {
        var keys = Object.keys(this.nodeMap);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            var node = this.nodeMap[key];
            this.detachFromList(node);
            delete this.nodeMap[key];
        }
    };
    return LRUCache;
}());
exports.LRUCache = LRUCache;
},{}],432:[function(require,module,exports){
// AWS SDK for JavaScript v2.702.0
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// License at https://sdk.amazonaws.com/js/BUNDLE_LICENSE.txt
require('./browser_loader');

var AWS = require('./core');

if (typeof window !== 'undefined') window.AWS = AWS;
if (typeof module !== 'undefined') {
    /**
     * @api private
     */
    module.exports = AWS;
}
if (typeof self !== 'undefined') self.AWS = AWS;

/**
 * @private
 * DO NOT REMOVE
 * browser builder will strip out this line if services are supplied on the command line.
 */
require('../clients/browser_default');

},{"../clients/browser_default":230,"./browser_loader":320,"./core":324}]},{},[432]);
