define("ember-svg-jar/inlined/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 2c1.105 0 2 .9 2 2v8c0 1.1-.895 2-2 2v4l-4.875-4H9c-1.105 0-2-.9-2-2V4c0-1.1.895-2 2-2h13z\" fill=\"#27ae60\"/><path d=\"M22 1c1.105 0 2 .9 2 2v8c0 1.1-.895 2-2 2v4l-4.875-4H9c-1.105 0-2-.9-2-2V3c0-1.1.895-2 2-2h13z\" fill=\"#2ecc71\"/><path d=\"M2 8c-1.105 0-2 .9-2 2v8c0 1.1.895 2 2 2v4l4.875-4H15c1.105 0 2-.9 2-2v-8c0-1.1-.895-2-2-2H2z\" fill=\"#2980b9\"/><path d=\"M2 7C.895 7 0 7.9 0 9v8c0 1.1.895 2 2 2v4l4.875-4H15c1.105 0 2-.9 2-2V9c0-1.1-.895-2-2-2H2z\" fill=\"#3498db\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});