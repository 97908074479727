define("ember-simplemde/helpers/simple-mde-preview", ["exports", "@ember/component/helper", "@ember/template", "@ember/utils", "@ember/debug"], function (_exports, _helper, _template, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.simpleMdePreview = simpleMdePreview;
  /*global SimpleMDE*/

  function simpleMdePreview(params /*, hash*/) {
    (true && !(params.length === 1) && (0, _debug.assert)('This helper requires one string parameter', params.length === 1));
    let plainText = params[0];
    if ((0, _utils.isEmpty)(plainText)) {
      return '';
    }
    (true && !((0, _utils.typeOf)(plainText) === 'string') && (0, _debug.assert)('Parameter should be string', (0, _utils.typeOf)(plainText) === 'string'));
    let rendered = SimpleMDE.prototype.markdown(plainText);
    return (0, _template.htmlSafe)(rendered);
  }
  var _default = _exports.default = (0, _helper.helper)(simpleMdePreview);
});