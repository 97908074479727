define("ember-svg-jar/inlined/question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path style=\"marker:none\" d=\"M50 8C26.804 8 8 26.804 8 50s18.804 42 42 42 42-18.804 42-42S73.196 8 50 8zm0 18c7.708 0 14 6.292 14 14 0 4.575-2.1 8.118-4.688 10.844-2.34 2.465-5.032 4.362-7.312 6.125V62a2 2 0 11-4 0v-6a2 2 0 01.75-1.563c2.6-2.08 5.524-4.064 7.688-6.343C58.6 45.814 60 43.406 60 40a9.97 9.97 0 00-10-10 9.97 9.97 0 00-10 10 2 2 0 11-4 0c0-7.708 6.292-14 14-14zm0 42a3 3 0 110 6 3 3 0 010-6z\" color=\"#000\" fill=\"#979797\" stroke-width=\"3.333\" stroke-linecap=\"round\" stroke-linejoin=\"round\" overflow=\"visible\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});