define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Advanced Search</title><path d=\"M9.734 2.6c-4.104 0-7.43 3.313-7.43 7.404 0 4.091 3.326 7.407 7.43 7.407 4.102 0 7.428-3.316 7.428-7.407 0-4.09-3.326-7.405-7.428-7.405M24.127 24.32l-.033.034a1.733 1.733 0 01-.19.162c-.7.524-1.694.388-2.313-.23l-6.514-6.514a9.449 9.449 0 01-5.341 1.646c-5.234 0-9.478-4.232-9.478-9.452S4.502.513 9.736.513c5.236 0 9.48 4.232 9.48 9.453a9.39 9.39 0 01-1.671 5.354l6.495 6.495c.682.683.752 1.807.087 2.505\" fill=\"#0f3b5e\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});