define("ember-cli-meta-tags/components/head-tags", ["exports", "@ember/array", "@ember/component", "ember-cli-meta-tags/templates/components/head-tags"], function (_exports, _array, _component, _headTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    headTags: (0, _array.A)([]),
    layout: _headTags.default
  });
});