define("ember-autoresize-modifier/modifiers/autoresize", ["exports", "ember-modifier", "@ember/object", "@ember/runloop", "@ember/string"], function (_exports, _emberModifier, _object, _runloop, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AutoresizeModifier = _exports.default = (_class = class AutoresizeModifier extends _emberModifier.default {
    resize() {
      let {
        element
      } = this;
      let dimension = this.args.named.mode ?? 'height';
      let previousWrap = element.style.whiteSpace;
      if (dimension === 'width') {
        // disable default wrapping
        element.style.whiteSpace = 'pre';
      }
      let capitalizeDimension = (0, _string.capitalize)(dimension);

      // height / width must be calculated independently from height / width previously enforced
      element.style[dimension] = 'auto';
      let isBorderBox = window.getComputedStyle(element).boxSizing === 'border-box';
      let requiredDimension = element[`scroll${capitalizeDimension}`];
      if (isBorderBox) {
        // borders must be added on top of scrollHeight / scrollWidth if box-sizing is border-box
        let borderDimension = element[`offset${capitalizeDimension}`] - element[`client${capitalizeDimension}`];
        requiredDimension += borderDimension;
      }
      element.style[dimension] = `${requiredDimension}px`;
      element.style.whiteSpace = previousWrap;
    }
    scheduleResize() {
      (0, _runloop.scheduleOnce)('afterRender', this, 'resize');
    }
    didInstall() {
      // resize for initial value
      this.scheduleResize();

      // resize on every input event
      this.element.addEventListener('input', this.scheduleResize);
    }
    didUpdateArguments() {
      // resize when arguments changes
      this.scheduleResize();
    }
    willRemove() {
      // clean up
      this.element.removeEventListener('input', this.scheduleResize);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "resize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scheduleResize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scheduleResize"), _class.prototype)), _class);
});