define("ember-svg-jar/inlined/profile-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#7f7f7f\"><path d=\"M858 416.4C858 557.56 743.56 672 602.4 672S346.8 557.56 346.8 416.4s114.43-255.6 255.6-255.6c141.16 0 255.6 114.43 255.6 255.6M1042.8 974.4C992.402 834 878.4 724.8 734.4 682.8c-4.8-1.2-9.602-1.2-13.199 1.2-36 19.198-75.602 30-118.8 30-43.199 0-82.801-10.802-118.8-30-3.602-2.4-8.398-2.4-13.199-1.2-144 42-258 151.2-308.4 291.6-14.398 39.602 14.398 80.398 56.398 80.398h769.2c40.797.004 69.598-41.996 55.199-80.398z\"/></g>",
    "attrs": {
      "class": "w-full h-full",
      "height": "1200",
      "width": "1200",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "159 161 887 893"
    }
  };
});