define("ember-cli-meta-tags/services/head-tags", ["exports", "@ember/object/internals", "@ember/service", "@ember/polyfills", "@ember/array", "@ember/object"], function (_exports, _internals, _service, _polyfills, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //TODO: consider polyfilled Set
  const VALID_HEAD_TAGS = (0, _array.A)(['base', 'link', 'meta', 'script', 'noscript', 'title']);
  var _default = _exports.default = _service.default.extend({
    headData: (0, _service.inject)(),
    // crawl up the active route stack and collect head tags
    collectHeadTags() {
      let tags = {};
      let currentHandlerInfos;
      if (true) {
        currentHandlerInfos = this.get('router.targetState.routerJsState.routeInfos');
      } else {
        currentHandlerInfos = this.get('router._routerMicrolib.currentHandlerInfos');
        if (!currentHandlerInfos) {
          currentHandlerInfos = this.get('router.router.currentHandlerInfos');
        }
      }
      let handlerInfos = (0, _array.A)(currentHandlerInfos);
      handlerInfos.forEach(handlerInfo => {
        if (true) {
          (0, _polyfills.assign)(tags, this._extractHeadTagsFromRoute(handlerInfo.route));
        } else {
          (0, _polyfills.assign)(tags, this._extractHeadTagsFromRoute(handlerInfo.handler));
        }
      });
      let tagArray = (0, _array.A)(Object.keys(tags)).map(id => tags[id]);
      this.set('headData.headTags', (0, _array.A)(tagArray));
    },
    _extractHeadTagsFromRoute(route) {
      if (!route) {
        return {};
      }
      let headTags = (0, _object.get)(route, 'headTags');
      if (!headTags) {
        return {};
      }
      if (typeof headTags === 'function') {
        headTags = headTags.apply(route);
      } else if (typeof headTags !== 'object') {
        // not recognized construct
        return {};
      }
      // convert headTags to object
      return this._buildTags(headTags);
    },
    // ensure all tags have a tagId and build object keyed by id
    _buildTags(headTagsArray) {
      let tagMap = {};
      (0, _array.A)(headTagsArray).forEach(function (tagDefinition) {
        if (!tagDefinition || !VALID_HEAD_TAGS.includes(tagDefinition.type)) {
          return;
        }
        let tagId = tagDefinition.tagId;
        if (!tagId) {
          tagId = (0, _internals.guidFor)(tagDefinition);
        }
        tagMap[tagId] = tagDefinition;
      });
      return tagMap;
    }
  });
});