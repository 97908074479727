define("ember-svg-jar/inlined/magnifying-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Magnifying Glass</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#EDF2F7\" fill-rule=\"nonzero\" d=\"M-1222.983-115.733h1345V1779.33h-1345z\"/><g transform=\"translate(-810.563 -6.994)\"><rect fill=\"#F5FAFF\" fill-rule=\"nonzero\" width=\"851\" height=\"46\" rx=\"4\"/><path d=\"M820.476 9.086c-4.104 0-7.429 3.314-7.429 7.405s3.325 7.407 7.429 7.407c4.102 0 7.428-3.316 7.428-7.407s-3.326-7.405-7.428-7.405m14.393 21.721l-.033.034a1.733 1.733 0 01-.189.162c-.7.524-1.695.388-2.314-.23l-6.514-6.514a9.449 9.449 0 01-5.341 1.646c-5.234 0-9.478-4.232-9.478-9.452C811 11.232 815.244 7 820.478 7c5.236 0 9.48 4.232 9.48 9.453a9.39 9.39 0 01-1.671 5.354l6.495 6.495c.682.683.752 1.807.087 2.505\" fill=\"#A4B8CB\"/></g></g>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});