define("ember-promise-modals/templates/components/modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hXLAIQeB",
    "block": "[[[10,0],[15,1,[30,0,[\"modalElementId\"]]],[15,0,[29,[\"epm-modal \",[30,0,[\"optionsClassName\"]],\" \",[30,0,[\"animatingOutClass\"]]]]],[12],[1,\"\\n  \"],[46,[30,1,[\"_name\"]],null,[[\"data\",\"close\"],[[30,1,[\"_data\"]],[28,[37,1],[[30,0],\"close\"],null]]],null],[1,\"\\n\"],[13]],[\"@modal\"],false,[\"component\",\"action\"]]",
    "moduleName": "ember-promise-modals/templates/components/modal.hbs",
    "isStrictMode": false
  });
});