define("ember-composable-helpers/helpers/slice", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;
  function slice([start, end, array]) {
    if (!array) {
      array = [];
    }
    return array.slice(start, end);
  }
  var _default = _exports.default = (0, _helper.helper)(slice);
});