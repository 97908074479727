define("ember-power-select-with-create/components/power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-with-create", "ember-power-select-with-create/templates/components/power-select-with-create"], function (_exports, _powerSelectWithCreate, _powerSelectWithCreate2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _powerSelectWithCreate.default.extend({
    layout: _powerSelectWithCreate2.default,
    powerSelectComponentName: 'power-select-multiple',
    actions: {
      selectOrCreate(selection, select) {
        let suggestion = selection.filter(option => {
          return option.__isSuggestion__;
        })[0];
        if (suggestion) {
          this.onCreate(suggestion.__value__, select);
        } else {
          this.onChange(selection, select);
        }
      }
    }
  });
});