define("ember-svg-jar/inlined/table-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 3.047c-4.938 0-8.955 4.017-8.955 8.953S7.063 20.953 12 20.953s8.954-4.016 8.954-8.952S16.938 3.047 12 3.047zM20.285 12c0 4.567-3.716 8.284-8.285 8.284S3.715 16.568 3.715 12c0-4.568 3.717-8.285 8.285-8.285S20.285 7.432 20.285 12z\"/><path d=\"M16.138 11.639l-6.123-3.061a.428.428 0 00-.483.073.43.43 0 00-.122.303v6.094a.41.41 0 00.407.422.454.454 0 00.199-.047l6.121-3.063c.148-.073.236-.208.236-.36s-.087-.288-.235-.361zm-.776.361l-5.282 2.641V9.36L15.362 12z\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});