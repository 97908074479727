define("ember-promise-modals/services/modals", ["exports", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/service", "ember-promise-modals/modal"], function (_exports, _array, _object, _computed, _service, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    count: (0, _object.computed)('_stack.@each.isClosing', function () {
      return this._stack.filter(modal => !modal.isClosing).length;
    }),
    top: (0, _computed.alias)('_stack.lastObject'),
    clickOutsideDeactivates: true,
    init() {
      this._super(...arguments);
      this._stack = (0, _array.A)([]);
    },
    willDestroy() {
      this._onLastModalRemoved();
    },
    open(name, data, options) {
      let modal = new _modal.default(this, name, data, options);
      this._stack.pushObject(modal);
      if (this._stack.length === 1) {
        this._onFirstModalAdded();
      }
      return modal;
    },
    _onFirstModalAdded() {
      document.body.classList.add('epm-scrolling-disabled');
    },
    _onLastModalRemoved() {
      document.body.classList.remove('epm-scrolling-disabled');
    },
    _onModalAnimationStart() {
      document.body.classList.add('epm-animating');
    },
    _onModalAnimationEnd() {
      document.body.classList.remove('epm-animating');
    }
  });
});