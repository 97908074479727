define("ember-svg-jar/inlined/calendar-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time</title><rect x=\"12\" y=\"30\" width=\"10\" height=\"8\" rx=\"1.993\" fill=\"#7F7F7F\"/><rect x=\"27\" y=\"30\" width=\"10\" height=\"8\" rx=\"1.993\" fill=\"#7F7F7F\"/><rect x=\"42\" y=\"30\" width=\"10\" height=\"8\" rx=\"1.993\" fill=\"#7F7F7F\"/><rect x=\"12\" y=\"44\" width=\"10\" height=\"8\" rx=\"1.993\" fill=\"#7F7F7F\"/><rect x=\"27\" y=\"44\" width=\"10\" height=\"8\" rx=\"1.993\" fill=\"#7F7F7F\"/><path fill=\"#7F7F7F\" d=\"M53.63 7H50V4a2 2 0 00-4 0v3H18V4a2 2 0 00-4 0v3h-3.63A8.378 8.378 0 002 15.37v38.26A8.378 8.378 0 0010.37 62h43.26A8.378 8.378 0 0062 53.63V15.37A8.378 8.378 0 0053.63 7zm-43.26 4H14v4a2 2 0 004 0v-4h28v4a2 2 0 004 0v-4h3.63A4.374 4.374 0 0158 15.37V20H6v-4.63A4.374 4.374 0 0110.37 11zm43.26 47H10.37A4.374 4.374 0 016 53.63V24h52v29.63A4.374 4.374 0 0153.63 58z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 2",
      "viewBox": "0 0 64 64"
    }
  };
});