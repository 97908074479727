define("ember-svg-jar/inlined/calendar-blank-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M72.5 85H16.957c-4.93 0-8.944-4.014-8.944-8.944v-55.54c0-4.93 4.014-8.945 8.944-8.945h55.541c4.937 0 8.944 4.014 8.944 8.944v55.541C81.45 80.993 77.436 85 72.499 85zM16.957 15.226a5.297 5.297 0 00-5.29 5.29v55.54a5.297 5.297 0 005.29 5.289h55.541c2.916 0 5.296-2.373 5.296-5.289v-55.54a5.297 5.297 0 00-5.296-5.29H16.958z\" fill=\"#4a5568\"/><path d=\"M29.196 21.073c-1.01 0-1.824-.82-1.824-1.824V6.825c0-1.011.82-1.824 1.824-1.824s1.832.82 1.832 1.824v12.424a1.84 1.84 0 01-1.832 1.824zM59.979 21.072a1.83 1.83 0 01-1.831-1.824V6.824c0-1.01.82-1.824 1.83-1.824 1.012 0 1.825.82 1.825 1.824v12.424a1.82 1.82 0 01-1.824 1.824zM79.614 32.22H9.83A1.83 1.83 0 018 30.396a1.83 1.83 0 011.831-1.825h69.783c1.01 0 1.83.821 1.83 1.825a1.83 1.83 0 01-1.83 1.824z\" fill=\"#4a5568\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 90 90",
      "fill": "none"
    }
  };
});