define("ember-svg-jar/inlined/darcy-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Darcy Logo</title><g bx:origin=\"0 0\"><path d=\"M.131 6.717c.469.319 2.077.325 2.483-.021.543-1.78 1.933-2.678 3.722-2.398.302.047.284.12.515-.11.28-.277.591-.75.744-.992.055-.087.074-.095.169-.313.22-.5.338-.672-.08-.9A22.253 22.253 0 005.144.78C4.321.458 2.515-.17 1.682.337L1.66.351l-.022.011C.974.708.67 1.89.53 2.567.249 3.928.18 5.33.13 6.717M9.062 3.197c-.588.493-.88 1.015-1.013 1.792-.046.267.062.263.218.475C9.272 6.829 9.32 8.239 8.222 9.61c-.11.137-.14.116-.14.3.002.586.49 1.519.924 1.958.209.211.276.183.51.04 1.434-.868 4.49-2.985 4.457-4.462v-.05c.032-.75-.84-1.607-1.36-2.068-.95-.847-1.908-1.486-2.965-2.153-.346-.218-.265-.247-.586.021\" fill=\"#304965\"/><g transform=\"translate(.025 8.036)\"><mask id=\"a\" fill=\"#fff\"><path d=\"M0 .006h7.879v6.811H0z\"/></mask><path d=\"M7.622 3.809c-.188-.32-.683-1.038-1.019-1.216-1.818.35-3.235-.32-3.933-2.082-.085-.215-.108-.3-.355-.366C1.603-.048-.013-.097 0 .37.035 2.06.291 5.868 1.607 6.602l.019.011.019.012c.646.406 1.835.078 2.505-.144 1.178-.39 2.054-.813 3.154-1.39.665-.348.727-.586.318-1.282\" fill=\"#304965\" mask=\"url(#a)\"/></g><path d=\"M9.32.818a.329.329 0 110 .658.329.329 0 010-.658M5.71 5.21a2.219 2.219 0 110 4.438 2.219 2.219 0 010-4.437m.765.573a.222.222 0 110 .444.222.222 0 010-.444m-1.583.43a.444.444 0 110 .89.444.444 0 010-.89M8.831 2.13a.397.397 0 110 .795.397.397 0 010-.795\" fill=\"#A7B5C0\"/></g>",
    "attrs": {
      "viewBox": "0 0 14 15",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:bx": "https://boxy-svg.com"
    }
  };
});