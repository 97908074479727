define("ember-svg-jar/inlined/play-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<switch><g><path d=\"M50 2.5C23.8 2.5 2.5 23.8 2.5 50S23.8 97.5 50 97.5 97.5 76.2 97.5 50 76.2 2.5 50 2.5zm15.3 49.9L42.9 67.3c-1.9 1.3-4.5-.1-4.5-2.4V35.1c0-2.3 2.6-3.7 4.5-2.4l22.4 14.9c1.7 1.1 1.7 3.7 0 4.8z\" fill=\"#979797\"/></g></switch>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});