define("ember-svg-jar/inlined/information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.fil0{fill:#000}</style></defs><path class=\"fil0\" d=\"M240.19 83.54c16.85-.8 31.16 12.22 31.95 29.07.8 16.86-12.22 31.17-29.07 31.96-16.85.8-31.16-12.22-31.96-29.08-.79-16.85 12.23-31.16 29.08-31.95zM179.91 223.86l-5.71-15.12c-.31-.84-.2-1.7.31-2.44 13.11-18.64 32.95-35.49 57.23-33.12 15.77 1.52 26.63 16.48 23.22 31.94-8.82 39.95-18.27 79.76-27.09 119.7-1.2 5.44 3.96 10.08 9.24 8.32 9-3.01 17.41-14.54 22.86-21.97 1.24-1.69 3.85-1.33 4.6.63l5.69 15.11c.32.84.22 1.71-.3 2.45-13.12 18.64-32.95 35.48-57.24 33.11-15.76-1.53-26.63-16.47-23.21-31.94 8.82-39.95 18.27-79.75 27.08-119.7 1.2-5.44-3.95-10.08-9.23-8.31-9 3-17.4 14.52-22.87 21.96-1.24 1.69-3.85 1.33-4.58-.62z\"/><path d=\"M223.1 0c123.12 0 223.1 99.98 223.1 223.1 0 123.12-99.98 223.1-223.1 223.1C99.98 446.2 0 346.22 0 223.1 0 99.98 99.98 0 223.1 0zm0 22.86c-110.66 0-200.24 89.58-200.24 200.24 0 110.66 89.58 200.24 200.24 200.24 110.66 0 200.24-89.58 200.24-200.24 0-110.66-89.58-200.24-200.24-200.24z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 446.2 446.2",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "shape-rendering": "geometricPrecision",
      "text-rendering": "geometricPrecision",
      "image-rendering": "optimizeQuality"
    }
  };
});