define("ember-filestack/utils/sanitize-transformations", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitizeTransformations;
  function sanitizeTransformations(obj) {
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      let type = typeof value;
      if (type === 'object' && value !== null) {
        sanitizeTransformations(value);
        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      } else if ((0, _utils.isNone)(value)) {
        delete obj[key];
      }
    });
  }
});