define("ember-cli-notifications/templates/components/notification-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "+3BjWwu2",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"notifications\",\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"notification\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"notification\"],false,[\"each\",\"-track-array\",\"notification-message\"]]",
    "moduleName": "ember-cli-notifications/templates/components/notification-container.hbs",
    "isStrictMode": false
  });
});