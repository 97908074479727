define("ember-cli-notifications/components/notification-message", ["exports", "@ember/component", "ember", "@ember/template", "@ember/object", "@ember/service", "ember-cli-notifications/templates/components/notification-message"], function (_exports, _component, _ember, _template, _object, _service, _notificationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/no-classic-classes, prettier/prettier, ember/no-get, ember/no-actions-hash */
  var _default = _exports.default = _component.default.extend({
    layout: _notificationMessage.default,
    tagName: '',
    notifications: (0, _service.inject)(),
    paused: false,
    dismissClass: (0, _object.computed)('notification.dismiss', function () {
      if (!this.get('notification.dismiss')) return 'c-notification--in';
      return false;
    }),
    clickableClass: (0, _object.computed)('notification.onClick', function () {
      if (this.get('notification.onClick')) return 'c-notification--clickable';
      return false;
    }),
    notificationSVGPath: (0, _object.computed)('notification.type', function () {
      switch (this.get('notification.type')) {
        case "error":
        case "info":
          return 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z';
        case "success":
          return 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z';
        case "warning":
          return 'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z';
      }
      return '';
    }),
    processedType: (0, _object.computed)('notification.type', function () {
      if (this.get('notification.type') && ['info', 'success', 'warning', 'error'].indexOf(this.get('notification.type')) !== -1) {
        return `c-notification--${this.get('notification.type')}`;
      }
      return '';
    }),
    // Apply the clear animation duration rule inline
    notificationClearDuration: (0, _object.computed)('paused', 'notification.clearDuration', function () {
      const duration = _ember.default.Handlebars.Utils.escapeExpression(this.get('notification.clearDuration'));
      const playState = this.get('paused') ? 'paused' : 'running';
      return (0, _template.htmlSafe)(`animation-duration: ${duration}ms; -webkit-animation-duration: ${duration}ms; animation-play-state: ${playState}; -webkit-animation-play-state: ${playState}`);
    }),
    actions: {
      handleOnClick() {
        if (this.get('notification.onClick')) {
          this.get('notification.onClick')(this.get('notification'));
        }
      },
      removeNotification() {
        this.get('notifications').removeNotification(this.get('notification'));
      },
      handleMouseEnter() {
        if (this.get('notification.autoClear')) {
          this.set('paused', true);
          this.notifications.pauseAutoClear(this.get('notification'));
        }
      },
      handleMouseLeave() {
        if (this.get('notification.autoClear')) {
          this.set('paused', false);
          this.notifications.setupAutoClear(this.get('notification'));
        }
      }
    }
  });
});