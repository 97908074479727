define("ember-svg-jar/inlined/comments-badge-black", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 23h24a2 2 0 000-4H20a2 2 0 000 4zM20 35h24a2 2 0 000-4H20a2 2 0 000 4z\"/><path d=\"M60 18.999c0-7.733-6.268-14-14-14H18c-7.733 0-14 6.267-14 14v16c0 7.732 6.267 14 14 14h26.686c1.59 0 3.116.632 4.243 1.756 2.953 2.957 7.655 7.658 7.655 7.658A2.002 2.002 0 0059.999 57zm-4 33.17V19c0-5.523-4.478-10-10-10H18c-5.524 0-10 4.477-10 10v16c0 5.522 4.476 10 10 10h26.686a9.993 9.993 0 017.07 2.928z\"/>",
    "attrs": {
      "height": "300",
      "width": "300",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 64",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
});