define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#BBB\" d=\"M48.4 27.6h-1.5v-6.9C46.9 10.4 38.6 2 28.3 2 18 1.9 9.6 10.3 9.6 20.7v6.9H8.1c-3.1 0-5.7 2.6-5.7 5.7v27.1c0 3.2 2.5 5.7 5.7 5.7h40.3c3.1 0 5.7-2.6 5.7-5.7V33.3c-.1-3.2-2.6-5.7-5.7-5.7zM30.6 47.2v3.5c0 .5-.4 1-1 1h-2.8c-.5 0-.9-.4-.9-1v-3.5c-1.3-.8-2.2-2.2-2.2-3.9 0-2.5 2-4.6 4.5-4.6s4.5 2 4.5 4.6c.1 1.7-.8 3.1-2.1 3.9zm8.1-19.6H17.8v-6.9c0-5.8 4.7-10.5 10.4-10.5 5.8 0 10.4 4.7 10.4 10.5v6.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 56.3 68.1"
    }
  };
});