define("ember-svg-jar/inlined/storefront-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g data-name=\"Group\" fill=\"#7F7F7F\"><path data-name=\"Compound Path\" d=\"M94.55 8H5.45v11.09H3.37v6.56a9.39 9.39 0 002.08 5.89V92h89.1V31.54a9.39 9.39 0 002.08-5.89v-6.56h-2.08zm-85.1 4h81.1v7.11H9.45zm83.18 13.65a5.44 5.44 0 01-10.88 0v-2.56h10.88zm-14.88 0v1.65a7.92 7.92 0 01-15.83 0v-4.21h15.83zM57.92 27.3a7.92 7.92 0 01-15.83 0v-4.21h15.83zm-35.67-1.65v-2.56h15.83v4.21a7.92 7.92 0 11-15.83 0zm-14.88 0v-2.56h10.88v2.56a5.44 5.44 0 11-10.88 0zM57.92 88H42.08V61.5h15.84zm4 0V57.5H38.08V88H9.45V34.46a9.37 9.37 0 0010-2.07 11.9 11.9 0 0020.63 1.51 11.9 11.9 0 0019.83 0 11.9 11.9 0 0020.69-1.51 9.37 9.37 0 0010 2.07V88z\"/><path data-name=\"Compound Path\" d=\"M14.48 74.53h18v-17h-18zm4-13h10v9h-10zM67.48 74.53h18v-17h-18zm4-13h10v9h-10z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 100 100"
    }
  };
});