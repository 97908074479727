define("ember-fullscreen/services/fullscreen", ["exports", "@ember/object", "@ember/runloop", "@ember/object/evented", "@ember/service", "screenfull"], function (_exports, _object, _runloop, _evented, _service, _screenfull) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend(_evented.default, {
    screenfull: _screenfull.default,
    init() {
      this._super(...arguments);
      this.setupListeners();
    },
    setupListeners() {
      if (this.get('isAvailable')) {
        this.updateEnabled();
        this.handler = (0, _runloop.bind)(this, this.updateEnabled);
        this.errorHandler = (0, _runloop.bind)(this, this.onError);
        this.screenfull.on('change', this.handler);
        this.screenfull.on('error', this.errorHandler);
      }
    },
    willDestroy() {
      if (this.get('isAvailable')) {
        this.screenfull.off('change', this.handler);
        this.screenfull.off('error', this.errorHandler);
      }
    },
    isEnabled: false,
    isAvailable: (0, _object.computed)(function () {
      return this.screenfull && this.screenfull.enabled;
    }),
    updateEnabled() {
      let isFullscreenEnabled = this.screenfull.isFullscreen;
      this.set('isEnabled', isFullscreenEnabled);
      this.trigger('fullscreenChange', isFullscreenEnabled);
    },
    onError(event) {
      this.trigger('error', event);
    },
    enable(elem) {
      this.screenfull && this.screenfull.request(elem);
    },
    disable() {
      this.screenfull && this.screenfull.exit();
    },
    toggle(elem) {
      this.screenfull && this.screenfull.toggle(elem);
    }
  });
});