define("ember-power-select-with-create/components/power-select-with-create", ["exports", "@ember/array", "@ember/debug", "@ember/component", "@ember/object", "rsvp", "ember-power-select-with-create/templates/components/power-select-with-create", "ember-power-select/utils/group-utils"], function (_exports, _array, _debug, _component, _object, _rsvp, _powerSelectWithCreate, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _powerSelectWithCreate.default,
    matcher: _groupUtils.defaultMatcher,
    suggestedOptionComponent: 'power-select-with-create/suggested-option',
    powerSelectComponentName: 'power-select',
    searchEnabled: true,
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      (true && !(this.onCreate && typeof this.onCreate === 'function') && (0, _debug.assert)('<PowerSelectWithCreate> requires an `onCreate` function', this.onCreate && typeof this.onCreate === 'function'));
    },
    // CPs
    optionsArray: (0, _object.computed)('options.[]', function () {
      let options = this.get('options');
      if (!options) {
        return (0, _array.A)();
      }
      if (options.then) {
        return options.then(value => (0, _array.A)(value).toArray());
      } else {
        return (0, _array.A)(options).toArray();
      }
    }),
    shouldShowCreateOption(term, options) {
      return this.get('showCreateWhen') ? this.get('showCreateWhen')(term, options) : true;
    },
    addCreateOption(term, results) {
      if (this.shouldShowCreateOption(term, results)) {
        if (this.get('showCreatePosition') === 'bottom') {
          results.push(this.buildSuggestionForTerm(term));
        } else {
          results.unshift(this.buildSuggestionForTerm(term));
        }
      }
    },
    actions: {
      searchAndSuggest(term, select) {
        return _rsvp.default.resolve(this.get('optionsArray')).then(newOptions => {
          if (term.length === 0) {
            return newOptions;
          }
          let searchAction = this.get('search');
          if (searchAction) {
            return (0, _rsvp.resolve)(searchAction(term, select)).then(results => {
              if (results.toArray) {
                results = results.toArray();
              }
              this.addCreateOption(term, results);
              return results;
            });
          }
          newOptions = this.filter((0, _array.A)(newOptions), term);
          this.addCreateOption(term, newOptions);
          return newOptions;
        });
      },
      selectOrCreate(selection, select, e) {
        if (selection && selection.__isSuggestion__) {
          this.onCreate(selection.__value__, select, e);
        } else {
          this.onChange(selection, select, e);
        }
      }
    },
    // Methods
    filter(options, searchText) {
      let matcher;
      if (this.searchField) {
        matcher = (option, text) => this.matcher((0, _object.get)(option, this.searchField), text);
      } else {
        matcher = (option, text) => this.matcher(option, text);
      }
      return (0, _groupUtils.filterOptions)(options || [], searchText, matcher);
    },
    buildSuggestionForTerm(term) {
      return {
        __isSuggestion__: true,
        __value__: term,
        text: this.buildSuggestionLabel(term)
      };
    },
    buildSuggestionLabel(term) {
      if (this.buildSuggestion) {
        return this.buildSuggestion(term);
      }
      return `Add "${term}"...`;
    }
  });
});