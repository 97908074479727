define("ember-svg-jar/inlined/meeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 4c-1.105 0-2 .9-2 2v14c0 1.1.895 2 2 2h14c1.105 0 2-.9 2-2V6c0-1.1-.895-2-2-2H5z\" fill=\"#bdc3c7\"/><path d=\"M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5z\" fill=\"#ecf0f1\"/><path d=\"M5 3a2 2 0 00-2 2v4h18V5a2 2 0 00-2-2H5z\" fill=\"#e74c3c\"/><path d=\"M7.5 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 113 0z\" fill=\"#c0392b\"/><path d=\"M6 1a1 1 0 00-1 1v3a1 1 0 102 0V2a1 1 0 00-1-1z\" fill=\"#bdc3c7\"/><path d=\"M19.5 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 113 0z\" fill=\"#c0392b\"/><g fill=\"#bdc3c7\"><path d=\"M18 1c-.552 0-1 .4-1 1v3c0 .5.448 1 1 1s1-.5 1-1V2c0-.6-.448-1-1-1zM5 11v2h2v-2H5zm3 0v2h2v-2H8zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zM5 14v2h2v-2H5zm3 0v2h2v-2H8zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zM5 17v2h2v-2H5zm3 0v2h2v-2H8zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});