define("ember-svg-jar/inlined/back-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M64.2 24.4c-1.6-1.6-4.1-1.6-5.7 0L35.8 47.2c-1.6 1.6-1.6 4.1 0 5.7l22.8 22.8c.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2c1.6-1.6 1.6-4.1 0-5.7L44.3 50l19.9-19.9c1.6-1.6 1.6-4.1 0-5.7z\" fill=\"#E2E8F0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "25 25 50 50"
    }
  };
});