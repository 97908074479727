define("ember-svg-jar/inlined/reaction-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Group 2-like</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#BCE6F8\" cx=\"15\" cy=\"15\" r=\"15\"/><path d=\"M23.55 13.604c0-1.008-.81-1.818-1.818-1.818h-3.78c.36-.774.774-1.962.774-3.24 0-1.206-.648-2.16-1.836-2.718a1.641 1.641 0 00-1.422 0c-.45.216-.774.612-.9 1.098l-.234.882c-.414 1.584-1.278 2.97-2.448 3.906-.216.18-.45.324-.684.468a1.053 1.053 0 00-.81-.378h-2.88c-.576 0-1.062.468-1.062 1.062v8.55c0 .576.468 1.062 1.062 1.062h2.862c.36 0 .666-.18.864-.45 1.746.504 3.348.756 5.094.756h4.176c1.008 0 1.818-.81 1.818-1.818 0-.324-.09-.648-.234-.9.504-.324.846-.9.846-1.53 0-.324-.09-.63-.234-.9.504-.324.846-.9.846-1.53 0-.468-.18-.9-.468-1.224.288-.378.468-.81.468-1.278zM8.952 21.002a.768.768 0 01-.774-.774c0-.432.342-.774.774-.774s.774.342.774.774a.78.78 0 01-.774.774zM20.58 15.44h1.152c.342 0 .63.288.63.63s-.27.63-.63.63h-1.368a.6.6 0 00-.594.594.6.6 0 00.594.594h.756c.342 0 .63.288.63.63s-.27.63-.63.63h-1.206a.6.6 0 00-.594.594.6.6 0 00.594.594h.612c.342 0 .63.288.63.63s-.27.63-.63.63h-4.194c-1.674 0-3.204-.252-4.896-.756v-7.434c.396-.198.792-.45 1.17-.756 1.368-1.098 2.394-2.7 2.862-4.554l.234-.882a.5.5 0 01.252-.306.428.428 0 01.396 0c.774.36 1.152.882 1.152 1.62 0 1.836-1.062 3.528-1.062 3.528a.618.618 0 00-.018.612.584.584 0 00.522.306h4.788c.342 0 .63.288.63.63s-.27.63-.63.63H20.58a.603.603 0 000 1.206z\" fill=\"#000\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});