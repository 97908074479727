define("ember-cli-meta-tags/initializers/router-head-tags", ["exports", "@ember/routing/router", "@ember/object", "@ember/service"], function (_exports, _router, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    if (true) {
      _router.default.reopen({
        headTags: (0, _service.inject)(),
        init() {
          this._super(...arguments);
          this.on('routeDidChange', () => {
            (0, _object.get)(this, 'headTags').collectHeadTags();
            this._super(...arguments);
          });
        }
      });
    } else {
      _router.default.reopen({
        headTags: (0, _service.inject)(),
        didTransition() {
          (0, _object.get)(this, 'headTags').collectHeadTags();
          this._super(...arguments);
        }
      });
    }
  }
  var _default = _exports.default = {
    name: 'router-head-tags',
    after: 'head-tags',
    initialize
  };
});