define("ember-svg-jar/inlined/user-connection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#bdc3c7\"><path d=\"M20.899 7.928a3.899 3.943 0 11-7.798 0 3.899 3.943 0 117.798 0z\"/><path d=\"M17 11.083c-.996 0-1.945.158-2.803.552a7.161 7.161 0 00-4.167 5.757c0 .867.65 1.577 1.511 1.577H22.46c.861 0 1.51-.71 1.51-1.577a7.162 7.162 0 00-4.166-5.757c-.858-.394-1.807-.552-2.803-.552z\"/></g><g fill=\"#7f8c8d\"><path d=\"M10.899 7.928a3.899 3.943 0 11-7.798 0 3.899 3.943 0 117.798 0z\"/><path d=\"M7 11.083c-.996 0-1.945.158-2.803.552A7.161 7.161 0 00.03 17.392c0 .867.65 1.577 1.511 1.577H12.46c.861 0 1.51-.71 1.51-1.577a7.162 7.162 0 00-4.166-5.757c-.858-.394-1.807-.552-2.803-.552z\"/></g><path d=\"M8.406 12.7c-2.885 1.3-4.978 4-5.344 7.3 0 1.1.833 2 1.938 2h14c1.105 0 1.938-.9 1.938-2-.366-3.3-2.459-6-5.344-7.3C14.945 14 13.583 15 12 15s-2.945-1-3.594-2.3z\" fill=\"#2c3e50\"/><path d=\"M17 7A5 5 0 117 7a5 5 0 1110 0z\" fill=\"#34495e\"/><path d=\"M12 11a8.952 8.952 0 00-3.594.75A9.025 9.025 0 003.062 19c0 1.105.833 2 1.938 2h14c1.105 0 1.938-.895 1.938-2a9.027 9.027 0 00-5.344-7.25A8.955 8.955 0 0012 11z\" fill=\"#34495e\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});