define("ember-drag-sort/templates/components/drag-sort-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7xG66UGV",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[6,[39,3],null,[[\"additionalArgs\",\"item\",\"index\",\"items\",\"group\",\"handle\",\"class\",\"tagName\",\"isHorizontal\",\"isRtl\",\"draggingEnabled\",\"dragEndAction\",\"determineForeignPositionAction\",\"sourceOnly\"],[[33,4],[30,1],[30,2],[33,2],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14]]],[[\"default\"],[[[[1,\"    \"],[18,3,[[30,1],[30,2]]],[1,\"\\n\"]],[]]]]]],[1,2]],null]],[\"item\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"items\",\"drag-sort-item\",\"additionalArgs\",\"group\",\"handle\",\"childClass\",\"childTagName\",\"isHorizontal\",\"isRtl\",\"draggingEnabled\",\"dragEndAction\",\"determineForeignPositionAction\",\"sourceOnly\",\"yield\"]]",
    "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs",
    "isStrictMode": false
  });
});