define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mini chat</title><path d=\"M31.72 90.32h2.62c8.59 0 15.66-.11 22.22-.3 4.15-.12 9-1.42 14.55-3.85C84.48 80.26 92.89 68.7 93.6 55.25v-.51c.15-1.1.26-2.21.31-3.31.47-10.25-3.48-20.31-11.13-28.32A43 43 0 0051.93 9.68 42 42 0 0016.2 73.84l1.11 1.79-.25 2.09a11.89 11.89 0 01-1.41 4.39c-1.57 2.74-3.19 5.41-4.87 8.13h5.34c5.13.05 10.43.08 15.6.08z\" fill=\"none\"/><path d=\"M0 96.22c10.75 0 21.28.1 31.72.1h2.47c7.55 0 15-.09 22.54-.3 5.65-.16 11.56-2 16.8-4.36C89.85 84.44 98.82 70.2 99.6 55.57q.27-1.92.36-3.86c1.18-25.51-21.45-48-48-48A48 48 0 0011.1 77a5.9 5.9 0 01-.65 2.13C7.23 84.74 3.73 90.18 0 96.22zm15.65-14.1a11.89 11.89 0 001.41-4.39l.25-2.09-1.11-1.8A42 42 0 0151.93 9.68a43 43 0 0130.9 13.43c7.65 8 11.6 18.07 11.13 28.32-.05 1.09-.15 2.21-.31 3.31v.52c-.72 13.45-9.13 25-22.49 30.91-5.56 2.43-10.45 3.72-14.6 3.83-6.56.19-13.63.29-22.22.3h-2.62c-5.16 0-10.47 0-15.59-.05h-5.34c1.67-2.72 3.29-5.4 4.86-8.13z\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "fill": "#4C5566",
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 100 100"
    }
  };
});