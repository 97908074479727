define("ember-composable-helpers/helpers/compact", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;
  function compact([value]) {
    let array;
    if (Array.isArray(value)) {
      array = value;
    } else {
      array = [value];
    }
    return array.filter(item => (0, _utils.isPresent)(item));
  }
  var _default = _exports.default = (0, _helper.helper)(compact);
});