define("ember-link/components/link/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "JdkblEOr",
    "block": "[[[18,7,[[28,[37,1],null,[[\"route\",\"models\",\"model\",\"query\",\"fromURL\",\"preventDefault\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6]]]]]]],[\"@route\",\"@models\",\"@model\",\"@query\",\"@fromURL\",\"@preventDefault\",\"&default\"],false,[\"yield\",\"link\"]]",
    "moduleName": "ember-link/components/link/template.hbs",
    "isStrictMode": false
  });
});