define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"nonzero\"><path d=\"M448 512H64c-35.328 0-64-28.672-64-64V64C0 28.672 28.672 0 64 0h384c35.328 0 64 28.672 64 64v384c0 35.328-28.672 64-64 64z\" fill=\"#1da1f2\"/><path d=\"M196.608 386.048c120.704 0 186.752-100.096 186.752-186.752 0-2.816 0-5.632-.128-8.448 12.8-9.216 23.936-20.864 32.768-34.048-11.776 5.248-24.448 8.704-37.76 10.368 13.568-8.064 23.936-20.992 28.928-36.352-12.672 7.552-26.752 12.928-41.728 15.872-12.032-12.8-29.056-20.736-47.872-20.736-36.224 0-65.664 29.44-65.664 65.664 0 5.12.64 10.112 1.664 14.976-54.528-2.688-102.912-28.928-135.296-68.608-5.632 9.728-8.832 20.992-8.832 33.024 0 22.784 11.648 42.88 29.184 54.656a66.39 66.39 0 01-29.696-8.192v.896c0 31.744 22.656 58.368 52.608 64.384-5.504 1.536-11.264 2.304-17.28 2.304-4.224 0-8.32-.384-12.288-1.152 8.32 26.112 32.64 45.056 61.312 45.568-22.528 17.664-50.816 28.16-81.536 28.16-5.248 0-10.496-.256-15.616-.896 28.928 18.432 63.488 29.312 100.48 29.312\" fill=\"#fff\"/></g>",
    "attrs": {
      "viewBox": "0 0 512 512",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
});