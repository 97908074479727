define("ember-composable-helpers/helpers/next", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/get-index", "@ember/utils", "@ember/array", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params"], function (_exports, _helper, _getIndex, _utils, _array, _getValueArrayAndUseDeepEqualFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.next = next;
  function next(currentValue, array, useDeepEqual = false) {
    if (!array) {
      array = [];
    }
    let currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);
    let lastIndex = array.length - 1;
    if ((0, _utils.isEmpty)(currentIndex)) {
      return;
    }
    return currentIndex === lastIndex ? currentValue : (0, _array.A)(array).objectAt(currentIndex + 1);
  }
  var _default = _exports.default = (0, _helper.helper)(function (params) {
    let {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return next(currentValue, array, useDeepEqual);
  });
});