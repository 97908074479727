define("ember-svg-jar/inlined/monthly-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M448.86 312.83a30.163 30.163 0 00-30.23 30.23v90.688a30.163 30.163 0 0030.23 30.23 30.163 30.163 0 0030.23-30.23V343.06a30.163 30.163 0 00-30.23-30.23zm302.29 0a30.163 30.163 0 00-30.23 30.23v90.688a30.163 30.163 0 0030.23 30.23 30.163 30.163 0 0030.23-30.23V343.06a30.163 30.163 0 00-30.23-30.23zm-423.2 75.57c-16.746 0-30.23 13.484-30.23 30.23v90.688h604.57V418.63c0-16.746-13.484-30.23-30.23-30.23h-75.57v45.344c0 24.859-20.484 45.344-45.344 45.344s-45.344-20.484-45.344-45.344V388.4h-211.6v45.344c0 24.859-20.484 45.344-45.344 45.344-24.859 0-45.344-20.484-45.344-45.344V388.4zm-30.23 136.03v332.52c0 16.746 13.484 30.23 30.23 30.23h544.11c16.746 0 30.23-13.484 30.23-30.23V524.43z\" fill=\"#394962\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "297.72 312.83 604.57 574.35"
    }
  };
});