define("ember-filestack/components/filestack-preview/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/polyfills", "@ember/service"], function (_exports, _component, _object, _computed, _polyfills, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    filestack: (0, _service.inject)(),
    // id option defaults to the component's elementId
    id: (0, _computed.reads)('elementId'),
    // All the possible preview options taken from https://filestack.github.io/filestack-js/interfaces/previewoptions.html
    previewOptions: Object.freeze(['css', 'id']),
    // gathers all the options to initialize picker
    gatheredOptions: (0, _object.computed)('previewOptions.[]', function () {
      let options = {};
      this.previewOptions.forEach(o => {
        let value = this.get(o);
        if (value !== undefined) {
          options[o] = value;
        }
      });
      return options;
    }),
    didInsertElement() {
      this._super(...arguments);
      this.initPreview();
    },
    async initPreview() {
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      let handle = this.handle;
      if (!handle) {
        throw new Error('Attempted to preview file without setting mandatory `handle` argument.');
      }
      try {
        await this.filestack.initClient();
        let options = this.options || {};
        let gatheredOptions = this.gatheredOptions;
        let pickerOptions = (0, _polyfills.assign)({}, options, gatheredOptions);
        this.filestack.preview(handle, pickerOptions);
      } catch (e) {
        if (this.onError) {
          this.onError(e);
        } else {
          throw e;
        }
      }
    }
  });
});