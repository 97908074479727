define("ember-svg-jar/inlined/table-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M32.203 34.38h17.793a1 1 0 000-2H32.203a1 1 0 000 2zM67.789 41.375H32.203a1 1 0 000 2h35.586a1 1 0 100-2zM67.789 50.369H32.203a1 1 0 000 2h35.586a1 1 0 100-2zM67.789 59.363H32.203a1 1 0 100 2h35.586a1 1 0 100-2zM66.816 68.357H32.203a1 1 0 100 2h34.614a1 1 0 10-.001-2z\"/><path d=\"M77.675 34.617L57.366 14.309a1 1 0 00-.343-.233c-.077-.032-.16-.038-.242-.049-.046-.006-.086-.027-.133-.027H23.016a1 1 0 00-1 1v70a1 1 0 001 1h53.967a1 1 0 001-1V35.335a.997.997 0 00-.308-.718zM57.648 17.419l16.916 16.916H57.648V17.419zM24.016 84V16h31.632v19.335a1 1 0 001 1h19.335V84H24.016z\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "xmlns": "http://www.w3.org/2000/svg",
      "version": "1.0",
      "viewBox": "0 0 100 100"
    }
  };
});