define("ember-promise-modals/components/modal-container", ["exports", "@ember/component", "@ember/service", "ember-promise-modals/templates/components/modal-container"], function (_exports, _component, _service, _modalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _modalContainer.default,
    tagName: '',
    modals: (0, _service.inject)()
  });
});