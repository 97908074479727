define("ember-svg-jar/inlined/notification-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#7F7F7F\" d=\"M317.139 401.576c-1.889-1.277-1.929-2.909-1.832-5.684a15.356 15.356 0 00-.437-5.168c-1.234-4.011-4.183-6.519-7.98-6.862v-.844a.905.905 0 00-1.809 0v.844c-3.799.343-6.748 2.851-7.979 6.862a15.384 15.384 0 00-.437 5.168c.096 2.773.061 4.407-1.832 5.684a2.905 2.905 0 00-1.225 3.295 2.446 2.446 0 002.284 1.819h6.596c.37 2.319 1.986 3.176 3.497 3.176s3.127-.856 3.498-3.176h6.595a2.446 2.446 0 002.284-1.819 2.907 2.907 0 00-1.223-3.295zm-11.154 6.483a1.603 1.603 0 01-1.649-1.368h3.299c-.119.81-.831 1.4-1.65 1.368zm10.647-3.717c-.079.26-.286.543-.555.543h-20.185c-.268 0-.475-.281-.555-.543a1.098 1.098 0 01.508-1.267c2.811-1.9 2.717-4.614 2.627-7.246a13.764 13.764 0 01.357-4.575c1.099-3.577 3.706-5.628 7.154-5.628s6.056 2.05 7.155 5.628c.374 1.495.494 3.041.357 4.575-.09 2.628-.181 5.347 2.626 7.246.446.253.656.778.511 1.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "292.003 382.01 28 28"
    }
  };
});