define("ember-svg-jar/inlined/dots-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M294.3 352.2c-13.148 0-23.801 10.656-23.801 23.801 0 13.148 10.656 23.801 23.801 23.801 13.141 0 23.809-10.656 23.809-23.801-.004-13.145-10.668-23.801-23.809-23.801zm81.691 0c-13.148 0-23.801 10.656-23.801 23.801 0 13.148 10.656 23.801 23.801 23.801 13.141 0 23.809-10.656 23.809-23.801S389.132 352.2 375.991 352.2zm81.691 0c-13.141 0-23.797 10.656-23.797 23.801 0 13.148 10.656 23.801 23.797 23.801s23.809-10.656 23.809-23.801-10.664-23.801-23.809-23.801z\"/>",
    "attrs": {
      "fill": "#bbb",
      "width": "400",
      "height": "200pt",
      "viewBox": "250 250 250 250",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});