define("ember-promise-modals/components/modal", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/object/internals", "@ember/service", "focus-trap", "ember-promise-modals/templates/components/modal"], function (_exports, _component, _object, _computed, _internals, _service, _focusTrap, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _modal.default,
    tagName: '',
    outAnimationClass: 'epm-out',
    modals: (0, _service.inject)(),
    optionsClassName: (0, _computed.readOnly)('modal._options.className'),
    modalElementId: null,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'modalElementId', (0, _internals.guidFor)(this));
      this.modal._componentInstance = this;
    },
    didInsertElement() {
      this._super(...arguments);
      let {
        clickOutsideDeactivates
      } = this.modals;
      let element = document.getElementById(this.modalElementId);
      let options = {
        clickOutsideDeactivates,
        fallbackFocus: `#${this.modalElementId}`,
        onDeactivate: () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          this.closeModal();
        }
      };
      this.focusTrap = (0, _focusTrap.createFocusTrap)(element, options);
      this.focusTrap.activate();
      this.fadeOutEnd = ({
        target,
        animationName
      }) => {
        this.modals._onModalAnimationEnd();
        let isntTarget = target !== element;
        let animationEndsWrong = animationName.substring(animationName.length - 4) !== '-out';
        if (isntTarget || animationEndsWrong) {
          return;
        }
        this.modal._remove();
      };
      this.modals._onModalAnimationStart();
      element.addEventListener('animationend', this.fadeOutEnd);
      (0, _object.set)(this, 'animatingOutClass', '');
    },
    willDestroyElement() {
      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }
      if (this.fadeOutEnd) {
        let element = document.getElementById(this.modalElementId);
        if (element) {
          element.removeEventListener('animationend', this.fadeOutEnd);
          // make sure that we remove the modal, also resolving the test waiter
          this.modal._remove();
        }
      }
      this._super(...arguments);
    },
    closeModal(result) {
      // Trigger out animation
      (0, _object.set)(this, 'animatingOutClass', this.outAnimationClass);
      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }
      this.modal._resolve(result);
    },
    actions: {
      close(result) {
        this.closeModal(result);
      }
    }
  });
});