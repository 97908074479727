define("ember-simplemde/components/simple-mde", ["exports", "@ember/runloop", "@ember/application", "@ember/component/text-area", "@ember/polyfills", "@ember/object", "@ember/utils", "ember", "ember-simplemde/templates/components/simple-mde"], function (_exports, _runloop, _application, _textArea, _polyfills, _object, _utils, _ember, _simpleMde) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    testing
  } = _ember.default;

  /*global SimpleMDE*/

  const options = {};
  var _default = _exports.default = _textArea.default.extend({
    layout: _simpleMde.default,
    /**
     * @private
     * @variable
     * to hold the SimpleMDE instance
     */
    currentEditor: null,
    /**
     * action to call when the value on the editor changes
     */
    change: null,
    /**
    * instance options to pass to simpleMDE
    */
    options: options,
    /**
    * default simpleMDE options
    */
    defaultSimpleMdeOptions: (0, _object.computed)(function () {
      return {
        showIcons: ['table']
      };
    }),
    /**
    * global options defined in consuming apps config
    */
    globalSimpleMdeOptions: (0, _object.computed)(function () {
      if (testing) {
        return {};
      } else {
        return (0, _object.get)((0, _application.getOwner)(this).resolveRegistration('config:environment'), 'simpleMDE') || {};
      }
    }),
    /**
     * @method
     * @private
     * get the list of options to pass to init the SimpleMDE instance
     */
    buildSimpleMDEOptions: (0, _object.computed)(function () {
      let builtOptions = (0, _polyfills.assign)({}, this.get('defaultSimpleMdeOptions'), this.get('globalSimpleMdeOptions'), this.get('options'));
      if (builtOptions.toolbar && (0, _utils.typeOf)(builtOptions.toolbar) === 'array') {
        builtOptions.toolbar.forEach(this.unpackToolbarOption);
      }
      return builtOptions;
    }),
    /**
     * @method
     * @private
     * Because simpleMDE needs toolbar options action handler to be a function reference,
     * and if toolbar options are passed in from the consuming apps config they are passed in as strings.
     * Thus, we unpack them and restore the global reference.
     * If the toolbar action handler is a string, we attempt to reference the global function reference matching that string.
     */
    unpackToolbarOption: function (toolbarOption) {
      if ((0, _utils.typeOf)(toolbarOption.action) === 'string') {
        toolbarOption.action = toolbarOption.action.split('.').reduce(function (accumulator, value) {
          if (!accumulator) {
            accumulator = window[value];
          } else {
            accumulator = accumulator[value];
          }
          return accumulator;
        }, null);
      }
    },
    /**
     * @event
     * @public
     * instantiate the editor with the contents of value
     */
    didInsertElement() {
      this.set('currentEditor', new SimpleMDE((0, _polyfills.merge)({
        element: document.getElementById(this.elementId)
      }, this.get('buildSimpleMDEOptions'))));
      this.get('currentEditor').value(this.get('value') || '');
      this.get('currentEditor').codemirror.on('change', () => (0, _runloop.once)(this, function () {
        if (this.change) {
          this.change(this.get('currentEditor').value());
        }
      }));
    },
    willDestroyElement() {
      this.get('currentEditor').toTextArea();
      this.set('currentEditor', null);
    },
    /**
     * @event
     * @public
     * updates the editor when the value property change from the outside
     */
    didReceiveAttrs() {
      let editor = this.get('currentEditor');
      if ((0, _utils.isEmpty)(editor)) {
        return;
      }
      let cursor = editor.codemirror.getDoc().getCursor();
      editor.value(this.get('value') || '');
      editor.codemirror.getDoc().setCursor(cursor);
    }
  });
});