define("ember-svg-jar/inlined/csv-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M495.6 257.6v179.2a11.2 11.2 0 01-11.203 11.2h-291.2c-6.184 0-11.2-5.016-11.2-11.2V145.6c0-6.187 5.017-11.203 11.2-11.203h179.2c6.187 0 11.203 5.016 11.203 11.203 0 6.184-5.016 11.2-11.203 11.2h-168v268.8h268.8v-168c0-6.188 5.016-11.204 11.199-11.204 6.187 0 11.203 5.016 11.203 11.203zm21.547-138.59a11.06 11.06 0 00-3.652-4.48c-.36-.274-.73-.528-1.117-.762a11.005 11.005 0 00-5.579-1.77h-89.602c-6.183 0-11.199 5.016-11.199 11.2 0 6.187 5.016 11.198 11.2 11.198h62.561l-137.69 137.67c-4.378 4.38-4.378 11.48 0 15.86 4.38 4.378 11.48 4.378 15.86 0l137.67-137.69v62.561c0 6.184 5.011 11.2 11.198 11.2 6.184 0 11.2-5.016 11.2-11.2v-89.602a10.858 10.858 0 00-.852-4.187z\"/>",
    "attrs": {
      "fill": "#979797",
      "width": "700pt",
      "height": "700pt",
      "viewBox": "0 0 700 700",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});