define("ember-svg-jar/inlined/ion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M199 0c27 0 46 24 58 56 32-13 62-17 82 2 19 20 16 50 2 82 32 12 56 31 56 59 0 27-24 46-56 58 14 32 17 62-2 82-20 19-50 16-82 2-12 32-31 56-58 56-28 0-47-24-59-56-32 14-62 17-82-2-19-20-15-50-2-82-32-12-56-31-56-58 0-28 24-47 56-59-13-32-17-62 2-82 20-19 50-15 82-2 12-32 31-56 59-56zm64 72c5 16 9 33 12 50 17 3 34 7 50 12 12-26 16-50 2-64s-38-10-64 2zM134 325c-5-16-9-33-12-50-17-3-34-7-50-12-12 26-16 50-2 64s38 10 64-2zm-71-83c8-15 18-30 28-43-10-14-20-29-28-44-26 10-46 24-46 44 0 19 20 33 46 43zm38-58c6-7 11-13 16-19 0-8 1-16 3-25-14 3-28 6-40 9 6 12 14 24 21 35zm36-41l6-7-6 1v6zm28-26c6-5 12-10 19-16-11-7-23-15-35-21-3 12-6 26-9 40 9-2 17-3 25-3zm34-26c13-10 28-20 43-28-10-26-24-46-43-46-20 0-34 20-44 46 15 8 30 18 44 28zm135 64c-8 15-18 30-28 44 10 13 20 28 28 43 26-10 46-24 46-43 0-20-20-34-46-44zm-38 58c-6 7-11 13-16 19 0 8-1 16-2 25 13-2 27-5 39-9-6-12-14-24-21-35zm-35 41l-7 7 6-1 1-6zm-29 26c-5 5-12 10-19 16 11 7 23 15 35 21 4-12 7-26 9-39-9 1-17 2-25 2zm-33 26c-14 10-29 20-44 28 10 26 24 46 44 46 19 0 33-20 43-46-15-8-30-18-43-28zm-50 11c12-6 24-14 35-21-7-6-13-11-19-16-8 0-16-1-25-2 3 13 6 27 9 39zm50-32l4-3h-9l5 3zm26-21c10-8 17-15 21-18 3-4 10-11 18-21 1-11 1-20 1-26 0-7 0-16-1-27-8-10-15-17-18-20-4-4-11-11-21-19-11-1-20-1-26-1-7 0-16 0-27 1-10 8-17 15-20 19-4 3-11 10-19 20 0 11-1 20-1 27 0 6 1 15 1 26 8 10 15 17 19 21 3 3 10 10 20 18 11 1 20 1 27 1 6 0 15 0 26-1zm57-61l3-4-3-5v9zm14-19c7-11 15-23 21-35-12-3-26-6-39-9 1 9 2 17 2 25 5 6 10 12 16 19zM248 80c-12 6-24 14-35 21 7 6 14 11 19 16 8 0 16 1 25 3-2-14-5-28-9-40zm-49 32l-5 3h9l-4-3zm-84 82l-3 5 3 4v-9zm-14 19c-7 11-15 23-21 35 12 4 26 7 40 9-2-9-3-17-3-25-5-5-10-12-16-19zm-29-79c16-5 33-9 50-12 3-17 7-34 12-50-26-12-50-16-64-2s-10 38 2 64zm253 129c-16 5-33 9-50 12-3 17-7 34-12 50 26 12 50 16 64 2s10-38-2-64zm-188-3l6 1-6-7v6zm123-123l-6-1 7 7-1-6z\" fill=\"#4c5566\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "height": "100",
      "width": "100",
      "fill": "#4C5566",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 397 397",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "shape-rendering": "geometricPrecision",
      "text-rendering": "geometricPrecision",
      "image-rendering": "optimizeQuality"
    }
  };
});