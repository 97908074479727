define("ember-composable-helpers/helpers/contains", ["exports", "@ember/array", "@ember/component/helper", "ember-composable-helpers/utils/includes"], function (_exports, _array, _helper, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function _contains(needle, haystack) {
    return (0, _includes.default)((0, _array.A)(haystack), needle);
  }
  function contains(needle, haystack) {
    if (!(0, _array.isArray)(haystack)) {
      return false;
    }
    if ((0, _array.isArray)(needle)) {
      return needle.reduce((acc, val) => acc && _contains(val, haystack), true);
    }
    return _contains(needle, haystack);
  }
  var _default = _exports.default = (0, _helper.helper)(function ([needle, haystack]) {
    return contains(needle, haystack);
  });
});