define("ember-filestack/services/filestack", ["exports", "@ember/service", "@ember/application", "@ember/polyfills", "ember-filestack/utils/sanitize-transformations"], function (_exports, _service, _application, _polyfills, _sanitizeTransformations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    async initClient() {
      if (this.client) {
        return this.client;
      }
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let emberFilestackOptions = ENV['ember-filestack'] || {};
      let apiKey = emberFilestackOptions.apiKey;
      let clientOptions = emberFilestackOptions.clientOptions;
      if (!apiKey) {
        throw new Error('Filestack API key not found.');
      }
      let filestack = await emberAutoImportDynamic("filestack-js");
      let client = filestack.init(apiKey, clientOptions);
      this.set('client', client);
      return client;
    },
    getUrl(handleOrUrl, transformations) {
      let filestack = this.client;
      if (!filestack) {
        throw new Error('Attempted to generate a transform url without calling `initClient` first.');
      }

      // glimmer gives us immutable EmptyObject instances, so we need to clone them
      transformations = (0, _polyfills.assign)({}, transformations);
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let emberFilestackOptions = ENV['ember-filestack'] || {};
      let customCDN = emberFilestackOptions.customCDN;
      let isUrl = handleOrUrl.match(/^http(s?):\/\//);
      let filestackUrl;
      if (isUrl && Object.keys(transformations).length === 0) {
        filestackUrl = handleOrUrl;
      } else {
        (0, _sanitizeTransformations.default)(transformations);
        filestackUrl = filestack.transform(handleOrUrl, transformations);
      }
      if (customCDN) {
        return filestackUrl.replace(filestack.session.urls.cdnUrl, customCDN);
      } else {
        return filestackUrl;
      }
    },
    async getPicker(options) {
      let filestack = this.client;
      if (!filestack) {
        throw new Error('Attempted to generate a transform url without calling `initClient` first.');
      }
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let emberFilestackOptions = ENV['ember-filestack'] || {};
      let configOptions = emberFilestackOptions.pickerOptions;
      let pickerOptions = (0, _polyfills.assign)({}, configOptions, options);
      return await filestack.picker(pickerOptions);
    },
    preview(handle, options) {
      let filestack = this.client;
      if (!filestack) {
        throw new Error('Attempted to preview file without calling `initClient` first.');
      }
      let ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      let emberFilestackOptions = ENV['ember-filestack'] || {};
      let configOptions = emberFilestackOptions.previewOptions;
      let previewOptions = (0, _polyfills.assign)({}, configOptions, options);
      return filestack.preview(handle, previewOptions);
    }
  });
});