define("ember-svg-jar/inlined/profile-pic-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M50 9.979c-22.056 0-40 17.943-40 40s17.944 40 40 40 40-17.944 40-40-17.944-40-40-40zM23.304 76.99c4.746-10.43 15.148-17.155 26.63-17.155A29.212 29.212 0 0176.6 77.081C69.74 83.815 60.35 87.98 50 87.98c-10.396 0-19.828-4.2-26.696-10.989zm54.783-1.443a31.204 31.204 0 00-28.153-17.712c-12.002 0-22.892 6.882-28.106 17.616C15.724 68.707 12 59.77 12 49.98c0-20.954 17.047-38 38-38s38 17.046 38 38c0 9.837-3.758 18.812-9.913 25.568z\"/><path d=\"M50 19.68c-9.03 0-16.377 7.346-16.377 16.377S40.97 52.434 50 52.434s16.377-7.347 16.377-16.377S59.03 19.68 50 19.68zm0 30.754c-7.928 0-14.377-6.45-14.377-14.377S42.073 21.68 50 21.68s14.377 6.45 14.377 14.377S57.927 50.434 50 50.434z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});