define("ember-svg-jar/inlined/thumbs-up-black", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.35 12.428a2.619 2.619 0 00-2.626-2.626h-5.46c.52-1.118 1.118-2.834 1.118-4.68 0-1.742-.936-3.12-2.652-3.926a2.37 2.37 0 00-2.054 0 2.415 2.415 0 00-1.3 1.586l-.338 1.274c-.598 2.288-1.846 4.29-3.536 5.642-.312.26-.65.468-.988.676a1.522 1.522 0 00-1.17-.546h-4.16c-.832 0-1.534.676-1.534 1.534v12.35c0 .832.676 1.534 1.534 1.534h4.134c.52 0 .962-.26 1.248-.65 2.522.728 4.836 1.092 7.358 1.092h6.032a2.619 2.619 0 002.626-2.626c0-.468-.13-.936-.338-1.3a2.657 2.657 0 001.222-2.21c0-.468-.13-.91-.338-1.3a2.657 2.657 0 001.222-2.21c0-.676-.26-1.3-.676-1.768.416-.546.676-1.17.676-1.846zM4.264 23.114a1.11 1.11 0 01-1.118-1.118 1.11 1.11 0 011.118-1.118 1.11 1.11 0 011.118 1.118c0 .598-.494 1.118-1.118 1.118zM21.06 15.08h1.664c.494 0 .91.416.91.91 0 .494-.39.91-.91.91h-1.976a.866.866 0 00-.858.858c0 .468.39.858.858.858h1.092c.494 0 .91.416.91.91 0 .494-.39.91-.91.91h-1.742a.866.866 0 00-.858.858c0 .468.39.858.858.858h.884c.494 0 .91.416.91.91 0 .494-.39.91-.91.91h-6.058c-2.418 0-4.628-.364-7.072-1.092V12.142a9.32 9.32 0 001.69-1.092c1.976-1.586 3.458-3.9 4.134-6.578l.338-1.274c.078-.26.26-.39.364-.442a.618.618 0 01.572 0c1.118.52 1.664 1.274 1.664 2.34 0 2.652-1.534 5.096-1.534 5.096a.892.892 0 00-.026.884.843.843 0 00.754.442h6.916c.494 0 .91.416.91.91 0 .494-.39.91-.91.91H21.06a.866.866 0 00-.858.858c0 .468.364.884.858.884z\" fill=\"#000\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});